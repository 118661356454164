import React, { useState, useMemo } from 'react';
import t from 'components/i18n';

import ProfileMenu from '../ProfileMenu';
import InsureScreen from './InsureScreen';

export default function Documentation({ type, history, user }) {
  const [activeItem, setActiveItem] = useState(1);

  const insurePermission =
    user.profile === 'Administrador' &&
    user.type !== 'F' &&
    user.type !== 'E';

  const menuItems = useMemo(
    () => [
      {
        name: t(
          'menu.profile.sectionDocumentation.buttonEnsurancePolicy',
        ),
        icon: 'icon-shield',
        key: 1,
        visible: insurePermission,
      },
    ],
    [insurePermission],
  );

  return (
    <>
      <div className="secondary-wrapper">
        <ProfileMenu
          menuItems={menuItems}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      </div>

      {activeItem === 1 && insurePermission && (
        <InsureScreen history={history} />
      )}
    </>
  );
}
