import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import Cropper from 'react-easy-crop';
import { Modal, Header, Body } from '@zendeskgarden/react-modals';
import { Button } from '@zendeskgarden/react-buttons';
import { Field, Range } from '@zendeskgarden/react-forms';
import { getCroppedImg } from 'shared/utils/Helpers';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import {
  UploadContainer,
  ImageWrapper,
  CropWrapper,
  CropContainer,
  FalseButton,
  Separator,
} from './UploadAndResizeFile.styled';
import t from 'components/i18n';

export default function UploadAndResizeFile({
  isLoading,
  source,
  buttonLabel,
  title,
  image,
  handleFile,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const inputFileRef = useRef();
  const sliderRef = useRef();

  // const updateZoom = useCallback(
  //   props => {
  //     if (props === 'in') {
  //       setZoom(zoom === 10 ? 10 : zoom + 1);
  //     }

  //     if (props === 'out') {
  //       setZoom(zoom === 1 ? 1 : zoom - 1);
  //     }
  //   },
  //   [zoom],
  // );

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPxls) => {
      setCroppedAreaPixels(croppedAreaPxls);
    },
    [],
  );

  const useCroppedImage = useCallback(async () => {
    try {
      const croppedImg = await getCroppedImg(
        source,
        croppedAreaPixels,
      );
      setCroppedImage(croppedImg);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, source]);

  useEffect(() => {
    if (croppedImage) {
      handleFile(croppedImage);
      setOpenModal(false);
    }
  }, [croppedImage, handleFile]);

  return (
    <UploadContainer>
      <ImageWrapper
        style={{ backgroundImage: image ? `url(${source})` : 'none' }}
        className="animated fadeIn"
      >
        {!image && <span className="icon icon-camera" />}
      </ImageWrapper>

      <input
        className="hidden"
        type="file"
        ref={inputFileRef}
        onChange={e => handleFile(e.target.files[0])}
        accept="image/*"
      />

      <Button
        className="white"
        onClick={() => {
          setOpenModal(true);
          inputFileRef.current.click();
        }}
      >
        {t(buttonLabel)}
      </Button>

      {openModal && (
        <Modal
          animate
          onClose={() => setOpenModal(false)}
          className="upload-modal"
        >
          <Header style={{ padding: 20 }}>{t('uploadAndResizeFile.resize')}</Header>

          <Body style={{ height: 480, padding: 20 }}>
            <CropWrapper>
              <CropContainer>
                <Cropper
                  image={source}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  cropShape="round"
                  showGrid={false}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  zoomWithScroll={false}
                />
              </CropContainer>

              <Field>
                <div className="rangeWrapper">
                  <FalseButton> - </FalseButton>

                  <Range
                    ref={sliderRef}
                    className="slider"
                    value={zoom}
                    min={1}
                    max={10}
                    step={1}
                    aria-labelledby="Zoom"
                    onChange={e => setZoom(Number(e.target.value))}
                  />

                  <FalseButton> + </FalseButton>
                </div>
              </Field>

              {/* {croppedImage && (
                <img src={croppedImage} alt="cropped" />
              )} */}

              <Separator />

              {/* \ Botões */}
              <BtnGroup>
                <Button key="edit" onClick={useCroppedImage}>
                  {t('uploadAndResizeFile.use')}
                </Button>

                <Button
                  key="clean"
                  onClick={() => setOpenModal(false)}
                  className="white"
                >
                  {t('uploadAndResizeFile.discart')}
                </Button>
              </BtnGroup>
              {/* \ Buttons */}
              <br />
            </CropWrapper>
          </Body>
        </Modal>
      )}
    </UploadContainer>
  );
}
