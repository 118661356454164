import styled from 'styled-components';
import {
  colorClear,
  colorGrayLightest,
  colorGrayLight,
  boxShadow,
  DEFAULT_SIZE,
} from 'components/GlobalStyles/variables';

export const RoundContainer = styled.div`
  position: relative;
  border: ${props =>
    props.bgColor
      ? `1px solid transparent`
      : `1px solid ${colorGrayLightest}`};
  box-sizing: border-box;
  background-color: ${props => props.bgColor || colorClear};
  border-radius: ${DEFAULT_SIZE}px;
  box-shadow: ${props => (props.noShadow ? '0' : boxShadow)};
  margin: ${props =>
    props.margin
      ? props.margin
      : props.noMargin
        ? '0 0 16px 0'
        : ' 0 0 16px 36px'};
  padding: ${props => props.padding || 0};
  overflow: hidden;

  &.steps {
    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 36px;
      background-color: #fff;
      z-index: 1;
      position: absolute;
      left: -34px;
      top: calc(50% - 18px);
    }

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 4px);
      left: -30px;
      line-height: 1;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      z-index: 20;
      background-color: ${colorGrayLight};
    }
  }

  .list-content {
    padding-top: 40px;
  }
`;
