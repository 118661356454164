import React, { useState, useEffect, useMemo } from 'react';
import t from 'components/i18n';
import ProfileMenu from '../ProfileMenu';
import { PersonalData } from './PersonalData';
import { AccountData } from './AccountData';
import { CollaboratorScreen } from './CollaboratorScreen';

export default function AccountSettings({ type, history, user }) {
  const [activeItem, setActiveItem] = useState(1);

  const collaboratorPermissions =
    user.type === 'P' && user.profile === 'Administrador';

  const menuItems = useMemo(
    () => [
      {
        name: t('menu.profile.personalData.title'),
        icon: 'icon-user-alt',
        key: 1,
        type: 'personal-data',
        visible: true,
      },
      {
        name: t('menu.profile.accountData.title'),
        icon: 'icon-lock-enabled',
        key: 2,
        type: 'account-data',
        visible: true,
      },
      {
        name: t('menu.profile.sectionAccountConfig.buttonEmployees'),
        icon: 'icon-clipboard-user',
        key: 3,
        type: 'collaborators',
        visible: collaboratorPermissions,
      },
    ],
    [collaboratorPermissions],
  );

  useEffect(() => {
    if (type) {
      setActiveItem(menuItems.find(item => item.type === type).key);
    }
  }, [menuItems, type]);

  return (
    <>
      <div className="secondary-wrapper">
        <ProfileMenu
          menuItems={menuItems}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      </div>

      {activeItem === 1 && <PersonalData history={history} />}

      {activeItem === 2 && <AccountData history={history} />}

      {activeItem === 3 && (
        <CollaboratorScreen history={history} user={user} />
      )}
    </>
  );
}
