import React from 'react';
import Modal from 'components/Forms/FormModal/Modal';
import * as routes from 'config/routes';
import t from 'components/i18n';

export default function SubmitModal({
  success,
  errorMessage,
  setSuccess,
  setErrorMessage,
  setCanEdit,
  history
}) {
  return (
    <Modal
      noButtons
      opened={success || errorMessage}
      warning={errorMessage}
      onCloseModal={() => {
        setSuccess(false);
        setErrorMessage('');
        setCanEdit(false);
        history.push(routes.WEEK_SCHEDULE);
      }}
    >
      <div className="modal-title">
        <span
          className={`icon icon-${
            success ? 'check' : 'exclamation-triangle'
          }`}
        />
        <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
      </div>

      <p>
        {success
          ? t('next.weekSchedule.submitModal.success')
          : errorMessage || t('next.weekSchedule.submitModal.error')}
      </p>
    </Modal>
  );
}
