import { Button } from '@zendeskgarden/react-buttons';

import styled from 'styled-components';
import { rgba } from 'polished';

import ContainerBase from 'components/Container';
import {
  colorDanger,
  colorGrayDarker,
  colorGrayDarkest,
  colorSuccess,
} from 'components/GlobalStyles/variables';
import { BtnGroup as BtnGroupBase } from 'components/Forms/BtnGroup/BtnGroup.styled';

const IconButton = styled(Button)`
  padding: 0 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: ${({ filled }) => filled ? "end" : "space-between"};
  padding: 0 1rem;
  width: 100%;
`;

const Separator = styled.hr`
  margin: 0 -16px;
  opacity: 0.25;
`;

const Container = styled(ContainerBase)`
  margin: 20px;
  max-width: 50%;
  min-width: 600px;

  .header {
    margin-bottom: 16px;
  }
`;

const FormSection = styled.section`
  padding: 16px 0 12px;
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.16)};
  margin: 0 -16px;
`;

const Item = styled.li`
  color: ${colorGrayDarkest};
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.16)};
  padding: 16px;
  font-size: 14px;
  line-height: 21px;
  list-style: none;

  p {
    margin: 0;
  }

  &:last-of-type {
    border: none;
  }

  &.right {
    justify-content: flex-end;
  }

  .margin {
    margin-bottom: 8px;
  }

  .label {
    font-size: 12px;
  }

  .small {
    font-size: 12px;
    line-height: 16px;
  }

  .icon {
    font-size: 21px;
    margin: 0 0 0 16px;
  }

  button {
    width: 154px;
  }
`;

const SectionWrapper = styled.div`
  padding: 16px;

  & .file-input__items {
    margin-left: 0;
    margin-bottom: 12px;
  }

  & .file-input {
    margin-top: 14px;
  }
`;

const Title = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 24px;
  color: ${colorGrayDarker}
    ${({ required }) =>
      required &&
      `
    &:after {
      content: "*";
      color: #D22630;
    }
  `};
`;

const DFlex = styled.div`
  display: flex;
`;

const BtnGroup = styled(BtnGroupBase)`
  margin-top: 16px;
  display: block;
`;

const StatusBadgeContainer = styled.div`
  height: 42px;
  background-color:
    ${({ isApproved }) =>
    isApproved ? colorSuccess : colorDanger};
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  span {
    margin: 0 10px 0 20px;
    font-size: 24px;
  }
`;

export {
  IconButton,
  BtnWrapper,
  Separator,
  Container,
  FormSection,
  Item,
  SectionWrapper,
  Title,
  DFlex,
  BtnGroup,
  StatusBadgeContainer
}
