import styled from 'styled-components';
import { utils } from 'styled-minimal';

export const Base = styled.div`
  position: fixed;
  z-index: 1000;
  > div {
    > * + * {
      margin-top: ${utils.spacer(3)};
    }
  }
`;

export const TopLeft = styled(Base)`
  left: ${utils.spacer(3)};
  top: ${utils.spacer(3)};
  width: 26rem;
  /* stylelint-disable */
  ${utils.responsive({
    md: `
      width: 32rem;
    `,
  })};
  /* stylelint-enable */
`;

export const TopRight = styled(Base)`
  right: ${utils.spacer(3)};
  top: ${utils.spacer(3)};
  width: 26rem;

  /* stylelint-disable */
  ${utils.responsive({
    md: `
      width: 32rem;
    `,
  })};
  /* stylelint-enable */
`;

export const BottomLeft = styled(Base)`
  bottom: ${utils.spacer(3)};
  left: ${utils.spacer(3)};
  width: 26rem;

  /* stylelint-disable */
  ${utils.responsive({
    md: `
      width: 32rem;
    `,
  })};
  /* stylelint-enable */
`;

export const BottomRight = styled(Base)`
  bottom: ${utils.spacer(3)};
  right: ${utils.spacer(3)};
  width: 26rem;

  /* stylelint-disable */
  ${utils.responsive({
    md: `
      width: 32rem;
    `,
  })};
  /* stylelint-enable */
`;

export const SystemAlertsWrapper = styled.div`
  pointer-events: none;
  position: fixed;
  z-index: 1000;
`;
