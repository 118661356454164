import React from 'react';
import Badge from 'components/Badge';
import Card from 'components/Card';
import { Button } from '@zendeskgarden/react-buttons';
import { colorGrayDarkest } from 'components/GlobalStyles/variables';
import {
  StyledFlockVisitCircuit,
  CardTitle,
  FlockList,
} from './VisitCircuit.styled';
import t from 'components/i18n';
import { statusMapper } from 'utils/mapper';

export default function FlockVisitCircuitCard(props) {
  const {
    flockList,
    status,
    onClick,
    cardTitle,
    cardSubtitle,
    selectedFlockCircuit
  } = props;

  const badgeProps = {};

  if (status) {
    if (selectedFlockCircuit.isFinished) {
      badgeProps.warning = true;
    } else {
      switch (status.toLowerCase()) {
        case 'andamento':
          badgeProps.info = true;
          break;
        case 'pendente':
          badgeProps.danger = true;
          break;
        default:
          badgeProps.success = true;
      }
    }
  }

  return (
    <StyledFlockVisitCircuit>
      <Card
        footerContent={
          selectedFlockCircuit.isFinished
            ? t('next.visitCircuit.visitCircuitList.schedule.fillingPercentage.incomplete')
            : t(statusMapper(status)) || t('next.visitCircuit.flockVisitCircuitCard.status.incomplete')
        }
        footerColor={ selectedFlockCircuit.isFinished ? "colorWarning" : "colorInfo" }
        footerIcon={ selectedFlockCircuit.isFinished ? "icon-stopwatch" : "icon-clock" }
      >
        <div className="flex">
          <CardTitle>
            <h2>{cardTitle}</h2>
            <p>{cardSubtitle}</p>
          </CardTitle>

          <Badge {...badgeProps}>
            {selectedFlockCircuit.isFinished
              ? t('next.visitCircuit.visitCircuitList.schedule.fillingPercentage.incomplete')
              : t(statusMapper(status))
            }
          </Badge>
        </div>

        <div className="flex">
          <FlockList>
            {flockList.map(item => (
              <li className="list-item">{t('next.visitCircuit.flockVisitCircuitCard.flock', { value: item })}</li>
            ))}
          </FlockList>

          <Button
            className="white flock"
            style={{
              alignSelf: 'flex-start',
              color: colorGrayDarkest,
            }}
            onClick={() => onClick()}
          >
            {t('next.visitCircuit.flockVisitCircuitCard.button.see-details')}
          </Button>
        </div>
      </Card>
    </StyledFlockVisitCircuit>
  );
}
