import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  colorGrayUltraLight,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

export const GroupedDetailsHeader = styled.header`
  h5 {
    font-family: ${fontFamilyTextBold};
    font-size: 14px;
    line-height: 21px;
    padding: ${DEFAULT_SIZE}px;
    margin: 0 0 0 12px;
  }
`;

export const GrayBackGround = styled.div`
  background-color: ${colorGrayUltraLight};
  padding: ${DEFAULT_SIZE * 2}px;
`;
