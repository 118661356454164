import React, { useState } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { List, ListItem } from 'components/List';
import CircleAndTitle from 'components/CircleAndTitle';
import { Form } from 'components/Forms/Forms.styled';
import MultiSelect from 'components/MultiSelect';
import { uniqBy, find } from 'lodash';
import {
  InputField,
  DropdownField,
  EditorField,
  FileInput,
} from 'components/Forms/Fields';
import { uploadFile, deleteFile } from 'shared/blob/service';
import {
  Separator,
  Subheading,
  Video,
} from 'containers/AgroBRF/Announcements/Announcements.styled';
import {
  allCentersOption,
  allLocationOption,
  allRegionalsOption,
} from 'containers/SharedContainers/Library/utils';
import t from 'components/i18n';

import { regionalMapper } from 'utils/mapper';
import {
  animalTypeOptions,
  exhibitionOptions,
  priorityOptions,
} from './data';

export default function AnnouncementsForm(props) {
  const {
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    files,
    setFiles,
    regionals = [],
    isEditing,
    resetForm,
    values,
    errors,
    touched,
    setUploadErrorMessage,
    userToken
  } = props;

  const [isUploading, setIsUploading] = useState(false);
  const [locale, setLocale] = useState([]);

  async function handleFile(file) {
    setIsUploading(true);
    try {
      const response = await uploadFile(file);
      if (response && response.length) {
        setFiles([...files, response]);
      } else {
        setUploadErrorMessage(
          t('announcements.form.error.loading-file', { value: file }),
        );
      }
    } catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  }

  async function removeFile(file) {
    setIsUploading(true);
    try {
      await deleteFile(file);
      setFiles(
        files.filter(e => {
          const item = typeof e === 'object' ? e.name : e;
          return item !== file;
        }),
      );
    } catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  }

  const localeOptions = React.useMemo(() => {
    const hasAllInclude = values['animalType'].value === 'all';

    const localeByAnimal = regionals.filter(regional =>
      regional.centerList.some(
        cl =>
          hasAllInclude ||
          cl.animalType === values['animalType'].value,
      ),
    );

    const uniqueLocales = regionals.length
      ? uniqBy(
          localeByAnimal.map((element, index) => ({
            countryCode: element.countryCode,
            id: index,
          })),
          'countryCode',
        )
      : [];

    const options = [
      {
        ...allLocationOption,
        values: uniqueLocales,
      },
      ...uniqueLocales,
    ];

    return {
      localeByAnimal,
      options,
    };
  }, [values]);

  const regionalsOptions = React.useMemo(() => {
    const countryCodes = (values['countryCode'] || []).map(
      el => el.countryCode,
    );

    const hasAllInclude = Boolean(
      find(values['countryCode'], { id: 'all' }),
    );

    const regionalsByLocales = localeOptions.localeByAnimal.filter(
      regional =>
        hasAllInclude || countryCodes.includes(regional.countryCode),
    );

    const unitOptions = regionalsByLocales.map(regional => ({
      ...regional,
      label: t(regionalMapper(regional.name)),
      centerList: regional.centerList.filter(
        centerList =>
          values['animalType'].value === 'all' ||
          centerList.animalType === values['animalType'].value,
      ),
    }));

    const options = [
      {
        ...allRegionalsOption,
        values: unitOptions,
      },
      ...unitOptions,
    ];

    return {
      regionalsByLocales,
      options,
    };
  }, [values, localeOptions]);

  const centerOptions = React.useMemo(() => {
    const regionals = (values['regionals'] || []).map(el => el.name);
    const animalType = values['animalType'].value;

    const hasAllInclude = Boolean(
      find(values['regionals'], { id: 'all' }),
    );

    const centerByRegionals = regionalsOptions.regionalsByLocales.filter(
      regional => hasAllInclude || regionals.includes(regional.name),
    );

    const unitOptions = centerByRegionals
      .map(regional => regional.centerList)
      .flat()
      .filter(
        centerList =>
          animalType === 'all' ||
          centerList.animalType === animalType,
      );

    const options = [
      {
        ...allCentersOption,
        centerList: unitOptions,
      },
      ...unitOptions,
    ];

    return {
      centerByRegionals,
      options,
    };
  }, [values, localeOptions]);

  return (
    <>
      <Form autoComplete="off">
        <List>
          <ListItem noPadding>
            <CircleAndTitle
              initials="01"
              name={t('announcements.form.title')}
              bgColor="colorPrimary"
            />
          </ListItem>
        </List>

        <Row>
          <Col>
            <InputField
              type="text"
              field="title"
              label={t('announcements.form.title.label')}
              placeholder={t('announcements.form.title.placeholder')}
              onBlur={handleBlur}
              onChange={e => {
                handleChange(e);
              }}
              max={80}
              counter
              values={values}
              errors={errors}
              touched={touched}
            />

            <InputField
              type="text"
              field="shortDescription"
              label={t('announcements.form.shortDescription.label')}
              placeholder={t(
                'announcements.form.shortDescription.placeholder',
              )}
              onBlur={handleBlur}
              onChange={e => {
                handleChange(e);
              }}
              max={80}
              counter
              values={values}
              errors={errors}
              touched={touched}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Video>
              <EditorField
                field="description"
                label={t('announcements.form.description.label')}
                setState={e => {
                  setFieldValue('description', e);
                }}
                disabled={false}
                onBlur={e => handleBlur(e)}
                onChange={e => handleChange(e)}
                toolbarOnFocus={false}
                values={values}
                errors={errors}
                touched={touched}
              />
            </Video>
          </Col>
        </Row>

        <Separator />

        <List>
          <ListItem noPadding>
            <CircleAndTitle
              initials="02"
              name={t("announcements.form.source.title")}
              bgColor="colorPrimary"
            />
          </ListItem>
        </List>

        <Row>
          <Col sm={6}>
            <InputField
              type="text"
              field="sourceName"
              label={t('announcements.form.sourceName.label')}
              placeholder={t(
                'announcements.form.sourceName.placeholder',
              )}
              onBlur={handleBlur}
              onChange={e => handleChange(e)}
              setState={e => {
                setFieldValue('sourceName', e);
              }}
              values={values}
              errors={errors}
              touched={touched}
            />
          </Col>

          <Col sm={6}>
            <InputField
              type="text"
              field="sourceUrl"
              label={t('announcements.form.sourceUrl.label')}
              placeholder={t(
                'announcements.form.sourceUrl.placeholder',
              )}
              onBlur={handleBlur}
              onChange={e => {
                handleChange(e);
              }}
              setState={e => {
                setFieldValue('sourceUrl', e);
              }}
              values={values}
              errors={errors}
              touched={touched}
            />
          </Col>
        </Row>

        <Separator gap={16} />

        <List>
          <ListItem noPadding>
            <CircleAndTitle
              initials="03"
              name={t('announcements.form.audience.title')}
              bgColor="colorPrimary"
            />
          </ListItem>
        </List>

        <Row>
          <Col>
            <DropdownField
              field="animalType"
              label={t('announcements.form.animalType.label')}
              placeholder={t(
                'announcements.form.animalType.placeholder',
              )}
              onSelect={e => {
                setFieldValue('animalType', e);
                setFieldValue('countryCode', []);
                setFieldValue('regionals', []);
                setFieldValue('centerList', []);
              }}
              options={animalTypeOptions || []}
              optionLabel="label"
              values={values}
              errors={errors}
              touched={touched}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <DropdownField
              field="showTo"
              label={t('announcements.form.showTo.label')}
              placeholder={t('announcements.form.showTo.placeholder')}
              onSelect={e => {
                setFieldValue('showTo', e);
              }}
              options={exhibitionOptions || []}
              optionLabel="label"
              values={values}
              errors={errors}
              touched={touched}
            />
          </Col>
        </Row>

        <br />

        <Subheading small>
          {t('announcements.form.location.title')}
        </Subheading>

        <Row>
          <Col>
            <MultiSelect
              field="countryCode"
              label={t('announcements.form.countryCode.label')}
              placeholder={t(
                'announcements.form.countryCode.placeholder',
              )}
              addItem={(_, values) => {
                setLocale(values);
                setFieldValue('countryCode', values);
                setFieldValue('regionals', []);
                setFieldValue('centerList', []);
              }}
              removeItemBy="countryCode"
              disabled={false}
              options={localeOptions.options}
              optionLabel="countryCode"
              values={values}
              group={false}
              errors={errors}
              touched={touched}
            />
          </Col>
        </Row>

        <Row>
          {regionals && regionals.length > 0 && (
            <Col>
              <MultiSelect
                field="regionals"
                label={t('announcements.form.regionals.label')}
                placeholder={t(
                  'announcements.form.regionals.placeholder',
                )}
                addItem={(key, values) => {
                  setFieldValue(key, values);
                  setFieldValue('centerList', []);
                }}
                removeItemBy="id"
                disabled={
                  !values.countryCode ||
                  Array.isArray(values.countryCode) && !values.countryCode.length
                }
                options={regionalsOptions.options}
                optionLabel="label"
                values={values}
                errors={errors}
                touched={touched}
              />
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <MultiSelect
              field="centerList"
              label={t('announcements.form.centerList.label')}
              placeholder={t(
                'announcements.form.centerList.placeholder',
              )}
              addItem={setFieldValue}
              removeItemBy="code"
              disabled={
                !values.regionals ||
                Array.isArray(values.regionals) && !values.regionals.length
              }
              options={centerOptions.options}
              optionLabel="name"
              values={values}
              errors={errors}
              touched={touched}
            />
          </Col>
        </Row>

        <Separator />

        <List>
          <ListItem noPadding>
            <CircleAndTitle
              initials="04"
              name={t('announcements.form.priority.title')}
              bgColor="colorPrimary"
            />
          </ListItem>
        </List>

        <Row>
          <Col>
            <DropdownField
              field="priority"
              label=""
              placeholder=""
              onSelect={e => {
                setFieldValue('priority', e);
              }}
              options={priorityOptions}
              optionLabel="label"
              values={values}
              errors={errors}
              touched={touched}
            />
          </Col>
        </Row>

        <Separator />

        <List>
          <ListItem noPadding>
            <CircleAndTitle
              initials="05"
              name={t('announcements.form.attach.title')}
              bgColor="colorPrimary"
            />
          </ListItem>
        </List>

        <FileInput
          type="file"
          field="files"
          label=""
          onChange={e => {
            handleFile(e.target.files[0]);
            handleChange(e);
          }}
          removeFile={removeFile}
          isLoading={isUploading}
          itemsToList={files}
          blobContainerName="announcements"
          itemLabel="name"
          onBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
          token={userToken}
        />

        <Separator />

        <Row>
          <Col sm={6} />
          <Col sm={6}>
            <BtnGroup>
              <Button
                key="submitForm"
                onClick={e => handleSubmit(e)}
                disabled={!props.isValid || props.isValidating}
              >
                {t('global.button.save')}
              </Button>
              {Object.keys(props.announcement).length > 0 ? (
                <Button
                  key="clean"
                  className="white"
                  onClick={() => {
                    props.setEditAnnouncement(false);
                  }}
                >
                  {t('global.button.cancel')}
                </Button>
              ) : (
                <Button
                  key="clean"
                  className="white"
                  onClick={() => {
                    resetForm();
                  }}
                >
                  {t('global.button.clear')}
                </Button>
              )}
            </BtnGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
}
