import React from 'react';
import {
  Message,
  Label as TextLabel,
} from '@zendeskgarden/react-forms';

import {
  getValidationMessage,
  getValidation,
} from 'shared/utils/Helpers';
import MaskedInput from 'components/MaskedInput';

export default function FloatInputField(props) {
  const inputName =
    props.index !== undefined ? props.fieldString : props.field;
  const inputValue =
    props.index !== undefined
      ? props.values[props.from][props.index][props.fieldString]
      : props.values[props.field];

  return (
    <>
      <TextLabel>{props.label}</TextLabel>
      <MaskedInput
        name={inputName}
        type="float"
        placeholder={props.placeholder}
        setState={props.setState}
        value={inputValue}
        onBlur={props.onBlur}
        onChange={props.onChange}
        max={props.max}
        disabled={props.disabled}
      />
      {props.index !== undefined ? (
        <Message
          validation={getValidation(
            props.values[props.from][props.index][props.fieldString],
            props.errors,
            `${props.from}[${props.index}].${props.fieldString}`,
            props.touched[props.from],
          )}
        >
          {getValidationMessage(
            `${props.from}[${props.index}].${props.fieldString}`,
            props.errors,
            props.touched[props.from],
          )}
        </Message>
      ) : (
        <Message
          validation={getValidation(
            props.values[props.field],
            props.errors,
            props.field,
            !props.touched[props.field],
          )}
        >
          {getValidationMessage(
            props.field,
            props.errors,
            !props.touched[props.field],
          )}
        </Message>
      )}
    </>
  );
}
