import React from 'react';
import moment from 'moment';
import DateAndTime from 'components/DateAndTime';
import KeyValueTable from 'components/KeyValueTable';
import IconPlaceholder from 'components/IconPlaceholder';
import BadgePill from 'components/BadgePill';
import { Item, Bullet } from '../VendorSchedule.styled';
import t from 'components/i18n';
import { statusMapper } from 'utils/mapper';

export default function NextSlaughterItem({
  date,
  icon,
  iconColor,
  status,
  statusColor,
  title,
  subTitle,
  keyValueTable,
  statusText,
  statusIcon,
  danger = false,
  additionalInfo = [],
  isFirstItem,
  isLastItem,
}) {
  return (
    <Item margin className="animated fadeIn">
      <div className="flex">
        <IconPlaceholder bgColor="colorClear" className="white">
          {isFirstItem ? (
            <span className={`icon ${icon} ${iconColor}`} />
          ) : (
            <Bullet />
          )}
        </IconPlaceholder>
        <div style={{ width: '100%' }}>
          <DateAndTime>
            {moment(date).format('DD ̸̸  MM ̸̸  YYYY : HH:mm')}
          </DateAndTime>
          <div className={isLastItem ? 'history' : ''}>
            <p>
              <strong>{title}</strong>
            </p>
            <p className="normal">{subTitle}</p>
          </div>
        </div>
        <BadgePill bgColor={statusColor}>{t(statusMapper(status))}</BadgePill>
      </div>
      <div className="additional">
        {additionalInfo.length > 0 &&
          additionalInfo.map(row => (
            <div className="flex">
              {row.map(rowColumn => (
                <p className="small">{rowColumn}</p>
              ))}
            </div>
          ))}
      </div>

      {keyValueTable && (
        <div className="additional">
          <KeyValueTable
            values={keyValueTable}
            status={{ text: statusText, icon: statusIcon }}
            danger={danger}
          />
        </div>
      )}
    </Item>
  );
}
