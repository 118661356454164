import React from 'react';
import Card from 'components/Card';
import DateAndTime from 'components/DateAndTime';

export default function OrientationItem({
  date,
  description,
  icon,
  ...props
}) {
  return (
    <Card {...props}>
      <DateAndTime>{date}</DateAndTime>
      {icon && <span className="icon icon-clip" />}
      <p className="description">{description}</p>
    </Card>
  );
}
