import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import * as routes from 'config/routes';
import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';
import t from 'components/i18n';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import NextFlockLabelMenu from './NextFlockLabelMenu';
import GeneralInformation from './GeneralInformation';
import Vaccines from './Vaccines';
import Origin from './Origin';
import { getFlockLabel } from '../service';
import {
  StyledNextFlockList,
  Summary,
  Container,
} from '../StyledNextFlock.styled';

export default function NextFlockLabelInfo(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [labelInfo, setLabelInfo] = useState(null);
  const [selectedKey, setSelectedKey] = useState(1);

  const getMenuOptions = useCallback(type => {
    const options = [
      {
        key: 1,
        label: t('next.flock.labelInfo.general-info.menu-options.generalInfo'),
        icon: 'icon-clipboard',
        component: GeneralInformation,
      },
      {
        key: 2,
        label: t('next.flock.labelInfo.general-info.menu-options.origin'),
        icon: 'icon-dolly',
        component: Origin,
      },
      {
        key: 3,
        label: t('next.flock.labelInfo.general-info.menu-options.vaccines'),
        icon: 'icon-syringue',
        component: Vaccines,
      },
    ];

    return options.filter(item => item.key === type)[0];
  }, []);

  const CurrentComponent = getMenuOptions(selectedKey).component;

  const hasContent = useMemo(() => {
    return (
      labelInfo &&
      labelInfo.placementInformation &&
      labelInfo.placementInformation.length > 0
    );
  }, [labelInfo]);

  useEffect(() => {
    async function getFlockLabelInformation() {
      setIsLoading(true);
      
      const response = await getFlockLabel(props.match.params.id);

      setLabelInfo(response);
      setIsLoading(false);
    }

    getFlockLabelInformation();
  }, [props.match.params.id]);

  return (
    <>
      {labelInfo && labelInfo.breadcrumbInformation && (
        <Breadcrumb
          history={props.history}
          backTo={() => props.history.push(routes.VENDORS)}
          path={[
            t("next.flock.labelInfo.general-info.bc.growers"),
            labelInfo.breadcrumbInformation.vendorName,
            t('next.flock.labelInfo.general-info.bc.flock', { value: labelInfo.breadcrumbInformation.flockNumber }),
            t('next.flock.labelInfo.general-info.bc.flag-data'),
          ]}
          links={[
            routes.VENDORS,
            `${routes.NEXT_FLOCK_LIST}/${labelInfo.breadcrumbInformation.vendorId}/new`,
            `${routes.NEXT_FLOCK_DETAILS}/${labelInfo.breadcrumbInformation.flockId}/new`,
          ]}
        />
      )}

      <Top>
        <h2>{t('next.flock.labelInfo.general-info.flag-data.title')}</h2>
      </Top>

      <StyledNextFlockList>
        {hasContent && (
          <NextFlockLabelMenu
            selectedKey={selectedKey}
            setSelectedKey={setSelectedKey}
            getMenuOptions={e => getMenuOptions(e)}
            {...props}
          />
        )}

        <Container
          style={{ width: '100%', maxWidth: 652, margin: 0 }}
        >
          <LoadingHolder isLoading={isLoading} />

          {!isLoading && !hasContent && (
            <NoContentTab icon="icon-tag">
              <p>
                {t('global.no-content')}
              </p>
            </NoContentTab>
          )}

          {!isLoading && hasContent && (
            <>
              <span className="container-detail" />

              <div className="container-title">
                <h4>{getMenuOptions(selectedKey).label}</h4>
              </div>

              <Summary>
                <CurrentComponent {...props} data={labelInfo} />
              </Summary>
            </>
          )}
        </Container>
      </StyledNextFlockList>
    </>
  );
}
