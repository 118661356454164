import styled from 'styled-components';

import { DEFAULT_SIZE } from 'components/GlobalStyles/variables';

const Spacer = styled.div`
  height: ${props =>
    props.multiplier
      ? `${DEFAULT_SIZE * props.multiplier}px`
      : `${DEFAULT_SIZE}px`};
`;

export default Spacer;
