import t from 'components/i18n';
import React from 'react';
import { InvoiceStatus } from './FlockStatusInvoice.styled';

function FlockStatusInvoice(props) {
  const {
    status = 'Pendente'
  } = props;

  const icon = {
    ['Pendente']: 'icon-exclamation-circle',
    ['Processando']: 'icon-clock'
  }[status];
  
  const text = {
    ['Pendente']: t("flock.badge.pendingInvoice"),
    ['Processando']: t("flock.badge.invoiceSent"),
  }[status];

  const classNames = `icon ${icon}`;

  if (status === "Enviado") {
    return null;
  }

  return (
    <InvoiceStatus status={status}>
      <span className={classNames} />
      {text}
    </InvoiceStatus>
  )
};

export default FlockStatusInvoice;
