// @flow

import React from 'react';
import { connect } from 'react-redux';
import Transition from 'components/Transition';
import Alert from 'components/Alert';
import {
  BottomLeft,
  BottomRight,
  SystemAlertsWrapper,
  TopLeft,
  TopRight,
} from './SystemAlerts.styled';

import { hideAlert } from './actions';

import type { TSystemAlertsProps } from './typings';

class SystemAlerts extends React.PureComponent<TSystemAlertsProps> {
  timeouts = null;

  constructor(props) {
    super(props);
    this.timeouts = {};
  }

  componentWillReceiveProps(nextProps) {
    const {
      app: { alerts: nextAlerts },
      dispatch,
    } = nextProps;
    if (nextAlerts.length) {
      nextAlerts.forEach(d => {
        if (d.timeout && !this.timeouts[d.id]) {
          this.timeouts[d.id] = setTimeout(() => {
            dispatch(hideAlert(d.id));
          }, d.timeout * 1000);
        }
      });
    }
  }

  clearTimeouts() {
    const hasTimeouts =
      this.timeouts && Object.keys(this.timeouts).length;

    if (hasTimeouts) {
      Object.keys(this.timeouts).forEach(d => {
        clearTimeout(this.timeouts[d]);
      });
    }
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  handleClick = e => {
    e.preventDefault();
    const { dataset } = e.currentTarget;
    const { dispatch } = this.props;

    dispatch(hideAlert(dataset.id));
  };

  renderAlerts(position) {
    const { app } = this.props;
    const items = app.alerts.filter(d => d.position === position);

    if (!items.length) {
      return null;
    }

    return items.map(d => (
      <Alert
        key={d.id}
        id={d.id}
        icon={d.icon}
        handleClickClose={this.handleClick}
        variant={d.variant}
      >
        {d.message}
      </Alert>
    ));
  }

  render() {
    return (
      <SystemAlertsWrapper key="SystemAlerts">
        <TopLeft>
          <Transition transition="slideDown">
            {this.renderAlerts('top-left')}
          </Transition>
        </TopLeft>
        <TopRight>
          <Transition transition="slideDown">
            {this.renderAlerts('top-right')}
          </Transition>
        </TopRight>
        <BottomLeft>
          <Transition transition="slideUp">
            {this.renderAlerts('bottom-left')}
          </Transition>
        </BottomLeft>
        <BottomRight>
          <Transition transition="slideUp">
            {this.renderAlerts('bottom-right')}
          </Transition>
        </BottomRight>
      </SystemAlertsWrapper>
    );
  }
}

function mapStateToProps(state) {
  return { app: state.app };
}

export default connect(mapStateToProps)(SystemAlerts);
