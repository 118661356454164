import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { USER_MANAGEMENT } from 'config/routes';
import { Button } from '@zendeskgarden/react-buttons';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import Top from 'components/Top';
import {
  scrollToTop,
  getPermissionsById,
} from 'shared/utils/Helpers';
import Breadcrumb from 'components/Breadcrumb';
import Toaster from 'components/Toaster';
import EditProfile from './Profile/EditProfile';
import AddUser from './User/AddUser';
import EditUser from './User/EditUser';
import ProfileList from './Profile/ProfileList';
import ProfileDetails from './Profile/ProfileDetails';
import UserList from './User/UserList';
import UserDetails from './User/UserDetails';
import { UserStyled } from './UserManagement.styled';
import {
  getFunctionalities,
  getProfiles,
  deleteUser,
} from './service';
import t from 'components/i18n';
import { profileKeyMapper } from 'utils';

export default function UserManagement({
  history,
  match: { params },
  responsive,
  user,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [functionalitiesList, setFunctionalitiesList] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [displayToaster, setDisplayToaster] = useState(false);
  const [selectedKey, setSelectedKey] = useState('user-list');
  const [profileList, setProfileList] = useState([]);

  const getFunctionalitiesList = useCallback(async () => {
    try {
      const response = await getFunctionalities();

      setFunctionalitiesList(mapFunctionalities(response.data));
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getProfileList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getProfiles();
      setProfileList(response);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }, []);

  const deleteUserCallback = useCallback(async id => {
    try {
      const response = await deleteUser(id);

      if (response) {
        setDisplayToaster(response.success ? 'success' : 'error');
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const userPermission = getPermissionsById(user.allowRoutes, '20');
  const profilePermission = getPermissionsById(
    user.allowRoutes,
    '21',
  );

  const tabs = useMemo(
    () => [
      {
        label: t('next.userManagement.tabs.users'),
        key: 'user-list',
        visible: userPermission.read,
      },
      {
        label: t('next.userManagement.tabs.profile'),
        key: 'profile-list',
        visible: profilePermission.read,
      },
    ],
    [profilePermission.read, userPermission.read],
  );

  const getTitle = useMemo(
    () => ({
      'user-list': t('next.userManagement.tabs.user-list'),
      'profile-list': t('next.userManagement.tabs.profile-list'),
      'edit-profile': profileKeyMapper(selectedProfile.name) || t('next.userManagement.tabs.edit-profile'),
      'edit-user': selectedUser.userName || t('next.userManagement.tabs.edit-user'),
      'profile-details': profileKeyMapper(selectedProfile.name) || t('next.userManagement.tabs.profile-details'),
      'user-details': selectedUser.userName || t('next.userManagement.tabs.user-details'),
      'add-user': t('next.userManagement.tabs.add-user'),
    }),
    [selectedProfile, selectedUser],
  );

  const mapFunctionalities = useCallback((values) => {
    const getLabelValue = item => {
      return `form.next.profileForm.field.allowRoutes.options.${item.trim().replaceAll(' ', '-').toLowerCase()}`;
    }

    return values.map(func => ({
      ...func,
      label: getLabelValue(func.name)
    }));
  }, []);

  useEffect(() => {
    getProfileList();

    getFunctionalitiesList();
  }, [getFunctionalitiesList, getProfileList]);

  useEffect(() => {
    if (selectedKey) {
      scrollToTop(document.getElementById('main'));
    }
  }, [selectedKey]);

  useEffect(() => {
    if (displayToaster) {
      setTimeout(() => setDisplayToaster(false), 3000);
    }
  }, [displayToaster]);

  return (
    <>
      {selectedKey.indexOf('list') === -1 && (
        <Breadcrumb
          history={history}
          backTo={() =>
            setSelectedKey(
              selectedKey.indexOf('profile') > -1
                ? 'profile-list'
                : 'user-list',
            )
          }
          links={[`${USER_MANAGEMENT}/user-list`]}
          path={[
            t('next.userManagement.bc.user-admin'),
            getTitle[selectedKey]
          ]}
        />
      )}

      <Top>
        <h2>{getTitle[selectedKey]}</h2>

        {selectedKey.indexOf('list') > -1 && (
          <div>
            {!!userPermission.create && (
              <Button
                className="tertiary"
                onClick={() => setSelectedKey('add-user')}
              >
                {t('next.userManagement.button.add-user')}
              </Button>
            )}

            {!!profilePermission.create && (
              <Button
                style={{ marginLeft: '16px' }}
                className="white"
                onClick={() => setSelectedKey('edit-profile')}
              >
                {t('next.userManagement.button.create-profile')}
              </Button>
            )}
          </div>
        )}
      </Top>

      {selectedKey.indexOf('list') > -1 && (
        <Header>
          {tabs.map(
            item =>
              item.visible && (
                <TabHeaderHolder
                  key={item.key}
                  onClick={() => setSelectedKey(item.key)}
                  className={selectedKey === item.key ? 'active' : ''}
                >
                  <div className="label">{item.label}</div>
                  <div className="bar" />
                </TabHeaderHolder>
              ),
          )}
        </Header>
      )}

      <UserStyled>
        {selectedKey === 'user-list' && (
          <UserList
            history={history}
            selectedKey={selectedKey}
            setSelectedKey={setSelectedKey}
            profileList={profileList}
            responsive={responsive}
            setSelectedUser={setSelectedUser}
            deleteUser={deleteUserCallback}
            updateItens={displayToaster === 'success'}
            userPermission={userPermission}
          />
        )}

        {selectedKey === 'add-user' && (
          <AddUser
            history={history}
            selectedKey={selectedKey}
            setSelectedKey={setSelectedKey}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            profileList={profileList}
            userPermission={userPermission}
          />
        )}

        {selectedKey === 'edit-user' && (
          <EditUser
            history={history}
            selectedKey={selectedKey}
            setSelectedKey={setSelectedKey}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            profileList={profileList}
            userPermission={userPermission}
          />
        )}

        {selectedKey === 'user-details' && (
          <UserDetails
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setSelectedKey={setSelectedKey}
            selectedKey={selectedKey}
            deleteUser={deleteUserCallback}
            userPermission={userPermission}
          />
        )}

        {selectedKey === 'profile-list' && (
          <ProfileList
            isLoading={isLoading}
            history={history}
            selectedKey={selectedKey}
            setSelectedKey={setSelectedKey}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
            profileList={profileList}
            setProfileList={setProfileList}
            updateItens={displayToaster === 'success'}
            profilePermission={profilePermission}
          />
        )}

        {selectedKey === 'edit-profile' && (
          <EditProfile
            selectedKey={selectedKey}
            setSelectedKey={setSelectedKey}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
            functionalitiesList={functionalitiesList}
            getProfileList={getProfileList}
            profilePermission={profilePermission}
          />
        )}

        {selectedKey === 'profile-details' && (
          <ProfileDetails
            selectedKey={selectedKey}
            setSelectedKey={setSelectedKey}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
            functionalitiesList={functionalitiesList}
            profilePermission={profilePermission}
          />
        )}

        <Toaster
          className="toaster__notification"
          isVisible={!!displayToaster}
          color={displayToaster}
          duration={3}
          delay={0}
        >
          <span className="icon icon-check" />{' '}
          {displayToaster === 'success'
            ? t('next.userManagement.delete-user.success')
            : t('next.userManagement.delete-user.error')}
        </Toaster>
      </UserStyled>
    </>
  );
}
