import React from 'react';
import { PROFILE } from 'config/routes';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import { InputField } from 'components/Forms/Fields';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';
import t from 'components/i18n';

export default function AccountAuthenticateForm({
  history,
  setFieldValue,
  handleSubmit,
  handleChange,
  handleBlur,
  isValid,
  values,
  errors,
  touched,
}) {
  return (
    <Form autoComplete="off">
      <Row>
        <Col md={12}>
          <InputField
            masked
            type="CPF/CNPJ"
            field="documentNumber"
            label={t('accountAuthenticateForm.form.documentNumber.label')}
            placeholder={t('accountAuthenticateForm.form.documentNumber.placeholder')}
            onBlur={handleBlur}
            onChange={e => {
              handleChange(e);
            }}
            setState={e => {
              setFieldValue('documentNumber', e);
            }}
            values={values}
            errors={errors}
            touched={touched}
          />

          <InputField
            masked
            type="phone"
            field="mobileNumber"
            label={t('accountAuthenticateForm.form.mobileNumber.label')}
            placeholder={t('accountAuthenticateForm.form.mobileNumber.placeholder')}
            onBlur={handleBlur}
            onChange={e => {
              handleChange(e);
            }}
            setState={e => {
              setFieldValue('mobileNumber', e);
            }}
            values={values}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>
      <Separator />
      {/* \ Botões */}
      <BtnGroup>
        <Button
          key="sendtoConfirm"
          onClick={() => handleSubmit()}
          disabled={!isValid}
        >
          {t('global.button.next')}
        </Button>
        <Button
          key="clean"
          onClick={() =>
            history.push(`${PROFILE}/account-settings/account-data`)
          }
          className="white"
        >
          {t('global.button.cancel')}
        </Button>
      </BtnGroup>
      {/* \ Buttons */}
    </Form>
  );
}
