import React from 'react';
import { ProgressBar } from './ProgressBar.styled';

export default function Options(props) {
  const { value } = props;
  const theme = props.theme || {};

  const bgColor = theme.bgColor || '255,255,255';
  const opacity = theme.opacity || '0.5';
  const barColor = theme.barColor || '#fff';

  return (
    <>
      <div
        style={{
          background: bgColor,
          padding: props.padding || 20,
        }}
      >
        <ProgressBar value={value} color={bgColor} opacity={opacity}>
          <span style={{ backgroundColor: barColor }} />
        </ProgressBar>
      </div>
    </>
  );
}
