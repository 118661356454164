// @flow

import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { Formik } from 'formik';
import t from 'components/i18n';
import { PROFILE } from 'config/routes';
import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';
import Modal from 'components/Forms/FormModal/Modal';
import InsuranceForm from 'containers/AgroBRF/Forms/InsuranceForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import {
  uploadInsurance,
  deleteInsurance,
  deleteFile,
  saveInsurancePolicy,
} from './service';
import { Container } from './InsurancePolicy.styled';

const validateSchema = props =>
  yup.object().shape({
    startDate: yup
      .date()
      .max(
        yup.ref('endDate'),
        t('global.validation.initial-date-before-final-date'),
      )
      .typeError(t('global.validation.select-initial-date'))
      .required(t('global.status.required-field')),
    endDate: yup
      .date()
      .min(
        yup.ref('startDate'),
        t('global.validation.initial-date-after-final-date'),
      )
      .typeError(t('global.validation.invalid-end-date'))
      .required(t('global.status.required-field')),
    files: yup
      .boolean()
      .oneOf([true], t('global.validation.required-policy-file')),
  });

export default function EditInsurance(props) {
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const {
    isLoading,
    history,
    setEditInsurance,
    selected,
    isValidating,
    files,
    setFiles,
  } = props;

  const isEditing = selected
    ? Object.keys(selected).length > 0
    : false;

  const successMessage = isEditing
    ? t('global.validation.record-changes-saved')
    : t('global.validation.record-created');

  const modalErrorMessage = t('global.validation.record-error');

  const initialValues = {
    id: 0,
    startDate: '',
    endDate: '',
    files: [],
  };

  const selectedValues = {};

  if (isEditing) {
    selectedValues.id = selected.id;
    selectedValues.startDate = moment(selected.startDate).format(
      'YYYY-MM-DD',
    );
    selectedValues.endDate = moment(selected.endDate).format(
      'YYYY-MM-DD',
    );
    selectedValues.files = selected.files.map(e => e.fileName);
  }

  const handleFile = useCallback(
    async file => {
      setIsUploading(true);
      try {
        const response = await uploadInsurance(file);
        setFiles([...files, response]);
      } catch (error) {
        console.error(error);
      }
      setIsUploading(false);
    },
    [files, setFiles],
  );

  const removeFile = useCallback(
    async file => {
      setIsUploading(true);
      try {
        await deleteFile(file);
        setFiles(
          files.filter(e => {
            const item = typeof e === 'object' ? e.id : e;
            return item !== file;
          }),
        );
      } catch (error) {
        console.error(error);
      }
      setIsUploading(false);
    },
    [files, setFiles],
  );

  const formData = values => ({
    id: isEditing ? selected.id : null,
    startDate: moment(values.startDate).format(),
    endDate: moment(values.endDate).format(),
    files: files.map(e => {
      let fileInput = {};
      if (typeof e === 'object') {
        fileInput = e;
      } else {
        fileInput.id = e;
      }
      return fileInput;
    }),
  });

  function submitForm(values, { setSubmitting }) {
    formData(values);

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await saveInsurancePolicy(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error);
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }
    sendData(formData(values));
  }

  const getTitleLabel = useCallback(
    args => (args ? t('insurancePolicy.edit-policy.title') : t('insurancePolicy.add-policy.title')),
    [],
  );

  useEffect(() => {
    if (isEditing && selected) {
      setFiles(selected.files);
    }
  }, [isEditing, selected, setFiles]);

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() => history.push(`${PROFILE}/documentation`)}
        links={[`${PROFILE}/documentation`]}
        path={[t('insurancePolicy.title'), getTitleLabel(isEditing)]}
      />

      <Top>
        <h2>{getTitleLabel(isEditing)}</h2>
      </Top>

      <Container style={{ padding: '16px' }}>
        <Formik
          initialValues={isEditing ? selectedValues : initialValues}
          validate={validate(validateSchema)}
          onSubmit={submitForm}
          render={formikProps => (
            <InsuranceForm
              {...formikProps}
              isValidating={isValidating}
              message={errorMessage}
              isLoading={isLoading}
              selected={selected}
              success={success}
              isEditing={isEditing}
              handleFile={e => handleFile(e)}
              removeFile={e => removeFile(e)}
              deleteInsurance={e => deleteInsurance(e)}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              files={files}
              setFiles={setFiles}
              {...props}
            />
          )}
        />
      </Container>

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setFiles([]);
          setErrorMessage('');
          setEditInsurance(false);
          history.push(`${PROFILE}/documentation`);
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>{success ? successMessage : modalErrorMessage}</p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
