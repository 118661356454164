import { Dropdown, Trigger } from '@zendeskgarden/react-dropdowns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FlagBrazil from './Brazil.svg'
import FlagEnglish from './English.svg'
import { StyledButton, StyledChevronDown, StyledFlag, StyledItem, StyledMenu } from './LanguageSwitch.styled'
import FlagTurkey from './Turkey.svg'

const languageOptions = ['pt-BR', 'en-US', 'tr-TR'];
const flags = {
  'pt-BR': FlagBrazil,
  'en-US': FlagEnglish,
  'tr-TR': FlagTurkey,
};
const labels = {
  'pt-BR': 'Português',
  'en-US': 'English',
  'tr-TR': 'Türkçe',
};

export default function LanguageSwitch({ triggerColor }) {
  const { i18n } = useTranslation();

  const flag = React.useMemo(() => {
    return flags[i18n.language] || null;
  }, [i18n.language])

  return (
    <Dropdown onSelect={value => i18n.changeLanguage(value)}>
      <Trigger>
        <StyledButton data-testid="ButtonLanguageSelector">
          <StyledFlag flag={flag} />
          <StyledChevronDown />
        </StyledButton>
      </Trigger>
      <StyledMenu>
        {languageOptions.map((lang) => (
          <StyledItem key={lang} value={lang} $active={lang === i18n.language}>
            <img src={flags[lang]} />
            <span>{labels[lang]}</span>
          </StyledItem>
        ))}
      </StyledMenu>
    </Dropdown>
  );
}