import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import validate from 'components/Forms/Fields/validate';
import { LIBRARY } from 'config/routes';
import Top from 'components/Top';
import t from 'components/i18n';
import Modal from 'components/Forms/FormModal/Modal';
import Breadcrumb from 'components/Breadcrumb';
import LibraryForm from 'containers/AgroBRF/Forms/LibraryForm';
import { fileTypes, profileTypes, animalTypes } from './utils.js';
import { Container } from './Library.styled';
import { saveLibrary, uploadLibraryFile } from './service';
import { uniqBy } from 'lodash';
import { regionalMapper } from 'utils/mapper.js';

const validateSchema = () => {
  return yup.object().shape({
    name: yup
      .string()
      .max(80, t('global.validation.max-character-exceeded'))
      .required(t('global.status.required-field')),
    fileType: yup
      .object()
      .nullable()
      .required(t('global.status.required-field')),
    fileName: yup
      .string()
      .nullable()
      .test('extension', t('global.validation.invalid-file'), val => {
        return val !== 'errorErrorError';
      })
      .required(t('global.status.required-field')),
    category: yup
      .object()
      .nullable()
      .required(t('global.status.required-field')),
    profileType:yup
      .object()
      .required(t('global.status.required-field')),
    subcategory: yup
      .object()
      .nullable()
      .required(t('global.status.required-field')),
    tags: yup
      .array()
      .of(yup.string())
      .required(t('global.status.required-field')),
  });
};

export default function EditLibrary({
  setEditLibrary,
  setShowDetails,
  isValidating,
  selectedItem,
  history,
  userId,
  categories,
  tags,
  regionals,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [files, setFiles] = useState([]);

  const initialValues = {
    id: 0,
    name: '',
    fileType: '',
    fileName: '',
    category: '',
    subcategory: '',
    animalType: '',
    profileType: 'P',
    tags: [],
    regionals: [],
    centerList: [],
  };

  let selectedValues = {};

  const mapAnimalCode = (animalType = '') => {
    return {
      ['todos']: "all",
      ['broiler']: 'Frango',
      ['turkey']: 'Peru',
      ['swine']: 'Suíno',
    }[animalType.toLowerCase()] || animalType;
  };

  if (selectedItem) {
    const category = categories.find(
      e => e.id === selectedItem.libraryCategoryId,
    );

    const subcategory = category.librarySubCategories.find(
      e => e.id === selectedItem.librarySubCategoryId,
    );

    const animalType = animalTypes.find(
      e => mapAnimalCode(e.value) === mapAnimalCode(selectedItem.animalType),
    );

    const profileType = profileTypes.find(
      e => e.value === selectedItem.profileType,
    );

    const fileType = fileTypes.find(
      e => e.value === selectedItem.fileType,
    );

    const tags = selectedItem.tags.map(item => item.name);
    
    const filteredRegionals = regionals
      .map(regional => ({
        ...regional,
        label: t(regionalMapper(regional.name)),
        centerList: regional.centerList.filter(center =>
          selectedItem.centersIds.includes(center.id),
        ),
      }))
      .filter(({ centerList }) => centerList.length);

    const countryCode = uniqBy(
      filteredRegionals.map((element, index) => ({
        countryCode: element.countryCode,
        id: index,
      })),
      'countryCode',
    );

    const centerList = filteredRegionals.map(locale => locale.centerList).flat();

    selectedValues = {
      id: selectedItem.id,
      name: selectedItem.name,
      fileType,
      profileType,
      fileName: selectedItem.blobName,
      category,
      subcategory: {
        ...subcategory,
        label: t(subcategory.name)
      },
      animalType,
      tags,
      countryCode,
      regionals: filteredRegionals,
      centerList
    };
  }

  async function handleFile(file) {
    setIsUploading(true);
    try {
      const response = await uploadLibraryFile(file);
      setFiles([response]);
    } catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  }

  async function removeFile(file) {
    setIsUploading(true);
    try {
      setFiles(
        files.filter(e => {
          const item = typeof e === 'object' ? e.id : e;
          return item !== file;
        }),
      );
    } catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  }

  function getTagsToSubmit(arr) {
    const filterTags = item => tags.filter(tag => tag.name === item);

    return arr.map(tag =>
      filterTags(tag).length
        ? filterTags(tag)[0]
        : { name: tag, id: 0 },
    );
  }

  const formData = values => ({
    Id: values.id || 0,
    Name: values.name,
    BlobName: files.length > 0 && files[0],
    LibrarySubCategoryId: values.subcategory && values.subcategory.id,
    AnimalType:
      (values.animalType && values.animalType.value) || 'all',
    ProfileType:
      (values.profileType && values.profileType.value) || 'T',
    FileType: values.fileType && values.fileType.value,
    UserId: userId,
    Tags: getTagsToSubmit(values.tags),
    CentersIds:
      Boolean(values.centerList.find(cl => cl.id === "all"))
        ? values.centerList[0].centerList.map(center => center.id)
        : values.centerList.map(center => center.id),
  });

  function submitForm(values, { setSubmitting }) {
    formData(values);

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await saveLibrary(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error);
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }
    sendData(formData(values));
  }

  function getTitleLabel(args) {
    return args ? t('library.form.section.edit-content') : t('library.form.section.add-content');
  }

  const modalSuccessMessage = t('library.form.section.modalSuccessMessage');
  const modalErrorMessage = t('library.form.section.modalErrorMessage');

  useEffect(() => {
    if (selectedItem) {
      setFiles([selectedItem.blobName]);
    }
  }, [selectedItem]);

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() => history.push(`${LIBRARY}/list`)}
        links={[`${LIBRARY}/list`]}
        path={[t('menu.library.title'), getTitleLabel(selectedItem)]}
      />

      <Top>
        <h2>{getTitleLabel(selectedItem)}</h2>
      </Top>

      <br />

      <Container style={{ padding: '16px' }}>
        <Formik
          initialValues={
            selectedItem ? selectedValues : initialValues
          }
          validate={validate(validateSchema)}
          onSubmit={submitForm}
          render={formikProps => (
            <LibraryForm
              {...formikProps}
              isValidating={isValidating}
              message={errorMessage}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              success={success}
              handleFile={e => handleFile(e)}
              removeFile={e => removeFile(e)}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              files={files}
              setFiles={setFiles}
              setShowDetails={setShowDetails}
              setEditLibrary={setEditLibrary}
              setShow
              selectedItem={selectedItem}
              categories={categories}
              tags={tags.length > 0 && tags.map(item => item.name)}
              fileTypes={fileTypes}
              profileTypes={profileTypes}
              animalTypes={animalTypes}
              regionals={regionals}
            />
          )}
        />
      </Container>

      {/* On submit modal */}
      <Modal
        noButtons={!success}
        opened={success || errorMessage}
        warning={errorMessage}
        deny={() => history.push(`${LIBRARY}/list`)}
        confirm={() => history.push(`${LIBRARY}/new`)}
        onCloseModal={() => {
          setSuccess(false);
          setFiles([]);
          setErrorMessage('');
          setEditLibrary(false);
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>{success ? modalSuccessMessage : modalErrorMessage}</p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
