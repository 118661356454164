import React, { useRef } from 'react';
import Login from 'containers/OnePage/Login';
import Contact from 'containers/OnePage/Contact';
import Member from 'containers/OnePage/Member';
import About from 'containers/OnePage/About';
import Footer from 'containers/OnePage/Footer';

export default function HomePage(props) {
  const memberRef = useRef(null);
  const contactRef = useRef(null);
  return (
    <>
      <Login {...props} refsGroup={[memberRef, contactRef]} />
      <About />
      <Member ref={memberRef} history={props.history} />
      <Contact ref={contactRef} />
      <Footer />
    </>
  );
}
