import mask, { cleanUp } from './mask';

const PATTERN = '###.###.###-##';

export default function doMask(input) {
  if (input === '') {
    return '';
  }

  let value = cleanUp(input);

  value = String(value);

  return mask(PATTERN, value);
}
