import React, { Fragment } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { isFunction } from 'shared/utils/Helpers';
import {
  Modal,
  Header,
  Body,
  Footer,
  Close,
} from '@zendeskgarden/react-modals';

function SystemModal(props) {
  const {
    modal: { options },
  } = props;

  if (!props.modal.opened) {
    return <Fragment />;
  }

  if (!options.renderBody) {
    throw new Error('Modal has no body defined');
  }

  const shouldRenderFooter = isFunction(options.renderFooter);

  return (
    <Fragment>
      {props.modal.opened && (
        <Modal {...options}>
          <Header>{options.modalTitle}</Header>
          <Body id="modalBody">{options.renderBody()}</Body>
          {shouldRenderFooter && (
            <Footer>{options.renderFooter()}</Footer>
          )}
          {options.showCloseButton && (
            <Close aria-label="Close modal" />
          )}
        </Modal>
      )}
    </Fragment>
  );
}

function mapStateToProps({ modal }) {
  return {
    modal,
  };
}

export default hot(connect(mapStateToProps)(SystemModal));
