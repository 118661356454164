import React, { useCallback, useEffect, useState } from 'react';

import Breadcrumb from 'components/Breadcrumb';
import LoadingHolder from 'components/LoadingHolder';
import { Formik } from 'formik';
import Top from 'components/Top';
import * as yup from 'yup';

import * as routes from 'config/routes';
import moment from 'moment';
import Toaster from 'components/Toaster';
import {
  getFlockAllInformation,
  getFlockInvoice,
} from 'containers/AgroBRF/FlockList/service';
import t from 'components/i18n';
import { FileInput, TextAreaField } from 'components/Forms/Fields';
import { Button } from '@zendeskgarden/react-buttons';
import Modal from 'components/Forms/FormModal/Modal';
import validate from 'components/Forms/Fields/validate';
import {
  Container,
  FormSection,
  Item,
  Separator,
  Title,
  Subtitle,
  BtnGroup,
  SectionWrapper,
  StatusBadgeContainer,
} from './InvoiceDetails.styled';
import { updateInvoice, uploadReceipt } from './service';


function validateSchema() {
  return yup.object().shape({
    description: yup
      .string()
      .max(500, t('global.validation.fill-input')),
  });
}

function StatusBadge(props) {
  const { invoice, response } = props;

  return (
    <StatusBadgeContainer error={response}>
      <span
        className={`icon ${
          response ? 'icon-exclamation-circle' : 'icon-check'
        }`}
      />
      {response
        ? t('alert.status.invoiceError')
        : t('alert.status.invoiceSent', {
            date: moment(invoice.updatedDate).format(
              'DD ̸̸  MM ̸̸  YYYY',
            ),
            hour: moment(invoice.updatedDate).format('HH:mm'),
          })}
    </StatusBadgeContainer>
  );
}

function InvoiceDetails(props) {
  const { history, user } = props;

  const flockID = props.match.params.id;

  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [description, setDescription] = useState('');
  const [backConfirmationModal, setBackConfirmationModal] = useState(
    false,
  );
  const [
    confirmationModalVisibility,
    setConfirmationModalVisibility,
  ] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        setLoading(true);
        const response = await getFlockAllInformation(flockID);

        if (response.success) {
          setData(response.data);
        }
      } catch (_) {
      } finally {
        setLoading(false);
      }
    }

    fetch();
  }, [flockID]);

  useEffect(() => {
    fetch();
  }, [data]);

  
  const fetch = useCallback(async () => {
    if (!data) {
      return;
    }

    const invoice = await getFlockInvoice(data.flock);

    setInvoice(invoice);

    if (invoice.status !== 'Pendente') {
      setFile(invoice.invoiceBlobName);
      setDescription(invoice.description);
    }
  }, [data]);

  const getWeight = useCallback(() => {
    if (!data) {
      return;
    }

    const accomplished = data['slaughter']['history']
      .flat()
      .filter(item => item.status === 'REALIZADO');

    const weight = accomplished
      .reduce((acum, curr) => (acum += +curr.infoLeft.value), 0)
      .toFixed(3);

    return weight;
  }, [data]);

  const getQuantitySlaughtered = useCallback(() => {
    if (!data) {
      return;
    }

    const accomplished = data['slaughter']['history']
      .flat()
      .filter(item => item.status === 'REALIZADO');

    const quantity = accomplished
      .reduce((acum, curr) => (acum += +curr.quantity.value), 0)
      .toFixed(3);

    return quantity;
  }, [data]);

  const submitForm = async () => {
    try {
      const receiptBlob = await uploadReceipt(file);

      const response = await updateInvoice(invoice.id, {
        description: description,
        invoiceBlobName: receiptBlob,
      });

      if (response.success) {
        fetch();
      }

      setResponse(response);
    } catch (e) {
      setResponse(e);
    } finally {
      setConfirmationModalVisibility(false);
    }
  };

  const confirmation = values => {
    setDescription(values['description']);
    setConfirmationModalVisibility(true);
  };

  const goBack = useCallback(
    values => {
      if (response || (invoice && invoice.status !== 'Pendente')) {
        return history.goBack();
      }

      if (values.description.length || file) {
        return setBackConfirmationModal(true);
      }

      history.goBack();
    },
    [response, invoice],
  );

  if (loading || !data) {
    return <LoadingHolder isLoading={true} />;
  }

  return (
    <div className="flock">
      <Breadcrumb
        history={history}
        path={[
          t('bc.flock.list'),
          t('bc.open-flock'),
          t('bc.flock.details', { value: data && data.flock }),
          t('bc.invoiceDetails.screen.title'),
        ]}
        links={[
          () => history.push(routes.FLOCK_LIST),
          () => history.push(routes.FLOCK_LIST),
          () => history.push(`${routes.FLOCK_LIST}/${flockID}`),
          '',
        ]}
        backTo={() => history.push(routes.NEWS_LIST)}
      />
      <Top slim>
        <header className="header">
          <h2>{t('invoiceDetails.screen.title')}</h2>
        </header>
        <Separator />
      </Top>
      {invoice && invoice.status !== 'Pendente' && (
        <StatusBadge invoice={invoice} />
      )}
      {response && !response.success && (
        <StatusBadge response={response} />
      )}
      <Container slim>
        <h4 className="header">{t('invoiceDetails.title')}</h4>

        <Separator />

        <Formik
          onSubmit={confirmation}
          initialValues={{ description }}
          validate={validate(validateSchema)}
          enableReinitialize
          render={({
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <>
              <FormSection>
                <Item className="flex">
                  <span className="small">
                    {t('invoiceDetails.label.name')}
                  </span>
                  <p className="right">{user.name}</p>
                </Item>

                <Item className="flex">
                  <span className="small">
                    {t('invoiceDetails.label.growerCode')}
                  </span>
                  <p className="right">{flockID}</p>
                </Item>

                <Item className="flex">
                  <span className="small">
                    {t('invoiceDetails.label.flock')}
                  </span>
                  <p className="right">{data.flock}</p>
                </Item>

                <Item className="flex">
                  <span className="small">
                    {t('invoiceDetails.label.quantityExecuted')}
                  </span>
                  <p className="right">{getQuantitySlaughtered()}</p>
                </Item>

                <Item className="flex">
                  <span className="small">
                    {t('invoiceDetails.label.totalWeight')}
                  </span>
                  <p className="right">{getWeight()}</p>
                </Item>

                <Separator style={{ margin: '0' }} />

                <SectionWrapper>
                  <Title required>
                    {t('invoiceDetails.label.addInvoice')}
                  </Title>

                  <FileInput
                    type="file"
                    className="file-input"
                    field="file"
                    onChange={e => setFile(e.target.files[0])}
                    showRemoveFile={
                      invoice && invoice.status === 'Pendente'
                    }
                    accept=".png, .jpeg, .pdf"
                    removeFile={() => setFile(null)}
                    isLoading={false}
                    itemsToList={file}
                    blobContainerName="invoice"
                    itemLabel="name"
                    showAddButton={!file}
                    onBlur={() => {}}
                    token={user.token}
                    values={[]}
                    errors={[]}
                    touched={touched}
                  />
                </SectionWrapper>

                <Separator style={{ margin: '0' }} />

                <SectionWrapper>
                  <Title>{t('invoiceDetails.label.typeNotes')}</Title>
                  <Subtitle>
                    {t('invoiceDetails.label.subtitleTypeNotes')}
                  </Subtitle>

                  <TextAreaField
                    field="description"
                    placeholder={t(
                      'invoiceDetails.placeholder.typeNotes',
                    )}
                    onChange={e =>
                      setFieldValue('description', e.target.value)
                    }
                    values={values}
                    errors={[]}
                    touched={false}
                  />
                </SectionWrapper>
              </FormSection>

              <BtnGroup>
                <Button
                  key="sendtoConfirm"
                  className="tertiary"
                  onClick={() => handleSubmit()}
                  disabled={
                    !file ||
                    (invoice && invoice.status !== 'Pendente')
                  }
                >
                  {t('global.button.next')}
                </Button>
                <Button
                  key="clean"
                  onClick={() => goBack(values)}
                  className="white"
                >
                  {t('global.button.back')}
                </Button>
              </BtnGroup>
            </>
          )}
        />

        {/* send confirmation modal */}
        {confirmationModalVisibility && (
          <Modal
            warning
            opened={confirmationModalVisibility}
            confirm={() => submitForm()}
            deny={() => setConfirmationModalVisibility(false)}
            onCloseModal={() => setConfirmationModalVisibility(false)}
            confirmLabel={t('button.global.send')}
            denyLabel={t('button.global.cancel')}
          >
            <span className="icon icon-exclamation-triangle" />
            <h4>{t('modal.sendInvoice.title')}</h4>
            <p>{t('modal.sendInvoice.subtitle')}</p>
          </Modal>
        )}

        {/* back confirmation modal */}
        {backConfirmationModal && !response && (
          <Modal
            warning
            opened={backConfirmationModal}
            confirm={() => history.goBack()}
            deny={() => setBackConfirmationModal(false)}
            onCloseModal={() => setBackConfirmationModal(false)}
            confirmLabel={t('global.button.yes')}
            denyLabel={t('button.global.cancel')}
          >
            <span className="icon icon-exclamation-triangle" />
            <h4>{t('global.word.attention')}</h4>
            <p>{t('alert.back.cancelProgress')}</p>
          </Modal>
        )}

        {/* response toast */}
        {response && (
          <Toaster
            className="toaster__notification"
            isVisible={true}
            color={response.success ? 'success' : 'danger'}
            duration={3}
            delay={0}
            title={
              response.success
                ? t('sendInvoice.sucess.title')
                : t('sendInvoice.error.title')
            }
          >
            <span className="icon icon-check" />
            {response.success
              ? t('sendInvoice.sucess.subtitle')
              : t('sendInvoice.error.subtitle')}
          </Toaster>
        )}

        
      
      </Container>

    </div>
  );
}

export default InvoiceDetails;
