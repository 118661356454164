// @flow

import React, { useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import {
  getCadecInfos,
  saveCadec,
} from 'containers/AgroBRF/Cadec/service';
import { CadecContext } from 'shared/contexts';
// validate
import validate from 'components/Forms/Fields/validate';
import { uploadFile } from 'shared/blob/service';
import AddCadecForm from './AddCadecForm';
import t from 'components/i18n';
// Contexts

const validateSchema = props => {
  return yup.object().shape({
    centerList: yup.object().required(t('global.status.required-field')),
    segmentList: yup.object().required(t('global.status.required-field')),
    segmentType: yup.array().required(t('global.status.required-field')),
    cadecName: yup.string().required(t('global.status.required-field')),

    // fileName: yup
    //   .string()
    //   .when('previousFile', (previousFile, schema) => {
    //     return !previousFile
    //       ? schema.required(t('global.status.required-field'))
    //       : schema;
    //   }),
  });
};

const initialValues = {
  id: '',
  centerList: [],
  segmentList: [],
  segmentType: [],
  centerCode: '',
  cadecName: '',
  fileName1: '',
  fileName2: '',
};

export default function AddCadec(props) {
  const cadecContext = useContext(CadecContext);
  const { selectedCadec } = cadecContext;
  const {
    setCadecSubmitResponse,
    removeFile1,
    removeFile2,
    setFile1,
    setFile2,
    setCadecSuccessSubmit,
  } = cadecContext.actions;
  const [data, setData] = useState({});
  const [isUploading1, setIsUploading1] = useState(false);
  const [isUploading2, setIsUploading2] = useState(false);
  const [formFile1, setFormFile1] = useState('');
  const [formFile2, setFormFile2] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message] = useState('');

  function getFileNameFromSelected(type) {
    return (
      selectedCadec.files.find(e => e.type === type) &&
      selectedCadec.files.find(e => e.type === type).fileName
    );
  }

  // set CADEC data
  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      try {
        const response = await getCadecInfos();

        setData(response);
      } catch (e) {
        console.error('Error on getCadecInfos', e);
      }

      setIsLoading(false);
    }
    fetch();
  }, []);

  async function handleFile1(file) {
    setIsUploading1(true);
    try {
      const response = await uploadFile(file);

      setFormFile1(response);
      setFile1(response);
    } catch (error) {
      console.error(error);
    }
    setIsUploading1(false);
  }

  async function handleFile2(file) {
    setIsUploading2(true);
    try {
      const response = await uploadFile(file);

      setFormFile2(response);
      setFile2(response);
    } catch (error) {
      console.error(error);
    }
    setIsUploading2(false);
  }

  if (props.isEditing && !props.isSubmitting) {
    useEffect(() => {
      setFormFile1(getFileNameFromSelected('REPRESENTANTES'));
      setFile1(getFileNameFromSelected('REPRESENTANTES'));
      setFormFile2(getFileNameFromSelected('REGULAMENTO'));
      setFile2(getFileNameFromSelected('REGULAMENTO'));
    }, [
      getFileNameFromSelected,
      selectedCadec.files,
      setFile1,
      setFile2,
    ]);

    useEffect(() => {
      if (selectedCadec.fileName1 === '') {
        setFormFile1('');
      }
    }, [selectedCadec.fileName1]);

    useEffect(() => {
      if (selectedCadec.fileName2 === '') {
        setFormFile2('');
      }
    }, [selectedCadec.fileName2]);

    initialValues.id = selectedCadec.id;
    initialValues.centerList = selectedCadec.center;
    initialValues.segmentList = { name: selectedCadec.typeName };
    initialValues.cadecName = selectedCadec.cadecName;
    initialValues.previousFile1 = true;
    initialValues.previousFile2 = true;
    initialValues.segmentType = selectedCadec.segmentList;

    if (Object.keys(data).length > 0) {
      initialValues.centerList.listType = data.centerList.filter(
        e => e.code === selectedCadec.center.code,
      )[0].listType;
      initialValues.segmentList.segmentType = initialValues.centerList.listType.filter(
        e => e.name === selectedCadec.typeName,
      )[0].segmentType;
    }
  } else {
    initialValues.id = '';
    initialValues.centerList = [];
    initialValues.segmentList = [];
    initialValues.segmentType = [];
    initialValues.centerCode = '';
    initialValues.cadecName = '';
    initialValues.fileName1 = '';
    initialValues.fileName2 = '';
  }

  function submitForm(values, { setSubmitting, setErrors }) {
    const formData = {
      id: values.id,
      cadecName: values.cadecName,
      centerCode: values.centerList.code,
      typeName: values.segmentList.name,
      files: [],
      segmentList: values.segmentType.map(item => parseInt(item.id)),
    };

    if (formFile1 && formFile1.length > 0) {
      formData.files.push({
        fileName: formFile1,
        type: 'REPRESENTANTES',
      });
    }

    if (formFile2 && formFile2.length > 0) {
      formData.files.push({
        fileName: formFile2,
        type: 'REGULAMENTO',
      });
    }

    async function saveData(val) {
      setSubmitting(true);
      try {
        const response = await saveCadec(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setCadecSubmitResponse(response.message);
        setCadecSuccessSubmit(true);
      } catch (error) {
        setCadecSubmitResponse(error.message);
        setCadecSuccessSubmit(false);
      }
      setSubmitting(false);
      props.goTo('cadecList');
    }

    saveData(formData);
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={validate(validateSchema)}
      onSubmit={submitForm}
      render={formikProps => (
        <AddCadecForm
          {...formikProps}
          message={message}
          unities={
            data &&
            data.centerList &&
            data.centerList
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map(center => ({
                ...center,
                name: `${center.code} - ${center.name}`,
              }))
          }
          selected={selectedCadec}
          handleFile1={e => handleFile1(e)}
          handleFile2={e => handleFile2(e)}
          removeFile1={removeFile1}
          removeFile2={removeFile2}
          goTo={props.goTo}
          isEditing={props.isEditing}
          isLoading={isLoading}
          isUploading1={isUploading1}
          isUploading2={isUploading2}
        />
      )}
    />
  );
}
