import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  sceneGap,
  colorPrimary,
  colorGrayLight,
  colorClear,
  colorGrayDarkest,
  colorGrayUltraLight,
  breakpointDesktop,
  breakpointTablet,
} from 'components/GlobalStyles/variables';

export const CopyrightWrapper = styled.section`
  background-color: ${colorClear};
  padding: ${sceneGap} ${DEFAULT_SIZE * 2}px;

  > div {
    max-width: 960px;
    margin: 0 auto;
  }

  .email-wrapper {
    justify-content: flex-start;

    @media (min-width: ${breakpointTablet}px) {
      justify-content: center;

      p {
        text-align: right;
      }
    }

    @media (min-width: ${breakpointDesktop}px) {
      justify-content: center;
    }
  }
`;

export const CopyrightBottomWrapper = styled.section`
  background-color: ${colorClear};
  padding: 10px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colorGrayUltraLight};

  > div {
    max-width: 960px;
    margin: 0 auto;
  }
`;

export const Dash = styled.div`
  width: 50px;
  background-color: ${colorPrimary};
  height: 3px;
  margin-bottom: 8px;
`;

export const FooterWrapper = styled.section`
  background: linear-gradient(
    135deg,
    rgba(210, 38, 46, 1) 29%,
    rgba(164, 47, 116, 1) 81%,
    rgba(147, 50, 142, 1) 100%
  );

  padding-top: 56px;
  padding-bottom: 80px;
  color: ${colorClear};

  p.description {
    margin-bottom: 40px;
    font-size: 14px;
    max-width: 530px;
  }

  h1.title {
    color: ${colorClear};
  }

  button {
    height: 60px;
  }

  .apple-store-button {
    background-color: ${colorClear} !important;
    color: ${colorGrayLight} !important;
  }

  .icon-store {
    position: relative;
    left: -60px;
    top: 6px;
    font-size: 24px;
    color: ;
  }

  .buttons-wrapper {
    justify-content: flex-start;

    @media (min-width: ${breakpointTablet}px) {
      justify-content: flex-end;
    }
  }
`;

export const EmailP = styled.p`
  color: ${colorGrayDarkest};
  font-weight: 500;
  font-size: 14px;
`;

export const CopyrightP = styled.p`
  color: ${colorGrayDarkest};
  font-size: 12px;
  margin-left: ${DEFAULT_SIZE}px;
`;
