import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { utils } from 'styled-minimal';
import {
  colorPrimary,
  colorDanger,
  colorWarning,
  colorSuccess,
} from 'components/GlobalStyles/variables';

export const IconWrapper = styled(SVG)`
  display: inline-block;
  line-height: 0;

  svg {
    height: auto;
    max-height: 100%;
    width: ${({ width }) => utils.px(width)};
  }
`;

export const Dot = styled.span`
  line-height: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${props => {
    switch (props.variant) {
      case 'primary':
        return colorPrimary;
      case 'info':
        return colorWarning;
      case 'danger':
        return colorDanger;
      default:
        return colorSuccess;
    }
  }};
`;
