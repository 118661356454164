import React from 'react';
import {
  StepsContainer,
  StepItem,
  Circle,
} from './HorizontalSteps.styled';

export default function HorizontalSteps({
  steps = [],
  activeStep = '',
  completedSteps = [],
  setCurrentStep,
  disableClick = false,
} = {}) {
  const stepsToDisplay = (steps || []).filter(step => !step.hidden);

  return (
    <StepsContainer className="steps-container">
      {stepsToDisplay.map((step, index) => {
        const isActive = activeStep.key === step.key;
        const isPast = completedSteps.includes(step.key);

        return (
          <StepItem
            onClick={() => !disableClick && setCurrentStep(step)}
            key={step.key}
            isActive={isActive}
            isPast={isPast}
            disableClick={disableClick}
          >
            <Circle isActive={isActive} isPast={isPast}>
              {!isPast && index + 1}
              {isPast && <span className="icon icon-check" />}
            </Circle>

            <p>{step.name}</p>
          </StepItem>
        );
      })}
    </StepsContainer>
  );
}
