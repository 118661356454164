import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as routes from 'config/routes';
import Breadcrumb from 'components/Breadcrumb';
import LoadingHolder from 'components/LoadingHolder';
import Icon from 'components/Icon';
import Top from 'components/Top';
import { getLogBookItemDetails } from 'containers/NextBRF/LogBook/service';
import OpenOrientationCard from './OpenOrientationCard';
import { OrientationStyled, ReadNote } from './Orientation.styled';
import t from 'components/i18n';

export default function NextOrientationScreen(props) {
  const { history, match, params: paramsProp } = props;

  const params = match ? match.params : paramsProp;

  const [isLoading, setIsLoading] = useState(false);
  const [orientation, setOrientation] = useState([]);
  const [flockNumber, setFlockNumber] = useState('');

  useEffect(() => {
    async function getItem(id) {
      const response = await getLogBookItemDetails(id);

      setIsLoading(true);
      try {
        setOrientation(response);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }

    getItem(params.id);
  }, [params]);

  useEffect(() => {
    function getFlockNumber(id) {
      const filtered =
        orientation &&
        orientation.flocks &&
        orientation.flocks.length > 0 &&
        orientation.flocks.filter(
          item => Number(item.flockId) === Number(id),
        );

      if (filtered && filtered.length > 0) {
        return filtered[0].flock;
      }

      return '';
    }

    if (params.flockId) {
      setFlockNumber(getFlockNumber(params.flockId));
    }
  }, [orientation, params]);

  return (
    <>
      <LoadingHolder isLoading={isLoading} />

      {orientation && params.flockId && (
        <Breadcrumb
          history={history}
          backTo={() => history.push(routes.LOGBOOK)}
          path={[
            t('next.orientation.bc.growers'),
            `${orientation.vendorName}`,
            t("next.orientation.bc.flock", { value: flockNumber }),
            t('next.orientation.bc.fal'),
            t("next.orientation.bc.orientation"),
          ]}
          links={[
            routes.VENDORS,
            `${routes.NEXT_FLOCK_LIST}/${orientation.vendorId}/new`,
            `${routes.NEXT_FLOCK_DETAILS}/${params.flockId}`,
            `${routes.NEXT_HOUSE_LIST}/${params.flockId}`,
          ]}
        />
      )}

      {!params.flockId && (
        <Breadcrumb
          history={history}
          backTo={() => history.push(routes.LOGBOOK)}
          path={[t("next.orientation.bc.logbook"), t("next.orientation.bc.visit"), t('next.orientation.bc.orientation')]}
          links={[
            `${routes.LOGBOOK}`,
            `${routes.OPEN_VISIT}/${orientation.id}`,
          ]}
        />
      )}

      <Top>
        <h2>{t('next.orientation.orientationScreen.title')}</h2>
      </Top>

      {orientation.read && (
        <ReadNote>
          <Icon name="icon-check" />
          {t('next.orientation.orientationScreen.read-on', { value: moment(orientation.readDate).format('DD ̸̸  MM ̸̸  YYYY • HH:mm') })}
        </ReadNote>
      )}

      {orientation && (
        <OrientationStyled>
          <div className="primary">
            <OpenOrientationCard
              history={history}
              data={orientation}
              back={() =>
                history.push(`${routes.OPEN_VISIT}/${orientation.id}`)
              }
            />
          </div>
        </OrientationStyled>
      )}
    </>
  );
}
