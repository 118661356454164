import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import SideboxFilter from 'components/SideboxFilter/SideboxFilter';
import SearchBox from 'components/SearchBox';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import { WhiteBoxInsideHeader } from 'components/HeaderBox';
import NextFeedItem from './NextFeedItem';
import t from 'components/i18n';
import { getFeedFilters } from '../ScheduleFilters';
import {
  getStatusColor,
  getFilteredContentByDate,
  getFiltersFromSideBox,
} from '../utils';
import { getFeedSchedule } from '../service';

export default function NextFeedList({
  vendors,
  flockId,
  getHeader,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [feedList, setFeedList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  const EmptySearch = () => {
    return (
      <NoContentTab icon="icon-truck" hideButton>
        <p>
          {t('global.no-content')}
        </p>
      </NoContentTab>
    );
  };

  const fetchData = useCallback(
    async (original, ...e) => {
      setIsLoading(true);
      try {
        const response = await getFeedSchedule(flockId, ...e);
        setFeedList(response || []);

        // get all available options to filter
        if (original) {
          setOriginalData(response || []);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    },
    [flockId],
  );

  const fetchBySideBoxFilters = useCallback(() => {
    const filterLabels = [
      t('next.vendorSchedule.filter.status'),
      t('next.vendorSchedule.filter.feed'),
      flockId
        ? ''
        : t('next.vendorSchedule.filter.flock'),
    ];

    fetchData(
      false,
      search.vendorCode,
      ...getFiltersFromSideBox(filterLabels, activeFilters),
    );
  }, [activeFilters, fetchData, flockId, search]);

  useEffect(() => {
    if (feedList.length > 0 && flockId) {
      getHeader(feedList[0]);
    }
  }, [feedList, flockId, getHeader]);

  useEffect(() => {
    if (vendors.length > 0) {
      setSearch(vendors[0]);
    }
    setActiveFilters([]);
  }, [vendors]);

  useEffect(() => {
    setActiveFilters([]);
    if (!vendors.length && !Object.keys(search).length) {
      fetchData(true, '');
    }

    if (Object.keys(search).length > 0 && !activeFilters.length) {
      fetchData(true, search.vendorCode);
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (activeFilters.length > 0) {
      fetchBySideBoxFilters();
    }

    setFeedList(originalData);
    // eslint-disable-next-line
  }, [activeFilters]);

  useEffect(() => {
    if (feedList && dateFilter) {
      const filteredByDate = getFilteredContentByDate(
        moment,
        feedList,
        dateFilter,
        'date',
      );

      setFilteredList(filteredByDate);
    }
  }, [feedList, dateFilter]);

  const content = dateFilter ? filteredList : feedList;

  return (
    <>
      <WhiteBoxInsideHeader>
        {!flockId && (
          <SearchBox
            options={vendors}
            label="label"
            action={setSearch}
            item={search}
            notFoundMessage={t('next.vendorSchedule.no-flock')}
          />
        )}

        {Boolean(originalData && Array.isArray(originalData) && originalData.length) && (
          <SideboxFilter
            dateFilter={flockId ? dateFilter : false}
            setDateFilter={flockId ? setDateFilter : undefined}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            filters={getFeedFilters(originalData, flockId)}
          />
        )}
      </WhiteBoxInsideHeader>

      <LoadingHolder isLoading={isLoading} />

      <div style={{ padding: '16px 0' }}>
        {!isLoading &&
          content.length > 0 &&
          content.map((item, index) => (
            <NextFeedItem
              key={index}
              icon="icon-truck"
              iconColor="primary"
              date={item.date}
              status={item.status}
              statusColor={getStatusColor(item.status)}
              firstItem={t('next.vendorSchedule.feed.quantity', { value: item.quantity })}
              secondItem={item.feedType}
              thirdItem={t('next.vendorSchedule.feed.flock', { value: item.flock })}
            />
          ))}

        {!isLoading && !content.length && <EmptySearch />}
      </div>
    </>
  );
}
