import {
  applyMiddleware,
  createStore,
  compose,
  combineReducers,
} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootSaga from 'shared/Sagas';
import rootReducer from 'shared/reducers';

import middleware, { sagaMiddleware } from './middleware';

const reducer = persistReducer(
  {
    key: 'agroBRF',
    storage,
    whitelist: ['app', 'user', 'checklist'],
  },
  combineReducers({ ...rootReducer }),
);

const composeEnhancer =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configStore = (initialState = {}) => {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(...middleware)),
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('shared/reducers', () => {
      const newReducer = require('shared/reducers').default;
      store.replaceReducer(newReducer);
    });
  }

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
