import styled from 'styled-components';
import {
  boxShadow,
  fontFamilyText,
  fontFamilyTextBold,
  colorGray,
  colorClear,
  colorGrayDarkest,
  breakpointTablet,
  DEFAULT_SIZE,
  breakpointDesktop,
} from 'components/GlobalStyles/variables';

export const InsuranceStyled = styled.div`
  position: relative;

  .main-wrapper {
    width: 100%;

    @media (min-width: ${breakpointDesktop}px) {
      max-width: 780px;
    }
  }

  .secondary-wrapper {
    min-width: 280px;
    margin: 0 0 20px;

    @media (min-width: ${breakpointDesktop}px) {
      min-width: 316px;
    }
  }
`;

export const Subheading = styled.strong`
  color: ${colorGrayDarkest};
  line-height: 24px;
  font-size: ${props => (props.small ? `14` : `16`)}px;
`;

export const Container = styled.div`
  position: relative;
  padding: 0 ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};
  max-width: 630px;

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px 20px 60px 20px;
  }

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }

  ${Subheading} {
    display: block;
    font-family: ${fontFamilyTextBold};
    font-weight: normal;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }
`;

export const Separator = styled.hr`
  margin: 10px 0 15px 0;
  opacity: 0.12;
`;
