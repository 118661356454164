import React from 'react';
import moment from 'moment';
import SideBox from 'components/SideBox';
import Icon from 'components/Icon';
import { CatchDetailsBox } from './SlaughterCollectDetails.styled';
import t from 'components/i18n';

const Row = ({ label, value, noBorder }) => (
  <div className={noBorder ? 'no-border' : ''}>
    <p className="flex">
      <span className="label">{label}</span>
      <span className="data">{value}</span>
    </p>
  </div>
);

export default function SlaughterCollectDetails({
  displayBox,
  setDisplayBox,
  data,
}) {
  const {
    isRead = '',
    loadNumber = '',
    raisedFeederDate = '',
    readDate = '',
    catchDate = '',
    squadNumber = '',
  } = data || {};

  return (
    <SideBox displayBox={displayBox} setDisplayBox={setDisplayBox}>
      <CatchDetailsBox className="side-box">
        <h1>{t('next.operationalSchedule.slaughterCollectDetails.title')}</h1>
        {isRead && readDate ? (
          <p className="alert green">
            <Icon name="icon icon-check-circle" />
            {t('next.operationalSchedule.slaughterCollectDetails.read-date', { value: moment
              .utc(readDate)
              .local()
              .format('DD ̸̸  MM ̸̸  YYYY • HH:mm') })}
          </p>
        ) : (
          <p className="alert red">
            <Icon name="icon icon-close-circle" />
            {t('next.operationalSchedule.slaughterCollectDetails.no-read')}
          </p>
        )}
        {!!catchDate && (
          <Row
            label={t('next.operationalSchedule.slaughterCollectDetails.catch-date')}
            value={moment(catchDate).format('DD ̸̸  MM ̸̸  YYYY')}
          />
        )}
        {!!catchDate && (
          <Row
            label={t('next.operationalSchedule.slaughterCollectDetails.catch-hour')}
            value={moment(catchDate).format('HH:mm')}
          />
        )}
        {!!squadNumber && (
          <Row
            label={t('next.operationalSchedule.slaughterCollectDetails.squad-number')}
            value={squadNumber}
          />
        )}
        {!!loadNumber && (
          <Row
            label={t('next.operationalSchedule.slaughterCollectDetails.load-number')}
            value={loadNumber}
          />
        )}
        {!!raisedFeederDate && (
          <Row
            label={t('next.operationalSchedule.slaughterCollectDetails.raised-feeder-date')}
            value={moment(raisedFeederDate).format('DD ̸̸  MM ̸̸  YYYY')}
          />
        )}
        {!!raisedFeederDate && (
          <Row
            label={t('next.operationalSchedule.slaughterCollectDetails.raised-feeder-hour')}
            value={moment(raisedFeederDate).format('HH:mm')}
            noBorder
          />
        )}
      </CatchDetailsBox>
    </SideBox>
  );
}
