import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { EDIT_PROFILE } from 'config/routes';
import { useSelector } from 'react-redux';
import { Button } from '@zendeskgarden/react-buttons';
import Container from 'components/Container';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import UploadAndResizeFile from 'components/UploadAndResizeFile';
import { uploadFile } from 'shared/blob/service';
import t from 'components/i18n';
import { getUserLgpd, updateUserLgpd } from '../../service';
import {
  Separator,
  FormSection,
  Item,
} from '../../ProfileAndSettings.styled';

export default function PersonalData({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [data, setData] = useState(null);
  const [image, setImage] = useState(null);

  const user = useSelector(state => state.user);
  const token = user && user.token;

  const handleFile = useCallback(async file => {
    setIsUploading(true);
    try {
      const response = await uploadFile(file);
      if (response && response.length) {
        setImage(response);
        updateUserLgpd({
          pictureBlobName: response,
          pictureBlobContainer: 'test',
          birthDate: null,
          phoneNumber: null,
          email: null,
          integratedDate: null,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  }, []);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await getUserLgpd();
        setData(response);

        if (response.blobName) {
          setImage(response.blobName);
        }
      } catch (e) {
        console.error('Fetch error', e);
      }
      setIsLoading(false);
    }

    fetchData();
  }, []);

  return (
    <Container className="container" slim>
      <h4>{t('menu.profile.personalData.title')}</h4>
      <Separator style={{ marginBottom: 0 }} />

      <LoadingHolder isLoading={isLoading} />

      {!isLoading && data && (
        <>
          <FormSection>
            <UploadAndResizeFile
              buttonLabel={t('menu.profile.accountData.personal-data.choose-picture')}
              image={image}
              source={`${API_URL}/Blob/Download?fileName=${image}&blobContainerName=test&hasWaterMark=false&token=${token}&client_id=${KONG_CLIENT_ID}`}
              title="perfil"
              isLoading={isUploading}
              handleFile={handleFile}
            />

            <Separator style={{ marginBottom: 0 }} />

            <br />

            <h6>{t('menu.profile.accountData.accessData.title')}</h6>

            <Item className="flex">
              <span className="small">
                {t(
                  'menu.profile.personalData.buttonSingleLine.label.fullName',
                )}
              </span>
              <p className="right">{data.name}</p>
            </Item>

            <Item className="flex">
              <span className="small">
                {t(
                  'menu.profile.personalData.buttonSingleLine.label.birthDate',
                )}
              </span>

              <p className="right">
                <Button
                  className="white whiteBorder"
                  style={{ width: 'auto' }}
                  onClick={() =>
                    history.push(`${EDIT_PROFILE}/birthdate`)
                  }
                >
                  {data.birthDate
                    ? moment(data.birthDate).format('DD ̸̸  MM ̸̸  YYYY')
                    : t('menu.profile.accountData.personal-data.birthDate.not-specified')}
                  <span className="icon icon-chevron-right" />
                </Button>
              </p>
            </Item>

            <Item className="flex">
              <span className="small">
                {t(
                  'menu.profile.personalData.buttonSingleLine.label.emailAddress',
                )}
              </span>

              <p className="right">
                <Button
                  className="white whiteBorder"
                  style={{ width: 'auto' }}
                  onClick={() =>
                    history.push(`${EDIT_PROFILE}/email`)
                  }
                >
                  {(data.email && data.email.toLowerCase()) ||
                    t('menu.profile.accountData.personal-data.email.not-specified')}
                  <span className="icon icon-chevron-right" />
                </Button>
              </p>
            </Item>
          </FormSection>

          <FormSection>
            <h6>
              {t(
                'menu.profile.personalData.sectionIntegration.title',
              )}
            </h6>
            <Item className="flex">
              <span className="small">
                {t(
                  'menu.profile.personalData.sectionIntegration.buttonSingleLine.label.growerCode',
                )}
              </span>

              <div className="right">
                {(data.vendors || []).map(vendor => (
                  <p key={vendor.vendor}>{vendor.vendor}</p>
                ))}
              </div>
            </Item>
            <Item className="flex">
              <span className="small">
                {t(
                  'menu.profile.personalData.sectionIntegration.buttonSingleLine.label.growerSince',
                )}
              </span>

              <p className="right">
                <Button
                  className="white whiteBorder"
                  style={{ width: 'auto' }}
                  onClick={() =>
                    history.push(`${EDIT_PROFILE}/integrateddate`)
                  }
                >
                  {data.integratedDate
                    ? moment(data.integratedDate).format('DD ̸̸  MM ̸̸  YYYY')
                    : t("menu.profile.accountData.personal-data.integratedDate.not-specified")}
                  <span className="icon icon-chevron-right" />
                </Button>
              </p>
            </Item>
          </FormSection>

          <FormSection>
            <h6>
              {t('menu.profile.personalData.sectionFarm.title')}
            </h6>

            <Item className="flex">
              <span className="small">
                {t(
                  'menu.profile.personalData.sectionFarm.buttonSingleLine.label.address',
                )}
              </span>

              <p className="right">
                {(data.vendors || []).length
                  ? data.vendors[0].streetCode
                  : t("menu.profile.accountData.personal-data.streetCode.not-specified")}
              </p>
            </Item>

            <Item className="flex">
              <span className="small">
                {t(
                  'menu.profile.personalData.sectionFarm.buttonSingleLine.label.city',
                )}
              </span>

              <p className="right">
                {(data.vendors || []).length
                  ? `${data.vendors[0].cityCode}/
                 ${data.vendors[0].stateCode}`
                  : t('menu.profile.accountData.personal-data.cityCode.not-specified')}
              </p>
            </Item>
          </FormSection>
        </>
      )}

      {!isLoading && !data && (
        <NoContentTab icon="icon-clipboard-user">
          <p>
            {t('menu.profile.accountData.personal-data.error-title')}<br />{' '}
            <span className="lighter">
              {t('menu.profile.accountData.personal-data.error-go-back')}
            </span>
          </p>
        </NoContentTab>
      )}
    </Container>
  );
}
