import styled from 'styled-components';
import {
  Select,
  Dropdown,
  Autocomplete,
  Item,
} from '@zendeskgarden/react-dropdowns';
import { Toggle, Label, Field } from '@zendeskgarden/react-forms';
import {
  colorPrimary,
  colorTertiary,
  colorInfo,
  colorGrayLight,
  colorClear,
  colorGrayDarker,
  colorGrayLightest,
  colorGrayLighter,
  colorGrayUltraLight,
  colorGrayDarkest,
  colorSuccess,
  DEFAULT_SIZE,
  boxShadow,
  breakpointTablet,
  fontFamilyText,
  fontFamilyTextBold,
  colorGrayDark,
  colorDanger,
  borderRadius,
} from '../GlobalStyles/variables';

export const ItemBox = styled(Item)`
  padding: 10px 20px !important;
`;

export const WhiteBox = styled.div`
  background-color: ${colorClear};
  padding: 16px 16px;
  border: 1px solid ${colorGrayLightest};
  border-radius: ${borderRadius};
`;

export const Spacer = styled.div`
  margin: 35px 0 0;
`;

export const Form = styled.form`
  .content-heading {
    color: ${colorGrayLight};
    font-family: ${fontFamilyTextBold};
    margin-top: 6px;
  }

  [class*='input--media'] {
    /* padding: 0; */
  }

  label {
    color: ${colorGrayLighter};
    font-family: ${fontFamilyTextBold};
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: ${DEFAULT_SIZE}px;
    margin-top: 12px;
  }

  input,
  input[type='text'],
  input[type='number'],
  input[type='time'],
  select {
    border: 1px solid ${colorGrayLightest}!important;
    width: 100%;
    padding: 16px !important;
    border-radius: 8px;
    height: 48px !important;
    max-height: 48px !important;
    min-height: 48px !important;
    color: ${colorGrayDarker}!important;

    &:disabled {
      color: ${colorGrayLighter}!important;
      background: ${colorGrayUltraLight};
      border: 1px solid ${colorGrayLightest}!important;
    }

    &:not[disabled] {
      color: ${colorGrayDarker}!important;
      border-color: ${colorGrayLighter}!important;
    }

    &:active,
    &:focus {
      border-color: ${colorGrayLighter}!important;
    }

    &.faux-child {
      border: none !important;
      margin: -10px 8px -10px -16px;
      height: 48px !important;
      border-radius: 8px !important;
    }
  }

  input[type='date'] {
    color: ${colorGrayLighter} !important;

    &:placeholder {
      color: ${colorGrayLighter} !important;
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    + label:not(.block-label) {
      color: ${colorGrayDarkest};
      text-transform: capitalize;
      font-size: 14px;
      font-weight: normal;
      display: inline-block;
      margin-left: 24px;
      letter-spacing: 0;

      &:before {
        left: -12px;
      }

      p {
        text-transform: none;
        margin: 0;
        line-height: 1;
      }
    }

    &:checked {
      + label {
        &:before {
          background-color: ${colorGrayDarkest}!important;
          border-color: ${colorGrayDarkest}!important;
        }
      }
    }

    ~ div {
      margin-left: 24px;
    }
  }

  .toggle-wrapper {
    input[type='checkbox'] {
      position: absolute !important;
      & + label {
        margin-left: 0 !important;
        width: 100%;
      }

      &:checked {
        & + label {
          &:before {
            background-color: ${colorSuccess}!important;
          }
        }
      }

      &:disabled {
        & + label {
          &:before {
            background-color: ${colorGrayLightest}!important;
          }
        }
      }
    }
  }

  buttons.button {
    border: 1px solid ${colorPrimary} !important;
    height: 40px !important;
    width: 120px;
    line-height: 40px;
    margin-left: 0 !important;
    margin-right: 0;
    float: right;
    background-color: ${colorPrimary}!important;

    @media (min-width: ${breakpointTablet}px) {
      width: 148px;
      margin-left: 15.5px !important;
    }

    &:hover {
      border-color: ${colorGrayDarkest} !important;
    }
  }

  .react-datepicker-time__header {
    font-size: 12px;
  }

  .react-datepicker__time-container {
    width: auto;
  }

  .react-datepicker__time-box {
    font-family: ${fontFamilyText};
    width: 100px !important;
    font-size: 12px;
    line-height: 20px;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__input-container {
    input:disabled {
      background: ${colorGrayUltraLight};
    }
  }

  // hr {
  //   margin-top: 68px;
  // }

  .notification-box {
    background-color: ${colorInfo};
    + hr {
      margin-top: -2px;
    }
  }

  .fake-radio {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: transparent;
    display: inline-block;
    position: relative;
    top: 3px;
    left: -15px;
    border: 1px solid ${colorGrayLightest};

    &.selected {
      border: 6px solid ${colorGrayDarkest};
    }
  }

  .editor-field {
    border: 1px solid ${colorGrayLightest};
    padding: 40px 16px 10px;
    border-radius: 8px;
    min-height: 258px;
    font-size: 14px;
    position: relative;

    &__toolbar {
      position: absolute;
      border: none;
      z-index: 100;
      top: 46px;
      left: 16px;
    }

    &:focus {
      border: 6px solid ${colorGrayDarkest};
    }

    .rdw-option-wrapper {
      height: 30px;
      padding: ${DEFAULT_SIZE}px;
    }
  }

  .datepicker__calendar {
    .react-datepicker__day,
    .react-datepicker__day-name {
      font-size: 11px;
    }

    .react-datepicker__navigation {
      &--next {
        border-left-color: ${colorGrayDarkest};
      }

      &--previous {
        border-right-color: ${colorGrayDarkest};
      }
    }
  }

  ${WhiteBox} {
    input[type='checkbox'] {
      + label {
        margin: 2px 0 2px 12px;
      }
    }
  }

  .rc-time-picker-clear {
    top: 12px;
    &-icon {
      &:after {
        font-size: 16px;
      }
    }
  }
`;

export const FieldSet = styled(Field)`
  padding: 0;
  border: none;
  width: 100%;
`;

export const SelectBox = styled(Select)`
  min-height: 48px !important;
  max-height: 48px !important;
  border-radius: 8px !important;
  color: ${colorGrayDarker}!important;
  line-height: 26px !important;

  .placeholder {
    color: ${colorGrayLighter}!important;

    &[disabled] {
      color: ${colorGrayLighter}!important;
    }
  }

  &:not[disabled] {
    border: 1px solid ${colorGrayUltraLight}!important;
  }

  &[disabled] {
    color: ${colorGrayLighter}!important;
    background-color: ${colorGrayUltraLight}!important;
    border: 1px solid ${colorGrayLightest}!important;
  }

  &:before {
    height: 48px !important;
  }
`;

export const AutocompleteBox = styled(Autocomplete)`
  min-height: 48px !important;
  max-height: 48px !important;
  line-height: 26px !important;
  border-radius: 8px !important;
  color: ${colorGrayDarker}!important;
  padding-left: 16px;
  dropdowns.select {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  dropdowns.field {
    overflow: hidden;
  }

  input {
    margin-top: -10px;
    box-shadow: none !important;
    border: 0 !important;
    
    &:active,
    &:focus,
    &:focus-within,
    &:target,
    &:hover,
    &:visited,
    &:focus-visible {
      box-shadow: none !important;
      border: 0 !important;
    }
  }

  .placeholder {
    color: ${colorGrayLighter}!important;

    &[disabled] {
      color: ${colorGrayLighter}!important;
    }
  }

  &:not[disabled] {
    border: 1px solid ${colorGrayUltraLight}!important;
  }

  &[disabled] {
    color: ${colorGrayLighter}!important;
    background-color: ${colorGrayUltraLight}!important;
    border: 1px solid ${colorGrayLightest}!important;
  }

  &:before {
    height: 48px !important;
  }
`;

export const DropdownBox = styled(Dropdown)`
  min-height: 48px !important;

  input {
    &:focus {
      box-shadow: none !important;
      border: none !important;
    }
  }
`;

export const FixedField = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  background-color: ${colorClear};
  width: 100%;
  left: 0;
  box-shadow: ${boxShadow};
  z-index: 10;

  @media (max-width: ${breakpointTablet}px) {
    background-color: transparent;
    position: relative;
    box-shadow: none;
    > div {
      padding: 20px 0;
    }
  }
`;

export const TextArea = styled.textarea`
  font-family: ${fontFamilyText};
  border: 1px solid ${colorGrayLightest}!important;
  width: 100%;
  min-height: 106px;
  padding: 10px 16px;
  border-radius: 8px;
`;

export const ToggleStyled = styled(Toggle)``;

export const LabelStyled = styled(Label)`
  padding-left: 0 !important;
  font-weight: normal !important;
  text-transform: none !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;

  &:before {
    left: calc(100% + 8px) !important;
  }
`;

export const AddItem = styled.div`
  color: ${props => (props.isNext ? colorTertiary : colorPrimary)};
  display: inline-block;
  padding: 10px 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;

  .icon {
    color: ${colorGrayDark};
    font-size: 12px;
    border: 2px solid ${colorGrayLight};
    font-weight: bold;
    border-radius: 50%;
    margin-right: 16px;
  }
`;

export const Counter = styled.div`
  position: relative;
  color: ${colorGrayLighter};
  font-size: 12px;
  float: right;
  margin: 4px 0 -${DEFAULT_SIZE * 2}px;

  span {
    color: ${colorGrayDarkest};

    &.red {
      color: ${colorDanger};
    }
  }
`;

export const FileInputWrapper = styled.div`
  position: relative;

  input {
    visibility: hidden;
    position: absolute;
    left: -9999px;
  }
`;

export const UploadButton = styled.span`
  background-color: ${colorGrayDarkest}!important;
  color: ${colorClear};
  height: 32px;
  margin: 0 12px 12px 0;
  cursor: pointer;
  border-radius: 32px;
  transition: 0.2s background-color ease-in;
  letter-spacing: 0.25px;
  font-size: 14px;
  padding: 6px 12px;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: ${colorGrayDark}!important;
  }

  .icon {
    font-size: 20px;
    margin-left: 8px;

    &.icon-close {
      font-size: 18px;
    }
  }
`;

export const Subheading = styled.h3`
  div & {
    font-family: ${fontFamilyTextBold};
    margin: ${DEFAULT_SIZE * 2}px 0;
    font-weight: normal;
    font-size: 16px;
  }
`;

export const GreyBox = styled.div`
  background-color: ${colorGrayUltraLight};
  padding: 16px 16px;
  margin: 0 -${DEFAULT_SIZE * 2}px ${DEFAULT_SIZE * 2}px;

  p {
    color: ${colorGrayDarkest};
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 12px;

    strong {
      color: ${colorPrimary};
      font-weight: normal;
      cursor: pointer;
    }
  }
`;

export const EyeIcon = styled.span`
  cursor: pointer;
  font-size: 16px;
  color: ${colorGrayLighter};
`;

export const Info = styled.div`
  font-size: 14px;
  margin-top: 12px;
`;
