// @flow

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as routes from 'config/routes';

import type { TRoutePrivateProps } from './typings';

function RoutePrivate(props: TRoutePrivateProps) {
  const {
    component: Component,
    isAuthenticated,
    layout,
    to,
    ...rest
  } = props;

  function TheComponent(properties) {
    if (layout) {
      return React.createElement(
        layout,
        properties,
        React.createElement(Component, properties),
      );
    }

    return <Component {...props} />;
  }

  return (
    <Route
      {...rest}
      render={properties =>
        isAuthenticated ? (
          <TheComponent {...properties} />
        ) : (
          <Redirect
            to={{
              pathname: to,
              state: {
                redirect: props.location && props.location.pathname,
                isAuthenticated,
              },
            }}
          />
        )
      }
    />
  );
}

RoutePrivate.defaultProps = {
  to: routes.MAIN,
};

export default RoutePrivate;
