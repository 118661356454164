import React, { Fragment } from 'react';
import Term from './Term';
import ModalActions from './ModalActions';
import { TermsOfUsage } from './TermModal.styled';

export default function ThermModal({ onAccept, onDeny, termData }) {
  return (
    <Fragment>
      <TermsOfUsage>
        <Term data={termData} />
      </TermsOfUsage>
      <ModalActions onAccept={onAccept} onDeny={onDeny} />
    </Fragment>
  );
}
