// @flow
import { createBrowserHistory } from 'history';
import qs from 'qs';
import { store } from 'store';
import ReactGA from 'react-ga';
import t from 'components/i18n';

const history = createBrowserHistory();

history.location = {
  ...history.location,
  query: qs.parse(history.location.search.substr(1)),
  state: {},
};

history.listen(() => {
  const hash = history.location.hash;

  /* ANALYTICS */
  if (window.location.hostname !== 'localhost') {
    if (store.getState('user').user.id) {
      const user = {
        id: store.getState('user').user.id,
        cpf: store.getState('user').user.cpf,
        type: store.getState('user').user.type,
      };
      ReactGA.set({
        title: t(
          'shared.history.ga.set',
          {
            id: user.id, 
            cpf: user.cpf, 
            type: user.type, 
          }
        ),
      });
    } else {
      ReactGA.set({ title: t("shared.history.ga.notAuthenticated") });
    }
    ReactGA.pageview(hash);
  }

  history.location = {
    ...history.location,
    query: qs.parse(history.location.search.substr(1)),
    state: history.location.state || {},
  };
});

const { go, goBack, push, replace } = history;

export { go, goBack, push, replace };
export default history;
