import React from 'react';
import Heading from 'components/Heading';
import Card from './Card';
import { AboutStyled, Wrapper, Container } from './About.styled';
import t from 'components/i18n';

const cards = [
  {
    icon: 'chart-analytics',
    title: t('lp.about.card.management.title'),
    description: t('lp.about.card.management.text'),
  },
  {
    icon: 'bell-enabled',
    title: t('lp.about.card.alerts.title'),
    description: t('lp.about.card.alerts.text'),
  },
  {
    icon: 'clock',
    title: t('lp.about.card.sync.title'),
    description: t('lp.about.card.sync.text'),
  },
  {
    icon: 'scale',
    title: t('lp.about.card.weighing.title'),
    description: t('lp.about.card.weighing.text'),
  },
];

export default function About(props) {
  return (
    <AboutStyled id="about">
      <Container className="center">
        <Heading
          pageTitle={t('lp.about.title')}
          subHeading={t('lp.about.subtitle')}
        />
        <p className="intro">
          {t('lp.about.text')}
        </p>
        <Wrapper>
          {cards.map((item, index) => (
            <Card key={index} {...item} />
          ))}
        </Wrapper>
      </Container>
    </AboutStyled>
  );
}
