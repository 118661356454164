import styled from 'styled-components';
import {
  borderRadius,
  colorGrayLightest,
} from 'components/GlobalStyles/variables';

export const ToggleOptionsStyled = styled.div`
  border: 1px solid ${colorGrayLightest};
  border-radius: ${borderRadius};
  padding-bottom: 10px;
  position: relative;
  margin-bottom: 16px;

  .toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  input[type='checkbox'] {
    + label {
      &:before {
        top: 8px;
      }
    }
  }

  input[type='checkbox'] {
    + label.toggle {
      padding: 6px 0 0 0px;

      &:before {
        top: 4px;
        right: 36px;
        left: auto;
      }
    }
  }
`;
