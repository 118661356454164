import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import moment from 'moment';
import { Button } from '@zendeskgarden/react-buttons';
import { VISIT_CIRCUIT, OPEN_VISIT } from 'config/routes';
import { ThemeContext } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import Box from 'components/Container';
import SideBox from 'components/SideBox';
import LoadingHolder from 'components/LoadingHolder';
import IconAndText from 'components/IconAndText';
import Modal from 'components/Forms/FormModal/Modal';
import * as routes from 'config/routes';
import { List } from 'components/List';
import {
  Heading,
  TitleBlock,
  FixedFieldCustom,
  StatusBar,
  DeleteContainer
} from 'components/SideBox/SideBox.styled';
import { DefaultButton } from 'components/CircuitQuestion/CircuitQuestion.styled';
import { Details } from './LogBook.styled';
import { getStatus, getHousesContent } from './utils';
import EditLogBook from './EditLogBook';
import t from 'components/i18n';
import { deleteRandomLogBookItem } from './service';

export default function LogBookDetails({
  data,
  history,
  setDisplayBox,
  displayBox,
  getLogBookList,
  getDetails,
  isLoading,
}) {
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const isEditable =
    data &&
    (data.logBookItemStatus === 0 || data.logBookItemStatus === 4);
  const themeContext = useContext(ThemeContext);

  const getList = useMemo(() => {
    return (
      data &&
      data.flocks &&
      data.flocks.length > 0 &&
      data.flocks.map((e, index) => ({
        title: t('next.logBookDetails.item.title', { value: e.flock }),
        left: `${t('next.logBook.house')} ${e.houseCode}`,
        right: e.age.length === 1
          ? t('next.logBookDetails.item.day', { value: e.age })
          : t('next.logBookDetails.item.days', { value: e.age })
      }))
    );
  }, [data]);

  useEffect(() => {
    if (displayBox) {
      setDisplayCalendar(false);
    }
  }, [displayBox]);

  const deleteItem = useCallback(() => {
    deleteRandomLogBookItem(data.id)
      .then(() => history.push(`${ routes.LOGBOOK }`));
  }, [data]);

  const getContentList = useCallback(
    data => {
      const label = getStatus(data.logBookItemStatus);

      return (
        <>
          <List>
            <IconAndText
              iconBackGround="colorPrimary"
              icon="icon-calendar-alt"
              title={t(data.logBookItemType)}
            />
            {data.isRandom && (
              <DeleteContainer>
                <div>
                  <p className="title">{
                    data.randomType === "visit"
                      ? t('next.logBookDetails.item.type.visit-event')
                      : t('next.logBookDetails.item.type.meeting-event')
                  }</p>
                  <p className="created-at">{t('next.logBookDetails.item.date', { value: moment(data.createDate).format('DD ̸̸  MM ̸̸  YYYY') })}</p>
                </div>
                <span
                  className={`icon icon-trash`}
                  onClick={() => setDeleteConfirmation(true)}
                  style={{
                    fontSize: 20,
                    cursor: 'pointer'
                  }}
                />
              </DeleteContainer>
            )}
            <IconAndText
              icon="icon-calendar"
              subtitle={moment(data.visitDate).format('DD ̸̸  MM ̸̸  YYYY')}
              action={
                isEditable ? () => setDisplayCalendar(true) : false
              }
              actionIcon="icon-edit"
              tooltip={t("next.logBookDetails.menu.edit-date")}
              border={data.logBookItemStatus <= 0}
            />

            {data.logBookItemStatus > 0 && (
              <StatusBar
                bgColor={
                  getStatus(data.logBookItemStatus).color &&
                  rgba(
                    themeContext.styles.palette[
                      getStatus(data.logBookItemStatus).color
                    ],
                    0.16,
                  )
                }
                style={{
                  color:
                    themeContext.styles.palette[
                      getStatus(data.logBookItemStatus).color
                    ],
                }}
              >
                <span
                  className={`icon ${
                    getStatus(data.logBookItemStatus).icon
                  }`}
                />
                {t(getStatus(data.logBookItemStatus).label)}
              </StatusBar>
            )}

            <IconAndText
              icon="icon-clipboard-user"
              subtitle={data.vendorName}
              border
            />

            {getList && getList.length && (
              <IconAndText
                alignTop
                icon="icon-flock"
                listTitle={t('next.logBookDetails.menu.open-flock')}
                list={getList}
                border
              />
            )}

            {data.status && (
              <IconAndText
                icon="icon-tag"
                subtitle={data.status}
              />
            )}
          </List>

          <FixedFieldCustom>
            <Button
              className="action tertiary"
              onClick={() =>
                {
                  if (
                    data.logBookItemStatus === 3 && data.justification && data.justification.length ||
                    data.logBookItemStatus === 4
                  ) {
                    history.push(`${OPEN_VISIT}/${data.id}`)
                    return;
                  }

                  history.push(`${VISIT_CIRCUIT}/${data.id}`)
                }
              }
            >
              {t(label && label.buttonLabel || 'label')}
            </Button>

            {data.logBookItemStatus === 0 && (
              <DefaultButton
                className="action secondary-button"
                onClick={() => setDisplayCalendar(true)}
              >
                {t('next.logBookDetails.menu.update-date')}
              </DefaultButton>
            )}
          </FixedFieldCustom>
        </>
      );
    },
    [getList, history, isEditable, themeContext.styles.palette],
  );

  if (!data) {
    return null;
  }

  const getCalendar = useCallback(
    data => {
      return (
        <EditLogBook
          id={data.id}
          isRandom={data.isRandom}
          orientation={data.orientation}
          data={data}
          status={data.logBookItemStatus}
          date={moment(data.visitDate).format('YYYY-MM-DD')}
          setDisplayCalendar={setDisplayCalendar}
          getLogBookList={getLogBookList}
          getDetails={getDetails}
        />
      );
    },
    [getDetails, getLogBookList],
  );

  return (
    <SideBox displayBox={data} setDisplayBox={setDisplayBox}>
      <Box className="side-box">
        <Details>
          <TitleBlock>
            <Heading>
              {displayCalendar
                ? t('next.logBookDetails.title.update-date')
                : t('next.logBookDetails.title.event-details')}
            </Heading>
          </TitleBlock>

          <LoadingHolder isLoading={isLoading} />

          {!isLoading &&
            data &&
            (displayCalendar
              ? getCalendar(data)
              : getContentList(data))}
        </Details>

        {/* send confirmation modal */}
        {deleteConfirmation && (
          <Modal
            warning
            opened={deleteConfirmation}
            confirm={() => deleteItem()}
            deny={() => setDeleteConfirmation(false)}
            onCloseModal={() => setDeleteConfirmation(false)}
            confirmLabel={t('global.button.delete')}
            denyLabel={t('button.global.cancel')}
          >
            <span className="icon icon-exclamation-triangle" />
            <h4>{t('next.logBookDetails.delete.title')}</h4>
            <p>{t('next.logBookDetails.delete.subtitle')}</p>
          </Modal>
        )}
      </Box>
    </SideBox>
  );
}
