import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { colorGrayDarkest } from 'components/GlobalStyles/variables';

export const WhiteBoxInsideHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  padding: 12px 16px 8px;
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};
`;
