import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import Modal from 'components/Forms/FormModal/Modal';

export default function PromptModal(props) {
  const [nextLocation, setNextLocation] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(
    false,
  );

  const handleBlockedNavigation = e => {
    if (!confirmedNavigation) {
      setNextLocation(e.pathname);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (confirmedNavigation) props.history.push(nextLocation);
  }, [confirmedNavigation, nextLocation, props.history]);

  return (
    <>
      <Modal
        opened={nextLocation}
        warning
        onCloseModal={() => {}}
        confirm={() => {
          setConfirmedNavigation(true);
        }}
        confirmLabel={props.confirmLabel}
        deny={() => {
          setConfirmedNavigation(false);
          setNextLocation(false);
        }}
        denyLabel={props.denyLabel}
      >
        <div className="modal-title">
          <span className="icon icon-exclamation-triangle" />
          <h4>{props.title}</h4>
        </div>

        <p>{props.message}</p>
      </Modal>
      <Prompt when={props.when} message={handleBlockedNavigation} />
    </>
  );
}
