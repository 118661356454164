import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  breakpointTablet,
  breakpointDesktop,
  colorClear,
  colorSuccess,
  colorDanger,
  colorGrayLighter,
  colorPrimary,
  colorTertiary,
} from 'components/GlobalStyles/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Main = styled.div`
  background-color: ${colorClear};
  border-radius: ${DEFAULT_SIZE}px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(37, 48, 71, 0.16);

  @media (min-width: ${breakpointDesktop}px) {
    width: 65%;
    min-width: 700px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${DEFAULT_SIZE * 2}px;
  padding-bottom: 40px;

  @media (min-width: ${breakpointTablet}px) {
    padding-left: ${DEFAULT_SIZE * 3}px;
    padding-right: ${DEFAULT_SIZE * 3}px;
  }

  @media (min-width: ${breakpointDesktop}px) {
    flex-direction: row;
  }
`;

export const IconPlaceholder = styled.div`
  display: flex;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${colorGrayLighter};
  position: relative;
  padding: 0;

  &.top {
    align-self: flex-start;
  }

  .icon {
    color: ${colorClear};
    font-size: 24px;
  }

  &.primary {
    background-color: ${colorPrimary};
  }

  &.success {
    background-color: ${colorSuccess};
  }

  &.danger {
    background-color: ${colorDanger};
  }

  &.tertiary {
    background-color: ${colorTertiary};
  }
`;

export const Initials = styled.div`
  color: ${colorClear};
  display: inline-block;
  background-color: ${colorGrayLighter};
  border-radius: 50%;
  margin-right: 16px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
`;

export const StatusWrapper = styled.div`
  margin-top: 16px;
`;

export const SlaugthConfirmed = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorSuccess};

  span {
    margin-right: 12px;
    &:before {
      transform: scale(1.6);
      display: inline-block;
    }
  }
`;
