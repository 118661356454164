import Breadcrumb from 'components/Breadcrumb';
import Container from 'components/Container';
import { DropdownField } from 'components/Forms/Fields';
import Icon from 'components/Icon';
import IconAndText from 'components/IconAndText';
import t from 'components/i18n';
import Top from 'components/Top';
import {
  CHECKLIST_DETAILS,
  NEXT_FLOCK_LIST,
  VENDORS,
  VENDORS_TABLE,
} from 'config/routes';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// styles
import {
  CategoryToggle,
  DetailsContainer,
  FlexWrapper,
  Separator,
} from './Checklists.styled';
import { useChecklist } from './hooks';
import { questionTitleMapper } from 'utils/mapper';

export default function ChecklistHistory({ history }) {
  const user = useSelector(state => state.user);
  const [{ details }, { onGoToDetails }] = useChecklist();
  const [hiddenCategories, setHiddenCategories] = useState([]);

  useEffect(() => {
    if (!details || !details.checklist) {
      history.push(`/`);
    }
    if (
      details &&
      details.checklist &&
      (!details.checklist.selectedHistory ||
        !details.checklist.questionGroupedByCategory)
    ) {
      onGoToDetails();
    }
  }, [details, history, onGoToDetails]);

  if (
    !details ||
    !details.checklist ||
    !details.vendor ||
    !details.checklist.selectedHistory
  ) {
    return null;
  }

  const { checklist, vendor } = details;
  const { selectedHistory } = checklist;

  const questionGroupedByCategory = selectedHistory.questions.reduce(
    (acum, question) => {
      if (acum[question.desCategoria]) {
        acum[question.desCategoria] = [
          ...acum[question.desCategoria],
          question,
        ];
        return acum;
      }

      acum[question.desCategoria] = [question];
      return acum;
    },
    {},
  );

  const toggleHidden = category => {
    if (hiddenCategories.includes(category)) {
      return setHiddenCategories(
        hiddenCategories.filter(
          hiddenCategory => hiddenCategory !== category,
        ),
      );
    }
    setHiddenCategories([...hiddenCategories, category]);
  };

  return (
    <>
      <Breadcrumb
        history={history}
        path={[
          t('checklist.history.bc.integrated'),
          vendor.vendorName,
          t('checklist.history.bc.title'),
          checklist.indLiquidacaoDesc,
          t('checklist.history.bc.flock', { value: selectedHistory.flock }),
        ]}
        links={[
          user && user.type === 'E' ? VENDORS : VENDORS_TABLE,
          `${NEXT_FLOCK_LIST}/${vendor.vendorId}`,
          `${NEXT_FLOCK_LIST}/${vendor.vendorId}?tab=1`,
          `${CHECKLIST_DETAILS}/`,
        ]}
      />

      <Top>
        <IconAndText
          title={t("checklist.history.flock", { value: selectedHistory.flock})}
          subtitle={moment(selectedHistory.createdDate).format(
            'DD ̸̸  MM ̸̸  YYYY',
          )}
          icon="icon-edit"
          iconBackGround="colorGrayLighter"
        />
      </Top>

      <DetailsContainer>
        <FlexWrapper>
          <Container className="container" slim hideDetail>
            {questionGroupedByCategory ? (
              Object.keys(questionGroupedByCategory).map(key => (
                <>
                  <CategoryToggle onClick={() => toggleHidden(key)}>
                    <h4>
                      <strong>{key}</strong>
                    </h4>
                    <Icon
                      className="icon"
                      name={
                        hiddenCategories.includes(key)
                          ? 'icon-plus'
                          : 'icon-minus'
                      }
                    />
                  </CategoryToggle>
                  <Separator style={{ marginBottom: 14 }} />

                  {!hiddenCategories.includes(key) && (
                    <>
                      {!!questionGroupedByCategory[key] &&
                        !!questionGroupedByCategory[key].length &&
                        questionGroupedByCategory[key].map(
                          question => {
                            return (
                              <>
                                <DropdownField
                                  field="field"
                                  label={question.desPergunta}
                                  placeholder={
                                    questionTitleMapper(question.answers[0].desResposta)
                                  }
                                  onSelect={() => null}
                                  options={[]}
                                  optionLabel="desc"
                                  values={{ field: '' }}
                                  hideMessage
                                  disabled
                                />
                                <Separator
                                  style={{ marginBottom: 14 }}
                                />
                              </>
                            );
                          },
                        )}
                    </>
                  )}
                </>
              ))
            ) : (
              <p>{t('checklist.history.show.error')}</p>
            )}
          </Container>
        </FlexWrapper>
      </DetailsContainer>
    </>
  );
}
