import { API_SUPERVISOR_URL } from 'config/constants';
import { request } from 'shared/utils/client';
import { fixNestedNamesRecursively } from 'shared/utils/Helpers/capitalizeName';

export async function getFeedReport(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Feed/GetFeedReport`,
    {
      method: 'POST',
      payload,
    },
  );

  return response;
}

export async function getPlacementReport(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Placement/GetPlacementReport`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}

export async function getSlaughterReport(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Slaughter/GetScheduleSlaughterOperationalUser`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}

export async function getProducersForOperationalUser() {
  const response = await request(
    `${API_SUPERVISOR_URL}/Producer/GetProducersForOperationalUser`,
    {
      method: 'GET'
    },
  );

  return {
    ...response,
    data: fixNestedNamesRecursively(response.data, 'vendorName'),
  };
}
