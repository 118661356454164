import React, { useState, useEffect } from 'react';
import t from 'components/i18n';
import Tabs, { Tab, TabContent, TabHeader } from 'components/Tabs';
import { isEmpty } from 'lodash';
import HistoryTop from 'containers/AgroBRF/FlockList/Flock/FlockHistory/HistoryTop';
// styles
import { ContentLine } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import RoundContainer from 'components/RoundContainer';
// service
import { getTechnicalIndicators } from 'components/Payment/service';
import NoContentTab from 'components/NoContentTab';
import { useMemo } from 'react';

export default function Demonstrative(props) {
  const { getBoxType, selectedKey, user } = props;

  const [data, setData] = useState({});

  const getTabs = useMemo(() => {
    if (!data || isEmpty(data)) return [];

    const {
      ageSlaughter,
      placementDate,
      slaughterDate,
      placementQuantity,
      slaughterQuantity,
      weightGain,
      slaughterWeight,
      averageWeight,
    } = data;

    return [
      {
        label: t(
          'flocks.flocksDetail.sectionRemuneration.demonstratives.age',
        ),
        value: ageSlaughter,
      },
      {
        label: t(
          'flocks.flocksDetail.sectionRemuneration.demonstratives.placementDate',
        ),
        value: placementDate,
      },
      {
        label: t(
          'flocks.flocksDetail.sectionRemuneration.demonstratives.slaughterDate',
        ),
        value: slaughterDate,
      },
      {
        label: t(
          'flocks.flocksDetail.sectionRemuneration.demonstratives.placementQuantity',
        ),
        value: placementQuantity,
      },
      {
        label: t(
          'flocks.flocksDetail.sectionRemuneration.demonstratives.slaughterQuantity',
        ),
        value: slaughterQuantity,
      },
      {
        label: t(
          'flocks.flocksDetail.sectionRemuneration.demonstratives.dailyWeightGain',
        ),
        value: weightGain,
      },
      {
        label: t(
          'flocks.flocksDetail.sectionRemuneration.demonstratives.totalSlaughterWeight',
        ),
        value: slaughterWeight,
      },
      {
        label: t(
          'flocks.flocksDetail.sectionRemuneration.demonstratives.averageSlaughterWeight',
        ),
        value: averageWeight,
      },
    ];

  }, [data]);

  useEffect(() => {
    async function fetch(flockId) {
      try {
        const response = await getTechnicalIndicators(flockId);
        setData(response || {});
      } catch (e) {
        console.error('Error on get flock demonstrative', e);
      }
    }

    fetch(props.flockId);
  }, [props.flockId]);

  return (
    <>
      {user.profile === 'Administrador' && (
        <HistoryTop
          iconBox={getBoxType(selectedKey)}
          iconType="chart-analytics"
          textContent={[
            {
              label: t(
                'flocks.flocksDetail.sectionRemuneration.liquidRevenue.label',
              ),
              content: data.flockNetResult,
            },
            {
              label: t(
                'flocks.flocksDetail.sectionRemuneration.flockResult.label',
              ),
              content: data.flockResult,
            },
          ]}
        />
      )}
      <RoundContainer noMargin>
        {data && !isEmpty(data) ? (
          <Tabs>
            <Tab key="tab-1">
              <TabHeader>
                {t(
                  'flocks.flocksDetail.sectionGeneralData.generalData.title',
                )}
              </TabHeader>
              <TabContent>
                <div className="list-content">
                  {getTabs.map((item, index) => (
                    <ContentLine key={index}>
                      <span className="label">{item.label}</span>
                      <span className="value">{item.value}</span>
                    </ContentLine>
                  ))}
                </div>
              </TabContent>
            </Tab>
            <Tab key="tab-2">
              <TabHeader>
                {t(
                  'flocks.flocksDetail.sectionGeneralData.technicalIndicators.title',
                )}
              </TabHeader>
              <TabContent>
                <div className="list-content">
                  <ContentLine>
                    <span className="label">
                      {t(
                        'flocks.flocksDetail.sectionTechnicalIndicators.mortalityRate.label',
                      )}
                    </span>
                    <div className="value">
                      {t(
                        'flocks.flocksDetail.sectionTechnicalIndicators.mortalityRate.quantityRealAjusted',
                        {
                          quantity:
                            data.mortality && data.mortality.realAJ.split('%')[0],
                        },
                      )}
                      <span className="small">
                        {t(
                          'flocks.flocksDetail.sectionTechnicalIndicators.mortalityRate.quantityReal',
                          {
                            quantity:
                              data.mortality && data.mortality.real.split('%')[0],
                          },
                        )}
                      </span>
                      <span className="small">
                        {t(
                          'flocks.flocksDetail.sectionTechnicalIndicators.mortalityRate.quantityForeseen',
                          {
                            quantity:
                              data.mortality &&
                              data.mortality.foreseen.split('%')[0],
                          },
                        )}
                      </span>
                    </div>
                  </ContentLine>
                  <ContentLine>
                    <span className="label">
                      {t(
                        'flocks.flocksDetail.sectionTechnicalIndicators.feedConversion.label',
                      )}
                    </span>
                    <div className="value">
                      {t(
                        'flocks.flocksDetail.sectionTechnicalIndicators.feedConversion.quantityRealAjusted',
                        {
                          quantity:
                            data.foodConversion &&
                            data.foodConversion.realAJ,
                        },
                      )}
                      <span className="small">
                        {t(
                          'flocks.flocksDetail.sectionTechnicalIndicators.feedConversion.quantityReal',
                          {
                            quantity:
                              data.foodConversion &&
                              data.foodConversion.real,
                          },
                        )}
                      </span>
                      <span className="small">
                        {t(
                          'flocks.flocksDetail.sectionTechnicalIndicators.feedConversion.quantityForeseen',
                          {
                            quantity:
                              data.foodConversion &&
                              data.foodConversion.foreseen,
                          },
                        )}
                      </span>
                    </div>
                  </ContentLine>
                </div>
              </TabContent>
            </Tab>
          </Tabs>
        ) : (
          <NoContentTab icon="icon-chart-analytics">
            <p>
              {t('flocks.empty.title')} <br />{' '}
            </p>
          </NoContentTab>
        )}
      </RoundContainer>
    </>
  );
}
