import React, { useState, useEffect } from 'react';
import logger from 'shared/utils/logger';
import uid from 'uuid';
import { scrollToTop } from 'shared/utils/Helpers';
import LoadingHolder from 'components/LoadingHolder';
import HistoryTop from 'containers/AgroBRF/FlockList/Flock/FlockHistory/HistoryTop';
import DeceasedSummaryItem from 'containers/AgroBRF/FlockList/Flock/FlockHistory/DeceasedSummaryItem';
import NoContentTab from 'components/NoContentTab';
import { getSwineMortalityList } from 'containers/AgroBRF/FlockList/service';
import t from 'components/i18n';

export default function SwineMortalityList({
  data,
  flockID,
  updateItems,
  setUpdateItems,
  setSelectedKey,
  removed,
  setRemoved,
  setDisplayExcludeModal,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [mortalityList, setMortalityList] = useState([]);

  async function fetchSummary(id) {
    setIsLoading(true);
    try {
      const response = await getSwineMortalityList(id);
      setMortalityList(response);
      setIsLoading(false);
    } catch (e) {
      logger('Error on getSummary', e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (removed) {
      setUpdateItems(true);
    }
  }, [removed, setUpdateItems]);

  useEffect(() => {
    if (updateItems) {
      fetchSummary(flockID);
      setTimeout(() => setRemoved(false), 2000);
      scrollToTop(document.getElementById('main'));
    }
  }, [flockID, setRemoved, updateItems]);

  useEffect(() => {
    fetchSummary(flockID);
  }, [flockID]);
  return (
    <>
      <LoadingHolder
        isLoading={isLoading}
        message={t('global.loading')}
      />

      {Object.keys(mortalityList).length > 0 && (
        <HistoryTop
          textContent={[
            {
              label: t('agro.flock.swine.mortality.totalMortality'),
              content: mortalityList && mortalityList.totalMortality,
            },
            {
              label: t('agro.flock.swine.mortality.percentageIndex'),
              content: mortalityList && mortalityList.percentageIndex,
            },
          ]}
        />
      )}

      {mortalityList.swineMortality &&
        mortalityList.swineMortality.map(feed => (
          <DeceasedSummaryItem
            key={`feed-${uid()}`}
            feed={feed}
            goTo={e => setSelectedKey(e)}
            exclude={() => setDisplayExcludeModal(true)}
            houses={data.houseInformation}
            hideOptions={!feed.isEditable}
          />
        ))}

      {Object.keys(mortalityList).length > 0 &&
        mortalityList.swineMortality.length === 0 &&
        !isLoading && (
        <NoContentTab icon="icon-pig-alt">
          <p>
            {t('global.no-content')}
          </p>
        </NoContentTab>
      )}
    </>
  );
}
