import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { SummaryItem } from '../StyledNextFlock.styled';
import t from 'components/i18n';

export default function GeneralInformation({ data, ...props }) {
  const severalPlacements =
    data && data.placementInformation.length > 1;

  function getHouseSexInfo(gender) {
    const houseSexValues = Object.entries(data.houseSex);
    const genderHouses = [];

    houseSexValues.map(item => {
      if (item[1] === gender) {
        genderHouses.push(item[0]);
      }
    });

    if (genderHouses.length > 1) {
      return (
        <>
          <strong>{gender}</strong>
          <span style={{ fontSize: '12px' }}>
            {' '}
            {t('next.flock.labelInfo.general-info.houses', { value: genderHouses.join(', ') })}
          </span>
        </>
      );
    }

    if (genderHouses.length === 1) {
      return (
        <>
          <strong>{gender}</strong>{' '}
          <span style={{ fontSize: '12px' }}>
            {t("next.flock.labelInfo.general-info.house", { value: genderHouses[0] })}
          </span>
        </>
      );
    }
  }

  const [
    generalInformationData,
    setGeneralInformationData,
  ] = useState([]);

  useEffect(() => {
    if (data) {
      setGeneralInformationData([
        {
          label: t('next.flock.labelInfo.general-info.placementDate.label'),
          info: moment(
            data &&
            data.placementInformation &&
            data.placementInformation[0].placementDate,
          ).format('DD ̸̸  MM ̸̸  YYYY'),
          hide: severalPlacements,
        },
        {
          label: t('next.flock.labelInfo.general-info.placementTotalQuantity.label'),
          info: data && t('next.flock.labelInfo.general-info.placementTotalQuantity.info', { value: data.placementTotalQuantity }),
          add:
            data &&
            data.originInformation.map(
              item =>
                t('next.flock.labelInfo.general-info.placementTotalQuantity.add', { cbc: item.quantity, house: item.houseDestination }),
            ),
        },
        {
          label: t('next.flock.labelInfo.general-info.boxesNumber.label'),
          info: data && t('next.flock.labelInfo.general-info.boxesNumber.info', { value: data.boxesNumber }),
        },
        {
          label: t('next.flock.labelInfo.general-info.broilerLineage.label'),
          info: data && data.broilerLineage,
        },
        {
          label: t('next.flock.labelInfo.general-info.sex.label'),
          info: (
            <>
              {getHouseSexInfo(t('next.flock.labelInfo.general-info.sex.female'))}
              <br />
              {getHouseSexInfo(t('next.flock.labelInfo.general-info.sex.male'))}
            </>
          ),
        },
        {
          label: t('next.flock.labelInfo.general-info.incubatory.label'),
          info: data && data.incubatory,
        },
      ]);
    }
  }, [data]);

  return (
    <>
      <br />
      {severalPlacements &&
        data.placementInformation.map((item, index) => (
          <SummaryItem
            className="flex"
            style={{ alignItems: 'flex-start' }}
            key={index}
          >
            <span className="label">
              {t('next.flock.labelInfo.general-info.placementInformation.date', { value: index.toString().length < 2 ? `0${index + 1}` : index + 1 })}
            </span>
            <div className="column">
              <span className="info">
                {moment(item.placementDate).format('DD ̸̸  MM ̸̸  YYYY')}
              </span>

              {item.placementHouseList.length === 1 && (
                <span className="info add">
                  {t('next.flock.labelInfo.general-info.placementInformation.house', { value: item.placementHouseList[0] })}
                </span>
              )}

              {item.placementHouseList.length > 1 && (
                <span className="info add">
                  {
                    t(
                      'next.flock.labelInfo.general-info.placementInformation.houses',
                      {
                        value: item.placementHouseList.map(
                          (subitem, idx) =>
                            `${subitem}${idx !==
                            item.placementHouseList.length && ','}`,
                        )
                      })}
                </span>
              )}
            </div>
          </SummaryItem>
        ))}

      {generalInformationData.map(
        (item, index) =>
          !item.hide && (
            <SummaryItem
              className="flex"
              style={{ alignItems: 'flex-start' }}
              key={index}
            >
              <span className="label">{item.label}</span>
              <div className="column">
                <span className="info">{item.info}</span>
                {item.add &&
                  item.add.map(subitem => (
                    <span className="info add">{subitem}</span>
                  ))}
              </div>
            </SummaryItem>
          ),
      )}
    </>
  );
}
