import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';

import Image from 'components/Image';
import CardWrapper from 'components/CardWrapper';

import { API_URL } from 'config/constants';

import { useHistory, useParams } from 'react-router';
import DateAndTime from 'components/DateAndTime';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import {
  BottomContentOrientation,
  TitleOrientation,
  Header,
  Item,
} from './VisitStyled.styled';
import { getLogbookItemsOrientations } from '../VisitCircuit/service';
import EditOrientation from './EditOrientation';
import t from 'components/i18n';

export default function PostCircuit(props) {
  const {
    setDisplayOrientation,
    orientations: { attachments = [], orientation, createdAt },
    visitDate,
    isRandom,
  } = props;

  return (
    <>
      {!isRandom && (
        <CardWrapper title={t('next.visitList.pastCircuit.title')}>
          <p>{t('next.visitList.pastCircuit.subtitle')}</p>
          <BtnGroup justifyContent="space-around">
            <Button
              key="save"
              className="tertiary"
              style={{ width: 220, marginTop: '1rem' }}
            >
              {t('next.visitList.pastCircuit.button.seeCircuitVisit')}
            </Button>
          </BtnGroup>
        </CardWrapper>
      )}

      <div onClick={() => setDisplayOrientation(true)}>
        <CardWrapper title="Orientação técnica">
          <CardWrapper>
            <Header>
              <DateAndTime noMargin>
                {t('next.visitList.pastCircuit.orientation.date', {
                  value: moment(createdAt || visitDate).format('DD/MM/YYYY').replaceAll('/', ' ̸̸  '),
                })}
              </DateAndTime>
              {attachments.length > 0 && (
                <span className="icon icon-clip" />
              )}
            </Header>
            <p>{orientation}</p>

            <BottomContentOrientation>
              <span className="icon icon-check" />
              <TitleOrientation>
                {t('next.visitList.pastCircuit.orientation.sent')}
              </TitleOrientation>
            </BottomContentOrientation>
          </CardWrapper>
        </CardWrapper>
      </div>
    </>
  );
}
