// @flow

import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';
import agroRouteList from 'containers/App/agroRoutes';
import nextRouteList from 'containers/App/nextRoutes';
import { Item } from './SidebarItem.styled';

const NewItem = props => {
  const themeContext = useContext(ThemeContext);

  return (
    <Item
      themeStyle={themeContext.styles}
      onClick={props.onClick}
      className={props.classes.join(' ')}
      clear={props.clear}
    >
      {props.children}
    </Item>
  );
};

export default function SidebarItem(props) {
  const {
    icon = '',
    label,
    active = false,
    onClick,
    route,
    clear,
  } = props;

  const classes = [];

  const user = useSelector(state => state.user);
  const routeList = [...agroRouteList(user), ...nextRouteList(user)];

  const sidebarRoute = routeList.find(item => item.route === route);

  const { allowRoutes } = user;

  if (active) {
    classes.push('active');
  }

  function displayItem() {
    if (
      route === 'HOME' ||
      !allowRoutes ||
      (allowRoutes && !allowRoutes.length)
    ) {
      return true;
    }

    return (
      allowRoutes &&
      sidebarRoute &&
      allowRoutes.length > 0 &&
      allowRoutes.findIndex(
        item => Number(item.id) === sidebarRoute.id,
      ) > -1
    ) || null;
  }

  return (
    displayItem() && (
      <NewItem
        classes={classes}
        onClick={onClick}
        clear={clear}
        route={route}
      >
        <div className="item-container sidebar-item">
          <div className="item-inner">
            {icon && (
              <div className="icon">
                <span className={icon} />
              </div>
            )}
            <span className="label">{label}</span>
          </div>
        </div>
      </NewItem>
    )
  );
}
