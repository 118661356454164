import { ListItem } from 'components/List/List';
import styled from 'styled-components';

export const CadecDetail = styled(ListItem)`
  padding: 0;
  height: auto;
  &:before {
    left: 0 !important;
    width: 100% !important;
  }
  justify-content: space-between;
`;
