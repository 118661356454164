import React, { useState, useEffect, useCallback } from 'react';
import { isEmpty, chain } from 'lodash';
import { Formik } from 'formik';
import * as yup from 'yup';

import t from 'components/i18n';
import Top from 'components/Top';
import Modal from 'components/Forms/FormModal/Modal';
import validate from 'components/Forms/Fields/validate';
import Breadcrumb from 'components/Breadcrumb';

import { Field } from '@zendeskgarden/react-forms';
import { Button } from '@zendeskgarden/react-buttons';
import MultiSelect from 'components/MultiSelect';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import {
  AutoCompleteDropdownField2,
  DatePickerField,
  DropdownField,
  InputField,
} from 'components/Forms/Fields';
import {
  Container,
  Separator,
  FlexContainer,
  Title,
  Form,
} from '../Event.styled';

import {
  GetProducerListVisits,
  GetHousesByVendor,
  GetLogBookReason,
} from '../service';

const initialValues = {};

function validateSchema(props, animalType) {
  const { reason } = props;

  return yup.object().shape({
    grower: yup.object().required(t('global.status.required-field')),
    reason: yup.object().required(t('global.status.required-field')),
    ...(animalType !== 'swine' && {
        house: yup.object().required(t('global.status.required-field'))
      }),
    ...(reason &&
      reason.name === 'technicalVisit.anotherReason' && {
        otherReason: yup
          .string()
          .required(t('global.status.required-field')),
      }),
  });
}

export default function Visit(props) {
  const { onSubmit, history, user } = props;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => onSubmit(values)}
      validate={validate(values => validateSchema(values, user.animalType))}
      render={props => <VisitForm history={history} {...props} user={user} />}
    />
  );
}

function VisitForm(props) {
  const {
    history,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
    handleSubmit,
    user: {
      animalType
    }
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [growerOptions, setGrowerOptions] = useState([]);
  const [housesOptions, setHousesOptions] = useState([]);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [backConfirmation, setBackConfirmation] = useState(false);

  useEffect(() => {
    if (!values.grower) {
      return;
    }

    async function fetch() {
      try {
        setLoading(true);
        const response = await GetHousesByVendor(
          values.grower.vendorCode,
        );

        if (response.success) {
          setHousesOptions(mapHouseOptions(response.data));
        }
      } catch (_) {
      } finally {
        setLoading(false);
      }
    }

    fetch();
  }, [values.grower]);

  useEffect(() => {
    if (!values.grower) {
      return;
    }

    async function fetch() {
      try {
        setLoading(true);
        const response = await GetLogBookReason();

        if (response.success) {
          setReasonOptions(mapReasonOptions(response.data));
        }
      } catch (_) {
      } finally {
        setLoading(false);
      }
    }

    fetch();
  }, [values.grower]);

  const mapUserOptions = useCallback(values => {
    return (values || []).map(grower => ({
      ...grower,
      label: `${grower.vendorName} - ${grower.vendorCode}`,
    }));
  }, []);

  const mapHouseOptions = useCallback(values => {
    const groupData = chain(values)
      .groupBy('epidemiologicalCore')
      .map((value, epidemiologicalCore) => {
        const house =
          value
            .map(house => house.houseCode)
            .join(', ');
        return {
          epidemiologicalCore,
          houses: value,
          label: t('create-new-event.visit.form.house-option', { epidemiologicalCore, house }),
        };
      })
      .value();
    return groupData;
  }, []);

  const mapReasonOptions = useCallback(values => {
    return (values || []).map(reason => ({
      ...reason,
      label: t(reason.label || reason.name),
    }));
  }, []);

  return (
    <>
      <Breadcrumb
        history={history}
        path={[t('bc.logbook'), t('bc.create-new-event')]}
        backTo={() =>
          isEmpty(values)
            ? history.goBack()
            : setBackConfirmation(true)
        }
      />
      <Top>
        <h2>{t('create-new-event.title')}</h2>
      </Top>

      <Container fit>
        <Title>{t('create-new-event.visit.form.title')}</Title>

        <Separator />

        <Form>
          <AutoCompleteDropdownField2
            field="grower"
            label={t('create-new-event.visit.form.label.name')}
            placeholder={t('create-new-event.visit.form.placeholder.name')}
            onSelect={value => setFieldValue('grower', value)}
            options={growerOptions}
            optionLabel="label"
            selectObject
            values={values}
            errors={errors}
            touched={touched}
            fetchMethod={GetProducerListVisits}
            initialValuesCount={10}
            optionsMapper={mapUserOptions}
            aditionalStyle={{
              marginBottom: animalType !== 'swine ' ? '18px' : 0,
            }}
          />

          <FlexContainer
            style={{
              ...(animalType === 'swine' && {
                marginBottom: '32px'
              })
            }}
            >
              <Field
                className="inner"
                style={{
                  ...(animalType === 'swine' && {
                    display: 'none'
                  })
                }}
              >
                <DropdownField
                  field="house"
                  label={t('create-new-event.visit.form.label.ue')}
                  placeholder={t('create-new-event.visit.form.placeholder.ue')}
                  onSelect={value => setFieldValue('house', value)}
                  removeItemBy="value"
                  disabled={!values['grower']}
                  options={housesOptions}
                  optionLabel="label"
                  values={values}
                  errors={errors}
                  touched={touched}
                  />
              </Field>
            <Field className="inner">
              <DatePickerField
                field="date"
                label={t('create-new-event.visit.form.label.date')}
                placeholder={t('create-new-event.visit.form.placeholder.date')}
                disabled={!values['grower']}
                values={values}
                onChange={value => setFieldValue('date', value)}
                errors={errors}
                touched={touched}
              />
            </Field>
          </FlexContainer>

          <DropdownField
            field="reason"
            label={t('create-new-event.visit.form.label.reason')}
            placeholder={t('create-new-event.visit.form.placeholder.reason')}
            onSelect={value => setFieldValue('reason', value)}
            disabled={!values['grower']}
            options={reasonOptions}
            optionLabel="label"
            values={values}
            errors={errors}
            touched={touched}
          />

          {values['reason'] &&
            values['reason'].name === 'technicalVisit.anotherReason' && (
              <InputField
                type="text"
                field="otherReason"
                label={t('create-new-event.visit.form.label.other-reason')}
                placeholder={t('create-new-event.visit.form.placeholder.other-reason')}
                onChange={event =>
                  setFieldValue('otherReason', event.target.value)
                }
                values={values}
                errors={errors}
                touched={touched}
              />
            )}

          <Separator />

          <BtnGroup className="event__btn-group">
            <Button
              key="submitForm"
              onClick={e => {
                if (animalType === 'swine') {
                  values['house'] = housesOptions[0];
                }

                return handleSubmit(values);
              }}
              className="tertiary"
              disabled={!isEmpty(errors) || !values['grower']}
            >
              {t('global.button.send')}
            </Button>
            <Button
              key="clean"
              className="white"
              onClick={() => resetForm()}
            >
              {t('global.button.clear')}
            </Button>
          </BtnGroup>
        </Form>
      </Container>

      {backConfirmation && (
        <Modal
          warning
          opened={backConfirmation}
          confirm={() => history.goBack()}
          deny={() => setBackConfirmation(false)}
          onCloseModal={() => setBackConfirmation(false)}
          confirmLabel={t('global.button.yes')}
          denyLabel={t('button.global.cancel')}
        >
          <span className="icon icon-exclamation-triangle" />
          <h4>{t('global.word.attention')}</h4>
          <p>
            {t('create-new-event.visit.modal.cancel-progress.description')}
          </p>
        </Modal>
      )}
    </>
  );
}
