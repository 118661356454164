import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SideboxFilter from 'components/SideboxFilter/SideboxFilter';
import NoContentTab from 'components/NoContentTab';
import Icon from 'components/Icon';
import BadgePill from 'components/BadgePill';
import { reduceBy } from 'shared/utils/Helpers';
import { WhiteBoxInsideHeader } from 'components/HeaderBox';
import { getMortalityList, displayHouseContent } from '../utils';
import t from 'components/i18n';
import {
  StyledNextFlockList,
  Container,
  HistoryItem,
} from '../StyledNextFlock.styled';

export default function NextFlockHistory({ mortality }) {
  // new filter
  const [activeFilters, setActiveFilters] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [filtered, setFiltered] = useState([]);

  const newMortalities = Object.assign([], mortality);

  function noContent() {
    return (
      <NoContentTab icon="icon-search">
        <p>{t('next.flock.mortalityHistory.no-content')}</p>
      </NoContentTab>
    );
  }

  const getFilters = () => {
    const filters = [];

    const housesArray = newMortalities.flatMap(item =>
      item.mortalityHouseList.flatMap(e => ({
        txt: t('next.flock.mortalityHistory.filters.house', {
          value: e.houseCode,
        }),
        value: e.houseCode,
      })),
    );

    const agesArray = newMortalities.map(item => ({
      txt: t('next.flock.mortalityHistory.filters.days'),
      value: item.totalDays,
    }));

    const houseItems = reduceBy(housesArray, 'id');

    if ((houseItems || []).length) {
      filters.push(
        {
          label: t('next.flock.mortalityHistory.filters.by-house'),
          items: houseItems
        },
      );
    }
    
    if ((agesArray || []).length) {
      filters.push(
        {
          label: t('next.flock.mortalityHistory.filters.by-age'),
          items: agesArray || [],
        },
      );
    }

    return filters;
  };

  function filterByLabel(param) {
    return activeFilters.filter(e => e.label === param)[0];
  }

  const ageFilter = filterByLabel(
    t('next.flock.mortalityHistory.filters.by-age'),
  );
  const houseFilter = filterByLabel(
    t('next.flock.mortalityHistory.filters.by-house'),
  );

  function getFilteredByDate() {
    const start =
      dateFilter &&
      dateFilter.startDate &&
      moment(dateFilter.startDate);
    const end =
      dateFilter && dateFilter.endDate && moment(dateFilter.endDate);

    if (start || end) {
      return mortality.filter(
        m =>
          moment(m.mortalityDate).isBetween(start, end, 'day') ||
          moment(m.mortalityDate).isSame(start, 'day') ||
          moment(m.mortalityDate).isSame(end, 'day'),
      );
    }

    return mortality;
  }

  function getFilteredContentByAge() {
    if (ageFilter) {
      return getFilteredByDate().filter(
        e => Number(e.totalDays) === Number(ageFilter.id),
      );
    }

    return getFilteredByDate();
  }

  function getFilteredContentByHouse(list, filter) {
    if (filter) {
      return (
        list &&
        list.filter(e => Number(e.houseCode) === Number(filter.id))
      );
    }

    return list;
  }

  function getAllFilters() {
    const filteredByMortality = getFilteredContentByAge().filter(
      e =>
        getFilteredContentByHouse(e.mortalityHouseList, houseFilter)
          .length,
    );

    if (ageFilter) {
      if (houseFilter) {
        if (filteredByMortality.length > 0) {
          return filteredByMortality;
        }
        return [];
      }
      return getFilteredContentByAge();
    }

    if (houseFilter) {
      if (filteredByMortality.length > 0) {
        return filteredByMortality;
      }
    }

    return getFilteredByDate();
  }

  useEffect(() => {
    if (activeFilters.length > 0 || dateFilter) {
      setFiltered(getAllFilters());
    } else {
      setFiltered(mortality);
    }
  }, [activeFilters, dateFilter, getAllFilters, mortality]);

  useEffect(() => {
    setFiltered(mortality);
  }, [mortality]);

  const filters = getFilters();

  return (
    <StyledNextFlockList>
      <Container>
        {Boolean(filters.length) && (
          <WhiteBoxInsideHeader>
            <SideboxFilter
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              filters={getFilters()}
              labelApply={t('global.button.apply')}
            />
          </WhiteBoxInsideHeader>
        )}

        <div>
          {filtered.length > 0 &&
            filtered.map((element, index) => {
              const houses = getFilteredContentByHouse(
                element.mortalityHouseList,
                houseFilter,
              );

              const houseList = getMortalityList(houses);

              return (
                <HistoryItem key={index}>
                  <header className="item-header">
                    <div className="date">
                      <Icon name="icon-calendar" />
                      <p>
                        {moment(element.mortalityDate).format(
                          'DD ̸̸  MM ̸̸  YYYY',
                        )}
                      </p>
                    </div>
                    <BadgePill
                      bgColor="colorGrayDark"
                      minWidth="52px"
                    >
                      {t('next.flock.mortalityHistory.filters.days')}
                    </BadgePill>
                  </header>

                  {houseList.map((item, idx) => {
                    const arrow =
                      item.quantity > item.expected ? 'up' : 'down';

                    return displayHouseContent(item, idx, arrow);
                  })}
                </HistoryItem>
              );
            })}

          {!filtered.length && noContent()}
        </div>
      </Container>
    </StyledNextFlockList>
  );
}
