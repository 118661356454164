// @flow

import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import Modal from 'components/Forms/FormModal/Modal';
import ChangeDateForm from 'containers/NextBRF/Forms/LogBook';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { updateLogBookItem } from './service';
import t from 'components/i18n';

const getValidateSchema = (date, data) => {
  const validateSchema = () => {
    const startOfWeek = moment(data.originalDate)
      .subtract(1, 'weeks');
    const visitLimit = moment(data.originalDate)
      .add(1, 'weeks')
      .endOf('week');

    return yup.object().shape({
      newDate: yup
        .date()
        .min(
          startOfWeek,
          ({ min }) => t('global.validation.min-date'),
        )
        .max(
          visitLimit,
          ({ max }) => t('global.validation.max-date'),
        )
        .required(t('global.status.required-field')),
    });
  };

  return validateSchema;
};

export default function EditLogBook(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const {
    isLoading,
    selected,
    isValidating,
    date,
    data,
    isRandom
  } = props;

  const initialValues = {
    newDate: date,
  };
  const formData = values => ({
    id: props.id,
    orientation: props.orientation,
    attachments: [],
    isRandom: isRandom,
    ...(
      isRandom
        ? {
          foreseenDate: moment(new Date(values.newDate)),
        }
        : {
          date: moment(new Date(values.newDate)),
        }
    ),
  });

  function submitForm(values, { setSubmitting }) {
    async function sendData(val) {
      setSubmitting(true);
      try {
        
        const response = await updateLogBookItem(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }
    sendData(formData(values));
  }

  useEffect(() => {
    if (success) {
      props.getLogBookList();
      props.getDetails(props.id);
      props.setDisplayCalendar(false);
    }
  }, [props, success]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate(
          getValidateSchema(date, data),
        )}
        onSubmit={submitForm}
        render={formikProps => (
          <ChangeDateForm
            {...formikProps}
            isValidating={isValidating}
            message={errorMessage}
            isLoading={isLoading}
            selected={selected}
            success={success}
            setDisplayCalendar={props.setDisplayCalendar}
            {...props}
          />
        )}
      />
      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>
          {success
            ? t('global.validation.record-changes-saved"')
            : t('global.validation.record-error')}
        </p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
