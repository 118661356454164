import axios from 'axios';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { request } from 'shared/utils/client';
import { fixFileName } from 'shared/utils/Helpers/fixFileName';

export async function uploadFile(payload) {
  const fd = new FormData();
  fd.append('file', payload, fixFileName(payload));

  const response = await request(`${API_URL}/Blob/UploadFile`, {
    headers: {
      'Content-Type': undefined,
      Authorization: null,
    },
    method: 'POST',
    payload: fd,
  });
  return response.data;
}

const downloadFile = ({fileName, blobContainerName, token}) => {
  return new Promise((resolve, reject) => {
    return axios
      .get(
        '/domain/v1/api/Blob/DownloadFile?fileName=' +
          fileName +
          '&blobContainerName=' +
          blobContainerName +
          '&token=' +
          token +
          '&client_id=' +
          KONG_CLIENT_ID
      )
      .then(({data: result}) => {
        const {success, message, data} = result;
        if (success) {
          return resolve(data);
        }
        return reject(message);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

export async function deleteFile(file) {
  const response = await request(
    `${API_URL}/Blob/DeleteFile?fileName=${file}`,
    {
      method: 'DELETE',
    },
  );
  return response.data;
}

export {downloadFile};