import React, { useState } from 'react';
import Icon from 'components/Icon';
import { HistoryList, ToggleHistory } from '../VendorSchedule.styled';
import t from 'components/i18n';

export default function History(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <HistoryList className="history-list" isOpen={isOpen}>
        {props.children}
      </HistoryList>

      <div className={`feed-show-history ${isOpen ? 'open' : ''}`}>
        <ToggleHistory
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className="unstyled-button"
        >
          <Icon
            className="feed-holder-icon"
            name={`icon-chevron-${isOpen ? 'up' : 'down'}`}
          />
          <span className="mini-heading">
            {isOpen
              ? t('next.vendorSchedule.history.close')
              : t('next.vendorSchedule.history.open')}
          </span>
        </ToggleHistory>
      </div>
    </>
  );
}
