// @flow

import React from 'react';
import styled from 'styled-components';
import {
  colorClear,
  colorGrayDarkest,
  breakpointTablet,
} from 'components/GlobalStyles/variables';

const Header = styled.header`
  background-color: ${colorGrayDarkest};
  height: 64px;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .is-open & {
    justify-content: flex-end;
  }

  @media (min-width: ${breakpointTablet}px) {
    background-color: ${colorClear};
  }
`;

export default function SidebarHeader({ children, ...rest }) {
  return <Header {...rest}>{children}</Header>;
}
