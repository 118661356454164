import styled from 'styled-components';
import {
  colorGrayLighter,
  breakpointTablet,
  // breakpointDesktop,
} from 'components/GlobalStyles/variables';

export const HamburgerMenu = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  cursor: pointer;

  @media (min-width: ${breakpointTablet}px) {
    display: none;
  }

  .hamburger-item {
    position: relative;
    background-color: ${colorGrayLighter}
    height: 2px;
    margin-bottom: 3px;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    bottom: 0;
    left: 0;
    right: 0;

    &.third {
      transition-delay: .4s;
    }
  }

  &.is-open {
    .hamburger-item {
      position: absolute;

      &.first {
        transform: rotate(45deg);
        bottom: 0;
        left: 0;
      }

      &.second {
        transform: rotate(-45deg);
        bottom: 0;
        right: 0;
      }

      &.third {
        opacity: 0;
        height: 0;
        transition-delay: .1s;
      }
    }
  }
`;
