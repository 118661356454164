import styled from 'styled-components';
import {
  colorDanger,
  colorSuccess,
  colorInfo,
  colorWarning,
  colorClear,
  colorPrimary,
  colorTertiary,
  colorGrayLighter,
  colorGrayLightest,
  colorGrayDarker,
  DEFAULT_SIZE,
  boxShadow,
  breakpointTablet,
  fontFamilyHeading,
  fontFamilyText,
  colorGrayDarkest,
} from 'components/GlobalStyles/variables';
import { AlertWrapper } from 'components/Alert/Alert.styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  overflow: scroll;

  .container-detail {
    height: 3px;
    width: 50px;
    margin: 30px 0 6px;

    background-color: ${props => {
      if ('danger' in props) {
        return colorDanger;
      }
      if ('warning' in props) {
        return colorWarning;
      }
      if ('success' in props) {
        return colorSuccess;
      }
      if ('info' in props) {
        return colorInfo;
      }
      if ('primary' in props) {
        return colorPrimary;
      }
      if ('tertiary' in props) {
        return colorTertiary;
      }
      if ('default' in props) {
        return colorGrayLighter;
      }

      return colorPrimary;
    }};
  }
`;

export const Container = styled.div`
  position: relative;
  padding: ${DEFAULT_SIZE * 2}px;
  max-width: 460px;
  width: 100%;

  p.info {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colorGrayDarker};
    margin: 0 0 36px;
  }

  form {
    margin-bottom: 100px;
  }

  ${AlertWrapper} {
    position: fixed;
    bottom: 30px;
    right: 30px;
  }

  .recaptchaWrapper {
    margin-top: 16px;
  }
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;

  &.top {
    margin-bottom: 0;
  }
`;

export const Layer = styled.div`
  position: relative;
`;

export const CheckboxField = styled.div`
  position: relative;

  label.block-label {
    font-family: ${fontFamilyHeading};
    display: flex;
    position: relative;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    color: ${colorGrayDarkest};
    height: 72px;
    background: ${colorClear};
    border: 1px solid ${colorGrayLightest};
    box-sizing: border-box;
    border-radius: 12px;
    line-height: 22px;
    letter-spacing: 0.15px;
    text-transform: none;
    z-index: 10;
    transition: 0.2s background-color ease-in;
    cursor: pointer;
    margin: 0 0 24px 0;
    width: 100%;
  }

  .icon {
    padding: 0 24px 0 16px;
    font-size: 20px;
    color: ${colorGrayLighter};
  }

  input[type='checkbox'] {
    z-index: 0;
    opacity: 0;
    display: none;

    &:checked {
      + label {
        color: ${colorClear};
        background-color: ${colorPrimary};
        border-color: ${colorPrimary};

        .icon {
          color: ${colorClear};
        }
      }
    }
  }
`;

export const ConclusionBox = styled.div`
  background: #ffffff;
  width: calc(100% + 32px);
  border: 1px solid ${colorGrayLightest};
  border-radius: 8px;
  padding: 32px 16px;
  margin: 90px 0 0 0;
  text-align: center;
  box-shadow: ${boxShadow};

  @media (max-width: ${breakpointTablet}px) {
    width: 100%;
  }

  .icon {
    font-size: 74px;
    color: ${colorPrimary};
  }

  p {
    color: ${colorGrayDarker};
    font-size: 14px;
    line-height: 20px;

    &.bold {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
