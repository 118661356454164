import React, { useContext } from 'react';
import Icon from 'components/Icon';
import BadgePill from 'components/BadgePill';
import { ThemeContext } from 'styled-components';
import {
  StyledIconButton,
  IconWrapper,
  SecondaryIconWrapper,
} from './IconButton.styled';

export default function IconButton(props) {
  const themeContext = useContext(ThemeContext);
  const {
    primaryIcon,
    secondaryIcon,
    caption,
    bgColor = themeContext.styles.palette.colorPrimary,
    action,
    disabled,
    soon,
    badgeColor,
  } = props;

  return (
    <StyledIconButton
      onClick={e => (action ? action(e) : false)}
      bg={bgColor}
      disabled={disabled}
    >
      {soon && (
        <BadgePill bgColor={badgeColor} className="soon">
          em breve
        </BadgePill>
      )}
      <div className="wrapper">
        <IconWrapper bg={themeContext.styles.palette[bgColor]}>
          <Icon name={`icon ${primaryIcon}`} />
        </IconWrapper>
        {secondaryIcon && (
          <SecondaryIconWrapper>
            <Icon name={`icon ${secondaryIcon}`} />
          </SecondaryIconWrapper>
        )}
        <p>{caption}</p>
      </div>
    </StyledIconButton>
  );
}
