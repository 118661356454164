import React from 'react';
import {
  Dropdown,
  Menu,
  Item,
  Trigger,
} from '@zendeskgarden/react-dropdowns';
import { Flex } from 'styled-minimal';
import Icon from 'components/Icon';
import { FooterOptions } from './FooterMenu.styled';

export default function FooterMenu(props) {
  const { placement, options } = props;
  return (
    <FooterOptions>
      <Flex justifyContent="flex-end">
        <Dropdown>
          <Trigger>
            <button
              onClick={() => {}}
              className="notification-open-actions"
              type="button"
            >
              <Icon name="icon-ellipsis-v" />
            </button>
          </Trigger>
          <Menu className="footer-menu" placement={placement}>
            {options.map(
              (item, index) =>
                item.visible && (
                  <Item
                    className="item"
                    value={item.label}
                    key={index}
                    onClick={() => item.action()}
                  >
                    <span className={`icon icon-${item.icon}`} />
                    {item.label}
                  </Item>
                ),
            )}
          </Menu>
        </Dropdown>
      </Flex>
    </FooterOptions>
  );
}
