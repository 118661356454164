import React from 'react';
import t from 'components/i18n';
import { INSURANCE } from 'config/routes';
import NoContentTab from 'components/NoContentTab';
import { Subheading } from './InsurancePolicy.styled';

export default function FirstPage({
  setEditForm,
  editForm,
  setUpdateItems,
  history,
}) {
  return (
    <div className="animated fadeIn">
      <Subheading>{t('insurancePolicy.title')}</Subheading>
      <p>{t('insurancePolicy.description')}</p>
      <NoContentTab
        icon="icon-shield"
        buttonLabel={t('insurancePolicy.buttonPrimary')}
        onButtonClick={() => {
          history.push(`${INSURANCE}/new`);
          setUpdateItems(false);
        }}
      >
        <p>
          {t('insurancePolicy.empty.title')}
          <br />
          <span className="lighter">
            {t('insurancePolicy.empty.subtitle')}
          </span>
        </p>
      </NoContentTab>
    </div>
  );
}
