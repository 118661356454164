import t from 'components/i18n';
import React from 'react';

export const LogBookStatus = [
  {
    status: 0,
    label: t('next.logBookStatus.option.awaiting-realization.label'),
    buttonLabel: t('next.logBookStatus.option.awaiting-realization.buttonLabel'),
    color: 'colorPrimary',
  },
  {
    status: 1,
    label: t('next.logBookStatus.option.visit-carried-out.label'),
    buttonLabel: t('next.logBookStatus.option.visit-carried-out.buttonLabel'),
    color: 'colorSuccess',
    icon: 'icon-check-circle',
  },
  {
    status: 2,
    label: t('next.logBookStatus.option.visit-not-carried-out.label'),
    buttonLabel: t('next.logBookStatus.option.visit-not-carried-out.buttonLabel'),
    color: 'colorDanger',
    icon: 'icon-close-circle',
  },
  {
    status: 3,
    label: t('next.logBookStatus.option.justified-unrealized-visit.label'),
    buttonLabel: t('next.logBookStatus.option.justified-unrealized-visit.buttonLabel'),
    color: 'colorDanger',
    icon: 'icon-close-circle',
  },
  {
    status: 4,
    label: t('next.logBookStatus.option.delayed-visit.label'),
    buttonLabel: t('next.logBookStatus.option.delayed-visit.buttonLabel'),
    color: 'colorSecondary',
    icon: 'icon-exclamation-circle',
  },
];

export function getStatus(item) {
  return LogBookStatus.find(e => e.status === item);
}

export function getHousesContent(houses, isParagraph, key) {
  const wrappedContent = (
    <>
      {houses.length === 1 ? t('next.logBook.house') : t('next.logBook.houses')}
      {houses.map((house, idx) => (
        <>
          <span>{house[key] || house}</span>
          {idx !== houses.length - 1 && ', '}
        </>
      ))}
    </>
  );
  return isParagraph ? <p>{wrappedContent}</p> : wrappedContent;
}
