import t from 'components/i18n';
import { reduceBy } from 'shared/utils/Helpers';
import { statusMapper } from 'utils/mapper';

export default function getFeedFilters(dataItems, flockId) {
  const newD = Object.assign([], dataItems);
  let statusOptions = [];
  let feedTypes = [];
  let flocks = [];

  if (newD.length > 0) {
    statusOptions = reduceBy(newD, 'status').map((e, i) => {
      return { txt: e.status, id: i, name: t(statusMapper(e.status)) };
    });

    feedTypes = reduceBy(newD, 'feedType').map((e, i) => {
      return { txt: e.feedType, id: i };
    });

    if (!flockId) {
      flocks = reduceBy(newD, 'flock').map((e, i) => {
        return { txt: e.flock, id: e.flockId };
      });
    }
  }

  const filtersData = [
    {
      label: t('next.vendorSchedule.filter.status'),
      items: statusOptions.length > 0 ? statusOptions : [],
    },
    {
      label: t('next.vendorSchedule.filter.feed'),
      items: feedTypes.length > 0 ? feedTypes : [],
    },
    {
      label: t('next.vendorSchedule.filter.flock'),
      items: flocks.length > 0 ? flocks : [],
    },
  ];

  if (flockId) {
    filtersData.pop();
  }

  return filtersData;
}
