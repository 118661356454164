import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/lib/constants';

import { HIDE_ALERT, SHOW_ALERT } from 'components/Alert/types';
import { SWITCH_MENU } from './types';

export const initialState = {
  alerts: [],
  menuIsOpen: false,
};

export default {
  app: handleActions(
    {
      [REHYDRATE]: state =>
        immutable(state, {
          alerts: { $set: [] },
        }),

      [SWITCH_MENU]: (state, { payload }) =>
        immutable(state, {
          menuIsOpen: { $set: payload.menuIsOpen },
        }),

      [HIDE_ALERT]: (state, { payload: { id } }) => {
        const alerts = state.alerts.filter(d => d.id !== id);

        return immutable(state, {
          alerts: { $set: alerts },
        });
      },
      [SHOW_ALERT]: (state, { payload }) =>
        immutable(state, {
          alerts: { $push: [payload] },
        }),
    },
    initialState,
  ),
};
