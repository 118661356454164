import React, { useState, useMemo } from 'react';
import t from 'components/i18n';
import ProfileMenu from '../ProfileMenu';
import TermsOfUse from './TermsOfUse';
import Policy from './Policy';
import PolicyExcerpt from './PolicyExcerpt';

export default function LegalInformation({ type, history, user }) {
  const [activeItem, setActiveItem] = useState(1);

  // const permission =
  //   user.profile === 'Administrador' &&
  //   (user.type !== 'F' && user.type !== 'E');

  const menuItems = useMemo(
    () => [
      {
        name: t('menu.profile.termsOfUse.title'),
        icon: 'icon-file',
        key: 1,
        // visible: permission,
        visible: true,
      },
      {
        name: t('menu.profile.privacyPolicy.title'),
        icon: 'icon-file',
        key: 2,
        // visible: !user.countryCodes.includes('TR'),
        visible: true,
      },
      {
        name: t('menu.profile.sectionLegalInformation.buttonPrivacyPolicySummary'),
        icon: 'icon-file',
        key: 3,
        // visible: !user.countryCodes.includes('TR'),
        visible: true,
      },
    ],
    [user],
  );

  return (
    <>
      <div className="secondary-wrapper">
        <ProfileMenu
          menuItems={menuItems}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      </div>

      {activeItem === 1 && <TermsOfUse history={history} />}

      {activeItem === 2 && <Policy history={history} />}

      {activeItem === 3 && <PolicyExcerpt history={history} />}
    </>
  );
}
