import { Row } from '@zendeskgarden/react-grid';
import {
  boxShadow,
  breakpointDesktop,
  breakpointTablet,
  colorClear,
  colorGrayDarker,
  colorGrayDarkest,
  colorGrayLight,
  colorGrayLighter,
  colorGrayLightest,
  colorGrayUltraLight,
  colorPrimary,
  DEFAULT_SIZE,
  fontFamilyText,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';
import { ListItem as li } from 'components/List';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${DEFAULT_SIZE * 2}px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;  

  @media (min-width: ${breakpointTablet}px) {
    flex-direction: row;
  }

  .mini-heading {
    font-family: ${fontFamilyTextBold};
    font-style: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }

  .details {
    width: 100%;
    max-width: 652px;
    /* background-color: ${colorClear}; */

    .details-container {
      display: flex;
      flex-direction: column;
    }

    .details-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .icon {
        font-size: 22px;
        color: ${colorGrayLighter};
      }
    }

    .details-heading {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.15px;
    }

    .details-top {
      display: flex;
      flex-direction: row;

      .icon-holder {
        margin-right: ${DEFAULT_SIZE * 2}px;
        align-self: flex-start;
      }

      .details-top-item {
        margin-right: ${DEFAULT_SIZE * 4}px;
      }

      .details-top-heading {
        color: ${colorGrayLighter};
        margin-bottom: inherit;
      }

      .details-top-label {
        font-family: ${fontFamilyText};
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: ${colorGrayDarker};
      }
    }

    .details-content {
      
      .details-content-heading {
        margin-bottom: ${DEFAULT_SIZE * 4}px;
      }
    }
  }

  .menu {
    min-width: 100%;
    margin-right: ${DEFAULT_SIZE * 2}px;

    @media (min-width: ${breakpointTablet}px) {
      width: auto;
      min-width: 315px;
    }

    @media (min-width: ${breakpointDesktop}px) {
      width: 100%;
      max-width: 376px;
    }
  }
  
`;

export const ContainerFixedListItems = styled(Container)`
  .cadec-list-item {
    &::before {
      bottom: -10px;
      left: 0;
    }
  }
`;

export const ListItem = styled(li)`
  position: relative;
  width: 100%;
  padding: 12px 0 0;

  &.clickable {
    cursor: pointer;
  }

  &.highlight {
    border: 1px solid ${colorGrayLightest};
    border-radius: 8px;
    padding: 16px;
    height: auto !important;
    overflow: hidden;
    margin: 16px 0;
    box-shadow: ${boxShadow};

    .text {
      width: 100%;

      hr {
        margin: 16px 0 10px;
      }
    }

    .small.inline-block {
      display: inline-block;
      font-size: 14px;
    }

    &:before {
      display: none;
    }

    a {
      font-size: 14px;
      margin-right: 32px;

      @media (max-width: ${breakpointTablet}px) {
        display: block;
        margin: 8px 0 0;
      }
    }
  }

  h3.list-item__title {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    font-family: ${fontFamilyTextBold};
    letter-spacing: 0.15px;
  }

  .list-item__subtitle {
    font-size: 12px;
    line-height: 20px;
    display: block;
    color: ${colorGrayLighter};
  }

  p {
    &.small {
      display: inline-block;
      font-size: 12px;
      line-height: 20px;
      margin: 0;
      color: ${colorGrayLighter};

      &:first-of-type {
        &:before {
          display: none;
        }
      }

      &:before {
        content: '';
        position: relative;
        top: -2px;
        width: 5px;
        height: 5px;
        display: inline-block;
        background-color: ${colorGrayLight};
        border-radius: 50%;
        margin: 0 6px 0;
      }
    }
  }

  .list-item-heading {
    font-size: 14px;
    letter-spacing: 0.75px;

    a {
      color: ${colorGrayDarkest};
    }
  }

  .cadec-list & {
    display: block;
    height: auto;
    width: 100%;
    padding: 12px 0;
    margin: 0;
    cursor: pointer;

    &:before {
      width: calc(100% - 40px);
      left: 56px;
    }

    ${Row} {
      height: 100%;
      align-items: center;
    }
  }
`;

export const Menu = styled.div`
  background-color: ${colorClear};
  padding: ${DEFAULT_SIZE * 2}px;
  border-radius: ${DEFAULT_SIZE}px;
  overflow: hidden;
  box-shadow: ${boxShadow};
  width: 100%;
  margin-bottom: 20px;

  .menu-icon {
    font-size: 24px;
    color: ${colorGrayLighter};
    align-items: center;
    display: flex;
    margin-right: 32px;

    &:after {
      content: '';
      transition: 0.2s ease-in background-color;
      background-color: transparent;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: -8px;
      z-index: 0;
    }
  }

  .list-item-heading {
    color: ${colorGrayDarkest};
  }

  .menu-heading {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }

  .active {
    .menu-icon,
    .chevron,
    .list-item-heading {
      color: ${colorGrayDarkest} !important;
    }

    .menu-icon {
      :before {
        z-index: 1;
      }
      :after {
        background-color: ${colorGrayUltraLight};
      }
    }
  }

  .menu-group {
    margin-bottom: ${DEFAULT_SIZE * 2}px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 56px;

    &:last-child {
      ${ListItem} {
        &:before {
          height: 0;
        }
      }
    }
  }

  @media (min-width: ${breakpointTablet}px) {
    width: auto;
  }

  @media (min-width: ${breakpointDesktop}px) {
    width: 100%;
    max-width: 376px;
  }
`;

export const TabletMenu = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: ${colorClear};
  border-radius: ${DEFAULT_SIZE}px;
  height: 72px;
  align-items: center;
  box-shadow: ${boxShadow};
  margin-bottom: ${DEFAULT_SIZE * 3}px;
  width: 100%;

  .tablet-menu-icon {
    font-size: 24px;
    color: ${colorGrayLighter};
    margin-bottom: ${DEFAULT_SIZE}px;
  }

  .tablet-menu-label {
    letter-spacing: 0.75px;
    font-size: 12px;
    line-height: 16px;
    color: ${colorGrayLighter};
  }

  .tablet-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;

    &:last-child {
      width: 140px;
    }

    &.active {
      .tablet-menu-label {
        color: ${colorGrayDarker};
      }
      .tablet-menu-icon {
        color: ${colorGrayDarker};
      }
    }
  }
`;

export const CadecMobileWrapper = styled.div`
  background-color: ${colorClear};
  padding: ${DEFAULT_SIZE * 2}px;
  border-radius: 12px;

  .details-header {
    // display: none !important;
  }
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;

  &.top {
    margin-bottom: 0;
  }
`;

export const IconPlaceholder = styled.div`
  display: flex;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${colorPrimary};
  position: relative;
  padding: 0;

  &.top {
    align-self: flex-start;
  }

  &.white {
    background-color: ${colorClear};
    .icon {
      color: ${colorPrimary};
    }
  }

  .icon {
    color: ${colorClear};
    font-size: 24px;
  }
`;

export const TopMenuStyled = styled.div`
  width: 100%;

  .label {
    min-width: 160px;
    justify-content: center;

    @media (max-width: ${breakpointTablet}px) {
      min-width: 90px;
    }
  }
`;
