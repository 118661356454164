import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function contactUs(payload) {
  const response = await request(`${API_URL}/Contact/ContactUs`, {
    method: 'POST',
    payload,
  });
  return response;
}

export async function getSubjects(payload) {
  const response = await request(`${API_URL}/Contact/GetSubjects`, {
    method: 'GET',
    payload,
  });
  return response;
}
