import React from 'react';
import moment from 'moment';
import { Row, Col } from '@zendeskgarden/react-grid';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { List, ListItem } from 'components/List/List';

// Styles
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import t from 'components/i18n';

export default function DeceasedConfirmation({
  house,
  selectedKey,
  selectedDeceased,
  deceasedDiscardedDate,
  swineDeceasedDiscarded,
  setSendToConfirmation,
  setDisplayExcludeModal,
  origin,
  isValid,
  isSubmitting,
  onSubmit,
  backToForm,
  goTo,
}) {
  const listArray = [];
  const deceased =
    Object.keys(selectedDeceased).length > 0
      ? selectedDeceased.swineDeceasedDiscarded
      : swineDeceasedDiscarded;

  if (Object.keys(selectedDeceased).length > 0) {
    listArray.push(
      {
        description:
          (selectedDeceased.house && selectedDeceased.house.label) ||
          t('form.next.addDeceasedForm.swineDeceasedDiscarded.confirmation.house', { value: selectedDeceased.house }),
        icon: 'pin',
      },
      {
        description: moment(
          selectedDeceased.deceasedDiscardedDate,
        ).format('DD ̸̸  MM ̸̸  YYYY'),
        icon: 'calendar',
      },
    );
  } else {
    listArray.push(
      { description: house && house[0].label, icon: 'pin' },
      {
        description: moment(deceasedDiscardedDate).format(
          'DD ̸̸  MM ̸̸  YYYY',
        ),
        icon: 'calendar',
      },
      {
        description: t('form.next.addDeceasedForm.swineDeceasedDiscarded.confirmation.origin', { value: origin }),
        icon: 'tag',
      },
    );
  }

  function displayTypesObjects(
    mortality,
    mOrigin,
    maleQuantity,
    femaleQuantity,
  ) {
    return [
      {
        description: mortality.description || mortality,
        icon: 'pig-alt',
      },
      {
        description: t('form.next.addDeceasedForm.swineDeceasedDiscarded.confirmation.origin', { value: mOrigin }),
        icon: false,
      },
      {
        description: t("form.next.addDeceasedForm.swineDeceasedDiscarded.confirmation.femaleQuantity", { value: femaleQuantity }),
        icon: false
      },
      {
        description: t("form.next.addDeceasedForm.swineDeceasedDiscarded.confirmation.maleQuantity", { value: maleQuantity }),
        icon: false
      },
    ];
  }

  function displayListItem(description, icon, index) {
    return (
      <ListItem
        key={index}
        className="confirm-list__item animated fadeIn"
      >
        <div className="icon-wrapper">
          <span className={`icon icon-${icon}`} />
        </div>
        <div className="list-item-content">
          <div className="list-item-description">{description}</div>
        </div>
      </ListItem>
    );
  }

  return (
    <div className="confirmation">
      <p className="content-heading">{t('global.informed-data.title')}</p>

      <List className="confirm-list">
        {listArray.map((item, index) =>
          displayListItem(item.description, item.icon, index),
        )}

        {deceased.map((item, index) => {
          return displayTypesObjects(
            item.mortality ||
              item.mortalityTypeId ||
              item.discardedTypeId,
            item.origin,
            item.maleQuantity,
            item.femaleQuantity,
          ).map((i, idx) =>
            displayListItem(i.description, i.icon, idx),
          );
        })}
      </List>

      <Separator />

      {/* \ Botões */}
      {(selectedKey === 'editDeceased' ||
        selectedKey === 'addDeceased') && (
        <BtnGroup className="button-group--bottom">
          {selectedDeceased.isEditable ? (
            <>
              <Button
                key="submit"
                onClick={() => onSubmit()}
                className="tertiary"
                disabled={isSubmitting || !isValid}
              >
                {t("global.button.save")}
              </Button>
              <Button
                key="edit"
                onClick={() => backToForm()}
                className="white"
              >
                {t("global.button.edit")}
              </Button>
            </>
          ) : (
            <Button
              key="back"
              onClick={() => goTo('deceasedSummary')}
              disabled={isSubmitting}
            >
              {t('global.button.back')}
            </Button>
          )}
        </BtnGroup>
      )}

      {selectedKey === 'deceasedConfirmation' && (
        <Row>
          {selectedDeceased.isEditable ? (
            <Col sm={6}>
              <Button
                key="delete"
                className="white"
                onClick={() => setDisplayExcludeModal(true)}
                disabled={isSubmitting}
                style={{ width: 138 }}
              >
                {t('global.button.delete')}
              </Button>
            </Col>
          ) : (
            <BtnGroup
              className="button-group--bottom"
              style={{ width: '100%' }}
            >
              <Button
                onClick={() => goTo('deceasedSummary')}
                key="back"
                className="white"
              >
                {t('global.button.back')}
              </Button>
            </BtnGroup>
          )}
          {selectedDeceased.isEditable && (
            <Col sm={6}>
              <BtnGroup className="button-group--bottom">
                <Button
                  key="edit"
                  onClick={() => {
                    goTo('editDeceased');
                    setSendToConfirmation(false);
                  }}
                  className="tertiary"
                >
                  {t("global.button.edit")}
                </Button>
                <Button
                  onClick={() => {
                    goTo('deceasedSummary');
                    setSendToConfirmation(false);
                  }}
                  key="back"
                  className="white"
                >
                  {t('global.button.back')}
                </Button>
              </BtnGroup>
            </Col>
          )}
        </Row>
      )}
      {/* \ Botões */}
    </div>
  );
}
