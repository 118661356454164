import styled from 'styled-components';
import { colorGrayLighter } from 'components/GlobalStyles/variables';

// import Icon from 'components/Icon';

export const EyeIcon = styled.span`
  cursor: pointer;
  font-size: 16px;
  color: ${colorGrayLighter};
`;

export const Info = styled.div`
  font-size: 14px;
  margin-top: 12px;
`;
