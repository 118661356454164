import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Row, Col } from '@zendeskgarden/react-grid';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { Button } from '@zendeskgarden/react-buttons';
import Container from 'components/Container';
import Image from 'components/Image';
import { Item } from './Orientation.styled';
import t from 'components/i18n';

export default function OpenOrientationCard({ data, back, history }) {
  const user = useSelector(state => state.user);

  return (
    <Container hideDetail className="animated">
      <Item className="flex">
        <span className="label">{t('next.orientation.orientationCard.visit.motivation')}</span>{' '}
        <span>{data.visitType}</span>
      </Item>

      <Item className="flex">
        <span className="label">{t('next.orientation.orientationCard.visit.date-hour')}</span>{' '}
        <div
          className="flex"
          style={{ flexDirection: 'column', textAlign: 'right' }}
        >
          <span className="margin">
            {moment(data.visitDate).format('DD ̸̸  MM ̸̸  YYYY')}
          </span>
          <span className="small">
            {moment(data.visitDate).format('HH:mm')}
          </span>
        </div>
      </Item>

      <Item>
        <span className="label">{t('next.orientation.orientationCard.title')}</span>{' '}
        <p>{data.orientation}</p>
      </Item>

      {data.blobNameList && data.blobNameList.length > 0 && (
        <Item>
          <span className="label">{t("next.orientation.orientationCard.pic")}</span>
          <br />
          <br />
          <Row>
            {data.blobNameList.map((item, index) => (
              <Col key={index} sm={4}>
                <a
                  href={`${API_URL}/Blob/Download?fileName=${item}&blobContainerName=logbookattachment&token=${user.token}&client_id=${KONG_CLIENT_ID}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    api={API_URL}
                    fileName={item}
                    blobContainer="logbookattachment"
                    token={user.token}
                  />
                </a>
              </Col>
            ))}
          </Row>
        </Item>
      )}

      <Item className="flex right">
        <Button className="tertiary" onClick={() => back()}>
          {t('global.button.back')}
        </Button>
      </Item>
    </Container>
  );
}
