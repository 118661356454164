// @flow

import React, { useState, useEffect, useMemo } from 'react';

// components
import logger from 'shared/utils/logger';
import LoadingHolder from 'components/LoadingHolder';
import InternalMenu from 'components/InternalMenu';
import { useSelector } from 'react-redux';
import {
  getFalResume,
  getOrientations,
  getMedications,
  } from 'containers/AgroBRF/FlockList/service';
import { API_REPORT_URL } from 'config/constants';
import { Container } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import ColoredCard from 'components/ColoredCard';
import { isUndefined } from 'utils';
import RecordContent from './RecordContent';
import OrientationList from './OrientationList';
import MedicationList from './MedicationList';
import t from 'components/i18n';
import { KONG_CLIENT_ID } from 'config/constants';

export default function Record(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [orientations, setOrientations] = useState([]);
  const [medications, setMedications] = useState([]);
  const [selectedKey, setSelectedKey] = useState('resume');
  const [activeMenu, setActiveMenu] = useState('houses');
  const [updateItems, setUpdateItems] = useState(false);

  const flockID = props.match.params.id;
  const type = props.match.params.type;

  const user = useSelector(state => state.user);

  const userCountryCode =
    !isUndefined(user.countryCodes) && user.countryCodes[0];

  const medicationTitle =
    props.flockType === 'turkey'
      ? t('flocks.newFal.medications.title.equalsTurkey')
      : t('flocks.newFal.houses.tabMenu.item.three');

  const hasFinished =
    records.filter(e => e.status === 'Concluído').length > 0;

  const menuList = useMemo(
    () => [
      {
        label: t('flocks.newFal.houses.tabMenu.item.one'),
        action: () => setActiveMenu('houses'),
      },
      {
        label: t('flocks.newFal.houses.tabMenu.item.two'),
        action: () => setActiveMenu('orientations'),
      },
      {
        label: medicationTitle,
        action: () => setActiveMenu('medications'),
      },
    ],
    [medicationTitle],
  );

  /*
   * Get FAL content
   */
  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      try {
        const resume = await getFalResume(flockID);
        const orientationList = await getOrientations(flockID);
        const medicationList = await getMedications(flockID);
        setRecords(resume);
        setOrientations(orientationList);
        setMedications(medicationList && medicationList.items);
      } catch (e) {
        logger('Error on getWeights', e);
      }

      setIsLoading(false);
    }

    fetch();
  }, [flockID]);

  useEffect(() => {
    if (type && type === 'houses') {
      setActiveMenu('houses');
    }

    if (type && type === 'orientations') {
      setActiveMenu('orientations');
    }

    if (type && type === 'medications') {
      setActiveMenu('medications');
    }
  }, [type]);

  const listProps = useMemo(
    () => ({
      responsive: props.responsive,
      selectedKey: selectedKey,
      history: props.history,
      setSelectedKey: setSelectedKey,
      updateItems: updateItems,
    }),
    [props.history, props.responsive, selectedKey, updateItems],
  );

  return (
    <Container className="flock-container flock-container__record">
      <div className="secondary-wrapper">
        <InternalMenu list={menuList} />

        {records && records.length > 0 && (
          <ColoredCard
            bgColor={hasFinished ? 'colorSuccess' : 'colorPrimary'}
            icon={hasFinished ? 'icon icon-check' : 'icon icon-clock'}
            title={
              hasFinished
                ? t('flocks.newFal.houses.cardDownload.status.done')
                : t('flocks.newFal.houses.cardDownload.status.inProgress')
            }
            text={
              hasFinished
                ? t('flocks.newFal.houses.cardDownload.description.hasFinished')
                : t('flocks.newFal.houses.cardDownload.description')
            }
            buttonLabel={t('flocks.newFal.houses.cardDownload.buttonPrimary')}
            buttonAction={`${API_REPORT_URL}/Fal?flockId=${flockID}&forceDownload=true&access_token=${user.token}&country=${userCountryCode}&client_id=${KONG_CLIENT_ID}`}
            download
          />
        )}
      </div>

      <div className="main-wrapper">
        <LoadingHolder isLoading={isLoading} />

        {!isLoading && activeMenu === 'houses' && (
          <RecordContent
            {...listProps}
            isLoading={isLoading}
            title={t('flocks.newFal.houses.title')}
            arrayContent={records}
          />
        )}

        {!isLoading && activeMenu === 'orientations' && (
          <OrientationList
            {...listProps}
            isLoading={isLoading}
            title={t('flocks.newFal.orientations.title')}
            arrayContent={orientations}
          />
        )}

        {!isLoading && activeMenu === 'medications' && (
          <MedicationList
            {...listProps}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            title={medicationTitle}
            arrayContent={medications}
          />
        )}
      </div>
    </Container>
  );
}
