import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { CHECKLIST_DETAILS, VISIT_CIRCUIT_LIST } from 'config/routes';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import Card from 'components/Card';
import Container from 'components/Container';
import NextFlockListMenu from 'containers/NextBRF/NextFlock/NextFlockListMenu';
// service
import { getLogBookChecklists, getChecklists } from './service';
// styles
import {
  ChecklistsContainer2,
  NewChecklistsContainer,
} from './Checklists.styled';
import { useChecklist } from './hooks';
import t from 'components/i18n';

export default function Checklists({
  data: vendor,
  history,
  isGtMobile,
  isMobile,
  isTablet,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [logbookList, setLogbookList] = useState([]);
  const [checkLists, setCheckLists] = useState([]);
  const [selectedKey, setSelectedKey] = useState('opened');

  const [, { setFocusedChecklist }] = useChecklist();

  useEffect(() => {
    async function fetchData(vndr) {
      setIsLoading(true);
      try {
        const response = await getLogBookChecklists(vndr);
        setLogbookList(response || []);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }

    fetchData(vendor.vendorCode);
  }, [vendor.vendorCode]);

  useEffect(() => {
    async function getAllChecklists(id) {
      setIsLoading(true);
      try {
        const response = await getChecklists(id);
        setCheckLists(response);
      } catch (e) {
        console.error('Error on get checklists', e);
      }
      setIsLoading(false);
    }

    if (vendor && vendor.vendorCode) {
      getAllChecklists(vendor.vendorCode);
    }
  }, [vendor]);

  const onGoToChecklistDetails = item => {
    setFocusedChecklist(item, vendor);

    setTimeout(() => {
      history.push(`${CHECKLIST_DETAILS}`);
    }, 500);
  };

  const onGoToVisitCircuitList = item => {
    setFocusedChecklist(item, vendor);

    setTimeout(() => {
      history.push(`${VISIT_CIRCUIT_LIST}/${vendor.vendorId}?state=${selectedKey}`);
    }, 500);
  };

  const getFilteredList = useMemo(() => {
    const currentYear = moment().get('year');

    if (selectedKey === 'opened') {
      return logbookList.filter(
        item => item.hasOpenedCircuits && parseInt(item.year) >= currentYear,
      );
    }

    return logbookList.filter(
      item => item.hasClosedCircuits || parseInt(item.year) < currentYear,
    );
  }, [logbookList, selectedKey]);

  const getNoContentTab = useMemo(
    () => (
      <Container
        hideDetail
        slim
        style={{ maxWidth: 652, width: '100%' }}
      >
        <NoContentTab icon="icon-tasks" hideButton>
          <p>
            {t('global.no-content')}
          </p>
        </NoContentTab>
      </Container>
    ),
    [],
  );

  return (
    <NewChecklistsContainer>
      <LoadingHolder isLoading={isLoading} />

      {!isLoading && (
        <>
          <div className="secondary-wrapper">
            <NextFlockListMenu
              selectedKey={selectedKey}
              setSelectedKey={setSelectedKey}
              isMobile={isMobile}
              isTablet={isTablet}
              isGtMobile={isGtMobile}
              isChecklist
            />
          </div>

          {!getFilteredList.length && getNoContentTab}

          {getFilteredList.length > 0 && (
            <ChecklistsContainer2>
              {checkLists.map(item => {
                const itemDate = !item.lastFill
                  ? t("checklist.no-record")
                  : moment(item.lastFill).format('DD ̸̸  MM ̸̸  YYYY');

                return (
                  <Card
                    key={item.codChaveMtech}
                    title={item.indLiquidacaoDesc}
                    subtitle={`${item.codVago1}`}
                    additional={t('checklist.number-questions', { value: item.numberOfQuestions })}
                    icon="icon-tasks"
                    iconBackGround="colorPrimary"
                    action={() => onGoToChecklistDetails(item)}
                    footerIcon="icon-clock"
                    footerColor="colorInfo"
                    footerContent={t('checklist.last-fill.on', { value: itemDate })}
                  />
                );
              })}

              {getFilteredList.map((item, index) => {
                return (
                  <Card
                    key={index}
                    title={item.name}
                    subtitle={item.subtitle}
                    additional={t('checklist.number-questions', { value: item.questionsQuantity })}
                    icon="icon-tasks"
                    iconBackGround="colorPrimary"
                    action={() => onGoToVisitCircuitList(item)}
                    footerIcon="icon-clock"
                    footerColor="colorInfo"
                    footerContent={
                      item.lastAnswer
                        ? t('checklist.last-fill.on', { value: moment(item.lastAnswer).format('DD ̸̸  MM ̸̸  YYYY') })                        
                        : t('checklist.no-record')
                    }
                  />
                );
              })}
            </ChecklistsContainer2>
          )}
        </>
      )}
    </NewChecklistsContainer>
  );
}
