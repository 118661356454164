import React from 'react';
import { SideBoxStyled, Shadow } from './SideBox.styled';

export default function SideBox(props) {
  return (
    <>
      <Shadow
        displayBox={props.displayBox}
        onClick={() => props.setDisplayBox(false)}
      />
      <SideBoxStyled displayBox={props.displayBox}>
        {!props.hideCloseButton && (
          <span
            className="icon icon-close"
            onClick={() => props.setDisplayBox(false)}
          />
        )}
        <div className="side-box-content">{props.children}</div>
      </SideBoxStyled>
    </>
  );
}
