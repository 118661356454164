import React, { createContext, useReducer } from 'react';

const AnnouncementsContext = createContext();

const initialState = {
  selected: {},
  removedId: null,
  editAnnouncement: false,
};

const types = {
  SELECTED: 'SELECTED',
  REMOVED_ID: 'REMOVED_ID',
  EDIT: 'EDIT',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SELECTED:
      return {
        ...state,
        selected: action.payload,
      };

    case types.REMOVED_ID:
      return {
        ...state,
        removedId: action.payload,
      };

    case types.EDIT:
      return {
        ...state,
        editAnnouncement: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

const actions = dispatch => ({
  setSelected: item =>
    dispatch({
      type: types.SELECTED,
      payload: item,
    }),

  setRemovedId: id =>
    dispatch({
      type: types.REMOVED_ID,
      payload: id,
    }),

  setEditAnnouncement: edit =>
    dispatch({
      type: types.EDIT,
      payload: edit,
    }),
});

const AnnouncementsContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AnnouncementsContext.Provider
      value={{
        ...state,
        dispatch,
        actions: actions(dispatch),
      }}
    >
      {props.children}
    </AnnouncementsContext.Provider>
  );
};

export { AnnouncementsContext, AnnouncementsContextProvider };
