import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Icon from 'components/Icon';
import numberDotMask from 'components/MaskedInput/numberDotMask';
import NoContentTab from 'components/NoContentTab';
import { getAnimalIcon } from 'shared/utils/Helpers';
import {
  RoundIconWrapper,
  SectionTitle,
  RecordSummaryItem,
  Comparative,
  Separator,
} from 'containers/NextBRF/NextFlock/NextRecord/NextRecord.styled';
import t from 'components/i18n';

export default function RecordItemsList(props) {
  const { data, waitingForSubmit } = props;

  const expectedAverageWeight =
    data.averageWeight && data.averageWeight.expectedAverageWeight;
  const hasExpectedAverageWeight = expectedAverageWeight !== null;
  const weightValue =
    data.averageWeight && data.averageWeight.weightValue;

  const user = useSelector(state => state.user);

  const animalIcon = useMemo(() => getAnimalIcon(user.animalType), [
    user,
  ]);

  function compareWeight() {
    const up = { className: 'positive', icon: 'up' };
    const down = { className: 'negative', icon: 'down' };
    const chosen = weightValue >= expectedAverageWeight ? up : down;

    return (
      <Comparative className={chosen.className}>
        {t('next.flock.recordItemList.weight', { value: weightValue })}
        <Icon className={`icon icon-arrow-${chosen.icon}`} />
      </Comparative>
    );
  }

  return (
    <>
      {props.isEmpty && (
        <NoContentTab icon="icon-tasks">
          <p>
            {t('next.flock.recordItemList.empty.title')}
            <br />{' '}
            <span className="lighter">
              {t('next.flock.recordItemList.empty.subtitle')}
            </span>
          </p>
        </NoContentTab>
      )}

      {!props.isEmpty && (
        <div>
          {/* Consumo de água */}
          {!data.isFirstDay && (
            <SectionTitle alignTop>
              <RoundIconWrapper>
                <Icon name="icon-drop-rain" />
              </RoundIconWrapper>
              <div>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.waterConsume.title')}</span>
                  <p>
                    {t('next.flock.recordItemList.waterConsumption.value', {
                      value: data.waterConsume &&
                        numberDotMask(
                          data.waterConsume.waterConsumption,
                        )
                    })}
                  </p>
                </RecordSummaryItem>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.waterTime.title')}</span>
                  <p>
                    {data.waterConsume && data.waterConsume.waterTime}
                  </p>
                </RecordSummaryItem>
              </div>
            </SectionTitle>
          )}
          {/* \ Consumo de água */}

          {/*  Temperatura */}
          {!data.isFirstDay && (
            <SectionTitle alignTop>
              <RoundIconWrapper>
                <Icon name="icon-temperature" />
              </RoundIconWrapper>
              <div>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.temperature.title')}</span>
                  <p>
                    {t("next.flock.recordItemList.temperature.value", {
                      min: data.temperature &&
                        data.temperature.minTemperature, max: data.temperature &&
                          data.temperature.maxTemperature
                    })}
                  </p>
                </RecordSummaryItem>
              </div>
            </SectionTitle>
          )}
          {/* \ Temperatura */}

          {/*  Umidade */}
          {!data.isFirstDay && (
            <SectionTitle alignTop>
              <RoundIconWrapper>
                <Icon name="icon-drop" />
              </RoundIconWrapper>
              <div>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.umidity.title')}</span>
                  <p>
                    {t('next.flock.recordItemList.umidity.value', { min: data.moisture && data.moisture.minHumidity, max: data.moisture && data.moisture.maxHumidity })}
                  </p>
                </RecordSummaryItem>
              </div>
            </SectionTitle>
          )}
          {/* \ Umidade */}

          {/* Cloro e PH da água */}
          {!data.isFirstDay && data.ph && data.ph.ph && (
            <SectionTitle alignTop>
              <RoundIconWrapper>
                <Icon name="icon-water" />
              </RoundIconWrapper>
              <div>
                <RecordSummaryItem>
                  <span className="label">{t("next.flock.recordItemList.chlorine.title")}</span>
                  <p>
                    {t("next.flock.recordItemList.chlorine.ppm", { value: data.ph && numberDotMask(data.ph.chlorine) || '-' })}PPM
                  </p>
                </RecordSummaryItem>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.ph.title')}</span>
                  <p>{data.ph && data.ph.ph}</p>
                </RecordSummaryItem>
              </div>
            </SectionTitle>
          )}
          {/* \ Cloro e PH da água */}

          {/*  Mortalidade */}
          <SectionTitle alignTop>
            <RoundIconWrapper>
              <Icon name={`${animalIcon}-alt`} />
            </RoundIconWrapper>
            <div>
              {data.isFirstDay && (
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.mortality.mortalityOnArrival.title')}</span>
                  <p>
                    {t("next.flock.recordItemList.mortality.mortalityOnArrival.value", { value: data.mortality && data.mortality.mortalityOnArrival && numberDotMask(data.mortality.mortalityOnArrival) })}
                  </p>
                </RecordSummaryItem>
              )}
              {!data.isFirstDay && (
                <>
                  <RecordSummaryItem>
                    <span className="label">{t('next.flock.recordItemList.mortality.naturalDeath.title')}</span>
                    <p>
                      {t("next.flock.recordItemList.mortality.naturalDeath.value", { value: data.mortality && numberDotMask(data.mortality.naturalDeath) })}
                    </p>
                  </RecordSummaryItem>
                  <RecordSummaryItem>
                    <span className="label">
                      {t("next.flock.recordItemList.mortality.refuseElimination.title")}
                    </span>
                    <p>
                      {data.mortality &&
                        numberDotMask(
                          data.mortality.refuseElimination,
                        )}
                        {t("next.flock.recordItemList.mortality.refuseElimination.value", { value: data.mortality &&
                        numberDotMask(
                          data.mortality.refuseElimination,
                        ) || '-' })}
                    </p>
                  </RecordSummaryItem>
                  <RecordSummaryItem>
                    <span className="label">
                      {t('next.flock.recordItemList.mortality.legElimination.title')}
                    </span>
                    <p>
                      {t('next.flock.recordItemList.mortality.legElimination.value', { value: data.mortality && numberDotMask(data.mortality.legElimination) })}
                    </p>
                  </RecordSummaryItem>
                  <RecordSummaryItem>
                    <span className="label">
                      {t('next.flock.recordItemList.mortality.otherElimination.title')}
                    </span>
                    <p>
                        {t('next.flock.recordItemList.mortality.otherElimination.value', { value: data.mortality &&
                        numberDotMask(
                          data.mortality.otherElimination,
                        ) || '-'})}
                    </p>
                  </RecordSummaryItem>
                </>
              )}
            </div>
          </SectionTitle>
          {/* \ Mortalidade */}

          {/* Peso médio */}
          {weightValue && (
            <SectionTitle alignTop>
              <RoundIconWrapper>
                <Icon name="icon-scale" />
              </RoundIconWrapper>

              <div>
                {waitingForSubmit ? (
                  <RecordSummaryItem>
                    <span className="label">
                      {t('next.flock.recordItemList.weightValue.averageWeight.title')}
                    </span>
                    <p>{t('next.flock.recordItemList.weightValue.averageWeight.value', { value: weightValue })}</p>
                  </RecordSummaryItem>
                ) : (
                  <>
                    <RecordSummaryItem>
                      <span className="label">
                        {t('next.flock.recordItemList.weightValue.averageWeight.title')}
                      </span>
                      {hasExpectedAverageWeight ? (
                        compareWeight()
                      ) : (
                        <p>{weightValue}</p>
                      )}
                    </RecordSummaryItem>
                    {hasExpectedAverageWeight && (
                      <RecordSummaryItem>
                        <span className="label">
                          {t('next.flock.recordItemList.weightValue.expectedAverageWeight.title')}
                        </span>
                        <p>
                          {t("next.flock.recordItemList.weightValue.expectedAverageWeight.value", { value: expectedAverageWeight })}
                        </p>
                      </RecordSummaryItem>
                    )}
                  </>
                )}
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.weightValue.weightTime.title')}</span>
                  <p>
                    {data.averageWeight && data.averageWeight.weightTime}
                  </p>
                </RecordSummaryItem>
              </div>
            </SectionTitle>
          )}
          {/* \ Peso médio */}

          {/* Início de ração */}
          {data.foodConsume && data.foodConsume.isInitialFeed && (
            <SectionTitle alignTop>
              <RoundIconWrapper>
                <Icon name="icon-corn" />
              </RoundIconWrapper>
              <div>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.foodConsume.isInitialFeed.title')}</span>
                  <p>
                    {(data.foodConsume &&
                      data.foodConsume.isInitialFeed) ||
                      data.isFirstDay
                        ? t('next.flock.recordItemList.foodConsume.isInitialFeed.first-day.yes')
                        : t('next.flock.recordItemList.foodConsume.isInitialFeed.first-day.no')}
                  </p>
                </RecordSummaryItem>

                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.foodConsume.initialFeedType.title')}</span>
                  <p>
                    {data.foodConsume &&
                      data.foodConsume.initialFeedType}
                  </p>
                </RecordSummaryItem>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.foodConsume.initialFeedTime.title')}</span>
                  <p>
                    {data.foodConsume &&
                      data.foodConsume.initialFeedTime}
                  </p>
                </RecordSummaryItem>
              </div>
            </SectionTitle>
          )}
          {/* \ Início de ração */}

          {/* Movimentação de ração */}
          {data.foodConsume && data.foodConsume.hasMovimentation && (
            <SectionTitle alignTop>
              <RoundIconWrapper>
                <Icon name="icon-corn" />
              </RoundIconWrapper>
              <div>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.foodConsume.hasMovimentation.title')}</span>
                  <p>
                    {data.foodConsume &&
                      data.foodConsume.hasMovimentation
                      ? t('next.flock.recordItemList.foodConsume.hasMovimentation.yes')
                      : t('next.flock.recordItemList.foodConsume.hasMovimentation.no')}
                  </p>
                </RecordSummaryItem>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.foodConsume.initialFeedType.title')}</span>
                  <p>
                    {data.foodConsume &&
                      data.foodConsume.initialFeedType}
                  </p>
                  <p>
                    {t('next.flock.recordItemList.foodConsume.hasMovimentation.movimentationFeedValue', { value: data.foodConsume && data.foodConsume.movimentationFeedValue })}
                  </p>
                </RecordSummaryItem>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.foodConsume.hasMovimentation.producer.title')}</span>
                  <p>
                    {data.foodConsume &&
                      data.foodConsume.movimentationVendorInformation}
                  </p>
                </RecordSummaryItem>
              </div>
            </SectionTitle>
          )}
          {/* \ Movimentação de ração */}

          {/* Fechamento do lote */}
          {data.flockClosure && data.flockClosure.isFlockClosure && (
            <SectionTitle alignTop>
              <RoundIconWrapper>
                <Icon name="icon-lock-enabled" />
              </RoundIconWrapper>
              <div>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.flockClosure.isFlockClosure.title')}</span>
                  <p>
                    {data.flockClosure &&
                      data.flockClosure.isFlockClosure
                      ? t('next.flock.recordItemList.flockClosure.isFlockClosure.yes')
                      : t('next.flock.recordItemList.flockClosure.isFlockClosure.no')}
                  </p>
                </RecordSummaryItem>
                <RecordSummaryItem>
                  <span className="label">
                    {t('next.flock.recordItemList.flockClosure.waterConsume.title')}
                  </span>
                  <p>
                    {t('next.flock.recordItemList.flockClosure.waterConsume.value', { value: data.flockClosure &&
                      data.flockClosure.waterConsume || '-' })}
                  </p>
                </RecordSummaryItem>
                <RecordSummaryItem>
                  <span className="label">
                    {t('next.flock.recordItemList.flockClosure.selfConsume.title')}
                  </span>
                  <p>
                    {t('next.flock.recordItemList.flockClosure.selfConsume.value', { value: data.flockClosure && data.flockClosure.selfConsume })}
                  </p>
                </RecordSummaryItem>
              </div>
            </SectionTitle>
          )}
          {/* \ Fechamento do lote */}

          {/* Sobra de ração */}
          {data.flockClosure && data.flockClosure.hasFeedRemnant && (
            <SectionTitle alignTop>
              <RoundIconWrapper>
                <Icon name="icon-lock-enabled" />
              </RoundIconWrapper>

              <div>
                <RecordSummaryItem>
                  <span className="label">{t('next.flock.recordItemList.flockClosure.hasFeedRemnant.title')}</span>
                  <p>
                    {data.flockClosure &&
                      data.flockClosure.hasFeedRemnant
                      ? t('next.flock.recordItemList.flockClosure.hasFeedRemnant.yes')
                      : t('next.flock.recordItemList.flockClosure.hasFeedRemnant.no')}
                  </p>
                </RecordSummaryItem>
                <RecordSummaryItem>
                  {data.flockClosure &&
                    data.flockClosure.feedRemnant.map(
                      (item, index) => (
                        <div key={index}>
                          <span className="label">
                            {t('next.flock.recordItemList.foodConsume.feedRemnant.title')} {index + 1}{' '}
                          </span>
                          <p>{item.feedType}</p>
                          <p>{t("next.flock.recordItemList.foodConsume.feedRemnant.value", { value: item.feedQuantity })}</p>
                        </div>
                      ),
                    )}
                </RecordSummaryItem>
              </div>
            </SectionTitle>
          )}
          {/* \  Sobra de ração */}
        </div>
      )}

      {data.isFirstDay && (
        <div>
          {/* Dados de origem */}
          <SectionTitle alignTop>
            <RoundIconWrapper>
              <Icon name={animalIcon} />
            </RoundIconWrapper>
            <div>
              <RecordSummaryItem>
                <span className="label">{t('next.flock.recordItemList.flockOpening.flockLineage.title')}</span>
                <p>
                  {data.flockOpening && data.flockOpening.flockLineage}
                </p>
              </RecordSummaryItem>
              <RecordSummaryItem>
                <span className="label">{t("next.flock.recordItemList.flockOpening.flockGenre.title")}</span>
                <p>
                  {data.flockOpening && data.flockOpening.flockGenre}
                </p>
              </RecordSummaryItem>
              <RecordSummaryItem>
                <span className="label">{t("next.flock.recordItemList.flockOpening.broilerType.title")}</span>
                <p>
                  {data.flockOpening && data.flockOpening.broilerType}
                </p>
              </RecordSummaryItem>
              <RecordSummaryItem
                style={{ border: 'none', marginBottom: '0' }}
              >
                <span className="label">{t('next.flock.recordItemList.flockOpening.averageWeight.title')}</span>
                <p>
                  {t('next.flock.recordItemList.flockOpening.averageWeight.value', { value: data.flockOpening && data.flockOpening.averageWeight })}
                </p>
              </RecordSummaryItem>
            </div>
          </SectionTitle>
          {/* \ Dados de origem */}

          {/* Procedência */}
          <Separator />

          <h5>{t('next.flock.recordItemList.flockOpening.origin.title')}</h5>
          <br />
          {data.origins &&
            data.origins.length > 0 &&
            data.origins.map(origin => (
              <SectionTitle alignTop>
                <RoundIconWrapper>
                  <Icon name={animalIcon} />
                </RoundIconWrapper>
                <div>
                  <RecordSummaryItem>
                    <span className="label">{t('next.flock.recordItemList.flockOpening.headquarters.title')}</span>
                    <div className="flex">
                      <p>{origin.origin}</p>
                      <p style={{ fontSize: '12px' }}>
                        {t("next.flock.recordItemList.flockOpening.headquarters.weeks", { value: origin.originAge })}
                      </p>
                    </div>
                  </RecordSummaryItem>
                </div>
              </SectionTitle>
            ))}
        </div>
      )}
      {/* \ Procedência */}
    </>
  );
}
