// @flow

import React, { useState, useEffect, useContext } from 'react';
import { dateToString, getRepeated } from 'shared/utils/Helpers';
import * as yup from 'yup';
import { Formik } from 'formik';

// service
import {
  swineDeceasedDiscarded,
  getSwineDeceasedDiscardedTypes,
} from 'containers/AgroBRF/FlockList/service';
// validate
import validate from 'components/Forms/Fields/validate';
// contexts
import { SwineContext } from 'shared/contexts';
// form
import AddDeceasedForm from './AddDeceasedForm';
import t from 'components/i18n';
import moment from 'moment';

export default function AddDeceased(props) {
  const [deceasedDiscardedTypes, setTypes] = useState([]);
  const swineContext = useContext(SwineContext);
  const { selectedDeceased, deceasedResponse } = swineContext;
  const {
    setSelectedDeceased,
    setDeceasedResponse,
    setSuccess,
  } = swineContext.actions;
  const [message, setMessage] = useState('');
  const [groupedByMortality, setGroupedByMortality] = useState([]);

  function originValidate(ref) {
    return this.test({
      name: 'originValidate',
      exclusive: false,
      message: t('global.validation.invalid-tattoo-number'),
      params: {
        reference: ref.path,
      },
      test: function(value) {
        const id = this.parent.simpleId;
        const group = ref;

        if (value.toString().length < 4) {
          return false;
        }

        if (group[id]) {
          const container = getRepeated(group[id].map(e => e.origin));

          return container.findIndex(e => e === value) === -1;
        }
        return true;
      },
    });
  }

  function getValidateSchema(repeated) {
    yup.addMethod(yup.string, 'originValidate', originValidate);

    const validateSchema = () => {
      return yup.object().shape({
        house: yup.object().required(t('global.status.required-field')),
        deceasedDiscardedDate: yup
          .string()
          .nullable()
          .required(t('global.status.required-field')),
        swineDeceasedDiscarded: yup.array().of(
          yup.object().shape({
            origin: yup
              .string()
              .originValidate(repeated)
              .required(t('global.status.required-field')),
            maleQuantity: yup
              .number()
              .nullable()
              .transform(e => (isNaN(e) ? undefined : e))
              .required(t('global.status.required-field')),
            femaleQuantity: yup
              .number()
              .nullable()
              .transform(e => (isNaN(e) ? undefined : e))
              .required(t('global.status.required-field')),
          }),
        ),
      });
    };

    return validateSchema;
  }

  const initialValues = {
    id: null,
    house: null,
    houseId: null,
    flockId: props.flockID,
    deceasedDiscardedDate: null,
    isEditable: true,
    mortalityValidationCheck: null,
    swineDeceasedDiscarded: [
      {
        deceasedOrDiscarded: '',
        origin: '',
        maleQuantity: '',
        femaleQuantity: '',
        mortalityTypeId: '',
        discardedTypeId: '',
      },
    ],
  };

  useEffect(() => {
    async function getTypes() {
      try {
        const types = await getSwineDeceasedDiscardedTypes(
          props.flockID,
        );
        setTypes(types);
      } catch (error) {
        console.error(error);
      }
    }
    getTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deceasedDiscardedTypes]);

  useEffect(() => {
    if (deceasedResponse === null) {
      setSuccess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deceasedResponse]);

  const selectedValues = selectedDeceased
    ? {
      id: selectedDeceased.id,
      house: selectedDeceased.house,
      houseId: selectedDeceased.houseId,
      flockId: props.flockID,
      isEditable: selectedDeceased.isEditable,
      deceasedDiscardedDate: dateToString(
        moment(
          new Date(selectedDeceased.deceasedDiscardedDate),
        )
      ),
      swineDeceasedDiscarded:
          selectedDeceased.swineDeceasedDiscarded,
    }
    : null;

  function submitForm(values, { setSubmitting, setErrors }) {
    const formData = {
      id: values.id || 0,
      houseId: values.house.id,
      house: values.house.label,
      flockId: values.flockId,
      isEditable: values.isEditable,
      deceasedDiscardedDate:
        moment(
          new Date(values.deceasedDiscardedDate),
        ),
      swineDeceasedDiscarded: values.swineDeceasedDiscarded.map(e => {
        const obj = {};
        if (e.id) obj.id = e.id || '';
        obj.mortalityTypeId = e.mortalityTypeId
          ? e.mortalityTypeId.id
          : 0;
        obj.discardedTypeId = e.discardedTypeId
          ? e.discardedTypeId.id
          : 0;
        obj.origin = `BRF${e.origin}` || null;
        obj.maleQuantity = e.maleQuantity || 0;
        obj.femaleQuantity = e.femaleQuantity || 0;
        return obj;
      }),
    };

    async function saveData(val) {
      try {
        setSubmitting(true);
        const response = await swineDeceasedDiscarded(val);

        setDeceasedResponse(response.message);
        if (response.success) {
          setSuccess(response.success);
        } else {
          setSuccess(false);
          throw Error(response);
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
      props.goTo('addMedication');
      // props.goTo('deceasedSummary');
    }
    saveData(formData);
  }

  return (
    props.houses.length > 0 &&
    deceasedDiscardedTypes &&
    deceasedDiscardedTypes.length > 0 && (
      <Formik
        initialValues={
          selectedDeceased ? selectedValues : initialValues
        }
        validate={validate(getValidateSchema(groupedByMortality))}
        onSubmit={submitForm}
        render={formikProps => (
          <AddDeceasedForm
            {...formikProps}
            houses={props.houses}
            flockID={props.flockID}
            message={message}
            setMessage={setMessage}
            setSelectedDeceased={e => setSelectedDeceased(e)}
            selectedDeceased={selectedDeceased}
            setGroupedByMortality={setGroupedByMortality}
            groupedByMortality={groupedByMortality}
            selectedKey={props.selectedKey}
            goTo={props.goTo}
            sendToConfirmation={props.sendToConfirmation}
            setSendToConfirmation={props.setSendToConfirmation}
            isEditing={props.isEditing}
            deceasedDiscardedTypes={deceasedDiscardedTypes}
            setDisplayExcludeModal={props.setDisplayExcludeModal}
            isLoading={props.isLoading}
          />
        )}
      />
    )
  );
}
