// @flow

import React, { useEffect, useState, useCallback } from 'react';
import t from 'components/i18n';
import * as yup from 'yup';
import { Formik } from 'formik';
import { cleanUp } from 'components/MaskedInput/mask';
import { PROFILE } from 'config/routes';
import cpfMask from 'components/MaskedInput/cpfMask';
import phoneMask from 'components/MaskedInput/phoneMask';
import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';
import Modal from 'components/Forms/FormModal/Modal';
import CollaboratorForm from 'containers/AgroBRF/Forms/CollaboratorForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { inviteUser } from './service';
import { Container } from './Collaborator.styled';

const validateSchema = props => {
  yup.addMethod(yup.string, 'validateDocNumber', function() {
    const message = props.verifyCpfMessage;
    return this.test('cpf', message, () => {
      if (!props.verifyCpfMessage) {
        return true;
      }
      return props.verifyCpfMessage.length < 0;
    });
  });

  return yup.object().shape({
    name: yup.string().required(t('global.status.required-field')),
    verifyCpfMessage: yup.string(),
    documentNumber: yup
      .string()
      .validateDocNumber()
      .required(t('global.status.required-field')),
    phoneNumber: yup
      .string()
      .min(14, t('global.validation.min-character-length', { length: 14 }))
      .required(t('global.status.required-field')),
    profile: yup.string().required(t('global.status.required-field')),
    validVendors: yup
      .boolean()
      .oneOf([true], t('global.validation.choose-growerCode')),
  });
};

export default function EditCollaborator(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const {
    user,
    history,
    setEditUser,
    isValidating,
    setRemovedUser,
    availableVendors,
  } = props;

  useEffect(() => {
    setRemovedUser(false);
  }, [setRemovedUser]);

  const isEditing = user && Object.keys(user).length > 0;

  const successMessage = isEditing
    ? t('menu.employees.inviteEmployee.success')
    : t('menu.employees.editEmployee.success');

  const modalErrorMessage = isEditing
    ? t('global.validation.error-saving-info')
    : t('menu.employees.inviteEmployee.error');

  if (isEditing) {
    user.documentNumber = cpfMask(user.documentNumber);
    user.phoneNumber = phoneMask(user.phoneNumber);
  }

  const initialValues = {
    id: 0,
    name: '',
    documentNumber: '',
    phoneNumber: '',
    vendors: [],
    profile: '',
    acceptedTerms: false,
  };

  function submitForm(
    values,
    { setSubmitting, setErrors, isValidating },
  ) {
    const formData = {
      id: values.id,
      name: values.name,
      documentNumber: cleanUp(values.documentNumber),
      phoneNumber: cleanUp(values.phoneNumber),
      vendors: values.vendors.map(vendor => {
        if (!vendor.isChecked) {
          return {
            ...vendor,
            houses: vendor.houses.map(house => ({
              ...house,
              isChecked: false,
            })),
          };
        }
        return vendor;
      }),
      profile: values.profile,
      acceptedTerms: values.acceptedTerms,
    };

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await inviteUser(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }

    sendData(formData);
  }

  const getTitleLabel = useCallback(
    args =>
      args
        ? t('menu.employees.create')
        : t('menu.employees.edit'),
    [],
  );

  const getCollaboratorForm = useCallback(
    initial => (
      <Formik
        initialValues={initial}
        validate={validate(validateSchema)}
        onSubmit={submitForm}
        render={formikProps => (
          <CollaboratorForm
            {...formikProps}
            isValidating={isValidating}
            message={errorMessage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            success={success}
            isEditing={isEditing}
            availableVendors={availableVendors}
            {...props}
          />
        )}
      />
    ),
    [
      availableVendors,
      errorMessage,
      isEditing,
      isLoading,
      isValidating,
      props,
      success,
    ],
  );

  return (
    <>
      <Breadcrumb
        history={history}
        links={[`${PROFILE}/account-settings/collaborators`]}
        path={[
          t('menu.employees.employeesList.title'),
          getTitleLabel(isEditing)
        ]}
      />

      <Top>
        <h2>{getTitleLabel(isEditing)}</h2>
      </Top>

      <Container style={{ padding: '16px' }}>
        {isEditing && getCollaboratorForm(user)}

        {!isEditing && getCollaboratorForm(initialValues)}
      </Container>

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          history.push(`${PROFILE}/account-settings/collaborators`);
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>{success ? successMessage : modalErrorMessage}</p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
