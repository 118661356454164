import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Circular } from './CircleAndTitle.styled';

export default function CircleAndTitle({
  bgColor,
  initials,
  name,
  description,
}) {
  const themeContext = useContext(ThemeContext);
  return (
    <>
      <Circular bgColor={themeContext.styles.palette[bgColor]}>
        {initials}
      </Circular>

      <div className="list-item-content">
        {name && <div className="list-item-heading">{name}</div>}
        {description && (
          <div className="list-item-description">{description}</div>
        )}
      </div>
    </>
  );
}
