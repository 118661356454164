import React, { useState, useContext } from 'react';
import * as yup from 'yup';
import * as routes from 'config/routes';
import qs from 'qs';
import { Formik } from 'formik';
import Alert from 'components/Alert';
import Transition from 'components/Transition';
import {
  FirstMemberForm,
  SecondMemberForm,
  ThirdMemberForm,
  OnSubmitPage,
} from 'containers/OnePage/Forms/MemberForm';
// context
import { MemberContext } from 'shared/contexts/Member';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { becomeVendor } from './service';
// styles
import { Container, Wrapper } from './BecomeAMember.styled';
import t from 'components/i18n';

const firstValidateSchema = () =>
  yup.object().shape({
    name: yup.string().required(t('global.status.required-field')),
    email: yup
      .string()
      .email(t('global.validation.invalid-email'))
      .required(t('global.status.required-field')),
    // recaptcha: yup.string().required(t('global.validation.invalid-captcha')),
  });

const secondValidateSchema = () =>
  yup.object().shape({
    animalType: yup.array.min(1).required(t('global.status.required-field')),
  });

const thirdValidateSchema = () =>
  yup.object().shape({
    cityID: yup.string().required(t('global.status.required-field')),
    phoneNumber: yup
      .string()
      .min(14, t('global.validation.min-character-length', { length: 14 }))
      .required(t('global.status.required-field')),
  });

export default function BecomeAMember(props) {
  const [formStep, setformStep] = useState(1); // set first step form
  const memberContext = useContext(MemberContext);
  const { submitResponse } = memberContext;
  const { setSubmitResponse, resetValues } = memberContext.actions;

  function submitForm(values, { setSubmitting, setErrors }) {
    const formData = {
      name: memberContext.name,
      email: memberContext.email,
      animalType: memberContext.animalType,
      cityID: memberContext.cityID,
      phoneNumber: memberContext.phoneNumber,
      message: memberContext.message,
      platform: 'web', // device param
    };

    async function saveData(val) {
      setSubmitting(true);
      try {
        const response = await becomeVendor(val);
        if (!response.success) {
          setSubmitResponse(response.message);
        } else {
          setformStep(4);
        }
      } catch (error) {
        console.error(error);
      }
      setSubmitting(false);
    }
    saveData(formData);
    resetValues();
  }

  function closeForm() {
    props.history.push(routes.SIGNIN);
    resetValues();
  }

  const formSteps = [
    {
      num: 1,
      form: FirstMemberForm,
      schema: firstValidateSchema
    },
    {
      num: 2,
      form: SecondMemberForm,
      schema: secondValidateSchema,
    },
    {
      num: 3,
      form: ThirdMemberForm,
      schema: thirdValidateSchema
    }
  ];

  const hideCancelButton =
    qs.parse(props.location.search)['?device'] === 'mobile';

  return (
    <Transition transition="fade">
      <Wrapper className="animated fadeIn">
        <Container>
          {formSteps.map(
            (step, index) =>
              formStep === step.num && (
                <Formik
                  key={index}
                  initialValues={memberContext}
                  validate={validate(step.schema)}
                  onSubmit={submitForm}
                  render={formikProps => (
                    <step.form
                      closeForm={closeForm}
                      {...props}
                      {...formikProps}
                      setformStep={setformStep}
                      hideCancelButton={hideCancelButton}
                    />
                  )}
                />
              ),
          )}
          {formStep === 4 && (
            <OnSubmitPage
              {...props}
              success={!submitResponse.length}
            />
          )}
          {submitResponse.length > 0 && (
            <Alert
              icon="icon-exclamation-triangle"
              variant="danger"
              handleClickClose={() => setSubmitResponse('')}
            >
              {submitResponse}
            </Alert>
          )}
        </Container>
      </Wrapper>
    </Transition>
  );
}
