import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  DEFAULT_SIZE,
  colorClear,
  colorGrayDarkest,
  colorGrayLight,
  colorGrayLighter,
  colorGrayLightest,
  breakpointTablet,
  fontFamilyText,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

export const BC = styled.div`
  position: fixed;
  top: 64px;
  display: flex;
  width: 100%;
  height: 40px;
  background-color: ${colorClear};
  font-style: normal;  
  font-size: 12px;
  line-height: 16px;  
  align-items: center;
  padding-left: ${DEFAULT_SIZE}px;
  padding-right: ${DEFAULT_SIZE * 2}px;
  z-index: 100;

  + div {
    margin-top: 40px;
  }
  

  @media (min-width: ${breakpointTablet}px) {
    padding-left: ${DEFAULT_SIZE * 2}px;
  }

  .icon {
    font-size: 18px !important;
    color: ${colorGrayLighter};
  }

  /* padding: ${DEFAULT_SIZE * 3}px;
  background-color: ${colorClear};
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};
  display: flex;
  width: 100%;

  h2 {
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.15px;
    margin: 0;
  } */
`;

export const Path = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${colorGrayLight};
    font-family: ${fontFamilyTextBold};
    padding: 2px 4px;
    border-radius: 4px;
    transition: 0.2s ease-in background-color;
    cursor: pointer;

    &:hover {
      background: ${colorGrayLightest};
    }

    &.noLink {
      font-family: ${fontFamilyText};
      cursor: default;

      &:hover {
        background: transparent;
      }
    }
  }

  span {
    display: block;
    margin: 0 ${DEFAULT_SIZE}px;
    color: ${colorGrayLighter};
  }
`;

export const Button = styled.button`
  background-color: transparent;
  border: 0 none;
  margin-right: ${DEFAULT_SIZE}px;
  margin-left: -${DEFAULT_SIZE}px;
  cursor: pointer;
`;
