import moment from "moment";

import { API_SUPERVISOR_URL, API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

const getFlockInvoiceList = async (payload) => {
  const response = await request(
    `${API_SUPERVISOR_URL}/FlockInvoice/GetFlockInvoiceList`,
    {
      method: 'POST',
      payload
    },
  );
  return response.data;
}

const getFlockInvoiceById = async (id) => {
  const response = await request(
    `${API_SUPERVISOR_URL}/FlockInvoice/GetFlockInvoiceById`,
    {
      method: 'GET',
      payload: { id }
    },
  );
  return response.data;
}

const getAttachment = async (fileName) => {
  const response = await request(
    `${API_URL}/Blob/DownloadFile`,
    {
      method: 'GET',
      payload: { 
        fileName,
        containerName: "invoice"
      }
    },
  );
  return response.data;
}

const approveInvoice = async (data) => {
  const response = await request(
    `${API_SUPERVISOR_URL}/FlockInvoice/UpdateFlockInvoice`,
    {
      method: 'PUT',
      payload: data
    },
  );
  return response;
}

const rejectInvoice = async (data) => {
  const response = await request(
    `${API_SUPERVISOR_URL}/FlockInvoice/UpdateFlockInvoice`,
    {
      method: 'PUT',
      payload: data
    },
  );
  return response;
}

export {
  getFlockInvoiceList,
  getFlockInvoiceById,
  approveInvoice,
  getAttachment,
  rejectInvoice
}
