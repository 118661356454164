import { API_URL, API_SUPERVISOR_URL, KONG_CLIENT_ID } from 'config/constants';
import { request } from 'shared/utils/client';
import { fixFileName } from 'shared/utils/Helpers/fixFileName';

export async function uploadImage(payload) {
  const fd = new FormData();
  fd.append('file', payload, fixFileName(payload));

  try {
    const response = await request(
      `${API_URL}/Blob/Upload?containerName=logbookattachment`,
      {
        headers: {
          'Content-Type': undefined,
          Authorization: null,
        },
        method: 'POST',
        payload: fd,
      },
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
}

export async function getImage(file) {
  const response = await request(
    `${API_URL}/Blob/Download?fileName=${file}&blobContainerName=logbookattachment&hasWaterMark=true&client_id=${KONG_CLIENT_ID}`,
    {
      headers: {
        'Content-Type': undefined,
        Authorization: null,
      },
      method: 'GET',
    },
  );
  return response.data;
}

export async function deleteImage(file) {
  const response = await request(
    `${API_URL}/Blob/DeleteFile?fileName=${file}`,
    {
      method: 'DELETE',
    },
  );
  return response.data;
}

export async function getLogBookChecklistsSchedules(id, vendor, state = "opened") {
  const response = await request(
    `${API_SUPERVISOR_URL}/logbook-checklists/${id}/schedulesOpenClosed?vendor=${vendor}&state=${state}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function getLogBookChecklistsQuestion({ id, vendor, epidemiologicalCore = null, flocks }) {
  epidemiologicalCore = null;

  const response = await request(
    `${API_SUPERVISOR_URL}/schedules/${id}/questions`,
    {
      method: 'GET',
      payload: {
        vendor,
        ...(flocks && flocks.length && { flocks: flocks.join(',') }),
        ...(epidemiologicalCore && { epidemiological: epidemiologicalCore })
      } 
    },
  );

  return response.data;
}

export async function getFlockQuestions(vendor) {
  const response = await request(
    `${API_SUPERVISOR_URL}/flock-questions?vendor=${vendor}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function getFlockCircuitQuestions({ vendor, scheduleId, state = 'opened' }) {
  const response = await request(
    `${API_SUPERVISOR_URL}/flock-questions?vendor=${vendor}&scheduleId=${scheduleId}&state=${state}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function saveLogBookChecklistsQuestion(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/supervisor-answers`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}

export async function saveLogBookChecklistsActionPlan(payload) {
  return request(`${API_SUPERVISOR_URL}/action-plans`, {
    method: 'POST',
    payload,
  });
}

export async function updateActionPlan(actionPlanId, payload) {
  return request(
    `${API_SUPERVISOR_URL}/action-plans/${actionPlanId}`,
    {
      method: 'PUT',
      payload,
    },
  );
}

export async function getLogbookQuestions({
  vendor,
  logbookDate,
  logbookItemTypeId,
  epidemiologicalCore,
  flocks
}) {
  const response = await request(
    `${API_SUPERVISOR_URL}/logbook-questions`,
    {
      method: 'GET',
      payload: {
        vendor,
        logbookDate,
        logbookItemTypeId,
        epidemiologicalCore,
        flocks: flocks.join(',')
      }
    },
  );

  return response.data;
}

export async function getLogBookItemDetails(id) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/GetLogBookItemDetails?id=${id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getLogbookItemsOrientations(logbookItemId) {
  const response = await request(
    `${API_SUPERVISOR_URL}/logbook-items/${logbookItemId}/orientations`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function putLogbookItemsOrientations(
  logbookItemId,
  payload,
) {
  return request(
    `${API_SUPERVISOR_URL}/logbook-items/${logbookItemId}/orientations`,
    {
      method: 'PUT',
      payload,
    },
  );
}
