import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Icon from 'components/Icon';
import numberDotMask from 'components/MaskedInput/numberDotMask';
import NoContentTab from 'components/NoContentTab';
import { getAnimalIcon } from 'shared/utils/Helpers';
import {
  RoundIconWrapper,
  SectionTitle,
  RecordSummaryItem,
  Comparative,
  Separator,
} from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import t from 'components/i18n';

export default function RecordItemsList(props) {
  const { data, waitingForSubmit } = props;

  const expectedAverageWeight =
    data.averageWeight && data.averageWeight.expectedAverageWeight;
  const hasExpectedAverageWeight = expectedAverageWeight !== null;
  const weightValue =
    data.averageWeight && data.averageWeight.weightValue;

  const user = useSelector(state => state.user);

  const animalIcon = useMemo(() => getAnimalIcon(user.animalType), [
    user,
  ]);

  function compareWeight() {
    const up = { className: 'positive', icon: 'up' };
    const down = { className: 'negative', icon: 'down' };
    const chosen = weightValue >= expectedAverageWeight ? up : down;

    return (
      <Comparative className={chosen.className}>
        {t('agro.flock.recordItemsList.weight', { value: weightValue })}
        <Icon className={`icon icon-arrow-${chosen.icon}`} />
      </Comparative>
    );
  }

  return props.isEmpty ? (
    <NoContentTab icon="icon-tasks">
      <p>
        {t("agro.flock.recordItemsList.empty.title")}
        <br />{' '}
        <span className="lighter">
          {t('agro.flock.recordItemsList.empty.subtitle')}
        </span>
      </p>
    </NoContentTab>
  ) : (
    <>
      {/* Consumo de água */}
      {!data.isFirstDay && (
        <SectionTitle alignTop>
          <RoundIconWrapper>
            <Icon name="icon-drop-rain" />
          </RoundIconWrapper>
          <div>
            <RecordSummaryItem>
              <span className="label">{t('agro.flock.recordItemsList.item.waterConsume.waterConsumption.label')}</span>
              <p>
                {t('agro.flock.recordItemsList.item.waterConsume.waterConsumption.value', { value: data.waterConsume && numberDotMask(data.waterConsume.waterConsumption) })}
              </p>
            </RecordSummaryItem>
            <RecordSummaryItem>
              <span className="label">{t('agro.flock.recordItemsList.item.waterConsume.waterTime.label')}</span>
              <p>
                {data.waterConsume && data.waterConsume.waterTime}
              </p>
            </RecordSummaryItem>
          </div>
        </SectionTitle>
      )}
      {/* \ Consumo de água */}

      {/*  Temperatura */}
      {!data.isFirstDay && (
        <SectionTitle alignTop>
          <RoundIconWrapper>
            <Icon name="icon-temperature" />
          </RoundIconWrapper>
          <div>
            <RecordSummaryItem>
              <span className="label">{t('agro.flock.recordItemsList.item.temperature.label')}</span>
              <p>
                {t('agro.flock.recordItemsList.item.temperature.value', { min: data.temperature && data.temperature.minTemperature, max: data.temperature && data.temperature.maxTemperature })}
              </p>
            </RecordSummaryItem>
          </div>
        </SectionTitle>
      )}
      {/* \ Temperatura */}

      {/*  Umidade */}
      {!data.isFirstDay && (
        <SectionTitle alignTop>
          <RoundIconWrapper>
            <Icon name="icon-drop" />
          </RoundIconWrapper>
          <div>
            <RecordSummaryItem>
              <span className="label">{t("agro.flock.recordItemsList.item.moisture.label")}</span>
              <p>
                {t(
                  'agro.flock.recordItemsList.item.moisture.value',
                  {
                    min: data.moisture && data.moisture.minHumidity,
                    max: data.moisture && data.moisture.maxHumidity
                  }
                )}
              </p>
            </RecordSummaryItem>
          </div>
        </SectionTitle>
      )}
      {/* \ Umidade */}

      {/* Cloro e PH da água */}
      {!data.isFirstDay && data.ph && data.ph.ph && (
        <SectionTitle alignTop>
          <RoundIconWrapper>
            <Icon name="icon-water" />
          </RoundIconWrapper>
          <div>
            <RecordSummaryItem>
              <span className="label">{t('agro.flock.recordItemsList.item.ph.chlorine.label')}</span>
              <p>{t('agro.flock.recordItemsList.item.ph.chlorine.value', { value: data.ph && numberDotMask(data.ph.chlorine) })}</p>
            </RecordSummaryItem>
            <RecordSummaryItem>
              <span className="label">{t("agro.flock.recordItemsList.item.ph.water.label")}</span>
              <p>{data.ph && data.ph.ph}</p>
            </RecordSummaryItem>
          </div>
        </SectionTitle>
      )}
      {/* \ Cloro e PH da água */}

      {/*  Mortalidade */}
      <SectionTitle alignTop>
        <RoundIconWrapper>
          <Icon name={`${animalIcon}-alt`} />
        </RoundIconWrapper>
        <div>
          {data.isFirstDay && (
            <RecordSummaryItem>
              <span className="label">{t('agro.flock.recordItemsList.item.mortality.mortalityOnArrival.label')}</span>
              <p>
                {t('agro.flock.recordItemsList.item.mortality.mortalityOnArrival.value', { value: data.mortality && data.mortality.mortalityOnArrival && numberDotMask(data.mortality.mortalityOnArrival) })}
              </p>
            </RecordSummaryItem>
          )}

          {!data.isFirstDay && (
            <>
              <RecordSummaryItem>
                <span className="label">{t('agro.flock.recordItemsList.item.mortality.naturalDeath.label')}</span>
                <p>
                  {t('agro.flock.recordItemsList.item.mortality.naturalDeath.value', { value: data.mortality && numberDotMask(data.mortality.naturalDeath) })}
                </p>
              </RecordSummaryItem>
              <RecordSummaryItem>
                <span className="label">{t('agro.flock.recordItemsList.item.mortality.refuseElimination.label')}</span>
                <p>
                  {t('agro.flock.recordItemsList.item.mortality.refuseElimination.value', { value: data.mortality && numberDotMask(data.mortality.refuseElimination) })}
                </p>
              </RecordSummaryItem>
              <RecordSummaryItem>
                <span className="label">
                  {t('agro.flock.recordItemsList.item.mortality.legElimination.label')}
                </span>
                <p>
                  {t('agro.flock.recordItemsList.item.mortality.legElimination.value', { value: data.mortality &&
                    numberDotMask(data.mortality.legElimination) })}
                </p>
              </RecordSummaryItem>
              <RecordSummaryItem>
                <span className="label">
                  {t('agro.flock.recordItemsList.item.mortality.otherElimination.label')}
                </span>
                <p>
                  {t('agro.flock.recordItemsList.item.mortality.otherElimination.value', { value: data.mortality &&
                    numberDotMask(data.mortality.otherElimination) })}
                </p>
              </RecordSummaryItem>
            </>
          )}
        </div>
      </SectionTitle>
      {/* \ Mortalidade */}

      {/* Peso médio */}
      {weightValue && (
        <SectionTitle alignTop>
          <RoundIconWrapper>
            <Icon name="icon-scale" />
          </RoundIconWrapper>

          <div>
            {waitingForSubmit ? (
              <RecordSummaryItem>
                <span className="label">{t('agro.flock.recordItemsList.item.weightValue.label')}</span>
                <p>{t('agro.flock.recordItemsList.item.weightValue.value', { value: weightValue })}</p>
              </RecordSummaryItem>
            ) : (
              <>
                <RecordSummaryItem>
                  <span className="label">{t('agro.flock.recordItemsList.item.weightValue.label')}</span>
                  {hasExpectedAverageWeight ? (
                    compareWeight()
                  ) : (
                    <p>{t('agro.flock.recordItemsList.item.weightValue.value', { value: weightValue })}</p>
                  )}
                </RecordSummaryItem>
                {hasExpectedAverageWeight && (
                  <RecordSummaryItem>
                    <span className="label">{t('agro.flock.recordItemsList.item.expectedAverageWeight.label')}</span>
                    <p>
                      {t('agro.flock.recordItemsList.item.expectedAverageWeight.value', { value: expectedAverageWeight })}
                    </p>
                  </RecordSummaryItem>
                )}
              </>
            )}
            <RecordSummaryItem>
              <span className="label">Horário da pesagem</span>
              <p>
                {data.averageWeight && data.averageWeight.weightTime}
              </p>
            </RecordSummaryItem>
          </div>
        </SectionTitle>
      )}
      {/* \ Peso médio */}

      {/* Início de ração */}
      {data.foodConsume && data.foodConsume.isInitialFeed && (
        <SectionTitle alignTop>
          <RoundIconWrapper>
            <Icon name="icon-corn" />
          </RoundIconWrapper>
          <div>
            <RecordSummaryItem>
              <span className="label">{t('agro.flock.recordItemsList.item.foodConsume.isInitialFeed.label')}</span>
              <p>
                {(data.foodConsume &&
                  data.foodConsume.isInitialFeed) ||
                data.isFirstDay
                  ? t('agro.flock.recordItemsList.item.foodConsume.isInitialFeed.firstDay.yes')
                  : t('agro.flock.recordItemsList.item.foodConsume.isInitialFeed.firstDay.no')
                  }
              </p>
            </RecordSummaryItem>

            <RecordSummaryItem>
              <span className="label">{t('agro.flock.recordItemsList.item.foodConsume.initialFeedType.label')}</span>
              <p>
                {data.foodConsume && data.foodConsume.initialFeedType}
              </p>
            </RecordSummaryItem>
            <RecordSummaryItem>
              <span className="label">{t('agro.flock.recordItemsList.item.foodConsume.initialFeedTime.label')}</span>
              <p>
                {data.foodConsume && data.foodConsume.initialFeedTime}
              </p>
            </RecordSummaryItem>
          </div>
        </SectionTitle>
      )}
      {/* \ Início de ração */}

      {/* Movimentação de ração */}
      {data.foodConsume && data.foodConsume.hasMovimentation && (
        <SectionTitle alignTop>
          <RoundIconWrapper>
            <Icon name="icon-corn" />
          </RoundIconWrapper>
          <div>
            <RecordSummaryItem>
              <span className="label">{t('agro.flock.recordItemsList.item.foodConsume.hasMovimentation.label')}</span>
              <p>
                {data.foodConsume && data.foodConsume.hasMovimentation
                  ? t('agro.flock.recordItemsList.item.foodConsume.hasMovimentation.hasMovimentation.yes')
                  : t('agro.flock.recordItemsList.item.foodConsume.hasMovimentation.hasMovimentation.no')
                  }
              </p>
            </RecordSummaryItem>
            <RecordSummaryItem>
              <span className="label">{t('agro.flock.recordItemsList.item.foodConsume.initialFeedType.label')}</span>
              <p>
                {data.foodConsume && data.foodConsume.initialFeedType}
              </p>
              <p>
                {t("agro.flock.recordItemsList.item.foodConsume.initialFeedType.value", { value: data.foodConsume && data.foodConsume.movimentationFeedValue })}
              </p>
            </RecordSummaryItem>
            <RecordSummaryItem>
              <span className="label">{t("agro.flock.recordItemsList.item.foodConsume.movimentationVendorInformation.label")}</span>
              <p>
                {data.foodConsume &&
                  data.foodConsume.movimentationVendorInformation}
              </p>
            </RecordSummaryItem>
          </div>
        </SectionTitle>
      )}
      {/* \ Movimentação de ração */}

      {/* Fechamento do lote */}
      {data.flockClosure && data.flockClosure.isFlockClosure && (
        <SectionTitle alignTop>
          <RoundIconWrapper>
            <Icon name="icon-lock-enabled" />
          </RoundIconWrapper>
          <div>
            <RecordSummaryItem>
              <span className="label">{t('agro.flock.recordItemsList.item.flockClosure.isFlockClosure.label')}</span>
              <p>
                {data.flockClosure && data.flockClosure.isFlockClosure
                  ? t('agro.flock.recordItemsList.item.flockClosure.isFlockClosure.isFlockClosure.yes')
                  : t('agro.flock.recordItemsList.item.flockClosure.isFlockClosure.isFlockClosure.no')}
              </p>
            </RecordSummaryItem>
            <RecordSummaryItem>
              <span className="label">{t("agro.flock.recordItemsList.item.flockClosure.waterConsume.label")}</span>
              <p>
                {t("agro.flock.recordItemsList.item.flockClosure.waterConsume.value", { value: data.flockClosure && data.flockClosure.waterConsume })}
              </p>
            </RecordSummaryItem>
            <RecordSummaryItem>
              <span className="label">{t("agro.flock.recordItemsList.item.flockClosure.selfConsume.label")}</span>
              <p>
                {t('agro.flock.recordItemsList.item.flockClosure.selfConsume.value', { value: data.flockClosure && data.flockClosure.selfConsume })}
              </p>
            </RecordSummaryItem>
          </div>
        </SectionTitle>
      )}
      {/* \ Fechamento do lote */}

      {/* Sobra de ração */}
      {data.flockClosure && data.flockClosure.hasFeedRemnant && (
        <SectionTitle alignTop>
          <RoundIconWrapper>
            <Icon name="icon-lock-enabled" />
          </RoundIconWrapper>

          <div>
            <RecordSummaryItem>
              <span className="label">{t("agro.flock.recordItemsList.item.flockClosure.hasFeedRemnant.label")}</span>
              <p>
                {data.flockClosure && data.flockClosure.hasFeedRemnant
                  ? t("agro.flock.recordItemsList.item.flockClosure.hasFeedRemnant.yes")
                  : t("agro.flock.recordItemsList.item.flockClosure.hasFeedRemnant.no")
                  }
              </p>
            </RecordSummaryItem>
            <RecordSummaryItem>
              {data.flockClosure &&
                data.flockClosure.feedRemnant.map((item, index) => (
                  <div key={index}>
                    <span className="label">
                      {t("agro.flock.recordItemsList.item.flockClosure.hasFeedRemnant.type")}
                      {index + 1}{' '}
                    </span>
                    <p>{item.feedType}</p>
                    <p>{t("agro.flock.recordItemsList.item.flockClosure.hasFeedRemnant.value", { value: item.feedQuantity })}</p>
                  </div>
                ))}
            </RecordSummaryItem>
          </div>
        </SectionTitle>
      )}
      {/* \ Sobra de ração */}

      {/* Dados de origem */}
      {data.isFirstDay && (
        <div>
          <SectionTitle alignTop>
            <RoundIconWrapper>
              <Icon name={animalIcon} />
            </RoundIconWrapper>
            <div>
              <RecordSummaryItem>
                <span className="label">{t("agro.flock.recordItemsList.item.flockClosure.flockLineage.label")}</span>
                <p>
                  {data.flockOpening && data.flockOpening.flockLineage}
                </p>
              </RecordSummaryItem>
              <RecordSummaryItem>
                <span className="label">{t("agro.flock.recordItemsList.item.flockClosure.genre.label")}</span>
                <p>
                  {data.flockOpening && data.flockOpening.flockGenre}
                </p>
              </RecordSummaryItem>
              <RecordSummaryItem>
                <span className="label">{t('agro.flock.recordItemsList.item.flockClosure.broilerType.label')}</span>
                <p>
                  {data.flockOpening && data.flockOpening.broilerType}
                </p>
              </RecordSummaryItem>
              <RecordSummaryItem
                style={{ border: 'none', marginBottom: '0' }}
              >
                <span className="label">{t('agro.flock.recordItemsList.item.flockClosure.averageWeight.label')}</span>
                <p>
                  {t('agro.flock.recordItemsList.item.flockClosure.averageWeight.value', { value: data.flockOpening && data.flockOpening.averageWeight })}
                </p>
              </RecordSummaryItem>
            </div>
          </SectionTitle>
          {/* \ Dados de origem */}

          {/* Procedência */}
          <Separator />

          <h5>{t('agro.flock.recordItemsList.item.origins.label')}</h5>
          <br />
          {data.origins &&
            data.origins.length > 0 &&
            data.origins.map(origin => (
              <SectionTitle alignTop>
                <RoundIconWrapper>
                  <Icon name={animalIcon} />
                </RoundIconWrapper>
                <div>
                  <RecordSummaryItem>
                    <span className="label">{t('agro.flock.recordItemsList.item.matriz.label')}</span>
                    <div className="flex">
                      <p>{origin.origin}</p>
                      <p style={{ fontSize: '12px' }}>
                        {t("agro.flock.recordItemsList.item.weeks.label", { value: origin.originAge })}
                      </p>
                    </div>
                  </RecordSummaryItem>
                </div>
              </SectionTitle>
            ))}
        </div>
      )}
      {/* \ Procedência */}
    </>
  );
}
