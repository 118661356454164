import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { SmallButtonStyled } from './SmallButton.styled';

export default function SmallButton({
  children,
  bgColor,
  textColor,
  onClick,
  disabled,
}) {
  const themeContext = useContext(ThemeContext);

  return (
    <SmallButtonStyled
      buttonBg={themeContext.styles.palette[bgColor]}
      textColor={themeContext.styles.palette[textColor]}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </SmallButtonStyled>
  );
}
