// @flow

import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';
import Toaster from 'components/Toaster';
import Modal from 'components/Forms/FormModal/Modal';
import AnnouncementsForm from 'containers/AgroBRF/Forms/AnnouncementsForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { putAnnouncement } from './service';
import { Container } from './Announcements.styled';
import t from 'components/i18n';
import { useMemo } from 'react';

import { animalTypeOptions, exhibitionOptions, priorityOptions } from '../Forms/AnnouncementsForm/data';
import { regionalMapper } from 'utils/mapper';

const validateSchema = props =>
  yup.object().shape({
    title: yup
      .string()
      .max(80, t('global.validation.max-character-exceeded'))
      .required(t('global.status.required-field')),
    shortDescription: yup
      .string()
      .max(80, t('global.validation.max-character-exceeded'))
      .required(t('global.status.required-field')),
    description: yup
      .string()
      .required(t('global.status.required-field')),
    priority: yup
      .object()
      .required(t('global.status.required-field')),
    regionals: yup
      .array()
      .required(t('global.status.required-field')),
    countryCode: yup
      .array()
      .required(t('global.status.required-field')),
    centerList: yup
      .array()
      .required(t('global.status.required-field')),
  });

export default function EditAnnouncements(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const {
    regionals,
    history,
    setEditAnnouncement,
    selected,
    setSelected,
    isValidating,
    userToken,
  } = props;

  const isEditing = selected && Object.keys(selected).length > 0;

  const successMessage = isEditing
    ? t('global.validation.record-changes-saved')
    : t('global.validation.record-created');

  const modalErrorMessage = t('global.validation.record-error');

  const initialValues = {
    id: 0,
    title: '',
    description: '',
    shortDescription: '',
    sourceName: '',
    sourceUrl: '',
    priority: {
      label: t('announcements.edit.defaultValue.priority.label'),
      priority: 'MODERATE',
    },
    showTo: {
      label: t('announcements.edit.defaultValue.showTo.label'),
      value: 'T',
      isDefault: true,
    },
    animalType: {
      label: t('announcements.edit.defaultValue.animalType.label'),
      value: 'all',
      isDefault: true,
    },
    regionals: [],
    countryCode: [],
    centerList: [],
    vendors: [],
    files: [],
  };

  const formData = values => {
    const hasAllInclude = property => {
      return (values[property] || []).find(
        attribute => attribute.id === 'all',
      );
    };

    const getAllValue = property => {
      const allInclude = hasAllInclude(property);

      return allInclude ? allInclude.values : values[property];
    };

    const setCenterList = () => {
      const allInclude = hasAllInclude('centerList');

      const centerListValue = getAllValue('centerList');

      if (!allInclude) {
        values['regionals'] = values['regionals'].map(regional => ({
          ...regional,
          centerList: regional.centerList.filter(center =>
            centerListValue.find(
              centerValue => centerValue.id === center.id,
            ),
          ),
        }));
      }

      return centerListValue;
    };

    values['countryCode'] = getAllValue('countryCode');
    values['regionals'] = getAllValue('regionals');
    values['centerList'] = setCenterList();
    values['animalType'] = values['animalType'].value;

    delete values['date'];

    return {
      ...values,
      animalType: values['animalType'].value,
      priority: values['priority'].priority,
      showTo: values['showTo'].value,

      files: files.map(e => {
        let fileInput = {};
        if (typeof e === 'object') {
          fileInput = e;
        } else {
          fileInput.name = e;
        }
        return fileInput;
      }),
    };
  };

  function submitForm(values, { setSubmitting }) {
    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await putAnnouncement(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error);
        setErrorMessage(error.message);
      } finally {
        setSubmitting(false);
      }
    }

    sendData(formData(values));
  }

  function getTitleLabel(args) {
    return args
      ? t('announcements.edit.title')
      : t('announcements.add.title');
  }

  const normalizeDefaultValues = useMemo(() => {
    if (!isEditing) return null;

    return {
      ...selected,
      animalType: animalTypeOptions.find(animal => animal.value === selected.animalType),
      showTo: exhibitionOptions.find(profile => profile.value === selected.showTo),
      centerList: selected.regionals.map(regional => regional.centerList).flat(),
      priority: priorityOptions.find(level => level.priority === selected.priority),
      regionals: selected.regionals.map(regional => ({ ...regional, label: regionalMapper(regional.name) })),
    };
  }, [selected, isEditing]);

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() => setEditAnnouncement(false)}
        path={[
          t('announcements.bc.announcements'),
          getTitleLabel(isEditing),
        ]}
      />

      <Top>
        <h2>{getTitleLabel(isEditing)}</h2>
      </Top>

      <Container style={{ padding: '16px' }}>
        <Formik
          initialValues={isEditing ? normalizeDefaultValues : initialValues}
          validate={validate(validateSchema)}
          onSubmit={submitForm}
          render={formikProps => (
            <AnnouncementsForm
              {...formikProps}
              isValidating={isValidating}
              regionals={regionals}
              message={errorMessage}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              selected={selected}
              success={success}
              isEditing={isEditing}
              setFiles={setFiles}
              files={files}
              uploadErrorMessage={uploadErrorMessage}
              setUploadErrorMessage={setUploadErrorMessage}
              userToken={userToken}
              {...props}
            />
          )}
        />
      </Container>

      {/* Upload error message */}
      <Toaster
        className="toaster__notification"
        isVisible={!!uploadErrorMessage}
        color="danger"
        duration={6}
        delay={0}
      >
        <span className="icon icon-exclamation-triangle" />{' '}
        {uploadErrorMessage}
      </Toaster>
      {/* \ Upload error message */}

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
          setSelected({});
          setEditAnnouncement(false);
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>
            {success
              ? t('global.validation.success')
              : t('global.validation.error')}
          </h4>
        </div>

        <p>{success ? successMessage : modalErrorMessage}</p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
