// @flow

import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik } from 'formik';
import {
  AgroLoginForm,
  NextLoginForm,
} from 'containers/OnePage/Forms/LoginForm';
import { Button } from '@zendeskgarden/react-buttons';
import { cleanUp } from 'components/MaskedInput/mask';
import { showAlert, hideAlert } from 'components/Alert/actions';
import {
  Heading,
  Subheading,
  TitleBlock,
} from 'components/SideBox/SideBox.styled';
import { AccessLink } from './SignIn.styled';
import t from 'components/i18n';

const schema = yup.object().shape({
  documentNumber: yup
    .string()
    .min(11, t('global.validation.min-character-length', { length: 11 }))
    .max(18, t('global.validation.max-character-length', { length: 18 }))
    .required(t('global.status.required-field'))
    .typeError(t('global.validation.must-specify-value')),
  password: yup
    .string()
    .required(t('global.status.required-field'))
    .min(6, t('global.validation.min-character-length', { length: 6 })),
});

const INITIAL_VALUE = {
  documentNumber: '',
  password: '',
};

const schemaNext = yup.object().shape({
  register: yup
    .string()
    .min(3, t('global.validation.min-character-length', { length: 3 }))
    .max(11, t('global.validation.max-character-length', { length: 11 }))
    .required(t('global.status.required-field'))
    .typeError(t('global.validation.must-specify-value')),
  password: yup
    .string()
    .required(t('global.status.required-field'))
    .min(1, t('global.validation.min-character-length', { length: 1 })),
});

const INITIAL_VALUE_NEXT = {
  register: '',
  password: '',
};

function SignIn(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [userType, setUserType] = useState(null); // useState(null)
  const [selectUser, setSelectUser] = useState(true); // useState(true)
  const { displayLoginBox, setDisplayLoginBox } = props;

  function submitForm(values, actions) {
    const payload = {
      ...values,
      documentNumber: cleanUp(values.documentNumber),
    };

    props.userSignIn(payload);
  }

  function submitFormNext(values, actions) {
    // if this results in a empty string, than it probably does not need a clean up
    const registration = cleanUp(values.register) || values.register;

    const payload = {
      ...values,
      registration,
    };

    props.userSignIn(payload);
  }

  useEffect(() => {
    setDisplayLoginBox(false);
  }, [setDisplayLoginBox]);

  useEffect(() => {
    if (!displayLoginBox) {
      setUserType(null); // @TODO: !!! =>  setUserType(null);
      setSelectUser(true); //  @TODO: !!! => setSelectUser(true);
      props.setUseTertiaryColors(false);
    }
  }, [displayLoginBox, props]);

  useEffect(() => {
    if (userType === 2) {
      props.setUseTertiaryColors(true);
    }
  }, [props, userType]);

  if (selectUser) {
    return (
      <div>
        <TitleBlock>
          <Heading>{t('lp.modal.login.title')}</Heading>
          <Subheading>{t('lp.modal.login.subtitle')}</Subheading>
        </TitleBlock>

        <div style={{ padding: '67px 0 20px 0' }}>
          <Button
            onClick={() => {
              setUserType(1);
              setSelectUser(false);
            }}
            primary
            stretched
          >
            {t('lp.modal.login.buttonPrimary.agro')}
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              setUserType(2);
              setSelectUser(false);
            }}
            stretched
            className="tertiary"
          >
            {t('lp.modal.login.buttonPrimary.next')}
          </Button>
        </div>
      </div>
    );
  }

  if (userType === 1) {
    return (
      <Formik
        validationSchema={schema}
        initialValues={INITIAL_VALUE}
        handleSubmit
        onSubmit={submitForm}
        validateOnBlur
        render={formikProps => (
          <AgroLoginForm
            {...formikProps}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        )}
      />
    );
  }

  if (userType === 2) {
    return (
      <Formik
        validationSchema={schemaNext}
        initialValues={INITIAL_VALUE_NEXT}
        handleSubmit
        onSubmit={submitFormNext}
        validateOnBlur
        render={formikProps => (
          <NextLoginForm
            showPassword={showPassword}
            setShowPassword={setShowPassword}
            directSignIn={props.userSignIn}
            showAlert={props.showAlert}
            {...formikProps}
          />
        )}
      />
    );
  }

  return false;
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showAlert,
      hideAlert,
    },
    dispatch,
  );
}

export default hot(
  connect(mapStateToProps, mapDispatchToProps)(SignIn),
);
