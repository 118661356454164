import styled from 'styled-components';
import {
  colorGrayLighter,
  fontFamilyHeading,
} from 'components/GlobalStyles/variables';

export const HeadingStyled = styled.div`
  max-width: 630px;

  .center & {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const PageTitle = styled.h5`
  font-family: ${fontFamilyHeading};
  font-size: 24px;
  line-height: 32px;
  font-weight: normal !important;
`;

export const SubHeading = styled.h6`
  font-family: ${fontFamilyHeading};
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: ${colorGrayLighter}!important;
  margin-bottom: 32px !important;
`;
