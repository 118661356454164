import React, { useEffect } from 'react';
import AddDeceased from './AddDeceased';

export default function DeceasedConfirmation(props) {
  useEffect(() => {
    props.setSendToConfirmation(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setSendToConfirmation]);

  return (
    <AddDeceased
      {...props}
      backToForm={() => {
        props.setSendToConfirmation(false);
      }}
    />
  );
}
