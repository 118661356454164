// @flow

import React, { useState, useMemo, useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { cleanUp } from 'components/MaskedInput/mask';
import Modal from 'components/Forms/FormModal/Modal';
import AccountAuthenticateForm from 'containers/AgroBRF/Forms/AccountAuthenticateForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { verifyAccount } from '../../../service';
import t from 'components/i18n';

const validateSchema = () =>
  yup.object().shape({
    documentNumber: yup
      .string()
      .min(11, t('global.validation.min-character-length', { length: 11 }))
      .max(18, t('global.validation.max-character-length', { length: 18 }))
      .required(t('global.status.required-field'))
      .typeError(t('global.validation.must-specify-value')),
    mobileNumber: yup
      .string()
      .required(t('global.status.required-field'))
      .min(14, t('global.validation.min-character-length', { length: 14 }))
      .typeError(t('global.validation.must-specify-value')),
  });

export default function Authenticate({
  match: { params },
  history,
  user,
  setEditUser,
  setSelected,
  isValidating,
  setStage,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      setStage(2);
    }
  }, [setStage, success]);

  const successMessage = useMemo(
    () => t('global.validation.info-success-save'),
    [],
  );

  const modalErrorMessage = useMemo(
    () =>
      t('global.validation.error-saving-info'),
    [],
  );

  const initialValues = useMemo(
    () => ({
      documentNumber: null,
      mobileNumber: null,
    }),
    [],
  );

  function submitForm(
    values,
    { setSubmitting, setErrors, isValidating },
  ) {
    const formData = {
      documentNumber: cleanUp(values.documentNumber),
      mobileNumber: cleanUp(values.mobileNumber),
      isForgetAccount: true,
    };

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await verifyAccount(val);
        if (!response.success) {
          setErrorMessage(response.message);
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }

    sendData(formData);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate(validateSchema)}
        onSubmit={submitForm}
        render={formikProps => (
          <AccountAuthenticateForm
            {...formikProps}
            isValidating={isValidating}
            message={errorMessage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            success={success}
            history={history}
            setStage={setStage}
            user={user}
            {...props}
          />
        )}
      />

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>
          {success
            ? successMessage
            : errorMessage || modalErrorMessage}
        </p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
