import React, { useContext } from 'react';
import moment from 'moment';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { List, ListItem } from 'components/List/List';
import NotificationBox from 'components/NotificationBox';
// Contexts
import { SwineContext } from 'shared/contexts';
// Styles
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import t from 'components/i18n';

export default function DeceasedConfirmation(props) {
  const swineDeceasedContext = useContext(SwineContext);
  const { selectedDeceased, selectedHouse } = swineDeceasedContext;
  const {
    house,
    deceasedDiscardedDate,
    swineDeceasedDiscarded,
    origin,
    isValid,
    isSubmitting,
  } = props;

  const listArray = [];
  const deceased =
    Object.keys(selectedDeceased).length > 0
      ? selectedDeceased.swineDeceasedDiscarded
      : swineDeceasedDiscarded;

  if (Object.keys(selectedDeceased).length > 0) {
    listArray.push(
      {
        description: selectedDeceased.house.label || t("agro.swine-deceased.info.house", { value: selectedHouse }),
        icon: 'pin',
      },
      {
        description: moment(
          selectedDeceased.deceasedDiscardedDate,
        ).format('DD ̸̸  MM ̸̸  YYYY'),
        icon: 'calendar',
      },
    );
  } else {
    listArray.push(
      { description: house && house.label, icon: 'pin' },
      {
        description: moment(deceasedDiscardedDate).format(
          'DD ̸̸  MM ̸̸  YYYY',
        ),
        icon: 'calendar',
      },
      {
        description: t('agro.swine-deceased.info.tatoo', { value: origin }),
        icon: 'tag',
      },
    );
  }

  function displayTypesObjects(
    mortality,
    mOrigin,
    maleQuantity,
    femaleQuantity,
  ) {
    return [
      {
        description: mortality.description || mortality,
        icon: 'pig-alt',
      },
      {
        description: t('agro.swine-deceased.info.tatoo', { value: mOrigin }),
        icon: false,
      },
      { description: t('agro.swine-deceased.info.death-female', { value: femaleQuantity }), icon: false },
      { description: t('agro.swine-deceased.info.death-male', { value: maleQuantity }), icon: false },
    ];
  }

  function displayListItem(description, icon, index) {
    return (
      <ListItem
        key={index}
        className="confirm-list__item animated fadeIn"
      >
        <div className="icon-wrapper">
          <span className={`icon icon-${icon}`} />
        </div>
        <div className="list-item-content">
          <div className="list-item-description">{description}</div>
        </div>
      </ListItem>
    );
  }

  return (
    <div className="confirmation">
      <p className="content-heading">{t("agro.swine-deceased.confirmation.title")}</p>
      <List className="confirm-list">
        {listArray.map((item, index) =>
          displayListItem(item.description, item.icon, index),
        )}

        {deceased.map((item, index) => {
          return displayTypesObjects(
            item.mortality ||
              item.mortalityTypeId ||
              item.discardedTypeId,
            item.origin,
            item.maleQuantity,
            item.femaleQuantity,
          ).map((i, idx) =>
            displayListItem(i.description, i.icon, idx),
          );
        })}
      </List>
      {selectedDeceased.isEditable && (
        <NotificationBox
          className="notification notification--info"
          type="info"
        >
          {t('agro.swine-deceased.update-deadline')}
        </NotificationBox>
      )}
      <Separator />
      <BtnGroup className="button-group--bottom">
        {selectedDeceased.isEditable ? (
          <>
            <Button
              key="submit"
              onClick={() => props.onSubmit()}
              disabled={isSubmitting || !isValid}
            >
              {t("global.button.save")}
            </Button>
            <Button
              key="edit"
              onClick={() => props.backToForm()}
              className="white"
            >
              {t("global.button.edit")}
            </Button>
          </>
        ) : (
          <Button
            key="back"
            onClick={() => props.goTo('deceasedSummary')}
            disabled={isSubmitting}
          >
            {t('global.button.back')}
          </Button>
        )}
      </BtnGroup>
    </div>
  );
}
