const inputNumber = str => {
  if (str === null || str === undefined) return null;
  const num = str.replace(/[^0-9]/g, '');
  return parseInt(num, 10) || 0;
};

const formatWeight = weight =>
  (inputNumber(weight) / 1000)
    .toFixed(3)
    .toString()
    .replace('.', ',');

const convertWeightToNumber = weight => inputNumber(weight) / 1000;

const convertTemperatureToNumber = temp => inputNumber(temp) / 10;

const convertToNumber = string => inputNumber(string);

export default formatWeight;
export {
  convertWeightToNumber,
  inputNumber,
  convertToNumber,
  convertTemperatureToNumber,
};
