import styled from 'styled-components';
import {
  colorClear,
  colorGrayDarkest,
} from 'components/GlobalStyles/variables';

export const DownloadButton = styled.a`
  margin: 0 8px 6px 0;
  border-radius: 16px;
  border: none;
  background-color: ${colorGrayDarkest};
  color: ${colorClear} !important;
  transition: 0.2s width ease-in;
  letter-spacing: 0.25px;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    font-size: 20px;
    margin-left: 8px;
  }
`;
