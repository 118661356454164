import React from 'react';
import {
  PageTitle,
  SubHeading,
  HeadingStyled,
} from './PageTitle.styled';

export default function Heading(props) {
  return (
    <HeadingStyled>
      <div className="container-detail" />
      <PageTitle>{props.pageTitle}</PageTitle>
      <SubHeading>{props.subHeading}</SubHeading>
      {props.paragraph && <p className="info">{props.paragraph}</p>}
    </HeadingStyled>
  );
}
