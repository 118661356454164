import styled from 'styled-components';
import {
  colorGrayLight,
  colorGrayDarkest,
  colorGrayLightest,
  colorGrayUltraLight,
  colorClear,
  breakpointTablet,
  fontFamilyTextBold,
  colorPrimary,
} from 'components/GlobalStyles/variables';

export const CalendarStyled = styled.section`
  width: 100%;
  overflow-x: hidden;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  p {
    color: ${colorGrayDarkest};
  }
`;

export const Day = styled.div`
  text-align: center;
  margin: 0 0 32px;
  font-size: 11px;

  color: ${colorGrayLight};
  cursor: pointer;
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  pointer-events: ${props => props.isDisabled && 'none'};

  .week-day {
    font-family: ${fontFamilyTextBold};
    text-transform: uppercase;
    margin-bottom: 8px;
    color: ${props => props.isDisabled && colorGrayLightest};
  }

  .month-day {
    position: relative;
    font-size: 12px;
    color: ${props => {
    if (props.isActive) {
      return colorClear;
    }
    return props.isDisabled && colorGrayLightest;
  }};
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    transition: 0.3s ease-out background-color, 0.3s ease-out opacity;
    background-color: ${props =>
      props.isActive
        ? props.bgColor || colorGrayDarkest
        : colorClear};

    &:after {
      content: '';
      position: absolute;
      display: ${props => (props.isMarked ? 'block' : 'none')};
      border-radius: 50%;
      background-color: ${props => {
    if (props.isActive) {
      return colorClear;
    }
    return props.bgColor || colorPrimary;
  }};
      width: 5px;
      height: 5px;
      z-index: 10;
      top: 26px;
      left: 15px;
    }

    @media (min-width: ${breakpointTablet}px) {
      font-size: 14px;
      width: 35px;
      height: 35px;
      line-height: 35px;
    }
  }
`;

export const ArrowGroup = styled.div`
  position: relative;
  margin: -8px 0;

  button {
    background: ${colorGrayUltraLight};
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 4px;
    cursor: pointer;
    font-size: 30px !important;

    &[disabled] {
      opacity: 0.4;
      .icon {
        color: ${colorGrayLightest};
      }
    }

    &:active {
      opacity: 0.75;
    }

    &:hover {
      .icon {
        color: ${colorGrayDarkest};
      }
    }
  }
`;
