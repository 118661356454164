import React from 'react';

import CardWrapper from 'components/CardWrapper';

import EditOrientation from './EditOrientation';
import t from 'components/i18n';

export default function OrientationEditCard(props) {
  const { getDetails, data, setDisplayOrientation, vendor } = props;

  return (
    <CardWrapper
      title={t('next.visitList.orientationEditCard.title', { value: vendor.vendorName })}
    >
      <p>{t('next.visitList.orientationEditCard.subtitle')}</p>

      <EditOrientation
        {...data}
        getDetails={getDetails}
        onSubmit={setDisplayOrientation}
      />
    </CardWrapper>
  );
}
