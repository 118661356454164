import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  colorClear,
  colorDanger,
  boxShadow,
  colorSuccess,
  colorInfo,
  colorGrayDarkest,
  fontFamilyHeading,
  fontFamilyTextBold,
  colorGrayUltraLight,
} from 'components/GlobalStyles/variables';

const red = `linear-gradient( 135deg, rgba(210,38,46,1) 37%,  rgba(147,50,142,1) 100% )`;

const green = `linear-gradient( 135deg, ${colorSuccess} 37%,  ${colorInfo} 100% )`;

export const Resume = styled.section`
  position: relative;
  margin: 0 0 16px 0;
  color: ${colorClear};
  background: ${props => {
    switch (props.color) {
      case 'red':
        return red;
      case 'green':
        return green;
      case 'purple':
        return '#93328E';
      default:
        return props.color;
    }
  }};
  padding: 14px 16px;
  border-radius: 8px;
  box-shadow: ${boxShadow};
  overflow: hidden;

  .label {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    margin: 0;
  }

  .main {
    font-size: 24px;
    line-height: 32px;
    font-family: ${fontFamilyHeading};
  }
`;

export const Content = styled.div`
  display: ${props => (props.flexP ? 'flex' : 'block')};

  p {
    display: flex;
    justify-content: space-between;
    line-height: 16px;
    margin: 0 0 7px;
  }

  strong {
    line-height: 20px;
    letter-spacing: 0.15px;
  }
`;

export const Column = styled.div`
  width: 50%;
  font-size: 14px;
  line-height: 21px;

  p {
    justify-content: flex-start;
    line-height: 21px;
    margin: 0 0 14px;
  }

  strong {
    margin-right: 5px;
  }
`;

export const FooterContent = styled.footer`
  display: flex;
  background-color: ${rgba(colorGrayDarkest, 0.25)};
  margin: 0 -16px -14px;
  padding: 16px;

  p {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    color: ${colorGrayUltraLight};
  }

  span {
    font-size: 14px;
    color: ${colorClear};
  }

  strong {
    display: block;
  }
`;

export const CardBadge = styled.div`
  display: block;
  font-family: ${fontFamilyTextBold};
  color: ${colorDanger};
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 14px;
  right: 16px;
  border-radius: 12px;
  padding: 4px 11px;
  background-color: ${colorClear};
`;

export const Separator = styled.hr`
  margin: 10px 0 15px 0;
  opacity: 0.12;
`;
