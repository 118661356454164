import '@zendeskgarden/react-dropdowns/dist/styles.css';
import '@zendeskgarden/react-buttons/dist/styles.css';
import '@zendeskgarden/react-forms/dist/styles.css';
import '@zendeskgarden/react-grid/dist/styles.css';
import '@zendeskgarden/react-modals/dist/styles.css';
import '@zendeskgarden/react-tooltips/dist/styles.css';
import '@zendeskgarden/react-notifications/dist/styles.css';
import '@zendeskgarden/react-tables/dist/styles.css';
import '@zendeskgarden/react-pagination/dist/styles.css';
import 'animate.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import GlobalStyles from './GlobalStyles';

export default GlobalStyles;
