// @flow

import React from 'react';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import t from 'components/i18n';

import {
  Fade,
  SlideDown,
  SlideLeft,
  SlideRight,
  SlideUp,
} from './Transition.styled';

import type { TTransitionProps } from './typings';

const classNames = {
  fade: 'fade',
  slideDown: 'slide-down',
  slideLeft: 'slide-left',
  slideRight: 'slide-right',
  slideUp: 'slide-up',
};

const transitions = {
  fade: Fade,
  slideDown: SlideDown,
  slideLeft: SlideLeft,
  slideRight: SlideRight,
  slideUp: SlideUp,
};

const Transition = (props: TTransitionProps) => {
  const { children, className, style, transition, ...rest } = props;

  const Component = transitions[transition];

  if (!Component) {
    console.error(t('transition.invalid', { value: transition })); // eslint-disable-line no-console
    return null;
  }

  return (
    <TransitionGroup className={className} style={style}>
      {React.Children.map(children, child => (
        <CSSTransition classNames={classNames[transition]} {...rest}>
          <Component>{child}</Component>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

Transition.defaultProps = {
  appear: false,
  enter: true,
  exit: true,
  style: null,
  timeout: 300,
  transition: 'fade',
};

export default Transition;
