import React, { useState } from 'react';
import {
  Field,
  Message,
  MediaFigure,
  MediaInput,
  Label,
} from '@zendeskgarden/react-forms';
import {
  getValidationMessage,
  getValidation,
  getTextfieldClass,
} from 'shared/utils/Helpers';
import { EyeIcon } from 'components/Forms/Forms.styled';

export default function PasswordField({
  field,
  placeholder,
  onBlur,
  onChange,
  values,
  touched,
  errors,
  label,
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Field
      className={getTextfieldClass(
        getValidation(values[field], errors, field, !touched),
      )}
    >
      <Label>{label}</Label>
      <MediaInput
        name={field}
        type="password"
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        validation={getValidation(
          values[field],
          errors,
          field,
          !touched[field],
        )}
      />
      {values[field] && (
        <MediaFigure>
          {!showPassword && (
            <EyeIcon
              className="icon-visibility-enabled"
              onClick={() => setShowPassword(true)}
            />
          )}

          {showPassword && (
            <EyeIcon
              className="icon-visibility-disabled"
              onClick={() => setShowPassword(false)}
            />
          )}
        </MediaFigure>
      )}
      <Message
        validation={getValidation(
          values[field],
          errors,
          field,
          !touched[field],
        )}
      >
        {getValidationMessage(field, errors, !touched[field])}
      </Message>
    </Field>
  );
}
