import React from 'react';
import { Switch } from 'react-router-dom';
import RoutePrivate from 'components/RoutePrivate';
import RoutePublic from 'components/RoutePublic';
import Private from 'containers/Private';

// Unlogged Home
import HomePage from 'containers/OnePage/HomePage';
import BecomeAMember from 'containers/OnePage/BecomeAMember';

// AgroBRF
import FullNewsItem from 'containers/AgroBRF/News/FullNewsItem';
import AnnouncementsDetails from 'containers/AgroBRF/Announcements/AnnouncementsDetails';

// Shared containers
import PolicyTermsMobile from 'containers/SharedContainers/PolicyTerms/PolicyTermsMobile';
import PolicyTermsExcerptMobile from 'containers/SharedContainers/PolicyTerms/PolicyTermsExcerptMobile';

import NotFound from 'components/NotFound';
import agroRouteList from './agroRoutes';
import nextRouteList from './nextRoutes';

export default function RouteList(props) {
  const { user, routes } = props;

  const publicRoutes = [
    { route: 'MAIN' },
    { route: 'SIGNIN' },
    { route: 'SIGNUP' },
    { route: 'PASSWORD_RECOVERY' },
    { route: 'PASSWORD_RECOVERY_NEXT' },
    { route: 'BECOME_A_MEMBER', component: BecomeAMember },
  ];

  const agroRoutes = agroRouteList(user);

  const nextRoutes = nextRouteList(user);

  function getPublicRoute(route, component = HomePage, index) {
    return (
      <RoutePublic
        key={index}
        isAuthenticated={user.isAuthenticated}
        path={routes[route]}
        component={component}
        exact
      />
    );
  }

  function getPrivateRoute(route, path, component, id, index) {
    const getComponent = givenId => {
      if (!id || user.type !== 'F') {
        return component;
      }

      if (
        user.allowRoutes.findIndex(item => Number(item.id) === id) >
        -1
      ) {
        return component;
      }

      return null;
    };

    return (
      <RoutePrivate
        key={index}
        user={user}
        isAuthenticated={user.isAuthenticated}
        path={path ? `${routes[route]}${path}` : routes[route]}
        layout={Private}
        component={getComponent(id)}
      />
    );
  }

  return (
    <Switch>
      {/* HOMEPAGE */}
      {publicRoutes.map((item, index) =>
        getPublicRoute(item.route, item.component, index),
      )}
      {/* \ HOMEPAGE */}

      {/* AGROBRF */}
      {agroRoutes.map((item, index) =>
        getPrivateRoute(
          item.route,
          item.path,
          item.component,
          item.id,
          index,
        ),
      )}

      {/* NEXTBRF */}
      {nextRoutes.map((item, index) =>
        getPrivateRoute(
          item.route,
          item.path,
          item.component,
          item.id,
          index,
        ),
      )}

      <RoutePublic
        user={user}
        isAuthenticated={user.isAuthenticated}
        path={`${routes.MOBILE_OPEN_NEWS}/:id`}
        component={FullNewsItem}
      />

      <RoutePublic
        user={user}
        isAuthenticated={user.isAuthenticated}
        path={`${routes.MOBILE_ANNOUNCEMENT}/:id`}
        component={AnnouncementsDetails}
      />

      <RoutePublic
        path={`${routes.MOBILE_POLICY}`}
        component={PolicyTermsMobile}
      />

      <RoutePublic
        path={`${routes.MOBILE_POLICY_EXCERPT}`}
        component={PolicyTermsExcerptMobile}
      />

      <RoutePrivate component={NotFound} />

      {/* \ AGROBRF */}
    </Switch>
  );
}
