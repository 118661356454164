import React from 'react';
import AddDeceased from 'containers/AgroBRF/Forms/SwineDeceased/AddDeceased';
import EditDeceased from 'containers/AgroBRF/Forms/SwineDeceased/EditDeceased';
import AddMedication from 'containers/AgroBRF/Forms/SwineMedication/AddMedication';
import EditMedication from 'containers/AgroBRF/Forms/SwineMedication/EditMedication';
import DeceasedConfirmation from 'containers/AgroBRF/Forms/SwineDeceased/ConfirmWrapper';
import SwineMortalityList from './Mortality/SwineMortalityList';
import SwineMedicationList from './Medication/SwineMedicationList';

export default function SwineRecordContent({
  data,
  selectedKey,
  setSelectedKey,
  sendToConfirmation,
  setSendToConfirmation,
  updateItems,
  setUpdateItems,
  removed,
  setRemoved,
  success,
  setSuccess,
  setDisplayExcludeModal,
  setMedicationResponse,
  setFavoriteCandidate,
}) {
  const defaultProps = {
    houses: data && data.houseInformation,
    flockID: data.flockID,
    goTo: e => setSelectedKey(e),
    sendToConfirmation: sendToConfirmation,
    setSendToConfirmation: setSendToConfirmation,
    setDisplayExcludeModal: setDisplayExcludeModal,
    setMedicationResponse: setMedicationResponse,
    success: success,
    setSuccess: setSuccess,
    removed: removed,
    setRemoved: setRemoved,
    setFavoriteCandidate: setFavoriteCandidate,
  };

  const listPros = {
    flockID: data.flockID,
    updateItems: updateItems,
    setUpdateItems: setUpdateItems,
    setSelectedKey: setSelectedKey,
    removed: removed,
    setRemoved: setRemoved,
    setDisplayExcludeModal: setDisplayExcludeModal,
  };

  const innerContent = selected => {
    switch (selected) {
      case 'addDeceased':
        return <AddDeceased {...defaultProps} />;
        break;

      case 'editDeceased':
        return <EditDeceased {...defaultProps} />;
        break;

      case 'deceasedConfirmation':
        return <DeceasedConfirmation {...defaultProps} />;
        break;

      case 'addMedication':
        return <AddMedication {...defaultProps} />;
        break;

      case 'editMedication':
        return <EditMedication {...defaultProps} isEditing />;
        break;

      case 'medicationDetails':
        return <EditMedication {...defaultProps} />;
        break;

      case 'medicationSummary':
        return <SwineMedicationList {...listPros} />;
        break;

      default:
        return <SwineMortalityList data={data} {...listPros} />;
    }
  };

  return (
    <div className="flock-details-content">
      {innerContent(selectedKey)}
    </div>
  );
}
