import React, { useState, useEffect } from 'react';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import NoContentTab from 'components/NoContentTab';
import { TopMenuStyled } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import Checklists from 'containers/NextBRF/Checklists';
import ChecklistsNewLayout from 'containers/NextBRF/Checklists/ChecklistsNewLayout';
import qs from 'qs';
import t from 'components/i18n';
import { Container } from './StyledNextFlock.styled';
import NextFlockList from './NextFlockList';

export default function NextFlockTopMenu({
  history,
  isLoading,
  user,
  match,
  data,
  isNewLayout,
  isGtMobile,
  isMobile,
  isTablet,
}) {
  const [active, setActive] = useState(null);

  const { tab } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    setActive(tab ? parseInt(tab, 10) : 0);
  }, [match, tab]);

  // const isTurkish = user.countryCodes.includes('TR');

  const Tabs = [
    {
      label: t('next.flock.flockListTopMenu.tabs.flocks'),
      key: 0,
      url: '',
      visible:
        user.allowRoutes.length > 0
          ? user.allowRoutes.findIndex(item => item.id === '14') > -1
          : true,
    },
    {
      label: t('next.flock.flockListTopMenu.tabs.checklists'),
      key: 1,
      url: '',
      visible: true,
    },
    {
      label: t('next.flock.flockListTopMenu.tabs.performance'),
      key: 2,
      url: '',
      visible: false,
    },
  ];

  return (
    <TopMenuStyled>
      <Header>
        {Tabs.map(
          item =>
            item.visible && (
              <TabHeaderHolder
                key={item.key}
                onClick={() => setActive(item.key)}
                className={active === item.key ? 'active' : ''}
              >
                <div className="label">{item.label}</div>
                <div className="bar" />
              </TabHeaderHolder>
            ),
        )}
      </Header>

      {active === 0 && (
        <NextFlockList
          data={data}
          user={user}
          history={history}
          isLoading={isLoading}
          isMobile={isMobile}
          isTablet={isTablet}
          isGtMobile={isGtMobile}
        />
      )}

      {active === 1 &&
        (isNewLayout ? (
          <ChecklistsNewLayout
            data={data}
            history={history}
            isGtMobile={isGtMobile}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        ) : (
          <Checklists data={data} history={history} />
        ))}

      {active === 2 && (
        <Container>
          <NoContentTab icon="icon-chart-line" hideButton>
            <p>
              {t('next.flock.flockListTopMenu.tabs.no-content.title')}
              <br />{' '}
              <span className="lighter">
                {t(
                  'next.flock.flockListTopMenu.tabs.no-content.subtitle',
                )}
              </span>
            </p>
          </NoContentTab>
        </Container>
      )}
    </TopMenuStyled>
  );
}
