import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  colorPrimary,
  colorClear,
} from 'components/GlobalStyles/variables';

export const BadgePillStyled = styled.span`
  display: inline-block;
  color: ${colorClear};
  background-color: ${props => props.bgColor || colorPrimary};
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 10px;
  line-height: 16px;
  border-radius: ${DEFAULT_SIZE}px;
  text-align: center;
  padding: 0 8px;
  margin-left: 40px;
  margin-top: 3px;
  min-width: ${props => props.minWidth || '104px'};
  height: 16px;
`;
