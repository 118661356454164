import t from "components/i18n";

export const tableHeader = [
  {
    width: 40,
    title: t("next.vendorsTable.tabs.user"),
  },
  {
    width: 15,
    title: t("next.vendorsTable.tabs.anymalType"),
  },
  {
    width: 15,
    title: t("next.vendorsTable.tabs.flock"),
  },
  {
    width: 15,
    title: t("next.vendorsTable.tabs.averageMortality"),
  },
  {
    width: 15,
    title: t("next.vendorsTable.tabs.criticality"),
  },
];
