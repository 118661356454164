import { colorClear } from 'components/GlobalStyles/variables';
import styled from 'styled-components';

export const NextSwine = styled.section`
  position: relative;
  padding: 20px;

  > div:not(.favorite) {
    max-width: 652px;
  }

  .bordered-container {
    background-color: ${colorClear};
  }
`;
