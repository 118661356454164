import styled from 'styled-components';
import FSTrumanLight from 'components/GlobalStyles/assets/fonts/fs_truman-light-webfont.woff';
import FSTrumanRegular from 'components/GlobalStyles/assets/fonts/fs_truman-regular-webfont.woff';
import FSTrumanRegular2 from 'components/GlobalStyles/assets/fonts/fs_truman-regular-webfont.woff2';
import FSTrumanLight2 from 'components/GlobalStyles/assets/fonts/fs_truman-light-webfont.woff2';
import SFProRegular from 'components/GlobalStyles/assets/fonts/sf_pro-regular.woff';
import SFProMedium from 'components/GlobalStyles/assets/fonts/sf_pro-medium.woff';
import AgroPack from 'components/GlobalStyles/assets/fonts/AgroPack.woff';
import AgroPackTTF from 'components/GlobalStyles/assets/fonts/AgroPack.ttf';
import {
  appColor,
  fontFamilyHeading,
  fontFamilyText,
  colorGrayDarker,
  breakpointDesktop,
  breakpointTablet,
  fontFamilyTextBold,
} from './variables';
import 'components/GlobalStyles/assets/styles/AgroPack.css';

const GlobalStyles = styled.div`
  /** icon library */
  @font-face {
    font-family: 'AgroPack';
    src:  url('${AgroPack}') format('woff2'),
      url('${AgroPackTTF}') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'fs_trumanlight';
    src: url('${FSTrumanLight2}') format('woff2'),
      url('${FSTrumanLight}') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'fs_trumanregular';
    src: url('${FSTrumanRegular2}') format('woff2'),
      url('${FSTrumanRegular}') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'sf_proregular';
    src: url('${SFProRegular}') format('truetype');      
    font-weight: normal;
    font-style: normal;
  } 

  @font-face {
    font-family: 'sf_probold';
    src: url('${SFProMedium}') format('truetype');      
    font-weight: normal;
    font-style: normal;
  } 

  a {
    color: ${appColor};
    text-decoration: none;

    &.disabled {
      pointer-events: none;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0 0 4px 0;
    padding: 0;
    color: ${colorGrayDarker};
  }

  h1, h2, h3, h4 {
    font-family: ${fontFamilyHeading};
  }

  .h1 {
    font-size: 96px;
  }

  p {
    font-family: ${fontFamilyText};
  }

  strong {
    font-family: ${fontFamilyTextBold};
    font-weight: normal;
  }

  .flex {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &.wrap {
      flex-wrap: wrap;
    }

    &.center {
      align-items: center;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  .unstyled-button {
    background-color: transparent;
    border: 0 none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    outline: none;
  }

  /* Responsive utilities */
  .hidden-phone {
    display: none;

    @media (min-width: ${breakpointTablet}px) {
      display: inherit;     
    }
  }

  .visible-tablet {
    display: none;

    @media (min-width: ${breakpointTablet}px) and (max-width: ${breakpointDesktop}px) {
      display: inherit;
    }
  }

  .visible-desktop {
    display: none;

    @media (min-width: ${breakpointDesktop}px) {
      display: inherit;
    }
  }

  .hidden-desktop {
    display: none;

    @media (min-width: ${breakpointDesktop}px) {
      display: inherit;
    }
  }

  .visible-phone {
    display: inherit;

    @media (min-width: ${breakpointTablet}px) {
      display: none;     
    }
  }
`;

export default GlobalStyles;

// export default () => <GlobalStyle />;
