import * as yup from 'yup';
import { convertWeightToNumber } from 'shared/utils/Helpers/formatWeight';
import t from 'components/i18n';

export function getValidateSchema(
  ageParam,
  feedTypes,
  userCountryCode,
) {
  const validateSchema = () => {
    // custom method for minimum weight value
    yup.addMethod(yup.string, 'minMethod', function() {
      const message = t('global.validation.min-weight', {
        weight: ageParam.weightMin,
      });

      // eslint-disable-next-line arrow-body-style
      return this.test('test-min', message, value => {
        if (!value) {
          return true;
        }
        return (
          convertWeightToNumber(ageParam.weightMin) <=
          convertWeightToNumber(value)
        );
      });
    });

    // custom method for maximum weight value
    yup.addMethod(yup.string, 'maxMethod', function() {
      const message = t('global.validation.max-weight', { weight: ageParam.weightMax });
      
      // eslint-disable-next-line arrow-body-style
      return this.test('test-max', message, value => {
        if (!value) {
          return true;
        }
        return (
          convertWeightToNumber(ageParam.weightMax) >=
          convertWeightToNumber(value)
        );
      });
    });

    function maxFeedQ(ref) {
      return this.test({
        name: 'maxFeedQ',
        exclusive: false,
        message: t('global.validation.max-exceeded'),
        params: {
          reference: ref.path,
        },
        test: function(value) {
          // console.log(this.resolve(ref));
          return value <= this.resolve(ref);
        },
      });
    }

    yup.addMethod(yup.number, 'maxFeedQ', maxFeedQ);

    return yup.object().shape({
      isFirstDay: yup.boolean(),
      // userCountryCode: yup.string(),
      waterConsumption: yup
        .string()
        .nullable()
        .when('isFirstDay', (isFirstDay, schema) =>
          isFirstDay || userCountryCode
            ? schema
            : schema
              .test('minL', t('global.validation.min-water-consumption', { water: 50 }), val => {
                if (val.length >= 3) {
                  return true;
                }
                  return val >= 50;
                })
              .required(t('global.validation.required-water-consumption')),
        ),
      waterTime: yup
        .string(t('global.validation.invalid-time'))
        .nullable()
        .when('isFirstDay', (isFirstDay, schema) =>
            isFirstDay || userCountryCode
              ? schema
              : schema.required(t('global.status.required-field')),
        ),

      minTemperature: yup
        .number()
        .when('isFirstDay', (isFirstDay, schema) =>
            isFirstDay || userCountryCode
              ? schema.nullable()
              : schema
                .transform(e => (isNaN(e) ? undefined : e))
                .min(0, t('global.validation.min-temperature'))
                .max(
                  yup.ref('maxTemperature'),
                  t('global.validation.min-exceeded'),
                )
                .required(t('global.status.required-field')),
        ),
      maxTemperature: yup
        .number()
        .when('isFirstDay', (isFirstDay, schema) =>
            isFirstDay || userCountryCode
              ? schema.nullable()
              : schema
                .transform(e => (isNaN(e) ? undefined : e))
                .min(
                  yup.ref('minTemperature'),
                  t('global.validation.max-exceeded'),
                )
                .required(t('global.status.required-field')),
        ),
      minHumidity: yup
        .number()
        .when('isFirstDay', (isFirstDay, schema) =>
            isFirstDay || userCountryCode
              ? schema.nullable()
              : schema
                .transform(e => (isNaN(e) ? undefined : e))
                .min(0, t('global.validation.fill-input'))
                .max(
                  yup.ref('maxHumidity'),
                  t('global.validation.fill-input'),
                )
                .required(t('global.status.required-field')),
        ),
      maxHumidity: yup
        .number()
        .when(
          'isFirstDay',
          (isFirstDay, schema) =>
            isFirstDay || userCountryCode
              ? schema.nullable()
              : schema
                .transform(e => (isNaN(e) ? undefined : e))
                .min(
                  yup.ref('minHumidity'),
                  t('global.validation.fill-input'),
                )
                .max(100, t('global.validation.fill-input'))
                .required(t('global.status.required-field')),
        ),
      naturalDeath: yup
        .number()
        .nullable()
        .when('isFirstDay', (isFirstDay, schema) =>
          !isFirstDay
            ? schema
                .transform(e => (isNaN(e) ? undefined : e))
                .min(
                  0,
                  t('global.validation.invalid-value'),
                )
                .max(100000, t('global.validation.fill-input'))
                .required(t('global.status.required-field'))
            : schema,
        ),
      refuseElimination: yup
        .number()
        .nullable()
        .when('isFirstDay', (isFirstDay, schema) =>
          !isFirstDay
            ? schema
                .transform(e => (isNaN(e) ? undefined : e))
                .min(0, t('global.validation.fill-input'))
                .max(100000, t('global.validation.fill-input'))
                .required(t('global.status.required-field'))
            : schema,
        ),
      legElimination: yup
        .number()
        .nullable()
        .when('isFirstDay', (isFirstDay, schema) =>
          !isFirstDay
            ? schema
                .transform(e => (isNaN(e) ? undefined : e))
                .min(0, t('global.validation.fill-input'))
                .max(100000, t('global.validation.invalid-value'))
                .required(t('global.status.required-field'))
            : schema,
        ),
      otherElimination: yup
        .number()
        .nullable()
        .when('isFirstDay', (isFirstDay, schema) =>
          !isFirstDay
            ? schema
                .transform(e => (isNaN(e) ? undefined : e))
                .min(0, t('global.validation.fill-input'))
                .max(100000, t('global.validation.invalid-value'))
                .required(t('global.status.required-field'))
            : schema,
        ),
      mortalityOnArrival: yup
        .number()
        .nullable()
        .when('isFirstDay', (isFirstDay, schema) =>
          isFirstDay
            ? schema
                .transform(e => (isNaN(e) ? undefined : e))
                .min(
                  0,
                  t('global.validation.fill-input'),
                )
                .max(100000, t('global.validation.invalid-value'))
                .required(t('global.status.required-field'))
            : schema,
        ),
      isRequiredPh: yup.boolean(),
      chlorine: yup
        .number()
        .transform(e => (isNaN(e) ? undefined : e))
        .min(0, t('global.validation.invalid-value'))
        .when('isRequiredPh', (isRequiredPh, schema) =>
          isRequiredPh
            ? schema.required(t('global.status.required-field'))
            : schema,
        ),
      ph: yup
        .number()
        .transform(e => (isNaN(e) ? undefined : e))
        .min(0.01, t('global.validation.invalid-value'))
        .max(14, t('global.validation.invalid-value'))
        .when('isRequiredPh', (isRequiredPh, schema) =>
          isRequiredPh
            ? schema.required(t('global.status.required-field'))
            : schema,
        ),
      isRequiredWeight: yup.boolean(),
      weightValue: yup
        .string()
        .nullable()
        .when('isRequiredWeight', (isRequiredWeight, schema) =>
          isRequiredWeight
            ? schema
                .minMethod()
                .maxMethod()
                .required(t('global.status.required-field'))
            : schema.nullable(),
        ),
      weightTime: yup
        .string()
        .when('isRequiredWeight', (isRequiredWeight, schema) =>
          isRequiredWeight
            ? schema.required(t('global.status.required-field'))
            : schema,
        ),
      isInitialFeed: yup.boolean(),
      initialFeedType: yup
        .string()
        .nullable()
        .when('isInitialFeed', (isInitialFeed, schema) =>
          isInitialFeed
            ? schema.required(t('global.validation.fill-input'))
            : schema,
        ),
      initialFeedTime: yup
        .string()
        .nullable()
        .when('isInitialFeed', (isInitialFeed, schema) =>
          isInitialFeed
            ? schema.required(t('global.status.required-field'))
            : schema,
        ),
      hasMovimentation: yup.boolean(),
      movimentationType: yup
        .string()
        .nullable()
        .when('hasMovimentation', (hasMovimentation, schema) =>
          hasMovimentation
            ? schema.required(
              t('global.validation.fill-input'),
              )
            : schema,
        ),
      movimentationFeedType: yup
        .string()
        .nullable()
        .when('hasMovimentation', (hasMovimentation, schema) =>
          hasMovimentation
            ? schema.required(t('global.validation.fill-input'))
            : schema,
        ),
      movimentationFeedMaxAllowed: yup.number(),
      movimentationFeedValue: yup
        .number()
        .transform(e => (isNaN(e) ? undefined : e))
        .when('hasMovimentation', (hasMovimentation, schema) =>
          hasMovimentation
            ? schema
                .maxFeedQ(yup.ref('movimentationFeedMaxAllowed'))
                .required(t('global.status.required-field'))
            : schema.nullable(),
        ),
      movimentationVendorInformation: yup
        .string()
        .nullable()
        .when('hasMovimentation', (hasMovimentation, schema) =>
          hasMovimentation
            ? schema.required(t('global.validation.fill-input'))
            : schema,
        ),
      isFlockClosure: yup.boolean(),
      waterConsume: yup
        .string()
        .when('isFlockClosure', (isFlockClosure, schema) =>
          isFlockClosure
            ? schema
                .test('minL', t('global.validation.min-water-consumption', { water: 50 }), val => {
                  if (val.toString().length >= 3) {
                    return true;
                  }
                  return val >= 50;
                })
                .required(t('global.validation.fill-input'))
            : schema.nullable(),
        ),
      selfConsume: yup
        .number()
        .nullable()
        .transform(e => (isNaN(e) ? undefined : e))
        .min(0, t('global.validation.fill-input'))
        .when('isFlockClosure', (isFlockClosure, schema) =>
          isFlockClosure
            ? schema.required(t('global.validation.fill-input'))
            : schema,
        ),
      hasFeedRemnant: yup.boolean(),
      feedRemnant: yup
        .array()
        .of(
          yup.object().shape({
            feedType: yup.string().nullable(),
            feedQuantity: yup.number().nullable(),
          }),
        )
        .when('hasFeedRemnant', {
          is: true,
          then: yup.array().of(
            yup.object().shape({
              feedType: yup.string().required(t('global.status.required-field')),
              feedRemnantMaxAllowed: yup.number(),
              feedQuantity: yup
                .number()
                .transform(e => (isNaN(e) ? undefined : e))
                .maxFeedQ(yup.ref('feedRemnantMaxAllowed'))
                .required(t('global.status.required-field')),
            }),
          ),
        }),
    });
  };

  return validateSchema;
}
