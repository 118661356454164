import React from 'react';
import AddWeighing from './AddWeighing';

export default function EditWeighing(props) {
  return (
    <AddWeighing
      {...props}
      goTo={props.goTo}
      isEditing
      sendToConfirmation={false}
    />
  );
}
