import React, { useState, useEffect, useMemo } from 'react';
import {
  VENDORS,
  NEXT_FLOCK_LIST,
  NEXT_FLOCK_DETAILS,
} from 'config/routes';
import Top from 'components/Top';
import Breadcrumb from 'components/Breadcrumb';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import Placement from './Placement';
import Slaughter from './Slaughter';
import Feed from './Feed';
import { ReportsStyled } from './OperationalSchedule.styled';
// service
import t from 'components/i18n';

export default function OperationalSchedule({
  match: { params },
  history,
  responsive,
}) {
  const [activeTab, setActiveTab] = useState(1);
  const [header, getHeader] = useState(null);

  const tabs = [
    {
      label: t("next.operationalSchedule.operationalSchedule.tabs.placement"),
      key: 1,
      Component: Placement,
      urlParam: 'placement',
    },
    {
      label: t("next.operationalSchedule.operationalSchedule.tabs.feed"),
      key: 3,
      Component: Feed,
      urlParam: 'feed',
    },
    {
      label: t("next.operationalSchedule.operationalSchedule.tabs.slaughter"),
      key: 2,
      Component: Slaughter,
      urlParam: 'slaughter',
    },
  ];

  const renderTabs = useMemo(
    () =>
      tabs.map(item => (
        <TabHeaderHolder
          key={item.key}
          onClick={() => setActiveTab(item.key)}
          className={activeTab === item.key ? 'active' : ''}
        >
          <div className="label">{item.label}</div>
          <div className="bar" />
        </TabHeaderHolder>
      )),
    [activeTab, tabs],
  );

  const renderTabComponent = useMemo(
    () =>
      tabs.map(
        (item, index) =>
          activeTab === item.key && (
            <item.Component
              key={index}
              flockId={params.id}
              getHeader={getHeader}
              responsive={responsive}
            />
          ),
      ),
    [activeTab, params.id, responsive, tabs],
  );

  return (
    <ReportsStyled>
      {header && (
        <Breadcrumb
          history={history}
          backTo={() =>
            history.push(`${NEXT_FLOCK_DETAILS}/${params.id}`)
          }
          path={[
            t('next.operationalSchedule.operationalSchedule.bc.growers'),
            header.vendorName,
            t('next.operationalSchedule.operationalSchedule.bc.flock', { value: header.flock }),
            t('schedule.title'),
          ]}
          links={[
            VENDORS,
            `${NEXT_FLOCK_LIST}/${params.vendor}/new`,
            `${NEXT_FLOCK_DETAILS}/${params.id}`,
          ]}
        />
      )}

      <Top>
        <h2>{t('schedule.title')}</h2>
      </Top>

      <Header>{renderTabs}</Header>
      {renderTabComponent}
    </ReportsStyled>
  );
}
