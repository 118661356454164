import styled from 'styled-components';
import { colorGrayLight } from 'components/GlobalStyles/variables';

export const OptionsMenu = styled.div`
  button {
    width: 30px;
    height: 30px;
    font-size: 20px;
    border: none;
    margin: 0 0 -8px 0;
    background: none !important;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.2s background-color ease-out;

    &:hover {
      color: ${colorGrayLight};
      background-color: rgba(0, 0, 0, 0.15) !important;
    }
  }

  .options-menu {
    .item {
      padding: 10px 16px;

      .icon {
        font-size: 20px;
        margin-right: 8px;
        position: relative;
        top: 2px;
      }

      a {
        color: ${colorGrayLight};
      }
    }
  }
`;
