import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import BadgePill from 'components/BadgePill';
import {
  colorDanger,
  colorSuccess,
} from 'components/GlobalStyles/variables';
import Icon from 'components/Icon';
import t from 'components/i18n';
import NoContentTab from 'components/NoContentTab';
import Pagination from 'components/Pagination';
import TableComponent from 'components/Table';
import moment from 'moment';
import addLeadingZero from 'shared/utils/Helpers/addLeadingZero';
import removeDuplicatesBasedOnProperties from 'shared/utils/Helpers/removeDuplicatesBasedOnProperties';
import { ThemeContext } from 'styled-components';
import {
  Container,
  ContentsWrapper,
  OptionsButton,
} from './OperationalSchedule.styled';
import { getSlaughterReport } from './service';
import SlaughterCollectDetails from './SlaughterCollectDetails';
import { tableHeader } from './utils';
import { creationMapper, statusMapper } from 'utils/mapper';

export default function Slaughter({ responsive }) {
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [data, setData] = useState(null);
  const [bodyRequest, setBodyRequest] = useState({
    PageNumber: 1,
    PageSize: 20,
    StartDate: '',
    EndDate: '',
    SupervisorName: '',
    VendorName: '',
    statusId: '',
    animalType: '',
  });

  const [activeFilters, setActiveFilters] = useState([]);

  const [dateFilter, setDateFilter] = useState(null);

  const { isMobile } = responsive;

  const themeContext = useContext(ThemeContext);

  let bgColor = '';

  const getReport = useCallback(async e => {
    setIsContentLoading(true);
    try {
      const response = await getSlaughterReport(e);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsContentLoading(false);
  }, []);

  const getFilterByLabel = useCallback(
    label => activeFilters.find(filter => label === filter.label),
    [activeFilters],
  );

  const getFilters = useMemo(() => {
    return [
      {
        label: t('next.operationalSchedule.filters.animalType.label'),
        alwaysShow: true,
        items: [
          {
            txt: t('next.operationalSchedule.filters.broiler'),
          },
          {
            txt: t('next.operationalSchedule.filters.swine'),
          },
          {
            txt: t('next.operationalSchedule.filters.turkey'),
          },
          {
            txt: t('next.operationalSchedule.filters.all'),
          },
        ],
      },
      {
        label: t('next.operationalSchedule.filters.status.label'),
        items: [
          {
            txt: t('next.operationalSchedule.filters.scheduled'),
            id: '1',
          },
          {
            txt: t('next.operationalSchedule.filters.confirmed'),
            id: '2',
          },
          {
            txt: t('next.operationalSchedule.filters.complete'),
            id: '3',
          },
        ],
      },
    ];
  }, []);

  useEffect(() => {
    if (activeFilters.length > 0) {
      // new search
      setCurrentPage(1);

      const supervisor = getFilterByLabel(t('next.operationalSchedule.filters.extensionist-name.label'));
      const producer = getFilterByLabel(t('next.operationalSchedule.filters.integrated-name.label'));
      const status = getFilterByLabel(t('next.operationalSchedule.filters.status.label'));
      const animalType = getFilterByLabel(t('next.operationalSchedule.filters.animalType.label'));

      setBodyRequest(oldBodyRequest => ({
        ...oldBodyRequest,
        StartDate: dateFilter ? dateFilter.startDate : '',
        EndDate: dateFilter ? dateFilter.endDate : '',
        VendorCode: producer ? producer.code : '',
        SupervisorCode: supervisor ? supervisor.id : '',
        StatusId: status ? Number(status.id) : '',
        AnimalType:
          animalType.txt.replace('í', 'i').replace('Todos', '') || '',
      }));
    }
  }, [activeFilters, dateFilter, getFilterByLabel]);

  const tableData = useMemo(
    () =>
      data &&
      data.itens &&
      data.itens.length > 0 &&
      data.itens.map((e, index) => {
        if (e.status) {
          switch (e.status.toLowerCase()) {
            case 'previsto':
              bgColor = 'colorGrayLight';
              break;
            case 'confirmado':
              bgColor = 'colorInfo';
              break;
            case 'realizado':
              bgColor = 'colorPrimary';
              break;
            default:
              bgColor = 'colorGrayLight';
          }
        }

        return {
          0: (
            <div className={isMobile ? 'column mobile' : 'column'}>
              <p className="small caps">{t('next.operationalSchedule.slaughter.table.content.code', { value: e.vendorCode })}</p>
              <p className="strong">{e.vendorName}</p>
              <p className="small">
                {t("next.operationalSchedule.slaughter.table.content.extensionist", { value: e.supervisorName })}
              </p>
            </div>
          ),
          1: `${t(creationMapper(e.animalType))}`,
          2: `${e.flock} - ${addLeadingZero(e.house)}`,
          3: moment(e.slaughterDate).format('DD ̸̸  MM ̸̸  YYYY - HH:mm'),
          4: t('next.operationalSchedule.slaughter.table.content.quantity', { value: e.slaughterQuantity }),
          5: (
            <BadgePill
              bgColor={bgColor}
              style={{ margin: '3px auto' }}
            >
              {t(statusMapper(e.status))}
            </BadgePill>
          ),
          6: e.catchDate ? (
            <div
              className="flex"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <>
                <Icon
                  color={e.isRead ? colorSuccess : colorDanger}
                  name={
                    e.isRead
                      ? 'icon-check-circle'
                      : 'icon-close-circle'
                  }
                  size={32}
                />
                <OptionsButton onClick={() => setSelectedSchedule(e)}>
                  <Icon size={24} name="icon-ellipsis-v" />
                </OptionsButton>
              </>
            </div>
          ) : null,
        };
      }),
    [data],
  );

  const getPagination = useMemo(
    () =>
      data &&
      Number(data.totalPages) > 0 && (
        <Pagination
          totalPages={data.totalPages}
          pagePadding={isMobile ? 0.75 : 2}
          currentPage={currentPage}
          onChangePage={x => setCurrentPage(x.e)}
        />
      ),
    [currentPage, data, isMobile],
  );

  useEffect(() => {
    if (currentPage) {
      getReport({ ...bodyRequest, PageNumber: currentPage });
    }
  }, [currentPage, bodyRequest, getReport]);

  useEffect(() => setCurrentPage(1), []);

  const renderTable = useMemo(() => {
    const setActiveFiltersWithDefault = filtersToActivate => {
      setActiveFilters(filtersToActivate);
    };

    return (
      <TableComponent
        loading={isContentLoading}
        filter={{
          setDateFilter: setDateFilter,
          activeFilters: activeFilters,
          setActiveFilters: setActiveFiltersWithDefault,
          filters: getFilters,
        }}
        titleColor={themeContext.styles.palette.colorPrimary}
        rowSize="default"
        header={tableHeader}
        mobileHeader={t("next.operationalSchedule.table.header.user")}
        data={tableData}
        width="25%"
        isMobile={isMobile}
      />
    );
  }, [
    activeFilters,
    getFilters,
    isContentLoading,
    isMobile,
    tableData,
    themeContext,
  ]);

  return (
    <>
      <ContentsWrapper>
        <Container style={{ marginBottom: '30px' }}>
          {!isContentLoading && !data && (
            <NoContentTab
              icon="icon-clipboard-user"
              onButtonClick={() => {}}
            >
              <p>
                {t('global.no-content')}
              </p>
            </NoContentTab>
          )}

          {renderTable}
        </Container>

        <SlaughterCollectDetails
          setDisplayBox={setSelectedSchedule}
          displayBox={selectedSchedule}
          data={selectedSchedule}
        />

        {getPagination}
      </ContentsWrapper>
    </>
  );
}
