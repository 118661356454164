import React, { useState, useEffect } from 'react';
import { Message, Label, Field } from '@zendeskgarden/react-forms';
import {
  getValidationMessage,
  getValidation,
} from 'shared/utils/Helpers';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function EditorField(props) {
  const { field, values, label, setState, errors, touched } = props;

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(),
  );

  useEffect(() => {
    if (values[field]) {
      const contentBlock = htmlToDraft(values[field]);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorStateFromPreviousValue = EditorState.createWithContent(
          contentState,
        );

        setEditorState(editorStateFromPreviousValue);
      }
    }
  }, []);

  return (
    <Field>
      <Label>{label}</Label>
      <Editor
        name={field}
        editorState={editorState}
        onEditorStateChange={event => {
          setEditorState(event);
          setState(
            draftToHtml(convertToRaw(event.getCurrentContent())),
          );
        }}
        editorClassName="editor-field"
        toolbarClassName="editor-field__toolbar"
        toolbarOnFocus={props.toolbarOnFocus}
        toolbar={{
          options: ['history', 'inline', 'list', 'embedded'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          link: {
            options: ['link'],
          },
          embedded: {
            popupClassName: 'editor-field__video',
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
          },
        }}
      />
      <Message
        validation={getValidation(
          values[field],
          errors,
          field,
          !touched[field],
        )}
      >
        {getValidationMessage(field, errors, !touched[field])}
      </Message>
    </Field>
  );
}
