import React, { memo, useCallback } from 'react';
import { Label } from '@zendeskgarden/react-forms';
import {
  Menu,
  Item,
  Field,
  Message,
} from '@zendeskgarden/react-dropdowns';
import {
  GroupTitle,
  GroupOptions,
} from 'components/MultiSelect/Items.styled';
import {
  getValidationMessage,
  getValidation,
} from 'shared/utils/Helpers';
import { displayLabel } from './helpers';
import { SelectBox, DropdownBox, FieldSet } from '../Forms.styled';
import t from 'components/i18n';

function DropdownField(props) {
  const {
    label = '',
    falseLabel,
    onSelect,
    optionLabel,
    disabled = false,
    placeholder,
    options = [],
    group,
    showTitle,
    hideMessage,
    className = '',
    field,
    values = {},
    from,
    touched,
    index,
    errors = [],
    optionsTitle,
    optionsGroup,
    disabledItems = [],
    fieldString,
    renderLabel,
  } = props;

  const customItemName = useCallback((item) => {
    if (typeof renderLabel === "function") {
      const labelItem = renderLabel(item[optionLabel] || item);
      return t(labelItem);
    }

    return t(item[optionLabel] || item);
  }, [renderLabel]);

  return (
    <FieldSet style={falseLabel && { marginTop: 23 }} className={className}>
      <DropdownBox
        onSelect={onSelect}
        downshiftProps={{
          itemToString: item => item && customItemName(item)
        }}
      >
        <Field>
          <Label>{label}</Label>
          <SelectBox disabled={disabled}>
            {displayLabel(props) ? (
              displayLabel(props)
            ) : (
              <span className="placeholder">{placeholder}</span>
            )}
          </SelectBox>
        </Field>
        <Menu>
          {options.map((option, index) =>
            group ? ( // grouped multilselect
              <>
                {showTitle && (
                  <GroupTitle>
                    {t(option[optionsTitle])}
                  </GroupTitle>
                )}
                <GroupOptions>
                  {option[optionsGroup] &&
                    option[optionsGroup].map((opt, i) => (
                      <Item
                        key={i}
                        value={opt}
                        disabled={
                          disabledItems.includes(opt) || 
                          opt.disabled
                        }
                      >
                        <span
                          className={
                            values[field] === opt
                              ? 'fake-radio selected'
                              : 'fake-radio'
                          }
                        />
                        {customItemName(opt)}
                      </Item>
                    ))}
                </GroupOptions>
              </>
            ) : (
              // simple multilselect
              <Item
                key={index}
                value={option}
                disabled={
                  disabledItems.includes(option) || 
                  option.disabled
                }
              >
                <span
                  className={
                    values[field] === option
                      ? 'fake-radio selected'
                      : 'fake-radio'
                  }
                />
                {customItemName(option)}
              </Item>
            ),
          )}
        </Menu>
      </DropdownBox>
      {!hideMessage &&
        (from ? (
          <Message
            validation={getValidation(
              values[from][index][fieldString],
              errors,
              `${from}[${index}].${fieldString}`,
              !touched[from],
            )}
          >
            {getValidationMessage(
              `${from}[${index}].${fieldString}`,
              errors,
              !touched[from],
            )}
          </Message>
        ) : (
          <Message
            validation={getValidation(
              values[field],
              errors,
              field,
              !touched[field],
            )}
          >
            {getValidationMessage(
              field,
              errors,
              !touched[field],
            )}
          </Message>
        ))}
    </FieldSet>
  );
}

export default memo(DropdownField);
