import React from 'react';
import { useSelector } from 'react-redux';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import ColoredCard from 'components/ColoredCard';
import { API_REPORT_URL, KONG_CLIENT_ID } from 'config/constants';
import { isUndefined } from 'utils';
import { Container } from './NextRecord.styled';
import HouseItem from './HouseItem';
import t from 'components/i18n';

export default function NextHouseList(props) {
  const { data, history, isLoading, houses } = props;

  const hasFinished =
    data.filter(e => e.status === 'Concluído').length > 0;

  const flockId = data.length && data[0].flockId;

  const user = useSelector(state => state.user);
  const userCountryCode =
    !isUndefined(user.countryCodes) && user.countryCodes[0];

  return (
    <Container>
      <div className="house-list">
        <LoadingHolder
          isLoading={isLoading}
          message={t('global.loading')}
        />

        {!isLoading &&
          data &&
          data.length > 0 &&
          data.map((feed) => (
            <HouseItem
              key={feed.id}
              feed={feed}
              history={history}
              houses={houses}
            />
          ))}

        {!isLoading && data && !data.length && (
          <NoContentTab icon="icon-calendar">
            <p>
              {t('global.no-content')}
            </p>
          </NoContentTab>
        )}
      </div>

      {!isLoading && data && data.length > 0 && (
        <ColoredCard
          bgColor="colorPrimary"
          icon={hasFinished ? 'icon icon-check' : 'icon icon-clock'}
          title={
            hasFinished
              ? t('next.flock.record.houseList.finished.title')
              : t('next.flock.record.houseList.in-progress.title')
          }
          text={
            hasFinished
              ? t('next.flock.record.houseList.finished.text')
              : t('next.flock.record.houseList.in-progress.text')
          }
          buttonLabel={t('next.flock.record.houseList.in-progress.buttonLabel')}
          buttonAction={`${API_REPORT_URL}/Fal?flockId=${flockId}&forceDownload=true&access_token=${user.token}&country=${userCountryCode}&client_id=${KONG_CLIENT_ID}`}
          download
        />
      )}
    </Container>
  );
}
