// @flow

import React from 'react';
import Dashboard from 'components/Dashboard';

function AgroDashboard({ responsive, user, history }) {
  return (
    <Dashboard
      responsive={responsive}
      user={user}
      history={history}
    />
  );
}

export default AgroDashboard;
