import React, { memo } from 'react';
import {
  Menu,
  Item,
  Field,
  Label,
} from '@zendeskgarden/react-dropdowns';
import { Message } from '@zendeskgarden/react-forms';
import {
  getValidationMessage,
  getValidation,
} from 'shared/utils/Helpers';
import { displayLabel } from './helpers';
import { SelectBox, DropdownBox } from '../Forms.styled';

function DropdownFieldFromArray(props) {
  return (
    <>
      <DropdownBox
        onSelect={props.onSelect}
        downshiftProps={{
          itemToString: item =>
            item && (item[props.optionLabel] || item),
        }}
      >
        <Field style={props.falseLabel && { marginTop: 35 }}>
          <Label>{props.label}</Label>
          <SelectBox disabled={props.disabled}>
            {displayLabel(props) ? (
              displayLabel(props)
            ) : (
              <span className="placeholder">{props.placeholder}</span>
            )}
          </SelectBox>
        </Field>
        <Menu>
          {props.options &&
            props.options.map((option, index) => (
              // simple multilselect
              <Item
                key={index}
                value={option}
                disabled={
                  props.disabledItems
                    ? props.disabledItems.find(e => e === option)
                    : false
                }
              >
                <span
                  className={
                    props.values[props.field] === option
                      ? 'fake-radio selected'
                      : 'fake-radio'
                  }
                />
                {option[props.optionLabel] || option}
              </Item>
            ))}
        </Menu>
      </DropdownBox>
      <Message
        validation={getValidation(
          props.values[props.from][props.index][props.fieldString],
          props.errors,
          `${props.from}[${props.index}].${props.fieldString}`,
          !props.touched[props.from],
        )}
      >
        {getValidationMessage(
          `${props.from}[${props.index}].${props.fieldString}`,
          props.errors,
          !props.touched[props.from],
        )}
      </Message>
    </>
  );
}

export default memo(DropdownFieldFromArray);
