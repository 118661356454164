import React, { useState, useMemo } from 'react';
import { HOME } from 'config/routes';
import Top from 'components/Top';
import Breadcrumb from 'components/Breadcrumb';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import Icon from 'components/Icon';
import t from 'components/i18n';
import { Table } from './components';
import { ReportsStyled } from './Financials.styled';
import { DefaultButton } from 'components/CircuitQuestion/CircuitQuestion.styled';
import { useCallback } from 'react';
import { approveInvoice } from './service';
import Toaster from 'components/Toaster';
import Modal from 'components/Forms/FormModal/Modal';
import { colorGrayDarkest, colorGrayLighter, colorSuccess } from 'components/GlobalStyles/variables';

export default function OperationalSchedule(props) {
  const {
    match: { params },
    history,
    responsive,
  } = props;

  const [activeTab, setActiveTab] = useState(1);
  const [selectedRows, setSelecteRows] = useState(null);
  const [response, setResponse] = useState(null);
  const [approveConfirmation, setApproveConfirmation] = useState(false);

  const tabs = [
    {
      label: t("next.invoiceManagement.tabs.pending"),
      key: 1,
      Component: Table,
      urlParam: 'pending',
    },
    {
      label: t("next.invoiceManagement.tabs.approved"),
      key: 3,
      Component: Table,
      urlParam: 'approved',
    }
  ];

  const renderTabs = useMemo(
    () =>
      tabs.map(item => (
        <TabHeaderHolder
          key={item.key}
          onClick={() => setActiveTab(item.key)}
          className={activeTab === item.key ? 'active' : ''}
        >
          <div className="label">{item.label}</div>
          <div className="bar" />
        </TabHeaderHolder>
      )),
    [activeTab, tabs],
  );

  const renderTabComponent = useMemo(
    () =>
      tabs.map(
        (item, index) =>
          activeTab === item.key && (
            <item.Component
              key={index}
              flockId={params.id}
              // getHeader={getHeader}
              responsive={responsive}
              history={history}
              setSelecteRows={setSelecteRows}
              {...item}
            />
          ),
      ),
    [activeTab, params.id, responsive, tabs],
  );

  const hasChecked = useMemo(
    () => (selectedRows || []).some(item => item.selected),
    [selectedRows]
  );

  const approveInvoices = useCallback(async () => {
    Promise.all(
      selectedRows.map(async item => 
        handleApprove(item.invoiceId)
      )
    )
    .then(() => setResponse({ success: true }))
    .catch(() => setResponse({ success: false }));
  }, [selectedRows]);

  const handleApprove = async (invoiceId) => {
    return await approveInvoice({
      invoiceId: invoiceId,
      invoiceStatus: "Concluido",
    });
  }

  return (
    <ReportsStyled>
      <Breadcrumb
        history={history}
        bbackTo={() => history.push(HOME)}
        path={[
          t('next.financials.bc.home'),
          t('next.financials.bc.financials'),
        ]}
        links={[HOME]}
      />

      <Top>
        <h2>{t('next.invoiceManagement.title')}</h2>

        <DefaultButton
          className={''}
          onClick={() => setApproveConfirmation(true)}
          style={{
            width: 'fit-content',
            padding: '8px 16px',
            display: 'flex',
            alignItems: 'center',
            ...(!hasChecked && ({
              backgroundColor: '#CFD2D8',
              borderColor: '#CFD2D8'
            }))
          }}
          disabled={!hasChecked}
        >
          <p
            style={{
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '16px',
              color: colorGrayDarkest,
              margin: 0,
              ...(!hasChecked && ({
                color: '#7F879A'
              }))
            }}
          >{t('next.invoiceManagement.button.approveInvoice')}</p>
          <Icon
            style={{
              marginLeft: 8,
              color: hasChecked ? colorSuccess : colorGrayLighter
            }}
            name="icon-check"
            size={20}
          />
        </DefaultButton>
      </Top>

      <Header>{renderTabs}</Header>

      {renderTabComponent}
      
      {approveConfirmation && (
        <Modal
          warning
          opened={approveConfirmation}
          confirm={() => {
            setApproveConfirmation(false);
            approveInvoices();
          }}
          deny={() => setApproveConfirmation(false)}
          onCloseModal={() => setApproveConfirmation(false)}
          confirmLabel={t('next.invoiceManagement.modalApprove.button.approveInvoice')}
          denyLabel={t('next.invoiceManagement.modalApprove.button.cancel')}
        >
          <div style={{ display: 'flex' }}>
            <span className="icon icon-exclamation-triangle" />
            <h4>{t('next.invoiceManagement.modalApprove.title')}</h4>
          </div>
          <p>{t('next.invoiceManagement.modalApprove.descriptio')}</p>
        </Modal>
      )}

      {response && (
        <Toaster
          className="toaster__notification"
          isVisible={true}
          color='success'
          duration={3}
          delay={0}
          title={
            response.success
              ? t('next.invoiceManagement.modal.success.title')
              : t('next.invoiceManagement.modal.error.title')
          }
        >
          <span className="icon icon-check" />
          {
            response.success
              ? t('next.invoiceManagement.modal.success.description')
              : t('next.invoiceManagement.modal.error.description')
          }
        </Toaster>
      )}
    </ReportsStyled>
  );
}
