import styled from 'styled-components';
import {
  colorPrimary,
  colorGrayLightest,
  colorGrayDarkest,
  colorGrayLighter,
  fontFamilyTextBold,
  fontFamilyText,
} from 'components/GlobalStyles/variables';

export const NoContentTabStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  min-height: 320px;

  .icon {
    font-size: 72px;
    color: ${colorGrayLightest};
  }

  p {
    font-size: 14px;
    color: ${colorGrayDarkest};
    font-family: ${fontFamilyTextBold};
    line-height: 21px;
    margin-bottom: 32px;
    overflow-wrap: break-word;

    .lighter {
      color: ${colorGrayLighter};
      font-family: ${fontFamilyText};
    }
  }

  button {
    background-color: ${props =>
    props.buttonColor || colorPrimary}!important;
    border: none !important;
  }
`;
