import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  fontFamilyTextBold,
  colorClear,
} from 'components/GlobalStyles/variables';

export const StyleIcon = styled.div`
  position: relative;
  background-color: ${props => props.bgColor || colorClear};
  margin: ${props =>
    props.margin
      ? props.margin
      : props.noMargin
      ? '0 0 8px 0'
      : ' 0 0 8px 16px'};
  padding: ${props => props.padding || 0};
  overflow: hidden;
`;

export const IconFooter = styled.footer`
  padding: ${DEFAULT_SIZE}px ${DEFAULT_SIZE * 2}px;
  background: ${colorClear};

  p.footer-p {
    font-family: ${fontFamilyTextBold};
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.footerColor};

    span {
      font-size: 20px;
      margin: 0 4px 0 0;
      position: relative;
      top: 2px;
    }
  }
`;
