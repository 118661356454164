import styled from 'styled-components';
// import rgba from 'polished/lib/color/rgba';
import {
  boxShadow,
  fontFamilyText,
  colorDanger,
  colorGray,
  colorClear,
  fontFamilyTextBold,
  DEFAULT_SIZE,
} from 'components/GlobalStyles/variables';

export const UserStyled = styled.div`
  position: relative;
  padding: 20px;

  .routes--error {
    color: ${colorDanger};
  }
`;

export const Container = styled.div`
  position: relative;
  padding: 1px ${DEFAULT_SIZE * 2}px ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  border-radius: 12px;
  box-shadow: ${boxShadow};
  width: 652px;

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }
`;

export const Separator = styled.hr`
  margin: 16px -16px 15px;
  opacity: 0.12;
`;

export const Subheading = styled.h3`
  div & {
    font-family: ${fontFamilyTextBold};
    margin: ${DEFAULT_SIZE * 2}px 0;
    font-weight: normal;
    font-size: 16px;
  }
`;

export const OptionsButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  outline: 0;
`;
