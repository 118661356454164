import React, { useState, useEffect } from 'react';
// styles
import { ContentLine } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import RoundContainer from 'components/RoundContainer';
// service
import { getPartialPayment } from 'components/Payment/service';
import NoContentTab from 'components/NoContentTab';
import t from 'components/i18n';

export default function Closure(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetch(flockId) {
      try {
        const response = await getPartialPayment(flockId);
        setData(response);
      } catch (e) {
        console.error('Error on get flock demonstrative', e);
      }
    }

    fetch(props.flockId);
  }, [props.flockId]);

  return data.length ? (
    data.map((table, index) => {
      const arr = [
        {
          label: t('flocks.partialClosing.section.liquidPayment.slaughterDate.label'),
          value: data[index].slaughterDate,
        },
        {
          label: t('flocks.partialClosing.section.liquidPayment.quantityHoused.label'),
          value: data[index].placementQuantity,
        },
        {
          label: t('flocks.partialClosing.section.liquidPayment.quantitySlaughted.label'),
          value: data[index].slaughterQuantity,
        },
        {
          label: t('flocks.partialClosing.section.liquidPayment.liveWeight.label'),
          value: data[index].placementAverageWeightAlive,
        },
        {
          label: t('flocks.partialClosing.section.liquidPayment.totalLiveWeight.label'),
          value: data[index].totalWeightAlive,
        },
        {
          label: t('flocks.partialClosing.section.liquidPayment.averageWeight.label'),
          value: data[index].averageWeightAlive,
        },
      ];

      return (
        <>
          <h4 className="mini-heading">{table.title}</h4>
          <RoundContainer key={index} noMargin>
            <div className="table">
              {arr.map(
                (item, i) =>
                  item.value !== null && (
                    <ContentLine key={i}>
                      <span className="label">{item.label}</span>
                      <span className="value">{item.value}</span>
                    </ContentLine>
                  ),
              )}
            </div>
          </RoundContainer>
        </>
      );
    })
  ) : (
    <NoContentTab icon="icon-money">
      <p>
        {t('flocks.newFal.empty.global.title')} <br />{' '}
      </p>
    </NoContentTab>
  );
}
