import React, { createContext, useReducer } from 'react';

const AtaContext = createContext();

const initialState = {
  selectedAta: {},
  ataSubmitResponse: null,
  ataToaster: false,
};

const types = {
  SET_SUBMIT_RESPONSE: 'SET_SUBMIT_RESPONSE',
  SET_SELECTED_ATA: 'SET_SELECTED_ATA',
  ADD_FILE: 'ADD_FILE',
  REMOVE_FILE: 'REMOVE_FILE',
  SET_DISPLAY_TOASTER: 'SET_DISPLAY_TOASTER',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_SELECTED_ATA:
      return {
        ...state,
        selectedAta: action.payload,
      };
    case types.ADD_FILE:
      return {
        ...state,
        selectedAta: {
          ...state.selectedAta,
          fileName: action.payload,
        },
      };
    case types.REMOVE_FILE:
      return {
        ...state,
        selectedAta: {
          ...state.selectedAta,
          fileName: '',
        },
      };
    case types.SET_SUBMIT_RESPONSE:
      return {
        ...state,
        ataSubmitResponse: action.payload,
      };
    case types.SET_DISPLAY_TOASTER:
      return {
        ...state,
        ataToaster: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const actions = dispatch => ({
  setSelectedAta: ata =>
    dispatch({
      type: types.SET_SELECTED_ATA,
      payload: ata,
    }),
  addFile: ata =>
    dispatch({
      type: types.ADD_FILE,
      payload: ata,
    }),
  removeFile: ata =>
    dispatch({
      type: types.REMOVE_FILE,
      payload: ata,
    }),
  setAtaSubmitResponse: response =>
    dispatch({
      type: types.SET_SUBMIT_RESPONSE,
      payload: response,
    }),
  setDisplayAtaToaster: response =>
    dispatch({
      type: types.SET_DISPLAY_TOASTER,
      payload: response,
    }),
});

const AtaContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AtaContext.Provider
      value={{
        ...state,
        dispatch,
        actions: actions(dispatch),
      }}
    >
      {props.children}
    </AtaContext.Provider>
  );
};

export { AtaContext, AtaContextProvider };
