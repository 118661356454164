import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import * as ActionTypes from './types';

export const userState = {
  id: false,
  name: false,
  phoneNumber: false,
  email: false,
  cnpj: false,
  cpf: false,
  type: false,
  smsToken: false,
  vendorCode: false,
  isFirstAccess: false,
  isAcceptedTerm: false,
  isAcceptedTermLgpd: false,
  isDeleted: false,
  justDeactivated: false,
  token: false,
  isAuthenticated: false,
  isNextUser: false,
  profile: false,
  animalType: false,
  countryCodes: [],
};

export default {
  user: handleActions(
    {
      [ActionTypes.SET_USER]: (state, action) => {
        const {
          id,
          name,
          phoneNumber,
          email,
          cnpj,
          cpf,
          type,
          countryCode,
          countryCodes,
          smsToken,
          vendorCode,
          isFirstAccess,
          isAcceptedTerm,
          isAcceptedTermLgpd,
          isDeleted,
          justDeactivated,
          token,
          isAuthenticated,
          isNextUser,
          profile,
          allowRoutes,
          animalType,
        } = action.payload;

        return immutable(state, {
          id: { $set: id },
          name: { $set: name },
          phoneNumber: { $set: phoneNumber },
          email: { $set: email },
          cnpj: { $set: cnpj },
          cpf: { $set: cpf },
          type: { $set: type },
          countryCode: { $set: countryCode },
          countryCodes: { $set: countryCodes },
          smsToken: { $set: smsToken },
          vendorCode: { $set: vendorCode },
          isFirstAccess: { $set: isFirstAccess },
          isAcceptedTerm: { $set: isAcceptedTerm },
          isAcceptedTermLgpd: { $set: isAcceptedTermLgpd },
          isDeleted: { $set: isDeleted },
          justDeactivated: { $set: justDeactivated },
          token: { $set: token },
          isAuthenticated: { $set: isAuthenticated },
          isNextUser: { $set: isNextUser },
          profile: { $set: profile },
          allowRoutes: { $set: allowRoutes },
          animalType: { $set: animalType },
        });
      },

      [ActionTypes.UNSET_USER]: (state, action) =>
        immutable(state, {
          id: { $set: false },
          name: { $set: false },
          phoneNumber: { $set: false },
          email: { $set: false },
          cnpj: { $set: false },
          cpf: { $set: false },
          type: { $set: false },
          smsToken: { $set: false },
          countryCode: { $set: false },
          countryCodes: { $set: false },
          vendorCode: { $set: false },
          isFirstAccess: { $set: false },
          isAcceptedTerm: { $set: false },
          isAcceptedTermLgpd: { $set: false },
          isDeleted: { $set: false },
          justDeactivated: { $set: false },
          token: { $set: false },
          isAuthenticated: { $set: false },
          isNextUser: { $set: false },
          profile: { $set: false },
          allowRoutes: { $set: false },
          animalType: { $set: false },
        }),

      [ActionTypes.SET_ACCEPT_LGPD]: (state, action) =>
        immutable(state, {
          isAcceptedTermLgpd: { $set: action.payload },
        }),
    },
    userState,
  ),
};
