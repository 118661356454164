import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getFunctionalities() {
  const response = await request(
    `${API_URL}/UserCorporateProfile/GetFunctionalities`,
    {
      method: 'GET',
    },
  );
  return response;
}

export async function getProfiles() {
  const response = await request(
    `${API_URL}/UserCorporateProfile/GetProfiles`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function saveProfile(payload) {
  const response = await request(
    `${API_URL}/UserCorporateProfile/Savenew`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}

export async function getRegionalsWithCenters() {
  const response = await request(
    `${API_URL}/UserCorporate/GetRegionalsWithCenters`,
    {
      method: 'GET',
    },
  );
  return response;
}

export async function getUsers(payload) {
  const response = await request( 
    `${API_URL}/UserCorporate/GetCorporateUsers?${payload}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function saveUser(payload) {
  const response = await request(`${API_URL}/UserCorporate/CreateUser`, {
    method: 'POST',
    payload,
  });
  return response;
}

export async function deleteUser(id) {
  const response = await request(`${API_URL}/UserCorporate/DeleteUser?id=${id}`, {
    method: 'DELETE',
  });
  return response;
}

export async function updateUser(id, payload) {
  const response = await request(`${API_URL}/UserCorporate/CreateUser`, {
    method: 'POST',
    payload: {
      Id: id,
      ...payload,
    },
  });
  return response;
}
