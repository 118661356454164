// @flow

import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import Modal from 'components/Forms/FormModal/Modal';
// import { getRepeated } from 'shared/utils/Helpers';
// validate
import validate from 'components/Forms/Fields/validate';
// Contexts
import GrainsForm from 'containers/AgroBRF/Forms/GrainsForm';
// service
import {
  createGrainProposal,
  getGrainTypes,
  getCenters,
} from './service';
import t from 'components/i18n';
import moment from 'moment';

function getValidateSchema(repeated) {
  const validateSchema = () =>
    yup.object().shape({
      grainProposals: yup.array().of(
        yup.object().shape({
          grainTypeId: yup
            .object()
            .nullable()
            .required(t('global.status.required-field')),
          volume: yup
            .string()
            .nullable()
            .required(t('global.status.required-field')),
          month: yup
            .string()
            .nullable()
            .required(t('global.status.required-field')),
          year: yup
            .string()
            .nullable()
            .required(t('global.status.required-field')),
          grainProposalCenterId: yup
            .object()
            .nullable()
            .required(t('global.status.required-field')),
        }),
      ),
    });

  return validateSchema;
}

const initialValues = {
  grainProposals: [
    {
      id: null,
      grainTypeId: null,
      volume: null,
      month: '',
      year: '',
      grainProposalCenterId: null,
    },
  ],
};

export default function EditGrains(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [grainList, setGrainList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [groupedByGrain, setGroupedByGrain] = useState([]);

  useEffect(() => {
    async function getGrainList() {
      setIsLoading(true);
      try {
        const response = await getGrainTypes();

        setGrainList(formatOptions(response));
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }

    async function getCenterList() {
      setIsLoading(true);
      try {
        const response = await getCenters();

        setCenterList(response);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }

    getGrainList();
    getCenterList();
  }, []);

  function formatOptions(values = []) {
    return values.map(value => ({ ...value, name: t(value.name) }))
  }

  function submitForm(values, { setSubmitting, setErrors }) {
    const date = moment();
    // TODO: re-testar
    const utcDate = date.utc();
    const formData = {
      grainProposals: values.grainProposals.map(proposal => ({
        id: 0,
        grainTypeId: proposal.grainTypeId.id,
        volume: Number(proposal.volume.replace('.', '')),
        period: `${proposal.year.name}-${proposal.month.value}-01T00:00:00`,
        grainProposalCenterId: proposal.grainProposalCenterId.id,
        date: utcDate.toDate(),
      })),
    };

    async function saveData(val) {
      setSubmitting(true);
      try {
        const response = await createGrainProposal(val);
        if (response) {
          setSuccess(response.success);
          setMessage(t(response.message));
        } else {
          setSuccess(false);
          throw Error(response);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }
    saveData(formData);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate(getValidateSchema())}
        onSubmit={submitForm}
        render={formikProps => (
          <GrainsForm
            {...formikProps}
            grainList={grainList}
            centerList={centerList}
            message={message}
            setMessage={setMessage}
            groupedByGrain={groupedByGrain}
            setGroupedByGrain={setGroupedByGrain}
            isLoading={isLoading}
          />
        )}
      />

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={!success}
        onCloseModal={() => props.setStage(success ? 3 : 1)}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>{success ? message : errorMessage}</p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
