import mask, { cleanUp } from './mask';

const PATTERN = '######';

const inputNumber = str => {
  const num = str.replace(/[^0-9]/g, '');
  return parseInt(num, 10) || 0;
};

const formatWeight = weight => {
  return (inputNumber(weight) / 1000)
    .toFixed(3)
    .toString()
    .replace('.', ',');
};

export default function doMask(input) {
  if (input === '') {
    return '';
  }
  const value = cleanUp(input);

  if (input.length > PATTERN.length - 1) {
    return formatWeight(mask(`${PATTERN}`, value));
  }

  return formatWeight(value);
}
