import mask, { cleanUp } from './mask';

const PATTERN = '(##) # ####-####';

export default function doMask(input) {
  if (input === '') {
    return '';
  }

  // checkValue(input);

  let value = cleanUp(input);

  value = String(value);

  return mask(PATTERN, value);
}
