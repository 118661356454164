import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import SideboxFilter from 'components/SideboxFilter/SideboxFilter';
import SearchBox from 'components/SearchBox';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import { WhiteBoxInsideHeader } from 'components/HeaderBox';
import RoundContainer from 'components/RoundContainer';
import NextPlacementItem from './NextPlacementItem';
import t from 'components/i18n';
import { getPlacementFilters } from '../ScheduleFilters';
import {
  getStatusColor,
  getFilteredContentByDate,
  getFiltersFromSideBox,
} from '../utils';
import { getSchedulePlacement } from '../service';

export default function NextFeedList({
  vendors,
  flockId,
  getHeader,
  selectedVendor,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [placementList, setPlacementList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  const EmptySearch = () => (
    <NoContentTab icon="icon-warehouse" hideButton>
      <p>
        {t('global.no-content')}
      </p>
    </NoContentTab>
  );

  const fetchData = useCallback(
    async (original, ...e) => {
      setIsLoading(true);
      try {
        const response = await getSchedulePlacement(flockId, ...e);
        setPlacementList(response || []);

        // get all available options to filter
        if (original) {
          setOriginalData(response || []);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    },
    [flockId],
  );

  const fetchBySideBoxFilters = useCallback(() => {
    const filterLabels = [
      t('next.vendorSchedule.filter.status'),
      t('next.vendorSchedule.filter.feed'),
      flockId
        ? ''
        : t('next.vendorSchedule.filter.flock'),
    ];

    fetchData(
      false,
      search ? search.vendorCode : '',
      ...getFiltersFromSideBox(filterLabels, activeFilters),
    );
  }, [activeFilters, fetchData, flockId, search]);

  useEffect(() => {
    if (placementList.length > 0 && flockId) {
      getHeader(placementList[0][0]);
    }
  }, [flockId, getHeader, placementList]);

  useEffect(() => {
    if (vendors.length > 0) {
      setSearch(selectedVendor || vendors[0]);
    }
    setActiveFilters([]);
  }, [vendors, selectedVendor]);

  useEffect(() => {
    setActiveFilters([]);
    if (!vendors.length && !Object.keys(search).length) {
      fetchData(true, '');
    }

    if (Object.keys(search).length > 0 && !activeFilters.length) {
      fetchData(true, search.vendorCode, '');
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (activeFilters.length > 0) {
      fetchBySideBoxFilters();
    }

    setPlacementList(originalData);
    // eslint-disable-next-line
  }, [activeFilters]);

  useEffect(() => {
    if (placementList && dateFilter) {
      const filteredByDate = placementList.map((item, index) =>
        getFilteredContentByDate(moment, item, dateFilter, 'date'),
      );

      setFilteredList(
        filteredByDate.filter(item => (item.length ? item : false)),
      );
    }
  }, [placementList, dateFilter]);

  const content = dateFilter ? filteredList : placementList;

  return (
    <>
      <WhiteBoxInsideHeader>
        {!flockId && (
          <SearchBox
            options={vendors}
            label="label"
            action={setSearch}
            item={search}
            notFoundMessage={t("next.vendorSchedule.no-flock")}
          />
        )}

        {Boolean(originalData && Array.isArray(originalData) && originalData.length) && (
          <SideboxFilter
            dateFilter={flockId ? dateFilter : false}
            setDateFilter={flockId ? setDateFilter : undefined}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            filters={getPlacementFilters(originalData.flat(), flockId)}
          />
        )}

        
      </WhiteBoxInsideHeader>

      <LoadingHolder isLoading={isLoading} />

      <div style={{ padding: '16px 0' }}>
        {!isLoading &&
          content.length > 0 &&
          content.map((e, i) => (
            <RoundContainer key={i} noMargin padding="12px 16px 16px">
              {e.map((item, index) => (
                <NextPlacementItem
                  key={index}
                  isLastItem={index === e.length - 1}
                  isFirstItem={index === 0}
                  icon="icon-warehouse"
                  iconColor="tertiary"
                  date={item.date}
                  status={item.status}
                  statusColor={getStatusColor(
                    item.status,
                    'colorPrimary',
                  )}
                  firstItem={t('next.vendorSchedule.placement.quantity', { value: item.quantity })}
                  secondItem={t('next.vendorSchedule.placement.house', { value: item.houseCode })}
                  thirdItem={t('next.vendorSchedule.placement.flock', { value: item.flock })}
                />
              ))}
            </RoundContainer>
          ))}

        {!isLoading && !content.length && <EmptySearch />}
      </div>
    </>
  );
}
