import React from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { Subheading } from './Grains.styled';
import t from 'components/i18n';

export default function Grains({ setStage }) {
  return (
    <>
      <Subheading>
        {t('menu.beAnAPartner.subtitle')}
      </Subheading>
      <span style={{ whiteSpace: "pre-line" }}>
        {t('menu.beAnAPartner.description')}
      </span>

      <div className="flex center">
        <Button primary onClick={() => setStage(2)}>
          {t('menu.beAnAPartner.buttonPrimary')}
        </Button>
      </div>
    </>
  );
}
