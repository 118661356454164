import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Trigger, Menu } from 'components/Dropdown';
import Icon from 'components/Icon';
import Box from 'components/Container';
import { POLLING_INTERVAL, ALLOW_NOTIFY_KEY } from 'config/constants';

import {
  getLastNotifications,
  readNotification,
  hideNotification,
} from 'components/Notifications/service';

import SidebarRight, {
  SidebarRightContent,
  SidebarToggler,
} from 'components/SidebarRight';
import Notification from 'components/Header/Notification';
import getFromLocalStorage from 'shared/utils/Helpers/getFromLocalStorage';
import saveToLocalStorage from 'shared/utils/Helpers/saveToLocalStorage';
import { MenuHolder } from './NotificationArea.styled';
import t from 'components/i18n';
import moment from 'moment';

const LOCALSTORAGE_AVAILABLE = typeof localStorage !== 'undefined';

function hasUnreadNoficiations(data) {
  return data.filter(d => d.isRead === false).length > 0;
}

export default function NotificationArea(props) {
  const [data, setData] = useState([]);
  const {
    responsive: { isMobile, isGtMobile },
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [actionIsLoading, setActionIsLoading] = useState(false);
  const hasData = !isLoading && data.length > 0;

  const isNextUser = useSelector(state => state.user).isNextUser;

  function setRead(id) {
    setActionIsLoading(true);
    try {
      readNotification(id).then(() => {
        setActionIsLoading(false);
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function setHidden(id) {
    setActionIsLoading(true);

    try {
      await hideNotification(id);

      setActionIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  const fetchData = async () => {
    const allowNotifyOn = getFromLocalStorage(ALLOW_NOTIFY_KEY);

    if (
      isLoading ||
      (allowNotifyOn && allowNotifyOn > moment().get('milliseconds'))
    ) {
      return;
    }

    saveToLocalStorage(
      ALLOW_NOTIFY_KEY,
      moment()
        .add(POLLING_INTERVAL * 6 / 1000, 'seconds')
        .get('milliseconds')
    );

    setIsLoading(true);
    try {
      const schedule = await getLastNotifications(
        '',
        2,
        isNextUser ? 2 : 1,
      );
      setData(schedule);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();

    window.notificationsInterval = setInterval(
      () => {
        fetchData();
      },
      LOCALSTORAGE_AVAILABLE ? 1000 : POLLING_INTERVAL,
    );

    return () => {
      if (window.notificationsInterval) {
        clearInterval(window.notificationsInterval);
      }
    };
  }, [fetchData]);

  const menuToggler = (
    <span className="notification-toggle icons-holder">
      <Icon name="icon-bell-enabled" />
      {hasUnreadNoficiations(data) && (
        <Icon className="dot" name="dot" variant="danger" />
      )}
    </span>
  );

  const menuContent = (
    <>
      {!hasData && (
        <div className="notifications-empty-message">
          {t('notification.no-content')}
        </div>
      )}

      {hasData &&
        data.map((notification, idx, list) => (
          <Notification
            history={props.history}
            key={notification.id}
            isFirstItem={idx === 0}
            isLastItem={idx === list.length - 1}
            isLoading={actionIsLoading}
            setRead={setRead}
            setHidden={setHidden}
            notification={notification}
          />
        ))}
    </>
  );

  return (
    <div className="notification-area">
      {isMobile && (
        <SidebarRight>
          <SidebarToggler>{menuToggler}</SidebarToggler>

          <SidebarRightContent title="Notificações">
            <MenuHolder>{menuContent}</MenuHolder>
          </SidebarRightContent>
        </SidebarRight>
      )}

      {isGtMobile && (
        <Dropdown clickOutsideToClose>
          <Trigger>{menuToggler}</Trigger>

          <Menu>
            <MenuHolder className="menu-holder">
              <Box {...{ slim: true, dark: true }}>
                <h2 className="notifications-heading">
                  {t('notification.title')}
                </h2>

                <div className="notifications-content">
                  {menuContent}
                </div>
              </Box>
            </MenuHolder>
          </Menu>
        </Dropdown>
      )}
    </div>
  );
}
