import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { Resume } from 'components/ResumeCard/ResumeCard.styled';

import {
  DEFAULT_SIZE,
  boxShadow,
  fontFamilyTextBold,
  breakpointTablet,
  breakpointTablet2,
  breakpointDesktop,
  borderRadius,
  colorTertiary,
  colorClear,
  colorGrayUltraLight,
  colorGrayLight,
  colorGrayLighter,
  colorGrayLightest,
  colorGrayDarker,
  colorGrayDarkest,
  colorInfo,
  colorDanger,
  colorSuccess,
} from 'components/GlobalStyles/variables';

export const TopMenuStyled = styled.div`
  width: 100%;

  .label {
    min-width: 160px;
    justify-content: center;

    @media (max-width: ${breakpointTablet}px) {
      min-width: 90px;
    }
  }
`;

export const Container = styled.div`
  padding: ${DEFAULT_SIZE * 2}px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpointTablet2}px) {
    flex-direction: row;
  }

  &>div:first-of-type {
    margin-right: 0;
    margin-bottom: 20px;
    
    @media (min-width: ${breakpointDesktop}px) {
      margin-right: 20px;
    }
  }

  .mini-heading {
    font-style: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }

  .main-wrapper {
    width: 100%;
    
    @media (min-width: ${breakpointDesktop}px) {
      max-width: 780px;

    }
  }
  
  .secondary-wrapper {
    min-width: 280px;
    margin: 0 0 20px;

    @media (min-width: ${breakpointDesktop}px) {
      min-width: 316px;      
    }
  }

  .flock-details {
    width: 100%;
    /* background-color: ${colorClear}; */

    .flock-details-container {
      display: flex;
      flex-direction: column;
    }

    .flock-details-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .icon {
        font-size: 22px;
        color: ${colorGrayLighter};
      }
    }

    .flock-details-heading {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.15px;
      margin: 0;
    }

    .flock-details-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: ${colorGrayUltraLight};
      width: calc(100% + 32px);
      padding: 8px 16px;
      line-height: 32px;
      margin: -17px 0 -17px -16px;

      .icon-holder {
        margin-right: ${DEFAULT_SIZE * 2}px;
      }

      .flock-details-top-item {
        position: relative;   
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 0 24px;         
        width: 33.3%;  
        flex-direction: column;  
        line-height: 18px;
        
        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
        
        @media (min-width: ${breakpointDesktop}px) { 
          border-right: 1px solid ${colorGrayLightest};     
          flex-direction: row;   
          text-align: left;
          line-height: 32px;
          
          &:last-of-type {
            border-right: none;
          }
        }

      }

      .flock-details-top-heading {
        color: ${colorGrayLight};
        margin-bottom: inherit;
        text-transform: uppercase;
        display: block;
        font-size: 10px;
        letter-spacing: 1.5px;
        
        @media (min-width: ${breakpointDesktop}px) {
          margin-right: 16px;
          text-transform: none;
          display: inline;
          font-size: 14px;
          letter-spacing: 0;
        }
      }

      .flock-details-top-label {
        font-style: normal;
        font-size: 14px;
        letter-spacing: 0.15px;
        color: ${colorGrayDarker};
      }
    }

    .flock-details-content {      

      .flock-details-content-heading {
        margin-bottom: ${DEFAULT_SIZE * 2}px;
      }
    }
  }  
  
  .filter-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .house-list {
    max-width: 652px;
    width: 100%;
  }

  .flock-menu {
    min-width: 100%;

    ${Resume} {
      margin: 0 0 16px -16px;
      width: calc(100% + 32px);
    }


    @media (min-width: ${breakpointTablet}px) {
      width: auto;
      min-width: 315px;
    }

    @media (min-width: ${breakpointDesktop}px) {
      width: 100%;
      margin-right: ${DEFAULT_SIZE * 2}px;
      max-width: 376px;
    }
  }

  &.column {
    flex-direction: column;
    
    @media (min-width: ${breakpointDesktop}px) {
      flex-direction: row;
    }
  }

  @media (min-width: ${breakpointTablet}px) {
    flex-direction: column;
  }

  @media (min-width: ${breakpointDesktop}px) {
    flex-direction: row;
  }
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;
  margin: ${props =>
    props.gap && `${props.gap}px -${DEFAULT_SIZE * 2}px`};
  width: ${props => props.full && `calc(100% + 32px)`};
`;

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${DEFAULT_SIZE};
`;

export const FormTop = styled.div`
  display: flex;
  justify-content: space-between;

  .blue {
    color: ${colorInfo};
    font-size: 12px;
    line-height: 16px;
    margin-top: 24px;
  }

  .round {
    background: ${colorInfo};
    color: ${colorClear};
    border-radius: 50%;
    display: inline-block;
    width: 15px;
    height: 15px;
    text-align: center;
  }
`;

export const RecordSummaryItem = styled.div`
  position: relative;
  margin-bottom: 14px;
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};
  padding-bottom: 8px;

  .label {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${colorGrayLight};
    font-family: ${fontFamilyTextBold};
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const Comparative = styled.p`
  &.negative {
    color: ${colorDanger};
  }

  &.positive {
    color: ${colorSuccess};
  }

  .icon {
    font-size: 18px;
    position: relative;
    top: 2px;
  }
`;

export const SectionTitle = styled.div`
  position: relative;
  display: flex;
  align-items: ${props => (props.alignTop ? 'flex-start' : 'center')};

  & > div {
    width: calc(100% - 56px);
  }

  .record-list & {
    &:last-of-type {
      & > div {
        &:last-of-type {
          ${RecordSummaryItem} {
            &:last-of-type {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
`;

export const RoundIconWrapper = styled.span`
  display:block;
  text-align: center;
  font-size: 20px;
  border: 2px solid;
  border-color: ${props =>
    props.isChecked ? colorSuccess : colorGrayLightest};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 16px;
  transition: .5s ease-in border-color;

  .small {
    position: absolute;
    top: 10px;
    font-size: 14px;
    color: ${colorClear};
    opacity: 0;
    transition: .3s ease-in opacity;

    &.visible {
      opacity: 1;
    }

    &:before {
      z-index: 1;
      position: relative;
    }

    &:after {
      content: '';
      position:relative;
      display:block;
      background-color: ${colorSuccess}
      border-radius: 50%;
      width: 15px;
      height: 15px;
      z-index: 0;
      top: -14px;
    }

    &.required {
      background-color: ${colorInfo}
      position:absolute;
      display:block;      
      border-radius: 50%;
      width: 15px;
      height: 15px;
      left: 30px;
      line-height: 16px;
      font-size: 12px;

      &:after {
        display: none;
      }

      &:before {
        display: none;
      }
    }
  }
`;

export const DownloadFile = styled.div`
  a.download-file {
    color: ${colorGrayDarkest};
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;

    .icon {
      color: ${colorGrayLighter};
      font-size: 22px;
      margin: 0 16px 0 0;
      position: relative;
      top: 4px;
    }

    &:hover {
      color: ${colorGrayLighter}!important;
    }
  }
`;

export const DownloadCardBox = styled.div`
  color: ${colorClear};
  background-color: ${colorTertiary};
  border-radius: ${borderRadius};
  width: 316px;
  height: 145px;
  box-shadow: ${boxShadow};

  .icon-wrapper {
    text-align: center;
    width: 56px;
    padding-top: 12px;
  }

  .icon-check {
    font-size: 24px;
  }

  .text {
    padding: 0 16px 0 0;

    p {
      font-size: 14px;
      margin-bottom: 18px;

      &.title {
        font-size: 16px;
      }
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    border: none;
    width: 244px;
    height: 40px;
    background-color: ${colorClear};
    border-radius: ${borderRadius};
    color: ${colorTertiary};
    font-family: ${fontFamilyTextBold};
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`;
