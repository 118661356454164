import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import Container from 'components/Container';
import LoadingHolder from 'components/LoadingHolder';
import AddMedication from 'containers/NextBRF/Forms/SwineMedication/AddMedication';
import EditMedication from 'containers/NextBRF/Forms/SwineMedication/EditMedication';
import { SwineContext } from 'shared/contexts';
import SwineMedicationList from './SwineMedicationList';
import t from 'components/i18n';
import SwineMedicationModals from './SwineMedicationModals';
import { NextSwine } from '../NextSwine.styled';

export default function Medication({
  flockInformation,
  isLoading,
  responsive,
  history,
  getTitle,
  selectedKey,
  setSelectedKey,
}) {
  const [updateItems, setUpdateItems] = useState(false);
  const [sendToConfirmation, setSendToConfirmation] = useState(false);
  const [displayExcludeModal, setDisplayExcludeModal] = useState(
    false,
  );

  const swineContext = useContext(SwineContext);
  const {
    medicationResponse,
    success,
    selectedMedicationId,
    removed,
  } = swineContext;
  const {
    setMedicationResponse,
    setSelectedMedicationId,
    setRemoved,
    setSuccess,
    setFavoriteCandidate,
  } = swineContext.actions;

  const houses = useMemo(() => {
    return (
      flockInformation &&
      flockInformation.houseInformation &&
      flockInformation.houseInformation.map(item => ({
        ...item,
        label: t("next.swineRecord.houses.label", { value: item.value }),
      }))
    );
  }, [flockInformation]);

  const defaultProps = useMemo(() => {
    return {
      houses: houses,
      flockID: flockInformation && flockInformation.flockID,
      goTo: e => setSelectedKey(e),
      sendToConfirmation: sendToConfirmation,
      setSendToConfirmation: setSendToConfirmation,
      setDisplayExcludeModal: setDisplayExcludeModal,
      setMedicationResponse: setMedicationResponse,
      success: success,
      setSuccess: setSuccess,
      removed: removed,
      setRemoved: setRemoved,
      setFavoriteCandidate: setFavoriteCandidate,
      setSelectedMedicationId: setSelectedMedicationId,
      selectedMedicationId: selectedMedicationId,
    };
  }, [
    flockInformation,
    houses,
    removed,
    selectedMedicationId,
    sendToConfirmation,
    setFavoriteCandidate,
    setMedicationResponse,
    setRemoved,
    setSelectedKey,
    setSelectedMedicationId,
    setSuccess,
    success,
  ]);

  const listProps = useMemo(
    () => ({
      flockID: flockInformation && flockInformation.flockID,
      flockStatus: flockInformation && flockInformation.flockStatus,
      responsive: responsive,
      selectedKey: selectedKey,
      history: history,
      setSelectedKey: setSelectedKey,
      updateItems: updateItems,
      setUpdateItems: setUpdateItems,
      setSelectedMedicationId: setSelectedMedicationId,
      selectedMedicationId: selectedMedicationId,
      displayExcludeModal: displayExcludeModal,
      setDisplayExcludeModal: setDisplayExcludeModal,
    }),
    [
      displayExcludeModal,
      flockInformation,
      history,
      responsive,
      selectedKey,
      selectedMedicationId,
      setSelectedKey,
      setSelectedMedicationId,
      updateItems,
    ],
  );

  useEffect(() => {
    if (flockInformation) {
      setSelectedKey('medicationSummary');
    }
  }, [flockInformation, setSelectedKey]);

  useEffect(() => {
    if (selectedMedicationId) {
      setUpdateItems(false);
    }
  }, [selectedMedicationId, setUpdateItems]);

  useEffect(() => {
    if (selectedKey) {
      getTitle(selectedKey);
    }
  }, [getTitle, selectedKey]);

  return (
    <NextSwine>
      <LoadingHolder isLoading={isLoading} />

      {houses && selectedKey === 'addMedication' && (
        <AddMedication {...defaultProps} {...listProps} />
      )}

      {houses && selectedKey === 'medicationSummary' && (
        <SwineMedicationList {...defaultProps} {...listProps} />
      )}

      {houses && selectedKey === 'editMedication' && (
        <EditMedication {...defaultProps} {...listProps} isEditing />
      )}

      {houses && selectedKey === 'medicationDetails' && (
        <Container hideDetail>
          <EditMedication {...defaultProps} {...listProps} />
        </Container>
      )}

      {/* Medication modals */}
      <SwineMedicationModals
        success={success}
        setSelectedKey={setSelectedKey}
        medicationResponse={medicationResponse}
        setMedicationResponse={setMedicationResponse}
        updateItems={updateItems}
        setUpdateItems={setUpdateItems}
        selectedMedicationId={selectedMedicationId}
        setSelectedMedicationId={setSelectedMedicationId}
        displayExcludeModal={displayExcludeModal}
        setDisplayExcludeModal={setDisplayExcludeModal}
        setRemoved={setRemoved}
        removed={removed}
      />
      {/* \ Medication modals */}
    </NextSwine>
  );
}
