export default function getValidation(value, errors, prop, pristine) {
  if (!errors) {
    return 'success';
  }

  const valid = !errors[prop];

  if (pristine) {
    return 'success';
  }

  if (!valid || value === undefined) {
    return 'error';
  }

  return 'success';
}
