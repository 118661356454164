import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
// contexts
import { SwineContext } from 'shared/contexts';
import LoadingHolder from 'components/LoadingHolder';
// service
import { getMedicationDetails } from 'containers/NextBRF/NextSwineRecord/serivce';
import AddMedication from './AddMedication';
import MedicationDetails from './MedicationDetails';

export default function EditMedication(props) {
  const swineContext = useContext(SwineContext);
  const { selectedMedicationId, medicationResponse } = swineContext;
  const {
    setSelectedMedicationId,
    setMedicationResponse,
    setSuccess,
    setFavoriteCandidate,
  } = swineContext.actions;

  const [loadingMedication, setLoadingMedication] = useState(false);
  const [medication, setMedication] = useState(null);

  const fetchMedication = useCallback(async id => {
    setLoadingMedication(true);
    try {
      const response = await getMedicationDetails(id);
      setMedication(response);
    } catch (e) {
      console.error('Error on getMedicationDetails', e);
    }
    setLoadingMedication(false);
  }, []);

  useEffect(() => {
    if (selectedMedicationId) {
      fetchMedication(selectedMedicationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (medicationResponse === null) {
      setSuccess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicationResponse]);

  useEffect(() => {
    if (props.removed) {
      props.goTo('medicationSummary');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.removed]);

  return (
    <>
      <LoadingHolder isLoading={loadingMedication} />

      {props.isEditing ? (
        <AddMedication
          {...props}
          isEditing={props.isEditing}
          medication={medication}
          setSelectedMedicationId={setSelectedMedicationId}
          loadingMedication={loadingMedication}
          medicationResponse={medicationResponse}
          setMedicationResponse={setMedicationResponse}
          setSuccess={setSuccess}
          setFavoriteCandidate={setFavoriteCandidate}
        />
      ) : (
        <MedicationDetails
          medication={medication}
          goTo={props.goTo}
          setDisplayExcludeModal={props.setDisplayExcludeModal}
        />
      )}
    </>
  );
}
