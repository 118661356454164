// @flow

import React from 'react';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { hot } from 'react-hot-loader/root';
import { bindActionCreators } from 'redux';
import Header from 'components/Header';
import { unsetUser, setAcceptLgpd } from 'components/User/actions';
import { switchMenu } from 'components/App/actions';
import MainMenu from 'components/MainMenu';
import PolicyTerms from 'containers/SharedContainers/PolicyTerms';
import { mapSizesToProps } from 'shared/utils/Helpers';
import Navigator from 'components/Navigator';
import { Container, Content, Main } from './Private.styled';

function PrivateContainer(props) {
  const { user, menuIsOpen, responsive } = props;
  const children = React.Children.map(props.children, child =>
    React.cloneElement(child, {
      user,
      responsive,
      menuIsOpen,
    }),
  );

  return (
    <Container className="container">
      <MainMenu
        user={props.user}
        history={props.history}
        activePath={props.location.pathname}
        menuIsOpen={menuIsOpen}
        switchMenu={props.switchMenu}
        unsetUser={props.unsetUser}
        responsive={responsive}
      />

      <Content className="content">
        <Header
          menuIsOpen={menuIsOpen}
          switchMenu={props.switchMenu}
          responsive={responsive}
          history={props.history}
        />

        <Main id="main" className="main" menuIsOpen={menuIsOpen}>
          {children}
        </Main>
      </Content>

      <PolicyTerms user={user} setAcceptLgpd={props.setAcceptLgpd} />

      {responsive.isMobile && <Navigator history={props.history} />}
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    menuIsOpen: state.app.menuIsOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      unsetUser,
      setAcceptLgpd,
      switchMenu,
    },
    dispatch,
  );
}

export default hot(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withSizes(mapSizesToProps)(PrivateContainer)),
);
