import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SideboxFilter from 'components/SideboxFilter/SideboxFilter';
import NoContentTab from 'components/NoContentTab';
import Icon from 'components/Icon';
import BadgePill from 'components/BadgePill';
import { reduceBy } from 'shared/utils/Helpers';
import { WhiteBoxInsideHeader } from 'components/HeaderBox';
import { getWeightList, displayHouseContent } from '../utils';
import {
  StyledNextFlockList,
  Container,
  HistoryItem,
} from '../StyledNextFlock.styled';
import t from 'components/i18n';

export default function WeightHistory({ weight }) {
  // new filter
  const [activeFilters, setActiveFilters] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [filtered, setFiltered] = useState([]);

  const newWeights = Object.assign([], weight);

  function noContent() {
    return (
      <NoContentTab icon="icon-search">
        <p>{t('next.flock.weightHistory.no-content')}</p>
      </NoContentTab>
    );
  }

  const getFilters = () => {
    const filters = [];

    const housesArray = newWeights.flatMap(item =>
      item.flockWeightList.flatMap(e => {
        return {
          txt: t("next.flock.weightHistory.filters.house", { value: e.houseCode }),
          id: e.houseCode,
        };
      }),
    );

    const agesArray = newWeights.map(item => {
      return {
        txt: t('next.flock.weightHistory.filters.days'),
        id: item.totalDays,
      };
    });

    const houseItems = reduceBy(housesArray, 'id');

    if ((houseItems || []).length) {
      filters.push(
        {
          label: t('next.flock.weightHistory.filters.by-age'),
          items: agesArray,
        },
      );
    }
    
    if ((agesArray || []).length) {
      filters.push(
        {
          label: t('next.flock.weightHistory.filters.by-house'),
          items: reduceBy(housesArray, 'id'),
        },
      );
    }

    return filters;
  };

  function filterByLabel(param) {
    return activeFilters.filter(e => e.label === param)[0];
  }

  const ageFilter = filterByLabel(t('next.flock.weightHistory.filters.by-age'));
  const houseFilter = filterByLabel(t('next.flock.weightHistory.filters.by-house'));

  function getFilteredByDate() {
    const start =
      dateFilter &&
      dateFilter.startDate &&
      moment(dateFilter.startDate);
    const end =
      dateFilter && dateFilter.endDate && moment(dateFilter.endDate);

    if (start || end) {
      return weight.filter(
        m =>
          moment(m.weightDate).isBetween(start, end, 'day') ||
          moment(m.weightDate).isSame(start, 'day') ||
          moment(m.weightDate).isSame(end, 'day'),
      );
    }

    return weight;
  }

  function getFilteredContentByAge() {
    if (ageFilter) {
      return getFilteredByDate().filter(
        e => Number(e.totalDays) === Number(ageFilter.id),
      );
    }

    return getFilteredByDate();
  }

  function getFilteredContentByHouse(list, filter) {
    if (filter) {
      return (
        list &&
        list.filter(e => Number(e.houseCode) === Number(filter.id))
      );
    }

    return list;
  }

  function getAllFilters() {
    const filteredByHouse = getFilteredContentByAge().filter(
      e =>
        getFilteredContentByHouse(e.flockWeightList, houseFilter)
          .length,
    );

    if (ageFilter) {
      if (houseFilter) {
        if (filteredByHouse.length > 0) {
          return filteredByHouse;
        }
        return [];
      }
      return getFilteredContentByAge();
    }

    if (houseFilter) {
      if (filteredByHouse.length > 0) {
        return filteredByHouse;
      }
    }

    return getFilteredByDate();
  }

  useEffect(() => {
    if (activeFilters.length > 0 || dateFilter) {
      setFiltered(getAllFilters());
    } else {
      setFiltered(weight);
    }
  }, [activeFilters, dateFilter, getAllFilters, weight]);

  useEffect(() => {
    setFiltered(weight);
  }, [weight]);

  const filters = getFilters();

  return (
    <StyledNextFlockList>
      <Container>
        {Boolean(filters.length) && (
          <WhiteBoxInsideHeader>
            <SideboxFilter
              activeFilters={activeFilters}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              setActiveFilters={setActiveFilters}
              filters={getFilters()}
              labelApply={t("global.button.apply")}
            />
          </WhiteBoxInsideHeader>
        )}

        <div>
          {filtered.length > 0 &&
            filtered.map((element, index) => {
              const houses = getFilteredContentByHouse(
                element.flockWeightList,
                houseFilter,
              );

              const houseList = getWeightList(houses);

              return (
                <HistoryItem key={index}>
                  <header className="item-header">
                    <div className="date">
                      <Icon name="icon-calendar" />
                      <p>
                        {moment(element.weightDate).format(
                          'DD ̸̸  MM ̸̸  YYYY',
                        )}
                      </p>
                    </div>
                    <BadgePill
                      bgColor="colorGrayDark"
                      minWidth="52px"
                    >
                      {t('next.flock.weightHistory.filters.days')}
                    </BadgePill>
                  </header>

                  {houseList.map((item, idx) => {
                    const arrow =
                      item.quantity > item.expected ? 'up' : 'down';

                    return displayHouseContent(item, idx, arrow);
                  })}
                </HistoryItem>
              );
            })}

          {!filtered.length && noContent()}
        </div>
      </Container>
    </StyledNextFlockList>
  );
}
