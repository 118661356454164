/* eslint-disable jsx-a11y/label-has-for */
import React, { useContext } from 'react';
// context
import { MemberContext } from 'shared/contexts/Member';
import { Button } from '@zendeskgarden/react-buttons';
import Heading from 'components/Heading';
import LoadingHolder from 'components/LoadingHolder';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Form, FixedField } from 'components/Forms/Forms.styled';
import {
  Container,
  Layer,
  CheckboxField,
} from 'containers/OnePage/BecomeAMember/BecomeAMember.styled';
import t from 'components/i18n';

export default function SecondMemberForm(props) {
  const memberContext = useContext(MemberContext);
  const { setAnimalType } = memberContext.actions;
  const { setformStep, handleChange, isLoading } = props;

  const options = [
    {
      name: 'Frango',
      icon: 'chicken',
      label: t('beAnAGrower.form.choose.option.one'),
    },
    {
      name: 'Peru',
      icon: 'turkey',
      label: t('beAnAGrower.form.choose.option.two'),
    },
    {
      name: 'Suíno',
      icon: 'pig',
      label: t('beAnAGrower.form.choose.option.three'),
    }
  ];

  function ToggleValues(val, arr) {
    if (arr.includes(val)) {
      setAnimalType(arr.filter(e => e !== val));
    } else {
      setAnimalType([...arr, val]);
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingHolder
          isLoading={isLoading}
          message={t('global.loading')}
        />
      ) : (
        <Form autoComplete="off">
          <>
            {/* Segunda tela */}
            <Layer stage={2}>
              <Heading
                pageTitle={t('beAnAGrower.form.title')}
                subHeading={t('beAnAGrower.form.choose.subtitle', { user: memberContext.name.split(' ')[0] })}
              />

              {/* Tipos de Animais */}
              {options.map((option, index) => (
                <CheckboxField key={index}>
                  <input
                    type="checkbox"
                    id={option.name}
                    value={option.name}
                    name={option.name}
                    onChange={e => {
                      ToggleValues(
                        e.target.value,
                        memberContext.animalType,
                      );
                      handleChange(e);
                    }}
                    checked={memberContext.animalType.includes(
                      option.name,
                    )}
                  />
                  <label
                    id={`${option.icon}-label`}
                    className="block-label"
                    htmlFor={option.name}
                  >
                    <span className={`icon icon-${option.icon}`} />
                    {t(option.label)}
                  </label>
                </CheckboxField>
              ))}

              {/* \ Tipos de Animais */}

              {/* \ Botões */}
              <FixedField>
                <Container>
                  <BtnGroup>
                    <Button
                      key="nextStage"
                      onClick={() => {
                        setformStep(3);
                      }}
                      disabled={!memberContext.animalType.length > 0}
                    >
                      {t('global.button.next')}
                    </Button>
                    {!props.hideCancelButton && (
                      <Button
                        key="clean"
                        onClick={() => props.closeForm()}
                        className="white"
                      >
                        {t('global.button.cancel')}
                      </Button>
                    )}
                  </BtnGroup>
                </Container>
              </FixedField>
              {/* \ Botões */}
            </Layer>
            {/* \ Primeira tela */}
          </>
        </Form>
      )}
    </>
  );
}
