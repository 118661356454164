import React from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { NoContentTabStyled } from './NoContentTab.styled';
import t from 'components/i18n';

export default function NoContentTab(props) {
  const {
    icon,
    children,
    onButtonClick,
    buttonLabel,
    hideButton = false,
    buttonColor,
  } = props;

  let contentIcon = '';

  if (icon) {
    contentIcon =
      icon.toString().indexOf('icon') > -1 ? icon : `icon-${icon}`;
  }
  return (
    <NoContentTabStyled
      className="no-content animated fadeIn"
      buttonColor={buttonColor}
    >
      <span className={`icon ${contentIcon}`} />
      {children}
      {!hideButton && onButtonClick && (
        <Button
          onClick={() => onButtonClick()}
          style={{ backgroundColor: buttonColor }}
        >
          {t(buttonLabel)}
        </Button>
      )}
    </NoContentTabStyled>
  );
}
