import styled from 'styled-components';

export const Logo = styled.div`
  align-items: flex-start;
  display: inline-flex;
  width: 140x;
  width: ${props =>
    props.name.match(/brf-minimal/) ? '40px' : '140px'};
  height: ${props =>
    props.name.match(/brf-minimal/) ? '40px' : '24px'};
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(${props =>
    require(`components/Logo/${props.name}.png`)});
`;
