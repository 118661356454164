import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import moment from 'moment';
import { scrollToTop } from 'shared/utils/Helpers';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import ToggleRange from 'components/ToggleRange';
import SmallButton from 'components/SmallButton';
import Icon from 'components/Icon';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import {
  Heading,
  Subheading,
  Separator,
  NewVisitWrapper,
  Notification,
  AddNew,
} from 'containers/NextBRF/WeekSchedule/WeekSchedule.styled';
import ExcludeModal from './ExcludeModal';
import t from 'components/i18n';

export default function Step2({
  history,
  setFieldValue,
  canEdit,
  configData,
  getLogBookConfigDefaultData,
  author,
  setAuthor,
  setCanEdit,
  setStep,
  customVisits,
  setCustomVisits,
  handleSubmit,
  values,
  errors,
  touched,
}) {
  const [displayModal, setDisplayModal] = useState(false);
  const [toExclude, setToExclude] = useState(null);

  const displayButton = useMemo(() => {
    /** Maximum quantity of custom visits is 2 */
    return customVisits.length < 2;
  }, [customVisits]);

  const getRange = useCallback((start, end) => {
    const arr = [];
    for (let i = start; i <= end; i++) {
      arr.push(i.toString());
    }

    return arr;
  }, []);

  const excludeCustomVisit = useCallback(
    id => {
      setCustomVisits(oldVisits =>
        oldVisits.filter(log => log.visitId !== id),
      );
    },
    [setCustomVisits],
  );

  useEffect(() => {
    const customized =
      configData &&
      configData.logBookConfigItems &&
      configData.logBookConfigItems.filter(item => item.isCustomized);

    if (customized && customized.length > 0) {
      setCustomVisits(customized);
    }
  }, [configData, setCustomVisits]);

  const displayVisitList = useCallback(
    (list, canExclude) => {
      if (list && list.length > 0) {
        return list.map(item => {
          const logbook = `logbookItem_${item.logBookItemTypeId}`;

          return (
            <ToggleRange
              key={item.logBookItemTypeId}
              canExclude={canExclude}
              excludeFunction={() => {
                setToExclude(item.visitId);
                setDisplayModal(true);
              }}
              name={logbook}
              startField={`${logbook}_daysStart`}
              endField={`${logbook}_daysEnd`}
              isActive={`${logbook}_isEnable`}
              heading={t(item.logBookItemType)}
              subheading={`(${item.logBookItemTypeId === 9
                  ? t('form.next.settings.step2.visit.subheading.slaught')
                  : t('form.next.settings.step2.visit.subheading.house')
                })`}
              setFieldValue={setFieldValue}
              startRange={getRange(
                item.daysStartLimit,
                Number(values[`${logbook}_daysEnd`]) - 1,
              )}
              endRange={getRange(
                Number(values[`${logbook}_daysStart`]) + 1,
                item.daysEndLimit,
              )}
              inactiveMessage={t('form.next.settings.step2.visit.inactive-message')}
              fullDisabled={!canEdit}
              isRequired={item.isRequired}
              isDefault="isDefault"
              values={values}
              errors={errors}
              touched={touched}
            />
          );
        });
      }
    },
    [canEdit, errors, getRange, setFieldValue, touched, values],
  );

  return (
    <div className="animated fadeIn">
      <Heading>{t('form.next.settings.step2.title')}</Heading>

      <Subheading>
        {t('form.next.settings.delete-visit.description')}
      </Subheading>

      {displayVisitList(
        (configData.logBookConfigItems || []).filter(
          item => !item.isCustomized,
        ),
        false,
      )}

      {customVisits.length > 0 && (
        <NewVisitWrapper>
          <p className="wrapper-title">
            {t('form.next.settings.step2.custom-visit.title')}{' '}
            <span className="lighter">{t('form.next.settings.step2.custom-visit.title-max')}</span>
          </p>

          {displayVisitList(customVisits, true)}
        </NewVisitWrapper>
      )}

      <br />

      {displayButton && (
        <AddNew>
          <SmallButton
            type="button"
            className="border-radius btn"
            bgColor="colorGrayDarkest"
            textColor="colorClear"
            onClick={() => setStep(3)}
            disabled={!canEdit}
          >
            {t('form.next.settings.step2.visit.add')}{' '}
            <Icon name="icon icon-plus-circle" />
          </SmallButton>
        </AddNew>
      )}

      {/* \ Botões */}

      {canEdit && (
        <>
          {!author && (
            <Notification>
              <span className="icon icon-info" />

              <p>
                {t('form.next.settings.step2.update-deadline')}
              </p>
            </Notification>
          )}

          <br />

          <Row>
            <Col>
              <Button
                key="clean"
                className="white"
                onClick={() => {
                  getLogBookConfigDefaultData(
                    values.animalType.label,
                    values.materialType.label,
                    values.animalQuantity
                      ? values.animalQuantity.value
                      : '',
                  );
                  scrollToTop(document.getElementById('main'));
                }}
                disabled={false}
              >
                {t("form.next.settings.step2.reset-default")}
              </Button>
            </Col>

            <Col>
              <BtnGroup>
                <Button
                  key="sendtoConfirm"
                  className="tertiary"
                  onClick={() => handleSubmit()}
                >
                  {t("global.button.save")}
                </Button>
                <Button
                  key="clean"
                  onClick={() => {
                    setCanEdit(false);
                    setStep(1);
                    setAuthor(null);
                  }}
                  className="white"
                >
                  {t('global.button.cancel')}
                </Button>
              </BtnGroup>
            </Col>
          </Row>
        </>
      )}

      {!canEdit && (
        <>
          {author && (
            <Notification>
              <span className="icon icon-info" />
              <p>
                {t(
                  'form.next.settings.step2.last-update',
                  {
                    name: author.name,
                    id: author.id || '0',
                    date: moment(author.date).format('DD ̸̸  MM ̸̸  YYYY'),
                    hour: moment(author.date).format('HH:mm'),
                  }
                )}
              </p>
            </Notification>
          )}

          <Separator />

          <BtnGroup>
            <Button
              key="sendtoConfirm"
              className="tertiary"
              onClick={() => {
                setCanEdit(true);
                setAuthor(null);
              }}
            >
              {t("global.button.edit")}
            </Button>
            <Button
              key="clean"
              onClick={() => {
                setStep(1);
                setCanEdit(false);
              }}
              className="white"
            >
              {t('global.button.back')}
            </Button>
          </BtnGroup>
        </>
      )}
      {/* \ Botões */}

      {/* Modal */}
      <ExcludeModal
        opened={displayModal}
        setOpened={setDisplayModal}
        mainFunction={() => excludeCustomVisit(toExclude)}
      />
      {/* \ Modal */}
    </div>
  );
}
