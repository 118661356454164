import React from 'react';
import Modal from 'components/Forms/FormModal/Modal';
import t from 'components/i18n';

export default function SubmitModal({
  success,
  setSuccess,
  errorMessage,
  setErrorMessage,
  setSelectedSchedule = null,
  setSelectedQuestions,
  getSchedules = null,
}) {
  return (
    <Modal
      noButtons
      opened={success || errorMessage}
      warning={errorMessage}
      onCloseModal={() => {
        setSelectedSchedule && setSelectedSchedule(null);
        setSelectedQuestions(null);
        setSuccess(false);
        setErrorMessage(false);
        getSchedules && getSchedules();
      }}
    >
      <div className="modal-title">
        <span
          className={`icon icon-${
            success ? 'check' : 'exclamation-triangle'
          }`}
        />
        <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
      </div>

      <p>
        {success
          ? t('global.validation.record-created')
          : t('global.validation.record-error')}
      </p>
    </Modal>
  );
}
