import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { ThemeContext } from 'styled-components';
import Top from 'components/Top';
import TableComponent from 'components/Table';
import BadgePill from 'components/BadgePill';
import Pagination from 'components/Pagination';
import { NEXT_FLOCK_LIST } from 'config/routes';
import {
  getVendors,
} from './service';
import { tableHeader } from './utils';
import { VendorsTableStyled, Container } from './VendorsTable.styled';
import t from 'components/i18n';
import { creationMapper } from 'utils/mapper';

export default function VendorsTable(props) {
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);
  const [data, setData] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [bodyRequest, setBodyRequest] = useState({
    PageNumber: 1,
    PageSize: 20,
    SupervisorCode: '',
    VendorCode: '',
    AnimalType: '',
    IsMortalityHigh: '',
  });

  const { isMobile } = props.responsive;

  const themeContext = useContext(ThemeContext);

  const getFilterByLabel = useCallback(
    label => activeFilters.find(filter => label === filter.label),
    [activeFilters],
  );

  const getFilters = useMemo(() => {
    return [
      {
        label: t('next.vendorsTable.animal-type.label'),
        items: [
          {
            txt: t('next.vendorsTable.animal-type.options.chicken'),
          },
          {
            txt: t('next.vendorsTable.animal-type.options.turkey'),
          },
          {
            txt: t('next.vendorsTable.animal-type.options.swine'),
          },
        ],
      },
    ];
  }, []);

  useEffect(() => {
    if (activeFilters) {
      // new search
      setCurrentPage(1);

      const supervisor = getFilterByLabel(t('next.vendorsTable.extensionist-name'));
      const producer = getFilterByLabel(t('next.vendorsTable.integrated-name'));
      const type = getFilterByLabel(t('next.vendorsTable.animal-type.label'));
      const isMortalityHigh = getFilterByLabel('Por criticidade');

      setBodyRequest(oldBodyRequest => ({
        ...oldBodyRequest,
        SupervisorCode: supervisor ? supervisor.id : '',
        VendorCode: producer ? producer.code : '',
        AnimalType: type ? type.txt : '',
        IsMortalityHigh: isMortalityHigh ? isMortalityHigh.value : '',
      }));
    }
  }, [activeFilters, getFilterByLabel]);

  const getLogBook = useCallback(async e => {
    setIsContentLoading(true);
    try {
      const response = await getVendors(e);
      setData(response.itens);
      setTotalPages(response && response.totalPages);
    } catch (error) {
      console.error(error);
    }
    setIsContentLoading(false);
  }, []);

  useEffect(() => {
    if (currentPage) {
      getLogBook({ ...bodyRequest, PageNumber: currentPage });
    }
  }, [currentPage, bodyRequest, getLogBook]);

  useEffect(() => setCurrentPage(1), []);

  const renderTable = useMemo(
    () => (
      <TableComponent
        loading={isContentLoading}
        filter={{
          activeFilters: activeFilters,
          setActiveFilters: setActiveFilters,
          filters: getFilters,
        }}
        titleColor={themeContext.styles.palette.colorPrimary}
        rowSize="default"
        header={tableHeader}
        mobileHeader="Integrado/Extensionista"
        data={
          data &&
          data.length > 0 &&
          data.map((e, index) => ({
            0: (
              <div className={isMobile ? 'column mobile' : 'column'}>
                <p className="small caps">{t('next.vendorsTable.content.code', { value: e.vendorCode })}</p>
                <p className="strong">{e.vendorName}</p>
                <p className="small">
                  {t('next.vendorsTable.content.grower', { value: e.supervisorName })}
                </p>
              </div>
            ),
            1: creationMapper(e.animalType),
            2: e.openFlockNumber,
            3: e.averageMortality,
            4: (
              <BadgePill
                bgColor={
                  e.isMortalityHigh ? 'colorDanger' : 'colorInfo'
                }
                style={{ margin: '3px auto' }}
              >
                {e.isMortalityHigh ? t('next.vendorsTable.content.mortality.high') : t('next.vendorsTable.content.mortality.low')}
              </BadgePill>
            ),
            onClick:
              e.animalType !== 'Frango'
                ? null
                : () =>
                    props.history.push(
                      `${NEXT_FLOCK_LIST}/${e.vendorId}/new`,
                    ),
          }))
        }
        width="25%"
        isMobile={isMobile}
      />
    ),
    [
      activeFilters,
      data,
      getFilters,
      isContentLoading,
      isMobile,
      props.history,
      themeContext,
    ],
  );

  return (
    <>
      <Top>
        <h2>{t('next.vendorsTable.title')}</h2>
      </Top>
      <VendorsTableStyled>
        <Container style={{ marginBottom: '30px' }}>
          {renderTable}
        </Container>

        {Boolean(totalPages) && Number(totalPages) > 1 && (
          <Pagination
            totalPages={totalPages}
            pagePadding={isMobile ? 0.75 : 2}
            currentPage={currentPage}
            onChangePage={x => setCurrentPage(x.e)}
          />
        )}
      </VendorsTableStyled>
    </>
  );
}
