import React, { useEffect, useState, useRef } from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Field, Message, Label } from '@zendeskgarden/react-forms';
import {
  getValidationMessage,
  getValidation,
} from 'shared/utils/Helpers';
import moment from 'moment';
import * as _ from 'lodash';

const TimePickerField = props => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const fieldValue = props.values[props.field];

  const hideSelect = _.debounce(() => {
    if (open) {
      setOpen(false);
      inputRef.current.blur();
    }
  }, 0);

  const isInside = function(event, element) {
    if (element === null) return false;

    const pos = {
      x: event.targetTouches
        ? event.targetTouches[0].pageX
        : event.pageX,
      y: event.targetTouches
        ? event.targetTouches[0].pageY
        : event.pageY,
    };

    const rect = element.getBoundingClientRect();
    return (
      pos.x < rect.right &&
      pos.x > rect.left &&
      pos.y < rect.bottom &&
      pos.y > rect.top
    );
  };

  const clickOut = _.debounce(evt => {
    if (
      isInside(
        evt,
        document.querySelector('.rc-time-picker-panel'),
      ) ||
      isInside(evt, document.querySelector('.rc-time-picker'))
    ) {
      //
    } else {
      setOpen(false);
    }
  }, 200);

  useEffect(() => {
    document
      .getElementById('main')
      .addEventListener('scroll', hideSelect);
    return () => {
      document
        .getElementById('main')
        .removeEventListener('scroll', hideSelect);
    };
  });

  useEffect(() => {
    document.addEventListener('click', clickOut);
    return () => {
      document.removeEventListener('click', clickOut);
    };
  });

  return (
    <Field>
      <Label>{props.label}</Label>

      <TimePicker
        name={props.field}
        defaultValue={fieldValue ? moment(fieldValue) : ''}
        popupClassName={props.popupClassName}
        onChange={e => {
          props.onChange(e && e.toDate() ? e.toDate() : e);
          setOpen(false);
        }}
        disabled={props.disabled}
        showSecond={false}
        allowEmpty
        placeholder=""
        open={open}
        ref={inputRef}
        value={fieldValue ? moment(fieldValue) : ''}
        onBlur={e => {
          props.onBlur();
        }}
        onFocus={() => {
          setOpen(true);
        }}
        style={{ width: '100%' }}
      />
      <Message
        validation={getValidation(
          props.values[props.field],
          props.errors,
          props.field,
          !props.touched[props.field],
        )}
      >
        {getValidationMessage(
          props.field,
          props.errors,
          !props.touched[props.field],
        )}
      </Message>
    </Field>
  );
};

export default TimePickerField;
