import React, { useMemo, useCallback } from 'react';
import { DropdownField } from 'components/Forms/Fields';
import { Button } from '@zendeskgarden/react-buttons';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import {
  Heading,
  Subheading,
  Separator,
} from 'containers/NextBRF/WeekSchedule/WeekSchedule.styled';
import {
  animalTypes,
  broilerTypes,
  turkeyMaterial,
  swineMaterial,
  animalQuantity,
} from './utils';
import t from 'components/i18n';

export default function Step1({
  setFieldValue,
  setPageTitle,
  segments,
  getLogBookConfigData,
  isValid,
  resetForm,
  values,
  errors,
  touched,
}) {
  const animalTypeOptions = useMemo(() => animalTypes, []);

  const broilerMaterialTypes = useMemo(() => broilerTypes, []);

  const turkeyMaterialTypes = useMemo(() => turkeyMaterial, []);

  const swineMaterialTypes = useMemo(() => swineMaterial, []);

  const animalQuantityOptions = useMemo(() => animalQuantity, []);

  const materialTypeOptions = useCallback(() => {
    if (values.animalType && values.animalType.value === 'peru') {
      return turkeyMaterialTypes;
    }

    if (values.animalType && values.animalType.value === 'suino') {
      return swineMaterialTypes;
    }

    return broilerMaterialTypes;
  }, [
    broilerMaterialTypes,
    swineMaterialTypes,
    turkeyMaterialTypes,
    values.animalType,
  ]);
  return (
    <div className="animated fadeIn">
      <Heading>{t('form.next.settings.step1.title')}</Heading>

      <Subheading>
        {t('form.next.settings.step1.sub-heading')}
      </Subheading>

      <br />

      {/* Tipo de criação */}
      <DropdownField
        field="animalType"
        label={t('form.next.settings.step1.field.animalType.label')}
        placeholder={t('form.next.settings.step1.field.animalType.placeholder')}
        onSelect={item => {
          setFieldValue('animalType', item);
          setFieldValue('centerId', '');
          setFieldValue('materialType', '');
          setFieldValue('animalQuantity', null);
        }}
        options={animalTypeOptions}
        optionLabel="label"
        values={values}
        errors={errors}
        touched={touched}
      />
      {/* \ Tipo de criação  */}

      {/* Segmento */}
      <DropdownField
        field="centerId"
        label={t('form.next.settings.step1.field.centerId.label')}
        placeholder={t('form.next.settings.step1.field.centerId.placeholder')}
        onSelect={item => {
          setFieldValue('centerId', item);
          setPageTitle(item.centerName);
        }}
        options={segments}
        disabled={!values.animalType || !segments.length}
        optionLabel="centerName"
        values={values}
        errors={errors}
        touched={touched}
      />
      {/* \ Segmento  */}

      {/* Material */}
      <DropdownField
        field="materialType"
        label={t('form.next.settings.step1.field.materialType.label')}
        placeholder={t('form.next.settings.step1.field.materialType.placeholder')}
        onSelect={item => setFieldValue('materialType', item)}
        options={materialTypeOptions()}
        disabled={!values.centerId}
        optionLabel="label"
        values={values}
        errors={errors}
        touched={touched}
      />
      {/* \ Material  */}

      {/* Animais alojados */}
      {values && values.isSwine && (
        <DropdownField
          field="animalQuantity"
          label={t('form.next.settings.step1.field.animalQuantity.label')}
          placeholder={t('form.next.settings.step1.field.animalQuantity.placeholder')}
          onSelect={item => setFieldValue('animalQuantity', item)}
          options={animalQuantityOptions}
          optionLabel="label"
          values={values}
          errors={errors}
          touched={touched}
        />
      )}
      {/* \ Animais alojados  */}

      <Separator />

      {/* \ Botões */}
      <BtnGroup>
        <Button
          key="sendtoConfirm"
          className="tertiary"
          onClick={() =>
            getLogBookConfigData(
              values.animalType.value,
              values.centerId.centerId,
              values.materialType.value,
              values.animalQuantity
                ? values.animalQuantity.value
                : '',
            )
          }
          disabled={!isValid}
        >
          {t('global.button.next')}
        </Button>
        <Button
          key="clean"
          onClick={() => resetForm()}
          className="white"
        >
          {t('global.button.cancel')}
        </Button>
      </BtnGroup>
      {/* \ Botões */}
    </div>
  );
}
