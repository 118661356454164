import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import * as routes from 'config/routes';
import Top from 'components/Top';
import Breadcrumb from 'components/Breadcrumb';
import { Button } from '@zendeskgarden/react-buttons';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import { TopMenuStyled } from 'containers/NextBRF/NextFlock/NextRecord/NextRecord.styled';
import { getFlockAllInformation } from './serivce';
import Medication from './Medication';
import Mortality from './Mortality';
import t from 'components/i18n';

export default function NextSwineRecord({
  history,
  match: { params },
  responsive: { isMobile, isTablet, isGtMobile },
}) {
  const [data, setData] = useState(null);
  const [title, setTitle] = useState('FAL');
  const [active, setActive] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getFlockAllInformation(params.id);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [params.id]);

  useEffect(() => {
    setActive(0);
    fetchData();
  }, [fetchData]);

  const getTitle = useCallback(selected => {
    const titles = {
      addMedication: t('next.swineRecord.title.addMedication'),
      medicationSummary: t('next.swineRecord.title.medicationSummary'),
      editMedication: t('next.swineRecord.title.editMedication'),
      medicationDetails: t('next.swineRecord.title.medicationDetails'),
    };

    setTitle(titles[selected] || 'FAL');
  }, []);

  const Tabs = useMemo(() => {
    return [
      {
        label: t('next.swineRecord.tabs.medication'),
        key: 0,
        url: ''
      },
      {
        label: t('next.swineRecord.tabs.mortality'),
        key: 1,
        url: '',
      },
    ];
  }, []);

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() =>
          history.push(`${routes.NEXT_FLOCK_DETAILS}/${params.id}`)
        }
        path={[data && data.flockTitle, 'FAL']}
        links={[`${routes.NEXT_FLOCK_DETAILS}/${params.id}`]}
      />

      <Top>
        <div className="top-content">
          <h2 className="top-content-heading">{title}</h2>
        </div>

        {selectedKey === 'medicationSummary' &&
          data.flockStatus !== 'closed' && (
            <Button
              style={{ marginLeft: '16px' }}
              className="tertiary"
              onClick={() => setSelectedKey('addMedication')}
            >
            {t('next.swineRecord.button.add-new-medicine')}
          </Button>
          )}

        {selectedKey === 'deceasedSummary' &&
          data.flockStatus !== 'closed' && (
            <Button
              style={{ marginLeft: '16px' }}
              className="tertiary"
              onClick={() => setSelectedKey('addDeceased')}
            >
            {t('next.swineRecord.button.add-new-deceased')}
          </Button>
          )}
      </Top>

      <TopMenuStyled>
        <Header>
          {data &&
            Tabs.map(item => (
              <TabHeaderHolder
                key={item.key}
                onClick={() => setActive(item.key)}
                className={active === item.key ? 'active' : ''}
              >
                <div className="label">{item.label}</div>
                <div className="bar" />
              </TabHeaderHolder>
            ))}
        </Header>

        {active === 0 && (
          <Medication
            history={history}
            flockInformation={data}
            isLoading={isLoading}
            isMobile={isMobile}
            isTablet={isTablet}
            isGtMobile={isGtMobile}
            getTitle={getTitle}
            selectedKey={selectedKey}
            setSelectedKey={setSelectedKey}
          />
        )}

        {active === 1 && (
          <Mortality
            history={history}
            flockInformation={data}
            flockId={params.id}
            getTitle={getTitle}
            isMobile={isMobile}
            isTablet={isTablet}
            isGtMobile={isGtMobile}
            selectedKey={selectedKey}
            setSelectedKey={setSelectedKey}
          />
        )}
      </TopMenuStyled>
    </>
  );
}
