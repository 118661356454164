import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getUserLgpd() {
  const response = await request(`${API_URL}/User/GetUserLgpd`, {
    method: 'GET',
  });

  return response.data;
}

export async function updateUserLgpd(payload) {
  const response = await request(`${API_URL}/User/UpdateUserLgpd`, {
    method: 'POST',
    payload,
  });

  return response;
}

export async function createPasswordLgpd(payload) {
  const response = await request(
    `${API_URL}/Authentication/CreatePasswordLgpd`,
    {
      method: 'POST',
      payload,
    },
  );

  return response;
}

export async function authenticate(payload) {
  const response = await request(
    `${API_URL}/Authentication/Authenticate`,
    {
      method: 'POST',
      payload,
    },
  );

  return response;
}

export async function verifyAccount(payload) {
  const response = await request(
    `${API_URL}/Authentication/VerifyAccountVendorCode`,
    {
      method: 'POST',
      payload,
    },
  );

  return response;
}

export async function authenticateVendorCode(payload) {
  const response = await request(
    `${API_URL}/Authentication/AuthenticateVendorCode`,
    {
      method: 'POST',
      payload,
    },
  );

  return response;
}

export async function createPassword(payload) {
  const response = await request(
    `${API_URL}/Authentication/CreatePassword`,
    {
      method: 'POST',
      payload,
    },
  );

  return response;
}

export async function getTermsOfUse() {
  const response = await request(`${API_URL}/Authentication/Term`, {
    method: 'GET',
  });

  return response;
}

export async function getShortTermsOfUse() {
  const response = await request(
    `${API_URL}/Authentication/GetShortTerm`,
    {
      method: 'GET',
    },
  );

  return response;
}

export async function getLgpdTerm() {
  const response = await request(
    `${API_URL}/Authentication/GetLgpdTerm`,
    {
      method: 'GET',
    },
  );

  return response;
}

export async function deactivateUser(payload) {
  const response = await request(`${API_URL}/User/DeactivateUser`, {
    method: 'DELETE',
    payload,
  });
  return response;
}
