import React, { useContext } from 'react';
import uid from 'uuid';
import { ThemeContext } from 'styled-components';
import ProgressBar from 'components/ProgressBar';
import {
  Resume,
  CardBadge,
  FooterContent,
  Content,
  Column,
} from './ResumeCard.styled';

export default function ClosedResumeCard(props) {
  const themeContext = useContext(ThemeContext);
  const {
    color,
    title,
    badge,
    highlight,
    content,
    displayHighlight = true,
    secondaryContent,
    progress,
    flexP,
    isSwine,
  } = props;
  return (
    <Resume color={themeContext.styles.palette[color]}>
      {badge && <CardBadge>{badge}</CardBadge>}

      {displayHighlight && (
        <>
          <span className="label">{title}</span>
          <div className="main">{highlight}</div>
          {!progress && <br />}
        </>
      )}

      <Content flexP={flexP}>
        {!!progress && (
          <ProgressBar
            value={progress}
            padding="16px 0"
            bgColor={themeContext.styles.palette[color]}
          />
        )}

        {content.map(
          item =>
            item.info && (
              <p
                key={`content-${uid()}`}
                style={{
                  display: 'block',
                  marginBottom: '16px',
                  fontSize: '14px',
                }}
              >
                <span>{item.label}: </span>
                <span>{item.info}</span>
              </p>
            ),
        )}
      </Content>

      {secondaryContent && secondaryContent.length > 0 && (
        <FooterContent
          style={{ display: 'block', borderTop: '1px solid #ccc' }}
        >
          {isSwine &&
            secondaryContent.map(
              (item, index) =>
                item.info && (
                  <div
                    key={index}
                    className="flex"
                    style={{ marginBottom: '8px' }}
                  >
                    <p>{item.label}</p>
                    <span>{item.info}</span>
                  </div>
                ),
            )}

          {!isSwine &&
            secondaryContent.map(
              (item, index) =>
                item.info && (
                  <Column key={index}>
                    <p>
                      {item.label} <br />
                      <span>{item.info}</span>
                    </p>
                  </Column>
                ),
            )}
        </FooterContent>
      )}
    </Resume>
  );
}
