import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  colorClear,
  DEFAULT_SIZE,
  colorGray,
  colorGrayDark,
  colorGrayDarker,
  colorGrayDarkest,
  colorGrayLighter,
  colorGrayLight,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

export const List = styled.div`
  background-color: ${colorClear};

  &.confirm-list {
    margin-top: 16px;
  }
  &.normal {
    > div {
      padding: 16px 0;
    }
  }
`;

export const ListItem = styled.div`
  display: flex;
  padding: ${props => (props.noPadding ? '0' : DEFAULT_SIZE * 2)}px;
  justify-content: ${props =>
    props.full ? 'space-between' : 'left'};
  align-items: ${props =>
    props.alignStart ? 'flex-start' : 'center'};
  position: relative;
  margin-bottom: ${props => (props.noMargin ? '0' : '12px')};

  ${props =>
    props.reverse &&
    `
      flex-direction: row-reverse;
      justify-content: space-between;
  `}

  .menu-item & {
    padding: 0;
  }

  &[role='button'] {
    cursor: pointer;
  }

  &.centered {
    justify-content: center;
    width: 100%;
  }

  .icon-holder {
    margin-right: ${DEFAULT_SIZE * 2}px;
  }

  .vendor & {
    padding-left: 0;
  }

  .list-item-heading,
  .list-subheading {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 2px 0;
    color: ${colorGrayDarkest};
  }

  .list-item-heading {
    font-family: ${fontFamilyTextBold}!important;

    .menu-item & {
      margin: 0;
      font-size: 14px;
    }
  }

  .list-subheading {
    font-size: 14px;
    line-height: 16px;

    p {
      margin: 0;
    }
  }

  .list-item-description {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    margin: 0;
    color: ${colorGrayLight};
    overflow-wrap: break-word;

    span {
      &:first-of-type {
        &:before {
          display: none;
        }
      }

      &:before {
        content: '';
        position: relative;
        top: -2px;
        width: 6px;
        height: 6px;
        display: inline-block;
        background-color: ${colorGrayLight};
        border-radius: 50%;
        margin: 0 6px 0;
      }
    }

    .list-item-subitem {
      font-size: 12px;
    }

    a:not(.primary) {
      color: ${colorGrayDarkest};

      &:hover {
        color: ${colorGrayLight};
      }
    }

    &.justify {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;

      p {
        margin: 0;
      }
    }

    &.small {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .chevron {
    margin-left: auto;
    font-size: 24px;
    color: ${colorGrayLighter};
  }

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${rgba(colorGrayDarker, 0.12)};
    bottom: 0;
    left: 56px;

    .normal & {
      width: calc(100% - 40px);
      left: 56px;
    }

    .menu-group & {
      left: 0;
    }

    .confirm-list & {
      width: calc(100% - 56px);
      left: 56px;
    }
  }

  &:last-of-type {
    &:before {
      display: none;
    }
  }

  &.flock-list__item {
    &:before {
      width: calc(100% - 72px);
      left: 72px;
    }
  }

  &.confirm-list__item {
    padding: 0;
    height: auto;

    .list-item-description {
      color: ${colorGrayDarker};
      font-size: 14px;
      padding: 13px 0 12px 6px;
      margin: 0;

      &.list-item-subitem {
        padding: 13px 16px 12px;
        p {
          margin: 0;

          &.small {
            font-size: 12px;
          }
        }
      }
    }

    &:last-of-type {
      margin-bottom: 16px;

      &:before {
        display: none;
      }
    }

    .icon-wrapper {
      text-align: center;
      min-width: 56px;

      .icon {
        color: ${colorGrayLighter};
        font-size: 24px;
        margin-left: -8px;
      }
    }

    .details {
      font-size: 12px;
      color: ${colorGrayLighter};
      margin-left: 6px;
    }
  }
`;

export const JustifiedList = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
`;

export const JustifiedListItem = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  color: ${colorGrayDarkest};
  padding: 16px;
  border-bottom: 1px solid ${rgba(colorGrayDarker, 0.12)};

  &:last-of-type {
    border-bottom: none;
  }

  p {
    margin: 0;
  }

  div {
    margin: 0;
    text-align: right;

    > span {
      display: block;
      color: ${colorGrayDarkest};
      font-size: 12px;
      margin: 8px 0 4px;
    }
  }

  span {
    color: ${colorGrayDark};
    font-size: 12px;
  }
`;

export const Overline = styled.span`
  color: ${props => props.color};
  display: block;
  font-family: ${fontFamilyTextBold};
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;

  div & {
    margin: 0 0 4px;
  }
`;

export const Title = styled.h3`
  color: ${colorGrayDarkest};
  font-family: ${fontFamilyTextBold};
  font-size: 16px;
  line-height: 24px;

  div & {
    margin: 0;
  }
`;

export const SubTitle = styled.p`
  color: ${colorGray};
  font-size: 14px;
  line-height: 21px;

  div & {
    margin: 0 0 4px;
  }
`;

export const Info = styled.div`
  p {
    color: ${colorGray};
    font-size: 12px;
    line-height: 16px;

    div & {
      margin: 0;
    }
  }
`;
