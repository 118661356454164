import { Col, Row } from '@zendeskgarden/react-grid';
import Icon from 'components/Icon';
import IconPlaceholder from 'components/IconPlaceholder';
import Options from 'components/Options';
import t from 'components/i18n';
import React, { useContext } from 'react';
import { CadecContext } from 'shared/contexts';
import { Flex } from 'styled-minimal';
// Styles
import { ListItem } from '../Cadec.styled';
import { getCadecIcon } from '../Cadec.utils';

export default function CadecItem(props) {
  const { onClick, cadecPermissions } = props;
  const cadecContext = useContext(CadecContext);
  const { setSelectedCadec } = cadecContext.actions;

  function selectItem(option) {
    setSelectedCadec(props.cadec);
    props.goTo(option);
  }

  function getOptions(isEditable) {
    const options = [
      {
        action: () => selectItem('details'),
        icon: 'visibility-enabled',
        label: t('cadec.options.details'),
        visible: true,
      },
      {
        action: () => selectItem('editCadec'),
        icon: 'edit',
        label: t('cadec.options.edit'),
        visible: !!cadecPermissions.edit, // @TODO: visible: isEditable,
      },
      {
        action: () => props.setDisplayModal(),
        icon: 'trash',
        label: t('cadec.options.delete'),
        visible: !!cadecPermissions.delete,
      },
    ];
    return options;
  }

  return (
    <>
      <ListItem
        key={`cadec-item-${props.cadec.id}`}
        className="animated fadeIn cadec-list-item clickable"
      >
        <Row>
          <Col
            onClick={() =>
              onClick ? onClick(props.cadec) : selectItem('details')
            }
            md={props.hideControls ? 21 : 11}
          >
            <Flex>
              <IconPlaceholder
                className={`top icon-holder ${props.iconBox} ${
                  props.whiteBgIcon ? 'white' : ''
                }`}
              >
                <Icon
                  className="icon"
                  name={getCadecIcon('cadecList')}
                  color={props.iconColor}
                />
              </IconPlaceholder>
              <div>
                <h3 className="list-item__title">
                  {props.cadec.cadecName}
                </h3>
                {props.cadec.segmentList &&
                  props.cadec.segmentList.map((item, index) => (
                    <p key={index} className="small">
                      {item.name}
                    </p>
                  ))}
                {props.cadec.segment && (
                  <p className="small">{props.cadec.segment}</p>
                )}
              </div>
            </Flex>
          </Col>
          {!props.hideControls && (
            <Col
              md={1}
              className="align-right"
              onClick={() => setSelectedCadec(props.cadec)}
            >
              <Options
                options={getOptions()}
                placement="start-bottom"
              />
            </Col>
          )}
        </Row>
      </ListItem>
    </>
  );
}
