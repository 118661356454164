import styled from 'styled-components';
import {
  boxShadow,
  fontFamilyText,
  colorGray,
  colorClear,
  breakpointTablet,
  DEFAULT_SIZE,
  colorGrayDarker,
} from 'components/GlobalStyles/variables';
import { Col } from '@zendeskgarden/react-grid';
import {
  MediaInput as MediaInputBase,
  Field as FieldBase
} from '@zendeskgarden/react-forms';

export const ContentsWrapper = styled.div`
  position: relative;
  min-width: 1050px;
  overflow-x: auto;
`;

export const Container = styled.div`
  position: relative;
  /* max-width: 986px; */
  padding: 0 ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px 20px 60px 20px;
  }

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }
`;

export const Menu = styled.div`
  position: absolute;
  right: 0rem;
  width: fit-content;
  min-width: 160px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid #cfd2d8;
  z-index: 1000;
  padding: 0.75rem 1rem;
`;

export const Item = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${({ disabled }) => disabled ? '45%' : '100%'}

  & > span {
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  & > svg {
    font-size: 24px;
  }
`;

export const ItemLabel = styled.p`
  color: ${colorGrayDarker};
  padding-left: 1rem;
  white-space: nowrap;
  margin: 0
`;

export const SearchContainer = styled(Col)`
  margin: 1.5rem 0 1rem 1rem;
`;

export const MediaInput = styled(MediaInputBase)`
  height: 3rem;
`;

export const Field = styled(FieldBase)`
  & > div {
    & > div {
      max-height: unset;
    }
  }
`;
