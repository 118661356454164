import React, { useState, useEffect } from 'react';
import t from 'components/i18n';
// context
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import LoadingHolder from 'components/LoadingHolder';
import {
  DropdownField,
  TextAreaField,
} from 'components/Forms/Fields';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Form } from 'components/Forms/Forms.styled';
import {
  Layer,
  ConclusionBox,
} from 'containers/SharedContainers/Faq/Faq.styled';

export default function FaqForm(props) {
  const [nextInput, setNextInput] = useState(null);
  const [displayTextfield, setDisplayTextfield] = useState(false);
  const [disableTextfield, setDisableTextfield] = useState(true);
  const {
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isLoading,
    resetForm,
    values,
    errors,
    touched,
  } = props;

  const { questions } = props.questions;

  useEffect(() => {
    if (nextInput) {
      setDisableTextfield(!values.question2);
    } else {
      setDisableTextfield(!values.question1);
    }
  }, [nextInput, values.question1, values.question2]);

  const getAnswers = answers => {
    return (answers || []).map(answer => ({
      ...answer,
      description: t(answer.description),
    }));
  };

  return (
    <>
      {isLoading ? (
        <LoadingHolder
          isLoading={isLoading}
          message={t('menu.support.loading.text')}
        />
      ) : (
        <>
          {/* Primeira tela */}
          <Layer className="center">
            {!props.success ? (
              <Form autoComplete="off">
                <p>{props.description}</p>
                <p>{props.text}</p>
                <Row>
                  <Col sm={12}>
                    {/* Assunto */}
                    <DropdownField
                      field="question1"
                      label={questions && t(questions[0].description)}
                      placeholder={t(
                        'menu.support.dropdown.one.placeholder',
                      )}
                      onSelect={item => {
                        setFieldValue('question1', item);
                        setFieldValue('question2', '');
                        setNextInput(item.nextFaqQuestionId);
                        setDisplayTextfield(true);
                      }}
                      options={
                        questions &&
                        questions[0].answers &&
                        getAnswers(questions[0].answers)
                      }
                      optionLabel="description"
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                    {/* \ Assunto */}
                  </Col>
                </Row>

                {nextInput && (
                  <Row>
                    <Col sm={12}>
                      {/* Assunto */}
                      <DropdownField
                        field="question2"
                        label={t(questions[1].description)}
                        placeholder={t(
                          'menu.support.dropdown.two.placeholder',
                        )}
                        onSelect={item => {
                          setFieldValue('question2', item);
                        }}
                        options={
                          questions[1].answers &&
                          getAnswers(questions[1].answers)
                        }
                        optionLabel="description"
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                      {/* \ Assunto */}
                    </Col>
                  </Row>
                )}

                {/* Mensagem */}
                {displayTextfield && (
                  <TextAreaField
                    field="message"
                    label={t('menu.support.inputTextArea.label')}
                    setState={e => {
                      setFieldValue('message', e);
                    }}
                    disabled={disableTextfield}
                    onBlur={e => handleBlur(e)}
                    onChange={e => handleChange(e)}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                )}
                {/* \ Mensagem */}

                <Row>
                  <Col sm={12}>
                    {/* \ Botões */}
                    <BtnGroup>
                      <Button
                        className={
                          props.user.isNextUser
                            ? 'tertiary'
                            : 'primary'
                        }
                        key="submitForm"
                        onClick={e => handleSubmit(e)}
                        disabled={
                          !props.isValid || props.isValidating
                        }
                      >
                        {t('menu.support.buttonPrimary')}
                      </Button>
                      <Button
                        key="clean"
                        className="white"
                        onClick={() => {
                          resetForm();
                          setNextInput(null);
                          setDisplayTextfield(false);
                        }}
                      >
                        {t('menu.support.buttonSecondary')}
                      </Button>
                    </BtnGroup>
                    {/* \ Botões */}
                  </Col>
                </Row>
              </Form>
            ) : (
              <ConclusionBox>
                <span className="icon icon-mail" />
                <p className="bold">
                  {t('menu.support.success.title')}
                </p>
                <p>{t('menu.support.success.description')}</p>
              </ConclusionBox>
            )}
          </Layer>
          {/* \ Primeira tela */}
        </>
      )}
    </>
  );
}
