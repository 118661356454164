import React, { useEffect, useCallback, useState } from 'react';
import { COLLABORATOR } from 'config/routes';
import Container from 'components/Container';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import Toaster from 'components/Toaster';
import CollaboratorList from 'containers/AgroBRF/Collaborator/CollaboratorList';
import {
  getInvitedUserList,
  getVendors,
} from 'containers/AgroBRF/Collaborator/service';
import { Subheading } from 'containers/AgroBRF/Collaborator/Collaborator.styled';
import t from 'components/i18n';
import { Separator } from '../../ProfileAndSettings.styled';

export default function CollaboratorScreen(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [vendors, setVendors] = useState([]);
  const [removedUser, setRemovedUser] = useState(false);

  const getInvitedList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getInvitedUserList();
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, []);

  const getAvailableVendors = useCallback(async () => {
    try {
      const response = await getVendors();
      setVendors(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getInvitedList();
    getAvailableVendors();
  }, [getAvailableVendors, getInvitedList]);

  useEffect(() => {
    if (removedUser) {
      getInvitedList();
    }
  }, [getInvitedList, removedUser]);

  return (
    <Container className="container" slim>
      <h4>
        {t('menu.profile.sectionAccountConfig.buttonEmployees')}
      </h4>

      <Separator style={{ marginBottom: 0 }} />

      <LoadingHolder isLoading={isLoading} />

      {!isLoading &&
        (!data.length ? (
          <>
            <Subheading style={{ paddingTop: '20px' }}>
              {t('menu.employees.subtitle')}
            </Subheading>
            <p>{t('menu.employees.description')}</p>
            <NoContentTab
              icon="icon-clipboard-user"
              onButtonClick={() =>
                props.history.push(`${COLLABORATOR}/new/edit`)
              }
              hideButton={props.user.profile === 'Assistente'}
              buttonLabel={t('menu.employees.inviteEmployee.title')}
            >
              <p>
                {t('menu.employees.message.title')}
                <br />{' '}
                <span className="lighter">
                  {t('menu.employees.message.subtitle')}
                </span>
              </p>
            </NoContentTab>
          </>
        ) : (
          <>
            <CollaboratorList
              data={data}
              selected={selected}
              setSelected={setSelected}
              setRemovedUser={setRemovedUser}
              removedUser={removedUser}
              {...props}
            />
            <Toaster
              className="toaster__notification"
              isVisible={removedUser}
              color="success"
              duration={3}
              delay={0}
            >
              <span className="icon icon-check" />
              {t('menu.employees.delete-confirmation')}
            </Toaster>
          </>
        ))}
    </Container>
  );
}
