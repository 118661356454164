import React, { useContext } from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Flex } from 'styled-minimal';
import IconPlaceholder from 'components/IconPlaceholder';
import t from 'components/i18n';
import Icon from 'components/Icon';
import { AtaContext } from 'shared/contexts';
import Options from 'components/Options';
import { getCadecIcon } from '../Cadec.utils';

// Styles
import { ListItem } from '../Cadec.styled';

export default function AtaItem(props) {
  const ataContext = useContext(AtaContext);
  const { setSelectedAta } = ataContext.actions;

  function selectItem(option) {
    setSelectedAta(props.ata);
    props.goTo(option);
  }

  function getOptions(isEditable) {
    const options = [
      {
        file: props.link,
        icon: 'visibility-enabled',
        label: t("cadec.options.viewFile"),
        visible: true,
      },
      {
        action: () => selectItem('editAta'),
        icon: 'edit',
        label: t("cadec.options.edit"),
        visible: !!props.cadecPermissions.edit, // @TODO: visible: isEditable,
      },
      {
        action: () => props.setDisplayModal(),
        icon: 'trash',
        label: t("cadec.options.delete"),
        visible: !!props.cadecPermissions.delete,
      },
    ];
    return options;
  }

  function LineContent() {
    return (
      <>
        <IconPlaceholder
          className={`top icon-holder ${props.iconBox} ${
            props.whiteBgIcon ? 'white' : ''
          }`}
        >
          <Icon className="icon" name={getCadecIcon('ataList')} />
        </IconPlaceholder>
        <div>
          <h3 className="list-item__title">{props.name}</h3>
          <span className="list-item__subtitle">
            {props.ata.date}
          </span>
          {props.ata.cadecs &&
            props.ata.cadecs.map((item, index) => (
              <p key={index} className="small">
                {item.name}
              </p>
            ))}
        </div>
      </>
    );
  }

  return (
    <>
      <ListItem
        key={`cadec-item-${props.ata.id}`}
        className="animated fadeIn"
      >
        <Row>
          <Col md={11}>
            <a
              href={props.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Flex>
                <LineContent />
              </Flex>
            </a>
          </Col>
          <Col
            md={1}
            className="align-right"
            onClick={() => setSelectedAta(props.ata)}
          >
            <Options
              options={getOptions()}
              placement="start-bottom"
            />
          </Col>
        </Row>
      </ListItem>
    </>
  );
}
