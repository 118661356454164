import React from 'react';
import { ACCOUNT_PASSWORD_RECOVERY } from 'config/routes';
import t from 'components/i18n';

import { Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';

import { Form } from 'components/Forms/Forms.styled';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Link, LinkHolder } from 'components/SideBox/SideBox.styled';

import InputPassword from 'components/Forms/Fields/InputPassword';

export default function UpdatePasswordForm({
  handleSubmit,
  handleChange,
  handleBlur,
  resetForm,
  isValid,
  isUploading,
  values,
  errors,
  touched,
}) {
  const { currentPassword, newPassword, confirmPassword } = values;

  return (
    <Form autoComplete="off">
      <Row>
        <Col md={12}>
          <h4>
            {t('menu.profile.accountData.updatePassword.title')}
          </h4>

          <br />

          <p className="info">
            {t('menu.profile.accountData.updatePassword.description')}
          </p>

          <br />

          <InputPassword
            name="currentPassword"
            label={t(
              'menu.profile.accountData.updatePassword.buttonSingleLine.oldPassword',
            )}
            placeholder={t(
              'menu.profile.accountData.updatePassword.buttonSingleLine.oldPassword',
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            value={currentPassword}
            errors={errors}
            touched={touched}
          />

          <InputPassword
            name="newPassword"
            label={t(
              'menu.profile.accountData.updatePassword.buttonSingleLine.newPassword',
            )}
            placeholder={t(
              'menu.profile.accountData.updatePassword.buttonSingleLine.newPassword',
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            value={newPassword}
            errors={errors}
            touched={touched}
          />

          <InputPassword
            name="passwordConfirmed"
            label={t(
              'menu.profile.accountData.updatePassword.buttonSingleLine.confirmNewPassword',
            )}
            placeholder={t(
              'menu.profile.accountData.updatePassword.buttonSingleLine.confirmNewPassword',
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            value={confirmPassword}
            errors={errors}
            touched={touched}
          />

          <LinkHolder>
            <Link to={ACCOUNT_PASSWORD_RECOVERY} primary>
              {t(
                'menu.profile.accountData.updatePassword.buttonSingleLine.linkForgotPassword',
              )}
            </Link>
          </LinkHolder>
        </Col>
      </Row>

      <Separator />

      <BtnGroup>
        <Button
          key="sendtoConfirm"
          onClick={() => {
            handleSubmit();
          }}
          disabled={!isValid || isUploading}
        >
          {t('menu.profile.accountData.updatePassword.buttonPrimary')}
        </Button>
        <Button
          key="clean"
          onClick={() => resetForm()}
          className="white"
        >
          {t(
            'global.button.clear',
          )}
        </Button>
      </BtnGroup>
    </Form>
  );
}
