import React from 'react';
import { DownloadButton } from './DownloadButtonForTable.styled';

export default function DownloadButtonForTable({ label, link }) {
  return (
    <>
      <DownloadButton href={link} target="_blank">
        {label} <span className="icon icon-chevron-down" />
      </DownloadButton>
    </>
  );
}
