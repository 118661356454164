import ContainerBase from 'components/Container';
import { colorGrayDarker, colorGrayDarkest, colorGrayLight, colorGrayLighter, colorGrayLightest, colorGrayUltraLight, colorInfo } from 'components/GlobalStyles/variables';
import RoundContainer from 'components/RoundContainer';
import styled from 'styled-components';

export const Container = styled(ContainerBase)`
  margin: 1rem 2rem;

  .container-content {
    margin-bottom: 0;
    padding: 8px 16px;
  }
`;

export const Separator = styled.hr`
  margin: 8px -16px 0;
  opacity: 0.12;
`;

export const InvoiceCardWrapper = styled.div`
  display: flex;
  padding: 16px 0;
`;

export const Card = styled(RoundContainer)`
  padding: 12px 16px;
  margin: 0;
  min-width: 232px;
  min-height: 148px;
  margin: 0 12px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const InvoiceDashboardHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
`;

const TextBase = styled.h1`
  font-size: 14px;
  line-height: 21px;
  color: ${colorGrayDarker};
  margin: 0,
`;

export const Title = styled(TextBase)`
  font-weight: 500;
`;

export const Subtitle = styled(TextBase)`
  font-weight: 400;
`;

export const Description = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 6px 0 12px 0;

  p {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: ${colorGrayLight};
  }
`;

export const Button = styled.div`
  height: 40px;
  margin: 0 -16px -12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorGrayUltraLight};
  color: ${colorInfo};
  border-top: 1px solid ${colorGrayLightest};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  line-height: 16px;
`;

export const RoundedButton = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: ${({ disabled }) => disabled ? colorGrayUltraLight : '#FF6600'}
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:last-of-type {
    margin-left: 8px;
  }

  span {
    color: ${({ disabled }) => disabled ? colorGrayLighter : '#ffffff'}
  }
`;

export const Navigator = styled.div`
  display: flex;
`;

export const NoActivities = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;

  span {
    color: #cfd2d8;
    margin-bottom: 20px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin: 0;

  }

  .title {
    color: ${colorGrayDarkest};
  }

  .subtitle {
    color: ${colorGrayLight};
  }
`;
