import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import * as ActionTypes from './types';

export const initialState = {
  opened: false,
  options: false,
};

export default {
  modal: handleActions(
    {
      [ActionTypes.MODAL_OPEN]: (state, action) =>
        immutable(state, {
          opened: { $set: true },
          options: { $set: action.payload },
        }),

      [ActionTypes.MODAL_CLOSE]: state =>
        immutable(state, {
          opened: { $set: false },
        }),
    },
    initialState,
  ),
};
