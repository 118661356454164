import { Button } from '@zendeskgarden/react-buttons';
import Container from 'components/Container';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { DropdownField } from 'components/Forms/Fields';
import t from 'components/i18n';
import React from 'react';
import { questionTitleMapper } from 'utils/mapper';
// styles
import { Separator } from './Checklists.styled';

const ConfirmChecklist = ({
  checklist,
  onGoBack,
  onSetAnswer,
  onSubmitAnswer,
}) => {
  return (
    <Container className="container" slim>
      <h4>{t('global.informed-data.title')}</h4>
      <Separator style={{ marginBottom: 14 }} />

      {Object.keys(checklist.questionGroupedByCategory).map(key => (
        <>
          <h5>
            <strong>{key}</strong>
          </h5>
          <Separator style={{ marginBottom: 14 }} />
          {!!checklist.questionGroupedByCategory[key] &&
            !!checklist.questionGroupedByCategory[key].length &&
            checklist.questionGroupedByCategory[key].map(question => (
              <>
                <DropdownField
                  field={question.codPerguntaIrn}
                  label={question.desPergunta}
                  placeholder={t('checklist.confirmation.select-option')}
                  onSelect={item =>
                    onSetAnswer(question.codPerguntaIrn, item)
                  }
                  options={question.answers.map(answer => ({
                    id: answer.codRespostaIrn,
                    desc: answer.desResposta,
                    label: questionTitleMapper(answer.desResposta)
                  }))}
                  optionLabel="label"
                  values={checklist.answerSheet}
                  hideMessage
                  disabled
                />
                <Separator style={{ marginBottom: 14 }} />
              </>
            ))}
        </>
      ))}

      <BtnGroup>
        <Button
          key="sendtoConfirm"
          onClick={() => onSubmitAnswer()}
          className="tertiary"
        >
          {t('global.button.save')}
        </Button>
        <Button
          key="clean"
          onClick={() => onGoBack()}
          className="white"
        >
          {t('global.button.back')}
        </Button>
      </BtnGroup>
    </Container>
  );
};

export default ConfirmChecklist;
