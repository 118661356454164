import React, { useEffect, useCallback, useMemo } from 'react';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Message, Field } from '@zendeskgarden/react-forms';
import { Form } from 'components/Forms/Forms.styled';
import { InputField } from 'components/Forms/Fields';
import StatusChange from 'components/StatusChange';
import LoadingHolder from 'components/LoadingHolder';
import CheckboxTable from 'components/CheckboxTable';
import { getFunctionalitiesList } from 'containers/NextBRF/UserManagement/Profile/utils';
import {
  Container,
  Separator,
  Subheading,
} from 'containers/NextBRF/UserManagement/UserManagement.styled';
import t from 'components/i18n';

export default function ProfileForm(props) {
  const {
    functionalitiesList,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    isLoading,
    values,
    errors,
    touched,
  } = props;

  const checkForTrue = useCallback(
    arr => arr.findIndex(item => item) > -1,
    [],
  );

  useEffect(() => {
    setFieldValue(
      'routes',
      values.allowRoutes.findIndex(item => checkForTrue(item.level)) >
        -1,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.allowRoutes]);

  const functionalities = useMemo(() => {
    return getFunctionalitiesList(functionalitiesList);
  }, [functionalitiesList]);

  return (
    <Container className="animated fadeIn">
      <LoadingHolder
        message={t('global.loading')}
        isLoading={isLoading}
      />
      {!isLoading && (
        <Form autoComplete="off">
          {values.id && ( // don't show status change if it's a new user
            <Field>
              <StatusChange
                label="Status"
                statusBgColor={
                  values.isEnabled ? 'colorPrimary' : 'colorGray'
                }
                status={values.isEnabled ? 'Ativo' : 'Inativo'}
                checked={values.isEnabled}
                onChange={() =>
                  setFieldValue('isEnabled', !values.isEnabled)
                }
              />
            </Field>
          )}

          <Subheading>{t('form.next.profileForm.identification.subheading')}</Subheading>

          {/* Nome do perfil */}
          <InputField
            type="text"
            field="name"
            label={t('form.next.profileForm.field.name.label')}
            placeholder={t('form.next.profileForm.field.name.placeholder')}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />
          {/* \ Nome do perfil */}

          {/* Atribuição do perfil */}
          <InputField
            type="text"
            field="attribution"
            label={t('form.next.profileForm.field.attribution.label')}
            placeholder={t('form.next.profileForm.field.attribution.placeholder')}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />
          {/* \ Atribuição do perfil */}

          <Separator />

          <Subheading>{t('form.next.profileForm.functionalities.subheading')}</Subheading>

          <Field>
            <CheckboxTable
              fieldGroup="allowRoutes"
              colSize={{ sm: 4 }}
              title={t("form.next.profileForm.field.allowRoutes.label")}
              setFieldValue={setFieldValue}
              isProfileForm
              labelList={[
                {
                  value: 'Criar',
                  label: t("form.next.profileForm.field.allowRoutes.options.label.create")
                },
                {
                  value: 'Edit',
                  label: t("form.next.profileForm.field.allowRoutes.options.label.edit")
                },
                {
                  value: 'Excluir',
                  label: t("form.next.profileForm.field.allowRoutes.options.label.delete")
                },
                {
                  value: 'Ler',
                  label: t("form.next.profileForm.field.allowRoutes.options.label.read")
                }]
              }
              list={functionalities}
              itemList="level"
              labelOption="label"
              values={values}
              errors={errors}
              touched={touched}
            />
          </Field>

          {!values.routes && (
            <Message className="routes--error">
              {t('form.next.profileForm.error.choose-at-lease-one-value')}
            </Message>
          )}

          <Separator />

          {/* \ Botões */}
          <BtnGroup>
            <Button
              key="sendtoConfirm"
              className="tertiary"
              onClick={() => {
                handleSubmit();
              }}
              disabled={!props.isValid || props.isUploading}
            >
              {t("global.button.save")}
            </Button>
            <Button
              key="clean"
              onClick={() => resetForm()}
              className="white"
            >
              {t("global.button.clear")}
            </Button>
          </BtnGroup>
          {/* \ Botões */}
        </Form>
      )}
    </Container>
  );
}
