import styled from 'styled-components';

import { colorGrayUltraLight } from 'components/GlobalStyles/variables';

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* opacity: 1 !important; */
  position: relative;
  transition: opacity 0.5s;
  background-color: ${colorGrayUltraLight};
`;

export const Main = styled.div`
  min-height: 100vh;
  /* max-height: 100vh; */
  /* overflow: hidden; */
  // overflow: scroll;
  scroll-behavior: smooth;
`;
