import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  colorClear,
  colorGrayLighter,
  colorGrayDarkest,
  colorGrayDarker,
  colorTertiary,
  colorDanger,
  colorPrimary,
  headerHeight,
  DEFAULT_SIZE,
  breakpointTablet,
} from 'components/GlobalStyles/variables';
import { Menu } from '@zendeskgarden/react-dropdowns';
import { FauxInput } from '@zendeskgarden/react-forms';

import { Container, utils } from 'styled-minimal';

const {
  // responsive,
  spacer,
} = utils;

export const SearchIcon = styled.span`
  font-size: 16px;
`;

export const HeaderWrapper = styled.header`
  background-color: ${colorClear};
  height: ${headerHeight}px;
`;

export const HeaderContainer = styled(Container)`
  background-color: ${colorClear};
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  padding-bottom: ${spacer(2)};
  padding-top: ${spacer(2)};
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};

  width: 100vw;
  max-width: 100%;
  margin: 0;

  .search {
    flex: 1;
    margin-right: ${DEFAULT_SIZE * 2}px;
  }

  .notification-area {
    .notification-toggle {
      cursor: pointer;
    }

    .icon-bell-enabled {
      font-size: 22px;
      color: ${colorGrayLighter};
    }

    .icons-holder {
      position: relative;

      .dot {
        position: absolute;
        right: 1px;
        top: -5px;
      }
    }
  }

  @media (min-width: ${breakpointTablet}px) {
    justify-content: flex-end; //space-around
    width: inherit;
  }
`;

export const Input = styled(FauxInput)`
  border: 0 none !important;
  padding-left: 0 !important;

  input {
    margin-top: 3px !important;
  }
`;

export const MenuHolder = styled(Menu)`
  padding: 0 !important;
  margin: 0 !important;
  border: 0 none !important;
  background-color: transparent !important;
  width: 350px;
  max-height: 400px;
  overflow: hidden !important;

  button {
    cursor: pointer;
    background-color: transparent;
    border: 0 none;
  }

  .notification-heading {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: ${colorClear} !important;
    margin-bottom: 12px;
  }

  .notification-item {
    display: flex;
    flex-direction: row;
    /* margin-left: -16px;
    margin-right: -16px; */
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;

    &.is-unread {
      background-color: ${colorGrayDarker};
    }

    .notification-icon {
      width: 40px;
      display: flex;
      justify-content: center;
      margin-right: 16px;
    }

    .notification-icon-holder {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${colorClear};
      border-radius: 8px;
      width: 40px;
      height: 40px;
      font-size: 24px;

      .icon-warehouse {
        color: ${colorTertiary};
      }

      .icon-truck {
        color: ${colorPrimary};
      }

      .icon-chicken,
      .icon-pig,
      .icon-turkey {
        color: ${colorDanger};
      }
    }

    .notification-message {
      flex: 1;
      padding-bottom: 20px;
      border-bottom: 1px solid ${rgba(colorClear, 0.12)};
    }

    .notification-text {
      font-size: 14px;
      margin-bottom: 8px;
      line-height: 20px;
      flex: 1;
    }

    .notification-date {
      font-size: 10px;
      letter-spacing: 1.5px;
      color: ${colorGrayLighter};
    }

    .notification-message-holder {
      display: flex;
    }

    .notification-actions {
      .notification-open-actions {
        background-color: transparent;
        border: 0 none;
        cursor: pointer;
        color: ${colorClear};
        font-size: 22px;
      }
    }
  }

  .notification-empty-message {
    text-align: center;
    padding: 16px 0;
  }

  .notification-content {
    position: relative;
    height: 300px;
    overflow: auto;
    left: -16px;
    right: -16px;
    width: 350px;
  }

  .notification-action-link {
    text-align: left;
    color: ${colorGrayDarker};
  }
  .notification-action-button {
    color: ${colorGrayDarkest};
  }

  .notification-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .notification-clear {
    color: ${colorPrimary};
    letter-spacing: 0.15px;
  }
`;
