import styled from 'styled-components';
import {
  colorClear,
  colorPrimary,
  colorTertiary,
  colorGrayLighter,
  colorGrayLightest,
  colorGrayDarkest,
  DEFAULT_SIZE,
  boxShadow,
  breakpointDesktop,
} from 'components/GlobalStyles/variables';

export const AboutStyled = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: ${colorClear};
  padding-bottom: 100px;

  .container-detail {
    background-color: ${colorPrimary};
    height: 3px;
    width: 50px;
    margin: 30px auto 6px;
  }

  .intro {
    color: ${colorGrayDarkest};
    max-width: 712px;
    padding: 0 20px;
    text-align: center;
    margin: 0 auto 56px;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 600px;

  @media (min-width: ${breakpointDesktop}px) {
    max-width: none;
  }
`;

export const Container = styled.div`
  position: relative;
  padding: ${DEFAULT_SIZE * 2}px;
  max-width: 1000px;
  width: 100%;
`;

export const CardStyled = styled.div`
  border: 1px solid ${colorGrayLightest};
  padding: 34px 8px 36px;
  border-radius: 8px;
  text-align: center;
  margin: 0 10px 20px;
  flex-basis: 220px;
  box-shadow: ${boxShadow};

  .icon {
    color: ${colorGrayLighter};
    font-size: 50px;
    display: block;
    margin: 0 auto 42px;
  }

  .title {
    color: ${colorTertiary};
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .description {
    color: ${colorGrayDarkest};
    max-width: 712px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
`;
