import React, { useCallback } from 'react';
import Card from 'components/Card';
import t from 'components/i18n';
import IconButton from 'components/IconButton';
import { ListWrapper } from './Indicators.styled';

export default function IndicatorsList({
  title,
  main,
  secondary,
  color,
  setSelected,
}) {
  const displayMainList = useCallback(
    list => {
      return (
        list &&
        list.length > 0 &&
        list.map(item => (
          <Card
            key={item.title}
            title={item.title}
            icon={item.icon}
            iconBackGround={color}
            additional={' '}
            subtitle={item.subheader}
            action={() => setSelected(item)}
            roundIconPlaceholder
            displayChevron
            noShadow
          />
        ))
      );
    },
    [color, setSelected],
  );

  const displaySecondaryList = useCallback(
    list => {
      return (
        list &&
        list.length > 0 &&
        list.map(item => (
          <IconButton
            key={item.title}
            primaryIcon={item.icon}
            caption={item.title}
            bgColor={color}
            action={() => (item.link ? setSelected(item) : {})}
            soon={!item.link}
            disabled={!item.link}
            badgeColor="colorPrimary"
          />
        ))
      );
    },
    [color, setSelected],
  );

  return (
    <ListWrapper>
      <div className="list-wrapper">
        <h1>{title}</h1>

        <h3>{t('next.indicatorList.subtitle')}</h3>

        <div className="flex">{displayMainList(main)}</div>
      </div>

      {secondary && secondary.length > 0 && (
        <div className="list-wrapper gray">
          <h1>{t('next.indicatorList.others.subtitle')}</h1>

          <div className="flex">
            {displaySecondaryList(secondary)}
          </div>
        </div>
      )}
    </ListWrapper>
  );
}
