// @flow
import { createActions } from 'redux-actions';
import { MODAL_OPEN, MODAL_CLOSE } from './types';

export const { modalOpen, modalClose } = createActions({
  [MODAL_OPEN]: payload => ({
    ...payload,
  }),
  [MODAL_CLOSE]: payload => ({
    ...payload,
  }),
});
