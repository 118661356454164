export default function getTextfieldClass(validation) {
  if (validation === 'none') {
    return '';
  }

  if (validation === 'error') {
    return 'has-error';
  }

  return '';
}
