import React, { useState, useEffect } from 'react';
import qs from 'qs';
import NoContentTab from 'components/NoContentTab';
import LoadingHolder from 'components/LoadingHolder';
import { NEWS_LIST } from 'config/routes';
import {
  SpotLightImageWrapper,
  TextWrapper,
  FullTitle,
  SubTitle,
  Card,
  DateAndTime,
  FullNewsStyled,
  Text,
} from './News.styled';
import { getNewsById } from './service';
import { handleDateAndTime } from './utils';
import t from 'components/i18n';
import moment from 'moment';

export default function FullNewsItem(props) {
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const newsId = props.newsId ? props.newsId : props.match.params.id;
  const token = props.location
    ? qs.parse(props.location.search)['?token']
    : null;

  async function fetchData(id, tk) {
    setIsLoading(true);

    try {
      const response = await getNewsById(id, tk);
      setContent(response.data);
    } catch (e) {
      console.error('Error on getNewsById', e);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData(newsId, token);
  }, [newsId, token]);

  return (
    <Card>
      <LoadingHolder
        isLoading={isLoading}
        message={t("global.loading")}
      />
      {!isLoading &&
        (Object.keys(content).length > 0 ? (
          <FullNewsStyled {...props}>
            <DateAndTime>
              {handleDateAndTime(moment(new Date(content.createdAt)))}
            </DateAndTime>
            <FullTitle {...props}>{content.title}</FullTitle>
            <SubTitle>{content.summary}</SubTitle>
            <SpotLightImageWrapper {...props} visible={imageLoaded}>
              {content.image && (
                <img
                  src={content.image}
                  alt={content.title}
                  onLoad={() => setImageLoaded(true)}
                />
              )}
            </SpotLightImageWrapper>
            <TextWrapper {...props}>
              {!props.highlight && (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: content.text,
                  }}
                />
              )}
            </TextWrapper>
          </FullNewsStyled>
        ) : (
          <NoContentTab
            icon="icon-close-circle"
            onButtonClick={() => props.history.push(NEWS_LIST)}
            buttonLabel="Voltar para notícias"
          >
            <p>
              {t('news.nonexistent.description')}<br />{' '}
              <span className="lighter">
                {t('news.nonexistent.go-back')}
              </span>
            </p>
          </NoContentTab>
        ))}
    </Card>
  );
}
