import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  fontFamilyTextBold,
  colorGrayDarkest,
  colorClear,
  colorInfo,
} from 'components/GlobalStyles/variables';

export const ReadNote = styled.div`
  font-family: ${fontFamilyTextBold};
  font-size: 12px;
  line-height: 16px;
  background-color: ${colorInfo};
  color: ${colorClear};
  padding: 12px 20px;

  span {
    font-size: 16px;
    margin-right: 4px;
  }
`;

export const OrientationStyled = styled.div`
  display: flex;
  padding: 20px;

  .secondary {
    max-width: 316px;
    margin-right: 20px;
  }

  .primary {
    width: 100%;
    max-width: 652px;
    margin-right: 20px;
  }
`;

export const Item = styled.div`
  color: ${colorGrayDarkest};
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.25)};
  padding: 16px 0;
  font-size: 14px;
  line-height: 21px;

  &:last-of-type {
    border: none;
  }

  &.right {
    justify-content: flex-end;
  }

  .margin {
    margin-bottom: 8px;
  }

  .label {
    font-size: 12px;
  }

  .small {
    font-size: 12px;
    line-height: 16px;
  }

  button {
    width: 154px;
  }
`;
