import React, { useState } from 'react';

import { Field, Label, MediaInput, Message } from '@zendeskgarden/react-forms';

import { EyeIcon } from 'components/Forms/Forms.styled';

import { getValidation } from 'shared/utils/Helpers';

export default function InputPassword({
  name,
  label,
  placeholder,
  onBlur,
  value,
  onChange,
  errors,
  touched
}) {
  const [showPassword, setShowPassword] = useState(false);

  const checkValidation = getValidation(value, errors, name, !touched[name])

  return (
    <Field
      className={checkValidation === 'error' && errors[name] ? 'has-error' : ''}
    >
      <Label>{label}</Label>
      <MediaInput
        name={name}
        className="faux-child"
        type={showPassword !== true ? 'password' : 'text'}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        end={
          <EyeIcon
            className={!showPassword ? 'icon-visibility-enabled' : 'icon-visibility-disabled'}
            onClick={() => setShowPassword(!showPassword)}
          />
        }
        erros={errors}
        touched={touched}
      />
      {checkValidation === 'error' && errors[name] && (
        <Message validation={checkValidation}>
          {errors[name]}
        </Message>
      )}
    </Field>
  )
}