import React, { useCallback, useEffect, useState } from 'react';

import Breadcrumb from 'components/Breadcrumb';
import LoadingHolder from 'components/LoadingHolder';
import { Formik } from 'formik';
import Top from 'components/Top';
import * as yup from 'yup';

import * as routes from 'config/routes';
import { Button } from '@zendeskgarden/react-buttons';
import Modal from 'components/Forms/FormModal/Modal';
import validate from 'components/Forms/Fields/validate';
import { TextAreaField } from 'components/Forms/Fields';
import t from 'components/i18n';
import Toaster from 'components/Toaster';

import {
  colorDanger,
  colorSuccess,
} from 'components/GlobalStyles/variables';
import {
  approveInvoice,
  getAttachment,
  getFlockInvoiceById,
  rejectInvoice,
} from '../../service';
import Image from 'components/Image';
import Icon from 'components/Icon';

import {
  IconButton,
  BtnWrapper,
  Separator,
  Container,
  FormSection,
  Item,
  SectionWrapper,
  Title,
  DFlex,
  BtnGroup,
  StatusBadgeContainer
} from './Details.styled';
import moment from 'moment';

function validateSchema() {
  return yup.object().shape({
    rejectionReason: yup
      .string()
      .min(5, t('global.validation.fill-input'))
      .max(500, t('global.validation.fill-input')),
  });
}

function StatusBadge(props) {
  const { invoice: { invoiceStatus, approvedRejectedDate } } = props;

  const isApproved = invoiceStatus === "Concluido";

  return (
    <StatusBadgeContainer isApproved={isApproved}>
      <span
        className={
          `icon
            ${ isApproved ? 'icon-exclamation-circle' : 'icon-check' }
          `
        }
      />
      {isApproved
        ? t(
            'next.financials.badge.approved-on',
            {
              date: moment(approvedRejectedDate).format("DD̸ MM̸ YYYY"),
              hour: moment(approvedRejectedDate).format("HH˸mm"),
            }
          )
        : t(
            'next.financials.badge.rejected-on',
            {
              date: moment(approvedRejectedDate).format("DD̸ MM̸ YYYY"),
              hour: moment(approvedRejectedDate).format("HH˸mm"),
            }
          )
      }
    </StatusBadgeContainer>
  );
}

function InvoiceDetails(props) {
  const { history, user } = props;

  const invoiceId = props.match.params.id;

  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const [showRejectionFlow, setShowRejectionFlow] = useState(false);
  const [confirmationReason, setConfirmationReason] = useState(null);
  const [approveConfirmation, setApproveConfirmation] = useState(false);
  const [rejectConfirmation, setRejectConfirmation] = useState(false);

  const [filled, setFilled] = useState(false);
  const [attachment, setAttachment] = useState(null);

  useEffect(() => {
    fetch();
  }, [response]);

  useEffect(() => {
    // God I'm sorry about that
    const rejectAction = document.location.href.includes('action=reject');

    setShowRejectionFlow(rejectAction && !filled);
  }, [filled]);

  const fetch = useCallback(async () => {
    if (!invoiceId) {
      return;
    }

    setLoading(true);

    const invoice = await getFlockInvoiceById(invoiceId);

    if (invoice.invoiceBlobName) {
      const image64 = await getAttachment(invoice.invoiceBlobName);
      setAttachment(image64);
    }

    setInvoice(invoice);
    setLoading(false);
    setFilled(invoice.invoiceStatus === 'Concluido' || invoice.invoiceStatus === 'Reprovado');

    if (invoice.invoiceStatus === 'Reprovado') {
      setRejectConfirmation(invoice.reasonRejection);
      setShowRejectionFlow(true);
    }
  }, [invoiceId]);

  const handleReject = async id => {
    const data = {
      invoiceId: id,
      invoiceStatus: "Reprovado",
      motive: confirmationReason
    };

    const result = await rejectInvoice(data);

    setResponse(result);
  };

  const handleApprove = async id => {
    const data = {
      invoiceId: id,
      invoiceStatus: "Concluido",
    };

    const result = await approveInvoice(data);

    setResponse(result);
  };

  if (loading) {
    return <LoadingHolder isLoading={true} />;
  }

  if (!invoice) {
    return null;
  }

  return (
    <div className="flock">
      <Breadcrumb
        history={history}
        path={[
          t('next.financials.bc.home'),
          t('next.financials.bc.financials'),
          t('next.financials.bc.flock', { value: invoice.flockNumber }),
        ]}
        links={[
          () => history.push(routes.HOME),
          () => history.push(routes.FINANCIALS),
        ]}
        backTo={() => history.push(routes.FINANCIALS)}
      />

      <Top slim>
        <header className="header">
          <h2>{t('next.financials.details.title', { value: invoice.flockNumber })}</h2>
        </header>
        <Separator />
      </Top>

      {invoice && (invoice.invoiceStatus === 'Concluido' || invoice.invoiceStatus === 'Reprovado') && (
        <StatusBadge invoice={invoice} />
      )}

      <Container slim>
        <h4 className="header">
          {t('next.financials.details.subtitle')}
        </h4>

        <Separator />

        <Formik
          onSubmit={() => {}}
          initialValues={{}}
          validate={validate(validateSchema)}
          enableReinitialize
          render={({
            touched,
            errors,
            setFieldValue,
          }) => (
            <>
              <FormSection>
                {showRejectionFlow && (
                  <SectionWrapper style={{ padding: '0 16px' }}>
                    <Title>{t('next.financials.details.reject-reason.title')}</Title>

                    <TextAreaField
                      field="rejectionReason"
                      placeholder={t(
                        'next.financials.details.reject-reason.placeholder',
                      )}
                      onChange={e =>
                        setConfirmationReason(e.target.value)
                      }
                      values={{
                        rejectionReason: confirmationReason
                      }}
                      disabled={filled}
                      errors={errors}
                      touched={touched}
                    />
                  </SectionWrapper>
                )}

                <Item className="flex">
                  <span className="small">
                    {t('next.financials.details.data.producerName')}
                  </span>
                  <p className="right">
                    {invoice.producerName}
                  </p>
                </Item>

                <Item className="flex">
                  <span className="small">
                    {t('next.financials.details.data.code')}
                  </span>
                  <p className="right">{invoice.producerVendor}</p>
                </Item>

                <Item className="flex">
                  <span className="small">
                    {t('next.financials.details.data.flock')}
                  </span>
                  <p className="right">{invoice.flockNumber}</p>
                </Item>

                <Item className="flex">
                  <span className="small">{t('next.financials.details.data.unit')}</span>
                  <p className="right">{invoice.unidadeName}</p>
                </Item>

                <Item className="flex">
                  <span className="small">
                    {t('next.financials.details.data.qtSlaughtered')}
                  </span>
                  <p className="right">{ t("next.financials.details.data.qtSlaughtered.value", { value: invoice.qtPlacementReal })}</p>
                </Item>

                <Separator style={{ margin: '0' }} />

                <SectionWrapper>
                  <Title>{t('next.financials.details.data.notes')}</Title>

                  <TextAreaField
                    field="invoiceDescription"
                    placeholder={t(
                      'invoiceDetails.placeholder.typeNotes',
                    )}
                    onChange={e =>
                      setFieldValue('description', e.target.value)
                    }
                    values={invoice}
                    disabled={true}
                    errors={[]}
                    touched={false}
                  />
                </SectionWrapper>

                <Separator style={{ margin: '0' }} />

                <SectionWrapper>
                  <Title required>
                    {t('invoiceDetails.label.addInvoice')}
                  </Title>

                  {invoice.invoiceBlobName && (
                    <Image
                      api={attachment}
                      item={invoice.invoiceBlobName}
                      blobContainer="invoice"
                      token={user.token}
                      showRemoveFile={false}
                    />
                  )}
                </SectionWrapper>
              </FormSection>

              <BtnGroup>
                <BtnWrapper filled={filled}>
                  <Button
                    key="clean"
                    onClick={() => 
                      showRejectionFlow
                        ? setShowRejectionFlow(false)
                        : history.goBack()
                    }
                    className="white"
                  >
                    {t('next.financials.details.bottomButtons.back')}
                  </Button>
                  
                  {!filled && (
                    showRejectionFlow ? (
                      <IconButton
                        key="rejectConfirmation"
                        onClick={() => setRejectConfirmation(true)}
                        className="tertiary"
                        disabled={
                          !confirmationReason ||
                          (confirmationReason && !confirmationReason.length) ||
                          Object.keys(errors || {}).length
                        }
                      >
                        {t('next.financials.details.bottomButtons.continue')}
                      </IconButton>
                    ) : (
                      <div>
                        <IconButton
                          key="approve"
                          onClick={() => setApproveConfirmation(true)}
                          className="white"
                          style={{
                            marginLeft: '1rem',
                          }}
                        >
                          {t('next.financials.details.bottomButtons.approve-note')}
                          <Icon
                            name="icon-check"
                            size={26}
                            color={colorSuccess}
                          />
                        </IconButton>

                        <IconButton
                          key="reject"
                          onClick={() => setShowRejectionFlow(true)}
                          className="white"
                        >
                          {t('next.financials.details.bottomButtons.reject-note')}
                          <Icon
                            name="icon-close"
                            size={26}
                            color={colorDanger}
                          />
                        </IconButton>
                      </div>
                    )
                  )}
                </BtnWrapper>
              </BtnGroup>
            </>
          )}
        />

        {approveConfirmation && (
          <Modal
            warning
            opened={approveConfirmation}
            confirm={() => {
              setApproveConfirmation(false);
              handleApprove(invoiceId);
            }}
            deny={() => setApproveConfirmation(false)}
            onCloseModal={() => setApproveConfirmation(false)}
            confirmLabel={t('next.financials.details.modal-confirmation.confirm-approve')}
            denyLabel={t('next.financials.details.modal-confirmation.back-approve')}
          >
            <DFlex>
              <span className="icon icon-exclamation-triangle" />
              <h4>{t('next.financials.details.modal-confirmation.approve.title')}</h4>
            </DFlex>
            <p>{t('next.financials.details.modal-confirmation.approve.description')}</p>
          </Modal>
        )}

        {rejectConfirmation && (
          <Modal
            warning
            opened={rejectConfirmation}
            confirm={() => {
              setRejectConfirmation(false);
              handleReject(invoiceId);
            }}
            deny={() => setRejectConfirmation(false)}
            onCloseModal={() => setRejectConfirmation(false)}
            confirmLabel={t('next.financials.details.modal-confirmation.confirm-reject')}
            denyLabel={t('next.financials.details.modal-confirmation.back-reject')}
          >
            <DFlex>
              <span className="icon icon-exclamation-triangle" />
              <h4>{t('next.financials.details.modal-confirmation.reject.title')}</h4>
            </DFlex>
            <p>{t('next.financials.details.modal-confirmation.reject.description')}</p>
          </Modal>
        )}

        {response && (
          <Toaster
            className="toaster__notification"
            isVisible={true}
            color={
              showRejectionFlow
                ? response.success ? 'danger' : 'danger'
                : response.success ? 'success' : 'danger'
              }
            duration={3}
            delay={0}
            title={
              showRejectionFlow
                ? 
                  response.success
                    ? t('next.financials.details.toast.reject.success.title')
                    : t('next.financials.details.toast.reject.error.title')
                :
                  response.success
                    ? t('next.financials.details.toast.approve.success.title')
                    : t('next.financials.details.toast.approve.error.title')
            }
          >
            <span className="icon icon-check" />
            {
              showRejectionFlow
                ? 
                  response.success
                    ? t('next.financials.details.toast.reject.success.description')
                    : t('next.financials.details.toast.reject.error.description')
                :
                  response.success
                    ? t('next.financials.details.toast.approve.success.description')
                    : t('next.financials.details.toast.approve.error.description')
            }
          </Toaster>
        )}
      </Container>
    </div>
  );
}

export default InvoiceDetails;
