import styled from 'styled-components';
import {
  boxShadow,
  fontFamilyText,
  fontFamilyTextBold,
  colorGray,
  colorClear,
  breakpointTablet,
  DEFAULT_SIZE,
} from 'components/GlobalStyles/variables';

export const CollaboratorStyled = styled.section`
  position: relative;
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;
`;

export const Subheading = styled.h3`
  font-size: 16px;
  line-height: 24px;
`;

export const Container = styled.div`
  position: relative;
  // padding: 0 ${DEFAULT_SIZE * 2}px;
  overflow: hidden;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};
  max-width: 630px;

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px;
  }

  p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }

  ${Subheading} {
    font-family: ${fontFamilyTextBold};
    font-weight: normal;
    font-size: 16px;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }
  
  }
`;
