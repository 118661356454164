import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import {
  Item,
  Menu,
  Label,
  Field,
} from '@zendeskgarden/react-dropdowns';
import { Message } from '@zendeskgarden/react-forms';
import {
  getValidationMessage,
  getValidation,
} from 'shared/utils/Helpers';
import { DropdownBox, AutocompleteBox } from '../Forms.styled';

export default function AutoCompleteDropdownField(props) {
  const {
    label,
    search = false,
    caption = false,
    optionLabel,
    noMatchLabel,
    onSelect,
    selectObject = false,
    options,
    disabled,
    loading,
    loadingLabel,
    placeholder,
    field,
    values,
    errors,
    touched,
  } = props;
  const [selectedItem, setSelectedItem] = useState(null);
  const [sObject, setObject] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [matchingOptions, setMatchingOptions] = useState(options);

  const filterSearch = debounce(val => search(val.trim()), 1000);

  const filterMatchingOptions = debounce(value => {
    const match = options.filter(option => {
      if (caption) {
        return (
          option[optionLabel]
            .trim()
            .toLowerCase()
            .indexOf(value.trim().toLowerCase()) !== -1 ||
          option[caption]
            .trim()
            .toLowerCase()
            .indexOf(value.trim().toLowerCase()) !== -1
        );
      }

      return (
        option[optionLabel]
          .trim()
          .toLowerCase()
          .indexOf(value.trim().toLowerCase()) !== -1
      );
    });

    setMatchingOptions(match);
  }, 300);

  useEffect(() => {
    if (selectedItem) return;

    if (values[field] && typeof values[field] === 'string') {
      setSelectedItem(values[field]);
    }
  }, [field, values, selectedItem]);

  useEffect(() => {
    if (sObject) return;

    if (typeof values[field] === 'object') {
      setObject(values[field]);
    }
  }, [sObject,field, values]);

  useEffect(() => {
    if (inputValue && inputValue.length > 3) {
      if (search) {
        filterSearch(inputValue);
      } else {
        filterMatchingOptions(inputValue);
      }
    }

    if (inputValue && inputValue.length < 3) {
      setMatchingOptions(options);
    }
  }, [
    filterMatchingOptions,
    filterSearch,
    inputValue,
    options,
    search,
  ]);

  useEffect(() => {
    if (selectedItem) {
      onSelect(selectObject ? sObject : selectedItem);
    }
  }, [sObject, selectObject, selectedItem]);

  useEffect(() => {
    if (sObject && !selectedItem) {
      setSelectedItem(
        sObject[caption]
          ? `${sObject[caption]} - ${sObject[optionLabel]}`
          : sObject[optionLabel],
      );
    }
  }, [caption, optionLabel, sObject, selectedItem]);

  const renderOptions = match => {
    if (!match.length) {
      return <Item disabled>{noMatchLabel}</Item>;
    }

    return match.map((option, index) => (
      <Item key={index} value={option}>
        {!caption && <p>{option[optionLabel]}</p>}
        {caption && (
          <p>
            {option[optionLabel]} - {option[caption]}
          </p>
        )}
      </Item>
    ));
  };

  return (
    <DropdownBox
      inputValue={inputValue}
      selectedItem={selectedItem}
      onSelect={item => setObject(item)}
      onInputValueChange={inputVal => {
        setInputValue(inputVal);
      }}
      downshiftProps={{
        itemToString: item => item && item[optionLabel],
      }}
    >
      <Field>
        <Label style={{ fontWeight: 500 }}>{label}</Label>

        <AutocompleteBox disabled={disabled}>
          {selectedItem || inputValue ? (
            <span>{selectedItem || inputValue}</span>
          ) : (
            <span className="placeholder">{placeholder}</span>
          )}
        </AutocompleteBox>
      </Field>
      <Menu>
        {loading ? (
          <Item disabled>{loadingLabel}</Item>
        ) : (
          renderOptions(
            matchingOptions.length > 0 ? matchingOptions : options,
          )
        )}
      </Menu>
      <Message
        validation={getValidation(
          values[field],
          errors,
          field,
          !touched[field],
        )}
      >
        {getValidationMessage(field, errors, !touched[field])}
      </Message>
    </DropdownBox>
  );
}
