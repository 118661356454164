import React, { useEffect } from 'react';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { useSelector } from 'react-redux';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import {
  DropdownField,
  InputField,
  FileInput,
} from 'components/Forms/Fields';
import LoadingHolder from 'components/LoadingHolder';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';
import t from 'components/i18n';

export default function AtaForm(props) {
  const {
    selected,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    isUploading,
    isLoading,
    handleFile,
    removeFile,
    values,
    errors,
    touched,
  } = props;

  const user = useSelector(state => state.user);

  useEffect(() => {
    if (selected.fileName) {
      setFieldValue('fileName', selected.fileName);
    }
  }, [selected.fileName, setFieldValue]);

  return (
    <>
      <LoadingHolder
        message={t('global.loading')}
        isLoading={isLoading}
      />
      {!isLoading && (
        <Form autoComplete="off">
          {props.unities && (
            <>
              {/* Unidades */}
              <DropdownField
                field="centers"
                label={t('cadec.form.centers.label')}
                placeholder={t('cadec.form.centers.placeholder')}
                onSelect={item => {
                  setFieldValue('centers', item);
                  setFieldValue('cadecs', []);
                }}
                options={props.unities}
                optionLabel="centerDescription"
                values={values}
                errors={errors}
                touched={touched}
              />

              {/* Cadec */}
              <DropdownField
                field="cadecs"
                label={t('cadec.form.cadecs.label')}
                placeholder={t('cadec.form.cadecs.placeholder')}
                onSelect={item => {
                  setFieldValue('cadecs', item);
                }}
                options={values.centers && values.centers.types}
                group
                optionsTitle="type"
                optionsGroup="cadecs"
                optionLabel="name"
                disabled={
                  values.centers &&
                  !Object.keys(values.centers).length
                }
                values={values}
                errors={errors}
                touched={touched}
              />

              <Row>
                <Col md={6}>
                  {/* Data da reunião */}
                  <InputField
                    type="date"
                    field="date"
                    label={t('cadec.form.date.label')}
                    placeholder={t('cadec.form.date.placeholder')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={
                      values.centers &&
                      !Object.keys(values.centers).length
                    }
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                  {/* \Data da reunião */}
                </Col>
                <Col md={6}>
                  {/* Número da ata */}
                  <InputField
                    type="text"
                    field="number"
                    label={t('cadec.form.number.label')}
                    placeholder={t('cadec.form.number.placeholder')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={
                      values.centers &&
                      !Object.keys(values.centers).length
                    }
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
              </Row>

              {/* Ata da reunião */}
              <FileInput
                type="file"
                field="fileName"
                label={t('cadec.form.fileName.label')}
                onChange={e => {
                  handleFile(e.target.files[0]);
                  handleChange(e);
                }}
                itemLabel="fileName"
                removeFile={e => {
                  removeFile(selected.fileName);
                  setFieldValue('fileName', '');
                }}
                itemToRemove="id"
                isLoading={isUploading}
                itemsToList={[values.fileName]}
                itemLink={`${API_URL}/Blob/Download?fileName=${selected.fileName}&blobContainerName=${selected.container}&token=${user.token}&client_id=${KONG_CLIENT_ID}`}
                blobContainerName={selected.container}
                disabled={
                  values.centers &&
                  !Object.keys(values.centers).length
                }
                token={user.token}
                onBlur={handleBlur}
                accept="application/pdf"
                values={values}
                errors={errors}
                touched={touched}
              />

              <Separator />

              <BtnGroup>
                <Button
                  key="sendtoConfirm"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={!props.isValid || props.isUploading}
                >
                  {t("global.button.save")}
                </Button>
                <Button
                  key="clean"
                  onClick={() => resetForm()}
                  className="white"
                >
                  {t("global.button.clear")}
                </Button>
              </BtnGroup>
            </>
          )}
        </Form>
      )}
    </>
  );
}
