import styled from 'styled-components';
import { ButtonGroup } from '@zendeskgarden/react-buttons';
import {
  colorPrimary,
  colorGrayLight,
  colorClear,
  colorGrayDarkest,
  breakpointTablet,
  breakpointDesktop,
  fontFamilyTextBold,
  DEFAULT_SIZE,
} from 'components/GlobalStyles/variables';

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;
`;

export const BtnGroup = styled(ButtonGroup)`
  display: flex;
  flex-direction: ${props =>
    props.noReverse ? 'row' : 'row-reverse'};
  justify-content: ${props =>
    props.justifyContent || 'space-between'};
  gap: 16px;

  @media (min-width: ${breakpointTablet}px) {
    justify-content: ${props => props.justifyContent || 'flex-start'};
  }

  button {
    border: 1px solid ${props =>
      props.blue
        ? props.color.colorGrayDarkest || colorGrayDarkest
        : props.color || colorPrimary} !important;
    width: ${props => (props.full ? '100%' : '120px')};
    font-size: 13px;
    height: 40px !important;    
    font-family: ${fontFamilyTextBold};
    margin-left: 0;
    margin-bottom: 10px;
    float: ${props => (props.left ? 'left' : 'right')};
    background-color: ${props =>
      props.blue
        ? props.color.colorGrayDarkest || colorGrayDarkest
        : props.color || colorPrimary}!important;

    @media (min-width: ${breakpointDesktop}px) {
      width: 150px;      
    }

    @media (min-width: ${breakpointTablet}px) {
      margin-bottom: 0;
    }
    
    &:hover {
      border-color: ${colorGrayDarkest} !important;
    }

    &[disabled] {
      &:hover {
        border-color: transparent !important;
      }
    }

    &.white {
      background-color: ${colorClear} !important;
      border-color: ${colorGrayDarkest} !important;
      color: ${colorGrayDarkest} !important;

      &:hover {
          color: ${colorGrayLight}!important;
    }

    &.no-border {
      width: 96px;
      border: none!important;
      padding: 0!important;
      margin: 0!important;

      @media (max-width: ${breakpointTablet}px) {
        display: none;
      }
    }

    &.border-radius {
      border-radius: 8px;
    }

    &.pointer {
      cursor: pointer;
    }

    &.noFloat {
      float: none;
      margin: 0!important;

      @media (max-width: ${breakpointTablet}px) {
        width: 100%;
      }
    }
  }
`;
