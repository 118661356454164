import React, { useState } from 'react';
import * as routes from 'config/routes';
import { handleDateAndTime } from './utils';
import t from 'components/i18n';
import {
  NewsListItemStyled,
  ImageWrapper,
  TextWrapper,
  Title,
  Excerpt,
  DateAndTime,
} from './News.styled';
import moment from 'moment';

export default function NewsListItem(props) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const {
    content,
    history,
    highlight,
    fullWidth,
    defaultText,
    stayTunedHighlight,
  } = props;

  return content ? (
    <NewsListItemStyled
      highlight={highlight}
      fullWidth={fullWidth}
      defaultText={defaultText}
      stayTunedHighlight={stayTunedHighlight}
      onClick={() =>
        history.push(`${routes.OPEN_NEWS}/${content.id}`)
      }
    >
      <ImageWrapper
        highlight={highlight}
        fullWidth={fullWidth}
        defaultText={defaultText}
        visible={imageLoaded}
      >
        <img
          src={content.image}
          alt={content.title}
          onLoad={() => setImageLoaded(true)}
        />
      </ImageWrapper>
      <TextWrapper>
        {/* {content.title === null && <Title>Teste BRF</Title>} */}
        <Title
          highlight={props.highlight}
          fullWidth={fullWidth}
          defaultText={defaultText}
        >
          {content.title}
        </Title>
        {props.hasExcerpt && <Excerpt>{content.summary}</Excerpt>}
        {!props.highlight && (
          <DateAndTime>
            {handleDateAndTime(moment(new Date(content.createdAt)))}
          </DateAndTime>
        )}
      </TextWrapper>
    </NewsListItemStyled>
  ) : (
    <p>{t('news.load-more.error')}</p>
  );
}
