import t from "components/i18n";

export default function getLibraryFilters(tags) {
  const documentOptions = [
    { txt: t('library.filters.document'), id: 1 },
    { txt: t('library.filters.image'), id: 2 },
    { txt: t('library.filters.video'), id: 3 },
  ];

  const tagOptions =
    tags.length > 0 &&
    tags.map((e, i) => ({ txt: e.name, id: e.id }));

  const filtersData = [
    {
      label: t('library.filters.content'),
      items: documentOptions.length > 0 ? documentOptions : [],
    },
    {
      label: t('library.filters.subject'),
      items: tagOptions.length > 0 ? tagOptions : [],
    },
  ];

  return filtersData;
}
