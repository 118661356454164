import React, { useState, useEffect, useContext } from 'react';
// context
import { MemberContext } from 'shared/contexts/Member';
import LoadingHolder from 'components/LoadingHolder';
import { Button } from '@zendeskgarden/react-buttons';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Form, FixedField } from 'components/Forms/Forms.styled';
import {
  DropdownField,
  InputField,
  TextAreaField,
} from 'components/Forms/Fields';
import Heading from 'components/Heading';
import {
  Container,
  Layer,
} from 'containers/OnePage/BecomeAMember/BecomeAMember.styled';

// service
import {
  getStates,
  getCity,
} from 'containers/OnePage/BecomeAMember/service';
import t from 'components/i18n';

export default function ThirdMemberForm(props) {
  const memberContext = useContext(MemberContext);
  const [countryStates, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const {
    setCoutryState,
    setCityID,
    setPhoneNumber,
    setMessage,
  } = memberContext.actions;

  const {
    setformStep,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    isLoading,
    values,
    errors,
    touched,
  } = props;

  useEffect(() => {
    async function fetch() {
      try {
        const response = await getStates();

        setStates(response.data);
      } catch (e) {
        console.error('Error on getStates', e);
      }
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetch(id) {
      try {
        const response = await getCity(id);

        setCities(response.data);
      } catch (e) {
        console.error('Error on getStates', e);
      }
    }
    if (Object.keys(values.countryState).length > 0) {
      fetch(values.countryState.id);
    }
  }, [values.countryState]);

  return (
    <>
      {isLoading && countryStates.length > 0 ? (
        <LoadingHolder
          isLoading={isLoading && countryStates.length > 0}
          message={t('global.loading')}
        />
      ) : (
        <Form autoComplete="off">
          <>
            {/* Segunda tela */}
            <Layer stage={2}>
              <Heading
                pageTitle={t('beAnAGrower.form.title')}
                subHeading={t('beAnAGrower.form.details.subtitle', { user: memberContext.name.split(' ')[0] })}
              />

              {/* Estados */}
              {countryStates && (
                <DropdownField
                  selectedItem={values.countryState}
                  field="countryState"
                  label={t('beAnAGrower.form.state')}
                  placeholder={t('beAnAGrower.form.select.placeholder')}
                  onSelect={e => {
                    setCoutryState(e.name);
                    setFieldValue('countryState', e);
                    setFieldValue('cityID', 0);
                  }}
                  options={countryStates}
                  optionLabel="name"
                  disabled={countryStates && !countryStates.length}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              )}
              {/* \ Estados  */}

              {/* Cidades */}
              <DropdownField
                selectedItem={values.cityID}
                field="cityID"
                label={t('beAnAGrower.form.city')}
                placeholder={t('beAnAGrower.form.select.placeholder')}
                onSelect={e => {
                  setCityID(e.id);
                  setFieldValue('cityID', e);
                }}
                options={cities && cities}
                optionLabel="name"
                disabled={cities && !cities.length}
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Cidades  */}

              {/* Telefone */}
              <InputField
                masked
                type="phone"
                field="phoneNumber"
                label={t('beAnAGrower.form.phone')}
                setState={e => {
                  setFieldValue('phoneNumber', e);
                  setPhoneNumber(e);
                }}
                onBlur={e => handleBlur(e)}
                onChange={e => handleChange(e)}
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Telefone  */}

              {/* Mensagem */}
              <TextAreaField
                field="message"
                label={t('beAnAGrower.form.message')}
                setState={e => {
                  setFieldValue('message', e);
                }}
                onBlur={e => handleBlur(e)}
                onChange={e => {
                  handleChange(e);
                  setMessage(e.target.value);
                }}
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Mensagem  */}

              {/* \ Botões */}
              <FixedField>
                <Container>
                  <BtnGroup>
                    <Button
                      key="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={!props.isValid || props.isSubmitting}
                    >
                      {t("global.button.send")}
                    </Button>
                    <Button
                      key="back"
                      onClick={() => setformStep(2)}
                      className="white"
                      disabled={props.isSubmitting}
                    >
                      {t("global.button.back")}
                    </Button>
                    <Button
                      key="clean"
                      onClick={() => props.closeForm()}
                      className="white no-border"
                      disabled={props.isSubmitting}
                    >
                      {t("global.button.cancel")}
                    </Button>
                  </BtnGroup>
                </Container>
              </FixedField>
              {/* \ Botões */}
            </Layer>
            {/* \ Primeira tela */}
          </>
        </Form>
      )}
    </>
  );
}
