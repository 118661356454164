import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import LoadingHolder from 'components/LoadingHolder';
import { getAnimalIcon } from 'shared/utils/Helpers';
import { getDashboard } from './service';
import { OverviewStyled, OverviewItem } from './Overview.styled';
import t from 'components/i18n';

export default function Overview({ title, user }) {
  const [isLoading, setIsLoading] = useState(false);
  const [overview, setOverview] = useState(null);

  const numberWithCommas = useCallback(x => {
    return (x || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }, []);

  const overViewList = useMemo(
    () => [
      {
        data: overview && overview.totalProducers,
        label: t('nextdashboard.overview.totalProducers'),
        icon: 'icon-clipboard-user',
      },
      {
        data: overview && overview.totalFlocks,
        label: t('nextdashboard.overview.totalFlocks'),
        icon: 'icon-flock',
      },
      {
        data: overview && overview.totalLogBookItems,
        label: t('nextdashboard.overview.totalLogBookItems'),
        icon: 'icon-calendar-alt',
      },
      {
        data:
          overview && numberWithCommas(overview.totalAnimalsHoused),
        label: t('nextdashboard.overview.totalAnimalsHoused'),
        icon: `${getAnimalIcon(user.animalType)}`,
      },
      {
        data: overview && overview.adjustedFeedConversion,
        label: t('nextdashboard.overview.adjustedFeedConversion'),
        icon: 'icon-chart-line',
        hidden: true
      },
      {
        data: overview && overview.adjustedRealMortality,
        label: t('nextdashboard.overview.adjustedRealMortality'),
        icon: `${getAnimalIcon(user.animalType)}-alt`,
        hidden: true
      },
    ],
    [numberWithCommas, overview, user.animalType],
  );

  useEffect(() => {
    async function getDashboardData() {
      setIsLoading(true);
      try {
        const response = await getDashboard();
        setOverview(response.data);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }

    getDashboardData();
  }, []);

  return (
    <OverviewStyled>
      <strong className="title">{title}</strong>
      <LoadingHolder isLoading={isLoading} />

      <ul className="flex wrap">
        {!isLoading &&
          overview &&
          overViewList.map((item, index) => 
          item.hidden
            ? null
            : (
              <OverviewItem
                className={`flex animated fadeIn ${index === 2 &&
                  user.animalType === 'swine' &&
                  'disabled'}`}
                key={index}
              >
                <div>
                  <strong>{item.data}</strong>
                  <p>{item.label}</p>
                </div>
                <span className={`icon ${item.icon}`} />
              </OverviewItem>
            )
          )
        }
      </ul>
    </OverviewStyled>
  );
}
