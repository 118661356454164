// @flow

import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

import type { TTerm } from 'shared/typings';
import type {
  TAuthenticatePayload,
  TVerifyAccountPayload,
  TAuthenticateVendorCodePayload,
} from './typings';
import { graphConfig } from 'config/authConfig';

export async function authenticate(payload: TAuthenticatePayload) {
  const response = await request(
    `${API_URL}/Authentication/Authenticate`,
    {
      method: 'POST',
      payload,
    },
  );

  const { success } = response;
  if (!success) throw new Error(response.message);

  return response.data;
}

export async function verifyAccount(payload: TVerifyAccountPayload) {
  const response = await request(
    `${API_URL}/Authentication/VerifyAccountVendorCode`,
    {
      method: 'POST',
      payload: {
        ...payload,
        mobileNumber: payload.phone,
        isFirstAccess: payload.isFirstAccess,
      },
    },
  );

  const { success } = response;
  if (!success) throw new Error(response.message);

  return response.data;
}

export async function authenticateSMSToken(
  payload: TVerifyAccountPayload,
) {
  const { smsToken } = payload;
  const response = await request(
    `${API_URL}/Authentication/AuthenticateSMSToken`,
    {
      method: 'POST',
      payload: {
        ...payload,
        smsToken,
      },
    },
  );

  const { success } = response;
  if (!success) throw new Error(response.message);

  return response.data;
}

export async function authenticateVendorCode(
  payload: TAuthenticateVendorCodePayload,
) {
  const { vendorCode } = payload;
  const response = await request(
    `${API_URL}/Authentication/AuthenticateVendorCode`,
    {
      method: 'POST',
      payload: {
        ...payload,
        vendorCode,
      },
    },
  );

  const { success } = response;
  if (!success) throw new Error(response.message);

  return response.data;
}

export async function createPassword(payload: TAuthenticatePayload) {
  const { documentNumber, password } = payload;
  const response = await request(
    `${API_URL}/Authentication/CreatePassword`,
    {
      method: 'POST',
      payload: {
        documentNumber,
        password,
      },
    },
  );

  const { success } = response;
  if (!success) throw new Error(response.message);

  return response.data;
}

export async function passwordRecovery(payload) {
  const response = await request(
    `${API_URL}/Authentication/CreatePasswordChallenge`,
    {
      method: 'POST',
      payload
    },
  );

  const { success } = response;
  if (!success) throw new Error(response.message);

  return response.data;
}

export async function getTerm(payload): Promise<TTerm> {
  const response = await request(`${API_URL}/Authentication/Term`, {
    method: 'GET',
    payload,
  });

  return response.data;
}

export async function acceptTerm(token): Promise<TTerm> {
  const response = await request(
    `${API_URL}/Authentication/TermAccepted`,
    {
      method: 'POST',
      headers: {
        Authorization: token
      }
    },
  );

  return response;
}

export async function getUser() {
  const response = await request(`${API_URL}/User/Get`, {
    method: 'GET',
  });

  return response.data;
}

export async function authenticateNext(payload) {
  const response = await request(
    `${API_URL}/Authentication/AuthenticateNext`,
    {
      method: 'POST',
      payload,
    },
  );

  const { success } = response;
  if (!success) throw new Error(response.message);

  return response.data;
}

export async function authenticateADFS(payload) {
  const response = await request(
    `${API_URL}/Authentication/AuthenticateADFS`,
    {
      method: 'POST',
      payload,
    })

  const { success } = response;
  if (!success) throw new Error(response.message);

  return response.data;
}

export async function logout() {
  const response = await request(
    `${API_URL}/Logout/Logout`,
    {
      method: 'GET'
    })

  const { success } = response;
  if (!success) throw new Error(response.message);

  return response.data;
}
