import {
  FILL_CHECKLIST,
  CHECKLIST_DETAILS,
  CHECKLIST_HISTORY,
} from 'config/routes';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ShowDetails } from './actions';
import { getQuestions, submitAnswer, getHistory } from './service';

export const useChecklist = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const dispatch = useDispatch();
  const { details } = useSelector(state => state.checklist);
  const history = useHistory();

  const setFocusedChecklist = (checklist, vendor) => {
    return dispatch(
      ShowDetails({
        checklist,
        vendor,
      }),
    );
  };

  const onGoToDetails = () => {
    history.push(`${CHECKLIST_DETAILS}`);
    dispatch(
      ShowDetails({
        ...details,
        checklist: {
          ...details.checklist,
          selectedFlock: '',
          submitStatus: '',
          answerSheet: details.checklist.questions
            ? details.checklist.questions.reduce(
                (sheet, question) => {
                  sheet[question.codPerguntaIrn] = '';
                  return sheet;
                },
                {},
              )
            : {},
        },
      }),
    );
  };

  const onFillChecklist = () => {
    history.push(`${FILL_CHECKLIST}`);
  };

  const onPickFlock = flockId => {
    return dispatch(
      ShowDetails({
        ...details,
        checklist: {
          ...details.checklist,
          selectedFlock: flockId,
        },
      }),
    );
  };

  const onSetAnswer = (questionId, answerId) => {
    return dispatch(
      ShowDetails({
        ...details,
        checklist: {
          ...details.checklist,
          answerSheet: {
            ...details.checklist.answerSheet,
            [questionId]: answerId,
          },
        },
      }),
    );
  };

  const onLoadAdditionalData = async () => {
    if (!details) {
      return null;
    }
    const { checklist, vendor } = details;

    setIsLoading(true);
    try {
      const { flocks, questions } = await getQuestions({
        codChaveMtech: checklist.codChaveMtech,
        vendor: vendor.vendorCode,
      });

      const questionGroupedByCategory = questions.reduce(
        (acum, question) => {
          if (acum[question.desCategoria]) {
            acum[question.desCategoria] = [
              ...acum[question.desCategoria],
              question,
            ];
            return acum;
          }

          acum[question.desCategoria] = [question];
          return acum;
        },
        {},
      );

      dispatch(
        ShowDetails({
          ...details,
          checklist: {
            ...checklist,
            flocks,
            questions,
            questionGroupedByCategory,
            answerSheet: questions.reduce((sheet, question) => {
              sheet[question.codPerguntaIrn] = '';
              return sheet;
            }, {}),
            canLoadHistory: true,
          },
        }),
      );
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const onLoadHistory = async () => {
    if (!details) {
      return null;
    }

    const { checklist, vendor } = details;

    setIsLoadingHistory(true);
    try {
      const historyItems = await getHistory({
        codChaveMtech: checklist.codChaveMtech,
        vendor: vendor.vendorCode,
      });

      if (historyItems && historyItems.length) {
        dispatch(
          ShowDetails({
            ...details,
            historyItems,
            canLoadHistory: false,
          }),
        );
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoadingHistory(false);
  };

  const onShowHistory = selectedHistory => {
    dispatch(
      ShowDetails({
        ...details,
        checklist: {
          ...details.checklist,
          selectedHistory,
        },
      }),
    );
    setTimeout(() => {
      history.push(`${CHECKLIST_HISTORY}`);
    }, 300);
  };

  const onSubmitAnswer = async () => {
    const {
      checklist: {
        flocks,
        selectedFlock,
        questions,
        answerSheet,
        indLiquidacao,
      },
    } = details;

    try {
      const builtAnswer = {
        flocks,
        selectedFlock,
        indLiquidacao,
        questions: questions.map(question => ({
          ...question,
          answers: question.answers.map(answer => ({
            ...answer,
            isSelected:
              answerSheet[question.codPerguntaIrn].id ===
              answer.codRespostaIrn,
          })),
        })),
      };

      await submitAnswer(builtAnswer);

      return dispatch(
        ShowDetails({
          ...details,
          checklist: {
            ...details.checklist,
            submitStatus: 'success',
          },
        }),
      );
    } catch (error) {
      return dispatch(
        ShowDetails({
          ...details,
          checklist: {
            ...details.checklist,
            submitStatus: 'error',
          },
        }),
      );
    }
  };

  return [
    { isLoading, details, isLoadingHistory },
    {
      setFocusedChecklist,
      onLoadAdditionalData,
      onFillChecklist,
      onPickFlock,
      onGoToDetails,
      onSetAnswer,
      onSubmitAnswer,
      onLoadHistory,
      onShowHistory,
    },
  ];
};
