import React from 'react';
import NewsListItem from './NewsListItem';
import { SectionTitle, SimpleList } from './News.styled';

export default function NewsColumn(props) {
  const { title, list, history } = props;
  return (
    <>
      {title && (
        <SectionTitle backgroundColor={props.colorPrimary}>
          <div className="container-detail" />
          {title}
        </SectionTitle>
      )}
      {list && (
        <SimpleList>
          {list.map((item, index) => (
            <NewsListItem
              key={index}
              fullWidth
              content={item}
              history={history}
            />
          ))}
        </SimpleList>
      )}
    </>
  );
}
