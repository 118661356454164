import React, { useState, useEffect } from 'react';
import { PROFILE } from 'config/routes';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { JustifiedList, JustifiedListItem } from 'components/List';
import Modal from 'components/Forms/FormModal/Modal';
import { Separator } from '@zendeskgarden/react-dropdowns';
import t from 'components/i18n';

export default function CollaboratorDetails(props) {
  const [openModal, setOpenModal] = useState(false);

  const {
    data,
    selected,
    deleteCollaborator,
    setEditUser,
    setRemovedUser,
    history,
  } = props;

  useEffect(() => {
    setRemovedUser(false);
  }, [setRemovedUser]);

  return (
    <>
      <JustifiedList style={{ padding: '16px' }}>
        {data.map((item, index) => (
          <JustifiedListItem key={index}>
            <span>{item.label}</span>
            <div>{item.item}</div>
          </JustifiedListItem>
        ))}
      </JustifiedList>

      <Separator />

      <br />

      <Row>
        <Col sm={6}>
          <BtnGroup noReverse style={{ padding: '0 0 0 16px' }}>
            <Button
              key="submitForm"
              onClick={() => setOpenModal(true)}
              className="white"
              disabled={props.isValidating}
            >
              {t('menu.employees.employeesList.employeeDetail.buttonSecondary')}
            </Button>
          </BtnGroup>
        </Col>
        <Col sm={6}>
          {/* \ Botões */}
          <BtnGroup style={{ padding: '0 16px 16px' }}>
            <Button key="submitForm" onClick={e => setEditUser(true)}>
              {t('menu.employees.employeesList.employeeDetail.buttonPrimary')}
            </Button>
            <Button
              key="clean"
              className="white"
              onClick={() =>
                history.push(
                  `${PROFILE}/account-settings/collaborators`,
                )
              }
            >
              {t('global.button.back')}
            </Button>
          </BtnGroup>
          {/* \ Botões */}
        </Col>
      </Row>

      <Modal
        warning
        opened={openModal}
        confirm={() => deleteCollaborator(selected.id)}
        deny={() => setOpenModal(false)}
        onCloseModal={() => {
          setOpenModal(false);
        }}
        confirmLabel={t('menu.employees.employeesList.employeeDetail.confirmationModal.buttonSecondary')}
        denyLabel={t('menu.employees.employeesList.employeeDetail.confirmationModal.buttonPrimary')}
      >
        <span className="icon icon-exclamation-triangle" />
        <h4>{t('menu.employees.employeesList.employeeDetail.confirmationModal.title')}</h4>
        <p>
          {t('menu.employees.employeesList.employeeDetail.confirmationModal.description', { collaboratorName: selected.name })}?
        </p>
      </Modal>
    </>
  );
}
