// @flow

import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@zendeskgarden/react-buttons';
import { Dots } from '@zendeskgarden/react-loaders';

import {
  Heading,
  Subheading,
  TitleBlock,
} from 'components/SideBox/SideBox.styled';
import Spacer from 'components/Spacer';
import { Info } from 'components/Forms/Forms.styled';
import t from 'components/i18n';

// import type { TSignup } from './typings';
import InputPassword from 'components/Forms/Fields/InputPassword';

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, t('global.validation.min-character-length', { length: 6 }))
    .required(t('global.status.required-field')),
});

const INITIAL_VALUE = {
  password: '',
};

function CreatePassword(props) {
  // const [showPassword, setShowPassword] = useState(false);
  const { isLoading, user } = props;

  function submitForm(values, actions) {
    const payload = {
      // password: cleanUp(values.password),
      password: values.password,
    };

    props.createPassword(payload, props.isRecovering);
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={INITIAL_VALUE}
      handleSubmit
      onSubmit={submitForm}
      render={({
        values,
        handleSubmit,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldTouched,
        isValid,
      }) => {
        function onChange(e) {
          handleChange(e);
        }

        function onBlur(e) {
          setFieldTouched(e.currentTarget.name);
          handleBlur(e);
        }

        const buttonProps = {
          stretched: true,
          primary: true,
          type: 'submit',
          disabled: !isValid || isLoading,
        };

        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <TitleBlock>
              <Heading>
                {t('recoveryPassword.newPassword.title', {
                  firstName: user.nameProvider || user.providerName,
                })}
              </Heading>
              <Subheading>
                {t('recoveryPassword.newPassword.subtitle')}
              </Subheading>
              <Info>
                {t('recoveryPassword.newPassword.description')}
              </Info>
            </TitleBlock>

            <Spacer multiplier={5} />

            <InputPassword
              name="password"
              label={t('recoveryPassword.newPassword.inputPassword')}
              placeholder={t('recoveryPassword.newPassword.placeholder')}
              onChange={onChange}
              onBlur={onBlur}
              value={values.password}
              errors={errors}
              touched={touched}
            />

            <Spacer />

            {!isLoading && <Button {...buttonProps}>{t('global.button.next')}</Button>}
            {isLoading && (
              <Button {...buttonProps}>
                <Dots />
              </Button>
            )}
          </form>
        );
      }}
    />
  );
}

export default CreatePassword;
