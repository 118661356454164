import React from 'react';
import moment from 'moment';
import SideBox from 'components/SideBox';
import Icon from 'components/Icon';
import Box from 'components/Container';
import t from 'components/i18n';

export default function JustificationDetails({
  displayBox,
  setDisplayBox,
  data,
}) {
  return (
    <SideBox displayBox={displayBox} setDisplayBox={setDisplayBox}>
      <Box className="side-box justification">
        <h1>{t('next.weekSchedule.justificationDetails.title')}</h1>
        {data && data.justification && (
          <>
            <div>
              <p className="flex">
                <span className="label">{t('next.weekSchedule.justificationDetails.date')}</span>
                <span className="data">
                  {moment(data.visitDate).format('DD ̸̸  MM ̸̸  YYYY')}
                </span>
              </p>
            </div>
            <div>
              <p className="flex">
                <span className="label">{t('next.weekSchedule.justificationDetails.hour')}</span>
                <span className="data">
                  {moment(data.visitDate).format('HH:mm')}
                </span>
              </p>
            </div>
            <div className="no-border">
              <p>
                <span className="label">{t('next.weekSchedule.justificationDetails.justification')}</span>
                <br />
                <br />
                <span className="justification full">
                  {data.justification}
                </span>
              </p>
            </div>
          </>
        )}

        {data && !data.justification && (
          <p className="red">
            <Icon name="icon icon-close-circle" />
            {t('next.weekSchedule.justificationDetails.noJustification')}
          </p>
        )}
      </Box>
    </SideBox>
  );
}
