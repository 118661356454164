import styled from 'styled-components';
import {
  colorGrayDarker,
  colorClear,
  colorGrayLightest,
  colorGrayLight,
  colorGrayDarkest,
  fontFamilyTextBold,
  breakpointTablet,
} from 'components/GlobalStyles/variables';

export const Container = styled.div`
  width: 100%;
  background-color: transparent;
`;

export const Header = styled.div`
  display: block;
  background-color: ${colorClear};

  @media (min-width: ${breakpointTablet}px) {
    display: flex;
    flex-direction: row;
  }
`;

export const Content = styled.div`
  overflow: hidden;
`;

export const TabHeaderHolder = styled.div`
  /* flex: 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  min-width: 140px;

  .label {
    font-family: ${fontFamilyTextBold};
    display: flex;
    color: ${colorGrayLight};
    font-size: 13px;
    line-height: 16px;
    padding-left: 24px;
    padding-right: 24px;
    height: 56px;
    align-items: center;
    letter-spacing: 0.75px;
    transition: color 0.3s ease-in-out;
  }

  .bar {
    transition: width 0.5s ease-in-out,
      background-color 0.3s ease-in-out;
    height: 2px;
    width: 0;
    margin-top: -2px;
  }

  &:hover,
  &.active {
    .bar {
      width: 100%;
    }

    .label {
      color: ${colorGrayDarkest};
    }
  }

  &:hover:not(.active) {
    .bar {
      background-color: ${colorGrayLightest};
    }
  }

  &.active {
    .bar {
      background-color: ${colorGrayDarker};
    }
  }
`;

export const TabContentHolder = styled.div``;
