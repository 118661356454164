import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import moment from 'moment';

import { Button } from '@zendeskgarden/react-buttons';

import t from 'components/i18n';
import Container from 'components/Container';
import {
  CircuitQuestionWithAnswer,
  CircuitQuestion,
} from 'components/CircuitQuestion';
import HorizontalSteps from 'components/HorizontalSteps';
import CardWrapper from 'components/CardWrapper';
import NoContentTab from 'components/NoContentTab';
import PostCircuit from 'containers/NextBRF/VisitList/PostCircuit';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import LoadingHolder from 'components/LoadingHolder';

import Card from 'components/Card';
import SubmitModal from './SubmitModal';
import ActionPlan from './ActionPlan';
import ActionPlanEdit from './ActionPlanEdit';
import ActionPlanDetails from './ActionPlanDetails';

import {
  saveLogBookChecklistsActionPlan,
  saveLogBookChecklistsQuestion,
  getLogBookItemDetails,
  getLogbookItemsOrientations,
  uploadImage,
  getLogBookChecklistsSchedules,
} from './service';
import OrientationEditCard from '../VisitList/OrientationEditCard';
import OrientationVisualization from '../VisitList/OrientationVisualization';
import {
  ButtonBack,
  CircuitDescription,
} from './VisitCircuit.styled';

export default function VisitCircuitWrapper(props) {
  const {
    id,
    params,
    user,
    vendor,
    epidemiologicalCore,
    flockList,
    displayPlan,
    setDisplayPlan,
    questionList = [],
    setQuestionList,
    selectedQuestions,
    setSelectedQuestions,
    displayDetails,
    setDisplayDetails,
    displayPlanEdit,
    setDisplayPlanEdit,
    history,
    displayOrientation,
    setDisplayOrientation,
    // selectedSchedule,
    displayDeleteModal,
    setDisplayDeleteModal,
    handleChangeExecuted,
    isRandom,
    setHasContent,
    flocks,
    logbookItemId,
    visitDate,
  } = props;

  const [questionsToSend, setQuestionsToSend] = useState([]);
  const [currentStep, setCurrentStep] = useState({
    name: t(
      'next.visitCircuit.visitCircuitListWrap.steps.circuits',
    ),
    key: 'circuits'
  });
  const [schedules, setSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [questionsSuccess, setQuestionsSuccess] = useState(false);
  const [planSuccess, setPlanSuccess] = useState(false);
  const [logbookItemDetails, setLogbookItemDetails] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [
    logbookItemsOrientations,
    setLogbookItemsOrientations,
  ] = useState();

  const has309 = questionList.find(question => question.id === 309);

  const checkListId =
    questionList &&
    questionList.length &&
    questionList[0].checkListId;

  useEffect(() => {

    if (isRandom || has309) {
      setCurrentStep(steps[1]);
    }
  }, [steps, isRandom, questionList]);

  useEffect(() => {
    async function getLogBookItemDetailsContent(paramsId) {
      setIsLoading(true);

      try {
        const response = await getLogBookItemDetails(paramsId);

        setIsLoading(false);
        setLogbookItemDetails(response);

        if (response.logBookItemStatus === 1) {
          setCurrentStep(steps[1]);
          getLogbookItemsOrientationsContent();
        }
      } catch (error) {
        console.error(error);
      }
    }

    getLogBookItemDetailsContent(params.id);
  }, [params.id]);

  const steps = useMemo(
    () => [
      {
        name: t(
          'next.visitCircuit.visitCircuitListWrap.steps.circuits',
        ),
        key: 'circuits',
        hidden: isRandom,
      },
      {
        name: t(
          'next.visitCircuit.visitCircuitListWrap.steps.orientations',
        ),
        key: 'orientations',
      },
    ],
    [isRandom],
  );

  useEffect(() => {
    setHasContent(Array.isArray(questionsToSend) && questionsToSend.length);
  }, [questionsToSend]);

  useEffect(() => {
    if (checkListId && vendor && vendor.vendorCode) {
      getSchedules();
    }
  }, [checkListId, getSchedules, vendor]);

  const saveActionPlans = useCallback(async () => {
    let plans = questionsToSend.filter(question =>
      Boolean(question.actionPlan),
    );

    // upload images
    plans = await Promise.all(
      plans.map(async plan => ({
        ...plan,
        actionPlan: {
          ...plan.actionPlan,
          attachmentsUrls: await Promise.all(
            plan.actionPlan.attachmentsUrls.map(file =>
              uploadImage(file),
            ),
          ),
        },
      })),
    );

    // upload action plan
    Promise.all(
      plans.map(async item => {
        const payload = {
          ...item.actionPlan,
          logBookChecklistId: item.logbookChecklistId,
          logbookChecklistScheduleId: item.logbookChecklistScheduleId,
          logbookChecklistQuestionId: item.logbookChecklistQuestionId,
          logbookChecklistAnswerId: item.logbookChecklistAnswerId,
          epidemiologicalCore: epidemiologicalCore,
        };

        saveLogBookChecklistsActionPlan(payload);
      }),
    )
      .then(() => {
        setQuestionsToSend(questionsToSend);
        saveQuiz(questionsToSend);
      })
      .catch(err => console.log(err.message));
  }, [epidemiologicalCore, questionsToSend, saveQuiz]);

  const saveQuiz = useCallback(async val => {
    setIsSubmitting(true);

    const normalizedBody = (val || []).map(item => ({
      ...item,
      logbookItemId,
      flocks
    }));

    try {
      const response = await saveLogBookChecklistsQuestion(normalizedBody);
      
      if (!response.success) {
        throw Error(response.message);
      }

      if (response.success) {
        await getLogbookItemsOrientationsContent();
        setCurrentStep(steps[1]);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
    setIsSubmitting(false);
  }, []);

  const onSelectAnswer = (questionId, answer) => {
    if (!questionId) {
      return;
    }

    setAnswers(state => {
      const currentAnswer = state.findIndex(
        answer => answer.questionId === questionId,
      );

      if (currentAnswer === -1) {
        return [...state, { questionId, answer }];
      }

      const previousState = [...state];
      previousState[currentAnswer].answer = answer;

      return previousState;
    });
  };

  const getSchedules = useCallback(async () => {
    try {
      const response = await getLogBookChecklistsSchedules(
        checkListId,
        vendor.vendorCode,
      );
      setSchedules(response || []);
    } catch (e) {
      console.error('Error on get logbook details', e);
    }
  }, [checkListId, vendor.vendorCode]);

  const displayQuestionList = () => {
    if (!(questionList || []).length) {
      return (
        <NoContentTab icon="icon-calendar-alt">
          <p>{t('global.no-content')}</p>
        </NoContentTab>
      );
    }

    return questionList.map((question, index) => {
      const filledAnswer = questionsToSend.find(
        qt => qt.logbookChecklistQuestionId === question.id,
      );

      if (!question.isEditable) {
        return (
          <CircuitQuestionWithAnswer
            {...question}
            {...filledAnswer}
            key={question.id}
            number={question.id}
            questionList={questionList}
            setDisplayDetails={setDisplayDetails}
            setDisplayPlanEdit={setDisplayPlanEdit}
            setQuestionsToSend={setQuestionsToSend}
            logBookChecklistSupervisorAnswers={
              question.logBookChecklistSupervisorAnswers
            }
            handleChangeExecuted={handleChangeExecuted}
            vendorCode={logbookItemDetails.vendorCode}
          />
        );
      }

      return (
        <CircuitQuestion
          {...filledAnswer}
          {...question}
          key={question.id}
          number={question.id}
          logbookItemDetails={logbookItemDetails}
          questionsToSend={questionsToSend}
          setQuestionsToSend={setQuestionsToSend}
          setQuestionList={setQuestionList}
          logBookChecklistSupervisorAnswers={
            question.logBookChecklistSupervisorAnswers
          }
          onSelectAnswer={onSelectAnswer}
          setDisplayPlanEdit={setDisplayPlanEdit}
          onPlanButtonClick={() => {
            setSelectedQuestions(question);
            setDisplayPlan(true);
          }}
          logbookChecklistScheduleId={
            question.logbookChecklistScheduleId
          }
          deleteModal={displayDeleteModal}
          setDeleteModal={setDisplayDeleteModal}
          user={user}
          vendorCode={logbookItemDetails.vendorCode}
          epidemiologicalCore={epidemiologicalCore}
        />
      );
    });
  };

  const getLogbookItemsOrientationsContent = async () => {
    try {
      const response = await getLogbookItemsOrientations(params.id);
      setLogbookItemsOrientations(response);
    } catch (error) {
      console.error(error);
    }
  };

  const displayScheduleList = useCallback(() => {
    return schedules.map((item, index) => (
      <Card
        {...(index + 1 === schedules.length && {
          margin: '0',
        })}
        key={item.id}
        reverse
        title={item.name}
        progress={item.fillingPercentage || '0'}
        progressColor={
          item.isFinished ? 'colorSuccess' : 'colorPrimary'
        }
        footerContent={
          item.isFinished
            ? t(
                'next.visitCircuit.visitCircuitListWrap.card.footer.incomplete',
              )
            : t(
                'next.visitCircuit.visitCircuitListWrap.card.footer.lastFillOn',
                {
                  value: moment(item.lastAnswer).format(
                    'DD ̸̸  MM ̸̸  YYYY',
                  ),
                },
              )
        }
        footerIcon={
          item.isFinished ? 'icon-check-circle' : 'icon-clock'
        }
        footerColor={item.isFinished ? 'colorSuccess' : 'colorInfo'}
        subtitle={
          item.endDate
            ? t(
                'next.visitCircuit.visitCircuitListWrap.card.subtitle.deadlineSpecified',
                {
                  value: moment(item.endDate).format(
                    'DD ̸̸  MM ̸̸  YYYY',
                  ),
                },
              )
            : t(
                'next.visitCircuit.visitCircuitListWrap.card.subtitle.deadlineNotSpecified',
              )
        }
      />
    ));
  }, [schedules]);

  if (isLoading) {
    return <LoadingHolder isLoading={isLoading} />;
  }

  return (
    <>
      {!displayPlan &&
        !displayPlanEdit &&
        !displayDetails &&
        !displayOrientation && (
          <>
            <div className="secondary">
              {flockList}

              {!isRandom && (
                <CardWrapper title="Circuito de visitas">
                  <CircuitDescription>
                    {t(
                      'next.visitCircuit.visitCircuitListWrap.circuiitDescription',
                    )}
                  </CircuitDescription>

                  {displayScheduleList()}

                  {schedules.length > 0 && (
                    <ButtonBack
                      onClick={() =>
                        history.push(
                          `/next-flock-list/${vendor.vendorId}/new`,
                        )
                      }
                    >
                      {t(
                        'next.visitCircuit.visitCircuitListWrap.button.seeVisits',
                      )}
                    </ButtonBack>
                  )}
                </CardWrapper>
              )}
            </div>

            <div className="primary">
              <Container
                hideDetail
                slim
                noStyle={
                  currentStep.key === 'orientations' &&
                  Boolean(logbookItemsOrientations && Array.isArray(logbookItemsOrientations) && logbookItemsOrientations.length)
                }
              >
                {logbookItemsOrientations && (
                  <HorizontalSteps
                    activeStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    steps={steps}
                    completedSteps={[]}
                    disableClick
                  />
                )}

                {currentStep.key === 'circuits' && (
                  <div className="visit-circuit-wrapper__questions">
                    {displayQuestionList()}

                    {(questionList || []).length > 0 && (
                      <BtnGroup>
                        <Button
                          key="save"
                          className="tertiary"
                          style={{ width: 220 }}
                          onClick={() => saveActionPlans()}
                          disabled={isSubmitting || Array.isArray(questionsToSend) && !questionsToSend.length}
                        >
                          {t('global.button.save-and-next')}
                        </Button>
                      </BtnGroup>
                    )}
                  </div>
                )}

                {(currentStep.key === 'orientations') && (
                  logbookItemsOrientations
                    ? (
                      <PostCircuit
                        vendor={vendor}
                        isRandom={isRandom}
                        visitDate={visitDate}
                        orientations={logbookItemsOrientations}
                        setDisplayOrientation={setDisplayOrientation}
                      />
                    ) : (
                      <OrientationEditCard
                        vendor={vendor}
                        setDisplayOrientation={setDisplayOrientation}
                        orientations={logbookItemsOrientations}
                      />
                    )
                )}
              </Container>
            </div>
          </>
        )}

      {displayPlan && (
        <Container
          hideDetail
          slim
          style={{ width: 652 }}
          className="animated fadeIn"
        >
          <ActionPlan
            user={user}
            setDisplayPlan={setDisplayPlan}
            setQuestionList={setQuestionList}
            selectedQuestions={selectedQuestions}
            setSelectedQuestions={setSelectedQuestions}
            questionList={questionList}
            selectedItem={selectedQuestions}
            questionsToSend={questionsToSend}
            setQuestionsToSend={setQuestionsToSend}
            vendorCode={logbookItemDetails.vendorCode}
          />
        </Container>
      )}

      {displayDetails && (
        <ActionPlanDetails
          selectedQuestions={selectedQuestions}
          details={displayDetails}
          setDisplayDetails={setDisplayDetails}
        />
      )}

      {displayPlanEdit && (
        <Container
          hideDetail
          slim
          style={{ width: 652 }}
          className="animated fadeIn"
        >
          <ActionPlanEdit
            user={user}
            vendorCode={logbookItemDetails.vendorCode}
            displayPlanEdit={displayPlanEdit}
            setDisplayPlanEdit={setDisplayPlanEdit}
            setQuestionList={setQuestionList}
            selectedQuestions={selectedQuestions}
            questionList={questionList}
            questionsToSend={questionsToSend}
            setQuestionsToSend={setQuestionsToSend}
          />
        </Container>
      )}

      {displayOrientation && (
        <OrientationVisualization
          logbookItemsOrientations={logbookItemsOrientations}
          visitDate={visitDate}
          user={user}
          id={id}
          history={history}
        />
      )}

      {/* On submit modal */}
      <SubmitModal
        success={questionsSuccess}
        setSuccess={setQuestionsSuccess}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        setSelectedQuestions={setSelectedQuestions}
      />
    </>
  );
}
