import styled from 'styled-components';
import {
  colorGrayDark,
  colorGrayLighter,
  colorClear,
  colorPrimary,
} from 'components/GlobalStyles/variables';

export const UploadContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;

  .hidden {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorGrayLighter};
  background-size: cover;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-bottom: 16px;

  span {
    font-size: 80px;
    color: ${colorClear};
  }
`;

export const FalseButton = styled.span`
  display: block;
  padding: 4px 24px;
  color: ${colorGrayDark};
  font-size: 22px;
  text-align: center;
`;

export const CropWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 320px;

  .rangeWrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    height: 42px;
       
  }

  .slider {    
    -webkit-appearance: none;
    appearance: none;
    width: 248px!important;

    &:hover {
      opacity: 1;
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }

    &::-webkit-slider-thumb {
      /* background: ${colorPrimary}; */
      cursor: pointer;
      border: none;
      box-shadow: none !important;
      width: 12px;
      height: 12px;
      margin-top: -5px;
    }

    &::-moz-range-thumb {
      /* background: ${colorPrimary}; */
      cursor: pointer;
      border: none;
      box-shadow: none !important;
      width: 12px;
      height: 12px;
      margin-top: -5px;
    }

    &::-ms-thumb {
      /* background: ${colorPrimary}; */
      cursor: pointer;
      border: none;
      box-shadow: none !important;
      width: 12px;
      height: 12px;
      margin-top: -5px;
    }

    &::-webkit-slider-runnable-track {
      height: 2px!important;
    }
    
  }

  .reactEasyCrop_CropAreaRound {
    width: 320px !important;
    height: 320px !important;
  }
`;

export const CropContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const Separator = styled.hr`
  margin: 10px 0 15px 0;
  opacity: 0.12;
`;
