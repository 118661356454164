import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import LoadingHolder from 'components/LoadingHolder';
import NotificationBox from 'components/NotificationBox';
import { colorInfo } from 'components/GlobalStyles/variables';
import qs from 'qs';
import t from 'components/i18n';
import { getLgpdTerm } from './service';

const Policy = styled.div`
  padding: 10px;
  ol {
    padding-left: 20px;
  }
`;

export default function PolicyTermsMobile({ location, user }) {
  const [isLoadingTerms, setIsLoadingTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [termData, setTermData] = useState('');

  const urlToken = location
    ? qs.parse(location.search)['?token']
    : null;

  const userToken = user && user.token;
  const token = userToken || urlToken;

  const getTermData = useCallback(async () => {
    setIsLoadingTerms(true);
    try {
      const response = await getLgpdTerm(token);

      setTermData(response.data);
    } catch (error) {
      setErrorMessage(error);
    }
    setIsLoadingTerms(false);
  }, [token]);

  useEffect(() => {
    getTermData();
  }, [getTermData]);

  return (
    <Policy>
      <LoadingHolder isLoading={isLoadingTerms} />

      {!!errorMessage && <p>{errorMessage}</p>}

      {!isLoadingTerms && termData && (
        <>
          {termData.acceptedDate && (
            <NotificationBox
              className="notification notification--info"
              type="warning"
              bgColor={colorInfo}
              style={{ marginTop: -10 }}
              slim
            >
              {t(
                'policy.policyTermsMobile.acceptedOn',
                {
                  date: moment(termData.acceptedDate).format('DD ̸̸  MM ̸̸  YYYY'),
                  hour: moment(termData.acceptedDate).format('HH:mm')
                }
              )}
            </NotificationBox>
          )}
          <div dangerouslySetInnerHTML={{ __html: termData.term }} />
        </>
      )}
    </Policy>
  );
}
