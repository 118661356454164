import React, { useState, useEffect } from 'react';
import Card from 'components/Card';
import Icon from 'components/Icon';
import DateAndTime from 'components/DateAndTime';
import t from 'components/i18n';
import { Button } from '@zendeskgarden/react-buttons';
import {
  PlanOptions,
  DefaultButton,
  BottomContentTime,
  Date,
  BottomContentDone,
  DateDone,
} from './CircuitQuestion.styled';

export default function PlanCard(props) {
  const {
    date,
    description,
    hasAttachment,
    displayOptions = true,
    wasExecuted,
    logBookChecklistAnswerId,
    actionPlanId,
    vendorCode,
    setDisplayDetails,
    setDisplayPlanEdit,
    setDeleteModal,
    handleChangeExecuted,
  } = props;

  const [executed, setExecuted] = useState(null);

  useEffect(() => {
    if (typeof executed === 'boolean') {
      handleChangeExecuted(actionPlanId, {
        vendor: vendorCode,
        wasExecuted: executed,
      });
    }
  }, [
    actionPlanId,
    executed,
    handleChangeExecuted,
    logBookChecklistAnswerId,
    vendorCode,
  ]);

  return (
    <>
      <p>{t('circuitQuestion.actionPlan.title')}</p>
      <Card className="plan-card">
        <DateAndTime>{date}</DateAndTime>

        {hasAttachment && <span className="icon icon-clip" />}

        <p className="description">{description}</p>

        {displayOptions && (
          <PlanOptions>
            <DefaultButton
              className="whitest"
              onClick={() => setDeleteModal()}
            >
              {t('global.button.delete')}
            </DefaultButton>

            <DefaultButton onClick={() => setDisplayPlanEdit()}>
              {t("global.button.edit")}
            </DefaultButton>
          </PlanOptions>
        )}

        {!displayOptions && (
          <>
            <Button
              key="details"
              className="tertiary"
              style={{ width: 260, margin: '20px 0 4px 0' }}
              onClick={() => setDisplayDetails()}
            >
              {t("global.button.details")}
            </Button>

            {!wasExecuted && (
              <div>
                <p>{t('circuitQuestion.actionPlan.wasExecuted.title')}</p>

                <DefaultButton
                  className={`${Boolean(executed) && 'active'} green`}
                  onClick={() => setExecuted(true)}
                >
                  <Icon name="icon-check" size={20} />
                  {t('circuitQuestion.actionPlan.wasExecuted.yes')}
                </DefaultButton>

                <DefaultButton
                  className={`${typeof executed === 'boolean' &&
                    !executed &&
                    'active'} red`}
                  onClick={() => setExecuted(false)}
                >
                  <Icon name="icon-close" size={20} />
                  {t('circuitQuestion.actionPlan.wasExecuted.no')}
                </DefaultButton>
              </div>
            )}
          </>
        )}

        {date && !wasExecuted ? (
          <BottomContentTime>
            <span className="icon icon-clock" />
            <Date>{t('circuitQuestion.actionPlan.deadline', { value: date })}</Date>
          </BottomContentTime>
        ) : (
          <BottomContentDone>
            <span className="icon icon-check" />
            <DateDone>{t('circuitQuestion.actionPlan.executed')}</DateDone>
          </BottomContentDone>
        )}
      </Card>
    </>
  );
}
