import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Input,
  Message,
  Label,
  Field,
} from '@zendeskgarden/react-forms';
import { DateRange } from 'react-date-range';
import { pt } from 'date-fns/esm/locale';
import {
  FilterStyled,
  DateRangeWrapper,
} from 'components/Filter/Filter.styled';
import {
  getValidationMessage,
  getValidation,
} from 'shared/utils/Helpers';

export default function DateRangeDropdownField({
  onChange,
  label,
  field,
  placeholder,
  values,
  errors,
  touched,
}) {
  const startRange = {
    startDate: moment(),
    endDate: null,
    key: 'date',
  };
  const [selectionRange, setSelectionRange] = useState(startRange);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [inputValue, setInputValue] = useState('');

  function formatDate(val) {
    return moment(val).format('DD MMM');
  }

  /**
   * 
   * @param {{startDate: moment.Moment, endDate: moment.Moment | null }} sel 
   */
  function getValue(sel) {
    const { startDate, endDate } = sel;

    if (endDate !== null && !startDate.isSame(endDate, 'day')) {
      setOpenCalendar(false);
      onChange(sel);
      setInputValue(
        `${formatDate(startDate)} - ${formatDate(endDate)}`,
      );
    }
  }

  useEffect(() => {
    getValue(selectionRange);
  }, [getValue, selectionRange]);

  useEffect(() => {
    if (values[field]) {
      getValue(values[field]);
    }
  }, [field, getValue, values]);

  return (
    <FilterStyled style={{ width: '100%' }}>
      <Label>{label}</Label>
      <Input
        onClick={e => {
          e.preventDefault();
          setOpenCalendar(!openCalendar);
        }}
        placeholder={placeholder}
        value={inputValue}
      />

      {openCalendar && (
        <DateRangeWrapper>
          <DateRange
            ranges={[selectionRange]}
            moveRangeOnFirstSelection={false}
            onChange={e => {
              setSelectionRange(e.date);
            }}
            dateDisplayFormat="DD ̸̸  MM ̸̸  YYYY"
            locale={pt}
            showDateDisplay={false}
          />
        </DateRangeWrapper>
      )}

      <Message
        validation={getValidation(
          values[field],
          errors,
          field,
          !touched[field],
        )}
      >
        {getValidationMessage(field, errors, !touched[field])}
      </Message>
    </FilterStyled>
  );
}
