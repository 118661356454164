import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  DEFAULT_SIZE,
  boxShadow,
  breakpointTablet,
  breakpointDesktop,
  fontFamilyText,
  fontFamilyTextBold,
  colorClear,
  colorGrayUltraLight,
  colorGrayLighter,
  colorGray,
  colorGrayDarkest,
} from 'components/GlobalStyles/variables';

export const LibraryStyled = styled.div`
  display: flex;
  padding: ${DEFAULT_SIZE * 2}px 20px;
  flex-wrap: wrap;

  .secondary-wrapper {
    min-width: 280px;
    margin: 0 20px 20px 0;

    @media (min-width: ${breakpointDesktop}px) {
      min-width: 316px;
    }
  }

  .container {
    max-width: 652px;
    width: 100%;
    overflow: hidden;
  }

  h4 {
    color: ${colorGrayDarkest};
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;
  margin: ${props =>
    props.gap && `${props.gap}px -${DEFAULT_SIZE * 2}px`};
  width: ${props => props.full && `calc(100% + 32px)`};
`;

export const Subheading = styled.strong`
  color: ${colorGrayDarkest};
  line-height: 24px;
  font-size: ${props => (props.small ? `14` : `16`)}px;
`;

export const Container = styled.div`
  position: relative;
  padding: 0 ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};
  max-width: 630px;

  @media (min-width: ${breakpointTablet}px) {
    margin: 0 20px 60px 20px;
  }

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }

  ${Subheading} {
    display: block;
    font-family: ${fontFamilyTextBold};
    font-weight: normal;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }

  .library-menu {
    min-width: 100%;

    @media (min-width: ${breakpointTablet}px) {
      width: auto;
      min-width: 315px;
    }

    @media (min-width: ${breakpointDesktop}px) {
      width: 100%;
      margin-right: ${DEFAULT_SIZE * 2}px;
      max-width: 376px;
    }
  }

  &.column {
    flex-direction: column;

    @media (min-width: ${breakpointDesktop}px) {
      flex-direction: row;
    }
  }

  @media (min-width: ${breakpointTablet}px) {
    flex-direction: column;
  }

  @media (min-width: ${breakpointDesktop}px) {
    flex-direction: row;
  }
`;

export const Menu = styled.div`
  background-color: ${colorClear};
  padding: 0 ${DEFAULT_SIZE * 2}px;
  padding-top: ${props => (props.hasChildren ? '30px' : '0')};
  margin-top: ${props => (props.hasChildren ? '-30px' : '0')};
  border-radius: ${DEFAULT_SIZE}px;
  overflow: hidden;
  box-shadow: ${boxShadow};
  width: 100%;
  margin-bottom: 15px;

  .menu-icon {
    font-size: 24px;
    color: ${colorGrayLighter};
    align-items: center;
    display: flex;
    margin-right: 24px;

    &:after {
      content: '';
      transition: 0.2s ease-in background-color;
      background-color: transparent;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      left: -8px;
      z-index: 0;
    }
  }

  .list-item-heading {
    color: ${colorGrayDarkest};
  }

  .menu-heading {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }

  .list-item-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .active {
    .menu-icon,
    .chevron,
    .list-item-heading {
      color: ${colorGrayDarkest} !important;
    }

    .menu-icon {
      :before {
        z-index: 1;
      }
      :after {
        background-color: ${colorGrayUltraLight};
      }
    }
  }

  .menu-group {
    margin-bottom: ${DEFAULT_SIZE * 2}px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 56px;
    width: 100%;
    cursor: pointer;

    .arrow {
      font-size: 24px;
    }

    &:after {
      content: '';
      display: block;
      background-color: ${colorGrayDarkest};
      opacity: 0.12;
      width: 100%;
      max-width: 250px;
      height: 1px;
      position: absolute;
      left: 50px;
      bottom: 0;
    }

    &:last-child {
      &:after {
        height: 0;
      }
    }
  }

  @media (min-width: ${breakpointTablet}px) {
    width: auto;
  }

  @media (min-width: ${breakpointDesktop}px) {
    width: 100%;
    max-width: 376px;
  }
`;

export const Item = styled.div`
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};
  margin: 0 0 16px;
  padding: 0 16px;
  color: ${colorGrayDarkest};

  p {
    margin: 0 0 16px;
    font-size: 12px;
    line-height: 16px;
  }

  span {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const Details = styled.section`
  ${Subheading} {
    padding: 0 16px;
    margin-bottom: ${DEFAULT_SIZE * 3}px;
  }
`;
