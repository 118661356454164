import styled from "styled-components";

import { Item, Menu } from "@zendeskgarden/react-dropdowns";

export const StyledButton = styled.button`
  background: transparent;
  border: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
`;

export const StyledFlag = styled.img.attrs(({ flag }) => ({
  src: flag,
}))`
  border: 1px solid #fff;
  border-radius: 100%;
  min-width: 26px;
`;

export const StyledChevronDown = styled.span.attrs({
  className: 'icon icon-chevron-down',
})`
  font-size: 24px;
`;

export const StyledMenu = styled(Menu)`
  &&& {
    background: #fff;
    border: 1px solid #cfd2d8;
    box-shadow: 0 0 16px rgba(37, 48, 71, 0.12);
    border-radius: 8px;
    display: block;
  }
`;

export const StyledItem = styled(Item)`
  display: flex !important;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;
  gap: 16px;

  padding: 18px 12px;
  font-family: 'SF Pro Display', sf_probold, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;

  background: transparent;
  &:hover {
    background: #cfd2d8;
  }

  transition: background 300ms;

  &&& {
    margin: 0;
    font-weight: ${({ $active }) => $active ? 700 : 400};
  }
`;

