import React from 'react';
import moment from 'moment';
import DateAndTime from 'components/DateAndTime';
import IconPlaceholder from 'components/IconPlaceholder';
import RoundContainer from 'components/RoundContainer';
import BadgePill from 'components/BadgePill';
import { Item } from '../VendorSchedule.styled';
import { statusMapper } from 'utils/mapper';
import t from 'components/i18n';

export default function NextFeedItem({
  date,
  icon,
  iconColor,
  status,
  statusColor,
  firstItem,
  secondItem,
  thirdItem,
}) {
  return (
    <Item className="animated fadeIn">
      <RoundContainer noMargin padding="12px 16px 16px">
        <div className="flex">
          <IconPlaceholder bgColor="colorClear" className="white">
            <span className={`icon ${icon} ${iconColor}`} />
          </IconPlaceholder>
          <div style={{ width: '100%' }}>
            <DateAndTime>
              {moment(date).format('DD ̸̸  MM ̸̸  YYYY')}
            </DateAndTime>
            <p>
              <strong>{firstItem}</strong>
            </p>
            <p className="normal">{secondItem}</p>
            <p className="small">{thirdItem}</p>
          </div>
          <BadgePill bgColor={statusColor}>{t(statusMapper(status))}</BadgePill>
        </div>
      </RoundContainer>
    </Item>
  );
}
