import React from 'react';
import t from 'components/i18n';

import { Button } from '@zendeskgarden/react-buttons';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Formik } from 'formik';
import { Container, Separator } from './Event.styled';
import { AutoCompleteDropdownField, DropdownField } from 'components/Forms/Fields';

import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';

export default function EventForm(props) {
  const { onSubmit, eventOptions, history } = props;

  return (
    <>
      <Breadcrumb
        history={history}
        path={[t('bc.logbook'), t('bc.create-new-event')]}
      />
      <Top>
        <h2>{t('create-new-event.title')}</h2>
      </Top>
      <Container hideDetail>
        <Formik
          initialValues={{}}
          onSubmit={values => onSubmit(values.eventType.value)}
          render={
          ({
            values,
            errors,
            touched,
            setFieldValue,
            resetForm,
            handleSubmit,
          }) => (
            <>
              <DropdownField
                field="eventType"
                label={t('create-new-event.label.event-type')}
                placeholder={t(
                  'create-new-event.placeholder.event-type',
                )}
                onSelect={event => setFieldValue('eventType', event)}
                options={eventOptions}
                optionLabel="label"
                values={values}
                errors={errors}
                touched={touched}
              />
              <div>
                <Separator />
                <BtnGroup className="event__btn-group">
                  <Button
                    key="submitForm"
                    className="tertiary"
                    onClick={e => handleSubmit(e)}
                    disabled={!values.eventType}
                  >
                    {t('global.button.send')}
                  </Button>
                  <Button
                    key="clean"
                    className="white"
                    onClick={() => resetForm()}
                    disabled={!values.eventType}
                  >
                    {t('global.button.clear')}
                  </Button>
                </BtnGroup>
              </div>
            </>
          )}
        />
      </Container>
    </>
  );
}
