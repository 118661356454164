import React from 'react';
import { Separator } from './NextRecord.styled';

export default function RecordTop(props) {
  const { textContent } = props;

  return (
    <>
      <div className="flock-details-top">
        {textContent &&
          textContent.length > 0 &&
          textContent.map((item, index) => (
            <div className="flock-details-top-item" key={index}>
              <span className="flock-details-top-heading">
                {item.label}
              </span>
              <span className="flock-details-top-label">
                {item.content}
              </span>
            </div>
          ))}
      </div>

      <Separator />
    </>
  );
}
