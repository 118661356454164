import { API_SUPERVISOR_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getVendors() {
  const response = await request(
    `${API_SUPERVISOR_URL}/Producer/GetProducerList`,
    {
      method: 'GET',
    },
  );
  return response;
}
