// @flow
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { HashRouter, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import history from 'shared/history';
import theme from 'components/GlobalStyles/theme';
import themeNext from 'components/GlobalStyles/theme-next';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import * as config from 'config/constants';
import * as routes from 'config/routes';
import Transition from 'components/Transition';
import { withTranslation } from 'react-i18next';

import {
  WeighingContextProvider,
  CadecContextProvider,
  AtaContextProvider,
  MemberContextProvider,
  AnnouncementsContextProvider,
  SwineContextProvider,
} from 'shared/contexts';

import SystemAlerts from 'containers/SystemAlerts';
import Modal from 'components/Modal';
import GlobalStyles from 'components/GlobalStyles';

import DeactivatedUser from 'containers/AgroBRF/DeactivatedUser';

import RouteList from './RouteList';

import { AppWrapper, Main } from './App.styled';
import type { TAppProps } from './typings';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'config/authConfig';

// Contexts

const msalInstance = new PublicClientApplication(msalConfig);

class App extends React.Component<TAppProps> {
  render() {
    const { user } = this.props;
    const { isNextUser } = user;

    return (
      <MsalProvider instance={msalInstance}>
        <HashRouter history={history}>
          <ThemeProvider theme={user.isNextUser ? themeNext : theme}>
            <GlobalStyles className="global">
              <AppWrapper logged={user.isAuthenticated}>
                <Helmet
                  defer={false}
                  htmlAttributes={{ lang: 'pt-br' }}
                  encodeSpecialCharacters
                  defaultTitle={config.title}
                  titleTemplate={`%s | ${isNextUser ? `Next BRF` : `Agro BRF`
                    }`}
                  titleAttributes={{
                    itemprop: 'name',
                    lang: 'pt-br',
                  }}
                />
                {/* {user.isAuthenticated && (
                <Header dispatch={dispatch} user={user} />
              )} */}
                <MemberContextProvider>
                  <CadecContextProvider>
                    <AtaContextProvider>
                      <WeighingContextProvider>
                        <SwineContextProvider>
                          <AnnouncementsContextProvider>
                            <Transition transition="fade">
                              {user.isDeleted ? (
                                <DeactivatedUser
                                  user={user}
                                  history={history}
                                />
                              ) : (
                                <Main
                                  isAuthenticated={user.isAuthenticated}
                                >
                                  <Switch>
                                    <RouteList
                                      user={user}
                                      routes={routes}
                                    />
                                  </Switch>
                                </Main>
                              )}
                            </Transition>
                            {/* <Footer /> */}
                            <SystemAlerts />
                            <Modal />
                          </AnnouncementsContextProvider>
                        </SwineContextProvider>
                      </WeighingContextProvider>
                    </AtaContextProvider>
                  </CadecContextProvider>
                </MemberContextProvider>
              </AppWrapper>
            </GlobalStyles>
          </ThemeProvider>
        </HashRouter>
      </MsalProvider>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default hot(connect(mapStateToProps)(withTranslation()(App)));
