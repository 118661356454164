import React from 'react';
import { TopStyled } from './Top.styled';

export default function Top(props) {
  const { top = false, left = false, padding } = props;
  return (
    <TopStyled top={top} left={left} padding={padding} {...props}>
      {props.children}
    </TopStyled>
  );
}
