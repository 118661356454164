import React from 'react';
import NoContentTab from 'components/NoContentTab';
import Container from 'components/Container';
import LoadingHolder from 'components/LoadingHolder';
import { colorTertiary } from 'components/GlobalStyles/variables';
import MedicationListItem from './MedicationListItem';
import t from 'components/i18n';
import {
  Separator,
  NextMedicationStyled,
} from './NextMedication.styled';

export default function MedicationList({
  isLoading,
  list,
  setEditMedication,
  setSelectedMedicationId,
  sendToConfirmation,
  setSendToConfirmation,
  setDisplayExcludeModal,
  isFlockClosed,
}) {
  return (
    <NextMedicationStyled>
      <Container className="container animated" slim>
        <h4>{t("next.medication.medicationList.resume.title")}</h4>

        <Separator style={{ marginBottom: 16 }} />

        <LoadingHolder isLoading={isLoading} />

        {!isLoading &&
          list.length > 0 &&
          list.map(item => (
            <MedicationListItem
              key={item.id}
              feed={item}
              setEditMedication={setEditMedication}
              setSelectedMedicationId={setSelectedMedicationId}
              sendToConfirmation={sendToConfirmation}
              setSendToConfirmation={setSendToConfirmation}
              setDisplayExcludeModal={setDisplayExcludeModal}
            />
          ))}

        {!isLoading && !list.length && (
          <NoContentTab
            icon="icon-medkit"
            onButtonClick={() =>
              isFlockClosed ? null : setEditMedication(true)
            }
            buttonLabel={
              isFlockClosed ? null : t('next.medication.medicationList.resume.no-content.button.add')
            }
            buttonColor={colorTertiary}
          >
            <p>
              {t('next.medication.medicationList.resume.no-content.message')}
            </p>
          </NoContentTab>
        )}
      </Container>
    </NextMedicationStyled>
  );
}
