import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  colorPrimary,
  colorClear,
  breakpointTablet,
  breakpointDesktop,
} from 'components/GlobalStyles/variables';

export const Welcome = styled.div`
  color: ${colorClear};

  .text {
    max-width: 300px;
    margin-bottom: 96px;
    z-index: 1;
  }

  h1 {
    font-size: ${DEFAULT_SIZE * 4.3}px;
    text-transform: uppercase;
    line-height: 40px;
    margin: 30px 0 24px;
  }

  h2 {
    font-size: ${DEFAULT_SIZE * 3}px;
    line-height: ${DEFAULT_SIZE * 4}px;
  }

  h3 {
    font-size: ${DEFAULT_SIZE * 2.3}px;
    line-height: 22px;
  }

  hr {
    border: none;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 50px;
      height: 3px;
      background-color: ${colorPrimary};
    }
  }

  section {
    position: relative;
    margin-bottom: ${DEFAULT_SIZE * 5}px;

    button {
      background-color: transparent;
      color: ${colorClear};
      border-radius: 8px;
      margin-top: 40px;
      width: 180px;
      height: 40px !important;
      line-height: 40px;
      border: 1px solid ${colorClear}!important;
      cursor: pointer;
      transition: .2s background-color ease-in;

      &:hover {
        color: ${colorPrimary};
        background-color:${colorClear};
      }
    }
  }

  h1,
  h2,
  h3,
  .logo {
    color ${colorClear} !important;
    position: relative;
    transition: all 0.5s ease-in-out;
    top: -5px;
    opacity: 0; 

    &.expose {
      opacity: 1;
      top: 0;
    }
  }

  // .logo {
  //   display: none;

  //   @media (min-width: ${breakpointDesktop}px) {
  //     display: block;
  //   }
  // }

  // display: none;

  @media (min-width: ${breakpointTablet}px) {
    display: inherit;
    margin-top: 88px;
  }

  @media (min-width: ${breakpointTablet}px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;
