// @flow

import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@zendeskgarden/react-buttons';
import MaskedInput from 'components/MaskedInput';
import FloatingLabel from 'components/FloatingLabel';
import { cleanUp } from 'components/MaskedInput/mask';
import t from 'components/i18n';
import { Dots } from '@zendeskgarden/react-loaders';
import { Field, Message, Label } from '@zendeskgarden/react-forms';
import {
  getValidationMessage,
  getValidation,
  getTextfieldClass,
} from 'shared/utils/Helpers';
import * as routes from 'config/routes';

import {
  Heading,
  Subheading,
  Link,
  TitleBlock,
  LinkHolder,
} from 'components/SideBox/SideBox.styled';
import Spacer from 'components/Spacer';
import type { TSignupProps } from './typings';

const schema = yup.object().shape({
  documentNumber: yup
    .string()
    .min(11, t('global.validation.min-character-length', { length: 11 }))
    .max(18, t('global.validation.max-character-length', { length: 18 }))
    .required(t('global.status.required-field'))
    .typeError(t('global.validation.must-specify-value')),
  phone: yup
    .string()
    .required(t('global.status.required-field'))
    // .min(16, t('global.validation.min-character-length', { length: 16 })),
    .min(14, t('global.validation.min-character-length', { length: 14 })),
});

const INITIAL_VALUE = {
  documentNumber: '',
  phone: '',
};

function SignUp(props: TSignupProps) {
  const { isLoading } = props;

  props.accessor.nextStep = props.nextStep;

  function submitForm(values, actions) {
    const documentNumber = cleanUp(values.documentNumber);
    const payload = {
      phone: cleanUp(values.phone),
      documentNumber,
    };

    props.userSignUp(payload);
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={INITIAL_VALUE}
      handleSubmit
      onSubmit={submitForm}
      render={({
        values,
        handleSubmit,
        setFieldValue,
        errors,
        handleChange,
        isValid,
        handleBlur,
        setFieldTouched,
        touched,
      }) => {
        function onChange(e) {
          handleChange(e);
        }

        function onBlur(e) {
          setFieldTouched(e.currentTarget.name);
          handleBlur(e);
        }

        const buttonProps = {
          stretched: true,
          primary: true,
          type: 'submit',
          disabled: !isValid || isLoading,
        };

        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <TitleBlock>
              <Heading>{t('signup.title')}</Heading>
              <Subheading>{t('signup.subtitle')}</Subheading>
            </TitleBlock>

            <Spacer multiplier={5} />

            <Field
              className={getTextfieldClass(
                getValidation(
                  values.documentNumber,
                  errors,
                  'documentNumber',
                  !touched.documentNumber,
                ),
              )}
            >
              <FloatingLabel>
                <Label>{t('signup.inputOne')}</Label>
                <MaskedInput
                  name="documentNumber"
                  type="CPF/CNPJ"
                  validation={getValidation(
                    values.documentNumber,
                    errors,
                    'documentNumber',
                    !touched.documentNumber,
                  )}
                  value={values.documentNumber}
                  onBlur={onBlur}
                  onChange={onChange}
                  setState={value => {
                    setFieldValue('documentNumber', value);
                  }}
                  disabled={isLoading}
                />
                <Message
                  validation={getValidation(
                    values.documentNumber,
                    errors,
                    'documentNumber',
                    !touched.documentNumber,
                  )}
                >
                  {getValidationMessage(
                    'documentNumber',
                    errors,
                    !touched.documentNumber,
                  )}
                </Message>
              </FloatingLabel>
            </Field>

            <Field
              mediaLayout
              className={getTextfieldClass(
                getValidation(
                  values.phone,
                  errors,
                  'phone',
                  !touched.phone,
                ),
              )}
            >
              <FloatingLabel>
                <Label>{t('signup.inputTwo')}</Label>
                <MaskedInput
                  name="phone"
                  type="phone"
                  onBlur={onBlur}
                  onChange={onChange}
                  validation={getValidation(
                    values.phone,
                    errors,
                    'phone',
                    !touched.phone,
                  )}
                  value={values.phone}
                  setState={value => {
                    setFieldValue('phone', value);
                  }}
                />
                <Message
                  validation={getValidation(
                    values.phone,
                    errors,
                    'phone',
                    !touched.phone,
                  )}
                >
                  {getValidationMessage(
                    'phone',
                    errors,
                    !touched.phone,
                  )}
                </Message>
              </FloatingLabel>
            </Field>

            {!isLoading && (
              <Button {...buttonProps}>
                {t('signup.buttonPrimary')}
              </Button>
            )}
            {isLoading && (
              <Button {...buttonProps}>
                <Dots />
              </Button>
            )}

            <Spacer />

            <LinkHolder center>
              <Link to={routes.SIGNIN} primary>
                {t('welcome.button.signin.text')}
              </Link>
            </LinkHolder>
          </form>
        );
      }}
    />
  );
}

export default SignUp;
