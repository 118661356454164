import React from 'react';
import moment from 'moment';
import Overview from 'containers/NextBRF/NextDashboard/Overview';
import DashboardPreview from 'containers/NextBRF/NextDashboard/DashboardPreview';
import SchedulingFeed from 'containers/AgroBRF/AgroDashboard/SchedulingFeed';
import ScheduleEvents from 'containers/NextBRF/NextDashboard/ScheduleEvents';
import Helmet from 'react-helmet';
import StayTuned from 'containers/AgroBRF/AgroDashboard/StayTuned';
import { greetingMessage } from 'shared/utils/Helpers';
import { Box, DashboardContainer, Separator } from './Dashboard.styled';
import Announcements from 'containers/AgroBRF/Announcements';
import t from 'components/i18n';
import InvoiceDashboardPreview from 'components/Invoice/DashboardPreview';

function Dashboard(props) {
  const {
    responsive,
    user = {},
    history,
    helmet,
    displayOverview = false,
    displayPreview = false,
  } = props;

  const isTurkish = user.countryCodes.includes('TR');

  return (
    <DashboardContainer
      className="dashboard-container"
      isNextUser={user.isNextUser}
    >
      <Helmet>
        <title>{t('dashboard.latest-news')}</title>
      </Helmet>

      <div className="dashboard-top">
        <div className="dashboard-header">
          <div className="welcome-label">{greetingMessage()}</div>
          <h2 className="provider">{user.name}</h2>
        </div>
      </div>

      {displayOverview && (
        <Overview
          title={t('dashboard.overview', { value: moment().format('MMMM YYYY') })}
          user={user}
        />
      )}

      {isTurkish && (
        <InvoiceDashboardPreview history={history} />
      )}

      {displayPreview && !isTurkish && (
        <DashboardPreview
          history={history}
          title={t('dashboard.preview')}
        />
      )}

      <div className="dashboard-content">
        {user.type !== 'F' && user.animalType !== 'swine' && (
          <div className="dashboard-secondary dashboard-item">
            <>
              {isTurkish
                ?
                  <SchedulingFeed
                    responsive={responsive}
                    history={history}
                    className="dashboard-item__main"
                  />
                :
                  <div className="dashboard-main dashboard-item dashboard-item__main news">
                    <StayTuned
                      history={history}
                      fullWidth={user.animalType === 'swine'}
                    />
                  </div>
              }
              {isTurkish
                ?
                  <Announcements
                    hideListFilters
                    hideHeader
                    hideListPagination
                    className="dashboard-item__secondary"
                    announcementListCustomHeader={
                      <Box slim>
                        <header className="header">
                          <h2>{t('menu.announcements.title')}</h2>
                        </header>
                        <Separator />
                      </Box>
                    }
                    {...props}
                  />
                :
                  user.isNextUser
                    ?
                      <ScheduleEvents
                        responsive={responsive}
                        history={history}
                        className="dashboard-item__secondary"
                      />
                    :
                      <SchedulingFeed
                        responsive={responsive}
                        history={history}
                        isCompact
                        className="dashboard-item__secondary"
                      />
              }
            </>
          </div>
        )}
      </div>
    </DashboardContainer>
  );
}

export default Dashboard;
