import React, { useEffect, useState } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import ContactsImg from './assets/grains_2.png';
import {
  GrayWrapper,
  Subheading,
  ContactList,
  Separator,
} from './Grains.styled';

// service
import { getContacts } from './service';
import t from 'components/i18n';

export default function Contacts({ setStage }) {
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    async function getContactLists() {
      try {
        const response = await getContacts();

        setContactList(response);
      } catch (error) {
        console.error(error);
      }
    }

    getContactLists();
  }, []);

  return (
    <>
      <GrayWrapper>
        <Row>
          <Col md={8}>
            <Subheading>{t('menu.beAnAPartner.send.new.title')}</Subheading>
            <p>
              {t('menu.beAnAPartner.send.new.description')}
            </p>

            <br />

            <Button primary onClick={() => setStage(2)}>
              {t('menu.beAnAPartner.send.new.buttonPrimary')}
            </Button>
          </Col>
          <Col md={4}>
            <img src={ContactsImg} alt={t('menu.beAnAPartner.title')} />
          </Col>
        </Row>
      </GrayWrapper>

      <p>
        {t('menu.beAnAPartner.send.new.support')}
      </p>

      <br />

      {contactList && contactList.length > 0 && (
        <ContactList>
          {contactList.map(contact => (
            <li>
              <p className="state">{contact.state}</p>
              <p className="name">{contact.name}</p>
              <p className="phone">{contact.phone}</p>
              <Separator />
            </li>
          ))}
        </ContactList>
      )}
    </>
  );
}
