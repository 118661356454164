import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import moment from 'moment';

import { Button } from '@zendeskgarden/react-buttons';
import { Field, Label, Input } from '@zendeskgarden/react-forms';
import {
  Menu,
  Item,
  Field as DropdownField,
} from '@zendeskgarden/react-dropdowns';

import Icon from 'components/Icon';
import Modal from 'components/Forms/FormModal/Modal';
import CircleAndTitle from 'components/CircleAndTitle';
import {
  SelectBox,
  DropdownBox,
  FieldSet,
} from 'components/Forms/Forms.styled';

import t from 'components/i18n';
import { questionTitleMapper } from 'utils/mapper';
import BadgeIcon from 'components/BadgeIcon';
import PlanCard from './PlanCard';

import {
  QuestionWrapper,
  Options,
  Question,
  DefaultButton,
} from './CircuitQuestion.styled';

export default function CircuitQuestion({
  id,
  number,
  title,
  label,
  logbookChecklistScheduleId,
  logBookChecklistId,
  questionType,
  onPlanButtonClick,
  answer: propsAnswer,
  onSelectAnswer,
  logBookChecklistAnswers,
  logBookChecklistSupervisorAnswers,
  questionsToSend,
  setQuestionsToSend,
  setQuestionList,
  hasActionPlan,
  handleChangeExecuted,
  setDisplayPlanEdit,
  vendorCode,
  epidemiologicalCore = null,
  update,
  logbookItemDetails,
}) {
  const [answer, setAnswer] = useState(null);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [inputText, setInputText] = useState(null);

  const givenAnswer =
    logBookChecklistSupervisorAnswers &&
    logBookChecklistSupervisorAnswers.length > 0 &&
    (logBookChecklistSupervisorAnswers.find(
      answer =>
        String(answer.vendor).padStart(10, '0') ===
        String(vendorCode).padStart(10, '0'),
    ) ||
      logBookChecklistSupervisorAnswers.find(
        answer =>
          String(answer.logBookChecklistActionPlan.vendor).padStart(
            10,
            '0',
          ) === String(vendorCode).padStart(10, '0'),
      ));

  useEffect(() => {
    const givenAnswer =
      logBookChecklistSupervisorAnswers &&
      logBookChecklistSupervisorAnswers.length > 0 &&
      logBookChecklistSupervisorAnswers.find(
        answer =>
          String(answer.vendor).padStart(10, '0') ===
          String(vendorCode).padStart(10, '0'),
      );

    setAnswer(givenAnswer || null);
  }, [logBookChecklistSupervisorAnswers]);

  const isDefault = hasActionPlan === true;
  const customButtons = questionType === 'Radio' && !hasActionPlan;
  const textInput = questionType === 'Text';
  const selectOptions = questionType === 'Dropdown';

  const plan =
    !selectOptions &&
    givenAnswer &&
    givenAnswer.logBookChecklistActionPlan;

  useEffect(() => {
    if (answer) {
      setQuestionsToSend(oldList => {
        const newItem = {
          logbookChecklistId:
            logBookChecklistId ||
            (logbookItemDetails && logbookItemDetails.checklistId),
          logbookChecklistScheduleId: logbookChecklistScheduleId,
          logbookChecklistQuestionId: id,
          logbookChecklistAnswerId: answer.id,
          vendor: vendorCode,
          answer: textInput || isDefault ? answer.title : null,
          // flock: null,
          epidemiologicalCore: epidemiologicalCore,
          actionPlan: plan,
        };

        const found = oldList.find(
          item => item.logbookChecklistQuestionId === id,
        );

        if (found) {
          return oldList.map(item => {
            if (item.logbookChecklistQuestionId === id) {
              return newItem;
            }

            return item;
          });
        }

        return [...oldList, newItem];
      });
    }
  }, [
    answer,
    epidemiologicalCore,
    id,
    isDefault,
    logBookChecklistId,
    logbookChecklistScheduleId,
    logbookItemDetails,
    plan,
    setDisplayPlanEdit,
    setQuestionsToSend,
    textInput,
    vendorCode,
  ]);

  useEffect(() => {
    const found = questionsToSend.find(
      item => item.logbookChecklistQuestionId === id,
    );

    if (found && !answer && update) {
      setAnswer(
        logBookChecklistAnswers.find(
          item => item.id === found.logbookChecklistAnswerId,
        ),
      );
    }
  }, [update, answer, id, logBookChecklistAnswers, questionsToSend]);

  const getCustomButtons = useMemo(
    () =>
      logBookChecklistAnswers &&
      logBookChecklistAnswers.length > 0 && (
        <Options>
          {logBookChecklistAnswers.map(item => {
            const isActive = function() {
              if (!givenAnswer) {
                return false;
              }

              if (answer) {
                return item.id === answer.id;
              }

              return item.id === givenAnswer.logBookChecklistAnswerId;
            };

            return (
              <DefaultButton
                key={item.id}
                className={`${isActive() && 'active'} custom`}
                onClick={() => setAnswer(item)}
              >
                {questionTitleMapper(item.title)}
              </DefaultButton>
            );
          })}
        </Options>
      ),
    [
      answer,
      givenAnswer.logBookChecklistAnswerId,
      logBookChecklistAnswers,
    ],
  );

  useEffect(() => {
    if (Boolean(plan)) {
      setAnswer(
        logBookChecklistAnswers.find(item => item.title === 'Não'),
      );
    }
  }, [logBookChecklistAnswers, plan]);

  const handleRemovePlan = useCallback(
    deletePlan => {
      let checkPlan;
      const found = questionsToSend.filter(
        item => item.actionPlan.id === deletePlan.id,
      );

      if (
        found &&
        (found[0].actionPlan.id !== deletePlan.id) === false
      ) {
        checkPlan = null;
      }

      setQuestionsToSend([
        { ...questionsToSend[0], actionPlan: checkPlan },
      ]);

      setQuestionList(oldList =>
        oldList.map(item => {
          if (
            item.id === questionsToSend[0].logbookChecklistQuestionId
          ) {
            return {
              ...item,
              logBookChecklistSupervisorAnswers: [
                {
                  logBookChecklistActionPlan: null,
                },
              ],
            };
          }
          return item;
        }),
      );
    },
    [questionsToSend, setQuestionList, setQuestionsToSend],
  );

  const handleSelectAnswer = key => {
    let answer;

    if (isDefault) {
      answer = logBookChecklistAnswers.find(
        item => item.title === key,
      );
    } else if (selectOptions) {
      answer = key;
    }

    setAnswer(answer);

    if (onSelectAnswer) {
      onSelectAnswer(id, answer);
    }
  };

  return (
    <QuestionWrapper>
      <Question>
        <CircleAndTitle
          initials={number}
          description={title}
          bgColor="colorPrimary"
        />
      </Question>

      {isDefault && (
        <>
          <Options>
            <DefaultButton
              className={`
                ${
                  answer &&
                  ((answer.logBookChecklistAnswerId &&
                    answer.logBookChecklistAnswerId === 1) ||
                    answer.title === 'Sim')
                    ? 'active'
                    : ''
                }
                green`}
              onClick={() => handleSelectAnswer('Sim')}
            >
              <Icon name="icon-check" size={20} />
              {t('circuitQuestion.defaultButton.yes')}
            </DefaultButton>

            <DefaultButton
              className={`
                ${
                  answer &&
                  ((answer.logBookChecklistAnswerId &&
                    answer.logBookChecklistAnswerId === 2) ||
                    answer.title === 'Não')
                    ? 'active'
                    : ''
                }
                red`}
              onClick={() => handleSelectAnswer('Não')}
            >
              <Icon name="icon-close" size={20} />
              {t('circuitQuestion.defaultButton.no')}
            </DefaultButton>

            <DefaultButton
              className={`
                ${
                  answer &&
                  ((answer.logBookChecklistAnswerId &&
                    answer.logBookChecklistAnswerId === 3) ||
                    answer.title === 'Não se aplica')
                    ? 'active'
                    : ''
                }
                no-border`}
              onClick={() => handleSelectAnswer('Não se aplica')}
            >
              {t('circuitQuestion.defaultButton.doNotApply')}
            </DefaultButton>
            {!answer && (
              <div>
                <BadgeIcon
                  footerIcon="icon-exclamation-triangle"
                  footerColor="colorDanger"
                  footerContent={t('circuitQuestion.textInput.badgeIcon')}
                />
              </div>
            )}
          </Options>

          {((!plan && answer && answer.title === 'Não') ||
            (!plan && propsAnswer && propsAnswer === 'Não')) && (
            <div style={{ marginBottom: '20px' }}>
              <p>
                {t('circuitQuestion.defaultButton.actionPlan.label')}
              </p>
              <Button
                onClick={() => onPlanButtonClick()}
                className="white"
              >
                {t('circuitQuestion.defaultButton.actionPlan.value')}
              </Button>
            </div>
          )}
        </>
      )}

      {plan && (
        <PlanCard
          {...plan}
          date={moment(plan.expectedDate).format('DD ̸̸  MM ̸̸  YYYY')}
          description={plan.action}
          footerContent={
            plan.wasExecuted
              ? t('circuitQuestion.actionPlan.executed')
              : t('circuitQuestion.actionPlan.executionDeadline', {
                  value: moment(plan.expectedDate).format(
                    'DD ̸̸  MM ̸̸  YYYY',
                  ),
                })
          }
          footerColor={
            plan.wasExecuted ? 'colorSuccess' : 'colorInfo'
          }
          footerIcon={
            plan.wasExecuted
              ? 'icon-check'
              : 'icon-exclamation-circle'
          }
          hasAttachment={
            plan.attachmentsUrls && plan.attachmentsUrls.length > 0
          }
          setDeleteModal={() => setDisplayDeleteModal(true)}
          setDisplayPlanEdit={() => setDisplayPlanEdit(plan)}
          displayOptions={givenAnswer.isEditable && !plan.wasExecuted}
          wasExecuted={plan.wasExecuted}
          handleChangeExecuted={handleChangeExecuted}
        />
      )}

      {/* On Delete modal */}
      <Modal
        warning
        opened={displayDeleteModal}
        confirm={() => {
          handleRemovePlan(plan);
          setDisplayDeleteModal(false);
        }}
        deny={() => setDisplayDeleteModal(false)}
        onCloseModal={() => null}
      >
        <span className="icon icon-exclamation-triangle" />
        <h4>{t('circuitQuestion.actionPlan.delete.title')}</h4>

        <p>{t('circuitQuestion.actionPlan.delete.subtitle')}</p>
      </Modal>

      {textInput && (
        <Options>
          <Field>
            <Label />
            <Input
              type="number"
              placeholder={t('circuitQuestion.textInput.placeholder')}
              onChange={e => {
                setAnswer({ id: null, title: e.target.value });
                setInputText(e.target.value);
              }}
            />
          </Field>
          {!inputText && (
            <div>
              <BadgeIcon
                footerIcon="icon-exclamation-triangle"
                footerColor="colorDanger"
                footerContent={t('circuitQuestion.textInput.badgeIcon')}
              />
            </div>
          )}
        </Options>
      )}

      {selectOptions && (
        <Options>
          <FieldSet>
            <DropdownBox
              onSelect={item => handleSelectAnswer(item)}
              downshiftProps={{
                itemToString: item => item && item.title,
              }}
              selectedItem={answer}
            >
              <DropdownField>
                <Label>{label || ''}</Label>
                <SelectBox>
                  <span className="placeholder">
                    {(answer && answer.title) ||
                      t('circuitQuestion.selectOptions.placeholder')}
                  </span>
                </SelectBox>
              </DropdownField>
              <Menu>
                {logBookChecklistAnswers.length > 0 &&
                  logBookChecklistAnswers.map((option, index) => (
                    <Item key={index} value={option}>
                      {option.title}
                    </Item>
                  ))}
              </Menu>
            </DropdownBox>
          </FieldSet>
          {!answer && (
            <div>
              <BadgeIcon
                footerIcon="icon-exclamation-triangle"
                footerColor="colorDanger"
                footerContent={t('circuitQuestion.textInput.badgeIcon')}
              />
            </div>
          )}
        </Options>
      )}

      {customButtons && getCustomButtons}
    </QuestionWrapper>
  );
}
