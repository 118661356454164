import styled from 'styled-components';
import { rgba } from 'polished';

import {
  colorGrayDarkest,
  colorInfo,
  colorTertiary,
} from 'components/GlobalStyles/variables';

export const VisitStyled = styled.div`
  display: flex;
  padding: 20px;

  .secondary {
    width: 100%;
    max-width: 316px;
    margin-right: 20px;
  }

  .primary {
    width: 100%;
    max-width: 652px;
  }
`;

export const Separator = styled.hr`
  margin: 0 -16px 16px;
  opacity: 0.25;
`;

export const Item = styled.div`
  color: ${colorGrayDarkest};
  border-bottom: ${({ noBorder }) => noBorder ? 'unset' : `1px solid ${rgba(colorGrayDarkest, 0.15)}`}};
  padding: 16px 0;
  font-size: 14px;
  line-height: 21px;

  &:last-of-type {
    border: none;
  }

  &.right {
    justify-content: flex-end;
  }

  .margin {
    margin-bottom: 8px;
  }

  .label {
    font-size: 12px;
  }

  .small {
    font-size: 12px;
    line-height: 16px;
  }

  button {
    width: 154px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  .icon {
    font-size: 2rem;
    color: ${colorTertiary};
  }
`;

export const BottomContentOrientation = styled.div`
  margin: 1rem -1.6rem -1.75rem;
  background: #eceff1;
  padding: 1rem 1.355rem;
  border-top: 1px solid #cfd2d8;
  display: flex;
  align-items: center;

  .icon {
    color: ${colorInfo};
    font-size: 2rem;
    margin-right: 0.75rem;
  }
`;

export const TitleOrientation = styled.span`
  font-size: 12px;
  color: ${colorInfo};
`;
