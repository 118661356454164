import moment from 'moment';
import { formatDateString } from 'shared/utils/Helpers/handleDate';

function getDateFromTimeString(date, time) {
  const formated = formatDateString(date);
  if (
    !time ||
    time === null ||
    isNaN(new Date(`${formated} ${time}`))
  ) {
    return '';
  }
  return moment(new Date(`${formated} ${time}`));
}

export const initialValues = data => {
  const initial = {
    isEditable: data.isEditable,
    isFirstDay: data.isFirstDay,
    isRequiredWeight: data.isRequiredWeight,
    isRequiredPh: data.isRequiredPh,
    hasFeedRemnant: data.flockClosure.hasFeedRemnant,
    flockLineage: data.flockOpening.flockLineage,
    flockGenre: data.flockOpening.flockGenre,
    broilerType: data.flockOpening.broilerType,
    initialAverageWeight: data.flockOpening.averageWeight,
    waterConsumption: data.waterConsume.waterConsumption,
    waterTime: getDateFromTimeString(
      data.date,
      data.waterConsume.waterTime,
    ),
    minTemperature: data.temperature.minTemperature,
    maxTemperature: data.temperature.maxTemperature,
    minHumidity: data.moisture.minHumidity,
    maxHumidity: data.moisture.maxHumidity,
    naturalDeath: data.mortality.naturalDeath,
    refuseElimination: data.mortality.refuseElimination,
    legElimination: data.mortality.legElimination,
    otherElimination: data.mortality.otherElimination,
    mortalityOnArrival: data.mortality.mortalityOnArrival,
    weightValue: data.averageWeight.weightValue,
    weightTime: getDateFromTimeString(
      data.date,
      data.averageWeight.weightTime,
    ),
    chlorine: data.ph.chlorine,
    ph: data.ph.ph,
    isInitialFeed: data.isFirstDay
      ? true
      : data.foodConsume.isInitialFeed,
    initialFeedType: {
      description: data.foodConsume.initialFeedType,
    },
    initialFeedTime: getDateFromTimeString(
      data.date,
      data.foodConsume.initialFeedTime,
    ),
    hasMovimentation: data.foodConsume.hasMovimentation,
    movimentationType: data.foodConsume.movimentationType,
    movimentationFeedType:
      data.foodConsume.movimentationFeedType &&
      data.foodConsume.movimentationFeedType.description,
    movimentationFeedValue: data.foodConsume.movimentationFeedValue,
    movimentationVendorInformation:
      data.foodConsume.movimentationVendorInformation,
    showFlockClosure: data.showFlockClosure,
    isFlockClosure: data.flockClosure.isFlockClosure,
    waterConsume: data.flockClosure.waterConsume,
    selfConsume: data.flockClosure.selfConsume,
    feedRemnant: data.flockClosure.feedRemnant,
  };

  return initial;
};
