import React, { useState, useEffect, useCallback } from 'react';
import Top from 'components/Top';
import t from 'components/i18n';
import Modal from 'components/Forms/FormModal/Modal';
import Toaster from 'components/Toaster';
import { LIBRARY } from 'config/routes';
import { Button } from '@zendeskgarden/react-buttons';
import {
  getLibraryCategories,
  getTagList,
  deleteLibraryItem,
  getRegionalsWithCenters,
} from 'containers/SharedContainers/Library/service';
import {
  scrollToTop,
  getPermissionsById,
} from 'shared/utils/Helpers';
import ItemDetails from './ItemDetails';
import LibraryListWrapper from './LibraryListWrapper';
import EditLibrary from './EditLibrary';

export default function Library(props) {
  const {
    history,
    match: { params },
    user,
  } = props;

  const [editLibrary, setEditLibrary] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [tags, setTags] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadingTags, setLoadingTags] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [successOnExclude, setSuccessOnExclude] = useState(false);
  const [updateItems, setUpdateItems] = useState(false);
  const [regionals, setRegionals] = useState([]);

  const libraryPermissions = getPermissionsById(
    user.allowRoutes,
    '3',
  );

  async function getRegionals() {
    try {
      if (
        libraryPermissions &&
        (libraryPermissions.create || libraryPermissions.edit)
      ) {
        const response = await getRegionalsWithCenters();
        setRegionals(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const removeItem = useCallback(async id => {
    try {
      const response = await deleteLibraryItem(id);
      setSuccessOnExclude(response);
      setDisplayDeleteModal(false);
      setShowDetails(false);
      setUpdateItems(true);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (params.type === 'new' && !editLibrary) {
      setEditLibrary(true);
    }

    async function getCategories() {
      setLoadingCategories(true);
      try {
        const response = await getLibraryCategories();

        setCategories((response || []).map(cat => ({ ...cat, label: t(cat.name) })));
      } catch (error) {
        console.error(error);
      }
      setLoadingCategories(false);
    }

    async function getTags() {
      setLoadingTags(true);
      try {
        const response = await getTagList();
        if (response.length > 0) {
          setTags(response);
        }
      } catch (error) {
        console.error(error);
      }
      setLoadingTags(false);
    }

    getCategories();
    getTags();
  }, [params, editLibrary]);

  useEffect(() => {
    if (regionals.length) {
      return;
    }

    getRegionals();
  }, [regionals, getRegionals]);

  useEffect(() => {
    if (successOnExclude) {
      setEditLibrary(false);
      setSelectedItem(null);
    }
  }, [successOnExclude]);

  useEffect(() => {
    if (!editLibrary) {
      setSelectedItem(null);
    }
    scrollToTop(document.getElementById('main'));
  }, [editLibrary]);

  useEffect(() => {
    if (showDetails && editLibrary) {
      setEditLibrary(false);
    }
    scrollToTop(document.getElementById('main'));
  }, [showDetails, editLibrary]);

  return (
    <>
      {!editLibrary && !showDetails && (
        <>
          <Top>
            <h2>{t('menu.library.title')}</h2>

            {libraryPermissions && libraryPermissions.create && (
              <Button
                className={user.isNextUser ? 'tertiary' : 'primary'}
                onClick={() => {
                  history.push(`${LIBRARY}/new`);
                }}
              >
                {t("library.button.publish-content")}
              </Button>
            )}
          </Top>

          {!editLibrary && (
            <LibraryListWrapper
              user={user}
              setEditLibrary={setEditLibrary}
              setShowDetails={setShowDetails}
              showDetails={showDetails}
              categories={categories}
              loadingCategories={loadingCategories}
              tags={tags}
              setSelectedItem={setSelectedItem}
              setDisplayDeleteModal={setDisplayDeleteModal}
              updateItems={updateItems}
              setUpdateItems={setUpdateItems}
              libraryPermissions={libraryPermissions}
            />
          )}
        </>
      )}

      {editLibrary && !showDetails && (
        <EditLibrary
          editLibrary={editLibrary}
          setEditLibrary={setEditLibrary}
          setShowDetails={setShowDetails}
          showDetails={showDetails}
          selectedItem={selectedItem}
          history={history}
          userId={user.id}
          categories={categories}
          loadingTags={loadingTags}
          tags={tags}
          regionals={regionals}
        />
      )}

      {!editLibrary && selectedItem && showDetails && (
        <ItemDetails
          history={history}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setEditLibrary={setEditLibrary}
          setShowDetails={setShowDetails}
          setDisplayDeleteModal={setDisplayDeleteModal}
          regionals={regionals}
        />
      )}

      {/* Exclude item modal */}
      <Modal
        warning
        opened={displayDeleteModal}
        confirm={() => {
          removeItem(selectedItem && selectedItem.id);
        }}
        deny={() => setDisplayDeleteModal(false)}
        onCloseModal={() => {}}
      >
        <span className="icon icon-exclamation-triangle" />
        <h4>{t('library.exclude.title')}</h4>
        <p>
          {t('library.exclude.subtitle', { value: selectedItem && selectedItem.name || '' })}
        </p>
      </Modal>
      {/* \ Exclude item modal */}

      <Toaster
        className="toaster__notification"
        isVisible={successOnExclude}
        color="success"
        duration={3}
        delay={1}
      >
        <span className="icon icon-check" />{t('library.exclude.success')}
      </Toaster>
    </>
  );
}
