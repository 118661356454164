import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { OPEN_ANNOUNCEMENT } from 'config/routes';
import { List, ListItem } from 'components/List';
import Icon from 'components/Icon';
import Options from 'components/Options';
import LoadingHolder from 'components/LoadingHolder';
import IconPlaceholder from 'components/IconPlaceholder';
import Pagination from 'components/Pagination';
import { uniqBy } from 'lodash';
import NoContentTab from 'components/NoContentTab';
import { scrollToTop, paginateArray, getPermissionsById } from 'shared/utils/Helpers';
import { getAnnouncementsWithPagination } from './service';
import t from 'components/i18n';
import AnnouncementsFilter from './AnnouncementsFilter';
import { Container } from './Announcements.styled';

const getIcon = priority => {
  switch (priority) {
    case 'LOW':
      return {
        name: 'icon-notice',
        color: 'secondary',
      };
    case 'HIGH':
      return {
        name: 'icon-notice',
        color: 'danger',
      };
    default:
      return {
        name: 'icon-notice',
        color: 'primary',
      };
  }
};

export default function AnnouncementsList(props) {
  const {
    history,
    selected,
    setSelected,
    setEditAnnouncement,
    setOpenModal,
    removedId,
    hideListFilters,
    setRemovedId,
    customHeader,
    hidePagination,
  } = props;

  const user = useSelector(state => state.user);

  const [data, setData] = useState([]);
  
  const [metadata, setMetadata] = useState(null);
  const [page, setPage] = useState(1);
  const [originalData, setOriginalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit] = useState(10);
  const [announcementsPermissions, setAnnouncementsPermissions] = useState({});

  useEffect(() => {
    setAnnouncementsPermissions(getPermissionsById(user.allowRoutes, '9') || {});
  }, [user, getPermissionsById]);

  const getEditorOptions = () => [
    {
      action: () =>
        history.push(`${OPEN_ANNOUNCEMENT}/${selected.id}`),
      icon: 'visibility-enabled',
      label: t('announcements.options.details'),
      visible:
        Boolean(announcementsPermissions) && Boolean(announcementsPermissions).read,
    },
    {
      action: () => setEditAnnouncement(true),
      icon: 'edit',
      label: t('announcements.options.edit'),
      visible:
        Boolean(announcementsPermissions) && Boolean(announcementsPermissions).edit,
    },
    {
      action: () => setOpenModal(true),
      icon: 'trash',
      label: t('announcements.options.delete'),
      visible:
        Boolean(announcementsPermissions) &&
        Boolean(announcementsPermissions).delete,
    },
  ];

  useEffect(() => {
    setIsLoading(true);

    getAnnouncementsWithPagination(
      {
        page,
        limit,
        system: user.isNextUser ? 'next' : 'agro'
      }
    ).then(result => {
      setIsLoading(false);

      if (result.success) {
        const formatedArray = result.data.announcementsListView.map(announcement => ({
          ...announcement,
          icon: getIcon(announcement.priority),
          formatedDate: `${moment(announcement.date).format('DD ̸̸  MM ̸̸  YYYY')} 
            • ${moment(announcement.date).format('HH:mm')}`,
          countryCode: uniqBy(
            announcement.regionals.map((element, id) => ({
              countryCode: element.countryCode,
              id,
            })),
            'countryCode',
          )
        }));

        setData(formatedArray);
        setOriginalData(formatedArray);
        setMetadata(result.data.metadata);
      }
    });
  }, [page, limit]);

  useEffect(() => {
    if (removedId) {
      setTimeout(() => setRemovedId(null), 3000);
      getAnnouncementsWithPagination(
        {
          page,
          limit,
          system: user.isNextUser ? 'next' : 'agro'
        }
      ).then(result => {
        setIsLoading(false);

        if (result.success) {
          const formatedArray = result.data.announcementsListView.map(announcement => ({
            ...announcement,
            icon: getIcon(announcement.priority),
            formatedDate: `${moment(announcement.date).format('DD ̸̸  MM ̸̸  YYYY')}
               • ${moment(announcement.date).format('HH:mm')}`,
            countryCode: uniqBy(
              announcement.regionals.map((element, id) => ({
                countryCode: element.countryCode,
                id,
              })),
              'countryCode',
            )
          }));
          setData(formatedArray);
          setOriginalData(formatedArray);
          setMetadata(result.data.metadata);
        }
      });

      setSelected({});
      scrollToTop(document.getElementById('main'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removedId, page, limit]);

  const hasOptions = () => {
    const options = getEditorOptions() || [];

    return options.some(option => option.visible);
  }

  return (
    <>
      <Container className='announcements-list-wrapper'>
        {!hideListFilters &&
          <AnnouncementsFilter
            info={{
              userType: user.type,
              originalData: originalData,
              dataList: data,
              setDataList: setData,
            }}
            data={originalData}
            setCurrentPage={setPage}
          />
        }

        {customHeader && customHeader}

        {isLoading && (
          <div
            className="announcements-list__loading"
            style={{
              display: 'flex',
              padding: '30px 0 0 0',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <LoadingHolder isLoading={isLoading} />
          </div>
        )}

        {!isLoading && data.length > 0 && (
          <List className="announcements-list normal">
            {data.map(item => {
              return (
                <ListItem
                  noMargin
                  role="button"
                  key={item.id}
                  className="animated fadeIn"
                  onClick={() => setSelected(item)}
                >
                  <IconPlaceholder
                    className={`top icon-holder ${item.icon.color}`}
                    onClick={() => history.push(`${OPEN_ANNOUNCEMENT}/${item.id}`)}
                  >
                    <Icon className="icon" name={item.icon.name} />
                  </IconPlaceholder>

                  <div
                    className="list-item-content"
                    style={{ width: '100%' }}
                    onClick={() => history.push(`${OPEN_ANNOUNCEMENT}/${item.id}`)}
                  >
                    <h2 className="list-item-heading">{item.title}</h2>
                    {!item.shortDescription && (
                      <div
                        className="list-subheading"
                        style={{ paddingRight: '35px' }}
                        dangerouslySetInnerHTML={{
                          __html: item.description.substr(0, 100)
                        }} />
                    )}

                    {item.shortDescription && (
                      <div className="list-subheading">
                        {item.shortDescription}
                      </div>
                    )}

                    <p className="list-item-description">
                      {item.formatedDate}
                    </p>
                  </div>

                  {Boolean(announcementsPermissions) && hasOptions() && (
                    <div
                      className="editorOptions"
                      style={{ position: 'absolute', right: 0 }}
                    >
                      <Options
                        onClick={e => {
                          e.stopPropagation();
                          setSelected(item);
                        }}
                        options={getEditorOptions()}
                        placement="auto" />
                    </div>
                  )}
                </ListItem>
              );
            })}
          </List>
        )}

        {!isLoading && (!originalData.length || !data.length) && (
          <NoContentTab icon="icon-notice" hideButton>
            <p>
              {t('global.no-content')}
            </p>
          </NoContentTab>
        )}
      </Container>

      {metadata && !hidePagination && (
        <div style={{ maxWidth: 652 }}>
          <Pagination
            totalPages={Math.ceil(metadata.totalItens / limit)}
            pagePadding={2}
            currentPage={page}
            onChangePage={x => setPage(x.e)}
          />
        </div>
      )}
    </>
  );
}
