import React, { useMemo } from 'react';
import Container from 'components/Container';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import {
  StyledNextFlockList,
  Summary,
  Subheading,
  SummaryItem,
  Separator,
} from '../StyledNextFlock.styled';
import t from 'components/i18n';

export default function NextDemonstrative({
  demonstrative = {},
  setDisplayDetails,
}) {
  const demonstrativeData = useMemo(() => {
    if (!demonstrative) return [];

    return [
      {
        label: t('next.flock.demonstrative-data.placementDate'),
        info: demonstrative.placementDate,
      },
      {
        label: t('next.flock.demonstrative-data.slaughterDate'),
        info: demonstrative.slaughterDate,
      },
      {
        label: t('next.flock.demonstrative-data.ageSlaughter'),
        info: demonstrative.ageSlaughter,
      },
      {
        label: t('next.flock.demonstrative-data.placementQuantity'),
        info: demonstrative.placementQuantity,
      },
      {
        label: t('next.flock.demonstrative-data.slaughterQuantity'),
        info: demonstrative.slaughterQuantity,
      },
      {
        label: t('next.flock.demonstrative-data.slaughterWeight'),
        info: demonstrative.slaughterWeight,
      },
      {
        label: t('next.flock.demonstrative-data.averageWeight'),
        info: demonstrative.averageWeight,
      },
      {
        label: t('next.flock.demonstrative-data.weightGain'),
        info: demonstrative.weightGain,
      },
      {
        label: t('next.flock.demonstrative-data.flockResult'),
        info: demonstrative.flockResult,
        highlight: true,
      },
    ];
  }, [demonstrative]);

  if (!demonstrative) {
    return null;
  }

  return (
    <StyledNextFlockList>
      <Container
        hideDetail
        style={{ width: '100%', maxWidth: '652px' }}
      >
        <Summary>
          <Subheading>{t('next.flock.demonstrative.general-information.title')}</Subheading>

          {demonstrativeData.map(
            item =>
              item.info && (
                <SummaryItem
                  className={`${
                    item.highlight ? 'flex gray' : 'flex'
                  }`}
                  key={item.label}
                >
                  <span className="label">{item.label}</span>
                  <span className="info">{item.info}</span>
                </SummaryItem>
              ),
          )}
          <br />
          <br />
          <Subheading>{t("next.flock.demonstrative.tecnical-indicator.title")}</Subheading>

          <SummaryItem
            className="flex"
            style={{ alignItems: 'flex-start' }}
          >
            <span className="label">{t('next.flock.demonstrative.tecnical-indicator.item.mortality')}</span>
            <div className="column">
              <span className="info">
                {demonstrative.mortality && demonstrative.mortality.realAJ}{' '}
                {t("next.flock.demonstrative.tecnical-indicator.item.real-ajusted")}
              </span>
              <span className="info add">
                {demonstrative.mortality && demonstrative.mortality.real}{' '}
                {t("next.flock.demonstrative.tecnical-indicator.item.real")}
              </span>
              <span className="info add">
                {demonstrative.mortality && demonstrative.mortality.foreseen}{' '}
                {t('next.flock.demonstrative.tecnical-indicator.item.foreseen')}
              </span>
            </div>
          </SummaryItem>

          <SummaryItem
            className="flex"
            style={{ alignItems: 'flex-start' }}
          >
            <span className="label">{t('next.flock.demonstrative.tecnical-indicator.item.feed-conversion')}</span>
            <div className="column">
              <span className="info">
                {demonstrative.foodConversion && demonstrative.foodConversion.realAJ}{' '}
                {t('next.flock.demonstrative.tecnical-indicator.item.real-ajusted')}
              </span>
              <span className="info add">
                {demonstrative.foodConversion && demonstrative.foodConversion.real}{' '}
                {t("next.flock.demonstrative.tecnical-indicator.item.real")}
              </span>
              <span className="info add">
                {demonstrative.foodConversion && demonstrative.foodConversion.foreseen}{' '}
                {t('next.flock.demonstrative.tecnical-indicator.item.foreseen')}
              </span>
            </div>
          </SummaryItem>
        </Summary>

        <Separator />

        <BtnGroup>
          <Button
            key="back"
            className="tertiary"
            onClick={() => setDisplayDetails(false)}
          >
            {t('global.button.back')}
          </Button>
        </BtnGroup>
      </Container>
    </StyledNextFlockList>
  );
}
