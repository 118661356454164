import React, { useState } from 'react';
import { Sidebar, SidebarItem } from 'components/Sidebar';
import Hamburger from 'components/Hamburger';
import { HeaderNavStyled, Wrapper } from './HaderNav.styled';
import t from 'components/i18n';
import LanguageSwitch from 'components/LanguageSwitch/LanguageSwitch';

function getSidebarItemProps(label, active = false) {
  const props = {
    label,
  };

  if (active) {
    props.active = true;
  }

  return props;
}

function pathIsActive(activePath, route) {
  return activePath === route || activePath.indexOf(route) !== -1;
}

function scrollToAnchor(anchor) {
  document
    .getElementById(anchor)
    .scrollIntoView({ behavior: 'smooth' });
}

export default function HeaderNav(props) {
  const { isGtMobile } = props.responsive;
  const [menuIsOpen, switchMenu] = useState(false);
  const activePath = props.location.pathname;
  const options = [
    {
      link: 'about',
      label: t('lp.menu.item.about'),
    },
    {
      link: 'member',
      label: t('lp.menu.item.grower'),
    },
    {
      link: 'contact',
      label: t('lp.menu.item.support'),
    },
  ];

  return (
    <HeaderNavStyled>
      <ul>
        {isGtMobile &&
          options.map((option, index) => (
            <li key={index}>
              <button
                onClick={e => scrollToAnchor(option.link)}
                type="button"
              >
                {option.label}
              </button>
            </li>
          ))}
        {isGtMobile && (
          <li>
            <button
              type="button"
              className="btn"
              onClick={() => props.toggleLoginBox()}
            >
              {t('lp.menu.buttonPrimary')}
            </button>
          </li>
        )}
        <li>
          <Hamburger
            menuIsOpen={menuIsOpen}
            onClick={() => switchMenu(!menuIsOpen)}
          />
        </li>
        <li>
          <LanguageSwitch />
        </li>
      </ul>
      {!isGtMobile && (
        <>
          <Wrapper>
            <Sidebar isOpen={menuIsOpen} style={{ zIndex: '80' }}>
              {options.map((option, index) => (
                <SidebarItem
                  onClick={() => {
                    switchMenu(!menuIsOpen);
                    scrollToAnchor(option.link);
                  }}
                  {...getSidebarItemProps(
                    option.label,
                    pathIsActive(activePath, option.link),
                  )}
                />
              ))}
              <SidebarItem
                onClick={() => {
                  switchMenu(!menuIsOpen);
                  props.toggleLoginBox();
                }}
                {...getSidebarItemProps(
                  'Login',
                  pathIsActive(activePath, '#signin'),
                )}
              />
            </Sidebar>
          </Wrapper>
        </>
      )}
    </HeaderNavStyled>
  );
}
