import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  borderRadius,
  colorClear,
  boxShadow,
  fontFamilyTextBold,
  colorPrimary,
} from 'components/GlobalStyles/variables';

export const Box = styled.div`
  color: ${colorClear};
  background-color: ${props => props.color || colorPrimary};
  border-radius: ${borderRadius};
  width: ${props => (props.full ? '100%' : '316px')};
  padding: ${DEFAULT_SIZE * 2}px ${DEFAULT_SIZE}px;
  box-shadow: ${boxShadow};

  .icon-wrapper {
    text-align: center;
    width: 56px;
    padding: 0 12px 0 0;
    font-size: 24px;
  }

  .text {
    padding: 0 16px 0 0;

    p {
      font-size: 14px;
      line-height: 21px;
      margin: 0 0 ${DEFAULT_SIZE * 2}px;
      color: ${colorClear};

      &.title {
        font-size: 16px;
      }
    }
  }

  a,
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    border: none;
    width: ${props => (props.full ? '100%' : '244px')};
    height: 40px;
    background-color: ${colorClear};
    border-radius: ${borderRadius};
    color: ${props => props.color || colorPrimary};
    font-family: ${fontFamilyTextBold};
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`;
