import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  colorGrayLighter,
} from 'components/GlobalStyles/variables';

export const StyledDate = styled.p`
  color: ${colorGrayLighter};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 ${DEFAULT_SIZE * 2}px ${DEFAULT_SIZE * 2}px 0;
  width: ${DEFAULT_SIZE * 5}px;
`;

export const MonthDay = styled.span`
  font-size: 32px;
  line-height: 42px;
`;

export const WeekDay = styled.span`
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
`;
