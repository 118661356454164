import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { PROFILE } from 'config/routes';
import Breadcrumb from 'components/Breadcrumb';
import Container from 'components/Container';
import Modal from 'components/Forms/FormModal/Modal';
import t from 'components/i18n';
import UpdatePasswordForm from 'containers/AgroBRF/Forms/UpdatePasswordForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { createPasswordLgpd, authenticate } from '../../service';
import { ProfileStyled } from '../../ProfileAndSettings.styled';

const validateSchema = props =>
  yup.object().shape({
    currentPassword: yup
      .string()
      .required(t('global.status.required-field'))
      .min(6, t('global.validation.min-character-length', { length: 6 })),
    newPassword: yup
      .string()
      .required(t('global.status.required-field'))
      .min(6, t('global.validation.min-character-length', { length: 6 })),
    passwordConfirmed: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        t('global.validation.new-password-required'),
      )
      .required(t('global.validation.password-required')),
  });

export default function UpdatePassword({
  match: { params },
  history,
  user,
  isValidating,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const successMessage = useMemo(
    () => t('menu.profile.accountData.updatePassword.success.subtitle'),
    [t],
  );

  const modalErrorMessage = useMemo(
    () => t('menu.profile.accountData.updatePassword.error.subtitle'),
    [t],
  );

  const authenticateUser = useCallback(async () => {
    try {
      await authenticate({
        documentNumber: user.cpf,
        password: newPassword,
      });
    } catch (err) {
      console.error(err);
    }
  }, [newPassword, user.cpf]);

  useEffect(() => {
    if (newPassword) {
      authenticateUser();
    }
  }, [authenticateUser, newPassword]);

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    passwordConfirmed: '',
  };

  function submitForm(
    values,
    { setSubmitting, setErrors, isValidating },
  ) {
    const formData = {
      documentNumber: user.cpf,
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      passwordConfirmed: values.passwordConfirmed,
    };

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await createPasswordLgpd(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
        setNewPassword(val.newPassword);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }

    sendData(formData);
  }

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() =>
          history.push(`${PROFILE}/account-settings/account-data`)
        }
        links={[
          `${PROFILE}/account-settings`,
          `${PROFILE}/account-settings`,
          `${PROFILE}/account-settings/account-data`,
        ]}
        path={[
          t('menu.profile.title'),
          t('menu.profile.sectionAccountConfig.Title'),
          t('menu.profile.accountData.title'),
          t('menu.profile.accountData.updatePassword.title'),
        ]}
      />

      <ProfileStyled>
        <Container className="container" hideDetail>
          <Formik
            initialValues={initialValues}
            validate={validate(validateSchema)}
            onSubmit={submitForm}
            render={formikProps => (
              <UpdatePasswordForm
                {...formikProps}
                isValidating={isValidating}
                message={errorMessage}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                success={success}
                params={params}
                {...props}
              />
            )}
          />
        </Container>
      </ProfileStyled>

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
          history.push(`${PROFILE}/account-settings/account-data`);
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>
          {success
            ? successMessage
            : errorMessage || modalErrorMessage}
        </p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
