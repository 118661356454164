// @flow
import React from 'react';
import { Logo } from './Logo.styled';

// brf-logo-white
// brf-minimal-logo
// agro-logo-white

type TLogoProps = {
  logoName: string,
  variant: 'white' | 'coloured',
};

export default function(props: TLogoProps) {
  const { name, variant, ...rest } = props;

  return <Logo name={`${name}-logo-${variant}`} {...rest} />;
}
