import styled from 'styled-components';
import ContainerBase from 'components/Container';
import TopBase from 'components/Top';
import { Form as FormBase } from 'components/Forms/Forms.styled';

export const Container = styled(ContainerBase)`
  margin: 16px;

  .container-detail {
    ${({ hideDetail }) => hideDetail && 'visibility: hidden;'}
  }

  .container-content {
    width: 60%;
    min-width: 620px;
    min-height: 460px;
    height: ${({ fit }) => fit ? 'fit-content' : '460px'};
    padding: 6px 16px 16px 16px;

    .container-inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;

export const Separator = styled.hr`
  margin: 16px -16px;
  opacity: 0.12;
  margin: ${props => props.gap && `${props.gap}px -16px`};
  width: ${props => props.full && `calc(100% + 32px)`};
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 12px;

  .inner {
    width: 100%;
  }
`;

export const Title = styled.p`
  margin: 0;
  margin-top: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
`;

export const Form = styled(FormBase)`
  margin-top: -16px;
`;
