import {
  all,
  // takeLatest
} from 'redux-saga/effects';
// import {} from 'components/User/Sagas';
// import * as ActionTypes from 'components/User/types';

export default function* root() {
  yield all([]);
}
