import React from 'react';
import moment from 'moment';
import { Row, Col } from '@zendeskgarden/react-grid';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { List, ListItem } from 'components/List/List';

// Styles
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import t from 'components/i18n';

export default function MedicationDetails({
  medication,
  goTo,
  setDisplayExcludeModal,
}) {
  const listArray = [];

  if (medication) {
    listArray.push(
      {
        description: t('next.medicationDetails.house', { value: medication.houseCode }),
        icon: 'pin',
      },
      {
        description: `${moment(medication.startedDate).format(
          'DD ̸̸  MM ̸̸  YYYY',
        )} - ${moment(medication.endedDate).format('DD ̸̸  MM ̸̸  YYYY')}`,
        icon: 'calendar',
      },
      {
        description: medication.materialName,
        icon: 'medkit',
        subItemLabel: t('next.medicationDetails.quantity-admin'),
        subItemValue: `${medication.quantity}${medication.measurementUnit}`,
      },
      {
        description: medication.laboratoryName,
        icon: 'flask',
        subItemLabel: t('next.medicationDetails.flock'),
        subItemValue: medication.vaccineBatch,
      },
      {
        description: medication.healthProblemName,
        icon: 'disease',
        subItemLabel: t('next.medicationDetails.quantity-treated.label'),
        subItemValue: t('next.medicationDetails.quantity-treated.value', { value: medication.quantityTreated }),
      },
    );
  }

  function displayListItem(
    index,
    description,
    icon,
    subItemLabel,
    subItemValue,
  ) {
    return (
      <>
        <ListItem
          key={index}
          className="confirm-list__item animated fadeIn"
        >
          <div className="icon-wrapper">
            <span className={`icon icon-${icon}`} />
          </div>
          <div
            className="list-item-content"
            style={{ width: '100%' }}
          >
            <div className="list-item-description">{description}</div>
          </div>
        </ListItem>

        {subItemValue && (
          <ListItem className="confirm-list__item animated fadeIn">
            <div className="icon-wrapper">
              <span className="icon" />
            </div>
            <div
              className="list-item-content"
              style={{ width: '100%' }}
            >
              <div className="list-item-subitem list-item-description flex">
                <p className="small">{subItemLabel}</p>
                <p>{subItemValue}</p>
              </div>
            </div>
          </ListItem>
        )}
      </>
    );
  }

  return (
    <div className="confirmation">
      <List className="confirm-list" style={{ marginTop: '0' }}>
        {listArray.map((item, index) =>
          displayListItem(
            index,
            item.description,
            item.icon,
            item.subItemLabel,
            item.subItemValue,
          ),
        )}
      </List>

      <Separator />

      {/* \ Botões */}
      {medication && !medication.enableEdit && (
        <BtnGroup className="button-group--bottom">
          <Button
            key="back"
            onClick={() => goTo('medicationSummary')}
            className="tertiary"
          >
            {t('global.button.back')}
          </Button>
        </BtnGroup>
      )}

      {medication && medication.enableEdit && (
        <Row>
          {medication && medication.enableDelete && (
            <Col sm={6}>
              <BtnGroup noReverse>
                <Button
                  key="submitForm"
                  onClick={() => setDisplayExcludeModal(true)}
                  className="white"
                >
                  {t('global.button.delete')}
                </Button>
              </BtnGroup>
            </Col>
          )}

          <Col sm={6}>
            <BtnGroup className="button-group--bottom">
              <Button
                key="back"
                className="white"
                onClick={() => goTo('medicationSummary')}
              >
                {t('global.button.back')}
              </Button>
              <Button
                key="edit"
                className="tertiary"
                onClick={() => goTo('editMedication')}
              >
                {t("global.button.edit")}
              </Button>
            </BtnGroup>
          </Col>
        </Row>
      )}
      {/* \ Botões */}
    </div>
  );
}
