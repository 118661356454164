import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import moment from 'moment';
import { Row, Col } from '@zendeskgarden/react-grid';
import t from 'components/i18n';
import Badge from 'components/Badge';
import FooterMenu from 'components/FooterMenu';

// Contexts
import { SwineContext } from 'shared/contexts';
// Styles
import {
  Container,
  ItemContainer,
  FeedItem,
} from 'containers/AgroBRF/FlockList/Flock/FlockHistory/FlockHistory.styled';
import { statusMapper } from 'utils/mapper';

export default function MedicationSummaryItem(props) {
  const { feed, goTo, exclude } = props;
  const swineContext = useContext(SwineContext);
  const {
    setSelectedMedicationId,
    setSelectedHouse,
  } = swineContext.actions;
  const badgeProps = {};

  const selectItem = useCallback(
    option => {
      setSelectedMedicationId(feed.id);
      setSelectedHouse(feed.house);
      goTo(option);
    },
    [
      feed.house,
      feed.id,
      goTo,
      setSelectedHouse,
      setSelectedMedicationId,
    ],
  );

  const getOptions = useCallback(
    () => {
      const options = [
        {
          action: () => selectItem('medicationDetails'),
          icon: 'visibility-enabled',
          label: t('next.swineRecord.medicationSummaryItem.options.details'),
          visible: true,
        },
        {
          action: () => selectItem('editMedication'),
          icon: 'edit',
          label: t('next.swineRecord.medicationSummaryItem.options.editar'),
          visible: feed.enableEdit,
        },
        {
          action: () => {
            exclude();
            setSelectedMedicationId(feed.id);
          },
          icon: 'trash',
          label: t('next.swineRecord.medicationSummaryItem.options.excluir'),
          visible: feed.enableDelete,
        },
      ];
      return options;
    },
    [exclude, feed, selectItem, setSelectedMedicationId],
  );

  if (feed.status) {
    switch (feed.status.toLowerCase()) {
      case 'pendente':
        badgeProps.info = true;
        break;
      default:
        badgeProps.success = true;
    }
  }

  return (
    <Container className="bordered-container">
      <ItemContainer
        className="item-container"
        onClick={() => selectItem('medicationDetails')}
      >
        <div className="open summary">
          <FeedItem key={`feed-item-${feed.id}`} medkit>
            <header className="item-header">
              <div className="date">
                {moment(feed.startedDate).format('DD ̸̸  MM ̸̸  YYYY')}
                {' - '}
                {moment(feed.endedDate).format('DD ̸̸  MM ̸̸  YYYY')}
              </div>
              <div className="status">
                {feed.status && (
                  <Badge {...badgeProps}>{t(statusMapper(feed.status))}</Badge>
                )}
              </div>
            </header>
            <Row>
              <Col>
                <strong>{feed.name}</strong>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  {feed.quantity}
                  {feed.measurementUnit}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <span className="strong">
                    {t('next.swineRecord.medicationSummaryItem.quantityTreated', { value: feed.quantityTreated })}
                  </span>
                </p>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <p>{t("next.swineRecord.medicationSummaryItem.house", { value: feed.house })}</p>
              </Col>
            </Row>
          </FeedItem>
        </div>
      </ItemContainer>

      {!props.hideOptions && (
        <FooterMenu
          placement="start-bottom"
          options={getOptions(feed.isEditable)}
        />
      )}
    </Container>
  );
}
