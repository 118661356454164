import { API_SUPERVISOR_URL } from 'config/constants';
import { request } from 'shared/utils/client';
import { fixNestedNamesRecursively } from 'shared/utils/Helpers/capitalizeName';

export async function getProducersForOperationalUser() {
  const response = await request(
    `${API_SUPERVISOR_URL}/Producer/GetProducersForOperationalUser`,
    {
      method: 'GET'
    },
  );
  return {
    ...response,
    data: fixNestedNamesRecursively(response.data, 'vendorName'),
  };
}

export async function getUserTermList(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Producer/GetUserTermList`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}

export async function getPrivacyPolicyList(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Producer/GetPrivacyPolicyList`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}

export async function getInsurancePolicyList(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Producer/GetInsurancePolicy`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}
