import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { ListItem as li } from 'components/List';
import { Resume } from 'components/ResumeCard/ResumeCard.styled';
import Top from 'components/Top';
import {
  DEFAULT_SIZE,
  breakpointTablet,
  breakpointTablet2,
  breakpointDesktop,
  colorClear,
  colorGrayUltraLight,
  colorGrayLight,
  colorGrayLighter,
  colorGrayLightest,
  colorGrayDarker,
  colorGrayDarkest,
  colorSuccess,
  colorDanger,
  colorInfo,
  boxShadow,
  fontFamilyText,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';
import { RoundedItem as RoundedItemBase } from 'components/MultiSelect/Items.styled';

export const RoundedItem = styled(RoundedItemBase)`
  margin: 0 12px 0 0;
  height: 3.2rem;
  padding: 0 8px 0;
`;

export const TopMenuStyled = styled.div`
  width: 100%;

  .label {
    min-width: 160px;
    justify-content: center;

    @media (max-width: ${breakpointTablet}px) {
      min-width: 90px;
    }
  }
`;

export const TopMobileHolder = styled.div`
  ${Top} {
    height: 40px;
    background-color: ${colorGrayDarker};
    padding: 8px 16px;

    .top-content-heading {
      font-size: 14px;
      line-height: 20px;
      color: ${colorClear};
    }

    .top-icon-holder,
    .top-content-subheading {
      display: none;
    }
  }
`;

export const ListItem = styled(li)`
  position: relative;
  height: 56px;
  width: 100%;

  .list-item-heading {
    font-size: 14px;
    color: ${colorGrayLight};

    .active & {
      font-family: ${fontFamilyTextBold};
      color: ${colorGrayDarkest};
    }
  }
`;

export const TabletMenu = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: ${colorClear};
  border-radius: ${DEFAULT_SIZE}px;
  height: 72px;
  align-items: center;
  box-shadow: ${boxShadow};
  margin-bottom: ${DEFAULT_SIZE * 3}px;
  width: 100%;

  .tablet-menu-icon {
    font-size: 24px;
    color: ${colorGrayLighter};
    margin-bottom: ${DEFAULT_SIZE}px;
  }

  .tablet-menu-label {
    letter-spacing: 0.75px;
    font-size: 12px;
    line-height: 16px;
    color: ${colorGrayLighter};
  }

  .tablet-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;

    &:last-child {
      width: 140px;
    }

    &.active {
      .tablet-menu-label {
        color: ${colorGrayDarker};
      }
      .tablet-menu-icon {
        color: ${colorGrayDarker};
      }
    }
  }
`;

export const Menu = styled.div`
  background-color: ${colorClear};
  padding: 0 ${DEFAULT_SIZE * 2}px;
  padding-top: ${props => (props.hasChildren ? '30px' : '0')};
  margin-top: ${props => (props.hasChildren ? '-30px' : '0')};
  border-radius: ${DEFAULT_SIZE}px;
  overflow: hidden;
  box-shadow: ${boxShadow};
  width: 100%;
  margin-bottom: 15px;

  .menu-icon {
    font-size: 24px;
    color: ${colorGrayLighter};
    align-items: center;
    display: flex;
    margin-right: 32px;

    &:after {
      content: '';
      transition: 0.2s ease-in background-color;
      background-color: transparent;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: -8px;
      z-index: 0;
    }
  }

  .list-item-heading {
    color: ${colorGrayDarkest};
  }

  .menu-heading {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }

  .active {
    .menu-icon,
    .chevron,
    .list-item-heading {
      color: ${colorGrayDarkest} !important;
    }

    .menu-icon {
      :before {
        z-index: 1;
      }
      :after {
        background-color: ${colorGrayUltraLight};
      }
    }
  }

  .menu-group {
    margin-bottom: ${DEFAULT_SIZE * 2}px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 56px;

    &:last-child {
      ${ListItem} {
        &:before {
          height: 0;
        }
      }
    }
  }

  @media (min-width: ${breakpointTablet}px) {
    width: auto;
  }

  @media (min-width: ${breakpointDesktop}px) {
    width: 100%;
    max-width: 376px;
  }
`;

export const Container = styled.div`
  padding: ${DEFAULT_SIZE * 2}px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpointTablet2}px) {
    flex-direction: row;
  }

  &>div:first-of-type {
    margin-right: 0;
    margin-bottom: 20px;
    
    @media (min-width: ${breakpointDesktop}px) {
      margin-right: 20px;
    }
  }

  .mini-heading {
    font-style: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }

  .main-wrapper {
    width: 100%;
    
    @media (min-width: ${breakpointDesktop}px) {
      max-width: 652px;
    }
  }
  
  .secondary-wrapper {
    min-width: 280px;
    margin: 0 0 20px;

    @media (min-width: ${breakpointDesktop}px) {
      min-width: 320px;      
    }
  }

  .flock-details {
    width: 100%;
    /* background-color: ${colorClear}; */

    .flock-details-container {
      display: flex;
      flex-direction: column;
    }

    .flock-details-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .icon {
        font-size: 22px;
        color: ${colorGrayLighter};
      }
    }

    .flock-details-heading {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.15px;
      margin: 0;
    }

    .flock-details-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: ${colorGrayUltraLight};
      width: calc(100% + 32px);
      padding: 16px;
      margin: -17px 0 -17px -16px;

      .icon-holder {
        margin-right: ${DEFAULT_SIZE * 2}px;
      }

      .flock-details-top-item {
        position: relative;        
        text-align: left;
        width: 100%;        
        
        @media (min-width: ${breakpointDesktop}px) {          
          text-align: center;          
          border-right: 1px solid ${colorGrayLightest};           
          
          &:last-of-type {
            border-right: none;
          }
        }

      }

      .flock-details-top-heading {
        color: ${colorGrayLighter};
        margin-bottom: inherit;
        text-transform: uppercase;
        display: block;
        font-size: 10px;
        letter-spacing: 1.5px;
        
        @media (min-width: ${breakpointDesktop}px) {
          margin-right: 16px;
          text-transform: none;
          display: inline;
          font-size: 14px;
          letter-spacing: 0;
        }
      }

      .flock-details-top-label {
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: ${colorGrayDarker};
      }
    }

    .flock-details-content {      

      .flock-details-content-heading {
        margin-bottom: ${DEFAULT_SIZE * 2}px;
      }

      .notification-box {
        margin-top: 12px;
      }
    }
  }  
  
  .filter-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .flock-menu {
    min-width: 100%;

    ${Resume} {
      margin: 0 0 16px -16px;
      width: calc(100% + 32px);
    }


    @media (min-width: ${breakpointTablet}px) {
      width: auto;
      min-width: 315px;
    }

    @media (min-width: ${breakpointDesktop}px) {
      width: 100%;
      margin-right: ${DEFAULT_SIZE * 2}px;
      max-width: 376px;
    }
  }

  &.column {
    flex-direction: column;
    
    @media (min-width: ${breakpointDesktop}px) {
      flex-direction: row;
    }
  }

  @media (min-width: ${breakpointTablet}px) {
    flex-direction: column;
  }

  @media (min-width: ${breakpointDesktop}px) {
    flex-direction: row;
  }
`;

export const FlockDetailsMobileWrapper = styled.div`
  background-color: ${colorClear};
  padding: ${DEFAULT_SIZE * 2}px;

  .flock-details-header {
    display: none !important;
  }
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;
  margin: ${props =>
    props.gap && `${props.gap}px -${DEFAULT_SIZE * 2}px`};
  width: ${props => props.full && `calc(100% + 32px)`};
`;

export const ContentLine = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${colorGrayLight};
  line-height: 16px;
  padding: 0 0 16px 16px;
  margin: 0 16px 16px;
  border-bottom: 1px solid ${colorGrayLightest};

  &:last-of-type {
    border-bottom: none;
  }

  .label {
    width: 152px;
    margin-right: 32px;
    font-size: 12px;
  }

  .value {
    display: inline-block;
    color: ${colorGrayDarker};
    font-size: 14px;
    line-height: 20px;
    font-family: ${fontFamilyTextBold};

    .small {
      color: ${colorGrayLight};
      display: block;
      font-size: 12px;
    }
  }

  .table__title {
  }

  .table & {
    &:first-of-type {
      width: calc(100% + 32px);
      margin-left: -16px;
      padding: 16px 46px;
      background-color: ${colorGrayLighter};
      color: ${colorClear};

      .value {
        color: ${colorClear};
      }
    }
  }
`;

export const RecordSummaryItem = styled.div`
  position: relative;
  margin-bottom: 14px;
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};
  padding-bottom: 8px;

  .label {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${colorGrayLight};
    font-family: ${fontFamilyTextBold};
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const Comparative = styled.p`
  &.negative {
    color: ${colorDanger};
  }

  &.positive {
    color: ${colorSuccess};
  }

  .icon {
    font-size: 18px;
    position: relative;
    top: 2px;
  }
`;

export const SectionTitle = styled.div`
  position: relative;
  display: flex;
  align-items: ${props => (props.alignTop ? 'flex-start' : 'center')};

  & > div {
    width: calc(100% - 56px);
  }

  .record-list & {
    &:last-of-type {
      & > div {
        &:last-of-type {
          ${RecordSummaryItem} {
            &:last-of-type {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
`;

export const RoundIconWrapper = styled.span`
  display:block;
  text-align: center;
  font-size: 20px;
  border: 2px solid;
  border-color: ${props =>
    props.isChecked ? colorSuccess : colorGrayLightest};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 16px;
  transition: .5s ease-in border-color;

  .small {
    position: absolute;
    top: 10px;
    font-size: 14px;
    color: ${colorClear};
    opacity: 0;
    transition: .3s ease-in opacity;

    &.visible {
      opacity: 1;
    }

    &:before {
      z-index: 1;
      position: relative;
    }

    &:after {
      content: '';
      position:relative;
      display:block;
      background-color: ${colorSuccess}
      border-radius: 50%;
      width: 15px;
      height: 15px;
      z-index: 0;
      top: -14px;
    }

    &.required {
      background-color: ${colorInfo}
      position:absolute;
      display:block;      
      border-radius: 50%;
      width: 15px;
      height: 15px;
      left: 30px;
      line-height: 16px;
      font-size: 12px;

      &:after {
        display: none;
      }

      &:before {
        display: none;
      }
    }
  }
`;

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${DEFAULT_SIZE};
`;

export const FormTop = styled.div`
  display: flex;
  justify-content: space-between;

  .blue {
    color: ${colorInfo};
    font-size: 12px;
    line-height: 16px;
    margin-top: 24px;
  }

  .round {
    background: ${colorInfo};
    color: ${colorClear};
    border-radius: 50%;
    display: inline-block;
    width: 15px;
    height: 15px;
    text-align: center;
  }
`;

export const DownloadFile = styled.div`
  a.download-file {
    color: ${colorGrayDarkest};
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;

    .icon {
      color: ${colorGrayLighter};
      font-size: 22px;
      margin: 0 16px 0 0;
      position: relative;
      top: 4px;
    }

    &:hover {
      color: ${colorGrayLighter}!important;
    }
  }
`;
