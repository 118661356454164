import { API_PRODUCER_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getFlockInvoice(page, limit) {
  const payload = {
    page,
    limit
  };

  const response = await request(
    `${API_PRODUCER_URL}/FlockInvoice/GetFlockInvoice`,
    {
      method: 'GET',
      payload
    },
  );

  return response;
}
