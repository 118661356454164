import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import { List, ListItem } from 'components/List';
import { getFileExtension } from 'shared/utils/Helpers';
import CircleAndTitle from 'components/CircleAndTitle';
import { uniqBy, find } from 'lodash';
import {
  InputField,
  DropdownField,
  CreateAndSearchDropdown,
  FileInput,
} from 'components/Forms/Fields';
import {
  Separator,
  Subheading,
} from 'containers/SharedContainers/Library/Library.styled';
import LoadingHolder from 'components/LoadingHolder';
import MultiSelect from 'components/MultiSelect';
import {
  allCentersOption,
  allRegionalsOption,
  allLocationOption,
} from 'containers/SharedContainers/Library/utils';
import t from 'components/i18n';
import { profileKeyMapper } from 'utils';
import { regionalMapper } from 'utils/mapper';

export default function LibraryForm({
  handleFile,
  handleChange,
  setFieldValue,
  removeFile,
  handleBlur,
  files,
  values,
  errors,
  touched,
  isUploading,
  isLoading,
  isValid,
  isValidating,
  resetForm,
  handleSubmit,
  selectedItem,
  setEditLibrary,
  tags,
  categories,
  fileTypes,
  profileTypes,
  animalTypes,
  regionals,
}) {
  const [subCategories, setSubCategories] = useState([]);
  const user = useSelector(state => state.user);
  const [locale, setLocale] = useState([]);
  const [file, setFile] = useState(false);

  useEffect(() => {
    if (values.category) {
      setSubCategories(
        values.category.librarySubCategories.map(subCat => ({
          ...subCat,
          label: t(subCat.name),
        })),
      );
    }

    if (values.fileType && values.fileName) {
      if (
        values.fileType.extensions.indexOf(
          getFileExtension(values.fileName),
        ) === -1
      ) {
        setFieldValue('fileName', 'errorErrorError');
      }
    }
  }, [setFieldValue, values]);

  useEffect(() => {
    if (files) {
      setFieldValue('fileName', files.length > 0 ? files[0] : '');
    }
  }, [files, setFieldValue]);

  const mapAnimalCode = (type = '') => {
    return (
      {
        ['all']: 'all',
        ['broiler']: 'Frango',
        ['turkey']: 'Peru',
        ['swine']: 'Suíno',
      }[type.toLowerCase()] || type
    );
  };

  const localeOptions = React.useMemo(() => {
    const hasAllInclude = values['animalType'].value === 'all';

    const localeByAnimal = regionals.filter(regional =>
      regional.centerList.some(
        cl =>
          hasAllInclude ||
          cl.animalType === mapAnimalCode(values['animalType'].value),
      ),
    );

    const uniqueLocales = regionals.length
      ? uniqBy(
          localeByAnimal.map((element, index) => ({
            countryCode: element.countryCode,
            id: index,
          })),
          'countryCode',
        )
      : [];

    const options = [
      {
        ...allLocationOption,
        values: uniqueLocales,
      },
      ...uniqueLocales,
    ];

    return {
      localeByAnimal,
      options,
    };
  }, [values]);

  const regionalsOptions = React.useMemo(() => {
    const countryCodes = (values['countryCode'] || []).map(
      el => el.countryCode,
    );

    const hasAllInclude = Boolean(
      find(values['countryCode'], { id: 'all' }),
    );

    const regionalsByLocales = localeOptions.localeByAnimal.filter(
      regional =>
        hasAllInclude || countryCodes.includes(regional.countryCode),
    );

    const unitOptions = regionalsByLocales.map(regional => ({
      ...regional,
      label: t(regionalMapper(regional.name)),
      centerList: regional.centerList.filter(
        centerList =>
          values['animalType'].value === 'all' ||
          centerList.animalType ===
            mapAnimalCode(values['animalType'].value),
      ),
    }));

    const options = [
      {
        ...allRegionalsOption,
        values: unitOptions,
      },
      ...unitOptions,
    ];

    return {
      regionalsByLocales,
      options,
    };
  }, [values, localeOptions]);

  const centerOptions = React.useMemo(() => {
    const regionals = (values['regionals'] || []).map(el => el.name);
    const animalType = mapAnimalCode(values['animalType'].value);

    const hasAllInclude = Boolean(
      find(values['regionals'], { id: 'all' }),
    );

    const centerByRegionals = regionalsOptions.regionalsByLocales.filter(
      regional => hasAllInclude || regionals.includes(regional.name),
    );

    const unitOptions = centerByRegionals
      .map(regional => regional.centerList)
      .flat()
      .filter(
        centerList =>
          animalType === 'all' ||
          centerList.animalType === animalType,
      );

    const options = [
      {
        ...allCentersOption,
        centerList: unitOptions,
      },
      ...unitOptions,
    ];

    return {
      centerByRegionals,
      options,
    };
  }, [values, localeOptions]);

  return (
    <>
      <LoadingHolder
        message={t('global.loading')}
        isLoading={isLoading}
      />
      {!isLoading && (
        <Form>
          <List>
            <ListItem noPadding>
              <CircleAndTitle
                initials="01"
                name={t('library.form.section.add-content')}
                bgColor="colorPrimary"
              />
            </ListItem>
          </List>

          <Row>
            <Col>
              {/* Título */}
              <InputField
                type="text"
                field="name"
                label={t('library.form.field.name.label')}
                placeholder={t('library.form.field.name.placeholder')}
                onBlur={handleBlur}
                onChange={e => {
                  handleChange(e);
                }}
                max={80}
                counter
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Título */}

              {/* Tipo do arquivo */}
              {fileTypes.length > 0 && (
                <DropdownField
                  field="fileType"
                  label={t('library.form.field.fileType.label')}
                  placeholder={t(
                    'library.form.field.fileType.placeholder',
                  )}
                  onSelect={e => {
                    setFieldValue('fileType', e);
                  }}
                  options={fileTypes || []}
                  optionLabel="label"
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              )}
              {/* \ Tipo do arquivo */}

              {/* Arquivo */}
              {values.fileType && (
                <FileInput
                  type="file"
                  field="fileName"
                  label={t('library.form.field.fileName.label')}
                  onChange={e => {
                    handleFile(e.target.files[0]);
                  }}
                  itemLabel="fileName"
                  removeFile={e => {
                    removeFile(e);
                  }}
                  itemToRemove="id"
                  isLoading={isUploading}
                  itemsToList={files}
                  blobContainerName="library"
                  token={user.token}
                  onBlur={handleBlur}
                  accept={values.fileType.accept}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              )}
              {/* \Arquivo */}
            </Col>
          </Row>

          <Separator />

          <List>
            <ListItem noPadding>
              <CircleAndTitle
                initials="02"
                name={t('library.form.section.profile')}
                bgColor="colorPrimary"
              />
            </ListItem>
          </List>

          <Row>
            <Col>
              {/* Perfil de usuário */}
              {profileTypes.length > 0 && (
                <DropdownField
                  field="profileType"
                  label={t('library.form.field.profileType.label')}
                  placeholder={t(
                    'library.form.field.profileType.placeholder',
                  )}
                  onSelect={e => {
                    setFieldValue('profileType', e);
                  }}
                  options={profileTypes || []}
                  optionLabel="label"
                  renderLabel={item => profileKeyMapper(item)}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              )}
              {/* \ Perfil de usuário */}

              {/* Tipo de criação */}
              <DropdownField
                field="animalType"
                label={t('library.form.field.animalType.label')}
                placeholder={t(
                  'library.form.field.animalType.placeholder',
                )}
                onSelect={e => {
                  setFieldValue('animalType', e);
                  setFieldValue('countryCode', []);
                  setFieldValue('regionals', []);
                  setFieldValue('centerList', []);
                }}
                options={animalTypes || []}
                optionLabel="label"
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Tipo de criação */}
            </Col>
          </Row>

          <br />
          <Subheading small>
            {t('library.form.section.location')}
          </Subheading>

          <Row>
            <Col>
              {/* Locale/country code */}
              <MultiSelect
                field="countryCode"
                label={t('library.form.field.countryCode.label')}
                placeholder={t(
                  'library.form.field.countryCode.placeholder',
                )}
                addItem={(_, values) => {
                  setLocale(values);
                  setFieldValue('countryCode', values);
                  setFieldValue('regionals', []);
                  setFieldValue('centerList', []);
                }}
                removeItemBy="countryCode"
                disabled={values.animalType === ''}
                options={localeOptions.options}
                optionLabel="countryCode"
                values={values}
                group={false}
                errors={errors}
                touched={touched}
              />
            </Col>
          </Row>

          <Row>
            {
              <Col>
                {/* Regional */}
                <MultiSelect
                  field="regionals"
                  label={t('library.form.field.regionals.label')}
                  placeholder={t(
                    'library.form.field.regionals.placeholder',
                  )}
                  addItem={(key, values) => {
                    setFieldValue(key, values);
                    setFieldValue('centerList', []);
                  }}
                  removeItemBy="id"
                  disabled={
                    !values.countryCode ||
                    (Array.isArray(values.countryCode) &&
                      !values.countryCode.length)
                  }
                  options={regionalsOptions.options}
                  optionLabel="label"
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            }
          </Row>
          <Row>
            <Col>
              {/* Unidade */}
              <MultiSelect
                field="centerList"
                label={t('library.form.field.centerList.label')}
                placeholder={t(
                  'library.form.field.centerList.placeholder',
                )}
                addItem={setFieldValue}
                removeItemBy="code"
                disabled={
                  !values.regionals ||
                  (Array.isArray(values.regionals) &&
                    !values.regionals.length)
                }
                options={centerOptions.options}
                optionLabel="name"
                values={values}
                errors={errors}
                touched={touched}
              />
            </Col>
          </Row>

          <Separator />

          <List>
            <ListItem noPadding>
              <CircleAndTitle
                initials="03"
                name={t('library.form.section.category')}
                bgColor="colorPrimary"
              />
            </ListItem>
          </List>

          <Row>
            <Col>
              {/* Categoria principal */}
              {categories.length > 0 && (
                <DropdownField
                  field="category"
                  label={t('library.form.field.category.label')}
                  placeholder={t(
                    'library.form.field.category.placeholder',
                  )}
                  onSelect={e => {
                    setFieldValue('category', e);
                    setFieldValue('subcategory', '');
                  }}
                  options={categories || []}
                  optionLabel="label"
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              )}
              {/* \ Categoria principal */}

              {/* Subcategoria */}
              <DropdownField
                field="subcategory"
                label={t('library.form.field.sub-category.label')}
                placeholder={t(
                  'library.form.field.sub-category.placeholder',
                )}
                onSelect={e => setFieldValue('subcategory', e)}
                options={subCategories || []}
                disabled={!values.category}
                optionLabel="label"
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Subcategoria */}

              {/* Tags */}
              {tags.length > 0 && (
                <CreateAndSearchDropdown
                  field="tags"
                  label={t('library.form.field.tag.label')}
                  placeholder={t(
                    'library.form.field.tag.placeholder',
                  )}
                  onSelect={tag => {
                    setFieldValue('tags', [...values.tags, tag]);
                  }}
                  deleteItem={tag => {
                    setFieldValue(
                      'tags',
                      values.tags.filter(item => item !== tag),
                    );
                  }}
                  options={tags}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              )}

              {/* \ Tags */}
            </Col>
          </Row>

          <br />

          <Separator />

          <Row>
            <Col sm={6} />
            <Col sm={6}>
              {/* \ Botões */}
              <BtnGroup>
                <Button
                  key="submitForm"
                  onClick={e => handleSubmit(e)}
                  disabled={!isValid || isValidating}
                >
                  {selectedItem
                    ? t('global.button.save')
                    : t('global.button.publish')}
                </Button>

                {selectedItem && (
                  <Button
                    key="clean"
                    className="white"
                    onClick={e => {
                      setEditLibrary(false);
                    }}
                  >
                    {t('global.button.cancel')}
                  </Button>
                )}

                {!selectedItem && (
                  <Button
                    key="clean"
                    className="white"
                    onClick={() => resetForm()}
                  >
                    {t('global.button.clear')}
                  </Button>
                )}
              </BtnGroup>
              {/* \ Botões */}
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}
