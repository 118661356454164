import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import momentLocale from 'moment/locale/pt-br';
import { ThemeContext } from 'styled-components';
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import {
  CalendarStyled,
  Wrapper,
  Day,
  ArrowGroup,
} from './WeekCalendar.styled';

moment.defineLocale('pt-br', momentLocale);

export default function WeekCalendar(props) {
  const [calendarStart, setCalendarStart] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  const {
    startDate = moment(),
    finishDate = moment().add(7, 'days'),
    date,
    setNextWeek,
    setPrevWeek,
    markedDates = [],
    bgColor,
    allDatesAvailable = false,
    hideArrows = false,
    activateFirst = true,
  } = props;
  const themeContext = useContext(ThemeContext);

  function getFullWeek(d) {
    const week = moment(d).week();

    return Array(7)
      .fill(0)
      .map((n, i) =>
        moment(d)
          .week(week)
          .startOf('week')
          .clone()
          .add(n + i, 'day')
          .format(),
      );
  }

  function getDateMonth(d) {
    return moment(d)
      .format('MMM')
      .toUpperCase();
  }

  function getDateYear(d) {
    return moment(d).format('YYYY');
  }

  function getCalendarTitle(arr) {
    let calendarString = '';
    const firstDayMonth = getDateMonth(arr[0]);
    const lastDayMonth = getDateMonth(arr[6]);
    const firstDayYear = getDateYear(arr[0]);
    const lastDayYear = getDateYear(arr[6]);

    if (firstDayYear === lastDayYear) {
      if (firstDayMonth === lastDayMonth) {
        calendarString = `${moment(arr[0]).format(
          'MMMM',
        )} ${getDateYear(arr[0])}`;
      } else {
        calendarString = `${firstDayMonth} / ${lastDayMonth} ${getDateYear(
          arr[0],
        )}`;
      }
    } else {
      calendarString = `${firstDayMonth} ${getDateYear(
        arr[0],
      )} / ${lastDayMonth} ${getDateYear(arr[7])}`;
    }

    return calendarString;
  }

  useEffect(() => {
    if (!calendarStart) {
      setCalendarStart(moment(date || startDate).format());

      if (activateFirst) {
        setActiveDate(moment(date || startDate).format());
      }
    }
  }, [date, startDate, calendarStart, activateFirst]);

  const calendar = getFullWeek(calendarStart);

  return (
    <div className="flock-details week-calendar">
      <header className="flock-details-header">
        <h2 className="flock-details-heading">
          {getCalendarTitle(calendar)}
        </h2>
        {!hideArrows && (
          <ArrowGroup>
            <button
              type="button"
              className="icon icon-chevron-left"
              onClick={() => {
                setCalendarStart(
                  moment(calendarStart).subtract(7, 'days'),
                );
                setPrevWeek();
              }}
            />
            <button
              type="button"
              className="icon icon-chevron-right"
              onClick={() => {
                setCalendarStart(
                  moment(calendarStart).add(7, 'days'),
                );
                setNextWeek();
              }}
            />
          </ArrowGroup>
        )}
      </header>

      <Separator />

      <CalendarStyled>
        <Wrapper>
          {calendar.map((item, index) => (
            <Day
              key={index}
              onClick={() => {
                setActiveDate(moment(item).format());
                props.onSelectDate(moment(item).format());
              }}
              isActive={moment(item).format() === activeDate}
              isMarked={markedDates.includes(moment(item).format())}
              isDisabled={
                !allDatesAvailable &&
                (moment(item).isBefore(startDate) ||
                  moment(item).isAfter(finishDate))
              }
              bgColor={themeContext.styles.palette[bgColor]}
            >
              <div className="week-day">
                {moment(item).format('ddd')}{' '}
              </div>
              <div className="month-day">
                {moment(item).format('DD')}
              </div>
            </Day>
          ))}
        </Wrapper>
      </CalendarStyled>
    </div>
  );
}
