import styled from 'styled-components';
import { FeedItem as FI } from 'components/FeedList';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import {
  DEFAULT_SIZE,
  colorPrimary,
  colorGrayDarkest,
  colorGrayLight,
  colorGrayLighter,
  colorGrayLightest,
  colorClear,
  colorInfo,
  colorSuccess,
  fontFamilyText,
  fontFamilyTextBold,
  breakpointLargeDesktop,
  breakpointTablet,
  colorDanger,
} from 'components/GlobalStyles/variables';

export const ItemContainer = styled.div`
  .feed-history-holder {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    margin-top: 16px;

    > div {
      padding-right: 0 !important;
      margin-bottom: 16px;
    }

    &.open {
      max-height: 5000px;
    }
  }

  .feed-show-history {
    margin-top: 0;

    button {
      display: flex;
      align-items: center;

      .mini-heading {
        margin: 0;
        margin-left: 30px;
        color: ${colorPrimary};
      }

      .feed-holder-icon {
        font-size: 24px;
        color: ${colorGrayLighter};
      }
    }
  }

  .columns {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (min-width: ${breakpointLargeDesktop}px) {
      flex-direction: row;
    }
  }
`;

export const FeedItem = styled(FI)`
  padding-bottom: 0;

  .align-right {
    font-size: 12px;
    text-align: right;
    // padding-right: 0;
  }

  strong {
    line-height: 24px;
    margin-bottom: 8px;
    display: block;
    font-size: 16px;
    letter-spacing: 0.15px;
  }

  p {
    font-size: 13px;
    color: ${colorGrayLight};
    margin: 0 0 5px;

    &.small {
      font-size: 13px;
    }

    .strong {
      font-family: ${fontFamilyTextBold};
    }
  }
`;

export const ColumnInfo = styled.div`
  width: 100%;
  margin: 0 0 16px;

  @media (min-width: ${breakpointLargeDesktop}px) {
    width: 25%;
    display: inline-block;
    margin: 0;
  }

  &.stretch {
    display: flex;
    justify-content: space-between;

    @media (min-width: ${breakpointLargeDesktop}px) {
      display: inline-block;
    }

    ${BtnGroup} {
      flex-direction: row;

      @media (max-width: ${breakpointTablet}px) {
        display: block;
        width: 100%;
      }
    }
  }

  p {
    font-size: 13px;
    line-height: 24px;
    margin: 0;
    color: ${colorGrayLight};
  }

  strong {
    display: block;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: ${colorGrayDarkest};
  }
`;

export const StatusFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  
  p {
    font-family: ${fontFamilyTextBold};
    margin: 4px 0 0;
    font-size: 12px;
    line-height: 16px;
    color: ${props => {
    if ('info' in props) {
      return colorInfo;
    }
    if ('danger' in props) {
      return colorDanger;
    }
    if ('primary' in props) {
      return colorPrimary;
    }
    return colorSuccess;
  }};

    span {
      font-family: ${fontFamilyText};
    }
  }

  .icon-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center
    margin-right: 6px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${props => {
    if ('info' in props) {
      return colorInfo;
    }
    if ('danger' in props) {
      return colorDanger;
    }
    if ('primary' in props) {
      return colorPrimary;
    }
    return colorSuccess;
  }}
    
    

    .icon {
      color: ${colorClear}!important;
      font-size: 20px;
      display: inline-block;
    }

    @media (max-width: ${breakpointTablet}px) {
      position: absolute;
      top: 14px;
      right: 10px;
    }
  }
`;

export const LastActivityStyled = styled.div`
  color: ${colorInfo};
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 12px;

  p,
  .icon {
    color: inherit;
  }
  p {
    margin: 0;
  }
  .icon {
    margin-right: 10px;
    font-size: 24px;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;

  ${StatusFooter} & {
    .icon {
      color: ${props => (props.info ? colorInfo : colorSuccess)};
    }
  }
`;

export const Container = styled.div`
  position: relative;
  padding: 12px ${DEFAULT_SIZE * 2}px;
  border: 1px solid ${colorGrayLightest};
  border-radius: ${DEFAULT_SIZE}px;
  margin-bottom: ${DEFAULT_SIZE * 2}px;
  box-shadow: 0px 4px 8px rgba(37, 48, 71, 0.16);
  transition: border-color 0.3s ease;

  &:hover {
    border-color: ${colorGrayLighter};
  }
`;

export const Group = styled.div`
  position: relative;

  .status {
    float: right;
  }
`;

export const GroupTitle = styled.strong`
  color: ${colorGrayDarkest};
  font-family: ${fontFamilyTextBold};
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 12px;
  margin-top: -12px;
  display: block;

  .icon {
    font-size: 20px;
    color: ${colorGrayLighter};
    margin: 0 12px 0 -9px;
    line-height: 28px;
    position: relative;
    top: 4px;

    @media (min-width: ${breakpointTablet}px) {
      margin: 0 22px 0 -9px;
    }
  }
`;

export const Item = styled.div`
  border-left: 1px solid ${colorGrayLightest};
  position: relative;
  padding-left: 20px;
  padding-bottom: 16px;

  @media (min-width: ${breakpointTablet}px) {
    padding-left: 30px;
  }

  &:before {
    content: '';
    display: block;
    width: 18px;
    height: 36px;
    background-color: ${colorClear};
    z-index: 0;
    position: absolute;
    left: -9px;
    top: calc(50% - 18px);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: ${colorGrayLight};
    z-index: 1;
    top: calc(50% - 4px);
    left: -4px;
  }

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 16px;

    &:before {
      height: 75%;
    }
  }

  p {
    margin: 0 0 4px;
    font-size: 14px;
    line-height: 24px;
    color: ${colorGrayDarkest};

    @media (min-width: ${breakpointTablet}px) {
      font-size: 16px;
    }

    &.small {
      font-size: 12px;
      line-height: 16px;
      color: ${colorGrayLight};
    }
  }

  ${Container} {
    margin-bottom: 0;
  }
`;
