/**
 * Removes duplicate itens of an array
 */

export default function reduceBy(arr, param) {
  const reduced =
    arr &&
    arr.length > 0 &&
    arr.reduce(
      (x, y) =>
        x.findIndex(e => e[param] === y[param]) < 0 ? [...x, y] : x,
      [],
    );

  return reduced;
}
