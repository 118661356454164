import { reduceBy } from 'shared/utils/Helpers';
import t from 'components/i18n';
import { statusMapper } from 'utils/mapper';

export default function getSlaughterFilters(dataItems, flockId) {
  const newD = Object.assign([], dataItems);
  let statusOptions = [];
  let houses = [];
  let flocks = [];

  if (newD.length > 0) {
    statusOptions = reduceBy(newD, 'status').map((e, i) => {
      return { txt: e.status, id: i, name: t(statusMapper(e.status)) };
    });

    if (!flockId) {
      flocks = reduceBy(newD, 'flock').map((e, i) => {
        return { txt: e.flock, id: e.flockId };
      });
    }

    houses = reduceBy(newD, 'house').map((e, i) => {
      if (e.house === null) return false;

      return {
        txt: t('next.vendorSchedule.filter.house.value', { value: e.house }),
        value: `${e.house}`,
        id: i,
      };
    });
  }

  const filtersData = [
    {
      label: t('next.vendorSchedule.filter.status'),
      items: statusOptions.length > 0 ? statusOptions : [],
    },
    {
      label: t('next.vendorSchedule.filter.flock'),
      items: flocks.length > 0 ? flocks : [],
    },
    {
      label: t('next.vendorSchedule.filter.house'),
      items: houses.length > 0 ? houses : [],
    },
  ];

  if (flockId) {
    filtersData.splice(1, 1);
  }

  return filtersData;
}
