import mask from './mask';

const PATTERN = '###.###';

export default function doMask(e) {
  const input = e ? e.toString() : false;

  if (input.length > 6) {
    return mask(PATTERN, input);
  }

  return Number(input).toLocaleString().replaceAll(',', '.');
}
