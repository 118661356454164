import React, { useState } from 'react';
import ButtonRound from 'components/ButtonRound';
import { Button } from '@zendeskgarden/react-buttons';
import t from 'components/i18n';
import { ModalFooter } from './TermModal.styled';

export default function ModalActions({ onAccept, onDeny }) {
  const [showWarning, setShowWarning] = useState(false);
  function deny() {
    const modalBody = document.getElementById('modalBody');

    setShowWarning(true);

    setTimeout(() => {
      modalBody.scroll({
        top: modalBody.scrollHeight,
        behavior: 'smooth',
      });
    }, 500);
  }

  return (
    <ModalFooter>
      <div className="acceptance">
        <div>{t('signup.termsOfUse.tab')}</div>
        <div>
          <div className="actions">
            <ButtonRound onClick={deny} danger type="button">
              <span className="icon-close" />
            </ButtonRound>

            <ButtonRound onClick={onAccept} success type="button">
              <span className="icon-check" />
            </ButtonRound>
          </div>
        </div>
      </div>

      {showWarning && (
        <div className="warning">
          <div className="message">
            <span className="icon-exclamation-triangle" />
            <span>{t('signup.termsOfUse.tab')}</span>
          </div>
          <div className="confirm">
            <span>
              {t('signup.termsOfUse.cancel.warning')}
            </span>
            <Button onClick={onDeny} type="button">
              {t('signup.termsOfUse.cancel.button')}
            </Button>
          </div>
        </div>
      )}
    </ModalFooter>
  );
}
