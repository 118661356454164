import React, { useEffect, useState, useContext } from 'react';
import Breadcrumb from 'components/Breadcrumb';
import Box from 'components/Container';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import { ThemeContext } from 'styled-components';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import Top from 'components/Top';
import Details from 'containers/AgroBRF/Cadec/Details';
import { getCadecDocuments } from 'containers/AgroBRF/Cadec/service';
import { getPermissionsById } from 'shared/utils/Helpers';
import { Container, ContainerFixedListItems } from './Cadec.styled';
import t from 'components/i18n';
import CadecItem from './CadecList/CadecItem';

export default function CadecToVendor(props) {
  const [data, setData] = useState([]);
  const [selectedCadec, setSelectedCadec] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [detailsTab, setDetailsTab] = useState('desc');

  const themeContext = useContext(ThemeContext);

  const cadecPermissions = getPermissionsById(
    props.user.allowRoutes,
    '4',
  );

  async function fetchData() {
    setIsLoading(true);

    try {
      const response = await getCadecDocuments();
      setData(response);
      if (response.length === 1) {
        setSelectedCadec(response[0]);
      }
    } catch (e) {
      console.error('Error on getCadecDocuments', e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {selectedCadec && (
        <Breadcrumb
          history={props.history}
          path={[t('cadec.bc.cadec'), selectedCadec && selectedCadec.cadecName]}
        />
      )}
      <Top>
        <h2>{selectedCadec ? selectedCadec.cadecName : t("cadec.bc.cadec")}</h2>
      </Top>

      {selectedCadec && (
        <>
          <Header>
            <TabHeaderHolder
              onClick={() => setDetailsTab('desc')}
              className={detailsTab === 'desc' ? 'active' : ''}
            >
              <div className="label">{t('cadec.details.description')}</div>
              <div className="bar" />
            </TabHeaderHolder>
            <TabHeaderHolder
              onClick={() => setDetailsTab('atas')}
              className={detailsTab === 'atas' ? 'active' : ''}
            >
              <div className="label">{t("cadec.details.atas")}</div>
              <div className="bar" />
            </TabHeaderHolder>
          </Header>
          <Container
            style={{
              width: '100%',
              maxWidth: '652px',
              flexDirection: 'column',
            }}
          >
            <Details
              stretchBox
              goTo={e => setSelectedCadec(null)}
              cadec={selectedCadec}
              cadecPermissions={cadecPermissions}
              detailsTab={detailsTab}
            />
          </Container>
        </>
      )}

      {!selectedCadec &&
        (data && data.length > 0 ? (
          <ContainerFixedListItems>
            <Box
              hideDetail
              style={{ maxWidth: '630px', width: '100%' }}
            >
              {data.map((item, index) => (
                <CadecItem
                  key={item.id}
                  cadec={item}
                  goTo={props.goTo}
                  setDisplayModal={() => props.setDisplayModal()}
                  iconColor={themeContext.styles.palette.colorPrimary}
                  whiteBgIcon
                  hideControls
                  onClick={setSelectedCadec}
                />
              ))}
            </Box>
          </ContainerFixedListItems>
        ) : (
          <Container>
            <Box
              hideDetail
              style={{ maxWidth: '630px', width: '100%' }}
            >
              {isLoading ? (
                <LoadingHolder isLoading={isLoading} />
              ) : (
                <NoContentTab icon="icon-file">
                  <p>
                    {t('global.no-content')}
                  </p>
                </NoContentTab>
              )}
            </Box>
          </Container>
        ))}
    </>
  );
}
