import { API_PRODUCER_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getOrientation(id) {
  const response = await request(
    `${API_PRODUCER_URL}/LogBookItem/GetOrientation?id=${id}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function setOrientationToReaded(payload) {
  const response = await request(
    `${API_PRODUCER_URL}/LogBookItem/SetOrientationToReaded`,
    {
      method: 'POST',
      payload,
    },
  );
  return response.data;
}
