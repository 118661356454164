import React, { useState, useEffect } from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import Box from 'components/Container';
import NewsListItem from 'containers/AgroBRF/News/NewsListItem';
import NewsColumn from 'containers/AgroBRF/News/NewsColumn';
import { getHighlightedNews } from 'containers/AgroBRF/News/service';
import LoadingHolder from 'components/LoadingHolder';
import {
  DashboardNewsList,
  ListColumn,
} from 'containers/AgroBRF/News/News.styled';
import { Container, Separator } from './StayTuned.styled';
import t from 'components/i18n';

export default function StayTuned(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [highlighted, setHighlighted] = useState([]);
  const [first, setFirst] = useState({});

  async function fetchHighlightedNews() {
    setIsLoading(true);

    try {
      const response = await getHighlightedNews();
      setHighlighted(response);
      setIsLoading(false);
    } catch (e) {
      console.error('Fetch error', e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchHighlightedNews();
  }, []);

  useEffect(() => {
    setFirst(highlighted.shift());
  }, [highlighted]);

  return (
    <Container>
      <Box slim className="stay-tuned">
        <div className="stay-tuned-holder">
          <header>
            <h2>{t('agrodashboard.stayTuned.last-news')}</h2>
            <Separator />
          </header>
          <DashboardNewsList className="dashboard-news-list" content={highlighted}>
            {isLoading ? (
              <LoadingHolder
                message={t('global.loading')}
                isLoading={isLoading}
              />
            ) : (
              <Row>
                <Col sm={props.fullWidth ? 4 : 6}>
                  <NewsListItem
                    content={first}
                    hasExcerpt
                    history={props.history}
                    stayTunedHighlight
                  />
                </Col>
                <Col sm={props.fullWidth ? 8 : 6}>
                  <ListColumn>
                    <NewsColumn
                      list={highlighted}
                      history={props.history}
                    />
                  </ListColumn>
                </Col>
              </Row>
            )}
          </DashboardNewsList>
        </div>
      </Box>
    </Container>
  );
}
