import styled from 'styled-components';
import {
  fontFamilyTextBold,
  colorGrayDarkest,
  colorTertiary,
  colorGrayLightest,
  colorGrayLighter,
  colorClear,
} from 'components/GlobalStyles/variables';

export const StepsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  line-height: 16px;
`;

export const StepItem = styled.div`
  font-size: 12px;
  position: relative;
  cursor: ${props => (props.disableClick ? 'default' : 'pointer')};

  p {
    display: inline;
    margin-left: 8px;
    font-family: ${fontFamilyTextBold};
    color: ${props => {
      if (props.isActive) {
        return colorTertiary;
      }

      if (props.isPast) {
        return colorGrayDarkest;
      }

      return colorGrayLighter;
    }};
    padding-right: 60px;

    &:after {
      content: '';
      width: 40px;
      height: 2px;
      background-color: ${props =>
        props.isPast ? colorTertiary : colorGrayLightest};
      display: block;
      position: absolute;
      right: 10px;
      top: 12px;
      transition: 0.3s ease-out background-color;
    }
  }

  &:last-of-type {
    p {
      padding-right: initial;
      &:after {
        display: none;
      }
    }
  }
`;

export const Circle = styled.span`
  font-family: ${fontFamilyTextBold};
  background-color: ${props => {
    if (props.isActive) {
      return colorClear;
    }

    if (props.isPast) {
      return colorTertiary;
    }

    return colorGrayLightest;
  }};
  color: ${props => {
    if (props.isActive) {
      return colorTertiary;
    }

    if (props.isPast) {
      return colorClear;
    }

    return colorGrayLighter;
  }};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: 0.3s ease-out background-color;
  border: 2px solid;
  border-color: ${props =>
    props.isPast || props.isActive
      ? colorTertiary
      : colorGrayLightest};
`;
