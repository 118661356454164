import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

const device = 'web';

export async function becomeVendor(payload) {
  const response = await request(
    `${API_URL}/Contact/BecomeVendor/${device}`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}

export async function getStates() {
  const response = await request(`${API_URL}/Contact/GetStates`, {
    method: 'GET',
  });
  return response;
}

export async function getCity(id) {
  const response = await request(`${API_URL}/Contact/GetCity/${id}`, {
    method: 'GET',
  });
  return response;
}
