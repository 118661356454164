import React from 'react';
import {
  Notification,
  Title,
} from '@zendeskgarden/react-notifications';
import { StyledToaster } from './Toaster.styled';

export default function Toaster(props) {
  return (
    props.isVisible && (
      <StyledToaster {...props}>
        <Notification className={props.className}>
          {props.title && <Title className="title">{props.title}</Title>}
          {props.children}
        </Notification>
      </StyledToaster>
    )
  );
}
