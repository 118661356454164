import {
  borderRadius,
  boxShadow,
  colorDanger,
  colorGrayDarker,
  colorGrayDarkest,
  colorGrayLight,
  colorGrayLightest,
  colorGrayUltraLight,
  colorHighlight,
  colorPrimary,
  colorSuccess,
  colorGrayLighter,
} from 'components/GlobalStyles/variables';
import styled from 'styled-components';

export const ConfirmModalStyled = styled.div`
  position: fixed;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  .shadow {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .box {
    max-width: 700px;
    border-radius: ${borderRadius};
    width: 100%;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .header {
    padding: 20px;
    border-bottom: 1px solid rgba(37, 48, 71, 0.12);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 16px;
      height: 3px;
      width: 50px;
      background: ${colorPrimary};
      top: 0;
    }
    .title {
      font-size: 20px;
      color: ${colorGrayDarker};
    }
    .icon-close {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .content {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .data {
    margin-right: 24px;
    flex: 1;
  }
  .data-title {
    font-size: 14px;
    color: ${colorGrayDarkest};
  }
  .row {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:first-of-type) {
      border-top: 1px solid rgba(37, 48, 71, 0.12);
    }
    .label {
      font-size: 12px;
      color: ${colorGrayLight};
    }
    .value {
      font-size: 14px;
      color: ${colorGrayDarkest};
    }
  }
  .confirm-wrapper {
    flex: 1;
  }
`;

export const QuickAccessItemCardStyled = styled.li`
  border: 1px solid #cfd2d8;
  border-radius: ${borderRadius};
  margin: 0 8px 10px;
  box-shadow: ${boxShadow};
  color: ${colorGrayLight};
  overflow: hidden;
  position: relative;

  min-width: 230px;

  .icon-wrapper {
    position: absolute;
    top: 8px;
    right: 8px;
    text-align: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 0 12px 0 0;
    background: ${p => (p.confirmed ? colorSuccess : colorDanger)};
    span {
      color: white;
      font-size: 24px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
  }

  .top-date {
    text-transform: uppercase;
    font-size: 10px;
  }

  .title {
    margin-top: 8px;
    font-size: 14px;

    color: ${colorGrayDarker};
  }

  .subtitle {
    margin-top: 5px;
    font-size: 14px;
  }

  .infos {
    margin-top: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-action {
    margin-top: 8px;
    background: ${colorGrayUltraLight};
    color: ${colorHighlight};
    padding: 12px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    border-top: 1px solid ${colorGrayLightest};
  }
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 16px;
  scroll-behavior: smooth;
`;

export const QuickActionScroll = styled.div`
  display: flex;
  align-items: center;
  .action {
    border-radius: 50%;
    background: ${colorGrayUltraLight};
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }
  .quick-action {
    color: ${colorGrayLighter};
    font-size: 26px;
    cursor: pointer;
  }
`;
