import Vendor from 'containers/AgroBRF/FlockList/Vendor';
import Vendors from 'containers/NextBRF/Vendors';
import VendorsTable from 'containers/NextBRF/VendorsTable';
import OperationalSchedule from 'containers/NextBRF/OperationalSchedule';
import NextFlock from 'containers/NextBRF/NextFlock';
import NextFlockDetails from 'containers/NextBRF/NextFlock/NextFlockDetails';
import NextOpenRecord from 'containers/NextBRF/NextFlock/NextRecord/NextOpenRecord';
import NextRecord from 'containers/NextBRF/NextFlock/NextRecord/NextRecord';
import NextSwineRecord from 'containers/NextBRF/NextSwineRecord';
import NextFlockLabelInfo from 'containers/NextBRF/NextFlock/NextFlockLabelInfo';
import LogBook from 'containers/NextBRF/LogBook';
import VendorSchedule from 'containers/NextBRF/VendorSchedule';
import NextOrientationScreen from 'containers/NextBRF/Orientation';
import WeekSchedule from 'containers/NextBRF/WeekSchedule';
import Settings from 'containers/NextBRF/WeekSchedule/Settings';
import NewVisit from 'containers/NextBRF/WeekSchedule/NewVisit';
import UserManagement from 'containers/NextBRF/UserManagement';
import ChecklistsDetails from 'containers/NextBRF/Checklists/ChecklistDetails';
import ChecklistFill from 'containers/NextBRF/Checklists/ChecklistFill';
import ChecklistHistory from 'containers/NextBRF/Checklists/ChecklistHistory';
import Documentation from 'containers/NextBRF/Documentation';
import { NextMedication } from 'containers/NextBRF/NextMedication';
import { Financials } from 'containers/NextBRF/Financials';
import { InvoiceDetails as FinancialInvoiceDetails } from 'containers/NextBRF/Financials';
import Indicators from 'containers/NextBRF/Indicators';

import Faq from 'containers/SharedContainers/Faq';
import Library from 'containers/SharedContainers/Library';

import { OpenVisit, VisitList } from 'containers/NextBRF/VisitList';
import OpenedVisitCircuit from 'containers/NextBRF/VisitCircuit/OpenedVisitCircuit';
import VisitCircuitList from 'containers/NextBRF/VisitCircuit/VisitCircuitList';
import InvoiceDetails from 'components/Invoice/InvoiceDetails';
import Event from 'containers/NextBRF/Event/Event';

export default function nextRouteList(user) {
  const routes = [
    {
      route: 'VENDOR',
      path: '/:id',
      component: Vendor,
      id: 10,
    },
    {
      route: 'VENDORS',
      path: false,
      component: Vendors,
      id: 16,
    },
    {
      route: 'INDICATORS',
      path: '/:type',
      component: Indicators,
      id: 20,
    },
    {
      route: 'INVOICE',
      path: '/:id',
      component: InvoiceDetails,
      id: 33,
    },
    {
      route: 'VENDORS_TABLE',
      path: false,
      component: VendorsTable,
      id: 10,
    },
    {
      route: 'REPORTS',
      component: OperationalSchedule,
      id: 16,
    },
    {
      route: 'NEXT_FLOCK_LIST',
      path: '/:id/:new?',
      component: NextFlock,
      id: 14,
    },
    {
      route: 'NEXT_FLOCK_DETAILS',
      path: '/:id',
      component: NextFlockDetails,
      id: 14,
    },
    {
      route: 'NEXT_FLOCK_LABEL',
      path: '/:id',
      component: NextFlockLabelInfo,
      id: 14,
    },
    {
      route: 'NEXT_OPEN_RECORD',
      path: '/:id/:date?',
      component: NextOpenRecord,
      id: 12,
    },
    {
      route: 'NEXT_HOUSE_LIST',
      path: '/:id',
      component: NextRecord,
      id: 12,
    },
    {
      route: 'NEXT_SWINE_RECORD',
      path: '/:id',
      component: NextSwineRecord,
      id: 12,
    },
    {
      route: 'LOGBOOK',
      path: '/:id?',
      component: LogBook,
      id: 19,
    },
    {
      route: 'LIBRARY',
      path: '/:type?/:category/:subcategory',
      component: user.type !== 'O' && Library,
      id: 3,
    },
    {
      route: 'VISIT_LIST',
      path: '/:id',
      component: VisitList,
      id: 19,
    },
    {
      route: 'OPEN_VISIT',
      path: '/:id',
      component: OpenVisit,
      id: 19,
    },
    {
      route: 'VISIT_CIRCUIT',
      path: '/:id',
      component: OpenedVisitCircuit,
    },
    {
      route: 'VISIT_CIRCUIT_LIST',
      path: '/:id',
      component: VisitCircuitList,
    },
    {
      route: 'NEXT_ORIENTATION',
      path: '/:id/:flockId?',
      component: NextOrientationScreen,
      id: 17,
    },
    {
      route: 'SCHEDULE',
      path: '/:id?/:vendor?/',
      component: VendorSchedule,
      id: 16,
    },
    {
      route: 'SCHEDULE_SEARCH',
      path: '/:type/:vendorCode',
      component: VendorSchedule,
      id: 16,
    },
    {
      route: 'WEEK_SCHEDULE',
      component: WeekSchedule,
      id: 1,
    },
    {
      route: 'WEEK_SCHEDULE_SETTINGS',
      component: Settings,
      id: 1,
    },
    {
      route: 'NEW_VISIT',
      component: NewVisit,
      id: 1,
    },
    {
      route: 'NEXTFAQ',
      component: Faq,
      id: 6,
    },
    {
      route: 'CHECKLIST_DETAILS',
      component: ChecklistsDetails,
      id: 5,
    },
    {
      route: 'FILL_CHECKLIST',
      component: ChecklistFill,
      id: 5,
    },
    {
      route: 'NEW_EVENT',
      component: Event,
      id: 23,
    },
    {
      route: 'CHECKLIST_HISTORY',
      component: ChecklistHistory,
      id: 5,
    },
    {
      route: 'USER_MANAGEMENT',
      path: '/:type?/:id?',
      component: UserManagement,
      id: 20,
    },
    {
      route: 'DOCUMENTATION',
      path: '/:type?',
      component: Documentation,
      id: 20,
    },
    {
      route: 'NEXT_MEDICATION',
      path: '/:id?/:type?/:typeId?',
      component: NextMedication,
      id: 20, // @TODO medication id
    },
    {
      route: 'DASHBOARDS',
      path: '/:type?',
      component: NextMedication,
      id: 20, // @TODO dashboard id
    },
    {
      route: 'FINANCIALS',
      path: '/',
      component: Financials,
      id: 24,
    },
    {
      route: 'FINANCIALS_DETAILS',
      path: '/:id',
      component: FinancialInvoiceDetails,
      id: 24,
    },
  ];

  return routes;
}
