/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
// context
import { Button } from '@zendeskgarden/react-buttons';
import { Field } from '@zendeskgarden/react-forms';
import { useSelector } from 'react-redux';
import { Row, Col } from '@zendeskgarden/react-grid';
import LoadingHolder from 'components/LoadingHolder';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import PromptModal from 'components/PromptModal';
import { getAnimalIcon } from 'shared/utils/Helpers';
import { FieldArray } from 'formik';
import {
  Form,
  LabelStyled,
  ToggleStyled,
  AddItem,
} from 'components/Forms/Forms.styled';
import {
  InputField,
  DropdownField,
  DropdownFieldFromArray,
  AutoCompleteDropdownField,
  TimePickerField,
  FloatInputField,
} from 'components/Forms/Fields';
import FormAccordion from 'components/FormAccordion';
import { Separator } from 'components/ResumeCard/ResumeCard.styled';
import t from 'components/i18n';

export default function FalForm(props) {
  const [maxH, setMaxH] = useState(600);
  const {
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    feedTypes,
    isLoading,
    isFirstDay,
    showFlockClosure,
    resetForm,
    getFormVendors,
    vendors,
    loadingVendors,
    values,
    errors,
    touched,
    dirty,
  } = props;

  const { isMobile } = props.responsive;

  const user = useSelector(state => state.user);
  const animalIcon = useMemo(() => getAnimalIcon(user.animalType), [
    user,
  ]);

  const checkFoodConsume = useCallback((val, err) => {
    const checkFeedType =
      val.initialFeedType &&
      val.initialFeedTime &&
      !err.initialFeedType &&
      !err.initialFeedTime;

    const checkMovimentation =
      val.movimentationType &&
      val.movimentationFeedType &&
      val.movimentationFeedValue &&
      val.movimentationVendorInformation &&
      !err.movimentationType &&
      !err.movimentationFeedType &&
      !err.movimentationFeedValue &&
      !err.movimentationVendorInformation;

    if (val.isInitialFeed) {
      if (val.hasMovimentation) {
        return checkFeedType && checkMovimentation;
      }
      return checkFeedType;
    }

    if (val.hasMovimentation) {
      if (val.isInitialFeed) {
        return checkFeedType && checkMovimentation;
      }

      return checkMovimentation;
    }

    return false;
  }, []);

  useEffect(() => {
    if (isMobile) setMaxH(1000);

    if (values.waterConsumption === null) {
      setFieldValue('waterConsumption', 0);
    }
  }, []);

  const checkMortalityField = useCallback((val, err) => {
    if (isFirstDay) {
      return (
        (val.mortalityOnArrival || val.mortalityOnArrival === '0') &&
        !err.mortalityOnArrival
      );
    }

    return (
      val.naturalDeath &&
      val.refuseElimination &&
      val.legElimination &&
      val.otherElimination &&
      !err.naturalDeath &&
      !err.refuseElimination &&
      !err.legElimination &&
      !err.otherElimination &&
      !err.mortalityOnArrival
    );
  }, []);

  return (
    <>
      <PromptModal
        opened={false}
        history={props.history}
        when={dirty}
        confirmLabel={t('form.next.falForm.quit.confirmation')}
        denyLabel={t('global.button.cancel')}
        title={t('form.next.falForm.quit.title')}
        message={t('form.next.falForm.quit.description')}
      />
      {isLoading ? (
        <LoadingHolder
          isLoading={isLoading}
          message={t('global.loading')}
        />
      ) : (
        <>
          {/* Primeira tela */}
          <Form autoComplete="off">
            {/** Consumo de água */}
            <FormAccordion
              icon="icon-drop-rain"
              sectionTitle={t('form.next.falForm.section.waterConsumption.title')}
              hideSeparator={!isFirstDay}
              isChecked={
                values.waterConsumption &&
                values.waterTime &&
                !errors.waterConsumption &&
                !errors.waterTime
              }
              opened={!isFirstDay}
              hide={isFirstDay}
              isRequired
            >
              <Row>
                <Col sm={6}>
                  <Field>
                    <InputField
                      masked
                      type="dot"
                      field="waterConsumption"
                      label={t('form.next.falForm.field.waterConsumption.label')}
                      placeholder={t('form.next.falForm.field.waterConsumption.placeholder')}
                      setState={e =>
                        setFieldValue('waterConsumption', e)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      max={999999}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={6}>
                  <Field>
                    <TimePickerField
                      type="time"
                      field="waterTime"
                      label={t('form.next.falForm.field.waterTime.label')}
                      onChange={e => setFieldValue('waterTime', e)}
                      onBlur={handleBlur}
                      dateFormat="HH:mm"
                      timeCaption={t("form.next.falForm.field.waterTime.placeholder")}
                      timeIntervals={15}
                      disabled={false}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
              </Row>
            </FormAccordion>
            {/** \ Consumo de água */}

            {/** Temperatura */}
            <FormAccordion
              icon="icon-temperature"
              sectionTitle={t('form.next.falForm.section.temperature.title')}
              isChecked={
                values.minTemperature &&
                values.maxTemperature &&
                !errors.minTemperature &&
                !errors.maxTemperature
              }
              hide={isFirstDay}
              isRequired
            >
              <Row>
                <Col sm={6}>
                  <Field>
                    <InputField
                      masked
                      type="temperature"
                      field="minTemperature"
                      label={t('form.next.falForm.field.min-temperature.label')}
                      placeholder={t('form.next.falForm.field.min-temperature.placeholder')}
                      onChange={handleChange}
                      setState={e =>
                        setFieldValue('minTemperature', e)
                      }
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      max={100}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={6}>
                  <Field>
                    <InputField
                      masked
                      type="temperature"
                      field="maxTemperature"
                      label={t('form.next.falForm.field.max-temperature.label')}
                      placeholder={t('form.next.falForm.field.max-temperature.placeholder')}
                      onChange={handleChange}
                      setState={e =>
                        setFieldValue('maxTemperature', e)
                      }
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      max={100}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
              </Row>
            </FormAccordion>
            {/** \ Temperatura */}

            {/** Umidade relativa */}
            <FormAccordion
              icon="icon-drop"
              sectionTitle={t('form.next.falForm.section.humidity.title')}
              isChecked={
                values.minHumidity &&
                values.maxHumidity &&
                !errors.minHumidity &&
                !errors.maxHumidity
              }
              hide={isFirstDay}
              isRequired
            >
              <Row>
                <Col sm={6}>
                  <Field>
                    <InputField
                      type="number"
                      field="minHumidity"
                      label={t('form.next.falForm.field.min-humidity.label')}
                      placeholder={t('form.next.falForm.field.min-humidity.placeholder')}
                      onChange={handleChange}
                      setState={e => setFieldValue('minHumidity', e)}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={6}>
                  <Field>
                    <InputField
                      type="number"
                      field="maxHumidity"
                      label={t("form.next.falForm.field.max-humidity.label")}
                      placeholder={t("form.next.falForm.field.max-humidity.placeholder")}
                      setState={e => setFieldValue('maxHumidity', e)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
              </Row>
            </FormAccordion>
            {/** \ Umidade relativa */}

            {/** Cloro e pH da água */}
            <FormAccordion
              icon="icon-water"
              sectionTitle={t('form.next.falForm.section.ph-chlorine.title')}
              isChecked={
                values.chlorine &&
                values.ph &&
                !errors.chlorine &&
                !errors.ph
              }
              hide={isFirstDay}
              isRequired={values.isRequiredPh}
              // hide={!isRequiredPh}
            >
              <Row>
                <Col sm={6}>
                  <Field>
                    <InputField
                      masked
                      type="dot"
                      size={5}
                      field="chlorine"
                      label={t('form.next.falForm.field.chlorine.label')}
                      placeholder={t('form.next.falForm.field.chlorine.placeholder')}
                      setState={e => setFieldValue('chlorine', e)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={6}>
                  <Field>
                    <InputField
                      masked
                      type="temperature"
                      field="ph"
                      label={t('form.next.falForm.field.ph.label')}
                      placeholder={t('form.next.falForm.field.ph.placeholder')}
                      setState={e => setFieldValue('ph', e)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      max={14}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
              </Row>
            </FormAccordion>
            {/** \ Cloro e pH da água */}

            {/** Mortalidade */}
            <FormAccordion
              icon={`${animalIcon}-alt`}
              sectionTitle={t('form.next.falForm.section.mortality.title')}
              isChecked={checkMortalityField(values, errors)}
              maxHeight={maxH}
              isRequired
            >
              {isFirstDay && (
                <Row>
                  <Col sm={12}>
                    <Field>
                      <InputField
                        masked
                        type="dot"
                        size={5}
                        field="mortalityOnArrival"
                        label={t("form.next.falForm.field.mortalityOnArrival.label")}
                        placeholder={t("form.next.falForm.field.mortalityOnArrival.placeholder")}
                        setState={e =>
                          setFieldValue('mortalityOnArrival', e)
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={false}
                        min={0}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    </Field>
                  </Col>
                </Row>
              )}

              {!isFirstDay && (
                <Row>
                  <Col sm={6}>
                    <Field>
                      <InputField
                        masked
                        type="dot"
                        size={5}
                        field="naturalDeath"
                        label={t('form.next.falForm.field.naturalDeath.label')}
                        placeholder={t('form.next.falForm.field.naturalDeath.placeholder')}
                        setState={e =>
                          setFieldValue('naturalDeath', e)
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={false}
                        min={0}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    </Field>
                  </Col>
                  <Col sm={6}>
                    <Field>
                      <InputField
                        masked
                        type="dot"
                        size={5}
                        field="refuseElimination"
                        label={t('form.next.falForm.field.refuseElimination.label')}
                        placeholder={t('form.next.falForm.field.refuseElimination.placeholder')}
                        setState={e =>
                          setFieldValue('refuseElimination', e)
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={false}
                        min={0}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    </Field>
                  </Col>
                </Row>
              )}

              {!isFirstDay && (
                <Row>
                  <Col sm={6}>
                    <Field>
                      <InputField
                        masked
                        type="dot"
                        size={5}
                        field="legElimination"
                        label={t('form.next.falForm.field.legElimination.label')}
                        placeholder={t('form.next.falForm.field.legElimination.placeholder')}
                        setState={e =>
                          setFieldValue('legElimination', e)
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={false}
                        min={0}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    </Field>
                  </Col>
                  <Col sm={6}>
                    <Field>
                      <InputField
                        masked
                        type="dot"
                        size={5}
                        field="otherElimination"
                        label={t('form.next.falForm.field.otherElimination.label')}
                        placeholder={t('form.next.falForm.field.otherElimination.placeholder')}
                        onChange={handleChange}
                        setState={e =>
                          setFieldValue('otherElimination', e)
                        }
                        onBlur={handleBlur}
                        disabled={false}
                        min={0}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    </Field>
                  </Col>
                </Row>
              )}
            </FormAccordion>
            {/** \ Mortalidade */}

            {/** Peso médio */}
            <FormAccordion
              icon="icon-scale"
              sectionTitle={t("form.next.falForm.section.weightValue.title")}
              isChecked={
                values.weightValue &&
                values.weightTime &&
                !errors.weightValue &&
                !errors.weightTime
              }
              hide={isFirstDay}
              isRequired={values.isRequiredWeight}
            >
              <Row>
                <Col sm={6}>
                  <Field>
                    <FloatInputField
                      field="weightValue"
                      label={t('form.next.falForm.field.weightValue.label')}
                      placeholder={t('form.next.falForm.field.weightValue.placeholder')}
                      setState={e => setFieldValue('weightValue', e)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      max={999999}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={6}>
                  <Field>
                    <TimePickerField
                      type="time"
                      field="weightTime"
                      label={t('form.next.falForm.field.weightTime.label')}
                      timeCaption={t('form.next.falForm.field.weightTime.placeholder')}
                      onChange={e => setFieldValue('weightTime', e)}
                      onBlur={handleBlur}
                      dateFormat="HH:mm"
                      timeIntervals={15}
                      disabled={false}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
              </Row>
            </FormAccordion>
            {/** \ Peso médio */}

            {/** Consumo de ração */}
            <FormAccordion
              icon="icon-corn"
              sectionTitle={t('form.next.falForm.section.feed.title')}
              isChecked={checkFoodConsume(values, errors)}
              maxHeight={maxH}
              isRequired={
                values.isInitialFeed || values.hasMovimentation
              }
            >
              <Row>
                <Col sm={12}>
                  <Field>
                    <ToggleStyled
                      checked={values.isInitialFeed}
                      onChange={e => {
                        setFieldValue(
                          'isInitialFeed',
                          e.target.checked,
                        );
                        setFieldValue('initialFeedType', '');
                        setFieldValue('initialFeedTime', '');
                      }}
                    >
                      {!isFirstDay && (
                        <LabelStyled regular={false}>
                          {t('form.next.falForm.field.isInitialFeed.label')}
                        </LabelStyled>
                      )}
                    </ToggleStyled>
                  </Field>
                </Col>
              </Row>

              <Separator />

              <Row>
                <Col sm={6}>
                  <Field>
                    <DropdownField
                      selectedItem={values.initialFeedType}
                      field="initialFeedType"
                      label={t("form.next.falForm.field.initialFeedType.label")}
                      placeholder={t("form.next.falForm.field.initialFeedType.placeholder")}
                      onSelect={item => {
                        setFieldValue('initialFeedType', item);
                      }}
                      options={feedTypes}
                      optionLabel="description"
                      disabled={
                        isFirstDay ? false : !values.isInitialFeed
                      }
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={6}>
                  <Field>
                    <TimePickerField
                      type="time"
                      field="initialFeedTime"
                      label={t('form.next.falForm.field.initialFeedTime.label')}
                      timeCaption={t('form.next.falForm.field.initialFeedTime.placeholder')}
                      onChange={e =>
                        setFieldValue('initialFeedTime', e)
                      }
                      onBlur={handleBlur}
                      dateFormat="HH:mm"
                      timeIntervals={15}
                      disabled={
                        isFirstDay ? false : !values.isInitialFeed
                      }
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
              </Row>

              <Separator />

              <Row>
                <Col sm={12}>
                  <Field>
                    <ToggleStyled
                      checked={values.hasMovimentation}
                      onChange={e => {
                        setFieldValue(
                          'hasMovimentation',
                          e.target.checked,
                        );
                        setFieldValue('movimentationType', '');
                        setFieldValue('movimentationFeedType', '');
                        setFieldValue('movimentationFeedValue', '');
                        setFieldValue(
                          'movimentationVendorInformation',
                          '',
                        );
                      }}
                    >
                      <LabelStyled regular={false}>
                        {t('form.next.falForm.field.hasMovimentation.label')}
                      </LabelStyled>
                    </ToggleStyled>
                  </Field>
                </Col>
              </Row>

              <Separator />

              <Row>
                <Col sm={4}>
                  <Field>
                    <DropdownField
                      field="movimentationType"
                      label={t('form.next.falForm.field.movimentationType.label')}
                      placeholder={t('form.next.falForm.field.movimentationType.placeholder')}
                      onSelect={e => {
                        setFieldValue('movimentationType', e);
                      }}
                      options={['Transferência', 'Recebimento']}
                      disabled={!values.hasMovimentation}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={8}>
                  <Field>
                    <DropdownField
                      field="movimentationFeedType"
                      label={t('form.next.falForm.field.movimentationFeedType.label')}
                      placeholder={t('form.next.falForm.field.movimentationFeedType.placeholder')}
                      onSelect={e => {
                        setFieldValue('movimentationFeedType', e);
                        setFieldValue(
                          'movimentationFeedMaxAllowed',
                          e.maximumAllowed,
                        );
                      }}
                      disabled={!values.hasMovimentation}
                      options={feedTypes}
                      optionLabel="description"
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
              </Row>

              <Row>
                <Col sm={4}>
                  <Field>
                    <InputField
                      type="number"
                      field="movimentationFeedValue"
                      label={t('form.next.falForm.field.movimentationFeedValue.label')}
                      placeholder={t('form.next.falForm.field.movimentationFeedValue.placeholder')}
                      setState={e =>
                        setFieldValue('movimentationFeedValue', e)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!values.hasMovimentation}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={8}>
                  <Field>
                    <AutoCompleteDropdownField
                      field="movimentationVendorInformation"
                      label={t("form.next.falForm.field.movimentationVendorInformation.label")}
                      placeholder={t("form.next.falForm.field.movimentationVendorInformation.placeholder")}
                      onSelect={e => {
                        setFieldValue(
                          'movimentationVendorInformation',
                          e,
                        );
                      }}
                      disabled={!values.hasMovimentation}
                      loadingLabel={t('global.loading')}
                      optionLabel="vendorName"
                      caption="vendorCode"
                      noMatchLabel={t("form.next.falForm.field.movimentationVendorInformation.no-match")}
                      search={getFormVendors}
                      loading={loadingVendors}
                      options={vendors}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
              </Row>
            </FormAccordion>
            {/** \ Consumo de ração */}

            {/** Fechamento do lote */}
            <FormAccordion
              icon="icon-lock-enabled"
              sectionTitle={t('form.next.falForm.section.closing.title')}
              isChecked={
                values.waterConsume &&
                values.selfConsume &&
                values.feedType &&
                values.feedQuantity &&
                !errors.waterConsume &&
                !errors.selfConsume &&
                !errors.feedType &&
                !errors.feedQuantity
              }
              maxHeight={maxH}
              hide={!showFlockClosure}
            >
              <Row>
                <Col sm={12}>
                  <Field>
                    <ToggleStyled
                      checked={values.isFlockClosure}
                      onChange={e =>
                        setFieldValue(
                          'isFlockClosure',
                          e.target.checked,
                        )
                      }
                    >
                      <LabelStyled regular={false}>
                        {t('form.next.falForm.field.isFlockClosure')}
                      </LabelStyled>
                    </ToggleStyled>
                  </Field>
                </Col>
              </Row>

              <Separator />

              <Row>
                <Col sm={6}>
                  <Field>
                    <InputField
                      masked
                      type="dot"
                      field="waterConsume"
                      label={t('form.next.falForm.field.waterConsume.label')}
                      placeholder={t('form.next.falForm.field.waterConsume.placeholder')}
                      setState={e => setFieldValue('waterConsume', e)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!values.isFlockClosure}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={6}>
                  <Field>
                    <InputField
                      type="number"
                      field="selfConsume"
                      label={t("form.next.falForm.field.selfConsume.label")}
                      placeholder={t("form.next.falForm.field.selfConsume.placeholder")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!values.isFlockClosure}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
              </Row>

              <Separator />

              {/** Sobra de ração */}
              <Row>
                <Col sm={12}>
                  <Field>
                    <ToggleStyled
                      checked={values.hasFeedRemnant}
                      onChange={e => {
                        setFieldValue(
                          'hasFeedRemnant',
                          e.target.checked,
                        );
                        setFieldValue('feedRemnant', [
                          {
                            feedType: null,
                            feedQuantity: null,
                          },
                        ]);
                      }}
                    >
                      <LabelStyled regular={false}>
                        {t('form.next.falForm.field.hasFeedRemnant')}
                      </LabelStyled>
                    </ToggleStyled>
                  </Field>
                </Col>
              </Row>

              <Field>
                <FieldArray
                  name="feedRemnant"
                  render={arrayHelpers =>
                    values.feedRemnant.map((item, index) => (
                      <>
                        {index > 0 && values.feedRemnant[index] && (
                          <div>
                            <AddItem
                              onClick={() => {
                                arrayHelpers.remove(index);
                                setMaxH(maxH - 152);
                              }}
                            >
                              <span className="icon icon-minus" />{' '}
                              {t('form.next.falForm.field.remove-feedRemnant')}
                            </AddItem>
                          </div>
                        )}

                        <Row index={index}>
                          <Col sm={6}>
                            <Field>
                              <DropdownFieldFromArray
                                field={`feedRemnant.${index}.feedType`}
                                label={t('form.next.falForm.field.feedType.label')}
                                placeholder={t('form.next.falForm.field.feedType.placeholder')}
                                fieldString="feedType"
                                from="feedRemnant"
                                index={index}
                                onSelect={e => {
                                  setFieldValue(
                                    `feedRemnant.${index}.feedType`,
                                    e,
                                  );
                                  setFieldValue(
                                    `feedRemnant.${index}.feedRemnantMaxAllowed`,
                                    e.maximumAllowed,
                                  );
                                }}
                                options={feedTypes}
                                optionLabel="description"
                                disabledItems={values.feedRemnant.map(
                                  e => e.feedType,
                                )}
                                disabled={!values.hasFeedRemnant}
                                values={values}
                                errors={errors}
                                touched={touched}
                              />
                            </Field>
                          </Col>
                          <Col sm={6}>
                            <Field>
                              <InputField
                                type="number"
                                field={`feedRemnant.${index}.feedQuantity`}
                                label={t('form.next.falForm.field.feedRemnant.label')}
                                placeholder={t("form.next.falForm.field.feedRemnant.placeholder")}
                                from="feedRemnant"
                                index={index}
                                fieldString="feedQuantity"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!values.hasFeedRemnant}
                                min={0}
                                values={values}
                                errors={errors}
                                touched={touched}
                              />
                            </Field>
                          </Col>
                        </Row>

                        {values.feedRemnant[index].feedType &&
                          values.feedRemnant[index].feedQuantity &&
                          !values.feedRemnant[index + 1] &&
                          values.feedRemnant.length !==
                            feedTypes.length && (
                          <div>
                            <AddItem
                              onClick={() => {
                                arrayHelpers.push({
                                  feedType: '',
                                    feedQuantity: '',
                                  });
                                setMaxH(maxH + 182); // section height
                                }}
                            >
                              <span className="icon icon-plus" />{' '}
                                {t('form.next.falForm.field.add-feedRemnant')}
                            </AddItem>
                            </div>
                        )}
                      </>
                    ))
                  }
                />
              </Field>
              {/** \ Sobra de ração */}
            </FormAccordion>
            {/** \ Fechamento do lote */}

            <Separator gap={8} />

            <br />

            <Row>
              <Col sm={6} />
              <Col sm={6}>
                {/* \ Botões */}
                <BtnGroup>
                  <Button
                    className="tertiary"
                    key="submitForm"
                    onClick={e => handleSubmit(e)}
                    disabled={!props.isValid || props.isValidating}
                  >
                    {t("global.button.send")}
                  </Button>
                  <Button
                    key="clean"
                    className="white"
                    onClick={() => {
                      resetForm();
                    }}
                  >
                    {t("global.button.clear")}
                  </Button>
                </BtnGroup>
                {/* \ Botões */}
              </Col>
            </Row>
          </Form>

          {/* \ Primeira tela */}
        </>
      )}
    </>
  );
}
