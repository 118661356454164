import React from 'react';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import { TextAreaField } from 'components/Forms/Fields';
import LoadingHolder from 'components/LoadingHolder';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';
import { colorTertiary } from 'components/GlobalStyles/variables';
import t from 'components/i18n';

export default function SendJustificationForm(props) {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    setFieldValue,
    isLoading,
    values,
    errors,
    touched,
  } = props;

  return (
    <>
      <LoadingHolder
        message={t('global.loading')}
        isLoading={isLoading}
      />
      {!isLoading && (
        <Form autoComplete="off">
          <Row>
            <Col md={12}>
              {/* Justificativa */}
              <TextAreaField
                field="justification"
                setState={e => {
                  setFieldValue('justification', e);
                }}
                placeholder={t('form.next.visit.justification.field.justification.placeholder')}
                onBlur={e => handleBlur(e)}
                onChange={e => handleChange(e)}
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Justificativa */}
            </Col>
          </Row>

          <Separator />

          {/* \ Botões */}
          <BtnGroup color={colorTertiary}>
            <Button
              key="sendtoConfirm"
              onClick={() => {
                handleSubmit();
              }}
              disabled={!props.isValid || props.isUploading}
            >
              {t("global.button.save")}
            </Button>
            <Button
              key="clean"
              onClick={() => resetForm()}
              className="white"
            >
              {t('global.button.cancel')}
            </Button>
          </BtnGroup>
          {/* \ Botões */}
        </Form>
      )}
    </>
  );
}
