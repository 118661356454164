import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

const updateLastAccess = async () => {
  const response = await request(`${API_URL}/User/UpdateLastAccess`, {
    method: 'PUT',
  });

  return response.data;
};

export {
  updateLastAccess
}
