// @flow

import React from 'react';
import Hamburger from 'components/Hamburger';
import Logo from 'components/Logo';
import NotificationArea from './NotificationArea';

import { HeaderContainer, HeaderWrapper } from './Header.styled';

import type { THeaderProps } from './typings';
import LanguageSwitch from 'components/LanguageSwitch/LanguageSwitch';

export default function Header(props: THeaderProps) {
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Hamburger
          menuIsOpen={props.menuIsOpen}
          onClick={() => props.switchMenu(!props.menuIsOpen)}
        />

        {/* <div className="header-content"> */}
        <div className="logo-holder visible-phone">
          <Logo name="agro" variant="coloured" />
        </div>

        {/* <div className="search hidden-phone">
          <Input mediaLayout>
            <MediaFigure>
              <SearchIcon className="icon-search" />
            </MediaFigure>
            <MediaInput
              aria-label="Buscar no site"
              placeholder="Buscar no site"
            />
          </Input>
        </div> */}
        {/* </div> */}

        <NotificationArea
          responsive={props.responsive}
          history={props.history}
        />
        <LanguageSwitch />
      </HeaderContainer>
    </HeaderWrapper>
  );
}
