import React, { useContext } from 'react';
import moment from 'moment';
import t from 'components/i18n';
import { Row, Col } from '@zendeskgarden/react-grid';
import Badge from 'components/Badge';
import FooterMenu from 'components/FooterMenu';

// Contexts
import { SwineContext } from 'shared/contexts';
// Styles
import {
  Container,
  ItemContainer,
  FeedItem,
} from './FlockHistory.styled';

export default function DeceasedSummaryItem(props) {
  const { feed, goTo, exclude } = props;
  const swineDeceasedContext = useContext(SwineContext);

  const {
    setSelectedDeceased,
    setSelectedHouse,
  } = swineDeceasedContext.actions;

  const badgeProps = {};

  function selectItem(option) {
    setSelectedDeceased(feed);
    setSelectedHouse(feed.house);
    goTo(option);
  }

  function getOptions() {
    const options = [
      {
        action: () => selectItem('deceasedConfirmation'),
        icon: 'visibility-enabled',
        label: t('global.button.details'),
        visible: true,
      },
      {
        action: () => selectItem('editDeceased'),
        icon: 'edit',
        label: t('global.button.edit'),
        visible: feed.isEditable,
      },
      {
        action: () => {
          exclude();
          setSelectedDeceased(feed);
        },
        icon: 'trash',
        label: t('global.button.delete'),
        visible: true,
      },
    ];
    return options;
  }

  if (feed.status) {
    switch (feed.status.toLowerCase()) {
      case 'pendente':
        badgeProps.info = true;
        break;
      default:
        badgeProps.success = true;
    }
  }

  function getTotal(gender) {
    const total = feed.swineDeceasedDiscarded.reduce(
      (ac, current) => ac + current[gender],
      0,
    );
    return total;
  }

  function listOrigins() {
    return feed.swineDeceasedDiscardedResume.origin;
  }

  function findType(type) {
    return feed.swineDeceasedDiscarded.find(e => e[type] !== null);
  }

  function checkMortalityType() {
    if (findType('mortalityTypeId') && findType('discardedTypeId')) {
      return `${t('deceased.summary.mortality')} • ${t(
        'deceased.summary.discard',
      )}`;
    }
    if (findType('mortalityTypeId')) {
      return `${t('deceased.summary.mortality')}`;
    }

    if (findType('discardedTypeId')) {
      return `${t('deceased.summary.discard')}`;
    }

    return false;
  }

  function getStatusMessage(status) {
    if (status.toLowerCase() === 'concluído') {
      return t('global.status.done');
    }

    if (status.toLowerCase() === 'pendente') {
      return t('global.status.pending');
    }

    return status;
  }

  return (
    <Container className="bordered-container">
      <ItemContainer
        className="item-container"
        onClick={() => selectItem('deceasedConfirmation')}
      >
        <div className="open summary">
          <FeedItem key={`feed-item-${feed.id}`} swine>
            <header className="item-header">
              <div className="date">
                {moment(feed.deceasedDiscardedDate).format('DD ̸̸  MM ̸̸  YYYY')}
              </div>
              <div className="status">
                {feed.status && (
                  <Badge {...badgeProps}>
                    {getStatusMessage(feed.status)}
                  </Badge>
                )}
              </div>
            </header>
            <Row>
              <Col>
                <strong>
                  {`${getTotal('maleQuantity') +
                    getTotal('femaleQuantity')} ${t(
                    'global.unit.head',
                  )}`}
                </strong>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>{checkMortalityType()}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <span className="strong">
                    ${t('deceased.summary.tattoo')}:{' '}
                  </span>{' '}
                  {listOrigins()}
                </p>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <p>
                  {' '}
                  {`${t('deceased.summary.house', {
                    value: feed.house,
                  })}`}
                </p>
              </Col>
            </Row>
          </FeedItem>
        </div>
      </ItemContainer>
      {!props.hideOptions && (
        <FooterMenu
          placement="start-bottom"
          options={getOptions(feed.isEditable)}
        />
      )}
    </Container>
  );
}
