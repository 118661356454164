import React from 'react';
import { Close } from 'components/MultiSelect/Items.styled';
import { ImageWrapper } from './Image.styled';
import { KONG_CLIENT_ID } from 'config/constants';

export default function Image({
  api,
  item = '',
  blobContainer,
  token,
  showRemoveFile = true,
  removeFile = false,
}) {
  const isObject = typeof item === 'object';
  const search = ' ';
  const replaceWith = '%20';

  const fileNameUrl = isObject ? item.name : item;
  const fileNameLessSpace = fileNameUrl.replaceAll(search, replaceWith);

  const backgroundImage = `${api}/Blob/Download?fileName=${fileNameLessSpace}&blobContainerName=${blobContainer}&token=${token}&client_id=${KONG_CLIENT_ID}`;

  return (
    <ImageWrapper>
      <img 
        className="animated fadeIn"
        src={isObject ? URL.createObjectURL(item) : backgroundImage}
      />
      {showRemoveFile && (
        <Close className="big" onClick={() => removeFile()} isImage>
          {' '}
          <span className="icon icon-close" />
        </Close>
      )}
    </ImageWrapper>
  );
}
