import React from 'react';
import t from 'components/i18n';
import Resume from 'components/Payment/Resume';
import SidebarRight, {
  SidebarRightContent,
  SidebarToggler,
} from 'components/SidebarRight';
import { Menu, TabletMenu } from './Flock.styled';
import { useEffect } from 'react';
import { getFlockInvoice } from '../service';
import { useState } from 'react';

export default function FlockMenu(props) {
  const {
    flockId,
    getHeading,
    getTabletToggler,
    getToggler,
    isTablet,
    isMobile,
    isGtMobile,
    selectedKey,
    getFlockDetails,
    type,
    data,
    isResume,
  } = props;

  // show closure option only if there is partial remuneration
  const showClosureOption = data && data.hasPartialRemuneration;

  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    if (!data) {
      return;
    }

    async function fetch() {
      const invoice = await getFlockInvoice(data.flock);
      setInvoice(invoice);
    }

    fetch();
  }, [data]);

  return (
    <div className="flock-menu">
      {isTablet && !isResume && (
        <TabletMenu>
          {type === 'closed' && getTabletToggler('payment')}
          {type === 'closed' && getTabletToggler('closure')}
          {getTabletToggler('next')}
          {getTabletToggler('feed')}
          {getTabletToggler('placement')}
          {getTabletToggler('slaughter')}
        </TabletMenu>
      )}

      {!isTablet && (
        <>
          {type === 'closed' && isResume && (
            <Menu>
              <Resume flockId={flockId} user={props.user} />
              <div className="menu-group">
                <h2 className="mini-heading">
                  {t('flocks.flocksDetail.sectionRemuneration.title')}
                </h2>
                {isMobile && (
                  <SidebarRight>
                    <SidebarToggler>
                      {getToggler('payment')}
                    </SidebarToggler>
                    {showClosureOption && (
                      <SidebarToggler>
                        {getToggler('closure')}
                      </SidebarToggler>
                    )}
                    <SidebarRightContent
                      title={getHeading(selectedKey)}
                    >
                      {getFlockDetails()}
                    </SidebarRightContent>
                  </SidebarRight>
                )}

                {isGtMobile && (
                  <>
                    {getToggler('payment')}
                    {invoice && getToggler('invoice')}
                    {showClosureOption && getToggler('closure')}
                  </>
                )}
              </div>
            </Menu>
          )}
          <Menu>
            <div className="menu-group">
              {isMobile && (
                <SidebarRight>
                  {!isResume && (
                    <>
                      <SidebarToggler>
                        {getToggler('next')}
                      </SidebarToggler>
                      <SidebarToggler>
                        {getToggler('feed')}
                      </SidebarToggler>
                      <SidebarToggler>
                        {getToggler('placement')}
                      </SidebarToggler>
                      <SidebarToggler>
                        {getToggler('slaughter')}
                      </SidebarToggler>
                    </>
                  )}
                  <SidebarRightContent
                    title={getHeading(selectedKey)}
                  >
                    {getFlockDetails()}
                  </SidebarRightContent>
                </SidebarRight>
              )}

              {isGtMobile && !isResume && (
                <>
                  {getToggler('next')}
                  {getToggler('feed')}
                  {getToggler('placement')}
                  {getToggler('slaughter')}
                </>
              )}
            </div>
          </Menu>
        </>
      )}
    </div>
  );
}
