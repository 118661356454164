import { API_PRODUCER_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function inviteUser(payload) {
  const response = await request(`${API_PRODUCER_URL}/InviteUser`, {
    method: 'POST',
    payload,
  });
  return response;
}

export async function docNumberValidate(payload) {
  const response = await request(
    `${API_PRODUCER_URL}/InviteUser/VerifyCPF`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}

export async function getInvitedUserList() {
  const response = await request(
    `${API_PRODUCER_URL}/InviteUser/GetInvitedUserList`,
    {
      method: 'GET',
    },
  );
  return response;
}

export async function getVendors() {
  const response = await request(
    `${API_PRODUCER_URL}/Vendor/GetVendors`,
    {
      method: 'GET',
    },
  );
  return response;
}

export async function deleteUser(id) {
  const response = await request(
    `${API_PRODUCER_URL}/InviteUser?id=${id}`,
    {
      method: 'DELETE',
    },
  );
  return response;
}
