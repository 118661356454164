import React, { useRef } from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Message } from '@zendeskgarden/react-forms';
import { API_URL } from 'config/constants';
import { InputField } from 'components/Forms/Fields/';
import LoadingHolder from 'components/LoadingHolder';
import Image from 'components/Image';
import {
  FileInputWrapper,
  UploadButton,
} from 'components/Forms/Forms.styled';
import {
  Close,
  RoundedItem,
} from 'components/MultiSelect/Items.styled';
import t from 'components/i18n';

export default function FileInput(props) {
  const {
    itemsToList = [],
    isLoading = false,
    removeFile,
    itemLabel = 'name',
    itemToRemove,
    itemLink,
    className = '',
    blobContainerName,
    accept,
    type,
    btnLabel,
    showRemoveFile,
    showAddButton = true,
    token,
    validFile,
  } = props;

  const inputFileRef = useRef();

  const maxLenFile = 20971520;

  function displayObject(item, index = 1) {
    if (!item || item === '') return;

    const itemToDisplay =
      typeof item === 'object' ? item[itemLabel] : item;

    return (
      itemToDisplay && (
        <RoundedItem key={index}>
          <p>
            <a
              href={itemLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {itemToDisplay.indexOf('_') > -1
                ? itemToDisplay.split('_')[1]
                : itemToDisplay}
            </a>
          </p>
          <Close
            onClick={() =>
              removeFile(item[itemToRemove] || itemToDisplay)
            }
          >
            {' '}
            <span className="icon icon-close-circle" />
          </Close>
        </RoundedItem>
      )
    );
  }

  function displayImagePreview(item, index = 1) {
    if (!item || item === '') return;

    return (
      <Col
        key={index}
        sm={4}
        className="display-image-preview__wrapper"
      >
        <Image
          api={API_URL}
          showRemoveFile={showRemoveFile}
          item={item}
          blobContainer={blobContainerName}
          token={token}
          removeFile={() => removeFile(item)}
        />
      </Col>
    );
  }

  function getMethod(item) {
    const method =
      {
        // from type of file
        ['application']: displayObject,
        ['image']: displayImagePreview,
        // from extension
        ['pdf']: displayObject,
        ['png']: displayImagePreview,
        ['jpg']: displayImagePreview,
        ['jpeg']: displayImagePreview,
        ['svg']: displayImagePreview,
        ['webp']: displayImagePreview,
        ['jfif']: displayImagePreview,
      }[item] || displayObject;

    return method;
  }

  function getType(item) {
    const getExtension = item => {
      const [_, extension] = item.split(/([^.]*)$/);
      return extension;
    };

    if (typeof item === 'object' || typeof item === 'string') {
      // type is for new files
      if (item.type) {
        const [type] = item.type.split('/');
        return type;
      }

      // get extension file from name
      if (item.name) {
        return getExtension(item.name);
      }
    }

    return getExtension(item);
  }

  function displayItems() {
    const isArray = Array.isArray(itemsToList);

    if (!itemsToList) {
      return;
    }

    const build = item => {
      const isArray = Array.isArray(item);

      if (!item || (isArray && !item.length)) {
        return;
      }

      const type = getType(item);

      if (!type) {
        return null;
      }

      const method = getMethod(type);

      if (!method) {
        return null;
      }

      return method(item);
    };

    if (!isArray) {
      return build(itemsToList);
    }

    return itemsToList.map((item, index) => build(item, index));
  }

  return (
    <div className={className}>
      <FileInputWrapper dark>
        <InputField
          {...props}
          field="fileInputWrapper"
          type="file"
          accept={accept}
          reference={inputFileRef}
          value=""
          token={token}
        />
        {showAddButton && (
          <UploadButton
            type="button"
            onClick={() => inputFileRef.current.click()}
          >
            {btnLabel || t('global.button.add')}
            <span className="icon icon-plus-circle" />
          </UploadButton>
        )}

        <Row className="file-input__items">{displayItems()}</Row>
        <LoadingHolder isLoading={isLoading} />
      </FileInputWrapper>
      {props.errors[props.field] && (
        <Message validation="error">
          {props.errors[props.field]}
        </Message>
      )}
    </div>
  );
}
