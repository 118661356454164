import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import { BtnGroup } from 'components//Forms/BtnGroup/BtnGroup.styled';
import { Circular } from 'components/CircleAndTitle/CircleAndTitle.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Form, Subheading } from 'components/Forms/Forms.styled';
import {
  DropdownField,
  InputField,
  DateRangeDropdownField,
  AutoCompleteDropdownField,
} from 'components/Forms/Fields';
import {
  getHealthProblems,
  getMaterialTypes,
  getLaboratory,
} from 'containers/AgroBRF/FlockList/service';
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import { colorTertiary } from 'components/GlobalStyles/variables';
import MedicationConfirmation from './MedicationConfirmation';
import t from 'components/i18n';

export default function MedicationForm({
  houses,
  goTo,
  isEditing = false,
  isValid,
  medication,
  selectedMedicationId,
  setSelectedMedicationId,
  sendToConfirmation,
  setSendToConfirmation,
  isSubmitting,
  setFieldValue,
  handleSubmit,
  handleChange,
  handleBlur,
  resetForm,
  values,
  errors,
  touched,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const [laboratoryList, setLaboratoryList] = useState([]);
  const [healthProblems, setHealthProblems] = useState([]);

  const getMaterialTypesList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getMaterialTypes();
      setMaterialList(response);
    } catch (e) {
      console.error('Error on getSummary', e);
    }
    setIsLoading(false);
  }, []);

  const getLaboratoryList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getLaboratory();
      setLaboratoryList(response);
    } catch (e) {
      console.error('Error on getSummary', e);
    }
    setIsLoading(false);
  }, []);

  const getHealthProblemsList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getHealthProblems();
      setHealthProblems(response);
    } catch (e) {
      console.error('Error on getSummary', e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!sendToConfirmation && houses.length > 0) {
      const house = houses.filter(
        item => values.houseId === item.id,
      )[0];

      setFieldValue('house', house);
    }

    if (sendToConfirmation) {
      if (values.house) {
        setFieldValue('houseId', values.house.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendToConfirmation]);

  useEffect(() => {
    if (values.id) {
      setFieldValue('id', values.id);
    } else {
      setFieldValue('enableEdit', true);
      setSendToConfirmation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id]);

  useEffect(() => {
    if (materialList) {
      const favoriteMaterial = materialList.filter(e => e.isFavorite);

      if (
        favoriteMaterial.length > 0 &&
        !values.material &&
        !values.id
      ) {
        setFieldValue('material', favoriteMaterial[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialList, values]);

  useEffect(() => {
    if (!sendToConfirmation) {
      getMaterialTypesList();
      getHealthProblemsList();
      getLaboratoryList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendToConfirmation]);

  return (
    values && (
      <Form autoComplete="off">
        {!sendToConfirmation ? (
          <>
            <Row>
              <Col md={6}>
                {/* Instalação */}
                <DropdownField
                  field="house"
                  label={t('form.next.medication.field.house.label')}
                  placeholder={t('form.next.medication.field.house.placeholder')}
                  onSelect={item => {
                    setFieldValue('house', item);
                  }}
                  disabledItems="show"
                  options={houses}
                  optionLabel="label"
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \ Instalação */}
              </Col>

              <Col md={6}>
                {/* Data */}
                <DateRangeDropdownField
                  field="treatmentPeriod"
                  label={t('form.next.medication.field.treatmentPeriod.label')}
                  placeholder={t('form.next.medication.field.treatmentPeriod.placeholder')}
                  buttonLabel={t('global.button.filter.date')}
                  keysToCompare={['startDate', 'endDate']}
                  onChange={item => {
                    setFieldValue('treatmentPeriod', item);
                  }}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \ Data */}
              </Col>
            </Row>

            <Separator gap={8} />
            <br />

            <Subheading>
              <Circular bgColor={colorTertiary}>
                {t('form.next.medication.section.medicine-data.number')}
              </Circular>
              {t('form.next.medication.section.medicine-data.title')}
            </Subheading>

            <Row>
              <Col>
                <AutoCompleteDropdownField
                  field="material"
                  label={t('form.next.medication.field.material.label')}
                  placeholder={t('form.next.medication.field.material.placeholder')}
                  onSelect={e => {
                    setFieldValue('material', e);
                  }}
                  loadingLabel={t('global.loading')}
                  optionLabel="description"
                  caption="code"
                  noMatchLabel={t('form.next.medication.field.material.no-matching')}
                  selectObject
                  options={materialList}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <AutoCompleteDropdownField
                  field="laboratory"
                  label={t('form.next.medication.field.laboratory.label')}
                  placeholder={t('form.next.medication.field.laboratory.placeholder')}
                  noMatchLabel={t('form.next.medication.field.laboratory.no-matching')}
                  onSelect={e => {
                    setFieldValue('laboratory', e);
                  }}
                  loadingLabel={t('global.loading')}
                  optionLabel="description"
                  selectObject
                  options={laboratoryList}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <InputField
                  type="text"
                  field="vaccineBatch"
                  label={t('form.next.medication.field.vaccineBatch.label')}
                  placeholder={t('form.next.medication.field.vaccineBatch.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <InputField
                  type="number"
                  field="quantity"
                  label={t('form.next.medication.field.quantity.label')}
                  placeholder={t('form.next.medication.field.quantity.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>

            <Separator gap={8} />
            <br />

            <Subheading>
              <Circular bgColor={colorTertiary}>{t('form.next.medication.section.treatment-data.number')}</Circular>
              {t('form.next.medication.section.treatment-data.title')}
            </Subheading>

            <Row>
              <Col>
                <AutoCompleteDropdownField
                  field="healthProblem"
                  label={t('form.next.medication.field.healthProblem.label')}
                  placeholder={t('form.next.medication.field.healthProblem.placeholder')}
                  noMatchLabel={t('form.next.medication.field.healthProblem.no-matching')}
                  onSelect={e => {
                    setFieldValue('healthProblem', e);
                  }}
                  loadingLabel={t('global.loading')}
                  optionLabel="name"
                  selectObject
                  options={healthProblems}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <InputField
                  type="number"
                  field="quantityTreated"
                  label={t('form.next.medication.field.quantityTreated.label')}
                  placeholder={t('form.next.medication.field.quantityTreated.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>

            <Separator />

            {/* \ Botões */}
            <BtnGroup>
              <Button
                key="sendtoConfirm"
                className="tertiary"
                onClick={() => {
                  setSendToConfirmation(true);
                }}
                disabled={!isValid}
              >
                {t("global.button.send")}
              </Button>
              <Button
                key="clean"
                onClick={() => {
                  resetForm();
                }}
                className="white"
              >
                {t("global.button.clear")}
              </Button>
            </BtnGroup>
            {/* \ Botões */}
          </>
        ) : (
          <MedicationConfirmation
            values={{ ...values }}
            isSubmitting={isSubmitting}
            backToForm={() => {
              setSendToConfirmation(false);
            }}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            isEditing={isEditing}
            isValid={isValid}
            goTo={goTo}
            medication={medication}
            setSelectedMedicationId={setSelectedMedicationId}
            selectedMedicationId={selectedMedicationId}
            isEditable={values.isEditable}
            setSendToConfirmation={setSendToConfirmation}
          />
        )}
      </Form>
    )
  );
}
