import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  colorInfo,
  colorGrayLightest,
  borderRadius,
  colorGrayUltraLight,
  colorClear,
} from 'components/GlobalStyles/variables';

export const StatusChangeWrapper = styled.div`
  margin: -1px -16px 12px;
  padding: 16px;
  border-radius: 12px 12px 0 0;
  background-color: ${rgba(colorInfo, 0.16)};
`;

export const Bordered = styled.div`
  background-color: ${colorClear};
  border: 1px solid ${colorGrayLightest};
  border-radius: ${borderRadius};
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 16px;

  p {
    font-size: 14px;
    margin-top: 16px;
  }
`;

export const Grey = styled.div`
  background-color: ${colorGrayUltraLight};
  padding: 14px 12px;
  width: 80px;
  border-radius: 0 12px 12px 0;

  input[type='checkbox'] + label:not(.block-label):before {
    left: 8px;
    top: -12px;
  }
`;
