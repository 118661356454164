import React from 'react';
import moment from 'moment';
import DateAndTime from 'components/DateAndTime';
import IconPlaceholder from 'components/IconPlaceholder';
import BadgePill from 'components/BadgePill';
import { Item, Bullet } from '../VendorSchedule.styled';
import { statusMapper } from 'utils/mapper';
import t from 'components/i18n';

export default function NextPlacementItem({
  date,
  icon,
  iconColor,
  status,
  statusColor,
  firstItem,
  secondItem,
  thirdItem,
  isFirstItem,
  isLastItem,
}) {
  return (
    <Item margin className="animated fadeIn">
      <div className="flex ">
        <IconPlaceholder bgColor="colorClear" className="white">
          {isFirstItem ? (
            <span className={`icon ${icon} ${iconColor}`} />
          ) : (
            <Bullet />
          )}
        </IconPlaceholder>
        <div style={{ width: '100%' }}>
          <DateAndTime>
            {moment(date).format('DD ̸̸  MM ̸̸  YYYY')}
          </DateAndTime>
          <div className={isLastItem ? '' : 'history'}>
            <p>
              <strong>{firstItem}</strong>
            </p>
            <p className="normal">{secondItem}</p>
            <p className="small">{thirdItem}</p>
          </div>
        </div>
        <BadgePill bgColor={statusColor}>{t(statusMapper(status))}</BadgePill>
      </div>
    </Item>
  );
}
