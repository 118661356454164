import styled from 'styled-components';
import {
  colorClear,
  colorDanger,
  colorGrayDarkest,
  colorGrayLight,
  colorSuccess,
  colorTertiary,
  colorInfo,
} from 'components/GlobalStyles/variables';
import { rgba } from 'polished';

// import { Button } from '@zendeskgarden/react-buttons';

export const QuestionWrapper = styled.div`
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.25)};
  margin: 0 -16px 16px;
  padding: 0 16px;

  &:last-of-type {
    border: none;
  }
`;

export const Options = styled.div`
  margin: 32px 0 42px;

  &.centered {
    margin: 32px auto 16px;
    text-align: center;
  }
`;

export const Question = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  line-height: 26px;
`;

export const DefaultButton = styled.button`
  border: 1px solid ${colorGrayDarkest};
  border-radius: 8px;
  background-color: transparent;
  font-size: 14px;
  line-height: 18px;
  margin-right: 20px !important;
  height: 40px;
  width: 124px;
  cursor: pointer;
  color: ${colorGrayDarkest} !important;
  overflow: none !important;

  transition: background-color 0.2s ease-in, color 0.2s ease-out,
    border 0.3s ease-out;

  [class^='icon-'] {
    font-size: 22px;
  }

  &.green {
    [class^='icon-'] {
      color: ${colorSuccess};
    }
  }

  &.red {
    [class^='icon-'] {
      color: ${colorDanger};
    }
  }

  &.no-border {
    border: 1px solid transparent;
    color: ${colorGrayLight};
    position: relative;

    &:after {
      display: block;
      background-color: ${colorGrayLight};
      content: '';
      height: 1px;
      position: absolute;
      left: 19px;
      top: 28px;
      width: 86px;
    }
  }

  &:hover {
    transition: background-color 0.2s ease-in, color 0.2s ease-out;
    &.green {
      background-color: transparent !important;
      border: 1px solid ${colorSuccess} !important;
      box-shadow: 0px 0px 0px 1px ${colorSuccess};
    }

    &.red {
      background-color: transparent !important;
      border: 1px solid ${colorDanger} !important;
      box-shadow: 0px 0px 0px 1px ${colorDanger};
    }

    &.no-border {
      background-color: transparent !important;
      border: 1px solid ${colorGrayDarkest} !important;
      box-shadow: 0px 0px 0px 1px ${colorGrayDarkest};
    }

    &.custom {
      border: 1px solid ${colorTertiary};
    }
  }

  &.active {
    color: ${colorClear} !important;

    [class^='icon-'] {
      color: ${colorClear};
    }

    &.green {
      background-color: ${colorSuccess} !important;
      border: 1px solid ${colorSuccess};
    }

    &.red {
      background-color: ${colorDanger} !important;
      border: 1px solid ${colorDanger};
    }

    &.no-border {
      background-color: ${colorGrayDarkest} !important;
      border: 1px solid ${colorGrayDarkest} !important;
      color: ${colorClear} !important;

      &:after {
        background-color: ${colorGrayDarkest};
      }
    }

    &.custom {
      background-color: ${colorTertiary};
      border-color: ${colorTertiary};
    }
  }
`;

export const PlanOptions = styled.div`
  margin: 32px auto 16px;
  text-align: center;

  ${DefaultButton} {
    width: 96px;
    margin-right: 10px;
    background-color: ${colorTertiary} !important;
    border: 1px solid ${colorTertiary} !important;
    color: ${colorClear} !important;

    &.whitest {
      background-color: ${colorClear} !important;
      color: ${colorGrayDarkest} !important;
      border: 1px solid ${colorGrayDarkest} !important;
    }

    &:hover {
      background-color: ${colorGrayDarkest};
      color: ${colorClear};
    }
  }
`;

export const Answer = styled.div`
  position: relative;
  padding: 21px 0 23px;
  font-size: 16px;

  display: flex;
  align-items: center;

  [class^='icon-'] {
    margin: 0 20px 0 8px;
  }
`;

export const BottomContentTime = styled.div`
  margin: 1rem -1.6rem -1.35rem;
  background: #eceff1;
  padding: 1rem 1.355rem;
  border-top: 1px solid #cfd2d8;
  display: flex;
  align-items: center;

  .icon {
    color: ${colorInfo} !important;
    font-size: 2rem;
    margin-right: 0.75rem;
  }
`;

export const BottomContentDone = styled(BottomContentTime)`
  .icon {
    color: ${colorSuccess} !important;
    font-size: 2rem;
    margin-right: 0.75rem;
  }
`;

export const Date = styled.span`
  font-size: 12px;
  color: ${colorInfo};
`;

export const DateDone = styled.span`
  font-size: 12px;
  color: ${colorSuccess};
`;
