import getFromLocalStorage from './Helpers/getFromLocalStorage';

export const logEnabler = () => {
  const forceEnable = getFromLocalStorage('enable_logs');

  window.enableLogs =
    forceEnable ||
    !window.location.hostname.includes('www.agrobrf.com');

  window.defaultLog = window.console.log;
  window.defaultInfo = window.console.info;
  window.defaultError = window.console.error;

  window.console.log = (...args) =>
    window.enableLogs && window.defaultLog(...args);
  window.console.info = (...args) =>
    window.enableLogs && window.defaultInfo(...args);
  window.console.error = (...args) =>
    window.enableLogs && window.defaultError(...args);
};
