import React, { useState, useEffect } from 'react';
import moment from 'moment';
import LoadingHolder from 'components/LoadingHolder';
import Container from 'components/Container';
import NotificationBox from 'components/NotificationBox';
import { colorInfo } from 'components/GlobalStyles/variables';
import t from 'components/i18n';
import { getLgpdTerm } from '../../service';
import {
  PolicyWrapper,
  Separator,
} from '../../ProfileAndSettings.styled';

export default function Policy({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await getLgpdTerm();
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  return (
    <Container className="container" slim>
      <h4>{t('menu.profile.privacyPolicy.title')}</h4>

      <Separator />
      <LoadingHolder isLoading={isLoading} />

      {data && (
        <>
          {data && data.acceptedDate && (
            <NotificationBox
              className="notification notification--info"
              type="warning"
              bgColor={colorInfo}
              slim
            >
              {t('menu.profile.termsOfUse.messageAlert', {
                date: moment(data.acceptedDate).format('DD ̸̸  MM ̸̸  YYYY'),
                hour: moment(data.acceptedDate).format('HH:mm'),
              })}
            </NotificationBox>
          )}
          <br />
          <PolicyWrapper
            dangerouslySetInnerHTML={{ __html: data.term }}
          />
        </>
      )}
    </Container>
  );
}
