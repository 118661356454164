import React from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import FloatingLabel from 'components/FloatingLabel';
import {
  EyeIcon,
  MediaFigure,
} from 'containers/OnePage/Login/SignIn/SignIn.styled';
import Spacer from 'components/Spacer';
import MaskedInput from 'components/MaskedInput';
import { Dots } from '@zendeskgarden/react-loaders';
import * as routes from 'config/routes';
import {
  getValidationMessage,
  getValidation,
  getTextfieldClass,
} from 'shared/utils/Helpers';
import {
  Field,
  Message,
  MediaInput,
  FauxInput,
  Label,
} from '@zendeskgarden/react-forms';
import {
  Heading,
  Subheading,
  Link,
  TitleBlock,
  LinkHolder,
} from 'components/SideBox/SideBox.styled';
import t from 'components/i18n';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'config/authConfig';
import { Divider } from './LoginForm.styles';

export default function NextLoginForm({
  values,
  handleSubmit,
  setFieldValue,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldTouched,
  isValid,
  isLoading,
  showPassword,
  setShowPassword,
  directSignIn,
  showAlert,
}) {
  const { instance, accounts } = useMsal();
  const [isMsalLoading, setIsMsalLoading] = React.useState(false);
  const [shouldAttemptADFS, setShouldAttemptADFS] = React.useState(false);

  const isEitherLoading = isLoading || isMsalLoading;

  function onChange(e) {
    handleChange(e);
  }

  function onBlur(e) {
    setFieldTouched(e.currentTarget.name);
    handleBlur(e);
  }

  const buttonPropsNext = {
    stretched: true,
    primary: true,
    type: 'submit',
    disabled: !isValid || isEitherLoading,
  };

  const handleMsalLogin = async (e) => {
    e.preventDefault();
    setIsMsalLoading(true);
    try {
      await instance.loginPopup(loginRequest);
      setShouldAttemptADFS(true);
    } catch (e) {
      console.error('Error during login:', e)
    } finally {
      setIsMsalLoading(false);
    }
  };

  React.useEffect(() => {
    async function doLoginADFS() {
      setIsMsalLoading(true);
      try {
        const {accessToken} = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        await directSignIn({ token: accessToken });
      } catch (err) {
        showAlert(
          t(err && typeof err.message === 'string' ? err.message : 'authentication.verifyaccount.unknownError'),
          {
            variant: 'danger',
            timeout: 5,
          },
        )
      } finally {
        setShouldAttemptADFS(false);
        setIsMsalLoading(false);
      }
    }

    if (!shouldAttemptADFS) return;
    doLoginADFS();
  }, [instance, accounts.length, shouldAttemptADFS])

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <TitleBlock>
        <Heading>{t('login.title')} </Heading>
        <Subheading>{t('login.subtitle')}</Subheading>
      </TitleBlock>

      <Spacer multiplier={5} />

      <Field
        className={getTextfieldClass(
          getValidation(
            values.register,
            errors,
            'register',
            !touched.register,
          ),
        )}
      >
        <FloatingLabel>
          <Label>{t('login.ext.login')}</Label>
          <MaskedInput
            name="register"
            type="register"
            validation={getValidation(
              values.register,
              errors,
              'register',
              !touched.register,
            )}
            value={values.register}
            onBlur={onBlur}
            onChange={onChange}
            setState={value => {
              setFieldValue('register', value);
            }}
            disabled={isEitherLoading}
          />
          <Message
            validation={getValidation(
              values.register,
              errors,
              'register',
              !touched.register,
            )}
          >
            {getValidationMessage(
              'register',
              errors,
              !touched.register,
            )}
          </Message>
        </FloatingLabel>
      </Field>

      <Field
        mediaLayout
        className={getTextfieldClass(
          getValidation(
            values.password,
            errors,
            'password',
            !touched.password,
          ),
        )}
      >
        <FloatingLabel>
          <Label>{t('login.inputPassword')}</Label>
          <FauxInput
            className="faux-input"
            mediaLayout
            validation={getValidation(
              values.password,
              errors,
              'password',
              !touched.password,
            )}
          >
            <MediaInput
              name="password"
              className="media-input"
              type={showPassword ? 'text' : 'password'}
              onBlur={onBlur}
              onChange={onChange}
              validation={getValidation(
                values.password,
                errors,
                'password',
                !touched.password,
              )}
              disabled={isEitherLoading}
            />
            {values.password && (
              <MediaFigure>
                {!showPassword && (
                  <EyeIcon
                    className="icon-visibility-enabled"
                    onClick={() => setShowPassword(true)}
                  />
                )}

                {showPassword && (
                  <EyeIcon
                    className="icon-visibility-disabled"
                    onClick={() => setShowPassword(false)}
                  />
                )}
              </MediaFigure>
            )}
          </FauxInput>

          <Message
            validation={getValidation(
              values.password,
              errors,
              'password',
              !touched.password,
            )}
          >
            {getValidationMessage(
              'password',
              errors,
              !touched.password,
            )}
          </Message>
        </FloatingLabel>
      </Field>

      <LinkHolder>
        <Link to={routes.PASSWORD_RECOVERY_NEXT}>
          {t('login.linkForgottenPassword')}
        </Link>
      </LinkHolder>

      <Spacer multiplier={2} />

      {!isEitherLoading && (
        <>
          <Button stretched type="submit" className="tertiary">
            {t('login.buttonPrimary')}
          </Button>
          <Divider><span>{t('login.ext.label.divider')}</span></Divider>
          <Button stretched type="button" className="tertiary" onClick={handleMsalLogin}>
            {t('login.ext.button.login-azure')}
          </Button>
        </>
      )}
      {isEitherLoading && (
        <Button {...buttonPropsNext}>
          <Dots />
        </Button>
      )}
    </form>
  );
}
