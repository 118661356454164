import React from 'react';
import Icon from 'components/Icon';
import LogoFooter from 'components/Logo/brfcomplete-logo-coloured.png';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import {
  CopyrightWrapper,
  CopyrightBottomWrapper,
  Dash,
  FooterWrapper,
  EmailP,
  CopyrightP,
} from './Footer.styled';
import t from 'components/i18n';
import { mapUserLang } from 'utils/locale';

const Copyrights = () => {

  const getEmail = () => {
    const defaultUserLangCode = mapUserLang(navigator.language || navigator.userLanguage);

    const email = {
      ['en-US']: 'agroinfo.tr@brf.com',
      ['pt-BR']: 'faleconoscointegrados@brf-br.com',
      ['tr-TR']: 'agroinfo.tr@brf.com',
    }[defaultUserLangCode || 'pt-BR'];

    return email;
  }

  return (
    <>
      <CopyrightWrapper>
        <div>
          <Row alignItems="center">
            <Col sm={12} md={7}>
              <img
                src={LogoFooter}
                style={{ height: 40 }}
                alt="brf-logo-footer"
              />
            </Col>
            <Col sm={12} md={5}>
              <div className="email-wrapper">
                <EmailP>{getEmail()}</EmailP>
              </div>
            </Col>
          </Row>
        </div>
      </CopyrightWrapper>
      <CopyrightBottomWrapper>
        <div>
          <Row alignItems="center">
            <Col>
              <CopyrightP>
                {t('lp.section.footer.text')}
              </CopyrightP>
            </Col>
          </Row>
        </div>
      </CopyrightBottomWrapper>
    </>
  );
};

const Footer = () => {
  return (
    <>
      <FooterWrapper>
        <Grid>
          <Row
            alignItems="center"
            justifyContent="center"
            style={{ maxWidth: '960px', margin: '0 auto' }}
          >
            <Col sm={12} md={7}>
              <Dash />
              <h1 className="title">{t('lp.section.downloadApp.title')}</h1>
              <p className="description">{t('lp.section.downloadApp.subtitle')}</p>
            </Col>
            <Col sm={12} md={5}>
              <Row className="buttons-wrapper">
                <Button
                  style={{
                    width: 250,
                    margin: 5,
                    border: 'none',
                  }}
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.br.brfAppAgro',
                      '_blank',
                    )
                  }
                  type="button"
                >
                  <span>
                    <Icon
                      className="icon-store"
                      name="icon-google-play"
                    />
                    Google Play
                  </span>
                </Button>

                <Button
                  style={{
                    width: 250,
                    margin: 5,
                    border: 'none',
                  }}
                  onClick={() =>
                    window.open(
                      'https://apps.apple.com/br/app/agrobrf/id1361237066',
                      '_blank',
                    )
                  }
                  type="button"
                  className="apple-store-button"
                >
                  <span>
                    <Icon
                      className="icon-store"
                      name="icon-apple-store"
                    />
                    App Store
                  </span>
                </Button>
              </Row>
            </Col>
          </Row>
        </Grid>
      </FooterWrapper>
      <Copyrights />
    </>
  );
};

export default Footer;
