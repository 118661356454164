import styled from 'styled-components';
import { rgba } from 'polished';
import {
  breakpointTablet2,
  colorClear,
  colorGray,
  colorGrayDark,
  colorGrayDarkest,
  colorGrayLighter,
  fontFamilyText,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';
import Background from './assets/background_bb.png';

export const Title = styled.h1`
  color: ${colorGrayDarkest};
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  max-width: 308px;

  @media (min-width: ${breakpointTablet2}px) {
    font-size: 24px;
    line-height: 36px;
    max-width: 378px;
  }
`;

export const BancoDoBrasilWrapper = styled.section`
  position: relative;
  max-width: 652px;
  margin: 0;
  padding: 16px 16px 0;
  background-image: url(${Background});
  background-size: cover;
  overflow: hidden;

  @media (min-width: ${breakpointTablet2}px) {
    margin: 26px;
    padding: 26px 26px 0;
    border-radius: 8px;
  }

  .container-content {
    padding: 72px 0px 16px;
    font-family: ${fontFamilyTextBold};
    background-color: ${rgba(colorClear, 0.9)};

    @media (min-width: ${breakpointTablet2}px) {
      padding: 51px 24px 24px;
    }
  }

  ${Title} {
    margin: 0 auto 21px;
    padding: 0 10px;
  }

  a.app-button {
    font-size: 14px;
    font-family: ${fontFamilyText};
    background-color: ${colorGrayDarkest};
    color: ${colorClear};
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;

    &:hover {
      background-color: ${colorGrayDark};
      color: ${colorClear};
    }

    &:active {
      background-color: ${colorGrayDark};
      color: ${colorClear};
    }
  }
`;

export const Bottom = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;

  @media (min-width: ${breakpointTablet2}px) {
    flex-direction: row;
  }

  h2 {
    font-size: 20px;
    line-height: 28px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    margin: 0;

    &.smaller {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .client {
    text-align: center;
    margin-bottom: 16px;

    @media (min-width: ${breakpointTablet2}px) {
      text-align: left;
      width: 261px;
      margin-bottom: 0;
    }
  }

  .qr-code {
    display: flex;
    align-items: center;

    img {
      margin-top: 12px;
    }

    p {
      padding-right: 10px;
      width: 134px;
    }
  }
`;

export const Header = styled.header`
  background-color: #fcfc30;
  width: 99px;
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -55.55px;
  z-index: 1;

  img {
    height: 50px;
    width: auto;
  }

  @media (min-width: ${breakpointTablet2}px) {
    width: 111px;
    height: 68px;

    img {
      height: 55px;
    }
  }
`;

export const Links = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    font-size: 16px;
    line-height: 24px;
    padding: 11px 16px;
    border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.2)};

    a {
      color: ${colorGrayDarkest};
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        color: ${colorGrayLighter};
      }

      span {
        font-size: 26px;
        color: ${colorGrayLighter};
      }
    }
  }
`;

export const Footer = styled.footer`
  color: ${colorClear};
  background-color: ${colorGray};
  margin: 0 -16px;
  padding: 8px 32px 8px 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpointTablet2}px) {
    margin: 0 -26px;
    padding: 8px 16px;
  }

  span {
    font-size: 32px;
  }

  .underline {
    color: ${colorClear};
    text-decoration: underline;
  }

  > div {
    padding-top: 4px;

    p {
      font-size: 10px;
      line-height: 12px;
      margin: 0;

      &.phone {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;
