import t from "components/i18n";

const animalTypeOptions = [
  {
    label: t('announcements.form.animal-type.options.all'),
    value: 'all',
    translate: t('announcements.form.animal-type.options.all'),
    isDefault: true,
  },
  {
    label: t('announcements.form.animal-type.options.broiler'),
    value: 'Frango',
    translate: t('announcements.form.animal-type.options.broiler'),
    isDefault: false,
  },
  {
    label: t('announcements.form.animal-type.options.swine'),
    value: 'Suíno',
    translate: t('announcements.form.animal-type.options.swine'),
    isDefault: false,
  },
  {
    label: t('announcements.form.animal-type.options.turkey'),
    value: 'Peru',
    translate: t('announcements.form.animal-type.options.turkey'),
    isDefault: false,
  },
];

const exhibitionOptions = [
  {
    label: t('announcements.form.exhibition-type.options.all'),
    value: 'T',
    isDefault: true,
  },
  {
    label: t(
      'announcements.form.exhibition-type.options.extensionists',
    ),
    value: 'E',
    isDefault: false,
  },
  {
    label: t('announcements.form.exhibition-type.options.producers'),
    value: 'P',
    isDefault: false,
  },
];

const priorityOptions = [
  {
    label: t('announcements.form.priority-type.options.high'),
    priority: 'HIGH',
  },
  {
    label: t('announcements.form.priority-type.options.moderate'),
    priority: 'MODERATE',
  },
  {
    label: t('announcements.form.priority-type.options.low'),
    priority: 'LOW',
  },
];

export {
  animalTypeOptions,
  exhibitionOptions,
  priorityOptions
}
