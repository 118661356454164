import React, { useState, useEffect } from 'react';
import t from 'components/i18n';
// context
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import LoadingHolder from 'components/LoadingHolder';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import Heading from 'components/Heading';
import { Form } from 'components/Forms/Forms.styled';
import {
  InputField,
  DropdownField,
  ReCaptchaField,
  TextAreaField,
} from 'components/Forms/Fields';
import { RECAPTCHA_KEY } from 'config/constants';
import {
  Layer,
  ConclusionBox,
} from 'containers/OnePage/Contact/Contact.styled';

// service
import {
  getStates,
  getCity,
} from 'containers/OnePage/BecomeAMember/service';

export default function ContactForm(props) {
  const [countryStates, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const {
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isLoading,
    values,
    errors,
    touched,
  } = props;

  function onLoadRecaptcha() {
  }

  useEffect(() => {
    async function fetch() {
      try {
        const response = await getStates();

        setStates(response.data);
      } catch (e) {
        console.error('Error on getStates', e);
      }
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetch(id) {
      try {
        const response = await getCity(id);

        setCities(response.data);
      } catch (e) {
        console.error('Error on getStates', e);
      }
    }
    if (Object.keys(values.countryState).length > 0) {
      fetch(values.countryState.id);
    }
  }, [values.countryState]);

  const formSubject = () => {
    const options = props.subjects.map(sb => ({ ...sb, label: t(sb.subject) }))

    return options || [];
  }

  return (
    <>
      {isLoading ? (
        <LoadingHolder
          isLoading={isLoading}
          message={t('global.loading')}
        />
      ) : (
        <>
          {/* Primeira tela */}
          <Layer className="center">
            <Heading
              pageTitle={t('menu.support.title')}
              subHeading={t('lp.section.support.subtitle')}
            />
            {!props.success ? (
              <Form autoComplete="off">
                <Row>
                  <Col sm={6}>
                    {/* Nome */}
                    <InputField
                      type="text"
                      field="name"
                      label={t('lp.section.support.form.input.name.label')}
                      placeholder={t('lp.section.support.form.input.name.placeholder')}
                      onBlur={handleBlur}
                      onChange={e => {
                        handleChange(e);
                      }}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                    {/* \ Nome */}
                  </Col>

                  <Col sm={6}>
                    {/* Email */}
                    <InputField
                      type="text"
                      field="email"
                      label={t('lp.section.support.form.input.email.label')}
                      placeholder={t('lp.section.support.form.input.email.placeholder')}
                      onBlur={handleBlur}
                      onChange={e => {
                        handleChange(e);
                      }}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                    {/* \ Email */}
                  </Col>
                </Row>
                {/* Estados */}
                <Row>
                  <Col sm={6}>
                    {countryStates && (
                      <DropdownField
                        selectedItem={values.countryState}
                        field="countryState"
                        label={t('lp.section.support.form.input.state.label')}
                        placeholder={t('lp.section.support.form.input.state.placeholder')}
                        onSelect={e => {
                          setFieldValue('countryState', e);
                          setFieldValue('cityID', 0);
                        }}
                        options={countryStates}
                        optionLabel="name"
                        disabled={
                          countryStates && !countryStates.length
                        }
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                  </Col>

                  {/* \ Estados  */}

                  {/* Cidades */}

                  <Col sm={6}>
                    <DropdownField
                      selectedItem={values.cityID}
                      field="cityID"
                      label={t('lp.section.support.form.input.city.label')}
                      placeholder={t('lp.section.support.form.input.city.placeholder')}
                      onSelect={e => {
                        setFieldValue('cityID', e);
                      }}
                      options={cities && cities}
                      optionLabel="name"
                      disabled={cities && !cities.length}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>
                {/* \ Cidades  */}

                {/* Telefone */}
                <InputField
                  masked
                  type="phone"
                  field="phoneNumber"
                  label={t('lp.section.support.form.input.phone.label')}
                  placeholder={t('lp.section.support.form.input.phone.placeholder')}
                  setState={e => {
                    setFieldValue('phoneNumber', e);
                  }}
                  onBlur={e => handleBlur(e)}
                  onChange={e => handleChange(e)}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \ Telefone  */}

                {/* Assunto */}
                <DropdownField
                  field="subject"
                  label={t('lp.section.support.form.input.subject.label')}
                  placeholder={t('lp.section.support.form.input.subject.placeholder')}
                  onSelect={item => {
                    setFieldValue('subject', item);
                  }}
                  options={formSubject()}
                  optionLabel="label"
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \ Assunto */}
                {/* Mensagem */}
                <TextAreaField
                  field="message"
                  label={t('lp.section.support.form.input.message.label')}
                  setState={e => {
                    setFieldValue('message', e);
                  }}
                  onBlur={e => handleBlur(e)}
                  onChange={e => handleChange(e)}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <Row>
                  <Col sm={6}>
                    {/* Captcha */}
                    <div className="recaptchaWrapper">
                      <ReCaptchaField
                        field="recaptcha"
                        theme="light"
                        sitekey={RECAPTCHA_KEY}
                        verifyCallback={response =>
                          setFieldValue('recaptcha', response)
                        }
                        onloadCallback={onLoadRecaptcha}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    {/* \ Captcha */}
                  </Col>
                  <Col sm={6}>
                    {/* \ Botões */}
                    <BtnGroup>
                      <Button
                        key="submitForm"
                        onClick={e => handleSubmit(e)}
                        disabled={
                          !props.isValid || props.isValidating
                        }
                      >
                        {t('lp.section.support.form.submitButton')}
                      </Button>
                    </BtnGroup>
                    {/* \ Botões */}
                  </Col>
                </Row>
              </Form>
            ) : (
              <ConclusionBox>
                <span className="icon icon-mail" />
                <p className="bold">
                  {t('lp.section.form.success.title')}
                </p>
                <p>{t('lp.section.form.success.subtitle')}</p>
              </ConclusionBox>
            )}
          </Layer>
          {/* \ Primeira tela */}
        </>
      )}
    </>
  );
}
