import React from 'react';
import SidebarRight, {
  SidebarRightContent,
  SidebarToggler,
} from 'components/SidebarRight';
import Icon from 'components/Icon';
import {
  Menu,
  TabletMenu,
  ListItem,
} from 'containers/AgroBRF/FlockList/Flock/Flock.styled';

export default function NextFlockLabelMenu(props) {
  const { selectedKey, setSelectedKey, getMenuOptions } = props;
  const { isMobile, isTablet, isGtMobile } = props.responsive;

  function getTabletToggler(type) {
    return (
      <button
        onClick={() => setSelectedKey(type)}
        type="button"
        className={`unstyled-button tablet-menu-item ${
          selectedKey === type ? 'active' : ''
        }`}
      >
        <Icon
          className="tablet-menu-icon"
          name={getMenuOptions(type).icon}
        />
        <span className="tablet-menu-label">
          {getMenuOptions(type).label}
        </span>
      </button>
    );
  }

  function getToggler(type) {
    const menuItemProps = {
      className: 'menu-item',
    };

    if (isGtMobile) {
      menuItemProps.className = `menu-item ${
        selectedKey === type ? 'active' : ''
      }`;
    }

    return (
      <div {...menuItemProps}>
        <Icon
          className="menu-icon"
          name={getMenuOptions(type).icon}
        />

        <ListItem
          onClick={() => {
            setSelectedKey(type);
          }}
          role="button"
          className="animated fadeIn"
        >
          <div className="list-item-content">
            <div className="list-item-heading">
              {getMenuOptions(type).label}
            </div>
          </div>
          <Icon name="chevron icon-chevron-right" />
        </ListItem>
      </div>
    );
  }

  return (
    <div
      className="flock-menu"
      style={{ width: '100%', maxWidth: 316, marginRight: 20 }}
    >
      {isTablet && (
        <TabletMenu>
          {getTabletToggler('opened')}
          {getTabletToggler('closed')}
        </TabletMenu>
      )}

      {!isTablet && (
        <Menu>
          <div className="menu-group">
            {isMobile && (
              <SidebarRight>
                <SidebarToggler>{getToggler('open')}</SidebarToggler>
                <SidebarToggler>
                  {getToggler('closed')}
                </SidebarToggler>
                <SidebarRightContent
                  title={getMenuOptions(selectedKey)}
                />
              </SidebarRight>
            )}

            {isGtMobile && (
              <>
                {getToggler(1)}
                {getToggler(2)}
                {getToggler(3)}
              </>
            )}
          </div>
        </Menu>
      )}
    </div>
  );
}
