import React, { useState } from 'react';
import { PROFILE, ACCOUNT_PASSWORD_RECOVERY } from 'config/routes';
import t from 'components/i18n';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import { InputField } from 'components/Forms/Fields';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';
import { Link, LinkHolder } from 'components/SideBox/SideBox.styled';
import InputPassword from 'components/Forms/Fields/InputPassword';

export default function PhoneNumberForm({
  user,
  history,
  authenticateUser,
  authenticated,
  setFieldValue,
  handleSubmit,
  handleChange,
  handleBlur,
  resetForm,
  isValid,
  values,
  errors,
  touched,
}) {
  const [canEdit, setCanEdit] = useState(false);

  return (
    <Form autoComplete="off">
      {!authenticated && (
        <>
          <Row>
            <Col md={12}>
              <h4>
                {t('menu.profile.accountData.confirmPassword.title')}
              </h4>

              <p className="info">
                {t(
                  'menu.profile.accountData.confirmPassword.description',
                )}
              </p>

              <br />

              <InputPassword
                name="password"
                placeholder={t(
                  'menu.profile.accountData.confirmPassword.input.label',
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />

              <LinkHolder>
                <Link to={ACCOUNT_PASSWORD_RECOVERY} primary>
                  {t(
                    'menu.profile.accountData.confirmPassword.link.forgotPassword',
                  )}
                </Link>
              </LinkHolder>
            </Col>
          </Row>

          <Separator />

          <BtnGroup>
            <Button
              key="sendtoConfirm"
              onClick={() => {
                authenticateUser(user.cpf, values.password);
                // setStage(2)
              }}
              disabled={
                (errors && errors.password) ||
                (values && !values.password)
              }
            >
              {t(
                'menu.profile.accountData.confirmPassword.buttonPrimary',
              )}
            </Button>
            <Button
              key="clean"
              onClick={() =>
                history.push(
                  `${PROFILE}/account-settings/account-data`,
                )
              }
              className="white"
            >
              {t(
                'menu.profile.accountData.confirmPassword.buttonSecondary',
              )}
            </Button>
          </BtnGroup>
        </>
      )}

      {authenticated && (
        <>
          <Row>
            <Col md={12}>
              <p className="info">
                {t(
                  'menu.profile.accountData.accessData.updateNumber.description',
                )}
              </p>

              <br />

              <InputField
                masked
                type="phone"
                field="phoneNumber"
                label={t(
                  'menu.profile.accountData.accessData.updateNumber.title',
                )}
                placeholder="(00)0000-0000"
                onBlur={handleBlur}
                onChange={e => {
                  handleChange(e);
                }}
                setState={e => {
                  setFieldValue('phoneNumber', e);
                }}
                disabled={!canEdit}
                values={values}
                errors={errors}
                touched={touched}
              />
            </Col>
          </Row>

          <Separator />

          <BtnGroup>
            {canEdit && (
              <Button
                key="save"
                onClick={() => handleSubmit()}
                disabled={
                  (errors && errors.phoneNumber) ||
                  (values && !values.phoneNumber)
                }
              >
                {t(
                  'menu.profile.accountData.accessData.updateNumber.buttonsSingleLine.buttonPrimary',
                )}
              </Button>
            )}

            {!canEdit && (
              <Button key="edit" onClick={() => setCanEdit(true)}>
                {t(
                  'menu.profile.accountData.accessData.updateNumber.buttonsSingleLine.buttonPrimary',
                )}
              </Button>
            )}
            <Button
              key="clean"
              onClick={() =>
                history.push(
                  `${PROFILE}/account-settings/account-data`,
                )
              }
              className="white"
            >
              {t(
                'menu.profile.accountData.accessData.updateNumber.buttonsSingleLine.buttonSecondary',
              )}
            </Button>
          </BtnGroup>
        </>
      )}
    </Form>
  );
}
