import React, { useState, useEffect, useMemo } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import {
  VENDORS,
  NEXT_MEDICATION,
  NEXT_FLOCK_LIST,
  NEXT_FLOCK_DETAILS,
} from 'config/routes';
import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';
import EditNextMedication from './EditNextMedication';
import MedicationList from './MedicationList';
import MedicationModals from './MedicationModals';
import { getMedications } from './service';
import t from 'components/i18n';

export default function NextMedication({
  history,
  responsive,
  match: { params },
  user,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [breadCrumb, setBreadCrumb] = useState(null);
  const [medicationList, setMedicationList] = useState([]);
  const [editMedication, setEditMedication] = useState(false);
  const [sendToConfirmation, setSendToConfirmation] = useState(false);
  const [selectedMedicationId, setSelectedMedicationId] = useState(
    null,
  );
  const [isFlockClosed, setIsFlockClosed] = useState(true);
  const [isEditing, setIsEditing] = useState(false); // checks if is a new medication or not
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(null);
  const [removed, setRemoved] = useState(false);
  const [updateItems, setUpdateItems] = useState(false);
  const [displayExcludeModal, setDisplayExcludeModal] = useState(
    false,
  );

  const getTitle = useMemo(() => {
    if (editMedication) {
      if (isEditing) {
        if (sendToConfirmation) {
          return t('next.medication.title.medicine-details');
        }
        return t('next.medication.title.edit-medicine');
      }
      return t('next.medication.title.include-medicine-vaccine');
    }
    return t('next.medication.title.medicine-vaccine');
  }, [editMedication, isEditing, sendToConfirmation]);

  useEffect(() => {
    async function fetchMedicationData(id) {
      setIsLoading(true);
      try {
        const fetchResponse = await getMedications(id);

        setMedicationList(fetchResponse.items);

        setBreadCrumb({
          flockId: fetchResponse.flockId,
          flockNumber: fetchResponse.flockNumber,
          vendorId: fetchResponse.vendorId,
          vendorName: fetchResponse.vendorName,
        });
        setUpdateItems(false);
        setIsFlockClosed(fetchResponse.isFlockClosed);
      } catch (e) {
        console.error('Error on getSummary', e);
      }
      setIsLoading(false);
    }

    if (!editMedication || updateItems) {
      fetchMedicationData(params.id);
    }
  }, [params.id, editMedication, updateItems]);

  useEffect(() => {
    if (removed || success) {
      setUpdateItems(true);
      setSelectedMedicationId(null);
      setSendToConfirmation(false);
      setEditMedication(false);
    }
  }, [removed, success]);

  return (
    <>
      {breadCrumb && (
        <Breadcrumb
          history={history}
          backTo={() =>
            history.push(
              `${NEXT_FLOCK_DETAILS}/${breadCrumb.flockId}`,
            )
          }
          path={[
            t('next.medication.bc.growers'),
            breadCrumb.vendorName,
            t('next.medication.bc.flock', { value: breadCrumb.flockNumber }),
            t('next.medication.bc.medicines-vaccines'),
            editMedication && getTitle,
          ]}
          links={[
            VENDORS,
            `${NEXT_FLOCK_LIST}/${breadCrumb.vendorId}/new`,
            `${NEXT_FLOCK_DETAILS}/${breadCrumb.flockId}`,
            editMedication && `${NEXT_MEDICATION}/${params.id}`,
          ]}
        />
      )}

      <Top>
        <h2>{getTitle}</h2>

        {!editMedication && !isFlockClosed && (
          <Button
            className="tertiary"
            onClick={() => {
              setEditMedication(true);
              setSelectedMedicationId(null);
              setSendToConfirmation(false);
            }}
          >
            {t('next.medication.add-new-medicine')}
          </Button>
        )}
      </Top>

      {!editMedication && (
        <MedicationList
          isLoading={isLoading}
          setEditMedication={setEditMedication}
          list={medicationList}
          setSelectedMedicationId={setSelectedMedicationId}
          sendToConfirmation={sendToConfirmation}
          setSendToConfirmation={setSendToConfirmation}
          setDisplayExcludeModal={setDisplayExcludeModal}
          isFlockClosed={isFlockClosed}
        />
      )}

      {editMedication && (
        <EditNextMedication
          flockID={Number(params.id)}
          setEditMedication={setEditMedication}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          selectedMedicationId={selectedMedicationId}
          setSelectedMedicationId={setSelectedMedicationId}
          sendToConfirmation={sendToConfirmation}
          setSendToConfirmation={setSendToConfirmation}
          setSuccess={setSuccess}
          setMedicationResponse={setResponse}
          setDisplayExcludeModal={setDisplayExcludeModal}
          animalType={user.animalType}
          isFlockClosed={isFlockClosed}
        />
      )}

      {/* Medication modals */}
      <MedicationModals
        success={success}
        medicationResponse={response}
        setMedicationResponse={setResponse}
        updateItems={updateItems}
        setUpdateItems={setUpdateItems}
        setSelectedMedicationId={setSelectedMedicationId}
        selectedMedicationId={selectedMedicationId}
        displayExcludeModal={displayExcludeModal}
        setDisplayExcludeModal={setDisplayExcludeModal}
        setRemoved={setRemoved}
        removed={removed}
      />
      {/* \ Medication modals */}
    </>
  );
}
