import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  DEFAULT_SIZE,
  breakpointTablet,
  breakpointDesktop,
  colorClear,
  colorGrayLight,
  colorGrayDarker,
  colorGrayDarkest,
} from 'components/GlobalStyles/variables';

export const TopStyled = styled.div`
  background-color: ${colorClear};
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};
  display: flex;
  justify-content:${props =>
    props.left ? 'flex-start' : 'space-between'}; 
  align-items: ${props => (props.top ? 'flex-start' : 'center')}; ;
  width: 100%;
  padding: ${props => {
    if (props.padding) {
      return `${props.padding}px`;
    }
    return `${DEFAULT_SIZE}px ${DEFAULT_SIZE * 3}px`;
  }};   
  height:  ${props => (props.padding ? 'auto' : DEFAULT_SIZE * 8)}px;

  &.vendor {
    padding-top: 0;
    padding-bottom: 0;
  }

  h2 {
    font-size: 24px;
    line-height: 36px;
    /* letter-spacing: 0.15px; */
    margin: 0;
  }

  @media (max-width: ${breakpointTablet}px) {
    padding: 16px;
  }

  .top-content {
    margin-right: ${DEFAULT_SIZE * 2}px;
  }

  .top-content-heading {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    margin: 0;

    @media (min-width: ${breakpointTablet}px) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  .top-content-subheading {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${colorGrayDarker};

    &.light {
      color: ${colorGrayLight};
      font-size: 12px;
      line-height: 16px;
    }
  }

  .top-icon-holder {
    margin-right: ${DEFAULT_SIZE * 2}px;
  }

  .top-content {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .top-badge-holder {
    margin-left: auto;

    @media (min-width: ${breakpointTablet}px) {
      margin-left: 0;
    }

    /* @media (min-width: ${breakpointDesktop}px) {
      width: 100%;
      max-width: 376px;
    } */
  }
`;
