import React from 'react';
import moment from 'moment';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { useSelector } from 'react-redux';
import Box from 'components/Container';
import t from 'components/i18n';
import SideBox from 'components/SideBox';
import {
  Heading,
  TitleBlock,
  SectionTitle,
} from 'components/SideBox/SideBox.styled';
import Icon from 'components/Icon';
import { colorTertiary } from 'components/GlobalStyles/variables';

export default function InsuranceDetails({
  title,
  openSideBox,
  setOpenSideBox,
  startDate,
  endDate,
  container,
  files,
}) {
  const user = useSelector(state => state.user);

  return (
    <SideBox displayBox={openSideBox} setDisplayBox={setOpenSideBox}>
      <Box className="side-box">
        {title && (
          <TitleBlock>
            <Heading>{title}</Heading>
          </TitleBlock>
        )}

        <SectionTitle>{t('documentation.insuranceDetails.title')}</SectionTitle>

        <div className="bordered-wrapper">
          <p className="flex">
            <span className="label">{t('documentation.insuranceDetails.start-date')}</span>
            <span className="data">
              {moment(startDate).format('DD ̸̸  MM ̸̸  YYYY')}
            </span>
          </p>
        </div>

        <div className="bordered-wrapper">
          <p className="flex">
            <span className="label">{t('documentation.insuranceDetails.end-date')}</span>
            <span className="data">
              {moment(endDate).format('DD ̸̸  MM ̸̸  YYYY')}
            </span>
          </p>
        </div>

        <SectionTitle>{t('documentation.insuranceDetails.documents')}</SectionTitle>

        {files &&
          files.length > 0 &&
          files.map((file, index) => (
            <div key={file} className="bordered-wrapper">
              <p>
                <a
                  href={`${API_URL}/Blob/Download?fileName=${file}&blobContainerName=${container}&token=${user.token}&client_id=${KONG_CLIENT_ID}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex "
                >
                  <Icon
                    name="icon-clip"
                    color={colorTertiary}
                    size={24}
                  />
                  {file}
                </a>
              </p>
            </div>
          ))}
      </Box>
    </SideBox>
  );
}
