import styled from 'styled-components';
import {
  colorPrimary,
  colorGrayLightest,
  colorGrayUltraLight,
} from 'components/GlobalStyles/variables';

export const TableStyled = styled.div`
  position: relative;

  .details-header {
    &:after {
      content: '';
      width: calc(100% + 32px);
      height: 1px;
      background: ${colorGrayLightest};
      display: block;
      margin-left: -16px;
      top: 1px;
      position: relative;
    }
  }

  h3.details-heading {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  .container-detail {
    display: block;
    background-color: ${props => props.color || colorPrimary};
    height: 3px;
    width: 50px;
    margin: 0 0 13px;
  }

  .column {
    flex-direction: column;
    width: 100%;

    &.mobile {
      padding: 0;
    }

    p {
      margin: 0 0 4px;

      &.small {
        font-size: 12px;
        line-height: 16px;
      }

      &.caps {
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      &.strong {
        font-size: 16px;
      }
    }
  }
`;

export const MobileRow = styled.div`
  &:before {
    content: '';
    display: block;
    height: 8px;
    width: 100%;
    background-color: ${colorGrayUltraLight};
  }
`;
