import React from 'react';
import { useSelector } from 'react-redux';
import TitleGroup from 'components/TitleGroup';
import IconButton from 'components/IconButton';
import { FlockActions, IconList } from '../StyledNextFlock.styled';
import { activitiesOptions } from '../utils';
import t from 'components/i18n';

export default function NextFlockActivities({
  history,
  bgColor,
  flockId,
  hasFal,
  isSwine,
}) {
  const user = useSelector(state => state.user);

  return (
    <FlockActions>
      <TitleGroup
        title={t('next.flock.flockActivities.title')}
        subtitle={t('next.flock.flockActivities.subtitle')}
      />

      <IconList>
        {activitiesOptions(user).map(item => {
          let disabled = hasFal ? item.disabled : true;

          if (isSwine && item.id === 8) {
            // enable Fal Digital item for swine supervisor
            disabled = false;
          }

          return (
            <IconButton
              key={item.label}
              primaryIcon={item.icon}
              caption={item.label}
              bgColor={bgColor}
              action={
                item.action && !disabled
                  ? () => history.push(`${item.action}/${flockId}`)
                  : false
              }
              disabled={disabled}
              soon={item.soon}
              badgeColor="colorGray"
            />
          );
        })}
      </IconList>
    </FlockActions>
  );
}
