import Breadcrumb from 'components/Breadcrumb';
import { colorTertiary } from 'components/GlobalStyles/variables';
import { JustifiedListItem } from 'components/List';
import LoadingHolder from 'components/LoadingHolder';
import Top from 'components/Top';
import {
  CHECKLIST_DETAILS,
  NEXT_FLOCK_LIST,
  VENDORS,
  VENDORS_TABLE,
} from 'config/routes';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { scrollToTop } from 'shared/utils/Helpers';
import Modal from 'components/Forms/FormModal/Modal';
import AnswerChecklist from './AnswerChecklist';
import ConfirmChecklist from './ConfirmChecklist';

// styles
import {
  DescriptionCard,
  DetailsContainer,
  FlexWrapper,
  Separator,
} from './Checklists.styled';
import FlockSelector from './FlockSelector';
import { useChecklist } from './hooks';
import t from 'components/i18n';

export default function ChecklistFill({ history }) {
  const user = useSelector(state => state.user);
  const [
    { details, isLoading },
    { onPickFlock, onGoToDetails, onSetAnswer, onSubmitAnswer },
  ] = useChecklist();
  const [currentStep, setCurrentStep] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    scrollToTop(document.getElementById('checklist-fill-title'));
  }, [currentStep]);

  useEffect(() => {
    if (!details) {
      history.push(`/`);
    }
    if (!currentStep) {
      setCurrentStep(
        Object.keys(details.checklist.questionGroupedByCategory)[0],
      );
    }
  }, [currentStep, details, history]);

  if (!details || !details.checklist || !details.vendor) {
    return null;
  }

  const { checklist, vendor } = details;

  const quantity = Object.keys(checklist.questionGroupedByCategory)
    .length;

  const fillingByCategory = Object.keys(
    checklist.questionGroupedByCategory,
  ).reduce((acum, key) => {
    acum[key] = {
      total: checklist.questionGroupedByCategory[key].length,
      filled: checklist.questionGroupedByCategory[key].filter(
        question => checklist.answerSheet[question.codPerguntaIrn],
      ).length,
    };
    return acum;
  }, {});

  const filledCategories = Object.keys(fillingByCategory).filter(
    key => {
      const { total, filled } = fillingByCategory[key];
      return total === filled;
    },
  );

  return (
    <>
      <Breadcrumb
        history={history}
        path={[
          'Integrados',
          vendor.vendorName,
          t('checklist.fill.bc.title'),
          checklist.indLiquidacaoDesc,
          t('checklist.fill.title'),
        ]}
        links={[
          user && user.type === 'E' ? VENDORS : VENDORS_TABLE,
          `${NEXT_FLOCK_LIST}/${vendor.vendorId}`,
          `${NEXT_FLOCK_LIST}/${vendor.vendorId}?tab=1`,
          `${CHECKLIST_DETAILS}/`,
        ]}
      />

      <LoadingHolder isLoading={isLoading} />

      <Top>
        <h2 id="checklist-fill-title">{t('checklist.fill.title')}</h2>
      </Top>

      <DetailsContainer>
        <FlexWrapper>
          {checklist.selectedFlock && (
            <DescriptionCard>
              <h4>{checklist.indLiquidacaoDesc}</h4>
              <h5>{checklist.codVago1 || checklist.codCheckList}</h5>
              <h5>
                {quantity} {quantity > 1 ? 'questões' : 'questão'}
              </h5>
              <Separator style={{ marginBottom: 0 }} />

              {!!fillingByCategory &&
                Object.keys(fillingByCategory).map(key => (
                  <JustifiedListItem key={key}>
                    <p>{key}</p>
                    <p>
                      <span
                        style={{
                          color: colorTertiary,
                          fontSize: '14px',
                        }}
                      >
                        {fillingByCategory[key].filled}
                      </span>
                      {` / ${fillingByCategory[key].total}`}
                    </p>
                  </JustifiedListItem>
                ))}
            </DescriptionCard>
          )}
          {checklist.selectedFlock && !showConfirmation && (
            <AnswerChecklist
              checklist={checklist}
              onGoBack={onGoToDetails}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              onSetAnswer={onSetAnswer}
              filledCategories={filledCategories}
              onConfirm={() => setShowConfirmation(true)}
            />
          )}
          {checklist.selectedFlock && showConfirmation && (
            <ConfirmChecklist
              checklist={checklist}
              onGoBack={() => setShowConfirmation(false)}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              onSetAnswer={onSetAnswer}
              filledCategories={filledCategories}
              onSubmitAnswer={onSubmitAnswer}
            />
          )}
          {!checklist.selectedFlock && (
            <FlockSelector
              onPickFlock={onPickFlock}
              selectedFlock={checklist.selectedFlock}
              flocks={checklist.flocks}
              onGoBack={onGoToDetails}
            />
          )}
        </FlexWrapper>
      </DetailsContainer>
      <Modal
        noButtons
        opened={!!checklist.submitStatus}
        onCloseModal={() => onGoToDetails()}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              checklist.submitStatus === 'success'
                ? 'check'
                : 'exclamation-triangle'
            }`}
          />
          <h4>
            {checklist.submitStatus === 'success'
              ? t('global.validation.success')
              : t('global.validation.error')}
          </h4>
        </div>

        <p>
          {checklist.submitStatus === 'success'
            ? t('checklist.fill.submit.successfull')
            : t('checklist.fill.submit.error')}
        </p>
      </Modal>
    </>
  );
}
