import React from 'react';
import { Field, Toggle, Label } from '@zendeskgarden/react-forms';
import { DropdownField } from 'components/Forms/Fields';

import t from 'components/i18n';
import Icon from 'components/Icon';
import { ToggleRangeStyled } from './ToggleRange.styled';

export default function ToggleRange(props) {
  const {
    canExclude,
    excludeFunction,
    heading,
    subheading,
    optionLabel,
    setFieldValue,
    startRange = [],
    endRange = [],
    startField,
    endField,
    isActive,
    inactiveMessage,
    disabled,
    fullDisabled,
    isRequired,
    isDefault,
    values,
    errors,
    touched,
  } = props;

  return (
    <ToggleRangeStyled className="flex" large={!canExclude}>
      <div className="title">
        <strong>{heading}</strong>
        <p>{subheading}</p>
      </div>

      <div className="flex">
        {values[isActive] ? (
          <>
            <div className="dropdown-wrapper">
              <DropdownField
                field={startField}
                label=""
                options={startRange}
                onSelect={item => {
                  setFieldValue(startField, item);
                  setFieldValue(isDefault, false);
                }}
                optionLabel={optionLabel}
                disabled={fullDisabled}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <p className="label">{t("toggleRange.fromTo")}</p>
            <div className="dropdown-wrapper">
              <DropdownField
                field={endField}
                label=""
                options={endRange}
                onSelect={item => {
                  setFieldValue(endField, item);
                  setFieldValue(isDefault, false);
                }}
                optionLabel={optionLabel}
                disabled={fullDisabled}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <p className="label">{t("toggleRange.days")}</p>
          </>
        ) : (
          <p className="inactive animated fadeIn">
            {inactiveMessage}
          </p>
        )}
        <div className="toggle-wrapper flex center">
          <Field>
            <Toggle
              field={isActive}
              disabled={fullDisabled || isRequired}
              checked={values && values[isActive]}
              onChange={e => {
                setFieldValue(isActive, !values[isActive]);
                setFieldValue(isDefault, false);
              }}
            >
              <Label />
            </Toggle>
          </Field>
        </div>

        {canExclude && (
          <button
            key="exclude"
            onClick={() => excludeFunction()}
            type="button"
            className="unstyled-button"
            disabled={fullDisabled || isRequired}
          >
            <Icon name="icon icon-trash" size={30} />
          </button>
        )}
      </div>
    </ToggleRangeStyled>
  );
}
