import React from 'react';
import {
  Container,
  Separator,
  InvoiceCardWrapper,
  Card,
  Header,
  Content,
  Title,
  Subtitle,
  Description,
  Button,
  InvoiceDashboardHeader,
  Navigator,
  RoundedButton,
  NoActivities,
} from './DashboardPreview.styled';
import DateAndTime from 'components/DateAndTime';

import * as routes from 'config/routes';
import Warning from '../assets/warning.svg';
import { useState } from 'react';
import Icon from 'components/Icon';
import t from 'components/i18n';
import { useEffect } from 'react';
import { getFlockInvoice } from './service';
import moment from 'moment';
import LoadingHolder from 'components/LoadingHolder';

function InvoiceCard(props) {
  const { date, flock, houses = [], flockId, history } = props;

  return (
    <Card>
      <Header>
        <DateAndTime>
          {moment(date).format('DD/MM/YYYY • HH:mm')}
        </DateAndTime>
        <img src={Warning} />
      </Header>
      <Content>
        <Title>{t('quickAccessCard.title.pendingInvoice')}</Title>
        <Subtitle>
          {t('global.word.flock-value', { value: flock })}
        </Subtitle>
        <Description>
          <p>{t('global.word.slaughter')}</p>
          <p>
            {t('global.word.houses', { value: houses.join(', ') })}
          </p>
        </Description>
      </Content>
      <Button
        role="buttom"
        onClick={() => history.push(`${routes.INVOICE}/${flockId}`)}
      >
        {t('quickAccessCard.button.sendInvoice')}
      </Button>
    </Card>
  );
}

function InvoiceDashboardPreview(props) {
  const { history } = props;

  const [page, setPage] = useState(1);
  const [limit] = useState(4);
  const [data, setData] = useState(null);
  const [metadata, setMetadata] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      setLoading(true);

      const filterData = data => {
        return (data || []).filter(
          invoice => invoice.status === 'Pendente',
        );
      };

      const result = await getFlockInvoice(page, limit);

      if (!result.success) {
        return;
      }

      const {
        data: { flocks, metadata },
      } = result

      setData(filterData(flocks));
      setMetadata(metadata);
      setLoading(false);
    }

    fetch();
  }, [page, limit]);

  const isFirstPage = page === 1;
  const isLastPage =
    page === Math.ceil((metadata.totalItems || 1) / limit);

  return (
    <Container>
      <InvoiceDashboardHeader className="header">
        <h2>{t('dashboard.sectionQuickAccess.title')}</h2>
        <Navigator>
          <RoundedButton
            disabled={isFirstPage}
            onClick={() =>
              !isFirstPage && setPage(currentPage => currentPage - 1)
            }
          >
            <Icon name="icon-chevron-left" size={24} />
          </RoundedButton>
          <RoundedButton
            disabled={isLastPage}
            onClick={() =>
              !isLastPage && setPage(currentPage => currentPage + 1)
            }
          >
            <Icon name="icon-chevron-right" size={24} />
          </RoundedButton>
        </Navigator>
      </InvoiceDashboardHeader>
      <Separator />
      {loading && <LoadingHolder isLoading={loading} />}
      {(!data || (data && data.length === 0)) && !loading && (
        <NoActivities>
          <Icon name="icon icon-check-circle" size={72} />
          <p className="title">{t('quickAccess.empty.title')}</p>
          <p className="subtitle">
            {t('quickAccess.empty.subtitle')}
          </p>
        </NoActivities>
      )}
      {!loading && (
        <InvoiceCardWrapper>
          {(data || []).map(flock => (
            <InvoiceCard
              date={flock.createdDate}
              flock={flock.flock}
              houses={flock.houses}
              flockId={flock.flockId}
              history={history}
            />
          ))}
        </InvoiceCardWrapper>
      )}
    </Container>
  );
}

export default InvoiceDashboardPreview;
