import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Icon from 'components/Icon';
import { Box } from './ColoredCard.styled';
import t from 'components/i18n';

export default function ColoredCard({
  icon,
  bgColor,
  title,
  text,
  additional = false,
  buttonLabel = false,
  buttonAction,
  download,
  full = false,
}) {
  const themeContext = useContext(ThemeContext);

  return (
    <div>
      <Box color={themeContext.styles.palette[bgColor]} full={full}>
        <div className="flex">
          <div className="icon-wrapper">
            <Icon name={icon} />
          </div>
          <div className="text">
            {title && <p className="title">{title}</p>}
            <p>{text}</p>
            {additional && <p>{additional}</p>}
            {download ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={buttonAction}
                className="white no-border"
                type="button"
                full
              >
                {t(buttonLabel)}
              </a>
            ) : (
              buttonLabel && (
                <button
                  type="button"
                  onClick={() => buttonAction()}
                  full={full}
                >
                  {t(buttonLabel)}
                </button>
              )
            )}
          </div>
        </div>
      </Box>
    </div>
  );
}
