import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import uid from 'uuid';
import * as routes from 'config/routes';
import { ThemeContext } from 'styled-components';
import Box from 'components/Container';
import moment from 'moment';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import { getAnimalIcon } from 'shared/utils/Helpers';
import Icon from 'components/Icon';
import { Overline, Title, SubTitle, Info } from 'components/List';
import { getWeeklyEvents } from '../service';
import {
  FeedWrapper,
  FeedContainer,
  FeedItem,
  Separator,
} from './ScheduleEvents.styled';
import t from 'components/i18n';

export default function ScheduleEvents(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const themeContext = useContext(ThemeContext);

  const user = useSelector(state => state.user);
  const animalIcon = useMemo(() => getAnimalIcon(user.animalType), [
    user,
  ]);

  const getColorsAndIcons = {
    abate: {
      color: 'colorDanger',
      icon: animalIcon,
      link: 'slaughter',
    },
    alojamento: {
      color: 'colorPrimary',
      icon: 'icon-warehouse',
      link: 'placement',
    },
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const schedule = await getWeeklyEvents();
        setData(schedule);
      } catch (e) {
        console.error(e);
      }

      setIsLoading(false);
    }

    fetchData();
  }, []);

  const classNames = `scheduling-feed ${props.className}`;

  return (
    <Box slim className={classNames}>
      <FeedWrapper>
        <header className="header">
          <h2>{t("next.scheduleEvents.events.title")}</h2>
        </header>

        <Separator />

        <section>
          <FeedContainer>
            <LoadingHolder isLoading={isLoading} />

            {!isLoading && !data.length && (
              <NoContentTab icon="icon-close-circle">
                <p>
                  {t('schedule.unavailable')} <br />{' '}
                </p>
              </NoContentTab>
            )}

            {data.length > 0 && (
              <div className="scheduling-feed-elements scheduling-feed-item">
                {data.map((feed, key) => (
                  <FeedItem
                    key={uid()}
                    type={feed.type}
                    onClick={() => {
                      props.history.push(
                        `${routes.SCHEDULE_SEARCH}/${
                          getColorsAndIcons[
                            feed.eventType.toLowerCase()
                          ].link
                        }/${feed.vendorCode}`,
                      );
                    }}
                  >
                    <div
                      className="flex center"
                      style={{
                        color:
                          themeContext.styles.palette[
                            getColorsAndIcons[
                              feed.eventType.toLowerCase()
                            ].color
                          ],
                      }}
                    >
                      <Overline>{feed.eventType}</Overline>
                      <Icon
                        name={`icon ${
                          getColorsAndIcons[
                            feed.eventType.toLowerCase()
                          ].icon
                        }`}
                        size={20}
                      />
                    </div>
                    <Title>{feed.vendorName}</Title>
                    <SubTitle>Código {feed.vendorCode}</SubTitle>
                    <Info className="flex">
                      <p>
                        {moment(feed.eventDate).format('DD ̸̸  MM ̸̸  YYYY')}
                      </p>
                      <p>
                        {feed.flockQuantity}{' '}
                        {t('next.scheduleEvents.events.flocks')}
                      </p>
                    </Info>
                    {key !== data.length && <Separator />}
                  </FeedItem>
                ))}
              </div>
            )}
          </FeedContainer>
        </section>
      </FeedWrapper>
    </Box>
  );
}
