import styled from 'styled-components';
import { rgba } from 'polished';
import {
  colorClear,
  colorGrayDarkest,
  colorGrayLighter,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

export const StyledInternalMenu = styled.section`
  position: relative;
  background-color: ${colorClear};
  border-radius: 12px;

  ul {
    padding: 0;
    margin: 0 0 20px;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colorGrayDarkest};
    font-family: ${fontFamilyTextBold};
    font-size: 16px;
    line-height: 24px;
    list-style: none;
    padding: 11px 25px 12px 16px;
    border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.2)};
    cursor: pointer;
    transition: 0.2s ease color;

    :hover {
      color: ${colorGrayLighter};
    }

    :last-of-type {
      border: none;
    }

    .icon {
      font-size: 24px;
      color: ${colorGrayLighter};
    }
  }
`;
