import React, { useState, useEffect } from 'react';
import { Field, Toggle, Label } from '@zendeskgarden/react-forms';

import { ToggleOptionsStyled } from './ToggleOptions.styled';

export default function ToggleOptions({
  name,
  checked = false,
  label,
  onChange,
  children,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(checked);
  }, [checked]);

  return (
    <ToggleOptionsStyled className="toggle-options">
      <Field>
        <Toggle
          name={name}
          checked={isOpen}
          onChange={e => onChange(e)}
        >
          <Label className="noCaps toggle">{label}</Label>
        </Toggle>
      </Field>
      {isOpen && (
        <div className="options animated fadeIn">{children}</div>
      )}
    </ToggleOptionsStyled>
  );
}
