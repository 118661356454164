import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import { BtnGroup } from 'components//Forms/BtnGroup/BtnGroup.styled';
import { Circular } from 'components/CircleAndTitle/CircleAndTitle.styled';
import { Button } from '@zendeskgarden/react-buttons';
import Container from 'components/Container';
import { Form, Subheading } from 'components/Forms/Forms.styled';
import {
  InputField,
  TimePickerField,
  AutoCompleteDropdownField,
} from 'components/Forms/Fields';
import {
  getHealthProblems,
  getMaterialTypes,
} from 'containers/AgroBRF/FlockList/service';
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import { colorTertiary } from 'components/GlobalStyles/variables';
import NextMedicationConfirmation from './NextMedicationConfirmation';
import t from 'components/i18n';

export default function MedicationForm({
  isValid,
  selectedMedication,
  setSelectedMedication,
  sendToConfirmation,
  setSendToConfirmation,
  setEditMedication,
  setDisplayExcludeModal,
  isFlockClosed,
  isEditing,
  setIsEditing,
  isSubmitting,
  setFieldValue,
  handleSubmit,
  handleChange,
  handleBlur,
  resetForm,
  values,
  errors,
  touched,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const [healthProblems, setHealthProblems] = useState([]);

  const getMaterialTypesList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getMaterialTypes();
      setMaterialList(response);
    } catch (e) {
      console.error('Error on getSummary', e);
    }
    setIsLoading(false);
  }, []);

  const getHealthProblemsList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getHealthProblems();
      setHealthProblems(response);
    } catch (e) {
      console.error('Error on getSummary', e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (values.id) {
      setFieldValue('id', values.id);
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [setFieldValue, setIsEditing, values.id]);

  useEffect(() => {
    if (!sendToConfirmation) {
      getMaterialTypesList();
      getHealthProblemsList();
    }
  }, [
    getHealthProblemsList,
    getMaterialTypesList,
    sendToConfirmation,
  ]);

  return (
    <Container slim hideDetail={!sendToConfirmation}>
      <Form autoComplete="off">
        {!sendToConfirmation ? (
          <>
            <Subheading>
              <Circular bgColor={colorTertiary}>{t('form.next.nextMedicationConfirmation.medicine.subheading-number')}</Circular>
              {t('form.next.nextMedicationConfirmation.medicine.subheading-description')}
            </Subheading>

            <Row>
              <Col>
                <AutoCompleteDropdownField
                  field="material"
                  label={t('form.next.nextMedicationConfirmation.field.material.label')}
                  placeholder={t('form.next.nextMedicationConfirmation.field.material.placeholder')}
                  onSelect={e => {
                    setFieldValue('material', e);
                  }}
                  loadingLabel={t('global.loading')}
                  optionLabel="description"
                  caption="code"
                  noMatchLabel={t('form.next.nextMedicationConfirmation.field.material.no-matching')}
                  selectObject
                  options={materialList}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                {/* Lote */}
                <InputField
                  type="number"
                  field="vaccineBatch"
                  label={t('form.next.nextMedicationConfirmation.field.vaccineBatch.label')}
                  placeholder={t('form.next.nextMedicationConfirmation.field.vaccineBatch.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \ Lote */}
              </Col>
            </Row>

            <Row>
              <Col>
                {/* Lote */}
                <InputField
                  type="number"
                  field="quantity"
                  label={t('form.next.nextMedicationConfirmation.field.quantity.label', { value: values.material ? values.material.measurement : '' })}
                  placeholder={t('form.next.nextMedicationConfirmation.field.quantity.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \ Lote */}
              </Col>
            </Row>

            <Separator gap={8} />
            <br />

            <Subheading>
              <Circular bgColor={colorTertiary}>{t('form.next.nextMedicationConfirmation.date.subheading-number')}</Circular>
              {t('form.next.nextMedicationConfirmation.date.subheading-description')}
            </Subheading>

            <Row>
              <Col md={6}>
                {/* Data de início */}
                <InputField
                  type="date"
                  field="startDate"
                  label={t('form.next.nextMedicationConfirmation.field.startDate.label')}
                  placeholder={t('form.next.nextMedicationConfirmation.field.startDate.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \Data de início */}
              </Col>

              <Col md={6}>
                {/* Horário */}
                <TimePickerField
                  type="time"
                  field="startTime"
                  label={t('form.next.nextMedicationConfirmation.field.startTime.label')}
                  timeCaption={t('form.next.nextMedicationConfirmation.field.startTime.placeholder')}
                  onChange={e => setFieldValue('startTime', e)}
                  onBlur={handleBlur}
                  dateFormat="HH:mm"
                  timeIntervals={15}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \ Horário */}
              </Col>
            </Row>

            <Separator gap={8} />
            <br />

            <Subheading>
              <Circular bgColor={colorTertiary}>
                {t('form.next.nextMedicationConfirmation.treatment.subheading-number')}
              </Circular>
                {t('form.next.nextMedicationConfirmation.treatment.subheading-description')}
            </Subheading>

            <Row>
              <Col md={6}>
                {/* Data de término */}
                <InputField
                  type="date"
                  field="endDate"
                  label={t('form.next.nextMedicationConfirmation.field.endDate.label')}
                  placeholder={t('form.next.nextMedicationConfirmation.field.endDate.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \Data de término */}
              </Col>

              <Col md={6}>
                {/* Horário */}
                <TimePickerField
                  type="time"
                  field="endTime"
                  label={t('form.next.nextMedicationConfirmation.field.endTime.label')}
                  timeCaption={t('form.next.nextMedicationConfirmation.field.endTime.placeholder')}
                  onChange={e => setFieldValue('endTime', e)}
                  onBlur={handleBlur}
                  dateFormat="HH:mm"
                  timeIntervals={15}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \ Horário */}
              </Col>
            </Row>

            <Separator gap={8} />
            <br />

            <Subheading>
              <Circular bgColor={colorTertiary}>
                {t('form.next.nextMedicationConfirmation.treatment-data.subheading-number')}
              </Circular>
              {t('form.next.nextMedicationConfirmation.treatment-data.subheading-description')}
            </Subheading>

            <Row>
              <Col>
                <AutoCompleteDropdownField
                  field="healthProblem"
                  label={t('form.next.nextMedicationConfirmation.field.healthProblem.label')}
                  placeholder={t('form.next.nextMedicationConfirmation.field.healthProblem.placeholder')}
                  onSelect={e => {
                    setFieldValue('healthProblem', e);
                  }}
                  loadingLabel={t('global.loading')}
                  optionLabel="name"
                  noMatchLabel={t('form.next.nextMedicationConfirmation.field.healthProblem.no-matching')}
                  selectObject
                  options={healthProblems}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>

            <Separator />

            {/* \ Botões */}
            <BtnGroup>
              <Button
                key="sendtoConfirm"
                className="tertiary"
                onClick={() => {
                  setSendToConfirmation(true);
                  setIsEditing(false);
                }}
                disabled={!isValid}
              >
                {t("global.button.send")}
              </Button>
              <Button
                key="clean"
                onClick={() => {
                  resetForm();
                }}
                className="white"
              >
                {t("global.button.clear")}
              </Button>
            </BtnGroup>
            {/* \ Botões */}
          </>
        ) : (
          <NextMedicationConfirmation
            values={{ ...values }}
            isSubmitting={isSubmitting}
            backToForm={() => setSendToConfirmation(false)}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            isValid={isValid}
            selectedMedication={selectedMedication}
            setSelectedMedication={setSelectedMedication}
            isEditing={isEditing}
            setEditMedication={setEditMedication}
            setSendToConfirmation={setSendToConfirmation}
            setDisplayExcludeModal={setDisplayExcludeModal}
            isFlockClosed={isFlockClosed}
          />
        )}
      </Form>
    </Container>
  );
}
