import styled from 'styled-components';
import {
  colorGrayLightest,
  colorGrayDarker,
  colorGrayLighter,
  colorGrayLight,
} from 'components/GlobalStyles/variables';

export const GroupTitle = styled.div`
  padding: 16px 0 11px 30px;
  &:after {
    content: '';
    display: block;
    width: calc(100% + 30px);
    height: 1px;
    background-color: ${colorGrayDarker};
    opacity: 0.12;
    margin-top: 12px;
    position: relative;
    right: 30px;
  }
`;

export const GroupOptions = styled.div`
  padding-left: 20px;
`;

export const Close = styled.div`
  display: inline-block;
  font-size: 20px;
  position: ${({ isImage }) => isImage ? 'absolute' : 'relative'};
  top: ${({ isImage }) => isImage ? '0' : '-1px'};
  right: ${({ isImage }) => isImage ? '0' : '-1px'};
  color: ${colorGrayLighter};
  cursor: pointer;

  &.big {
    font-size: 32px;

    span {
      position: relative;
      ${({ isImage }) => isImage && 'top: -5px;'}
    }

    &:before {
      content: '';
      position: absolute;
      background-color: ${colorGrayLightest};
      display: block;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      z-index: 0;
    }
  }
`;

export const RoundedItem = styled.div`
  display: inline-flex;
  align-items: center;
  background: ${colorGrayLightest};
  border-radius: 16px;
  font-size: 14px;
  padding: 2px 8px 2px;
  cursor: pointer;
  margin: 0 12px 0 0;
  margin-right: 1rem;
  word-break: break-word;

  p {
    margin: 0;
    display: inline-block;
    color: ${colorGrayDarker};

    a {
      color: ${colorGrayDarker};
      &:hover {
        color: ${colorGrayLight};
      }
    }

    & ${Close} {
      margin-left: 6px;
    }
  }
`;
