import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  colorGrayDarkest,
  breakpointTablet,
  DEFAULT_SIZE,
} from 'components/GlobalStyles/variables';

export const FeedWrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.15px;
      margin: 0;

      @media (min-width: ${breakpointTablet}px) {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
`;

export const Separator = styled.hr`
  margin: 16px 0 12px;
  opacity: 0.12;
`;

export const FeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 385px;
  min-height: 385px;
  overflow: auto;
  padding-top: 10px;

  .scheduling-feed-sidebar {
    position: sticky;
    top: 0;
    border-right: 1px solid ${rgba(colorGrayDarkest, 0.12)};
    margin-right: 40px;

    ul,
    li {
      margin: 0;
      padding: 0;
    }

    li {
      display: block;
      margin-bottom: ${DEFAULT_SIZE * 2}px;

      button {
        cursor: pointer;
        border: 0;
        background: none;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.75px;
        color: ${rgba(colorGrayDarkest, 0.54)};

        &:focus {
          outline: 0 none;
        }
      }

      &.active {
        button {
          color: ${colorGrayDarkest};
        }
      }
    }
  }

  .scheduling-feed-elements {
    flex: 1;
    min-width: 255px;
  }
`;

export const FeedItem = styled.div`
  max-width: 256px;
  cursor: pointer;
`;
