import React, { createContext, useReducer } from 'react';

const CadecContext = createContext();

const initialState = {
  selectedCadec: {},
  cadecSubmitResponse: null,
  cadecToaster: false,
  successOnSubmit: null,
};

const types = {
  SET_SUBMIT_CADEC_RESPONSE: 'SET_SUBMIT_CADEC_RESPONSE',
  SET_SELECTED_CADEC: 'SET_SELECTED_CADEC',
  SET_FILE_1: 'SET_FILE_1',
  SET_FILE_2: 'SET_FILE_2',
  REMOVE_FILE_1: 'REMOVE_FILE_1',
  REMOVE_FILE_2: 'REMOVE_FILE_2',
  SET_DISPLAY_CADEC_TOASTER: 'SET_DISPLAY_CADEC_TOASTER',
  SET_SUCCESS_ON_SUBMIT: 'SET_SUCCESS_ON_SUBMIT',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_SELECTED_CADEC:
      return {
        ...state,
        selectedCadec: action.payload,
      };
    case types.SET_FILE_1:
      return {
        ...state,
        selectedCadec: {
          ...state.selectedCadec,
          fileName1: action.payload,
        },
      };
    case types.SET_FILE_2:
      return {
        ...state,
        selectedCadec: {
          ...state.selectedCadec,
          fileName2: action.payload,
        },
      };
    case types.REMOVE_FILE_1:
      return {
        ...state,
        selectedCadec: {
          ...state.selectedCadec,
          fileName1: '',
        },
      };
    case types.REMOVE_FILE_2:
      return {
        ...state,
        selectedCadec: {
          ...state.selectedCadec,
          fileName2: '',
        },
      };
    case types.SET_SUBMIT_CADEC_RESPONSE:
      return {
        ...state,
        cadecSubmitResponse: action.payload,
      };
    case types.SET_DISPLAY_CADEC_TOASTER:
      return {
        ...state,
        cadecToaster: action.payload,
      };
    case types.SET_SUCCESS_ON_SUBMIT:
      return {
        ...state,
        successOnSubmit: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const actions = dispatch => ({
  setSelectedCadec: cadec =>
    dispatch({
      type: types.SET_SELECTED_CADEC,
      payload: cadec,
    }),
  setFile1: file =>
    dispatch({
      type: types.SET_FILE_1,
      payload: file,
    }),
  setFile2: file =>
    dispatch({
      type: types.SET_FILE_2,
      payload: file,
    }),
  removeFile1: cadec =>
    dispatch({
      type: types.REMOVE_FILE_1,
      payload: cadec,
    }),
  removeFile2: cadec =>
    dispatch({
      type: types.REMOVE_FILE_2,
      payload: cadec,
    }),
  setCadecSubmitResponse: response => {
    dispatch({
      type: types.SET_SUBMIT_CADEC_RESPONSE,
      payload: response,
    });
  },
  setCadecSuccessSubmit: response => {
    dispatch({
      type: types.SET_SUCCESS_ON_SUBMIT,
      payload: response,
    });
  },
  setDisplayCadecToaster: response =>
    dispatch({
      type: types.SET_DISPLAY_CADEC_TOASTER,
      payload: response,
    }),
});

const CadecContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CadecContext.Provider
      value={{
        ...state,
        dispatch,
        actions: actions(dispatch),
      }}
    >
      {props.children}
    </CadecContext.Provider>
  );
};

export { CadecContext, CadecContextProvider };
