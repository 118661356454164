import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import moment from 'moment';
import * as routes from 'config/routes';
import { scrollToTop } from 'shared/utils/Helpers';
import Top from 'components/Top';
import IconAndText from 'components/IconAndText';
import LoadingHolder from 'components/LoadingHolder';
import Breadcrumb from 'components/Breadcrumb';
import CardWrapper from 'components/CardWrapper';
import Modal from 'components/Forms/FormModal/Modal';
import VisitCircuitWrapper from './VisitCircuitWrapper';
import { VisitStyled } from './VisitCircuit.styled';
import { getLogbookQuestions, updateActionPlan } from './service';
import { getLogBookItemDetails } from '../LogBook/service';
import { useChecklist } from '../Checklists/hooks';
import t from 'components/i18n';
import { sortBy } from 'lodash';

export default function OpenedVisitCircuit({
  history,
  match: { params },
  user,
}) {
  const [isLoading, setIsLoading] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState(null);
  const [displayPlan, setDisplayPlan] = useState(false);
  const [displayOrientation, setDisplayOrientation] = useState(false);
  const [actionPlanDetails, setActionPlanDetails] = useState(null);
  const [actionPlanEdit, setActionPlanEdit] = useState(null);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [hasContent, setHasContent] = useState(null);
  const [
    confirmationBackVisibility,
    setConfirmationBackVisibility,
  ] = useState(false);

  const [{ details }] = useChecklist();

  const { vendor = {}, checklist = {} } = details || {};

  useEffect(() => {
    async function getDetails(id) {
      setIsLoading(true);
      try {
        const response = await getLogBookItemDetails(id);
        setAdditionalInfo(response);
      } catch (e) {
        console.error('Error on get logbook details', e);
      }
      setIsLoading(false);
    }

    getDetails(params.id);
  }, [params.id]);

  useEffect(() => {
    async function getChecklistsQuestions(
      vendorCode,
      logbookDate,
      logbookItemTypeId,
      epidemiologicalCore,
      flocks
    ) {
      setIsLoading(true);

      try {
        const response = await getLogbookQuestions({
          vendor: vendorCode,
          logbookDate,
          logbookItemTypeId,
          epidemiologicalCore,
          flocks: flocks && Array.isArray(flocks) && flocks.map(item => item.flock)
        });

        setQuestionList(sortBy(response, (value) => value.id));
      } catch (e) {
        console.error('Error on get checklist questions', e);
      }
      setIsLoading(false);
    }

    if (additionalInfo && additionalInfo.epidemiologicalCore) {
      getChecklistsQuestions(
        additionalInfo.vendorCode,
        additionalInfo.visitDate,
        additionalInfo.visitTypeId,
        additionalInfo.epidemiologicalCore,
        additionalInfo.flocks
      );
    }
  }, [additionalInfo, checklist.id, vendor.vendorCode]);

  useEffect(() => {
    scrollToTop(document.getElementById('main'));
  }, [displayPlan, displayOrientation, selectedQuestions]);

  useEffect(() => {
    const custonHashChange = (event) => {
      event.preventDefault();
  
      if (hasContent) {
        setConfirmationBackVisibility(true);
      }
    }

    window.addEventListener('hashchange', custonHashChange);
    return () => {
      window.removeEventListener('hashchange', custonHashChange);
    };
  }, []);

  const flockList = useMemo(
    () => (
      <CardWrapper title={t('next.visitCircuit.openedVisitCircuit.flockList.card.title')}>
        <ul>
          {additionalInfo &&
            additionalInfo.flocks &&
            additionalInfo.flocks.length > 0 &&
            additionalInfo.flocks.map(e => {
              return (
                <li key={e.flock}>
                  <span>{t("next.visitCircuit.openedVisitCircuit", { value: e.flock })}</span>
                  <span className="right">
                    {e.age} {e.age.length === 1 ? t('next.visitCircuit.openedVisitCircuit.flockList.card.day') : t('next.visitCircuit.openedVisitCircuit.flockList.card.days')}
                  </span>
                </li>
              );
            })}
        </ul>
      </CardWrapper>
    ),
    [additionalInfo],
  );

  const breadcrumbPaths = useCallback(() => {
    const paths = [
      {
        label: t('next.visitCircuit.openedVisitCircuit.bc.logbook'),
        link: routes.LOGBOOK
      },
      {
        label: t('next.visitCircuit.openedVisitCircuit.bc.visitCircuit'),
        link: `${routes.VISIT_CIRCUIT}/${params.id}`
      },
    ];

    if (displayPlan && !displayOrientation) {
      paths.push({ label: t('next.visitCircuit.openedVisitCircuit.bc.actionPlan') });
    }

    if (displayOrientation) {
      paths.push({ label: t('next.visitCircuit.openedVisitCircuit.bc.orientation') });
    }

    return paths;
  }, [displayPlan, displayOrientation, params.id]);

  const removePlanFromQuestion = useCallback(() => {
    setQuestionList(oldList =>
      oldList.map(item => {
        if (item.id === selectedQuestions.id) {
          return { ...item, plan: null };
        }

        return item;
      }),
    );
    setDisplayDeleteModal(false);
  }, [selectedQuestions]);

  const handleChangeExecuted = useCallback(
    async (actionPlanId, payload) => {
      try {
        await updateActionPlan(actionPlanId, payload);
      } catch (e) {
        console.error('Error on update action plan', e);
      }
    },
    [],
  );

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() => hasContent ? setConfirmationBackVisibility(true) : history.push(routes.LOGBOOK)}
        path={breadcrumbPaths().map(item => item.label)}
        links={breadcrumbPaths().map(item => item.link)}
      />

      <LoadingHolder isLoading={isLoading} />

      {additionalInfo && (
        <>
          <Top style={{ height: 84 }} padding={0}>
            <IconAndText
              title={additionalInfo.visitType}
              iconBackGround="colorPrimary"
              icon="icon-calendar-alt"
              subtitle={moment(additionalInfo.visitDate).format(
                'DD ̸̸  MM ̸̸  YYYY',
              )}
              additional={t("next.visitCircuit.openedVisitCircuit.aditional-info.badge", { value: additionalInfo.epidemiologicalCore })}
              noPadding
            />
          </Top>

          <VisitStyled>
            <VisitCircuitWrapper
              {...additionalInfo}
              user={user}
              history={history}
              params={params}
              vendor={vendor}
              handleChangeExecuted={handleChangeExecuted}
              flockList={flockList}
              setHasContent={setHasContent}
              displayPlan={displayPlan}
              displayOrientation={displayOrientation}
              setDisplayOrientation={setDisplayOrientation}
              setDisplayPlan={setDisplayPlan}
              displayDetails={actionPlanDetails}
              setDisplayDetails={setActionPlanDetails}
              displayPlanEdit={actionPlanEdit}
              setDisplayPlanEdit={setActionPlanEdit}
              flocks={(additionalInfo.flocks || []).map(item => item.flock)}
              logbookItemId={additionalInfo.id}
              questionList={questionList}
              setQuestionList={setQuestionList}
              displayDeleteModal={displayDeleteModal}
              setDisplayDeleteModal={setDisplayDeleteModal}
              selectedQuestions={selectedQuestions}
              setSelectedQuestions={setSelectedQuestions}
              selectedSchedule={selectedSchedule}
              setSelectedSchedule={setSelectedSchedule}
              epidemiologicalCore={additionalInfo.epidemiologicalCore}
            />

            <Modal
              warning
              opened={displayDeleteModal}
              confirm={() => {
                removePlanFromQuestion();
              }}
              deny={() => setDisplayDeleteModal(false)}
              onCloseModal={null}
            >
              <span className="icon icon-exclamation-triangle" />
              <h4>{t('next.visitCircuit.openedVisitCircuit.action-plan.remove.title')}</h4>
              <p>{t('next.visitCircuit.openedVisitCircuit.action-plan.remove.subtitle')}</p>
            </Modal>
          </VisitStyled>
        </>
      )}

      {confirmationBackVisibility && (
        <Modal
          warning
          opened={confirmationBackVisibility}
          confirm={() => history.push(routes.LOGBOOK)}
          deny={() => setConfirmationBackVisibility(false)}
          onCloseModal={() => setConfirmationBackVisibility(false)}
          confirmLabel={t('global.button.yes')}
          denyLabel={t('button.global.cancel')}
        >
          <span className="icon icon-exclamation-triangle" />
          <h4>{t('global.modal.title.attention')}</h4>
          <p>{t('visitCircuit.exit-confirmation')}</p>
        </Modal>
      )}
    </>
  );
}
