const getFromLocalStorage = (key, parse = false) => {
  if (!key || !window.localStorage) {
    return null;
  }

  const stored = window.localStorage.getItem(key);
  if (!stored) {
    return null;
  }

  if (parse) {
    return JSON.parse(stored);
  }
  return stored;
};

export default getFromLocalStorage;
