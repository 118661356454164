// @flow

import React, { useState, useMemo } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { PROFILE } from 'config/routes';
import Modal from 'components/Forms/FormModal/Modal';
import PasswordRecoveryForm from 'containers/AgroBRF/Forms/PasswordRecoveryForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { createPassword } from '../../../service';
import t from 'components/i18n';

const validateSchema = () =>
  yup.object().shape({
    newPassword: yup
      .string()
      .required(t('global.status.required-field'))
      .min(6, t('global.validation.min-character-length', { length: 6 })),
    passwordConfirmed: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        t('menu.profile.accountData.updatePassword.confirm-new-password'),
      )
      .required(t('menu.profile.accountData.updatePassword.required-new-password')),
  });

export default function Recover({
  match: { params },
  history,
  user,
  setEditUser,
  setSelected,
  isValidating,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const successMessage = useMemo(
    () => t('global.validation.password-created-successfully'),
    [t],
  );

  const modalErrorMessage = useMemo(
    () => t('global.validation.password-created-error'),
    [t],
  );

  const initialValues = useMemo(
    () => ({
      newPassword: null,
      passwordConfirmed: null,
    }),
    [],
  );

  function submitForm(
    values,
    { setSubmitting, setErrors, isValidating },
  ) {
    const formData = {
      documentNumber: user.cnpj || user.cpf,
      password: values.passwordConfirmed,
    };

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await createPassword(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }

    sendData(formData);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate(validateSchema)}
        onSubmit={submitForm}
        render={formikProps => (
          <PasswordRecoveryForm
            {...formikProps}
            isValidating={isValidating}
            message={errorMessage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            success={success}
            history={history}
            user={user}
            {...props}
          />
        )}
      />

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
          history.push(`${PROFILE}/account-settings`);
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>{success ? successMessage : modalErrorMessage}</p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
