// @flow

import React, { useState } from 'react';
import Box from 'components/Container';
import Welcome from 'containers/OnePage/Login/Welcome';
import SignIn from 'containers/OnePage/Login/SignIn';
import SignUp from 'containers/OnePage/Login/SignUp';
import SideBox from 'components/SideBox';
import Authentication from 'containers/OnePage/Login/Authentication';
import CreatePassword from 'containers/OnePage/Login/CreatePassword';
import RecoverPassword from 'containers/OnePage/Login/RecoverPassword';
import Success from 'containers/OnePage/Login/Success';
import withSizes from 'react-sizes';
import LoginBgImg from 'shared/assets/images/login_bg.png';
import { Button } from '@zendeskgarden/react-buttons';
import {
  mapSizesToProps,
  getLevelPermissions,
} from 'shared/utils/Helpers';
import { match } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { showAlert, hideAlert } from 'components/Alert/actions';
import { modalOpen, modalClose } from 'components/Modal/actions';
import * as routes from 'config/routes';
import {
  setUser as setUserFn,
  unsetUser,
} from 'components/User/actions';
import * as userService from 'components/User/service';
import StepWizard from 'react-step-wizard';
import BrowserHistory from 'shared/history';
import {
  Heading,
  TitleBlock,
} from 'components/SideBox/SideBox.styled';
import HeaderNav from './HeaderNav';
import ThermModal from './TermModal';
import {
  Background,
  Header,
  Logo,
  Body,
  Container,
} from './Login.styled';
import t from 'components/i18n';

type TViewType =
  | routes.SIGNIN
  | routes.SIGNUP
  | routes.PASSWORD_RECOVERY
  | routes.PASSWORD_RECOVERY_NEXT;

type TLoginProps = {
  history: typeof BrowserHistory,
  match: match,
};

function handleView(path, view, setter) {
  if (
    path === routes.PASSWORD_RECOVERY &&
    view !== routes.PASSWORD_RECOVERY
  ) {
    setter(routes.PASSWORD_RECOVERY);
  } else if (
    path === routes.PASSWORD_RECOVERY_NEXT &&
    view !== routes.PASSWORD_RECOVERY_NEXT
  ) {
    setter(routes.PASSWORD_RECOVERY_NEXT);
  } else if (path === routes.SIGNUP && view !== routes.SIGNUP) {
    setter(routes.SIGNUP);
  } else if (path === routes.SIGNIN && view !== routes.SIGNIN) {
    setter(routes.SIGNIN);
  }
}

function Login(props: TLoginProps) {
  const [view, setView] = useState < TViewType > (routes.SIGNIN);
  const [user, setUser] = useState({
    documentNumber: null,
    mobileNumber: '',
  });
  const [state, setState] = useState({
    isLoading: false,
  });
  const [displayLoginBox, setDisplayLoginBox] = useState(true);
  const [useTertiaryColors, setUseTertiaryColors] = useState(false);

  const [passwordRecoveryData, setPasswordRecoveryData] = useState({});
  const [createUserData, setCreateUser] = useState({});

  // this is an accessor for the wizard methods
  const accessor = {
    nextStep: null,
  };

  const {
    match: { path },
    history,
  } = props;

  if (path === '/') {
    history.push(routes.SIGNIN);
  }

  handleView(path, view, setView);

  async function onAccept(
    redirect = false,
    callback = () => undefined,
    token
  ) {
    setState(previousState => ({
      ...previousState,
      isLoading: true,
    }));

    try {
      const termResponse = await userService.acceptTerm(token);

      // redirect to home page
      if (termResponse) {
        if (termResponse.success) {
          history.push(routes.HOME);
          props.modalClose();

          callback();
        }
      } else {
        props.showAlert(
          t('login.ext.error.user-not-authorized'),
          {
            variant: 'danger',
            timeout: 5,
          }
        );
      }
    } catch (e) {
      if (e.response && e.response.message) {
        props.showAlert(e.response.message, {
          variant: 'danger',
          timeout: 5,
        });
      }

      if (redirect) {
        history.push(routes.SIGNIN);
      }
    } finally {
      setState(previousState => ({
        ...previousState,
        isLoading: false,
      }));
    }
  }

  function onDeny() {
    props.modalClose();
    props.unsetUser();

    history.push(routes.SIGNIN);
  }

  function toggleLoginBox() {
    setDisplayLoginBox(!displayLoginBox);
    if (!displayLoginBox) {
      history.push(routes.SIGNIN);
    }
  }

  function openModal({
    termData,
    onAcceptFn,
    onDenyFn,
    redirect,
    token,
    onAcceptCallback,
  }) {
    props.modalOpen({
      large: true,
      modalTitle: t('home.signup.termsOfUseModal.title'),
      renderBody: () => (
        <ThermModal
          termData={termData}
          onAccept={() => onAcceptFn(redirect, onAcceptCallback, token)}
          onDeny={onDenyFn}
        />
      ),
    });
  }

  async function userSignIn(payload) {
    setState(previousState => ({
      ...previousState,
      isLoading: true,
    }));

    try {
      let theUser = '';

      if (typeof payload.token === 'string' || payload.registration !== undefined) {
        theUser = typeof payload.token === 'string'
          ? await userService.authenticateADFS(payload)
          : await userService.authenticateNext({
            ...payload,
          });

        // //ATENCAO - REMOVER E USAR MODAL DE TERMOS DE USO
        theUser.isAcceptedTerm = true;

        const allowedRoutes = () => {
          if (theUser.allowRoutes) {
            return theUser.allowRoutes
              .filter(route => route.level !== '0')
              .map(item => ({
                id: item.id,
                level: getLevelPermissions(Number(item.level)),
              }));
          }

          return null;
        };

        props.setUser({
          ...theUser,
          isAuthenticated: true,
          isNextUser: true,
          allowRoutes: allowedRoutes(),
        });
      } else {
        theUser = await userService.authenticate({
          ...payload,
        });

        if (theUser.isAcceptedTerm) {
          props.setUser({
            ...theUser,
            isAuthenticated: true,
            isNextUser: payload.registration !== undefined,
            allowRoutes: [],
          });
          return;
        }

        if (theUser.isAcceptedTermLgpd) {
          props.setUser({
            ...theUser,
            isAcceptedTermLgpd: true,
          });
          return;
        }

        const term = await userService.getTerm({ token: theUser.token });

        openModal({
          termData: term && term.term,
          onAcceptFn: onAccept,
          onDenyFn: onDeny,
          redirect: false,
          token: theUser.token,
          onAcceptCallback: () => {
            props.setUser({
              ...theUser,
              isAuthenticated: true,
              isNextUser: payload.registration !== undefined,
            });
          }
        });
      }
    } catch (e) {
      props.showAlert(e.message, {
        variant: 'danger',
        timeout: 5,
        icon: 'icon-exclamation-triangle',
      });
    } finally {
      setState(previousState => ({
        ...previousState,
        isLoading: false,
      }));
    }
  }

  async function userSignUp(payload) {
    setState(previousState => ({
      ...previousState,
      isLoading: true,
    }));

    setCreateUser(payload);

    try {
      const theUser = await userService.verifyAccount({
        ...payload,
        documentNumber: payload.documentNumber,
      });

      if (theUser.isFirstAccess) {
        setUser(previousState => ({
          ...previousState,
          ...theUser,
          documentNumber: payload.documentNumber,
        }));

        accessor.nextStep();
      } else {
        props.showAlert(
          t('signup.error.user-already-on-system'),
          {
            variant: 'danger',
            timeout: 5,
          },
        );
      }
    } catch (e) {
      props.showAlert(e.message, {
        variant: 'danger',
        timeout: 5,
      });
    } finally {
      setState(previousState => ({
        ...previousState,
        isLoading: false,
      }));
    }
  }

  async function userPasswordRecover(payload) {
    setState(previousState => ({
      ...previousState,
      isLoading: true,
    }));

    try {
      const account = await userService.verifyAccount({
        ...payload,
        documentNumber: payload.documentNumber,
        isForgetAccount: true,
      });

      setUser(previousState => ({
        ...previousState,
        ...account,
        documentNumber: payload.documentNumber,
      }));

      setPasswordRecoveryData(payload);

      accessor.nextStep();
    } catch (e) {
      props.showAlert(e.message, {
        variant: 'danger',
        timeout: 5,
      });
    } finally {
      setState(previousState => ({
        ...previousState,
        isLoading: false,
      }));
    }
  }

  async function userAuthenticate(payload) {
    setState(previousState => ({
      ...previousState,
      isLoading: true,
    }));

    try {
      await userService.authenticateVendorCode({
        ...payload,
        documentNumber: user.documentNumber,
      });

      setPasswordRecoveryData(state => ({ ...state, ...payload }));
      setCreateUser(state => ({ ...state, ...payload }));

      accessor.nextStep();
    } catch (e) {
      props.showAlert(e.message, {
        variant: 'danger',
        timeout: 5,
      });
    } finally {
      setState(previousState => ({
        ...previousState,
        isLoading: false,
      }));
    }
  }

  function userPasswordRedefined() {
    // redirect to home page
    props.setUser({ ...user, isAuthenticated: true });
  }

  async function createPassword(payload, isRecovering) {
    setState(previousState => ({
      ...previousState,
      isLoading: true,
    }));

    try {
      let theUser;

      if (isRecovering) {
        theUser = await userService.passwordRecovery({
          documentNumber: passwordRecoveryData.documentNumber,
          phoneNumber: passwordRecoveryData.phone,
          vendorCode: passwordRecoveryData.vendorCode,
          password: payload.password
        });
   
        setPasswordRecoveryData(state => ({ ...state, ...payload }));
        setUser({ ...theUser, isAuthenticated: true });
      } else {
        theUser = await userService.createPassword({
          documentNumber: createUserData.documentNumber,
          phoneNumber: createUserData.phone,
          vendorCode: createUserData.vendorCode,
          password: payload.password
        });

        setCreateUser(state => ({ ...state, ...payload }));
      }

      props.setUser(theUser);

      if (theUser.isAcceptedTerm) {
        if (isRecovering) {
          accessor.nextStep();
          return;
        }
        props.setUser({ ...theUser, isAuthenticated: true });
        return;
      }

      const term = await userService.getTerm({ token: theUser.token });

      openModal({
        termData: term && term.term,
        onAcceptFn: onAccept,
        onDenyFn: onDeny,
        redirect: isRecovering === false,
        token: theUser.token,
        onAcceptCallback: () => {
          if (isRecovering) {
            accessor.nextStep();
            return;
          }

          props.setUser({
            ...theUser,
            isAuthenticated: true,
            allowRoutes: [],
          });
        }
      });
    } catch (e) {
      props.showAlert(e.response.message, {
        variant: 'danger',
        timeout: 5,
      });
    } finally {
      setState(previousState => ({
        ...previousState,
        isLoading: false,
      }));
    }
  }

  return (
    <Background className="background login__background">
      <Header>
        <Container>
          <Logo />
          <HeaderNav {...props} toggleLoginBox={toggleLoginBox} />
        </Container>
      </Header>

      <Body>
        <Container>
          <Welcome className="container-item" {...props} />

          <SideBox
            setDisplayBox={setDisplayLoginBox}
            displayBox={displayLoginBox}
          >
            {view === routes.SIGNIN && (
              <Box
                className="side-box"
                footerDetail
                tertiary={useTertiaryColors}
                backgroundImage={LoginBgImg}
              >
                {wizardProps => (
                  <SignIn
                    {...{
                      ...wizardProps,
                      userSignIn,
                      isLoading: state.isLoading,
                      user,
                      accessor,
                      displayLoginBox,
                      setDisplayLoginBox,
                      useTertiaryColors,
                      setUseTertiaryColors,
                      showAlert
                    }}
                  />
                )}
              </Box>
            )}

            {view === routes.SIGNUP && (
              <StepWizard>
                <Box className="side-box" footerDetail>
                  {wizardProps => (
                    <SignUp
                      {...{
                        ...wizardProps,
                        setView,
                        userSignUp,
                        isLoading: state.isLoading,
                        user,
                        accessor,
                      }}
                    />
                  )}
                </Box>

                <Box className="side-box" footerDetail>
                  {wizardProps => (
                    <Authentication
                      {...{
                        ...wizardProps,
                        userAuthenticate,
                        isLoading: state.isLoading,
                        user,
                        accessor,
                      }}
                    />
                  )}
                </Box>

                <Box className="side-box" footerDetail>
                  {wizardProps => (
                    <CreatePassword
                      {...{
                        ...wizardProps,
                        createPassword,
                        isLoading: state.isLoading,
                        user,
                        accessor,
                      }}
                    />
                  )}
                </Box>
              </StepWizard>
            )}

            {view === routes.PASSWORD_RECOVERY && (
              <StepWizard>
                <Box className="side-box" footerDetail>
                  {wizardProps => (
                    <RecoverPassword
                      {...{
                        ...wizardProps,
                        setView,
                        userPasswordRecover,
                        isLoading: state.isLoading,
                        user,
                        accessor,
                      }}
                    />
                  )}
                </Box>

                <Box className="side-box" footerDetail>
                  {wizardProps => (
                    <Authentication
                      {...{
                        ...wizardProps,
                        userAuthenticate,
                        isLoading: state.isLoading,
                        user,
                        accessor,
                      }}
                    />
                  )}
                </Box>

                <Box className="side-box" footerDetail>
                  {wizardProps => (
                    <CreatePassword
                      {...{
                        ...wizardProps,
                        createPassword,
                        isLoading: state.isLoading,
                        user,
                        accessor,
                        isRecovering: true,
                      }}
                    />
                  )}
                </Box>

                <Box className="side-box" footerDetail>
                  {wizardProps => (
                    <Success
                      {...{
                        ...wizardProps,
                        userPasswordRedefined,
                        accessor,
                      }}
                    />
                  )}
                </Box>
              </StepWizard>
            )}

            {view === routes.PASSWORD_RECOVERY_NEXT && (
              <StepWizard>
                <Box className="side-box" footerDetail tertiary>
                  <div>
                    <TitleBlock>
                      <Heading>{t('next.passwordRecovery.title')}</Heading>
                    </TitleBlock>
                    <div
                      style={{
                        fontSize: '14px',
                        paddingTop: '40px',
                      }}
                    >
                      <p>
                        {t('next.passwordRecovery.description')}
                      </p>

                      <Button
                        onClick={() => history.push(routes.SIGNIN)}
                        stretched
                        className="tertiary"
                        style={{ marginTop: '88px' }}
                      >
                        {t('global.button.back')}
                      </Button>
                    </div>
                  </div>
                </Box>
              </StepWizard>
            )}
          </SideBox>
        </Container>
      </Body>
    </Background>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    alert: state.alert,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUser: setUserFn,
      unsetUser,
      showAlert,
      hideAlert,
      modalOpen,
      modalClose,
    },
    dispatch,
  );
}

export default hot(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withSizes(mapSizesToProps)(Login)),
);
