import React from 'react';
import Icon from 'components/Icon';
import { List, ListItem } from 'components/List';
import { Menu } from './ProfileAndSettings.styled';
import { profileKeyMapper } from 'utils';

export default function ProfileMenu({
  menuItems,
  activeItem,
  setActiveItem,
}) {
  return (
    <Menu>
      <List>
        {menuItems.length > 0 &&
          menuItems.map(
            (item, index) =>
              item.visible && (
                <div
                  key={index}
                  className="menu-item"
                  onClick={() => setActiveItem(item.key)}
                >
                  <ListItem
                    noMargin
                    className={
                      item.key === activeItem ? 'active' : ''
                    }
                  >
                    <Icon className="menu-icon" name={item.icon} />
                    <div className="list-item-content">
                      <div className="list-item-heading">
                        {profileKeyMapper(item.name)}
                      </div>
                    </div>
                  </ListItem>
                  <Icon className="arrow" name="icon-chevron-right" />
                </div>
              ),
          )}
      </List>
    </Menu>
  );
}
