import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function activateUser(id) {
  const response = await request(
    `${API_URL}/User/ActivateUser?id=${id}`,
    {
      method: 'POST',
    },
  );

  return response;
}
