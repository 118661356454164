import React, { useCallback, useRef } from 'react';
import moment from 'moment';
import { FieldArray } from 'formik';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form, AddItem } from 'components/Forms/Forms.styled';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import {
  InputField,
  DropdownFieldFromArray,
} from 'components/Forms/Fields';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';
import t from 'components/i18n';

export default function GrainsForm(props) {
  const {
    grainList,
    centerList,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
    resetForm,
    values,
    errors,
    touched,
  } = props;

  const form = useRef(null);

  function handleReset() {
    resetForm();
    form.current.reset();
  }

  const getMonths = useCallback(
    () =>
      Array.apply(0, Array(12)).map((_, i) => ({
        name: moment()
          .month(i)
          .format('MMMM'),
        value: moment()
          .month(i)
          .format('MM'),
      })),
    [],
  );

  const getYears = useCallback(() => {
    const currentYear = moment().get('year');

    return [
      { name: currentYear },
      { name: currentYear + 1 },
      { name: currentYear + 2 },
    ];
  }, []);

  return (
    <>
      <p style={{ marginTop: 0 }}>
        {t('menu.beAnAPartner.form.description')}
      </p>

      <Form autoComplete="off" onSubmit={handleSubmit} ref={form}>
        <FieldArray
          name="grainProposals"
          render={arrayHelpers =>
            values.grainProposals.map((_proposal, index) => (
              <div key={index}>
                {index > 0 && (
                  <div>
                    <AddItem
                      onClick={() => {
                        arrayHelpers.remove(index);
                        // setMaxH(maxH - 152);
                      }}
                    >
                      <span className="icon icon-minus" /> 
                      {t('menu.beAnAPartner.form.button.remove')}
                    </AddItem>
                  </div>
                )}
                <Row>
                  <Col md={12}>
                    <DropdownFieldFromArray
                      field={`grainProposals.${index}.grainTypeId`}
                      label={t('menu.beAnAPartner.form.typeOfGrain.label')}
                      from="grainProposals"
                      fieldString="grainTypeId"
                      onSelect={item =>
                        setFieldValue(
                          `grainProposals.${index}.grainTypeId`,
                          item,
                        )
                      }
                      placeholder={t('menu.beAnAPartner.form.typeOfGrain.placeholder')}
                      index={index}
                      options={grainList}
                      optionLabel="name"
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  <Col md={12}>
                    <InputField
                      masked
                      type="dot"
                      field={`grainProposals.${index}.volume`}
                      label={t('menu.beAnAPartner.form.availableVolume.label')}
                      placeholder={t('menu.beAnAPartner.form.availableVolume.placeholder')}
                      from="grainProposals"
                      fieldString="volume"
                      setState={e =>
                        setFieldValue(
                          `grainProposals.${index}.volume`,
                          e,
                        )
                      }
                      index={index}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <DropdownFieldFromArray
                      field={`grainProposals.${index}.month`}
                      label={t('menu.beAnAPartner.form.deliveryPeriod.label')}
                      from="grainProposals"
                      fieldString="month"
                      onSelect={item =>
                        setFieldValue(
                          `grainProposals.${index}.month`,
                          item,
                        )
                      }
                      placeholder={t('menu.beAnAPartner.form.deliveryPeriod.filter.month.label')}
                      options={getMonths()}
                      optionLabel="name"
                      index={index}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  <Col md={6}>
                    <DropdownFieldFromArray
                      field={`grainProposals.${index}.year`}
                      from="grainProposals"
                      onSelect={item =>
                        setFieldValue(
                          `grainProposals.${index}.year`,
                          item,
                        )
                      }
                      placeholder={t('menu.beAnAPartner.form.deliveryPeriod.filter.year.label')}
                      fieldString="year"
                      options={getYears()}
                      optionLabel="name"
                      index={index}
                      values={values}
                      errors={errors}
                      touched={touched}
                      falseLabel
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <DropdownFieldFromArray
                      field={`grainProposals.${index}.grainProposalCenterId`}
                      label={t('menu.beAnAPartner.form.deliveryUnit.label')}
                      from="grainProposals"
                      fieldString="grainProposalCenterId"
                      onSelect={item =>
                        setFieldValue(
                          `grainProposals.${index}.grainProposalCenterId`,
                          item,
                        )
                      }
                      placeholder={t('menu.beAnAPartner.form.deliveryUnit.placeholder')}
                      options={centerList}
                      optionLabel="description"
                      index={index}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>

                <div>
                  <AddItem
                    onClick={() => {
                      arrayHelpers.push({
                        maleQuantity: '',
                        femaleQuantity: '',
                        mortalityTypeId: '',
                        discardedTypeId: '',
                      });
                    }}
                  >
                    <span className="icon icon-plus" />
                    {t('menu.beAnAPartner.form.button.add')}
                  </AddItem>
                </div>

                <Separator />
              </div>
            ))
          }
        />

        <BtnGroup>
          <Button
            type="submit"
            key="sendtoConfirm"
            disabled={!isValid}
          >
            {t('menu.beAnAPartner.form.buttonPrimary')}
          </Button>
          <Button
            key="clean"
            onClick={() => handleReset()}
            className="white"
          >
            {t('menu.beAnAPartner.form.buttonSecondary')}
          </Button>
        </BtnGroup>
      </Form>
    </>
  );
}
