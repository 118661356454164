// @flow
import React from 'react';
import styled from 'styled-components';
import { lighten } from 'shared/utils/colors';

import {
  DEFAULT_SIZE,
  colorClear,
  colorDanger,
  colorSuccess,
  colorPrimary,
} from 'components/GlobalStyles/variables';

const Btn = styled.button`
  cursor: pointer;
  width: 48px;
  height: 48px;
  color: ${colorClear};
  border: 0 none;
  border-radius: 50%;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  margin-right: ${DEFAULT_SIZE}px;
  background-color: ${colorPrimary};
  transition: 0.5s all ease-in-out;

  &:focus,
  &:active {
    outline: 0 none;
    box-shadow: 0px 4px 8px rgba(37, 48, 71, 0.16) !important;
  }

  [class^='icon-'] {
    font-size: 24px;
    margin-top: 10px;
  }

  &.danger {
    background-color: ${colorDanger};

    &:hover,
    &:focus {
      background-color: lighten(${colorDanger});
    }
  }

  &.success {
    background-color: ${colorSuccess};

    &:hover,
    &:focus {
      background-color: ${lighten(colorSuccess)};
    }
  }
`;

function getType(obj) {
  if ('danger' in obj) {
    return 'danger';
  }
  return 'success';
}

export default function(props) {
  const { type = 'button', children } = props;

  const className = getType(props);

  const btnProps = {
    ...props,
    className,
    type,
  };

  return <Btn {...btnProps}>{children}</Btn>;
}
