import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { ThemeContext } from 'styled-components';
import NoContentTab from 'components/NoContentTab';
import TableComponent from 'components/Table';
import Options from 'components/Options';
import Pagination from 'components/Pagination';
import t from 'components/i18n';
import BadgePill from 'components/BadgePill';
import { getUsers } from '../service';
import { Container } from '../UserManagement.styled';
import { profileKeyMapper } from 'utils';
import { regionalMapper, creationMapper } from 'utils/mapper';

export default function UserList({
  responsive,
  updateItens,
  setSelectedUser,
  setSelectedKey,
  userPermission,
  deleteUser,
}) {
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setUserData] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  const { isMobile } = responsive;

  const themeContext = useContext(ThemeContext);

  // pagination
  const itensPerPage = 10;

  useEffect(() => {
    setSelectedUser({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserList = useCallback(async e => {
    setIsContentLoading(true);
    try {
      const response = await getUsers(e);
      setUserData(response);
    } catch (error) {
      console.error(error);
    }
    setIsContentLoading(false);
  }, []);

  const getFilterByLabel = useCallback(
    label => activeFilters.find(filter => label === filter.label),
    [activeFilters],
  );

  const user = getFilterByLabel('Por usuário');
  const region = getFilterByLabel('Por regional');
  const center = getFilterByLabel('Por unidade');
  const creationType = getFilterByLabel('Por tipo de criação');
  const profileType = getFilterByLabel('Por tipo de perfil');
  const status = getFilterByLabel('Por status');

  const getFilters = useCallback(() => {
    const mapFilters = (arr, txt, id, targetId) =>
      arr &&
      arr.length > 0 &&
      arr.map((item, index) => ({
        ...item,
        txt: item[txt],
        id: item[id],
        targetId: item[targetId],
        index,
      }));

    // eslint-disable-next-line one-var
    let newUsers, newCenters, newProfiles;

    if (userData) {
      newUsers = Object.assign([], userData.userFilters);
      newCenters = Object.assign([], userData.centerFilters);
      newProfiles = Object.assign([], userData.profileFilters);
    }

    const filterUsers = mapFilters(newUsers, 'userName', 'userId');

    const filterRegionals = mapFilters(
      newCenters,
      'regional',
      'regional',
    );

    const filterCenters = mapFilters(
      newCenters,
      'centerName',
      'centerName',
      'regional',
    );

    const typeCenters = mapFilters(
      newCenters,
      'creationType',
      'creationType',
      'centerName',
    );

    const filterProfiles = mapFilters(
      newProfiles,
      'userCorporateProfile',
      'userCorporateProfileId',
    );

    return [
      {
        label: t('next.userManagement.user.userList.filters.user'),
        items: filterUsers,
        autocomplete: true,
        resetAll: true,
      },
      {
        label: t('next.userManagement.user.userList.filters.regional'),
        items: filterRegionals,
      },
      {
        label: t('next.userManagement.user.userList.filters.unit'),
        items: filterCenters,
        target: t('next.userManagement.user.userList.filters.regional'),
      },
      {
        label: t('next.userManagement.user.userList.filters.anymalType'),
        items: typeCenters,
        target: t('next.userManagement.user.userList.filters.unit'),
      },
      {
        label: t('next.userManagement.user.userList.filters.profile'),
        items: filterProfiles,
      },
      {
        label: t('next.userManagement.user.userList.filters.status'),
        items: [
          {
            txt: t('next.userManagement.user.userList.filters.status.values.active'),
            value: true
          },
          {
            txt: t('next.userManagement.user.userList.filters.status.values.inactive'),
            value: false
          },
        ],
      },
    ];
  }, [userData]);

  const stringRequest = useMemo(
    () => ({
      PageNumber: currentPage,
      PageSize: itensPerPage,
      UserId: user ? user.id : undefined,
      UserCorporateProfileId: profileType
        ? profileType.id
        : undefined,
      CenterName: center ? center.txt : undefined,
      Regional: region ? region.txt : undefined,
      CreationType: creationType ? creationType.txt : undefined,
      Active: status ? status.value : '',
    }),
    [
      center,
      creationType,
      currentPage,
      status,
      profileType,
      region,
      user,
    ],
  );

  useEffect(() => {
    if (activeFilters) {
      setCurrentPage(1);
    }
  }, [activeFilters]);

  useEffect(() => {
    if (stringRequest) {
      getUserList(
        JSON.stringify(stringRequest)
          .replace('}', '')
          .replace('{', '')
          .replaceAll('"', '')
          .replaceAll(':', '=')
          .replaceAll(',', '&'),
      );
    }
  }, [stringRequest, getUserList]);

  useEffect(() => {
    if (updateItens) {
      getUserList(
        JSON.stringify(stringRequest)
          .replace('}', '')
          .replace('{', '')
          .replaceAll('"', '')
          .replaceAll(':', '=')
          .replaceAll(',', '&'),
      );
    }
  }, [getUserList, updateItens, stringRequest]);

  const getUserOptions = useCallback(
    userObject => [
      {
        action: () => {
          setSelectedUser(userObject);
          setSelectedKey('user-details');
        },
        icon: 'visibility-enabled',
        label: t('next.userManagement.profile.menu.options.details'),
        visible: userPermission && !!userPermission.read,
      },
      {
        action: () => {
          // setSelectedUser(userObject);
          // setSelectedKey('edit-user');
        },
        icon: 'edit',
        label: t('next.userManagement.profile.menu.options.edit'),
        visible: userPermission && !!userPermission.edit,
      },
    ],
    [setSelectedKey, setSelectedUser, userPermission],
  );

  const getStatus = useCallback(
    status => (
      <BadgePill
        style={{ minWidth: 77, margin: '3px auto 0' }}
        bgColor={status ? 'colorPrimary' : 'colorGray'}
      >
        {status ? t('next.userManagement.profile.status.active') : t('next.userManagement.profile.status.inactive')}
      </BadgePill>
    ),
    [],
  );

  const getItems = useCallback((types, type) => {
    if (types.length === 3) {
      return t('next.userManagement.user.userList.all-items');
    }

    const mapperFn = {
      ['regionals']: regionalMapper,
      ['creationTypes']: creationMapper,
    }[type];

    return types.map((item, index) => {
      if (index === types.length - 1) {
        return t(mapperFn(item));
      }
      return `${t(mapperFn(item))}, `;
    });
  }, []);

  const tableData = useMemo(
    () =>
      userData &&
      userData.itens &&
      userData.itens.length > 0 &&
      userData.itens.map(e => ({
        0: (
          <div className={isMobile ? 'column mobile' : 'column'}>
            <p className="small caps">{t("next.userManagement.user.table.content.code", { value: e.enrollment })}</p>
            <p className="strong">{e.userName}</p>
          </div>
        ),
        1: getItems(e.regionals, 'regionals'),
        2: getItems(e.creationTypes, 'creationTypes'),
        3: profileKeyMapper(e.userCorporateProfile),
        4: getStatus(e.active),
        5: (
          <Options
            onClick={x => {
              x.stopPropagation();
            }}
            options={getUserOptions(e)}
            placement="auto"
            style={{ marginTop: '-5px' }}
          />
        ),
        onClick: () => {
          setSelectedUser(e);
          setSelectedKey('user-details');
        },
      })),
    [
      getItems,
      getStatus,
      getUserOptions,
      isMobile,
      setSelectedKey,
      setSelectedUser,
      userData,
    ],
  );

  return (
    <>
      <Container
        style={{
          marginBottom: '30px',
          width: '100%',
          paddingBottom: '0',
        }}
      >
        {!isContentLoading && !userData && (
          <NoContentTab
            icon="icon-clipboard-user"
            onButtonClick={() => {}}
          >
            <p>
              {t('global.no-content')}
            </p>
          </NoContentTab>
        )}

        <TableComponent
          loading={isContentLoading}
          filter={{
            activeFilters: activeFilters,
            setActiveFilters: setActiveFilters,
            filters: getFilters(),
          }}
          titleColor={themeContext.styles.palette.colorPrimary}
          rowSize="default"
          header={[
            {
              width: 22,
              title: t('next.userManagement.user.table.header.name'),
              alignLeft: true,
            },
            {
              width: 20,
              title: t('next.userManagement.user.table.header.regional'),
            },
            {
              width: 20,
              title: t('next.userManagement.user.table.header.anymalType'),
            },
            {
              width: 20,
              title: t('next.userManagement.user.table.header.profile'),
            },
            {
              width: 15,
              title: t('next.userManagement.user.table.header.status'),
            },
            {
              width: 3,
              title: '',
            },
          ]}
          mobileHeader={t('next.userManagement.user.table.header.mobile')}
          data={tableData}
          width="25%"
          isMobile={isMobile}
        />
      </Container>

      {userData && Number(userData.totalPages) > 1 && (
        <Pagination
          totalPages={userData.totalPages}
          pagePadding={isMobile ? 0.75 : 2}
          currentPage={currentPage}
          onChangePage={x => setCurrentPage(x.e)}
        />
      )}
    </>
  );
}
