import styled from 'styled-components';

export const StyledLoadingHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 40px;
  margin-bottom: 20px;
  padding-bottom: 20px;

  p {
    padding: 20px 0 0;
    margin: 0 0 10px 0;
    font-size: 14px;
  }

  &.hidden {
    display: none;
  }
`;
