// @flow

import type { TResponse } from 'shared/typings';
import logger from 'shared/utils/logger';
import { store } from 'store';
import { unsetUser } from 'components/User/actions';
import errorMessages from 'config/errorMessages';
import { KONG_CLIENT_ID } from 'config/constants';

function getToken() {
  const state = store.getState();
  if (state.user && state.user.token) {
    return state.user.token;
  }

  return null;
}

function addParamsToURL(params) {
  const query = Object.keys(params)
    .map(
      k =>
        `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`,
    )
    .join('&');

  return `?${query}`;
}

export class ServerError extends Error {
  response: Object;

  constructor(message?: string): Error {
    super(message);

    Error.captureStackTrace(this, ServerError);

    this.name = 'ServerError';

    return this;
  }
}

export function parseError(error: string): string {
  return error || 'Something went wrong';
}

export function request(
  url: string,
  options: {},
): Promise<TResponse> {
  const errors = [];
  const config = {
    method: 'GET',
    payload: options.payload,
    ...options,
  };

  if (!url) {
    errors.push('url');
  }

  // if (
  //   !config.payload &&
  //   (config.method !== 'GET' && config.method !== 'DELETE')
  // ) {
  //   errors.push('payload');
  // }

  if (errors.length) {
    throw new Error(
      `Error! You must pass \`${errors.join('`, `')}\``,
    );
  }

  const configToken = () => {
    if (options.headers !== undefined) {
      if (options.headers.Authorization !== null) {
        return options.headers.Authorization;
      }
    }
    return getToken();
  };

  const token = configToken();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'client_id': KONG_CLIENT_ID,
    ...config.headers,
  };

  if (!headers['Content-Type']) {
    delete headers['Content-Type'];
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`;
    const user = store.getState().user;
    headers.RequestOrigin = user.isNextUser ? 'WebNext' : 'WebAgro';
  }

  const params: Object = {
    headers,
    method: config.method,
  };

  if (config.signal) {
    params.signal = config.signal;
  }

  if (params.method !== 'GET' && !headers['Content-Type']) {
    params.body = config.payload;
  } else if (params.method !== 'GET') {
    params.body = JSON.stringify(config.payload);
  }

  if (params.method === 'GET' && config.payload) {
    url += addParamsToURL(config.payload);
  }

  logger(`REQUEST (${params.method}): ${url}`, options.payload);
  logger('TOKEN', headers.Authorization);

  return fetch(url, params)
    .then(async response => {
      const contentType = response.headers.get('content-type');

      if (response.status === 401) {
        store.dispatch(unsetUser());
        return false;
      }

      try {
        const json = await response.json();

        // if (response.status > 299) {
        if (!json.success) {
          // const error = new ServerError(response.statusText);
          const error = new ServerError(json.message);
          error.status = response.status;

          if (
            contentType &&
            contentType.includes('application/json')
          ) {
            error.response = json;
          } else {
            error.response = await response.text();
          }

          throw error;
        } else {
          if (
            contentType &&
            contentType.includes('application/json')
          ) {
            logger(`RESPONSE (${params.method}): ${url}`, json);
            return json;
          }

          return response.text();
        }
      } catch (e) {
        if (e instanceof SyntaxError) {
          throw new ServerError(response.status);
        }
        throw e;
      }
    })
    .catch(e => {
      // throw e;
      return {
        data: [],
        message: errorMessages[e.message]
          ? errorMessages[e.message]
          : e.message,
        success: false,
      };
    });
}
