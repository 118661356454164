import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

export function displayMessageText(iconName, text, color) {
  return (
    <p
      style={{
        color: color,
        margin: '3px 0 0 0',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Icon name={iconName} size="22" />
      <span
        style={{
          fontSize: '12px',
          lineHeight: '1',
          paddingLeft: '5px',
          marginTop: '2px',
        }}
      >
        {text}
      </span>
    </p>
  );
}

displayMessageText.propTypes = {
  iconName: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
};
