import React, { useEffect, useState } from 'react';
import t from 'components/i18n';
import { dateToTimeString } from 'shared/utils/Helpers/handleDate';
import { Row, Col } from '@zendeskgarden/react-grid';
import { BtnGroup } from 'components//Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Form } from 'components/Forms/Forms.styled';
import { Field, Label } from '@zendeskgarden/react-forms';
import {
  DropdownField,
  InputField,
  FloatInputField,
  TimePickerField,
} from 'components/Forms/Fields';
import {
  getAgeList,
  getSchedule,
} from 'containers/AgroBRF/FlockList/service';
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import Confirmation from './Confirmation';

export default function AddWeighingForm(props) {
  const {
    enabledAges,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    selectedWeight,
    isSubmitting,
    goTo,
    message,
    isLoading,
    values,
    errors,
    setSelectedWeight,
    touched,
    houses,
    isValid,
    flockID,
    sendToConfirmation: sendToConfirmationProp,
  } = props;

  // set houses
  const housesArray = houses.map(house => ({
    ...house,
    label: t('activities.weight.house', { value: house.value }),
  }));

  // set ages
  const [availableAges, setAvailableAges] = useState(
    enabledAges || [],
  );

  // set schedules
  const [setAvailableSchedules] = useState([]);

  // send form to confirmation
  const [sendToConfirmation, setSendToConfirmation] = useState(
    sendToConfirmationProp,
  );

  useEffect(() => {
    async function fetchData(flock, house) {
      try {
        const response = await getAgeList(flock, house);
        response.forEach(
          age =>
            (age.label = t('activities.weight.days', {
              value: age.value,
            })),
        );
        setAvailableAges(response);
      } catch (e) {
        console.error(e);
      }
    }
    if (Object.keys(values.house).length > 0) {
      fetchData(flockID, values.house.id);
    }
  }, [flockID, values]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getSchedule();
        response.all.forEach(
          schedule => (schedule.label = `${schedule.date}`),
        );

        setAvailableSchedules(
          response.all.filter(item => item.date === values.age.date),
        );
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, [setAvailableSchedules, values]);

  useEffect(() => {
    setSendToConfirmation(sendToConfirmationProp);
  }, [sendToConfirmationProp]);

  useEffect(() => {
    if (!Object.keys(selectedWeight).length) {
      resetForm();
      setFieldValue('schedule', '');
      setFieldValue('deaths', '');
      setFieldValue('deletions', '');
    }
  }, [resetForm, selectedWeight, setFieldValue]);

  const dataToConfirmation = {
    // id: id.length ? id : null,
    hour: dateToTimeString(values.schedule),
    value: values.weight,
    dead: values.deaths,
    eliminate: values.deletions,
    flockId: values.flockId,
    date: values.age.date,
    ageValue: values.age.value,
    houseValue: values.house.value,
    houseId: values.house.id,
    totalDeaths: values.totalDeaths,
    weightAgeId: values.age.id,
    isEditable: true,
  };

  return (
    <Form autoComplete="off">
      {!sendToConfirmation ? (
        <>
          {/* Instalações */}
          <DropdownField
            field="house"
            label={t('flock.oldFal.form.label.houses')}
            onSelect={item => {
              setFieldValue('house', item);
              setFieldValue('age', {});
              setFieldValue('flockId', flockID);
            }}
            disabledItems="show"
            options={housesArray}
            optionLabel="label"
            values={values}
            errors={errors}
            touched={touched}
          />
          {/* \ Instalações */}

          <Row>
            <Col md={6}>
              {/* Idade */}
              <DropdownField
                field="age"
                label={t('flock.oldFal.form.label.age')}
                onSelect={item => {
                  setFieldValue('age', item);
                  setFieldValue('schedule', '');
                }}
                options={availableAges}
                optionLabel="label"
                disabled={!availableAges.length}
                disabledItems="show"
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \Idade */}
            </Col>

            <Col md={6}>
              {/* Horário */}
              <TimePickerField
                type="time"
                field="schedule"
                label={t('flock.oldFal.form.label.time')}
                onChange={e => setFieldValue('schedule', e)}
                onBlur={handleBlur}
                dateFormat="HH:mm"
                timeCaption={t('global.time.picker.label')}
                timeIntervals={15}
                disabled={!Object.keys(values.age).length}
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Horário */}
            </Col>
          </Row>

          <Row>
            <Col>
              {/* Peso */}
              <Field>
                <Label>
                  {t('flock.oldFal.form.label.weight')}
                </Label>
                <FloatInputField
                  field="weight"
                  placeholder={t(
                    'flock.oldFal.form.placeholder.weight',
                  )}
                  setState={item => {
                    setFieldValue('weight', item);
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!Object.keys(values.age).length > 0}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </Field>
              {/* \ Peso */}
            </Col>
          </Row>

          <Row>
            <Col>
              {/* Mortes naturais */}
              <InputField
                type="number"
                field="deaths"
                label={t('flock.oldFal.form.label.naturalDeaths')}
                placeholder={t(
                  'flock.oldFal.form.placeholder.naturalDeaths',
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!Object.keys(values.age).length > 0}
                min={0}
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Mortes naturais */}
            </Col>
            <Col>
              {/* Eliminações */}
              <InputField
                type="number"
                field="deletions"
                label={t('flock.oldFal.form.label.eliminations')}
                placeholder={t(
                  'flock.oldFal.form.placeholder.eliminations',
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!Object.keys(values.age).length > 0}
                min={0}
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Eliminações */}
            </Col>
          </Row>

          <Separator />

          {/* \ Botões */}
          <BtnGroup>
            <Button
              key="sendtoConfirm"
              onClick={() => {
                setSelectedWeight(dataToConfirmation);
                setSendToConfirmation(true);
              }}
              disabled={!isValid}
            >
              {t('global.button.next')}
            </Button>
            <Button
              key="clean"
              onClick={() => {
                setSelectedWeight({});
                resetForm();
                setAvailableAges([]);
              }}
              className="white"
            >
              {t("global.button.clear")}
            </Button>
          </BtnGroup>
          {/* \ Botões */}
        </>
      ) : (
        <Confirmation
          {...values}
          isSubmitting={isSubmitting}
          backToForm={() => setSendToConfirmation(false)}
          goTo={goTo}
          onSubmit={handleSubmit}
          message={message}
          isLoading={isLoading}
          setSelectedWeight={e => setSelectedWeight(e)}
        />
      )}
    </Form>
  );
}
