import React, { useContext } from 'react';
import { dateToTimeString } from 'shared/utils/Helpers/handleDate';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { scrollToTop } from 'shared/utils/Helpers';
import { List, ListItem } from 'components/List/List';
import NotificationBox from 'components/NotificationBox';
// Contexts
import { WeighingContext } from 'shared/contexts';
// Styles
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import t from 'components/i18n';

export default function Confirmation(props) {
  const weighingContext = useContext(WeighingContext);
  const { house, age, weight, deaths, deletions } = props;
  const {
    houseValue,
    ageValue,
    date,
    hour,
    value,
    dead,
    eliminate,
    isEditable,
  } = weighingContext.selectedWeight;
  const totalDeletions = Number(deaths) + Number(deletions);
  const totalDeaths = Number(dead) + Number(eliminate);

  const ListArray = [];
  const formattedSchedule = dateToTimeString(hour);

  if (!Object.keys(weighingContext.selectedWeight).length > 0) {
    // if it is a new weight form

    ListArray.push(
      {
        description: t('addweight.confirmation.house', { value: house.label }),
        icon: 'pin'
      },
      {
        description: t('addweight.confirmation.age', { value: age.label }),
        icon: 'calendar',
      },
      {
        description: t('addweight.confirmation.date', { date: age.date, hour: formattedSchedule }),
        icon: 'clock',
      },
      {
        description: t('addweight.confirmation.weight', { value: weight }),
        icon: 'scale'
      },
      {
        description: t('addweight.confirmation.death', { value: totalDeletions }),
        icon: 'close-circle',
      },
      {
        description: t('addweight.confirmation.natural-death', { value: deaths }),
        icon: '' 
      },
      {
        description: t('addweight.confirmation.elimination-death', { value: deletions }),
        icon: '' 
      },
    );
  } else {
    // if there is a selected weight
    ListArray.push(
      {
        description: t('addweight.confirmation.house', { value: houseValue }),
        icon: 'pin'
      },
      {
        description: t('addweight.confirmation.age', { value: ageValue }),
        icon: 'calendar',
      },
      {
        description: t('addweight.confirmation.date', { date, hour }),
        icon: 'clock',
      },
      {
        description: t('addweight.confirmation.weight', { value }),
        icon: 'scale'
      },
      {
        description: t('addweight.confirmation.death', { value: totalDeaths }),
        icon: 'close-circle',
      },
      {
        description: t('addweight.confirmation.natural-death', { value: dead }),
        icon: ''
      },
      {
        description: t('addweight.confirmation.elimination-death', { value: eliminate }),
        icon: ''
      },
    );
  }

  return (
    <div className="confirmation">
      <p className="content-heading">{t('addweight.confirmation.title')}</p>
      <List className="confirm-list" style={{ marginTop: '0' }}>
        {ListArray.map((item, index) => (
          <ListItem
            key={index}
            className="confirm-list__item animated fadeIn"
          >
            <div className="icon-wrapper">
              <span className={`icon icon-${item.icon}`} />
            </div>
            <div className="list-item-content">
              <div className="list-item-description">
                {item.description}
                {item.icon === 'close-circle' && (
                  <span className="details">
                    {' '}
                    {t('addweight.confirmation.death.nature-elimination-description')}
                  </span>
                )}
              </div>
            </div>
          </ListItem>
        ))}
      </List>
      {isEditable && (
        <NotificationBox
          className="notification notification--info"
          type="info"
        >
          {t('addweight.confirmation.update-deadline')}
        </NotificationBox>
      )}
      <Separator />
      {/* \ Botões */}
      <BtnGroup className="button-group--bottom">
        {isEditable ? (
          <>
            <Button
              key="submit"
              onClick={() => props.onSubmit()}
              disabled={props.isSubmitting}
            >
              {t("global.button.save")}
            </Button>
            <Button
              key="edit"
              onClick={() => {
                props.backToForm();
                scrollToTop(document.getElementById('main'));
              }}
              className="white"
            >
              {t("global.button.edit")}
            </Button>
          </>
        ) : (
          <Button
            key="back"
            onClick={() => props.goTo('summary')}
            disabled={props.isSubmitting}
          >
            {t('global.button.back')}
          </Button>
        )}
      </BtnGroup>
      {/* \ Botões */}
    </div>
  );
}
