import React from 'react';
import BadgePill from 'components/BadgePill';
import { Toggle, Label } from '@zendeskgarden/react-forms';
import {
  StatusChangeWrapper,
  Bordered,
  Grey,
} from './StatusChange.styled';
import { statusMapper } from 'utils/mapper';
import t from 'components/i18n';

export default function StatusChange({
  status,
  label,
  checked,
  onChange,
  statusBgColor,
}) {
  return (
    <StatusChangeWrapper>
      <Bordered>
        <p>{label}</p>

        <div
          className="flex"
          style={{
            width: 240,
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <BadgePill bgColor={statusBgColor}>{t(statusMapper(status))}</BadgePill>
          <Grey>
            <Toggle checked={checked} onChange={() => onChange()}>
              <Label />
            </Toggle>
          </Grey>
        </div>
      </Bordered>
    </StatusChangeWrapper>
  );
}
