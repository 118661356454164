import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import Modal from 'components/Forms/FormModal/Modal';
import { SendJustificationForm } from 'containers/NextBRF/Forms/Visit';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { saveJustification } from 'containers/NextBRF/LogBook/service';
import t from 'components/i18n';

const validateSchema = props =>
  yup.object().shape({
    justification: yup
      .nullable()
      .string()
      .required(t('global.status.required-field')),
  });

export default function EditJustification(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const { isLoading, selected, isValidating } = props;

  const initialValues = {
    justification: props.justification,
  };

  const formData = values => ({
    logBookItemId: props.id,
    justification: values.justification,
  });

  function submitForm(values, { setSubmitting }) {
    formData(values);

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await saveJustification(val);

        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }
    sendData(formData(values));
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        props.getDetails(props.id);
      }, 2500);
    }
  }, [props, success]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate(validateSchema)}
        onSubmit={submitForm}
        render={formikProps => (
          <SendJustificationForm
            {...formikProps}
            isValidating={isValidating}
            message={errorMessage}
            isLoading={isLoading}
            selected={selected}
            success={success}
            {...props}
          />
        )}
      />
      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>
          {success
            ? t('global.validation.record-changes-saved')
            : t('global.validation.record-error')}
        </p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
