export default function getLevelPermissions(permissions) {
  if (!permissions) {
    return null;
  }

  const permissionsArray = Number(permissions)
    .toString(2)
    .padStart(4, '0')
    .split('');

  return {
    delete: Number(permissionsArray[0]),
    edit: Number(permissionsArray[1]),
    create: Number(permissionsArray[2]),
    read: Number(permissionsArray[3]),
  };
}
