import React, { useCallback } from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { JustifiedListItem } from 'components/List';
import NotificationBox from 'components/NotificationBox';
import LoadingHolder from 'components/LoadingHolder';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { colorDanger } from 'components/GlobalStyles/variables';
import { Form } from 'components/Forms/Forms.styled';
import {
  Container,
  Separator,
  Subheading,
} from 'containers/NextBRF/UserManagement/UserManagement.styled';
import t from 'components/i18n';
import { profileKeyMapper } from 'utils';
import { regionalMapper, creationMapper } from 'utils/mapper';

export default function UserDetails({
  isLoading,
  selectedUser,
  setSelectedUser,
  setSelectedKey,
  deleteUser,
  userPermission,
}) {
  const {
    active,
    userId,
    email,
    userCorporateProfile,
    enrollment,
    creationTypes,
    regionals,
    centerList,
  } = selectedUser;

  const displayItem = useCallback(
    (label, value) => (
      <JustifiedListItem>
        <span>{label}</span>
        <p>
          <strong>{profileKeyMapper(value)}</strong>
        </p>
      </JustifiedListItem>
    ),
    [],
  );

  const displayMultipleItems = useCallback(
    (label, array, message, type) => {
      const mapperFn = {
        ['regionals']: regionalMapper,
        ['creationTypes']: creationMapper,
        ['centerList']: (item) => item,
      }[type];

      return (
        <JustifiedListItem>
          <span>{label}</span>

          {!array.length && (
            <p>
              <strong>{message}</strong>
            </p>
          )}

          {array.length && (
            <div style={{ width: '70%' }}>
              <p>
                {array.map((item, index) => {
                  if (index === array.length - 1) {
                    return <strong>{t(mapperFn(item))}</strong>;
                  }

                  return <strong>{t(mapperFn(item))}, </strong>;
                })}
              </p>
            </div>
          )}
        </JustifiedListItem>
      );
    },
    [],
  );

  return (
    <>
      {selectedUser && !selectedUser.active && (
        <NotificationBox
          type="warning"
          bgColor={colorDanger}
          slim
          style={{
            paddingLeft: 22,
            margin: '-20px -20px 16px -20px',
            width: 'calc(100% + 40px)',
          }}
        >
          {t('next.userManagement.user.inactive-profile')}
        </NotificationBox>
      )}

      <Container>
        <LoadingHolder
          message={t('global.loading')}
          isLoading={isLoading}
        />
        {!isLoading && (
          <Form>
            <Subheading>{t('next.userManagement.user.editUser.info.title')}</Subheading>

            {displayItem(
              t("next.userManagement.user.editUser.info.email"),
              email
            )}
            {displayItem(
              t("next.userManagement.user.editUser.info.profile"),
              userCorporateProfile
            )}
            {displayItem(
              t("next.userManagement.user.editUser.info.id"),
              enrollment
            )}

            <br />

            <Subheading>{t('next.userManagement.user.editUser.access.title')}</Subheading>

            {displayItem(
              t('next.userManagement.user.editUser.access.status.label'),
              active
                ? t('next.userManagement.user.editUser.access.status.active')
                : t('next.userManagement.user.editUser.access.status.inactive')
              )}

            {displayMultipleItems(
              t('next.userManagement.user.editUser.access.data.anymalType.label'),
              creationTypes,
              t('next.userManagement.user.editUser.access.data.anymalType.message'),
              'creationTypes'
            )}

            {displayMultipleItems(
              t('next.userManagement.user.editUser.access.data.regional.label'),
              regionals,
              t('next.userManagement.user.editUser.access.data.regional.message'),
              'regionals'
            )}

            {displayMultipleItems(
              t('next.userManagement.user.editUser.access.data.unit.label'),
              centerList,
              t('next.userManagement.user.editUser.access.data.unit.message'),
              'centerList'
            )}

            <Separator />

            {/* \ Botões */}
            <Row>
              <Col size={4}>
                {!!userPermission && !!userPermission.delete && (
                  <Button
                    key="exclude"
                    onClick={() => {
                      deleteUser(userId);
                      setSelectedKey('user-list');
                    }}
                    className="white"
                    style={{ width: '155px' }}
                  >
                    {t('global.button.delete')}
                  </Button>
                )}
              </Col>
              <Col size={8}>
                <BtnGroup>
                  {!!userPermission && !!userPermission.edit && (
                    <Button
                      key="sendtoConfirm"
                      className="tertiary"
                      onClick={() => setSelectedKey('edit-user')}
                    >
                      {t("global.button.edit")}
                    </Button>
                  )}
                  <Button
                    key="clean"
                    onClick={() => {
                      setSelectedKey('user-list');
                      setSelectedUser({});
                    }}
                    className="white"
                  >
                    {t('global.button.back')}
                  </Button>
                </BtnGroup>
              </Col>
            </Row>
            {/* \ Botões */}
          </Form>
        )}
      </Container>
    </>
  );
}
