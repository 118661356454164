import React from 'react';
import { useState, useCallback } from 'react';

import EventForm from './components/EventForm';
import Visit from './components/Visit/Visit';
import Meeting from './components/Meeting/Meeting';
import t from 'components/i18n';

const eventOptions = [
  {
    value: 'visit',
    label: t('create-new-event.label.event-type.option.visit'),
  },
  {
    value: 'meeting',
    label: t('create-new-event.label.event-type.option.meet'),
    disabled: true,
  },
];

export default function Event(props) {
  const { history, user } = props;

  const [eventType, setEventType] = useState();

  const getEventForm = useCallback(() => {
    const component = {
      ['visit']: <Visit history={history} user={user} />,
      ['meeting']: <Meeting history={history} user={user} />,
    }[eventType];

    return component;
  }, [eventType]);

  return (
    <>
      {!eventType && (
        <EventForm
          onSubmit={setEventType}
          eventOptions={eventOptions}
          history={history}
        />
      )}

      {eventType && getEventForm()}
    </>
  );
}
