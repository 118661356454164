import React from 'react';
import Modal from 'components/Forms/FormModal/Modal';
import Toaster from 'components/Toaster';
import { removeSwineMortality } from 'containers/AgroBRF/FlockList/service';
import t from 'components/i18n';

export default function SwineMortalityModals({
  success,
  setSelectedKey,
  deceasedResponse,
  setDeceasedResponse,
  setUpdateItems,
  setSelectedDeceased,
  displayExcludeModal,
  selectedDeceased,
  setDisplayExcludeModal,
  setRemoved,
  removed,
}) {
  async function removeItem(id) {
    try {
      const response = await removeSwineMortality(id);
      setRemoved(response.success);
    } catch (e) {
      console.error('Fetch error', e);
    }
  }

  return (
    <>
      {/* Success or fail modal */}
      <Modal
        opened={deceasedResponse !== null}
        confirm={() => {
          setSelectedKey('addDeceased');
          setDeceasedResponse(null);
          setUpdateItems(true);
          setSelectedDeceased(null);
        }}
        deny={() => setDeceasedResponse(null)}
        onCloseModal={() => {
          setDeceasedResponse(null);
          setSelectedDeceased(null);
        }}
        warning={!success}
        noButtons={!success}
      >
        <span
          className={`icon icon-${
            success ? 'check' : 'exclamation-triangle'
          }`}
        />
        <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>

        <p>
          {success
            ? t('agro.flock.swine.mortality.delete.success')
            : deceasedResponse}
        </p>
      </Modal>
      {/* \ Success or fail modal */}

      {/* Exclude item modal */}
      <Modal
        warning
        opened={displayExcludeModal && selectedDeceased}
        confirm={() => {
          removeItem(selectedDeceased.id);
          setDisplayExcludeModal(false);
          setUpdateItems(removed);
        }}
        deny={() => setDisplayExcludeModal(false)}
        onCloseModal={() => {}}
      >
        <span className="icon icon-exclamation-triangle" />
        <h4>{t("agro.flock.swine.mortality.delete.title")}</h4>
        {/* <p>{cadecSubmitResponse}</p> */}
        <p>
          {t("agro.flock.swine.mortality.delete.message")}
        </p>
      </Modal>
      {/* \ Exclude item modal */}

      <Toaster
        className="toaster__notification"
        isVisible={removed}
        color="success"
        duration={3}
        delay={0}
      >
        <span className="icon icon-check" />
        {t('agro.flock.swine.mortality.delete.success')}
      </Toaster>
    </>
  );
}
