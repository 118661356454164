import { API_SUPERVISOR_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function GetProducerListVisits(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Producer/GetProducerListVisits`,
    {
      method: 'GET',
      payload
    },
  );

  return response;
}

export async function GetHousesByVendor(vendor) {
  const response = await request(
    `${API_SUPERVISOR_URL}/House/GetHousesByVendor?vendor=${vendor}`,
    {
      method: 'GET',
    },
  );

  return response;
}

export async function GetLogBookReason() {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/GetLogBookReason`,
    {
      method: 'GET',
    },
  );

  return response;
}

export async function SaveRandomVisits(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/SaveRandomVisits`,
    {
      method: 'POST',
      payload
    },
  );

  return response;
}
