import React, { useState, useEffect, useCallback } from 'react';
import { FieldArray } from 'formik';
import { Row, Col } from '@zendeskgarden/react-grid';
import { BtnGroup } from 'components//Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import {
  Form,
  Subheading,
  AddItem,
} from 'components/Forms/Forms.styled';
import t from 'components/i18n';
import {
  DropdownField,
  InputField,
  DropdownFieldFromArray,
} from 'components/Forms/Fields';
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import DeceasedConfirmation from './DeceasedConfirmation';

export default function AddDeceasedForm(props) {
  const [maxH, setMaxH] = useState(600);

  const {
    setGroupedByMortality,
    houses,
    isEditing,
    isLoading,
    isValid,
    deceasedDiscardedTypes,
    selectedDeceased,
    setSelectedDeceased,
    sendToConfirmation,
    setSendToConfirmation,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    values,
    errors,
    touched,
  } = props;

  // set houses
  const housesArray = houses;
  housesArray.forEach(
    house => (house.label = t('form.agro.swine-deceased.house', { value: house.value })),
  );

  // set mortality or deceased
  const types = [
    { label: t('form.agro.swine-deceased.types-mortality'), isMortality: true },
    { label: t('form.agro.swine-deceased.types-deceased'), isMortality: false },
  ];

  const getReason = useCallback(
    (reason, index) => {
      const mortality = deceasedDiscardedTypes.filter(e => {
        return (
          values.swineDeceasedDiscarded[index][reason] &&
          e.id ===
            (values.swineDeceasedDiscarded[index][reason] ||
              values.swineDeceasedDiscarded[index][reason].id)
        );
      })[0];

      return mortality;
    },
    [deceasedDiscardedTypes, values.swineDeceasedDiscarded],
  );

  const groupMortalities = useCallback(arr => {
    // group array by generic id
    const group = arr.reduce((r, a) => {
      r[a.simpleId] = [...(r[a.simpleId] || []), a];

      return r;
    }, {});

    return group;
  }, []);

  useEffect(() => {
    if (values.swineDeceasedDiscarded) {
      values.swineDeceasedDiscarded.map(item => {
        if (item.origin) {
          return (item.origin =
            item.origin.indexOf('BRF') > -1
              ? item.origin.substring(3, 7)
              : item.origin);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // sets a new generic id for mortality or discarded.
    values.swineDeceasedDiscarded.map(
      item =>
        (item.simpleId = item.mortalityTypeId
          ? item.mortalityTypeId.id
          : item.discardedTypeId && item.discardedTypeId.id),
    );

    setGroupedByMortality(
      groupMortalities(values.swineDeceasedDiscarded),
    );
  }, [
    groupMortalities,
    setGroupedByMortality,
    values.swineDeceasedDiscarded,
  ]);

  useEffect(() => {
    if (!sendToConfirmation && houses.length > 0) {
      const house = houses.filter(
        item => values.houseId === item.id,
      )[0];

      setFieldValue('house', house);

      if (values.swineDeceasedDiscarded) {
        values.swineDeceasedDiscarded.map((i, index) => {
          const mortality = getReason('mortalityTypeId', index);
          const discarded = getReason('discardedTypeId', index);

          if (mortality) {
            if (mortality.isMortality) {
              setFieldValue(
                `swineDeceasedDiscarded.${index}.deceasedOrDiscarded`,
                { isMortality: true, label: 'Mortalidade' },
              );

              setFieldValue(
                `swineDeceasedDiscarded.${index}.mortalityTypeId`,
                mortality,
              );
            }
          }

          if (discarded) {
            setFieldValue(
              `swineDeceasedDiscarded.${index}.deceasedOrDiscarded`,
              { isMortality: false, label: 'Descarte' },
            );

            setFieldValue(
              `swineDeceasedDiscarded.${index}.discardedTypeId`,
              discarded,
            );
          }
        });
      }
    }

    if (sendToConfirmation) {
      if (values.house) {
        setFieldValue('houseId', values.house.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendToConfirmation]);

  const checkedValues = useCallback(
    index => {
      return (
        toString(
          values.swineDeceasedDiscarded[index].femaleQuantity,
        ) &&
        toString(values.swineDeceasedDiscarded[index].maleQuantity)
      );
    },
    [values.swineDeceasedDiscarded],
  );

  return (
    values &&
    deceasedDiscardedTypes.length > 0 && (
      <Form autoComplete="off">
        {!sendToConfirmation ? (
          <>
            <Row>
              <Col md={6}>
                {/* Instalação */}
                <DropdownField
                  selectedItem={values.house}
                  field="house"
                  label={t('form.agro.swine-deceased.field.house.label')}
                  placeholder={t('form.agro.swine-deceased.field.house.placeholder')}
                  onSelect={item => {
                    setFieldValue('house', item);
                  }}
                  disabledItems="show"
                  options={housesArray}
                  optionLabel="label"
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \ Instalação */}
              </Col>
              <Col md={6}>
                {/* Data */}
                <InputField
                  type="date"
                  field="deceasedDiscardedDate"
                  label={t('form.agro.swine-deceased.field.deceasedDiscardedDate.label')}
                  placeholder={t('form.agro.swine-deceased.field.deceasedDiscardedDate.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* \ Data */}
              </Col>
            </Row>

            <Subheading>{t('form.agro.swine-deceased.mortality.section')}</Subheading>
            <FieldArray
              name="swineDeceasedDiscarded"
              render={arrayHelpers =>
                values.swineDeceasedDiscarded.map((item, index) => (
                  <>
                    {index > 0 && checkedValues(index) && (
                      <div key={index}>
                        <AddItem
                          onClick={() => {
                            arrayHelpers.remove(index);
                            setMaxH(maxH - 152);
                          }}
                        >
                          <span className="icon icon-minus" />
                          {t('form.agro.swine-deceased.button.remove-mortality')}
                        </AddItem>
                      </div>
                    )}
                    <DropdownFieldFromArray
                      field={`swineDeceasedDiscarded.${index}.mortalityTypeId`}
                      label={t('form.agro.swine-deceased.field.swineDeceasedDiscarded.death-reason.label')}
                      placeholder={t('form.agro.swine-deceased.field.swineDeceasedDiscarded.death-reason.placeholder')}
                      fieldString="mortalityTypeId"
                      from="swineDeceasedDiscarded"
                      onSelect={e => {
                        setFieldValue(
                          `swineDeceasedDiscarded.${index}.mortalityTypeId`,
                          e,
                        );
                        setFieldValue(
                          `swineDeceasedDiscarded.${index}.discardedTypeId`,
                          null,
                        );
                        setFieldValue(
                          `swineDeceasedDiscarded.${index}.mortality`,
                          null,
                        );
                      }}
                      optionLabel="description"
                      // disabled={
                      //   !values.swineDeceasedDiscarded[index]
                      //     .deceasedOrDiscarded
                      // }
                      index={index}
                      options={
                        deceasedDiscardedTypes.filter(
                          type => type.isMortality,
                        ) || []
                      }
                      values={values}
                      errors={errors}
                      touched={touched}
                    />

                    {/* Tatuagem */}
                    <InputField
                      masked
                      type="tattoo"
                      field={`swineDeceasedDiscarded.${index}.origin`}
                      label={t('form.agro.swine-deceased.field.tatoo.label')}
                      placeholder={t('form.agro.swine-deceased.field.tatoo.placeholder')}
                      fieldString="origin"
                      index={index}
                      setState={e =>
                        setFieldValue(
                          `swineDeceasedDiscarded.${index}.origin`,
                          e,
                        )
                      }
                      from="swineDeceasedDiscarded"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                    {/* \ Tatuagem */}

                    <Row>
                      <Col>
                        {/* Quantidade de fêmeas */}
                        <InputField
                          type="number"
                          field={`swineDeceasedDiscarded.${index}.femaleQuantity`}
                          label={t('form.agro.swine-deceased.field.swineDeceasedDiscarded.femaleQuantity.label')}
                          placeholder={t('form.agro.swine-deceased.field.swineDeceasedDiscarded.femaleQuantity.placeholder')}
                          fieldString="femaleQuantity"
                          index={index}
                          from="swineDeceasedDiscarded"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          min={0}
                          disabled={
                            !(
                              values.swineDeceasedDiscarded[index]
                                .mortalityTypeId ||
                              values.swineDeceasedDiscarded[index]
                                .discardedTypeId
                            )
                          }
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                        {/* \ Quantidade de fêmeas */}
                      </Col>

                      <Col>
                        {/* Quantidade de machos */}
                        <InputField
                          type="number"
                          field={`swineDeceasedDiscarded.${index}.maleQuantity`}
                          label={t("form.agro.swine-deceased.field.swineDeceasedDiscarded.maleQuantity.label")}
                          placeholder={t("form.agro.swine-deceased.field.swineDeceasedDiscarded.maleQuantity.placeholder")}
                          fieldString="maleQuantity"
                          index={index}
                          from="swineDeceasedDiscarded"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          min={0}
                          disabled={
                            !(
                              values.swineDeceasedDiscarded[index]
                                .mortalityTypeId ||
                              values.swineDeceasedDiscarded[index]
                                .discardedTypeId
                            )
                          }
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                        {/* \ Quantidade de machos */}
                      </Col>
                    </Row>
                    {checkedValues(index) && (
                      <div>
                        <AddItem
                          onClick={() => {
                            arrayHelpers.push({
                              maleQuantity: '',
                              femaleQuantity: '',
                              mortalityTypeId: '',
                              discardedTypeId: '',
                            });
                            setMaxH(maxH + 182); // section height
                          }}
                        >
                          <span className="icon icon-plus" />{' '}
                          {t('form.agro.swine-deceased.button.add-mortality')}
                        </AddItem>
                      </div>
                    )}
                  </>
                ))
              }
            />

            <Separator />

            {/* \ Botões */}
            <BtnGroup>
              <Button
                key="sendtoConfirm"
                onClick={() => {
                  props.setSelectedDeceased(values);
                  setSendToConfirmation(true);
                }}
                disabled={!props.isValid}
              >
                {t('global.button.next')}
              </Button>
              <Button
                key="clean"
                onClick={() => {
                  resetForm();
                }}
                className="white"
              >
                {t("global.button.clear")}
              </Button>
            </BtnGroup>
            {/* \ Botões */}
          </>
        ) : (
          <DeceasedConfirmation
            {...values}
            isSubmitting={props.isSubmitting}
            backToForm={() => {
              setSendToConfirmation(false);
            }}
            goTo={props.goTo}
            onSubmit={handleSubmit}
            message={props.message}
            isLoading={isLoading}
            isEditing={isEditing}
            isValid={isValid}
            deceasedDiscardedTypes={deceasedDiscardedTypes}
            setSelectedDeceased={setSelectedDeceased}
            selectedDeceased={selectedDeceased}
            isEditable={values.isEditable}
            setSendToConfirmation={setSendToConfirmation}
          />
        )}
      </Form>
    )
  );
}
