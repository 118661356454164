import React, { useState, useEffect } from 'react';
import uid from 'uuid';
import StepWizard from 'react-step-wizard';
import {
  Container,
  Header,
  Content,
  TabHeaderHolder,
  TabContentHolder,
} from './Tabs.styled';

function Wrapper({ children, ...rest }) {
  return (
    <>
      {children && (
        <>
          {typeof children === 'function' && children({ ...rest })}
          {typeof children !== 'function' && children}
        </>
      )}
    </>
  );
}

export function TabContent(props) {
  return (
    <TabContentHolder className={props.active ? 'active' : ''}>
      {props.children}
    </TabContentHolder>
  );
}

export function TabHeader(props) {
  const {
    onClick,
    active,
    children,
    tabIdx,
    customClick
  } = props;

  return (
    <TabHeaderHolder
      onClick={e => {
        onClick(e, tabIdx);
        if (customClick) {
          customClick(e, tabIdx);
        }
      }}
      className={active ? 'active' : ''}
    >
      <div className="label">{children}</div>
      <div className="bar" />
    </TabHeaderHolder>
  );
}

TabHeader.displayName = 'TabHeader';

export function Tab(props) {
  return props.children;
}

export function SimpleTabs(props) {
  return props.children;
}

export default function Tabs(props) {
  const tabContent = [];
  const tabHeaders = [];
  const [activeIdx, setActiveIdx] = useState(null);

  let wizard;

  function onSetClick(e, tabIdx) {
    setActiveIdx(tabIdx);
  }

  function getActiveTab(idx) {
    if (activeIdx === idx) {
      return true;
    }

    if (activeIdx === null && idx === 0) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    if (props.firstActiveTab) setActiveIdx(props.firstActiveTab);
  }, [props.firstActiveTab]);

  useEffect(() => {
    wizard.goToStep(activeIdx + 1);
  }, [activeIdx, wizard]);

  React.Children.forEach(props.children, (child, idx) => {
    React.Children.forEach(child.props.children, ch => {
      if (ch.type.displayName === 'TabHeader') {
        tabHeaders.push(
          React.cloneElement(ch, {
            key: uid(),
            tabIdx: idx,
            onClick: onSetClick,
            active: getActiveTab(idx),
          }),
        );
      } else {
        tabContent.push(
          React.cloneElement(ch, {
            key: uid(),
            tabIdx: idx,
          }),
        );
      }
    });
  });

  return (
    <Container>
      <Header>{tabHeaders}</Header>
      <Content>
        <StepWizard>
          {React.Children.map(tabContent, child => (
            <Wrapper key={uid()}>
              {wizardProps => {
                wizard = wizardProps;
                return React.cloneElement(child, {
                  key: uid(),
                });
              }}
            </Wrapper>
          ))}
        </StepWizard>
      </Content>
    </Container>
  );
}
