import React, { useMemo } from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Label, Checkbox, Field } from '@zendeskgarden/react-forms';
import {
  CheckboxTableField,
  FieldTitle,
  FieldRow,
} from './CheckboxTable.styed';

export default function CheckboxTable({
  fieldGroup,
  values,
  list,
  itemList,
  title,
  labelList,
  setFieldValue,
  labelOption,
  isProfileForm,
  disableAll = false,
}) {
  return (
    <CheckboxTableField>
      <FieldTitle>
        <Row>
          <Col sm={4}>
            <p>{title}</p>
          </Col>
          <Col sm={8}>
            <Row>
              {labelList.length > 0 &&
                labelList.map(item => (
                  <Col sm={3}>
                    <p className="center">{
                      typeof item === "object"
                        ? item[labelOption]
                        : item
                    }</p>
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </FieldTitle>
      {list &&
        list.length > 0 &&
        list.map((item, index) => (
          <FieldRow>
            <Row>
              <Col sm={4}>
                <p>{item[labelOption] || item.name}</p>
              </Col>
              <Col sm={8}>
                <Row>
                  {item.checkboxList.length > 0 &&
                    item.checkboxList.map((checkbox, cbIndex) => {
                      let val = values &&
                        values[fieldGroup] &&
                        values[fieldGroup][index] &&
                        values[fieldGroup][index][itemList] &&
                        values[fieldGroup][index][itemList][cbIndex];

                        if (isProfileForm) {
                          const checkboxValue = values[fieldGroup].find(checkValue => checkValue.id === item.id);
                          val = Boolean(checkboxValue ? checkboxValue.level[cbIndex] : val);
                        }
                      return (
                        <Col sm={3} className="center">
                          <Field>
                            <Checkbox
                              type="checkbox"
                              name={`${fieldGroup}.${index}.${itemList}.${cbIndex}`}
                              onChange={e => setFieldValue(
                                `${fieldGroup}.${index}.${itemList}.${cbIndex}`,
                                !val
                              )}
                              checked={checkbox.disabled ? false : val}
                              disabled={disableAll ||
                                checkbox.disabled}
                            >
                              <Label />
                            </Checkbox>
                          </Field>
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            </Row>
          </FieldRow>
        ))}
    </CheckboxTableField>
  );
}
