import React, { useState, useEffect, useContext } from 'react';
import * as routes from 'config/routes';
import { ThemeContext } from 'styled-components';
import Breadcrumb from 'components/Breadcrumb';
import NewsColumn from './NewsColumn';
import t from 'components/i18n';
import FullNewsItem from './FullNewsItem';
import { getNews } from './service';
import { CardColumn, ListColumn, Wrapper, Flex } from './News.styled';

export default function FullNewsWrapper(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [latest, setLatest] = useState([]);

  const themeContext = useContext(ThemeContext);

  async function fetchNews() {
    setIsLoading(true);

    try {
      const response = await getNews();
      setLatest(response);
      setIsLoading(false);
    } catch (e) {
      console.error('Fetch error', e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <>
      <Breadcrumb
        history={props.history}
        path={[t('news.title')]}
        backTo={() => props.history.push(routes.NEWS_LIST)}
      />
      <Wrapper>
        {!isLoading && (
          <Flex>
            <CardColumn>
              <FullNewsItem
                newsId={props.match.params.id}
                history={props.history}
              />
              {/* <NewsCard title="Relacionados" /> */}
            </CardColumn>
            <ListColumn>
              <NewsColumn
                title={t('news.latest-news')}
                list={latest}
                history={props.history}
                colorPrimary={
                  themeContext.styles.palette.colorPrimary
                }
              />
            </ListColumn>
          </Flex>
        )}
      </Wrapper>
    </>
  );
}
