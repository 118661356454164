import React, { useState, useCallback } from 'react';
import moment from 'moment';
import Icon from 'components/Icon';
import CircleAndTitle from 'components/CircleAndTitle';

import {
  colorDanger,
  colorGrayLighter,
  colorSuccess,
} from 'components/GlobalStyles/variables';
import PlanCard from './PlanCard';
import {
  QuestionWrapper,
  Question,
  Answer,
} from './CircuitQuestion.styled';
import t from 'components/i18n';
import { questionTitleMapper } from 'utils/mapper';

export default function CircuitQuestionWithAnswer(props) {
  const {
    isEditable,
    vendorCode,
    number,
    title,
    setEditPlan,
    setDeleteModal,
    setDisplayDetails,
    isFinished,
    logBookChecklistAnswers,
    logBookChecklistSupervisorAnswers = [],
    handleChangeExecuted,
  } = props;

  const answer =
    logBookChecklistSupervisorAnswers.length > 0 &&
    logBookChecklistSupervisorAnswers.find(answer => String(answer.vendor).padStart(10, '0') === String(vendorCode).padStart(10, '0'));

  const findAnswerTitle = logBookChecklistAnswers.filter(
    item => item.id === answer.logBookChecklistAnswerId,
  );

  const plan = answer.logBookChecklistActionPlan;

  return (
    <QuestionWrapper>
      <Question>
        <CircleAndTitle
          initials={number}
          description={title}
          bgColor="colorPrimary"
        />
      </Question>

      {(answer === 'Sim' || answer.answer === true) && (
        <Answer>
          <Icon name="icon-check" size={26} color={colorSuccess} />
          {t('circuitQuestion.defaultButton.yes')}
        </Answer>
      )}

      {answer.answer === 'none' && (
        <Answer>
          <Icon
            name="icon-minus"
            size={26}
            color={colorGrayLighter}
          />
          {t("circuitQuestion.defaultButton.doNotApply")}
        </Answer>
      )}

      {answer &&
        answer.answer !== 'none' &&
        typeof answer.answer !== 'boolean' && (
          <Answer>
            <Icon name="icon-check" size={26} color={colorSuccess} />
            {findAnswerTitle.length > 0
              ? typeof findAnswerTitle[0] === 'object'
                ? questionTitleMapper(findAnswerTitle[0].title)
                : questionTitleMapper(findAnswerTitle[0])
              : answer.answer}
          </Answer>
        )}

      {(answer === 'Não' || answer.answer === false) && (
        <Answer>
          <Icon name="icon-close" size={26} color={colorDanger} />
          {t('circuitQuestion.defaultButton.no')}
        </Answer>
      )}

      {plan && (
        <PlanCard
          date={moment(plan.expectedDate).format('DD ̸̸  MM ̸̸  YYYY')}
          description={plan.action}
          footerContent={
            plan.wasExecuted
              ? t('circuitQuestion.actionPlan.executed')
              : t('circuitQuestion.actionPlan.executionDeadline', { value: moment(plan.expectedDate).format('DD ̸̸  MM ̸̸  YYYY') })
          }
          footerColor={
            plan.wasExecuted ? 'colorSuccess' : 'colorInfo'
          }
          footerIcon={
            plan.wasExecuted
              ? 'icon-check'
              : 'icon-exclamation-circle'
          }
          hasAttachment={
            plan.attachmentsUrls && plan.attachmentsUrls.length > 0
          }
          setDeleteModal={setDeleteModal}
          setEditPlan={setEditPlan}
          displayOptions={(isEditable && !plan.wasExecuted) && !isFinished}
          setDisplayDetails={() => setDisplayDetails(plan)}
          wasExecuted={plan.wasExecuted}
          logBookChecklistAnswerId={answer.logBookChecklistAnswerId}
          actionPlanId={plan.id}
          vendorCode={vendorCode}
          handleChangeExecuted={handleChangeExecuted}
        />
      )}
    </QuestionWrapper>
  );
}
