import React, { useState, useRef, memo } from 'react';
import { Message, Input, Label } from '@zendeskgarden/react-forms';
import { useTranslation } from 'react-i18next';
import {
  Close,
  RoundedItem,
} from 'components/MultiSelect/Items.styled';
import {
  getValidationMessage,
  getValidation,
} from 'shared/utils/Helpers';
import {
  Counter,
  FieldSet,
  Spacer,
} from 'components/Forms/Forms.styled';
import MaskedInput from 'components/MaskedInput';
import Toaster from 'components/Toaster';

function InputField(props) {
  const [lengthCounter, setLengthCounter] = useState(0);
  const [error, setError] = useState('');
  const [file, setFile] = useState(false);
  const InputComponent = props.masked ? MaskedInput : Input;
  const inputRef = useRef(null);
  const pattern =
    props.type === 'number' ? '[0-9]+([,.][0-9]+)?' : undefined;

  const imageExtensions = [
    'png',
    'jpg',
    'jpeg',
    'svg',
    'webp',
    'jfif',
  ];
  const docExtensions = ['pdf'];
  const maxLenFile = 20971520;

  const { t } = useTranslation();

  return (
    <FieldSet>
      {props.label && <Label>{props.label}</Label>}

      {props.hiddenLabel && <Spacer> </Spacer>}

      <InputComponent
        name={props.field}
        type={props.type}
        className="input_field"
        placeholder={props.placeholder}
        value={
          props.from
            ? props.values[props.from][props.index][props.fieldString]
            : props.values[props.field]
        }
        onBlur={props.onBlur}
        setState={props.setState}
        ref={props.reference || inputRef}
        min={props.min}
        max={props.max}
        disabled={props.disabled}
        accept={props.accept}
        multiple={props.multiple}
        pattern={pattern}
        onChange={e => {
          if (props.accept) {
            const [fileExtension] = (
              (e.target && e.target.value) ||
              ''
            ).match(/[^.]+$/i);

            const fileSize = e.target && e.target.files[0].size;

            const [type, extension] = props.accept.split('/');

            const acceptAll =
              Boolean(extension) && extension.includes('*');

            // Validação de imagem
            const imageValidation = acceptAll
              ? imageExtensions.includes(fileExtension)
              : props.accept.includes(fileExtension);

            // Validação de documentos
            const fileValidation = acceptAll
              ? docExtensions.includes(fileExtension)
              : props.accept.includes(fileExtension);

            console.log('File PDF');
            console.log(fileSize, 'File size)');
            console.log(maxLenFile, 'File size');

            if (
              (type === 'image' && !imageValidation) ||
              (type === 'application' && !fileValidation) ||
              fileSize > maxLenFile
            ) {
              setError('Arquivo inválido ou maior que o permitido');
              setFile(false); // Assumindo que essa função deve ser 'false' para indicar falha na validação
              return;
            }

            // Se o arquivo passar na validação, você pode continuar com a inclusão do arquivo
            setFile(true);
          }
          props.onChange(e);
          props.counter && setLengthCounter(e.target.value.length);
        }}
        onClick={props.onClick}
      />
      {!Boolean((props.errors || []).length) ? null : props.from ? (
        <Message
          validation={getValidation(
            props.values[props.from][props.index][props.fieldString],
            props.errors,
            `${props.from}[${props.index}].${props.fieldString}`,
            !props.touched[props.from],
          )}
        >
          {getValidationMessage(
            `${props.from}[${props.index}].${props.fieldString}`,
            props.errors,
            !props.touched[props.from],
          )}
        </Message>
      ) : (
        <Message
          validation={getValidation(
            props.values[props.field],
            props.errors,
            props.field,
            !props.touched[props.field],
          )}
        >
          {getValidationMessage(
            props.field,
            props.errors,
            !props.touched[props.field],
          )}
        </Message>
      )}
      {props.counter && (
        <Counter>
          <span
            className={
              inputRef.current &&
              inputRef.current.value.length > props.max
                ? 'red'
                : ''
            }
          >
            {lengthCounter}
          </span>
          /{props.max}
        </Counter>
      )}

      {/* Shows previous selected file (editing mode) */}
      {props.type === 'file' &&
        props.previousFile &&
        !props.values[props.field] &&
        file && (
          <RoundedItem>
            <p>
              <a
                href={props.previousFile}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.fileLabel || props.previousFile}
              </a>
            </p>
            <Close
              onClick={() => props.removeFile(props.previousFile)}
            >
              <span className="icon icon-close-circle" />
            </Close>
          </RoundedItem>
        )}
      {/* \ Shows previous selected file (editing mode) */}
      {file && (
        <Toaster
          className="toaster__notification"
          isVisible={file}
          color="danger"
          duration={3}
          delay={0.05}
          title={t('sendInvoice.error.title')}
        >
          <span className="icon icon-exclamation-triangle" />
          {t(error)}
        </Toaster>
      )}
    </FieldSet>
  );
}

export default memo(InputField);
