// @flow

import React, { useState, useEffect } from 'react';
import logger from 'shared/utils/logger';
import Tabs, { Tab, TabContent, TabHeader } from 'components/Tabs';
import IconPlaceholder from 'components/IconPlaceholder';
import t from 'components/i18n';
import Icon from 'components/Icon';
import { Dots } from '@zendeskgarden/react-loaders';
import { colorPrimary } from 'components/GlobalStyles/variables';
import BrowserHistory from 'shared/history';
import * as routes from 'config/routes';
import Top from 'components/Top';
import { List, ListItem } from 'components/List';
import NoContentTab from 'components/NoContentTab';

// service
import { getFlockListSupervisor, getVendors } from './service';

// style
import {
  Container,
  Content,
  Main,
  Initials,
} from './FlockList.styled';

function getIcon(type) {
  const types = {
    broiler: 'icon-chicken',
    turkey: 'icon-turkey',
    pig: 'icon-pig',
    swine: 'icon-pig',
  };

  return types[type];
}

// gets flock lists from all producers
function GetFullFlockList({ data, isLoading, history }) {
  const hasData = data && data.length > 0;

  return (
    <List>
      {isLoading && (
        <ListItem className="animated fadeIn centered">
          <Dots color={colorPrimary} size="16" />
        </ListItem>
      )}

      {!isLoading && !hasData && (
        <NoContentTab icon="icon-flock">
          <p>{t('flocks.view.next.empty.title')} </p>
        </NoContentTab>
      )}

      {!isLoading &&
        hasData &&
        data.map(item => (
          <ListItem
            role="button"
            key={item.id}
            onClick={() =>
              history.push(`${routes.FLOCK_LIST}/${item.id}`)
            }
            className="animated fadeIn"
            noMargin
          >
            <IconPlaceholder
              className={`top icon-holder ${
                item.status === 'open' ? 'success' : 'danger'
              }`}
            >
              <Icon className="icon" name={getIcon(item.type)} />
            </IconPlaceholder>

            <div className="list-item-content">
              <h2 className="list-item-heading">
                {t(item.title && item.title.key, {
                  value: item.title && item.title.value,
                })}
              </h2>
              <p className="list-subheading">
                {t("flocks.flocksDetail.sectionLastestRecords.vendorCode", { value: item.vendorCode })}
              </p>
              <p className="list-item-description">
                {t(item.house && item.house.key, {
                  value: item.house && item.house.value,
                })}
              </p>
            </div>

            <Icon name="chevron icon-chevron-right" />
          </ListItem>
        ))}
    </List>
  );
}

function GetVendorsList({ data, isLoading, history }) {
  const hasData = data && data.length > 0;

  return (
    <List>
      {isLoading && (
        <ListItem className="centered animated fadeIn">
          <Dots color={colorPrimary} size="16" />
        </ListItem>
      )}

      {!isLoading && !hasData && (
        <NoContentTab icon="icon-flock">
          <p>{t('flocks.flocksDetail.sectionLastestRecords.empty.title')} </p>
        </NoContentTab>
      )}

      {!isLoading &&
        hasData &&
        data.map(item => (
          <ListItem
            role="button"
            key={item.cpf}
            onClick={() => {
              history.push(`${routes.VENDOR}/${item.cpf}`);
            }}
            className="animated fadeIn"
            noMargin
          >
            <Initials>{item.initialsName}</Initials>

            <div className="list-item-content">
              <div className="list-item-heading">{item.name}</div>

              <div className="list-item-description">
                {t(item.description.key, {value: item.description.value})}
              </div>
            </div>

            <Icon name="chevron icon-chevron-right" />
          </ListItem>
        ))}
    </List>
  );
}

type TFlockListProps = {
  history: typeof BrowserHistory,
};

export default function SupervisorFlockList(props: TFlockListProps) {
  const [flockIsLoading, setFlockIsLoading] = useState(true);
  const [vendorIsLoading, setVendorIsLoading] = useState(true);
  const [flockData, setFlockData] = useState({});
  const [vendors, setVendors] = useState({});

  useEffect(() => {
    async function fetchData() {
      setFlockIsLoading(true);
      try {
        const response = await getFlockListSupervisor();
        setFlockData(response);
        if (response) setFlockIsLoading(false);
      } catch (e) {
        logger('Fetch error', e);
        setFlockIsLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchVendors() {
      setVendorIsLoading(true);
      try {
        const response = await getVendors('');
        setVendors(response);
        if (response) setVendorIsLoading(false);
      } catch (e) {
        logger('Fetch error', e);
        setVendorIsLoading(false);
      }
    }

    fetchVendors();
  }, []);

  return (
    <Container className="supervisor-flock-list">
      <Top>
        <h2>{t('flocks.title')}</h2>
      </Top>

      <Content>
        <Main>
          <Tabs>
            <Tab>
              <TabHeader>{t('flocks.view.next.tabMenu.all')}</TabHeader>
              <TabContent>
                <GetFullFlockList
                  data={flockData}
                  isLoading={flockIsLoading}
                  history={props.history}
                />
              </TabContent>
            </Tab>

            <Tab>
              <TabHeader>{t('flocks.view.next.tabMenu.growers')}</TabHeader>
              <TabContent>
                <GetVendorsList
                  data={vendors}
                  isLoading={vendorIsLoading}
                  history={props.history}
                />
              </TabContent>
            </Tab>
          </Tabs>
        </Main>
      </Content>
    </Container>
  );
}
