import React, { useCallback, useMemo, useContext } from 'react';
import moment from 'moment';
import { Row, Col } from '@zendeskgarden/react-grid';
import Badge from 'components/Badge';
import FooterMenu from 'components/FooterMenu';

// Contexts
import { SwineContext } from 'shared/contexts';
// Styles
import {
  Container,
  ItemContainer,
  FeedItem,
} from 'containers/AgroBRF/FlockList/Flock/FlockHistory/FlockHistory.styled';
import t from 'components/i18n';
import { statusMapper } from 'utils/mapper';

export default function DeceasedSummaryItem(props) {
  const { feed, goTo, exclude } = props;
  const swineDeceasedContext = useContext(SwineContext);
  const {
    setSelectedDeceased,
    setSelectedHouse,
  } = swineDeceasedContext.actions;
  const badgeProps = {};

  const selectItem = useCallback(
    option => {
      setSelectedDeceased(feed);
      setSelectedHouse(feed.house);
      goTo(option);
    },
    [feed, goTo, setSelectedDeceased, setSelectedHouse],
  );

  const getOptions = useCallback(
    () => {
      const options = [
        {
          action: () => selectItem('deceasedConfirmation'),
          icon: 'visibility-enabled',
          label: t('next.swineRecord.deceasedSummaryItem.options.details'),
          visible: true,
        },
        {
          action: () => selectItem('editDeceased'),
          icon: 'edit',
          label: t('next.swineRecord.deceasedSummaryItem.options.editar'),
          visible: feed.isEditable,
        },
        {
          action: () => {
            exclude();
            setSelectedDeceased(feed);
          },
          icon: 'trash',
          label: t('next.swineRecord.deceasedSummaryItem.options.excluir'),
          visible: true,
        },
      ];
      return options;
    },
    [exclude, feed, selectItem, setSelectedDeceased],
  );

  if (feed.status) {
    switch (feed.status.toLowerCase()) {
      case 'pendente':
        badgeProps.info = true;
        break;
      default:
        badgeProps.success = true;
    }
  }

  const getTotal = useCallback(
    gender => {
      const total = feed.swineDeceasedDiscarded.reduce(
        (ac, current) => ac + current[gender],
        0,
      );
      return total;
    },
    [feed.swineDeceasedDiscarded],
  );

  const listOrigins = useMemo(() => {
    return feed.swineDeceasedDiscardedResume.origin;
  }, [feed.swineDeceasedDiscardedResume.origin]);

  const checkMortalityType = useCallback(() => {
    function findType(type) {
      return feed.swineDeceasedDiscarded.find(e => e[type] !== null);
    }
    if (findType('mortalityTypeId') && findType('discardedTypeId')) {
      return t('next.swineRecord.deceasedSummaryItem.mortalityType.mortalityType-discardedType');
    }
    if (findType('mortalityTypeId')) {
      return t('next.swineRecord.deceasedSummaryItem.mortalityType.mortalityType');
    }

    if (findType('discardedTypeId')) {
      return t('next.swineRecord.deceasedSummaryItem.mortalityType.discartedType');
    }

    return false;
  }, [feed.swineDeceasedDiscarded]);

  return (
    <Container className="bordered-container">
      <ItemContainer
        className="item-container"
        onClick={() => selectItem('deceasedConfirmation')}
      >
        <div className="open summary">
          <FeedItem key={`feed-item-${feed.id}`} swine>
            <header className="item-header">
              <div className="date">
                {moment(feed.deceasedDiscardedDate).format(
                  'DD ̸̸  MM ̸̸  YYYY',
                )}
              </div>
              <div className="status">
                {feed.status && (
                  <Badge {...badgeProps}>{t(statusMapper(feed.status))}</Badge>
                )}
              </div>
            </header>
            <Row>
              <Col>
                <strong>
                  {t('next.swineRecord.deceasedSummaryItem.mortalityType.totalMortality', { value: `${getTotal('maleQuantity')} + ${getTotal('femaleQuantity')}}` })}
                </strong>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>{checkMortalityType()}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <span className="strong">
                    {t('next.swineRecord.deceasedSummaryItem.mortalityType.origin', { value: listOrigins })}  
                  </span>
                </p>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <p>{t('deceased.summary.house', {value: feed.house })}</p>
              </Col>
            </Row>
          </FeedItem>
        </div>
      </ItemContainer>

      {!props.hideOptions && (
        <FooterMenu
          placement="start-bottom"
          options={getOptions(feed.isEditable)}
        />
      )}
    </Container>
  );
}
