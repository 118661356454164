import { Button } from '@zendeskgarden/react-buttons';
import Container from 'components/Container';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { DropdownField } from 'components/Forms/Fields';
import HorizontalSteps from 'components/HorizontalSteps';
import t from 'components/i18n';
import React from 'react';
import { questionTitleMapper } from 'utils/mapper';
// styles
import { Separator } from './Checklists.styled';

const AnswerChecklist = ({
  checklist,
  currentStep,
  setCurrentStep,
  onGoBack,
  onSetAnswer,
  filledCategories,
  onConfirm,
}) => {
  const steps = Object.keys(checklist.questionGroupedByCategory);
  const questionOnCurrentStep =
    checklist.questionGroupedByCategory[currentStep];

  const previousStep = steps[steps.indexOf(currentStep) - 1];
  const nextStep = steps[steps.indexOf(currentStep) + 1];
  const hasFilledEverything = Object.values(
    checklist.answerSheet,
  ).every(answer => !!answer);

  return (
    <Container className="container" hideDetail slim>
      <HorizontalSteps
        activeStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
        completedSteps={filledCategories}
      />

      <div style={{ minHeight: '300px' }}>
        {!!questionOnCurrentStep &&
          !!questionOnCurrentStep.length &&
          questionOnCurrentStep.map(question => (
            <>
              <Separator style={{ marginBottom: 14 }} />

              <DropdownField
                field={question.codPerguntaIrn}
                label={question.desPergunta}
                placeholder={t('checklist.answer.dropdown.label')}
                onSelect={item =>
                  onSetAnswer(question.codPerguntaIrn, item)
                }
                options={question.answers.map(answer => ({
                  id: answer.codRespostaIrn,
                  desc: answer.desResposta,
                  label: questionTitleMapper(answer.desResposta)
                }))}
                optionLabel="label"
                values={checklist.answerSheet}
                hideMessage
              />
            </>
          ))}
      </div>

      <Separator style={{ marginBottom: 14 }} />
      <BtnGroup>
        <Button
          key="sendtoConfirm"
          onClick={() =>
            nextStep ? setCurrentStep(nextStep) : onConfirm()
          }
          className="tertiary"
          disabled={!nextStep && !hasFilledEverything}
        >
          {t('global.button.next')}
        </Button>
        <Button
          key="clean"
          onClick={() =>
            previousStep ? setCurrentStep(previousStep) : onGoBack()
          }
          className="white"
        >
          {t('global.button.back')}
        </Button>
      </BtnGroup>
    </Container>
  );
};

export default AnswerChecklist;
