import styled from "styled-components";
import ContainerBase from 'components/Container';
import { colorGrayDarker, colorGrayLight, colorGrayLighter } from "components/GlobalStyles/variables";

export const Container = styled(ContainerBase)`
  margin: 16px;

  .container-content {
    width: 60%;
    min-width: 620px;
    height: fit-content;
    padding: 6px 16px 16px 16px;
    .container-inner {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }
  }
`;

export const Content = styled.div`
  padding: 0 20px 0 60px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;

  .icon {
    font-size: 22px;
    color: ${colorGrayLighter}
    margin: 0 26px 0 12px;
  }
`;

export const UserName = styled.p`
  margin: 0;
  weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colorGrayDarker};
`;

export const InlineInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;

    &:first-of-type {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: ${colorGrayLight};
    }

    &:last-of-type {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: ${colorGrayDarker};
    }
  }
`;
