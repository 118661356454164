import styled from 'styled-components';
import {
  colorClear,
  colorGrayLighter,
} from 'components/GlobalStyles/variables';

export const Circular = styled.div`
  color: ${colorClear};
  display: inline-block;
  background-color: ${props => props.bgColor || colorGrayLighter};
  border-radius: 50%;
  margin-right: 16px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  line-height: 40px;
  text-align: center;
`;
