import React, { useState, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import Modal from 'components/Forms/FormModal/Modal';
import ProfileForm from 'containers/NextBRF/Forms/ProfileForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { saveProfile } from '../service';
import t from 'components/i18n';

const validateSchema = () =>
  yup.object().shape({
    name: yup.string().required(t('global.status.required-field')),
    attribution: yup.string().required(t('global.status.required-field')),
    routes: yup
      .boolean()
      .oneOf(
        [true],
        t('editprofile.validation.choose-profile-permissions'),
      ),
  });

export default function EditProfile(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const { isLoading, selectedProfile, isValidating } = props;

  useEffect(() => {
    if (success) {
      props.getProfileList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfile, success]);

  const selectedValues = useMemo(() => ({
    id: selectedProfile.id,
    name: selectedProfile.name,
    attribution: selectedProfile.attribution,
    isEnabled: selectedProfile.isEnabled,
    routes: true,
    allowRoutes: props.functionalitiesList.map(item => {
      const routeFound =
        selectedProfile.allowRoutes &&
        selectedProfile.allowRoutes.find(
          route => Number(route.id) === Number(item.id),
        );

      if (routeFound) {
        const newLevel = Number(routeFound.level)
          .toString(2)
          .padStart(4, '0')
          .split('');
        
        const returns = {
          id: Number(item.id),
          level: [
            Boolean(Number(newLevel[2])), // create
            Boolean(Number(newLevel[1])), // edit
            Boolean(Number(newLevel[0])), // exclude
            Boolean(Number(newLevel[3])), // read
          ],
          // table order,
        };

        return returns;
      }

      return {
        id: item.id,
        level: [false, false, false, false],
      };
    }),
  }), [selectedProfile]);

  const initialValues = {
    name: '',
    attribution: '',
    routes: false,
    isEnabled: true,
    allowRoutes: props.functionalitiesList.map(item => ({
      id: item.id,
      level: [false, false, false, false],
    })),
  };

  const formData = values => ({
    id: values.id || 0,
    name: values.name,
    attribution: values.attribution,
    isEnabled: values.isEnabled,
    allowRoutes: values.allowRoutes
      // .filter(route => route.level[3])
      .map(item => {
        let [create, edit, exclude, read] = item.level;

        if (exclude) {
          create = 1;
        }

        if (create) {
          edit = 1;
        }

        if (edit) {
          read = 1;
        }

        const permissions = {
          id: item.id,
          read: Boolean(read),
          edit: Boolean(edit),
          create: Boolean(create),
          exclude: Boolean(exclude),
        };

        return permissions;
      }),
  });

  function submitForm(values, { setSubmitting }) {
    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await saveProfile(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }
    sendData(formData(values));
  }

  return (
    <>
      <Formik
        initialValues={selectedProfile ? selectedValues : initialValues}
        validate={validate(validateSchema)}
        onSubmit={submitForm}
        render={formikProps => (
          <ProfileForm
            {...formikProps}
            isValidating={isValidating}
            message={errorMessage}
            isLoading={isLoading}
            selectedProfile={selectedProfile}
            success={success}
            functionalitiesList={props.functionalitiesList}
            {...props}
          />
        )}
      />

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
          props.setSelectedKey('profile-list');
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>
          {success
            ? 
              selectedProfile
                ? t('next.userManagement.profile.editProfile.success.updated')
                : t('next.userManagement.profile.editProfile.success.created')
            : selectedProfile
              ? t('next.userManagement.profile.editProfile.error.updated')
              : t('next.userManagement.profile.editProfile.error.created')
          }
        </p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
