import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import { TextAreaField, FileInput } from 'components/Forms/Fields';
import LoadingHolder from 'components/LoadingHolder';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';
import { colorTertiary } from 'components/GlobalStyles/variables';
import t from 'components/i18n';

export default function SendOrientationForm(props) {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    setFieldValue,
    handleFile,
    removeFile,
    isUploading,
    isLoading,
    files,
    values,
    errors,
    touched,
  } = props;

  const user = useSelector(state => state.user);
  const form = useRef(null);

  return (
    <>
      <LoadingHolder
        message={t('global.loading')}
        isLoading={isLoading}
      />
      {!isLoading && (
        <Form autoComplete="off" ref={form} onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              {/* Orientação */}
              <TextAreaField
                field="orientation"
                setState={e => {
                  setFieldValue('orientation', e);
                }}
                placeholder={t(
                  'form.next.visit.orientation.placeholder',
                )}
                onBlur={e => handleBlur(e)}
                onChange={e => handleChange(e)}
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \Orientação */}

              {/* Arquivo */}
              <FileInput
                type="image"
                field="files"
                label={t('form.next.visit.files.label')}
                onChange={e => {
                  for (const element of e.target.files) {
                    handleFile(element);
                  }
                }}
                itemLabel="fileName"
                removeFile={e => {
                  removeFile(e);
                }}
                itemToRemove="id"
                isLoading={isUploading}
                blobContainerName="logbookattachment"
                itemsToList={files}
                onBlur={handleBlur}
                accept=".png, .jpg, .jpeg, .svg, .webp, .jfif"
                token={user.token}
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \Arquivo */}
            </Col>
          </Row>

          <Separator />

          {/* \ Botões */}
          <BtnGroup color={colorTertiary}>
            <Button
              type="submit"
              key="sendtoConfirm"
              disabled={!props.isValid || props.isUploading}
            >
              {t('form.next.visit.button.finish-visit')}
            </Button>
            <Button
              key="clean"
              onClick={() => {
                resetForm();
                form.current.reset();
              }}
              className="white"
            >
              {t('form.next.visit.button.clear')}
            </Button>
          </BtnGroup>
          {/* \ Botões */}
        </Form>
      )}
    </>
  );
}
