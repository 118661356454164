import React from 'react';
import Recaptcha from 'react-recaptcha';
import { Message } from '@zendeskgarden/react-forms';

import {
  getValidationMessage,
  getValidation,
} from 'shared/utils/Helpers';

export default function ReCaptchaField(props) {
  return (
    <>
      <Recaptcha
        name={props.field}
        hl="pt-BR"
        ref={props.refs}
        sitekey={props.sitekey}
        size="normal"
        render="explicit"
        data-theme={props.theme}
        onloadCallback={props.onloadCallback}
        verifyCallback={props.verifyCallback}
      />
      <Message
        validation={getValidation(
          props.values[props.field],
          props.errors,
          props.field,
          !props.touched[props.field],
        )}
      >
        {getValidationMessage(
          props.field,
          props.errors,
          !props.touched[props.field],
        )}
      </Message>
    </>
  );
}
