import styled from 'styled-components';
import { colorGrayLight } from 'components/GlobalStyles/variables';

export const AccordionStyled = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const ToggleAccordion = styled.span`
  display: block;
  background: none;
  border: none;
  font-size: 30px;
  color: ${colorGrayLight};
  width: 30px;
  text-align: center;
`;

export const AccordionContent = styled.div`
  position: relative;
  max-height: ${props => (props.isOpen ? props.maxHeight : '0')}px;
  overflow: hidden;
  transform-origin: top;
  transition: max-height 0.2s ease-out;

  &.overflow-visible {
    overflow: visible;
  }
`;
