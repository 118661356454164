import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import t from 'components/i18n';
import { InputField, FileInput } from 'components/Forms/Fields';
import LoadingHolder from 'components/LoadingHolder';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';

export default function InsuranceForm(props) {
  const {
    selected,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    isUploading,
    isLoading,
    files,
    handleFile,
    removeFile,
    values,
    errors,
    touched,
  } = props;

  const [file, setFile] = useState(null);

  useEffect(() => {
    setFieldValue('files', files.length > 0);

    if (selected && selected.startDate) {
      setFieldValue(
        'startDate',
        moment(selected.startDate).format('YYYY-MM-DD'),
      );
    }

    if (selected && selected.endDate) {
      setFieldValue(
        'endDate',
        moment(selected.endDate).format('YYYY-MM-DD'),
      );
    }
  }, [files, selected, setFieldValue]);

  return (
    <>
      <LoadingHolder
        message={t('global.loading')}
        isLoading={isLoading}
      />
      {!isLoading && (
        <Form autoComplete="off">
          <Row>
            <Col md={12}>
              <InputField
                type="date"
                field="startDate"
                label={t(
                  'insurancePolicy.createPolicy.sectionValidityPolicy.input.startDate.label',
                )}
                placeholder="dd/mm/aaaa"
                onChange={handleChange}
                onBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </Col>

            <Col md={12}>
              <InputField
                type="date"
                field="endDate"
                label={t(
                  'insurancePolicy.createPolicy.sectionValidityPolicy.input.closeDate.label',
                )}
                placeholder="dd/mm/aaaa"
                onChange={handleChange}
                onBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </Col>

            <Col md={12}>
              <FileInput
                type="file"
                field="files"
                label={t(
                  'insurancePolicy.createPolicy.sectionPolicyDocument.title',
                )}
                btnLabel={t(
                  'insurancePolicy.createPolicy.sectionPolicyDocument.button.addFiles',
                )}
                onChange={e => {
                  handleFile(e.target.files[0]);
                  handleChange(e);
                  setFile(e.target.files[0]);
                }}
                itemLabel="fileName"
                removeFile={e => {
                  removeFile(e);
                  setFile(null);
                }}
                itemToRemove="id"
                isLoading={isUploading}
                itemsToList={files}
                onBlur={handleBlur}
                accept=".pdf"
                values={values}
                errors={errors}
                touched={touched}
              />
            </Col>
          </Row>

          <Separator />

          <BtnGroup>
            <Button
              key="sendtoConfirm"
              onClick={() => {
                handleSubmit();
              }}
              disabled={!props.isValid || props.isUploading}
            >
              {t(
                'insurancePolicy.createPolicy.sectionPolicyDocument.buttonPrimary',
              )}
            </Button>
            <Button
              key="clean"
              onClick={() => resetForm()}
              className="white"
            >
              {t(
                'insurancePolicy.createPolicy.sectionPolicyDocument.buttonSecondary',
              )}
            </Button>
          </BtnGroup>
        </Form>
      )}
    </>
  );
}
