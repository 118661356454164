import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { utils } from 'styled-minimal';
import {
  boxShadow,
  fontFamilyHeading,
  fontFamilyText,
  colorClear,
  colorGray,
  colorGrayLight,
  colorGrayDarker,
  colorGrayDarkest,
  colorDanger,
  colorInfo,
  breakpointTablet,
  DEFAULT_SIZE,
  colorGrayLighter,
  colorGrayUltraLight,
} from 'components/GlobalStyles/variables';

export const ScheduleStyled = styled.div`
  position: relative;

  .justification {
    h1 {
      font-size: 24px;
      margin-bottom: 60px;
    }

    .container-inner {
      > div {
        padding: 0 8px;
        border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};

        &.no-border {
          border: none;
        }
      }

      p {
        font-size: 14px;
        line-height: 16px;
        color: ${colorGrayDarkest};

        &.red {
          color: ${colorDanger};
          font-size: 14px;
          line-height: 21px;
          padding: 12px 26px;
          border-radius: 8px;
          background-color: ${rgba(colorDanger, 0.16)};
          display: flex;
          align-items: center;

          .icon {
            font-size: 18px;
            margin-right: 20px;
          }
        }

        .label {
          font-size: 12px;
          color: ${colorGrayLight};
        }

        .data {
          &.full {
            line-height: 21px;
          }
        }
      }
    }
  }
`;

export const NewVisitWrapper = styled.div`
  box-shadow: ${boxShadow};

  .wrapper-title {
    align-items: center;
    background-color: ${colorGrayUltraLight};
    color: ${colorGrayDarkest};
    display: flex;
    height: 40px;
    padding: 0 0 0 12px;
    font-size: 14px;
    margin: 24px ​0 0;
    line-height: 21px;

    .lighter {
      color: ${colorGrayLighter};
      margin-left: 4px;
    }
  }
`;

export const Spacer = styled.p`
  color: ${colorGrayLight};
  font-size: 14px;
  margin: ${DEFAULT_SIZE * 6}px 0 0 ${DEFAULT_SIZE * 2}px;
  flex: 0;
  text-align: center;
`;

export const Container = styled.div`
  position: relative;
  padding: 0 ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};

  &.maxWidth {
    max-width: 652px;
    padding: 16px;
  }

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px 20px 60px 20px;
  }

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }
`;

export const OptionsButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  outline: 0;
`;

export const Heading = styled.h2`
  font-family: ${fontFamilyHeading};
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  margin-bottom: ${utils.px(12)};
  color: ${colorGrayDarker};
`;

export const Subheading = styled.strong`
  display: block;
  font-size: 14px;
  font-family: ${fontFamilyText} !important;
  font-weight: normal;
  margin-top: ${DEFAULT_SIZE * 2}px;
  margin-bottom: ${DEFAULT_SIZE}px;
  line-height: 21px;
  color: ${colorGrayLight};
`;

export const AddNew = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0 32px;
`;

export const Notification = styled.section`
  background-color: ${colorInfo};
  color: ${colorClear};
  margin: 0 -16px;
  padding: 12px 16px;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }

  span {
    font-size: 24px;
    margin-right: 6px;
  }
`;

export const Separator = styled.hr`
  margin: 16px -16px 15px;
  opacity: 0.12;
`;
