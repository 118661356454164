const addLeadingZero = value => {
  if (!value) {
    return '';
  }
  if (parseInt(value, 10) >= 10) {
    return value;
  }
  if (typeof value === 'string' && value[0] === '0') {
    return value;
  }
  return `0${value}`;
};

export default addLeadingZero;
