import React from 'react';
import t from 'components/i18n';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { COLLABORATOR } from 'config/routes';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import cpfMask from 'components/MaskedInput/cpfMask';
import phoneMask from 'components/MaskedInput/phoneMask';
import Icon from 'components/Icon';
import CircleAndTitle from 'components/CircleAndTitle';
import { List, ListItem } from 'components/List';
import CollaboratorDetails from './CollaboratorDetails';
import { Separator } from './Collaborator.styled';
import { profileKeyMapper } from 'utils';

export default function CollaboratorList(props) {
  const {
    data,
    selected,
    setSelected,
    setEditUser,
    setRemovedUser,
    deleteCollaborator,
    history,
  } = props;

  const listData = [
    {
      label: t('menu.employees.list.field.dateExpedition'),
      item: selected.createdDate,
    },
    {
      label: t('menu.employees.list.field.document'),
      item:
        selected.documentNumber && cpfMask(selected.documentNumber),
    },
    {
      label: t('menu.employees.list.field.cellphone'),
      item: selected.phoneNumber && phoneMask(selected.phoneNumber),
    },
    {
      label: t('menu.employees.list.field.access'),
      item:
        selected.vendors &&
        selected.vendors
          .filter(e => e.isChecked)
          .map(item => {
            const houseList = item.houses.filter(
              house => house.isChecked,
            );
            return (
              <div
                key={item.vendorCode}
                style={{ marginBottom: '16px' }}
              >
                <strong>Código {item.vendorCode}</strong>
                <br />
                <span>
                  Instalação{' '}
                  {houseList.map((e, index) => {
                    if (index === 0) {
                      return `${e.houseCode}`;
                    }
                    if (index === houseList.length - 1) {
                      return ` e ${e.houseCode}`;
                    }

                    return `, ${e.houseCode}`;
                  })}
                </span>
              </div>
            );
          }),
    },
  ];

  return Object.keys(selected).length === 0 ? (
    <>
      <Row justifyContent="end">
        <Col
          size={6}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        />
      </Row>

      <List className="menu-group">
        {data.map((item, index) => (
          <ListItem
            noMargin
            role="button"
            key={index}
            onClick={() =>
              history.push(`${COLLABORATOR}/${item.id}/details`)
            }
            className="animated fadeIn"
          >
            <CircleAndTitle
              name={item.name}
              initials={item.initialsName}
              description={profileKeyMapper(item.profile)}
            />
            <Icon name="chevron icon-chevron-right" />
          </ListItem>
        ))}
      </List>

      <Separator />

      <BtnGroup>
        <Button
          key="new"
          primary
          style={{ width: 176 }}
          onClick={() => history.push(`${COLLABORATOR}/new/edit`)}
        >
          {t('menu.employees.inviteEmployee.title')}
        </Button>
      </BtnGroup>

      <br />
    </>
  ) : (
    <CollaboratorDetails
      data={listData}
      selected={selected}
      setSelected={setSelected}
      setEditUser={setEditUser}
      setRemovedUser={setRemovedUser}
      deleteCollaborator={deleteCollaborator}
      history={history}
    />
  );
}
