import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { ThemeContext } from 'styled-components';
import NoContentTab from 'components/NoContentTab';
import TableComponent from 'components/Table';
import BadgePill from 'components/BadgePill';
import Pagination from 'components/Pagination';
import t from 'components/i18n';
import removeDuplicatesBasedOnProperties from 'shared/utils/Helpers/removeDuplicatesBasedOnProperties';
import moment from 'moment';
import { getFeedReport } from './service';
import {
  ContentsWrapper,
  Container,
} from './OperationalSchedule.styled';

export default function Feed({ responsive }) {
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);
  const [data, setData] = useState(null);
  const [bodyRequest, setBodyRequest] = useState({
    PageNumber: 1,
    PageSize: 20,
    StartDate: '',
    EndDate: '',
    SupervisorName: '',
    VendorName: '',
    Status: '',
    animalType: '',
  });

  const [activeFilters, setActiveFilters] = useState([]);

  const [dateFilter, setDateFilter] = useState(null);

  const { isMobile } = responsive;

  const themeContext = useContext(ThemeContext);

  let bgColor = '';

  const getReport = useCallback(async e => {
    setIsContentLoading(true);
    try {
      const response = await getFeedReport(e);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsContentLoading(false);
  }, []);

  const getFilterByLabel = useCallback(
    label => activeFilters.find(filter => label === filter.label),
    [activeFilters],
  );

  const getFilters = useMemo(() => {
    return [
      {
        label: t('next.operationalSchedule.filters.animalType.label'),
        alwaysShow: true,
        items: [
          {
            txt: t('next.operationalSchedule.filters.broiler'),
          },
          {
            txt: t('next.operationalSchedule.filters.swine'),
          },
          {
            txt: t('next.operationalSchedule.filters.turkey'),
          },
          {
            txt: t('next.operationalSchedule.filters.all'),
          },
        ],
      },
      {
        label: t('next.operationalSchedule.filters.status.label'),
        items: [
          {
            txt: t('next.operationalSchedule.filters.scheduled'),
          },
          {
            txt: t('next.operationalSchedule.filters.complete'),
          },
        ],
      },
    ];
  }, []);

  useEffect(() => {
    if (activeFilters.length > 0) {
      // new search
      setCurrentPage(1);

      const supervisor = getFilterByLabel(t('next.operationalSchedule.filters.extensionist-name.label'));
      const producer = getFilterByLabel(t('next.operationalSchedule.filters.integrated-name.label'));
      const status = getFilterByLabel(t('next.operationalSchedule.filters.status.label'));
      const animalType = getFilterByLabel(t('next.operationalSchedule.filters.animalType.label'));

      setBodyRequest(oldBodyRequest => ({
        ...oldBodyRequest,
        StartDate: dateFilter ? dateFilter.startDate : '',
        EndDate: dateFilter ? dateFilter.endDate : '',
        SupervisorName: supervisor
          ? supervisor.txt.split('-')[0].trim()
          : '',
        VendorName: producer ? producer.txt.split('-')[0].trim() : '',
        Status: status ? status.txt : '',
        animalType:
          animalType.txt.replace('í', 'i').replace('Todos', '') || '',
      }));
    }
  }, [activeFilters, dateFilter, getFilterByLabel]);

  const tableData = useMemo(
    () =>
      data &&
      data.itens &&
      data.itens.length > 0 &&
      data.itens.map((e, index) => {
        if (e.status) {
          switch (e.status.toLowerCase()) {
            case 'realizado':
              bgColor = 'colorPrimary';
              break;
            default:
              bgColor = 'colorGrayLight';
          }
        }

        return {
          0: (
            <div className={isMobile ? 'column mobile' : 'column'}>
              <p className="small caps">{t('next.operationalSchedule.feed.table.content.code', { value: e.vendorCode })}</p>
              <p className="strong">{e.vendorName}</p>
              <p className="small">
                {t("next.operationalSchedule.feed.table.content.extensionist", { value: e.supervisorName })}
              </p>
            </div>
          ),
          1: `${e.animalType}`,
          2: `${e.flock}`,
          3: `${moment(e.date).format('DD ̸̸  MM ̸̸  YYYY')}`,
          4: t('next.operationalSchedule.feed.table.content.quantity', { value: e.quantity }),
          5: (
            <BadgePill bgColor={bgColor} style={{ marginLeft: 0 }}>
              {e.status}
            </BadgePill>
          ),
        };
      }),
    [data],
  );

  const getPagination = useMemo(
    () =>
      data &&
      Number(data.totalPages) > 0 && (
        <Pagination
          totalPages={data.totalPages}
          pagePadding={isMobile ? 0.75 : 2}
          currentPage={currentPage}
          onChangePage={x => setCurrentPage(x.e)}
        />
      ),
    [currentPage, data, isMobile],
  );

  useEffect(() => {
    if (currentPage) {
      getReport({ ...bodyRequest, PageNumber: currentPage });
    }
  }, [currentPage, bodyRequest, getReport]);

  useEffect(() => setCurrentPage(1), []);

  const renderTable = useMemo(() => {
    const setActiveFiltersWithDefault = filtersToActivate => {
      setActiveFilters(filtersToActivate);
    };

    return (
      <TableComponent
        loading={isContentLoading}
        filter={{
          setDateFilter: setDateFilter,
          activeFilters: activeFilters,
          setActiveFilters: setActiveFiltersWithDefault,
          filters: getFilters,
        }}
        titleColor={themeContext.styles.palette.colorPrimary}
        rowSize="default"
        header={[
          {
            width: 25,
            title: t('next.operationalSchedule.table.header.user'),
            alignLeft: true,
          },
          {
            width: 15,
            title: t('next.operationalSchedule.table.header.anymalType'),
          },
          {
            width: 15,
            title: t('next.operationalSchedule.table.header.flock'),
          },
          {
            width: 20,
            title: t('next.operationalSchedule.table.header.date'),
          },
          {
            width: 12,
            title: t('next.operationalSchedule.table.header.quantity'),
          },
          {
            width: 13,
            title: t('next.operationalSchedule.table.header.status'),
          },
        ]}
        mobileHeader={t("next.operationalSchedule.table.header.user")}
        data={tableData}
        width="25%"
        isMobile={isMobile}
      />
    );
  }, [
    activeFilters,
    getFilters,
    isContentLoading,
    isMobile,
    tableData,
    themeContext.styles.palette.colorPrimary,
  ]);

  return (
    <>
      <ContentsWrapper>
        <Container style={{ marginBottom: '30px' }}>
          {!isContentLoading && !data && (
            <NoContentTab
              icon="icon-clipboard-user"
              onButtonClick={() => {}}
            >
              <p>
                {t('global.no-content')}
              </p>
            </NoContentTab>
          )}

          {renderTable}
        </Container>

        {getPagination}
      </ContentsWrapper>
    </>
  );
}
