// @flow

import React, { useState, useEffect } from 'react';
import t from 'components/i18n';

// components
import Breadcrumb from 'components/Breadcrumb';
import Badge from 'components/Badge';
import logger from 'shared/utils/logger';
import Icon from 'components/Icon';
import Top from 'components/Top';
import NoContentTab from 'components/NoContentTab';
import { FlockTopMenu } from 'containers/AgroBRF/FlockList/Flock/FlockTopMenu';
import Box from 'components/Container';
import LoadingHolder from 'components/LoadingHolder';
import IconPlaceholder from 'components/IconPlaceholder';
import {
  getFlockAllInformation,
  getEventInformation,
} from 'containers/AgroBRF/FlockList/service';
import { FLOCK_LIST } from 'config/routes';
import {
  getTopBadgeText,
  getFeedIcon,
  getTopIconColor,
} from './Flock.utils';
import { TopMobileHolder, Container } from './Flock.styled';

export default function Flock(props) {
  const [data, setData] = useState({});
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const title = t('flocks.listView.item.title', {
    flockNumber: data.flockTitle && data.flockTitle.value,
  });

  /*
   * set data based on url params
   */
  useEffect(() => {
    const flockId = props.match.params.id;

    async function fetch() {
      setIsLoading(true);

      try {
        const flockResponse = await getFlockAllInformation(flockId);
        const eventsResponse = await getEventInformation(flockId);

        if (flockResponse.success) {
          setEvents(eventsResponse);
          setData(flockResponse.data);
        } else {
          setError(true);
        }
        setIsLoading(false);
      } catch (e) {
        logger('Error on getFlockInformation', e);
        setIsLoading(false);
      }
    }

    fetch();
  }, [props.match.params.id]);

  function renderHeader(offCanvas = false) {
    const top = (
      <Top top left padding={16} className="flock__header">
        <IconPlaceholder
          className={`top-icon-holder ${getTopIconColor(
            data.flockStatus,
          )}`}
        >
          <Icon className="icon" name={getFeedIcon(data.flockType)} />
        </IconPlaceholder>

        <div className="top-content">
          <h2 className="top-content-heading">{title}</h2>
          <span className="top-content-subheading">
            {t('flocks.listView.item.subtitle', {
              growerCode:
                data.vendorCodeDescription &&
                data.vendorCodeDescription.value,
            })}
          </span>
          <span className="top-content-subheading light">
            {t('flocks.listView.item.overline', {
              houses: data.house && data.house.value,
            })}
          </span>
        </div>

        <div className="top-badge-holder">
          <Badge default>
            {t(getTopBadgeText(data.flockStatus))}
          </Badge>
        </div>
      </Top>
    );

    if (offCanvas) {
      return <TopMobileHolder>{top}</TopMobileHolder>;
    }

    return top;
  }

  return (
    <div className="flock">
      <Breadcrumb
        history={props.history}
        path={[t('dashboard.bottomNavigation.itemTwo'), title]}
      />

      {/* <LoadingHolder isLoading={isLoading} /> */}

      {!isLoading && !error && (
        <>
          {renderHeader()}

          <FlockTopMenu
            user={props.user}
            responsive={props.responsive}
            match={props.match}
            data={data}
            flock={data.flockTitle && data.flockTitle.value}
            events={events}
            history={props.history}
            houses={data.houseInformation}
          />
        </>
      )}

      {error && (
        <Container>
          <div className="main-wrapper">
            <Box hideDetail>
              <NoContentTab
                icon="icon-close-circle"
                onButtonClick={() => props.history.push(FLOCK_LIST)}
                buttonLabel={t('global.404.button')}
              >
                <p>
                  {t('global.404.title')} <br />{' '}
                  <span className="lighter">
                    {t('global.404.subtitle')}
                  </span>
                </p>
              </NoContentTab>
            </Box>
          </div>
        </Container>
      )}
    </div>
  );
}
