import React from 'react';
import { NEXT_OPEN_RECORD } from 'config/routes';
import BadgePill from 'components/BadgePill';
// Styles
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import {
  Container,
  ItemContainer,
  StatusFooter,
  ColumnInfo,
  Text,
  Title,
} from './NextHouseList.styled';
import t from 'components/i18n';
import moment from 'moment';

export default function HouseItem(props) {
  const { feed, goTo } = props;
  const badgeProps = {};

  function selectItem(option) {
    goTo(option);
  }

  if (feed) {
    if (feed.status === 'Pendente') {
      if (feed.isFalUpToDate) {
        badgeProps.success = true;
      } else {
        badgeProps.info = true;
      }
    }

    if (feed.status === 'Concluído') {
      badgeProps.danger = true;
    }
  }

  function getHouseIcon(status, update) {
    if (status === 'Concluído') {
      return 'icon-lock-enabled';
    }

    return update ? 'icon-check-circle' : 'icon-clock';
  }

  const credits =
    feed.status === 'Concluído'
      ? t('next.flock.record.houseItem.status.complete.title')
      : t('next.flock.record.houseItem.status.incomplete.title');

  return (
    <Container className="bordered-container">
      <ItemContainer
        className="item-container"
        onClick={() => selectItem('confirmation')}
      >
        <div className="open summary">
          <div className="top">
            <Title>{feed.house}</Title>
            {feed.status !== 'Concluído' ? (
              <BadgePill
                bgColor={
                  feed.isFalUpToDate ? 'colorSuccess' : 'colorInfo'
                }
              >
                {feed.isFalUpToDate ? t('next.flock.record.houseItem.status.badge.fal-up-to-date') : t('next.flock.record.houseItem.status.badge.fal-delayed')}
              </BadgePill>
            ) : (
              <BadgePill bgColor="colorDanger">{t('next.flock.record.houseItem.status.badge.complete')}</BadgePill>
            )}
          </div>
          <div className="columns">
            <ColumnInfo>
              <p>{t('next.flock.record.houseItem.column.age')}</p>
              <strong>{feed.days}</strong>
            </ColumnInfo>
            <ColumnInfo>
              <p>{t('next.flock.record.houseItem.column.averageWeight')}</p>
              <strong className={feed.IsWeightBelow ? 'red' : ''}>
                {feed.averageWeight}
                {feed.IsWeightBelow && (
                  <span className="icon icon-arrow-down" />
                )}
              </strong>
            </ColumnInfo>
            <ColumnInfo>
              <p>{t('next.flock.record.houseItem.column.mortality')}</p>
              <strong className={feed.isMortalityHigh ? 'red' : ''}>
                {feed.averageMortality}
                {feed.isMortalityHigh && (
                  <span className="icon icon-arrow-up" />
                )}
              </strong>
            </ColumnInfo>
          </div>
        </div>
      </ItemContainer>
      <StatusFooter {...badgeProps}>
        <Text {...badgeProps}>
          <span
            className={`icon ${getHouseIcon(
              feed.status,
              feed.isFalUpToDate,
            )} `}
          />
          <p>
            {
              t(
                'next.flock.record.houseItem.footer.credits-by-user',
                {
                  credits,
                  user: feed.lastUpdateBy,
                  date: moment(feed.lastUpdate).format('DD ̸̸  MM ̸̸  YYYY')
                }
              )
            }
          </p>
        </Text>
        <BtnGroup>
          <button
            type="button"
            className="white border-radius pointer noFloat"
            onClick={() =>
              props.history.push(`${NEXT_OPEN_RECORD}/${feed.falId}`)
            }
          >
            {t('next.flock.record.houseItem.footer.button.open-fal')}
          </button>
        </BtnGroup>
      </StatusFooter>
    </Container>
  );
}
