import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as routes from 'config/routes';
import Top from 'components/Top';
import Breadcrumb from 'components/Breadcrumb';
import LoadingHolder from 'components/LoadingHolder';
import Card from 'components/Card';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import { TopMenuStyled } from 'containers/NextBRF/NextFlock/NextRecord/NextRecord.styled';
// service
import { getLogBookItemByFlock } from 'containers/NextBRF/LogBook/service';
import t from 'components/i18n';

export default function VisitList({
  history,
  match: { params },
  responsive: { isMobile, isTablet, isGtMobile },
}) {
  const [data, setData] = useState([]);
  const [past, setPast] = useState([]);
  const [open, setOpen] = useState([]);
  const [active, setActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchData(id) {
    setIsLoading(true);
    try {
      const response = await getLogBookItemByFlock(id);
      setData(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setActive(0);
    fetchData(params.id);
  }, [params]);

  useEffect(() => {
    if (data) {
      setPast(data.filter(e => !e.open));
      setOpen(data.filter(e => e.open));
    }
  }, [data]);

  const Tabs = [
    { label: t('next.visitList.tabs.previous'), key: 0 },
    { label: t('next.visitList.tabs.open'), key: 1 },
  ];

  function getList(list, hasButton) {
    if (!list.length) {
      return (
        <p style={{ textAlign: 'center' }}>{t('next.visitList.empty-list')}</p>
      );
    }

    return list.map((item, index) => (
      <Card
        key={index}
        title={item.visitType}
        subtitle={moment(item.visitDate).format('DD ̸̸  MM ̸̸  YYYY')}
        icon="icon-calendar-alt"
        iconBackGround="colorPrimary"
        buttonLabel={hasButton ? t('next.visitList.button.label') : false}
        action={() =>
          history.push(
            hasButton
              ? `${routes.LOGBOOK}/${item.id}`
              : `${routes.OPEN_VISIT}/${item.id}`,
          )
        }
        footerIcon="icon-tag"
        footerColor="colorInfo"
        footerContent={item.status || null}
      />
    ));
  }

  return (
    <>
      <LoadingHolder isLoading={isLoading} />

      <Breadcrumb
        history={history}
        backTo={() =>
          history.push(
            `${routes.NEXT_FLOCK_DETAILS}/${data[0].flockId}`,
          )
        }
        path={
          data.length
            ? [
                t("next.visitList.bc.growers"),
                data[0].vendorName,
                t('next.visitList.bc.flock', { value: data[0].flock }),
                t('next.visitList.bc.visits'),
              ]
            : [
              t("next.visitList.bc.growers"),
              t('next.visitList.bc.visits')
            ]
        }
        links={
          data.length
            ? [
                routes.VENDORS,
                `${routes.NEXT_FLOCK_LIST}/${data[0].vendorId}/new`,
                `${routes.NEXT_FLOCK_DETAILS}/${data[0].flockId}`,
              ]
            : [routes.VENDORS]
        }
      />

      <Top>
        <div className="top-content">
          <h2 className="top-content-heading">{t('next.visitList.visits.title')}</h2>
        </div>
      </Top>

      <TopMenuStyled>
        <Header>
          {Tabs.map(item => (
            <TabHeaderHolder
              key={item.key}
              onClick={() => setActive(item.key)}
              className={active === item.key ? 'active' : ''}
            >
              <div className="label">{item.label}</div>
              <div className="bar" />
            </TabHeaderHolder>
          ))}
        </Header>

        <div style={{ maxWidth: '690px', padding: '16px 20px' }}>
          {active === 0 && getList(past)}

          {active === 1 && getList(open, true)}
        </div>
      </TopMenuStyled>
    </>
  );
}
