// @flow

import React, { useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { ThemeContext } from 'styled-components';
import t from 'components/i18n';
import Top from 'components/Top';
import FaqForm from 'containers/AgroBRF/Forms/FaqForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { isUndefined } from 'utils';
import { getQuestions, sendFaq } from './service';
import { FaqStyled, Separator, Container } from './Faq.styled';

const validateSchema = props =>
  yup.object().shape({
    question1: yup.object().required(t('global.status.required-field')),
    // question2: yup.object().required(t('global.status.required-field')),
    message: yup.string().required(t('global.status.required-field')),
  });

const initialValues = {
  question1: '',
  question2: '',
  message: '',
};

export default function Faq(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const themeContext = useContext(ThemeContext);

  const { user } = props;

  async function getFormQuestions() {
    setIsLoading();
    try {
      const response = await getQuestions();
      if (response) {
        setQuestions(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getFormQuestions();
  }, []);

  function submitForm(
    values,
    { setSubmitting, setErrors, isValidating },
  ) {
    const formData = {
      message: values.message,
      platform: 'web',
      questions: [
        {
          questionId: values.question1.nextFaqQuestionId || 1,
          answerId: values.question2.id || values.question1.id,
        },
      ],
    };

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await sendFaq(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setMessage(error.message);
      }
      setSubmitting(false);
    }

    sendData(formData);
  }

  const getTermText = () => {
    const userCode =
      !isUndefined(user.countryCodes) && user.countryCodes[0];

    const email = {
      BR: 'faleconoscointegrados@brf-br.com',
      TR: 'agroinfo.tr@brf.com',
    }[userCode || 'BR'];

    return t('menu.support.information', { email });
  };

  return (
    <FaqStyled id="contact">
      <Top>
        <h2>{t('menu.support.title')}</h2>
      </Top>
      <Container>
        <header className="details-header">
          <span
            className="container-detail"
            style={{
              backgroundColor:
                themeContext.styles.palette.colorPrimary,
            }}
          />
          <h4 className="details-heading">
            {t('menu.support.subtitle')}
          </h4>
        </header>
        <Separator />

        {questions && (
          <Formik
            initialValues={initialValues}
            validate={validate(validateSchema)}
            onSubmit={submitForm}
            render={formikProps => (
              <FaqForm
                user={props.user}
                {...formikProps}
                isValidating={props.isValidating}
                description={t('menu.support.description')}
                text={getTermText()}
                message={message}
                isLoading={isLoading}
                questions={questions}
                success={success}
              />
            )}
          />
        )}
      </Container>
    </FaqStyled>
  );
}
