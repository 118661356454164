import React from 'react';
import { Input } from '@zendeskgarden/react-forms';
import { cleanUp } from './mask';
import t from 'components/i18n';
import cpfMask from './cpfMask';
import cnpjMask from './cnpjMask';
import phoneMask from './phoneMask';
import floatMask from './floatMask';
import temperatureMask from './temperatureMask';
import tattooMask from './tattooMask';
import numberDotMask from './numberDotMask';
import registerMask from './registerMask';

function getMethod(type, input, size) {
  if (type === 'CPF/CNPJ') {
    if (input.length < 12) {
      return cpfMask;
    }
    return cnpjMask;
  }

  if (type === 'cpf') return cpfMask;

  if (type === 'phone') return phoneMask;

  if (type === 'temperature') return temperatureMask;

  if (type === 'dot') return numberDotMask;

  if (type === 'float') return floatMask;

  if (type === 'register') return registerMask;

  if (type === 'tattoo') return tattooMask;

  return () => undefined;
}

function prepareValue(event, state, type) {
  let existing = state;

  existing = type === 'register' ? existing : cleanUp(existing);

  if (!type) {
    throw new Error(t('maskedInput.validation.noType'));
  }

  const fn = getMethod(type, existing);
  existing = fn(existing);

  return existing;
}

export default function MaskedInput(props) {
  const { setState, value, onKeyDown, type } = props;

  function setValue(event, val) {
    if (typeof onKeyDown === 'function') {
      onKeyDown(event);
    }

    setState(prepareValue(event, val, type));
  }

  function onChange(event) {
    if (typeof props.onChange === 'function') {
      props.onChange(event);
    }

    setValue(event, event.target.value);
  }

  return (
    <Input
      {...props}
      type="text"
      className="input_field"
      maskType={type}
      onChange={onChange}
      value={value}
    />
  );
}
