import React, { useState, useEffect, useContext } from 'react';
import logger from 'shared/utils/logger';
import uid from 'uuid';
import { scrollToTop } from 'shared/utils/Helpers';
import LoadingHolder from 'components/LoadingHolder';
import MedicationSummaryItem from 'containers/AgroBRF/FlockList/Flock/FlockHistory/MedicationSummaryItem';
import NoContentTab from 'components/NoContentTab';

// contexts
import { SwineContext } from 'shared/contexts';

import { getResumeList } from 'containers/AgroBRF/FlockList/service';
import MedicationFavoriteBox from './MedicationFavoriteBox';
import t from 'components/i18n';

export default function SwineMortalityList({
  data,
  flockID,
  updateItems,
  setUpdateItems,
  setSelectedKey,
  removed,
  setRemoved,
  setDisplayExcludeModal,
}) {
  const swineContext = useContext(SwineContext);
  const { favoriteCandidate } = swineContext;
  const { setFavoriteCandidate } = swineContext.actions;
  const [isLoading, setIsLoading] = useState(false);
  const [medicationList, setMedicationList] = useState([]);

  async function fetchSummary(id) {
    setIsLoading(true);
    try {
      const response = await getResumeList(id);
      setMedicationList(response);
    } catch (e) {
      logger('Error on getSummary', e);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (removed) {
      setUpdateItems(true);
    }
  }, [removed, setUpdateItems]);

  useEffect(() => {
    if (updateItems) {
      fetchSummary(flockID);
      setTimeout(() => setRemoved(false), 2000);
      scrollToTop(document.getElementById('main'));
    }
  }, [flockID, setRemoved, updateItems]);

  useEffect(() => {
    fetchSummary(flockID);
  }, [flockID]);

  return (
    <>
      <MedicationFavoriteBox
        data={favoriteCandidate}
        setFavoriteCandidate={setFavoriteCandidate}
      />
      {medicationList &&
        medicationList.length > 0 &&
        medicationList.map(feed => (
          <MedicationSummaryItem
            key={`feed-${uid()}`}
            feed={feed}
            goTo={e => setSelectedKey(e)}
            exclude={() => setDisplayExcludeModal(true)}
          />
        ))}

      {medicationList &&
        medicationList.length === 0 &&
        (isLoading ? (
          <LoadingHolder
            isLoading={isLoading}
            message={t('global.loading')}
          />
        ) : (
          <NoContentTab icon="icon-pig-alt">
            <p>
              {t('global.no-content')}
            </p>
          </NoContentTab>
        ))}
    </>
  );
}
