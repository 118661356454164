import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import t from 'components/i18n';
import { useParams } from 'react-router-dom';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import SideboxFilter from 'components/SideboxFilter/SideboxFilter';
import Container from 'components/Container';
import { WhiteBoxInsideHeader } from 'components/HeaderBox';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import LibraryList from './LibraryList';
import { LibraryStyled, Separator } from './Library.styled';
import LibraryMenu from './LibraryMenu';
import getLibraryFilters from './GetLibraryFilters';
import { getLibraryItems } from './service';

export default function LibraryListWrapper({
  categories,
  updateItems,
  setUpdateItems,
  setEditLibrary,
  setSelectedItem,
  setDisplayDeleteModal,
  showDetails,
  setShowDetails,
  tags,
  libraryPermissions,
}) {
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [library, setLibrary] = useState([]);
  const [libraryTotalPages, setLibraryTotalPages] = useState(1);
  const [filteredLibrary, setFilteredLibrary] = useState(null);
  const [
    filteredLibraryTotalPages,
    setFilteredLibraryTotalPages,
  ] = useState(1);
  const [dateFilter, setDateFilter] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [subCategory, setSubCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { urlCategory, urlSubCategory } = useParams();

  const getFiltersFromSideBox = useCallback(
    filters =>
      filters.map(filterItem => {
        if (activeFilters.find(e => e.label === filterItem)) {
          return activeFilters.find(e => e.label === filterItem).txt;
        }

        return '';
      }),
    [activeFilters],
  );

  const getLibraryList = useCallback(
    async (isFiltered, id, ...rest) => {
      setIsLoadingItems(true);
      setUpdateItems(false);
      try {
        const response = await getLibraryItems(id, ...rest);
        if (isFiltered) {
          setFilteredLibrary(response && response.libraryItemList);
          setFilteredLibraryTotalPages(
            response && response.totalPages,
          );
        } else {
          setLibrary(response && response.libraryItemList);
          setLibraryTotalPages(response && response.totalPages);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoadingItems(false);
    },
    [setUpdateItems],
  );

  useEffect(() => {
    if (urlCategory && categories.length > 0) {
      const filterByUrlCategory = categories.findIndex(
        cat => cat.id === Number(urlCategory),
      );

      setActiveTab(
        filterByUrlCategory > -1 ? filterByUrlCategory : 0,
      );
    }
  }, [urlCategory, categories]);

  useEffect(() => {
    // reset filters on change subcategory
    setActiveFilters([]);
    setDateFilter(null);
    setFilteredLibrary(null);
  }, [library]);

  useEffect(() => {
    // apply filters
    if (subCategory && (activeFilters.length > 0 || dateFilter)) {
      setCurrentPage(1);
      getLibraryList(
        true,
        subCategory.id,
        1,
        10,
        ...getFiltersFromSideBox([
          t('library.filters.content"'),
          t('library.filters.subject'),
        ]),
        dateFilter
          ? moment(dateFilter.startDate).format('YYYY-MM-DDT00:00:01')
          : '',
        dateFilter
          ? moment(dateFilter.endDate).format('YYYY-MM-DDT23:59:59')
          : '',
      );
    }

    if (!activeFilters.length) {
      setFilteredLibrary(null);
    }

    if (!dateFilter) {
      setFilteredLibrary(null);
    }
  }, [
    dateFilter,
    activeFilters,
    subCategory,
    getLibraryList,
    getFiltersFromSideBox,
  ]);

  useEffect(() => {
    // page change or force update
    if (subCategory && (updateItems || currentPage)) {
      getLibraryList(
        true,
        subCategory.id,
        currentPage,
        10,
        ...getFiltersFromSideBox([
          t('library.filters.content'),
          t('library.filters.subject'),
        ]),
        dateFilter
          ? moment(dateFilter.startDate).format('YYYY-MM-DDT00:00:01')
          : '',
        dateFilter
          ? moment(dateFilter.endDate).format('YYYY-MM-DDT23:59:59')
          : '',
      );
    }
  }, [
    currentPage,
    dateFilter,
    getFiltersFromSideBox,
    getLibraryList,
    subCategory,
    updateItems,
  ]);

  const content = filteredLibrary || library || [];

  return (
    <>
      <Header>
        {categories.map((item, index) => (
          <TabHeaderHolder
            key={index}
            onClick={() => setActiveTab(index)}
            className={activeTab === index ? 'active' : ''}
          >
            <div className="label">{t(item.name)}</div>
            <div className="bar" />
          </TabHeaderHolder>
        ))}
      </Header>

      <LibraryStyled>
        <div className="secondary-wrapper">
          <LibraryMenu
            menuItems={
              categories.length > 0 &&
              categories[activeTab].librarySubCategories
            }
            getLibraryList={getLibraryList}
            activeTab={activeTab}
            urlSubCategory={urlSubCategory}
            subCategory={subCategory}
            setSubCategory={setSubCategory}
          />
        </div>

        <Container className="container" slim>
          <h4>{subCategory && t(subCategory.name)}</h4>
          <Separator style={{ marginBottom: 0 }} />

          <WhiteBoxInsideHeader>
            <SideboxFilter
              dateFilter={dateFilter || false}
              setDateFilter={setDateFilter || undefined}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              filters={getLibraryFilters(tags)}
            />
          </WhiteBoxInsideHeader>

          <LoadingHolder isLoading={isLoadingItems} />

          {!isLoadingItems && !content.length && (
            <NoContentTab icon="icon-library">
              <p>
                {t('global.no-content')}
              </p>
            </NoContentTab>
          )}

          {!isLoadingItems && content.length > 0 && (
            <LibraryList
              content={content}
              isLoadingItems={isLoadingItems}
              setEditLibrary={setEditLibrary}
              setShowDetails={setShowDetails}
              setSelectedItem={setSelectedItem}
              showDetails={showDetails}
              setDisplayDeleteModal={setDisplayDeleteModal}
              filteredLibraryTotalPages={filteredLibraryTotalPages}
              libraryTotalPages={libraryTotalPages}
              filteredLibrary={filteredLibrary}
              currentPage={filteredLibrary}
              setCurrentPage={setCurrentPage}
              libraryPermissions={libraryPermissions}
            />
          )}
        </Container>
      </LibraryStyled>
    </>
  );
}
