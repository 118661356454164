import styled from "styled-components";

export const InvoiceStatus = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${({ status }) => status === 'Pendente' ? '#FF6600' : '#0066B3' };
  margin-top: 8px;

  span {
    margin-right: 12px;
    &:before {
      transform: scale(1.6);
      display: inline-block;
    }
  }
`;
