// @flow

import React, { useState, useEffect } from 'react';

// components
import Box from 'components/Container';
import FlockMenu from 'containers/AgroBRF/FlockList/Flock/FlockMenu';
import Demonstrative from 'containers/AgroBRF/FlockList/Flock/Demonstrative';
import Closure from 'containers/AgroBRF/FlockList/Flock/Closure';
import Icon from 'components/Icon';
import * as routes from 'config/routes';
import {
  getBoxType,
  getFeedIcon,
  getFlockIcon,
  getHeading,
} from './Flock.utils';
import {
  Container,
  ListItem,
  Separator,
  FlockDetailsMobileWrapper,
} from './Flock.styled';
import t from 'components/i18n';

export default function Activities(props) {
  const [selectedKey, setSelectedKey] = useState(null);

  const { isGtMobile, isMobile, isTablet } = props.responsive;
  const { data, history } = props;

  let flockDetailsTop;

  /*
   * set data based on url params
   */

  useEffect(() => {
    if (props.match.params.activity) {
      setSelectedKey(props.match.params.activity);
    } else {
      setSelectedKey('payment');
    }
  }, [props.match.params.activity]);

  const boxProps = {
    slim: true,
    [getBoxType(selectedKey)]: true,
  };

  function getTabletToggler(type) {
    return (
      <button
        onClick={() => setSelectedKey(type)}
        type="button"
        className={`unstyled-button tablet-menu-item ${
          selectedKey === type ? 'active' : ''
        }`}
      >
        <Icon
          className="tablet-menu-icon"
          name={getFlockIcon(type)}
        />
        <span className="tablet-menu-label">{getHeading(type)}</span>
      </button>
    );
  }

  function getToggler(type) {
    const menuItemProps = {
      className: 'menu-item',
    };

    if (isGtMobile) {
      menuItemProps.className = `menu-item ${selectedKey === type ? 'active' : ''}`;
    }

    return (
      <div {...menuItemProps}>
        {type !== 'slaughter' ? (
          <Icon className="menu-icon" name={getFlockIcon(type)} />
        ) : (
          <Icon
            className="menu-icon"
            name={getFeedIcon(data.flockType)}
          />
        )}

        <ListItem
          onClick={() => {
            if (type === 'invoice') {
              history.push(`${routes.INVOICE}/${props.match.params.id}`)
            }

            setSelectedKey(type)
          }}
          role="button"
          className="animated fadeIn"
        >
          <div className="list-item-content">
            <div className="list-item-heading">
              {getHeading(type)}
            </div>
          </div>
          <Icon name="chevron icon-chevron-right" />
        </ListItem>
      </div>
    );
  }

  function getFlockDetails() {
    const top = (
      <header className="flock-details-header">
        <h2 className="flock-details-heading">
          {getHeading(selectedKey)}
        </h2>
      </header>
    );

    function innerContent() {
      switch (selectedKey) {
        case 'closure':
          return (
            <Closure
              getBoxType={getBoxType}
              selectedKey={selectedKey}
              flockId={props.match.params.id}
            />
          );
          break;
        case 'payment':
          return (
            <Demonstrative
              getBoxType={getBoxType}
              selectedKey={selectedKey}
              flockId={props.match.params.id}
              user={props.user}
            />
          );
          break;

        default:
          return <p>{t('global.loading')}</p>;
      }
    }

    const content = (
      <div className="flock-details-content">{innerContent()}</div>
    );

    const details = (
      <div className="flock-details-container">
        {isMobile && (
          <>
            <FlockDetailsMobileWrapper className="mobile-wrapper">
              {content}
            </FlockDetailsMobileWrapper>
          </>
        )}

        {isGtMobile && (
          <>
            {top}
            <Separator />
            {selectedKey !== 'payment' && flockDetailsTop}
            {content}
          </>
        )}
      </div>
    );

    return (
      <div className="flock-details">
        {isMobile && details}
        {isGtMobile && (
          <Box {...boxProps} style={{ maxWidth: '676px' }}>
            {details}
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
      <Container className="flock-container flock-container__closed-summary">
        <FlockMenu
          flockId={props.match.params.id}
          getHeading={getHeading}
          getTabletToggler={getTabletToggler}
          getToggler={getToggler}
          isTablet={isTablet}
          isMobile={isMobile}
          isGtMobile={isGtMobile}
          selectedKey={selectedKey}
          getFlockDetails={getFlockDetails}
          type={data.flockStatus}
          data={data}
          user={props.user}
          isResume
        />

        {isGtMobile && getFlockDetails()}
      </Container>
    </>
  );
}
