// @flow

import React from 'react';
import * as routes from 'config/routes';
import t from 'components/i18n';
import {
  Sidebar,
  SidebarHeader,
  SidebarItem,
} from 'components/Sidebar';
import Logo from 'components/Logo';
import Hamburger from 'components/Hamburger';
import Icon from 'components/Icon';
import { Wrapper, MainWrapper } from './MainMenu.styled';
import { useEffect } from 'react';
import { updateLastAccess } from 'shared/services/common';
import { logout } from 'components/User/service';

function getSidebarItemProps(icon, label, active = false) {
  const props = {
    icon,
    label,
  };

  if (active) {
    props.active = true;
  }

  return props;
}

function pathIsActive(activePath, route) {
  return activePath === route || activePath.indexOf(route) > -1;
}

export default function MainMenu(props) {
  const { menuIsOpen, activePath, history, user } = props;

  useEffect(() => {
    updateLastAccess();
  }, []);

  const logoName = user.isNextUser ? 'next' : 'agro';

  history.listen(() => props.switchMenu(false));

  const hasCountryCode =
    props.user.countryCodes && props.user.countryCodes.length;
  const shouldHideMenu = !(
    hasCountryCode && props.user.countryCodes.find(cc => cc === 'TR')
  );
  const shouldShowMenu = (
    hasCountryCode && props.user.countryCodes.find(cc => cc === 'TR')
  );

  // console.log('v1.1.9');

  return (
    <MainWrapper>
      <Sidebar isOpen={menuIsOpen}>
        <SidebarHeader>
          {menuIsOpen && (
            <Logo
              className="hidden-phone"
              name={logoName}
              variant="coloured"
            />
          )}

          <Logo
            className="visible-desktop"
            name={logoName}
            variant="coloured"
          />

          <Logo
            className="visible-tablet brf-minimal"
            name="brf-minimal"
            variant="coloured"
          />

          <div className="visible-phone hamburger-button">
            <Hamburger
              menuIsOpen={menuIsOpen}
              onClick={(e, isOpen) => props.switchMenu(isOpen)}
            />
          </div>
        </SidebarHeader>

        {/* <Search className="visible-phone">
          <Input mediaLayout small>
            <MediaFigure>
              <Icon name="icon-search" size="16" />
            </MediaFigure>
            <MediaInput
              aria-label="Buscar no site"
              placeholder="Buscar no site"
            />
          </Input>
        </Search> */}

        {!user.isNextUser && (
          <Wrapper>
            <SidebarItem
              onClick={() => history.push(routes.HOME)}
              route="HOME"
              {...getSidebarItemProps(
                'icon-home',
                t('menu.buttonSingleLine.home'),
                pathIsActive(activePath, routes.HOME),
              )}
            />

            <SidebarItem
              onClick={() => history.push(routes.FLOCK_LIST)}
              route="FLOCK_LIST"
              {...getSidebarItemProps(
                'icon-flock',
                t('menu.buttonSingleLine.flockList'),
                pathIsActive(activePath, routes.FLOCK_LIST) ||
                  activePath.indexOf(routes.OPEN_RECORD) !== -1,
              )}
            />

            {props.user.type !== 'O' && (
              <SidebarItem
                onClick={() => history.push(`${routes.LIBRARY}/list`)}
                route="LIBRARY"
                {...getSidebarItemProps(
                  'icon-library',
                  t('menu.buttonSingleLine.library'),
                  pathIsActive(activePath, `${routes.LIBRARY}/list`),
                )}
              />
            )}

            {shouldHideMenu && (
              <SidebarItem
                onClick={() => history.push(routes.NEWS_LIST)}
                route="NEWS_LIST"
                {...getSidebarItemProps(
                  'icon-news',
                  t('menu.buttonSingleLine.news'),
                  pathIsActive(activePath, routes.NEWS_LIST),
                )}
              />
            )}

            <div>
              <SidebarItem
                onClick={() => history.push(routes.ANNOUNCEMENTS)}
                route="ANNOUNCEMENTS"
                {...getSidebarItemProps(
                  'icon-notice',
                  t('menu.buttonSingleLine.announcements'),
                  pathIsActive(activePath, routes.ANNOUNCEMENTS),
                )}
              />
            </div>

            {props.user.type !== 'F' && shouldHideMenu && (
              <SidebarItem
                onClick={() => history.push(routes.CADEC)}
                route="CADEC"
                {...getSidebarItemProps(
                  'icon-users',
                  t('menu.buttonSingleLine.cadec'),
                  pathIsActive(activePath, routes.CADEC),
                )}
              />
            )}

            {props.user.type !== 'E' && shouldHideMenu && (
              <SidebarItem
                onClick={() => history.push(routes.GRAINS)}
                route="GRAINS"
                {...getSidebarItemProps(
                  'icon-corn',
                  t('menu.beAnAPartner.title'),
                  pathIsActive(activePath, routes.GRAINS),
                )}
              />
            )}

            {props.user.type === 'P' && shouldHideMenu && (
              <SidebarItem
                onClick={() => history.push(routes.BANCO_DO_BRASIL)}
                route="BANCO_DO_BRASIL"
                {...getSidebarItemProps(
                  'icon-money',
                  t('menu.buttonSingleLine.BB'),
                  pathIsActive(activePath, routes.BANCO_DO_BRASIL),
                )}
              />
            )}

            <SidebarItem
              onClick={() =>
                history.push(
                  `${routes.PROFILE}/account-settings/personal-data`,
                )
              }
              route="PROFILE"
              {...getSidebarItemProps(
                'icon-cog',
                t('menu.buttonSingleLine.profile'),
                pathIsActive(
                  activePath,
                  routes.PROFILE ||
                    routes.EDIT_PROFILE ||
                    routes.UPDATE_PASSWORD,
                ),
              )}
            />

            {props.user.type !== 'E' && props.user.type !== 'F' && (
              <SidebarItem
                onClick={() => history.push(routes.FAQ)}
                route="FAQ"
                {...getSidebarItemProps(
                  'icon-comment',
                  t('menu.buttonSingleLine.support'),
                  pathIsActive(activePath, routes.FAQ),
                )}
              />
            )}
          </Wrapper>
        )}

        {user.isNextUser && (
          <Wrapper>
            <SidebarItem
              onClick={() => history.push(routes.HOME)}
              route="HOME"
              {...getSidebarItemProps(
                'icon-home',
                t('menu.buttonSingleLine.home'),
                pathIsActive(activePath, routes.HOME),
              )}
            />

            {props.user.type !== 'E' && (
              <SidebarItem
                onClick={() =>
                  history.push(`${routes.USER_MANAGEMENT}/user-list`)
                }
                route="USER_MANAGEMENT"
                {...getSidebarItemProps(
                  'icon-clipboard-user',
                  t('menu.buttonSingleLine.userManagement'),
                  pathIsActive(activePath, routes.USER_MANAGEMENT),
                )}
              />
            )}

            {props.user.type !== 'E' && (
              <SidebarItem
                onClick={() => history.push(routes.WEEK_SCHEDULE)}
                route="WEEK_SCHEDULE"
                {...getSidebarItemProps(
                  'icon-calendar',
                  t('menu.buttonSingleLine.logBook-manager'),
                  pathIsActive(activePath, routes.WEEK_SCHEDULE),
                )}
              />
            )}

            {props.user.type !== 'F' &&
              props.user.type !== 'O' && (
                <SidebarItem
                  onClick={() => history.push(routes.LOGBOOK)}
                  route="LOGBOOK"
                  {...getSidebarItemProps(
                    'icon-calendar',
                    t('menu.buttonSingleLine.logBook'),
                    pathIsActive(activePath, routes.LOGBOOK),
                  )}
                />
              )}

            {shouldHideMenu && (
              <SidebarItem
                onClick={() => history.push(routes.CADEC)}
                route="CADEC"
                {...getSidebarItemProps(
                  'icon-users',
                  t('menu.buttonSingleLine.cadec'),
                  pathIsActive(activePath, routes.CADEC),
                )}
              />
            )}

            <div>
              <SidebarItem
                onClick={() => history.push(routes.ANNOUNCEMENTS)}
                route="ANNOUNCEMENTS"
                {...getSidebarItemProps(
                  'icon-notice',
                  t('menu.buttonSingleLine.announcements'),
                  pathIsActive(activePath, routes.ANNOUNCEMENTS),
                )}
              />
            </div>

            {props.user.type !== 'F' && props.user.type !== 'O' && (
              <SidebarItem
                onClick={() => history.push(routes.VENDORS)}
                route="VENDORS"
                {...getSidebarItemProps(
                  'icon-clipboard-user',
                  t('menu.buttonSingleLine.vendors'),
                  pathIsActive(activePath, routes.VENDORS),
                )}
              />
            )}

            {props.user.type !== 'E' && (
              <SidebarItem
                onClick={() => history.push(routes.VENDORS_TABLE)}
                route="VENDORS_TABLE"
                {...getSidebarItemProps(
                  'icon-clipboard-user',
                  t('menu.buttonSingleLine.vendors'),
                  pathIsActive(activePath, routes.VENDORS_TABLE),
                )}
              />
            )}

            {props.user.type === 'E' ||
              (props.user.type === 'F' && (
                <SidebarItem
                  onClick={() =>
                    history.push(`${routes.INDICATORS}/opened`)
                  }
                  route="INDICATORS"
                  {...getSidebarItemProps(
                    'icon-chart-analytics',
                    t('menu.buttonSingleLine.dashboard'),
                    pathIsActive(activePath, routes.INDICATORS),
                  )}
                />
              ))}

            <SidebarItem
              onClick={() => history.push(routes.DOCUMENTATION)}
              route="DOCUMENTATION"
              {...getSidebarItemProps(
                'icon-file',
                t('menu.buttonSingleLine.documentation'),
                pathIsActive(activePath, routes.DOCUMENTATION),
              )}
            />

            {props.user.type === 'E' && (
              <SidebarItem
                onClick={() => history.push(routes.SCHEDULE)}
                route="SCHEDULE"
                {...getSidebarItemProps(
                  'icon-stopwatch',
                  t('menu.buttonSingleLine.schedule'),
                  pathIsActive(activePath, routes.SCHEDULE),
                )}
              />
            )}

            <SidebarItem
              onClick={() => history.push(`${routes.LIBRARY}/list`)}
              route="LIBRARY"
              {...getSidebarItemProps(
                'icon-library',
                t('menu.buttonSingleLine.library'),
                pathIsActive(activePath, `${routes.LIBRARY}/list`),
              )}
            />

            {props.user.type !== 'E' && (
              <SidebarItem
                onClick={() => history.push(routes.REPORTS)}
                route="REPORTS"
                {...getSidebarItemProps(
                  'icon-stopwatch',
                  t('menu.buttonSingleLine.schedule'),
                  pathIsActive(activePath, routes.REPORTS),
                )}
              />
            )}

            {shouldHideMenu && (
              <SidebarItem
                onClick={() => history.push(routes.NEWS_LIST)}
                route="NEWS_LIST"
                {...getSidebarItemProps(
                  'icon-news',
                  t('menu.buttonSingleLine.news'),
                  pathIsActive(activePath, routes.NEWS_LIST),
                )}
              />
            )}

            <SidebarItem
              onClick={() => history.push(routes.NEXTFAQ)}
              route="NEXTFAQ"
              {...getSidebarItemProps(
                'icon-comment',
                t('menu.buttonSingleLine.support'),
                pathIsActive(activePath, routes.NEXTFAQ),
              )}
            />

            {shouldShowMenu && (
              <SidebarItem
                onClick={() => history.push(routes.FINANCIALS)}
                route="FINANCIALS"
                {...getSidebarItemProps(
                  'icon-book',
                  t('menu.buttonSingleLine.financials'),
                  pathIsActive(activePath, routes.FINANCIALS),
                )}/>
              )}
          </Wrapper>
        )}

        <footer className="sidebar-footer">
          <button
            type="button"
            className="unstyled-button sidebar-footer-link"
            onClick={() => {
              logout();
              props.unsetUser();
            }}
          >
            <span className="logout">
              <Icon name="icon-sign-out" />
              <span className="label">
                {t('menu.buttonSingleLine.logout')}
              </span>
            </span>
          </button>
        </footer>
      </Sidebar>
    </MainWrapper>
  );
}
