import mask, { removeSpecialCharacters } from './mask';

const PATTERN = '###########';

export default function doMask(input) {
  if (input === '') {
    return '';
  }

  const value = removeSpecialCharacters(String(input));

  return mask(PATTERN, value);
}
