import { API_SUPERVISOR_URL } from 'config/constants';
import { request } from 'shared/utils/client';
import qs from 'qs';

export async function getChecklists(vendorCode) {
  const response = await request(
    `${API_SUPERVISOR_URL}/CheckList/GetCheckLists?vendor=${vendorCode}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getQuestions(params) {
  const response = await request(
    `${API_SUPERVISOR_URL}/CheckList/GetQuestionsByCodeChaveMtech?${qs.stringify(
      params,
    )}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function getHistory(params) {
  const response = await request(
    `${API_SUPERVISOR_URL}/CheckList/GetHistoricQuestionsByCodeChaveMtech?${qs.stringify(
      params,
    )}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function submitAnswer(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/CheckList/SaveCheckListAnswer`,
    {
      method: 'POST',
      payload,
    },
  );

  return response.data;
}

export async function getLogBookChecklists(vendor) {
  const response = await request(
    `${API_SUPERVISOR_URL}/logbook-checklists?vendor=${vendor}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}
