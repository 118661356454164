// @flow

import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getQuestions() {
  const response = await request(`${API_URL}/Faq/GetQuestions`, {
    method: 'GET',
  });

  return response.data;
}

export async function sendFaq(payload) {
  const response = await request(`${API_URL}/Faq/SendFaq`, {
    method: 'POST',
    payload,
  });

  return response;
}
