import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import Container from 'components/Container';
import {
  CircuitQuestionWithAnswer,
  CircuitQuestion,
} from 'components/CircuitQuestion';
import { Button } from '@zendeskgarden/react-buttons';
import { Form } from 'components/Forms/Forms.styled';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import NoContentTab from 'components/NoContentTab';
import LoadingHolder from 'components/LoadingHolder';
import SubmitModal from './SubmitModal';
import ActionPlan from './ActionPlan';
import ActionPlanEdit from './ActionPlanEdit';
import ActionPlanDetails from './ActionPlanDetails';
import {
  getLogBookChecklistsQuestion,
  saveLogBookChecklistsQuestion,
  saveLogBookChecklistsActionPlan,
  getLogBookChecklistsSchedules,
  updateActionPlan,
  getFlockCircuitQuestions,
} from './service';

import { useChecklist } from '../Checklists/hooks';
import t from 'components/i18n';
import { getLogBookItemDetails } from '../LogBook/service';

interface QuestionFormProps {
  user: object;
  isFlockVisit: boolean;
  vendorCode: string;
  selectedFlockCircuitName: string;
  selectedItem: object;
  selectedSchedule: object;
  setSelectedSchedule: () => void;
  selectedQuestions: object;
  setSelectedQuestions: () => void;
  displayDetails: object;
  setDisplayDetails: () => void;
  displayPlanEdit: object;
  setDisplayPlanEdit: () => void;
  displayPlanForm: object;
  setDisplayPlanForm: () => void;
  // logbookChecklistScheduleId,
  getSchedules: object;
  isFinished?: Boolean;
}

export default function QuestionForm({
  user,
  isFlockVisit,
  vendorCode,
  id,
  selectedFlockCircuitName,
  selectedItem,
  selectedSchedule,
  setSelectedSchedule,
  selectedQuestions,
  setSelectedQuestions,
  displayDetails,
  setDisplayDetails,
  displayPlanEdit,
  isFinished,
  setDisplayPlanEdit,
  displayPlanForm,
  setDisplayPlanForm,
  // logbookChecklistScheduleId,
  getSchedules,
  state,
  scheduleId
}: QuestionFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [questionList, setQuestionList] = useState(null);
  const [questionsToSend, setQuestionsToSend] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [questionsSuccess, setQuestionsSuccess] = useState(false);
  const [epidemiologicalUnit, setEpidemiologicalUnit] = useState(
    null,
  );
  const [planSuccess, setPlanSuccess] = useState(false);

  const [flocks, setFlocks] = useState(null);

  const [{ details }] = useChecklist();

  const { vendor, checklist } = details;

  const getChecklistsQuestions = useCallback(
    async (id, vndr) => {
      setIsLoading(true);
      try {
        const responseFlock = await getFlockCircuitQuestions({ vendor: vendorCode, state, scheduleId});
        setEpidemiologicalUnit(responseFlock[0].epidemiologicalUnit);
        setFlocks(responseFlock[0].flockList);

        if (selectedFlockCircuitName === 'Circuito dos lotes') {
          const response = await getLogBookChecklistsQuestion({
            id,
            vendor: vndr,
            epidemiologicalCore: responseFlock[0].epidemiologicalUnit,
            flocks: responseFlock[0].flockList,
          });

          setQuestionList(response);
        } else {
          const response = await getLogBookChecklistsQuestion({
            id,
            vendor: vndr,
            flocks: responseFlock[0].flockList,
          });

          setQuestionList(response);
        }
      } catch (e) {
        console.error('Error on get checklist questions', e);
      }
      setIsLoading(false);
    },
    [selectedFlockCircuitName, vendorCode],
  );

  const handleChangeExecuted = useCallback(
    async (actionPlanId, payload) => {
      try {
        await updateActionPlan(actionPlanId, payload);

        await getChecklistsQuestions(selectedSchedule.id, vendorCode);
      } catch (e) {
        console.error('Error on update action plan', e);
      }
    },
    [getChecklistsQuestions, selectedSchedule.id, vendorCode],
  );

  useEffect(() => {
    if (!isFlockVisit && selectedSchedule) {
      getChecklistsQuestions(selectedSchedule.id, vendorCode);
    }
  }, [
    getChecklistsQuestions,
    isFlockVisit,
    selectedSchedule,
    vendorCode,
  ]);

  const saveActionPlans = useCallback(async () => {
    const plansToSave = questionsToSend.filter(question =>
      Boolean(question.actionPlan),
    );

    Promise.all(
      plansToSave.map(item => {
        const payload = {
          ...item.actionPlan,
          logbookChecklistId: selectedItem.logBookChecklistId,
          logbookChecklistScheduleId: item.logbookChecklistScheduleId,
          logbookChecklistQuestionId: item.logbookChecklistQuestionId,
          logbookChecklistAnswerId: item.logbookChecklistAnswerId,
          epidemiologicalCore: epidemiologicalUnit,
        };

        saveLogBookChecklistsActionPlan(payload);
      }),
    )
      .then(() => {
        setPlanSuccess(true);
      })
      .catch(err => {
        console.error(err.message);
      });
  }, [questionsToSend]);

  const saveQuiz = useCallback(
    async val => {
      try {
        setIsSubmitting(true);
        if (selectedFlockCircuitName === 'Circuito dos lotes') {
          const responseFlock = await getFlockCircuitQuestions({ vendor: vendorCode, state, scheduleId});
          const responseSchedules = await getLogBookChecklistsSchedules(
            checklist.id,
            vendor.vendorCode,
          );
          const logBookChecklistId =
            responseSchedules[0].logBookChecklistId;

          const flock = val.map(item => ({
            ...item,
            epidemiologicalCore: responseFlock[0].epidemiologicalUnit,
            logbookChecklistId: logBookChecklistId,
            flocks,
          }));

          const response = await saveLogBookChecklistsQuestion(flock);
          if (!response.success) {
            throw Error(response.message);
          }
          setQuestionsSuccess(response.success);
        } else {
          const data = val.map(item => ({
            ...item,
            flocks,
          }));

          const response = await saveLogBookChecklistsQuestion(data);
          if (!response.success) {
            throw Error(response.message);
          }
          setQuestionsSuccess(response.success);
        }
      } catch (error) {
        setErrorMessage(error.message);
        setIsSubmitting(false);
        throw Error(error.message);
      }
    },
    [
      checklist.id,
      selectedFlockCircuitName,
      vendor.vendorCode,
      vendorCode,
      flocks,
    ],
  );

  useEffect(() => {
    if (planSuccess) {
      saveQuiz(questionsToSend);
    }
  }, [planSuccess, questionsToSend, saveQuiz]);

  if (
    !isLoading &&
    (!questionList || (questionList && !questionList.length))
  ) {
    return (
      <Container hideDetail slim className="animated fadeIn">
        <NoContentTab icon="icon-tasks" hideButton>
          <p>{t('global.no-content')}</p>
        </NoContentTab>
      </Container>
    );
  }
  
  return (
    <>
      {!displayDetails && !displayPlanForm && !displayPlanEdit && (
        <Container hideDetail slim className="animated fadeIn">
          <Form>
            <LoadingHolder isLoading={isLoading} />

            {!isLoading && questionList && questionList.length > 0 && (
              <>
                {questionList.map((item, index) => {
                  if (!item.isEditable || isFinished) {
                    return (
                      <CircuitQuestionWithAnswer
                        {...item}
                        key={item.id}
                        number={item.id}
                        questionList={questionList}
                        setDisplayDetails={setDisplayDetails}
                        setEditPlan={setDisplayPlanForm}
                        isFinished={isFinished}
                        handleChangeExecuted={handleChangeExecuted}
                        vendorCode={vendorCode}
                      />
                    );
                  }

                  return (
                    <CircuitQuestion
                      {...item}
                      key={item.id}
                      number={item.id}
                      questionsToSend={questionsToSend}
                      setQuestionsToSend={setQuestionsToSend}
                      setQuestionList={setQuestionList}
                      setDisplayDetails={setDisplayDetails}
                      setDisplayPlanEdit={setDisplayPlanEdit}
                      logBookChecklistId={
                        selectedSchedule.logBookChecklistId
                      }
                      selectedQuestions={selectedQuestions}
                      logbookChecklistScheduleId={selectedSchedule.id}
                      onPlanButtonClick={() => {
                        setSelectedQuestions(item);
                        setDisplayPlanForm(true);
                      }}
                      vendorCode={vendorCode}
                      user={user}
                      update={!displayPlanForm}
                      handleChangeExecuted={handleChangeExecuted}
                    />
                  );
                })}

                {!isFinished && (
                  <BtnGroup>
                    <Button
                      key="save"
                      className="tertiary"
                      // disabled={!isValid || isSubmitting}
                      onClick={() => saveActionPlans()}
                    >
                      {t('global.button.save')}
                    </Button>
                    <Button
                      key="back"
                      className="white"
                      onClick={() => setSelectedSchedule(null)}
                    >
                      {t('global.button.back')}
                    </Button>
                  </BtnGroup>
                )}

                {isFinished && (
                  <BtnGroup>
                    <Button
                      key="back"
                      className="tertiary"
                      onClick={() => setSelectedSchedule(null)}
                    >
                      {t('global.button.back')}
                    </Button>
                  </BtnGroup>
                )}
              </>
            )}
          </Form>
        </Container>
      )}

      {displayDetails && (
        <ActionPlanDetails
          selectedQuestions={selectedQuestions}
          details={displayDetails}
          setDisplayDetails={setDisplayDetails}
        />
      )}

      {displayPlanForm && (
        <Container hideDetail slim className="animated fadeIn">
          <ActionPlan
            user={user}
            selectedItem={selectedItem}
            setDisplayPlan={setDisplayPlanForm}
            setQuestionList={setQuestionList}
            selectedQuestions={selectedQuestions}
            questionList={questionList}
            questionsToSend={questionsToSend}
            setQuestionsToSend={setQuestionsToSend}
            vendorCode={vendorCode}
          />
        </Container>
      )}

      {displayPlanEdit && (
        <Container hideDetail slim className="animated fadeIn">
          <ActionPlanEdit
            user={user}
            vendorCode={vendorCode}
            displayPlanEdit={displayPlanEdit}
            setDisplayPlanEdit={setDisplayPlanEdit}
            setQuestionList={setQuestionList}
            selectedQuestions={selectedQuestions}
            questionList={questionList}
            questionsToSend={questionsToSend}
            setQuestionsToSend={setQuestionsToSend}
          />
        </Container>
      )}

      {/* On submit modal */}
      <SubmitModal
        success={questionsSuccess}
        setSuccess={setQuestionsSuccess}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        setSelectedSchedule={setSelectedSchedule}
        setSelectedQuestions={setSelectedQuestions}
        getSchedules={getSchedules}
      />
      {/* \ On submit modal */}
    </>
  );
}
