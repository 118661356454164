/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import validate from 'components/Forms/Fields/validate';
import * as routes from 'config/routes';
import { Formik } from 'formik';
import { OPEN_RECORD, FLOCK_LIST } from 'config/routes';
import { scrollToTop } from 'shared/utils/Helpers';
import { Button } from '@zendeskgarden/react-buttons';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import Breadcrumb from 'components/Breadcrumb';
import Box from 'components/Container';
import WeekCalendar from 'components/WeekCalendar';
import RecordSummary from 'containers/AgroBRF/FlockList/Record/RecordSummary';
import RecordItemsList from 'containers/AgroBRF/FlockList/Record/RecordItemsList';
import Top from 'components/Top';
import HistoryTop from 'containers/AgroBRF/FlockList/Flock/FlockHistory/HistoryTop';
import Badge from 'components/Badge';
import NoContentTab from 'components/NoContentTab';
import {
  Container,
  BadgeWrapper,
  FormTop,
} from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import FalForm from 'containers/AgroBRF/Forms/FalForm';
import {
  stringToDate,
  formatDateString,
} from 'shared/utils/Helpers/handleDate';
import {
  initialValues,
  formData,
  getValidateSchema,
} from 'containers/AgroBRF/FlockList/Record/helpers';
import {
  getFalInformation,
  getDeceasedTypes,
  getFeedTypes,
  getFalVendor,
  getAge,
} from 'containers/AgroBRF/FlockList/service';
import t from 'components/i18n';
import { useSelector } from 'react-redux';
import { isUndefined } from 'utils';

export default function OpenRecord(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [week, setWeek] = useState(null);
  const [deceasedTypes, setDeceasedTypes] = useState([]);
  const [feedTypes, setFeedTypes] = useState([]);
  const [loadingVendors, setLoadingVendors] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [falAge, setFalAge] = useState([]);
  const [isSummary, setIsSummary] = useState(false);
  const [summaryContent, setSummaryContent] = useState({});
  const [displayForm, setDisplayForm] = useState(false);

  const user = useSelector(state => state.user);
  const userCountryCode =
    !isUndefined(user.countryCodes) && user.countryCodes[0];

  const badgeProps = {};

  if (data.dailyStatus) {
    switch (data.dailyStatus.toLowerCase()) {
      case 'pendente':
        badgeProps.info = true;
        break;
      default:
        badgeProps.success = true;
    }
  }

  const hasData = Object.keys(data).length > 0;

  const editable =
    props.user.profile !== 'Assistente'
      ? data.isEditable || data.pendingSubmit
      : false;

  const previousSubmitted =
    (data &&
      data.mortality &&
      data.mortality.mortalityOnArrival !== null &&
      Number(data.mortality.mortalityOnArrival) > -1) ||
    (data && data.waterConsume && data.waterConsume.waterConsumption);

  async function fetchData(falId, falDate) {
    setIsLoading(true);
    try {
      const response = await getFalInformation(falId, falDate);
      setData(response);
    } catch (e) {
      console.error('Error on get fal information', e);
    }
    setIsLoading(false);
  }

  async function getFormDeceasedTypes() {
    try {
      const response = await getDeceasedTypes();
      setDeceasedTypes(response);
    } catch (e) {
      console.error('Error on get deceased types', e);
    }
  }

  async function getFormFeedTypes(falId) {
    try {
      const response = await getFeedTypes(falId);
      setFeedTypes(response);
    } catch (e) {
      console.error('Error on get feed types', e);
    }
  }

  async function getFormVendors(search) {
    setLoadingVendors(true);
    try {
      const response = await getFalVendor(search);
      setVendors(response);
      setLoadingVendors(false);
    } catch (e) {
      console.error('Error on get vendors', e);
      setLoadingVendors(false);
    }
  }

  function submitForm(values) {
    setSummaryContent(formData(values, data));
    setIsSummary(true);
  }

  async function getFalAge(falId, date) {
    setLoadingVendors(true);
    try {
      const response = await getAge(falId, formatDateString(date));
      setFalAge(response);
    } catch (e) {
      console.error('Error on get age', e);
    }
  }

  useEffect(() => {
    if (props.match.params.date === undefined) {
      fetchData(props.match.params.id, null);
    } else {
      fetchData(props.match.params.id, props.match.params.date);
    }

    getFormFeedTypes(props.match.params.id);

    getFormDeceasedTypes();
  }, []);

  useEffect(() => {
    if (hasData) {
      getFalAge(props.match.params.id, data.date);
      setWeek(data.week);
      setSummaryContent({});
    }

    if (editable) {
      if (previousSubmitted) {
        setDisplayForm(false);
      } else {
        setDisplayForm(true);
      }
    } else {
      setDisplayForm(false);
    }
  }, [data]);

  useEffect(() => {
    scrollToTop(document.getElementById('main'));
  }, [isSummary, displayForm]);

  return isSummary ? (
    <RecordSummary
      data={summaryContent}
      isEditable={editable}
      isLocked={data.status === 'Concluído'}
      backToEdit={() => setIsSummary(false)}
      backToList={() =>
        props.history.push(`${OPEN_RECORD}/${data.falId}`)
      }
      {...props}
    />
  ) : (
    <>
      {data && (
        <Breadcrumb
          history={props.history}
          backTo={() =>
            props.history.push(`${FLOCK_LIST}/${data.flockId}/record`)
          }
          path={[
            t('flocks.title'),
            t('flocks.listView.item.title', { flockNumber: data.flock || ''}),
            data.house && t('flocks.newFal.houses.card.title', { value: data.house}),
          ]}
          links={[
            routes.FLOCK_LIST,
            `${FLOCK_LIST}/${data.flockId}`,
            `${FLOCK_LIST}/${data.flockId}/record`,
          ]}
        />
      )}
      <Top>
        <div className="top-content">
          <h2 className="top-content-heading">
            {!isLoading &&
              (hasData
                ? t('flocks.newFal.houses.card.title', { value: data.house})
                : t('global.word.house.notFound'))}
          </h2>
        </div>
      </Top>

      <Container className="flock-container flock-container__open-record">
        <div className="main-wrapper">
          <Box slim>
            {hasData && (
              <>
                <WeekCalendar
                  startDate={stringToDate(data.startDate)}
                  finishDate={stringToDate(data.finishDate)}
                  date={stringToDate(data.date)}
                  id={props.match.params.id}
                  onSelectDate={e =>
                    fetchData(props.match.params.id, e)
                  }
                  history={props.history}
                  setNextWeek={() => setWeek(week + 1)}
                  setPrevWeek={() =>
                    setWeek(week === 1 ? 1 : week - 1)
                  }
                  weekNum={week}
                />
                <div className="flock-details">
                  <HistoryTop
                    textContent={[
                      {
                        label: t('flock.openRecord.history.week'),
                        content: week,
                      },
                      {
                        label: t('flocks.newFal.houses.cardDetails.view.header.flocksAge.label'),
                        content: t('flocks.newFal.houses.cardDetails.view.header.flocksAge.value', { value: data.age }),
                      },
                      {
                        label: t('flocks.newFal.houses.cardDetails.view.header.cumulativeMortality.label'),
                        content: t('flocks.newFal.houses.cardDetails.view.header.cumulativeMortality.value', { value: data.accumulatedMortality }),
                      },
                    ]}
                  />
                  {editable
                    ? displayForm && (
                        <FormTop>
                          <p>{t('flocks.newFal.houses.cardDetails.form.section.subtitle')}</p>
                          <p className="blue">
                            <span className="round">!</span>{' '}
                          {t("flocks.newFal.houses.cardDetails.form.section.alert")}
                        </p>
                        </FormTop>
                      )
                    : props.user.profile !== 'Assistente' && (
                        <BadgeWrapper>
                          <Badge {...badgeProps}>
                            {data.dailyStatus}
                          </Badge>
                        </BadgeWrapper>
                      )}
                </div>

                {displayForm && (
                  // TODO: re-testar campos de data que vêm de initialValues
                  <Formik
                    enableReinitialize
                    initialValues={
                      Object.keys(summaryContent).length > 0
                        ? initialValues(summaryContent)
                        : initialValues(data)
                    }
                    validate={validate(
                      getValidateSchema(
                        falAge,
                        feedTypes,
                        userCountryCode.includes('TR'),
                      ),
                    )}
                    onSubmit={submitForm}
                    render={formikProps => (
                      <FalForm
                        {...formikProps}
                        history={props.history}
                        deceasedTypes={deceasedTypes}
                        feedTypes={feedTypes}
                        isLoading={isLoading}
                        getFormVendors={getFormVendors}
                        isEditable={editable}
                        isFirstDay={data.isFirstDay}
                        isRequiredWeight={data.isRequiredWeight}
                        isRequiredPh={data.isRequiredPh}
                        showFlockClosure={data.showFlockClosure}
                        responsive={props.responsive}
                        vendors={vendors}
                        loadingVendors={loadingVendors}
                        currentFlockAge={data.age}
                      />
                    )}
                  />
                )}

                {!displayForm && (
                  <div className="record-list">
                    <RecordItemsList
                      data={data}
                      isEmpty={!editable && !previousSubmitted}
                    />
                    {editable && (
                      <BtnGroup>
                        <Button
                          key={0}
                          onClick={() => setDisplayForm(true)}
                        >
                          {t('global.button.edit')}
                        </Button>
                      </BtnGroup>
                    )}
                  </div>
                )}
              </>
            )}

            {!hasData && !isLoading && (
              <NoContentTab
                icon="icon-scale"
                onButtonClick={() => props.history.push(FLOCK_LIST)}
                buttonLabel="Voltar para lotes"
              >
                <p>
                  {t("flocks.empty.title")} <br />{' '}
                  <span className="lighter">
                    {t("flocks.empty.subtitle")}
                  </span>
                </p>
              </NoContentTab>
            )}
          </Box>
        </div>
      </Container>
    </>
  );
}
