import React from 'react';
import { OPEN_RECORD } from 'config/routes';
import t from 'components/i18n';
// Styles
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import {
  Container,
  ItemContainer,
  StatusFooter,
  ColumnInfo,
  Text,
} from './FlockHistory.styled';
import { getFeedCutDates } from '../../service';

export default function RecordItem(props) {
  const { feed, goTo } = props;
  const badgeProps = {};

  function selectItem(option) {
    goTo(option);
  }

  if (feed.status && feed.status === 'Pendente') {
    if (feed.isFalUpToDate) {
      badgeProps.success = true;
    } else {
      badgeProps.info = true;
    }
  }

  if (feed.status && feed.status === 'Concluído') {
    badgeProps.danger = true;
  }

  return (
    <Container className="bordered-container">
      <ItemContainer
        className="item-container"
        onClick={() => selectItem('confirmation')}
      >
        <div className="open summary">
          <div className="columns">
            <ColumnInfo>
              <strong>{t('flocks.newFal.houses.cardDetails.title', {value: feed.house})}</strong>
              <p>{feed.days === 1? t('global.unit.days', {value: feed.days}) : t('global.unit.days_plural', {value: feed.days})}</p>
            </ColumnInfo>
            <ColumnInfo className="stretch">
              <p>{t('flocks.newFal.houses.card.averageWeightPerformed.label')}</p>
              <strong>{feed.averageWeight}</strong>
            </ColumnInfo>
            <ColumnInfo className="stretch">
              <p>{t('flocks.newFal.houses.card.averageMortality.label')}</p>
              <strong>{feed.averageMortality}</strong>
            </ColumnInfo>
            <ColumnInfo className="stretch">
              <BtnGroup>
                <button
                  type="button"
                  className="white border-radius pointer noFloat"
                  onClick={() =>
                    props.history.push(`${OPEN_RECORD}/${feed.falId}`)
                  }
                >
                  {t('flocks.newFal.houses.card.buttonSecondary')}
                </button>
              </BtnGroup>
            </ColumnInfo>
          </div>
        </div>
      </ItemContainer>
      <Separator gap={8} full />
      <StatusFooter {...badgeProps}>
        <Text>
          <div className="icon-wrapper">
            <span
              className={`icon ${
                badgeProps.danger
                  ? `icon-lock-enabled`
                  : `icon-check-circle`
              }`}
            />
          </div>
          <p>
            {badgeProps.danger
              ? t(`flocks.newFal.houses.card.footer.alert.formClosure`) + ': '
              : t(`global.status.lastFilling`)}{': '}
            <span>{feed.lastUpdate}</span>
          </p>
        </Text>
      </StatusFooter>
    </Container>
  );
}
