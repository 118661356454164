import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  memo,
} from 'react';
import * as _ from 'lodash';
import ThemeSwitcher from 'components/GlobalStyles/ThemeSwitcher';
import t from 'components/i18n';
import Box from 'components/Container';
import SideBox from 'components/SideBox';
import { reduceBy } from 'shared/utils/Helpers';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Menu, Field } from '@zendeskgarden/react-dropdowns';
import { Button } from '@zendeskgarden/react-buttons';
import SmallButton from 'components/SmallButton';
import {
  Heading,
  TitleBlock,
} from 'components/SideBox/SideBox.styled';
import {
  SelectBox,
  DropdownBox,
  AutocompleteBox,
  ItemBox,
} from 'components/Forms/Forms.styled';
import { FilterStyled, FilterBox, FixedField } from './Filter.styled';

function NewModalFilter(props) {
  const [openSideBox, setOpenSideBox] = useState(false);
  const [activeFilter, setActiveFilter] = useState([]);
  const [filterAutocomplete, setFilterAutocomplete] = useState('');

  const themeSwitcher = ThemeSwitcher();

  const applyFilters = useCallback(() => {
    props.applyFilters(activeFilter);
  }, [activeFilter, props]);

  useEffect(() => {
    setActiveFilter(props.activeFilters);
  }, [props.activeFilters]);

  useEffect(() => {
    if (openSideBox) {
      setActiveFilter(props.activeFilters);
    }
  }, [openSideBox, props.activeFilters]);

  const setItem = useCallback(item => {
    setActiveFilter(prev => {
      const someComboFilter = prev.find(
        combo => combo.label === item.label,
      );

      let newArr = prev.filter(el => el.label !== item.label);

      if (item.txt !== item.label) {
        if (item.resetAll) {
          newArr = [item];
        } else {
          newArr.push(item);
        }
      }

      if (someComboFilter && someComboFilter.id !== undefined) {
        newArr = newArr.filter(
          el => el.targetId !== someComboFilter.id,
        );
      }

      if (item.id !== undefined) {
        newArr = newArr.filter(
          el =>
            // if (el.targetId !== undefined) {
            //   return el.targetId === item.id;
            // }
            true,
        );
      }

      return newArr;
    });
  }, []);

  const loadText = useCallback(
    item => {
      if (item.items && item.items.length > 0) {
        for (let ind = 0; ind < item.items.length; ind++) {
          const el = item.items[ind];
          if (activeFilter.some(filter => filter.txt === el.txt)) {
            return el.name || el.txt;
          }
        }
        return item.name || item.label;
      }
    },
    [activeFilter],
  );

  const itemsView = useMemo(
    () =>
      _.cloneDeep(props.list).map(x => {
        // /lists all active filters
        const tgtId = activeFilter.find(
          filter => filter.label === x.target,
        );

        if (tgtId !== undefined) {
          x.items = x.items.filter(itensList => {
            if (x.target !== undefined) {
              if (tgtId.id === itensList.targetId) {
                return true;
              }
              return false;
            }
            return true;
          });
        }
        return x;
      }),
    [activeFilter, props.list],
  );

  const renderItems = useMemo(
    () =>
      itemsView.map((arr, index) => {
        const filteredArr =
          arr.autocomplete && filterAutocomplete
            ? arr.items.filter(item =>
                item.txt
                  .trim()
                  .toLowerCase()
                  .includes(filterAutocomplete.trim().toLowerCase()),
              )
            : arr.items && arr.items[0] && arr.items[0].id
            ? reduceBy(arr.items, 'id')
            : arr.items;

        return (
          <DropdownBox
            inputValue={filterAutocomplete}
            onInputValueChange={value => setFilterAutocomplete(value)}
            key={index}
          >
            <FilterBox>
              <Field>
                {arr.autocomplete ? (
                  <AutocompleteBox>{loadText(arr)}</AutocompleteBox>
                ) : (
                  <SelectBox>{loadText(arr)}</SelectBox>
                )}
              </Field>
              <Menu>
                {!filterAutocomplete && (
                  <ItemBox
                    key={0}
                    value={arr.name || arr.label}
                    onClick={x => {
                      setItem({
                        label: arr.label,
                        txt: arr.label,
                        resetAll: arr.resetAll,
                        ...x,
                      });
                    }}
                  >
                    {arr.name || arr.label}
                  </ItemBox>
                )}
                {filteredArr &&
                  filteredArr.length > 0 &&
                  filteredArr.map(
                    (option, i) =>
                      option && (
                        <ItemBox
                          key={i + 1}
                          value={option.name || option.txt}
                          onClick={() => {
                            setItem({
                              label: arr.label,
                              txt: option.txt,
                              resetAll: arr.resetAll,
                              ...option,
                            });
                          }}
                        >
                          {t(option.name || option.txt)}
                        </ItemBox>
                      ),
                  )}
              </Menu>
            </FilterBox>
          </DropdownBox>
        );
      }),
    [filterAutocomplete, itemsView, loadText, setItem],
  );

  return (
    <FilterStyled dark={props.dark} theme={props.theme}>
      <SmallButton
        type="button"
        className="border-radius btn"
        bgColor="colorPrimary"
        textColor="colorClear"
        disabled={props.disabled}
        onClick={() => {
          setOpenSideBox(!openSideBox);
        }}
      >
        {t(props.buttonLabel)} <span className="icon icon-plus-circle" />
      </SmallButton>
      <SideBox
        displayBox={openSideBox}
        setDisplayBox={setOpenSideBox}
      >
        <Box className="side-box">
          {props.title && (
            <TitleBlock>
              <Heading>{props.title}</Heading>
            </TitleBlock>
          )}

          <div className="flex-column">
            <div className="filter-container">{renderItems}</div>
          </div>

          {/* \ Botões */}
          <FixedField>
            <BtnGroup blue color={themeSwitcher}>
              <Button
                key="apply"
                onClick={() => {
                  applyFilters();
                  setOpenSideBox(false);
                }}
              >
                {t("global.button.apply")}
              </Button>
              <Button
                key="clean"
                onClick={() => {
                  props.clearFilters();
                  setOpenSideBox(false);
                }}
                className="white"
              >
                {t("global.button.clear")}
              </Button>
            </BtnGroup>
          </FixedField>
          {/* \ Botões */}
        </Box>
      </SideBox>
    </FilterStyled>
  );
}

export default memo(NewModalFilter);
