import React, { useState, useCallback } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import ActionPlanForm from 'containers/NextBRF/Forms/ActionPlanForm';
import validate from 'components/Forms/Fields/validate';
import Modal from 'components/Forms/FormModal/Modal';
import { uploadImage, deleteImage } from './service';
import t from 'components/i18n';

// validate

const validateSchema = () => {
  return yup.object().shape({
    nonCompliance: yup.string().required(t('global.status.required-field')),
    cause: yup.string().required(t('global.status.required-field')),
    action: yup.string().required(t('global.status.required-field')),
    how: yup.string().required(t('global.status.required-field')),
    // vendor: yup.string().required(t('global.status.required-field')),
    expectedDate: yup.string().required(t('global.status.required-field')),
    // visitReason: yup.string().required(t('global.status.required-field')),
  });
};

export default function ActionPlanEdit(
  {
    user,
    setQuestionList,
    selectedQuestions,
    questionsToSend,
    setQuestionsToSend,
    // id,
    // details: {
    //   action,
    //   cause,
    //   how,
    //   responsible,
    //   expectedDate,
    //   nonCompliance,
    //   visitReason,
    //   attachmentsUrls,
    // },
    displayPlanEdit: {
      id,
      action,
      cause,
      how,
      responsible,
      expectedDate,
      nonCompliance,
      visitReason,
      attachmentsUrls,
    },
    setDisplayPlanEdit,
  },
  props,
) {
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);

  const initialValues = {
    id,
    nonCompliance,
    cause,
    action,
    how,
    responsible,
    expectedDate,
    attachmentsUrls,
  };

  const selectedAnswer = useCallback(() => {
    const found = questionsToSend.find(
      item =>
        item.logbookChecklistQuestionId ===
        (selectedQuestions && selectedQuestions.id),
    );

    return found;
  }, [questionsToSend, selectedQuestions]);

  const handleFile = useCallback(
    async file => {
      setIsUploading(true);
      try {
        const response = await uploadImage(file);
        if (response) {
          setFiles([...files, response]);
        }
      } catch (error) {
        console.error(error);
      }
      setIsUploading(false);
    },
    [files],
  );

  const removeFile = useCallback(
    async file => {
      setIsUploading(true);
      try {
        const response = await deleteImage(file);
        if (response) {
          setFiles(files.filter(e => e !== file));
        }
      } catch (error) {
        console.error(error);
      }
      setIsUploading(false);
    },
    [files],
  );

  function submitForm(values, { setSubmitting, setErrors }) {
    const formData = {
      id: values.id || 0,
      nonCompliance: values.nonCompliance,
      cause: values.cause,
      action: values.action,
      how: values.how,
      vendor: props.vendorCode,
      expectedDate: values.expectedDate,
      attachmentsUrls: values.attachmentsUrls,
      visitReason: t("next.visitCircuit.actionPlan.visitTitle"),
      wasExecuted: null,
    };

    async function saveData(val) {
      setSubmitting(true);
      try {
        setQuestionList(oldList =>
          oldList.map(item => {
            if (item.id === selectedQuestions.id) {
              return {
                ...item,
                logBookChecklistSupervisorAnswers: [
                  {
                    logBookChecklistAnswerId: selectedAnswer.logBookChecklistAnswerId,
                    answer: selectedAnswer.answer,
                    logBookChecklistQuestionId: selectedAnswer.logBookChecklistQuestionId,
                    logBookChecklistActionPlan: val,
                    logBookChecklistId: selectedAnswer.logBookChecklistId,
                  },
                ],
              };
            }
            return item;
          }),
        );

        setQuestionsToSend(previousList =>
          previousList.map(item => {
            if (
              item.logbookChecklistQuestionId === selectedQuestions.id
            ) {
              return { ...item, actionPlan: val };
            }
            return item;
          }),
        );

        setSuccess(true);
        // const response = await saveLogBookChecklistsActionPlan(
        //   selectedAnswerId(),
        //   val,
        // );
        // if (response.success) {
        //   setSuccess(response.success);
        // } else {
        //   setSuccess(false);
        //   throw Error(response);
        // }
      } catch (error) {
        console.error(error);
      }
      setSubmitting(false);
    }
    saveData(formData);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate(validateSchema)}
        onSubmit={submitForm}
        render={formikProps => (
          <ActionPlanForm
            {...formikProps}
            {...props}
            handleFile={e => handleFile(e)}
            removeFile={e => removeFile(e)}
            isUploading={isUploading}
            files={files}
            user={user}
            setDisplayPlan={setDisplayPlanEdit}
          />
        )}
      />

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
          setDisplayPlanEdit(null);
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>
          {success
            ? t('global.validation.record-changes-saved')
            : t('global.validation.record-error')}
        </p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
