import styled from 'styled-components';
import { rgba } from 'polished';
import {
  colorGrayDarkest,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

export const Progress = styled.div`
  @keyframes growProgressBar {
    0%,
    33% {
      --pgPercentage: 0;
    }
    100% {
      --pgPercentage: var(--value);
    }
  }

  @property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
  }

  --value: ${props => props.value};
  --size: 40px;
  --fg: ${props => props.color};
  --bg: ${rgba(colorGrayDarkest, 0.25)};
  --pgPercentage: var(--value);
  animation: growProgressBar 2s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(
      closest-side,
      white 90%,
      transparent 0 99.9%,
      white 0
    ),
    conic-gradient(
      var(--fg) calc(var(--pgPercentage) * 1%),
      var(--bg) 0
    );
  font-family: ${fontFamilyTextBold};
  font-size: calc(var(--size) / 3);
  color: var(--fg);

  &::before {
    counter-reset: percentage ${props => props.value};
    content: counter(percentage) '%';
  }
`;
