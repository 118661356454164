import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';
import { fixFileName } from 'shared/utils/Helpers/fixFileName';

export async function updateInvoice(invoice, payload) {
  const response = await request(
    `${API_URL}/FlockInvoice/UpdateFlockInvoice?flockInvoiceId=${invoice}`,
    {
      method: 'PUT',
      payload
    },
  );

  return response;
}

export async function uploadReceipt(payload) {
  const fd = new FormData();
  fd.append('file', payload, fixFileName(payload));

  const response = await request(
    `${API_URL}/Blob/Upload?containerName=invoice`,
    {
      headers: {
        'Content-Type': undefined,
        Authorization: null,
      },
      method: 'POST',
      payload: fd,
    },
  );

  return response.data;
}
