import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  DEFAULT_SIZE,
  colorGrayDarkest,
  colorGrayLight,
  colorClear,
  fontFamilyTextBold,
  fontFamilyHeading,
  colorTertiary,
  boxShadow,
  borderRadius,
  colorPrimary,
  colorGrayUltraLight,
  breakpointTablet,
  breakpointDesktop,
} from 'components/GlobalStyles/variables';

export const Wrapper = styled.div`
  padding: 20px 16px;
`;

export const Card = styled.div`
  color: ${colorGrayDarkest};
  box-shadow: ${boxShadow};
  background-color: ${colorClear};
  padding: ${DEFAULT_SIZE * 5}px;
  margin-bottom: 20px;
  border-radius: ${borderRadius};

  @media (max-width: ${breakpointDesktop}px) {
    padding: ${DEFAULT_SIZE * 3}px;
  }

  &.has-card-title {
    padding: 0 0 36px;
    ${Wrapper} {
      padding: 0;
    }
  }

  .container-detail {
    background-color: ${colorPrimary};
    height: 3px;
    width: 50px;
    margin: 0 0 12px;
  }
`;

export const CardTitle = styled.div`
  font-family: ${fontFamilyHeading};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  padding: 0 16px 16px;
  border: 1px solid ${colorGrayUltraLight};
`;

export const SectionTitle = styled.div`
  font-family: ${fontFamilyHeading};
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15px;
  padding: 0;
  margin-bottom: 24px;

  .container-detail {
    background-color: ${props => props.backgroundColor};
    height: 3px;
    width: 50px;
    margin: 0 0 12px;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpointDesktop}px) {
    flex-direction: column;
  }
`;

export const CardColumn = styled.div`
  flex-basis: 652px;
  margin-right: 32px;
  @media (max-width: ${breakpointDesktop}px) {
    margin-right: 0;
  }
`;

export const SubTitle = styled.p`
  font-family: ${fontFamilyHeading} !important;
  color: ${colorGrayLight};
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 36px;
`;

export const ListColumn = styled.div`
  flex-basis: 316px;

  ${SubTitle} {
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  color: ${colorGrayDarkest};
  font-size: 14px;
  line-height: 20px;

  a {
    color: ${colorPrimary};
  }
`;

export const NewsListItemStyled = styled.article`
  position: relative;
  letter-spacing: 0.15px;
  margin: 0 0 16px;
  cursor: pointer;
  height: auto;
  border-bottom: ${props =>
    props.stayTunedHighlight
      ? `1px solid ${rgba(colorGrayDarkest, 0.12)}`
      : 'none'};
  margin-bottom: ${props =>
    props.stayTunedHighlight ? '16px' : 'none'}!important;
  
    &:last-child {
      margin: 0;
    }

  ${props =>
    props.highlight &&
    `
      height: 328px;  
      margin-bottom: 16px !important;               
    `}

  @media (min-width: ${breakpointTablet}px) {
    border-bottom: ${props => props.stayTunedHighlight && 'none'};
    margin-bottom: ${props =>
      props.stayTunedHighlight && 'none'}!important;
  }

  @media (max-width: ${breakpointDesktop}px) {
    height: ${props => (props.highlight ? ' 230px' : 'auto')};
    width: 100%;
    padding: 0;    
  }

  p {
    color: ${colorGrayLight};
    font-size: 14px;
    line-height: 20px;
  }

  .highlight-column & {
    height: 159px;
    margin-bottom: 10px;
  }

  ${ListColumn} & {
    align-items: flex-start;
    margin-bottom: ;
  }

  ${props =>
    props.fullWidth &&
    `
      display: flex;    
      align-items: center;                   
    `}
`;

export const TextWrapper = styled.div`
  position: relative;
  margin: ${props => (props.fullWidth ? '0 20px' : '0')};
`;

export const ImageWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  line-height: 0;
  margin-bottom: ${props => (props.highlight ? '0' : '16px')};
  height: 210px;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  ${props =>
    props.highlight &&
    `
      height: 100%;              
    `};
  ${props =>
    props.fullWidth &&
    `
      height: 100%;              
    `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: ${props => (props.visible ? '1' : '0')};
    transition: 0.4s ease-in transform, 0.2s ease-out opacity;

    @media (max-width: ${breakpointDesktop}px) {
      width: 100%;
      max-height: ${props => props.defaultText && '220px'};
    }

    ${Card} & {
      height: 128px;
    }
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }

  ${Card} & {
    margin-bottom: 12px;
  }

  ${ListColumn} & {
    flex-basis: 96px;
    margin-right: 16px;
    min-width: 96px;
    height: ${props => (props.fullWidth ? '100%' : '96px')};

    img {
      height: 96px;
    }
  }
`;

export const SpotLightImageWrapper = styled.div`
  width: calc(100% + ${DEFAULT_SIZE * 10}px);
  margin: 0 0 ${DEFAULT_SIZE * 5}px -${DEFAULT_SIZE * 5}px;

  @media (max-width: ${breakpointDesktop}px) {
    width: calc(100% + ${DEFAULT_SIZE * 6}px);
    margin: 0 0 ${DEFAULT_SIZE * 3}px -${DEFAULT_SIZE * 3}px;
  }

  img {
    width: 100%;
    opacity: ${props => (props.visible ? '1' : '0')};
    transition: 0.4s ease-in transform, 0.2s ease-out opacity;
  }
`;

export const Title = styled.h6`
  position: relative;
  color: ${colorGrayDarkest} !important;
  font-family: ${fontFamilyTextBold}, sans;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal !important;
  letter-spacing: 0.15px;
  position: ${props => (props.highlight ? 'absolute' : 'relative')};
  padding: ${props => (props.highlight ? '16px' : '0')} !important;
  z-index: 10;
  width: 100%;

  ${props =>
    props.highlight &&
    `
      font-family: ${fontFamilyHeading};
      color: ${colorClear}!important;
      bottom: 0;
      font-weight: normal !important;   
      margin: 0!important;  
      

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150%;
        border-radius: 8px;
        z-index: -1;
        background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.75));
      }
      `}

  ${Card} & {
    font-size: 18px;
    line-height: 22px;
  }

  ${ListColumn} & {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const FullTitle = styled.h5`
  font-family: ${fontFamilyHeading};
  font-size: 22px;
  line-height: 30px;
  color: ${colorTertiary} !important;
`;

export const Excerpt = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 8px;
`;

export const DateAndTime = styled.span`
  display: block;
  color: ${colorGrayLight};
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 16px;
  margin-bottom: 16px;
`;

export const Highlight = styled.section`
  margin-bottom: 24px;
  .highlight-column {
    display: flex;
    flex-direction: column;
  }
`;

export const SimpleList = styled.section`
  position: relative;
  // display: flex;
  // flex-wrap: wrap;
  margin: 0;

  ${NewsListItemStyled} {
    margin-bottom: 36px;

    ${ListColumn} & {
      margin: 0 0 16px;
      border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};

      &:last-child {
        border: none;
        margin: 0;
      }
    }
  }
`;

export const FullNewsStyled = styled.section`
  position: relative;
`;

export const DashboardNewsList = styled.section`
  min-height: 37rem;
  display: flex;
  align-items: center;
  justify-content: space-around; 
`;

export const More = styled.div`
  text-align: center;
  width: 100%;
  margin: 16px auto 32px;

  button {
    width: auto;
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    font-size: 14px;
    border: none;
    color: ${colorClear};
    background: ${props => props.backgroundColor};
    cursor: pointer;
    border-radius: 8px;
    transition: 0.2s background-color ease-out;

    &:hover {
      background-color: ${props =>
        rgba(props.backgroundColor, 0.86)}!important;
    }
  }
}
`;
