import styled from 'styled-components';
import RoundContainer from 'components/RoundContainer';
import { List, ListItem } from 'components/List';
import {
  DEFAULT_SIZE,
  breakpointDesktop,
  colorClear,
  colorGrayUltraLight,
  colorGrayLight,
  colorGrayLighter,
  colorGrayLightest,
  colorGrayDarker,
  colorTertiary,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

export const LogBookWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogBookStyled = styled.div`
  .side-box-content {
    & > .container-content {
      height: 100%;
    }

    ${List} {
      margin-top: 30px;
    }

    ${ListItem} {
      margin-bottom: 0;
      padding-bottom: 0;

      .top {
        align-self: flex-start;
        margin-top: 0;
      }

      &:first-of-type {
        &:before {
          display: none;
        }
      }
    }

    button.action {
      width: calc(100% - 42px);
    }
  }
`;

export const Container = styled.div`
  padding: ${DEFAULT_SIZE * 2}px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  max-width: 630px;

  .flock-details {
    width: 100%;
    /* background-color: ${colorClear}; */

    .flock-details-container {
      display: flex;
      flex-direction: column;
    }

    .flock-details-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .icon {
        font-size: 22px;
        color: ${colorGrayLighter};
      }
    }

    .flock-details-heading {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.15px;
      margin: 0;
    }

    .flock-details-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: ${colorGrayUltraLight};
      width: calc(100% + 32px);
      padding: 8px 16px;
      line-height: 32px;
      margin: -17px 0 -17px -16px;

      .icon-holder {
        margin-right: ${DEFAULT_SIZE * 2}px;
      }

      .flock-details-top-item {
        position: relative;   
        display: flex;
        justify-content: space-between;
        padding: 0 24px;         
        width: 33.3%;    
        
        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
        
        @media (min-width: ${breakpointDesktop}px) { 
          border-right: 1px solid ${colorGrayLightest};        
          
          &:last-of-type {
            border-right: none;
          }
        }

      }

      .flock-details-top-heading {
        color: ${colorGrayLight};
        margin-bottom: inherit;
        text-transform: uppercase;
        display: block;
        font-size: 10px;
        letter-spacing: 1.5px;
        
        @media (min-width: ${breakpointDesktop}px) {
          margin-right: 16px;
          text-transform: none;
          display: inline;
          font-size: 14px;
          letter-spacing: 0;
        }
      }

      .flock-details-top-label {
        font-style: normal;
        font-size: 14px;
        letter-spacing: 0.15px;
        color: ${colorGrayDarker};
      }
    }

    .flock-details-content {      

      .flock-details-content-heading {
        margin-bottom: ${DEFAULT_SIZE * 2}px;
      }
    }    
  }

  ${RoundContainer} {
    width: 100%;
  }  
`;

export const LogList = styled.section`
  overflow-y: ${props => (props.scrollable ? 'auto' : 'visible')};
  max-height: 400px;
  padding-right: 15px;
  width: calc(100% + 15px);
`;

export const Details = styled.div`
  .item-title {
    color: ${colorTertiary};
    font-family: ${fontFamilyTextBold};
  }
`;
