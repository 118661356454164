import React from 'react';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import { InputField, PasswordField } from 'components/Forms/Fields';
import t from 'components/i18n';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';

export default function PasswordRecoveryForm({
  setStage,
  user,
  history,
  handleSubmit,
  handleChange,
  handleBlur,
  resetForm,
  isValid,
  values,
  errors,
  touched,
}) {
  return (
    <Form autoComplete="off">
      <Row>
        <Col md={12}>
          <h4>{t('menu.profile.accountData.recoveryPassword.newPassword.subtitle')} {user.name}</h4>
          <p className="info">
            {t('menu.profile.accountData.recoveryPassword.newPassword.description')}
          </p>

          <br />

          <PasswordField
            field="newPassword"
            label={t('menu.profile.accountData.recoveryPassword.newPassword.title')}
            placeholder={t('menu.profile.accountData.recoveryPassword.newPassword.placeholder')}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />

          <PasswordField
            field="passwordConfirmed"
            label={t('menu.profile.accountData.recoveryPassword.newPassword.confirm-password')}
            placeholder={t('menu.profile.accountData.recoveryPassword.newPassword.confirm-password.placeholder')}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>

      <Separator />

      {/* \ Botões */}
      <BtnGroup>
        <Button
          key="submit"
          onClick={() => {
            handleSubmit();
          }}
          disabled={!isValid}
        >
          {t("global.button.save")}
        </Button>
      </BtnGroup>
      {/* \ Buttons */}
    </Form>
  );
}
