import { API_SUPERVISOR_URL } from 'config/constants';
import { request } from 'shared/utils/client';
import { fixNestedNamesRecursively } from 'shared/utils/Helpers/capitalizeName';

export async function getLogBookItems(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/GetLogBookItemsByFilters`,
    {
      method: 'GET',
      payload,
    },
  );
  return response.data;
}

export async function getProducersForOperationalUser() {
  const response = await request(
    `${API_SUPERVISOR_URL}/Producer/GetProducersForOperationalUser`,
    {
      method: 'GET'
    },
  );
  return {
    ...response,
    data: fixNestedNamesRecursively(response.data, 'vendorName'),
  };
}

export async function getLogBookSegment(animalType) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/GetLogBookSegment?animalType=${animalType}`,
    {
      method: 'GET',
    },
  );

  return response;
}

export async function getLogBookConfig(
  centerId,
  animalType,
  materialType,
  animalQuantity,
) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/GetLogBookConfig?centerId=${centerId}&animalType=${animalType}&materialType=${materialType}&animalQuantity=${animalQuantity}`,
    {
      method: 'GET',
    },
  );

  return response;
}

export async function getLogBookConfigDefault(
  animalType,
  materialType,
  animalQuantity = null,
) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/GetLogBookConfigDefault?&animalType=${animalType}&materialType=${materialType}&animalQuantity=${animalQuantity ||
      ''}`,
    {
      method: 'GET',
    },
  );

  return response;
}

export async function saveLogBookConfig(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/SaveLogBookConfig`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}
