import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  colorClear,
  colorGrayDark,
  breakpointTablet,
  breakpointDesktop,
} from 'components/GlobalStyles/variables';
import rgba from 'polished/lib/color/rgba';

// const { responsive } = utils;

export const Container = styled.div`
  display: flex;
  overflow: hidden;

  .hamburger-button {
    margin-right: 22px;
  }

  /* .sidebar-footer {
    display: flex;
    margin-top: auto;
    height: ${DEFAULT_SIZE * 7}px;
    border-top: 1px solid ${rgba(colorClear, 0.56)};

    .sidebar-footer-link {
      display: flex;
      width: 100%;
      padding: 0 ${DEFAULT_SIZE * 3}px;
      cursor: pointer;
      transition: background-color 0.25s ease-in-out;

      &:hover {
        background-color: ${colorGrayDark};
        .icon-sign-out,
        .label {
          color: ${colorClear};
        }
      }
    }
  }

  .logout {
    display: flex;
    align-items: center;
    .icon-sign-out,
    .label {
      color: ${rgba(colorClear, 0.56)};
      letter-spacing: 0.75px;
    }

    .icon-sign-out {
      margin-right: ${DEFAULT_SIZE}px;
      font-size: 16px;
    }

    .label {
      font-size: 13px;
      display: none;

      @media (min-width: ${breakpointDesktop}px) {
        display: inherit;
      }
    }
  } */
`;

export const Main = styled.div`
  position: relative;
  max-height: calc(100vh - 64px);
  overflow: ${props => (props.menuIsOpen ? 'hidden' : 'auto')};
  padding-bottom: 50px;

  // @media (min-width: ${breakpointTablet}px) {
  //   max-height: calc(100vh - 64px);
  // }
`;

export const Content = styled.div`
  flex: 1;
  height: 100vh;
`;
