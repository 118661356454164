import React, { useState, useEffect } from 'react';
import LoadingHolder from 'components/LoadingHolder';
import Container from 'components/Container';
import NoContentTab from 'components/NoContentTab';
import { getShortTermsOfUse } from '../../service';
import {
  PolicyWrapper,
  Separator,
} from '../../ProfileAndSettings.styled';
import t from 'components/i18n';

export default function PolicyExcerpt({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await getShortTermsOfUse();
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  return (
    <Container className="container" slim>
      <h4>{t('menu.profile.sectionLegalInformation.buttonPrivacyPolicySummary')}</h4>

      <Separator />
      <LoadingHolder isLoading={isLoading} />

      {!isLoading && data && (
        <PolicyWrapper
          dangerouslySetInnerHTML={{ __html: data.term }}
        />
      )}

      {!isLoading && !data && (
        <NoContentTab icon="icon-file">
          <p>
            {t('dashboard.lgpd.error-on-fetch')}
            <br /> {t('dashboard.lgpd.error-on-fetch.try-again-later')}
          </p>
        </NoContentTab>
      )}
    </Container>
  );
}
