import React, { memo } from 'react';
import { Pagination } from '@zendeskgarden/react-pagination';

function PaginationComponent({
  totalPages,
  currentPage,
  pagePadding,
  onChangePage,
}) {
  return (
    <Pagination
      totalPages={totalPages}
      currentPage={currentPage}
      pagePadding={pagePadding}
      onChange={e => onChangePage({ e })}
    />
  );
}

export default memo(PaginationComponent);
