import t from 'components/i18n';
import { indexOf } from 'lodash';

export const LogBookStatus = [
  {
    status: 0,
    label: t('next.weekSchedule.utils.logBookStatus.foreseen'),
    color: 'colorGrayLight'
  },
  {
    status: 1,
    label: t('next.weekSchedule.utils.logBookStatus.accomplished'),
    color: 'colorSuccess',
    icon: 'icon-check-circle',
  },
  {
    status: 2,
    label: t('next.weekSchedule.utils.logBookStatus.unrealized'),
    color: 'colorDanger',
    icon: 'icon-close-circle',
  },
  {
    status: 3,
    label: t('next.weekSchedule.utils.logBookStatus.unrealized'),
    color: 'colorDanger',
    icon: 'icon-close-circle',
  },
  {
    status: 4,
    label: t('next.weekSchedule.utils.logBookStatus.overdue'),
    color: 'colorInfo',
  },
];

export function getStatus(item) {
  return LogBookStatus.find(e => e.status === item);
}

export const tableHeader = [
  {
    width: 25,
    title: t('next.weekSchedule.table.header.user'),
    alignLeft: true,
  },
  {
    width: 15,
    title: t('next.weekSchedule.table.header.flock'),
  },
  {
    width: 15,
    title: t('next.weekSchedule.table.header.date'),
  },
  {
    width: 15,
    title: t('next.weekSchedule.table.header.visit-type'),
  },
  {
    width: 15,
    title: t('next.weekSchedule.table.header.status'),
  },
  {
    width: 15,
    title: t('next.weekSchedule.table.header.justification'),
  },
];

export const formatVisitName = name => {
  const searched = 'Visita Técnica -';
  if (indexOf(searched) > -1) {
    return name.replace(searched, '');
  }

  return name;
};
