import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import Box from 'components/Container';
import Card from 'components/Card';
import DateAndTime from 'components/DateAndTime';
import NoContentTab from 'components/NoContentTab';
import LoadingHolder from 'components/LoadingHolder';
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import { ORIENTATION } from 'config/routes';
import t from 'components/i18n';

export default function OrientationList({
  arrayContent,
  isLoading,
  history,
  title,
}) {
  const getFooter = useCallback(feed => {
    if (feed.read) {
      return `Confirmação de leitura:
          ${moment(feed.readDate).format('DD ̸̸  MM ̸̸  YYYY • HH:mm')}`;
    }

    if (!feed.read) {
      return 'Confirmação de leitura pendente';
    }
  }, []);

  const getFooterIcon = useCallback(feed => {
    return `icon  ${feed.read ? 'icon-check' : 'icon-clock'}`;
  }, []);

  const getFlockContent = useMemo(() => {
    return (
      <div className="flock-details-content">
        {!arrayContent.length && (
          <NoContentTab icon="icon-calendar">
            <p>
              {t('flocks.view.next.empty.title')} <br />{' '}
              <span className="lighter">
                {t('global.empty.list')}
              </span>
            </p>
          </NoContentTab>
        )}

        {arrayContent.length > 0 &&
          arrayContent.map((feed, index) => {
            return (
              <Card
                key={`orientation-${feed.id}`}
                onClick={() =>
                  history.push(`${ORIENTATION}/${feed.id}`)
                }
                footerContent={getFooter(feed)}
                footerIcon={getFooterIcon(feed)}
                footerColor={feed.read ? 'colorSuccess' : 'colorInfo'}
                whiteBg
              >
                <DateAndTime>
                  {moment(feed.visitDate).format(
                    'DD ̸̸  MM ̸̸  YYYY • HH:mm',
                  )}
                </DateAndTime>

                <p className="description">{feed.orientation}</p>
              </Card>
            );
          })}
      </div>
    );
  }, [arrayContent, getFooter, getFooterIcon, history]);

  return (
    <div className="flock-details ">
      <Box slim>
        <div className="flock-details-container animated fadeIn">
          <header className="flock-details-header">
            <h2 className="flock-details-heading">{title}</h2>
          </header>
          <Separator />

          <LoadingHolder isLoading={isLoading} />

          {!isLoading && getFlockContent}
        </div>
      </Box>
    </div>
  );
}
