import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  memo,
} from 'react';
import { ThemeContext } from 'styled-components';
import { NewModalFilter, DropdownFilter } from 'components/Filter';
import {
  dateFilterList,
  getDateLabel,
} from 'components/Filter/FiltersUtils';
import {
  Close,
  RoundedItem,
} from 'components/MultiSelect/Items.styled';
import t from 'components/i18n';

function SideboxFilter(props) {
  const {
    activeFilters,
    setActiveFilters,
    filters,
    setDateFilter,
    disabled
  } = props;

  const [openCalendar, setOpenCalendar] = useState(false);
  const [dateFilterInside, setDateFilterInside] = useState(null);

  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    if (setDateFilter !== undefined) {
      setDateFilter(dateFilterInside);
      // setActiveFilters([...activeFilters]);
    }
  }, [dateFilterInside, setDateFilter]);

  const dateFilter = useMemo(
    () =>
      !dateFilterInside ? (
        <DropdownFilter
          buttonLabel={t('global.button.filter.date')}
          list={dateFilterList}
          arrayItem="date"
          keysToCompare={['startDate', 'endDate']}
          onSelectItemAction={setDateFilterInside} //
          openCalendar={openCalendar}
          setOpenCalendar={setOpenCalendar}
          clearFilters={dateFilterInside === null}
          dark
        />
      ) : (
        <div style={{ display: 'flex' }}>
          {' '}
          <RoundedItem className="sidebox-filter__item">
            <p>
              {getDateLabel(dateFilterInside.startDate)} -{' '}
              {getDateLabel(dateFilterInside.endDate)}
            </p>
            <Close
              onClick={() => {
                setDateFilterInside(null);
              }}
            >
              <span className="icon icon-close-circle" />
            </Close>
          </RoundedItem>
        </div>
      ),
    [dateFilterInside, openCalendar],
  );

  const fixedFilters = useMemo(
    () => filters.filter(filter => filter.alwaysShow),
    [filters],
  );

  const renderSelectedFilters = useMemo(
    () =>
      activeFilters.length > 0 &&
      activeFilters
        .filter(x => x.label !== 'date')
        .filter(
          filter =>
            !fixedFilters.find(
              fixedFilter => fixedFilter.label === filter.label,
            ),
        )
        .map(
          (item, i) =>
            item && (
              <RoundedItem className="sidebox-filter__item" key={i}>
                <p>
                  {item.txt.length > 20
                    ? `${item.txt.substring(1, 20)}...`
                    : item.name || item.txt}
                </p>
                <Close
                  onClick={el =>
                    setActiveFilters(
                      activeFilters.filter(x => x !== item),
                    )
                  }
                >
                  <span className="icon icon-close-circle" />
                </Close>
              </RoundedItem>
            ),
        ),
    [activeFilters, fixedFilters, setActiveFilters],
  );

  const renderList = useMemo(
    () => filters.filter(filter => !filter.alwaysShow),
    [filters],
  );

  const renderDropdown = useMemo(
    () =>
      fixedFilters.length > 0 &&
      fixedFilters.map(item => {
        const activeOption = activeFilters.find(
          activeFilter => activeFilter.label === item.label,
        );

        return (
          <DropdownFilter
            controledLabel={t(item.label)}
            controledValue={activeOption ? activeOption.txt : ''}
            list={item.items.map(option => ({
              label: t(option.txt),
            }))}
            commonMode
            onSelectItemAction={selected =>
              setActiveFilters([
                ...activeFilters.filter(
                  activeFilter =>
                    activeFilter.label !== selected.label,
                ),
                {
                  ...selected,
                },
              ])
            }
            dark
          />
        );
      }),
    [activeFilters, fixedFilters, setActiveFilters],
  );

  const renderModalFilter = useMemo(
    () => (
      <NewModalFilter
        theme={{
          bgButton: themeContext.styles.palette.colorPrimary,
          text: themeContext.styles.palette.colorClear,
        }}
        title={t('sidebox-filter.filter.title')}
        buttonLabel={t('sidebox-filter.more-filters.title')}
        applyFilters={setActiveFilters}
        list={renderList}
        activeFilters={activeFilters}
        disabled={disabled}
        clearFilters={() => {
          setActiveFilters([]);
        }}
      />
    ),
    [activeFilters, renderList, setActiveFilters, themeContext],
  );

  return (
    <>
      {setDateFilter !== undefined && dateFilter}

      {renderDropdown}

      {renderModalFilter}

      {renderSelectedFilters}
    </>
  );
}

export default memo(SideboxFilter);
