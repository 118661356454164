import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  DEFAULT_SIZE,
  fontFamilyTextBold,
  colorClear,
  colorGrayUltraLight,
  colorGrayDarkest,
  colorGrayDarker,
  colorTertiary,
  colorGrayLight,
  colorGrayLightest,
  fontSizeSmall,
  fontSizeNormal,
  colorDanger,
  colorInfo,
} from 'components/GlobalStyles/variables';

export const StyledKeyValueTable = styled.div`
  background-color: ${colorClear};
  border: 1px solid ${colorGrayLightest};
  border-radius: ${DEFAULT_SIZE}px;
  overflow: hidden;
  margin-top: 20px;
  padding: ${props => (props.hasStatusRow ? '8px 0 0' : '8px 0')};
`;

export const RowGroup = styled.div`
  margin: 0 8px;
  display: flex;
  &:not(:last-of-type) {
    border-bottom: ${({ isLastRow }) =>
    isLastRow ? '' : `1px solid ${rgba(colorGrayDarkest, 0.16)}`};
  }
`;

export const KeyValueItem = styled.div`
  flex: 1;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-of-type) {
    border-right: 1px solid ${rgba(colorGrayDarkest, 0.16)};
  }
  .item-label {
    color: ${colorGrayLight};
    font-size: ${fontSizeSmall};
  }
  .item-value {
    color: ${colorGrayDarker};
    font-size: ${fontSizeNormal};
  }
`;

export const StatusRow = styled.div`
  background: ${colorGrayUltraLight};
  border-top: 1px solid ${rgba(colorGrayDarkest, 0.16)};
  padding: 12px 20px;
  color: ${props => (props.danger ? colorDanger : colorInfo)};
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: ${fontSizeSmall};

  .icon.status {
    font-size: 18px;
    margin: -2px 8px 0 0;
  }
`;

export const Wrapper = styled.div`
  padding: 12px 16px;
  background-color: ${colorClear};

  button.white {
    color: ${colorTertiary}!important;
    font-family: ${fontFamilyTextBold};
  }

  p.description {
    margin: 0;
    font-size: 14px;
  }

  .children {
    width: 100%;

    .icon {
      color: ${colorTertiary};
      float: right;
      display: block;
      font-size: 20px;
    }
  }
`;

export const Footer = styled.footer`
  border: 1px solid ${rgba(colorGrayDarkest, 0.12)};
  padding: ${DEFAULT_SIZE}px ${DEFAULT_SIZE * 2}px;
  background: ${colorGrayUltraLight};

  p.footer-p {
    font-family: ${fontFamilyTextBold};
    margin: 0;
    font-size: 12px;
    line-height: 21px;
    color: ${props => props.footerColor};

    span {
      font-size: 20px;
      margin: 0 4px 0 0;
      position: relative;
      top: 2px;
    }
  }
`;
