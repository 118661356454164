import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import * as yup from 'yup';
import { WEEK_SCHEDULE } from 'config/routes';
import { Formik } from 'formik';
import Top from 'components/Top';
import Breadcrumb from 'components/Breadcrumb';
import IconAndText from 'components/IconAndText';
import SettingsForm from 'containers/NextBRF/Forms/SettingsForm';
import validate from 'components/Forms/Fields/validate';
import SubmitModal from './SubmitModal';

// validate
import {
  getLogBookSegment,
  getLogBookConfig,
  getLogBookConfigDefault,
  saveLogBookConfig,
} from './service';
import { ScheduleStyled, Container } from './WeekSchedule.styled';
import t from 'components/i18n';

const validateSchema = props =>
  yup.object().shape({
    isSwine: yup.boolean(),
    animalType: yup
      .string()
      .nullable()
      .required(t('global.status.required-field')),
    centerId: yup
      .string()
      .nullable()
      .required(t('global.status.required-field')),
    materialType: yup
      .string()
      .nullable()
      .required(t('global.status.required-field')),
    animalQuantity: yup
      .string()
      .nullable()
      .when('isSwine', (isSwine, schema) =>
        isSwine ? schema.required(t('global.status.required-field')) : schema,
      ),
  });

export default function Settings(props) {
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [segments, setSegments] = useState([]);
  const [configData, setConfigData] = useState(null);
  const [step, setStep] = useState(1);
  const [pageTitle, setPageTitle] = useState(1);
  const [canEdit, setCanEdit] = useState(false);
  const [author, setAuthor] = useState(null);
  const [customVisits, setCustomVisits] = useState([]);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getSegmentData = useCallback(async animalType => {
    try {
      const response = await getLogBookSegment(animalType);
      setSegments(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getLogBookConfigData = useCallback(
    async (
      centerId,
      animalType,
      materialType,
      animalQuantity = null,
    ) => {
      setIsLoading2(true);
      try {
        const config = await getLogBookConfig(
          animalType,
          centerId,
          materialType,
          animalQuantity || '',
        );

        if (!config.success) {
          setErrorMessage(config.message);
        }

        setConfigData(config.data);
      } catch (error) {
        console.log(error.message);
      }
      setIsLoading2(false);
    },
    [],
  );

  const getLogBookConfigDefaultData = useCallback(
    async (animalType, materialType, animalQuantity) => {
      setIsLoading2(true);
      try {
        const config = await getLogBookConfigDefault(
          animalType,
          materialType,
          animalQuantity,
        );
        setConfigData(config.data);
      } catch (error) {
        console.error(error);
      }
      setIsLoading2(false);
    },
    [],
  );

  useEffect(() => {
    if (
      configData &&
      configData.logBookConfigItems &&
      configData.logBookConfigItems.length > 0
    ) {
      setStep(2);
      setCustomVisits([]);
    }
  }, [configData]);

  useEffect(() => {
    if (step === 1) {
      setAuthor(null);
    }
  }, [step]);

  const getLogBookConfigItems = useMemo(() => {
    if (customVisits && customVisits.length > 0) {
      return [
        ...(configData && configData.logBookConfigItems),
        ...customVisits,
      ];
    }

    return configData && configData.logBookConfigItems;
  }, [configData, customVisits]);

  const initialValues = {
    isSwine: false,
    animalType: null,
    centerId: null,
    materialType: null,
    animalQuantity: null,
  };

  const formData = values => ({
    animalType: values.animalType && values.animalType.value,
    centerId: values.centerId && values.centerId.centerId,
    materialType: values.materialType && values.materialType.value,
    isDefault: values.isDefault,
    isCustomized: values.isCustomized,
    logBookConfigItems: getLogBookConfigItems.map(item => {
      const logbookId = `logbookItem_${item.logBookItemTypeId}`;
      let itemId = item.id;

      if (
        values.isDefault &&
        (item.isEnable !== values[`${logbookId}_isEnable`] ||
          item.daysStart !==
            Number(values[`${logbookId}_daysStart`]) ||
          item.daysEnd !== Number(values[`${logbookId}_daysEnd`]))
      ) {
        itemId = 0;
      }

      return {
        id: itemId,
        logBookItemTypeId: item.logBookItemTypeId,
        logBookItemType: item.logBookItemType,
        isRequired: values[`${logbookId}_isRequired`],
        isEnable: values[`${logbookId}_isEnable`],
        isCustomized: values[`${logbookId}_isCustomized`],
        daysStart: Number(values[`${logbookId}_daysStart`]),
        daysEnd: Number(values[`${logbookId}_daysEnd`]),
        daysStartLimit: item.daysStartLimit,
        daysEndLimit: item.daysEndLimit,
      };
    }),
  });

  function submitForm(
    values,
    { setSubmitting, setErrors, isValidating },
  ) {
    formData(values);

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await saveLogBookConfig(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setConfigData(response.data);
        setSuccess(true);
        setAuthor({
          name: response.data.userName,
          id: response.data.userId,
          date: response.data.createDate,
        });

        getLogBookConfigData(
          val.animalType,
          val.centerId,
          val.materialType,
          val.animalQuantity ? val.animalQuantity.value : '',
        );
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }

    sendData(formData(values));
  }

  const getLinks = useMemo(() => {
    const paths = [WEEK_SCHEDULE];

    if (step === 3) {
      paths.push(() => setStep(2));
    }

    return paths;
  }, [step]);

  const getPaths = useMemo(() => {
    const paths = [
      t('next.weekSchedule.settings.logbook.title'),
      t('next.weekSchedule.settings.step1.title')
    ];

    if (step === 3) {
      paths.push(t('next.weekSchedule.settings.step3.title'));
    }

    return paths;
  }, [step]);

  return (
    <>
      <Breadcrumb
        history={props.history}
        backTo={() => setStep(2)}
        links={getLinks}
        path={getPaths}
      />
      <Top>
        {step === 1 && <h2>{t('next.weekSchedule.settings.step1.title')}</h2>}

        {step === 2 && (
          <IconAndText
            title={pageTitle}
            subtitle={configData.materialType}
            icon="icon-calendar"
            iconBackGround="colorPrimary"
          />
        )}

        {step === 3 && <h2>{t('next.weekSchedule.settings.step3.title')}</h2>}
      </Top>
      <ScheduleStyled>
        <Container className="maxWidth">
          <Formik
            initialValues={initialValues}
            validate={validate(validateSchema)}
            onSubmit={submitForm}
            render={formikProps => (
              <SettingsForm
                {...formikProps}
                message={errorMessage}
                isLoading={isLoading1}
                setIsLoading={setIsLoading1}
                step={step}
                setStep={setStep}
                canEdit={canEdit}
                setCanEdit={setCanEdit}
                setPageTitle={setPageTitle}
                getSegmentData={getSegmentData}
                customVisits={customVisits}
                setCustomVisits={setCustomVisits}
                isLoadingSecondForm={isLoading2}
                getLogBookConfigData={getLogBookConfigData}
                getLogBookConfigDefaultData={
                  getLogBookConfigDefaultData
                }
                configData={configData}
                segments={segments}
                author={author}
                setAuthor={setAuthor}
                success={success}
                {...props}
              />
            )}
          />
        </Container>

        {/* On submit modal */}
        <SubmitModal
          success={success}
          errorMessage={errorMessage}
          setSuccess={setSuccess}
          setErrorMessage={setErrorMessage}
          setCanEdit={setCanEdit}
          history={props.history}
        />
        {/* \ On submit modal */}
      </ScheduleStyled>
    </>
  );
}
