import styled from 'styled-components';
import { rgba } from 'polished';
import {
  fontFamilyTextBold,
  colorGrayLightest,
} from 'components/GlobalStyles/variables';

export const GrainStyled = styled.section`
  padding: 20px;
  max-width: 692px;

  p {
    font-size: 14px;
  }

  .center {
    justify-content: center;
    padding: 66px 0 295px;
  }

  .container-content {
    background-position: left bottom;
  }
`;

export const Subheading = styled.h3`
  font-size: 16px;
  line-height: 24px;
`;

export const GrayWrapper = styled.article`
  background-color: ${rgba(colorGrayLightest, 0.25)};
  padding: 16px;
  border-radius: 12px;
`;

export const ContactList = styled.ul`
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    p {
      margin: 0;
    }

    .state {
      font-family: ${fontFamilyTextBold};
      font-size: 10px;
      text-transform: uppercase;
    }

    .name {
      font-size: 16px;
      line-height: 24px;
      font-family: ${fontFamilyTextBold};
    }

    .phone {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const Separator = styled.hr`
  margin: 10px 0 15px 0;
  opacity: 0.12;
`;
