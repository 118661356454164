import React, { useState } from 'react';
import uuid from 'uuid/v4';
import * as yup from 'yup';
import { Formik } from 'formik';
import NewVisitForm from 'containers/NextBRF/Forms/NewVisitForm';
import validate from 'components/Forms/Fields/validate';
import t from 'components/i18n';

const validateSchema = props =>
  yup.object().shape({
    logBookItemType: yup
      .string()
      .nullable()
      .required(t('global.status.required-field')),
    anotherTypeOfVisit: yup
      .string()
      .nullable()
      .when('logBookItemType', (logBookItemType, schema) =>
        logBookItemType &&
        logBookItemType.label === 'Outro tipo de visita'
          ? schema.required(t('global.status.required-field'))
          : schema,
      ),
    daysStart: yup
      .string()
      .nullable()
      .required(t('global.status.required-field')),
    daysEnd: yup
      .string()
      .nullable()
      .required(t('global.status.required-field')),
  });

export default function NewVisit(props) {
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const initialValues = {
    anotherTypeOfVisit: null,
    logBookItemType: null,
    daysStart: null,
    daysEnd: null,
    isCustomized: true,
  };

  const formData = values => ({
    visitId: uuid(),
    logBookItemType: values.anotherTypeOfVisit
      ? t('next.weekSchedule.newVisit.form.tecnical-visit', { value: values.anotherTypeOfVisit })
      : t('next.weekSchedule.newVisit.form.tecnical-visit', { value: values.logBookItemType.label }),
    daysStartLimit: Number(values.daysStart),
    daysStart: values.daysStart,
    daysEnd: values.daysEnd,
    daysEndLimit: Number(values.daysEnd),
    isEnable: true,
    isCustomized: true,
  });

  function submitForm(
    values,
    { setSubmitting, setErrors, isValidating },
  ) {
    formData(values);

    async function sendData(val) {
      setSubmitting(true);
      try {
        props.setCustomVisits([...props.customVisits, val]);
        setSuccess(true);
        props.setStep(2);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }
    sendData(formData(values));
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={validate(validateSchema)}
      onSubmit={submitForm}
      render={formikProps => (
        <NewVisitForm {...formikProps} {...props} />
      )}
    />
  );
}
