import styled from 'styled-components';
import {
  colorClear,
  DEFAULT_SIZE,
  breakpointDesktop,
  breakpointTablet,
} from 'components/GlobalStyles/variables';
import agroBgDetail from 'shared/assets/images/background-detail.png';
import nextBgDetail from 'shared/assets/next/background-detail.png';
import ContainerBox from 'components/Container';

function getColorsByUser(props) {
  if (props.isNextUser) {
    return {
      img: nextBgDetail,
      bgColor: `linear-gradient(0deg,rgb(41, 56, 150) -50%,rgb(64, 126, 201) 100%)`,
    };
  }
  return {
    img: agroBgDetail,
    bgColor: ` linear-gradient(0deg, rgba(147,50,142,1) -50%, rgba(210,38,46,1)  100%)`,
  };
}


export const DashboardContainer = styled.div`  
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .dashboard-item__main {
    width: 65%;
    min-width: 65%;

    &.news {
      .container-content {
        height: 47.5rem;
      }
    }
  }

  .dashboard-item__secondary {
    width: 100%;
  }

  @media (min-width: ${breakpointTablet}px) {
    /* flex-direction: row; */
  }

  .dashboard-top {
    position: relative;
    /* margin-bottom: ${DEFAULT_SIZE * 2}px; */
    padding: ${DEFAULT_SIZE * 2}px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 96px;
    background:  ${props => getColorsByUser(props).bgColor};    
    z-index: 0;

    &::before {
      position: absolute;
      content: '';
      background: url(${props => getColorsByUser(props).img}) no-repeat 0 0;
      top: 0;
      left: 0;
      width: 45%;
      height: 100%;
      min-width: 200px;
      background-size: cover !important;
      z-index: -1;
    }

    @media (min-width: ${breakpointTablet}px) {
      flex-direction: row;
      padding-left: ${DEFAULT_SIZE * 3}px;
      padding-right: ${DEFAULT_SIZE * 3}px;

      .dashboard-header {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }

  .dashboard-header {
    .welcome-label {
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1.5px;
      color: ${colorClear};
    }

    .provider {
      font-size: 24px;
      line-height: 27px;
      letter-spacing: 0.15px;
      margin: 0;
      color: ${colorClear};
    }

    &.secondary {
      // width: 316px;
    }
  }

  .dashboard-content {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    padding: ${DEFAULT_SIZE * 2}px;
    padding-bottom: 40px;

    @media (min-width: ${breakpointTablet}px) {
      padding-left: ${DEFAULT_SIZE * 3}px;
      padding-right: ${DEFAULT_SIZE * 3}px;
    }

    @media (min-width: ${breakpointDesktop}px) {
      flex-direction: row;
    }
  }

  .secondary {
    // width: 316px;
  }

  .dashboard-main {
    flex: 1;
    width: 100%;
    max-width: 780px;
  }

  .dashboard-item {
    @media (min-width: ${breakpointDesktop}px) { 
      margin: 0;

      &.dashboard-secondary {
        margin-right: 0;
      }
    }
  }

  .dashboard-secondary {
    display: flex;
    width: 100%;
    height: 550px;
    gap: ${DEFAULT_SIZE * 2}px;

    @media (max-width: ${breakpointTablet}px) { 
      flex-direction: column;
      height: unset;
    }

    .scheduling-feed {
      @media (max-width: ${breakpointTablet}px) { 
        width: 100%;
      }

      .scheduling-feed__wrapper {
        height: 44.25rem;
      }

      .scheduling-feed__placeholder {
        align-items: center;
        justify-content: space-between;
        display: flex;
        cursor: pointer;

        span {
          margin-left: 1rem;
        }
      }

      .scheduling-feed__container {
        height: 90%;
        overflow: hidden;

        &.compact {
          justify-content: space-around;
        }

        @media (max-width: ${breakpointTablet}px) { 
          flex-direction: column;
        }
      }
    }

    .announcements {
      margin-top: -20px;

      @media (max-width: ${breakpointTablet}px) { 
        width: 100%;
      }

      .announcements-list {
        height: 371px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .container-content {
        border-radius: 12px 12px 0;
        margin-bottom: 0;
        border-bottom: none;
        box-shadow: none;
        overflow-x: clip;
        overflow-y: auto;
      }

      .announcements-list-wrapper {
        padding-bottom: 2rem;
        box-shadow: 0px 0px 16px rgb(37 48 71 / 8%);

        @media (max-width: ${breakpointTablet}px) { 
          margin: 0;
        }

        .announcements-list__loading {
          height: 371px;
          justify-content: center;
        }
      }
    }
  }
`;

export const Box = styled(ContainerBox)`
  margin: 0 -1.65rem;

  h2 {
    font-size: 20px;
  }
`;

export const Separator = styled.hr`
  margin: 11px -16px 0;
  opacity: 0.12;
`;
