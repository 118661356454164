// @flow
import React, { useState, useEffect, useCallback } from 'react';
import Icon from 'components/Icon';
import * as appRoutes from 'config/routes';
import { Container } from './Navigator.styled';
import t from 'components/i18n';

function getRouteByType(type) {
  const routes = {
    home: appRoutes.HOME,
    cadec: appRoutes.CADEC,
    flock: appRoutes.FLOCK_LIST,
    news: appRoutes.NEWS_LIST,
    notice: appRoutes.ANNOUNCEMENTS,
    library: appRoutes.LIBRARY,
  };

  return routes[type];
}

function getButtonInfo(type) {
  const props = {
    home: {
      label: t("menu.buttonSingleLine.home"),
      icon: 'icon-home',
    },
    cadec: {
      label: t('menu.buttonSingleLine.cadec'),
      icon: 'icon-users',
    },
    flock: {
      label: t('menu.buttonSingleLine.flockList'),
      icon: 'icon-flock',
    },
    news: {
      label: t("menu.buttonSingleLine.news"),
      icon: 'icon-news',
    },
    notice: {
      label: t('menu.buttonSingleLine.notifications'),
      icon: 'icon-notice',
    },
    library: {
      label: t('menu.buttonSingleLine.library'),
      icon: 'icon-library',
    },
  };

  return props[type];
}

function NavigatorButton({ type, history }) {
  const [activeClass, setActiveClass] = useState('');
  const route = getRouteByType(type);
  const activePath = history.location.pathname;
  const info = getButtonInfo(type);

  const activeClassSetter = useCallback(() => {
    if (activePath.indexOf(route) !== -1) {
      setTimeout(() => {
        setActiveClass('active');
      }, 50);
    } else {
      setActiveClass('');
    }
  }, [activePath, route]);

  function onClick() {
    history.push(route);
  }

  useEffect(() => {
    activeClassSetter();
  }, [activeClassSetter]);

  return (
    <button
      onClick={onClick}
      type="button"
      className={`unstyled-button navigator-icon-holder ${activeClass}`}
    >
      <Icon className="navigator-icon" name={info.icon} />
      <span className="navigator-icon-label">{info.label}</span>
    </button>
  );
}

export default function Navigator({ history, user = {} }) {
  return (
    <Container>
      <NavigatorButton type="home" history={history} />
      <NavigatorButton type="cadec" history={history} />
      <NavigatorButton type="news" history={history} />
      <NavigatorButton type="flock" history={history} />
      {/* <NavigatorButton type="notice" history={history} />
      <NavigatorButton type="library" history={history} /> */}
    </Container>
  );
}
