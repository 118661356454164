
import React from 'react';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { SearchContainer, MediaInput, Field } from './Components.styled';

const Search = (props) => {
  const {
    value,
    onChange
  } = props;

  const { t } = useTranslation();

  return (
    <SearchContainer sm={5}>
      <Field
        onChange={event => onChange(event.target.value)}
        value={value}
      >
        <MediaInput
          start={<Icon className="icon-search" size={25} />}
          placeholder={t("next.invoiceManagement.searchBar")}
        />
      </Field>
    </SearchContainer>
  );
};

export default Search;
