import React from 'react';
import t from 'components/i18n';

export default function NotFound() {
  return (
    <div className="Layout-error">
      <h2>{t('page.404.title')}</h2>
      <p>{t('page.404.description')}</p>
    </div>
  );
}
