import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';
import qs from 'qs';

export async function getCadecInfos() {
  const response = await request(`${API_URL}/Cadec/GetCadecInfos`, {
    method: 'GET',
  });
  return response.data;
}

export async function getAll() {
  const response = await request(`${API_URL}/Cadec/GetAll`, {
    method: 'GET',
  });
  return response.data;
}

export async function getCadecs(params) {
  const response = await request(
    `${API_URL}/Cadec/GetCadecs?${qs.stringify(params)}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getRegionalsWithCenters() {
  const response = await request(
    `${API_URL}/UserCorporate/GetRegionalsWithCenters`,
    {
      method: 'GET',
    },
  );

  return response;
}

export async function getAllCenters() {
  const response = await request(`${API_URL}/Cadec/GetAllCenters`, {
    method: 'GET',
  });

  return response;
}

export async function getCadecPagedDocuments(params) {
  const response = await request(
    `${API_URL}/CadecDocument/GetPagedListCadecDocuments?${qs.stringify(
      params,
    )}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function saveCadec(payload) {
  const response = await request(`${API_URL}/Cadec`, {
    method: 'POST',
    payload,
  });
  return response;
}

export async function deleteCadec(id) {
  const response = await request(
    `${API_URL}/Cadec/DeleteCadec?cadecID=${id}`,
    {
      method: 'DELETE',
    },
  );
  return response;
}

// Atas

export async function getAtaInfos() {
  const response = await request(
    `${API_URL}/CadecDocument/GetCadecDocumentInfos`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function saveAta(payload) {
  const response = await request(`${API_URL}/CadecDocument`, {
    method: 'POST',
    payload,
  });
  return response;
}

export async function getAllCadecDocuments() {
  const response = await request(
    `${API_URL}/CadecDocument/GetAllCadecDocuments`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getCadecDocuments() {
  const response = await request(
    `${API_URL}/CadecDocument/GetCadecDocuments`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function deleteAta(id) {
  const response = await request(
    `${API_URL}/CadecDocument?id=${id}`,
    {
      method: 'DELETE',
    },
  );
  return response;
}
