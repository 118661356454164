import React, { useCallback } from 'react';
import Top from 'components/Top';
import Container from 'components/Container';
import Icon from 'components/Icon';
import { Circular } from 'components/CircleAndTitle/CircleAndTitle.styled';
import Logo from './assets/logo_bb.png';
import QrCode from './assets/qrcode.png';
import { links } from './utils';
import {
  BancoDoBrasilWrapper,
  Header,
  Title,
  Links,
  Bottom,
  Footer,
} from './BancoDoBrasil.styled';
import t from 'components/i18n';
import { useTranslation } from 'react-i18next';

export default function BancoDoBrasil({
  responsive: { isMobile, isGtTablet },
}) {
  const {t} = useTranslation();
  const notSimulationLinks = links.filter(item => !item.simulation);
  const simulationLinks = links.filter(item => item.simulation);

  const getLinkItem = useCallback((url, label) => {
    const translated = t(label);
    return (
      <li>
        <a href={url} target="_blank" rel="noreferrer">
          {translated.charAt(0).toUpperCase() +
            translated.slice(1).toLowerCase()}
          <Icon name="icon-chevron-right" />
        </a>
      </li>
    );
  }, []);

  return (
    <>
      <Top>
        <h2>{t('bb.partnership.title')}</h2>
      </Top>
      <BancoDoBrasilWrapper>
        <Header>
          <img src={Logo} alt={t('bb.partnership.title')} />
        </Header>

        <Container hideDetail>
          <Title>
            {t('bb.partnership.subtitle')}
          </Title>

          <Links>
            {isGtTablet &&
              links.map(item =>
                getLinkItem(
                  item.url,
                  isMobile ? item.mobile : item.title,
                ),
              )}

            {!isGtTablet && (
              <>
                {notSimulationLinks.map(item =>
                  getLinkItem(
                    item.url,
                    isMobile ? item.mobile : item.title,
                  ),
                )}

                <br />
                <br />

                <Title>{t('bb.partnership.simulateYourFinancing')}</Title>
                {simulationLinks.map(item =>
                  getLinkItem(item.url, item.mobile),
                )}
              </>
            )}
          </Links>

          {isGtTablet && (
            <Bottom>
              <div className="client">
                <h2>{t("bb.partnership.notClientYet.title")}</h2>

                <p>
                  {t("bb.partnership.openOnlineAccount.web")}
                </p>
              </div>

              <div className="qr-code">
                <img src={QrCode} alt="QR Code" />

                <p className="smaller">
                  {t('bb.partnership.qrCodeHint.web')}
                </p>
              </div>
            </Bottom>
          )}

          {!isGtTablet && (
            <Bottom>
              <div className="client">
                <h2>{t("bb.partnership.notClientYet.title")}</h2>

                <p>{t('bb.partnership.openOnlineAccount.mobile')}</p>
              </div>

              <div className="qr-code">
                <a
                  href="https://app.adjust.com/95cbp3j"
                  target="_blank"
                  rel="noreferrer"
                  className="app-button"
                >
                  {t("bb.partnership.qrCodeHint.mobile")}
                </a>
              </div>
            </Bottom>
          )}
        </Container>

        <Footer>
          {isGtTablet && (
            <Circular>
              <Icon name="icon-question" />
            </Circular>
          )}

          <div>
            <p>
              {t("bb.partnership.footer.doubts.tite")}{' '}
              <a
                href="http://bb.com.br"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                {t("bb.partnership.footer.doubts.href")}
              </a>
            </p>
            <p className="phone">4004 00001 / 0800 729 0001</p>
          </div>
        </Footer>
      </BancoDoBrasilWrapper>
    </>
  );
}
