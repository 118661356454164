import React, { useEffect, useState } from 'react';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { useSelector } from 'react-redux';
import LoadingHolder from 'components/LoadingHolder';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Form } from 'components/Forms/Forms.styled';
import {
  DropdownField,
  InputField,
  FileInput,
} from 'components/Forms/Fields';
import MultiSelect from 'components/MultiSelect';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';
import t from 'components/i18n';

export default function AddCadecForm(props) {
  const {
    selected,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    isUploading1,
    isUploading2,
    isLoading,
    handleFile1,
    removeFile1,
    handleFile2,
    removeFile2,
    values,
    errors,
    touched,
  } = props;

  const user = useSelector(state => state.user);

  useEffect(() => {
    if (selected.fileName1) {
      setFieldValue('fileName1', selected.fileName1);
    }

    if (selected.fileName2) {
      setFieldValue('fileName2', selected.fileName2);
    }
  }, [selected.fileName1, selected.fileName2, setFieldValue]);

  return (
    <>
      <LoadingHolder
        isLoading={isLoading}
        message={t('global.loading')}
      />
      {!isLoading && (
        <Form autoComplete="off">
          {props.unities && (
            <>
              <DropdownField
                field="centerList"
                label={t("addCadec.form.centerList.label")}
                placeholder={t("addCadec.form.centerList.placeholder")}
                onSelect={item => {
                  setFieldValue('centerList', item);
                  setFieldValue('segmentList', []);
                  setFieldValue('segmentType', []);
                }}
                options={props.unities}
                optionLabel="name"
                values={values}
                errors={errors}
                touched={touched}
              />

              <DropdownField
                field="segmentList"
                label={t('addCadec.form.segmentList.label')}
                placeholder={t('addCadec.form.segmentList.placeholder')}
                onSelect={item => {
                  setFieldValue('segmentList', item);
                  setFieldValue('segmentType', []);
                }}
                options={
                  values.centerList.listType &&
                  values.centerList.listType.sort((a, b) =>
                    a.name > b.name ? 1 : -1,
                  )
                }
                disabled={!Object.keys(values.centerList).length}
                optionLabel="name"
                values={values}
                errors={errors}
                touched={touched}
              />

              <MultiSelect
                selectedItem={values.segmentType}
                field="segmentType"
                label={t('addCadec.form.segmentType.label')}
                placeholder={t('addCadec.form.segmentType.placeholder')}
                addItem={setFieldValue}
                removeItemBy="id"
                options={
                  values.segmentList.segmentType &&
                  values.segmentList.segmentType.sort((a, b) =>
                    a.name < b.name ? 1 : -1,
                  )
                }
                disabled={!Object.keys(values.segmentList).length}
                disabledItems="visible"
                optionLabel="name"
                values={values}
                errors={errors}
                touched={touched}
              />

              <InputField
                field="cadecName"
                label={t('addCadec.form.cadecName.label')}
                placeholder={t('addCadec.form.cadecName.placeholder')}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!values.segmentType.length}
                min={0}
                values={values}
                errors={errors}
                touched={touched}
              />

              <FileInput
                type="file"
                field="fileName1"
                label={t('addCadec.form.fileName1.label')}
                onChange={e => handleFile1(e.target.files[0])}
                itemLabel="fileName"
                removeFile={e => {
                  removeFile1(e);
                  setFieldValue('cadecName', `${values.cadecName} `); // required to exclude file
                }}
                itemToRemove="id"
                isLoading={isUploading1}
                itemsToList={[values.fileName1]}
                itemLink={
                  selected.files && selected.files.length > 0
                    ? `${API_URL}/Blob/Download?fileName=${selected.fileName1}&blobContainerName=${selected.files[0].container}&token=${user.token}&client_id=${KONG_CLIENT_ID}`
                    : ''
                }
                blobContainerName={
                  selected.files && selected.files.length > 0
                    ? selected.files[0].container
                    : ''
                }
                token={user.token}
                onBlur={handleBlur}
                accept=".pdf"
                values={values}
                errors={errors}
                touched={touched}
              />

              <FileInput
                type="file"
                field="fileName2"
                label={t('addCadec.form.fileName2.label')}
                onChange={e => handleFile2(e.target.files[0])}
                itemLabel="fileName"
                removeFile={e => {
                  removeFile2(e);
                  setFieldValue('cadecName', `${values.cadecName} `); // required to exclude file
                }}
                itemToRemove="id"
                isLoading={isUploading2}
                itemsToList={[values.fileName2]}
                itemLink={
                  selected.files && selected.files.length > 0
                    ? `${API_URL}/Blob/Download?fileName=${selected.fileName2}&blobContainerName=${selected.files[0].container}&token=${user.token}&client_id=${KONG_CLIENT_ID}`
                    : ''
                }
                blobContainerName={
                  selected.files && selected.files.length > 0
                    ? selected.files[0].container
                    : ''
                }
                token={user.token}
                onBlur={handleBlur}
                accept=".pdf"
                values={values}
                errors={errors}
                touched={touched}
              />

              <Separator />

              <BtnGroup>
                <Button
                  key="sendtoConfirm"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={
                    !props.isValid || isUploading1 || isUploading2
                  }
                >
                  {t("global.button.save")}
                </Button>
                <Button
                  key="clean"
                  onClick={() => resetForm()}
                  className="white"
                >
                  {t("global.button.clear")}
                </Button>
              </BtnGroup>
            </>
          )}
        </Form>
      )}
    </>
  );
}
