import { dateToTimeString } from 'shared/utils/Helpers/handleDate';

export function formData(values, data) {
  return {
    falId: data.falId,
    date: data.date,
    isEditable: data.isEditable,
    isFirstDay: data.isFirstDay,
    status: data.dailyStatus,
    startDate: data.startDate,
    finishDate: data.finishDate,
    week: data.week,
    pendingSubmit: data.pendingSubmit,
    age: data.age,
    accumulatedMortality: data.accumulatedMortality,
    isRequiredWeight: data.isRequiredWeight,
    isRequiredPh: data.isRequiredPh,
    flockOpening: {
      flockLineage:
        (values.flockLineage && values.flockLineage) || null,
      flockGenre: (values.flockLineage && values.flockGenre) || null,
      broilerType:
        (values.flockLineage && values.broilerType) || null,
      averageWeight:
        (values.flockLineage && values.initialAverageWeight) || null, // attention
    },
    waterConsume: {
      waterConsumption:
        values.waterConsumption &&
        values.waterConsumption.replace('.', ''),
      waterTime:
        values.waterTime && dateToTimeString(values.waterTime),
    },
    temperature: {
      minTemperature: values.minTemperature,
      maxTemperature: values.maxTemperature,
    },
    moisture: {
      minHumidity: values.minHumidity,
      maxHumidity: values.maxHumidity,
    },
    mortality: {
      naturalDeath: !data.isFirstDay
        ? values.naturalDeath.toString().replace('.', '')
        : null,
      refuseElimination: !data.isFirstDay
        ? values.refuseElimination.toString().replace('.', '')
        : null,
      legElimination: !data.isFirstDay
        ? values.legElimination.toString().replace('.', '')
        : null,
      otherElimination: !data.isFirstDay
        ? values.otherElimination.toString().replace('.', '')
        : null,
      mortalityOnArrival:
        data.isFirstDay && values.mortalityOnArrival
          ? values.mortalityOnArrival.toString().replace('.', '')
          : null,
    },
    averageWeight: {
      weightValue: values.weightValue,
      weightTime: dateToTimeString(values.weightTime),
    },
    ph: {
      chlorine: values.chlorine,
      ph: values.ph,
    },
    foodConsume: {
      isInitialFeed: values.isInitialFeed,
      initialFeedType:
        values.initialFeedType && values.initialFeedType.description,
      initialFeedTime: dateToTimeString(values.initialFeedTime),
      hasMovimentation: values.hasMovimentation,
      movimentationType: values.movimentationType,
      movimentationFeedType:
        values.movimentationFeedType &&
        values.movimentationFeedType.description,
      movimentationFeedValue: values.movimentationFeedValue,
      movimentationVendorInformation:
        values.movimentationVendorInformation,
    },
    showFlockClosure: !data.showFlockClosure,
    flockClosure: {
      isFlockClosure: values.isFlockClosure,
      waterConsume:
        values.waterConsume &&
        values.waterConsume.toString().replace('.', ''),
      selfConsume: values.selfConsume,
      hasFeedRemnant: values.hasFeedRemnant,
      feedRemnant: values.feedRemnant.map(e => {
        const feedR = {};
        feedR.feedQuantity = e.feedQuantity || 0;
        feedR.feedType = e.feedType.description || 0;
        return feedR;
      }),
    },
    origins: data.origins,
  };
}
