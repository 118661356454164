import ColoredCard from 'components/ColoredCard';
import t from 'components/i18n';
import Icon from 'components/Icon';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import addLeadingZero from 'shared/utils/Helpers/addLeadingZero';
import { registerFeedCut } from '../service';
import {
  ConfirmModalStyled,
  List,
  QuickAccessItemCardStyled,
} from './QuickAccessItems.styled';

const ConfirmModal = ({ item, onConfirm, onClose }) => {
  if (!item) {
    return null;
  }

  const {
    feedCutDate,
    confirmedDate,
    type,
    flock,
    description,
    house,
    isConfirmed,
  } = item;

  return (
    <ConfirmModalStyled>
      <div className="shadow" onClick={onClose} />
      <div className="box">
        <div className="header">
          <span className="title">{type}</span>
          <Icon
            onClick={onClose}
            className="close-icon"
            name="icon-close"
          />
        </div>
        <div className="content">
          <div className="data">
            <p className="data-title">{t('agro.flock.quickAccessItems.description', { value: description })}</p>
            <div className="rows">
              <div className="row">
                <span className="label">{t('agro.flock.quickAccessItems.flock')}</span>
                <span className="value">{flock}</span>
              </div>
              <div className="row">
                <span className="label">{t('agro.flock.quickAccessItems.houses')}</span>
                <span className="value">{addLeadingZero(house)}</span>
              </div>
              <div className="row">
                <span className="label">
                  {t('agro.flock.quickAccessItems.feedDate')}
                </span>
                <span className="value">
                  {moment(feedCutDate).format('DD ̸̸  MM ̸̸  YYYY')}
                </span>
              </div>
              <div className="row">
                <span className="label">
                  {t('agro.flock.quickAccessItems.feedHour')}
                </span>
                <span className="value">
                  {moment(feedCutDate).format('HH:mm')}
                </span>
              </div>
            </div>
          </div>
          <div className="confirm-wrapper">
            {isConfirmed ? (
              <ColoredCard
                icon="icon-check"
                title={t("agro.flock.quickAccessItems.confirmed.title")}
                text={t('agro.flock.quickAccessItems.confirmed.message', { value: addLeadingZero(house) })}
                additional={moment(confirmedDate).format(
                  'DD ̸̸  MM ̸̸  YYYY HH:mm',
                )}
              />
            ) : (
              <ColoredCard
                full
                icon="icon icon-exclamation-triangle"
                text={t('agro.flock.quickAccessItems.unconfirmed.message', { value: addLeadingZero(house) })}
                buttonLabel={t('agro.flock.quickAccessItems.unconfirmed.button')}
                buttonAction={() => onConfirm(item.id)}
              />
            )}
          </div>
        </div>
      </div>
    </ConfirmModalStyled>
  );
};

const QuickAccessItemCard = ({ onFocus, item }) => {
  const {
    feedCutDate,
    type,
    flock,
    description,
    house,
    isConfirmed,
  } = item;

  return (
    <QuickAccessItemCardStyled
      confirmed={isConfirmed}
      className="quick-access-item__card"
    >
      <div className="content">
        <div className="icon-wrapper">
          <Icon
            name={isConfirmed ? 'icon-check-circle' : 'icon-clock'}
          />
        </div>
        <span className="top-date">
          {moment(feedCutDate).format('DD ̸̸  MM ̸̸  YYYY HH:mm')}
        </span>
        <span className="title">{type}</span>
        <span className="subtitle">{t('agro.flock.quickAccessItems.flock')}{flock}</span>
        <div className="infos">
          <span>{description}</span>
          <span>{t('agro.flock.quickAccessItems.houses')} {addLeadingZero(house)}</span>
        </div>
      </div>
      <div onClick={() => onFocus(item.id)} className="bottom-action">
        {isConfirmed ? t('agro.flock.quickAccessItems.viewRecord') : t('agro.flock.quickAccessItems.register')}
      </div>
    </QuickAccessItemCardStyled>
  );
};

export default function QuickAccessItems({
  feedCutDates = [],
  fetchFeedCutDates,
  flockNumber,
} = {}) {
  const [focusedItemId, setFocusedItemId] = useState(null);
  const focusedItem = feedCutDates.find(
    feedCutDate => feedCutDate.id === focusedItemId,
  );

  const onConfirm = async itemId => {
    await registerFeedCut({
      id: itemId,
      readDate: moment().toISOString(),
    });

    fetchFeedCutDates();
  };

  // attaches scroll handlers
  useEffect(() => {
    const SCROLL_QUANTITY = 160;
    const container = document.querySelector('.quick-action-list');

    const scrollToTheLeft = () => {
      const currentPosition = container.scrollLeft;
      container.scrollLeft =
        currentPosition > 0 ? currentPosition - SCROLL_QUANTITY : 0;
    };

    const scrollToTheRight = () => {
      const currentPosition = container.scrollLeft;
      const maxScrollLeft =
        container.scrollWidth - container.clientWidth;

      container.scrollLeft =
        currentPosition - SCROLL_QUANTITY < maxScrollLeft
          ? currentPosition + SCROLL_QUANTITY
          : maxScrollLeft;
    };

    const scrollLeft = document.querySelector('.quick-action-left');
    const scrollRight = document.querySelector('.quick-action-right');

    scrollLeft.addEventListener('click', scrollToTheLeft);
    scrollRight.addEventListener('click', scrollToTheRight);

    return () => {
      scrollLeft.removeEventListener('click', scrollToTheLeft);
      scrollRight.removeEventListener('click', scrollToTheRight);
    };
  }, []);

  return (
    <>
      <List className="quick-action-list">
        {feedCutDates
          .filter(item => item.flock === flockNumber)
          .map(item => (
            <QuickAccessItemCard
              onFocus={setFocusedItemId}
              key={item.id}
              item={item}
            />
          ))}
      </List>
      <ConfirmModal
        onConfirm={onConfirm}
        onClose={() => setFocusedItemId(null)}
        item={focusedItem}
      />
    </>
  );
}
