import React, { useCallback } from 'react';
import Logo from 'components/Logo';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { store } from 'store';
import { unsetUser, setUser } from 'components/User/actions';
import { activateUser } from './service';
import {
  Header,
  DeactivatedUserStyled,
  Wrapper,
  SubHeading,
  Separator,
} from './DeactivatedUser.styled';
import t from 'components/i18n';

export default function DeactivatedUser({ user, history }) {
  const activateUserById = useCallback(
    async id => {
      try {
        const response = await activateUser(id);
        if (response.success) {
          store.dispatch(
            setUser({
              ...user,
              isDeleted: false,
              isNextUser: false,
              justDeactivated: false,
              isAuthenticated: true,
            }),
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    [user],
  );

  return (
    <>
      <Header>
        <Logo
          name="agro"
          variant="coloured"
          onClick={() => store.dispatch(unsetUser())}
        />
      </Header>
      <DeactivatedUserStyled>
        <Wrapper>
          <SubHeading>
            {user.justDeactivated
              ? t("menu.profile.accountData.deactivateAccount.justDeactivated.title")
              : t("menu.profile.accountData.deactivateAccount.noJustDeactivated.title")
            }
          </SubHeading>
          <p>
            {user.justDeactivated
              ? t('menu.profile.accountData.deactivateAccount.justDeactivated.message')
              : t('menu.profile.accountData.deactivateAccount.noJustDeactivated.message')}
          </p>

          <Separator />

          <BtnGroup>
            <Button
              key="edit"
              onClick={() => activateUserById(user.id)}
            >
              {t('menu.profile.accountData.deactivateAccount.reactive')}
            </Button>

            <Button
              key="clean"
              onClick={() => store.dispatch(unsetUser())}
              className="white"
            >
              {user.justDeactivated
                ? t('global.button.close')
                : t('global.button.cancel')
              }
            </Button>
          </BtnGroup>
        </Wrapper>
      </DeactivatedUserStyled>
    </>
  );
}
