import React from 'react';
import moment from 'moment';
import { SummaryItem } from '../StyledNextFlock.styled';
import t from 'components/i18n';

export default function Vaccines({ data, ...props }) {
  return (
    <>
      <br />
      {data &&
        data.vaccineInformation.map((item, index) => (
          <SummaryItem key={index}>
            <span className="info">{item.name}</span>
            <br />
            <span className="label">
              {t('next.flock.labelInfo.vaccines.code', {
                value: item.code,
              })}
            </span>
            <br />
            <br />
            <span className="info">
              {t('next.flock.labelInfo.vaccines.quantity', {
                value: item.quantity,
              })}
            </span>
            <br />
            <span className="label">
              {t('next.flock.labelInfo.vaccines.departureNumber', {
                name: item.laboratoryName,
                // Dear god, I'm sorry abut that line
                number: String(item.departureNumber).replace('/', ' ̸̸  '),
              })}
            </span>
            <br />
            <span className="label">
              {t('next.flock.labelInfo.vaccines.date', {
                fabricationDate: moment(item.fabricationDate).format(
                  'DD ̸̸  MM ̸̸  YYYY',
                ),
                expiracyDate: moment(item.expiracyDate).format(
                  'DD ̸̸  MM ̸̸  YYYY',
                ),
              })}
            </span>
            <br />
            <span className="label">
              {t('next.flock.labelInfo.vaccines.applicationDate', {
                value: moment(item.applicationDate).format(
                  'DD ̸̸  MM ̸̸  YYYY',
                ),
              })}
            </span>
          </SummaryItem>
        ))}
    </>
  );
}
