// @flow

import React from 'react';
import Dashboard from 'components/Dashboard';
import t from 'components/i18n';

function NextDashboard({ responsive, user, history }) {
  return (
    <Dashboard
      helmet={t('nextdashboard.helmet')}
      responsive={responsive}
      user={user}
      history={history}
      displayOverview={user.type !== 'F' && user.type !== 'O'}
      displayPreview={user.type !== 'O'}
    />
  );
}

export default NextDashboard;
