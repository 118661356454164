import styled from 'styled-components';
import {
  colorClear,
  colorGrayDarkest,
  colorInfo,
  boxShadow,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

export const StyledIconButton = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 10px 24px;

  .soon {
    position: absolute;
    top: -9px;
    min-width: 77px;
    margin: 0;
    z-index: 10;
  }

  .wrapper {
    background-color: ${colorClear};
    border-radius: 8px;
    height: 96px;
    width: 96px;
    transition: 0.2s box-shadow ease-in;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled ? 0.3 : 1)};

    &:hover {
      box-shadow: ${boxShadow};
    }

    p {
      font-family: ${fontFamilyTextBold};
      color: ${colorGrayDarkest};
      font-size: 12px;
      text-align: center;
      margin: 8px 12px 16px;
    }
  }
`;

export const IconWrapper = styled.div`
  background-color: ${props => props.bg};
  color: ${colorClear};
  font-size: 24px;
  border-radius: 50%;
  margin: 12px auto 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
`;

export const SecondaryIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colorClear};
  background-color: ${colorInfo};
  font-size: 26px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 15px;
  left: 54px;
  transition: 0.3s ease-in opacity;

  &:before {
    z-index: 1;
    position: relative;
  }
`;
