import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import * as routes from 'config/routes';
import Breadcrumb from 'components/Breadcrumb';
import LoadingHolder from 'components/LoadingHolder';
import Top from 'components/Top';
import ColoredCard from 'components/ColoredCard';
import OpenOrientationCard from './OpenOrientationCard';
import { OrientationStyled } from './Orientation.styled';
import { getOrientation, setOrientationToReaded } from './service';
import t from 'components/i18n';

export default function OrientationScreen({
  history,
  match: { params },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [read, setRead] = useState(null);
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState([]);

  const getOrientationData = useCallback(async id => {
    const response = await getOrientation(id);

    setIsLoading(true);

    try {
      setData(response);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }, []);

  const setReadItem = useCallback(async () => {
    await setOrientationToReaded({
      LogBookItemId: params.id,
    });

    try {
      setUpdate(true);
    } catch (error) {
      console.error(error);
    }
    setUpdate(false);
  }, [params.id]);

  useEffect(() => {
    getOrientationData(params.id);
  }, [getOrientationData, params.id]);

  useEffect(() => {
    if (update) {
      getOrientationData(params.id);
    }
  }, [getOrientationData, params.id, update]);

  useEffect(() => {
    if (data) {
      setRead(data.read);
    }
  }, [data]);

  return (
    <>
      <LoadingHolder isLoading={isLoading} />

      <Breadcrumb
        history={history}
        backTo={() =>
          history.push(
            `${routes.FLOCK_LIST}/${data.flockId}/record/orientations`,
          )
        }
        path={[
          t('flocks.title'),
          t('flocks.listView.item.title', { flockNumber: data.flock }),
          t("orientations.tecnical-orientation")]}
        links={[
          routes.FLOCK_LIST,
          `${routes.FLOCK_LIST}/${data.flockId}/record/orientations`,
        ]}
      />

      <Top>
        <h2>{t('orientations.tecnical-orientation')}</h2>
      </Top>

      {data && (
        <OrientationStyled>
          <div className="primary">
            <OpenOrientationCard data={data} />
          </div>

          <div className="secondary">
            {read && (
              <ColoredCard
                icon="icon-check"
                title={t('orientations.tecnical-orientation.read.title')}
                text={t('orientations.tecnical-orientation.read.description')}
                additional={moment(data.readDate).format(
                  'DD ̸̸  MM ̸̸  YYYY HH:mm',
                )}
              />
            )}

            {!read && (
              <ColoredCard
                icon="icon-check"
                title={t("orientations.tecnical-orientation.not-read.title")}
                text={t("orientations.tecnical-orientation.not-read.description")}
                buttonLabel={t("orientations.tecnical-orientation.not-read.button")}
                buttonAction={() => setReadItem()}
              />
            )}
          </div>
        </OrientationStyled>
      )}
    </>
  );
}
