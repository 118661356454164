import { API_SUPERVISOR_URL } from 'config/constants';
import { request } from 'shared/utils/client';
import { fixNestedNamesRecursively } from 'shared/utils/Helpers/capitalizeName';

export async function getVendors(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Producer/GetPagedListOfProducers`,
    {
      method: 'GET',
      payload,
    },
  );
  return response.data;
}

export async function getProducersForOperationalUser() {
  const response = await request(
    `${API_SUPERVISOR_URL}/Producer/GetProducersForOperationalUser`,
    {
      method: 'GET'
    },
  );
  return {
    ...response,
    data: fixNestedNamesRecursively(response.data, 'vendorName'),
  };
}
