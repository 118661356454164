import React from 'react';
import moment from 'moment';
import t from 'components/i18n';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { useSelector } from 'react-redux';
import IconAndText from 'components/IconAndText';
import Pagination from 'components/Pagination';
import Options from 'components/Options';
import { List, ListItem } from 'components/List';
import { icons, nextIcons } from './utils';

export default function LibraryList({
  content,
  isLoadingItems,
  setEditLibrary,
  setShowDetails,
  setSelectedItem,
  setDisplayDeleteModal,
  libraryTotalPages,
  filteredLibraryTotalPages,
  filteredLibrary,
  currentPage,
  setCurrentPage,
  libraryPermissions,
}) {
  const user = useSelector(state => state.user);

  const isEditable = !!libraryPermissions.edit;

  const displayItem = (item, index) => {
    const getIcon = (user.isNextUser ? nextIcons : icons).filter(
      e => e.fileType === item.fileType,
    )[0];
    return (
      <IconAndText
        key={index}
        iconBackGround={getIcon ? getIcon.color : 'colorPrimary'}
        icon={getIcon ? getIcon.icon : 'icon-file'}
        title={t(item.name)}
        subtitle={moment(item.createdDate).format('DD ̸̸  MM ̸̸  YYYY')}
      />
    );
  };

  function displayPages(filtered) {
    return filtered
      ? !isLoadingItems && Number(filteredLibraryTotalPages) > 1
      : !isLoadingItems && Number(libraryTotalPages) > 1;
  }

  function getOptions(item) {
    const options = [
      {
        action: () => {
          setShowDetails(true);
          setSelectedItem(item);
        },
        icon: 'visibility-enabled',
        label: t('library.libraryList.options.details'),
        visible: !!libraryPermissions.read,
      },
      {
        action: () => {
          setEditLibrary(true);
          setSelectedItem(item);
        },
        icon: 'edit',
        label: t('library.libraryList.options.edit'),
        visible: !!libraryPermissions.edit,
      },
      {
        action: () => {
          setDisplayDeleteModal(true);
          setSelectedItem(item);
        },
        icon: 'trash',
        label: t('library.libraryList.options.delete'),
        visible: !!libraryPermissions.delete,
      },
    ];
    return options;
  }

  return (
    <>
      {!isLoadingItems && content.length > 0 && (
        <List style={{ width: '100%' }}>
          {content.map((item, index) => (
            <ListItem
              key={item.id}
              noPadding
              style={{ marginBottom: '0' }}
              className="animated fadeIn flex"
            >
              {isEditable && (
                <>
                  <div
                    onClick={() => {
                      setShowDetails(true);
                      setSelectedItem(item);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {displayItem(item, index)}
                  </div>

                  <Options
                    options={getOptions(item)}
                    placement="start-bottom"
                  />
                </>
              )}

              {!isEditable && (
                <a
                  href={`${API_URL}/Blob/Download?fileName=${item.blobName}&blobContainerName=library&token=${user.token}&client_id=${KONG_CLIENT_ID}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {displayItem(item, index)}
                </a>
              )}
            </ListItem>
          ))}
        </List>
      )}
      {displayPages(filteredLibrary) && (
        <Pagination
          totalPages={
            filteredLibrary
              ? filteredLibraryTotalPages
              : libraryTotalPages
          }
          // pagePadding={isMobile ? 0.75 : 2}
          pagePadding={2}
          currentPage={currentPage}
          onChangePage={x => setCurrentPage(x.e)}
        />
      )}
    </>
  );
}
