import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';

import { useHistory, useParams } from 'react-router';

import Modal from 'components/Forms/FormModal/Modal';
import { SendOrientationForm } from 'containers/NextBRF/Forms/Visit';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import {
  // updateLogBookItem,
  uploadImage,
  deleteImage,
} from 'containers/NextBRF/LogBook/service';
import { putLogbookItemsOrientations } from '../VisitCircuit/service';
import t from 'components/i18n';

const validateSchema = props =>
  yup.object().shape({
    orientation: yup
      .string()
      .nullable()
      .required(t('global.status.required-field')),
  });

export default function EditOrientation(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);

  const { isLoading, selected, isValidating, onSubmit } = props;

  const history = useHistory();

  const params = useParams();

  const initialValues = {
    orientation: props.orientation,
  };

  const formData = values => ({
    id: props.id,
    date: props.visitDate,
    orientation: values.orientation,
    attachments: files,
  });

  async function handleFile(file) {
    setIsUploading(true);

    try {
      const response = await uploadImage(file);
      if (response) {
        setFiles([...files, response]);
      }
    } catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  }

  async function removeFile(file) {
    setIsUploading(true);
    try {
      const response = await deleteImage(file);
      if (response) {
        setFiles(files.filter(e => e !== file));
      }
    } catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  }

  function submitForm(values, { setSubmitting }) {
    formData(values);

    async function sendData(val) {
      setSubmitting(true);
      try {
        // const response = await updateLogBookItem(val);
        const response = await putLogbookItemsOrientations(
          params.id,
          val,
        );

        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }
    sendData(formData(values));
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);

        if (onSubmit) {
          onSubmit(true);
        } else {
          history.goBack();
        }
      }, 2500);
    }
  }, [history, onSubmit, success]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate(validateSchema)}
        onSubmit={submitForm}
        render={formikProps => (
          <SendOrientationForm
            {...formikProps}
            isValidating={isValidating}
            handleFile={e => handleFile(e)}
            removeFile={e => removeFile(e)}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            files={files}
            setFiles={setFiles}
            message={errorMessage}
            isLoading={isLoading}
            selected={selected}
            success={success}
            {...props}
          />
        )}
      />
      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>
          {success
            ? t('global.validation.record-created')
            : t('global.validation.record-error')}
        </p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
