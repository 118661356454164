import React from 'react';
import AgroDashboard from 'containers/AgroBRF/AgroDashboard';
import NextDashboard from 'containers/NextBRF/NextDashboard';

export default function Home(props) {
  const { isNextUser } = props.user;

  return (
    <>
      {isNextUser ? <NextDashboard {...props} /> : <AgroDashboard {...props} />}
    </>
  );
}
