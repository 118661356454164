import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { API_TRANSLATIONS_URL, KONG_CLIENT_ID } from 'config/constants';

import Backend from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { mapUserLang } from 'utils/locale';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/tr';

const fallbackLng = localStorage.getItem('lang') || 'en-US';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    fallbackLng,
    debug: false,
    backend: {
      loadPath: lang => `${API_TRANSLATIONS_URL}/DownloadTranslations?fileName=${mapUserLang(lang)}`,
      parse: res => {
        const response = JSON.parse(res);
        if (response.success) {
          return JSON.parse(response.data.translation);
        }

        return null;
      },
      customHeaders: {
        "client_id": KONG_CLIENT_ID
      }
    },
    react: {
      wait: true,
      useSuspense: false,
      bindI18nStore: 'onAdded'
    },
  });

i18n.on('languageChanged', lang => {
  localStorage.setItem('lang', lang);
  moment.locale(mapUserLang(lang));
  moment.updateLocale(mapUserLang(lang));
});

export default i18n;

/*
  import { useTranslation } from 'react-i18next';
  const { t } = useTranslation();
*/
