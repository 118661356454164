import moment from 'moment';
import {
  getPastDate,
  stringToDate,
} from 'shared/utils/Helpers/handleDate';
import t from 'components/i18n';

export const dateFilterList = [
  {
    label: t('global.time.picker.filter.date.today'),
    startDate: moment(),
    endDate: moment(),
  },
  {
    label: t('global.time.picker.filter.date.yesterday'),
    startDate: getPastDate(1),
    endDate: moment(),
  },
  {
    label: t('global.time.picker.filter.date.last7Days'),
    startDate: getPastDate(7),
    endDate: moment(),
  },
];

export function getValuesOf(array, key) {
  const flatMapArray = array.flat().map(e => e[key]);
  const filteredArray = flatMapArray.filter(
    (v, i) => flatMapArray.indexOf(v) === i,
  );

  return filteredArray;
}

export function simpleFilter(arrayToFilter, item, condition) {
  let result = arrayToFilter;

  if (condition) {
    result = result
      .map(m => m.filter(e => 
        typeof e[item] === 'object'
          ? e[item].value === condition
          : e[item] === condition)
      )
      .filter(r => r.length);
  }

  return result;
}

export function filterByDate(
  arrayToFilter,
  item,
  startDate,
  endDate,
) {
  let result = arrayToFilter;

  if (startDate || endDate) {
    const start = moment(startDate).startOf('day');
    const end = moment(endDate).endOf('day');

    result = result
      .map(m => {
        if (Array.isArray(m)) {
          return m.filter(
            f =>
              moment(stringToDate(f[item])).isBetween(
                start,
                end,
                'day',
              ) ||
              moment(stringToDate(f[item])).isSame(start, 'day') ||
              moment(stringToDate(f[item])).isSame(end, 'day'),
          );
        }

        return (
          moment(m[item]).isBetween(start, end, 'day') ||
          moment(m[item]).isSame(start, 'day') ||
          moment(m[item]).isSame(end, 'day')
        );
      })
      .filter(r => r.length);
  }

  return result;
}

export function getDateLabel(dateString) {
  return moment(dateString).format('DMMM');
}
