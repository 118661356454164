// @flow

import React, { useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
// import uid from 'uuid';
import logger from 'shared/utils/logger';
import {
  getAtaInfos,
  saveAta,
} from 'containers/AgroBRF/Cadec/service';
import validate from 'components/Forms/Fields/validate';
import { AtaContext } from 'shared/contexts';
import { uploadFile } from 'shared/blob/service';
import { stringToDate, dateToString } from 'shared/utils/Helpers';
import AtaForm from './AtaForm';
import t from 'components/i18n';

const validateSchema = props =>
  yup.object().shape({
    centers: yup.object().required(t('global.status.required-field')),
    cadecs: yup.object().required(t('global.status.required-field')),
    date: yup.string().required(t('global.status.required-field')),
    number: yup
      .string()
      .min(1)
      .required(t('global.status.required-field')),
    previousFile: yup.boolean(),
    fileName: yup
      .string()
      .when('previousFile', (previousFile, schema) =>
        !previousFile ? schema.required(t('global.status.required-field')) : schema,
      ),
  });

const initialValues = {
  id: '',
  centers: [],
  cadecs: [],
  date: '',
  number: '',
  fileName: '',
  previousFile: false,
};

export default function AddAta(props) {
  const ataContext = useContext(AtaContext);
  const { selectedAta } = ataContext;
  const {
    setAtaSubmitResponse,
    removeFile,
    addFile,
  } = ataContext.actions;
  const [data, setData] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [formFile, setFormFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // set CADEC data
  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      try {
        const response = await getAtaInfos();

        setData(response);
      } catch (e) {
        logger('Error on getAtaInfos', e);
      }

      setIsLoading(false);
    }
    fetch();
  }, []);

  async function handleFile(file) {
    setIsUploading(true);
    try {
      const response = await uploadFile(file);

      setFormFile(response);
      addFile(response);
    } catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  }

  if (props.isEditing && !props.isSubmitting) {
    const numberFromDocumentName = selectedAta.name.substring(
      selectedAta.name.lastIndexOf(' ') + 1,
      selectedAta.name.lastIndexOf('/'),
    );

    initialValues.id = selectedAta.id;
    initialValues.centers =
      data.centers &&
      data.centers.filter(
        center =>
          center.centerCode === selectedAta.cadecs[0].centerCode,
      )[0];
    initialValues.cadecs = selectedAta.cadecs[0];
    initialValues.previousFile = true;
    initialValues.fileName = '';
    initialValues.date = dateToString(stringToDate(selectedAta.date));
    initialValues.number = numberFromDocumentName;
  } else {
    initialValues.id = '';
    initialValues.centers = [];
    initialValues.cadecs = [];
    initialValues.date = '';
    initialValues.fileName = '';
    initialValues.number = '';
    initialValues.previousFile = false;
  }

  useEffect(() => {
    setFormFile(selectedAta.fileName);
  }, [selectedAta]);

  function submitForm(values, { setSubmitting, setErrors }) {
    const formData = {
      id: values.id,
      cadecDocumentName:
        t('cadec.form.cadecDocumentName', { value: `${values.number}/${values.date.toString().split('-')[0]}` })
          .replace('&#x2F;', '/'),
      centerCode: values.centers.centerCode,
      listCadecID: [values.cadecs.id],
      dateCreated: values.date,
      fileName: formFile,
    };

    async function saveData(val) {
      setSubmitting(true);
      try {
        const response = await saveAta(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setAtaSubmitResponse(response.message);
      } catch (error) {
        setAtaSubmitResponse(error.message);
      }
      setSubmitting(false);
      props.goTo('cadecList');
    }

    saveData(formData);
  }

  return (
    Object.keys(data).length > 0 && (
      <Formik
        initialValues={initialValues}
        validate={validate(validateSchema)}
        onSubmit={submitForm}
        render={formikProps => (
          <AtaForm
            {...formikProps}
            isSubmitting={props.isSubmitting}
            unities={data.centers
              .sort((a, b) =>
                a.centerDescription > b.centerDescription ? 1 : -1,
              )
              .map(center => ({
                ...center,
                centerDescription: `${center.centerCode} - ${center.centerDescription}`,
              }))}
            selected={selectedAta}
            handleFile={e => handleFile(e)}
            removeFile={e => removeFile(e)}
            goTo={props.goTo}
            isEditing={props.isEditing}
            isLoading={isLoading}
            isUploading={isUploading}
          />
        )}
      />
    )
  );
}
