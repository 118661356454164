import moment from "moment";

export function stringToDate(value) {
  let date = '';
  if (value.includes(' • ')) {
    date = value.split(' • ')[0];
  } else {
    date = value;
  }

  const dateSplitArr = date.split('/');
  const dateFormatStr = `${dateSplitArr[1]}/${dateSplitArr[0]}/${dateSplitArr[2]} 00:00:00 `;

  return moment(dateFormatStr);
}

export function formatDateString(value) {
  const valueSplitArr = value.split('/');
  const formatDateStr = `${valueSplitArr[2]}-${valueSplitArr[1]}-${valueSplitArr[0]}`;
  return formatDateStr;
}

/**
 * 
 * @param {string | Date | moment.Moment} value 
 * @returns {string}
 */
export function dateToString(value) {
  return moment(value).format('YYYY-MM-DD')
}

export function dateToTimeString(value) {
  return moment(value).format('HH:mm');
}

export function getPastDate(interval) {
  return moment().subtract(interval, 'days');
}
