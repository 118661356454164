import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import {
  colorGrayLighter,
  colorClear,
  fontFamilyTextBold,
  colorDanger,
  size,
} from 'components/GlobalStyles/variables';

const TextfieldHolder = styled.div`
  position: relative;

  div[data-garden-id='forms.faux_input'] {
    padding: 0px;
    border: 1px solid ${colorGrayLighter}!important;

    &:focus {
      box-shadow: none !important;
    }

    input {
      border: none !important;
    }
  }

  div[data-garden-id='forms.media_input'] {
    border: none !important;
    box-shadow: none !important;

    input {
      outline: none !important;
    }
  }

  .input_field {
    border-radius: ${size} !important;
    min-height: 48px !important;
    max-height: 48px !important;
    border: 1px solid ${colorGrayLighter} !important;
    color: ${colorGrayLighter} !important;

    ::placeholder {
      color: ${colorGrayLighter} !important;
    }

    &[class*='input--bare'] {
      min-height: 1.9em !important;
    }

    &.is-focused,
    &:focus {
      box-shadow: none !important;
    }

    &[class*='--error'] {
      border-color: ${colorDanger} !important;
      // margin-top: 8px !important;
    }

    &[class*='input--sm']:not([class*='input--select']) {
      min-height: 40px !important;

      &:before {
        height: 48px;
      }
    }

    &[class*='input--select'] {
      min-height: 30px !important;
      overflow: hidden;
    }
  }

  label[data-garden-id='forms.text_label'] {
    position: absolute;
    font-size: 14px;
    color: ${colorGrayLighter};
    top: 16px;
    left: 10px;
    transition: all 0.2s ease-in-out;
    padding: 0 5px;
    line-height: 14px;
  }

  &.is-focused {
    label[data-garden-id='forms.text_label'] {
      font-weight: normal !important;
      font-family: ${fontFamilyTextBold};
      top: -6px;
      left: 16px;
      font-size: 12px;
      background-color: ${colorClear};
    }
  }
`;

function getClass(className = '', isFocusedClass) {
  let klasses = className
    .split(isFocusedClass)
    .join(' ')
    .trim();
  klasses += ` ${klasses} ${isFocusedClass} `;

  return klasses.trim();
}

export default function MaterialStyledTextfield({ children }) {
  const [isFocused, setIsFocused] = useState(false);
  const isFocusedClass = isFocused ? 'is-focused' : '';

  const ref = useRef();

  const clonedChildren = React.Children.map(
    children,
    (child, key) => {
      const props = {};

      if (child.type.displayName === 'Label') {
        props.onClick = e => {
          const label = e.target;
          const next = label.nextElementSibling;

          setIsFocused(true);

          // ref.current.attrs.className = getClass(
          //   ref.current.attrs.className,
          //   'is-focused',
          // );

          if (next.nodeName.toLowerCase() === 'input') {
            next.focus();
          } else {
            next.querySelector('input').focus();
          }
        };
      }

      if (child.props.onFocus) {
        const original = child.props.onFocus;
        props.onFocus = e => {
          original(e);
          setIsFocused(true);
        };
      } else {
        props.onFocus = e => {
          setIsFocused(true);
        };
      }

      if (child.props.onBlur) {
        const original = child.props.onBlur;
        props.onBlur = e => {
          original(e);
          if (e.target.value === '') {
            setIsFocused(false);
          }
        };
      } else {
        props.onBlur = e => {
          if (e.target.value === '') {
            setIsFocused(false);
          }
        };
      }

      if (child.props.onChange) {
        const original = child.props.onChange;
        props.onChange = e => {
          original(e);
          if (e.target.value !== '') {
            setIsFocused(true);
          }
        };
      } else {
        props.onChange = e => {
          if (e.target.value !== '') {
            setIsFocused(true);
          }
        };
      }

      props.className = getClass(
        child.props.className || '',
        isFocusedClass,
      );

      const clone = React.cloneElement(child, props);

      // if (ref.current) {
      //   ref.current.attrs.className = isFocusedClass;
      // }

      return clone;
    },
  );

  return (
    <TextfieldHolder ref={ref} className={isFocusedClass}>
      {clonedChildren}
    </TextfieldHolder>
  );
}
