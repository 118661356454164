// @flow

import React from 'react';

// components
import Box from 'components/Container';
import RecordItem from 'containers/AgroBRF/FlockList/Flock/FlockHistory/RecordItem';
import NoContentTab from 'components/NoContentTab';
import uid from 'uuid';
import LoadingHolder from 'components/LoadingHolder';
import { getBoxType } from 'containers/AgroBRF/FlockList/Flock/Flock.utils';
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import type { TFlockProps } from './typings';
import t from 'components/i18n';

export default function RecordContent(props: TFlockProps) {
  const {
    arrayContent,
    isLoading,
    selectedKey,
    setSelectedKey,
  } = props;

  const boxProps = {
    slim: true,
    [getBoxType(selectedKey)]: true,
  };

  function getFlockDetails() {
    const top = (
      <header className="flock-details-header">
        <h2 className="flock-details-heading">{props.title}</h2>
      </header>
    );

    const content = (
      <div className="flock-details-content">
        {arrayContent.map(feed => (
          <RecordItem
            key={`feed-${uid()}`}
            feed={feed}
            goTo={e => setSelectedKey(e)}
            history={props.history}
            houses={props.houses}
          />
        ))}
      </div>
    );

    const noContent = isLoading ? (
      <LoadingHolder
        message={t('global.loading')}
        isLoading={isLoading}
      />
    ) : (
      <NoContentTab icon="icon-clipboard">
        <p>
          {t('global.no-content')}
        </p>
      </NoContentTab>
    );

    const details = (
      <div className="flock-details-container animated fadeIn">
        {top}
        <Separator />
        {arrayContent.length > 0 ? content : noContent}
      </div>
    );

    return (
      <div className="flock-details">
        <Box {...boxProps}>{details}</Box>
      </div>
    );
  }

  return (
    <>
      <LoadingHolder isLoading={isLoading} />
      {!isLoading && getFlockDetails()}
    </>
  );
}
