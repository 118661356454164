// @flow

import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import ContactForm from 'containers/OnePage/Forms/ContactForm';
// validate
import validate from 'components/Forms/Fields/validate';
import {
  ContactStyled,
  Wrapper,
  Container,
} from 'containers/OnePage/Contact/Contact.styled';
// service
import { contactUs, getSubjects } from './service';
import t from 'components/i18n';

const validateSchema = props => {
  return yup.object().shape({
    name: yup.string().required(t('global.status.required-field')),
    email: yup
      .string()
      .email(t('global.validation.invalid-email'))
      .required(t('global.status.required-field')),
    countryState: yup.string().required(t('global.status.required-field')),
    cityID: yup.string().required(t('global.status.required-field')),
    phoneNumber: yup
      .string()
      .min(14, t('global.validation.min-character-length', { length: 14 }))
      .required(t('global.status.required-field')),
    subject: yup.string().required(t('global.status.required-field')),
    recaptcha: yup.string().required(t('global.validation.invalid-captcha')),
  });
};

const initialValues = {
  name: '',
  email: '',
  countryState: '',
  cityID: '',
  phoneNumber: '',
  subject: '',
  message: '',
  recaptcha: '',
};

export default function Contact(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  async function getFormSubjects() {
    setIsLoading();
    try {
      const response = await getSubjects();
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getFormSubjects();
  }, []);

  function submitForm(
    values,
    { setSubmitting, setErrors, isValidating },
  ) {
    const formData = {
      name: values.name,
      email: values.email,
      subjectID: values.subject.id,
      cityID: values.cityID.id,
      phoneNumber: values.phoneNumber,
      message: values.message,
    };

    async function saveData(val) {
      setSubmitting(true);
      try {
        const response = await contactUs(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setMessage(error.message);
      }
      setSubmitting(false);
    }

    saveData(formData);
  }

  return (
    <ContactStyled id="contact">
      <Wrapper>
        <Container>
          <Formik
            initialValues={initialValues}
            validate={validate(validateSchema)}
            onSubmit={submitForm}
            render={formikProps => (
              <ContactForm
                {...formikProps}
                isValidating={props.isValidating}
                message={message}
                isLoading={isLoading}
                subjects={data}
                success={success}
              />
            )}
          />
        </Container>
      </Wrapper>
    </ContactStyled>
  );
}
