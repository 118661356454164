import React from 'react';
import { INDICATORS } from 'config/routes';
import Top from 'components/Top';
import IconAndText from 'components/IconAndText';
import Breadcrumb from 'components/Breadcrumb';
import { IndcatorsStyled, ListWrapper } from './Indicators.styled';
import t from 'components/i18n';

export default function IndicatorDetails({
  history,
  selected,
  setSelected,
  color,
}) {
  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() => setSelected(null)}
        path={[t('next.indicatorDetails.bc.dashboard'), selected.title]}
        links={[`${INDICATORS}/opened`]}
      />

      <>
        <Top>
          <IconAndText
            topText="Dashboard"
            title={selected.title}
            iconBackGround={color}
            icon={selected.icon}
          />
        </Top>

        <IndcatorsStyled>
          <ListWrapper className={`full ${selected.key}`}>
            <iframe title={t("next.indicatorDetails.title")} src={selected.link || ''} />
          </ListWrapper>
        </IndcatorsStyled>
      </>
    </>
  );
}
