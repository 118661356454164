import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { request } from 'shared/utils/client';
import { fixFileName } from 'shared/utils/Helpers/fixFileName';

export async function saveLibrary(payload) {
  const response = await request(`${API_URL}/Library`, {
    method: 'PUT',
    payload,
  });
  return response;
}

export async function getLibraryCategories() {
  const response = await request(
    `${API_URL}/Library/GetLibraryCategories`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getCategorySubCategories() {
  const response = await request(
    `${API_URL}/Library/GetCategorySubCategories`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getTagList() {
  const response = await request(`${API_URL}/Library/GetTagList`, {
    method: 'GET',
  });
  return response.data;
}

export async function getLibraryItems(
  subCategoryId,
  pageIndex,
  pageSize,
  fileType = '',
  tag = '',
  startDate = '',
  endDate = '',
) {
  const response = await request(
    `${API_URL}/Library/GetLibraryItems?subCategoryId=${subCategoryId}&pageIndex=${pageIndex}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&fileType=${fileType}&tag=${tag}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function deleteLibraryItem(id) {
  const response = await request(
    `${API_URL}/Library/Delete?id=${id}`,
    {
      method: 'DELETE',
    },
  );
  return response.data;
}

export async function downloadLibraryItem(file) {
  const response = await request(
    `${API_URL}/Blob/Download?fileName=${file}&blobContainerName=library&client_id=${KONG_CLIENT_ID}`,
    {
      method: 'GET',
    },
  );
  return response;
}

export async function uploadLibraryFile(payload) {
  const fd = new FormData();
  fd.append('file', payload, fixFileName(payload));

  const response = await request(
    `${API_URL}/Blob/Upload?containerName=library`,
    {
      headers: {
        'Content-Type': undefined,
        Authorization: null,
      },
      method: 'POST',
      payload: fd,
    },
  );
  return response.data;
}

export async function getRegionalsWithCenters() {
  const response = await request(
    `${API_URL}/UserCorporate/GetRegionalsWithCenters`,
    {
      method: 'GET',
    },
  );

  return response;
}
