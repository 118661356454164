import styled from 'styled-components';
import { easing } from 'components/GlobalStyles/variables';

export const Fade = styled.div`
  &.fade-appear,
  &.fade-enter {
    opacity: 0.01;
  }

  &.fade-appear {
    &.fade-appear-active {
      opacity: 1;
      transition: 0.3s opacity;
    }
  }

  &.fade-enter {
    &.fade-enter-active {
      opacity: 1;
      transition: 0.3s opacity;
    }
  }

  &.fade-exit {
    opacity: 1;

    &.fade-exit-active {
      opacity: 0.01;
      transition: 0.3s opacity;
    }
  }
`;

export const SlideDown = styled.div`
  &.slide-down-appear,
  &.slide-down-enter {
    opacity: 0.01;
    transform: translate3d(0, -100%, 0);
  }

  &.slide-down-appear {
    &.slide-down-appear-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }

  &.slide-down-enter {
    &.slide-down-enter-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }

  &.slide-down-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);

    &.slide-down-exit-active {
      opacity: 0.01;
      transform: translate3d(0, -100%, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }
`;

export const SlideLeft = styled.div`
  &.slide-left-appear,
  &.slide-left-enter {
    opacity: 0.01;
    transform: translate3d(100%, 0, 0);
  }

  &.slide-left-appear {
    &.slide-left-appear-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }

  &.slide-left-enter {
    &.slide-left-enter-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }

  &.slide-left-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);

    &.slide-left-exit-active {
      opacity: 0.01;
      transform: translate3d(100%, 0, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }
`;

export const SlideRight = styled.div`
  &.slide-right-appear,
  &.slide-right-enter {
    opacity: 0.01;
    transform: translate3d(-100%, 0, 0);
  }

  &.slide-right-appear {
    &.slide-right-appear-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }

  &.slide-right-enter {
    &.slide-right-enter-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }

  &.slide-right-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);

    &.slide-right-exit-active {
      opacity: 0.01;
      transform: translate3d(-100%, 0, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }
`;

export const SlideUp = styled.div`
  &.slide-up-appear,
  &.slide-up-enter {
    opacity: 0.01;
    transform: translate3d(0, 100%, 0);
  }

  &.slide-up-appear {
    &.slide-up-appear-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }

  &.slide-up-enter {
    &.slide-up-enter-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }

  &.slide-up-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);

    &.slide-up-exit-active {
      opacity: 0.01;
      transform: translate3d(0, 100%, 0);
      transition: 0.3s opacity, 0.3s transform ${easing};
    }
  }
`;
