import React, { useState, useEffect, useCallback } from 'react';
import FlockVisitCircuitCard from './FlockVisitCircuitCard';
import { getFlockCircuitQuestions } from './service';
import t from 'components/i18n';

import Container from 'components/Container';
import NoContentTab from 'components/NoContentTab';

export default function FlockVisitCircuitList({
  vendorCode,
  selectedFlockCircuit,
  setSelectedSchedule,
  schedules,
  state,
  scheduleId
}) {
  const [questionList, setQuestionList] = useState(null);

  const getFlockQuestionsList = useCallback(async (vendor, state, scheduleId) => {
    try {
      const response = await getFlockCircuitQuestions({ vendor, state, scheduleId });
      setQuestionList(response);
    } catch (e) {
      console.error('Error on get checklist questions', e);
    }
  }, []);

  useEffect(() => {
    if (selectedFlockCircuit) {
      getFlockQuestionsList(vendorCode, state, scheduleId);
    }
  }, [getFlockQuestionsList, selectedFlockCircuit, vendorCode, state, scheduleId]);

  const displayList = useCallback(
    () =>
      questionList &&
      questionList.length > 0 &&
      questionList.map(item => (
        <FlockVisitCircuitCard
          {...item}
          schedules={schedules}
          cardTitle={
            t('next.visitCircuit.FlockVisitCircuitList.card.title', { value: Array.isArray(item.installationList)
              ? item.installationList.map(instalation => instalation)
              : item.installationList })}
          cardSubtitle={t('next.visitCircuit.FlockVisitCircuitList.card.subtitle', { value: item.epidemiologicalUnit })}
          flockList={item.flockList}
          onClick={() =>
            setSelectedSchedule({ id: selectedFlockCircuit.id })
          }
          status={item.status}
          selectedFlockCircuit={selectedFlockCircuit}
        />
      )),
    [
      questionList,
      schedules,
      selectedFlockCircuit.id,
      setSelectedSchedule,
    ],
  );

  if (
    !questionList ||
    (Array.isArray(questionList) && !Boolean(questionList.length))
  ) {
    return (
      <Container hideDetail slim className="animated fadeIn">
        <NoContentTab icon="icon-tasks" hideButton>
          <p>{t('global.no-content')}</p>
        </NoContentTab>
      </Container>
    );
  }

  return displayList();
}
