import t from "components/i18n";

export function displayLabel(e) {
  if (e.from && e.optionLabel) {
    return (
      t(e.values[e.from] &&
        e.values[e.from][e.index] &&
        e.values[e.from][e.index][e.fieldString] &&
        e.values[e.from][e.index][e.fieldString][e.optionLabel]) ||
      t(e.values[e.from] &&
        e.values[e.from][e.index] &&
        e.values[e.from][e.index][e.fieldString] &&
        e.values[e.from][e.index][e.fieldString])
    );
  }

  if (e.from && !e.optionLabel) {
    return t(e.values[e.from] && e.values[e.from][e.field]);
  }

  if (!e.from && e.optionLabel) {
    return t(e.values[e.field] && e.values[e.field][e.optionLabel]);
  }

  return e.values[e.field];
}
