import React, { useState, useEffect } from 'react';
import moment from 'moment';
import t from 'components/i18n';
import { CHECKLIST_DETAILS } from 'config/routes';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import Card from 'components/Card';
// service
import { getChecklists } from './service';
// styles
import { ChecklistsContainer } from './Checklists.styled';
import { useChecklist } from './hooks';

export default function Checklists({ data: vendor, history }) {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [, { setFocusedChecklist }] = useChecklist();

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await getChecklists(vendor.vendorCode);
      setList(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (vendor.vendorCode && !isLoading && !list.length) {
      fetchData();
    }
  }, [fetchData, isLoading, list.length, vendor.vendorCode]);

  const onGoToChecklistDetails = item => {
    setFocusedChecklist(item, vendor);
    setTimeout(() => {
      history.push(`${CHECKLIST_DETAILS}`);
    }, 500);
  };

  return (
    <>
      <LoadingHolder isLoading={isLoading} />

      {!isLoading && !list.length && (
        <NoContentTab icon="icon-tasks" hideButton>
          <p>
            {t('global.no-content')}
          </p>
        </NoContentTab>
      )}

      {list.length > 0 && (
        <ChecklistsContainer>
          {list.map((item, index) => {
            const itemDate = !item.lastFill
              ? t('checklist.no-record')
              : moment(item.lastFill).format('DD ̸̸  MM ̸̸  YYYY');

            return (
              <Card
                key={index}
                title={item.indLiquidacaoDesc}
                subtitle={`${item.codCheckList}`}
                additional={`${item.numberOfQuestions} questões`}
                icon="icon-tasks"
                iconBackGround="colorPrimary"
                action={() => onGoToChecklistDetails(item)}
                footerIcon="icon-clock"
                footerColor="colorInfo"
                footerContent={t('checklist.last-fill.on', { value: itemDate })}
              />
            );
          })}
        </ChecklistsContainer>
      )}
    </>
  );
}
