import { API_PRODUCER_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getGrainTypes() {
  const response = await request(
    `${API_PRODUCER_URL}/GrainProposal/GetGrainTypes`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function getContacts() {
  const response = await request(
    `${API_PRODUCER_URL}/GrainProposal/GetContacts`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function getCenters() {
  const response = await request(
    `${API_PRODUCER_URL}/GrainProposal/GetCenters`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function createGrainProposal(payload) {
  const response = await request(
    `${API_PRODUCER_URL}/GrainProposal/CreateGrainProposal`,
    {
      method: 'POST',
      payload,
    },
  );

  return response;
}
