import React from 'react';
import { PROFILE, ACCOUNT_PASSWORD_RECOVERY } from 'config/routes';
import t from 'components/i18n';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import { PasswordField } from 'components/Forms/Fields';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';
import { Link, LinkHolder } from 'components/SideBox/SideBox.styled';

export default function DeactivateAccountForm({
  user,
  history,
  deleteUser,
  authenticated,
  handleChange,
  handleBlur,
  handleSubmit,
  resetForm,
  isValid,
  values,
  errors,
  touched,
}) {
  return (
    <Form autoComplete="off">
      {!authenticated && (
        <>
          <Row>
            <Col md={12}>
              <h4>
                {t('menu.profile.accountData.confirmPassword.title')}
              </h4>

              <p className="info">
                {t(
                  'menu.profile.accountData.confirmPassword.description',
                )}
              </p>

              <br />

              <PasswordField
                field="password"
                label={t('menu.profile.accountData.deactivateAccount.confirmPassword.inputSingleLine.label')}
                placeholder={t('menu.profile.accountData.deactivateAccount.confirmPassword.inputSingleLine.placeholder')}
                onChange={handleChange}
                onBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
              <LinkHolder>
                <Link to={ACCOUNT_PASSWORD_RECOVERY} primary>
                  {t(
                    'menu.profile.accountData.confirmPassword.link.forgotPassword',
                  )}
                </Link>
              </LinkHolder>
            </Col>
          </Row>

          <Separator />

          <BtnGroup>
            <Button
              key="deactivate"
              onClick={() => handleSubmit()}
              disabled={!isValid}
            >
              {t(
                'menu.profile.accountData.confirmPassword.buttonPrimary',
              )}
            </Button>
            <Button
              key="cancel"
              onClick={() =>
                history.push(
                  `${PROFILE}/account-settings/account-data`,
                )
              }
              className="white"
            >
              {t(
                'menu.profile.accountData.confirmPassword.buttonSecondary',
              )}
            </Button>
          </BtnGroup>
        </>
      )}
    </Form>
  );
}
