import React from 'react';
import { INDICATORS } from 'config/routes';
import Card from 'components/Card';
import { OverviewStyled } from './Overview.styled';
import t from 'components/i18n';

export default function DashboardPreview({ title, history }) {
  return (
    <OverviewStyled>
      <strong className="title">{title}</strong>

      <div className="flex cardWrapper">
        <Card
          icon="icon-chart-analytics"
          title={t('nextdashboard.card.open-flock.title')}
          subtitle={t('nextdashboard.card.open-flock.subtitle')}
          additional={t('nextdashboard.card.open-flock.aditional')}
          iconBackGround="colorInfo"
          action={() => history.push(`${INDICATORS}/opened`)}
          noShadow
        />

        <Card
          icon="icon-chart-analytics"
          title={t('nextdashboard.card.closed-flock.title')}
          subtitle={t('nextdashboard.card.closed-flock.subtitle')}
          additional={t('nextdashboard.card.closed-flock.aditional')}
          iconBackGround="colorDarkest"
          action={() => history.push(`${INDICATORS}/closed`)}
          noShadow
        />
      </div>
    </OverviewStyled>
  );
}
