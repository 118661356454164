import styled from 'styled-components';
import theme from 'components/GlobalStyles/theme';

import { Alert as AlertComponent, utils } from 'styled-minimal';

const { colors, palette } = utils.getTheme(theme);
const variants = { ...colors, ...palette };

AlertComponent.displayName = 'AlertComponent';

export const AlertWrapper = styled(AlertComponent)`
  display: flex;
  line-height: 1;
  padding: 0;
  padding-right: 20px;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  margin-left: -10px;
  word-break: break-word;
  .icon-close {
    color: #fff;
  }
`;

export const AlertIcon = styled.div`
  align-items: center;
  background-color: ${({ variant }) => variants[variant]};
  color: #fff;
  display: flex;
  padding: ${utils.spacer(2)};
  font-size: 20px;
`;

export const AlertButton = styled.button`
  /* background-color: ${({ variant }) => variants[variant]}; */
  background-color: transparent;
  border: 0 none;
  cursor: pointer;
  color: #ccc;
  pointer-events: all;
  position: absolute;
  right: ${utils.spacer(1)};
  top: ${utils.spacer(2)};
  font-size: 20px;

  &:hover &{
    color: #fff;
  }
`;
