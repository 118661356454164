// @flow

import React, { useState, useEffect, useContext } from 'react';
// Contexts
import { SwineContext } from 'shared/contexts';

// components
import Box from 'components/Container';
import SwineMenu from 'containers/AgroBRF/FlockList/Swine/SwineMenu';
import Icon from 'components/Icon';

import { scrollToTop } from 'shared/utils/Helpers';
import {
  getBoxType,
  getFeedIcon,
  getFlockIcon,
  getHeading,
} from 'containers/AgroBRF/FlockList/Flock/Flock.utils';
import {
  Container,
  ListItem,
  Separator,
  FlockDetailsMobileWrapper,
} from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import SwineRecordContent from './SwineRecordContent';
import SwineMortalityModals from './Mortality/SwineMortalityModals';
import SwineMedicationModals from './Medication/SwineMedicationModals';
import type { TFlockProps } from './typings';

export default function SwineRecord(props: TFlockProps) {
  const [selectedKey, setSelectedKey] = useState('deceasedSummary');
  const [displayExcludeModal, setDisplayExcludeModal] = useState(
    false,
  );
  const [updateItems, setUpdateItems] = useState(false);
  const [sendToConfirmation, setSendToConfirmation] = useState(
    props.sendToConfirmation,
  );

  const swineContext = useContext(SwineContext);
  const {
    deceasedResponse,
    medicationResponse,
    success,
    selectedDeceased,
    selectedMedicationId,
    removed,
  } = swineContext;
  const { data } = props;
  const {
    setDeceasedResponse,
    setMedicationResponse,
    setSelectedDeceased,
    setSelectedMedicationId,
    setRemoved,
    setResetForm,
    setSuccess,
    setFavoriteCandidate,
  } = swineContext.actions;
  const { isGtMobile, isMobile, isTablet } = props.responsive;

  let flockDetailsTop;

  useEffect(() => {
    if (selectedKey === 'addDeceased') {
      setResetForm(true);
    }

    setSendToConfirmation(selectedKey === 'deceasedConfirmation');

    scrollToTop(document.getElementById('main'));
  }, [selectedKey]);

  useEffect(() => {
    if (selectedDeceased || selectedMedicationId) {
      setUpdateItems(false);
    }
  }, [selectedDeceased, selectedMedicationId]);

  useEffect(() => {
    scrollToTop(document.getElementById('main'));
  }, [sendToConfirmation]);

  const boxProps = {
    slim: true,
    [getBoxType(selectedKey)]: true,
  };

  function getTabletToggler(type) {
    return (
      <button
        onClick={() => setSelectedKey(type)}
        type="button"
        className={`unstyled-button tablet-menu-item ${
          selectedKey === type ? 'active' : ''
        }`}
      >
        <Icon
          className="tablet-menu-icon"
          name={getFlockIcon(type)}
        />
        <span className="tablet-menu-label">{getHeading(type)}</span>
      </button>
    );
  }

  function getToggler(type) {
    const menuItemProps = {
      className: 'menu-item',
    };

    if (isGtMobile) {
      menuItemProps.className = `menu-item ${
        selectedKey === type ? 'active' : ''
      }`;
    }

    return (
      <div {...menuItemProps}>
        {type !== 'slaughter' ? (
          <Icon className="menu-icon" name={getFlockIcon(type)} />
        ) : (
          <Icon
            className="menu-icon"
            name={getFeedIcon(data.flockType)}
          />
        )}

        <ListItem
          onClick={() => {
            setSelectedKey(type);
            setSelectedDeceased(null);
            setSelectedMedicationId(null);
          }}
          role="button"
          className="animated fadeIn"
        >
          <div className="list-item-content">
            <div className="list-item-heading">
              {getHeading(type)}
            </div>
          </div>
          <Icon name="chevron icon-chevron-right" />
        </ListItem>
      </div>
    );
  }

  function getFlockDetails() {
    const top = (
      <header className="flock-details-header">
        <h2 className="flock-details-heading">
          {getHeading(selectedKey)}
        </h2>
      </header>
    );

    const content = (
      <SwineRecordContent
        data={data}
        selectedKey={selectedKey}
        setSelectedKey={setSelectedKey}
        sendToConfirmation={sendToConfirmation}
        setSendToConfirmation={setSendToConfirmation}
        updateItems={updateItems}
        setUpdateItems={setUpdateItems}
        removed={removed}
        setRemoved={setRemoved}
        setSuccess={setSuccess}
        setDisplayExcludeModal={setDisplayExcludeModal}
        setMedicationResponse={setMedicationResponse}
        setFavoriteCandidate={setFavoriteCandidate}
      />
    );

    const details = (
      <div className="flock-details-container">
        {isMobile && (
          <>
            <FlockDetailsMobileWrapper className="mobile-wrapper">
              {content}
            </FlockDetailsMobileWrapper>
          </>
        )}

        {isGtMobile && (
          <>
            {top}
            <Separator />
            {flockDetailsTop}
            {content}
          </>
        )}
      </div>
    );

    return (
      <div className="flock-details">
        {isMobile && details}
        {isGtMobile && (
          <Box {...boxProps} style={{ maxWidth: '676px' }}>
            {details}
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
      <Container className="flock-container flock-container__swine-record">
        {/* Sidebar menu */}
        <SwineMenu
          flockId={props.match.params.id}
          getHeading={getHeading}
          getTabletToggler={getTabletToggler}
          getToggler={getToggler}
          isTablet={isTablet}
          isMobile={isMobile}
          isGtMobile={isGtMobile}
          selectedKey={selectedKey}
          setSelectedDeceased={setSelectedDeceased}
          setSelectedMedicationId={setSelectedMedicationId}
          getFlockDetails={getFlockDetails}
          type={data.flockStatus}
          data={data}
          user={props.user}
        />
        {/* \ Sidebar Menu */}

        {isGtMobile && getFlockDetails()}

        {/* Mortality modals */}
        <SwineMortalityModals
          success={success}
          setSelectedKey={setSelectedKey}
          deceasedResponse={deceasedResponse}
          setDeceasedResponse={setDeceasedResponse}
          updateItems={updateItems}
          setUpdateItems={setUpdateItems}
          selectedDeceased={selectedDeceased}
          setSelectedDeceased={setSelectedDeceased}
          displayExcludeModal={displayExcludeModal}
          setDisplayExcludeModal={setDisplayExcludeModal}
          setRemoved={setRemoved}
          removed={removed}
        />
        {/* \ Mortality modals */}

        {/* Medication modals */}
        <SwineMedicationModals
          success={success}
          setSelectedKey={setSelectedKey}
          medicationResponse={medicationResponse}
          setMedicationResponse={setMedicationResponse}
          updateItems={updateItems}
          setUpdateItems={setUpdateItems}
          setSelectedMedicationId={setSelectedMedicationId}
          selectedMedicationId={selectedMedicationId}
          displayExcludeModal={displayExcludeModal}
          setDisplayExcludeModal={setDisplayExcludeModal}
          setRemoved={setRemoved}
          removed={removed}
        />
        {/* \ Medication modals */}
      </Container>
    </>
  );
}
