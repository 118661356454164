// @flow

import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

import type { TScheduleObject } from './typings';

export async function getSchedule(): Promise<TScheduleObject> {
  const response = await request(`${API_URL}/Schedule/GetSchedule`, {
    method: 'GET',
  });

  return response.data;
}
