import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';
import { fixFileName } from 'shared/utils/Helpers/fixFileName';

export async function getInsurancePolicy() {
  const response = await request(
    `${API_URL}/InsurancePolicy/GetInsurancePolicy`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function uploadInsurance(payload) {
  const fd = new FormData();
  fd.append('file', payload, fixFileName(payload));

  const response = await request(
    `${API_URL}/InsurancePolicy/Upload`,
    {
      headers: {
        'Content-Type': undefined,
        Authorization: null,
      },
      method: 'POST',
      payload: fd,
    },
  );
  return response.data;
}

export async function deleteFile(file) {
  const response = await request(
    `${API_URL}/Blob/DeleteFile?fileName=${file}`,
    {
      method: 'DELETE',
    },
  );
  return response.data;
}

export async function saveInsurancePolicy(payload) {
  const response = await request(`${API_URL}/InsurancePolicy/Save`, {
    method: 'POST',
    payload,
  });

  return response;
}

export async function deleteInsurance(id) {
  const response = await request(
    `${API_URL}/InsurancePolicy/Delete?insuranceId=${id}`,
    {
      method: 'DELETE',
    },
  );
  return response;
}
