import React, { createContext, useReducer } from 'react';

const SwineContext = createContext();

const initialState = {
  selectedDeceased: null,
  selectedMedicationId: null,
  selectedHouse: null,
  medicationResponse: null,
  deceasedResponse: null,
  success: false,
  removed: false,
  resetForm: false,
  favoriteCandidate: null,
};

const types = {
  SET_MEDICATION_RESPONSE: 'SET_MEDICATION_RESPONSE',
  SET_DECEASED_RESPONSE: 'SET_DECEASED_RESPONSE',
  SET_SELECTED_DECEASED: 'SET_SELECTED_DECEASED',
  SET_SELECTED_MEDICATION_ID: 'SET_SELECTED_MEDICATION_ID',
  SET_SUCCESS: 'SET_SUCCESS',
  SET_REMOVED: 'SET_REMOVED',
  SET_FAVORITE_CANDIDATE: 'SET_FAVORITE_CANDIDATE',
  RESET: 'RESET',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_SELECTED_DECEASED:
      return {
        ...state,
        selectedDeceased: action.payload,
      };
    case types.SET_SELECTED_MEDICATION_ID:
      return {
        ...state,
        selectedMedicationId: action.payload,
      };
    case types.SET_SELECTED_HOUSE:
      return {
        ...state,
        selectedHouse: action.payload,
      };
    case types.SET_DECEASED_RESPONSE:
      return {
        ...state,
        deceasedResponse: action.payload,
      };
    case types.SET_MEDICATION_RESPONSE:
      return {
        ...state,
        medicationResponse: action.payload,
      };
    case types.SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case types.SET_REMOVED:
      return {
        ...state,
        removed: action.payload,
      };
    case types.RESET:
      return {
        ...state,
        resetForm: action.payload,
      };
    case types.SET_FAVORITE_CANDIDATE:
      return {
        ...state,
        favoriteCandidate: action.payload,
      };
    default:
      console.log('Unknown reducer type.');
      return {
        ...state,
      };
  }
};

const actions = dispatch => ({
  setSelectedDeceased: item =>
    dispatch({
      type: types.SET_SELECTED_DECEASED,
      payload: item,
    }),
  setSelectedMedicationId: item =>
    dispatch({
      type: types.SET_SELECTED_MEDICATION_ID,
      payload: item,
    }),
  setSelectedHouse: item =>
    dispatch({
      type: types.SET_SELECTED_HOUSE,
      payload: item,
    }),
  setDeceasedResponse: response =>
    dispatch({
      type: types.SET_DECEASED_RESPONSE,
      payload: response,
    }),
  setMedicationResponse: response =>
    dispatch({
      type: types.SET_MEDICATION_RESPONSE,
      payload: response,
    }),
  setSuccess: response =>
    dispatch({
      type: types.SET_SUCCESS,
      payload: response,
    }),
  setRemoved: response =>
    dispatch({
      type: types.SET_REMOVED,
      payload: response,
    }),
  setFavoriteCandidate: response =>
    dispatch({
      type: types.SET_FAVORITE_CANDIDATE,
      payload: response,
    }),
  setResetForm: response =>
    dispatch({
      type: types.RESET,
      payload: response,
    }),
  // reset: () =>
  //   dispatch({
  //     type: types.RESET,
  //   }),
});

const SwineContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SwineContext.Provider
      value={{
        ...state,
        dispatch,
        actions: actions(dispatch),
      }}
    >
      {props.children}
    </SwineContext.Provider>
  );
};

export { SwineContext, SwineContextProvider };
