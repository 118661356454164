import styled from 'styled-components';
import { FeedItem as FI } from 'components/FeedList';
import {
  boxShadow,
  fontFamilyText,
  fontFamilyTextBold,
  colorGrayLightest,
  colorGrayLighter,
  colorGrayLight,
  colorPrimary,
  breakpointLargeDesktop,
  colorGray,
  colorClear,
  colorGrayDarkest,
  breakpointTablet,
  DEFAULT_SIZE,
} from 'components/GlobalStyles/variables';

export const NextMedicationStyled = styled.section`
  margin: 20px 20px 60px 20px;

  .container-content {
    max-width: 654px;
  }
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;
  margin: ${props =>
    props.gap && `${props.gap}px -${DEFAULT_SIZE * 2}px`};
`;

export const Subheading = styled.strong`
  color: ${colorGrayDarkest};
  line-height: 24px;
  font-size: ${props => (props.small ? `14` : `16`)}px;
`;

export const MedicationContainer = styled.section`
  position: relative;
  padding: 16px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};
  max-width: 630px;

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px 20px 60px 20px;
  }

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }

  ${Subheading} {
    display: block;
    font-family: ${fontFamilyTextBold};
    font-weight: normal;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }
`;

export const Container = styled.div`
  position: relative;
  padding: 12px ${DEFAULT_SIZE * 2}px;
  border: 1px solid ${colorGrayLightest};
  border-radius: ${DEFAULT_SIZE}px;
  margin-bottom: ${DEFAULT_SIZE * 2}px;
  box-shadow: 0px 4px 8px rgba(37, 48, 71, 0.16);
  transition: border-color 0.3s ease;

  &:hover {
    border-color: ${colorGrayLighter};
  }
`;

export const ItemContainer = styled.div`
  .feed-history-holder {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    margin-top: 16px;

    > div {
      padding-right: 0 !important;
      margin-bottom: 16px;
    }

    &.open {
      max-height: 5000px;
    }
  }

  .feed-show-history {
    margin-top: 0;

    button {
      display: flex;
      align-items: center;

      .mini-heading {
        margin: 0;
        margin-left: 30px;
        color: ${colorPrimary};
      }

      .feed-holder-icon {
        font-size: 24px;
        color: ${colorGrayLighter};
      }
    }
  }

  .columns {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (min-width: ${breakpointLargeDesktop}px) {
      flex-direction: row;
    }
  }
`;

export const FeedItem = styled(FI)`
  padding-bottom: 0;

  .align-right {
    font-size: 12px;
    text-align: right;
    // padding-right: 0;
  }

  strong {
    line-height: 24px;
    margin-bottom: 8px;
    display: block;
    font-size: 16px;
    letter-spacing: 0.15px;
  }

  p {
    font-size: 13px;
    color: ${colorGrayLight};
    margin: 0 0 5px;

    &.small {
      font-size: 13px;
    }

    .strong {
      font-family: ${fontFamilyTextBold};
    }
  }
`;
