// @flow

import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';

// components
import Container from 'components/Container';

// service
import { saveMedications } from 'containers/NextBRF/NextSwineRecord/serivce';

// validate
import validate from 'components/Forms/Fields/validate';

// form
import MedicationForm from './MedicationForm';
import t from 'components/i18n';

export default function AddMedication({
  medication = null,
  houses,
  flockID,
  goTo,
  setSelectedMedicationId,
  selectedMedicationId,
  sendToConfirmation,
  setSendToConfirmation,
  isEditing,
  setMedicationResponse,
  setSuccess,
  setFavoriteCandidate,
}) {
  const [message, setMessage] = useState('');
  const [selectedValues, setSelectedValues] = useState(null);
  const [loadingForm, setLoadingForm] = useState(true);

  const validateSchema = () => {
    return yup.object().shape({
      house: yup.object().required(t('global.status.required-field')),
      treatmentPeriod: yup.object().required(t('global.status.required-field')),
      material: yup.string().required(t('global.status.required-field')),
      laboratory: yup.string().required(t('global.status.required-field')),
      vaccineBatch: yup.string().required(t('global.status.required-field')),
      quantity: yup.number().required(t('global.status.required-field')),
      healthProblem: yup.string().required(t('global.status.required-field')),
      quantityTreated: yup.string().required(t('global.status.required-field')),
    });
  };

  const initialValues = {
    house: '',
    treatmentPeriod: '',
    material: '',
    laboratory: '',
    vaccineBatch: '',
    quantity: '',
    healthProblem: '',
    quantityTreated: '',
  };

  function submitForm(values, { setSubmitting, setErrors }) {
    const formData = {
      id: values.id || 0,
      houseId: values.house.id,
      houseCode: values.house.value,
      startedDate: values.treatmentPeriod.startDate,
      endedDate: values.treatmentPeriod.endDate,
      flockId: flockID,
      materialTypeId: values.material.id,
      materialName: values.material.description,
      measurementUnit: values.material.measurement,
      laboratoryTypeId: values.laboratory.id,
      laboratoryName: values.laboratory.description,
      vaccineBatch: values.vaccineBatch,
      quantity: values.quantity,
      quantityTreated: values.quantityTreated,
      healthProblemTypeId: values.healthProblem.id,
      healthProblemName: values.healthProblem.name,
      animalType: 'swine',
    };

    async function saveData(val) {
      setSubmitting(true);
      try {
        const response = await saveMedications(val);

        if (response.data) {
          setMedicationResponse(response.message);
          setFavoriteCandidate(val);
          setSuccess(response.success);
        } else {
          setSuccess(false);
          throw Error(response);
        }
      } catch (error) {
        console.error(error);
      }
      setSubmitting(false);
      goTo('medicationSummary');
    }

    saveData(formData);
  }

  useEffect(() => {
    if (medication) {
      setSelectedValues({
        id: medication.id,
        houseId: medication.houseId,
        treatmentPeriod: {
          startDate: medication.startedDate,
          key: 'date',
          endDate: medication.endedDate,
        },
        material: {
          description: medication.materialName,
          id: medication.materialTypeId,
          measurement: medication.measurementUnit,
          code:
            medication.materialCode.indexOf('000000000000') > -1
              ? medication.materialCode.substr(12)
              : medication.materialCode,
        },
        laboratory: {
          description: medication.laboratoryName,
          id: medication.laboratoryTypeId,
        },
        vaccineBatch: medication.vaccineBatch,
        quantity: medication.quantity,
        healthProblem: {
          name: medication.healthProblemName,
          id: medication.healthProblemTypeId,
        },
        quantityTreated: medication.quantityTreated,
      });
      setLoadingForm(false);
    }
  }, [medication]);

  return (
    houses.length > 0 &&
    (!loadingForm || !isEditing) && (
      <Container hideDetail>
        <Formik
          initialValues={medication ? selectedValues : initialValues}
          validate={validate(validateSchema)}
          onSubmit={submitForm}
          render={formikProps => (
            <MedicationForm
              {...formikProps}
              houses={houses}
              flockID={flockID}
              message={message}
              setMessage={setMessage}
              goTo={goTo}
              medication={medication}
              selectedMedicationId={selectedMedicationId}
              setSelectedMedicationId={setSelectedMedicationId}
              sendToConfirmation={sendToConfirmation}
              setSendToConfirmation={setSendToConfirmation}
              isEditing={isEditing}
            />
          )}
        />
      </Container>
    )
  );
}
