// @flow

import React, { useState } from 'react';
import { PROFILE } from 'config/routes';
import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';
import Container from 'components/Container';
import t from 'components/i18n';
import Authenticate from './Authenticate';
import Verify from './Verify';
import Recover from './Recover';
import { ProfileStyled } from '../../../ProfileAndSettings.styled';

export default function AccountPasswordRecover(props) {
  const [stage, setStage] = useState(1);

  return (
    <>
      <Breadcrumb
        history={props.history}
        backTo={() =>
          props.history.push(
            `${PROFILE}/account-settings/personal-data`,
          )
        }
        links={[
          `${PROFILE}/account-settings`,
          `${PROFILE}/account-settings`,
          `${PROFILE}/account-settings/account-data`,
        ]}
        path={[
          t('menu.profile.title'),
          t('menu.profile.sectionAccountConfig.Title'),
          t('menu.profile.accountData.title'),
          t('menu.profile.accountData.recoveryPassword.title'),
        ]}
      />

      <Top>
        <h2>{t('recoveryPassword.buttonPrimary')}</h2>
      </Top>

      <ProfileStyled>
        <Container className="container" hideDetail>
          {stage === 1 && (
            <Authenticate
              {...props}
              setStage={setStage}
              className="animated fadeIn"
            />
          )}

          {stage === 2 && (
            <Verify
              {...props}
              setStage={setStage}
              className="animated fadeIn"
            />
          )}

          {stage === 3 && (
            <Recover
              {...props}
              setStage={setStage}
              className="animated fadeIn"
            />
          )}
        </Container>
      </ProfileStyled>
    </>
  );
}
