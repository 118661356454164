import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import t from 'components/i18n';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { cleanUp } from 'components/MaskedInput/mask';
import Modal from 'components/Forms/FormModal/Modal';
import ToggleOptions from 'components/ToggleOptions';
import {
  Field,
  Label,
  Hint,
  Radio,
  Checkbox,
} from '@zendeskgarden/react-forms';
import { docNumberValidate } from 'containers/AgroBRF/Collaborator/service';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import {
  Form,
  Subheading,
  GreyBox,
  WhiteBox,
} from 'components/Forms/Forms.styled';
import { InputField } from 'components/Forms/Fields';
import { Separator } from '@zendeskgarden/react-dropdowns';
import { profileKeyMapper, docNumberValidateCpf } from 'utils';

export default function CollaboratorForm(props) {
  const [displayModal, setDisplayModal] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [readTerms, setReadTerms] = useState(false);
  const [open, setOpen] = useState();
  const [displayAlert, setDisplayAlert] = useState(false);

  const {
    availableVendors,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isEditing,
    resetForm,
    values,
    errors,
    touched,
  } = props;

  function renderRadioOptions(args, name) {
    return args.map((item, index) => (
      <Field key={index}>
        <Radio
          name={name}
          value={item.value}
          checked={values[name] === item.value}
          onChange={e => setFieldValue(name, e.target.value)}
        >
          <Label>{profileKeyMapper(item.label)}</Label>
          <Hint>{item.description}</Hint>
        </Radio>
        <br />
        {index + 1 !== args.length && <Separator />}
      </Field>
    ));
  }

  const radioOptions = [
    {
      value: 'Administrador',
      label: t(
        'menu.employees.inviteEmployee.sectionEmployeeProfile.radio.administrador.label',
      ),
      description: t(
        'menu.employees.inviteEmployee.sectionEmployeeProfile.radio.administrador.description',
      ),
    },
    {
      value: 'Editor',
      label: t(
        'menu.employees.inviteEmployee.sectionEmployeeProfile.radio.editor.label',
      ),
      description: t(
        'menu.employees.inviteEmployee.sectionEmployeeProfile.radio.editor.description',
      ),
    },
    {
      value: 'Assistente',
      label: t(
        'menu.employees.inviteEmployee.sectionEmployeeProfile.radio.assistant.label',
      ),
      description: t(
        'menu.employees.inviteEmployee.sectionEmployeeProfile.radio.assistant.description',
      ),
    },
  ];

  const checkBoxOptions = useMemo(() => values.vendors, [values]);

  const verifyDocumentNumber = useCallback(
    async cpf => {
      try {
        const response = await docNumberValidate(cleanUp(cpf));
        if (!response.success) {
          setFieldValue('verifyCpfMessage', response.message);
          throw Error(response.message);
        }
        setFieldValue('verifyCpfMessage', '');
      } catch (error) {
        console.error(error);
      }
    },

    [setFieldValue],
  );

  useEffect(() => {
    if (values.name === '') {
      setIsNewForm(true);
    }

    setFieldValue('validVendors', true);

    values.vendors.map((_, index) => {
      setFieldValue(`vendors.${index}.validHouses`, true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // check all users and houses values
    // if is a new form
    if (isNewForm && checkBoxOptions.length > 0) {
      checkBoxOptions.forEach((option, optionIndex) => {
        const vendorHouses = checkBoxOptions[optionIndex].houses;

        setFieldValue(`vendors.${optionIndex}.isChecked`, false);

        if (vendorHouses && vendorHouses.length > 0) {
          vendorHouses.forEach((vendor, vendorIndex) => {
            setFieldValue(
              `vendors.${optionIndex}.houses.${vendorIndex}.isChecked`,
              true,
            );
          });
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewForm]);

  useEffect(() => {
    if (checkBoxOptions.length > 0) {
      const checked = checkBoxOptions.filter(item => item.isChecked);

      setFieldValue(`validVendors`, !!checked.length);

      checkBoxOptions.map((vendor, checkedIndex) => {
        const checkedHouses = vendor.houses.filter(
          house => house.isChecked,
        );

        if (!checkedHouses.length) {
          setFieldValue(`vendors.${checkedIndex}.isChecked`, false);
        }

        if (!vendor.isChecked) {
          vendor.houses.forEach((house, houseIndex) => {
            setFieldValue(
              `vendors.${checkedIndex}.houses.${houseIndex}.isChecked`,
              true,
            );
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBoxOptions]);

  useEffect(() => {
    if (readTerms) {
      setFieldValue('acceptedTerms', readTerms);
    }
  }, [readTerms, setFieldValue]);

  useEffect(() => {
    if (availableVendors) {
      setFieldValue('vendors', availableVendors);
    }
  }, [availableVendors, setFieldValue]);

  return (
    <>
      <Form autoComplete="off">
        <Subheading>
          {t('menu.employees.inviteEmployee.form.title')}
        </Subheading>

        <Row>
          <Col>
            {/* Nome */}
            <InputField
              type="text"
              field="name"
              label={t(
                'menu.employees.inviteEmployee.input.employeeName.label',
              )}
              placeholder={t(
                'menu.employees.inviteEmployee.input.employeeName.placeholder',
              )}
              onBlur={handleBlur}
              onChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            {/* Nome */}
            <InputField
              masked
              type="cpf"
              field="documentNumber"
              label={t(
                'menu.employees.inviteEmployee.input.employeeDocumentNumber.label',
              )}
              placeholder={t(
                'menu.employees.inviteEmployee.input.employeeDocumentNumber.placeholder',
              )}
              onBlur={e => {
                handleBlur(e);
                verifyDocumentNumber(e.target.value);
                setOpen(docNumberValidateCpf(e.target.value));
                setDisplayAlert(docNumberValidateCpf(e.target.value));
              }}
              onChange={e => {
                handleChange(e);
              }}
              setState={e => {
                setFieldValue('documentNumber', e);
              }}
              disabled={isEditing}
              values={values}
              errors={errors}
              touched={touched}
            />

            {open === false && displayAlert === false ? (
              <>
                <Field style={{ color: 'red' }}>
                  <p style={{ color: 'red', fontWeight: 'bold' }}>
                    {t('O CPF nao esta correto!')}
                  </p>
                </Field>
              </>
            ) : null}

            {/* \ Nome */}
          </Col>

          <Col sm={6}>
            {/* Email */}
            <InputField
              masked
              type="phone"
              field="phoneNumber"
              label={t(
                'menu.employees.inviteEmployee.input.employeeMobileNumber.label',
              )}
              placeholder={t(
                'menu.employees.inviteEmployee.input.employeeMobileNumber.placeholder',
              )}
              onBlur={handleBlur}
              onChange={e => {
                handleChange(e);
              }}
              setState={e => {
                setFieldValue('phoneNumber', e);
              }}
              values={values}
              errors={errors}
              touched={touched}
            />
            {/* \ Email */}
          </Col>
        </Row>

        <br />

        <Subheading>
          {t(
            'menu.employees.inviteEmployee.sectionEmployeeAccess.title',
          )}
        </Subheading>

        <Row>
          <Col>
            {checkBoxOptions.map((vendor, vendorIndex) => (
              <Field key={vendor.id} name={`vendors.${vendorIndex}`}>
                <ToggleOptions
                  label={t(
                    'menu.employees.inviteEmployee.sectionEmployeeAccess.switch.growerCode.label',
                    { growerCode: vendor.vendorCode },
                  )}
                  checked={vendor.isChecked}
                  onChange={e => {
                    setFieldValue(
                      `vendors.${vendorIndex}.isChecked`,
                      !values.vendors[vendorIndex].isChecked,
                    );
                  }}
                >
                  {vendor.houses &&
                    vendor.houses.length > 0 &&
                    vendor.houses.map((house, houseIndex) => (
                      <Field
                        key={houseIndex}
                        name={`vendors.${vendorIndex}.houses.${houseIndex}`}
                      >
                        <Checkbox
                          key={house.id}
                          checked={house.isChecked}
                          onChange={e => {
                            setFieldValue(
                              `vendors.${vendorIndex}.houses.${houseIndex}.isChecked`,
                              !values.vendors[vendorIndex].houses[
                                houseIndex
                              ].isChecked,
                            );
                          }}
                        >
                          <Label className="noCaps">
                            {t(
                              'menu.employees.inviteEmployee.sectionEmployeeAccess.checkbox.house.label',
                              { value: house.houseCode },
                            )}
                          </Label>
                        </Checkbox>
                      </Field>
                    ))}
                </ToggleOptions>
              </Field>
            ))}
          </Col>
        </Row>

        <br />
        <br />

        <Subheading>
          {t(
            'menu.employees.inviteEmployee.sectionEmployeeProfile.title',
          )}
        </Subheading>

        <Row>
          <Col>
            <div role="group" aria-label="radio-options">
              {renderRadioOptions(radioOptions, 'profile')}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <GreyBox>
              <p>
                {t(
                  'menu.employees.inviteEmployee.sectionTermsofUse.description-1',
                )}
                <strong onClick={() => setDisplayModal(true)}>
                  {' '}
                  {t(
                    'menu.employees.inviteEmployee.sectionTermsofUse.description-link',
                  )}{' '}
                </strong>
                {t(
                  'menu.employees.inviteEmployee.sectionTermsofUse.description-2',
                )}
              </p>
              <WhiteBox>
                <Field>
                  <Checkbox
                    name="read"
                    checked={readTerms}
                    onChange={() => setReadTerms(!readTerms)}
                  >
                    <Label>
                      <p>
                        {t(
                          'menu.employees.inviteEmployee.sectionTermsofUse.checkbox',
                        )}
                      </p>
                    </Label>
                  </Checkbox>
                </Field>
              </WhiteBox>
            </GreyBox>
          </Col>
        </Row>
        <Row>
          <Col sm={6} />
          <Col sm={6}>
            {/* \ Botões */}
            <BtnGroup>
              <Button
                key="submitForm"
                onClick={e => handleSubmit(e)}
                disabled={
                  !readTerms || !props.isValid || props.isValidating
                }
              >
                {t('menu.employees.inviteEmployee.buttonPrimary')}
              </Button>
              {Object.keys(props.user).length > 0 ? (
                <Button
                  key="clean"
                  className="white"
                  onClick={() => {
                    props.setEditUser(false);
                  }}
                >
                  {t('menu.employees.inviteEmployee.buttonSecondary')}
                </Button>
              ) : (
                <Button
                  key="clean"
                  className="white"
                  onClick={() => {
                    resetForm();
                  }}
                >
                  {t('menu.employees.inviteEmployee.buttonSecondary')}
                </Button>
              )}
            </BtnGroup>
            {/* \ Botões */}
          </Col>
        </Row>

        <Modal
          header={t(
            'menu.employees.inviteEmployee.termsOfResponsability.title',
          )}
          opened={displayModal}
          onCloseModal={() => setDisplayModal(false)}
          color="colorPrimary"
          noButtons
          warning
          style={{ width: '480px', maxWidth: '480px' }}
        >
          <p className="noMargin">
            {t(
              'menu.employees.inviteEmployee.termsOfResponsability.description',
            )}
          </p>
          <br />
          <br />
        </Modal>
      </Form>
    </>
  );
}
