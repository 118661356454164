import React, { useState } from 'react';

import VisitForm from './VisitForm';
import Confirmation from './Confirmation';

export default function Visit(props) {
  const { history, user } = props;

  const [data, setData] = useState();

  return data ? (
    <Confirmation data={data} history={history} user={user} />
  ) : (
    <VisitForm onSubmit={setData} history={history} user={user} />
  );
}
