import React, { useState, useEffect } from 'react';
import t from 'components/i18n';
import Box from 'components/Container';
import SideBox from 'components/SideBox';
import ThemeSwitcher from 'components/GlobalStyles/ThemeSwitcher';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Menu, Item, Field } from '@zendeskgarden/react-dropdowns';
import { Button } from '@zendeskgarden/react-buttons';
import SmallButton from 'components/SmallButton';
import {
  Heading,
  TitleBlock,
} from 'components/SideBox/SideBox.styled';
import {
  SelectBox,
  DropdownBox,
} from 'components/Forms/Forms.styled';
import { FilterStyled, FilterBox, FixedField } from './Filter.styled';

export default function ModalFilter(props) {
  const [openSideBox, setOpenSideBox] = useState(false);
  const [selected, setSelected] = useState({});
  const themeSwitcher = ThemeSwitcher();

  function applyFilters() {
    if (props.customFilters !== undefined) {
      props.customFilters();
    } else if (Object.keys(selected).length) {
      props.list.map(item => {
        if (selected[item.label]) {
          item.action(selected[item.label]);
        }
      });
    } else {
      props.clearFilters();
    }
  }

  useEffect(() => {
    if (props.clearFilters) setSelected({});
  }, [props.clearFilters]);

  const cbSelection = x => {
    for (let index = 0; index < props.list.length; index++) {
      const element = props.list[index];
      const elChange = element.change !== undefined && element.change;

      if (
        (x == 'all' && elChange) ||
        (element.arrayList.indexOf(x) >= 0 && elChange)
      ) {
        element.action([element.label, x, setSelected, selected]);
      }
    }
  };

  return (
    <FilterStyled dark={props.dark} theme={props.theme}>
      <SmallButton
        type="button"
        className={`border-radius btn ${props.dark && 'dark'}`}
        bgColor="colorPrimary"
        textColor="colorClear"
        onClick={() => {
          setSelected({});
          setOpenSideBox(!openSideBox);
        }}
      >
        {t(props.buttonLabel)} <span className="icon icon-plus-circle" />
      </SmallButton>
      <SideBox
        displayBox={openSideBox}
        setDisplayBox={setOpenSideBox}
      >
        <Box className="side-box">
          {props.title && (
            <TitleBlock>
              <Heading>{props.title}</Heading>
            </TitleBlock>
          )}

          {props.list
            .filter(e => e.arrayList.length)
            .map((arr, index) => (
              <DropdownBox key={index} onSelect={cbSelection}>
                <FilterBox>
                  <Field>
                    <SelectBox>
                      {selected[arr.label]
                        ? selected[arr.label]
                        : arr.placeholder}
                    </SelectBox>
                  </Field>
                  <Menu>
                    <Item
                      key={0}
                      value="all"
                      onClick={() =>
                        setSelected({
                          ...selected,
                          [arr.label]: '',
                        })
                      }
                    >
                      {arr.placeholder}
                    </Item>
                    {arr.arrayList.map((option, i) =>
                      option && typeof option === 'object' ? (
                        <Item
                          key={i + 1}
                          value={t(option.key, { value: option.value })}
                          onClick={() =>
                            setSelected({
                              ...selected,
                              [arr.label]: t(option.key, { value: option.value }),
                            })
                          }
                        >
                          {t(option.key, { value: option.value })}
                        </Item>
                      ) : (
                        <Item
                          key={i + 1}
                          value={option}
                          onClick={() =>
                            setSelected({
                              ...selected,
                              [arr.label]: t(option),
                            })
                          }
                        >
                          {t(option)}
                        </Item>
                      ),
                    )}
                  </Menu>
                </FilterBox>
              </DropdownBox>
            ))}
          {/* \ Botões */}
          <FixedField className="bottom__buttons">
            <BtnGroup blue color={themeSwitcher}>
              <Button
                key="apply"
                onClick={() => {
                  applyFilters();
                  setOpenSideBox(false);
                }}
              >
                {t('global.button.apply')}
              </Button>
              <Button
                key="clean"
                onClick={() => {
                  props.clearFilters();
                  setOpenSideBox(false);
                }}
                className="white"
              >
                {t('global.button.clear')}
              </Button>
            </BtnGroup>
          </FixedField>
          {/* \ Botões */}
        </Box>
      </SideBox>
    </FilterStyled>
  );
}
