import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  DEFAULT_SIZE,
  colorGrayLight,
  colorGrayDarker,
  colorGrayDarkest,
} from 'components/GlobalStyles/variables';

export const TopText = styled.span`
  display: inline-block;
  color: ${colorGrayLight};
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 16px;
  margin-left: 16px;
`;

export const Container = styled.ul`
  max-height: 147px;
  overflow-x: auto;
  padding-right: 12px !important;
`;

export const Wrapper = styled.div`
  margin: 0;
  padding-bottom: 0;

  &:after {
    content: '';
    display: ${props => (props.border ? 'block' : 'none')};
    height: 1px;
    background-color: ${rgba(colorGrayDarkest, 0.12)};
    width: calc(100% - 56px);
    position: relative;
    left: 56px;
  }

  .top {
    align-self: flex-start;
    margin-top: 4px;
  }

  p {
    color: ${colorGrayDarker};
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 0 16px;

    &.additional {
      color: ${colorGrayLight};
      font-size: 12px;
    }
  }

  strong {
    font-size: 16px;
    padding: ${DEFAULT_SIZE * 2}px 0;

    &.list-title {
      font-size: 14px;
    }
  }

  .button-span {
    font-size: 20px;
    padding: 4px 8px;
    margin: -4px 0;
    cursor: pointer;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    margin-bottom: 12px;

    li {
      color: ${colorGrayDarker};
      font-size: 14px;
      margin: 0 0 ${DEFAULT_SIZE}px ${DEFAULT_SIZE * 2}px;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 12px;
        color: ${colorGrayLight};
      }
    }
  }
`;
