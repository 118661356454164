import React, { useState, useEffect, useCallback } from 'react';
import LoadingHolder from 'components/LoadingHolder';
import Container from 'components/Container';
import Toaster from 'components/Toaster';
import t from 'components/i18n';
import EditInsurance from 'containers/AgroBRF/InsurancePolicy/EditInsurance';
import FirstPage from 'containers/AgroBRF/InsurancePolicy/FirstPage';
import InsuranceDetails from 'containers/AgroBRF/InsurancePolicy/InsuranceDetails';
import {
  getInsurancePolicy,
  deleteInsurance,
} from 'containers/AgroBRF/InsurancePolicy/service';
import { Separator } from 'containers/AgroBRF/InsurancePolicy/InsurancePolicy.styled';

export default function InsuranceScreen({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [updateItems, setUpdateItems] = useState(false);
  const [files, setFiles] = useState([]);

  const getInsurance = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getInsurancePolicy();
      setData(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, []);

  const deleteInsurancePolicy = useCallback(async id => {
    try {
      const response = await deleteInsurance(id);
      if (response.success) {
        setIsLoading(true);
        setUpdateItems(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (updateItems || !editForm) {
      getInsurance();
    }
  }, [editForm, getInsurance, updateItems]);

  return (
    <>
      {editForm ? (
        <EditInsurance
          selected={data}
          setEditInsurance={setEditForm}
          files={files}
          setFiles={setFiles}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ) : (
        <>
          <Container className="container" slim>
            <h4>
              {t(
                'menu.profile.sectionDocumentation.buttonEnsurancePolicy',
              )}
            </h4>

            <Separator />
            <br />
            <LoadingHolder isLoading={isLoading} />

            {!isLoading &&
              (data && data.files && data.files.length > 0 ? (
                <InsuranceDetails
                  data={data}
                  history={history}
                  deleteInsurance={deleteInsurancePolicy}
                  setUpdateItems={setUpdateItems}
                  setEditForm={setEditForm}
                />
              ) : (
                <FirstPage
                  editForm={editForm}
                  history={history}
                  setEditForm={setEditForm}
                  setUpdateItems={setUpdateItems}
                />
              ))}
          </Container>
          <Toaster
            className="toaster__notification"
            isVisible={updateItems && !data}
            color="success"
            duration={3}
            delay={0}
          >
            <span className="icon icon-check" />
            {t('insurancePolicy.delete.confirmation.description')}
          </Toaster>
        </>
      )}
    </>
  );
}
