import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  colorClear,
  breakpointDesktop,
  breakpointTablet,
} from 'components/GlobalStyles/variables';

const width = '254px';

export const Item = styled.div`
  position: relative;
  color: ${rgba(colorClear, 0.56)};
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  height: 56px;

  .item-container {
    transition: all 0.25s ease-in-out;
    display: flex;
    height: 56px;
    align-items: center;
    background-color: ${props =>
      props.themeStyle.palette.colorGrayDarkest};
    padding: 0 20px;
  }

  &:before {
    content: '';
    position: absolute;
    /* width: 3px; */
    width: 0;
    height: 32px;
    left: 0;
    top: calc(50% - 16px);
    background-color: ${props =>
      props.themeStyle.palette.colorPrimary};
    transition: width 0.25s ease-in-out;
    z-index: 1;
  }

  &.active,
  &:hover {
    .item-container {
      color: ${props =>
        props.clear
          ? colorClear
          : props.themeStyle.palette.colorClear};
      opacity: ${props => (props.clear ? '1' : '0.56')};
    }

    &:before {
      width: 3px;
    }
  }

  &.active {
    .item-container {
      background-color: ${rgba(colorClear, 0.1)};
    }
  }

  .item-inner {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 32px;
    min-width: 32px;
  }

  &:hover {
    width: ${width};

    .item-container {
      // position: fixed;
      width: ${width};

      .item-inner {
        overflow: unset;
        width: auto;
      }
    }
  }

  .is-open & {
    .item-inner {
      width: 100vw;
      overflow: inherit;
    }

    &:hover {
      .item-container {
        position: absolute;
        width: 100vw;
      }
    }
  }

  @media (min-width: ${breakpointTablet}px) {
    .item-inner {
      width: 32px;
      min-width: 32px;
    }
  }

  @media (min-width: ${breakpointDesktop}px) {
    .item-inner {
      width: ${width};
      min-width: ${width};
    }
  }

  .icon {
    font-size: 24px;
    margin-right: 20px;
  }

  .label {
    font-size: 13px;
    letter-spacing: 0.75px;
    color: ${props => (props.clear ? colorClear : `inherit`)};
  }
`;
