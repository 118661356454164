// @flow

import React, { useState, useMemo, useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import Modal from 'components/Forms/FormModal/Modal';
import AccountVerifyForm from 'containers/AgroBRF/Forms/AccountVerifyForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { authenticateVendorCode } from '../../../service';
import t from 'components/i18n';

const validateSchema = () =>
  yup.object().shape({
    vendorCode: yup.string().required(t('global.status.required-field')),
  });

export default function Verify({
  match: { params },
  history,
  user,
  setEditUser,
  setSelected,
  isValidating,
  setStage,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      setStage(3);
    }
  }, [setStage, success]);

  const successMessage = useMemo(
    () => t('global.validation.info-success-save'),
    [],
  );

  const modalErrorMessage = useMemo(
    () =>
      t('global.validation.error-saving-info'),
    [],
  );

  const initialValues = useMemo(
    () => ({
      documentNumber: null,
      vendorCode: null,
    }),
    [],
  );

  function submitForm(
    values,
    { setSubmitting, setErrors, isValidating },
  ) {
    const formData = {
      documentNumber: user.cnpj || user.cpf,
      vendorCode: values.vendorCode,
    };

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await authenticateVendorCode(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }

    sendData(formData);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate(validateSchema())}
        onSubmit={submitForm}
        render={formikProps => (
          <AccountVerifyForm
            {...formikProps}
            isValidating={isValidating}
            message={errorMessage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            success={success}
            history={history}
            user={user}
            setStage={setStage}
            {...props}
          />
        )}
      />

      {/* On submit modal */}
      <Modal
        noButtons
        opened={errorMessage}
        warning={errorMessage}
        onCloseModal={() => setErrorMessage('')}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>{success ? successMessage : modalErrorMessage}</p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
