import React, { useState, useEffect } from 'react';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import ContentSwitcher from 'containers/AgroBRF/FlockList/Flock/ContentSwitcher';
import { FLOCK_LIST } from 'config/routes';
import t from 'components/i18n';
import Record from 'containers/AgroBRF/FlockList/Record/Record';
import SummaryScreen from './SummaryScreen';
import ClosedSummary from './ClosedSummary';
import Activities from './Activities';
import { TopMenuStyled } from './Flock.styled';

export function FlockTopMenu(props) {
  const [active, setActive] = useState(null);
  const {
    responsive,
    match,
    history,
    houses,
    data,
    user,
    flock,
    events,
  } = props;

  const visible = () => {
    if (data.flockType === 'swine') {
      return data.hasSwineDeceasedDiscarded;
    }

    if (data.flockType === 'broiler' || data.flockType === 'turkey') {
      return true;
    }

    return data.hasFal;
  };

  useEffect(() => {
    if (props.match.params.activity) {
      if (
        props.match.params.activity === 'payment' ||
        props.match.params.activity === 'partial'
      ) {
        setActive(0);
      }

      if (props.match.params.activity === 'record') {
        setActive(2);
      } else {
        setActive(1);
      }
    } else {
      setActive(0);
    }
  }, [props.match]);

  const Tabs = [
    {
      label: t('flocks.flocksDetail.tabMenu.itemOne'),
      key: 0,
      url: '',
      visible: true,
    },
    {
      label: t('flocks.flocksDetail.tabMenu.itemTwo'),
      key: 1,
      url: 'next',
      visible: true,
    },
    {
      label: t('flocks.flocksDetail.tabMenu.itemThree'),
      key: 2,
      url: 'record',
      visible: visible(),
    },
  ];

  return (
    <TopMenuStyled>
      <Header className="flock-top-menu-tabs">
        {Tabs.map(
          item =>
            item.visible && (
              <TabHeaderHolder
                key={item.key}
                onClick={() =>
                  props.history.push(
                    `${FLOCK_LIST}/${data.flockID}/${item.url}`,
                  )
                }
                className={active === item.key ? 'active' : ''}
              >
                <div className="label">{item.label}</div>
                <div className="bar" />
              </TabHeaderHolder>
            ),
        )}
      </Header>

      {active === 0 &&
        (data.flockStatus === 'open' ? (
          <SummaryScreen
            responsive={responsive}
            match={match}
            houses={houses}
            flock={flock}
            oldWeights={!data.hasFal}
            animalType={data.flockType}
            history={history}
            user={user}
          />
        ) : (
          <ClosedSummary
            responsive={responsive}
            match={match}
            data={data}
            events={events}
            history={history}
            user={user}
          />
        ))}

      {active === 1 && (
        <Activities
          responsive={responsive}
          match={match}
          data={data}
          events={events}
          history={history}
        />
      )}

      {active === 2 && data.hasFal && (
        <Record
          responsive={responsive}
          match={match}
          history={history}
          flockType={data.flockType}
        />
      )}

      {active === 2 && !data.hasFal && (
        <ContentSwitcher
          responsive={responsive}
          match={match}
          history={history}
          data={data}
          isVisible={visible}
          user={user}
        />
      )}
    </TopMenuStyled>
  );
}
