import React, { useState, useEffect, useCallback } from 'react';
import { NEXT_ORIENTATION } from 'config/routes';
import moment from 'moment';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import { OrientationItem } from 'containers/NextBRF/Orientation';
import { getOrientations } from 'containers/NextBRF/LogBook/service';
import t from 'components/i18n';
import OpenOrientationCard from './OpenOrientationCard';

export default function OrientationList(props) {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData(id) {
      setIsLoading(true);
      try {
        const response = await getOrientations(id);
        setData(response);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }

    fetchData(props.flockId);
  }, [props.flockId]);

  const getList = useCallback(
    list => {
      if (!list.length) {
        return (
          <NoContentTab icon="icon-search">
            {/* No content */}
            <p>
              {t('global.no-content')}
            </p>
            {/* \ No content */}
          </NoContentTab>
        );
      }

      return list.map((item, index) => (
        <OrientationItem
          key={index}
          date={moment(item.foreseenDate).format('DD ̸̸  MM ̸̸  YYYY HH:mm')}
          description={item.orientation}
          action={() =>
            props.history.push(
              `${NEXT_ORIENTATION}/${item.id}/${props.flockId}`,
            )
          }
          footerIcon="icon-check"
          footerColor="colorInfo"
          footerContent={
            item.read
              ? t('next.orientation.orientationList.item.read-on', { value: moment(item.foreseenDate).format('DD ̸̸  MM ̸̸  YYYY HH:mm') })
              : t('next.orientation.orientationList.item.sent')
          }
        />
      ));
    },
    [props.flockId, props.history],
  );

  return (
    <div style={{ maxWidth: '684px', padding: '16px' }}>
      <LoadingHolder isLoading={isLoading} />

      {!isLoading &&
        data.length > 0 &&
        (selected ? (
          <OpenOrientationCard
            data={selected}
            back={() => setSelected(null)}
          />
        ) : (
          getList(data)
        ))}
    </div>
  );
}
