import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import qs from 'qs';
import t from 'components/i18n';
import { getShortTerm } from './service';

const Policy = styled.div`
  padding: 10px;
  ol {
    padding-left: 20px;
  }
`;

export default function PolicyTermsExcerptMobile({ location, user }) {
  const [isLoadingTerms, setIsLoadingTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [termData, setTermData] = useState('');

  const urlToken = location
    ? qs.parse(location.search)['?token']
    : null;

  const userToken = user && user.token;
  const token = userToken || urlToken;

  const getTermData = useCallback(async () => {
    setIsLoadingTerms(true);
    try {
      const response = await getShortTerm(token);

      setTermData(response.data);
    } catch (error) {
      setErrorMessage(error);
    }
    setIsLoadingTerms(false);
  }, [token]);

  useEffect(() => {
    getTermData();
  }, [getTermData]);

  return (
    <Policy>
      <LoadingHolder isLoading={isLoadingTerms} />

      {!!errorMessage && <p>{errorMessage}</p>}

      {!isLoadingTerms && termData && (
        <div dangerouslySetInnerHTML={{ __html: termData.term }} />
      )}

      {!isLoadingTerms && !termData && (
        <NoContentTab icon="icon-file">
          <p>
            {t('policy.policyTermsExcerptMobile')}
          </p>
        </NoContentTab>
      )}
    </Policy>
  );
}
