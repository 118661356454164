import { useEffect, useState } from 'react';
import { store } from 'store';

export default function ThemeSwitcher(props) {
  const [user, setUser] = useState(false);

  /* colors */
  const colorsAgro = {
    colorSuccess: '#009688',
    colorDanger: '#d22630',
    colorWarning: '#ffcc19',
    colorInfo: '#407ec9',
    colorHighlight: '#407ec9',
    colorPrimary: '#FF6200',
    colorSecondary: '#d22630',
    colorSecondaryYellow: '#FFAC2E',
    colorTertiary: '#93328E',
  };

  const colorsNext = {
    colorSuccess: '#000000',
    colorDanger: '#000000',
    colorWarning: '#000000',
    colorInfo: '#407ec9',
    colorHighlight: '#000000',
    colorPrimary: '#000000',
    colorSecondary: '#000000',
    colorSecondaryYellow: '#FFAC2E',
    colorTertiary: '#000000',
    colorGrayDarkest: '#362448',
  };

  useEffect(() => {
    const storeUser = store.getState('user');
    setUser(storeUser.user);
  }, []);

  return user.isNextUser ? colorsNext : colorsAgro;
}
