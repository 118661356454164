import React from 'react';
import { Message, Label, Input } from '@zendeskgarden/react-forms';
import {
  getValidationMessage,
  getValidation,
  displayMessageText,
} from 'shared/utils/Helpers';
import { FilterStyled } from 'components/Filter/Filter.styled';
import DatePicker from 'react-datepicker';
import moment from 'moment';

export default function DatePickerField(props) {
  const CustomInput = ({ value, onClick }) => (
    <Input
      type="text"
      onClick={e => onClick(e)}
      placeholder={props.placeholder}
      value={value || null}
      readOnly
      disabled={props.disabled}
    />
  );

  const date = props.values[props.field]
    ? moment(new Date(`${props.values[props.field]} GMT-0300`))
    : null;

  return (
    <FilterStyled
      style={{ width: '100%' }}
      className={props.className}
    >
      <Label>{props.label}</Label>
      <DatePicker
        selected={date && date.toDate()}
        onChange={e => props.onChange(e)}
        calendarClassName="datepicker__calendar"
        locale="pt-BR"
        dateFormat="dd ̸̸  MM ̸̸  yyy"
        showPopperArrow={false}
        withPortal
        customInput={<CustomInput />}
      />
      {props.errors && Boolean(props.errors.length) && (
        <Message
          validation={getValidation(
            props.values[props.field],
            props.errors,
            props.field,
            !props.touched[props.field],
          )}
        >
          {getValidationMessage(
            props.field,
            props.errors,
            !props.touched[props.field],
          )}
        </Message>
      )}
    </FilterStyled>
  );
}
