import styled from 'styled-components';
import {
  colorGrayLighter,
  colorPrimary,
  colorGrayDarkest,
} from 'components/GlobalStyles/variables';

// import Icon from 'components/Icon';

export const EyeIcon = styled.span`
  cursor: pointer;
  font-size: 20px;
  color: ${colorGrayLighter};
`;

export const MediaFigure = styled.div`
  padding: 0 4px 0 4px;
`;

export const AccessLink = styled.p`
  color: ${colorGrayDarkest};
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 60px;
  cursor: pointer;

  strong {
    color: ${colorPrimary};
  }
`;
