import { API_PRODUCER_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getFalResume(flockId) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/GetFalResume?flockID=${flockId}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getFalTracking(flockId) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/GetFalTracking?flockID=${flockId}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getFalInformation(falId, date) {
  const url =
    date !== null
      ? `${API_PRODUCER_URL}/Fal/GetFalInformation?falId=${falId}&date=${date}`
      : `${API_PRODUCER_URL}/Fal/GetFalInformation?falId=${falId}`;
  const response = await request(url, {
    method: 'GET',
  });
  return response.data;
}

export async function putFalInformation(payload) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/PutFalInformation`,
    {
      method: 'PUT',
      payload,
    },
  );
  return response;
}

export async function getDeceasedTypes() {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/GetDeceasedTypes`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getFeedTypes(id) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/GetFeedTypes?falId=${id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getFalVendor(id) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/GetVendors?vendorToFind=${id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getAge(id, date) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/getAge?falId=${id}&date=${date}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}
