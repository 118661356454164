import React from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import FloatingLabel from 'components/FloatingLabel';
import {
  EyeIcon,
  MediaFigure,
} from 'containers/OnePage/Login/SignIn/SignIn.styled';
import Spacer from 'components/Spacer';
import MaskedInput from 'components/MaskedInput';
import { Dots } from '@zendeskgarden/react-loaders';
import * as routes from 'config/routes';
import t from 'components/i18n';
import {
  getValidationMessage,
  getValidation,
  getTextfieldClass,
} from 'shared/utils/Helpers';
import {
  Field,
  Message,
  MediaInput,
  FauxInput,
  Label,
} from '@zendeskgarden/react-forms';
import {
  Heading,
  Subheading,
  Link,
  TitleBlock,
  LinkHolder,
} from 'components/SideBox/SideBox.styled';

export default function AgroLoginForm({
  values,
  handleSubmit,
  setFieldError,
  setFieldValue,
  errors,
  touched,
  dirty,
  status,
  handleChange,
  handleBlur,
  setFieldTouched,
  isValid,
  isLoading,
  showPassword,
  setShowPassword,
}) {
  function onChange(e) {
    handleChange(e);
  }

  function onBlur(e) {
    setFieldTouched(e.currentTarget.name);
    handleBlur(e);
  }

  const buttonProps = {
    stretched: true,
    primary: true,
    type: 'submit',
    disabled: !isValid || isLoading,
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <TitleBlock>
        <Heading>{t('login.title')}</Heading>
        <Subheading>{t('login.subtitle')}</Subheading>
      </TitleBlock>

      <Spacer multiplier={5} />

      <Field
        className={getTextfieldClass(
          getValidation(
            values.documentNumber,
            errors,
            'documentNumber',
            !touched.documentNumber,
          ),
        )}
      >
        <FloatingLabel>
          <Label>{t('login.inputLogin')}</Label>
          <MaskedInput
            name="documentNumber"
            type="CPF/CNPJ"
            validation={getValidation(
              values.documentNumber,
              errors,
              'documentNumber',
              !touched.documentNumber,
            )}
            value={values.documentNumber}
            onBlur={onBlur}
            onChange={onChange}
            setState={value => {
              setFieldValue('documentNumber', value);
            }}
            disabled={isLoading}
          />
          <Message
            validation={getValidation(
              values.documentNumber,
              errors,
              'documentNumber',
              !touched.documentNumber,
            )}
          >
            {getValidationMessage(
              'documentNumber',
              errors,
              !touched.documentNumber,
            )}
          </Message>
        </FloatingLabel>
      </Field>

      <Field
        mediaLayout
        className={getTextfieldClass(
          getValidation(
            values.password,
            errors,
            'password',
            !touched.password,
          ),
        )}
      >
        <FloatingLabel>
          <Label>{t('login.inputPassword')}</Label>
          <FauxInput
            className="faux-input"
            mediaLayout
            validation={getValidation(
              values.password,
              errors,
              'password',
              !touched.password,
            )}
          >
            <MediaInput
              name="password"
              className="media-input"
              type={showPassword ? 'text' : 'password'}
              onBlur={onBlur}
              onChange={onChange}
              validation={getValidation(
                values.password,
                errors,
                'password',
                !touched.password,
              )}
              disabled={isLoading}
            />
            {values.password && (
              <MediaFigure>
                {!showPassword && (
                  <EyeIcon
                    className="icon-visibility-enabled"
                    onClick={() => setShowPassword(true)}
                  />
                )}

                {showPassword && (
                  <EyeIcon
                    className="icon-visibility-disabled"
                    onClick={() => setShowPassword(false)}
                  />
                )}
              </MediaFigure>
            )}
          </FauxInput>

          <Message
            validation={getValidation(
              values.password,
              errors,
              'password',
              !touched.password,
            )}
          >
            {getValidationMessage(
              'password',
              errors,
              !touched.password,
            )}
          </Message>
        </FloatingLabel>
      </Field>

      <LinkHolder>
        <Link to={routes.PASSWORD_RECOVERY}>
          {t('login.linkForgottenPassword')}
        </Link>
      </LinkHolder>

      <Spacer multiplier={2} />

      {!isLoading && (
        <Button {...buttonProps}>{t('login.buttonPrimary')}</Button>
      )}
      {isLoading && (
        <Button {...buttonProps}>
          <Dots />
        </Button>
      )}

      <Spacer />

      <LinkHolder center>
        <Link to={routes.SIGNUP} primary>
          {t('login.buttonSecondary')}
        </Link>
      </LinkHolder>
    </form>
  );
}
