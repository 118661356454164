import styled from "styled-components";

export const Divider = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;

  &:before, &:after {
    border-bottom: 1px solid #cfd2d8;
    flex-grow: 1;
    content: ' ';
  }

  color: #93328e;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  & > span {
    padding: 24px 21px;
  }
`