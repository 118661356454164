import React, { useState, useEffect, useCallback } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import Modal from 'components/Forms/FormModal/Modal';
import { reduceBy } from 'shared/utils/Helpers';
import UserForm from 'containers/NextBRF/Forms/UserForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { getRegionalsWithCenters, updateUser } from '../service';
import t from 'components/i18n';
import { profileCreationMapper, profileRegionMapper } from 'utils';

const validateSchema = props =>
  yup.object().shape({
    name: yup.string().required(t('global.status.required-field')),
    mail: yup.string().required(t('global.status.required-field')),
    enrollment: yup.string().required(t('global.status.required-field')),
    creationTypes: yup.array().required(t('global.status.required-field')),
    regionals: yup.array().required(t('global.status.required-field')),
    centerList: yup.array().required(t('global.status.required-field')),
    userCorporateProfileId: yup
      .string()
      .required(t('global.status.required-field')),
  });

export default function EditUser(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [regionals, setRegionals] = useState([]);

  const { isLoading, selectedUser, isValidating } = props;

  const initialValues = {
    name: selectedUser && selectedUser.userName,
    mail: selectedUser && selectedUser.email,
    enrollment: selectedUser && selectedUser.enrollment,
    userCorporateProfile: {
      name: selectedUser && selectedUser.userCorporateProfile,
      id: selectedUser && selectedUser.userCorporateProfileId,
    },
    userCorporateProfileId:
      selectedUser && selectedUser.userCorporateProfileId,
    creationTypes:
      selectedUser && selectedUser.creationTypes
        ? selectedUser.creationTypes.map(item => ({
          label: profileCreationMapper(item),
          value: item,
        }))
        : [],
    active: selectedUser && selectedUser.active,
    regionals:
      selectedUser && selectedUser.regionals
        ? selectedUser.regionals.map(item => {
          const regional =
              regionals.length > 0 &&
              regionals.find(reg => reg.name === item);
          const { id, name } = regional;
          return {
            id,
            name,
            label: profileRegionMapper(name),
          };
        })
        : [],
    centerList:
      selectedUser && selectedUser.centerIdList
        ? regionals && regionals.length && regionals.map(regional => regional.centerList.filter(cl => selectedUser.centerIdList.includes(cl.id)) ).flat()
        : [],
  };

  const formData = useCallback(
    values => {
      const unities = values.centerList
        ? values.centerList.map(item => item.id)
        : regionals
          .map(item =>
            item.centerList.map(center => center.id).flat(),
          )
          .flat();

      return {
        id: values.id,
        active: values.active,
        name: values.name,
        mail: values.mail,
        enrollment: values.enrollment.toString(),
        userCorporateProfileId: Number(values.userCorporateProfileId),
        centerIds: unities,
      };
    },
    [regionals],
  );

  useEffect(() => {
    async function getRegionals() {
      try {
        const response = await getRegionalsWithCenters();
        setRegionals(
          response.data.map(item => ({
            ...item,
            animalTypes: reduceBy(
              item.centerList.map(center => ({
                animalType: center.animalType,
              })),
              'animalType',
            ).map(animal => animal.animalType),
          })),
        );
      } catch (error) {
        console.error(error);
      }
    }

    getRegionals();
  }, []);

  const submitForm = useCallback(
    (values, { setSubmitting }) => {
      formData(values);

      async function sendData(val) {
        setSubmitting(true);
        try {
          const response = await updateUser(selectedUser.userId, val);
          if (!response.success) {
            throw Error(response.message);
          }
          setSuccess(true);
        } catch (error) {
          setErrorMessage(error.message);
        }
        setSubmitting(false);
      }
      sendData(formData(values));
    },
    [formData, selectedUser.userId],
  );

  return (
    <>
      {regionals.length > 0 && (
        <Formik
          initialValues={initialValues}
          validate={validate(validateSchema)}
          onSubmit={submitForm}
          render={formikProps => (
            <UserForm
              {...formikProps}
              isValidating={isValidating}
              message={errorMessage}
              isLoading={isLoading}
              selected={selectedUser}
              success={success}
              profileList={props.profileList}
              regionals={regionals}
              {...props}
            />
          )}
        />
      )}

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
          props.setSelectedKey('user-list');
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>
          {success
            ? t('global.validation.record-changes-saved')
            : t('global.validation.record-error')}
        </p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
