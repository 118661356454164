import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import moment from 'moment';
import { scrollToTop } from 'shared/utils/Helpers';
import {
  OpenedResumeCard,
  ClosedResumeCard,
} from 'components/ResumeCard';
import LoadingHolder from 'components/LoadingHolder';
import TitleGroup from 'components/TitleGroup';
import t from 'components/i18n';
import NoContentTab from 'components/NoContentTab';
import Container from 'components/Container';
import NextFlockActivities from './NextFlockActivities';
import FlockHeader from './FlockHeader';
import InfoCard from './InfoCard';
import NextDemonstrative from './NextDemonstrative';
import NextFlockDetailsMenu from './NextFlockDetailsMenu';
import { WeightHistory, MortalityHistory } from '../NextFlockHistory';
import {
  StyledNextFlockList,
  CardList,
} from '../StyledNextFlock.styled';

// service
import { getFlockScreen } from '../service';
import {
  getStatus,
  getNotifications,
  formatNumber,
  getWeightList,
  getMortalityList,
} from '../utils';

export default function NextFlockDetails({
  user,
  history,
  match: { params },
  responsive: { isMobile, isTablet, isGtMobile },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  // display mortalities or weight history
  const [displayDetails, setDisplayDetails] = useState(false);
  const [displayTabs, setDisplayTabs] = useState(false);

  const isSwine = useMemo(() => {
    return user.animalType === 'swine';
  }, [user]);

  const isTurkey = useMemo(() => {
    return user.animalType === 'turkey';
  }, [user]);

  useEffect(() => {
    const getFlockDetails = async id => {
      setIsLoading(true);
      try {
        const response = await getFlockScreen(id);
        setData(response);
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    };

    getFlockDetails(params.id);
  }, [params.id]);

  useEffect(() => {
    scrollToTop(document.getElementById('main'));

    if (
      displayDetails === 'weight' ||
      displayDetails === 'mortality'
    ) {
      setDisplayTabs(true);
    }
  }, [displayDetails]);

  const header = data && data.flockHeader;
  const emptyContent = header && !header.flockStatusEnum;
  const weight = data && data.flockWeight;
  const technicalIndicators = data && data.technicalIndicators;
  const mortality = data && data.flockMortality;
  const card = data && data.flockCard;

  const getCardBadge = useCallback(val => {
    const value = val.toString();
    return t('next.flock.flockDetails.card-badge.heads', { value });
  }, []);

  const cardMainContent = useMemo(() => {
    if (card) {
      if (isSwine) {
        if (header.flockClosed) {
          return [
            {
              label: t('next.flock.flockDetails.card-content.slaughterDate.label'),
              info: technicalIndicators.slaughterDate,
            },
          ];
        }
        return [
          {
            label: t('next.flock.flockDetails.card-content.flockAge.label'),
            info: t('next.flock.flockDetails.card-content.flockAge.value', { value: card.flockAge }),
          },
        ];
      }

      return [
        {
          label: t('next.flock.flockDetails.card-content.flockAge.label'),
          info: t('next.flock.flockDetails.card-content.flockAge.value', { value: card.flockAge }),
        },
        {
          label: t("next.flock.flockDetails.card-content.averageWeight.label"),
          info: t("next.flock.flockDetails.card-content.averageWeight.label", { value: card.averageWeight }),
        },
      ];
    }
    return [];
  }, [card, isSwine, header, technicalIndicators]);

  const swineColumnsContent = useMemo(() => {
    if (card) {
      if (isSwine) {
        return [
          {
            label: t('next.flock.flockDetails.card-content.placementDate.label'),
            info: `${moment(card.placementDate).format(
              'DD ̸̸  MM ̸̸  YYYY',
            )}`,
          },
          {
            label: t('next.flock.flockDetails.card-content.mortalityRate.label'),
            info: t("next.flock.flockDetails.card-content.mortalityRate.value", { value: card.mortalityRate })
          },
        ];
      }
    }
    return [];
  }, [card, isSwine]);

  const cardListContent = useMemo(() => {
    if (technicalIndicators) {
      return [
        {
          label: t('next.flock.flockDetails.card-content.averageWeightCarriedOut.label'),
          info: t('next.flock.flockDetails.card-content.averageWeightCarriedOut.value', { value: technicalIndicators.averageWeight }),
        },
        {
          label: t('next.flock.flockDetails.card-content.averageForeseenWeight.label'),
          info: t('next.flock.flockDetails.card-content.averageForeseenWeight.value', { value: technicalIndicators.averageForeseenWeight }),
        },
        {
          label: t('next.flock.flockDetails.card-content.foodConversion.real.label'),
          info:
            (technicalIndicators.foodConversion &&
              technicalIndicators.foodConversion.real) ||
            null,
        },
        {
          label: t('next.flock.flockDetails.card-content.foodConversion.foreseen.label'),
          info:
            (technicalIndicators.foodConversion &&
              technicalIndicators.foodConversion.foreseen) ||
            null,
        },
      ];
    }

    return [];
  }, [technicalIndicators]);

  const getProgressInfo = useCallback((val, total) => {
    if (Number(val) > Number(total)) {
      return 100;
    }

    return (Number(val) * 100) / Number(total);
  }, []);

  const getColorStatus = useMemo(() => {
    if (isSwine || isTurkey) {
      return header && header.flockClosed
        ? 'colorGray'
        : 'colorPrimary';
    }

    if (header && !header.flockClosed) {
      return getStatus(header.flockStatusEnum).color;
    }

    return 'colorGray';
  }, [header, isSwine, isTurkey]);

  const getPerformance = useCallback(() => {
    if (
      user.allowRoutes.length === 0 ||
      user.allowRoutes.findIndex(item => item.id === '18' > -1)
    ) {
      return true;
    }

    return false;
  }, [user.allowRoutes]);

  return (
    <>
      {isLoading && <LoadingHolder isLoading={isLoading} />}

      {!isLoading && (!data || data.length === 0) && (
        <NoContentTab icon="icon-flock" hideButton>
          <p>
            {t('global.no-content')}
          </p>
        </NoContentTab>
      )}

      {/* HEADER */}
      {!isLoading && header && (
        <FlockHeader
          header={header}
          history={history}
          getNotifications={getNotifications}
          getStatus={getStatus}
          bgColor={getColorStatus}
          displayDetails={displayDetails}
          setDisplayDetails={setDisplayDetails}
          displayTabs={displayTabs}
          isSwine={isSwine || isTurkey}
        />
      )}
      {/* \ HEADER */}

      {!isLoading && !displayDetails && (
        <>
          {emptyContent && (
            <StyledNextFlockList
              style={{ paddingTop: 20, width: '100%', maxWidth: 652 }}
            >
              <Container hideDetail style={{ width: '100%' }}>
                <NoContentTab icon="icon-flock" hideButton>
                  <p>
                    {t('global.no-content')}
                  </p>
                </NoContentTab>
              </Container>
            </StyledNextFlockList>
          )}

          {!emptyContent && (
            <>
              {/* ACTIVITIES */}
              {card && (
                <NextFlockActivities
                  history={history}
                  bgColor={getColorStatus}
                  flockId={data && data.flockId}
                  hasFal={data.hasFal}
                  isSwine={isSwine}
                  isClosed={header.flockClosed}
                />
              )}
              {/* \ ACTIVITIES */}

              <StyledNextFlockList>
                {/* RESUME */}
                <section className="secondary-wrapper">
                  {card && (
                    <TitleGroup
                      title={t('next.flock.flockDetails.card-content.resume.title')}
                      subtitle={t('next.flock.flockDetails.card-content.resume.subtitle')}
                    />
                  )}

                  {card && (
                    <NextFlockDetailsMenu
                      user={user}
                      flockClosed={header.flockClosed}
                      flockId={params.id}
                      vendorId={header.vendorId}
                      setDisplayDetails={setDisplayDetails}
                      hasRealPlacement={data.hasRealPlacement}
                      history={history}
                      isSwine={isSwine}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      isGtMobile={isGtMobile}
                    >
                      {header.flockClosed && (
                        <ClosedResumeCard
                          color={getColorStatus}
                          title={t('next.flock.flockDetails.card-content.resume.flock-closed')}
                          highlight={
                            technicalIndicators
                            ? t("next.flock.flockDetails.card-content.resume.slaughterQuantity", { value: technicalIndicators.slaughterQuantity })
                            : t('global.undefined')
                          }
                          badge={
                            !!card.mortalityQuantity &&
                            card.mortalityQuantity.toString()
                          }
                          content={cardMainContent}
                          progress={Number(card.mortalityRate)}
                          secondaryContent={
                            isSwine
                              ? swineColumnsContent
                              : cardListContent
                          }
                          displayHighlight
                          isSwine={isSwine}
                        />
                      )}

                      {!header.flockClosed && (
                        <OpenedResumeCard
                          color={getColorStatus}
                          title={t('next.flock.flockDetails.card-content.resume.flock-open')}
                          badge={getCardBadge(card.mortalityQuantity)}
                          highlight={t('next.flock.flockDetails.card-content.resume.quantity', { value: formatNumber(card.placementQuantity) })}
                          content={cardMainContent}
                          columnsContent={swineColumnsContent}
                          progress={getProgressInfo(
                            card.flockAge,
                            card.flockMaximumAge,
                          )}
                          displayHighlight
                          isSwine={isSwine}
                          flexP
                        />
                      )}
                    </NextFlockDetailsMenu>
                  )}
                </section>
                {/* \ RESUME */}

                {/* CARD LIST */}
                {!isSwine && getPerformance() && (
                  <section className="main-wrapper">
                    {weight && (
                      <TitleGroup
                        title={t('next.flock.flockDetails.card-list.card-content.title')}
                        subtitle={t('next.flock.flockDetails.card-list.card-content.subtitle')}
                      />
                    )}

                    <CardList>
                      {/* WEIGHT CARD */}
                      {weight && (
                        <section className="secondary-wrapper">
                          <InfoCard
                            title={t('next.flock.flockDetails.card-list.card-content.weight')}
                            mainInfo={
                              weight.averageWeight
                                ? t('next.flock.flockDetails.card-list.card-content.weight.value', { value: weight.averageWeight })
                                : '-'
                            }
                            mainLabel={t('next.flock.flockDetails.card-list.card-content.weight.mainLabel')}
                            headingList={t('next.flock.flockDetails.card-list.card-content.weight.headingList')}
                            houseInfoList={getWeightList(
                              weight.flockWeightResumeList,
                            )}
                            buttonLabel={t('next.flock.flockDetails.card-list.card-content.weight.buttonLabel')}
                            buttonAction={() =>
                              setDisplayDetails('weight')
                            }
                            bgColor={getColorStatus}
                            flexP
                          />
                        </section>
                      )}
                      {/* \ WEIGHT CARD */}

                      {/* MORTALITY CARD */}
                      {mortality && (
                        <section className="secondary-wrapper">
                          <InfoCard
                            title={t('next.flock.flockDetails.card-list.card-content.mortality')}
                            mainInfo={
                              mortality.mortalityQuantity
                                ? t("next.flock.flockDetails.card-list.card-content.mortality.value", { value: formatNumber(mortality.mortalityQuantity) })
                                : '-'
                            }
                            mainLabel={t('next.flock.flockDetails.card-list.card-content.mortality.mainLabel')}
                            headingList={t('next.flock.flockDetails.card-list.card-content.mortality.headingList')}
                            buttonLabel={t('next.flock.flockDetails.card-list.card-content.mortality.buttonLabel')}
                            houseInfoList={getMortalityList(
                              mortality.mortalityHouseList,
                              )}
                            buttonAction={() =>
                              setDisplayDetails('mortality')
                            }
                            bgColor={getColorStatus}
                          />
                        </section>
                      )}
                      {/* \ MORTALITY CARD */}
                    </CardList>
                  </section>
                )}
                {/* \ CARD LIST */}
              </StyledNextFlockList>
            </>
          )}
        </>
      )}

      {!isLoading && displayDetails === 'weight' && (
        <WeightHistory
          weight={weight && weight.flockHistoricWeightList}
          setDisplayDetails={setDisplayDetails}
        />
      )}

      {!isLoading && displayDetails === 'mortality' && (
        <MortalityHistory
          mortality={
            mortality && mortality.flockHistoricMortalityList
          }
          setDisplayDetails={setDisplayDetails}
        />
      )}

      {!isLoading && displayDetails === 'demonstrative' && (
        <NextDemonstrative
          demonstrative={technicalIndicators}
          setDisplayDetails={setDisplayDetails}
        />
      )}
    </>
  );
}
