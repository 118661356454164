import styled from 'styled-components';
import {
  colorClear,
  colorPrimary,
  colorGray,
  colorGrayDarker,
  DEFAULT_SIZE,
  breakpointTablet,
  boxShadow,
  fontFamilyText,
} from 'components/GlobalStyles/variables';
import { AlertWrapper } from 'components/Alert/Alert.styled';

export const FaqStyled = styled.section`
  position: relative;

  h4.details-heading {
    font-size: 16px;
    line-height: 22px;
  }

  .container-detail {
    display: block;
    background-color: ${colorPrimary};
    height: 3px;
    width: 50px;
    margin: -16px 0 13px;
  }
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;
`;

export const Container = styled.div`
  position: relative;
  padding: ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};
  max-width: 630px;

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px;
  }

  p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }

  form {
    margin-bottom: 30px;
  }

  ${AlertWrapper} {
    position: fixed;
    bottom: 30px;
    right: 30px;
  }

  .recaptchaWrapper {
    margin-top: 16px;
  }

  button {
    margin-top: 10px;
    @media (max-width: ${breakpointTablet}px) {
      margin: 39px auto 0;
    }
  }
`;

export const Layer = styled.div`
  position: relative;
`;

export const ConclusionBox = styled.div`
  max-width: 460px;
  border-radius: 8px;
  padding: 32px 16px;
  margin: 10px auto 0;
  text-align: center;

  .icon {
    font-size: 74px;
    color: ${colorPrimary};
  }

  p {
    color: ${colorGrayDarker};
    font-size: 14px;
    line-height: 20px;

    &.bold {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
