import React from 'react';
import moment from 'moment';
import { Row, Col } from '@zendeskgarden/react-grid';
import { useSelector } from 'react-redux';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import Container from 'components/Container';
import { Button } from '@zendeskgarden/react-buttons';
import Image from 'components/Image';
import {
  StyledFlockVisitCircuit,
  Item,
  TopBadger,
} from './VisitCircuit.styled';
import t from 'components/i18n';

export default function ActionPlanDetails(props) {
  const {
    id,
    details: {
      action,
      cause,
      how,
      responsible,
      expectedDate,
      nonCompliance,
      visitReason,
      attachmentsUrls,
      wasExecuted,
    },
    setDisplayDetails,
  } = props;

  const user = useSelector(state => state.user);

  return (
    <>
      {wasExecuted && (
        <TopBadger>
          <span className="icon icon-check" />
          <p>{t('next.visitCircuit.actionPlanDetails.action-plan-executed')}</p>
        </TopBadger>
      )}
      <StyledFlockVisitCircuit
        style={{ width: 645, paddingTop: wasExecuted ? '4rem' : '0' }}
      >
        <Container hideDetail className="animated fadeIn">
          <Item className="flex" padding="0 0 16px 0">
            <span className="label">{t("next.visitCircuit.actionPlanDetails.content.visit-reason")}</span>{' '}
            <span>{visitReason}</span>
          </Item>

          <Item className="flex">
            <span className="label">{t("next.visitCircuit.actionPlanDetails.content.date-time")}</span>{' '}
            <div
              className="flex"
              style={{ flexDirection: 'column', textAlign: 'right' }}
            >
              <span className="margin">
                {moment(expectedDate).format('DD ̸̸  MM ̸̸  YYYY')}
              </span>
              <span className="small">
                {moment(expectedDate).format('HH:mm')}
              </span>
            </div>
          </Item>

          <Item className="flex">
            <span className="label">{t('next.visitCircuit.actionPlanDetails.content.responsible')}</span>
            <span>{responsible}</span>
          </Item>

          <Item>
            <span className="label">{t('next.visitCircuit.actionPlanDetails.content.nonCompliance')}</span>
            <p>{nonCompliance}</p>
          </Item>

          <Item>
            <span className="label">{t('next.visitCircuit.actionPlanDetails.content.cause')}</span>
            <p>{cause}</p>
          </Item>

          <Item>
            <span className="label">{t('next.visitCircuit.actionPlanDetails.content.action')}</span>
            <p>{action}</p>
          </Item>

          <Item>
            <span className="label">{t('next.visitCircuit.actionPlanDetails.content.how')}</span>
            <p>{how}</p>
          </Item>

          {attachmentsUrls && attachmentsUrls.length > 0 && (
            <Item>
              <span className="label">{t('next.visitCircuit.actionPlanDetails.content.attachments')}</span>
              <br />
              <br />
              <Row>
                {attachmentsUrls.map((item, index) => (
                  <Col key={index} sm={4}>
                    <a
                      href={`${API_URL}/Blob/Download?fileName=${item}&blobContainerName=logbookattachment&token=${user.token}&client_id=${KONG_CLIENT_ID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        api={API_URL}
                        fileName={item}
                        blobContainer="logbookattachment"
                        token={user.token}
                      />
                    </a>
                  </Col>
                ))}
              </Row>
            </Item>
          )}

          <Item className="flex right">
            <Button
              className="tertiary"
              onClick={() => setDisplayDetails(null)}
            >
              {t('global.button.back')}
            </Button>
          </Item>
        </Container>
      </StyledFlockVisitCircuit>
    </>
  );
}
