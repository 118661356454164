import React, { useState } from 'react';
import moment from 'moment';
import { INSURANCE, PROFILE } from 'config/routes';
import { useSelector } from 'react-redux';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import t from 'components/i18n';
import { JustifiedList, JustifiedListItem } from 'components/List';
import Modal from 'components/Forms/FormModal/Modal';
import { Separator } from '@zendeskgarden/react-dropdowns';

export default function InsuranceDetails({
  data,
  deleteInsurance,
  setEditForm,
  history,
  params,
}) {
  const [openModal, setOpenModal] = useState(false);

  const user = useSelector(state => state.user);

  const details = [
    {
      label: t('insurancePolicy.detailed.start-date'),
      item: <p>{moment(data.startDate).format('DD ̸̸  MM ̸̸  YYYY')}</p>,
    },
    {
      label: t('insurancePolicy.detailed.end-date'),
      item: <p>{moment(data.endDate).format('DD ̸̸  MM ̸̸  YYYY')}</p>,
    },
    {
      label: t('insurancePolicy.detailed.document'),
      item: (
        <div>
          {data.files.map((e, index) => (
            <p key={index}>
              <a
                href={`${API_URL}/Blob/Download?fileName=${e.id}&blobContainerName=${e.container}&token=${user.token}&client_id=${KONG_CLIENT_ID}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {e.fileName}
              </a>
            </p>
          ))}
        </div>
      ),
    },
  ];

  return (
    <>
      <JustifiedList className="animated fadeIn">
        {details.map((item, index) => (
          <JustifiedListItem key={index}>
            <span>{item.label}</span>
            {item.item}
          </JustifiedListItem>
        ))}
      </JustifiedList>

      <Separator />

      <br />

      <Row>
        <Col sm={6} />
        <Col sm={6}>
          {/* \ Botões */}
          <BtnGroup style={{ paddingBottom: '16px' }}>
            <Button
              key="submitForm"
              onClick={e => history.push(`${INSURANCE}/${data.id}`)}
            >
              {t("global.button.edit")}
            </Button>
            <Button
              key="clean"
              className="white"
              onClick={() => setOpenModal(true)}
            >
              {t('insurancePolicy.editPolicy.buttonSecondary')}
            </Button>
          </BtnGroup>
          {/* \ Botões */}
        </Col>
      </Row>

      <Modal
        warning
        opened={openModal}
        confirm={() => {
          deleteInsurance(data.id);
        }}
        deny={() => setOpenModal(false)}
        onCloseModal={() => {
          history.push(`${PROFILE}/documentation`);
        }}
      >
        <span className="icon icon-exclamation-triangle" />
        <h4>{t('insurancePolicy.delete.title')}</h4>
        {/* <p>{submitResponse}</p> */}
        <p>
          {t('insurancePolicy.delete.description')}
        </p>
      </Modal>
    </>
  );
}
