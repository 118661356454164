import styled from 'styled-components';
import {
  colorClear,
  colorGrayDarkest,
  boxShadow,
  breakpointTablet,
} from 'components/GlobalStyles/variables';

import { Field } from '@zendeskgarden/react-forms';

export const FilterStyled = styled(Field)`
  position: relative;
  display: inline-block;
  margin: 0 12px 6px 0;

  .border-radius {
    border-radius: 16px;
    border: none;
  }

  button.btn {
    .icon-close {
      font-size: 18px;
    }

    &.dateTrigger {
      background-color: ${colorGrayDarkest};
      color: ${colorClear};
      line-height: 20px;
      padding: 6px 12px;
      margin-right: 1rem;
    }
  }

  .rdrWeekDay,
  .rdrMonthPicker select,
  .rdrYearPicker select {
    text-transform: uppercase;
    border: 0 !important;
  }

  .container-content {
    border-radius: 0;
    padding: 24px 0;

    .container-inner {
      padding: 0;

      > div {
        padding: 0 24px;
      }
    }

    .flex-column {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: calc(100vh - 226px);
      justify-content: space-between;
    }
  }
`;

export const FilterBox = styled.div`
  margin-top: 30px;
  overflow: hidden;
`;

export const FixedField = styled.section`
  padding: 16px 0;
  width: 100%;
  margin: 16px 0 0 0;
  display: flex;
  justify-content: center;
  box-shadow: 3px -4px 12px rgba(37, 48, 71, 0.16);

  button {
    justify-content: center;
  }
`;

export const DateRangeWrapper = styled.div`
  position: absolute;
  z-index: 10;
  box-shadow: ${boxShadow};

  @media (min-width: ${breakpointTablet}px) {
    top: -100px;
    left: 140px;
  }
`;
