import React, { useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash';
import {
  Menu,
  Field,
  Item,
  Dropdown,
  Autocomplete,
} from '@zendeskgarden/react-dropdowns';
import {
  Close,
  RoundedItem,
} from 'components/MultiSelect/Items.styled';
import { SearchBoxStyled } from './SearchBox.styled';

export default function SearchBox({
  options,
  label,
  action,
  item,
  notFoundMessage,
}) {
  const [inputValue, setInputValue] = useState('');
  const [matchingOptions, setMatchingOptions] = useState(options);

  const filterMatchingOptionsRef = useRef(
    debounce(value => {
      const mOptions = options.filter(option => {
        return (
          option[label]
            .trim()
            .toLowerCase()
            .indexOf(value.trim().toLowerCase()) !== -1
        );
      });

      setMatchingOptions(mOptions);
    }, 300),
  );

  const inputRef = useRef();

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);

  const renderOptions = () => {
    if (matchingOptions.length === 0) {
      return <Item disabled>{notFoundMessage}</Item>;
    }

    return matchingOptions.map(option => (
      <Item key={option[label]} value={option}>
        <span>{option[label]}</span>
      </Item>
    ));
  };

  return (
    <SearchBoxStyled>
      {item && (
        <RoundedItem onClick={() => action('')}>
          {item[label]}
          <Close>
            <span className="icon icon-close-circle" />
          </Close>
        </RoundedItem>
      )}

      {!item && (
        <Dropdown
          ref={inputRef}
          inputValue={inputValue}
          selectedItem={item[label]}
          onSelect={e => action(e)}
          onInputValueChange={e => setInputValue(e)}
          downshiftProps={{ defaultHighlightedIndex: 0 }}
        >
          <Field>
            <Autocomplete isBare>
              <span>{item[label]}</span>
            </Autocomplete>
          </Field>
          <Menu>{renderOptions()}</Menu>
        </Dropdown>
      )}
    </SearchBoxStyled>
  );
}
