import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function acceptLgpdTerm(token) {
  const response = await request(`${API_URL}/User/AcceptLgpdTerm`, {
    method: 'POST',
    headers: {
      Authorization: token
    }
  });

  return response;
}

export async function getLgpdTerm(token = '') {
  if (token !== '') {
    const response = await request(
      `${API_URL}/Authentication/GetLgpdTerm?token=${token}`,
      {
        method: 'GET',
      },
    );

    return response;
  }

  const response = await request(
    `${API_URL}/Authentication/GetLgpdTerm`,
    {
      method: 'GET',
    },
  );

  return response;
}

export async function getShortTerm(token = '') {
  if (token !== '') {
    const response = await request(
      `${API_URL}/Authentication/GetShortTerm?token=${token}`,
      {
        method: 'GET',
      },
    );

    return response;
  }

  const response = await request(
    `${API_URL}/Authentication/GetLgpdTerm`,
    {
      method: 'GET',
    },
  );

  return response;
}
