import React, { useContext } from 'react';
import uid from 'uuid';
import { ThemeContext } from 'styled-components';
import ProgressBar from 'components/ProgressBar';
import {
  Resume,
  CardBadge,
  Separator,
  Content,
  Column,
  FooterContent,
} from './ResumeCard.styled';
import t from 'components/i18n';

export default function OpenedResumeCard(props) {
  const themeContext = useContext(ThemeContext);
  const {
    color,
    content,
    title,
    badge,
    highlight,
    progress,
    displayHighlight = true,
    flexP = false,
    columnsContent,
  } = props;

  return (
    <Resume color={themeContext.styles.palette[color]}>
      {badge && <CardBadge>{badge}</CardBadge>}

      {displayHighlight && (
        <>
          <span className="label">{title}</span>
          <div className="main">{highlight}</div>

          {progress ? (
            <ProgressBar
              value={progress}
              padding="16px 0"
              bgColor={themeContext.styles.palette[color]}
            />
          ) : (
            <Separator />
          )}
        </>
      )}

      <Content flexP={flexP}>
        {content.map(
          item =>
            item.info &&
            (flexP ? (
              <Column key={`content-${uid()}`}>
                <p>
                  <strong>{t(item.label)}:</strong> {'  '}
                  {item.info}
                </p>
              </Column>
            ) : (
              <p key={`content-${uid()}`}>
                <span className="label">{t(item.label)}</span>
                <strong>{t(item.info)}</strong>
              </p>
            )),
        )}
      </Content>

      {columnsContent && (
        <FooterContent>
          {columnsContent.length > 0 &&
            columnsContent.map(
              (item, index) =>
                item.info && (
                  <Column key={index}>
                    <p>
                      {item.label} <br />
                      <span>{t(item.info)}</span>
                    </p>
                  </Column>
                ),
            )}
        </FooterContent>
      )}
    </Resume>
  );
}
