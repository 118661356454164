import styled from 'styled-components';

import {
  colorClear,
  colorGrayDarkest,
  colorGrayDark,
} from '../GlobalStyles/variables';

export const ViewImage = styled.div`
  input[type='file'] {
    display: none;
  }
`;

export const ButtonUpload = styled.label`
  background-color: ${colorGrayDarkest} !important;
  color: ${colorClear} !important;
  height: 32px;
  margin: 0 12px 20px 0;
  cursor: pointer;
  border-radius: 32px;
  text-transform: none;
  transition: 0.2s background-color ease-in;
  letter-spacing: 0.25px;
  font-size: 14px;
  padding: 6px 12px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  width: 11rem;

  .icon {
    margin-left: 6px;
    font-size: 18px;
  }

  &:hover {
    background-color: ${colorGrayDark} !important;
  }
`;

export const PictureBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 6px;

  div {
    height: 182px;
    max-width: 182px;
    position: relative;

    span {
      position: absolute;
      width: 32px;
      height: 32px;
      right: 0;

      background: #cfd2d8;
      border-radius: 999px;
      font-size: 25px;
      margin: 8px 8px 0 0;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #7f879a;
      transition: 0.1s filter ease-in;

      &:hover {
        filter: brightness(0.8);
      }
    }

    img {
      height: 100%;
      max-width: 100%;
      border-radius: 12px;
      object-fit: cover;
      box-shadow: 0px 2px 4px rgba(37, 48, 71, 0.2);
    }
  }
`;
