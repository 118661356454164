import React, { useCallback } from 'react';
import moment from 'moment';
import { Row, Col } from '@zendeskgarden/react-grid';
import Badge from 'components/Badge';
import FooterMenu from 'components/FooterMenu';
import t from 'components/i18n';

// Styles
import {
  Container,
  ItemContainer,
  FeedItem,
} from './NextMedication.styled';
import { statusMapper } from 'utils/mapper';

export default function MedicationSummaryItem({
  feed,
  setSelectedMedicationId,
  setEditMedication,
  exclude,
  hideOptions,
  setSendToConfirmation,
  setDisplayExcludeModal,
}) {
  const badgeProps = {};

  const selectItem = useCallback(() => {
    setSelectedMedicationId(feed.id);
  }, [feed, setSelectedMedicationId]);

  const getOptions = useCallback(
    () => {
      const options = [
        {
          action: () => {
            setEditMedication(true);
            selectItem('medicationDetails');
            setSendToConfirmation(true);
          },
          icon: 'visibility-enabled',
          label: t('next.medication.medicationListItem.options.details.label'),
          visible: true,
        },
        {
          action: () => {
            selectItem('editMedication');
            setEditMedication(true);
          },
          icon: 'edit',
          label: t('next.medication.medicationListItem.options.editar.label'),
          visible: feed.enableEdit,
        },
        {
          action: () => {
            setDisplayExcludeModal(true);
            setSelectedMedicationId(feed);
          },
          icon: 'trash',
          label: t('next.medication.medicationListItem.options.excluir.label'),
          visible: feed.enableDelete,
        },
      ];
      return options;
    },
    [
      feed,
      selectItem,
      setDisplayExcludeModal,
      setEditMedication,
      setSelectedMedicationId,
      setSendToConfirmation,
    ],
  );

  if (feed.status) {
    switch (feed.status.toLowerCase()) {
      case 'pendente':
        badgeProps.info = true;
        break;
      default:
        badgeProps.success = true;
    }
  }

  return (
    <Container className="bordered-container animated fadeIn">
      <ItemContainer
        className="item-container"
        onClick={() => {
          selectItem('medicationDetails');
          setEditMedication(true);
          setSendToConfirmation(true);
        }}
      >
        <div className="open summary">
          <FeedItem key={`feed-item-${feed.id}`} medkit>
            <header className="item-header">
              <div className="date">
                {moment(feed.startedDate).format('DD ̸̸  MM ̸̸  YYYY')}
                {' - '}
                {moment(feed.endedDate).format('DD ̸̸  MM ̸̸  YYYY')}
              </div>
              <div className="status">
                {feed.status && (
                  <Badge {...badgeProps}>{t(statusMapper(feed.status))}</Badge>
                )}
              </div>
            </header>
            <Row>
              <Col>
                <strong>{feed.name}</strong>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  {feed.quantity}
                  {feed.measurementUnit}
                </p>
              </Col>
            </Row>
          </FeedItem>
        </div>
      </ItemContainer>
      {!hideOptions && (
        <FooterMenu
          placement="start-bottom"
          options={getOptions(feed.isEditable)}
        />
      )}
    </Container>
  );
}
