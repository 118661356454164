import i18next from 'i18next';
import { isUndefined } from 'utils';

export default function t(k, v) {
  if (isUndefined(k)) {
    return '';
  }
  
  if (typeof k === 'string') {
    return i18next.t(k, v);
  }

  return i18next.t(k.key, v);
}
