import React, { useState, useEffect, useCallback } from 'react';
import t from 'components/i18n';
import LoadingHolder from 'components/LoadingHolder';
import Top from 'components/Top';
import Toaster from 'components/Toaster';
import EditInsurance from './EditInsurance';
import FirstPage from './FirstPage';
import InsuranceDetails from './InsuranceDetails';
import { getInsurancePolicy, deleteInsurance } from './service';
import { InsuranceStyled, Container } from './InsurancePolicy.styled';

export default function InsurancePolicy({
  history,
  match: { params },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [updateItems, setUpdateItems] = useState(false);
  const [files, setFiles] = useState([]);

  const getInsurance = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getInsurancePolicy();
      setData(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, []);

  const deleteInsurancePolicy = useCallback(async id => {
    try {
      const response = await deleteInsurance(id);
      if (response.success) {
        setIsLoading(true);
        setUpdateItems(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (updateItems || !editForm) {
      getInsurance();
    }
  }, [editForm, getInsurance, updateItems]);

  useEffect(() => {
    if (params.type) {
      setEditForm(true);
    }
  }, [params.type]);

  return (
    <InsuranceStyled>
      {editForm ? (
        <EditInsurance
          history={history}
          selected={data}
          setEditInsurance={setEditForm}
          files={files}
          setFiles={setFiles}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ) : (
        <>
          <Top>
            <h2>
              {editForm
                ? t(
                  'insurancePolicy.createPolicy.sectionValidityPolicy.input.startDate.label',
                )
                : t('insurancePolicy.title')}
            </h2>
          </Top>
          <Container>
            <br />
            <LoadingHolder isLoading={isLoading} />

            {!isLoading &&
              (data && data.files && data.files.length > 0 ? (
                <InsuranceDetails
                  data={data}
                  params={params}
                  history={history}
                  deleteInsurance={deleteInsurancePolicy}
                  setUpdateItems={setUpdateItems}
                  setEditForm={setEditForm}
                />
              ) : (
                <FirstPage
                  editForm={editForm}
                  setEditForm={setEditForm}
                  setUpdateItems={setUpdateItems}
                />
              ))}
          </Container>
          <Toaster
            className="toaster__notification"
            isVisible={updateItems && !data}
            color="success"
            duration={3}
            delay={0}
          >
            <span className="icon icon-check" />
            {t('insurancePolicy.delete.confirmation.description')}
          </Toaster>
        </>
      )}
    </InsuranceStyled>
  );
}
