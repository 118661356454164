import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getNews(skip = 0, limit = 10) {
  const response = await request(
    `${API_URL}/News/GetNews?skip=${skip}&limit=${limit}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function getHighlightedNews() {
  const response = await request(
    `${API_URL}/News/GetHighlightedNews`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function getNewsById(id, token = null) {
  const response = await request(
    `${API_URL}/News/GetNewsById?id=${id}`,
    {
      headers: {
        Authorization: token,
      },
      method: 'GET',
    },
  );

  return response;
}
