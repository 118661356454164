import React from 'react';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import { InputField } from 'components/Forms/Fields';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';
import t from 'components/i18n';

export default function PersonalDataForm({
  params,
  setFieldValue,
  handleSubmit,
  handleChange,
  handleBlur,
  resetForm,
  isValid,
  isUploading,
  setIsUploading,
  values,
  errors,
  touched,
}) {
  const getPrimaryButtonLabel = key => {
    return (
      {
        birthdate:
          'menu.profile.personalData.updateBirthDate.buttonPrimary',
        email: 'menu.profile.personalData.updateEmail.buttonPrimary',
        integrateddate:
          'menu.profile.personalData.updateGrowerSince.buttonPrimary',
      }[key] || key
    );
  };

  const getSecondButtonLabel = key => {
    return (
      {
        birthdate:
          'menu.profile.personalData.updateBirthDate.buttonSecondary',
        email:
          'menu.profile.personalData.updateEmail.buttonSecondary',
        integrateddate:
          'menu.profile.personalData.updateGrowerSince.buttonSecondary',
      }[key] || key
    );
  };

  return (
    <Form autoComplete="off">
      <Row>
        {params.slice === 'birthdate' && (
          <Col md={12}>
            <InputField
              type="date"
              field="birthDate"
              label={t(
                'menu.profile.personalData.updateBirthDate.label',
              )}
              placeholder="dd/mm/aaaa"
              onChange={handleChange}
              onBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
            />
          </Col>
        )}

        {params.slice === 'integrateddate' && (
          <Col md={12}>
            <InputField
              type="date"
              field="integratedDate"
              label={t(
                'menu.profile.personalData.updateGrowerSince.label',
              )}
              placeholder="dd/mm/aaaa"
              onChange={handleChange}
              onBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
            />
          </Col>
        )}

        {params.slice === 'email' && (
          <Col md={12}>
            <InputField
              type="text"
              field="email"
              label={t('menu.profile.personalData.updateEmail.label')}
              placeholder="Informar email"
              onChange={handleChange}
              onBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
            />
          </Col>
        )}
      </Row>

      <Separator />

      <BtnGroup>
        <Button
          key="sendtoConfirm"
          onClick={() => {
            handleSubmit();
          }}
          disabled={!isValid || isUploading}
        >
          {t(getPrimaryButtonLabel(params.slice))}
        </Button>
        <Button
          key="clean"
          onClick={() => resetForm()}
          className="white"
        >
          {t(getSecondButtonLabel(params.slice))}
        </Button>
      </BtnGroup>
    </Form>
  );
}
