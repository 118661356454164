// @flow

import { Button } from '@zendeskgarden/react-buttons';
import Breadcrumb from 'components/Breadcrumb';
import Box from 'components/Container';
import Modal from 'components/Forms/FormModal/Modal';
import t from 'components/i18n';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import Toaster from 'components/Toaster';
import Top from 'components/Top';
import AtaList from 'containers/AgroBRF/Cadec/AtaList/AtaList';
import { Container } from 'containers/AgroBRF/Cadec/Cadec.styled';
import {
  getBoxType,
  getHeading,
} from 'containers/AgroBRF/Cadec/Cadec.utils';
import CadecList from 'containers/AgroBRF/Cadec/CadecList/CadecList';
import Details from 'containers/AgroBRF/Cadec/Details';
import {
  deleteAta,
  deleteCadec,
} from 'containers/AgroBRF/Cadec/service';
import AddCadec from 'containers/AgroBRF/Forms/AddCadec/AddCadec';
import EditCadec from 'containers/AgroBRF/Forms/AddCadec/EditCadec';
import AddAta from 'containers/AgroBRF/Forms/AtaForm/AddAta';
import EditAta from 'containers/AgroBRF/Forms/AtaForm/EditAta';
import React, { useContext, useEffect, useState } from 'react';
// Contexts
import { AtaContext, CadecContext } from 'shared/contexts';
import {
  getPermissionsById,
  scrollToTop,
} from 'shared/utils/Helpers';

export default function Cadec(props) {
  const [selectedKey, setSelectedKey] = useState('cadecList');
  const [displayCadecModal, setDisplayCadecModal] = useState(false);
  const [displayAtaModal, setDisplayAtaModal] = useState(false);
  const [updateItems, setUpdateItems] = useState(false);
  const [detailsTab, setDetailsTab] = useState('desc');
  const cadecContext = useContext(CadecContext);
  const ataContext = useContext(AtaContext);
  const {
    cadecSubmitResponse,
    selectedCadec,
    cadecToaster,
    successOnSubmit,
  } = cadecContext;
  const {
    setCadecSubmitResponse,
    setSelectedCadec,
    setDisplayCadecToaster,
  } = cadecContext.actions;
  const { ataSubmitResponse, selectedAta, ataToaster } = ataContext;
  const {
    setAtaSubmitResponse,
    setSelectedAta,
    setDisplayAtaToaster,
  } = ataContext.actions;

  const cadecPermissions = getPermissionsById(
    props.user.allowRoutes,
    '4',
  );

  useEffect(() => {
    setUpdateItems(false);
  }, [setSelectedCadec]);

  useEffect(() => {
    setUpdateItems(false);
  }, [setSelectedAta]);

  function removeItem(item, type) {
    if (type === 'cadec') {
      deleteCadec(item);
      setDisplayCadecToaster(true);
      setTimeout(() => setDisplayCadecToaster(false), 4100);
      if (selectedKey !== 'cadecList') setSelectedKey('cadecList');
    } else {
      deleteAta(item);
      setDisplayAtaToaster(true);
      setTimeout(() => setDisplayAtaToaster(false), 4100);
    }
    scrollToTop(document.getElementById('main'));
  }

  const boxProps = {
    slim: true,
    [getBoxType(selectedKey)]: true,
  };

  function getContent() {
    function innerContent() {
      switch (selectedKey) {
        case 'addAta':
          return (
            <AddAta goTo={e => setSelectedKey(e)} isEditing={false} />
          );

        case 'editAta':
          return <EditAta goTo={e => setSelectedKey(e)} isEditing />;

        case 'ataList':
          return (
            <AtaList
              goTo={e => setSelectedKey(e)}
              updateItems={updateItems}
              setDisplayModal={() => setDisplayAtaModal(true)}
            />
          );
        case 'addCadec':
          return (
            <AddCadec
              goTo={e => setSelectedKey(e)}
              isEditing={false}
            />
          );

        case 'editCadec':
          return (
            <EditCadec goTo={e => setSelectedKey(e)} isEditing />
          );

        case 'cadecList':
          return (
            <CadecList
              goTo={e => setSelectedKey(e)}
              updateItems={updateItems}
              setDisplayModal={() => setDisplayCadecModal(true)}
              cadecPermissions={cadecPermissions}
            />
          );

        case 'details':
          return (
            <Details
              goTo={e => setSelectedKey(e)}
              cadec={selectedCadec}
              displayToaster={setDisplayCadecToaster}
              setDisplayModal={() => setDisplayCadecModal(true)}
              setAtaDisplayModal={() => setDisplayAtaModal(true)}
              cadecPermissions={cadecPermissions}
              detailsTab={detailsTab}
            />
          );

        default:
          return (
            <>
              <h5 className="mini-heading details-content-heading">
                CADEC
              </h5>
            </>
          );
      }
    }

    const content = (
      <div className="details-content">{innerContent()}</div>
    );

    const details = (
      <div className="details-container">
        {/* {top}
        <Separator className="top" /> */}
        {content}
      </div>
    );

    if (selectedKey === 'cadecList' || selectedKey === 'details') {
      return <div className="details">{details}</div>;
    }

    return (
      <div className="details">
        <Box {...boxProps} hideDetail>
          {details}
        </Box>
      </div>
    );
  }

  const lists = ['ataList', 'cadecList'];

  return (
    <>
      {!lists.includes(selectedKey) && (
        <Breadcrumb
          history={props.history}
          path={['CADEC', getHeading(selectedKey)]}
          backTo={() =>
            setSelectedKey(
              selectedKey.toLowerCase().indexOf('ata') > -1
                ? 'ataList'
                : 'cadecList',
            )
          }
        />
      )}
      <Top>
        <h2>
          {selectedKey === 'details'
            ? selectedCadec.cadecName
            : getHeading(selectedKey)}
        </h2>

        {cadecPermissions &&
          !!cadecPermissions.create &&
          (selectedKey === 'ataList' ||
            selectedKey === 'cadecList') && (
            <div>
              <Button
                className={
                  props.user.isNextUser ? 'tertiary' : 'primary'
                }
                onClick={() => {
                  setSelectedKey('addAta');
                  setSelectedAta({});
                }}
              >
              {t('cadec.button.publish')}
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              white
              onClick={() => {
                setSelectedKey('addCadec');
                setSelectedCadec({});
              }}
            >
              {t('cadec.button.add')}
            </Button>
          </div>
        )}
      </Top>

      {selectedKey === 'details' && (
        <Header>
          <TabHeaderHolder
            onClick={() => setDetailsTab('desc')}
            className={detailsTab === 'desc' ? 'active' : ''}
          >
            <div className="label">{t('cadec.details.description')}</div>
            <div className="bar" />
          </TabHeaderHolder>
          <TabHeaderHolder
            onClick={() => setDetailsTab('atas')}
            className={detailsTab === 'atas' ? 'active' : ''}
          >
            <div className="label">{t('cadec.details.atas')}</div>
            <div className="bar" />
          </TabHeaderHolder>
        </Header>
      )}

      <Container>
        {getContent()}

        {/* On submit modal */}
        <Modal
          warning={!successOnSubmit}
          opened={cadecSubmitResponse !== null}
          confirm={() => {
            setSelectedKey('addCadec');
            setCadecSubmitResponse(null);
          }}
          deny={() => setCadecSubmitResponse(null)}
          onCloseModal={() => {
            setSelectedCadec({});
            setSelectedAta({});
            setCadecSubmitResponse(null);
          }}
        >
          <div className="modal-title">
            <span
              className={`icon icon-${
                successOnSubmit ? 'check' : 'exclamation-triangle'
              }`}
            />
            <h4>{cadecSubmitResponse}</h4>
          </div>
          {/* <p>{cadecSubmitResponse}</p> */}
          <p>{t("cadec.button.add-another")}</p>
        </Modal>
        {/* \ On submit modal */}

        {/* Exclude item modal */}
        <Modal
          warning
          opened={displayCadecModal}
          confirm={() => {
            removeItem(selectedCadec.id, 'cadec');
            setDisplayCadecModal(false);
            setUpdateItems(true);
          }}
          deny={() => setDisplayCadecModal(false)}
          onCloseModal={() => {}}
        >
          <span className="icon icon-exclamation-triangle" />
          <h4>{t('cadec.delete.title')}</h4>
          {/* <p>{cadecSubmitResponse}</p> */}
          <p>
            {t('cadec.delete.subtitle', { value: selectedCadec.cadecName })}
          </p>
        </Modal>
        {/* \ Exclude item modal */}

        {/* On submit modal (ATA) */}
        <Modal
          opened={ataSubmitResponse !== null}
          confirm={() => {
            setSelectedKey('addAta');
            setAtaSubmitResponse(null);
          }}
          deny={() => setAtaSubmitResponse(null)}
          onCloseModal={() => {
            setSelectedAta({});
            setAtaSubmitResponse(null);
          }}
        >
          <span className="icon icon-check" />
          <h4>{t("cadec.add.success")}</h4>
          {/* <p>{cadecSubmitResponse}</p> */}
          <p>{t('cadec.button.add-another')}</p>
        </Modal>
        {/* \ On submit modal (ATA) */}

        {/* Exclude (ATA) */}
        <Modal
          warning
          opened={displayAtaModal}
          confirm={() => {
            removeItem(selectedAta.id, 'ata');
            setDisplayAtaModal(false);
            setUpdateItems(true);
          }}
          deny={() => setDisplayAtaModal(false)}
          onCloseModal={() => {}}
        >
          <span className="icon icon-exclamation-triangle" />
          <h4>{t("cadec.delete.title")}</h4>
          <p>
            {t('cadec.delete.subtitle', { value: selectedAta.name })}
          </p>
        </Modal>
        {/* \ Exclude (ATA) */}

        <Toaster
          className="toaster__notification"
          isVisible={cadecToaster}
          color="success"
          duration={3}
          delay={1}
        >
          <span className="icon icon-check" />{t('cadec.delete.success')}
        </Toaster>

        <Toaster
          className="toaster__notification"
          isVisible={ataToaster}
          color="success"
          duration={3}
          delay={1}
        >
          <span className="icon icon-check" />{t('cadec.ata.delete.success')}
        </Toaster>
      </Container>
    </>
  );
}
