import React, { useEffect, useCallback, useMemo } from 'react';
import TableComponent from 'components/Table';
import Options from 'components/Options';
import LoadingHolder from 'components/LoadingHolder';
import BadgePill from 'components/BadgePill';
import { Container } from '../UserManagement.styled';
import t from 'components/i18n';
import { profileAttributionMapper, profileKeyMapper } from 'utils';

export default function ProfileList({
  history,
  isMobile,
  setSelectedKey,
  setSelectedProfile,
  isLoading,
  profileList,
  profilePermission,
}) {
  const getProfileOptions = useCallback(
    profile => [
      {
        action: () => {
          setSelectedProfile(profile);
          setSelectedKey('profile-details');
        },
        icon: 'visibility-enabled',
        label: t('next.userManagement.profile.menu.options.details'),
        visible: profilePermission && !!profilePermission.read,
      },
      {
        action: () => {
          setSelectedProfile(profile);
          setSelectedKey('edit-profile');
        },
        icon: 'edit',
        label: t('next.userManagement.profile.menu.options.edit'),
        visible: profilePermission && !!profilePermission.edit,
      },
    ],
    [profilePermission, setSelectedKey, setSelectedProfile],
  );

  const getStatus = useCallback(
    status => (
      <BadgePill
        style={{ minWidth: 77, margin: '3px auto 0' }}
        bgColor={status ? 'colorPrimary' : 'colorGray'}
      >
        {status ? t('next.userManagement.profile.status.active') : t('next.userManagement.profile.status.inactive')}
      </BadgePill>
    ),
    [],
  );

  const tableData = useMemo(
    () =>
      profileList.length > 0 &&
      profileList.map(item => ({
        0: (
          <div style={{ padding: '6px 0' }}>
            <strong>{profileKeyMapper(item.name)}</strong>
          </div>
        ),
        1: <p>{profileAttributionMapper(item.name, item.attribution)}</p>,
        2: getStatus(item.isEnabled),
        3: (
          <Options
            onClick={e => {
              e.stopPropagation();
            }}
            options={getProfileOptions(item)}
            placement="auto"
            style={{ marginTop: '-5px' }}
          />
        ),
        onClick: () => {
          setSelectedProfile(item);
          setSelectedKey('profile-details');
        },
      })),
    [
      getProfileOptions,
      getStatus,
      profileList,
      setSelectedKey,
      setSelectedProfile,
    ],
  );

  useEffect(() => {
    setSelectedProfile(false);
  }, [setSelectedProfile]);

  return (
    <Container style={{ width: 'auto', padding: '0 16px' }}>
      <LoadingHolder isLoading={isLoading} />

      {!isLoading && (
        <TableComponent
          rowSize="default"
          header={[
            {
              width: 40,
              title: t('next.userManagement.profile.profileList.table.header.nome'),
              alignLeft: true,
            },
            {
              width: 37,
              title: t('next.userManagement.profile.profileList.table.header.attributions'),
              alignLeft: true,
            },
            {
              width: 20,
              title: t('next.userManagement.profile.profileList.table.header.status'),
              alignLeft: false,
            },
            {
              width: 3,
              title: '',
            },
          ]}
          mobileHeader={t('next.userManagement.profile.profileList.table.header.mobileHeader')}
          data={tableData}
          width="25%"
          isMobile={isMobile}
        />
      )}
    </Container>
  );
}
