const getPermissionsById = (routes, id) => {
  if (routes) {
    const found = routes.find(route => route.id === id);

    return found ? found.level : false;
  }

  return false;
};

export default getPermissionsById;
