export const mapUserLang = (lang) => {
  return {
    ['en']: 'en-US',
    ['en-AU']: 'en-US',
    ['en-BZ']: 'en-US',
    ['en-CA']: 'en-US',
    ['en-CB']: 'en-US',
    ['en-GB']: 'en-US',
    ['en-IE']: 'en-US',
    ['en-JM']: 'en-US',
    ['en-NZ']: 'en-US',
    ['en-PH']: 'en-US',
    ['en-TT']: 'en-US',
    ['en-US']: 'en-US',
    ['en-ZA']: 'en-US',
    ['en-ZW']: 'en-US',
    ['pt']: 'pt-BR',
    ['pt-BR']: 'pt-BR',
    ['pt-PT']: 'pt-BR',
    ['tr']: "tr-TR",
    ['tr-TR']: "tr-TR",
  }[lang || 'pt'];
}
