import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

import {
  colorClear,
  colorGray,
  colorGrayLighter,
  colorGrayDarker,
  colorTertiary,
  colorDanger,
  colorSuccess,
  colorPrimary,
  breakpointTablet,
} from 'components/GlobalStyles/variables';

export const NotificationItem = styled.div`
  display: flex;
  flex-direction: column;

  &.is-unread {
    background-color: ${colorGrayDarker};
  }

  &.is-last {
    .notification-message {
      &:before {
        display: none;
      }
    }
  }

  .notification-content {
    display: flex;
    flex-direction: row;
    padding: 16px;

    &.hidden {
      display: none;
    }

    .notification-icon {
      width: 40px;
      display: flex;
      justify-content: center;
      margin-right: 16px;
    }

    .notification-icon-holder {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${colorClear};
      border-radius: 8px;
      width: 40px;
      height: 40px;
      font-size: 24px;
      cursor: pointer;

      .icon-warehouse {
        color: ${colorTertiary};
      }

      .icon-truck {
        color: ${colorPrimary};
      }

      .icon-library {
        color: ${colorPrimary};
      }

      .icon-clock {
        color: ${colorDanger};
      }

      .icon-notice, .icon-edit-alt {
        color: ${colorPrimary};
      }

      .icon-money {
        color: ${colorSuccess};
      }

      .icon-chicken,
      .icon-pig,
      .icon-turkey {
        color: ${colorDanger};
      }
    }

    .notification-message {
      flex: 1;
    }

    .notification-message {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        width: 105%;
        height: 1px;
        background: ${rgba(colorClear, 0.12)};
        bottom: -16px;
        right: -16px;
      }
    }    

    .notification-text {
      color: ${colorClear};
      font-size: 14px;
      margin-bottom: 8px;
      line-height: 20px;
      flex: 1;
      min-height: 60px;
      cursor: pointer;
    }

    .notification-date {
      font-size: 10px;
      letter-spacing: 1.5px;
      color: ${colorGrayLighter};
    }

    .notification-message-holder {
      display: flex;
    }

    .notification-trigger-action {
      margin-left: 10px;
      .notification-open-actions {
        background-color: transparent;
        border: 0 none;
        cursor: pointer;
        color: ${colorClear};
        font-size: 22px;
      }
    }

    .notification-actions {
      display: flex;
      justify-items: center;
      flex-direction: row;
      margin-top: 20px;
      display: none;

      &.isOpen {
        display: block;
      }
    }

    .notification-action-button {
      height: 32px;
      color: ${colorClear};
      background-color: ${colorGray};
      line-height: 16px;
      letter-spacing: 0.4px;
      border-radius: 0;
      margin-right: 1px;
      padding: 0 8px;
      font-size: 12px;

      .label {
      }

      &:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }    

    /* &:not(:last-of-type) {
      .notification-message {
        border-bottom: 1px solid ${rgba(colorClear, 0.12)};
      }
    } */

    @media (min-width: ${breakpointTablet}px) {
      margin-left: 0;
      margin-right: 0;

      &:not(:last-of-type) {
        .notification-message {
          padding-bottom: 20px;
        }
      }
    }
  }
`;
