// @flow

import React from 'react';
import { appColor } from 'components/GlobalStyles/variables';

import type { TLoader } from './typings';

import {
  LoaderRotate,
  LoaderRotateSVG,
  LoaderGrow,
  LoaderPulse,
  LoaderRotateCircle,
} from './Loader.styled';

function Loader(props: TLoader) {
  let html;

  if (props.type === 'rotate') {
    html = (
      <LoaderRotate {...props}>
        <LoaderRotateSVG {...props}>
          <LoaderRotateCircle
            {...props}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth={2}
          />
        </LoaderRotateSVG>
      </LoaderRotate>
    );
  } else if (props.type === 'pulse') {
    html = (
      <LoaderPulse {...props}>
        <div />
        <div />
      </LoaderPulse>
    );
  } else {
    html = (
      <LoaderGrow {...props}>
        <div />
      </LoaderGrow>
    );
  }

  return html;
}

Loader.defaultProps = {
  block: false,
  color: appColor,
  size: 32,
  type: 'grow',
};

export default Loader;
