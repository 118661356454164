import React, { useState } from 'react';
import { GRAINS } from 'config/routes';
import Top from 'components/Top';
import Breadcrumb from 'components/Breadcrumb';
import Container from 'components/Container';
import Intro from './Intro';
import EditGrains from './EditGrains';
import Contacts from './Contacts';
import IntroImg from './assets/grains_1.png';
import { GrainStyled } from './Grains.styled';
import t from 'components/i18n';

export default function Grains({ history }) {
  const [stage, setStage] = useState(1);

  return (
    <>
      {stage === 2 && (
        <Breadcrumb
          history={history}
          backTo={() => setStage(1)}
          links={[GRAINS]}
          path={[t('menu.beAnAPartner.title'), t('menu.beAnAPartner.fill-form')]}
        />
      )}

      <Top>
        <h2>
          {stage === 2
            ? t('menu.beAnAPartner.fill-form')
            : t('menu.beAnAPartner.title')}
        </h2>
      </Top>

      <GrainStyled>
        <Container
          slim
          hideDetail
          backgroundImage={stage === 1 && IntroImg}
          className="animated fadeIn"
        >
          {stage === 1 && <Intro setStage={setStage} />}

          {stage === 2 && <EditGrains setStage={setStage} />}

          {stage === 3 && <Contacts setStage={setStage} />}
        </Container>
      </GrainStyled>
    </>
  );
}
