import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  memo,
} from 'react';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { API_SUPERVISOR_URL, KONG_CLIENT_ID } from 'config/constants';
import NoContentTab from 'components/NoContentTab';
import t from 'components/i18n';
import TableComponent from 'components/Table';
import Pagination from 'components/Pagination';
import { getUserTermList } from './service';
import { ContentsWrapper, Container } from './Documentation.styled';
import { creationMapper, statusMapper } from 'utils/mapper';

function Terms({ responsive }) {
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);
  const [data, setData] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [bodyRequest, setBodyRequest] = useState({
    PageNumber: 1,
    PageSize: 20,
    SupervisorCode: '',
    VendorCode: '',
    AnimalType: '',
    AcceptedTerm: '',
  });

  const { isMobile } = responsive;

  const themeContext = useContext(ThemeContext);
  const user = useSelector(state => state.user);

  let bgColor = '';
  let iconClass = '';

  const getTermList = useCallback(async e => {
    setIsContentLoading(true);
    try {
      const response = await getUserTermList(e);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsContentLoading(false);
  }, []);

  const getFilterByLabel = useCallback(
    label => activeFilters.find(filter => label === filter.label),
    [activeFilters],
  );

  const getFilters = useMemo(() => {
    return [
      {
        label: t('documentation.term.animal-type.label'),
        items: [
          {
            txt: t('documentation.term.animal-type.options.chicken'),
          },
          {
            txt: t('documentation.term.animal-type.options.turkey'),
          },
          {
            txt: t('documentation.term.animal-type.options.swine'),
          },
        ],
      },
      {
        label: t('documentation.term.status-type.label'),
        items: [
          {
            txt: t('documentation.term.status-type.options.complete'),
            val: true,
          },
          {
            txt: t('documentation.term.status-type.options.incomplete'),
            val: false,
          },
        ],
      },
    ];
  }, []);

  useEffect(() => {
    if (activeFilters) {
      // new search
      setCurrentPage(1);

      const supervisor = getFilterByLabel(t('documentation.term.extensionist-name'));
      const producer = getFilterByLabel(t('documentation.term.integrated-name'));
      const animalType = getFilterByLabel(t('documentation.term.animal-type.label'));
      const acceptedTerm = getFilterByLabel('Por aceite');

      setBodyRequest(oldBodyRequest => ({
        ...oldBodyRequest,
        SupervisorCode: supervisor ? supervisor.id : '',
        VendorCode: producer ? producer.code : '',
        AnimalType: animalType
          ? animalType.txt.replace('í', 'i').replace('Todos', '')
          : '',
        AcceptedTerm: acceptedTerm ? acceptedTerm.val : '',
      }));
    }
  }, [activeFilters, getFilterByLabel]);

  const tableData = useMemo(
    () =>
      data &&
      data.itens &&
      data.itens.length > 0 &&
      data.itens.map((e, index) => {
        if (e.acceptedTerm) {
          switch (e.acceptedTerm.toLowerCase()) {
            case 'realizado':
              bgColor = 'green';
              iconClass = 'icon icon-check-circle';
              break;
            default:
              bgColor = 'red';
              iconClass = 'icon icon-close-circle';
          }
        }

        return {
          0: (
            <div
              index={index}
              className={isMobile ? 'column mobile' : 'column'}
            >
              <p className="small caps">{t("documentation.term.table.code", { value: e.vendorCode })}</p>
              <p className="strong">{e.vendorName}</p>
              <p className="small">
                {t('documentation.term.table.extensionist', { value: e.supervisorName })}
              </p>
            </div>
          ),
          1: `${creationMapper(e.animalType)}`,
          2: (
            <div className={bgColor}>
              <span className={iconClass} /> {t(statusMapper(e.acceptedTerm))}
            </div>
          ),
          3: `${
            e.acceptedTermDate
              ? moment
                  .utc(e.acceptedTermDate)
                  .local()
                  .format('DD ̸̸  MM ̸̸  YYYY - HH:mm')
              : '-'
          }`,
        };
      }),
    [data],
  );

  const getPagination = useMemo(
    () =>
      data &&
      Number(data.totalPages) > 0 && (
        <Pagination
          totalPages={data.totalPages}
          pagePadding={isMobile ? 0.75 : 2}
          currentPage={currentPage}
          onChangePage={x => setCurrentPage(x.e)}
        />
      ),
    [currentPage, data, isMobile],
  );

  useEffect(() => {
    if (currentPage) {
      getTermList({ ...bodyRequest, PageNumber: currentPage });
    }
  }, [currentPage, bodyRequest, getTermList]);

  const renderTable = useMemo(
    () => (
      <TableComponent
        loading={isContentLoading}
        filter={{
          activeFilters: activeFilters,
          setActiveFilters: setActiveFilters,
          filters: getFilters,
        }}
        downloadLink={`${API_SUPERVISOR_URL}/Producer/DownloadGetUserTermList?pageSize=20&pageNumber=${bodyRequest.PageNumber}&VendorCode=${bodyRequest.VendorCode}&SupervisorCode=${bodyRequest.SupervisorCode}&AnimalType=${bodyRequest.AnimalType}&AcceptedTerm=${bodyRequest.AcceptedTerm}&token=${user.token}&client_id=${KONG_CLIENT_ID}`}
        downloadLabel={t('documentation.term.table.download.label')}
        titleColor={themeContext.styles.palette.colorPrimary}
        rowSize="default"
        header={[
          {
            width: 40,
            title: t('documentation.term.table.header.profile'),
          },
          {
            width: 20,
            title: t('documentation.term.table.header.animal-type'),
          },
          {
            width: 20,
            title: t('documentation.term.table.header.registration'),
          },
          {
            width: 20,
            title: t('documentation.term.table.header.date'),
          },
        ]}
        mobileHeader={t('documentation.term.table.header.profile')}
        data={tableData}
        width="25%"
        isMobile={isMobile}
      />
    ),
    [
      activeFilters,
      bodyRequest,
      getFilters,
      isContentLoading,
      isMobile,
      tableData,
      themeContext,
      user,
    ],
  );

  return (
    <ContentsWrapper>
      <Container style={{ marginBottom: '30px' }}>
        {!isContentLoading && !data && (
          <NoContentTab
            icon="icon-clipboard-user"
            onButtonClick={() => {}}
          >
            <p>
              {t('global.no-content')}
            </p>
          </NoContentTab>
        )}

        {renderTable}
      </Container>

      {getPagination}
    </ContentsWrapper>
  );
}

export default memo(Terms);
