import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import Container from 'components/Container';
import LoadingHolder from 'components/LoadingHolder';
import AddDeceased from 'containers/NextBRF/Forms/SwineDeceased/AddDeceased';
import EditDeceased from 'containers/NextBRF/Forms/SwineDeceased/EditDeceased';
import DeceasedConfirmation from 'containers/NextBRF/Forms/SwineDeceased/ConfirmWrapper';
import { SwineContext } from 'shared/contexts';
import SwineMortalityList from './SwineMortalityList';
import t from 'components/i18n';
import SwineMortalityModals from './SwineMortalityModals';
import { NextSwine } from '../NextSwine.styled';

export default function Mortality({
  flockInformation,
  isLoading,
  responsive,
  history,
  getTitle,
  selectedKey,
  setSelectedKey,
}) {
  const [displayExcludeModal, setDisplayExcludeModal] = useState(
    false,
  );
  const [updateItems, setUpdateItems] = useState(false);
  const [sendToConfirmation, setSendToConfirmation] = useState(false);

  const swineContext = useContext(SwineContext);

  const {
    deceasedResponse,
    success,
    selectedDeceased,
    removed,
  } = swineContext;

  const {
    setDeceasedResponse,
    setSelectedDeceased,
    setRemoved,
    setSuccess,
  } = swineContext.actions;

  const houses = useMemo(() => {
    return (
      flockInformation &&
      flockInformation.houseInformation &&
      flockInformation.houseInformation.map(item => ({
        ...item,
        label: t("next.swineRecord.houses.label", { value: item.value }),
      }))
    );
  }, [flockInformation]);

  const defaultProps = useMemo(() => {
    return {
      houses: houses,
      flockID: flockInformation && flockInformation.flockID,
      goTo: e => setSelectedKey(e),
      sendToConfirmation: sendToConfirmation,
      setSendToConfirmation: setSendToConfirmation,
      setDisplayExcludeModal: setDisplayExcludeModal,
      setDeceasedResponse: setDeceasedResponse,
      success: success,
      setSuccess: setSuccess,
      removed: removed,
      setRemoved: setRemoved,
      deceasedResponse: deceasedResponse,
    };
  }, [
    deceasedResponse,
    flockInformation,
    houses,
    removed,
    sendToConfirmation,
    setDeceasedResponse,
    setRemoved,
    setSelectedKey,
    setSuccess,
    success,
  ]);

  const listProps = useMemo(
    () => ({
      flockID: flockInformation && flockInformation.flockID,
      flockStatus: flockInformation && flockInformation.flockStatus,
      responsive: responsive,
      selectedKey: selectedKey,
      history: history,
      setSelectedKey: setSelectedKey,
      updateItems: updateItems,
      setUpdateItems: setUpdateItems,
      selectedDeceased: selectedDeceased,
      setSelectedDeceased: setSelectedDeceased,
      displayExcludeModal: displayExcludeModal,
      setDisplayExcludeModal: setDisplayExcludeModal,
    }),
    [
      displayExcludeModal,
      flockInformation,
      history,
      responsive,
      selectedDeceased,
      selectedKey,
      setSelectedDeceased,
      setSelectedKey,
      updateItems,
    ],
  );

  useEffect(() => {
    setSelectedKey('deceasedSummary');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedKey === 'addDeceased') {
      setSendToConfirmation(false);
      setSelectedDeceased(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey]);

  useEffect(() => {
    if (selectedDeceased) {
      setUpdateItems(false);
    }
  }, [selectedDeceased, setUpdateItems]);

  useEffect(() => {
    if (selectedKey) {
      getTitle(selectedKey);
    }
  }, [getTitle, selectedKey]);

  return (
    <NextSwine>
      <LoadingHolder isLoading={isLoading} />

      {houses && selectedKey === 'addDeceased' && (
        <Container hideDetail>
          <AddDeceased
            {...defaultProps}
            {...listProps}
            isEditing={false}
          />
        </Container>
      )}

      {houses && selectedKey === 'deceasedSummary' && (
        <SwineMortalityList {...defaultProps} {...listProps} />
      )}

      {houses && selectedKey === 'editDeceased' && (
        <Container hideDetail>
          <EditDeceased {...defaultProps} {...listProps} />
        </Container>
      )}

      {houses && selectedKey === 'deceasedConfirmation' && (
        <Container hideDetail>
          <DeceasedConfirmation {...defaultProps} {...listProps} />
        </Container>
      )}

      {/* Mortality modals */}
      <SwineMortalityModals
        {...defaultProps}
        success={success}
        selectedKey={selectedKey}
        setSelectedKey={setSelectedKey}
        deceasedResponse={deceasedResponse}
        setDeceasedResponse={setDeceasedResponse}
        updateItems={updateItems}
        setUpdateItems={setUpdateItems}
        selectedDeceased={selectedDeceased}
        setSelectedDeceased={setSelectedDeceased}
        displayExcludeModal={displayExcludeModal}
        setDisplayExcludeModal={setDisplayExcludeModal}
        setRemoved={setRemoved}
        removed={removed}
      />
      {/* \ Mortality modals */}
    </NextSwine>
  );
}
