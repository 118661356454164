// @flow

import { API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

import type { TPayment } from './typings';

export async function getPayment(
  abortSignal: AbortController,
): Promise<TPayment> {
  const params = { method: 'GET' };

  if (abortSignal) {
    params.signal = abortSignal.signal;
  }

  const response = await request(
    `${API_URL}/Payment/GetPayment`,
    params,
  );

  return response.data;
}

export async function getRemunerationResume(flockId) {
  const params = { method: 'GET' };

  const response = await request(
    `${API_URL}/Payment/GetRemunerationResume/${flockId}`,
    params,
  );

  return response.data;
}

export async function getTechnicalIndicators(flockId) {
  const params = { method: 'GET' };

  const response = await request(
    `${API_URL}/Payment/GetTechnicalIndicators/${flockId}`,
    params,
  );

  return response.data;
}

export async function getPartialPayment(flockId) {
  const params = { method: 'GET' };

  const response = await request(
    `${API_URL}/Payment/GetPartialPayment/${flockId}`,
    params,
  );

  return response.data;
}
