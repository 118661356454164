import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import LoadingHolder from 'components/LoadingHolder';
import Toaster from 'components/Toaster';
import {
  Modal,
  Header,
  Body,
  Footer,
} from '@zendeskgarden/react-modals';
import { getLgpdTerm, acceptLgpdTerm } from './service';
import t from 'components/i18n';

export default function PolicyTerms({ user, setAcceptLgpd }) {
  const [displayPolicyModal, setDisplayPolicyModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoadingTerms, setIsLoadingTerms] = useState(false);
  const [termData, setTermData] = useState('');

  const acceptTerm = useCallback(async () => {
    setDisplayPolicyModal(false);

    try {
      const response = await acceptLgpdTerm(user && user.token);

      if (!response.success) {
        throw Error(response.message);
      }

      setAcceptLgpd(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  }, [setAcceptLgpd]);

  const getTermData = useCallback(async () => {
    setIsLoadingTerms(true);
    try {
      const response = await getLgpdTerm();

      setTermData(response.data && response.data.term);
    } catch (error) {
      setErrorMessage(error);
    }
    setIsLoadingTerms(false);
  }, []);

  useEffect(() => {
    if (
      user.isAuthenticated &&
      !user.isNextUser && // @TODO: include lgpd terms for Next user
      !user.isAcceptedTermLgpd
    ) {
      setDisplayPolicyModal(true);
      getTermData();
    }
  }, [getTermData, user]);

  return (
    displayPolicyModal && (
      <div className="policy">
        <Modal className="modal__policy">
          <Header className="header">
            {t('dashboard.lgpd.title')}
          </Header>

          <Body className="body">
            <LoadingHolder isLoading={isLoadingTerms} />

            {!isLoadingTerms && termData && (
              <div dangerouslySetInnerHTML={{ __html: termData }} />
            )}
          </Body>

          <Footer className="footer">
            <p>
              {t('dashboard.lgpd.description')}
            </p>

            <Button
              className="primary"
              disabled={isLoadingTerms}
              onClick={() => acceptTerm()}
            >
              {t('dashboard.lgpd.buttonPrimary')}
            </Button>
          </Footer>
        </Modal>

        <Toaster
          className="toaster__notification"
          isVisible={!!errorMessage}
          color="warning"
          duration={3}
          delay={0}
        >
          {errorMessage}
        </Toaster>
      </div>
    )
  );
}
