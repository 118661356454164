import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { ThemeContext } from 'styled-components';
import NoContentTab from 'components/NoContentTab';
import TableComponent from 'components/Table';
import BadgePill from 'components/BadgePill';
import Pagination from 'components/Pagination';
import moment from 'moment';
import { approveInvoice, getFlockInvoiceList } from '../service';
import { useTranslation } from 'react-i18next'
import { Field, Checkbox, Label } from '@zendeskgarden/react-forms';
import Modal from 'components/Forms/FormModal/Modal';
import { Cell } from '@zendeskgarden/react-tables';

import { creationMapper } from 'utils/mapper';
import { Container, ContentsWrapper } from './Components.styled';
import Icon from 'components/Icon';
import * as routes from 'config/routes';
import DropDown from './DropdownMenu';
import Toaster from 'components/Toaster';
import { uniqBy, debounce } from 'lodash';
import Search from './Search';

export default function Table({ responsive, history, setSelecteRows, urlParam }) {
  const { t } = useTranslation();

  const [isContentLoading, setIsContentLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [response, setResponse] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [approveConfirmation, setApproveConfirmation] = useState(false);

  const { isMobile } = responsive;

  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    handleQuery(currentPage, activeFilters, search, urlParam);
  }, [currentPage, search, urlParam]);

  useEffect(() => {
    handleQuery(1, activeFilters, search, urlParam);
  }, [activeFilters, urlParam]);

  const getFinancialsData = async (page, activeFilters, search, urlParam) => {
    setIsContentLoading(true);
    try {
      const payload = {
        pageNumber: page || currentPage,
        pageSize,
        ...filters(activeFilters),
        ...(search && { search }),
        ...(
          urlParam === 'approved'
          ? {
            status: "Concluido"
          }
          : search && { search }
        ),
      }

      const { flockInvoiceInfoList, metadata } = await getFlockInvoiceList(payload);

      setData({ itens: flockInvoiceInfoList });
      setMetadata(metadata);
    } catch (error) {
      console.error(error);
    }
    setIsContentLoading(false);
  };

  const getFilters = useMemo(() => {
    if (!metadata) {
      return;
    }

    const {
      creationSystem,
      regional,
      status,
      unidade
    } = metadata;

    const regionalTypeOptions = 
      regional.map(record => ({
        txt: record,
        id: record,
        filter: "regional"
      }));

    const unitTypeOptions = 
      unidade.map(record => ({
        txt: record,
        id: record,
        filter: "unidade"
      }));

    const creationTypeOptions = 
      creationSystem.map(record => ({
        txt: record,
        id: record,
        filter: "creationSystem"
      }));

    const statusTypeOptions = 
      status.map(record => ({
        txt: record,
        id: record,
        filter: "status"
      }));

    const filters = [
      {
        label: t('next.invoiceManagement.filters.byRegion'),
        asd: "byRegion",
        items: [
          (regionalTypeOptions.length && ({
            txt: t('next.invoiceManagement.filters.option.byRegion'),
            filter: "regional",
            id: 'all',
          })),
          ...regionalTypeOptions
        ]
      },
      {
        label: t('next.invoiceManagement.filters.byUnit'),
        items: [
          (unitTypeOptions.length && ({
            txt: t('next.invoiceManagement.filters.option.byUnit'),
            filter: "unidade",
            id: 'all',
          })),
          ...unitTypeOptions
        ]
      },
      {
        label: t('next.invoiceManagement.filters.byCreationType'),
        items: [
          (creationTypeOptions.length && ({
            txt: t('next.invoiceManagement.filters.option.byCreationType'),
            filter: "creationSystem",
            id: 'all',
          })),
          ...creationTypeOptions
        ]
      }
    ];

    if (urlParam === 'pending') {
      filters.push({
        label: t('next.invoiceManagement.filters.byStatus'),
        items: [
          (statusTypeOptions.length && ({
            txt: t('next.invoiceManagement.filters.option.byStatus'),
            filter: "status",
            id: 'all',
          })),
          ...statusTypeOptions
        ]
      });
    };

    return filters;
  }, [metadata]);

  const handleQuery = useCallback(debounce(getFinancialsData, 1000), []);

  const filters = (activeFilters) => {
    return activeFilters.reduce(
      (obj, item) => Object.assign(obj, { [item.filter]: item.id }),
      {}
    )
  };

  const mapBadgeColor = (status = '') => {
    return {
      ['concluido']: 'colorSuccess',
      ['enviado']: 'colorSuccess',
      ['processando']: 'colorInfo',
      ['pendente']: 'colorSecondaryYellow',
      ['reprovado']: 'colorDanger'
    }[status.toLowerCase()];
  };

  const mapBadgeLabel = (status = '') => {
    return {
      ['concluido']: 'next.invoiceManagement.table.row.status.conclude',
      ['enviado']: 'next.invoiceManagement.table.row.status.sent',
      ['processando']: 'next.invoiceManagement.table.row.status.processing',
      ['pendente']: 'next.invoiceManagement.table.row.status.pending',
      ['reprovado']: 'next.invoiceManagement.table.row.status.reproved',
    }[status.toLowerCase()];
  }

  const tableData = useMemo(
    () =>
      data &&
      data.itens &&
      data.itens.length > 0 &&
      data.itens.map((e, index) => {
        return {
          0: (
            <Cell isMinimum>
              <Field>
                <Checkbox
                  checked={e.selected}
                  disabled={e.invoiceStatus === 'Concluido' || e.invoiceStatus === 'Reprovado'}
                  onChange={() => {
                    const updatedRows = [...data.itens];

                    updatedRows[index].selected = !updatedRows[index]
                      .selected;

                    const stateUpdated = {
                      ...data,
                      itens: updatedRows,
                    }
                    
                    updateData(stateUpdated);
                  }}
                >
                  <Label hidden>Checkbox for item {index}</Label>
                </Checkbox>
              </Field>
            </Cell>
          ),
          1: (
            <div className={isMobile ? 'column mobile' : 'column'}>
              <p className="small caps">
                {t('next.invoiceManagement.table.row.code', { value: e.producerVendor })}
              </p>
              <p className="strong">
                {e.producerName}
              </p>
              <p className="small">
                {t('next.invoiceManagement.table.row.fieldSupervisorName', { value: e.supervisorName })}
              </p>
            </div>
          ),
          2: `${e.unidadeName}`,
          3: `${creationMapper(e.animalType.type)}`,
          4: `${e.flockNumber}`,
          5: `${t('next.invoiceManagement.table.row.quantitySlaughtered', { value: e.qtPlacementReal })}`,
          6: `${moment(e.slaughterDate).isValid() ? moment(e.slaughterDate).format('DD/MM/YYYY') : "-"}`,
          7: (
            <BadgePill
              bgColor={mapBadgeColor(e.invoiceStatus)}
              style={{ marginLeft: 0 }}
            >
              {t(mapBadgeLabel(e.invoiceStatus))}
            </BadgePill>
          ),
          8: (
            <div
              style={{
                width: 'fit-content',
                display: 'flex',
              }}
            >
              <div
                style={{
                  borderRadius: '50%',
                  width: '32px',
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  backgroundColor: '#D22630',
                  boxShadow: '0px 4px 16px 0px #25304733',
                  cursor: 'pointer',
                  ...((e.invoiceStatus === 'Concluido' || e.invoiceStatus === 'Reprovado') && {
                    cursor: 'default',
                    backgroundColor: '#CFD2D8',
                  }),
                }}
                onClick={
                  () =>
                    (
                      e.invoiceStatus !== 'Concluido' &&
                      e.invoiceStatus !== 'Reprovado'
                    ) &&
                  history.push(`${routes.FINANCIALS_DETAILS}/${e.invoiceId}?action=reject`)
                }
              >
                <Icon
                  name="icon-close"
                  size={24}
                  color={(e.invoiceStatus === 'Concluido' || e.invoiceStatus === 'Reprovado') ? '#7F879A' : '#fff'}
                />
              </div>
              <div
                style={{
                  borderRadius: '50%',
                  width: '32px',
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  backgroundColor: '#009688',
                  cursor: 'pointer',
                  boxShadow: '0px 4px 16px 0px #25304733',
                  marginLeft: 16,
                  ...((e.invoiceStatus === 'Concluido' || e.invoiceStatus === 'Reprovado') && {
                    cursor: 'default',
                    backgroundColor: '#CFD2D8',
                  }),
                }}
                onClick={
                  () =>
                    (
                      e.invoiceStatus !== 'Concluido' &&
                      e.invoiceStatus !== 'Reprovado'
                    ) &&
                  setApproveConfirmation(e.invoiceId)
                }
              >
                <Icon
                  name="icon-check"
                  color={(e.invoiceStatus === 'Concluido' || e.invoiceStatus === 'Reprovado') ? '#7F879A' : '#fff'}
                  size={24}
                />
              </div>
            </div>
          ),
          9: (
            <DropDown id={e.invoiceId} history={history} blobName={e.invoiceBlobName} />
          ),
        };
      }),
    [data],
  );

  const updateData = (values) => {
    setData(values);
    setSelecteRows(values.itens.filter(item => item.selected));
  }

  const handleApprove = async id => {
    const data = {
      invoiceId: id,
      invoiceStatus: "Concluido",
    };

    const result = await approveInvoice(data);

    setResponse(result);
    handleQuery(currentPage, activeFilters);
  };

  return (
    <>
      <ContentsWrapper>
        <Search 
          value={search}
          onChange={setSearch}
        />

        <Container style={{ marginBottom: '30px' }}>
          {!isContentLoading && !data && (
            <NoContentTab
              icon="icon-clipboard-user"
              onButtonClick={() => {}}
            >
              <p>{t('global.no-content')}</p>
            </NoContentTab>
          )}

          <TableComponent
            loading={isContentLoading}
            filter={{
              activeFilters,
              setActiveFilters,
              filters: getFilters,
            }}
            titleColor={themeContext.styles.palette.colorPrimary}
            rowSize="default"
            header={[
              {
                width: 3,
                title: '',
                hidden: true
              },
              {
                width: 25,
                title: t('next.invoiceManagement.table.column.name'),
                alignLeft: true,
              },
              {
                width: 15,
                title: t('next.invoiceManagement.table.column.unit'),
              },
              {
                width: 15,
                title: t('next.invoiceManagement.table.column.typeOfCreation'),
              },
              {
                width: 15,
                title: t('next.invoiceManagement.table.column.flock'),
              },
              {
                width: 15,
                title: t('next.invoiceManagement.table.column.quantity'),
              },
              {
                width: 15,
                title: t('next.invoiceManagement.table.column.slaughterDate'),
              },
              {
                width: 15,
                title: t('next.invoiceManagement.table.column.status'),
              },
              {
                width: 10,
                title: t('next.invoiceManagement.table.column.action'),
              },
              {
                width: 3,
                title: t(''),
              },
            ]}
            mobileHeader={t('next.operationalSchedule.table.header.user')}
            data={tableData}
            state={data || {}}
            filterDisabled={!data || !data.itens || !data.itens.length}
            width="25%"
            isMobile={isMobile}
            showCheckbox
            setData={updateData}
          />
        </Container>

        {metadata &&
          Number(metadata.totalPages) > 0 && (
            <Pagination
              totalPages={metadata.totalPages}
              pagePadding={isMobile ? 0.75 : 2}
              currentPage={currentPage}
              onChangePage={x => setCurrentPage(x.e)}
            />
          )
        }
      </ContentsWrapper>

      {response && (
        <Toaster
          className="toaster__notification"
          isVisible={true}
          color='success'
          duration={3}
          delay={0}
          title={
            response.success
              ? t('Nota fiscal foi aprovada')
              : t('Erro!')
          }
        >
          <span className="icon icon-check" />
          {
            response.success
              ? t('Aguarde o fechamento do lote e a geração da remuneração.')
              : t('Ocorreu um erro ao aprovar notas')
          }
        </Toaster>
      )}

      {Boolean(approveConfirmation) && (
        <Modal
          warning
          opened={Boolean(approveConfirmation)}
          confirm={() => {
            handleApprove(approveConfirmation);
            setApproveConfirmation(false);
          }}
          deny={() => setApproveConfirmation(false)}
          onCloseModal={() => setApproveConfirmation(false)}
          confirmLabel={t('Confirmar')}
          denyLabel={t('Voltar')}
        >
          <div style={{ display: 'flex' }}>
            <span className="icon icon-exclamation-triangle" />
            <h4>{t('Deseja realmente aprovar a nota fiscal?')}</h4>
          </div>
          <p>{t('Está operação não poderá ser revertida após a confirmação.')}</p>
        </Modal>
      )}
    </>
  );
}
