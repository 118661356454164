import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Progress } from './ProgressCircle.styled';

export default function ProgressCircle({ value, barColor }) {
  const themeContext = useContext(ThemeContext);

  return (
    <Progress
      color={themeContext.styles.palette[barColor]}
      // eslint-disable-next-line radix
      value={parseInt(value)}
    />
  );
}
