import React, { useContext } from 'react';
// context
import { MemberContext } from 'shared/contexts/Member';
import { Button } from '@zendeskgarden/react-buttons';
import Heading from 'components/Heading';
import LoadingHolder from 'components/LoadingHolder';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Form, FixedField } from 'components/Forms/Forms.styled';
import { InputField, ReCaptchaField } from 'components/Forms/Fields';
import { RECAPTCHA_KEY } from 'config/constants';
import {
  Container,
  Layer,
} from 'containers/OnePage/BecomeAMember/BecomeAMember.styled';
import t from 'components/i18n';

export default function FirstMemberForm(props) {
  const reCaptchaRef = React.createRef();
  const memberContext = useContext(MemberContext);
  const { setName, setEmail, setRecaptcha } = memberContext.actions;
  const {
    setformStep,
    setFieldValue,
    handleChange,
    handleBlur,
    isLoading,
    values,
    errors,
    touched,
  } = props;

  function onLoadRecaptcha() {
    console.log('Done!');
  }

  return (
    <>
      {isLoading ? (
        <LoadingHolder
          isLoading={isLoading}
          message={t('global.loading')}
        />
      ) : (
        <Form autoComplete="off">
          <>
            {/* Primeira tela */}
            <Layer>
              <Heading
                pageTitle={t('beAnAGrower.form.title')}
                subHeading={t('beAnAGrower.form.subtitle')}
                paragraph={t('beAnAGrower.form.description')}
              />
              {/* Nome */}
              <InputField
                type="text"
                field="name"
                label={t('beAnAGrower.form.input.name.label')}
                placeholder={t('beAnAGrower.form.input.name.placeholder')}
                onBlur={handleBlur}
                onChange={e => {
                  setName(e.target.value);
                  handleChange(e);
                }}
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Nome */}

              {/* Email */}
              <InputField
                type="text"
                field="email"
                label={t('beAnAGrower.form.input.email.label')}
                placeholder={t('beAnAGrower.form.input.email.placeholder')}
                onBlur={handleBlur}
                onChange={e => {
                  setEmail(e.target.value);
                  handleChange(e);
                }}
                values={values}
                errors={errors}
                touched={touched}
              />
              {/* \ Email */}

              {/* Captcha */}
              <div className="recaptchaWrapper">
                <ReCaptchaField
                  refs={reCaptchaRef}
                  field="recaptcha"
                  theme="light"
                  sitekey={RECAPTCHA_KEY}
                  verifyCallback={response => {
                    setRecaptcha(response);
                    setFieldValue('recaptcha', response);
                  }}
                  onloadCallback={onLoadRecaptcha}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              {/* \ Captcha */}

              {/* \ Botões */}
              <FixedField>
                <Container>
                  <BtnGroup>
                    <Button
                      key="nextStage"
                      onClick={() => {
                        setformStep(2);
                      }}
                      disabled={!props.isValid}
                    >
                      {t('beAnAGrower.form.details.buttonPrimary')}
                    </Button>
                    {!props.hideCancelButton && (
                      <Button
                        key="clean"
                        onClick={() => {
                          props.closeForm();
                        }}
                        className="white"
                      >
                      {t('beAnAGrower.form.details.buttonCancel')}
                      </Button>
                    )}
                  </BtnGroup>
                </Container>
              </FixedField>
              {/* \ Botões */}
            </Layer>
            {/* \ Primeira tela */}
          </>
        </Form>
      )}
    </>
  );
}
