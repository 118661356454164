import React, { useEffect } from 'react';
import { Form } from 'components/Forms/Forms.styled';
import LoadingHolder from 'components/LoadingHolder';
import NewVisit from 'containers/NextBRF/WeekSchedule/NewVisit';
import Step1 from './Step1';
import Step2 from './Step2';
import t from 'components/i18n';

export default function SettingsForm(props) {
  const {
    history,
    handleSubmit,
    resetForm,
    setFieldValue,
    isLoading,
    getSegmentData,
    segments,
    getLogBookConfigData,
    getLogBookConfigDefaultData,
    configData,
    canEdit,
    setCanEdit,
    setPageTitle,
    customVisits,
    setCustomVisits,
    step,
    setStep,
    author,
    setAuthor,
    isValid,
    values,
    errors,
    touched,
  } = props;

  useEffect(() => {
    if (values.animalType && values.animalType.value) {
      getSegmentData(values.animalType.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.animalType]);

  useEffect(() => {
    if (values.animalType && values.animalType.value === 'suino') {
      setFieldValue('isSwine', true);
    } else {
      setFieldValue('isSwine', false);
    }
  }, [setFieldValue, values.animalType]);

  useEffect(() => {
    if (
      configData &&
      configData.logBookConfigItems &&
      configData.logBookConfigItems.length > 0
    ) {
      configData.logBookConfigItems.forEach(item => {
        const logbook = `logbookItem_${item.logBookItemTypeId}`;

        setFieldValue(
          `${logbook}_daysStart`,
          item.daysStart.toString(),
        );
        setFieldValue(`${logbook}_daysEnd`, item.daysEnd.toString());
        setFieldValue(`${logbook}_isEnable`, item.isEnable);
        setFieldValue(`${logbook}_isRequired`, item.isRequired);
        setFieldValue(`${logbook}_isCustomized`, item.isCustomized);
      });
      setFieldValue('isDefault', configData.isDefault);
    }
  }, [configData, setFieldValue]);

  useEffect(() => {
    if (customVisits && customVisits.length > 0) {
      customVisits.forEach(item => {
        const logbook = `logbookItem_${item.logBookItemTypeId}`;

        setFieldValue(
          `${logbook}_daysStart`,
          item.daysStart.toString(),
        );
        setFieldValue(`${logbook}_daysEnd`, item.daysEnd.toString());
        setFieldValue(`${logbook}_isEnable`, item.isEnable);
        setFieldValue(`${logbook}_isRequired`, item.isRequired);
        setFieldValue(`${logbook}_isCustomized`, item.isCustomized);
      });
    }
  }, [customVisits, setFieldValue]);

  return (
    <>
      <LoadingHolder
        message={t('global.loading')}
        isLoading={isLoading}
      />

      {!isLoading && (
        <Form autoComplete="off">
          {step === 1 && (
            /* First screen */
            <Step1
              setFieldValue={setFieldValue}
              setPageTitle={setPageTitle}
              segments={segments}
              getLogBookConfigData={getLogBookConfigData}
              isValid={isValid}
              resetForm={resetForm}
              values={values}
              errors={errors}
              touched={touched}
            />
            /* \ First screen */
          )}

          {step === 2 && (
            /* Second screen */
            <Step2
              setFieldValue={setFieldValue}
              setPageTitle={setPageTitle}
              segments={segments}
              author={author}
              setAuthor={setAuthor}
              canEdit={canEdit}
              setCanEdit={setCanEdit}
              setStep={setStep}
              history={history}
              handleSubmit={handleSubmit}
              configData={configData}
              getLogBookConfigData={getLogBookConfigData}
              getLogBookConfigDefaultData={
                getLogBookConfigDefaultData
              }
              customVisits={customVisits}
              setCustomVisits={setCustomVisits}
              isValid={isValid}
              resetForm={resetForm}
              values={values}
              errors={errors}
              touched={touched}
            />
            /* \ Second screen */
          )}

          {step === 3 && (
            /* New visit form */
            <NewVisit
              setStep={setStep}
              customVisits={customVisits}
              setCustomVisits={setCustomVisits}
            />
            /* \ New visit form */
          )}
        </Form>
      )}
    </>
  );
}
