import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { BadgePillStyled } from './BadgePill.styled';

export default function BadgePill({
  bgColor,
  minWidth,
  children,
  style,
  className,
}) {
  const themeContext = useContext(ThemeContext);

  return (
    <BadgePillStyled
      className={className}
      style={style}
      bgColor={themeContext.styles.palette[bgColor]}
      minWidth={minWidth}
    >
      {children}
    </BadgePillStyled>
  );
}
