import React from 'react';
import SidebarRight, {
  SidebarRightContent,
  SidebarToggler,
} from 'components/SidebarRight';
import Icon from 'components/Icon';
import t from 'components/i18n';
import {
  Menu,
  TabletMenu,
  ListItem,
} from 'containers/AgroBRF/FlockList/Flock/Flock.styled';

export default function NextFlockListMenu(props) {
  const {
    selectedKey,
    isMobile,
    isTablet,
    isGtMobile,
    setSelectedKey,
    isChecklist,
  } = props;

  function getHeading(type, isChecklist) {
    if (isChecklist) {
      return type === 'opened' ? t('next.flock.flockListMenu.checklist.open.title') : t('next.flock.flockListMenu.checklist.close.title');
    }

    return type === 'opened' ? t('next.flock.flockListMenu.flock.open.title') : t('next.flock.flockListMenu.flock.close.title');
  }

  function getMenuIcon(type) {
    return type === 'opened'
      ? 'icon-lock-disabled'
      : 'icon-lock-enabled';
  }

  function getTabletToggler(type) {
    return (
      <button
        onClick={() => setSelectedKey(type)}
        type="button"
        className={`unstyled-button tablet-menu-item ${
          selectedKey === type ? 'active' : ''
        }`}
      >
        <Icon className="tablet-menu-icon" name={getMenuIcon(type)} />
        <span className="tablet-menu-label">
          {getHeading(type, isChecklist)}
        </span>
      </button>
    );
  }

  function getToggler(type) {
    const menuItemProps = {
      className: 'menu-item',
    };

    if (isGtMobile) {
      menuItemProps.className = `menu-item ${
        selectedKey === type ? 'active' : ''
      }`;
    }

    return (
      <div {...menuItemProps}>
        <Icon className="menu-icon" name={getMenuIcon(type)} />

        <ListItem
          onClick={() => {
            setSelectedKey(type);
          }}
          role="button"
          className="animated fadeIn"
        >
          <div className="list-item-content">
            <div className="list-item-heading">
              {getHeading(type, isChecklist)}
            </div>
          </div>
          <Icon name="chevron icon-chevron-right" />
        </ListItem>
      </div>
    );
  }

  return (
    <div className="flock-menu">
      {isTablet && (
        <TabletMenu>
          {getTabletToggler('opened')}
          {getTabletToggler('closed')}
        </TabletMenu>
      )}

      {!isTablet && (
        <Menu>
          <div className="menu-group">
            {isMobile && (
              <SidebarRight>
                <SidebarToggler>{getToggler('open')}</SidebarToggler>
                <SidebarToggler>
                  {getToggler('closed')}
                </SidebarToggler>
                <SidebarRightContent
                  title={getHeading(selectedKey, isChecklist)}
                />
              </SidebarRight>
            )}

            {isGtMobile && (
              <>
                {getToggler('opened')}
                {getToggler('closed')}
              </>
            )}
          </div>
        </Menu>
      )}
    </div>
  );
}
