import styled from 'styled-components';
import {
  boxShadow,
  fontFamilyText,
  colorGray,
  colorClear,
  breakpointTablet,
  DEFAULT_SIZE,
} from 'components/GlobalStyles/variables';

export const ReportsStyled = styled.div`
  position: relative;
`;

export const ContentsWrapper = styled.div`
  position: relative;
  /* max-width: 986px; */
`;

export const Container = styled.div`
  position: relative;
  /* max-width: 986px; */
  padding: 0 ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px 20px 60px 20px;
  }

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }
`;

export const OptionsButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  outline: 0;
`;
