import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  DEFAULT_SIZE,
  boxShadow,
  breakpointTablet,
  breakpointDesktop,
  fontFamilyText,
  fontFamilyTextBold,
  colorClear,
  colorGrayUltraLight,
  colorGrayLighter,
  colorGrayDarkest,
} from 'components/GlobalStyles/variables';

export const ProfileStyled = styled.div`
  display: flex;
  padding: ${DEFAULT_SIZE * 2}px 20px;
  flex-wrap: wrap;

  p {
    color: ${colorGrayDarkest};
    font-size: 14px;
    line-height: 21px;
  }

  .secondary-wrapper {
    min-width: 280px;
    margin: 0 20px 20px 0;

    @media (min-width: ${breakpointDesktop}px) {
      min-width: 316px;
    }
  }

  .container {
    max-width: 652px;
    width: 100%;
    overflow: hidden;
  }

  h4 {
    color: ${colorGrayDarkest};
    font-size: 16px;
    line-height: 24px;
  }

  .info {
    color: ${colorGrayDarkest};
    font-size: 14px;
    margin: 0;
    line-height: 21px;
  }

  button.white.whiteBorder {
    padding: 0 !important;
    line-height: 1 !important;
    font-size: 14px !important;
  }
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -20px;
  opacity: 0.25;
  margin: ${props => props.gap && `${props.gap}px -${DEFAULT_SIZE * 2}px`};
  width: ${props => props.full && `calc(100% + 32px)`};
`;

export const Menu = styled.div`
  background-color: ${colorClear};
  padding: 0 ${DEFAULT_SIZE * 2}px;
  padding-top: ${props => (props.hasChildren ? '30px' : '0')};
  margin-top: ${props => (props.hasChildren ? '-30px' : '0')};
  border-radius: ${DEFAULT_SIZE}px;
  overflow: hidden;
  box-shadow: ${boxShadow};
  width: 100%;
  margin-bottom: 15px;

  .menu-icon {
    font-size: 24px;
    color: ${colorGrayLighter};
    align-items: center;
    display: flex;
    margin-right: 24px;

    &:after {
      content: '';
      transition: 0.2s ease-in background-color;
      background-color: transparent;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      left: -8px;
      z-index: 0;
    }
  }

  .list-item-heading {
    color: ${colorGrayDarkest};
  }

  .menu-heading {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }

  .list-item-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .active {
    .menu-icon,
    .chevron,
    .list-item-heading {
      color: ${colorGrayDarkest} !important;
    }

    .menu-icon {
      :before {
        z-index: 1;
      }
      :after {
        background-color: ${colorGrayUltraLight};
      }
    }
  }

  .menu-group {
    margin-bottom: ${DEFAULT_SIZE * 2}px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 56px;
    width: 100%;
    cursor: pointer;

    .arrow {
      font-size: 24px;
    }

    &:after {
      content: '';
      display: block;
      background-color: ${colorGrayDarkest};
      opacity: 0.12;
      width: 100%;
      max-width: 250px;
      height: 1px;
      position: absolute;
      left: 50px;
      bottom: 0;
    }

    &:last-child {
      &:after {
        height: 0;
      }
    }
  }

  @media (min-width: ${breakpointTablet}px) {
    width: auto;
  }

  @media (min-width: ${breakpointDesktop}px) {
    width: 100%;
    max-width: 376px;
  }
`;

export const FormSection = styled.section`
  padding: 32px 16px 12px;
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.16)};
  margin: 0 -16px;

  h6 {
    font-family: ${fontFamilyTextBold};
    color: ${colorGrayDarkest};
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 16px;
  }
`;

export const Item = styled.li`
  color: ${colorGrayDarkest};
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.16)};
  padding: 16px;
  font-size: 14px;
  line-height: 21px;
  list-style: none;

  p {
    margin: 0;
  }

  &:last-of-type {
    border: none;
  }

  &.right {
    justify-content: flex-end;
  }

  .margin {
    margin-bottom: 8px;
  }

  .label {
    font-size: 12px;
  }

  .small {
    font-size: 12px;
    line-height: 16px;
  }

  .icon {
    font-size: 21px;
    margin: 0 0 0 16px;
  }

  button {
    width: 154px;
  }
`;

export const TermWrapper = styled.section`
  padding-bottom: 24px;

  h2,
  h3,
  footer {
    font-size: 14px;
  }

  h3 {
    padding-top: 16px;
  }
`;

export const PolicyWrapper = styled.section`
  padding-bottom: 24px;

  ol {
    margin: 0;
    padding: 0 0 0 16px;
  }

  b {
    font-family: ${fontFamilyTextBold};
    font-weight: normal;
  }

  h2,
  h4,
  footer {
    font-size: 14px;
  }

  h4 {
    padding-top: 16px;

    &:first-of-type {
      padding: 0;
    }
  }
`;
