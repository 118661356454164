// @flow

import React, { useState, useEffect, useCallback } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import LoadingHolder from 'components/LoadingHolder';
import { Formik } from 'formik';
// validate
import validate from 'components/Forms/Fields/validate';
import { NextMedicationStyled } from './NextMedication.styled';

// form
import NextMedicationForm from '../Forms/NextMedicationForm';

// service
import { saveMedications, getMedicationDetails } from './service';
import t from 'components/i18n';

export default function EditNextMedication({
  flockID,
  setEditMedication,
  setSelectedMedicationId,
  selectedMedicationId,
  setMedicationResponse,
  setSuccess,
  isEditing,
  setIsEditing,
  sendToConfirmation,
  setSendToConfirmation,
  setDisplayExcludeModal,
  animalType,
  isFlockClosed,
}) {
  const [message, setMessage] = useState('');
  const [selectedValues, setSelectedValues] = useState(null);
  const [loadingMedication, setLoadingMedication] = useState(false);
  const [medicationDetails, setMedicationDetails] = useState(null);

  const validateSchema = () => {
    return yup.object().shape({
      startDate: yup
        .date()
        .max(
          yup.ref('endDate'),
          t('global.validation.initial-date-before-final-date'),
        )
        .typeError(t('global.validation.select-initial-date'))
        .required(t('global.status.required-field')),
      endDate: yup
        .date()
        .min(
          yup.ref('startDate'),
          t('global.validation.initial-date-after-final-date'),
        )
        .typeError(t('global.validation.invalid-end-date'))
        .required(t('global.status.required-field')),
      material: yup.string().required(t('global.status.required-field')),
      startTime: yup.string().required(t('global.status.required-field')),
      endTime: yup.string().required(t('global.status.required-field')),
      vaccineBatch: yup.string().required(t('global.status.required-field')),
      quantity: yup.number().required(t('global.status.required-field')),
      healthProblem: yup.string().required(t('global.status.required-field')),
    });
  };

  const fetchMedication = useCallback(async id => {
    setLoadingMedication(true);
    try {
      const response = await getMedicationDetails(id);
      setMedicationDetails(response);
    } catch (e) {
      console.error('Error on getMedicationDetails', e);
    }
    setLoadingMedication(false);
  }, []);

  useEffect(() => {
    if (selectedMedicationId) {
      fetchMedication(selectedMedicationId);
    }
  }, [fetchMedication, selectedMedicationId]);

  const initialValues = {
    animalType: '',
    treatmentPeriod: '',
    material: '',
    laboratory: '',
    vaccineBatch: '',
    quantity: '',
    healthProblem: '',
    quantityTreated: '',
    flockID: flockID,
  };

  const submitForm = useCallback(
    (values, { setSubmitting, setErrors }) => {
      const formData = {
        id: values.id || 0,
        startedDate: `${values.startDate}T${moment(
          values.startTime,
        ).format('HH:mm:ss')}.000Z`,
        endedDate: `${values.endDate}T${moment(values.endTime).format(
          'HH:mm:ss',
        )}.000Z`,
        flockId: flockID,
        materialTypeId: values.material.id,
        materialName: values.material.description,
        materialCode: values.material.materialCode,
        measurementUnit: values.material.measurement,
        vaccineBatch: values.vaccineBatch.toString(),
        quantity: values.quantity,
        healthProblemTypeId: values.healthProblem.id,
        healthProblemName: values.healthProblem.name,
        animalType: animalType,
      };

      async function saveData(val) {
        setSubmitting(true);
        try {
          const response = await saveMedications(val);

          if (response.data) {
            setMedicationResponse(response.message);
            setSuccess(response.success);
          } else {
            setSuccess(false);
            throw Error(response);
          }
        } catch (error) {
          console.error(error);
        }
        setSubmitting(false);
        setEditMedication(false);
      }

      saveData(formData);
    },
    [
      animalType,
      flockID,
      setEditMedication,
      setMedicationResponse,
      setSuccess,
    ],
  );

  useEffect(() => {
    if (medicationDetails) {
      setSelectedValues({
        id: medicationDetails.id,
        houseId: medicationDetails.houseId,
        startDate: medicationDetails.startedDate.substr(0, 10),
        endDate: medicationDetails.endedDate.substr(0, 10),
        startTime: medicationDetails.startedDate,
        endTime: medicationDetails.endedDate,
        material: {
          description: medicationDetails.materialName,
          id: medicationDetails.materialTypeId,
          measurement: medicationDetails.measurementUnit,
          code:
            medicationDetails.materialCode.indexOf('000000000000') >
            -1
              ? medicationDetails.materialCode.substr(12)
              : medicationDetails.materialCode,
        },
        laboratory: {
          description: medicationDetails.laboratoryName,
          id: medicationDetails.laboratoryTypeId,
        },
        vaccineBatch: medicationDetails.vaccineBatch,
        quantity: medicationDetails.quantity,
        healthProblem: {
          name: medicationDetails.healthProblemName,
          id: medicationDetails.healthProblemTypeId,
        },
        quantityTreated: medicationDetails.quantityTreated,
      });
    }
  }, [medicationDetails, selectedMedicationId]);

  const getFormikForm = useCallback(
    val => {
      return (
        <Formik
          initialValues={val}
          validate={validate(validateSchema)}
          onSubmit={submitForm}
          render={formikProps => (
            <NextMedicationForm
              {...formikProps}
              flockID={flockID}
              message={message}
              setMessage={setMessage}
              selectedMedication={selectedMedicationId}
              setSelectedMedication={setSelectedMedicationId}
              setEditMedication={setEditMedication}
              sendToConfirmation={sendToConfirmation}
              setSendToConfirmation={setSendToConfirmation}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              setDisplayExcludeModal={setDisplayExcludeModal}
              isFlockClosed={isFlockClosed}
            />
          )}
        />
      );
    },
    [
      flockID,
      isEditing,
      isFlockClosed,
      message,
      selectedMedicationId,
      sendToConfirmation,
      setDisplayExcludeModal,
      setEditMedication,
      setIsEditing,
      setSelectedMedicationId,
      setSendToConfirmation,
      submitForm,
    ],
  );

  return (
    <NextMedicationStyled>
      <LoadingHolder isLoading={loadingMedication} />

      {!loadingMedication &&
        medicationDetails &&
        selectedValues &&
        getFormikForm(selectedValues)}

      {!loadingMedication &&
        !medicationDetails &&
        getFormikForm(initialValues)}
    </NextMedicationStyled>
  );
}
