// @flow

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as routes from 'config/routes';

import type { TRoutePublic } from './typings';

function RoutePublic(props: TRoutePublic) {
  const {
    component: Component,
    isAuthenticated,
    to,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={properties =>
        isAuthenticated ? (
          <Redirect to={to} />
        ) : (
          <Component {...properties} />
        )
      }
    />
  );
}

RoutePublic.defaultProps = {
  to: routes.HOME,
};

export default RoutePublic;
