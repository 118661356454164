import t from "components/i18n";

export const fileTypes = [
  {
    label: t('fileType.label.document'),
    value: 'Documento',
    accept: 'application/pdf, application/ppt',
    input: 'file',
    extensions: ['pdf', 'ppt'],
  },
  {
    label: t('fileType.label.image'),
    value: 'Imagem',
    accept: 'image/*',
    input: 'image',
    extensions: ['jpg', 'jpeg', 'png'],
  },
  {
    label: t('fileType.label.video'),
    value: 'Vídeo',
    accept: 'video/mp4',
    input: 'file',
    extensions: ['mp4', 'mov'],
  },
];

export const profileTypes = [
  { label: t('userType.profile.all'), value: 'T' },
  { label: t('userType.profile.next'), value: 'E' },
  { label: t('userType.profile.growers'), value: 'P' },
];

export const animalTypes = [
  { label: t('creationType.option.all'), value: 'all' },
  { label: t('creationType.option.chicken'), value: 'broiler' },
  { label: t('creationType.option.turkey'), value: 'turkey' },
  { label: t('creationType.option.suine'), value: 'swine' },
];

export const allLocationOption = {
  id: 'all',
  countryCode: t('locations.option.all'),
  label: t('locations.option.all'),
};

export const allRegionalsOption = {
  id: 'all',
  name: t('regions.option.all'),
  label: t('regional.all'),
};

export const allCentersOption = {
  animalType: 'All',
  id: 'all',
  name: t('units.option.all'),
  title: t('units.option.all'),
};

export const icons = [
  {
    fileType: 'Documento',
    color: 'colorPrimary',
    icon: 'icon-file',
  },
  {
    fileType: 'Imagem',
    color: 'colorTertiary',
    icon: 'icon-image',
  },
  {
    fileType: 'Vídeo',
    color: 'colorDanger',
    icon: 'icon-video',
  },
];

export const nextIcons = [
  {
    fileType: 'Documento',
    color: 'colorSecondary',
    icon: 'icon-file',
  },
  {
    fileType: 'Imagem',
    color: 'colorPrimary',
    icon: 'icon-image',
  },
  {
    fileType: 'Vídeo',
    color: 'colorDanger',
    icon: 'icon-video',
  },
];
