import React from 'react';
import { StyledTitleGroup } from './TitleGroup.styled';

export default function TitleGroup(props) {
  const { title, subtitle } = props;

  return (
    <StyledTitleGroup>
      <h3>{title}</h3>
      <h4>{subtitle}</h4>
    </StyledTitleGroup>
  );
}
