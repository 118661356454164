import React, { useState } from 'react';
import * as routes from 'config/routes';
import LoadingHolder from 'components/LoadingHolder';
import Icon from 'components/Icon';
import t from 'components/i18n';
import { NotificationItem } from './Notification.styled';

function getIcon(category) {
  switch (category) {
    case 'broiler': {
      return 'icon-chicken';
    }

    case 'turkey': {
      return 'icon-turkey';
    }

    case 'swine': {
      return 'icon-pig';
    }

    case 'placement': {
      return 'icon-warehouse';
    }

    case 'slaughter': {
      return 'icon-chicken';
    }

    case 'payment': {
      return 'icon-money';
    }

    case 'partial': {
      return 'icon-money';
    }

    case 'communication': {
      return 'icon-notice';
    }

    case 'library': {
      return 'icon-library';
    }

    case 'Orientation': {
      return 'icon-edit-alt';
    }

    case 'removalFeed': {
      return 'icon-clock';
    }

    default: {
      return 'icon-truck';
    }
  }
}

export default function Notification({
  notification,
  setRead,
  setHidden,
  isLoading,
  isLastItem,
  history,
}) {
  const [actionIsOpen, setActionIsOpen] = useState(false);

  function getItemId(category) {
    switch (category) {
      case 'communication':
        return notification.announcementId;

      case 'library':
        return `${notification.libraryCategoryId}/${notification.librarySubCategoryId}`;

      case 'Orientation':
        return notification.logBookItemId;

      default:
        return notification.flockId;
    }
  }

  function redirect(notificationId, itemId, category, app) {
    setRead(notificationId);

    if (itemId) {
      switch (category) {
        case 'removalFeed':
          history.push(`${routes.FLOCK_LIST}/${itemId}/next`);
          break;

        case 'communication':
          history.push(`${routes.OPEN_ANNOUNCEMENT}/${itemId}`);
          break;

        case 'Orientation':
          history.push(
            `${
              app === 1 ? routes.ORIENTATION : routes.NEXT_ORIENTATION
            }/${itemId}/${notification.flockId}`,
          );
          break;

        case 'payment':
          history.push(`${routes.FLOCK_LIST}/${itemId}`);
          break;

        case 'library':
          history.push(`${routes.LIBRARY}/list/${itemId}`);
          break;

        default:
          history.push(`${routes.FLOCK_LIST}/${itemId}/${category}`);
          break;
      }
    }
  }

  if (isLoading) {
    return <LoadingHolder isLoading={isLoading} />;
  }

  return (
    <NotificationItem
      key={notification.id}
      className={`notification ${
        notification.isRead === false ? 'is-unread' : ''
      }
      ${isLastItem ? ' is-last' : ''}`}
    >
      <div className="notification-content">
        <div className="notification-icon">
          <span className="notification-icon-holder">
            <Icon
              onClick={() =>
                redirect(
                  notification.id,
                  getItemId(notification.category),
                  notification.category,
                  notification.app,
                )
              }
              className={getIcon(
                notification.animalType || notification.category,
              )}
              name={getIcon(
                notification.animalType || notification.category,
              )}
            />
          </span>
        </div>

        <div className="notification-message">
          <div className="notification-message-holder">
            <div
              className="notification-text"
              onClick={() =>
                redirect(
                  notification.id,
                  getItemId(notification.category),
                  notification.category,
                  notification.app,
                )
              }
            >
              {notification.message}
            </div>
            <div className="notification-trigger-action">
              <button
                onClick={() => setActionIsOpen(!actionIsOpen)}
                className="notification-open-actions"
                type="button"
              >
                <Icon name="icon-ellipsis-v" />
              </button>
            </div>
          </div>
          <div className="notification-date">
            {notification.createdDate}
          </div>

          <div
            className={`notification-actions ${
              actionIsOpen ? 'isOpen' : ''
            }`}
          >
            {!notification.isRead && (
              <button
                disabled={isLoading}
                onClick={() => setRead(notification.id)}
                type="button"
                className="notification-action-button"
              >
                {<LoadingHolder isLoading={isLoading} size="12" />}

                {!isLoading && <span>{t('notification.mark-as-read')}</span>}
              </button>
            )}

            <button
              disabled={isLoading}
              onClick={() => setHidden(notification.id)}
              type="button"
              className="notification-action-button"
            >
              {<LoadingHolder isLoading={isLoading} size="12" />}
              {!isLoading && <span>{t('notification.ocult')}</span>}
            </button>
          </div>
        </div>
      </div>
    </NotificationItem>
  );
}
