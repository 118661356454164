import React from 'react';
import styled from 'styled-components';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import t from 'components/i18n';
import {
  colorGrayLighter,
  colorClear,
} from 'components/GlobalStyles/variables';
import Icon from 'components/Icon';

import { materialTypeFavorite } from '../../service';

const FavoriteBox = styled.div`
  position: relative;
  background-color: ${props => props.bgColor || colorGrayLighter};
  width: calc(100% + 32px);
  font-size: 14px;
  line-height: 16px;
  margin: -16px -16px 16px;
  padding: 16px;

  p,
  h5 {
    color: ${colorClear};
  }

  h5 {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;

    span {
      font-size: 24px;
      margin-right: 18px;
    }
  }

  button {
    max-width: 96px;
    margin-right: 12px;
    background-color: transparent;
  }
`;

export default function MedicationFavorite({
  data,
  setFavoriteCandidate,
}) {
  async function saveFavorite(id, val) {
    try {
      await materialTypeFavorite(id, val);
      setFavoriteCandidate(null);
    } catch {
      console.error('Error on materialTypeFavorite');
    }
  }
  return (
    data && (
      <FavoriteBox className="animated fadeIn">
        <h5>
          <Icon name="icon-exclamation-triangle" />
          Atenção
        </h5>
        <div style={{ marginLeft: '44px' }}>
          <p>
            {t("agro.flock.swine.medication.favoriteBox.add", { value: data.materialName })}
          </p>
          <BtnGroup noReverse>
            <Button
              key="deny"
              onClick={() => setFavoriteCandidate(null)}
              className="transparent"
            >
              {t('agro.flock.swine.medication.favoriteBox.button.no')}
            </Button>
            <Button
              key="apply"
              onClick={() => saveFavorite(data.id, data)}
              className="white whiteBorder"
            >
              {t('agro.flock.swine.medication.favoriteBox.button.yes')}
            </Button>
          </BtnGroup>
        </div>
      </FavoriteBox>
    )
  );
}
