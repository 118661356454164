import styled from 'styled-components';
import {
  fontFamilyText,
  colorGray,
  colorClear,
  colorSuccess,
  colorDanger,
  colorGrayLighter,
  colorPrimary,
  colorTertiary,
  colorSecondaryYellow,
} from 'components/GlobalStyles/variables';

export const IconPlaceholderStyled = styled.div`
  display: flex;
  min-width: ${props => (props.bigIcon ? '20' : '40')}px;
  margin: ${props => (props.bigIcon ? '0 12px 0 -6px' : '0')};
  height: 40px;
  border-radius: ${props => (props.round ? '50%' : '8px')};
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgColor || colorGrayLighter};
  position: relative;
  padding: 0;

  &.top {
    align-self: flex-start;
  }

  .icon {
    color: ${props =>
      props.bgColor === colorClear ? colorGrayLighter : colorClear};
    font-size: ${props => (props.bigIcon ? '30' : '24')}px;
  }

  &.initial-name {
    font-family: ${fontFamilyText}!important;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: ${colorGray};
  }

  &.primary {
    background-color: ${colorPrimary};
  }

  &.secondary {
    background-color: ${colorSecondaryYellow};
  }

  &.success {
    background-color: ${colorSuccess};
  }

  &.danger {
    background-color: ${colorDanger};
  }

  &.tertiary {
    background-color: ${colorTertiary};
  }

  &.white {
    background-color: ${colorClear};

    .icon {
      color: ${colorPrimary};
    }

    .primary {
      color: ${colorPrimary};
    }

    .secondary {
      color: ${colorSecondaryYellow};
    }

    .success {
      color: ${colorSuccess};
    }

    .danger {
      color: ${colorDanger};
    }

    .tertiary {
      color: ${colorTertiary};
    }
  }
`;
