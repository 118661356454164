// @flow

import { API_PRODUCER_URL, API_URL } from 'config/constants';
import { request } from 'shared/utils/client';

// swine medication
export async function getHealthProblems() {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetHealthProblems`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getMaterialTypes() {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetMaterialTypes`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getLaboratory() {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetLaboratory`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getMedications(flockID) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetMedications?flockId=${flockID}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getMedicationDetails(medicationId) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetMedicationDetails?medicationId=${medicationId}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getLatestResume(flockID) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetLatestResume?flockId=${flockID}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function saveMedications(payload) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/SaveMedication`,
    {
      method: 'PUT',
      payload,
    },
  );
  return response;
}

export async function removeMedication(id) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/RemoveMedication?id=${id}`,
    {
      method: 'DELETE',
    },
  );
  return response;
}

export async function materialTypeFavorite(id, payload) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/MaterialTypeFavorite/${id}`,
    {
      method: 'PUT',
      payload,
    },
  );
  return response;
}

export async function getFlockAllInformation(flockID) {
  const response = await request(
    `${API_URL}/Flock/GetFlockAllInformation`,
    {
      method: 'GET',
      payload: {
        flockID,
      },
    },
  );

  return response;
}

export async function getResumeList(flockID) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetResumeList?flockId=${flockID}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

// mortality

export async function getSwineMortalityList(id) {
  const response = await request(
    `${API_PRODUCER_URL}/SwineDeceasedDiscarded/GetSwineMortality?flockId=${id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}
