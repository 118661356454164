import React, { useState, useEffect } from 'react';
import * as routes from 'config/routes';
import Top from 'components/Top';
import Breadcrumb from 'components/Breadcrumb';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
// import NoContentTab from 'components/NoContentTab';
import { TopMenuStyled } from 'containers/NextBRF/NextFlock/NextRecord/NextRecord.styled';
import { OrientationList } from 'containers/NextBRF/Orientation';
import NextHouseList from './NextHouseList';
import { getFalResume } from './service';
import t from 'components/i18n';

export default function NextRecord({
  user,
  history,
  match: { params },
  responsive: { isMobile, isTablet, isGtMobile },
}) {
  const [data, setData] = useState([]);
  const [active, setActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await getFalResume(params.id);
      setData(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setActive(0);
    fetchData();
  }, []);

  const Tabs = [
    { label: t('next.flock.record.tabs.fal'), key: 0, url: '', visible: true },
    {
      label: t('next.flock.record.tabs.orientations'),
      key: 1,
      url: '',
      visible: false,
      // visible: user.allowRoutes
      //   ? !!user.allowRoutes.findIndex(item => item.id === '17' > -1)
      //   : true,
    },
  ];

  const backRoute = user && user.type === 'E' ? routes.VENDORS : routes.VENDORS_TABLE;

  return (
    <>
      {data.length > 0 ? (
        <Breadcrumb
          history={history}
          backTo={() =>
            history.push(
              `${routes.NEXT_FLOCK_DETAILS}/${data[0].flockId}`,
            )
          }
          path={[
            t("next.flock.record.bc.growers"),
            data[0].vendorName,
            t("next.flock.record.bc.flock", { value: data[0].flock }),
            t('next.flock.record.bc.fal'),
          ]}
          links={[
            backRoute,
            `${routes.NEXT_FLOCK_LIST}/${data[0].vendorId}/new`,
            `${routes.NEXT_FLOCK_DETAILS}/${data[0].flockId}`,
          ]}
        />
      ) : (
        <Breadcrumb
          history={history}
          backTo={() => history.push(backRoute)}
          path={[
            t('next.flock.record.bc.growers'),
            t('next.flock.record.bc.fal')
          ]}
          links={[backRoute]}
        />
      )}
      <Top>
        <div className="top-content">
          <h2 className="top-content-heading">{t('next.flock.record.fal.title')}</h2>
        </div>
      </Top>
      <TopMenuStyled>
        <Header>
          {Tabs.map(
            item =>
              item.visible && (
                <TabHeaderHolder
                  key={item.key}
                  onClick={() => setActive(item.key)}
                  className={active === item.key ? 'active' : ''}
                >
                  <div className="label">{item.label}</div>
                  <div className="bar" />
                </TabHeaderHolder>
              ),
          )}
        </Header>

        {active === 0 && (
          <NextHouseList
            history={history}
            data={data}
            isLoading={isLoading}
            isMobile={isMobile}
            isTablet={isTablet}
            isGtMobile={isGtMobile}
          />
        )}

        {active === 1 && (
          <OrientationList history={history} flockId={params.id} />
          // <NoContentTab icon={'icon-clock'} hideButton>
          //   <p>
          //     {t('global.no-content')}
          //   </p>
          // </NoContentTab>
        )}
      </TopMenuStyled>
    </>
  );
}
