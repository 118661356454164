import React from 'react';
import { DropdownField } from 'components/Forms/Fields';
import { Close, RoundedItem } from './Items.styled';
import t from 'components/i18n';

export default function MultiSelect(props = {}) {
  const data = props.values[props.field] || [];

  function removeItem(item) {
    props.addItem(
      props.field,
      data.filter(
        e => e[props.removeItemBy] !== item[props.removeItemBy],
      ),
      item,
    );
  }

  function onSelectItem(item) {
    if (item[props.defaultItem]) {
      const filtered = props.options.filter(
        e => e[props.defaultItem] === false,
      );

      return props.addItem(props.field, filtered);
    }

    if (
      data &&
      data.find(e => item[props.optionLabel] === e[props.optionLabel])
    ) {
      return removeItem(item);
    }

    return props.addItem(props.field, [...data, item], item);
  }

  return (
    <>
      <DropdownField
        {...props}
        onSelect={item => onSelectItem(item)}
      />
      {data && data.length > 0 && (
        <div className="selected-options">
          {data.map((item, index) => {
            return (
              !item[props.defaultItem] && (
                <RoundedItem key={index}>
                  <p>{t(item[props.optionLabel])}</p>
                  <Close onClick={() => removeItem(item)}>
                    <span className="icon icon-close-circle" />
                  </Close>
                </RoundedItem>
              )
            );
          })}
        </div>
      )}
    </>
  );
}
