import React from 'react';
import KeyValueTable from 'components/KeyValueTable';
import {
  GroupedDetailsHeader,
  GrayBackGround,
} from './GroupedDetails.styled';

export default function GroupedDetails({ title, values }) {
  return (
    <>
      <GroupedDetailsHeader>
        <h5>{title}</h5>
      </GroupedDetailsHeader>
      <GrayBackGround>
        <KeyValueTable
          values={values}
          style={{ marginTop: 0, padding: 0 }}
        />
      </GrayBackGround>
    </>
  );
}
