const getParameterByName = (search, url = window.location.href) => {
  const propName = search.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp('[?&]' + propName + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  if (!results) {
    return null
  };

  if (!results[2]) {
    return ''
  };

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export { getParameterByName };
