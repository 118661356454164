// @flow

import React, { useState, useMemo, useCallback } from 'react';
import * as yup from 'yup';
import { store } from 'store';
import { setUser } from 'components/User/actions';
import { Formik } from 'formik';
import { Button } from '@zendeskgarden/react-buttons';
import { PROFILE } from 'config/routes';
import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';
import Container from 'components/Container';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import Modal from 'components/Forms/FormModal/Modal';
import { Link } from 'react-router-dom';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import DeactivateAccountForm from 'containers/AgroBRF/Forms/DeactivateAccountForm';
import t from 'components/i18n';
import { authenticate, deactivateUser } from '../../service';
import {
  ProfileStyled,
  Separator,
} from '../../ProfileAndSettings.styled';

const validateSchema = props =>
  yup.object().shape({
    password: yup
      .string()
      .required(t('global.status.required-field'))
      .min(6, t('global.validation.min-character-length', { length: 6 })),
  });

export default function DeactivateAccount({
  match: { params },
  history,
  setEditUser,
  setSelected,
  isValidating,
  user,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [stage, setStage] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const authenticateUser = useCallback(async (cpf, password) => {
    try {
      const response = await authenticate({
        documentNumber: cpf,
        password: password,
      });

      if (response.success) {
        store.dispatch(
          setUser({ ...response.data, justDeactivated: true }),
        );
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const modalErrorMessage = useMemo(
    () => t('global.validation.wrong-password'),
    [],
  );

  const initialValues = useMemo(
    () => ({
      id: user.id,
      password: '',
    }),
    [user.id],
  );

  function submitForm(values, { setSubmitting }) {
    const formData = {
      id: values.id,
      password: values.password,
    };

    async function saveData(val) {
      setSubmitting(true);
      try {
        const response = await deactivateUser(val);
        if (response.success) {
          setSuccess(response.success);
          authenticateUser(user.cpf, val.password);
        } else {
          setSuccess(false);
          setErrorMessage(response.message);
          throw Error(response);
        }
      } catch (error) {
        console.error(error);
      }
      setSubmitting(false);
    }
    saveData(formData);
  }

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() =>
          history.push(`${PROFILE}/account-settings/account-data`)
        }
        links={[
          `${PROFILE}/account-settings`,
          `${PROFILE}/account-settings`,
          `${PROFILE}/account-settings/account-data`,
        ]}
        path={[
          t('menu.profile.title'),
          t('menu.profile.sectionAccountConfig.Title'),
          t('menu.profile.accountData.title'),
          t('menu.profile.accountData.deactivateAccount.title'),
        ]}
      />

      <Top>
        <h2>
          {t('menu.profile.accountData.deactivateAccount.title')}
        </h2>
      </Top>

      <ProfileStyled>
        <Container className="container" hideDetail>
          {stage === 1 && (
            <>
              <p style={{ marginTop: 0 }}>
                {t(
                  'menu.profile.accountData.deactivateAccount.description',
                )}
              </p>
              {/* <Link to={`${PROFILE}/account-settings`}>
                Dados de acesso
              </Link> */}

              <Separator />

              {/* \ Botões */}
              <BtnGroup>
                <Button
                  key="sendtoConfirm"
                  onClick={() => setStage(2)}
                >
                  {t(
                    'menu.profile.accountData.deactivateAccount.buttonPrimary',
                  )}
                </Button>
                <Button
                  key="clean"
                  onClick={() =>
                    history.push(
                      `${PROFILE}/account-settings/account-data`,
                    )
                  }
                  className="white"
                >
                  {t(
                    'menu.profile.accountData.deactivateAccount.buttonSecondary',
                  )}
                </Button>
              </BtnGroup>
              {/* \ Botões */}
            </>
          )}

          {stage === 2 && (
            <Formik
              initialValues={initialValues}
              validate={validate(validateSchema)}
              onSubmit={submitForm}
              render={formikProps => (
                <DeactivateAccountForm
                  {...formikProps}
                  isValidating={isValidating}
                  message={errorMessage}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  success={success}
                  history={history}
                  {...props}
                />
              )}
            />
          )}
        </Container>
      </ProfileStyled>

      {/* On submit modal */}
      <Modal
        noButtons
        opened={errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          history.push(`${PROFILE}/account-settings/account-data`);
        }}
      >
        <div className="modal-title">
          <span className="icon icon-exclamation-triangle" />
          <h4>{t('deactivateAccount-error.title')}</h4>
        </div>

        <p>{errorMessage || modalErrorMessage}</p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
