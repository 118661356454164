import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import Box from 'components/Container';
import Card from 'components/Card';
import DateAndTime from 'components/DateAndTime';
import NoContentTab from 'components/NoContentTab';
import LoadingHolder from 'components/LoadingHolder';
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import MedicationDetails from './MedicationDetails';
import { getMedicationDetails } from '../service';
import t from 'components/i18n';

export default function MedicationList({
  arrayContent,
  isLoading,
  setIsLoading,
  title,
}) {
  const [medicationId, setMedicationId] = useState(null);
  const [medicationDetails, setMedicationDetails] = useState(null);

  useEffect(() => {
    async function fetchMedicationDetails(id) {
      try {
        const response = await getMedicationDetails(id);
        setMedicationDetails(response);
      } catch (error) {
        console.error(error);
      }
    }

    if (medicationId) {
      fetchMedicationDetails(medicationId);
    } else {
      setMedicationDetails(null);
    }
  }, [medicationId, setIsLoading]);

  useEffect(() => {
    if (medicationDetails === null) {
      setMedicationId(null);
    }
  }, [medicationDetails]);

  const getFlockContent = useMemo(() => {
    if (!medicationDetails) {
      return (
        <div className="flock-details-content">
          {!arrayContent.length && (
            <NoContentTab icon="icon-medkit">
              <p>
              {t('flocks.view.next.empty.title')} <br />{' '}
                <span className="lighter">
                {t('global.empty.list')}
                </span>
              </p>
            </NoContentTab>
          )}

          {arrayContent.length > 0 &&
            arrayContent.map((medication, index) => {
              return (
                <Card key={medication.id}>
                  <DateAndTime>
                    {moment(medication.startedDate).format(
                      'DD ̸̸  MM ̸̸  YYYY • HH:mm',
                    )}
                  </DateAndTime>

                  <p className="title">{medication.name}</p>

                  <p className="description">
                    {medication.quantity} {medication.measurementUnit}
                  </p>
                  <div className="flex" style={{ marginTop: 8 }}>
                    <p className="smaller">{medication.id}</p>
                    <p className="smaller">
                      <a
                        className="link"
                        onClick={() => setMedicationId(medication.id)}
                      >
                        {t('flocks.newFal.medications.card.button')}
                      </a>
                    </p>
                  </div>
                </Card>
              );
            })}
        </div>
      );
    }

    return (
      <MedicationDetails
        medicationDetails={medicationDetails}
        setMedicationDetails={setMedicationDetails}
      />
    );
  }, [arrayContent, medicationDetails]);

  return (
    <div className="flock-details ">
      <Box slim>
        <div className="flock-details-container animated fadeIn">
          <header className="flock-details-header">
            <h2 className="flock-details-heading">
              {medicationDetails ? t('flocks.newFal.medications.details.title') : title}
            </h2>
          </header>
          <Separator />

          <LoadingHolder isLoading={isLoading} />

          {!isLoading && getFlockContent}
        </div>
      </Box>
    </div>
  );
}
