import React, { useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import uuid from 'uuid';
import { NEXT_FLOCK_DETAILS } from 'config/routes';
import {
  colorInfo,
  colorDanger,
} from 'components/GlobalStyles/variables';
import { List, ListItem } from 'components/List';
import t from 'components/i18n';
import IconPlaceholder from 'components/IconPlaceholder';
import Icon from 'components/Icon';
import BadgePill from 'components/BadgePill';
import NoContentTab from 'components/NoContentTab';
import { displayMessageText } from 'shared/utils/Helpers';
import NextFlockListMenu from './NextFlockListMenu';
import {
  StyledNextFlockList,
  ItemRow,
  Container,
} from './StyledNextFlock.styled';

import { getIcon, getList, getStatus, formatNumber } from './utils';

export default function NextFlockList({
  history,
  user,
  data,
  isGtMobile,
  isMobile,
  isTablet,
}) {
  const [selectedKey, setSelectedKey] = useState('opened');

  const content = useMemo(() => {
    return getList(data).filter(e => e.key === selectedKey)[0].list;
  }, [data, selectedKey]);

  const displayIconBgColor = useCallback(
    item => {
      if (
        user.animalType === 'swine' ||
        user.animalType === 'turkey'
      ) {
        return selectedKey === 'opened'
          ? 'colorPrimary'
          : 'colorGray';
      }

      return selectedKey === 'opened'
        ? getStatus(item.flockStatusEnum).color
        : 'colorGray';
    },
    [selectedKey, user.animalType],
  );

  return (
    <StyledNextFlockList>
      <div className="secondary-wrapper">
        <NextFlockListMenu
          selectedKey={selectedKey}
          setSelectedKey={setSelectedKey}
          isMobile={isMobile}
          isTablet={isTablet}
          isGtMobile={isGtMobile}
        />
      </div>

      {Object.keys(data).length > 0 && (
        <Container style={{ marginTop: 0 }}>
          <div className="container-detail" />
          <div className="container-title">
            <h4>
              {selectedKey === 'opened'
                ? t('next.flock.flockList.open')
                : t('next.flock.flockList.closed')}
            </h4>
          </div>
          <List>
            {!content.length > 0 && (
              <NoContentTab icon="icon-flock" hideButton>
                <p>{t('global.no-content')}</p>
              </NoContentTab>
            )}
            {content.length > 0 &&
              content.map(item => {
                return (
                  <ListItem
                    role="button"
                    key={`feed-item-${uuid()}`}
                    className="animated fadeIn"
                    onClick={() =>
                      history.push(`${NEXT_FLOCK_DETAILS}/${item.id}`)
                    }
                    style={{ padding: '16px 0' }}
                    noMargin
                  >
                    <IconPlaceholder
                      className="top icon-holder"
                      bgColor={displayIconBgColor(item)}
                    >
                      <Icon
                        className="icon"
                        name={getIcon(item.type)}
                      />
                    </IconPlaceholder>

                    <div className="list-item-content">
                      <ItemRow>
                        <h2 className="list-item-heading">
                          {t('next.flock.flockList.header.flock', {
                            value: item.flock,
                          })}
                        </h2>

                        {user.animalType === 'broiler' && (
                          <div className="status">
                            {selectedKey === 'opened' && (
                              <BadgePill
                                bgColor={
                                  getStatus(item.flockStatusEnum)
                                    .color
                                }
                              >
                                {
                                  getStatus(item.flockStatusEnum)
                                    .label
                                }
                              </BadgePill>
                            )}

                            {selectedKey === 'closed' && (
                              <BadgePill bgColor="colorGray">
                                {t(
                                  'next.flock.flockList.content.badge.closed',
                                )}
                              </BadgePill>
                            )}
                          </div>
                        )}
                      </ItemRow>

                      <p className="list-subheading">
                        {t('next.flock.flockList.content.houses')}
                        {item.houseCodes.length > 0 &&
                          item.houseCodes.map((e, idx) => (
                            <>
                              <span>{e}</span>
                              {idx !== item.houseCodes.length - 1 &&
                                ', '}
                            </>
                          ))}
                      </p>

                      <div className="list-item-description justify small">
                        {selectedKey === 'opened' &&
                          item.hasPlacementReal && (
                            <div>
                              <p>
                                {t(
                                  'next.flock.flockList.content.placementDate',
                                  {
                                    value: moment(
                                      item.placementDate,
                                    ).format('DD ̸̸  MM ̸̸  YYYY'),
                                  },
                                )}
                              </p>
                              <p>
                                {t(
                                  'next.flock.flockList.content.flockAge',
                                  { value: item.flockAge },
                                )}
                              </p>
                            </div>
                          )}
                        {selectedKey === 'opened' &&
                          !item.hasPlacementReal && (
                            <p>
                              {t(
                                'next.flock.flockList.content.placementDateSchedule',
                              )}
                              {moment(item.placementDate).format('DD ̸̸  MM ̸̸  YYYY')}
                            </p>
                          )}
                        {selectedKey === 'closed' && (
                          <p>
                            {t(
                              'next.flock.flockList.content.slaughterDate',
                              {
                                value:
                                  Boolean(item.slaughterDate)
                                    ? moment(
                                        item.slaughterDate,
                                      ).format('DD ̸̸  MM ̸̸  YYYY')
                                    : t('global.undefined')
                              },
                            )}
                          </p>
                        )}

                        <p>
                          {t(
                            'next.flock.flockList.content.slaughterQuantity',
                            {
                              value: formatNumber(
                                item.placementQuantity,
                              ),
                            },
                          )}
                        </p>
                      </div>

                      {item.swabDates && (
                        <div className="list-item-description justify small">
                          <p>
                            {t(
                              'next.flock.flockList.content.swabDates',
                              {
                                value: `
                                  ${
                                    moment(item.swabDatesFrom).format('DD ̸̸  MM ̸̸  YYYY')
                                  } - 
                                  ${
                                    moment(item.swabDatesTo).format('DD ̸̸  MM ̸̸  YYYY')
                                  }
                                  `
                              }
                            )}
                          </p>
                        </div>
                      )}

                      {item.swabAlert &&
                        displayMessageText(
                          'icon-clock',
                          t('next.flock.flockList.alert.swabAlert'),
                          colorInfo,
                        )}

                      {item.swabDelayedAlert &&
                        displayMessageText(
                          'icon-clock',
                          t(
                            'next.flock.flockList.alert.swabDelayedAlert',
                          ),
                          colorInfo,
                        )}

                      {item.mortalityAlert &&
                        displayMessageText(
                          'icon-exclamation-circle',
                          t(
                            'next.flock.flockList.alert.mortalityAlert',
                          ),
                          colorDanger,
                        )}
                    </div>
                  </ListItem>
                );
              })}
          </List>
        </Container>
      )}
    </StyledNextFlockList>
  );
}
