import styled from 'styled-components';
import {
  colorGrayDarkest,
  colorClear,
} from 'components/GlobalStyles/variables';

export const Container = styled.div`
  position: fixed;
  display: flex;
  width: 100vw;
  bottom: 0;
  background-color: ${colorGrayDarkest};
  height: 56px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 12px 12px 0px 0px;

  .navigator-icon-holder {
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;

    .navigator-icon-label {
      /* font-size: 0; */
      height: 0;
      overflow: hidden;
    }

    &.active {
      margin-top: -16px;

      .navigator-icon-label {
        font-size: 13px;
        overflow: inherit;
      }

      .navigator-icon {
        opacity: 1;
      }
    }
  }

  .navigator-icon {
    color: ${colorClear};
    opacity: 0.56;
    font-size: 24px;

    transition: all 0.2s ease;
  }

  .navigator-icon-label {
    margin-top: 3px;
    color: ${colorClear};
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.75px;
    transition: all 0.2s ease;
    text-align: center;
  }
`;
