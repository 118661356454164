import styled from 'styled-components';
import RoundContainer from 'components/RoundContainer';
import {
  DEFAULT_SIZE,
  fontFamilyTextBold,
  colorClear,
  colorGrayLight,
  colorGrayDarker,
  colorGrayDarkest,
} from 'components/GlobalStyles/variables';

export const Wrapper = styled.div`
  display: flex;
  padding: 20px;
`;

export const CardWrapperStyled = styled.section`
  position: relative;
  width: 100%;

  & > ${RoundContainer} {
    background-color: ${colorClear};
    margin: 0 0 16px 0;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    margin-bottom: ${DEFAULT_SIZE}px;

    li {
      color: ${colorGrayDarker};
      font-size: 14px;
      margin: 0 0 ${DEFAULT_SIZE}px 0;
      display: flex;
      justify-content: space-between;

      span {
        &.right {
          font-size: 12px;
          color: ${colorGrayLight};
        }
      }
    }
  }
`;

export const Title = styled.h5`
  color: ${colorGrayDarkest};
  font-family: ${fontFamilyTextBold};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  ${CardWrapperStyled} & {
    margin: 0 0 ${DEFAULT_SIZE * 2}px;
  }
`;
