import React, { useState, useEffect, useCallback } from 'react';
import { PROFILE } from 'config/routes';
import { Button } from '@zendeskgarden/react-buttons';
import {
  getInvitedUserList,
  getVendors,
  deleteUser,
} from 'containers/AgroBRF/Collaborator/service';
import { List, ListItem } from 'components/List';
import Breadcrumb from 'components/Breadcrumb';
import CircleAndTitle from 'components/CircleAndTitle';
import NoContentTab from 'components/NoContentTab';
import Toaster from 'components/Toaster';
import LoadingHolder from 'components/LoadingHolder';
import t from 'components/i18n';
import CollaboratorList from './CollaboratorList';
import EditCollaborator from './EditCollaborator';
import {
  CollaboratorStyled,
  Container,
  Subheading,
} from './Collaborator.styled';
import { profileKeyMapper } from 'utils';

export default function Collaborator(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [editUser, setEditUser] = useState(false);
  const [selected, setSelected] = useState({});
  const [vendors, setVendors] = useState([]);
  const [removedUser, setRemovedUser] = useState(false);

  const getInvitedList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getInvitedUserList();
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, []);

  const getAvailableVendors = useCallback(async () => {
    try {
      const response = await getVendors();
      setVendors(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const deleteCollaborator = useCallback(
    async id => {
      try {
        const response = await deleteUser(id);
        if (response.success) {
          setRemovedUser(true);
          props.history.push(
            `${PROFILE}/account-settings/collaborators`,
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    [props.history],
  );

  useEffect(() => {
    getInvitedList();
    getAvailableVendors();
  }, [getAvailableVendors, getInvitedList]);

  useEffect(() => {
    if (props.match.params.type === 'edit') {
      setEditUser(true);
    }

    if (props.match.params.id) {
      if (props.match.params.id === 'new') {
        setSelected({});
      } else {
        const selectedUser = data.find(
          item => item.id === Number(props.match.params.id),
        );

        if (selectedUser) {
          setSelected(selectedUser);
        } else {
          setSelected({});
        }
      }
    }
  }, [data, props.match.params, vendors]);

  useEffect(() => {
    if (!editUser || removedUser) {
      getInvitedList();
    }
  }, [editUser, getInvitedList, removedUser]);

  return (
    <>
      {editUser ? (
        <EditCollaborator
          user={selected}
          setSelected={setSelected}
          setEditUser={setEditUser}
          history={props.history}
          availableVendors={vendors}
          setRemovedUser={setRemovedUser}
        />
      ) : (
        <CollaboratorStyled>
          {Object.keys(selected).length > 0 && (
            <>
              <Breadcrumb
                history={props.history}
                links={[`${PROFILE}/account-settings/collaborators`]}
                path={[t('menu.employees.employeesList.title'), t('menu.employees.employeesList.employeeDetail.title')]}
              />
              <List className="menu-group">
                <ListItem className="animated fadeIn">
                  <CircleAndTitle
                    name={selected.name}
                    initials={selected.initialsName}
                    description={profileKeyMapper(selected.profile)}
                  />
                </ListItem>
              </List>
            </>
          )}

          <Container style={{ padding: '0' }}>
            <LoadingHolder isLoading={isLoading} />

            {!isLoading &&
              (!data.length ? (
                <div style={{ padding: '0 16px' }}>
                  <Subheading style={{ paddingTop: '20px' }}>
                    {t(
                      'menu.employees.employeesList.employeeDetail.singleLine.accessPermission.label',
                    )}
                  </Subheading>
                  <p>{t('menu.employees.description')} </p>
                  <NoContentTab
                    icon="icon-clipboard-user"
                    onButtonClick={() => setEditUser(true)}
                    hideButton={props.user.profile === 'Assistente'}
                    buttonLabel={t(
                      'menu.employees.inviteEmployee.title',
                    )}
                  >
                    <p>
                      {t('menu.employees.message.title')}
                      <br />{' '}
                      <span className="lighter">
                        {t('menu.employees.message.subtitle')}
                      </span>
                    </p>
                  </NoContentTab>
                </div>
              ) : (
                <>
                  <CollaboratorList
                    data={data}
                    selected={selected}
                    setSelected={setSelected}
                    setEditUser={setEditUser}
                    setRemovedUser={setRemovedUser}
                    removedUser={removedUser}
                    deleteCollaborator={deleteCollaborator}
                    {...props}
                  />
                  <Toaster
                    className="toaster__notification"
                    isVisible={removedUser}
                    color="success"
                    duration={3}
                    delay={0}
                  >
                    <span className="icon icon-check" />
                    {t('menu.employees.message.delete.success')}
                  </Toaster>
                </>
              ))}
          </Container>
        </CollaboratorStyled>
      )}
    </>
  );
}
