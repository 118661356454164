import React, { useState, useCallback } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import ActionPlanForm from 'containers/NextBRF/Forms/ActionPlanForm';
import validate from 'components/Forms/Fields/validate';
import Modal from 'components/Forms/FormModal/Modal';
import { uploadImage, deleteImage } from './service';
import t from 'components/i18n';

// validate

const validateSchema = () => {
  return yup.object().shape({
    nonCompliance: yup.string().required(t('global.status.required-field')),
    cause: yup.string().required(t('global.status.required-field')),
    action: yup.string().required(t('global.status.required-field')),
    how: yup.string().required(t('global.status.required-field')),
    responsible: yup.string().required(t('global.status.required-field')),
    // vendor: yup.string().required(t('global.status.required-field')),
    expectedDate: yup.string().required(t('global.status.required-field')),
    // visitReason: yup.string().required(t('global.status.required-field')),
  });
};

export default function ActionPlan({
  user,
  setQuestionList,
  selectedItem,
  selectedQuestions,
  questionsToSend,
  setQuestionsToSend,
  vendorCode,
  setDisplayPlan,
}) {
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);

  const initialValues = {
    id: null,
    nonCompliance: '',
    cause: '',
    action: '',
    how: '',
    vendor: null,
    expectedDate: '',
    responsible: '',
    attachmentsUrls: [],
  };

  const selectedAnswer = useCallback(() => {
    const found = questionsToSend.find(
      item =>
        item.logbookChecklistQuestionId ===
        (selectedQuestions && selectedQuestions.id),
    );

    return found;
  }, [questionsToSend, selectedQuestions]);
  
  const handleFile = async (file, setFieldValue) => {
    setIsUploading(true);
    try {
      const response = await uploadImage(file);
      if (response) {
        setFiles(currentValue => {
          const files = [...currentValue, response];

          setFieldValue('attachmentsUrls', files);

          return files;
        });
      }
    } catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  };

  const removeFile = async (file, setFieldValue) => {
    setIsUploading(true);
    try {
      const response = await deleteImage(file);
      if (response) {
        setFiles(currentValue => {
          const files = currentValue.filter(e => e !== file);

          setFieldValue('attachmentsUrls', files);

          return files;
        });
      }
    } catch (error) {
      console.error(error);
    }
    setIsUploading(false);
  };

  // attachmentsUrls
  function submitForm(values, { setSubmitting }) {
    const formData = {
      id: values.id || 0,
      nonCompliance: values.nonCompliance,
      cause: values.cause,
      action: values.action,
      how: values.how,
      vendor: vendorCode,
      expectedDate: values.expectedDate,
      attachmentsUrls: values.attachmentsUrls,
      visitReason: t('next.visitCircuit.actionPlan.visitTitle'),
      wasExecuted: null,
    };

    async function saveData(val) {
      setSubmitting(true);

      try {
        setQuestionList(oldList =>
          oldList.map(item => {
            if (item.id !== selectedQuestions.id) {
              return item;
            }

            return {
              ...item,
              logBookChecklistSupervisorAnswers: [
                {
                  logBookChecklistAnswerId: selectedAnswer.logBookChecklistAnswerId,
                  answer: selectedAnswer.answer,
                  logBookChecklistQuestionId: selectedAnswer.logBookChecklistQuestionId,
                  logBookChecklistId: selectedItem.id,
                  logBookChecklistActionPlan: val,
                },
              ],
            };
          }),
        );

        setQuestionsToSend(previousList =>
          previousList.map(item => {
            if (
              item.logbookChecklistQuestionId !== selectedQuestions.id
            ) {
              return item;
            }

            return { ...questionsToSend[0], actionPlan: val };
          }),
        );

        setSuccess(true);
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    }

    saveData(formData);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate(validateSchema)}
        onSubmit={submitForm}
        render={formikProps => {
          return (
            <ActionPlanForm
              {...formikProps}
              handleFile={e => handleFile(e, formikProps.setFieldValue)}
              removeFile={e => removeFile(e, formikProps.setFieldValue)}
              isUploading={isUploading}
              files={files}
              user={user}
              setDisplayPlan={setDisplayPlan}
            />
          );
        }}
      />

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
          setDisplayPlan(false);
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>
          {success
            ? t('global.validation.record-changes-saved')
            : t('global.validation.record-error')}
        </p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
