/**
 * Given a list of objects, removes duplicates comparing only selected properties
 */
const removeDuplicatesBasedOnProperties = (
  arrayWithDuplicates = [],
  properties = [],
) =>
  arrayWithDuplicates.reduce((acum, element) => {
    if (
      !acum.find(alreadyInside =>
        properties.every(
          prop => alreadyInside[prop] === element[prop],
        ),
      )
    ) {
      acum.push(element);
    }

    return acum;
  }, []);

export default removeDuplicatesBasedOnProperties;
