import t from "components/i18n";

export function getStatusColor(status, color) {
  switch (status.toLowerCase()) {
    case 'realizado':
      return color;
      break;

    case 'confirmado':
      return 'colorInfo';
      break;

    case 'previsto':
      return 'colorGrayLighter';
      break;

    default:
      return 'default';
  }
}

export function getFilteredContentByDate(
  mmnt,
  array,
  dateFilter,
  date,
) {
  const start =
    dateFilter && dateFilter.startDate && mmnt(dateFilter.startDate);
  const end =
    dateFilter && dateFilter.endDate && mmnt(dateFilter.endDate);

  if (start || end) {
    return array.filter(
      m =>
        mmnt(m[date]).isBetween(start, end, 'day') ||
        mmnt(m[date]).isSame(start, 'day') ||
        mmnt(m[date]).isSame(end, 'day'),
    );
  }

  return array;
}

export function getFiltersFromSideBox(filters, activeFilters) {
  return filters.map((filterItem, index) => {
    if (activeFilters.filter(e => e.label === filterItem).length) {
      if (filterItem === t('next.operationalSchedule.scheduleFilters.slaughter.options.house')) {
        return activeFilters.filter(e => e.label === filterItem)[0]
          .value;
      }

      if (filterItem === t('next.operationalSchedule.scheduleFilters.slaughter.options.flock')) {
        return activeFilters.filter(e => e.label === filterItem)[0]
          .id;
      }
      return activeFilters.filter(e => e.label === filterItem)[0].txt;
    }

    return '';
  });
}

export const tableHeader = [
  {
    width: 20,
    title: t('next.operationalSchedule.table.header.user'),
    alignLeft: true,
  },
  {
    width: 15,
    title: t('next.operationalSchedule.table.header.anymalType'),
  },
  {
    width: 15,
    title: t('next.operationalSchedule.table.header.flock'),
  },
  {
    width: 20,
    title: t('next.operationalSchedule.table.header.date'),
  },
  {
    width: 10,
    title: t('next.operationalSchedule.table.header.quantity'),
  },
  {
    width: 10,
    title: t('next.operationalSchedule.table.header.status'),
  },
  {
    width: 10,
    title: t('next.operationalSchedule.table.header.read'),
  },
];
