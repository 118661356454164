import t from "components/i18n";

export function getCadecIcon(type) {
  const types = {
    ataList: 'icon-file',
    addAta: 'icon-upload',
    addCadec: 'icon-plus-circle',
    cadecList: 'icon-users',
    broiler: 'icon-chicken',
    turkey: 'icon-turkey',
    swine: 'icon-pig',
  };

  return types[type];
}

export function getHeading(key) {
  const types = {
    ataList: t("cadec.heading.ataList"),
    addAta: t("cadec.heading.addAta"),
    editAta: t("cadec.heading.editAta"),
    addCadec: t("cadec.heading.addCadec"),
    editCadec: t("cadec.heading.editCadec"),
    cadecList: t("cadec.heading.cadecList"),
    details: t("cadec.heading.details"),
  };

  return types[key];
}

export function getVendorTypesMenu(key) {
  const types = {
    broiler: t("cadec.filters.animalType.broiler"),
    turkey: t("cadec.filters.animalType.turkey"),
    swine: t("cadec.filters.animalType.swine"),
  };

  return types[key];
}

export function getBoxType(key) {
  const types = {
    ataList: 'primary',
    addAta: 'primary',
    addCadec: 'primary',
    cadecList: 'primary',
  };

  return types[key];
}
