import Icon from 'components/Icon';
import React, { useState } from 'react';
import { Button } from '@zendeskgarden/react-buttons';

import t from 'components/i18n';
import Top from 'components/Top';
import Toaster from 'components/Toaster';
import Breadcrumb from 'components/Breadcrumb';
import * as routes from 'config/routes';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';

import { Separator, Title } from '../Event.styled';
import {
  Container,
  Content,
  User,
  UserName,
  InlineInfo,
} from './Visit.styled';

import { SaveRandomVisits } from '../service';
import moment from 'moment';

export default function Confirmation(props) {
  const { data, history, user: { animalType } } = props;

  const [response, setResponse] = useState(null);

  const sendData = () => {
    const payload = {
      reason: data.reason.name,
      idReason: data.reason.id,
      visitDate: data.date,
      epidemiologicalCore: parseInt(data.house.epidemiologicalCore),
      houses: data.house.houses.map(house => house.houseCode),
      vendor: data.grower.vendorCode,
      type: "visit",
      ...(
        data['reason'].name === "technicalVisit.anotherReason" && {
          anotherReason: data['otherReason'],
        }
      )
    };

    SaveRandomVisits(payload)
      .then(response => {
        setResponse(response);
        setTimeout(() => {
          history.push(`${ routes.LOGBOOK }?event=new-event&createdAt=${moment()}`);
        }, 2000);
      });
  };

  return (
    <>
      <Breadcrumb
        history={history}
        path={[t('bc.logbook'), t('bc.create-new-event')]}
        backTo={() => history.goBack()}
      />
      <Top>
        <h2>{t('create-new-event.title')}</h2>
      </Top>
      <Container>
        <Title>{t('create-new-event.visit.check-data.title')}</Title>

        <Separator />

        <User>
          <Icon name="icon icon-user" width={20} />
          <UserName>{data.grower.label}</UserName>
        </User>

        <Content>
          <Separator />

          {animalType !== 'swine' && (
            <>
              <InlineInfo>
                <p>{t("create-new-event.visit.form.label.ue")}</p>
                <p>{data.house.label}</p>
              </InlineInfo>

              <Separator />
            </>
          )}

          <InlineInfo>
            <p>{t("create-new-event.visit.form.label.date")}</p>
            <p>{moment(data.date).format('DD ̸̸  MM ̸̸  YYYY')}</p>
          </InlineInfo>

          <Separator />

          <InlineInfo>
            <p>{t('create-new-event.visit.form.label.reason')}</p>
            <p>{
              data['reason'].name === "technicalVisit.anotherReason"
                ? data.otherReason
                : data.reason.label
            }</p>
          </InlineInfo>
        </Content>

        <Separator />

        <BtnGroup className="event__btn-group">
          <Button
            key="submitForm"
            className="tertiary"
            onClick={() => sendData()}
            disabled={response && response.success}
          >
            {t('global.button.save')}
          </Button>
          <Button
            key="clean"
            className="white"
            onClick={() => history.goBack()}
          >
            {t('global.button.back')}
          </Button>
        </BtnGroup>

        {response && (
          <Toaster
            className="toaster__notification"
            isVisible={true}
            color={response.success ? 'success' : 'danger'}
            duration={3}
            delay={0}
            title={
              response.success
                ? t('create-new-event.visit.success.title')
                : t('create-new-event.visit.error.title')
            }
          >
            <span
              className={`icon icon-exclamation-circle ${
                response.success
                  ? 'icon-check'
                  : 'icon-exclamation-circle'
              }`}
            />
            {response.success
              ? t('create-new-event.visit.success.description')
              : t('create-new-event.visit.error.description')}
          </Toaster>
        )}
      </Container>
    </>
  );
}
