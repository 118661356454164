import React from 'react';
import SupervisorFlockList from './SupervisorFlockList';
import ProducerFlockList from './ProducerFlockList';

export default function FlockList(props) {
  return props.user.type === 'E' ? ( // usuário extensionista
    <SupervisorFlockList {...props} />
  ) : (
    <ProducerFlockList {...props} />
  );
}
