import React, { useState, useEffect, useContext } from 'react';
import { DateRange } from 'react-date-range';
import { ThemeContext } from 'styled-components';
import {
  Dropdown,
  Menu,
  Item,
  Trigger,
} from '@zendeskgarden/react-dropdowns';
import { pt } from 'date-fns/esm/locale';
import t from 'components/i18n';
import { FilterStyled, DateRangeWrapper } from './Filter.styled';
import moment from 'moment';

export default function Filter(props) {
  const {
    openCalendar,
    setInitialState,
    buttonLabel,
    setOpenCalendar,
    controledValue,
    controledLabel,
    placeholder,
    onSelectItemAction,
  } = props;

  const startRange = {
    startDate: moment(),
    endDate: null,
    key: 'date',
  };
  const [label, setLabel] = useState(buttonLabel);
  const [icon, setIcon] = useState('open');
  const [selectionRange, setSelectionRange] = useState(startRange);

  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    if (setInitialState) {
      setLabel(buttonLabel);
      setIcon('open');
    }
  }, [buttonLabel, setInitialState]);

  useEffect(() => {
    const { startDate, endDate } = selectionRange;
    // TODO: test if behaviour is unchanged
    if (endDate !== null && !startDate.isSame(endDate, 'day')) {
      setOpenCalendar(false);
      onSelectItemAction(selectionRange);
    }
  }, [setOpenCalendar, onSelectItemAction, selectionRange]);

  useEffect(() => {
    if (setInitialState) {
      setSelectionRange(startRange);
      setOpenCalendar(false);
    }
  }, [setOpenCalendar, setInitialState, startRange]);

  return (
    <FilterStyled
      theme={{
        bgButton: themeContext.styles.palette.colorGrayDarkest,
        text: themeContext.styles.palette.colorClear,
      }}
    >
      <Dropdown
        onSelect={() => setIcon('open')}
        placeholder={placeholder}
      >
        <Trigger>
          <button
            type="button"
            className="border-radius btn dateTrigger"
            onClick={() => {
              setIcon('close');
              if (openCalendar) {
                setOpenCalendar(false);
              }
            }}
          >
            {t(controledValue || controledLabel || label)}

            {icon === 'close' && <span className="icon icon-close" />}

            {icon === 'open' && (
              <span className="icon icon-chevron-down" />
            )}
          </button>
        </Trigger>

        <Menu placement="bottom">
          {props.list.map((option, index) => (
            <Item
              key={index}
              value={t(option.label) || placeholder}
              placeholder={t('global.filters.select-value.placeholder')}
              onClick={() => {
                setLabel(t(option.label));
                if (!props.commonMode) {
                  props.onSelectItemAction({
                    startDate: option[props.keysToCompare[0]],
                    endDate: option[props.keysToCompare[1]],
                  });
                  return;
                }
                props.onSelectItemAction({
                  label: props.controledLabel || label,
                  txt: t(option.label),
                });
              }}
            >
              {t(option.label) || placeholder}
            </Item>
          ))}
          {!props.commonMode && (
            <Item
              value="custom"
              onClick={() => setOpenCalendar(!openCalendar)}
              placeholder={placeholder}
            >
              {t('global.time.picker.filter.date.custom')}
            </Item>
          )}
        </Menu>
      </Dropdown>
      {openCalendar && (
        <DateRangeWrapper>
          <DateRange
            ranges={[selectionRange]}
            moveRangeOnFirstSelection={false}
            onChange={e => setSelectionRange(e.date)}
            dateDisplayFormat="DD ̸̸  MM ̸̸  YYYY"
            locale={pt}
            showDateDisplay={false}
          />
        </DateRangeWrapper>
      )}
    </FilterStyled>
  );
}
