import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import SideboxFilter from 'components/SideboxFilter/SideboxFilter';
import SearchBox from 'components/SearchBox';
import LoadingHolder from 'components/LoadingHolder';
import t from 'components/i18n';
import NoContentTab from 'components/NoContentTab';
import { WhiteBoxInsideHeader } from 'components/HeaderBox';
import RoundContainer from 'components/RoundContainer';
import floatMask from 'components/MaskedInput/floatMask';
import GroupedDetails from 'components/GroupedDetails';
import { getAnimalIcon } from 'shared/utils/Helpers';
import NextSlaughterItem from './NextSlaughterItem';
import History from '../History';
import { getSlaughterFilters } from '../ScheduleFilters';
import {
  getStatusColor,
  getFilteredContentByDate,
  getFiltersFromSideBox,
  getCatchData,
  sumOfItems,
} from '../utils';
import { getScheduleSlaughter } from '../service';

export default function NextSlaughterList({
  vendors,
  flockId,
  selectedVendor,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [displayGroupedList, setDisplayGroupedList] = useState(true);
  const [groupedList, setGroupedList] = useState([]);
  const [slaughterList, setSlaughterList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  const user = useSelector(state => state.user);
  const animalIcon = useMemo(() => getAnimalIcon(user.animalType), [
    user,
  ]);

  const EmptySearch = useCallback(
    () => (
      <NoContentTab icon={animalIcon} hideButton>
        <p>
          {t('global.no-content')}
        </p>
      </NoContentTab>
    ),
    [animalIcon],
  );

  const getGroupedListByHouse = useCallback(
    arr =>
      arr.map((list, houseIndex) => {
        const accomplished = list.filter(
          item => item.status === 'REALIZADO',
        );

        return {
          key: houseIndex,
          house: t("next.vendorSchedule.flock", { value: list[0].house }),
          list: [list],
          totalQuantity: sumOfItems(accomplished, 'quantity'),
          flockAverageWeight: (
            sumOfItems(accomplished, 'averageWeight', true) /
            accomplished.length
          ).toFixed(0),
        };
      }),
    [],
  );

  const fetchData = useCallback(
    async (original, ...e) => {
      setIsLoading(true);
      try {
        const response = await getScheduleSlaughter(flockId, ...e);
        setSlaughterList(response || []);

        // get all available options to filter
        if (original) {
          setOriginalData(response || []);
          setGroupedList(getGroupedListByHouse(response));
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    },
    [flockId, getGroupedListByHouse],
  );

  const fetchBySideBoxFilters = useCallback(() => {
    const filterLabels = [
      t('next.vendorSchedule.filter.status'),
      t('next.vendorSchedule.filter.feed'),
      flockId
        ? ''
        : t('next.vendorSchedule.filter.flock'),
    ];

    fetchData(
      false,
      search ? search.vendorCode : '',
      ...getFiltersFromSideBox(filterLabels, activeFilters),
    );
  }, [activeFilters, fetchData, flockId, search]);

  const getCatchDataCallBack = useCallback(
    (catchData, format) => {
      if (catchData) {
        return getCatchData(catchData, format);
      }

      return null;
    },

    [],
  );

  const getContentCallBack = useCallback(
    (item, isFirst, hasHistory, key) => {
      const additionalInfo = [[item.flock && t("next.vendorSchedule.flock", { value: item.flock })]];

      if (item.status === 'CONFIRMADO') {
        additionalInfo.push([
          item.averageWeight
            ? t('next.vendorSchedule.slaughter.averageWeight', { value: item.averageWeight })
            : '-',
          t("next.vendorSchedule.slaughter.house", { value: item.house }),
        ]);
      }

      if (item.status === 'REALIZADO') {
        additionalInfo.push(
          [
            item.weight
              ? t('next.vendorSchedule.slaughter.weight', { value: floatMask(item.weight) }) 
              : '-',
            t("next.vendorSchedule.slaughter.house", { value: item.house }),
          ],
          [
            item.averageWeight
              ? t('next.vendorSchedule.slaughter.averageWeight', { value: item.averageWeight })
              : '-',
            item.licensePlate
              ? t('next.vendorSchedule.slaughter.licensePlate', { value: item.licensePlate })
              : '-',
          ],
        );
      }

      return (
        <NextSlaughterItem
          key={key}
          isFirstItem={isFirst}
          isLastItem={hasHistory}
          icon={`icon-${item.animalType}`}
          iconColor="danger"
          date={item.date}
          status={item.status}
          statusColor={getStatusColor(item.status, 'colorDanger')}
          title={t('next.vendorSchedule.slaughterList.quantity', { value: item.quantity })}
          // subTitle={`Produto ${item.productCode}`}
          keyValueTable={getCatchDataCallBack(item.catchData, moment)}
          additionalInfo={additionalInfo}
          danger={item.catchData && !item.catchData.isRead}
          statusText={
            item.catchData && item.catchData.isRead
              ? t('next.vendorSchedule.slaughterList.readDate', { value: moment(item.catchData.readDate).format('DD ̸̸  MM ̸̸  YYYY • HH:mm') })
              : t('next.vendorSchedule.slaughterList.noRead')
          }
          statusIcon={
            item.catchData && item.catchData.isRead
              ? 'icon icon-check-double'
              : 'icon icon-exclamation-circle'
          }
        />
      );
    },
    [getCatchDataCallBack],
  );

  const getContentListCallback = useCallback(
    (content, isGrouped) =>
      content.length > 0 &&
      content.map((slaughter, i) => {
        const firstItem = slaughter[0];
        const hasHistory = slaughter.length > 1;
        const inner = hasHistory
          ? [...slaughter].filter(item => item !== firstItem)
          : [];

        return (
          <RoundContainer
            key={i}
            noMargin
            padding={isGrouped ? '8px 0 0' : '12px 16px 16px'}
            style={{
              border: isGrouped ? '0' : '',
              boxShadow: isGrouped ? 'none' : '',
            }}
          >
            {firstItem &&
              getContentCallBack(firstItem, true, hasHistory)}

            {hasHistory && (
              <History>
                {inner.map((item, index) =>
                  getContentCallBack(item, false, hasHistory, index),
                )}
              </History>
            )}
          </RoundContainer>
        );
      }),
    [getContentCallBack],
  );

  useEffect(() => {
    if (vendors.length > 0) {
      setSearch(selectedVendor || vendors[0]);
    }
    setActiveFilters([]);
    setDisplayGroupedList(true);
  }, [vendors, selectedVendor]);

  useEffect(() => {
    setActiveFilters([]);
    if (!vendors.length && !Object.keys(search).length) {
      fetchData(true, '');
    }

    if (Object.keys(search).length > 0 && !activeFilters.length) {
      fetchData(true, search.vendorCode, '');
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (activeFilters.length > 0) {
      fetchBySideBoxFilters();
      setDisplayGroupedList(false);
    } else {
      setDisplayGroupedList(true);
    }

    setSlaughterList(originalData);
    // eslint-disable-next-line
  }, [activeFilters]);

  useEffect(() => {
    if (slaughterList && dateFilter) {
      const filteredByDate = slaughterList.map((item, index) =>
        getFilteredContentByDate(moment, item, dateFilter, 'date'),
      );

      setFilteredList(
        filteredByDate.filter(item => (item.length ? item : false)),
      );
      setDisplayGroupedList(false);
    }
    // setDisplayGroupedList(false);
  }, [slaughterList, dateFilter]);

  const content = dateFilter ? filteredList : slaughterList;

  return (
    <>
      <WhiteBoxInsideHeader>
        {!flockId && (
          <SearchBox
            options={vendors}
            label="label"
            action={setSearch}
            item={search}
            notFoundMessage={t('next.vendorSchedule.no-flock')}
          />
        )}

        {Boolean(originalData && Array.isArray(originalData) && originalData.length) && (
          <SideboxFilter
            dateFilter={flockId ? dateFilter : false}
            setDateFilter={flockId ? setDateFilter : undefined}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            filters={getSlaughterFilters(originalData.flat(), flockId)}
          />
        )}
      </WhiteBoxInsideHeader>

      <LoadingHolder isLoading={isLoading} />

      <div style={{ padding: '16px 0' }}>
        {!isLoading &&
          !displayGroupedList &&
          content.length > 0 &&
          getContentListCallback(content, false)}

        {!isLoading &&
          displayGroupedList &&
          groupedList.length > 0 &&
          groupedList.map((item, i) => {
            const accomplishedFound =
              item.list
                .flat()
                .findIndex(
                  listItem => listItem.status === 'REALIZADO',
                ) > -1;

            if (accomplishedFound) {
              return (
                <RoundContainer key={i} noMargin>
                  <GroupedDetails
                    title={item.house}
                    values={[
                      {
                        label: t('next.vendorSchedule.slaughterList.totalQuantity.label'),
                        value: t('next.vendorSchedule.slaughterList.totalQuantity.value', { value: item.totalQuantity }),
                      },
                      {
                        label: t('next.vendorSchedule.slaughterList.flockAverageWeight.label'),
                        value: t("next.vendorSchedule.slaughterList.flockAverageWeight.value", { value: item.flockAverageWeight })
                      },
                    ]}
                  />
                  <div style={{ padding: '8px 20px' }}>
                    {getContentListCallback(item.list, true)}
                  </div>
                </RoundContainer>
              );
            }

            return getContentListCallback(item.list, false);
          })}

        {!isLoading && !content.length && <EmptySearch />}
      </div>
    </>
  );
}
