import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';

import { Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';

import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { List, ListItem } from 'components/List/List';

// Styles
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import t from 'components/i18n';

export default function NextMedicationConfirmation({
  values,
  isValid,
  isSubmitting,
  onSubmit,
  backToForm,
  isEditing,
  setEditMedication,
  selectedMedication,
  setSelectedMedication,
  setDisplayExcludeModal,
  isFlockClosed,
}) {
  const [listArray, setListArray] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (values) {
      setListArray([
        {
          description: values.material.description,
          icon: 'medkit',
          subItems: [
            {
              subItemLabel: t('next.nextMedicationConfirmation.data.drug-batch'),
              subItemValue: `${values.vaccineBatch}`,
            },
            {
              subItemLabel: t('next.nextMedicationConfirmation.data.administered-quantity'),
              subItemValue: `${values.quantity}${values.material
                .measurement || values.measurementUnit}`,
            },
            {
              subItemLabel: t('next.nextMedicationConfirmation.data.start-treatment'),
              subItemValue: `${moment(values.startDate).format(
                'DD ̸̸  MM ̸̸  YYYY',
              )} - ${moment(values.startTime).format('HH:mm')}`,
            },
            {
              subItemLabel: t('next.nextMedicationConfirmation.data.end-treatment'),
              subItemValue: `${moment(values.endDate).format(
                'DD ̸̸  MM ̸̸  YYYY',
              )} - ${moment(values.endTime).format('HH:mm')}`,
            },
          ],
        },

        {
          description: values.healthProblem.name,
          icon: 'disease',
          subItems: [
            {
              subItemLabel: t('next.nextMedicationConfirmation.data.disease-code'),
              subItemValue: `${values.healthProblem.id}`,
            },
          ],
        },
      ]);
    }
  }, [values]);

  const displayListItem = useCallback(
    (index, description, icon, subItems) => {
      return (
        <>
          <ListItem
            key={index}
            className="confirm-list__item animated fadeIn"
          >
            <div className="icon-wrapper">
              <span className={`icon icon-${icon}`} />
            </div>
            <div
              className="list-item-content"
              style={{ width: '100%' }}
            >
              <div className="list-item-description">
                {description}
              </div>
            </div>
          </ListItem>

          {subItems &&
            subItems.length > 0 &&
            subItems.map(item => (
              <ListItem className="confirm-list__item animated fadeIn">
                <div className="icon-wrapper">
                  <span className="icon" />
                </div>
                <div
                  className="list-item-content"
                  style={{ width: '100%' }}
                >
                  <div className="list-item-subitem list-item-description flex">
                    <p className="small">{item.subItemLabel}</p>
                    <p>{item.subItemValue}</p>
                  </div>
                </div>
              </ListItem>
            ))}
        </>
      );
    },
    [],
  );

  return (
    <>
      <h4>{t('next.nextMedicationConfirmation.title')}</h4>

      <Separator style={{ marginBottom: 16 }} />

      <List className="confirm-list" style={{ marginTop: '0' }}>
        {listArray.length > 0 &&
          listArray.map((item, index) =>
            displayListItem(
              index,
              item.description,
              item.icon,
              item.subItems,
            ),
          )}
      </List>

      <Separator />

      {/* \ Botões */}
      {isEditing && selectedMedication && (
        <Row>
          <Col sm={4}>
            {!isFlockClosed && (
              <Button
                key="exclude"
                onClick={() => setDisplayExcludeModal(true)}
                className="white"
                style={{ width: '155px' }}
              >
                {t('next.nextMedicationConfirmation.button.delete')}
              </Button>
            )}
          </Col>
          <Col sm={8}>
            <BtnGroup>
              {!isFlockClosed && (
                <Button
                  key="edit"
                  className="tertiary"
                  onClick={() => backToForm()}
                >
                  {t("global.button.edit")}
                </Button>
              )}
              <Button
                key="clean"
                onClick={() => {
                  history.go(0);
                  setSelectedMedication(null);
                  setEditMedication(false);
                }}
                className="white"
              >
                {t('global.button.back')}
              </Button>
            </BtnGroup>
          </Col>
        </Row>
      )}

      {!isEditing && (
        <BtnGroup className="button-group--bottom">
          <Button
            key="submit"
            className="tertiary"
            onClick={() => onSubmit()}
            disabled={isSubmitting || !isValid}
          >
            {t("global.button.save")}
          </Button>

          <Button
            key="edit"
            onClick={() => backToForm()}
            className="white"
          >
            {t("global.button.edit")}
          </Button>
        </BtnGroup>
      )}
      {/* \ Botões */}
    </>
  );
}
