export const MAIN = '/';
export const SIGNIN = '/signin';
export const SIGNUP = '/signup';
export const PASSWORD_RECOVERY = '/password-recovery';
export const PASSWORD_RECOVERY_NEXT = '/password-recovery-next';
export const HOME = '/home';
export const FLOCK_LIST = '/flock-list';
export const RECORD_LIST = '/record-list';
export const CADEC = '/cadec';
export const VENDOR = '/vendor';
export const INSURANCE = '/insurance';

export const VENDORS = '/vendors';
export const VENDORS_TABLE = '/vendors-table';
export const OPEN_VENDORS = '/open-vendors';

export const LIBRARY = '/library';
export const NEWS_LIST = '/news';
export const OPEN_NEWS = '/article';
export const MOBILE_OPEN_NEWS = '/mobile/news';
export const ANNOUNCEMENTS = '/announcements-list';
export const OPEN_ANNOUNCEMENT = '/announcement';
export const MOBILE_ANNOUNCEMENT = '/mobile/announcement';
export const CONTACT_US = '/contact-us';
export const FAQ = '/faq';
export const NEXTFAQ = '/contact';
export const BECOME_A_MEMBER = '/seja-um-integrado';
export const OPEN_RECORD = '/fal';
export const COLLABORATOR = '/collaborator';
export const REPORTS = '/reports';
export const NEXT_FLOCK_LIST = '/next-flock-list';
export const NEXT_FLOCK_DETAILS = '/next-flock-details';
export const NEXT_FLOCK_LABEL = '/next-flock-label';
export const NEXT_OPEN_RECORD = '/next-fal';
export const NEXT_HOUSE_LIST = '/houses';
export const NEXT_SWINE_RECORD = '/swine-record';
export const LOGBOOK = '/logbook';
export const VISIT_LIST = '/visit-list';
export const OPEN_VISIT = '/open-visit';
export const VISIT_CIRCUIT_LIST = '/visit-circuit-list';
export const VISIT_CIRCUIT = '/visit-circuit';
export const SCHEDULE = '/schedule';
export const SCHEDULE_SEARCH = '/schedule-search';
export const ORIENTATION = '/orientation';
export const NEXT_ORIENTATION = '/next-orientation';
export const NEXT_DASHBOARD = '/dashboard';
export const WEEK_SCHEDULE = '/week-schedule';
export const WEEK_SCHEDULE_SETTINGS = '/week-schedule-settings';
export const NEW_VISIT = '/new-visit';
export const USER_MANAGEMENT = '/user-management';
export const MOBILE_POLICY = '/mobile/policy';
export const MOBILE_POLICY_EXCERPT = '/mobile/policy-excerpt';
export const CHECKLIST_DETAILS = '/checklist-details';
export const CHECKLIST_HISTORY = '/checklist-history';
export const FILL_CHECKLIST = '/checklist-fill';
export const NEW_EVENT = '/event';
export const PROFILE = '/profile-and-settings';
export const EDIT_PROFILE = '/edit-profile';
export const UPDATE_PASSWORD = '/update-password';
export const EDIT_PHONENUMBER = '/edit-phonenumber';
export const ACCOUNT_PASSWORD_RECOVERY = '/account-password-recovery';
export const DEACTIVATE_ACCOUNT = '/deactivate-account';
export const GRAINS = '/grains';
export const DOCUMENTATION = '/documentation';
export const NEXT_MEDICATION = '/medication';
export const EDIT_MEDICATION = '/edit-medication';
export const INDICATORS = '/indicators';
export const INVOICE = '/invoice';
export const FINANCIALS = '/financials';
export const FINANCIALS_DETAILS = '/invoice-details';
export const BANCO_DO_BRASIL = '/banco-do-brasil';
