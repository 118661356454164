import React, { useState, useEffect, useMemo } from 'react';
import {
  VENDORS,
  NEXT_FLOCK_LIST,
  NEXT_FLOCK_DETAILS,
} from 'config/routes';
import Top from 'components/Top';
import LoadingHolder from 'components/LoadingHolder';
import Breadcrumb from 'components/Breadcrumb';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import NextFeedList from './Feed/NextFeedList';
import NextPlacementList from './Placement/NextPlacementList';
import NextSlaughterList from './Slaughter/NextSlaughterList';
import { ScheduledStyled, Container } from './VendorSchedule.styled';
// service
import { geVendors } from './service';
import t from 'components/i18n';

export default function VendorSchedule({
  match: { params },
  history,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [header, getHeader] = useState(null);

  const tabs = useMemo(
    () => [
      {
        label: t('next.vendorSchedule.tabs.feed'),
        key: 0,
        Component: NextFeedList,
        urlParam: 'feed',
      },
      {
        label: t('next.vendorSchedule.tabs.placement'),
        key: 1,
        Component: NextPlacementList,
        urlParam: 'placement',
      },
      {
        label: t('next.vendorSchedule.tabs.slaughter'),
        key: 2,
        Component: NextSlaughterList,
        urlParam: 'slaughter',
      },
    ],
    [],
  );

  useEffect(() => {
    async function fetchVendors() {
      setIsLoading(true);
      try {
        const response = await geVendors();
        setVendors(
          response.map(item => ({
            ...item,
            label: `${item.vendorName} - ${item.vendorCode}`,
          })),
        );
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }

    if (!params.id) {
      // if there's no id in url params,
      // get vendors list to create dropdown box
      fetchVendors();
    }

    if (params.type) {
      setActiveTab(
        tabs.filter(e => e.urlParam === params.type)[0].key,
      );
    } else {
      setActiveTab(0);
    }
  }, [params.id, params.type, tabs]);

  useEffect(() => {
    if (vendors && params.vendorCode) {
      setSelectedVendor(
        vendors.filter(
          item => item.vendorCode === params.vendorCode,
        )[0],
      );
    }
  }, [params.vendorCode, vendors]);

  return (
    <ScheduledStyled>
      <LoadingHolder isLoading={isLoading} />
      {!isLoading && (
        <>
          {header && (
            <Breadcrumb
              history={history}
              backTo={() =>
                history.push(`${NEXT_FLOCK_DETAILS}/${params.id}`)
              }
              path={[
                t('next.vendorSchedule.bc.growers'),
                header.vendorName,
                t('next.vendorSchedule.bc.flock', { value: header.flock }),
                t('schedule.title'),
              ]}
              links={[
                VENDORS,
                `${NEXT_FLOCK_LIST}/${params.vendor}/new`,
                `${NEXT_FLOCK_DETAILS}/${params.id}`,
              ]}
            />
          )}

          <Top>
            <h2>{t('schedule.title')}</h2>
          </Top>

          <Header>
            {tabs.map(item => (
              <TabHeaderHolder
                key={item.key}
                onClick={() => setActiveTab(item.key)}
                className={activeTab === item.key ? 'active' : ''}
              >
                <div className="label">{item.label}</div>
                <div className="bar" />
              </TabHeaderHolder>
            ))}
          </Header>

          <Container>
            {tabs.map(
              (item, index) =>
                activeTab === item.key && (
                  <item.Component
                    key={index}
                    vendors={vendors}
                    flockId={params.id}
                    selectedVendor={selectedVendor}
                    getHeader={getHeader}
                  />
                ),
            )}
          </Container>
        </>
      )}
    </ScheduledStyled>
  );
}
