import styled from 'styled-components';

import {
  DEFAULT_SIZE,
  colorClear,
  colorDanger,
  colorGrayDarkest,
  colorGrayLightest,
} from 'components/GlobalStyles/variables';

export const TermsOfUsage = styled.div`
  .description {
    text-align: justify;
    line-height: 20px;
  }

  footer {
    margin-bottom: 40px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  justify-content: space-between;

  flex-direction: column;

  .acceptance {
    background-color: ${colorGrayDarkest};
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    color: ${colorClear};
    padding: ${DEFAULT_SIZE * 3}px;
    border-radius: ${DEFAULT_SIZE}px;
    font-size: 14px;
  }

  .actions {
    justify-content: flex-end;
    display: flex;
  }

  .warning {
    background-color: ${colorClear};
    border: 1px solid ${colorGrayLightest};
    border-radius: ${DEFAULT_SIZE}px;
    box-shadow: 0px 8px 16px rgba(37, 48, 71, 0.24);
    padding: 28px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;

    .message {
      color: ${colorDanger};
      font-weight: bold;
      margin-bottom: 25px;

      [class^='icon-'] {
        margin-right: 20px;
      }
    }

    .confirm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 38px;
      > span {
        margin-right: 10px;
      }
    }
  }
`;
