import React from 'react';
import { Tooltip } from '@zendeskgarden/react-tooltips';
import { ListItem } from 'components/List';
import Icon from 'components/Icon';
import IconPlaceholder from 'components/IconPlaceholder';
import ProgressCircle from 'components/ProgressCircle';
import { colorGrayLighter } from 'components/GlobalStyles/variables';
import { Wrapper, TopText, Container } from './IconAndText.styled';

export default function IconAndText(props) {
  const {
    alignTop,
    noPadding,
    border,
    iconBackGround = false,
    bigIcon,
    icon,
    title,
    topText,
    subtitle,
    additional,
    action = false,
    actionIcon,
    tooltip,
    list,
    listTitle,
    progress,
    progressColor,
    reverse,
    displayChevron = false,
  } = props;

  return (
    <Wrapper border={border}>
      <ListItem
        noPadding
        noMargin
        reverse={reverse}
        alignStart={Boolean(additional)}
      >
        {icon && (
          <IconPlaceholder
            bigIcon={bigIcon}
            bgColor={iconBackGround || 'colorClear'}
            className={alignTop ? 'top' : ''}
            round
          >
            <span className={`icon ${icon}`} />
          </IconPlaceholder>
        )}

        {progress && (
          <ProgressCircle value={progress} barColor={progressColor} />
        )}
        {!action && (
          <>
            <div style={{ padding: noPadding ? '0' : '16px 0' }}>
              {topText && <TopText>{topText}</TopText>}

              {title && (
                <p>
                  <strong>{title}</strong>
                </p>
              )}
              {subtitle && <p>{subtitle}</p>}
              {additional && (
                <p className="additional">{additional}</p>
              )}
            </div>
            {displayChevron && (
              <Icon
                name="icon-chevron-right"
                size={24}
                color={colorGrayLighter}
              />
            )}
          </>
        )}

        {action && (
          <>
            <div className="flex" onClick={() => action()}>
              <p>{subtitle}</p>

              {subtitle && (
                <Tooltip
                  trigger={
                    <span
                      role="button"
                      className={`button-span icon ${actionIcon}`}
                    />
                  }
                >
                  {tooltip}
                </Tooltip>
              )}
            </div>
            {displayChevron && (
              <Icon
                name="icon-chevron-right"
                size={24}
                color={colorGrayLighter}
              />
            )}
          </>
        )}

        {list && (
          <Container>
            <li>
              <strong className="list-title">{listTitle}</strong>
            </li>
            {list.map((e, i) => (
              <>
                <p className="item-title">{e.title}</p>
                <li key={i}>
                  <span>{e.left}</span>
                  <span className="right">{e.right}</span>
                </li>
              </>
            ))}
          </Container>
        )}
      </ListItem>
    </Wrapper>
  );
}
