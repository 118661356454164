import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  colorClear,
  fontFamilyTextBold,
  colorGrayLight,
  colorGrayDarkest,
  boxShadow,
  breakpointDesktop,
} from 'components/GlobalStyles/variables';

export const CategoryToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .icon {
    font-size: 30px;
  }
`;

export const NewChecklistsContainer = styled.div`
  display: flex;
  padding: 16px 20px;

  .secondary-wrapper {
    min-width: 280px;
    margin: 0 20px 20px 0;

    @media (min-width: ${breakpointDesktop}px) {
      min-width: 316px;
    }
  }
`;

export const ChecklistsContainer2 = styled.div`
  max-width: 630px;
  width: 100%;
`;

export const ChecklistsContainer = styled.div`
  max-width: 690px;
  padding: 16px 20px;
`;

export const DetailsContainer = styled.div`
  padding: 16px 20px;
  max-width: 1024px;

  .container {
    max-width: 652px;
    width: 100%;
    overflow: hidden;

    h4 {
      font-size: 18px;
      line-height: 28px;
      margin: 0;
    }
  }
`;

export const DescriptionCard = styled.div`
  background-color: ${colorClear};
  padding: 16px;
  width: 316px;
  align-self: flex-start;
  border-radius: 8px;
  box-shadow: ${boxShadow};

  button {
    width: 100%;
    margin-top: 12px;
  }

  .title {
    color: ${colorGrayDarkest};
    line-height: 24px;
    font-family: ${fontFamilyTextBold};
    margin: 0;
  }

  .table-title {
    margin: 24px 0 16px;
    font-size: 12px;
    color: ${colorGrayLight};

    &:first-of-type {
      margin-left: 16px;
    }

    &:last-of-type {
      margin-right: 16px;
    }
  }
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;
  margin: ${props =>
    props.gap && `${props.gap}px -${DEFAULT_SIZE * 2}px`};
  width: ${props => props.full && `calc(100% + 32px)`};
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  & > * {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;
