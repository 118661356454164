import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  colorGrayLight,
  colorGrayLightest,
  colorGrayDarkest,
  colorPrimary,
  colorDanger,
  colorTertiary,
  breakpointTablet,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

const FeedList = styled.div`
  margin-top: 16px;
`;

export default FeedList;

export const FeedItem = styled.div`
  position: relative;
  padding-bottom: 32px;
  padding-left: 40px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  transition: 0.2s background-color ease-in-out;
  cursor: pointer;

  @media (min-width: ${breakpointTablet}px) {
    padding-left: 54px;

    .scheduling-feed-elements & {
      padding-left: 36px;
    }
  }

  .scheduling-feed-elements & {
    padding-right: 10px;
  }

  .bordered & {
    padding: 12px ${DEFAULT_SIZE * 2}px;
    border: 1px solid ${colorGrayLightest};
    border-radius: ${DEFAULT_SIZE}px;
    padding-left: ${DEFAULT_SIZE * 2 + 54}px;
  }

  .shadowed {
    box-shadow: 0px 4px 8px rgba(37, 48, 71, 0.16);
  }

  &::before {
    position: absolute;
    font-family: AgroPack;
    left: 0;
    top: 0;
    font-size: 24px;

    .bordered & {
      top: 12px;
      left: ${DEFAULT_SIZE * 2}px;
    }

    ${props => {
      if ('dot' in props) {
        return `
        content: '\\00B7';
        color: ${colorGrayDarkest};
        font-size: 70px!important;
        text-align: center;
        left: 1px;
        top: -32px;
      `;
      }

      if (props.type === 'feed') {
        return `
        content: '\\e938'; /* truck */
        color: ${colorPrimary};
      `;
      }

      if (props.type === 'placement') {
        return `
        content: '\\e93e'; /* warehouse */
        color: ${colorTertiary};
      `;
      }

      if (props.type === 'slaughter') {
        if ('pig' in props) {
          return `
          content: '\\e92f';
          color: ${colorDanger};
        `;
        }

        if ('broiler' in props) {
          return `
          content: '\\e913';
          color: ${colorDanger};
        `;
        }

        if ('turkey' in props) {
          return `
          content: '\\e940';
          color: ${colorDanger};
        `;
        }
    }

      if ('summary' in props) {
        return `
        content: '\\e933'; /* scale */
        color: ${colorGrayLight};
      `;
      }

      if ('swine' in props) {
        return `
        content: '\\e96a'; /* pig-alt */
        color: ${colorGrayLight};
      `;
      }

      if ('medkit' in props) {
        return `
        content: '\\e955'; /* pig-alt */
        color: ${colorGrayLight};
      `;
      }
    }}
  }

  &::after {
    position: absolute;
    content: '';
    width: 1px;
    top: 32px;
    bottom: 12px;
    left: 12px;
    background-color: ${colorGrayLightest};

    .summary & {
      display: none;
    }

    .bordered & {
      top: 44px;
      left: ${DEFAULT_SIZE * 2 + 12}px;
    }
  }

  &.not-connected {
    &::after {
      display: none;
    }
  }

  .flock {
    font-family: ${fontFamilyTextBold};
    letter-spacing: 0.15px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .item-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .date {
      letter-spacing: 1.5px;
      font-size: 10px;
      line-height: 21px;
      color: ${colorGrayLight};
    }
  }

  .item-footer {
    font-size: 14px;
    color: ${colorGrayLight};
    /* pointer-events: none; */

    > div {
      width: auto;
    }

    .units,
    .caption {
      display: block;
      line-height: 16px;
      margin-bottom: 4px;
    }

    .caption {
      font-size: 13px;
    }

    .feed-history-holder & {
      padding-bottom: 12px;
      border-bottom: 1px solid ${colorGrayLightest};
    }
  }

  &:last-of-type {
    .item-footer {
      border: none;
    }
  }
`;
