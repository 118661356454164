import styled from 'styled-components';
import {
  colorClear,
  colorSuccess,
  colorDanger,
  colorInfo,
  colorPrimary,
  colorGrayDarker,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables.js';
import {
  Modal,
  Header,
  Body,
  Footer,
} from '@zendeskgarden/react-modals';

export const FormModal = styled(Modal)`
  background-color: none !important;
  max-width: 320px;
  // height: 165px;

  &:after {
    display: none;
  }

  ${Header} {
    .container-detail {
      display: block;
      background-color: ${props => props.color || colorPrimary};
      height: 3px;
      width: 50px;
      margin: -20px 0 13px;
    }
  }

  ${Body},
  ${Footer} {
    color: ${props => {
      if (props.warning) {
        return colorGrayDarker;
      }
      return colorClear;
    }};
    background-color: ${props => {
      if ('danger' in props) {
        return colorDanger;
      }
      if (props.warning) {
        return colorClear;
      }
      if ('info' in props) {
        return colorInfo;
      }

      return colorSuccess;
    }};

    .modal-title {
      display: flex;
    }

    .icon,
    h4 {
      font-family: ${fontFamilyTextBold};
      font-weight: normal;
      display: inline-block;
      font-size: 16px;
    }

    .icon {
      font-size: 22px;
      margin: 0 20px 0 -20px;
      position: relative;
      top: 3px;
    }

    h4,
    p {
      margin: 0;
    }

    p {
      margin: 12px 0 0 22px;

      &.noMargin {
        margin: 0;
      }
    }
  }

  ${Body} {
    padding: 15px 40px;
  }

  ${Footer} {
    padding: 10px 10px 20px;
    justify-content: center;
    button {
      background-color: ${props => {
        if (props.warning) {
          return props.bgColor;
        }
        return 'transparent';
      }} !important;
      border: 1px solid ${colorClear} !important;
      border-color: ${props => {
        if (props.warning) {
          return props.bgColor;
        }
        return colorClear;
      }} !important;
      margin: 0 6px;

      &.white {
        background-color: ${colorClear} !important;
        border: ${props => {
          if (props.warning) {
            return `1px solid ${colorGrayDarker}`;
          }
          return `1px solid ${colorClear}`;
        }} !important;
        color: ${props => {
          if ('danger' in props) {
            return colorDanger;
          }
          if (props.warning) {
            return colorGrayDarker;
          }
          if ('info' in props) {
            return colorInfo;
          }

          return colorSuccess;
        }} !important;
      }
    }
  }
`;
