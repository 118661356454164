import styled from 'styled-components';
import {
  colorClear,
  colorGrayUltraLight,
  colorGrayLight,
  colorGrayDarkest,
  colorGrayLightest,
} from 'components/GlobalStyles/variables';

export const IndcatorsStyled = styled.section`
  position: relative;
  padding: 20px;
  height: ${props => props.pageHeight || 'auto'};
`;

export const ListWrapper = styled.div`
  background-color: ${colorClear};
  border-radius: 8px;
  max-width: 652px;
  box-shadow: 0px 0px 16px rgba(37, 48, 71, 0.12);

  h1 {
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: ${colorGrayDarkest};
  }

  h3 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${colorGrayLight};
    margin-bottom: 24px;
  }

  &.full {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    padding-top: 56.25%;

    &.phase-feeding {
      padding-top: 200%;
    }

    &.fal {
      padding-top: 103%;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  .list-wrapper {
    padding: 40px;

    &.gray {
      background-color: ${colorGrayUltraLight};

      h1 {
        margin-bottom: 24px;
      }

      .flex {
        justify-content: flex-start;

        > div {
          margin: 0;
          > div {
            border: 1px solid ${colorGrayLightest};
          }
        }
      }
    }
  }

  .flex {
    flex-wrap: wrap;
    gap: 20px;

    > section {
      width: calc(50% - 10px);

      > div {
        margin-bottom: 0;
        > div {
          background-color: ${colorGrayUltraLight};
        }
      }
    }
  }
`;
