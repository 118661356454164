import { API_URL, API_SUPERVISOR_URL } from 'config/constants';
import { request } from 'shared/utils/client';
import { fixFileName } from 'shared/utils/Helpers/fixFileName';

export async function getLogBookItems() {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/GetLogBookItems`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getLogBookItemDetails(id) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/GetLogBookItemDetails?id=${id}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function updateLogBookItem(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/UpdateLogBookItem`,
    {
      method: 'PUT',
      payload,
    },
  );
  return response;
}

export async function getLogBookItemByFlock(flock) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/GetLogBookItemByFlock?flockId=${flock}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getOrientations(flock) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/GetOrientations?flockId=${flock}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function saveJustification(payload) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/SaveJustification`,
    {
      method: 'POST',
      payload,
    },
  );
  return response.data;
}

export async function uploadImage(payload) {
  const fd = new FormData();
  fd.append('file', payload, fixFileName(payload));

  const response = await request(
    `${API_URL}/Blob/Upload?containerName=logbookattachment`,
    {
      headers: {
        'Content-Type': undefined,
        Authorization: null,
      },
      method: 'POST',
      payload: fd,
    },
  );
  return response.data;
}

export async function deleteImage(file) {
  const response = await request(
    `${API_URL}/Blob/DeleteFile?fileName=${file}`,
    {
      method: 'DELETE',
    },
  );
  return response.data;
}

export async function deleteRandomLogBookItem(id) {
  const response = await request(
    `${API_SUPERVISOR_URL}/LogBookItem/DeleteLogBookItemRandom?id=${id}`,
    {
      method: 'DELETE',
    },
  );
  return response;
}
