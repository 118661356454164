import React, { useContext } from 'react';
import moment from 'moment';
import t from 'components/i18n';
import { Row, Col } from '@zendeskgarden/react-grid';
import Badge from 'components/Badge';
import FooterMenu from 'components/FooterMenu';

// Contexts
import { SwineContext } from 'shared/contexts';
// Styles
import {
  Container,
  ItemContainer,
  FeedItem,
} from './FlockHistory.styled';

export default function MedicationSummaryItem(props) {
  const { feed, goTo, exclude } = props;
  const swineContext = useContext(SwineContext);
  const {
    setSelectedMedicationId,
    setSelectedHouse,
  } = swineContext.actions;
  const badgeProps = {};

  function selectItem(option) {
    setSelectedMedicationId(feed.id);
    setSelectedHouse(feed.house);
    goTo(option);
  }

  function getOptions() {
    const options = [
      {
        action: () => selectItem('medicationDetails'),
        icon: 'visibility-enabled',
        label: t('global.button.details'),
        visible: true,
      },
      {
        action: () => selectItem('editMedication'),
        icon: 'edit',
        label: t('global.button.edit'),
        visible: feed.enableEdit,
      },
      {
        action: () => {
          exclude();
          setSelectedMedicationId(feed.id);
        },
        icon: 'trash',
        label: t('global.button.delete'),
        visible: feed.enableDelete,
      },
    ];
    return options;
  }

  if (feed.status) {
    switch (feed.status.toLowerCase()) {
      case 'pendente':
        badgeProps.info = true;
        break;
      default:
        badgeProps.success = true;
    }
  }
  function getStatusMessage(status) {
    if (status.toLowerCase() === 'concluído') {
      return t('global.status.done');
    }

    if (status.toLowerCase() === 'pendente') {
      return t('global.status.pending');
    }

    return status;
  }

  return (
    <Container className="bordered-container">
      <ItemContainer
        className="item-container"
        onClick={() => selectItem('medicationDetails')}
      >
        <div className="open summary">
          <FeedItem key={`feed-item-${feed.id}`} medkit>
            <header className="item-header">
              <div className="date">
                {moment(feed.startedDate).format('DD ̸̸  MM ̸̸  YYYY')}
                {' - '}
                {moment(feed.endedDate).format('DD ̸̸  MM ̸̸  YYYY')}
              </div>
              <div className="status">
                {feed.status && (
                  <Badge {...badgeProps}>
                    {getStatusMessage(feed.status)}
                  </Badge>
                )}
              </div>
            </header>
            <Row>
              <Col>
                <strong>{feed.name}</strong>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  {feed.quantity}
                  {feed.measurementUnit}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <span className="strong">
                    {t('summary.weight.treatedAnimals')}:{' '}
                  </span>{' '}
                  {feed.quantityTreated}
                  {t('global.unit.head')}
                </p>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <p>
                  {t('summary.weight.house', { value: feed.house })}
                </p>
              </Col>
            </Row>
          </FeedItem>
        </div>
      </ItemContainer>
      {!props.hideOptions && (
        <FooterMenu
          placement="start-bottom"
          options={getOptions(feed.isEditable)}
        />
      )}
    </Container>
  );
}
