import React, { useState, useEffect, useCallback } from 'react';

import moment from 'moment';
import * as _ from 'lodash';
import { DropdownFilter } from 'components/Filter';
import {
  dateFilterList,
  getDateLabel,
} from 'components/Filter/FiltersUtils';
import {
  Close,
  RoundedItem,
} from 'components/MultiSelect/Items.styled';
import { WhiteBoxInsideHeader } from 'components/HeaderBox';
import SideboxFilter from 'components/SideboxFilter/SideboxFilter';
import { reduceBy } from 'shared/utils/Helpers';
import { getAnimalType } from './utils';
import t from 'components/i18n';
import { regionalMapper } from 'utils/mapper';

export default function AnnouncementsFilter(props) {
  // /actions
  const { originalData, setDataList } = props.info;

  const [dateFilter, setDateFilter] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);

  // new filter
  const [activeFilters, setActiveFilters] = useState([]);

  const applyFilters = useCallback(() => {
    const regionalsFilter = _.find(activeFilters, {
      label: t("announcements.filter.regionals"),
    });
    const centerFilter = _.find(activeFilters, {
      label: t("announcements.filter.units"),
    });
    const animalTypeFilter = _.find(activeFilters, {
      label: t("announcements.filter.anymalType"),
    });

    const itemsByDateFilter = () => {
      if (dateFilter !== null) {
        const nw = originalData.filter((x, i) =>
          moment(x.date).isBetween(
            moment(dateFilter.startDate).startOf('day'),
            moment(dateFilter.endDate).endOf('day'),
            null,
            '[]',
          )
            ? x
            : false,
        );
        return nw;
      }
      return originalData;
    };

    const itemsByRegionalFilter = item => {
      if (
        regionalsFilter === undefined ||
        regionalsFilter.length === 0
      )
        return true;

      if (
        regionalsFilter !== undefined &&
        activeFilters.length > 0 &&
        _.some(item.regionals, { name: regionalsFilter.txt })
      ) {
        return true;
      }
      return false;
    };

    const itemsByCentersFilter = item => {
      let selectItem = false;
      if (centerFilter === undefined || centerFilter.length === 0) {
        return true;
      }
      if (centerFilter !== undefined) {
        if (item.regionals.length > 0) {
          item.regionals.forEach(x => {
            if (
              x.centerList.map(x => x.name).includes(centerFilter.txt)
            ) {
              selectItem = true;
            }
          });
        }
      }
      return selectItem;
    };

    const itemsByAnimalTypeFilter = item => {
      let selectItem = false;
      if (
        animalTypeFilter === undefined ||
        animalTypeFilter.length === 0
      ) {
        return true;
      }
      if (animalTypeFilter !== undefined) {
        if (getAnimalType(item.animalType) === animalTypeFilter.txt) {
          selectItem = true;
        }
      }
      return selectItem;
    };

    const filtereds = itemsByDateFilter()
      .filter(itemsByRegionalFilter)
      .filter(itemsByCentersFilter)
      .filter(itemsByAnimalTypeFilter);

    setDataList(filtereds);
  }, [activeFilters, dateFilter, originalData, setDataList]);

  const getFilters = useCallback(dataItems => {
    const newD = Object.assign([], dataItems);
    const regionals = [];
    const centers = [];
    let animalTypes = [];

    if (newD.length > 0) {
      for (let indx = 0; indx < newD.length; indx++) {
        if (newD[indx].regionals.length > 0) {
          regionals.push(newD[indx].regionals);
          newD[indx].regionals.forEach(x => {
            if (x.centerList.length > 0) {
              centers.push(x.centerList);
            }
          });
        }
      }

      animalTypes = reduceBy(newD, 'animalType').map((e, i) => {
        if (e.animalType === 'all') {
          return false;
        }
        return { txt: getAnimalType(e.animalType), id: i };
      });
    }

    const filtersData = [
      {
        label: t('announcements.filter.regionals'),
        items:
          regionals.length > 0
            ? _.uniqBy(
                _.flatten(regionals).map(x => ({
                  txt: x.name,
                  label: t(regionalMapper(x.name)),
                  id: x.id,
                })),
                'txt',
              )
            : [],
      },
      {
        label: t('announcements.filter.units'),
        target: t('announcements.filter.regionals'),
        items:
          centers.length > 0
            ? _.uniqBy(
                _.flatten(centers).map(x => ({
                  txt: x.name,
                  targetId: x.regionalId,
                })),
                'txt',
              )
            : [false],
      },
      {
        label: t('announcements.filter.anymalType'),
        target: t('announcements.filter.units'),
        items: animalTypes.length > 0 ? animalTypes : [],
      },
    ];

    return filtersData;
  }, []);

  useEffect(() => {
    applyFilters();
    props.setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  useEffect(() => {
    setOpenCalendar(false);
    applyFilters();
    props.setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  return (
    <WhiteBoxInsideHeader>
      {!dateFilter ? (
        <DropdownFilter
          buttonLabel={t('global.button.filter.date')}
          list={dateFilterList}
          arrayItem="date"
          keysToCompare={['startDate', 'endDate']}
          onSelectItemAction={setDateFilter}
          openCalendar={openCalendar}
          setOpenCalendar={setOpenCalendar}
          clearFilters={dateFilter === null}
        />
      ) : (
        <div style={{ display: 'flex' }}>
          {' '}
          <RoundedItem>
            <p>
              {getDateLabel(dateFilter.startDate)} -{' '}
              {getDateLabel(dateFilter.endDate)}
            </p>
            <Close
              onClick={() => {
                setDateFilter(null);
              }}
            >
              <span className="icon icon-close-circle" />
            </Close>
          </RoundedItem>
        </div>
      )}

      {props.info.userType === 'F' && (
        <SideboxFilter
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          filters={
            props.data.length > 0 ? getFilters(props.data) : []
          }
        />
      )}
    </WhiteBoxInsideHeader>
  );
}
