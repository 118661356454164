import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Wrapper } from './Container.styled';

export default function Container(props) {
  const { children, hideDetail = false, ...rest } = props;
  const themeContext = useContext(ThemeContext);
  return (
    <Wrapper {...props} palette={themeContext.styles.palette}>
      {!hideDetail && <div className="container-detail" />}
      <div
        className="container-content"
        style={{
          backgroundImage: `url(${props.backgroundImage})`,
          overflowX: 'clip',
          overflowY: 'auto',
        }}
      >
        {children && (
          <div className="container-inner">
            {typeof children === 'function' && children({ ...rest })}
            {typeof children !== 'function' && children}
          </div>
        )}
      </div>
    </Wrapper>
  );
}
