import t from "components/i18n";

export const links = [
  {
    title: t("bb.utils.links.web.operationsStatement"),
    mobile: t("bb.utils.links.mobile.operationsStatement"),
    url: 'https://nam10.safelinks.protection.outlook.com/?url=https://www49.bb.com.br/m/ExtratoOperacoesRurais/%23/cm49eyJvcHIiOiJFTVBSRVNUSU1PQ1JFRElUT1JVUkFMRVhUUkFUT09QRVJBQ09FUyJ9&data=04%7c01%7calana.schramm%40brf.com%7c293e722f3ac44fd45e4308d910832296%7c27951710ebb94efba4a548e460822d4b%7c0%7c0%7c637558977688036597%7cUnknown%7cTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7c1000&sdata=mASD6LxRO1PltFXHOWG/4niu8ghfetels5dh0UZqLEs%3D&reserved=0',
  },
  {
    title: t("bb.utils.links.web.futureReleases"),
    mobile: t("bb.utils.links.mobile.futureReleases"),
    url: 'https://nam10.safelinks.protection.outlook.com/?url=https://www49.bb.com.br/m/LancamentosFuturosOpRurais/%23/cm49eyJvcHIiOiJFTVBSRVNUSU1PQ1JFRElUT1JVUkFMTEFOQ0FNRU5UT1NGVVRVUk9TIn0%3D&data=04%7c01%7calana.schramm%40brf.com%7c293e722f3ac44fd45e4308d910832296%7c27951710ebb94efba4a548e460822d4b%7c0%7c0%7c637558977688036597%7cUnknown%7cTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7c1000&sdata=jFoHG7Kla7koGNXCiFPbcbG6YUiBzeiZ0Fcid7EpZXk%3D&reserved=0',
  },
  {
    title: t("bb.utils.links.web.ruralInvestment"),
    mobile: t("bb.utils.links.mobile.ruralInvestment"),
    url: 'https://nam10.safelinks.protection.outlook.com/?url=https://www49.bb.com.br/m/InvestimentoRural/%23/cm49eyJvcHIiOiJJTlZFU1RJTUVOVE9SVVJBTCJ9&data=04%7c01%7calana.schramm%40brf.com%7c293e722f3ac44fd45e4308d910832296%7c27951710ebb94efba4a548e460822d4b%7c0%7c0%7c637558977688046555%7cUnknown%7cTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7c1000&sdata=QxhIzLKGuIEtNCHTxFstZaSfgb3dTGGfIuF%2BkuJnm1s%3D&reserved=0',
  },
  {
    title: t("bb.utils.links.web.agriculturalCosting"),
    mobile: t("bb.utils.links.mobile.agriculturalCosting"),
    url: 'https://nam10.safelinks.protection.outlook.com/?url=https://www49.bb.com.br/m/EmprestimoCreditoRuralCusteioAgricola/%23/cm49eyJvcHIiOiJFTVBSRVNUSU1PQ1JFRElUT1JVUkFMQ1VTVEVJT0FHUklDT0xBIiwiYXRpdmlkYWRlIjoiMiJ9&data=04%7c01%7calana.schramm%40brf.com%7c293e722f3ac44fd45e4308d910832296%7c27951710ebb94efba4a548e460822d4b%7c0%7c0%7c637558977688046555%7cUnknown%7cTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7c1000&sdata=cnpdb4AdJVXIZnZdR2bBHwtJrJsTA7AnfqBFLSZJ0qA%3D&reserved=0',
  },
  {
    title: t("bb.utils.links.web.livestockCosting"),
    mobile: t("bb.utils.links.mobile.livestockCosting"),
    url: 'https://www49.bb.com.br/m/EmprestimoCreditoRuralCusteioPecuario/#/cm49eyJvcHIiOiJFTVBSRVNUSU1PQ1JFRElUT1JVUkFMQ1VTVEVJT1BFQ1VBUklPIiwiYXRpdmlkYWRlIjoiMSJ9',
  },
  {
    title: t("bb.utils.links.web.ruralProductBill"),
    mobile: t("bb.utils.links.mobile.ruralProductBill"),
    url: 'https://www49.bb.com.br/m/cedula-produto-rural/#/cm49eyJvcHIiOiJDRURQUiJ9',
  },
  {
    title: t("bb.utils.links.web.poultryFarm"),
    mobile: t("bb.utils.links.mobile.poultryFarm"),
    url: 'https://www49.bb.com.br/m/produtos/#/dD00OCZuPTMmcz0xJnA9MjAmYz0y',
    simulation: true,
  },
  {
    title: t("bb.utils.links.web.pigFarm"),
    mobile: t("bb.utils.links.mobile.pigFarm"),
    url: 'https://www49.bb.com.br/m/produtos/#/dD00OCZuPTMmcz0xJnA9MjEmYz0y',
    simulation: true,
  },
  {
    title: t("bb.utils.links.web.renewableEnergySystem"),
    mobile: t("bb.utils.links.mobile.renewableEnergySystem"),
    url: 'https://www49.bb.com.br/m/produtos/#/dD00OCZuPTMmcz0xJnA9MjImYz0y',
    simulation: true,
  },
];
