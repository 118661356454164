import t from 'components/i18n';

export default function make(pattern, original) {
  let count = -1;
  let prevPontuation = '';
  let valueWithPontuation = '';

  return pattern.split('').reduce((newValue, char, i) => {
    const value = newValue;

    if (count >= original.length) {
      return value;
    }

    // Fills in the number
    if (char === '#') {
      count += 1;
      valueWithPontuation = value;

      if (original[count]) {
        valueWithPontuation =
          value + prevPontuation + original[count];
      }
      prevPontuation = '';

      return valueWithPontuation;
    }

    prevPontuation += char;

    return value;
  }, '');
}

export function cleanUp(v) {
  return v.replace(/\D/g, '');
}

export function removeSpecialCharacters(v) {
  return v.replace(/[^\w\s]/gi, '');
}

function hasNumbersOnly(input) {
  return Number.isNaN(+input) === false;
}

class MaskError extends Error {
  constructor(...args) {
    super(...args);
    const [message, type] = args;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MaskError);
    }

    this.message = message;
    this.type = type;
  }
}

export function checkValue(val) {
  let value = val;

  if (!value) {
    throw new MaskError(t('maskedInput.validation.undefined'), 'UNDEFINED');
  }

  value = cleanUp(value);

  if (typeof value !== 'string') {
    throw new MaskError(t('maskedInput.validation.shouldBeString'), 'NOT_A_STRING');
  }

  if (!hasNumbersOnly(value)) {
    throw new MaskError(
      t("maskedInput.validation.onlyContainNumbers"),
      'NOT_NUMBERS_ONLY',
    );
  }

  if (String(value).length > 14) {
    throw new MaskError(t("maskedInput.validation.maxLength", { value: '14' }), 'TOO_LONG');
  }
}
