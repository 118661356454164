import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Top from 'components/Top';
import NoContentTab from 'components/NoContentTab';
import t from 'components/i18n';
import VendorsList from './VendorsList';

// style
import { VendorsStyled, Container } from './Vendors.styled';

const EmptyResult = () => {
  return (
    <NoContentTab icon="icon-clipboard-user" hideButton>
      <p>
        {t('global.no-content')}
      </p>
    </NoContentTab>
  );
};

const EmptySearch = () => {
  return (
    <NoContentTab icon="icon-clipboard-user" hideButton>
      {t('global.no-content')}
    </NoContentTab>
  );
};

export default function Vendors(props) {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <VendorsStyled>
        <Top>
          <h2>{t('next.vendors.title')}</h2>
        </Top>
        <Container>
          <VendorsList
            EmptyResult={EmptyResult}
            EmptySearch={EmptySearch}
            colorPrimary={themeContext.styles.palette.colorPrimary}
            {...props}
          />
        </Container>
      </VendorsStyled>
    </>
  );
}
