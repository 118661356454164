import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import * as _ from 'lodash';
import t from 'components/i18n';
import {
  colorInfo,
  colorDanger,
} from 'components/GlobalStyles/variables';
import { NEXT_FLOCK_LIST } from 'config/routes';
import { List, ListItem } from 'components/List';
import IconPlaceholder from 'components/IconPlaceholder';
import Icon from 'components/Icon';
import { displayMessageText } from 'shared/utils/Helpers';
import LoadingHolder from 'components/LoadingHolder';
import { WhiteBoxInsideHeader } from 'components/HeaderBox';
import SideboxFilter from 'components/SideboxFilter/SideboxFilter';
import { getVendors } from './service';
import { SubHeading } from './VendorsPR.styled';

export default function VendorsList({
  history,
  user,
  EmptySearch,
  EmptyResult,
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getVendors().then(result => {
      setIsLoading(false);
      if (result.success) {
        setData(result.data);
      }
    });
  }, []);

  const displayFilters = useMemo(() => {
    if (user.animalType === 'swine') {
      return false;
    }

    return true;
  }, [user.animalType]);

  const ENUM_STAGE = useMemo(
    () => ({
      isBeforePlacement: t('next.vendors.status.stage.isBeforePlacement'),
      isInitialPlacement: t('next.vendors.status.stage.isInitialPlacement'),
      isFinalPlacement: t('next.vendors.status.stage.isFinalPlacement'),
      isIntermediatePlacement: t('next.vendors.status.stage.isIntermediatePlacement'),
      isSanitaryEmpty: t('next.vendors.status.stage.isSanitaryEmpty'),
    }),
    [],
  );

  const getFilters = useCallback(list => {
    const filtersData = [
      {
        label: t('next.vendors.filters.priority.label'),
        items: [
          {
            txt: t("next.vendors.filters.priority.value.mortalityAlert"),
            value: 'mortalityAlert',
          },
          {
            txt: t("next.vendors.filters.priority.value.swabAlert"),
            value: 'swabAlert'
          },
          {
            txt: t("next.vendors.filters.priority.value.swabDelayedAlert"),
            value: 'swabDelayedAlert',
          },
        ],
      },
      {
        label: t('next.vendors.filters.flock.label'),
        items: _.uniqBy(
          list
            .map((x, i) => {
              x.isBeforePlacement = i <= 3;
              return x;
            })
            .filter(x => {
              return (
                x.isBeforePlacement ||
                x.isFinalPlacement ||
                x.isInitialPlacement ||
                x.isIntermediatePlacement ||
                x.isSanitaryEmpty
              );
            })
            .map(x => {
              let filterTxt = '';

              if (x.isBeforePlacement) {
                filterTxt = t('next.vendors.status.stage.isBeforePlacement');
              }
              if (x.isInitialPlacement) {
                filterTxt = t('next.vendors.status.stage.isInitialPlacement');
              }
              if (x.isIntermediatePlacement) {
                filterTxt = t('next.vendors.status.stage.isIntermediatePlacement');
              }
              if (x.isFinalPlacement) {
                filterTxt = t('next.vendors.status.stage.isFinalPlacement');
              }
              if (x.isSanitaryEmpty) {
                filterTxt = t('next.vendors.status.stage.isSanitaryEmpty');
              }

              return {
                txt: filterTxt,
              };
            }),
          'txt',
        ),
      },
    ];

    return filtersData;
  }, []);

  const getFilteredItems = useCallback(
    dataItems => {
      let filtData = dataItems;

      if (activeFilters.length > 0) {
        if (_.some(activeFilters, { label: t('next.vendors.filters.priority.label') })) {
          const urgency = activeFilters.filter(
            e => e.label === t('next.vendors.filters.priority.label'),
          )[0].value;

          filtData = filtData.filter(x => x[urgency]);
        }

        if (_.some(activeFilters, { label: t('next.vendors.filters.flock.label') })) {
          filtData = filtData.filter(x => {
            let filterText = '';
            if (x.isBeforePlacement) {
              filterText = ENUM_STAGE.isBeforePlacement;
            }
            if (x.isInitialPlacement) {
              filterText = ENUM_STAGE.isInitialPlacement;
            }
            if (x.isIntermediatePlacement) {
              filterText = ENUM_STAGE.isIntermediatePlacement;
            }
            if (x.isFinalPlacement) {
              filterText = ENUM_STAGE.isFinalPlacement;
            }
            if (x.isSanitaryEmpty) {
              filterText = ENUM_STAGE.isSanitaryEmpty;
            }
            if (
              filterText.length > 0 &&
              _.some(activeFilters, { txt: filterText })
            ) {
              return true;
            }
            return false;
          });
        }
      }

      return filtData;
    },
    [
      ENUM_STAGE.isBeforePlacement,
      ENUM_STAGE.isFinalPlacement,
      ENUM_STAGE.isInitialPlacement,
      ENUM_STAGE.isIntermediatePlacement,
      ENUM_STAGE.isSanitaryEmpty,
      activeFilters,
    ],
  );

  let filteredItems = getFilteredItems(data);
  useEffect(() => {
    filteredItems = getFilteredItems(data);
  }, [activeFilters]);

  const getFlockLength = useCallback(num => {
    if (num === 0) {
      return t('flocks.view.next.growers.item.overline.empty');
    }

    if (num > 1) {
      return t('flocks.view.next.growers.item.overline', { value: num });
    }

    return t('flocks.view.next.growers.item.overline.one', { value: num });
  }, []);

  return (
    <>
      <LoadingHolder isLoading={isLoading} />

      {!isLoading && (
        <>
          {displayFilters && (
            <WhiteBoxInsideHeader>
              <SideboxFilter
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                filters={getFilters(data)}
                labelApply={t("global.button.apply")}
              />
            </WhiteBoxInsideHeader>
          )}

          {filteredItems.length > 0 ? (
            <List className="normal">
              {filteredItems.map(item => (
                <ListItem
                  role="button"
                  key={item.vendorCode}
                  className="animated fadeIn"
                >
                  <IconPlaceholder
                    round
                    className="top icon-holder initial-name"
                    onClick={() =>
                      history.push(
                        `${NEXT_FLOCK_LIST}/${item.id}/new`,
                      )
                    }
                  >
                    <span style={{ fontSize: '16px', color: '#fff' }}>
                      {item.initialsName}
                    </span>
                  </IconPlaceholder>

                  <div
                    className="list-item-content"
                    style={{ width: '100%' }}
                    onClick={() =>
                      history.push(
                        `${NEXT_FLOCK_LIST}/${item.id}/new`,
                      )
                    }
                  >
                    <h2 className="list-item-heading">{item.name}</h2>
                    <SubHeading className="sub-heading">
                      Código: {item.vendorCode}
                    </SubHeading>
                    <div
                      className="list-subheading"
                      style={{ paddingRight: '35px' }}
                    />
                    <p className="list-item-description">
                      {getFlockLength(item.openFlockNumber)}
                    </p>

                    {item.swabAlert &&
                      displayMessageText(
                        'icon-clock',
                        t('next.vendors.alerts.swabAlert'),
                        colorInfo,
                      )}

                    {item.swabDelayedAlert &&
                      displayMessageText(
                        'icon-clock',
                        t('next.vendors.alerts.swabDelayedAlert'),
                        colorInfo,
                      )}

                    {item.mortalityAlert &&
                      displayMessageText(
                        'icon-exclamation-circle',
                        t('next.vendors.alerts.mortalityAlert'),
                        colorDanger,
                      )}
                  </div>
                  <Icon name="chevron icon-chevron-right" />
                </ListItem>
              ))}
            </List>
          ) : (
            EmptySearch()
          )}
        </>
      )}

      {!isLoading && data.length === 0 && EmptyResult()}
    </>
  );
}
