import React, { createContext, useReducer } from 'react';

const MemberContext = createContext();

const initialState = {
  name: '',
  email: '',
  recaptcha: '',
  animalType: [],
  countryState: '',
  cityID: 0,
  phoneNumber: '',
  message: '',
  platform: '',
  submitResponse: '',
};

const types = {
  SET_NAME: 'SET_NAME',
  SET_EMAIL: 'SET_EMAIL',
  SET_RECAPTCHA: 'SET_RECAPTCHA',
  SET_ANIMAL_TYPE: 'SET_ANIMAL_TYPE',
  SET_COUNTRY_STATE: 'SET_COUNTRY_STATE',
  SET_CITY_ID: 'SET_CITY_ID',
  SET_PHONE_NUMBER: 'SET_PHONE_NUMBER',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_PLATFORM: 'SET_PLATFORM',
  SET_SUBMIT_RESPONSE: 'SET_SUBMIT_RESPONSE',
  RESET_VALUES: 'RESET_VALUES',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case types.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case types.SET_RECAPTCHA:
      return {
        ...state,
        recaptcha: action.payload,
      };
    case types.SET_ANIMAL_TYPE:
      return {
        ...state,
        animalType: action.payload,
      };
    case types.SET_COUNTRY_STATE:
      return {
        ...state,
        countryState: action.payload,
      };
    case types.SET_CITY_ID:
      return {
        ...state,
        cityID: action.payload,
      };
    case types.SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case types.SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case types.SET_PLATFORM:
      return {
        ...state,
        platform: action.payload,
      };
    case types.SET_SUBMIT_RESPONSE:
      return {
        ...state,
        submitResponse: action.payload,
      };
    case types.RESET_VALUES:
      return {
        ...initialState,
      };

    default:
      console.log('Unknown reducer type.');
      return {
        ...state,
      };
  }
};

const actions = dispatch => ({
  setName: value =>
    dispatch({
      type: types.SET_NAME,
      payload: value,
    }),
  setEmail: value =>
    dispatch({
      type: types.SET_EMAIL,
      payload: value,
    }),
  setRecaptcha: value =>
    dispatch({
      type: types.SET_RECAPTCHA,
      payload: value,
    }),
  setAnimalType: value =>
    dispatch({
      type: types.SET_ANIMAL_TYPE,
      payload: value,
    }),
  setCoutryState: value =>
    dispatch({
      type: types.SET_COUNTRY_STATE,
      payload: value,
    }),
  setCityID: value =>
    dispatch({
      type: types.SET_CITY_ID,
      payload: value,
    }),
  setPhoneNumber: value =>
    dispatch({
      type: types.SET_PHONE_NUMBER,
      payload: value,
    }),
  setMessage: value =>
    dispatch({
      type: types.SET_MESSAGE,
      payload: value,
    }),
  setPlatform: value =>
    dispatch({
      type: types.SET_PLATFORM,
      payload: value,
    }),
  setSubmitResponse: response =>
    dispatch({
      type: types.SET_SUBMIT_RESPONSE,
      payload: response,
    }),
  resetValues: () =>
    dispatch({
      type: types.RESET_VALUES,
    }),
});

const MemberContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MemberContext.Provider
      value={{
        ...state,
        dispatch,
        actions: actions(dispatch),
      }}
    >
      {props.children}
    </MemberContext.Provider>
  );
};

export { MemberContext, MemberContextProvider };
