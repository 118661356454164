import React from 'react';
import {
  StyledDate,
  WeekDay,
  MonthDay,
} from './DateHighLight.styled';

export default function DateHighLight({ monthDay, weekDay }) {
  return (
    <StyledDate>
      <MonthDay>{monthDay}</MonthDay>
      <WeekDay>{weekDay}</WeekDay>
    </StyledDate>
  );
}
