import React, { useCallback } from 'react';
import Modal from 'components/Forms/FormModal/Modal';
import Toaster from 'components/Toaster';
import { removeMedication } from 'containers/AgroBRF/FlockList/service';
import t from 'components/i18n';

export default function SwineMedicationModals({
  success,
  setSelectedKey,
  medicationResponse,
  setMedicationResponse,
  selectedMedicationId,
  setSelectedMedicationId,
  displayExcludeModal,
  setDisplayExcludeModal,
  setRemoved,
  removed,
}) {
  async function removeItem(id) {
    try {
      const response = await removeMedication(id);
      setRemoved(response.success);
    } catch (e) {
      console.error('Fetch error', e);
    }
  }

  return (
    <>
      {/* Success or fail modal */}
      <Modal
        opened={medicationResponse !== null}
        warning={!success}
        onCloseModal={() => {
          setMedicationResponse(null);
          setSelectedMedicationId(null);
        }}
        noButtons
      >
        <span
          className={`icon icon-${
            success ? 'check' : 'exclamation-triangle'
          }`}
        />
        <h4>{success ? t('next.swineRecord.medicationModals.title.success') : t('next.swineRecord.medicationModals.title.error')}</h4>
        <p>
          {success
            ? t('next.swineRecord.medicationModals.message.success')
            : medicationResponse}
        </p>
      </Modal>
      {/* \ Success or fail modal */}

      {/* Exclude item modal */}
      <Modal
        warning
        opened={displayExcludeModal && selectedMedicationId}
        confirm={() => {
          removeItem(selectedMedicationId);
          setDisplayExcludeModal(false);
        }}
        deny={() => setDisplayExcludeModal(false)}
        onCloseModal={() => {}}
      >
        <span className="icon icon-exclamation-triangle" />
        <h4>{t('next.swineRecord.medicationModals.exclude.title')}</h4>
        {/* <p>{cadecSubmitResponse}</p> */}
        <p>
          {t("next.swineRecord.medicationModals.exclude.subtitle")}
        </p>
      </Modal>
      {/* \ Exclude item modal */}

      <Toaster
        className="toaster__notification"
        isVisible={removed}
        color="success"
        duration={3}
        delay={0}
      >
        <span className="icon icon-check" />
        {t("next.swineRecord.medicationModals.exclude.toast.success")}
      </Toaster>
    </>
  );
}
