import mask, { cleanUp } from './mask';

const PATTERN = '###';

const inputNumber = str => {
  const num = str.replace(/[^0-9]/g, '');
  return Number(num) || 0;
};

const formatWeight = weight =>
  (inputNumber(weight) / 10).toFixed(1).toString();

export default function doMask(input) {
  if (input === '') {
    return '';
  }
  const value = cleanUp(input);

  if (input.length > PATTERN.length - 1) {
    return formatWeight(mask(`${PATTERN}`, value));
  }

  return formatWeight(value);
}
