import React, { createContext, useReducer } from 'react';

const WeighingContext = createContext();

const initialState = {
  selectedWeight: {},
  submitResponse: null,
  success: false,
};

const types = {
  SET_SUBMIT_RESPONSE: 'SET_SUBMIT_RESPONSE',
  SET_SELECTED_WEIGHT: 'SET_SELECTED_WEIGHT',
  SET_SUCCESS: 'SET_SUCCESS',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_SELECTED_WEIGHT:
      return {
        ...state,
        selectedWeight: action.payload,
      };
    case types.SET_SUBMIT_RESPONSE:
      return {
        ...state,
        submitResponse: action.payload,
      };
    case types.SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    default:
      console.log('Unknown reducer type.');
      return {
        ...state,
      };
  }
};

const actions = dispatch => ({
  setSelectedWeight: weight =>
    dispatch({
      type: types.SET_SELECTED_WEIGHT,
      payload: weight,
    }),
  setSubmitResponse: response =>
    dispatch({
      type: types.SET_SUBMIT_RESPONSE,
      payload: response,
    }),
  setSuccess: response =>
    dispatch({
      type: types.SET_SUCCESS,
      payload: response,
    }),
});

const WeighingContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <WeighingContext.Provider
      value={{
        ...state,
        dispatch,
        actions: actions(dispatch),
      }}
    >
      {props.children}
    </WeighingContext.Provider>
  );
};

export { WeighingContext, WeighingContextProvider };
