import React from 'react';
import Container from 'components/Container';
import {
  UPDATE_PASSWORD,
  EDIT_PHONENUMBER,
  DEACTIVATE_ACCOUNT,
} from 'config/routes';
import { Button } from '@zendeskgarden/react-buttons';
import t from 'components/i18n';
import {
  Separator,
  FormSection,
  Item,
} from '../../ProfileAndSettings.styled';

export default function AccountData({ history }) {
  return (
    <Container className="container" slim>
      <h4>{t('menu.profile.accountData.accessData.title')}</h4>
      <Separator style={{ marginBottom: 0 }} />

      <FormSection>
        <h6>{t('menu.profile.accountData.accessData.label')}</h6>

        <Item>
          <Button
            className="white whiteBorder flex"
            style={{ width: '100%' }}
            onClick={() => history.push(`${EDIT_PHONENUMBER}`)}
          >
            {t(
              'menu.profile.accountData.sectionAccessData.buttonSingleLine.mobileNumber',
            )}
            <span className="right icon icon-chevron-right" />
          </Button>
        </Item>

        <Item>
          <Button
            className="white whiteBorder flex"
            style={{ width: '100%' }}
            onClick={() => history.push(UPDATE_PASSWORD)}
          >
            {t(
              'menu.profile.accountData.sectionAccessData.buttonSingleLine.updateNumber',
            )}
            <span className="right icon icon-chevron-right" />
          </Button>
        </Item>
      </FormSection>

      <FormSection>
        <h6>
          {t(
            'menu.profile.accountData.sectionPermission.mobileNumber',
          )}
        </h6>

        <Item>
          <Button
            className="white whiteBorder flex"
            style={{ width: '100%' }}
            onClick={() => history.push(`${DEACTIVATE_ACCOUNT}`)}
          >
            {t(
              'menu.profile.accountData.sectionPermission.buttonSingleLine.disableAccount',
            )}
            <span className="right icon icon-chevron-right" />
          </Button>
        </Item>
      </FormSection>
    </Container>
  );
}
