import React from 'react';
import styled from 'styled-components';
import { utils } from 'styled-minimal';
import { Link as Anchor } from 'react-router-dom';
import {
  colorClear,
  colorGrayDarkest,
  colorPrimary,
  colorTertiary,
  colorGrayLight,
  fontFamilyHeading,
  fontSizeSuper,
  colorGrayDarker,
  fontSizeLarge,
  breakpointDesktop,
  fontFamilyTextBold,
  borderRadius,
  DEFAULT_SIZE,
  colorGrayLighter,
} from 'components/GlobalStyles/variables';

export const SideBoxStyled = styled.div`
  opacity: ${props => (props.displayBox ? '1' : '0')};
  max-height: ${props => (props.displayBox ? 'none' : '0')};
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  max-width: 300px !important;
  transform: ${props =>
    props.displayBox ? 'none' : 'translateX(300px)'};
  transition: 0.3s opacity ease, 0.4s transform ease 0.4s;
  z-index: 1000;

  @media (min-width: ${breakpointDesktop}px) {
    max-width: 360px !important;
    transform: ${props =>
      props.displayBox ? 'none' : 'translateX(360px)'};
  }

  .side-box {
    position: relative;
    height: 100vh;
    box-shadow: none;
    min-width: 300px;

    .container-content {
      border-radius: 0;
      box-shadow: none;
      height: 100%;

      .bottom__buttons {
        position: absolute;
        bottom: 6.5rem;
      }
    }

    @media (min-width: ${breakpointDesktop}px) {
      min-width: 360px;
    }
  }

  &:before {
    content: '';
    display: block;
    height: 64px;
    background-color: ${colorClear};
    border-radius: 8px 0px 0px 0px;
  }

  .icon-close {
    color: ${colorGrayDarkest};
    font-size: 30px;
    padding: 10px;
    padding-right: 0;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
  }
`;

export const Shadow = styled.div`
  opacity: ${props => (props.displayBox ? '1' : '0')};
  transition: 0.3s opacity ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${props => (props.displayBox ? '100vh' : '0')};
  background: rgba(0, 0, 0, 0.5);
  z-index: ${props => (props.displayBox ? '900' : '0')};
`;

export const Heading = styled.h1`
  font-family: ${fontFamilyHeading};
  font-size: ${fontSizeSuper};
  font-weight: normal;
  margin: 0;
  margin-bottom: ${utils.px(12)};
  color: ${colorGrayDarker};
`;

export const Subheading = styled.h2`
  font-size: ${fontSizeLarge};
  font-family: ${fontFamilyTextBold} !important;
  font-weight: normal;
  margin-top: ${DEFAULT_SIZE * 2}px !important;
  margin-bottom: 0 !important;
  color: ${colorGrayLight};
`;

export const TitleBlock = styled.div``;

export const SectionTitle = styled.strong`
  display: block;
  font-size: 14px;
  line-height: 21px;
  font-family: ${fontFamilyTextBold};
  font-weight: normal;
  color: ${colorGrayDarkest};
  margin: 36px 0 24px;
`;

export const Link = styled(({ primary, ...props }) => (
  <Anchor {...props} />
))`
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  color: ${props =>
    props.primary ? colorPrimary : colorTertiary}!important;
  letter-spacing: 0.75px;
`;

export const LinkHolder = styled.div`
  line-height: 32px;
  ${props => (props.center ? 'text-align: center;' : '')}
`;

export const FixedField = styled.div`
  position: absolute;
  bottom: 80px;
  padding: 0;
  width: 100%;
  margin: 0;
`;

export const FixedFieldCustom = styled(FixedField)`
  .action {
    font-size: 14px !important;
  }

  .secondary-button {
    margin-top: 1rem;
  }
`;

export const StatusBar = styled.div`
  display: flex;
  color: ${props => props.color || colorGrayDarker};
  background-color: ${props => props.bgColor || colorClear};
  font-size: 14px;
  line-height: 21px;
  padding: 12px 16px 12px 28px;
  margin-left: -16px;
  border-radius: ${borderRadius};

  span {
    font-size: 20px;
    margin-right: 28px;
  }
`;

export const DeleteContainer = styled.div`
  border: 1px solid #CFD2D8;
  padding: 20px 16px 16px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;

  .title {
    font-weight 600;
    font-size: 16px;
    line-height: 24px;
    color: ${colorGrayDarker};
    margin: 0;
  }

  .created-at {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colorGrayLight}
    margin: 0;
  }

  .icon {
    color: ${colorGrayLighter};
    cursor: pointer;
  }
`;
