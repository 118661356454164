import { API_SUPERVISOR_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getDashboard() {
  const response = await request(
    `${API_SUPERVISOR_URL}/Supervisor/GetDashboard`,
    {
      method: 'GET',
    },
  );

  return response;
}

export async function getWeeklyEvents() {
  const response = await request(
    `${API_SUPERVISOR_URL}/Flock/GetWeeklyEvents`,
    {
      method: 'GET',
    },
  );

  return response.data;
}
