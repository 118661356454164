import React, { useMemo } from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { JustifiedListItem } from 'components/List';
import NotificationBox from 'components/NotificationBox';
import LoadingHolder from 'components/LoadingHolder';
import CheckboxTable from 'components/CheckboxTable';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { colorDanger } from 'components/GlobalStyles/variables';
import { Form } from 'components/Forms/Forms.styled';
import {
  Container,
  Separator,
  Subheading,
} from 'containers/NextBRF/UserManagement/UserManagement.styled';
import { getFunctionalitiesList } from './utils';
import t from 'components/i18n';
import { profileAttributionMapper } from 'utils';

export default function ProfileDetails({
  isLoading,
  selectedProfile,
  setSelectedKey,
  functionalitiesList,
  profilePermission,
}) {
  const functionalities = useMemo(() => {
    return getFunctionalitiesList(functionalitiesList);
  }, [functionalitiesList]);

  return (
    <>
      {selectedProfile && !selectedProfile.isEnabled && (
        <NotificationBox
          type="warning"
          bgColor={colorDanger}
          slim
          style={{
            paddingLeft: 22,
            margin: '-20px -20px 16px -20px',
            width: 'calc(100% + 40px)',
          }}
        >
          {t('next.userManagement.profile.profileDetails.inactive')}
        </NotificationBox>
      )}

      <Container className="animated fadeIn">
        <LoadingHolder
          message={t('global.loading')}
          isLoading={isLoading}
        />
        {!isLoading && selectedProfile && (
          <Form>
            <Subheading>{t('next.userManagement.profile.profileDetails.identification')}</Subheading>

            <JustifiedListItem>
              <span>{t('next.userManagement.profile.profileDetails.profile-status')}</span>
              <p>
                <strong>
                  {selectedProfile.isEnabled ? 'Ativo' : 'Inativo'}
                </strong>
              </p>
            </JustifiedListItem>

            <JustifiedListItem>
              <span>{t('next.userManagement.profile.profileDetails.related-users')}</span>
              <p>
                <strong>{selectedProfile.users}</strong>
              </p>
            </JustifiedListItem>

            <JustifiedListItem>
              <span>{t('next.userManagement.profile.profileDetails.profile-atribution')}</span>
              <p>
                <strong>{profileAttributionMapper(selectedProfile.attribution)}</strong>
              </p>
            </JustifiedListItem>

            <Separator />

            <Subheading>{t('next.userManagement.profile.profileDetails.functionalities')}</Subheading>
            <CheckboxTable
              fieldGroup="allowRoutes"
              colSize={{ sm: 4 }}
              title={t('next.userManagement.profile.profileDetails.functionalities')}
              setFieldValue={() => {}}
              isProfileForm
              labelList={[
                t('next.userManagement.profile.profileDetails.checkboxTable.criar'),
                t('next.userManagement.profile.profileDetails.checkboxTable.editar'),
                t('next.userManagement.profile.profileDetails.checkboxTable.excluir'),
                t('next.userManagement.profile.profileDetails.checkboxTable.ler'),
              ]}
              list={functionalities}
              itemList="level"
              labelOption="label"
              values={{
                allowRoutes: selectedProfile.allowRoutes.map(item => {
                  const routeFound =
                    selectedProfile.allowRoutes &&
                    selectedProfile.allowRoutes.find(
                      route => Number(route.id) === Number(item.id),
                    );

                  if (routeFound) {
                    const newLevel = Number(routeFound.level)
                      .toString(2)
                      .padStart(4, '0')
                      .split('');

                    return {
                      id: Number(item.id),
                      level: [
                        Number(newLevel[2]), // create
                        Number(newLevel[1]), // edit
                        Number(newLevel[0]), // exclude
                        Number(newLevel[3]), // read
                      ],
                      // table order,
                    };
                  }

                  return {
                    id: item.id,
                    level: [false, false, false, false],
                  };
                }),
              }}
              disableAll
            />

            <Separator />

            {/* \ Botões */}
            <Row>
              <Col size={4} />

              <Col size={8}>
                <BtnGroup>
                  {!!profilePermission && !!profilePermission.edit && (
                    <Button
                      key="sendtoConfirm"
                      className="tertiary"
                      onClick={() => setSelectedKey('edit-profile')}
                    >
                      {t("global.button.edit")}
                    </Button>
                  )}
                  <Button
                    key="clean"
                    onClick={() => setSelectedKey('profile-list')}
                    className="white"
                  >
                    {t('global.button.back')}
                  </Button>
                </BtnGroup>
              </Col>
            </Row>
            {/* \ Botões */}
          </Form>
        )}
      </Container>
    </>
  );
}
