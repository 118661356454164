import React from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import NewsListItem from './NewsListItem';
import t from 'components/i18n';
import {
  Wrapper,
  Highlight,
  SimpleList,
  SectionTitle,
} from './News.styled';

export default function NewsList(props) {
  const { highlighted, news } = props;

  return (
    <Wrapper>
      {highlighted.length > 0 && (
        <Highlight>
          <Row>
            {highlighted.map(
              (item, index) =>
                index < 4 && (
                  <Col md={6} lg={3} key={index}>
                    <NewsListItem
                      highlight
                      content={item}
                      history={props.history}
                    />
                  </Col>
                ),
            )}
          </Row>
        </Highlight>
      )}

      <SectionTitle backgroundColor={props.colorPrimary}>
        <div className="container-detail" />
        {t('news.latest-news')}
      </SectionTitle>

      {news.length > 0 && (
        <SimpleList>
          <Row>
            {news.map((item, index) => (
              <Col sm={4} lg={3} key={index} id={item.id}>
                <NewsListItem
                  key={index}
                  defaultText
                  content={item}
                  history={props.history}
                />
              </Col>
            ))}
          </Row>
        </SimpleList>
      )}
    </Wrapper>
  );
}
