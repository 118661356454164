import styled from 'styled-components';
import {
  boxShadow,
  colorClear,
  fontFamilyHeading,
  colorGrayDarkest,
  colorGray,
  colorGrayLightest,
} from 'components/GlobalStyles/variables';

export const Header = styled.div`
  padding: 20px 0 20px 40px;
  background-color: ${colorClear};
  border-bottom: 1px solid ${colorGrayLightest};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

export const DeactivatedUserStyled = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubHeading = styled.h6`
  font-family: ${fontFamilyHeading};
  font-size: 16px;
  line-height: 24px;
  color: ${colorGrayDarkest};
`;

export const Wrapper = styled.div`
  padding: 16px;
  max-width: 652px;
  height: 178px;
  box-shadow: ${boxShadow};
  border-radius: 8px;
  background-color: ${colorClear};

  p {
    color: ${colorGray};
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }

  ${SubHeading} {
    margin-bottom: 8px;
  }
`;

export const Separator = styled.hr`
  margin: 16px -16px;
  opacity: 0.12;
`;
