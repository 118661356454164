// @flow

import React from 'react';

import { Alert as AlertComponent, Box } from 'styled-minimal';
import Icon from 'components/Icon';

import { AlertButton, AlertIcon, AlertWrapper } from './Alert.styled';
import type { TAlertProps } from './typings';

AlertComponent.displayName = 'AlertComponent';

function Alert(props: TAlertProps) {
  // eslint-disable-next-line
  const { children, handleClickClose, id, icon, ...rest } = props;
  const output = {};
  let name;

  switch (rest.variant) {
    case 'success': {
      name = icon || 'icon-Check';
      break;
    }
    case 'warning': {
      name = icon || 'icon-Exclamation-Circle';
      break;
    }
    case 'danger': {
      name = icon || 'icon-Times-Circle';
      break;
    }
    default: {
      name = icon || 'icon-Exclamation-Circle';
    }
  }

  if (handleClickClose) {
    output.button = (
      <AlertButton
        data-id={id}
        onClick={handleClickClose}
        type="button"
        data-testid="AlertButton"
      >
        <Icon name="icon-close" width={10} />
      </AlertButton>
    );
  }

  return (
    <AlertWrapper {...rest} data-testid="AlertWrapper">
      <AlertIcon {...rest}>
        <Icon name={name} width={24} />
      </AlertIcon>
      <Box p={2} pr={handleClickClose ? 3 : 2}>
        {children}
      </Box>
      {output.button}
    </AlertWrapper>
  );
}

Alert.defaultProps = {
  outline: true,
  variant: 'info',
};

export default Alert;
