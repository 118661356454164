// @flow

import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@zendeskgarden/react-buttons';
import { Dots } from '@zendeskgarden/react-loaders';
import t from 'components/i18n';
import {
  Field,
  Message,
  Input,
  Label,
} from '@zendeskgarden/react-forms';
import FloatingLabel from 'components/FloatingLabel';
import {
  Heading,
  Subheading,
  TitleBlock,
} from 'components/SideBox/SideBox.styled';
import Spacer from 'components/Spacer';
import { cleanUp } from 'components/MaskedInput/mask';
import {
  getValidationMessage,
  getValidation,
  getTextfieldClass,
} from 'shared/utils/Helpers';
import { Info } from './Authentication.styled';

import type { TSignup } from './typings';

const schema = yup.object().shape({
  vendorCode: yup.mixed().required(t('global.status.required-field')),
});

const INITIAL_VALUE = {
  vendorCode: '',
};

function Authentication(props: TSignup) {
  const { isLoading } = props;
  function submitForm(values, actions) {
    const payload = {
      vendorCode: cleanUp(values.vendorCode),
    };

    props.userAuthenticate(payload);
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={INITIAL_VALUE}
      handleSubmit
      onSubmit={submitForm}
      render={({
        values,
        handleSubmit,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldTouched,
        isValid,
      }) => {
        function onChange(e) {
          handleChange(e);
        }

        function onBlur(e) {
          setFieldTouched(e.currentTarget.name);
          handleBlur(e);
        }

        const buttonProps = {
          stretched: true,
          primary: true,
          type: 'submit',
          disabled: !isValid || isLoading,
        };

        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <TitleBlock>
              <Heading>{t('recoveryPassword.clifor.title')}</Heading>
              <Subheading>
                {t('recoveryPassword.clifor.subtitle')}
              </Subheading>
              <Info>{t('recoveryPassword.clifor.description')}</Info>
            </TitleBlock>

            <Spacer multiplier={5} />

            <Field
              className={getTextfieldClass(
                getValidation(
                  values.vendorCode,
                  errors,
                  'vendorCode',
                  !touched.vendorCode,
                ),
              )}
            >
              <FloatingLabel>
                <Label>
                  {t('recoveryPassword.clifor.inputClifor')}
                </Label>
                <Input
                  name="vendorCode"
                  type="text"
                  validation={getValidation(
                    values.vendorCode,
                    errors,
                    'vendorCode',
                    !touched.vendorCode,
                  )}
                  value={values.vendorCode}
                  onBlur={onBlur}
                  onChange={onChange}
                  disabled={isLoading}
                />
                <Message
                  validation={getValidation(
                    values.vendorCode,
                    errors,
                    'vendorCode',
                    !touched.vendorCode,
                  )}
                >
                  {getValidationMessage(
                    'vendorCode',
                    errors,
                    !touched.vendorCode,
                  )}
                </Message>
              </FloatingLabel>
            </Field>

            <Spacer />

            {!isLoading && <Button {...buttonProps}>{t('global.button.next')}</Button>}
            {isLoading && (
              <Button {...buttonProps}>
                <Dots />
              </Button>
            )}
          </form>
        );
      }}
    />
  );
}

export default Authentication;
