import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  DEFAULT_SIZE,
  fontFamilyTextBold,
  colorClear,
  colorGrayUltraLight,
  colorGrayDark,
  colorGrayDarkest,
  colorTertiary,
  colorSuccess,
  colorPrimary,
} from 'components/GlobalStyles/variables';

export const StyledCard = styled.section`
  position: relative;
  cursor: ${props => (props.hasAction ? 'pointer' : 'default')};
`;

export const Wrapper = styled.div`
  padding: 12px 16px;
  background-color: ${colorClear};

  button.white {
    color: ${colorTertiary}!important;
    font-family: ${fontFamilyTextBold};
  }

  p.title {
    color: ${colorGrayDarkest};
    font-family: ${fontFamilyTextBold};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0;
  }

  p.description {
    margin: 0;
    color: ${colorGrayDark};
    font-size: 14px;
  }

  p.smaller {
    margin: 0;
    color: ${colorGrayDark};
    font-size: 12px;
    line-height: 16px;

    .link {
      color: ${colorSuccess};
      font-family: ${fontFamilyTextBold};
      cursor: pointer;
      transition: color 0.2s ease-out;
      display: block;
      padding: 0 4px 8px;

      &:hover {
        color: ${colorPrimary};
      }
    }
  }

  .children {
    width: 100%;

    .icon {
      color: ${colorTertiary};
      float: right;
      display: block;
      font-size: 20px;
    }
  }
`;

export const Footer = styled.footer`
  border: 1px solid ${rgba(colorGrayDarkest, 0.12)};
  border-left: 0;
  border-right: 0;
  padding: ${DEFAULT_SIZE}px ${DEFAULT_SIZE * 2}px;
  background: ${props =>
    props.whiteBg ? colorClear : colorGrayUltraLight};

  p.footer-p {
    font-family: ${fontFamilyTextBold};
    margin: 0;
    font-size: 12px;
    line-height: 21px;
    color: ${props => props.footerColor};

    span {
      font-size: 20px;
      margin: 0 4px 0 0;
      position: relative;
      top: 2px;
    }
  }
`;
