import styled from 'styled-components';
// import rgba from 'polished/lib/color/rgba';
import {
  colorClear,
  colorPrimary,
  breakpointTablet,
} from 'components/GlobalStyles/variables';

export const MenuHolder = styled.div`
  padding: 0 !important;
  margin: 0 !important;
  border: 0 none !important;
  background-color: transparent !important;

  button {
    cursor: pointer;
    background-color: transparent;
    border: 0 none;
  }

  .notifications-heading {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: ${colorClear} !important;
    margin-bottom: 12px;
  }

  .notifications-empty-message {
    text-align: center;
    padding: 16px 0;
    color: ${colorClear};
    width: 100%;
    white-space: nowrap;
  }

  .notifications-content {
    position: relative;
    min-height: 280px;
    max-height: 300px;
    overflow: auto;
    left: -16px;
    right: -16px;
    width: 350px;
  }

  .notifications-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .notifications-clear {
    color: ${colorPrimary};
    letter-spacing: 0.15px;
  }

  .notifications-content {
    min-height: 245px;
    max-height: 400px;
    overflow-x: auto !important;
  }

  @media (min-width: ${breakpointTablet}px) {
    width: 350px;
  }
`;
