import styled from 'styled-components';
import {
  fontFamilyText,
  colorGray,
  colorClear,
  colorSuccess,
  colorDanger,
  colorGrayLighter,
  colorPrimary,
  colorTertiary,
  colorSecondaryYellow,
} from 'components/GlobalStyles/variables';

export const SubHeading = styled.p`
  font-size: 12px;
  font-family: ${fontFamilyText}!important;
  font-style: normal;
  line-height: 16px;
  color: ${colorGray};
  margin: 0;
  overflow-wrap: break-word;
`;
export const IconPlaceholder = styled.div`
  display: flex;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${colorGrayLighter};
  position: relative;
  padding: 0;

  &.top {
    align-self: flex-start;
  }

  .icon {
    color: ${colorClear};
    font-size: 24px;
  }

  &.initial-name {
    font-family: ${fontFamilyText}!important;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: ${colorGray};
  }

  &.primary {
    background-color: ${colorPrimary};
  }

  &.secondary {
    background-color: ${colorSecondaryYellow};
  }

  &.success {
    background-color: ${colorSuccess};
  }

  &.danger {
    background-color: ${colorDanger};
  }

  &.tertiary {
    background-color: ${colorTertiary};
  }
`;

export const RoundedItem = styled.div`
  display: inline-flex;
  align-items: center;

  border-radius: 16px;
  font-size: 14px;
  padding: 4px 8px 4px;
  cursor: pointer;
  margin: 0 12px 12px 0;
  word-break: break-word;

  background: #cfd2d8;
  &:hover {
    background-color: #ccc;
  }
  p {
    margin: 0;
    display: inline-block;
    color: #2b3851;
  }
  .icon-close-circle {
    color: #7f879a;
    &:hover {
      color: black;
    }
  }
`;
