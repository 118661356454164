import React from 'react';
import { StyledInternalMenu } from './InternalMenu.styled';

export default function InternalMenu({ list }) {
  return (
    <StyledInternalMenu>
      <ul>
        {list &&
          list.length > 0 &&
          list.map(item => (
            <li onClick={item.action}>
              {item.label}{' '}
              <span className="icon icon-chevron-right" />
            </li>
          ))}
      </ul>
    </StyledInternalMenu>
  );
}
