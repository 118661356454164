import styled from 'styled-components';
import { rgba } from 'polished';
import {
  colorGrayDarkest,
  colorGrayLight,
  colorGrayLighter,
} from 'components/GlobalStyles/variables';

export const ToggleRangeStyled = styled.div`
  padding: 0 16px;
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};
  align-items: center;

  &:last-of-type {
    border-bottom: none;
  }

  .title {
    min-width: ${props => (props.large ? '250px' : '188px')};
    color: ${colorGrayDarkest};

    strong,
    p {
      font-size: 14px;
      margin: 0;
    }

    p {
      color: ${colorGrayLighter};
      font-size: 12px;
    }
  }

  p {
    font-size: 14px;
    margin: 0;

    &.label {
      display: block;
      text-align: center;
    }
  }

  .flex {
    align-items: center;
  }

  .dropdown-wrapper {
    width: 80px;
    padding-top: 12px;
    height: 70px;

    label {
      display: none;
    }
  }

  .toggle-wrapper {
    width: 76px;
    justify-content: center;
  }

  .inactive {
    color: ${colorGrayLight};
    text-transform: uppercase;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
  }

  .unstyled-button {
    color: ${colorGrayLighter};

    &:not(:disabled) {
      &:hover {
        color: ${colorGrayDarkest};
      }
    }
  }

  input[type='checkbox'] {
    + label {
      &:before {
        top: -12px;
        left: 0 !important;
        width: 48px;
        height: 24px;
        background-size: 16px;
      }
    }
  }

  input[type='checkbox'] {
    + label.toggle {
      padding: 6px 0 0 16px;

      &:before {
        top: 4px;
        right: 16px;
        left: auto;
      }
    }
  }
`;
