import styled from 'styled-components';
import Box from 'components/Container';
import {
  colorDanger,
  colorGrayDarkest,
  colorGrayLight,
  colorSuccess,
} from 'components/GlobalStyles/variables';
import rgba from 'polished/lib/color/rgba';

export const CatchDetailsBox = styled(Box)`
  h1 {
    font-size: 24px;
    margin-bottom: 60px;
  }

  .container-inner {
    > div {
      padding: 0 8px;
      border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};

      &.no-border {
        border: none;
      }
    }

    p {
      font-size: 14px;
      line-height: 16px;
      color: ${colorGrayDarkest};

      &.alert {
        font-size: 14px;
        line-height: 21px;
        padding: 12px 26px;
        border-radius: 8px;
        display: flex;
        align-items: center;

        &.red {
          color: ${colorDanger};
          background-color: ${rgba(colorDanger, 0.16)};
        }

        &.green {
          color: ${colorSuccess};
          background-color: ${rgba(colorSuccess, 0.16)};
        }

        .icon {
          font-size: 24px;
          margin-right: 20px;
        }
      }

      .label {
        font-size: 12px;
        color: ${colorGrayLight};
      }

      .data {
        &.full {
          line-height: 21px;
        }
      }
    }
  }
`;
