import styled from 'styled-components';
import { FauxInput } from '@zendeskgarden/react-forms';
import rgba from 'polished/lib/color/rgba';
import {
  colorGrayDarker,
  DEFAULT_SIZE,
  colorClear,
  breakpointDesktop,
} from 'components/GlobalStyles/variables';

export const Input = styled(FauxInput)`
  border: 0 none !important;

  input {
    margin-top: 3px !important;
  }
`;

export const Search = styled.div`
  background-color: ${colorGrayDarker};
  padding: ${DEFAULT_SIZE * 2}px;
`;

export const Wrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

export const MainWrapper = styled.div`
  .sidebar-footer {
    display: flex;
    margin-top: auto;
    height: ${DEFAULT_SIZE * 7}px;
    border-top: 1px solid ${rgba(colorClear, 0.56)};

    .sidebar-footer-link {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 ${DEFAULT_SIZE * 3}px;
      cursor: pointer;
      transition: background-color 0.25s ease-in-out;

      &:hover {
        background-color: ${rgba(colorClear, 0.1)};
        .icon-sign-out,
        .label {
          color: ${colorClear};
        }
      }
    }
  }

  .logout {
    display: flex;
    align-items: center;
    .icon-sign-out,
    .label {
      color: ${rgba(colorClear, 0.56)};
      letter-spacing: 0.75px;
    }

    .icon-sign-out {
      margin-right: ${DEFAULT_SIZE}px;
      font-size: 22px;
    }

    .label {
      font-size: 13px;
      display: none;

      @media (min-width: ${breakpointDesktop}px) {
        display: inherit;
      }
    }
  }

  .is-open {
    .logout .label {
      display: inherit;
    }
  }
`;
