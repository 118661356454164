import { keyframes } from 'styled-components';

/* colors */
export const colorsAgro = {
  colorSuccess: '#009688',
  colorDanger: '#d22630',
  colorWarning: '#ffcc19',
  colorInfo: '#407ec9',
  colorHighlight: '#407ec9',
  colorPrimary: '#F55F20',
  colorSecondary: '#d22630',
  colorSecondaryYellow: '#FFAC2E',
  colorTertiary: '#00428A',

  colorClear: '#fff',
  colorGrayDarkest: '#253047',
  colorGrayDarker: '#2B3851',
  colorGrayDark: '#364156',
  colorGray: '#44516b',
  colorGrayLight: '#596581',
  colorGrayLighter: '#7f879a',
  colorGrayLightest: '#cfd2d8',
  colorGrayUltraLight: '#eceff1',
};

export const colorsNext = {
  colorSuccess: '#009688',
  colorDanger: '#d22630',
  colorWarning: '#ffcc19',
  colorInfo: '#407ec9',
  colorHighlight: '#000000',
  colorPrimary: '#00428A',
  colorSecondary: '#F55F20',
  colorSecondaryYellow: '#FFAC2E',
  colorTertiary: '#000000',

  colorClear: '#fff',
  colorGrayDarkest: '#002D65',
  colorGrayDarker: '#2B3851',
  colorGrayDark: '#364156',
  colorGray: '#44516b',
  colorGrayLight: '#596581',
  colorGrayLighter: '#7f879a',
  colorGrayLightest: '#cfd2d8',
  colorGrayUltraLight: '#eceff1',
};

const theme = colorsAgro;

export const {
  colorSuccess,
  colorDanger,
  colorWarning,
  colorInfo,
  colorHighlight,
  colorPrimary,
  colorSecondary,
  colorSecondaryYellow,
  colorTertiary,
} = theme;

// utils.getTheme(theme)

/* Define theme */

/* export colors */

// warning, success and info are not available
// for buttons, alerts and etc.

// export const colorPrimary = '#407ec9';

export const colorClear = '#fff';
export const colorGrayDarkest = '#253047';
export const colorGrayDarker = '#2B3851';
export const colorGrayDark = '#364156';
export const colorGray = '#44516b';
export const colorGrayLight = '#596581';
export const colorGrayLighter = '#7f879a';
export const colorGrayLightest = '#cfd2d8';
export const colorGrayUltraLight = '#eceff1';

export const colorText = colorGrayDarkest;

export const colorNavbar = colorClear;
export const backgroundColor = colorClear;
export const backgroundHighlightColor = '#fafafa';

export const DEFAULT_SIZE = 8;
export const DEFAULT_RADIUS_SIZE = 12;
export const headerHeight = 64;

/* scene */
export const sceneGap = `${DEFAULT_SIZE * 3}px`;
export const sceneGapLarge = `${DEFAULT_SIZE * 4}px`;

/* sizes */
export const size = `${DEFAULT_SIZE}px`;
export const sizeHalf = `${DEFAULT_SIZE / 2}px`;
export const sizeDefault = `${DEFAULT_SIZE}px`;
export const sizeLarge = `${DEFAULT_SIZE * 2}px`;
export const sizeLarger = `${DEFAULT_SIZE * 3}px`;
export const sizeExtraLarge = `${DEFAULT_SIZE * 4}px`;

/* border */
export const borderWidth = StyleSheet.hairlineWidth;
export const borderWidthLarge = StyleSheet.hairlineWidth * 2;
export const borderColor = colorGray;

/* radius */
export const borderRadius = `${DEFAULT_RADIUS_SIZE}px`;

/* shadow */
export const boxShadow = '0px 4px 8px rgba(37, 48, 71, 0.16)';

/* font-size */
export const fontSizeSmall = '12px';
export const fontSizeNormal = '14px';
export const fontSizeLarge = '16px';
export const fontSizeExtraLarge = '20px';
export const fontSizeSuper = '24px';

/* font-family/weight */
export const fontFamilyHeading = 'sf_probold';
export const fontFamilyText = 'sf_proregular';
export const fontFamilyTextBold = 'sf_probold';

export const appColor = colorPrimary;
export const easing = 'cubic-bezier(0.35, 0.01, 0.77, 0.34);';

/* responsive breakpoints (min-width) */
// const sizes = {
//   desktop: 992,
//   tablet: 768,
//   phone: 576,
// }
// export const breakpointDesktop = 769;
// export const breakpointTablet = 361;
export const breakpointDesktop = 1025;
export const breakpointLargeDesktop = 1200;
export const breakpointTablet = 670;
export const breakpointTablet2 = 767;

export const fadeIn = keyframes`
    0 {
    opacity: 0;
    }

    10% {
    opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
`;
