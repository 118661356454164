const docNumberValidateCpf = (cpf = '') => {
  cpf = cpf.replace(/\.|-/g, '');
  console.log(cpf);

  let sum;
  let rest;
  sum = 0;
  let i;

  console.log('False 1');
  if (cpf === '00000000000') return false;

  // eslint-disable-next-line radix
  for (i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  console.log('False 2');
  // eslint-disable-next-line radix
  if (rest !== parseInt(cpf.substring(9, 10))) {
    return false;
  }

  sum = 0;
  // eslint-disable-next-line radix
  for (i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  console.log('False 3');
  // eslint-disable-next-line radix
  if (rest !== parseInt(cpf.substring(10, 11))) {
    return false;
  }

  return true;
};

export { docNumberValidateCpf };
