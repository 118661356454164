import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  colorGrayLight,
} from 'components/GlobalStyles/variables';

export const DateAndTime = styled.span`
  display: inline-block;
  color: ${colorGrayLight};
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 16px;
  margin-bottom: ${props =>
    props.noMargin ? '' : `${DEFAULT_SIZE - 2}px;`};
`;
