// @flow

import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import logger from 'shared/utils/logger';
import { Aside } from './Sidebar.styled';

type TSidebarProps = {
  children: React.ReactNode,
  isOpen?: boolean,
  narrow?: boolean,
};

function Sidebar(props: TSidebarProps) {
  const themeContext = useContext(ThemeContext);
  logger('menuIsOpen', props.isOpen);

  let classes = [];

  if (props.narrow) {
    classes.push('narrow');
  } else {
    classes = classes.filter(c => c !== 'narrow');
  }

  if (props.isOpen) {
    classes.push('is-open');
  } else {
    classes = classes.filter(c => c !== 'is-open');
  }

  return (
    <Aside
      themeStyle={themeContext.styles}
      className={classes.join(' ')}
    >
      {props.children}
    </Aside>
  );
}

Sidebar.defaultProps = {
  narrow: false,
  isOpen: false,
};

export default Sidebar;
