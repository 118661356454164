import React from 'react';
import { HamburgerMenu } from './Hamburger.styled';

export default function Hamburger(props) {
  const { menuIsOpen } = props;

  return (
    <HamburgerMenu
      onClick={e => {
        if ('onClick' in props) {
          props.onClick(e, !menuIsOpen);
        }
      }}
      className={menuIsOpen ? 'is-open' : ''}
    >
      <div className="hamburger-item first" />
      <div className="hamburger-item second" />
      <div className="hamburger-item third" />
    </HamburgerMenu>
  );
}
