import React from 'react';
import { NEXT_ORIENTATION } from 'config/routes';
import moment from 'moment';
import CardWrapper from 'components/CardWrapper';
import { OrientationItem } from 'containers/NextBRF/Orientation';
import EditJustification from './EditJustification';
import t from 'components/i18n';

export default function OpenVisit({ data, history, getDetails }) {
  return (
    <div className="primary">
      {!data.orientation && !data.justification && (
        <CardWrapper>
          <p>
            {t('next.visitList.pastVisit.noOrientation-noJustification', { value: data.vendorName })}
          </p>
          <br />
          <EditJustification getDetails={getDetails} {...data} />
        </CardWrapper>
      )}

      {!data.orientation && data.justification && (
        <CardWrapper title={t('next.visitList.pastVisit.noOrientation-justification')}>
          <OrientationItem
            description={data.justification}
            date={moment(data.visitDate).format('DD ̸̸  MM ̸̸  YYYY HH:mm')}
          />
        </CardWrapper>
      )}

      {data.orientation && !data.justification && (
        <CardWrapper title={t('next.visitList.pastVisit.orientation-justification')}>
          <OrientationItem
            description={data.orientation}
            action={() => history.push(`${NEXT_ORIENTATION}/${data.id}`)}
            icon={data.blobNameList.length > 0}
            date={moment(data.visitDate).format('DD ̸̸  MM ̸̸  YYYY HH:mm')}
            footerContent={
              data.read
                ? t('next.visitList.pastVisit.orientation-justification.footer.read', { value: moment(data.readDate).format('DD ̸̸  MM ̸̸  YYYY HH:mm') })
                : t('next.visitList.pastVisit.orientation-justification.footer.sent')
            }
            footerIcon="icon-check"
            footerColor="colorInfo"
          />
        </CardWrapper>
      )}
    </div>
  );
}
