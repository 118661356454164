const saveToLocalStorage = (key, data, stringify = true) => {
  if (!key || !window.localStorage || !data) {
    return null;
  }

  const normalized = stringify ? JSON.stringify(data) : data;

  window.localStorage.setItem(key, normalized);
};

export default saveToLocalStorage;
