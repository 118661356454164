// @flow
import React, { useState, createContext } from 'react';
import Icon from 'components/Icon';
import {
  Container,
  Header,
  Button,
  Content,
} from './SidebarRight.styled';

type TContext = {
  isOpen: Boolean,
  toggle: () => undefined,
};

const Context = createContext<TContext>({
  isOpen: false,
  toggle: () => null,
});

function getMenuIsOpen(props) {
  if ('menuIsOpen' in props) {
    if (props.menuIsOpen) {
      return true;
    }
    return false;
  }

  return false;
}

export function SidebarRightHeader({ title }) {
  return (
    <Context.Consumer>
      {({ toggle }) => (
        <Header>
          <div className="title-holder">
            <div className="button-holder">
              <Button onClick={toggle}>
                <Icon
                  className="icon icon-back"
                  name="icon-arrow-left"
                />
              </Button>
            </div>
            {title && <h2>{title}</h2>}
          </div>
          <div className="button-holder">
            <Button>
              <Icon className="icon icon-settings" name="icon-cog" />
            </Button>
          </div>
        </Header>
      )}
    </Context.Consumer>
  );
}

export function SidebarRightContent({ title, children }) {
  return (
    <Context.Consumer>
      {({ isOpen, toggle, content, setContent }) => {
        const componentProps = {
          className: isOpen ? 'sidebarright is-open' : 'sidebarright',
        };
        return (
          <Container {...componentProps}>
            <SidebarRightHeader title={title} />
            <Content>{children}</Content>
          </Container>
        );
      }}
    </Context.Consumer>
  );
}

export function SidebarToggler({ children }) {
  return (
    <Context.Consumer>
      {({ isOpen, toggle }) =>
        React.cloneElement(children, {
          onClick: toggle,
        })
      }
    </Context.Consumer>
  );
}

export default function SidebarRight(props) {
  const { menuIsOpen, children } = props;
  const [isOpen, setIsOpen] = useState(
    getMenuIsOpen({
      menuIsOpen,
      clickOutsideToClose: props.clickOutsideToClose,
    }) || false,
  );
  const state = {
    isOpen,
    toggle: val => {
      if (val !== undefined) {
        setIsOpen(val);
      }

      setIsOpen(!isOpen);
    },
  };

  return (
    <Context.Provider value={state}>
      <>{children}</>
    </Context.Provider>
  );
}
