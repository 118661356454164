import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import {
  Menu,
  Item,
  Field,
  Label,
} from '@zendeskgarden/react-dropdowns';
import {
  Close,
  RoundedItem,
} from 'components/MultiSelect/Items.styled';
import { DropdownBox, AutocompleteBox } from '../Forms.styled';

export default function CreateAndSearchDropdown(props) {
  const {
    label,
    onSelect,
    deleteItem,
    options,
    disabled,
    placeholder,
    loading,
    loadingLabel,
    values,
    field,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [matchingOptions, setMatchingOptions] = useState(options);

  const filterMatchingOptionsRef = useRef(
    debounce(value => {
      const match = options.filter(
        option =>
          option
            .trim()
            .toLowerCase()
            .indexOf(value.trim().toLowerCase()) !== -1,
      );
      if (match.length > 2) {
        setMatchingOptions(match);
      } else {
        setMatchingOptions([value]);
      }
    }, 300),
  );

  const displayItems = () => {
    return values[field].map((item, index) => (
      <RoundedItem key={index}>
        {item}
        <Close onClick={() => deleteItem(item)}>
          {' '}
          <span className="icon icon-close-circle" />
        </Close>
      </RoundedItem>
    ));
  };

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);

  const renderOptions = match => {
    if (match.length) {
      return match.map((option, index) => (
        <Item key={index} value={option}>
          <p>{option}</p>
        </Item>
      ));
    }
  };

  return (
    <>
      <DropdownBox
        inputValue={inputValue}
        onSelect={item => {
          onSelect(item);
        }}
        onInputValueChange={inputVal => {
          setInputValue(inputVal);
        }}
        downshiftProps={{ defaultHighlightedIndex: 0 }}
      >
        <Field>
          <Label>{label}</Label>

          <AutocompleteBox disabled={disabled}>
            {inputValue ? (
              <span>{inputValue}</span>
            ) : (
              <span className="placeholder">{placeholder}</span>
            )}
          </AutocompleteBox>
        </Field>

        <Menu>
          {loading ? (
            <Item disabled>{loadingLabel}</Item>
          ) : (
            renderOptions(
              matchingOptions.length > 0
                ? matchingOptions.filter(
                    tag => !values[field].includes(tag),
                  )
                : options,
            )
          )}
        </Menu>
      </DropdownBox>
      {values[field].length > 0 && (
        <>
          <br />
          {displayItems()}
        </>
      )}
    </>
  );
}
