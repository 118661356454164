import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { getRegionalsWithCenters } from 'containers/AgroBRF/Announcements/service';
import { ThemeContext } from 'styled-components';
import { AnnouncementsContext } from 'shared/contexts';
import t from 'components/i18n';
import Modal from 'components/Forms/FormModal/Modal';
import Top from 'components/Top';
import Toaster from 'components/Toaster';
import {
  scrollToTop,
  getPermissionsById,
} from 'shared/utils/Helpers';
import AnnouncementsList from './AnnouncementsList';
import EditAnnouncements from './EditAnnouncements';
import { deleteItem } from './utils';
import { AnnouncementsStyled } from './Announcements.styled';
import { getFalResume } from '../FlockList/service';

export default function Announcements(props) {
  const [regionals, setRegionals] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const {
    user,
    history,
    hideHeader = false,
    hideListFilters = false,
    hideListPagination = false,
    announcementListCustomHeader,
    className,
  } = props;

  const context = useContext(AnnouncementsContext);
  const themeContext = useContext(ThemeContext);

  const { selected, removedId, editAnnouncement } = context;
  const {
    setSelected,
    setRemovedId,
    setEditAnnouncement,
  } = context.actions;

  const announcementsPermissions = getPermissionsById(
    user.allowRoutes,
    '9',
  );

  const getRegionals = useCallback(async () => {
    try {
      const response = await getRegionalsWithCenters();
      setRegionals(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getRegionals();

    return () => {
      setSelected({});
      setEditAnnouncement(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRegionals]);

  useEffect(() => {
    if (editAnnouncement) {
      scrollToTop(document.getElementById('main'));
    }
  }, [editAnnouncement]);

  const classNames = `announcements ${className}`;

  return (
    <div className={classNames}>
      {editAnnouncement ? (
        <EditAnnouncements
          regionals={regionals}
          announcement={selected}
          setSelected={setSelected}
          selected={selected}
          setEditAnnouncement={setEditAnnouncement}
          history={history}
          announcementsPermissions={announcementsPermissions}
          userToken={user.token}
        />
      ) : (
        <AnnouncementsStyled>
          {!hideHeader && (
            <Top>
              <h2>{t('menu.announcements.title')}</h2>
              {!editAnnouncement &&
                !!announcementsPermissions &&
                !!announcementsPermissions.create && (
                  <Button
                    className={
                      user.isNextUser ? 'tertiary' : 'primary'
                    }
                    onClick={() => {
                      setEditAnnouncement(true);
                      setSelected({});
                    }}
                  >
                    {t('announcements.button.new')}
                  </Button>
                )}
            </Top>
          )}

          <AnnouncementsList
            selected={selected}
            setSelected={setSelected}
            setOpenModal={setOpenModal}
            customHeader={announcementListCustomHeader}
            setEditAnnouncement={setEditAnnouncement}
            removedId={removedId}
            hideListFilters={hideListFilters}
            setRemovedId={setRemovedId}
            removedAnnouncement={removedId}
            colorPrimary={themeContext.styles.palette.colorPrimary}
            hidePagination={hideListPagination}
            {...props}
          />
          <Toaster
            className="toaster__notification"
            isVisible={removedId}
            color="success"
            duration={3}
            delay={0}
          >
            <span className="icon icon-check" />
            {t('announcements.delete.success')}
          </Toaster>

          <Modal
            warning
            opened={openModal}
            confirm={() => {
              deleteItem(selected.id, setRemovedId);
              setOpenModal(false);
            }}
            deny={() => setOpenModal(false)}
            onCloseModal={() => {
              setOpenModal(false);
            }}
          >
            <span className="icon icon-exclamation-triangle" />
            <h4>{t('announcements.delete.confirmation.title')}</h4>
            <p>{t('announcements.delete.confirmation.subtitle')}</p>
          </Modal>
        </AnnouncementsStyled>
      )}
    </div>
  );
}
