import React, { useEffect, useState, useMemo } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import Container from 'components/Container';
import LoadingHolder from 'components/LoadingHolder';
import Top from 'components/Top';
import { JustifiedListItem } from 'components/List';
import NoContentTab from 'components/NoContentTab';
import IconAndText from 'components/IconAndText';
import Breadcrumb from 'components/Breadcrumb';
import t from 'components/i18n';
import Card from 'components/Card';
import {
  VENDORS,
  VENDORS_TABLE,
  NEXT_FLOCK_LIST,
} from 'config/routes';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useChecklist } from './hooks';
// styles
import {
  DetailsContainer,
  DescriptionCard,
  Separator,
  FlexWrapper,
} from './Checklists.styled';

const getHistoryStatus = status => {
  if (status === 1) {
    return {
      icon: 'icon-check',
      text: t('checklist.details.integration.complete'),
      color: 'colorInfo',
    };
  }
  if (status === 2) {
    return {
      icon: '',
      text: t('checklist.details.integration.error'),
      color: 'colorDanger',
    };
  }
  return {
    icon: 'icon-clock',
    text: t('checklist.details.integration.incomplete'),
    color: 'colorInfo',
  };
};

export default function ChecklistsDetails({ history }) {
  const user = useSelector(state => state.user);
  const [loadedAdditional, setLoadedAdditional] = useState(false);
  const [loadedQuestions, setLoadedQuestions] = useState(false);
  const [
    { details, isLoading, isLoadingHistory },
    {
      onLoadAdditionalData,
      onFillChecklist,
      onLoadHistory,
      onShowHistory,
    },
  ] = useChecklist();

  useEffect(() => {
    if (!details) {
      return history.push(`/`);
    }

    if (
      details &&
      details.checklist &&
      details.checklist.canLoadHistory &&
      !isLoadingHistory &&
      !loadedAdditional &&
      loadedQuestions
    ) {
      onLoadHistory().then(() => setLoadedAdditional(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, loadedQuestions]);

  useEffect(() => {
    onLoadAdditionalData().then(() => setLoadedQuestions(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!details || !details.checklist || !details.vendor) {
    return null;
  }

  const { checklist, vendor, historyItems } = details;

  const noContent = () => (
    <NoContentTab icon="icon-tasks" hideButton>
      <p>
        {t('global.no-content')}
      </p>
    </NoContentTab>
  );

  return (
    <>
      <Breadcrumb
        history={history}
        path={[
          t('checklist.details.integrated'),
          vendor.vendorName,
          t('checklist.details.title'),
          checklist.indLiquidacaoDesc,
        ]}
        links={[
          user && user.type === 'E' ? VENDORS : VENDORS_TABLE,
          `${NEXT_FLOCK_LIST}/${vendor.vendorId}`,
          `${NEXT_FLOCK_LIST}/${vendor.vendorId}/new?tab=1`,
        ]}
      />

      <LoadingHolder isLoading={isLoading} />

      <Top>
        <IconAndText
          title={checklist.indLiquidacaoDesc}
          subtitle={checklist.codVago1 || checklist.codCheckList}
          icon="icon-tasks"
          iconBackGround="colorPrimary"
        />
      </Top>

      <DetailsContainer>
        <FlexWrapper>
          <DescriptionCard>
            <p className="title">{t('checklist.details.description')}</p>

            <LoadingHolder isLoading={isLoading} />

            {!isLoading &&
            !!checklist.questionGroupedByCategory &&
            !!Object.keys(checklist.questionGroupedByCategory)
              .length ? (
              <>
                  <div className="flex">
                  <span className="table-title">{t('checklist.details.steps')}</span>
                  <span className="table-title">{t('checklist.details.number-questions')}</span>
                </div>

                  {!!checklist.questionGroupedByCategory &&
                  Object.keys(
                    checklist.questionGroupedByCategory,
                  ).map(key => (
                    <JustifiedListItem key={key}>
                      <p>{key}</p>
                      <p>
                        {
                          checklist.questionGroupedByCategory[key]
                            .length
                        }
                      </p>
                    </JustifiedListItem>
                  ))}

                  <Button
                    onClick={onFillChecklist}
                    className="tertiary"
                  >
                    {t('checklist.details.button.fill-now')}
                  </Button>
                </>
            ) : (
              <NoContentTab hideButton>
                <p>{t('checklist.details.fill-unavailable')}</p>
              </NoContentTab>
            )}
          </DescriptionCard>

          <Container className="container" slim>
            <h4>Histórico</h4>
            <Separator style={{ marginBottom: 14 }} />

            <LoadingHolder isLoading={isLoadingHistory} />

            {historyItems &&
              !!historyItems.length &&
              historyItems.map(historyItem => {
                const { icon, text, color } = getHistoryStatus(
                  historyItem.integrationStatus,
                );
                return (
                  <Card
                    key={historyItem.id}
                    title={t("checklist.details.flock", { value: historyItem.flock})}
                    subtitle={moment(historyItem.createdDate).format(
                      'DD ̸̸  MM ̸̸  YYYY',
                    )}
                    icon="icon-edit"
                    iconBackGround="colorGrayLighter"
                    action={() => onShowHistory(historyItem)}
                    footerIcon={icon}
                    footerColor={color}
                    footerContent={text}
                  />
                );
              })}

            {!isLoadingHistory &&
              (!historyItems || !historyItems.length) &&
              noContent()}
          </Container>
        </FlexWrapper>
      </DetailsContainer>
    </>
  );
}
