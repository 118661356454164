import t from "components/i18n";

export function getFunctionalitiesList(functionalitiesList) {
  const readAndEditItems = [1, 12, 19];
  const readOnlyItems = [13, 14, 16];
  const disabledItems = [5, 6, 7, 8, 15, 17, 18, 19];
  const inactiveItems = [];

  const activeItems = functionalitiesList.filter(
    item => inactiveItems.indexOf(item.id) === -1,
  );

  if (functionalitiesList) {
    const newFunctionalities = activeItems.map(item => {
      if (readAndEditItems.indexOf(item.id) > -1) {
        return {
          ...item,
          label: t(item.label),
          checkboxList: [
            { name: 'create', value: item.create, disabled: true },
            { name: 'update', value: item.update },
            { name: 'delete', value: item.delete, disabled: true },
            { name: 'read', value: item.read },
          ],
        };
      }

      if (readOnlyItems.indexOf(item.id) > -1) {
        return {
          ...item,
          label: t(item.label),
          checkboxList: [
            { name: 'create', value: item.create, disabled: true },
            { name: 'update', value: item.update, disabled: true },
            { name: 'delete', value: item.delete, disabled: true },
            { name: 'read', value: item.read },
          ],
        };
      }

      if (disabledItems.indexOf(item.id) > -1) {
        return {
          ...item,
          label: t(item.label),
          checkboxList: [
            { name: 'create', value: item.create, disabled: true },
            { name: 'update', value: item.update, disabled: true },
            { name: 'delete', value: item.delete, disabled: true },
            { name: 'read', value: item.read, disabled: true  },
          ],
        };
      }

      return {
        ...item,
        label: t(item.label),
        checkboxList: [
          { name: 'create', value: item.create },
          { name: 'update', value: item.update },
          { name: 'delete', value: item.delete },
          { name: 'read', value: item.read },
        ],
      };
    });

    return newFunctionalities;
  }

  return [];
}
