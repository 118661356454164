import user from 'components/User/reducers';
import app from 'components/App/reducers';
import alert from 'components/Alert/reducers';
import modal from 'components/Modal/reducers';
import checklist from 'containers/NextBRF/Checklists/reducers';

export default {
  ...user,
  ...app,
  ...alert,
  ...modal,
  ...checklist,
};
