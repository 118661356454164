import React, { useEffect, useState, useMemo } from 'react';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Form } from 'components/Forms/Forms.styled';
import { InputField, DropdownField } from 'components/Forms/Fields';
import StatusChange from 'components/StatusChange';
import LoadingHolder from 'components/LoadingHolder';
import MultiSelect from 'components/MultiSelect';
import {
  Container,
  Separator,
  Subheading,
} from 'containers/NextBRF/UserManagement/UserManagement.styled';
import t from 'components/i18n';
import { profileKeyMapper, profileRegionMapper } from 'utils';
import { useCallback } from 'react';
import { regionalMapper } from 'utils/mapper';

export default function UserForm(props) {
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    profileList,
    resetForm,
    regionals = [],
    isLoading,
    values,
    errors,
    touched,
  } = props;

  const [unities] = useState(regionals.flatMap(item => item.centerList));
  const [regional] = useState(regionals);

  const animalTypeOptions = useMemo(
    () => [
      {
        label: t('form.next.userForm.animal.options.broiler'),
        value: 'broiler',
      },
      {
        label: t('form.next.userForm.animal.options.swine'),
        value: 'swine',
      },
      {
        label: t('form.next.userForm.animal.options.turkey'),
        value: 'turkey',
      },
    ],
    [],
  );

  useEffect(() => {
    if (!values.id) {
      setFieldValue('active', true);
    }
  }, [setFieldValue, values.id]);

  const mapAnimalCode = (type = '') => {
    return {
      ['all']: 'all',
      ['broiler']: 'Frango',
      ['turkey']: 'Peru',
      ['swine']: 'Suíno',
    }[type.toLowerCase()] || type;
  }

  const filteredProfileList = useMemo(
    () => profileList.filter(item => item.isEnabled),
    [profileList],
  );

  const getRegionalsOptions = useCallback(() => {
    const animalValue = (values['creationTypes'] || []).map(creationType => mapAnimalCode(creationType.value));

    const regionalOptions =
      (regional || regionals || [])
        .filter(rg => rg.centerList.some(cl => animalValue.includes(cl.animalType)))
        .map(rg => ({ ...rg, label: t(regionalMapper(rg.name)) }));

    return regionalOptions;
  }, [regional, regionals, values]);

  const getCenterListOptions = useCallback(() => {
    const animalValue = (values['creationTypes'] || []).map(creationType => mapAnimalCode(creationType.value));
    const regionalValue = (values['regionals'] || []).map(rg => rg.name);

    const regionalsFiltered = getRegionalsOptions().filter(rg => regionalValue.includes(rg.name));
    const centerListOptions = regionalsFiltered.map(rg => rg.centerList.filter(cl => animalValue.includes(cl.animalType)));

    return centerListOptions.flat();
  }, [regional, regionals, values]);

  return (
    <Container>
      <LoadingHolder
        message={t('global.loading')}
        isLoading={isLoading}
      />
      {!isLoading && (
        <Form autoComplete="off">
          {values.id && ( // don't show status change if it's a new user
            <StatusChange
              label="Status"
              statusBgColor={
                values.active ? 'colorPrimary' : 'colorGray'
              }
              status={values.active ? 'Ativo' : 'Inativo'}
              checked={values.active}
              onChange={() => setFieldValue('active', !values.active)}
            />
          )}

          <Subheading>{t('form.next.userForm.identify-user.title')}</Subheading>

          {/* Nome do usuário */}
          <InputField
            type="text"
            field="name"
            label={t('form.next.userForm.field.name.label')}
            placeholder={t('form.next.userForm.field.name.placeholder')}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />
          {/* \ Nome do usuário */}

          {/* Email do usuário */}
          <InputField
            type="text"
            field="mail"
            label={t('form.next.userForm.field.mail.label')}
            placeholder={t('form.next.userForm.field.mail.placeholder')}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />
          {/* \ Email do usuário */}

          {/* ID do usuário */}
          <InputField
            type="number"
            field="enrollment"
            label={t('form.next.userForm.field.enrollment.label')}
            placeholder={t('form.next.userForm.field.enrollment.placeholder')}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />
          {/* \ ID do usuário */}

          <Separator />

          <Subheading>{t('form.next.userForm.identify-profile.title')}</Subheading>

          {/* Perfil do usuário */}
          <DropdownField
            field="userCorporateProfile"
            label={t('form.next.userForm.field.userCorporateProfile.label')}
            placeholder={t('form.next.userForm.field.userCorporateProfile.placeholder')}
            onSelect={item => {
              setFieldValue('userCorporateProfile', item);
              setFieldValue('userCorporateProfileId', item.id);
            }}
            options={filteredProfileList}
            optionLabel="name"
            values={values}
            renderLabel={(item) => profileKeyMapper(item)}
            errors={errors}
            touched={touched}
          />
          {/* \ Perfil do usuário  */}

          <Separator />

          <Subheading>{t('form.next.userForm.identify-access.title')}</Subheading>

          {/* Tipo de criação */}
          <MultiSelect
            field="creationTypes"
            label={t('form.next.userForm.field.creationTypes.label')}
            placeholder={t('form.next.userForm.field.creationTypes.placeholder')}
            addItem={(key, value) => {
              setFieldValue(key, value);
              setFieldValue("regionals", "");
              setFieldValue("centerList", "");
            }}
            removeItemBy="label"
            options={animalTypeOptions || []}
            optionLabel="label"
            values={values}
            errors={errors}
            touched={touched}
          />
          {/* \ Tipo de criação  */}

          {/* Regional */}
          <MultiSelect
            field="regionals"
            label={t('form.next.userForm.field.regionals.label')}
            placeholder={t('form.next.userForm.field.regionals.placeholder')}
            addItem={(key, value) => {
              setFieldValue(key, value);
              setFieldValue("centerList", "");
            }}
            removeItemBy="id"
            disabled={
              values['creationTypes'] === ''
              || Array.isArray(values['creationTypes']) && !values['creationTypes'].length
            }
            options={getRegionalsOptions()}
            optionLabel="label"
            values={values}
            errors={errors}
            touched={touched}
          />
          {/* \ Regional */}

          {/* Unidade */}
          <MultiSelect
            field="centerList"
            label={t('form.next.userForm.field.centerList.label')}
            placeholder={t('form.next.userForm.field.centerList.placeholder')}
            addItem={setFieldValue}
            removeItemBy="code"
            options={getCenterListOptions()}
            disabled={
              values['regionals'] === ''
              || Array.isArray(values['regionals']) && !values['regionals'].length
            }
            optionLabel="name"
            values={values}
            errors={errors}
            touched={touched}
          />
          {/* \ Unidade */}

          <Separator />

          {/* \ Botões */}
          <BtnGroup>
            <Button
              key="sendtoConfirm"
              className="tertiary"
              onClick={() => {
                handleSubmit();
              }}
              disabled={!props.isValid || props.isUploading}
            >
              {t("global.button.save")}
            </Button>
            <Button
              key="clean"
              onClick={() => resetForm()}
              className="white"
            >
              {t("global.button.clear")}
            </Button>
          </BtnGroup>
          {/* \ Botões */}
        </Form>
      )}
    </Container>
  );
}
