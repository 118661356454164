import styled from 'styled-components';
import {
  colorGrayDarkest,
  colorGrayLight,
} from 'components/GlobalStyles/variables';

export const StyledTitleGroup = styled.div`
  position: relative;
  margin-bottom: 24px;

  h3 {
    color: ${colorGrayDarkest};
    font-size: 20px;
    line-height: 28px;
  }

  h4 {
    color: ${colorGrayLight};
    font-size: 16px;
    line-height: 24px;
  }
`;
