/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';
import Box from 'components/Container';
import NotificationBox from 'components/NotificationBox';
import Modal from 'components/Forms/FormModal/Modal';
import { putFalInformation } from 'containers/AgroBRF/FlockList/service';
import { colorInfo } from 'components/GlobalStyles/variables';
import {
  Container,
  Separator,
} from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import RecordItemsList from 'containers/AgroBRF/FlockList/Record/RecordItemsList';
import t from 'components/i18n';
import moment from 'moment';

const validDataFAL = fal => {
  if (
    !fal.isRequiredWeight &&
    !_.isEmpty(fal.averageWeight.weightValue) &&
    fal.averageWeight.weightValue.length > 0
  ) {
    fal.isRequiredWeight = true;
  }

  if (
    !fal.isRequiredPh &&
    !_.isEmpty(fal.ph.chlorine) &&
    !fal.isRequiredPh &&
    !_.isEmpty(fal.ph.ph)
  ) {
    fal.isRequiredPh = true;
  }

  return fal;
};

export default function RecordSummary(props) {
  const [isSubmitiing, setIsSubmitiing] = useState(false);
  const [formResponse, setFormResponse] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { data } = props;

  async function saveData(val) {
    setIsSubmitiing(true);
    try {
      const response = await putFalInformation(val);

      setFormResponse(response);

      if (!response) {
        throw Error(response);
      }
    } catch (error) {
      console.error(error);
    }
    setIsSubmitiing(false);
  }

  useEffect(() => {
    if (formResponse.data) {
      setSubmitted(true);
    }
  }, [formResponse]);

  return (
    <>
      <Breadcrumb history={props.history} path={[t('flocks.title')]} />
      <Top>
        <div className="top-content">
          <h2 className="top-content-heading">{t('agro.flock.recordSummary.head.title')}</h2>
        </div>
      </Top>

      <Container className="flock-container flock-container__record-summary">
        <div className="main-wrapper">
          <Box slim>
            <div className="flock-details">
              <header className="flock-details-header">
                <h2 className="flock-details-heading">
                  {t('agro.flock.recordSummary.head.subtitle', { value: moment(data.date).format('DD ̸̸  MM ̸̸  YYYY') })}
                </h2>
              </header>

              <Separator />

              <div className="record-list">
                <RecordItemsList data={data} waitingForSubmit />
              </div>

              {/* \ Sobra de ração */}
              {(!submitted || !props.isLocked) && (
                <NotificationBox
                  className="notification notification--info"
                  type="info"
                  bgColor={colorInfo}
                >
                  {t('agro.flock.recordSummary.updateDeadlines')}
                </NotificationBox>
              )}

              <br />

              {props.isEditable ? (
                <Row>
                  <Col sm={6} />
                  <Col sm={6}>
                    {/* \ Botões */}
                    {submitted ? (
                      <BtnGroup>
                        <Button
                          key="clean"
                          onClick={() => props.backToEdit()}
                          disabled={isSubmitiing}
                        >
                          {t("global.button.edit")}
                        </Button>
                      </BtnGroup>
                    ) : (
                      <BtnGroup>
                        <Button
                          key="submitForm"
                          onClick={() => saveData(validDataFAL(data))}
                          disabled={isSubmitiing}
                        >
                          {t("global.button.send")}
                        </Button>
                        <Button
                          key="clean"
                          className="white"
                          onClick={() => props.backToEdit()}
                          disabled={isSubmitiing}
                        >
                          {t('global.button.back')}
                        </Button>
                      </BtnGroup>
                    )}
                    {/* \ Botões */}
                  </Col>
                </Row>
              ) : (
                <BtnGroup>
                  <Button
                    key="clean"
                    onClick={() => props.backToList()}
                  >
                    {t('global.button.back')}
                  </Button>
                </BtnGroup>
              )}
            </div>
          </Box>
        </div>
      </Container>

      {/* On submit modal */}
      <Modal
        noButtons
        opened={formResponse}
        warning={formResponse && !formResponse.success}
        onCloseModal={() => {
          setFormResponse(false);
          props.backToList();
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              formResponse.success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>
            {formResponse.success ? t('global.validation.success') : t('global.validation.error')}
          </h4>
        </div>

        <p>
          {formResponse.success
            ? t('agro.flock.recordSummary.update.success')
            : t('agro.flock.recordSummary.update.error')
            }
        </p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
