import React from 'react';
import moment from 'moment';

import { Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import * as routes from 'config/routes';

import Image from 'components/Image';
import CardWrapper from 'components/CardWrapper';

import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { Item, Separator } from './VisitStyled.styled';
import t from 'components/i18n';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';

export default function OrientationVisualization(props) {
  const {
    logbookItemsOrientations = {},
    visitDate,
    user,
    id,
    history
  } = props;

  if (!logbookItemsOrientations) {
    return null;
  }

  return (
    <CardWrapper style={{ maxWidth: '60%', minWidth: "600px" }}>
      <Item className="flex">
        <span className="label">{t('next.visitList.orientationVisualization.visitReason')}</span>{' '}
        <span>{t('next.visitList.orientationVisualization.houses')}</span>
      </Item>

      <Item className="flex">
        <span className="label">{t('next.visitList.orientationVisualization.dateTime')}</span>{' '}
        <div
          className="flex"
          style={{ flexDirection: 'column', textAlign: 'right' }}
        >
          <span className="margin">
            {moment(logbookItemsOrientations.visitDate || visitDate).format(
              'DD ̸̸  MM ̸̸  YYYY',
            )}
          </span>
          <span className="small">
            {moment(logbookItemsOrientations.visitDate || visitDate).format(
              'HH:mm',
            )}
          </span>
        </div>
      </Item>

      <Item className="flex">
        <div>
          <span className="label">{t('next.visitList.orientationVisualization.orientation')}</span>
          <br />
          <br />
          <div>
            <p>{logbookItemsOrientations.orientation}</p>
          </div>
        </div>
      </Item>

      {(logbookItemsOrientations.attachments || []).length > 0 && (
        <Item noBorder>
          <span className="label">{t('next.visitList.orientationVisualization.attachments')}</span>
          <br />
          <br />
          <Row>
            {logbookItemsOrientations.attachments.map(
              (item, index) => (
                <Col key={index} sm={4}>
                  <a
                    href={`${API_URL}/Blob/Download?fileName=${item}&blobContainerName=logbookattachment&token=${user.token}&client_id=${KONG_CLIENT_ID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      api={API_URL}
                      item={item}
                      blobContainer="logbookattachment"
                      token={user.token}
                    />
                  </a>
                </Col>
              ),
            )}
          </Row>
        </Item>
      )}
      
      <Separator />

      <BtnGroup>
        <Button
          key="clean"
          className="tertiary"
          onClick={() => history.push(`${routes.VISIT_CIRCUIT}/${id}`)}
        >
          {t('global.button.back')}
        </Button>
      </BtnGroup>
    </CardWrapper>
  );
}
