// @flow

import React, { useState, createContext } from 'react';
import {
  Container,
  MenuHolder,
  Bkdrop,
  Btn,
} from './Dropdown.styled';

type TMenuContext = {
  clickOutsideToClose: Boolean,
  isOpen: Boolean,
  toggleMenu: () => null,
};

const DropdownContext = createContext<TMenuContext>({
  clickOutsideToClose: false,
  isOpen: false,
  toggleMenu: () => null,
});

function Backdrop({ show = false, onClick }) {
  return <Bkdrop onClick={onClick} show={show} />;
}

export function Trigger({ children }) {
  return (
    <DropdownContext.Consumer>
      {({ toggleMenu, isOpen }) => (
        <Btn type="button" onClick={() => toggleMenu()}>
          {children}
        </Btn>
      )}
    </DropdownContext.Consumer>
  );
}

export function Menu({ children }) {
  return (
    <DropdownContext.Consumer>
      {({ toggleMenu, isOpen, clickOutsideToClose }) => {
        const klasses = [];

        if (isOpen) {
          klasses.push('is-open');
        } else {
          klasses.length = 0;
        }

        return (
          <>
            <MenuHolder className={klasses.join(' ')}>
              {children}
            </MenuHolder>

            {clickOutsideToClose && (
              <Backdrop
                show={isOpen}
                onClick={() => toggleMenu(true)}
              />
            )}
          </>
        );
      }}
    </DropdownContext.Consumer>
  );
}

function getMenuIsOpen(props) {
  if ('menuIsOpen' in props) {
    if (props.menuIsOpen) {
      return true;
    }
    return false;
  }

  return false;
}

function getClickOutsideToClose(props) {
  if ('clickOutsideToClose' in props) {
    if (props.clickOutsideToClose) {
      return true;
    }
    return false;
  }

  return false;
}

type DropdownProps = {
  menuIsOpen: boolean,
  clickOutsideToClose?: boolean,
};

export function Dropdown(props: DropdownProps) {
  const { menuIsOpen, children } = props;
  const [isOpen, setIsOpen] = useState(
    getMenuIsOpen({
      menuIsOpen,
      clickOutsideToClose: props.clickOutsideToClose,
    }) || false,
  );
  const clickOutsideToClose = getClickOutsideToClose({
    menuIsOpen,
    clickOutsideToClose: props.clickOutsideToClose,
  });
  const state = {
    isOpen,
    clickOutsideToClose,
    toggleMenu: val => {
      if (val !== undefined) {
        setIsOpen(val);
      }

      setIsOpen(!isOpen);
    },
  };

  return (
    <DropdownContext.Provider value={state}>
      <Container>{children}</Container>
    </DropdownContext.Provider>
  );
}

Dropdown.defaultProps = {
  clickOutsideToClose: false,
};
