import { lighten } from 'shared/utils/colors';
import rgba from 'polished/lib/color/rgba';
import {
  DEFAULT_SIZE,
  colorClear,
  colorPrimary,
  colorTertiary,
  colorHighlight,
  colorGrayUltraLight,
  colorGrayLight,
  colorGrayLighter,
  colorGrayLightest,
  colorGrayDarker,
  colorGrayDarkest,
  colorDanger,
  size,
  fontFamilyText,
  fontFamilyTextBold,
} from './variables';

export default {
  'buttons.button': `
    /** Default Styles */     
    border-color: ${colorGrayDarker} !important;
    border-radius: ${size} !important;
    color: ${colorClear} !important;
    background-color: ${colorGrayDarker} !important;
    font-family: ${fontFamilyTextBold};
    font-size: 13px !important;
    padding: 0 2em !important;
    min-width: auto !important;
    line-height: 40px !important;

    &:hover {
      background-color: ${lighten(colorGrayDarker)} !important;
    }

    &:focus,
    &[class*="is-active"],
    &:active,
    &[class*="is-focus"],
    &:focus {
      background-color: ${lighten(colorGrayDarker)} !important;
      border-color: ${colorHighlight} !important;
      box-shadow: 0px 4px 8px rgba(37, 48, 71, 0.16) !important;
      color: ${colorClear} !important;
    }
    /** end: Default Styles */ 

    &[class*="primary"] {
      background-color: ${colorPrimary} !important;
      border-color: ${colorPrimary} !important;
      color: ${colorClear} !important;

      &:hover:not(:disabled),
      &[class*="is-hovered"] {
        color: ${colorClear} !important;
        background-color: ${lighten(colorPrimary)} !important;
      }

      &[class*="is-active"],
      &:active:not(:disabled),
      &[class*="is-focus"],
      &:focus {
        background-color: ${colorPrimary} !important;
        border-color: ${colorHighlight} !important;
        box-shadow: 0px 4px 8px rgba(37, 48, 71, 0.16) !important;
        color: ${colorClear} !important;
      }
    }

    &[class*="tertiary"] {
      background-color: ${colorTertiary} !important;
      border-color: ${colorTertiary} !important;
      color: ${colorClear} !important;

      &:hover:not(:disabled),
      &[class*="is-hovered"] {
        color: ${colorClear} !important;
        background-color: ${lighten(colorTertiary)} !important;
      }

      &[class*="is-active"],
      &:active:not(:disabled),
      &[class*="is-focus"],
      &:focus {
        background-color: ${colorTertiary} !important;
        border-color: ${colorHighlight} !important;
        box-shadow: 0px 4px 8px rgba(37, 48, 71, 0.16) !important;
        color: ${colorClear} !important;
      }
    }

    &:disabled,
    &[class*='is-disabled'] {
      background-color: ${colorGrayLightest} !important;
      color: ${colorGrayLighter} !important;
      border-color: transparent !important;
    }

    &.white {
      background-color: ${colorClear} !important;
      border-color: ${colorGrayDarkest} !important;
      color: ${colorGrayDarkest} !important;

      &.whiteBorder {
        border-color: ${colorClear} !important;

        &:active, &:focus {
          box-shadow: none!important;
        }
      }

      &:hover {
        color: ${colorGrayLight}!important;
      }
    }

    &.transparent {
      background-color: transparent!important;
      border-color: ${colorClear} !important;
      color: ${colorClear} !important;

      &:hover {
        color: ${colorGrayLightest}!important;
        border-color: ${colorClear} !important;
      }
    }
  `,

  'forms.text_label': `
    color: ${colorGrayLighter}!important; 
    font-size: 14px;
    font-weight: normal !important;

    .has-error & {
      color: ${colorDanger} !important;
      font-weight: normal;
    }
  `,

  'forms.input': `
    border-radius: ${size} !important;
    min-height: 48px !important;
    max-height: 48px !important;
    border: 1px solid ${colorGrayLighter} !important;
    color: ${colorGrayLighter} !important;

    ::placeholder {
      color: ${colorGrayLighter} !important;
    }

    &[class*="input--bare"] {
      min-height: 1.9em !important;
    }

    &.is-focused,
    &:focus {
      box-shadow: none !important;
    }

    &[class*="--error"] {
      border-color: ${colorDanger} !important;
      // margin-top: 8px !important;
    }

    &[class*="input--sm"]:not([class*="input--select"]) {
      min-height: 40px !important;

      &:before {
        height: 48px;
      }
    } 

    &[class*="input--select"] {
      min-height: 30px !important;
      overflow:hidden;
    }
  `,

  'dropdowns.select_view': `   
    padding-top: 0!important;
    padding-bottom: 0!important;
    line-height: 44px!important;

    &[class*="is-focused"] {
      input:focus {
        border-color: ${colorClear}!important;
      }
    }    
  `,

  'dropdowns.menu': `    
    [class*="StyledMaxHeightWrapper"] {
      max-height: 210px;
    }
  `,

  'dropdowns.item': `    
    p {
      margin: 0;
      font-size: 14px;
    }
  `,

  'forms.faux_input': `
    border: 1px solid ${colorGrayLighter}!important;  `,

  'textfields.text_group': `
    position: relative;
    margin-bottom: 12px !important;
  `,

  'forms.text_message': `
    font-weight: normal !important;
    font-family: ${fontFamilyTextBold};
    position: relative;
    left: 0;
    right: 0;
    display: block !important;
    background: 0 none !important;
    padding-left: 0 !important;
    margin-top: ${DEFAULT_SIZE / 2}px !important;
    margin-left: ${DEFAULT_SIZE * 3}px !important;
    margin-right: ${DEFAULT_SIZE * 2}px !important;
    margin-bottom: ${DEFAULT_SIZE * 2}px !important;
    height: 20px !important;
    line-height: 20px !important;

    &:after {
      font-family: AgroPack;
      font-size: 20px;
    }

    &[class*="--error"] {
      &:after {
        position: absolute;
        content: "\\e91e";
        left: -26px;
        top: 0;
      }
    }
  `,

  'forms.media_input': `
    border: 0 none !important;
    border-radius: 0 !important;
    font-family: ${fontFamilyText} !important;
    
    &:focus {
      outline: 0 none !important;
    }
  `,

  'select.select_view': `
    cursor: pointer !important;
    min-height: inherit !important;    

    &:before {
      top: 5px !important;
      right: 3px !important;
      font-family: AgroPack;
      background-image: none !important;
      content: '\\e90f' !important;
      color: ${colorGrayDarkest} !important;
      font-size: 24px !important;
      width: 24px !important;
      height: 24px !important;  
    }

    &[class*="is-open"] {
      &:before {
        top: 2px !important;
        transform: rotate(180deg) translateY(1px);        
      }
    }

    &[class*="is-focused"], 
    &:focus {
      outline: 0 none !important;
      box-shadow: none !important;
    }
  `,

  'select.item': `
    position: relative;
    border: 0 none !important;
    font-family: ${fontFamilyText} !important;
    padding-left: 40px !important;
    line-height: 25px !important;
    
    &:before {
      top: 5px !important;
      left: 10px !important;
      font-family: AgroPack;
      background-image: none !important;
      content: '\\e90e' !important;
      color: ${colorGrayDarkest} !important;
      font-size: 24px !important;
    }
  `,

  'modals.backdrop': `
    z-index: 999!important;

    .upload-modal {
      &::after {
        left: 20px;
      }
    }
  `,

  'modals.modal_view': `
    position: relative;
    border-radius: ${DEFAULT_SIZE}px !important;
    border: 0 none !important;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: ${DEFAULT_SIZE * 5}px;
      width: 50px;
      height: 3px;
      background-color: ${colorPrimary};
    }

    &.modal__policy {
      max-width: 800px;
      max-height: calc(100vh - 180px)!important;
      width: calc(100% - 40px);
      color: ${colorGrayDarkest};
      
      button {
        width: 230px;
      }

      .header {
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        padding: 12px 20px;
        background-color: ${colorGrayDarkest};
        color: ${colorClear};
      }

      .body {
        padding: 0 30px 20px 10px;

        ol {
          list-style: none/
          counter-reset: counter;
          >li {      
            p, h4, li {
              margin-left: -10px;
              text-align: justify;
            }

            ul {
              margin: 6px 8px 16px;
            }
            
            h4 {
              :first-of-type {
                margin-left: 0;
              }
            }
          }
        }
      }

      .footer {
        justify-content: space-between;
        padding: 12px 12.5px;
        background-color: ${rgba(colorGrayDarkest, 0.16)};
        border-top: 1px solid ${rgba(colorGrayDarkest, 0.12)};
        
        p {
          font-family: ${fontFamilyTextBold};
          margin: 0;
          font-size: 12px;
          line-height: 16px;
          margin-right: 16px;
        }
      }

      &:after {
        display: none;
      }
    }
  `,
  'modals.header': `
    font-size: 20px !important;
    font-weight: normal !important;
   
  `,

  // tables
  'tables.table': `
    margin: 0 -16px;
    width: calc(100% + 32px)!important;    
    font-size: 14px;
  `,

  'tables.head': `
    background-color: ${colorGrayUltraLight};
    `,

  'tables.header_row': `
    border-bottom-color: transparent!important;
  `,

  'tables.header_cell': `    
    color: ${colorGrayLight}
    font-size: 14px;
    justify-content: center;
    font-weight: normal;

    &.left {
      justify-content: flex-start;
    }
  `,

  'tables.cell': `
    color: ${colorGrayDarkest};
    align-items: center;
    justify-content: center;
    padding: 10px 16px!important;   

    &.justify {
      justify-content: space-between;
    }
  `,

  // pagination
  'pagination.page': `
    color: ${colorGrayDarkest}!important;    

    &[class*="is-current"] {
      background-color : ${colorGrayDarkest}!important;
      color: ${colorClear}!important;
      font-weight: normal!important;
  `,
};
