import styled from 'styled-components';
import {
  colorClear,
  colorPrimary,
  colorGrayDarker,
  colorGrayDarkest,
  breakpointTablet2,
} from 'components/GlobalStyles/variables';
import { HamburgerMenu } from 'components/Hamburger/Hamburger.styled';
import { Aside } from 'components/Sidebar/Sidebar.styled';

export const HeaderNavStyled = styled.div`
  ul {
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    padding: 0;

    li {      
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      margin-left: 20px;
      letter-spacing: 0.75px;
      text-align: center;

      @media (max-width: ${breakpointTablet2}px) {
        margin-left: 10px;
      }

      button {
        color: ${colorClear};
        transition: 0.2s background-color ease-in, 0.2s color ease-in;
        background-color: transparent;
        
        cursor: pointer;        

        &:hover {
          color: ${colorPrimary};
        }
      }

      button {
        border: none;
        transition: 0.2s background-color ease-in, 0.2s color ease-in;
        &.btn {
          display: block;
          width: 96px;
          line-height: 40px;
          height: 40px;
          color: ${colorClear};
          background-color: ${colorPrimary};
          border-radius: 8px;
          cursor: pointer;
          &:hover {
            background-color: ${colorGrayDarkest};
            color: ${colorClear};
          }  
          
          @media (max-width: ${breakpointTablet2}px) {
            width: 78px;
          }
        }
      }

      ${HamburgerMenu} {
        background-color: ${colorGrayDarkest};
        width: 40px;
        height: 40px;
        border-radius: 8px;
        padding: 14px 10px;

        .hamburger-item {
          position: relative;
          background-color: ${colorClear};
          width: 20px;    
               
        }
        
        &.is-open {
          .hamburger-item {            
      
            &.first {
              bottom: -4px;              
            }    
          }
      }
    }
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .item-inner {
    width: 100%;
  }

  ${Aside} {
    background-color: ${colorGrayDarker};
    z-index: 80;
    .item-container {
      background-color: ${colorGrayDarker};
      padding-left: 72px;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 64px;
      background-color: ${colorGrayDarkest};
    }
  }
`;
