import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import {
  getCadecs,
  getRegionalsWithCenters,
} from 'containers/AgroBRF/Cadec/service';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import { ThemeContext } from 'styled-components';
import Pagination from 'components/Pagination';
import Box from 'components/Container';
import { ModalFilter } from 'components/Filter';
import removeDuplicatesBasedOnProperties from 'shared/utils/Helpers/removeDuplicatesBasedOnProperties';
import {
  Close,
  RoundedItem,
} from 'components/MultiSelect/Items.styled';
import CadecItem from './CadecItem';
import { Separator } from '../Cadec.styled';
import t from 'components/i18n';

const animalTypes = ['Frango', 'Suíno', 'Peru'];

const segments = [
  'Frango de Corte',
  'Frango SPO (Sistema Produtor de Ovos – Cria e Recria)',
  'SPD (Sistema Produtor de Desmamados)',
  'SVC (Sistema Vertical de Crechário)',
  'SVT (Sistema Vertical de Terminação)',
  'SIP (Sistema Iniciador de Perus)',
  'Peru SPO (Sistema Produtor de Ovos)',
  'STP (Sistema Terminador de Perus)',
];

const FilterBullet = ({ item, onClear }) =>
  item ? (
    <RoundedItem>
      <p>{item}</p>
      <Close onClick={onClear}>
        <span className="icon icon-close-circle" />
      </Close>
    </RoundedItem>
  ) : null;

export default function CadecList(props) {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [typeFilter, setTypeFilter] = useState(null);
  const [segmentFilter, setSegmentFilter] = useState(null);
  const [centerFilter, setCenterFilter] = useState(null);
  const [typeList] = useState(animalTypes);
  const [segmentList] = useState(segments);
  const [centerListComplete, setCenterListComplete] = useState([]);
  const hasData = data && data.length > 0;
  const themeContext = useContext(ThemeContext);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const params = {
        PageNumber: currentPage,
        PageSize: 20,
        GrowerType: typeFilter,
        GrowerSegment: segmentFilter,
        CenterCode: centerFilter
          ? centerFilter.split('-')[0].trim()
          : '',
      };

      const response = await getCadecs(params);

      setTotalPages(response.totalPages);
      setData(response.cadecs);
    } catch (e) {
      console.error('Error on getCadecDocuments', e);
    }

    setIsLoading(false);
  }, [centerFilter, currentPage, segmentFilter, typeFilter]);

  const clearAllFilters = useMemo(() => {
    setCenterFilter(null);
    setTypeFilter(null);
    setSegmentFilter(null);
  }, []);

  useEffect(() => {
    async function getRegionals() {
      try {
        const response = await getRegionalsWithCenters();
        const regionals = response.data;

        setCenterListComplete(
          removeDuplicatesBasedOnProperties(
            regionals.map(regional => regional.centerList).flat(),
            ['code'],
          ),
        );
      } catch (error) {
        console.error(error);
      }
    }

    getRegionals();
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    props.updateItems,
    currentPage,
    typeFilter,
    segmentFilter,
    centerFilter,
    fetchData,
  ]);

  const centerList = useMemo(() => {
    return centerListComplete
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map(center =>
        `${center.code} - ${center.name.split('-')[0]}`.trim(),
      );
  }, [centerListComplete]);

  return (
    <>
      <Box hideDetail slim>
        <LoadingHolder isLoading={isLoading} />

        {!isLoading && hasData && (
          <div className="cadec-list">
            <ModalFilter
              theme={{
                bgButton: themeContext.styles.palette.colorGrayDarker,
                text: themeContext.styles.palette.colorClear,
              }}
              title={t('cadec.filters.title')}
              buttonLabel={t('cadec.filters.title')}
              list={[
                {
                  label: 'center',
                  arrayList: centerList,
                  action: setCenterFilter,
                  placeholder: t('cadec.filters.options.producer')
                },
                {
                  label: 'typeName',
                  arrayList: typeList,
                  action: setTypeFilter,
                  placeholder: t('cadec.filters.options.animalType')
                },
                {
                  label: 'segmentList',
                  arrayList: segmentList,
                  action: setSegmentFilter,
                  placeholder: t('cadec.filters.options.segment')
                },
              ]}
              clearFilters={clearAllFilters}
            />

            <FilterBullet
              item={typeFilter}
              onClear={() => setTypeFilter(null)}
            />

            <FilterBullet
              item={segmentFilter}
              onClear={() => setSegmentFilter(null)}
            />

            <FilterBullet
              item={centerFilter}
              onClear={() => setCenterFilter(null)}
            />

            <Separator />
            {data.map((item, index) => (
              <CadecItem
                key={item.id}
                cadec={item}
                goTo={props.goTo}
                setDisplayModal={() => props.setDisplayModal()}
                cadecPermissions={props.cadecPermissions}
                iconColor={themeContext.styles.palette.colorPrimary}
                whiteBgIcon
              />
            ))}
          </div>
        )}

        {!isLoading && !data.length && (
          <NoContentTab icon="icon-users">
            <p>{t("cadec.filters.no-result")}</p>
          </NoContentTab>
        )}
      </Box>
      {data && totalPages > 0 && (
        <Pagination
          totalPages={totalPages}
          pagePadding={2}
          currentPage={currentPage}
          onChangePage={({ e }) => setCurrentPage(e)}
        />
      )}
    </>
  );
}
