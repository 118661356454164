import React, { useState, useEffect, useMemo } from 'react';
import Top from 'components/Top';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import t from 'components/i18n';
import Terms from './Terms';
import Policy from './Policy';
import Insurance from './Insurance';

export default function Documentation({
  match: { params },
  history,
  responsive,
}) {
  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    {
      label: t('menu.profile.termsOfUse.title'),
      key: 1,
      Component: Terms,
    },
    {
      label: t('menu.profile.privacyPolicy.title'),
      key: 2,
      Component: Policy,
    },
    {
      label: t(
        'menu.profile.sectionDocumentation.buttonEnsurancePolicy',
      ),
      key: 3,
      Component: Insurance,
    },
  ];

  const renderTabs = useMemo(
    () =>
      tabs.map(item => (
        <TabHeaderHolder
          key={item.key}
          onClick={() => setActiveTab(item.key)}
          className={activeTab === item.key ? 'active' : ''}
        >
          <div className="label">{item.label}</div>
          <div className="bar" />
        </TabHeaderHolder>
      )),
    [activeTab, tabs],
  );

  const renderContainers = useMemo(
    () =>
      tabs.map(
        item =>
          item.key === activeTab && (
            <item.Component
              responsive={responsive}
            />
          ),
      ),
    [activeTab, responsive, tabs],
  );

  return (
    <>
      <Top>
        <h2>{t('documentation.title')}</h2>
      </Top>
      <Header>{renderTabs}</Header>
      {renderContainers}
    </>
  );
}
