import React, { useState, useEffect } from 'react';
import t from 'components/i18n';
import { OpenedResumeCard } from 'components/ResumeCard';

// service
import { getRemunerationResume } from './service';
import { useMemo } from 'react';

export default function Resume(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetch(flockId) {
      try {
        const response = await getRemunerationResume(flockId);
        setData(response);
      } catch (e) {
        console.error('Error on get payment resume', e);
      }
    }

    fetch(props.flockId);
  }, [props.flockId]);

  const content = useMemo(() => {
    if (!data) return [];

    return [
      {
        label: t(
          'flocks.flocksDetail.sectionRemuneration.averageWeight.label',
        ),
        info: t(
          'flocks.flocksDetail.sectionRemuneration.averageWeight.quantity',
          { quantity: data.averageWeight },
        ),
      },
      {
        label: t(
          'flocks.flocksDetail.sectionRemuneration.feedConversion.label',
        ),
        info: data.foodConversion,
      },
      {
        label: t(
          'flocks.flocksDetail.sectionRemuneration.mortalityTotal.label',
        ),
        info: data.mortality,
      },
    ];
  }, [data]);

  return (
    data && (
      <OpenedResumeCard
        color="colorDanger"
        title={t(
          'flocks.flocksDetail.sectionRemuneration.netIncome.label',
        )}
        highlight={data.flockResult}
        content={content}
        displayHighlight={props.user.profile === 'Administrador'}
      />
    )
  );
}
