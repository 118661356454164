/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import validate from 'components/Forms/Fields/validate';
import t from 'components/i18n';
import { Formik } from 'formik';
import {
  VENDORS,
  NEXT_OPEN_RECORD,
  NEXT_FLOCK_LIST,
  NEXT_HOUSE_LIST,
  NEXT_FLOCK_DETAILS,
} from 'config/routes';
import {
  scrollToTop,
  getPermissionsById,
} from 'shared/utils/Helpers';
import Breadcrumb from 'components/Breadcrumb';
import Badge from 'components/Badge';
import NoContentTab from 'components/NoContentTab';
import Box from 'components/Container';
import Top from 'components/Top';
import WeekCalendar from 'components/WeekCalendar';
import { Button } from '@zendeskgarden/react-buttons';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import RecordSummary from 'containers/NextBRF/NextFlock/NextRecord/RecordSummary';
import RecordItemsList from 'containers/NextBRF/NextFlock/NextRecord/RecordItemsList';
import RecordTop from 'containers/NextBRF/NextFlock/NextRecord/RecordTop';
import {
  Container,
  BadgeWrapper,
  FormTop,
} from 'containers/NextBRF/NextFlock/NextRecord/NextRecord.styled';
import FalForm from 'containers/NextBRF/Forms/FalForm';
import {
  stringToDate,
  formatDateString,
} from 'shared/utils/Helpers/handleDate';
import {
  initialValues,
  formData,
  getValidateSchema,
} from 'containers/NextBRF/NextFlock/NextRecord/helpers';
import {
  getFalInformation,
  getDeceasedTypes,
  getFeedTypes,
  getFalVendor,
  getAge,
} from 'containers/NextBRF/NextFlock/NextRecord/service';

export default function NextOpenRecord(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [week, setWeek] = useState(null);
  const [deceasedTypes, setDeceasedTypes] = useState([]);
  const [feedTypes, setFeedTypes] = useState([]);
  const [loadingVendors, setLoadingVendors] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [falAge, setFalAge] = useState([]);
  const [isSummary, setIsSummary] = useState(false);
  const [noCollect, setNoCollect] = useState(false);
  const [summaryContent, setSummaryContent] = useState({});
  const [displayForm, setDisplayForm] = useState(false);

  const badgeProps = {};
  if (data.dailyStatus) {
    switch (data.dailyStatus.toLowerCase()) {
      case 'pendente':
        badgeProps.info = true;
        break;
      default:
        badgeProps.success = true;
    }
  }

  const hasData = Object.keys(data).length > 0;

  const recordPermissions = getPermissionsById(
    props.user.allowRoutes,
    '12',
  );

  const userCanEdit = recordPermissions
    ? recordPermissions.edit
    : true;

  const editable =
    userCanEdit &&
    (props.user.profile !== 'Assistente'
      ? data.isEditable || data.pendingSubmit
      : false);

  const previousSubmitted =
    (data &&
      data.mortality &&
      data.mortality.mortalityOnArrival !== null &&
      Number(data.mortality.mortalityOnArrival) > -1) ||
    (data && data.waterConsume && data.waterConsume.waterConsumption);

  async function fetchData(falId, falDate) {
    setIsLoading(true);
    try {
      const response = await getFalInformation(falId, falDate);
      setData(response);
    } catch (e) {
      console.error('Error on get fal information', e);
    }
    setIsLoading(false);
  }

  async function getFormDeceasedTypes() {
    try {
      const response = await getDeceasedTypes();
      setDeceasedTypes(response);
    } catch (e) {
      console.error('Error on get deceased types', e);
    }
  }

  async function getFormFeedTypes(falId) {
    try {
      const response = await getFeedTypes(falId);
      setFeedTypes(response);
    } catch (e) {
      console.error('Error on get feed types', e);
    }
  }

  async function getFormVendors(search) {
    setLoadingVendors(true);
    try {
      const response = await getFalVendor(search);
      setVendors(response);
      setLoadingVendors(false);
    } catch (e) {
      console.error('Error on get vendors', e);
      setLoadingVendors(false);
    }
  }

  function submitForm(values) {
    setSummaryContent(formData(values, data));
    setIsSummary(true);
  }

  useEffect(() => {
    if (props.match.params.date === undefined) {
      fetchData(props.match.params.id, null);
    } else {
      fetchData(props.match.params.id, props.match.params.date);
    }

    getFormFeedTypes(props.match.params.id);

    getFormDeceasedTypes();
  }, []);

  async function getFalAge(falId, date) {
    setLoadingVendors(true);
    try {
      const response = await getAge(falId, formatDateString(date));
      setFalAge(response);
    } catch (e) {
      console.error('Error on get age', e);
    }
  }

  useEffect(() => {
    if (hasData) {
      getFalAge(props.match.params.id, data.date);
      setWeek(data.week);
      setSummaryContent({});
    }

    if (data.isFirstDay && previousSubmitted) {
      setNoCollect(false);
    }

    if (editable) {
      if (previousSubmitted) {
        setDisplayForm(false);
        setNoCollect(false);
      } else {
        setDisplayForm(false);
        setNoCollect(true);
      }
    } else {
      setDisplayForm(false);
    }
  }, [data]);

  useEffect(() => {
    scrollToTop(document.getElementById('main'));
  }, [isSummary, displayForm]);

  return isSummary ? (
    <RecordSummary
      data={summaryContent}
      isEditable={editable}
      isLocked={data.status === 'Concluído'}
      backToEdit={() => setIsSummary(false)}
      backToList={() =>
        props.history.push(`${NEXT_OPEN_RECORD}/${data.falId}`)
      }
      {...props}
    />
  ) : (
    <>
      {hasData && (
        <Breadcrumb
          history={props.history}
          backTo={() =>
            props.history.push(`${NEXT_HOUSE_LIST}/${data.flockId}`)
          }
          path={[
            t('next.flock.openRecord.bc.growers'),
            data.vendorName || '',
            t("next.flock.openRecord.bc.flock", { value: data.flock || '' }),
            t('next.flock.openRecord.bc.fal'),
            t('next.flock.openRecord.bc.house', { value: data.house })
          ]}
          links={[
            `${VENDORS}`,
            `${NEXT_FLOCK_LIST}/${data.vendorId}/new`,
            `${NEXT_FLOCK_DETAILS}/${data.flockId}`,
            `${NEXT_HOUSE_LIST}/${data.flockId}`,
          ]}
        />
      )}
      <Top>
        <div className="top-content">
          <h2 className="top-content-heading">
            {!isLoading &&
              (hasData
                ? t('next.flock.openRecord.house.title', { value: data.house })
                : t('next.flock.openRecord.no-house.title')
              )}
          </h2>
        </div>
      </Top>

      <Container className="flock-container flock-container__next-open-record">
        <div className="main-wrapper">
          <Box slim>
            {hasData && (
              <>
                <WeekCalendar
                  startDate={stringToDate(data.startDate)}
                  finishDate={stringToDate(data.finishDate)}
                  date={stringToDate(data.date)}
                  id={props.match.params.id}
                  onSelectDate={e =>
                    fetchData(props.match.params.id, e)
                  }
                  history={props.history}
                  setNextWeek={() => setWeek(week + 1)}
                  setPrevWeek={() =>
                    setWeek(week === 1 ? 1 : week - 1)
                  }
                  weekNum={week}
                />
                <div className="flock-details">
                  <RecordTop
                    textContent={[
                      {
                        label: t('next.flock.openRecord.details.calendar.filter.week'),
                        content: week,
                      },
                      {
                        label: t('next.flock.openRecord.details.calendar.filter.age'),
                        content: data.age,
                      },
                      {
                        label: t('next.flock.openRecord.details.calendar.filter.accumulatedMortality'),
                        content: data.accumulatedMortality,
                      },
                    ]}
                  />

                  {editable && !noCollect && (
                    <FormTop>
                      <p>{t('next.flock.openRecord.details.collect-data')}</p>
                      <p className="blue">
                        <span className="round">!</span>
                        {t('next.flock.openRecord.details.data-required')}
                      </p>
                    </FormTop>
                  )}

                  {!editable && props.user.profile !== 'Assistente' && (
                    <BadgeWrapper>
                      <Badge {...badgeProps}>
                        {data.dailyStatus}
                      </Badge>
                    </BadgeWrapper>
                  )}
                </div>

                {displayForm && (
                  <Formik
                    enableReinitialize
                    initialValues={
                      Object.keys(summaryContent).length > 0
                        ? initialValues(summaryContent)
                        : initialValues(data)
                    }
                    validate={validate(
                      getValidateSchema(falAge, feedTypes),
                    )}
                    onSubmit={submitForm}
                    render={formikProps => (
                      <FalForm
                        {...formikProps}
                        history={props.history}
                        deceasedTypes={deceasedTypes}
                        feedTypes={feedTypes}
                        isLoading={isLoading}
                        getFormVendors={getFormVendors}
                        isEditable={editable}
                        isFirstDay={data.isFirstDay}
                        isRequiredWeight={data.isRequiredWeight}
                        isRequiredPh={data.isRequiredPh}
                        showFlockClosure={data.showFlockClosure}
                        responsive={props.responsive}
                        vendors={vendors}
                        loadingVendors={loadingVendors}
                      />
                    )}
                  />
                )}
                {!displayForm && !noCollect && (
                  <div className="record-list">
                    <RecordItemsList
                      data={data}
                      isEmpty={!editable && !previousSubmitted}
                    />

                    {editable && (
                      <BtnGroup>
                        <Button
                          key={0}
                          className="tertiary"
                          onClick={() => setDisplayForm(true)}
                        >
                          {t("global.button.edit")}
                        </Button>
                      </BtnGroup>
                    )}
                  </div>
                )}
              </>
            )}

            {!hasData && !isLoading && (
              <NoContentTab icon="icon-scale">
                <p>
                  {t('global.no-content')}
                </p>
              </NoContentTab>
            )}

            {!displayForm && noCollect && (
              <NoContentTab icon="icon-tasks">
                <p>
                  {t('next.flock.openRecord.details.no-content.title')} <br />{' '}
                  <span className="lighter">
                    {t('next.flock.openRecord.details.no-content.subtitle')}
                  </span>
                </p>
              </NoContentTab>
            )}
          </Box>
        </div>
      </Container>
    </>
  );
}
