import 'babel-polyfill';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Loader from 'components/Loader';
import { store, persistor } from 'store';

import ReactGA from 'react-ga';

import 'components/GlobalStyles/assets/styles/global.css';

import 'config/i18n';

import App from 'containers/App';

import { logEnabler } from 'shared/utils/logEnabler';
import * as serviceWorker from './serviceWorker';

logEnabler();

smoothscroll.polyfill();
const reactAppContainer = document.getElementById('root');

ReactGA.initialize('UA-152852637-1');

const Body = () => (
  <Provider store={store}>
    <PersistGate
      loading={<Loader size={100} block />}
      persistor={persistor}
    >
      <App />
    </PersistGate>
  </Provider>
);

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(<Body />, reactAppContainer);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
