import React from 'react';
import {
  Dropdown,
  Menu,
  Item,
  Trigger,
} from '@zendeskgarden/react-dropdowns';
import { Flex } from 'styled-minimal';
import Icon from 'components/Icon';
import { OptionsMenu } from './Options.styled';

export default function Options({
  placement,
  options,
  style,
  onClick,
}) {
  return (
    <OptionsMenu style={style} onClick={onClick}>
      <Flex justifyContent="flex-end">
        <Dropdown>
          <Trigger>
            <button
              className="notification-open-actions"
              type="button"
            >
              <Icon name="icon-ellipsis-v" />
            </button>
          </Trigger>
          <Menu className="options-menu" placement={placement}>
            {options.map((item, index) =>
              item.file ? (
                <Item className="item" value={item.label} key={index}>
                  <a
                    href={item.file}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className={`icon icon-${item.icon}`} />
                    {item.label}
                  </a>
                </Item>
              ) : (
                item.visible && (
                  <Item
                    className="item"
                    value={item.label}
                    key={index}
                    onClick={() => item.action()}
                  >
                    <span className={`icon icon-${item.icon}`} />
                    {item.label}
                  </Item>
                )
              ),
            )}
          </Menu>
        </Dropdown>
      </Flex>
    </OptionsMenu>
  );
}
