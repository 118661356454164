import React, { useContext } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { ThemeContext } from 'styled-components';
import RoundContainer from 'components/RoundContainer';
import IconAndText from 'components/IconAndText';
import { StyledCard, Wrapper, Footer } from './Card.styled';
import t from 'components/i18n';

export default function Card(props) {
  const {
    title,
    subtitle,
    additional,
    buttonLabel,
    action,
    footerContent,
    footerColor,
    footerIcon,
    bigIcon = false,
    icon,
    iconBackGround,
    progress,
    progressColor,
    noShadow,
    children,
    reverse,
    onClick,
    whiteBg = false,
    roundIconPlaceholder = false,
    displayChevron = false,
    margin,
  } = props;

  const themeContext = useContext(ThemeContext);

  return (
    <StyledCard
      hasAction={action}
      onClick={() => (onClick ? onClick() : false)}
      style={{ cursor: `${action ? 'pointer' : 'inherit'}` }}
    >
      <RoundContainer
        className="card-rounded-container"
        noShadow={noShadow}
        noMargin
        margin={margin}
      >
        <Wrapper onClick={() => (action ? action() : false)}>
          {(title || subtitle || icon) && (
            <IconAndText
              bigIcon={bigIcon}
              noPadding
              reverse={reverse}
              title={title}
              subtitle={subtitle}
              additional={additional}
              icon={icon}
              iconBackGround={iconBackGround}
              round={roundIconPlaceholder}
              displayChevron={displayChevron}
              progress={progress}
              progressColor={progressColor}
            />
          )}

          {buttonLabel && (
            <Button onClick={() => action()} className="white">
              {t(buttonLabel)}
            </Button>
          )}

          {children && <div className="children">{children}</div>}
        </Wrapper>

        {footerContent && (
          <Footer
            whiteBg={whiteBg}
            footerColor={themeContext.styles.palette[footerColor]}
          >
            <p className="footer-p">
              {footerIcon && (
                <span className={`icon ${footerIcon}`} />
              )}
              {footerContent}
            </p>
          </Footer>
        )}
      </RoundContainer>
    </StyledCard>
  );
}
