import t from 'components/i18n';

export function getStatusColor(status, color) {
  switch (status.toLowerCase()) {
    case 'realizado':
      return color;
      break;

    case 'confirmado':
      return 'colorInfo';
      break;

    case 'previsto':
      return 'colorGrayLighter';
      break;

    default:
      return 'default';
  }
}

export function getFilteredContentByDate(
  format,
  item,
  dateFilter,
  date,
) {
  const start =
    dateFilter &&
    dateFilter.startDate &&
    format(dateFilter.startDate);
  const end =
    dateFilter && dateFilter.endDate && format(dateFilter.endDate);

  if (Array.isArray(item)) {
    if (start || end) {
      return item.filter(
        m =>
          format(m[date]).isBetween(start, end, 'day') ||
          format(m[date]).isSame(start, 'day') ||
          format(m[date]).isSame(end, 'day'),
      );
    }
  } else {
    return (
      format(item[date]).isBetween(start, end, 'day') ||
      format(item[date]).isSame(start, 'day') ||
      format(item[date]).isSame(end, 'day')
    );
  }

  return item;
}

export function getFiltersFromSideBox(filters, activeFilters) {
  return filters.map((filterItem, index) => {
    if (activeFilters.filter(e => e.label === filterItem).length) {
      if (filterItem === 'Por instalação') {
        return activeFilters.filter(e => e.label === filterItem)[0]
          .value;
      }

      if (filterItem === 'Por lote') {
        return activeFilters.filter(e => e.label === filterItem)[0]
          .id;
      }
      return activeFilters.filter(e => e.label === filterItem)[0].txt;
    }

    return '';
  });
}

export function getCatchData(catchData, format) {
  return [
    {
      label: t('next.vendorSchedule.catchData.date'),
      value: format(catchData.date).format('DD ̸̸  MM ̸̸  YYYY'),
    },
    {
      label: t('next.vendorSchedule.catchData.hour'),
      value: format(catchData.date).format('HH:mm'),
    },
    {
      label: t('next.vendorSchedule.catchData.numberOfTeams'),
      value: catchData.numberOfTeams,
    },
    {
      label: t('next.vendorSchedule.catchData.numberOfLoads'),
      value: catchData.numberOfLoads,
    },
    {
      label: t('next.vendorSchedule.catchData.raisedFeederDate'),
      value: format(catchData.raisedFeederDate).format('DD ̸̸  MM ̸̸  YYYY'),
    },
    {
      label: t('next.vendorSchedule.catchData.raisedFeederHour'),
      value: format(catchData.raisedFeederDate).format('HH:mm'),
    },
  ];
}

export function sumOfItems(arr, key, tofixed) {
  const reduced = arr.reduce(
    (acc, curr) => acc + parseFloat(curr[key]),
    0,
  );

  if (tofixed) {
    return reduced.toFixed(3) * 1000;
  }

  return reduced;
}
