import React from 'react';
import {
  NEXT_HOUSE_LIST,
  VISIT_LIST,
  NEXT_SWINE_RECORD,
  NEXT_MEDICATION,
} from 'config/routes';
import RoundContainer from 'components/RoundContainer';
import Icon from 'components/Icon';
import { colorDanger } from 'components/GlobalStyles/variables';
import { displayMessageText } from 'shared/utils/Helpers';
import { ItemRow, ItemColumn } from './StyledNextFlock.styled';
import t from 'components/i18n';

export function getIcon(type) {
  const types = {
    broiler: 'icon-chicken',
    turkey: 'icon-turkey',
    pig: 'icon-pig',
    swine: 'icon-pig',
  };

  return types[type];
}

export function getList(data) {
  return [
    { key: 'opened', list: data.openFlockList || [] },
    { key: 'closed', list: data.closedFlockList || [] },
  ];
}

export function getStatus(item) {
  const statusList = [
    {
      status: '-1',
      label: t('next.flock.status.undefined'),
      color: 'colorGrayLighter'
    },
    {
      status: '0',
      label: t('next.flock.status.pre-accommodation'),
      color: 'colorSecondary'
    },
    {
      status: '1',
      label: t('next.flock.status.initial'),
      color: 'colorInfo'
    },
    {
      status: '2',
      label: t('next.flock.status.intermediary'),
      color: 'colorPrimary'
    },
    {
      status: '3',
      label: t('next.flock.status.final'),
      color: 'colorDanger'
    },
    {
      status: '4',
      label: t('next.flock.status.empty'),
      color: 'colorGrayLighter'
    },
  ];

  const filterByStatus = statusList.filter(
    e => e.status.toString() === item.toString(),
  );

  return filterByStatus.length > 0
    ? filterByStatus[0]
    : 'colorGrayLighter';
}

export const activitiesOptions = user => {
  const { allowRoutes, animalType } = user;

  const isSwine = animalType === 'swine';

  const isTurkish = user.countryCodes.includes('TR');

  const options = [
    {
      id: 1,
      icon: 'icon-calendar-alt',
      label: t('next.flock.activities.options.visits'),
      action: VISIT_LIST,
      disabled: true,
      soon: isSwine,
      // disabled: allowRoutes.length > 0
      //   ? allowRoutes.findIndex(item => item.id === '19') === -1
      //   : false,
      hide: isTurkish,
    },
    {
      id: 2,
      icon: 'icon-tasks',
      label: t('next.flock.activities.options.fal-digital'),
      action: NEXT_HOUSE_LIST,
      disabled:
        allowRoutes.length > 0
          ? allowRoutes.findIndex(item => item.id === '12') === -1
          : false,
      hide: isSwine && isTurkish,
    },
    {
      id: 8,
      icon: 'icon-tasks',
      label: t('next.flock.activities.options.fal-digital'),
      action: NEXT_SWINE_RECORD,
      disabled: false,
      hide: !isSwine || isTurkish,
    },
    {
      id: 3,
      icon: 'icon-swine',
      label: t('next.flock.activities.options.piglet-monitoring'),
      disabled: true,
      soon: isSwine,
      hide: !isSwine || isTurkish,
    },
    {
      id: 4,
      icon: 'icon-bootprint',
      label: t('next.flock.activities.options.prop'),
      disabled: true,
      hide: isSwine || isTurkish,
    },
    {
      id: 5,
      icon: 'icon-dolly',
      label: t('next.flock.activities.options.requisitions'),
      disabled: true,
      soon: isSwine,
      hide: isTurkish,
    },
    {
      id: 6,
      icon: 'icon-medkit',
      label: t('next.flock.activities.options.medicines-vaccines'),
      action: `${NEXT_MEDICATION}`,
      hide: isSwine || isTurkish,
    },
    {
      id: 7,
      icon: 'icon-clipboard',
      label: t('next.flock.activities.options.health-monitoring'),
      disabled: true,
      soon: isSwine,
      hide: isTurkish,
    },
  ];

  return options.filter(item => !item.hide);
};

export function getNotifications(e, Component, color1, color2) {
  const arr = [
    {
      display: 'swabAlert',
      text: t('next.flock.notifications.age-of-propet-collection.label'),
      color: color1,
      icon: 'clock',
    },
    {
      display: 'swabDelayedAlert',
      text: t('next.flock.notifications.delayed-tax-collection.label'),
      color: color1,
      icon: 'clock',
    },
    {
      display: 'mortalityAlert',
      text: t('next.flock.notifications.high-mortality-rate.label'),
      color: color2,
      icon: 'exclamation-circle',
    },
  ];

  return arr.map(
    (item, index) =>
      arr[item.display] && (
        <Component bgColor={item.color} type={item.icon}>
          {item.text}
        </Component>
      ),
  );
}

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export const getWeightList = list =>
  list &&
  list.map(item => ({
    house: t('next.flock.weightList.house', { value: item.houseCode }),
    quantity: t('next.flock.weightList.quantity', { value: item.weight }),
    small: t('next.flock.weightList.small', { value: item.gpd }),
    alert: item.isAlert,
    alertLabel: t("next.flock.weightList.alertLabel", { value: item.expectedWeight }),
  }));

export const getMortalityList = list =>
  list &&
  list.map(item => ({
    house: t('next.flock.mortalityList.house', { value: item.houseCode }),
    quantity: t('next.flock.mortalityList.quantity', { value: item.mortalityQuantity }),
    small: t('next.flock.mortalityList.small', { value: item.mortalityPercentage }),
    alert: item.mortalityAlert,
    alertLabel: t('next.flock.mortalityList.alertLabel'),
  }));

export function displayHouseContent(item, index, arrow) {
  return (
    <RoundContainer key={index} padding="12px 16px" className="steps">
      <ItemRow>
        <ItemColumn>
          <strong>{item.house}</strong>
        </ItemColumn>
        <ItemColumn className="align-right">
          <p>
            <span className={item.alert ? 'red big' : 'big'}>
              {item.quantity}
            </span>
            {item.alert && (
              <Icon
                name={`icon-arrow-${arrow}`}
                className="big red"
              />
            )}
          </p>
          <p>
            <span className="small">{item.small}</span>
          </p>
        </ItemColumn>
      </ItemRow>
      <br />

      {item.alert &&
        displayMessageText(
          'icon-exclamation-circle',
          item.alertLabel,
          colorDanger,
        )}
    </RoundContainer>
  );
}

export function getBoxType(key) {
  const types = {
    feed: 'primary',
    placement: 'tertiary',
    slaughter: 'danger',
    payment: 'danger',
  };

  return types[key];
}
