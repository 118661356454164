import React from 'react';
import { Notification } from './NotificationBox.styled';

export default function NotificationBox(props) {
  let iconType;

  switch (props.type) {
    case 'success': {
      iconType = 'icon-check';
      break;
    }
    case 'warning': {
      iconType = 'icon-exclamation-circle';
      break;
    }
    case 'danger': {
      iconType = 'icon-times-circle';
      break;
    }
    case 'clock': {
      iconType = 'icon-clock';
      break;
    }
    default: {
      iconType = 'icon-exclamation-triangle';
    }
  }
  return (
    <Notification
      className={`notification-box notification-box--${props.type}`}
      bgColor={props.bgColor}
      slim={props.slim}
      style={props.style}
    >
      <div className="icon-wrapper">
        <span className={`icon ${iconType}`} />
      </div>
      {props.children}
    </Notification>
  );
}
