import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  colorGrayUltraLight,
  colorGrayLight,
  colorGrayDarkest,
} from 'components/GlobalStyles/variables';

export const CheckboxTableField = styled.div`
  font-size: 14px;

  .center {
    text-align: center;
  }
`;

export const FieldRow = styled.div`
  color: ${colorGrayDarkest};
  padding: 0 0 0 16px;
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};

  &:last-of-type {
    border: none;
  }

  input[type='checkbox'] + label:before {
    left: -8px !important;
  }
`;

export const FieldTitle = styled.div`
  color: ${colorGrayLight};
  line-height: 21px;
  background-color: ${colorGrayUltraLight};
  padding: 0 0 0 16px;
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};
`;
