import styled from 'styled-components';
import { Notification } from '@zendeskgarden/react-notifications';
import {
  colorClear,
  colorSuccess,
  colorGrayDarker,
  fadeIn,
  colorDanger,
} from 'components/GlobalStyles/variables.js';

export const StyledToaster = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  opacity: 0;
  animation: ${fadeIn} ${props => props.duration}s linear ${props =>
  props.delay}s forwards; 

  ${Notification} {
    &.toaster__notification {
      position: fixed;
      bottom: 20px;
      right: 30px;
      border: none;
      width: 320px;
      padding: 16px 40px;
      border-radius: 8px;
      z-index: 9999;
      color: ${props =>
        props.color === 'success' ? colorClear : colorGrayDarker};

      color: ${props =>
        props.color
          ? colorClear
          : colorGrayDarker};
    }

      background-color: ${props =>
        props.color
          ? props.color === 'success'
            ? colorSuccess
            : colorDanger
          : colorGrayDarker};

        
      .title {
        color: ${props =>
          props.color
            ? colorClear
            : colorGrayDarker};
      }
    }
  }

  .icon {
    position: relative;
    top: 2px;
    font-size: 20px;
    margin: 0 4px 0 -26px;
  }
`;
