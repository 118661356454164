import React, { useState, useEffect } from 'react';
import {
  RoundIconWrapper,
  SectionTitle,
  Separator,
} from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import Icon from 'components/Icon';
import {
  AccordionContent,
  AccordionStyled,
  ToggleAccordion,
} from './FormAccordion.styled';

export default function FormAccordion(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [overFlowVisible, setOverFlowVisible] = useState(false);
  const {
    hide = false,
    sectionTitle,
    icon,
    hideSeparator,
    children,
    isChecked,
    isRequired,
    opened,
    maxHeight = 300,
  } = props;

  useEffect(() => {
    if (opened) setIsOpen(true);
  }, [opened]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setOverFlowVisible(true);
      }, 400);
    } else {
      setOverFlowVisible(false);
    }
  }, [isOpen]);

  return (
    !hide && (
      <>
        {!hideSeparator && <Separator gap={8} />}
        <AccordionStyled onClick={() => setIsOpen(!isOpen)}>
          <SectionTitle>
            <RoundIconWrapper
              isChecked={isChecked}
              isRequired={isRequired}
            >
              <Icon name={icon} />
              <span
                className={`icon icon-check small ${isChecked &&
                  `visible`}`}
              />
              <span
                className={`small required ${isRequired &&
                  !isChecked &&
                  `visible`}`}
              >
                !
              </span>
            </RoundIconWrapper>
            <p>{sectionTitle}</p>
          </SectionTitle>
          <ToggleAccordion>{isOpen ? '-' : '+'}</ToggleAccordion>
        </AccordionStyled>
        <AccordionContent
          isOpen={isOpen}
          className={overFlowVisible ? 'overflow-visible' : ''}
          maxHeight={maxHeight}
          onFocus={() => setIsOpen(true)}
        >
          {children}{' '}
        </AccordionContent>
      </>
    )
  );
}
