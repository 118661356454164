import React, { useContext } from 'react';
import { Dots } from '@zendeskgarden/react-loaders';
import { ThemeContext } from 'styled-components';
import { StyledLoadingHolder } from './LoadingHolder.styled';
import t from 'components/i18n';

export default function LoadingHolder(props) {
  const {
    isLoading,
    message = t('global.loading'),
    size = '16',
  } = props;
  const themeContext = useContext(ThemeContext);

  return isLoading ? (
    <StyledLoadingHolder>
      <div>
        <p>{message}</p>
      </div>
      <Dots
        color={themeContext.styles.palette.colorPrimary}
        size={size}
      />
    </StyledLoadingHolder>
  ) : null;
}
