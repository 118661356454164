import React, { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import RoundContainer from 'components/RoundContainer';
import { Overline, Title, SubTitle, Info } from 'components/List';
import { getHousesContent } from './utils';
import t from 'components/i18n';
import moment from 'moment';

export default function LogItem({
  overline,
  icon,
  title,
  subtitle,
  color = null,
  bgColor = false,
  isRandom,
  createdAt,
  justification,
  randomType,
  houseList,
  epidemiologicalCore,
  listLogBookItemFlock,
}) {
  const themeContext = useContext(ThemeContext);

  const getFlockListContent = useMemo(() => {
    return (
      listLogBookItemFlock &&
      listLogBookItemFlock.length > 0 &&
      listLogBookItemFlock.map(item => (
        <>
          <br />

          <Overline color={themeContext.styles.palette[color]}>
            {t('next.logBook.item.flock', { value: item.flock })}
          </Overline>
          <Info className="flex">
            <p>{t('next.logBook.item.age', { value: item.age })}</p>

            {item.houses &&
              item.houses.length > 0 &&
              getHousesContent(item.houses, true)}
          </Info>
        </>
      ))
    );
  }, [color, listLogBookItemFlock, themeContext.styles.palette]);

  return (
    <RoundContainer
      padding="16px"
      noMargin
      bgColor={
        bgColor || rgba(themeContext.styles.palette[color], 0.16)
      }
    >
      <div className="flex">
        <Overline color={themeContext.styles.palette[color]}>
          {overline}
        </Overline>
        <div>
          {icon && (
            <span
              className={`icon ${icon}`}
              style={{
                fontSize: 20,
                color: themeContext.styles.palette[color],
              }}
            />
          )}
          {isRandom && (
            <span
              className={`icon icon-edit-alt`}
              style={{
                fontSize: 20,
                color: themeContext.styles.palette[color],
              }}
            />
          )}
        </div>
      </div>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <Info className="flex">
        {(epidemiologicalCore !== '-1' && epidemiologicalCore !== -1) && (
          <p>{epidemiologicalCore}</p>
        )}
        {justification && (
          <p
            style={{
              color: themeContext.styles.palette[color],
            }}
          >
            {t('next.logBook.item.justification')}
          </p>
        )}
      </Info>
      {isRandom && (
        <>
          <br />
          <Overline color={themeContext.styles.palette[color]}>
            {t('next.logBook.item.custom-event.type', { value: randomType })}
          </Overline>
          <Info className="flex">
            <p>{t('next.logBook.item.custom-event.date', { value: moment(createdAt).format('DD ̸̸  MM ̸̸  YYYY')})}</p>
          </Info>
        </>
      )}
      {getFlockListContent}
    </RoundContainer>
  );
}
