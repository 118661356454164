import React from 'react';
import SidebarRight, {
  SidebarRightContent,
  SidebarToggler,
} from 'components/SidebarRight';
import { Menu, TabletMenu } from './Flock.styled';

export default function TemporaryFlockMenu(props) {
  const {
    getHeading,
    getTabletToggler,
    getToggler,
    isTablet,
    isMobile,
    isGtMobile,
    selectedKey,
    getFlockDetails,
    type,
    data,
    user,
  } = props;

  // show add option only if there are available houses
  const showAddOption =
    data &&
    data.houseInformation &&
    data.houseInformation.length > 0 &&
    user.profile !== 'Assistente';

  return (
    /* if flock is closed or it's not broiler,
      do not show weighning options */
    type === 'open' &&
    data.flockType === 'broiler' && ( // @TODO: CHECK FLOCKTYPE
      <div className="flock-menu flock-menu__temporary-flock-menu">
        {isTablet && (
          <TabletMenu>
            {getTabletToggler('summary')}
            {showAddOption && getTabletToggler('add')}
          </TabletMenu>
        )}

        {!isTablet && (
          <>
            <Menu>
              {isMobile && (
                <div className="menu-group">
                  <SidebarRight>
                    <SidebarRightContent
                      title={getHeading(selectedKey)}
                    >
                      {getFlockDetails()}
                    </SidebarRightContent>
                  </SidebarRight>
                </div>
              )}

              <div className="menu-group">
                {isMobile && (
                  <SidebarRight>
                    <SidebarToggler>
                      {getToggler('summary')}
                    </SidebarToggler>
                    <SidebarToggler>
                      {showAddOption && getToggler('add', true)}
                    </SidebarToggler>
                    <SidebarRightContent
                      title={getHeading(selectedKey)}
                    >
                      {getFlockDetails()}
                    </SidebarRightContent>
                  </SidebarRight>
                )}

                {isGtMobile && (
                  <>
                    {getToggler('summary')}
                    {showAddOption && getToggler('add', true)}
                  </>
                )}
              </div>
            </Menu>
          </>
        )}
      </div>
    )
  );
}
