import React, { useState, useEffect, useMemo } from 'react';
import {
  VENDORS,
  VENDORS_TABLE,
  NEXT_FLOCK_LIST,
} from 'config/routes';
import uuid from 'uuid';
import { List, ListItem } from 'components/List';
import {
  colorInfo,
  colorDanger,
} from 'components/GlobalStyles/variables';
import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';
import IconPlaceholder from 'components/IconPlaceholder';
import Icon from 'components/Icon';
import BadgePill from 'components/BadgePill';
import NotificationBox from 'components/NotificationBox';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import { useSelector } from 'react-redux';
import { ItemRow } from '../StyledNextFlock.styled';
import { getIcon } from '../utils';
import t from 'components/i18n';

export default function FlockHeader({
  history,
  header,
  getNotifications,
  getStatus,
  bgColor,
  displayDetails,
  setDisplayDetails,
  displayTabs,
  isSwine,
}) {
  const [title, setTitle] = useState(t('next.flock.flockDetails.instalation-performance.title'));

  const headerTabs = useMemo(
    () => [
      { label: t('next.flock.flockDetails.tabs.weight.label'), action: 'weight' },
      { label: t('next.flock.flockDetails.tabs.mortality.label'), action: 'mortality' },
    ],
    [],
  );

  useEffect(() => {
    if (displayDetails === 'demonstrative') {
      setTitle(t('next.flock.flockDetails.demonstrative-flock.title'));
    }
  }, [displayDetails]);

  const getBadgeLabel = useMemo(() => {
    if (isSwine) {
      return header.flockClosed ? t('next.flock.flockDetails.header.close') : t('next.flock.flockDetails.header.open');
    }

    return header.flockClosed
      ? t('next.flock.flockDetails.header.close')
      : t(getStatus(header.flockStatusEnum).label);
  }, [
    getStatus,
    header.flockClosed,
    header.flockStatusEnum,
    isSwine,
  ]);

  const user = useSelector(state => state.user);
  const backRoute =
    user && user.type === 'E' ? VENDORS : VENDORS_TABLE;

  return (
    <>
      {displayDetails ? (
        <Breadcrumb
          history={history}
          backTo={() => setDisplayDetails(false)}
          path={[
            t('next.flock.flockDetails.bc.growers'),
            header.vendorName,
            t('next.flock.flockDetails.bc.flock', { value: header.flock }),
            title,
          ]}
          links={[
            backRoute,
            `${NEXT_FLOCK_LIST}/${header.vendorId}/new`,
            () => setDisplayDetails(false),
          ]}
        />
      ) : (
        <Breadcrumb
          history={history}
          backTo={() =>
            history.push(`${NEXT_FLOCK_LIST}/${header.vendorId}/new`)
          }
          path={[
            t('next.flock.flockDetails.bc.growers'),
            header.vendorName,
            t('next.flock.flockDetails.bc.flock', { value: header.flock }),
          ]}
          links={[
            backRoute,
            `${NEXT_FLOCK_LIST}/${header.vendorId}/new`,
          ]}
        />
      )}

      {displayDetails ? (
        <>
          <Top>
            <h2>{title}</h2>
          </Top>
          {displayTabs && (
            <Header>
              {headerTabs.map(item => (
                <TabHeaderHolder
                  key={item.key}
                  onClick={() => setDisplayDetails(item.action)}
                  className={
                    displayDetails === item.action && 'active'
                  }
                >
                  <div className="label">{item.label}</div>
                  <div className="bar" />
                </TabHeaderHolder>
              ))}
            </Header>
          )}
        </>
      ) : (
        <List>
          <ListItem noMargin>
            <IconPlaceholder
              className="top icon-holder"
              bgColor={bgColor}
            >
              <Icon
                className="icon"
                name={getIcon(header.flockType)}
              />
            </IconPlaceholder>
            <div className="list-item-content">
              <ItemRow>
                <h2 className="list-item-heading">
                  {t('next.flock.header.flockDetails.flock', { value: header.flock })}
                </h2>
                <div className="status">
                  <BadgePill bgColor={bgColor}>
                    {getBadgeLabel}
                  </BadgePill>
                </div>
              </ItemRow>

              <p className="list-subheading">
                {t('next.flock.header.flockDetails.houses')}
                {header.houseCodes &&
                  header.houseCodes.length > 0 &&
                  header.houseCodes.map((house, idx) => (
                    <span key={`house-${uuid()}`}>
                      {house}
                      {idx !== header.houseCodes.length - 1 && ', '}
                    </span>
                  ))}
              </p>
            </div>
          </ListItem>

          {!isSwine &&
            getNotifications(
              header,
              NotificationBox,
              colorInfo,
              colorDanger,
            )}
        </List>
      )}
    </>
  );
}
