import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Top from 'components/Top';
import { NewsList } from 'containers/AgroBRF/News';
import LoadingHolder from 'components/LoadingHolder';
import { More } from './News.styled';
import { getHighlightedNews, getNews } from './service';
import t from 'components/i18n';

export default function News(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [highlighted, setHighlighted] = useState([]);
  const [news, setNews] = useState([]);
  const [interval, setInterval] = useState(0);
  const themeContext = useContext(ThemeContext);
  const newsConst = 24;

  async function fetchHighlightedNews() {
    setIsLoading(true);

    try {
      const response = await getHighlightedNews();
      setHighlighted(response);
      setIsLoading(false);
    } catch (e) {
      console.error('Fetch error', e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchHighlightedNews();
  }, []);

  async function fetchNews() {
    setIsLoading(true);

    try {
      const response = await getNews(0, newsConst);
      setNews(response);
      setInterval(newsConst + 1);
      setIsLoading(false);
    } catch (e) {
      console.error('Fetch error', e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchNews();
  }, []);

  async function fetchMoreNews() {
    setIsLoadingMore(true);

    try {
      const response = await getNews(interval, interval + newsConst);
      setNews(news.concat(response));
      setInterval(interval + newsConst);
      setIsLoadingMore(false);
    } catch (e) {
      console.error('Fetch error', e);
      setIsLoadingMore(false);
    }
  }

  return (
    <>
      <Top>
        <h2>{t('news.title')}</h2>
      </Top>

      {isLoading ? (
        <LoadingHolder
          message={t('global.loading')}
          isLoading={isLoading}
        />
      ) : (
        <NewsList
          responsive={props.responsive}
          highlighted={highlighted}
          news={news}
          history={props.history}
          colorPrimary={themeContext.styles.palette.colorPrimary}
        />
      )}
      {isLoadingMore ? (
        <LoadingHolder
          isLoading={isLoadingMore}
          message={t('global.loading')}
        />
      ) : (
        <More
          backgroundColor={themeContext.styles.palette.colorPrimary}
        >
          <button type="button" onClick={() => fetchMoreNews()}>
            {t('news.load-more')}
          </button>
        </More>
      )}
    </>
  );
}
