import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

export const Container = styled.div`
  position: relative;
`;

export const MenuHolder = styled.div`
  position: absolute;
  right: -9px;
  z-index: 999;
  transition: top 0.2s ease-in-out, opacity 0.2s ease-in-out,
    max-height 0.2s ease-in-out, transform 0.2s ease-in-out;

  /* initial status */
  max-height: 0;
  /* width: 0; */
  /* transform: scaleY(0); */
  overflow: hidden;
  top: -30px;
  opacity: 0;

  &.is-open {
    max-height: 1000px;
    /* transform: scaleY(1); */
    /* width: 350px; */
    top: 30px;
    opacity: 1;
  }
`;

export const Bkdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 998;
  /* background-color: ${rgba('#000', 0.5)}; */
  background-color: transparent;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const Btn = styled.button`
  background: none;
  border: 0 none;
  margin: 0 none;
  cursor: pointer;

  &:active,
  &:focus {
    outline: 0 none;
  }
`;
