import t from 'components/i18n';
import React from 'react';
import { CardStyled } from './About.styled';

export default function Card(props) {
  return (
    <CardStyled>
      <span className={`icon icon-${props.icon}`} />
      <strong className="title">{t(props.title)}</strong>
      <p className="description">{t(props.description)}</p>
    </CardStyled>
  );
}
