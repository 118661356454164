import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FLOCK_LIST } from 'config/routes';
import { Row, Col } from '@zendeskgarden/react-grid';
import Icon from 'components/Icon';
import Badge from 'components/Badge';
import ColoredCard from 'components/ColoredCard';
import KeyValueTable from 'components/KeyValueTable';
import { isUndefined } from 'utils';
import t from 'components/i18n';
import { markAsRead } from '../../service';
import {
  Container,
  ItemContainer,
  FeedItem,
  LastActivityStyled,
} from './FlockHistory.styled';
import { statusMapper } from 'utils/mapper';

function getBadgeType(key) {
  const types = {
    feed: 'primary',
    placement: 'tertiary',
    slaughter: 'danger',
  };

  return types[key];
}

function FlockHistoryItem({ history, selectedKey }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ItemContainer
      className="item-container"
      color={getBadgeType(selectedKey)}
    >
      <div className={`feed-history-holder ${isOpen ? 'open' : ''}`}>
        {history}
      </div>
      <div className="feed-show-history">
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className="unstyled-button"
        >
          <Icon
            className="feed-holder-icon"
            name={`icon-chevron-${isOpen ? 'up' : 'down'}`}
          />
          <span className="mini-heading">
            {isOpen
              ? t('global.button.hideHistory')
              : t('global.button.seeHistory')}
          </span>
        </button>
      </div>
    </ItemContainer>
  );
}

function ConfirmedSlaughterDetails({
  feedItem: {
    expectedCollectAt,
    expectedFeedCutAt,
    expectedCrews,
    expectedLoads,
    mtechCatchId,
    isRead,
    newReadDate,
    readDate,
    readDateUtc,
    house,
  },
  setReadItem,
} = {}) {
  // TODO: confirm if data will be shown only when all are filled

  const shouldSkip =
    isUndefined(expectedCollectAt) &&
    isUndefined(expectedFeedCutAt) &&
    isUndefined(expectedCrews) &&
    isUndefined(expectedLoads);

  if (shouldSkip) {
    return null;
  }

  const readConfirmation = newReadDate || readDateUtc;

  const slaughterValues = [
    {
      label: t(
        'dashboard.sectionSchedule.listView.item.divWhenConfirmed.dateOfCatch.label',
      ),
      value: expectedCollectAt
        ? moment(expectedCollectAt).format('DD ̸̸  MM ̸̸  YYYY')
        : null,
    },
    {
      label: t(
        'dashboard.sectionSchedule.listView.item.divWhenConfirmed.hourOfCatch.label',
      ),
      value: expectedCollectAt
        ? moment(expectedCollectAt).format('HH:mm')
        : null,
    },
    {
      label: t(
        'dashboard.sectionSchedule.listView.item.divWhenConfirmed.numberOfTeams.label',
      ),
      value: expectedCrews,
    },
    {
      label: t(
        'dashboard.sectionSchedule.listView.item.divWhenConfirmed.numberOfLoads.label',
      ),
      value: expectedLoads,
    },
    {
      label: t(
        'dashboard.sectionSchedule.listView.item.divWhenConfirmed.feedWithdrawalDate.label',
      ),
      value: expectedFeedCutAt
        ? moment(expectedFeedCutAt).format('DD ̸̸  MM ̸̸  YYYY')
        : null,
    },
    {
      label: t(
        'dashboard.sectionSchedule.listView.item.divWhenConfirmed.feedWithdrawalHour.label',
      ),
      value: expectedFeedCutAt
        ? moment(expectedFeedCutAt).format('HH:mm')
        : null,
    },
  ].filter(({ value }) => value);

  return (
    <Row>
      <Col md={12}>
        <KeyValueTable
          values={slaughterValues}
          status={{
            text: readConfirmation
              ? `${t('global.status.confirmUpdate')}: ${moment
                  .utc(readDateUtc)
                  .local()
                  .format('DD ̸̸  MM ̸̸  YYYY')}`
              : null,
            icon: readConfirmation ? 'icon icon-check' : null,
          }}
        />

        {!readDate && !newReadDate && (
          <>
            <br />
            <ColoredCard
              full
              icon="icon icon-exclamation-triangle"
              text={t(
                'dashboard.sectionSchedule.listView.item.divWhenConfirmed.card.description',
                { value: house },
              )}
              buttonLabel={t(
                'dashboard.sectionSchedule.listView.item.divWhenConfirmed.card.button',
              )}
              buttonAction={() =>
                setReadItem(mtechCatchId, moment().toISOString())
              }
            />
          </>
        )}
      </Col>
    </Row>
  );
}

const LastActivity = ({ historyGroup = [] } = {}) => {
  const [latestFilledUpdatedDate] = historyGroup
    .map(({ lastUpdated }) => lastUpdated)
    .filter(date => date)
    .sort((a, b) => (a < b ? 1 : -1));

  if (!latestFilledUpdatedDate) {
    return null;
  }

  return (
    <LastActivityStyled>
      <span className="icon icon-clock" />
      <p>
        {t('global.status.lastUpdate')}:
        <span>
          {moment
            .utc(latestFilledUpdatedDate)
            .local()
            .format('DD ̸̸  MM ̸̸  YYYY')}
        </span>
      </p>
    </LastActivityStyled>
  );
};

export default function FlockHistory({
  feed,
  selectedKey,
  flockType,
  style,
  notificationCount,
  routerHistory,
}) {
  const [newReadDate, setNewReadDate] = useState(null);

  if (!feed) {
    return null;
  }

  useEffect(() => {
    if (newReadDate) {
      if (Array.isArray(feed)) {
        routerHistory.push(
          `${FLOCK_LIST}/${feed[0].flockId}/slaughter`,
        );
      } else {
        routerHistory.push(`${FLOCK_LIST}/${feed.flockId}/slaughter`);
      }
    }
  }, [feed, newReadDate, routerHistory]);

  async function setReadItem(id, date) {
    try {
      await markAsRead({
        MtechCatchId: id,
        Date: date,
      });

      setNewReadDate(date || null);
    } catch (error) {
      console.error(error);
    }
  }

  let feedArray = [];
  feedArray = Array.isArray(feed) ? feed : [feed];

  function getStatusMessage(status) {
    if (status.toLowerCase() === 'abate previsto') {
      return t('global.status.foreseenSlaughter');
    }

    if (status.toLowerCase() === 'confimado') {
      return t('global.status.confirmed');
    }

    if (status.toLowerCase() === 'concluído') {
      return t('global.status.done');
    }

    if (status.toLowerCase() === 'previsto') {
      return t('global.status.foreseen');
    }

    if (status.toLowerCase() === 'realizado') {
      return t('global.status.real');
    }

    if (status.toLowerCase() === 'pendente') {
      return t('global.status.pending');
    }

    return status;
  }

  const inner = feedArray.map((feedItem, key, arr) => {
    const badgeProps = {};
    const feedProps = {
      [selectedKey]: true,
    };

    if (
      selectedKey === 'slaughter' ||
      feedItem.type === 'slaughter'
    ) {
      // @todo Implement animal type
      feedProps[flockType] = true;
    }

    if (key !== 0) {
      delete feedProps[selectedKey];
      delete feedProps[flockType];
      feedProps.dot = true;
    }

    // removes the connector from single items
    if (arr.length === 1 && key === 0) {
      feedProps.className = 'not-connected';
    }

    if (feedItem.status) {
      switch (feedItem.status.toLowerCase()) {
        case 'realizado':
          badgeProps[getBadgeType(selectedKey)] = true;
          break;
        case 'programada':
          badgeProps.white = true;
          break;
        case 'confirmado':
          // TODO: check for other requirements
          badgeProps.info = true;
          break;
        default:
          badgeProps.default = true;
      }
    }

    return (
      <FeedItem
        className="flock-history__feed-item"
        key={`feed-item-${feedItem.id}`}
        {...feedProps}
        type={feedItem.type}
      >
        <header className="item-header">
          <div className="date">{feedItem.date}</div>
          <div className="status">
            {feedItem.status && (
              <Badge {...badgeProps}>
                {getStatusMessage(statusMapper(feedItem.status))}
              </Badge>
            )}
          </div>
        </header>
        <div className="flock">
          {t(feedItem.quantity && feedItem.quantity.key, {
            value: feedItem.quantity && feedItem.quantity.value,
          })}
        </div>

        <footer className="item-footer">
          <Row>
            <Col md={6}>
              {feedItem.infoLeft && (
                <div className="caption 1">
                  {t(feedItem.infoLeft && feedItem.infoLeft.key, {
                    value:
                      feedItem.infoLeft && feedItem.infoLeft.value,
                  })}
                </div>
              )}
              {feedItem.infoLeft2 && (
                <div className="caption 2">
                  {t(feedItem.infoLeft2 && feedItem.infoLeft2.key, {
                    value:
                      feedItem.infoLeft2 && feedItem.infoLeft2.value,
                  })}
                </div>
              )}
            </Col>
            <Col md={6} className="align-right">
              {feedItem.infoRight && (
                <div className="caption 3">
                  {t(feedItem.infoRight && feedItem.infoRight.key, {
                    value:
                      feedItem.infoRight && feedItem.infoRight.value,
                  })}
                </div>
              )}
              {feedItem.infoRight2 && (
                <div className="caption 4">
                  {t(feedItem.infoRight2 && feedItem.infoRight2.key, {
                    value:
                      feedItem.infoRight2 &&
                      feedItem.infoRight2.value,
                  })}
                </div>
              )}
            </Col>
          </Row>
          <ConfirmedSlaughterDetails
            feedItem={feedItem}
            setReadItem={setReadItem}
            newReadDate={newReadDate}
          />
        </footer>
      </FeedItem>
    );
  });

  const firstItem = inner.shift();
  const history = inner;

  return (
    <Container style={{ ...style }} className="flock-history">
      {firstItem}

      {history.length > 0 && (
        <FlockHistoryItem
          history={history}
          selectedKey={selectedKey}
        />
      )}
      <LastActivity historyGroup={feedArray} />
    </Container>
  );
}
