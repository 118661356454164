import styled from 'styled-components';
import { DEFAULT_SIZE } from 'components/GlobalStyles/variables';

function getSize(props) {
  if ('slim' in props) {
    return '16px';
  }

  return '24px';
}

function getMode(props) {
  if ('dark' in props) {
    return {
      color: props.palette.colorClear,
      bgColor: props.palette.colorGrayDarkest,
    };
  }

  return {
    color: props.palette.colorGrayDarkest,
    bgColor: props.palette.colorClear,
  };
}

export const Wrapper = styled.div`
  position: relative;
  border-radius: 12px;
  color: ${props => getMode(props).color};

  ${props => {
    if ('footerDetail' in props) {
      return `
        padding-bottom: 3px;
        background: none;       
      `;
    }
    return '';
  }}

  .container-content {
    border-radius: 12px;
    background-color: ${props => getMode(props).bgColor};
    padding: ${props => getSize(props)};
    margin-bottom: ${DEFAULT_SIZE * 4}px;
    max-height: ${props => props.maxHeight && `560px`};
    box-shadow: 0px 0px 16px rgba(37, 48, 71, 0.08);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 25px bottom;
    ${props => {
      if (props.noStyle) {
        return `
          padding: 0;
          background-color: transparent;
          border: none;
          box-shadow: none;
        `;
      }
      return '';
    }}
  }

  .container-inner {
    border-radius: 12px;
    height: 100%;
  }

  .container-detail {
    position: absolute;
    top: 0px;
    left: ${props => getSize(props)};
    height: 3px;
    width: 50px;

    background-color: ${props => {
      if ('danger' in props) {
        return props.palette.colorDanger;
      }
      if ('warning' in props) {
        return props.palette.colorWarning;
      }
      if ('success' in props) {
        return props.palette.colorSuccess;
      }
      if ('info' in props) {
        return props.palette.colorInfo;
      }
      if ('primary' in props) {
        return props.palette.colorPrimary;
      }
      if (props.tertiary) {
        return props.palette.colorTertiary;
      }
      if ('default' in props) {
        return props.palette.colorGrayLighter;
      }

      return props.bgColor || props.palette.colorPrimary;
    }};
  }
`;
