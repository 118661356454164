import React from 'react';
import moment from 'moment';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { List, ListItem } from 'components/List/List';
import t from 'components/i18n';

// Styles
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';

export default function MedicationConfirmation(props) {
  const {
    values,
    isValid,
    isSubmitting,
    onSubmit,
    backToForm,
  } = props;

  const listArray = [];

  if (values) {
    listArray.push(
      {
        description: values.house.label,
        icon: 'pin',
      },
      {
        description: `${moment(
          values.treatmentPeriod.startDate,
        ).format('DD ̸̸  MM ̸̸  YYYY')} - ${moment(
          values.treatmentPeriod.endDate,
        ).format('DD ̸̸  MM ̸̸  YYYY')}`,
        icon: 'calendar',
      },
      {
        description: values.material.description,
        icon: 'medkit',
        subItemLabel: t('agro.swine-medication.info.qt-admin'),
        subItemValue: `${values.quantity}${values.material
          .measurement || values.measurementUnit}`,
      },
      {
        description: values.laboratory.description,
        icon: 'flask',
        subItemLabel: t('agro.swine-medication.info.flock'),
        subItemValue: values.vaccineBatch,
      },
      {
        description: values.healthProblem.name,
        icon: 'disease',
        subItemLabel: t('agro.swine-medication.info.qt-animals-treated'),
        subItemValue: t('agro.swine-medication.info.qt-animals-treated.value', { value: values.quantityTreated }),
      },
    );
  }

  function displayListItem(
    index,
    description,
    icon,
    subItemLabel,
    subItemValue,
  ) {
    return (
      <>
        <ListItem
          key={index}
          className="confirm-list__item animated fadeIn"
        >
          <div className="icon-wrapper">
            <span className={`icon icon-${icon}`} />
          </div>
          <div
            className="list-item-content"
            style={{ width: '100%' }}
          >
            <div className="list-item-description">{description}</div>
          </div>
        </ListItem>

        {subItemValue && (
          <ListItem className="confirm-list__item animated fadeIn">
            <div className="icon-wrapper">
              <span className="icon" />
            </div>
            <div
              className="list-item-content"
              style={{ width: '100%' }}
            >
              <div className="list-item-subitem list-item-description flex">
                <p className="small">{subItemLabel}</p>
                <p>{subItemValue}</p>
              </div>
            </div>
          </ListItem>
        )}
      </>
    );
  }

  return (
    <div className="confirmation">
      <List className="confirm-list" style={{ marginTop: '0' }}>
        {listArray.length > 0 &&
          listArray.map((item, index) =>
            displayListItem(
              index,
              item.description,
              item.icon,
              item.subItemLabel,
              item.subItemValue,
            ),
          )}
      </List>

      <Separator />

      {/* \ Botões */}
      <BtnGroup className="button-group--bottom">
        <Button
          key="submit"
          onClick={() => onSubmit()}
          disabled={isSubmitting || !isValid}
        >
          {t("global.button.save")}
        </Button>
        <Button
          key="edit"
          onClick={() => backToForm()}
          className="white"
        >
          {t("global.button.edit")}
        </Button>
      </BtnGroup>
      {/* \ Botões */}
    </div>
  );
}
