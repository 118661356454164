import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { List, ListItem } from 'components/List/List';

// Styles
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import t from 'components/i18n';

export default function MedicationDetails({
  medicationDetails,
  setMedicationDetails,
}) {
  const listArray = useMemo(() => {
    if (medicationDetails) {
      return [
        {
          description: medicationDetails.materialName,
          icon: 'medkit',
          subItems: [
            {
              subItemLabel: t('flocks.newFal.medications.details.medicationBatch.label'),
              subItemValue: `${medicationDetails.vaccineBatch}`,
            },
            {
              subItemLabel: t('flocks.newFal.medications.details.administeredQuantity.label'),
              subItemValue: `${medicationDetails.quantity}${medicationDetails.measurementUnit}`,
            },
            {
              subItemLabel: t('flocks.newFal.medications.details.startTreatment.label'),
              subItemValue: `${moment(
                medicationDetails.startedDate,
              ).format('DD ̸̸  MM ̸̸  YYYY - HH:mm')}`,
            },
            {
              subItemLabel: t('flocks.newFal.medications.details.endTreatment.label'),
              subItemValue: `${moment(
                medicationDetails.endedDate,
              ).format('DD ̸̸  MM ̸̸  YYYY - HH:mm')}`,
            },
          ],
        },
        {
          description: medicationDetails.healthProblemName,
          icon: 'disease',
          subItems: [
            {
              subItemLabel: t('flocks.newFal.medications.details.code.label'),
              subItemValue: `${medicationDetails.healthProblemTypeId}`,
            },
          ],
        },
      ];
    }
  }, [medicationDetails]);

  const displayListItem = useCallback(
    (index, description, icon, subItems) => {
      return (
        <>
          <ListItem
            key={index}
            className="confirm-list__item animated fadeIn"
          >
            <div className="icon-wrapper">
              <span className={`icon icon-${icon}`} />
            </div>
            <div
              className="list-item-content"
              style={{ width: '100%' }}
            >
              <div className="list-item-description">
                {description}
              </div>
            </div>
          </ListItem>

          {subItems &&
            subItems.length > 0 &&
            subItems.map(item => (
              <ListItem className="confirm-list__item animated fadeIn">
                <div className="icon-wrapper">
                  <span className="icon" />
                </div>
                <div
                  className="list-item-content"
                  style={{ width: '100%' }}
                >
                  <div className="list-item-subitem list-item-description flex">
                    <p className="small">{item.subItemLabel}</p>
                    <p>{item.subItemValue}</p>
                  </div>
                </div>
              </ListItem>
            ))}
        </>
      );
    },
    [],
  );

  return (
    <>
      <List className="confirm-list" style={{ marginTop: '0' }}>
        {listArray &&
          listArray.length > 0 &&
          listArray.map((item, index) =>
            displayListItem(
              index,
              item.description,
              item.icon,
              item.subItems,
            ),
          )}
      </List>

      <Separator />

      {/* \ Botões */}
      <BtnGroup className="button-group--bottom">
        <Button
          key="submit"
          className="primary"
          onClick={() => setMedicationDetails(null)}
        >
          {t('global.button.back')}
        </Button>
      </BtnGroup>
      {/* \ Botões */}
    </>
  );
}
