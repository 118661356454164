import styled from 'styled-components';
import {
  DEFAULT_SIZE,
  fontFamilyText,
  colorClear,
  colorGray,
  boxShadow,
  breakpointTablet,
  colorGrayDarkest,
  colorGrayLight,
  colorGrayLighter,
  colorGrayLightest,
  fontFamilyTextBold,
  colorDanger,
} from 'components/GlobalStyles/variables';

export const ScheduledStyled = styled.section`
  position: relative;
`;

export const Container = styled.div`
  max-width: 652px;
  position: relative;
  padding: 0 ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px 20px 60px 20px;
  }

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }
`;

export const Item = styled.div`
  position: relative;
  margin: ${props => (props.margin ? '0 0 24px' : '0')};

  &:last-child {
    margin: ${props => (props.margin ? '0' : '0')};
    .history-list & {
      margin: 0 0 16px;
    }
  }

  .additional {
    margin-left: 40px;
  }

  .history {
    &::after {
      position: absolute;
      content: '';
      width: 1px;
      top: 30px;
      left: 12px;
      height: calc(100% - 10px);
      background-color: ${colorGrayLightest};
    }
  }

  .icon {
    margin: -20px 16px 0 0;
  }

  p {
    color: ${colorGrayDarkest};
    margin: 0;

    strong {
      margin-bottom: 4px;
      display: block;
    }

    &.normal {
      font-size: 14px;
      line-height: 21px;
    }

    &.small {
      font-size: 12px;
      line-height: 16px;
      color: ${colorGrayLight};
    }
  }
`;

export const Bullet = styled.span`
  position: relative;
  top: -8px;
  width: 6px;
  height: 6px;
  display: inline-block;
  background-color: ${colorGrayLight};
  border-radius: 50%;
  margin: 0 0 0 -16px;
`;

export const HistoryList = styled.div`
  position: relative;
  max-height: ${props => (props.isOpen ? '5000px' : '0')};
  transition: 0.3s max-height, margin-bottom ease;
  overflow: hidden;
  margin-bottom: 0;
`;

export const ToggleHistory = styled.button`
  font-family: ${fontFamilyTextBold};
  position: relative;
  color: ${colorGrayLighter};
  display: block;
  cursor: pointer;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  .feed-holder-icon {
    font-size: 20px;
    width: 30px;
    display: inline-block;
    margin-right: 10px;
  }

  .mini-heading {
    color: ${colorDanger};
  }
`;
