import { Button } from '@zendeskgarden/react-buttons';
import Container from 'components/Container';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { DropdownField } from 'components/Forms/Fields';
import t from 'components/i18n';
import React, { useState } from 'react';
// styles
import { Separator } from './Checklists.styled';

const FlockSelector = ({
  flocks,
  selectedFlock,
  onPickFlock,
  onGoBack,
}) => {
  const [selectingFlock, setselectingFlock] = useState(null);
  const hasFlocks = flocks && flocks.length;

  return (
    <Container className="container" hideDetail slim>
      <h5>{t('checklist.flockSelector.title')}</h5>
      <Separator style={{ marginBottom: 14 }} />
      <div style={{ minHeight: '200px' }}>
        <DropdownField
          field="flock"
          label={t('checklist.flockSelector.field.flock.label')}
          placeholder={
            hasFlocks
              ? t('checklist.flockSelector.field.flock.content.placeholder')
              : t('checklist.flockSelector.field.flock.no-content.placeholder')
          }
          onSelect={item => setselectingFlock(item)}
          options={
            flocks && flocks.length
              ? flocks.map(flock => ({ id: flock }))
              : []
          }
          optionLabel="id"
          values={{ flock: selectingFlock }}
          hideMessage
          disabled={!hasFlocks}
        />
      </div>
      <Separator style={{ marginBottom: 14 }} />
      <BtnGroup>
        <Button
          key="sendtoConfirm"
          onClick={() => onPickFlock(selectingFlock.id)}
          className="tertiary"
          disabled={!selectingFlock}
        >
          {t('global.button.next')}
        </Button>
        <Button key="clean" onClick={onGoBack} className="white">
          {t('global.button.cancel')}
        </Button>
      </BtnGroup>
    </Container>
  );
};

export default FlockSelector;
