import React from 'react';
import SidebarRight, {
  SidebarRightContent,
  SidebarToggler,
} from 'components/SidebarRight';
import t from 'components/i18n';
import {
  Menu,
  TabletMenu,
} from 'containers/AgroBRF/FlockList/Flock/Flock.styled';

export default function TemporaryFlockMenu(props) {
  const {
    getHeading,
    getTabletToggler,
    getToggler,
    isTablet,
    isMobile,
    isGtMobile,
    selectedKey,
    getFlockDetails,
    type,
    data,
    user,
  } = props;

  // show add option only if there are available houses
  const showAddOption =
    data &&
    type === 'open' &&
    data.houseInformation &&
    data.houseInformation.length > 0 &&
    user.profile !== 'Assistente';

  return (
    <div className="flock-menu">
      {isTablet && (
        <TabletMenu>
          {getTabletToggler('deceasedSummary')}
          {showAddOption && getTabletToggler('addDeceased')}
        </TabletMenu>
      )}

      {!isTablet && (
        <>
          <Menu>
            {isMobile && (
              <div className="menu-group">
                <SidebarRight>
                  <SidebarRightContent
                    title={getHeading(selectedKey)}
                  >
                    {getFlockDetails()}
                  </SidebarRightContent>
                </SidebarRight>
              </div>
            )}

            <div className="menu-group">
              {isMobile && (
                <SidebarRight>
                  <SidebarToggler>
                    {getToggler('deceasedSummary')}
                  </SidebarToggler>
                  <SidebarToggler>
                    {showAddOption && getToggler('addDeceased', true)}
                  </SidebarToggler>
                  <SidebarToggler>
                    {getToggler('medicationSummary', true)}
                  </SidebarToggler>
                  <SidebarToggler>
                    {showAddOption &&
                      getToggler('addMedication', true)}
                  </SidebarToggler>
                  <SidebarRightContent
                    title={getHeading(selectedKey)}
                  >
                    {getFlockDetails()}
                  </SidebarRightContent>
                </SidebarRight>
              )}

              {isGtMobile && (
                <>
                  <br />
                  <h4 className="menu-heading">{t("agro.flock.swineMenu.mortality.title")}</h4>
                  {getToggler('deceasedSummary')}
                  {showAddOption && getToggler('addDeceased', true)}
                  <br />
                  <h4 className="menu-heading">{t("agro.flock.swineMenu.medication.title")}</h4>
                  {getToggler('medicationSummary')}
                  {user.type === 'E' &&
                    showAddOption &&
                    getToggler('addMedication')}
                </>
              )}
            </div>
          </Menu>
        </>
      )}
    </div>
  );
}
