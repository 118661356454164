import styled from 'styled-components';
import { breakpointTablet } from 'components/GlobalStyles/variables';

export const Container = styled.div`
  header {
    h2 {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.15px;

      @media (min-width: ${breakpointTablet}px) {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }

  .stay-tuned {
    // max-width: 652px;
  }
  min-height: 37rem;
  .stay-tuned-holder {
    min-height: 160px;
  }

  .more {
    font-size: 13px;

    .icon {
      font-size: 20px;
      position: relative;
      top: 2px;
    }
  }
`;

export const Separator = styled.hr`
  margin: 16px -16px 15px;
  opacity: 0.12;
`;
