import i18next from 'i18next';

export function getFeedIcon(type) {
  const types = {
    broiler: 'icon-chicken',
    turkey: 'icon-turkey',
    pig: 'icon-pig',
    swine: 'icon-pig',
  };

  return types[type];
}

export function getFlockIcon(type) {
  const types = {
    next: 'icon-calendar-alt',
    feed: 'icon-truck',
    placement: 'icon-warehouse',
    slaughter: 'icon-chicken',
    add: 'icon-plus',
    deceasedSummary: 'icon-pig-alt',
    addDeceased: 'icon-plus',
    summary: 'icon-scale',
    payment: 'icon-chart-analytics',
    closure: 'icon-money',
    medicationSummary: 'icon-medkit',
    addMedication: 'icon-plus',
    invoice: 'icon-file',
  };

  return types[type];
}

export function getHeading(key) {
  const types = {
    next: i18next.t(
      'flocks.flocksActivities.sectionNextEvents.title',
    ),
    feed: i18next.t(
      'flocks.flocksActivities.sectionHistoric.button.feed',
    ),
    placement: i18next.t(
      'flocks.flocksActivities.sectionHistoric.button.placement',
    ),
    slaughter: i18next.t(
      'flocks.flocksActivities.sectionHistoric.button.slaughter',
    ),
    add: i18next.t('flock.header.add.title'),
    addDeceased: i18next.t('flock.header.addDeceased.title'),
    summary: i18next.t('flock.header.summary.title'),
    deceasedSummary: i18next.t('flock.header.deceasedSummary.title'),
    confirmation: i18next.t('flock.header.confirmation.title'),
    editDeceased: i18next.t('flock.header.editDeceased.title'),
    editMedication: i18next.t('flock.header.editMedication.title'),
    deceasedConfirmation: i18next.t(
      'flock.header.deceasedConfirmation.title',
    ),
    medicationDetails: i18next.t(
      'flock.header.medicationDetails.title',
    ),
    payment: i18next.t(
      'flocks.flocksDetail.sectionFlockStatement.title',
    ),
    closure: i18next.t(
      'flocks.flocksDetail.sectionRemuneration.button.partialClosure',
    ),
    medicationSummary: i18next.t(
      'flock.header.medicationSummary.title',
    ),
    addMedication: i18next.t('flock.header.addMedication.title'),
    invoice: i18next.t('flockDetails.sectionRemuneration.button.seeInvoice'), 
  };

  return types[key];
}

export function getBoxType(key) {
  const types = {
    feed: 'primary',
    placement: 'tertiary',
    slaughter: 'danger',
    payment: 'danger',
  };

  return types[key];
}

export function getTopBadgeText(flockType) {
  const types = {
    open: 'flocks.tabMenu.open',
    closed: 'flocks.tabMenu.closed',
    close: 'flocks.tabMenu.closed',
  };

  return types[flockType];
}

export function getTopIconColor(flockType) {
  const types = {
    open: 'success',
    closed: 'danger',
    close: 'danger',
  };

  return types[flockType];
}

export function sumOfItems(arr, key, tofixed) {
  const reduced = arr.reduce((acc, curr) => {
    const currentKey =
      (curr[key] && curr[key].split(' ')[2]) || curr[key];

    if (!currentKey) {
      return 0;
    }

    return acc + parseFloat(currentKey.replace(',', '.'));
  }, 0);

  if (tofixed) {
    return reduced.toFixed(3) * 1000;
  }

  return reduced;
}
