// @flow
import React from 'react';
import Icon from 'components/Icon';
import * as routes from 'config/routes';
import uid from 'uuid';
import { BC, Path, Button } from './BreadCrumb.styled';

export default function Breadcrumb(props) {
  const navigationClick = x => {
    if (
      x.pathname.indexOf('flock-list') > -1 ||
      x.pathname.indexOf('fal') > -1
    ) {
      props.history.push(routes.FLOCK_LIST);
    }

    if (x.pathname.indexOf('collaborator') > -1) {
      props.history.push(routes.COLLABORATOR);
    }

    if (x.pathname.indexOf('cadec') > -1) {
      props.history.push(routes.CADEC);
    }

    if (x.pathname.indexOf('announcement') > -1) {
      props.history.push(routes.ANNOUNCEMENTS);
    }

    if (x.pathname.indexOf('insurance') > -1) {
      props.history.push(routes.INSURANCE);
    }
  };

  const path = props.path.map((text, i) => {
    const noLink = i === props.path.length - 1 ? 'noLink' : '';
    return (
      <Path
        key={uid()}
        className="breadcrumb-path"
        onClick={() => {
          if (
            props.links &&
            props.links.length > 0 &&
            props.links[i]
          ) {
            if (typeof props.links[i] === 'function') {
              props.links[i]();
            } else {
              props.history.push(props.links[i]);
            }
          } else if (i === 0) {
            navigationClick(props.history.location);
          }
        }}
      >
        {i !== 0 && <span>/</span>}
        <p className={noLink}>{text}</p>
      </Path>
    );
  });

  return (
    <BC>
      <Button
        onClick={() =>
          props.backTo ? props.backTo() : props.history.goBack()
        }
      >
        <Icon className="icon icon-back" name="icon-arrow-left" />
      </Button>
      {path}
    </BC>
  );
}
