import React, {
  useState,
  useRef,
  useEffect,
  useMemo
} from 'react';

import Icon from 'components/Icon';
import { colorGrayLighter } from 'components/GlobalStyles/variables';

import { Menu, Item, ItemLabel } from './Components.styled';
import useClickOutsideHook from './useClickOutsideHook';
import { useTranslation } from 'react-i18next'

import * as routes from 'config/routes';
import SVG from 'react-inlinesvg';
import Eye from '../assets/eye.svg';
import Download from '../assets/download.svg';
import { getAttachment } from '../service';

const DropDown = (props) => {
  const {
    id,
    history,
    blobName
  } = props;

  const { t } = useTranslation();

  const [visibility, setVisibility] = useState(false);
  const [attachment, setAttachment] = useState(null);

  useEffect(() => {
    if (blobName && visibility) {
      getInvoiceAttachment(blobName)
    }
  }, [blobName, visibility]);

  const wrapperRef = useRef(null);

  const getInvoiceAttachment = async (blobName) => {
    const image64 = await getAttachment(blobName);
    setAttachment(image64);
  }

  useClickOutsideHook(wrapperRef, () => setVisibility(false));

  const image = useMemo(() => attachment, [attachment]);

  return (
    <div style={{ position: 'relative' }} ref={wrapperRef}>
      <Icon
        name="icon-ellipsis-v"
        size={24}
        color={colorGrayLighter}
        style={{
          cursor: 'pointer',
          fontSize: '24px'
        }}
        onClick={() => setVisibility(state => !state)}
      />

      {visibility && (
        <Menu
          hasArrow
          style={{
            position: 'absolute',
            right: '0rem',
            width: 'fit-content',
            minWidth: '145px',
            backgroundColor: '#FFFFFF',
            borderRadius: '8px'
          }}
        >
          <Item onClick={() => history.push(`${routes.FINANCIALS_DETAILS}/${id}`)}>
            <SVG src={Eye} />
            <ItemLabel>{t('next.invoiceManagement.button.seeDetails')}</ItemLabel>
          </Item>
          <a
            href={image}
            download="image.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Item disabled={!image}>
              <SVG src={Download} />
              <ItemLabel>{t('next.invoiceManagement.button.downloadFile')}</ItemLabel>
            </Item>
          </a>
        </Menu>
      )}
    </div>
  );
};

export default DropDown;
