import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import {
  Item,
  Menu,
  Label,
  Field,
} from '@zendeskgarden/react-dropdowns';
import { Message } from '@zendeskgarden/react-forms';
import { Dots } from '@zendeskgarden/react-loaders';
import {
  getValidationMessage,
  getValidation,
} from 'shared/utils/Helpers';
import { DropdownBox, AutocompleteBox } from '../Forms.styled';

export default function AutoCompleteDropdownField(props) {
  const {
    caption = false,
    onSelect,
    fetchMethod,
    initialValuesCount,
    optionsMapper,
    label,
    optionLabel,
    noMatchLabel,
    disabled,
    loadingLabel,
    placeholder,
    aditionalStyle,
    field,
    values,
    errors,
    touched,
  } = props;

  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState(null);
  const [intervalValue, setIntervalValue] = useState(null);

  const filterMatchingOptionsRef = useRef(
    debounce((value, intervalValue) => {
      async function fetch() {
        try {
          setLoading(true);

          const getSearch = () => {
            if (values[field]) {
              return {search: values[field].vendorCode};
            } else if (value && value.length) {
              return {search: intervalValue && intervalValue.vendorCode || value}
            } else {
              return null;
            }
          }

          const searchValue = getSearch();

          const payload = {
            limit: options === null ? 10 : initialValuesCount,
            ...(searchValue && searchValue)
          }

          const response = await fetchMethod(payload);
  
          if (response.success) {
            setOptions(optionsMapper(response.data));
          }
        } catch (_) {
        } finally {
          setLoading(false);
        }
      }
  
      fetch();

    }, 300)
  );

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue, intervalValue);
  }, [inputValue, intervalValue]);

  const renderOptions = () => {
    if (loading) {
      return null;
    }

    if (!options || !options.length) {
      return <Item disabled>{noMatchLabel}</Item>;
    }

    return options.map((option, index) => (
      <Item key={index} value={option}>
        {!caption && <p>{option[optionLabel]}</p>}
        {caption && (
          <p>
            {option[optionLabel]} - {option[caption]}
          </p>
        )}
      </Item>
    ));
  };

  return (
    <DropdownBox
      selectedItem={values[field]}
      onSelect={(item, b) => {
        if (values[field] && values[field].vendorCode === item.vendorCode) {
          onSelect(null);
          setInputValue(null);
          setIntervalValue(null);
        } else {
          onSelect(item);
          setInputValue(null);
          setIntervalValue(null);
        }
      }}
      onInputValueChange={(inputVal, event) => {
        setIntervalValue(event.selectedItem || inputVal);
        return setInputValue(event.selectedItem ? event.selectedItem.vendorCode : inputVal);
      }}
      focusInset={false}
      isBare={true}
    >
      <Field style={aditionalStyle}>
        <Label style={{ fontWeight: 500 }}>{label}</Label>

        <AutocompleteBox disabled={disabled}>
          {values[field] || inputValue ? (
            <span>{values[field] && values[field][optionLabel] || inputValue}</span>
          ) : (
            <span className="placeholder">{placeholder}</span>
          )}
        </AutocompleteBox>
      </Field>

      <Menu>
        {loading && (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100px'
          }}>
            <Dots size={16}/>
          </div>
        )}
        {renderOptions()}
      </Menu>

      {errors && errors.length && (
        <Message
          validation={getValidation(
            values[field],
            errors,
            field,
            !touched[field],
            )}
            >
          {getValidationMessage(field, errors, !touched[field])}
        </Message>
      )}
    </DropdownBox>
  );
}
