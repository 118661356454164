import NPMPackage from '../../package.json';
import config from '../config.json';

export const { name, title, description } = NPMPackage;
export const { endpoints, RECAPTCHA_KEY, KONG_CLIENT_ID } = config;

export const API_URL = `${endpoints.API_GATEWAY_URL}/domain/v1/api`;

export const API_EVENT_URL = `${endpoints.API_GATEWAY_URL}/event/v1/api`;

export const API_PRODUCER_URL = `${endpoints.API_GATEWAY_URL}/producer/v1/api`;

export const API_REPORT_URL = `${endpoints.API_GATEWAY_URL}/report/v1/api`;

export const API_SUPERVISOR_URL = `${endpoints.API_GATEWAY_URL}/supervisor/v1/api`;

export const API_TRANSLATIONS_URL = `${endpoints.API_GATEWAY_URL}/domain/v1/api/translations`;

export const POLLING_INTERVAL = 10000;
export const ALLOW_NOTIFY_KEY = 'allow_notify_on';
