import styled from 'styled-components';
import {
  breakpointTablet,
  breakpointDesktop,
} from 'components/GlobalStyles/variables';

const width = '254px';
const narrowWidth = '64px';

export const Aside = styled.aside`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(37, 48, 71, 0.08);
  background-color: ${props =>
    props.themeStyle.palette.colorGrayDarkest};
  z-index: 999;
  transition: width 0.5s ease-in-out, min-width 0.5s ease-in-out,
    opacity 0.5s ease-in-out;

  /* Initial display */
  top: 0;
  left: 0;
  width: 0;
  min-width: 0;
  height: 100vh;
  opacity: 0;
  overflow: hidden;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
    height: 56px;
  }

  &.narrow {
    width: ${narrowWidth};
    min-width: ${width};
  }

  &.is-open {
    top: 0;
    left: 0;
    width: 100vw;
    min-width: 100vw;
    opacity: 1;
    z-index: 999;
  }

  @media (min-width: ${breakpointTablet}px) {
    opacity: 1;
    overflow: inherit;
    width: ${narrowWidth};
    min-width: ${narrowWidth};
  }

  @media (min-width: ${breakpointDesktop}px) {
    width: ${width};
    min-width: ${width};
  }
`;
