import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';

import { HIDE_ALERT, SHOW_ALERT } from 'components/Alert/types';

export const initialState = {
  message: false,
};

export default {
  alert: handleActions(
    {
      [HIDE_ALERT]: (state, { payload }) =>
        immutable(state, {
          message: { $set: false },
        }),
      [SHOW_ALERT]: (state, { payload: { message } }) =>
        immutable(state, {
          message: { $set: message },
        }),
    },
    initialState,
  ),
};
