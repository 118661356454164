// @flow

import React, { useState, useMemo, useEffect } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import { PROFILE } from 'config/routes';
import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';
import Container from 'components/Container';
import LoadingHolder from 'components/LoadingHolder';
import Modal from 'components/Forms/FormModal/Modal';
import PersonalDataForm from 'containers/AgroBRF/Forms/PersonalDataForm';
import t from 'components/i18n';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { updateUserLgpd, getUserLgpd } from '../../service';
import { ProfileStyled } from '../../ProfileAndSettings.styled';

const getValidateSchema = field => {
  const validateSchema = () => {
    if (field) {
      if (field === 'email') {
        return yup.object().shape({
          email: yup
            .string()
            .email(t('global.validation.invalid-email'))
            .required(t('global.status.required-field')),
        });
      }

      return yup.object().shape({
        [field]: yup
          .string()
          .typeError(t('global.validation.invalid-value'))
          .required(t('global.status.required-field')),
      });
    }

    return yup.object().shape();
  };

  return validateSchema;
};

export default function EditPersonalData({
  match: { params },
  history,
  setEditUser,
  setSelected,
  isValidating,
  ...props
}) {
  const [data, setData] = useState(null);
  const [formSlice, setFormSlice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await getUserLgpd();
        setData(response);
        if (response) setIsLoading(false);
      } catch (e) {
        console.error('Fetch error', e);
      }
      setIsLoading(false);
    }

    fetchData();
  }, []);

  const successMessage = useMemo(
    () => t('global.validation.info-success-save'),
    [t],
  );

  const modalErrorMessage = useMemo(
    () =>
      t('global.validation.error-saving-info'),
    [t],
  );

  const initialValues = useMemo(
    () => ({
      birthDate: data && moment(data.birthDate).format('YYYY-MM-DD'),
      email: data && data.email,
      integratedDate:
        data && moment(data.integratedDate).format('YYYY-MM-DD'),
      pictureBlobName: data && data.pictureBlobName,
      phoneNumber: data && data.phoneNumber,
    }),
    [data],
  );

  function submitForm(
    values,
    { setSubmitting, setErrors, isValidating },
  ) {
    const formData = {
      birthDate: values.birthDate ? moment(values.birthDate) : null,
      email: values.email || null,
      integratedDate: values.integratedDate
        ? moment(values.integratedDate)
        : null,
      pictureBlobName: values.pictureBlobName || null,
      pictureBlobContainer: 'test',
      phoneNumber: values.phoneNumber || null,
    };

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await updateUserLgpd(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }

    sendData(formData);
  }

  const personalData = useMemo(
    () => [
      {
        field: 'birthDate',
        title: t(
          'menu.profile.personalData.buttonSingleLine.link.birthDate',
        ),
      },
      {
        field: 'email',
        title: t(
          'menu.profile.personalData.buttonSingleLine.link.emailAddress',
        ),
      },
      {
        field: 'integratedDate',
        title: t(
          'menu.profile.personalData.sectionIntegration.buttonSingleLine.link.growerSince',
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    if (params.slice && personalData) {
      setFormSlice(
        personalData.find(
          item => item.field.toLowerCase() === params.slice,
        ),
      );
    }
  }, [params.slice, personalData]);

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() =>
          history.push(`${PROFILE}/account-settings/personal-data`)
        }
        links={[
          `${PROFILE}/account-settings`,
          `${PROFILE}/account-settings`,
          `${PROFILE}/account-settings/personal-data`,
        ]}
        path={[
          t('menu.profile.title'),
          t('menu.profile.sectionAccountConfig.Title'),
          t('menu.profile.personalData.title'),
          formSlice && formSlice.title,
        ]}
      />

      <Top>
        <h2>{formSlice && formSlice.title}</h2>
      </Top>

      <ProfileStyled>
        <Container className="container" hideDetail>
          <LoadingHolder
            message={t('global.loading')}
            isLoading={isLoading}
          />
          {!isLoading && data && (
            <Formik
              initialValues={initialValues}
              validate={validate(
                getValidateSchema(formSlice && formSlice.field),
              )}
              onSubmit={submitForm}
              render={formikProps => (
                <PersonalDataForm
                  {...formikProps}
                  isValidating={isValidating}
                  message={errorMessage}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  success={success}
                  params={params}
                  {...props}
                />
              )}
            />
          )}
        </Container>
      </ProfileStyled>

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
          history.push(`${PROFILE}/account-settings`);
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>{success ? successMessage : modalErrorMessage}</p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
