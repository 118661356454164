/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { getAllCadecDocuments } from 'containers/AgroBRF/Cadec/service';
import { ModalFilter } from 'components/Filter';
import { ThemeContext } from 'styled-components';
import NoContentTab from 'components/NoContentTab';
import LoadingHolder from 'components/LoadingHolder';
import {
  Close,
  RoundedItem,
} from 'components/MultiSelect/Items.styled';
import t from 'components/i18n';
import AtaItem from './AtaItem';
import { Separator } from '../Cadec.styled';

export default function AtaList(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [typeFilter, setTypeFilter] = useState(null);
  const [segmentFilter, setSegmentFilter] = useState(null);
  const [centerFilter, setCenterFilter] = useState(null);
  const [typeList, setTypeList] = useState([]);
  const [segmentList, setSegmentList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredContent, setFilteredContent] = useState([]);
  const filters = [segmentFilter, typeFilter, centerFilter];
  const hasData = data.length > 0;
  const themeContext = useContext(ThemeContext);

  const user = useSelector(state => state.user);

  async function fetchData() {
    setIsLoading(true);

    try {
      const response = await getAllCadecDocuments();
      setData(response);
    } catch (e) {
      console.error('Error on getCadecDocuments', e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [props.updateItems]);

  /*
   * apply filters
   */
  useEffect(() => {
    let filteredBySegment = [];
    let filteredByType = [];
    let filteredByCenter = [];

    if (centerFilter) {
      filteredByCenter = data.filter(
        e => e.cadecs[0].center === centerFilter,
      );
    }

    if (typeFilter) {
      filteredByType = data.filter(
        e => e.cadecs[0].animalType === typeFilter,
      );
    }

    if (segmentFilter) {
      filteredBySegment = data.filter(
        e => e.cadecs[0].segment === segmentFilter,
      );
    }

    if (filters.filter(e => e !== null).length) {
      setIsFiltered(true);

      const ids = data.filter(
        el =>
          (!filteredByCenter.length ||
            filteredByCenter.flat().find(x => x.id === el.id)) &&
          (!filteredBySegment.length ||
            filteredBySegment.flat().find(x => x.id === el.id)) &&
          (!filteredByType.length ||
            filteredByType.flat().find(x => x.id === el.id)),
      );

      setFilteredContent(ids);
    } else {
      setFilteredContent(data);
    }
  }, [typeFilter, segmentFilter, centerFilter, filters, data]);

  /*
   * clear filters
   */
  useEffect(() => {
    if (!isFiltered) {
      setCenterFilter(null);
      setTypeFilter(null);
      setSegmentFilter(null);
      setFilteredContent(data);
    }
  }, [data, isFiltered]);

  useEffect(() => {
    setFilteredContent(data);

    if (data && data.length > 0) {
      setCenterList([...new Set(data.map(e => e.cadecs[0].center))]);
      setTypeList([
        ...new Set(data.map(e => e.cadecs[0].animalType)),
      ]);
      setSegmentList([
        ...new Set(data.map(e => e.cadecs[0].segment)),
      ]);
    }
  }, [data]);

  return (
    <>
      <LoadingHolder isLoading={isLoading} />

      {!isLoading && hasData && (
        <div className="cadec-list">
          <ModalFilter
            theme={{
              bgButton: themeContext.styles.palette.colorPrimary,
              text: themeContext.styles.palette.colorClear,
            }}
            title={t("cadec.filters.title")}
            buttonLabel={t("cadec.filters.title")}
            list={[
              {
                label: 'center',
                arrayList: centerList,
                action: setCenterFilter,
                placeholder: t('cadec.filters.options.producer'),
              },
              {
                label: 'typeName',
                arrayList: typeList,
                action: setTypeFilter,
                placeholder: t('cadec.filters.options.animalType'),
              },
              {
                label: 'segmentList',
                arrayList: segmentList,
                action: setSegmentFilter,
                placeholder: t('cadec.filters.options.segment'),
              },
            ]}
            clearFilters={() => setIsFiltered(false)}
          />

          {centerFilter &&
            [centerFilter].map(
              (item, i) =>
                item && (
                  <RoundedItem key={i}>
                    <p>{item}</p>
                    <Close
                      onClick={() => {
                        setCenterFilter(null);
                      }}
                    >
                      <span className="icon icon-close-circle" />
                    </Close>
                  </RoundedItem>
                ),
            )}

          {segmentFilter &&
            [segmentFilter].map(
              (item, i) =>
                item && (
                  <RoundedItem key={i}>
                    <p>{item}</p>
                    <Close
                      onClick={() => {
                        setSegmentFilter(null);
                      }}
                    >
                      <span className="icon icon-close-circle" />
                    </Close>
                  </RoundedItem>
                ),
            )}

          {typeFilter &&
            [typeFilter].map(
              (item, i) =>
                item && (
                  <RoundedItem key={i}>
                    <p>{item}</p>
                    <Close
                      onClick={() => {
                        setTypeFilter(null);
                      }}
                    >
                      <span className="icon icon-close-circle" />
                    </Close>
                  </RoundedItem>
                ),
            )}

          <Separator />
          {filteredContent.map(item => (
            <AtaItem
              key={item.id}
              ata={item}
              link={`${API_URL}/Blob/Download?fileName=${item.fileName}&blobContainerName=${item.container}&token=${user.token}&client_id=${KONG_CLIENT_ID}`}
              name={item.name}
              goTo={props.goTo}
              iconBox="turkey"
              setDisplayModal={() => props.setDisplayModal()}
              whiteBgIcon
            />
          ))}
        </div>
      )}

      {!isLoading && !filteredContent.length && (
        <NoContentTab
          icon="icon-file"
          onButtonClick={() => !hasData && props.goTo('addAta')}
          buttonLabel={!hasData && t("cadec.no-content.button.start")}
        >
          {t('global.no-content')}
        </NoContentTab>
      )}
    </>
  );
}
