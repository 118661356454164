import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import moment from 'moment';
import { scrollToTop } from 'shared/utils/Helpers';
import Top from 'components/Top';
import Box from 'components/Container';
import t from 'components/i18n';
import * as routes from 'config/routes';
import WeekCalendar from 'components/WeekCalendar';
import DateHighLight from 'components/DateHighLight';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import LogItem from './LogItem';
import { Button } from '@zendeskgarden/react-buttons';
import LogBookDetails from './LogBookDetails';
import { getStatus } from './utils';
import { getLogBookItems, getLogBookItemDetails } from './service';
import {
  LogBookStyled,
  Container,
  LogList,
  LogBookWrapper,
} from './LogBook.styled';

export default function LogBook(props) {
  const {
    match,
    history,
    user,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [week, setWeek] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [logBookItems, setLogBookItems] = useState([]);
  const [filteredLogBook, setFilteredLogBook] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const logList = useRef();
  const logBookId = match.params.id;
  const isNextUser = user.isNextUser;

  const getLogBookList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getLogBookItems();
      setLogBookItems(response);
    } catch (e) {
      console.error('Error on get logbook list', e);
    }
    setIsLoading(false);
  }, []);

  const getDetails = useCallback(async id => {
    setIsLoadingDetails(true);
    try {
      const response = await getLogBookItemDetails(id);
      setSelectedItem(response);
    } catch (e) {
      console.error('Error on get logbook details', e);
    }
    setIsLoadingDetails(false);
  }, []);

  const filterDataByDate = useCallback((dateToFilter, list) => {
    const filtered = list.filter(
      e =>
        moment(e.foreseenDate).format('DD ̸̸  MM ̸̸  YYYY') ===
        moment(dateToFilter).format('DD ̸̸  MM ̸̸  YYYY'),
    );

    if (!dateToFilter) {
      setFilteredLogBook(list);
    } else {
      setFilteredLogBook(filtered);
    }
  }, []);

  const filterDataById = useCallback((id, list) => {
    const filtered = list.filter(e => Number(e.id) === Number(id));

    if (!filtered.length) {
      setFilteredLogBook(list);
    }
  }, []);

  useEffect(() => {
    getLogBookList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logBookItems && logBookItems.length > 0) {
      // initial list
      setFilteredLogBook(logBookItems);

      const firstDate = logBookItems.filter(
        item =>
        moment(item.foreseenDate).isSame(moment(), 'day') &&
        moment(item.foreseenDate).isSame(moment(), 'month')
      );

      setSelectedDate(
        firstDate.length > 0
          ? firstDate[0]
          : moment().format('YYYY-MM-DD'),
      );

      // filter content by id if there's url param
      if (logBookId) {
        filterDataById(logBookId, logBookItems);
        getDetails(logBookId);

        if (filteredLogBook.length > 0) {
          setSelectedDate(filteredLogBook[0].foreseenDate);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logBookItems]);

  useEffect(() => {
    if (selectedDate && logBookItems.length > 0) {
      filterDataByDate(selectedDate, logBookItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, logBookItems]);

  useEffect(() => {
    const filteredLogBookitems = logBookItems.filter(
      e => selectedItem.id === e.id,
    );

    if (selectedItem && filteredLogBookitems.length > 0) {
      selectedItem.logBookItemType =
        filteredLogBookitems[0].logBookItemType;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const renderCalendar = () => {
    const hasLogBookItems = logBookItems.length;

    const startDate = hasLogBookItems
      ? logBookItems[0].foreseenDate
      : moment().toISOString();
    const finishDate = hasLogBookItems
      ? logBookItems[logBookItems.length - 1].foreseenDate
      : moment().toISOString();

    return (
      <WeekCalendar
        startDate={startDate}
        finishDate={finishDate}
        date={selectedDate}
        id={match.params.id}
        onSelectDate={e => {
          setSelectedDate(e);

          if (logList && logList.current) {
            scrollToTop(logList.current);
          }
        }}
        markedDates={(logBookItems || []).map(e =>
          moment(e.foreseenDate).format(),
        )}
        history={history}
        setNextWeek={() => setWeek(week + 1)}
        setPrevWeek={() => setWeek(week - 1)}
        bgColor="colorPrimary"
        allDatesAvailable
        activateFirst
      />
    );
  };

  return (
    <LogBookStyled
      className="log-book__container"
      bgColor="colorPrimary"
    >
      <Top>
        <h2>{t('next.logBook.title')}</h2>
          <Button
            className="tertiary"
            onClick={() => history.push(routes.NEW_EVENT)}
          >
            {t('logbook.creatEvent.button')}
          </Button>
      </Top>

      <Container>
        <Box>
          <LogBookWrapper className="logbook__wrapper">
            <LoadingHolder isLoading={isLoading} />

            {!isLoading && renderCalendar()}

            {!isLoading && !logBookItems.length && (
              <NoContentTab icon="icon-calendar" hideButton>
                <p>
                  {t('global.no-content')}
                </p>
              </NoContentTab>
            )}

            {!isLoading && logBookItems.length > 0 && (
              <>
                <LogList
                  id="loglist"
                  ref={logList}
                  scrollable={logBookItems.length > 3}
                >
                  {filteredLogBook.length > 0 &&
                    filteredLogBook.map((item, index) => {
                      const monthDay = moment(
                        item.foreseenDate,
                      ).format('DD');
                      const weekDay = moment(
                        item.foreseenDate,
                      ).format('ddd');

                      return (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            cursor: 'pointer',
                          }}
                          onClick={() => getDetails(item.id)}
                        >
                          <DateHighLight
                            monthDay={monthDay}
                            weekDay={weekDay}
                          />
                          <LogItem
                            {...item}
                            overline={t(item.logBookItemType)}
                            title={item.vendorName}
                            subtitle={t("next.logBook.item.subtitle", { value: item.vendorCode })}
                            color={getStatus(item.status).color}
                            bgColor={
                              item.status === 0 ? 'colorClear' : null
                            }
                            houseList={item.listLogBookItemHouse}
                            icon={getStatus(item.status).icon}
                            justification={item.justification}
                            epidemiologicalCore={
                              item.epidemiologicalCore && item.epidemiologicalCore !== '-1' && item.epidemiologicalCore !== -1 &&
                              t('next.logBook.item.epidemiologicalCore', { value: item.epidemiologicalCore })
                            }
                            isRandom={item.isRandom}
                            createdAt={item.createDate}
                            listLogBookItemFlock={
                              item.listLogBookItemFlock
                            }
                          />
                        </div>
                      );
                    })}
                </LogList>

                {!filteredLogBook.length && (
                  <NoContentTab icon="icon-calendar" hideButton>
                    <p>
                      {t('next.logBook.no-content.pt1')}<br />{' '}
                      <span className="lighter">
                        {t('next.logBook.no-content.pt2')}
                      </span>
                    </p>
                  </NoContentTab>
                )}

                {selectedItem && (
                  <LogBookDetails
                    data={selectedItem}
                    setDisplayBox={setSelectedItem}
                    displayBox={selectedItem}
                    getLogBookList={getLogBookList}
                    getDetails={getDetails}
                    isLoading={isLoadingDetails}
                    history={history}
                  />
                )}
              </>
            )}
          </LogBookWrapper>
        </Box>
      </Container>
    </LogBookStyled>
  );
}
