import React from 'react';
import moment from 'moment';
import { NEXT_ORIENTATION } from 'config/routes';
import CardWrapper from 'components/CardWrapper';
import { OrientationItem } from 'containers/NextBRF/Orientation';
import OrientationEditCard from './OrientationEditCard';
import t from 'components/i18n';

export default function OpenVisit({ data, history, getDetails }) {
  return (
    <div className="primary">
      {!data.orientation && (
        <OrientationEditCard getDetails={getDetails} data={data} />
      )}

      {data.orientation && (
        <CardWrapper title="Orientação técnica">
          <OrientationItem
            description={data.orientation}
            action={() =>
              history.push(`${NEXT_ORIENTATION}/${data.id}`)
            }
            date={moment(data.visitDate).format('DD ̸̸  MM ̸̸  YYYY HH:mm')}
            icon={data.blobNameList.length > 0}
            footerContent={
              data.read
                ? t('next.visitList.futureVisit.footer.read', { value: moment(data.readDate).format('DD ̸̸  MM ̸̸  YYYY HH:mm') })
                : t('next.visitList.futureVisit.footer.sent')
            }
            footerIcon="icon-check"
            footerColor="colorInfo"
          />
        </CardWrapper>
      )}
    </div>
  );
}
