import React, { useContext } from 'react';
import t from 'components/i18n';
import { Row, Col } from '@zendeskgarden/react-grid';
import Badge from 'components/Badge';

// Contexts
import { WeighingContext } from 'shared/contexts';
// Styles
import {
  Container,
  ItemContainer,
  FeedItem,
} from './FlockHistory.styled';

export default function Summary(props) {
  const { feed, goTo } = props;
  const weighingContext = useContext(WeighingContext);
  const { setSelectedWeight } = weighingContext.actions;
  const badgeProps = {};

  function selectItem(option) {
    setSelectedWeight(feed);
    goTo(option);
  }

  if (feed.status) {
    switch (feed.status.toLowerCase()) {
      case 'pendente':
        badgeProps.info = true;
        break;
      default:
        badgeProps.success = true;
    }
  }

  function getStatusMessage(status) {
    if (status.toLowerCase() === 'enviado') {
      return t('global.status.sent');
    }

    if (status.toLowerCase() === 'concluído') {
      return t('global.status.done');
    }

    if (status.toLowerCase() === 'pendente') {
      return t('global.status.pending');
    }

    return status;
  }

  return (
    <Container className="bordered-container">
      <ItemContainer
        className="item-container"
        onClick={() => selectItem('confirmation')}
      >
        <div className="open summary">
          <FeedItem key={`feed-item-${feed.id}`} summary="true">
            <header className="item-header">
              <div className="date">{feed.date}</div>
              <div className="status">
                {feed.status && (
                  <Badge {...badgeProps}>
                    {getStatusMessage(feed.status)}
                  </Badge>
                )}
              </div>
            </header>
            <Row>
              <Col>
                <strong>{t("summary.weight.value", { value: feed.weightValue || feed.value })}</strong>
                <p>
                  {feed.gpd
                    ? t('summary.weight.dailyGain', {
                      value: feed.gpd,
                    })
                    : t('summary.weight.deaths', {
                      value: feed.totalDeaths,
                    })}
                </p>
              </Col>
              <Col className="align-right">
                <p className="small">
                  {feed.houseValue
                    ? t('summary.weight.house', {
                      value: feed.houseValue,
                    })
                    : ' '}
                </p>
                <p className="small">
                  {feed.house?  t('flocks.flocksDetail.sectionLastestRecords.listView.item.houseNumber',
                  {houses: feed.house}):
                    t('summary.weight.days', {
                      value: feed.ageValue,
                    })}
                </p>
              </Col>
            </Row>
          </FeedItem>
        </div>
      </ItemContainer>
    </Container>
  );
}
