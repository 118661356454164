import t from "components/i18n";

export const animalTypes = [
  {
    label: t('form.next.settings.utils.animalTypes.options.chicken'),
    value: 'frango',
  },
  {
    label: t('form.next.settings.utils.animalTypes.options.swine'),
    value: 'suino',
  },
  {
    label: t('form.next.settings.utils.animalTypes.options.turkry'),
    value: 'peru',
  },
];

export const broilerTypes = [
  {
    label: t('form.next.settings.utils.broilerTypes.options.light'),
    value: 'Leve',
  },
  {
    label: t('form.next.settings.utils.broilerTypes.options.heavy'),
    value: 'Pesado',
  },
];

export const turkeyMaterial = [
  {
    label: t('form.next.settings.utils.turkeyMaterial.options.whole-female'),
    value: 'Fêmea inteira',
  },
  {
    label: t('form.next.settings.utils.turkeyMaterial.options.cutting-female'),
    value: 'Fêmea corte',
  },
  {
    label: t('form.next.settings.utils.turkeyMaterial.options.cutting-male'),
    value: 'Macho corte',
  },
];

export const swineMaterial = [
  {
    label: t('form.next.settings.utils.swineMaterial.options.normal'),
    value: 'Normal',
  },
  {
    label: t('form.next.settings.utils.swineMaterial.options.parma'),
    value: 'Parma',
  },
  {
    label: t('form.next.settings.utils.swineMaterial.options.tend'),
    value: 'Tender',
  },
];

export const animalQuantity = [
  {
    label: t('form.next.settings.utils.animalQuantity.options.less-600-heads'),
    value: 'P',
  },
  {
    label: t('form.next.settings.utils.animalQuantity.options.from-601-1200-heads'),
    value: 'M',
  },
  {
    label: t('form.next.settings.utils.animalQuantity.options.more-than-1200-heads'),
    value: 'G',
  },
];
