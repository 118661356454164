import t from 'components/i18n';

const creationMapper = (item = '') => {
  return {
    ['frango']: t('animalType.chicken'),
    ['suíno']: t('animalType.swine'),
    ['suino']: t('animalType.swine'),
    ['peru']: t('animalType.turkey'),
    ['todos']: t('creationType.option.all'),
    ['todos']: t('creationType.option.all'),
    ['all']: t('creationType.option.all'),
    ['broiler']: t('animalType.chicken'),
    ['turkey']: t('animalType.turkey'),
    ['swine']: t('animalType.swine'),
  }[item.toLowerCase()] || item;  
}

const statusMapper = (item = '') => {
  return {
    ['confirmado']: t('global.status.confirmed'),
    ['concluído']: t('global.status.done'),
    ['concluido']: t('global.status.done'),
    ['previsto']: t('global.status.foreseen'),
    ['realizado']: t('global.status.real'),
    ['pendente']: t('global.status.pending'),
  }[item.toLowerCase()] || item;  
}

const regionalMapper = (item = '') => {
  return {
    ['sul']: t('regional.br.south'),
    ['centro oeste']: t('regional.br.midwest'),
    ['central']: t('regional.br.central'),
    ['turquia']: t('regional.tr.turkey'),
    ['todas']: t('regional.all'),
    ['todas as regiões']: t('regional.all'),
    ['todos as regiões']: t('regional.all'),
    ['todos as regionais']: t('regional.all'),
    ['all']: t('regional.all'),
  }[item.toLowerCase()] || item;  
}

const questionTitleMapper = (item = '') => {
  return {
    ['sim']: t('circuitQuestion.defaultButton.yes'),
    ['não']: t('circuitQuestion.defaultButton.no'),
    ['não se aplica']: t('circuitQuestion.defaultButton.doNotApply'),
  }[item.toLowerCase()] || item;  
}

export {
  creationMapper,
  statusMapper,
  regionalMapper,
  questionTitleMapper
};
