// @flow

import React from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import t from 'components/i18n';

import {
  Heading,
  Subheading,
  TitleBlock,
} from 'components/SideBox/SideBox.styled';
import Spacer from 'components/Spacer';

function Success(props) {
  props.accessor.nextStep = props.nextStep;

  function submitForm(values, actions) {
    props.userPasswordRedefined();
  }

  const buttonProps = {
    stretched: true,
    primary: true,
    type: 'button',
    onClick: submitForm,
  };

  return (
    <div>
      <TitleBlock>
        <Heading>{t('recoveryPassword.success.title')}</Heading>
        <Subheading>
          {t('recoveryPassword.success.description')}
        </Subheading>
      </TitleBlock>

      <Spacer multiplier={28} />

      <Button {...buttonProps}>{t('global.button.next')}</Button>
    </div>
  );
}

export default Success;
