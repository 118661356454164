import { API_SUPERVISOR_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getFlockList(id) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Flock/GetFlockList?vendorId=${id}`,
    {
      method: 'GET',
    },
  );
  return response;
}

export async function getFlockScreen(id) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Flock/GetFlockScreenInformation?flockId=${id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getFlockLabel(id) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Flock/GetFlockLabelInformation?flockId=${id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}
