import t from 'components/i18n';
import { deleteAnnouncement } from './service';

export async function deleteItem(id, setRemoved) {
  try {
    const response = await deleteAnnouncement(id);
    setRemoved(response);
  } catch (error) {
    console.error(error);
  }
}

export function getAnimalType(type) {
  const types = {
    broiler: t('announcements.utils.animalType.broiler'),
    turkey: t('announcements.utils.animalType.turkey'),
    swine: t('announcements.utils.animalType.swine'),
  };

  return types[type];
}
