import React, { useState, useEffect } from 'react';
import * as routes from 'config/routes';
import LoadingHolder from 'components/LoadingHolder';
import Breadcrumb from 'components/Breadcrumb';
import { List, ListItem } from 'components/List';
import CircleAndTitle from 'components/CircleAndTitle';
import { useSelector } from 'react-redux';
import t from 'components/i18n';
import NextFlockTopMenu from './NextFlockTopMenu';

// service
import { getFlockList } from './service';

export default function NextFlock({
  match: { params },
  history,
  responsive: { isMobile, isTablet, isGtMobile },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const user = useSelector(state => state.user);
  const backRoute =
    user && user.type === 'E' ? routes.VENDORS : routes.VENDORS_TABLE;

  const isNewLayout = params.new === 'new';

  useEffect(() => {
    async function getList(id) {
      setIsLoading(true);
      try {
        const response = await getFlockList(id);
        setData(response.data);
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    }

    getList(params.id);
  }, [params.id]);

  if (!data) {
    return null;
  }

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() => history.push(backRoute)}
        path={[t('next.flock.bc.growers'), data.vendorName]}
        links={[backRoute]}
      />

      <List>
        <LoadingHolder isLoading={isLoading} />

        {!isLoading && data && (
          <ListItem noMargin>
            <CircleAndTitle
              name={data.vendorName}
              description={t('next.flock.vendor-code', { value: data.vendorCode })}
              initials={data.vendorInitials}
            />
          </ListItem>
        )}
      </List>

      <NextFlockTopMenu
        data={data}
        history={history}
        user={user}
        isLoading={isLoading}
        isNewLayout={isNewLayout}
        isMobile={isMobile}
        isTablet={isTablet}
        isGtMobile={isGtMobile}
      />
    </>
  );
}
