import styled from 'styled-components';
import {
  borderRadius,
  DEFAULT_SIZE,
} from '../GlobalStyles/variables';

export const ImageWrapper = styled.div`
  img {
    position: relative;
    overflow: hidden;
    width: 150px;
    min-width: 150px;
    height: 150px;
    min-height: 150px;
    margin: 0 0 ${DEFAULT_SIZE * 2}px 0;
    border-radius: ${borderRadius};
    background-position: center center;
    background-size: cover;

    div {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
`;
