// @flow

import React, { useState, useEffect } from 'react';
import logger from 'shared/utils/logger';
import Tabs, { Tab, TabContent, TabHeader } from 'components/Tabs';
import IconPlaceholder from 'components/IconPlaceholder';
import t from 'components/i18n';
import Icon from 'components/Icon';
import { Dots } from '@zendeskgarden/react-loaders';
import { colorPrimary } from 'components/GlobalStyles/variables';
import Top from 'components/Top';
import BrowserHistory from 'shared/history';
import Pagination from 'components/Pagination';
import * as routes from 'config/routes';
import { List, ListItem } from 'components/List';
import NoContentTab from 'components/NoContentTab';
import {
  getFlockInvoice,
  getFlockListOpen,
  getFlockListClosed,
} from './service';
import {
  Container,
  Content,
  Main,
  Initials,
  StatusWrapper,
  SlaugthConfirmed,
} from './FlockList.styled';
import FlockStatusInvoice from 'components/Invoice/FlockStatusInvoice';

function getIcon(type) {
  const types = {
    broiler: 'icon-chicken',
    turkey: 'icon-turkey',
    pig: 'icon-pig',
    swine: 'icon-pig',
  };

  return types[type];
}

function SlaugthConfirmedStatus() {
  return (
    <SlaugthConfirmed>
      <span className="icon icon-check-circle" />
      {t('flock.badge.slaughterExecuted')}
    </SlaugthConfirmed>
  );
}

function GetList(props) {
  const { type, cpf, history } = props;

  const [data, setData] = useState([]);
  const [metadata, setMetadata] = useState(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const hasData = Boolean(data && data.length);

  const [internalData, setInternalData] = useState([]);

  useEffect(() => {
    const method = {
      ['openFlockList']: getFlockListOpen,
      ['closedFlockList']: getFlockListClosed,
    }[type];

    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await method(cpf || '', page, limit);

        setData(response.flocks);
        setMetadata(response.metadata);
      } catch (e) {
        logger('Fetch error', e);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [limit, page]);

  useEffect(() => {
    if (!hasData) {
      return;
    }

    Promise.all(
      data.map(async item => {
        if (!item.isSlaughtered) {
          return item;
        }

        const invoice = await getFlockInvoice(item.flock);
        return {
          ...item,
          invoice,
        };
      }),
    ).then(data => setInternalData(data));
  }, [data]);

  return (
    <List>
      <div>
        {isLoading && (
          <ListItem className="centered animated fadeIn">
            <Dots color={colorPrimary} size="16" />
          </ListItem>
        )}

        {!isLoading && !hasData && (
          <NoContentTab icon="icon-flock">
            <p>{t('flocks.empty.title')}</p>
          </NoContentTab>
        )}

        {!isLoading && hasData && (
          <>
            {internalData.map(item => (
              <ListItem
                role="button"
                key={item.id}
                onClick={() =>
                  history.push(`${routes.FLOCK_LIST}/${item.id}`)
                }
                className="animated fadeIn flock-list__item"
                noMargin
              >
                <IconPlaceholder
                  className={`top icon-holder ${
                    type === 'openFlockList' ? 'success' : 'danger'
                  }`}
                >
                  <Icon className="icon" name={getIcon(item.type)} />
                </IconPlaceholder>

                <div className="list-item-content">
                  <h2 className="list-item-heading">
                    {t('flocks.listView.item.title', {
                      flockNumber: item.flock,
                    })}
                  </h2>
                  <p className="list-subheading">
                    {t('flocks.listView.item.subtitle', {
                      growerCode: item.vendorCode,
                    })}
                  </p>
                  <p className="list-item-description">
                    {t('flocks.listView.item.overline', {
                      houses: item.house.value,
                    })}
                  </p>
                  {item.isSlaughtered && item.status !== "closed" && (
                    <StatusWrapper>
                      <SlaugthConfirmedStatus />
                      <FlockStatusInvoice
                        status={item.invoice.status}
                      />
                    </StatusWrapper>
                  )}
                </div>
                <Icon name="chevron icon-chevron-right" />
              </ListItem>
            ))}
            {metadata && (
              <div style={{ maxWidth: 652, marginTop: '16px' }}>
                <Pagination
                  totalPages={Math.ceil(metadata.totalItems / limit)}
                  pagePadding={2}
                  currentPage={page}
                  onChangePage={x => setPage(x.e)}
                />
              </div>
            )}
          </>
        )}
      </div>
    </List>
  );
}

export default function ProducerFlockList(props) {
  const { cpf = '', history, isVendor, vendor } = props;

  const [activeTab, setActiveTab] = useState('openFlockList');

  return (
    <>
      <Container className="producer-flock-list">
        {!isVendor ? (
          <Top>
            <h2>{t('dashboard.bottomNavigation.itemTwo')}</h2>
          </Top>
        ) : (
          <Top className="vendor">
            {vendor && (
              <ListItem className="animated fadeIn">
                <Initials>{vendor.initialsName}</Initials>

                <div className="list-item-content">
                  <div className="list-item-heading">
                    {vendor.name}
                  </div>

                  <div className="list-item-description">
                    {t(vendor.description.key, {
                      value: vendor.description.value,
                    })}
                  </div>
                </div>
              </ListItem>
            )}
          </Top>
        )}
        <Content style={{ maxWidth: '676px' }}>
          <Main>
            <Tabs>
              <Tab>
                <TabHeader
                  customClick={() => setActiveTab('openFlockList')}
                >
                  {t('flocks.tabMenu.open')}
                </TabHeader>
                <TabContent>
                  {activeTab === 'openFlockList' ? (
                    <GetList
                      type="openFlockList"
                      cpf={cpf}
                      history={history}
                    />
                  ) : null}
                </TabContent>
              </Tab>

              <Tab>
                <TabHeader
                  customClick={() => setActiveTab('closedFlockList')}
                >
                  {t('flocks.tabMenu.closed')}
                </TabHeader>
                <TabContent>
                  {activeTab === 'closedFlockList' ? (
                    <GetList
                      type="closedFlockList"
                      cpf={cpf}
                      history={history}
                    />
                  ) : null}
                </TabContent>
              </Tab>
            </Tabs>
          </Main>
        </Content>
      </Container>
    </>
  );
}
