import React from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { DropdownField, InputField } from 'components/Forms/Fields';
import {
  Heading,
  Subheading,
  Separator,
  Spacer,
} from 'containers/NextBRF/WeekSchedule/WeekSchedule.styled';
import t from 'components/i18n';

export default function NewVisitForm(props) {
  const {
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue,
    isValid,
    values,
    errors,
    touched,
  } = props;

  return (
    <Form autoComplete="off">
      <div className="animated fadeIn">
        <Heading>{t('form.next.newVisit.section.title')}</Heading>

        <Subheading>
          {t('form.next.newVisit.section.subtitle')}
        </Subheading>

        {/* Tipo de criação */}
        <DropdownField
          field="logBookItemType"
          label={t('form.next.logBook.changeDateForm.field.logBookItemType.label')}
          placeholder={t('form.next.logBook.changeDateForm.field.logBookItemType.placeholder')}
          onSelect={item => setFieldValue('logBookItemType', item)}
          options={[
            {
              label: t('form.next.logBook.changeDateForm.field.logBookItemType.options.fal-conference'),
              labelTranslated: t('form.next.logBook.changeDateForm.field.logBookItemType.options.fal-conference'),
            },
            {
              label: t('form.next.logBook.changeDateForm.field.logBookItemType.options.pre-slaughter'),
              labelTranslated: t('form.next.logBook.changeDateForm.field.logBookItemType.options.pre-slaughter'),
            },
            {
              label: t('form.next.logBook.changeDateForm.field.logBookItemType.options.other'),
              labelTranslated: t('form.next.logBook.changeDateForm.field.logBookItemType.options.other'),
            },
          ]}
          optionLabel="labelTranslated"
          values={values}
          errors={errors}
          touched={touched}
        />
        {/* \ Tipo de criação  */}

        {values.logBookItemType &&
          values.logBookItemType.label === 'Outro tipo de visita' && (
            <InputField
              field="anotherTypeOfVisit"
              type="text"
              label={t('form.next.logBook.changeDateForm.field.anotherTypeOfVisit.label')}
              placeholder={t('form.next.logBook.changeDateForm.field.anotherTypeOfVisit.placeholder')}
              onChange={e => handleChange(e)}
              values={values}
              errors={errors}
              touched={touched}
            />
          )}

        <br />
        <Heading>{t('form.next.newVisit.age.section.title')}</Heading>

        <Subheading>
          {t('form.next.newVisit.age.section.subtitle')}
        </Subheading>

        <Row>
          {/* Idade inicial */}
          <Col sm={6}>
            <div className="flex">
              <InputField
                field="daysStart"
                type="number"
                label={t('form.next.logBook.changeDateForm.field.daysStart.label')}
                placeholder={t('form.next.logBook.changeDateForm.field.daysStart.placeholder')}
                onChange={handleChange}
                setState={e => setFieldValue('daysStart', e)}
                min={0}
                values={values}
                errors={errors}
                touched={touched}
              />

              <Spacer>a</Spacer>
            </div>
          </Col>
          {/* \  Idade inicial   */}

          {/*  Idade final  */}
          <Col sm={6}>
            <div className="flex">
              <InputField
                field="daysEnd"
                hiddenLabel
                placeholder={t('form.next.logBook.changeDateForm.field.daysEnd.placeholder')}
                onChange={handleChange}
                setState={e => setFieldValue('daysEnd', e)}
                min={0}
                values={values}
                errors={errors}
                touched={touched}
              />

              <Spacer>{t('form.next.logBook.changeDateForm.field.daysEnd.label')}</Spacer>
            </div>
          </Col>
          {/* \  Idade final   */}
        </Row>

        <Separator />

        {/* \ Botões */}
        <BtnGroup>
          <Button
            key="sendtoConfirm"
            className="tertiary"
            onClick={e => handleSubmit(e)}
            disabled={!isValid}
          >
            {t("global.button.save")}
          </Button>
          <Button
            key="clean"
            onClick={() => resetForm()}
            className="white"
          >
            {t('global.button.cancel')}
          </Button>
        </BtnGroup>
        {/* \ Botões */}
      </div>
    </Form>
  );
}
