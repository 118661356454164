import React from 'react';
import Modal from 'components/Forms/FormModal/Modal';
import t from 'components/i18n';

export default function ExcludeModal({
  opened,
  setOpened,
  mainFunction,
}) {
  return (
    <Modal
      warning
      opened={opened}
      confirm={e => {
        mainFunction(e);
        setOpened(false);
      }}
      onCloseModal={() => setOpened(false)}
    >
      <div className="modal-title">
        <span className="icon icon-exclamation-triangle" />

        <h4>{t('form.next.settings.delete-visit.title')}</h4>
      </div>

      <p>
        {t('form.next.settings.delete-visit.description-1')}
        <br />
        {t('form.next.settings.delete-visit.description-2')}
      </p>
    </Modal>
  );
}
