import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import * as routes from 'config/routes';
import Top from 'components/Top';
import IconAndText from 'components/IconAndText';
import LoadingHolder from 'components/LoadingHolder';
import Breadcrumb from 'components/Breadcrumb';
import CardWrapper from 'components/CardWrapper';
import FutureVisit from './FutureVisit';
import PastVisit from './PastVisit';
import { VisitStyled } from './VisitStyled.styled';
import { getLogBookItemDetails } from '../LogBook/service';
import t from 'components/i18n';

export default function OpenVisit({ history, match: { params } }) {
  const [isLoading, setIsLoading] = useState(null);
  const [data, setData] = useState(null);
  const [isFuture, setIsFuture] = useState(false);

  const getDetails = useCallback(async id => {
    setIsLoading(true);
    try {
      const response = await getLogBookItemDetails(id);
      setData(response);
    } catch (e) {
      console.error('Error on get logbook details', e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getDetails(params.id);
  }, [getDetails, params]);

  useEffect(() => {
    if (data) {
      setIsFuture(!data.logBookItemStatus);
    }
  }, [data]);

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() => history.push(routes.LOGBOOK)}
        path={[t('next.visitList.openVisit.bc.logbook'), t('next.visitList.openVisit.bc.visit')]}
        links={[routes.LOGBOOK]}
      />

      <LoadingHolder isLoading={isLoading} />

      {data && (
        <>
          <Top>
            <IconAndText
              title={data.visitType}
              iconBackGround="colorPrimary"
              icon="icon-calendar-alt"
              subtitle={moment(data.visitDate).format('DD ̸̸  MM ̸̸  YYYY')}
            />
          </Top>

          <VisitStyled>
            <div className="secondary">
              <CardWrapper title={t('next.visitList.openVisit.content.openFlock')}>
                <ul>
                  {data.flocks.map((e, index) => {
                    return (
                      <li>
                        <span>{t("next.visitList.flock", { value: e.flock })}</span>
                        <span className="right">
                          {e.age}{' '}
                          {e.age.length === 1 ? t('next.visitList.openVisit.content.day') : t('next.visitList.openVisit.content.days')}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </CardWrapper>
            </div>

            {isFuture && (
              <FutureVisit
                data={data}
                history={history}
                getDetails={getDetails}
              />
            )}

            {!isFuture && (
              <PastVisit
                data={data}
                history={history}
                getDetails={getDetails}
              />
            )}
          </VisitStyled>
        </>
      )}
    </>
  );
}
