import React from 'react';
import RoundContainer from 'components/RoundContainer';
import { CardWrapperStyled, Title } from './CardWrapper.styled';

export default function CardWrapper({
  title,
  children,
  onClick,
  style,
}) {
  return (
    <CardWrapperStyled onClick={onClick} style={style}>
      <RoundContainer padding="16px">
        {title && <Title>{title}</Title>}
        {children}
      </RoundContainer>
    </CardWrapperStyled>
  );
}
