/* eslint-disable */
function log(...args) {
  if (process.env.NODE_ENV === 'development') {
    // console.info(...args);
  }
}

function logger(k, v) {
  return log(k, v);
}

export default logger;
/* eslint-enable */
