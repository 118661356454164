import styled from 'styled-components';
import {
  boxShadow,
  fontFamilyText,
  fontFamilyTextBold,
  colorGray,
  colorClear,
  colorPrimary,
  colorGrayLightest,
  colorGrayLight,
  colorGrayDarkest,
  breakpointTablet,
  DEFAULT_SIZE,
} from 'components/GlobalStyles/variables';

export const AnnouncementsStyled = styled.section`
  position: relative;
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;
  margin: ${props =>
    props.gap && `${props.gap}px -${DEFAULT_SIZE * 2}px`};
`;

export const Subheading = styled.strong`
  color: ${colorGrayDarkest};
  line-height: 24px;
  font-size: ${props => (props.small ? `14` : `16`)}px;
`;

export const AHref = styled.a`
  display: flex;
  align-items: center;
`;

export const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 8rem;
`;

export const Container = styled.div`
  position: relative;
  padding: 0 ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};
  max-width: 630px;

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px 0 30px 20px;
  }

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }

  ${Subheading} {
    display: block;
    font-family: ${fontFamilyTextBold};
    font-weight: normal;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
  }
`;

export const DateAndTime = styled.span`
  display: inline-block;
  color: ${colorGrayLight};
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 16px;
  margin-bottom: 16px;
  padding-top: 40px;
`;

export const Title = styled.h3`
  display: block;
  font-family: ${fontFamilyTextBold};
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px !important;
`;

export const ContentWrapper = styled.div`
  font-size: 14px;
  p {
    color: ${colorGrayDarkest};
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 24px;
    overflow-wrap: break-word;
  }

  iframe {
    width: 100%;
    height: 236px;

    @media (min-width: ${breakpointTablet}px) {
      height: 316px;
    }
  }

  .image-wrapper {
    padding: 0;
    border: none;
    margin: 0 0 16px 0;
  }
`;

export const Attachments = styled.div`
  margin: 0 0 32px;
  padding-top: 32px;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    font-size: 14px;
    line-height: 21px;
    list-style: none;
    display: inline-flex;
    align-items: center;
    border: 1px solid ${colorGrayLightest};
    border-radius: 16px;
    font-size: 14px;
    cursor: pointer;
    margin: 0 12px 12px 0;
    word-break: break-word;
    height: 3.25rem;
    width: 12rem;
    display: flex;
    align-items: center;
    padding-left: 0.35rem;

    .icon {
      color: ${colorClear};
      margin-right: 4px;
      position: relative;
      font-size: 16px;

      &:before {
        background: ${colorPrimary};
        padding: 4px;
        border-radius: 50%;
        transition: 0.2s ease-in opacity;
      }
    }
    a {
      color: ${colorGrayDarkest};
      transition: 0.2s ease-in color;

      &:hover {
        color: ${colorPrimary};

        .icon {
          &:before {
            opacity: 0.8;
          }
        }
      }
    }
  }
`;

export const Source = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 16px;

  p {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
  }

  span.label {
    color: ${colorGrayLight};
    display: block;
    float: left;
    width: 92px;
  }

  > div {
    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  a {
    p {
      color: ${colorPrimary};
    }
  }
`;

export const Image = styled.img`
  display: block;
  margin: 0 0 12px;
`;

export const Video = styled.div`
  figure {
    margin: 0;
  }
  .rdw-embedded-modal {
    height: auto;
    .rdw-embedded-modal-size {
      > span {
        width: 48%;
        input {
          width: 86% !important;
        }
      }
    }
    .rdw-embedded-modal-btn-section {
      align-self: center;
      margin-top: 7px;
      button {
        margin-left: 5px !important;
        float: none !important;
        width: 49% !important;
        height: 40px !important;
        line-height: 25px !important;
        border-radius: 8px !important;
      }
      button.rdw-embedded-modal-btn:disabled {
        border: 1px solid ${colorPrimary} !important;
        background-color: ${colorPrimary} !important;
        color: ${colorClear};
      }

      button:nth-child(2) {
        background-color: #fff !important;
        width: 128px !important;
        border: 1px solid #ece9e9 !important;
      }
    }
  }
`;
