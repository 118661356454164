// @flow
import { createActions } from 'redux-actions';
import { SET_USER, UNSET_USER, SET_ACCEPT_LGPD } from './types';

export const { setUser, unsetUser, setAcceptLgpd } = createActions({
  [SET_USER]: payload => ({
    ...payload,
  }),
  [UNSET_USER]: () => undefined,
  [SET_ACCEPT_LGPD]: payload => payload,
});
