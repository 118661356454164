import React from 'react';
import styled from 'styled-components';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import Icon from 'components/Icon';
import BecomeMemberImage from 'containers/OnePage/Login/assets/background_member_section_colors.jpg';

import {
  DEFAULT_SIZE,
  colorPrimary,
  colorGrayLighter,
  colorClear,
  colorGrayDarker,
  colorGrayDarkest,
  breakpointDesktop,
  breakpointTablet,
  fontFamilyHeading,
} from 'components/GlobalStyles/variables';
import t from 'components/i18n';

const BenefitItem = ({ title, description, icon }) => {
  return (
    <Row fluid alignItems="center">
      <Col size={2} justifyContent="center">
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: 25,
            backgroundColor: colorGrayLighter,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon name={icon} className="icon-benefit" />
        </div>
      </Col>
      <Col size={10}>
        <p className="benefit-title">{title}</p>
        <p
          className="benefit-description"
          style={{ color: colorGrayDarkest }}
        >
          {description}
        </p>
      </Col>
    </Row>
  );
};

const BecomeMember = props => {
  return (
    <SectionWrapper id="member">
      <div style={{ padding: 0, flex: 1 }}>
        <BenefitsContentWrapper>
          <div>
            <Dash />
            <h1 className="title">{t('lp.section.grower.title')}</h1>
            <h3 className="subtitle">
              {t('lp.section.grower.subtitle')}
            </h3>
            <BenefitItem
              title={t('lp.section.grower.list.item.quality.title')}
              description={t('lp.section.grower.list.item.quality.text')}
              icon="icon-check"
            />
            <BenefitItem
              title={t('lp.section.grower.list.item.inovation.title')}
              description={t('lp.section.grower.list.item.inovation.text')}
              icon="icon-corn"
            />
            <BenefitItem
              title={t('lp.section.grower.list.item.assistance.title')}
              description={t('lp.section.grower.list.item.assistance.text')}
              icon="icon-user"
            />
            <BenefitItem
              title={t('lp.section.grower.list.item.profit.title')}
              description={t('lp.section.grower.list.item.profit.text')}
              icon="icon-chart-line"
            />
          </div>
        </BenefitsContentWrapper>
      </div>
      <div style={{ padding: 0, flex: 1 }}>
        <div
          className="background-image"
          style={{
            backgroundImage: `url('${BecomeMemberImage}')`,
            backgroundSize: 'cover',
            height: '100%',
          }}
        >
          <ImageContetWrapper>
            <div>
              <h1 className="title">
                {t('lp.section.grower.item.title')}
              </h1>
              <p className="subtitle">
                {t('lp.section.grower.item.subtitle')}
              </p>
              <Button
                style={{
                  maxWidth: 300,
                  marginTop: 40,
                }}
                onClick={() =>
                  props.history.push('/seja-um-integrado')
                }
                className="integrado-button"
                type="button"
              >
                <span>{t('lp.section.grower.item.buttonPrimary')}</span>
              </Button>
            </div>
          </ImageContetWrapper>
        </div>
      </div>
    </SectionWrapper>
  );
};

export const Dash = styled.div`
  width: 50px;
  background-color: ${colorPrimary};
  height: 3px;
  margin: 56px 0 13px;
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  @media (max-width: ${breakpointTablet}px) {
    flex-direction: column;
  }

  @media (min-width: ${breakpointDesktop}px) {
    flex-direction: row;
  }
`;

export const ImageContetWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px 0 76px;
  max-width: 440px;
  height: 100%;

  @media (max-width: ${breakpointTablet}px) {
    padding: ${DEFAULT_SIZE * 20}px ${DEFAULT_SIZE * 3}px;
    margin: 0 auto;
  }

  h1.title {
    color: ${colorClear};
    font-size: 24px;
    line-height: 32px;
  }

  p.subtitle {
    color: ${colorClear};
    font-family: ${fontFamilyHeading};
    font-size: 15px;
    line-height: 22px;
    font-weight: normal;
    letter-spacing: 0.15px;
  }

  p.description {
    text-align: justify;
  }

  .integrado-button {
    background-color: ${colorPrimary} !important;
    border-color: ${colorPrimary} !important;

    @media (max-width: ${breakpointTablet}px) {
      margin: 0 auto;
      display: block;
    }
  }
`;

export const BenefitsContentWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 450px;
  float: right;
  padding: 0 72px 64px 24px;

  @media (max-width: ${breakpointTablet}px) {
    padding: 0 24px 64px;
    margin: 0 auto;
    float: none;
  }

  h1.title {
    color: ${colorGrayDarker};
    font-size: 24px;
  }

  h3.subtitle {
    color: ${colorGrayLighter};
    margin-top: 0px;
    font-size: 16px;

    @media (min-width: ${breakpointDesktop}px) {
      font-size: 20px;
    }
  }

  p.description {
    text-align: justify;
  }

  .icon-benefit {
    color: ${colorClear};
    font-size: 26px;
  }

  .benefit-title {
    color: purple;
    margin-bottom: 0px;
    font-weight: bold;
    padding-left: 8px;
  }

  .benefit-description {
    margin-top: 5px;
    font-size: 14px;
    padding-left: 8px;
  }
`;

export default BecomeMember;
