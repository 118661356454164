import styled from 'styled-components';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import rgba from 'polished/lib/color/rgba';
import {
  DEFAULT_SIZE,
  breakpointLargeDesktop,
  breakpointTablet,
  colorClear,
  colorPrimary,
  colorGrayUltraLight,
  colorGrayLight,
  colorGrayLighter,
  colorGrayLightest,
  colorGrayDarkest,
  colorSuccess,
  colorDanger,
  colorInfo,
  fontFamilyText,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

export const Container = styled.div`
  background-color: ${colorClear};
  position: relative;
  border: 1px solid ${colorGrayLightest};
  border-radius: ${DEFAULT_SIZE}px;
  margin-bottom: ${DEFAULT_SIZE * 2}px;
  box-shadow: 0px 4px 8px rgba(37, 48, 71, 0.16);
  width: 100%;
  transition: border-color 0.3s ease;
  overflow: hidden;

  &:hover {
    border-color: ${colorGrayLighter};
  }
`;

export const ItemContainer = styled.div`
  padding: 12px ${DEFAULT_SIZE * 2}px;
  .feed-history-holder {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    margin-top: 16px;

    > div {
      padding-right: 0 !important;
      margin-bottom: 16px;
    }

    &.open {
      max-height: 5000px;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .feed-show-history {
    margin-top: 0;

    button {
      display: flex;
      align-items: center;

      .mini-heading {
        margin: 0;
        margin-left: 30px;
        color: ${colorPrimary};
      }

      .feed-holder-icon {
        font-size: 24px;
        color: ${colorGrayLighter};
      }
    }
  }

  .columns {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (min-width: ${breakpointLargeDesktop}px) {
      flex-direction: row;
    }
  }
`;

export const ColumnInfo = styled.div`
  width: 100%;
  margin: 0 0 16px;
  padding: 0 24px;
  border-right: 1px solid ${rgba(colorGrayDarkest, 0.25)};

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    border-right: 0;
  }

  @media (min-width: ${breakpointLargeDesktop}px) {
    width: 33%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  &.stretch {
    display: flex;
    justify-content: space-between;

    @media (min-width: ${breakpointLargeDesktop}px) {
      display: inline-block;
    }

    ${BtnGroup} {
      flex-direction: row;

      @media (max-width: ${breakpointTablet}px) {
        display: block;
        width: 100%;
      }
    }
  }

  p {
    font-size: 12px;
    line-height: 32px;
    margin: 0;
    color: ${colorGrayLight};
  }

  strong {
    display: block;
    font-size: 14px;
    line-height: 32px;
    margin: 0;
    color: ${colorGrayDarkest};

    &.red {
      color: ${colorDanger};
    }

    .icon {
      font-size: 20px;
      position: relative;
      top: 3px;
    }
  }
`;

export const StatusFooter = styled.footer`
  padding: 12px ${DEFAULT_SIZE * 2}px;
  display: flex;
  justify-content: space-between;
  background-color: ${colorGrayUltraLight};

  p {
    font-family: ${fontFamilyTextBold};
    margin: 4px 0 0;
    font-size: 12px;
    line-height: 16px;
    color: ${props => {
      if ('info' in props) {
        return colorInfo;
      }
      if ('danger' in props) {
        return colorDanger;
      }
      if ('primary' in props) {
        return colorPrimary;
      }
      return colorSuccess;
    }};
  }
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: ${fontFamilyTextBold};
  margin-bottom: 16px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;

  span {
    font-family: ${fontFamilyText};
  }

  .icon {
    color: ${props => {
      if ('info' in props) {
        return colorInfo;
      }
      if ('danger' in props) {
        return colorDanger;
      }
      if ('primary' in props) {
        return colorPrimary;
      }
      return colorSuccess;
    }};
    font-size: 26px;
    display: inline-block;
    margin: 0 6px 0 0;
  }
`;
