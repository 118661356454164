import Loader from 'components/Loader';
import React, { useState, useEffect } from 'react';
import logger from 'shared/utils/logger';
import ProducerFlockList from './ProducerFlockList';
import { ThemeContext } from 'styled-components';
// service
import { getVendors } from './service';
import LoadingHolder from 'components/LoadingHolder';
import t from 'components/i18n';
import NoContentTab from 'components/NoContentTab';

export default function Vendor(props) {
  const [vendor, setVendor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const cpf = props.match.params.id;

  useEffect(() => {
    async function fetchVendors() {
      setIsLoading(true);
      try {
        const response = await getVendors(cpf);
        setVendor(response);
      } catch (e) {
        logger('Fetch error', e);
      } finally {
        setIsLoading(false);
      }
    }

    if (!vendor) {
      fetchVendors();
    }
  }, [vendor]);

  if (isLoading) {
    return (
      <LoadingHolder
        isLoading={isLoading}
        message={t('global.loading')}
      />
    )
  }
  
  if (cpf === "null" || (!isLoading && (!vendor || (vendor && !vendor.length)))) {
    return (
      <NoContentTab icon="icon-close-circle">
        <p>
          {t('global.no-content')}<br />{' '}
        </p>
      </NoContentTab>
    )
  }

  return (
    <ProducerFlockList
      {...props}
      isVendor
      cpf={cpf}
      vendor={vendor[0]}
    />
  );
}
