import React from 'react';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import { InputField } from 'components/Forms/Fields';
import { Separator } from 'containers/AgroBRF/Cadec/Cadec.styled';
import t from 'components/i18n';

export default function AccountVerifyForm({
  setFieldValue,
  handleSubmit,
  handleChange,
  handleBlur,
  isValid,
  values,
  errors,
  touched,
}) {
  return (
    <Form autoComplete="off">
      <Row>
        <Col md={12}>
          <h4>{t('accountVerify.form.title')}</h4>
          <p className="info">
            {t('accountVerify.form.description')}
          </p>

          <br />

          <InputField
            type="text"
            field="vendorCode"
            label={t('accountVerify.form.vendorCode.label')}
            placeholder={t('accountVerify.form.vendorCode.placeholder')}
            onBlur={handleBlur}
            onChange={e => {
              handleChange(e);
            }}
            setState={e => {
              setFieldValue('vendorCode', e);
            }}
            values={values}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>

      <Separator />

      {/* \ Botões */}
      <BtnGroup>
        <Button
          key="sendtoStage3"
          onClick={() => handleSubmit()}
          disabled={!isValid}
        >
          {t('global.button.next')}
        </Button>
      </BtnGroup>
      {/* \ Buttons */}
    </Form>
  );
}
