// @flow

import {
  API_URL,
  API_PRODUCER_URL,
} from 'config/constants';
import { request } from 'shared/utils/client';


export async function getFlockListOpen(code, page = 1, limit = 10) {
  const payload = {
    code,
    page,
    limit
  }

  const response = await request(
    `${API_URL}/Flock/GetFlockListOpen`,
    {
      method: 'GET',
      payload
    },
  );

  return response.data;
}

export async function getFlockListClosed(code, page = 1, limit = 10) {
  const payload = {
    code,
    page,
    limit
  }

  const response = await request(
    `${API_URL}/Flock/GetFlockListClosed`,
    {
      method: 'GET',
      payload
    },
  );

  return response.data;
}

export async function getFlockInformation<T>(
  flockID: Number,
  resumeType?: String,
): Promise<T> {
  const response = await request(
    `${API_URL}/Flock/GetFlockInformation`,
    {
      method: 'GET',
      payload: {
        flockID,
        resumeType,
      },
    },
  );

  return response.data;
}

export async function getFlockInvoice(flock) {
  const response = await request(
    `${API_URL}/FlockInvoice/GetFlockInvoiceByFlock`,
    {
      method: 'GET',
      payload: {
        flock
      },
    },
  );

  return response.data;
}

export async function getFlockAllInformation<T>(
  flockID: Number,
): Promise<T> {
  const response = await request(
    `${API_URL}/Flock/GetFlockAllInformation`,
    {
      method: 'GET',
      payload: {
        flockID,
      },
    },
  );

  return response;
}

export async function getVendors(cpf: Number) {
  const response = await request(
    `${API_URL}/Flock/GetVendors?code=${cpf}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function getFlockListSupervisor() {
  const response = await request(
    `${API_URL}/Flock/GetFlockListSupervisor`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function getAgeList(flockId, houseId) {
  const response = await request(
    `${API_PRODUCER_URL}/Weight/GetAgeList?flockId=${flockId}&houseId=${houseId}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function getSchedule() {
  const response = await request(`${API_URL}/Schedule/GetSchedule`, {
    method: 'GET',
  });

  return response.data;
}

export async function getFlockResumeCard(id) {
  const response = await request(
    `${API_URL}/Flock/GetFlockResumeCard?flockID=${id}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function getEventInformation(id) {
  const response = await request(
    `${API_URL}/Flock/GetEventInformation?flockID=${id}`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function saveWeight(payload) {
  const response = await request(
    `${API_PRODUCER_URL}/Weight/SaveWeight`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}

export async function getWeights(flockId) {
  const response = await request(
    `${API_PRODUCER_URL}/Weight/OldGetWeights?flockID=${flockId}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getWeightsResume(flockId) {
  const response = await request(
    `${API_PRODUCER_URL}/Weight/GetWeightsResume?flockID=${flockId}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function deleteWeight(flockId) {
  const response = await request(
    `${API_PRODUCER_URL}/Weight/DeleteWeight?id=${flockId}`,
    {
      method: 'DELETE',
    },
  );
  return response.data;
}

/** * FAL */

export async function getFalResume(flockId) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/GetFalResume?flockID=${flockId}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getFalTracking(flockId) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/GetFalTracking?flockID=${flockId}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getFalInformation(falId, date) {
  const url =
    date !== null
      ? `${API_PRODUCER_URL}/Fal/GetFalInformation?falId=${falId}&date=${date}`
      : `${API_PRODUCER_URL}/Fal/GetFalInformation?falId=${falId}`;
  const response = await request(url, {
    method: 'GET',
  });
  return response.data;
}

export async function putFalInformation(payload) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/PutFalInformation`,
    {
      method: 'PUT',
      payload,
    },
  );
  return response;
}

export async function getDeceasedTypes() {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/GetDeceasedTypes`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getFeedTypes(id) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/GetFeedTypes?falId=${id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getFalVendor(id) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/GetVendors?vendorToFind=${id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getAge(id, date) {
  const response = await request(
    `${API_PRODUCER_URL}/Fal/getAge?falId=${id}&date=${date}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

// swine mortality
export async function getSwineMortalityList(id) {
  const response = await request(
    `${API_PRODUCER_URL}/SwineDeceasedDiscarded/GetSwineMortality?flockId=${id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getSwineDeceasedDiscardedTypes(id) {
  const response = await request(
    `${API_PRODUCER_URL}/SwineDeceasedDiscarded/GetSwineDeceasedDiscardedTypes?flockId=${id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function swineDeceasedDiscarded(payload) {
  const response = await request(
    `${API_PRODUCER_URL}/SwineDeceasedDiscarded/Save`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}

export async function removeSwineMortality(id) {
  const response = await request(
    `${API_PRODUCER_URL}/SwineDeceasedDiscarded?id=${id}`,
    {
      method: 'DELETE',
    },
  );
  return response;
}

// medications
export async function getHealthProblems() {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetHealthProblems`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getMaterialTypes() {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetMaterialTypes`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getLaboratory() {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetLaboratory`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getResumeList(flockID) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetResumeList?flockId=${flockID}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getMedications(flockID) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetMedications?flockId=${flockID}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getMedicationDetails(medicationId) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetMedicationDetails?medicationId=${medicationId}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getLatestResume(flockID) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/GetLatestResume?flockId=${flockID}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function saveMedications(payload) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/SaveMedication`,
    {
      method: 'PUT',
      payload,
    },
  );
  return response;
}

export async function removeMedication(id) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/RemoveMedication?id=${id}`,
    {
      method: 'DELETE',
    },
  );
  return response;
}

export async function materialTypeFavorite(id, payload) {
  const response = await request(
    `${API_PRODUCER_URL}/Medications/MaterialTypeFavorite/${id}`,
    {
      method: 'PUT',
      payload,
    },
  );
  return response;
}

export async function getOrientations(id) {
  const response = await request(
    `${API_PRODUCER_URL}/LogBookItem/GetOrientations?flockId=${id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function markAsRead(payload) {
  const response = await request(`${API_URL}/Slaughter/MarkAsRead`, {
    method: 'POST',
    payload,
  });
  return response;
}

export async function getFeedCutDates() {
  const response = await request(
    `${API_PRODUCER_URL}/SlaughterCatch/GetFeedCutDates`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

export async function registerFeedCut(payload) {
  const response = await request(
    `${API_PRODUCER_URL}/SlaughterCatch/RegisterFeedCut`,
    {
      method: 'POST',
      payload,
    },
  );
  return response;
}
