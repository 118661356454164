import React, { useState, useEffect } from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Form } from 'components/Forms/Forms.styled';
import {
  TextAreaField,
  InputField,
  FileInput,
  // FileInput,
} from 'components/Forms/Fields';
import { Button } from '@zendeskgarden/react-buttons';
import {
  BtnGroup,
  Separator,
} from 'components/Forms/BtnGroup/BtnGroup.styled';

import PreviewImage from 'components/PreviewImage';
import t from 'components/i18n';
import { uploadFile } from 'shared/blob/service';

export default function ActionPlanForm({
  user,
  setDisplayPlan,
  handleFile,
  removeFile,
  isUploading,
  files,
  setFieldValue,
  handleChange,
  handleBlur,
  handleSubmit,
  // isEditing,
  resetForm,
  isValid,
  isValidating,
  pristine,
  values,
  errors,
  touched,
}) {
  const [image, setImage] = useState([]);
  const [file, setFile] = useState(null);

  return (
    <Form
      autoComplete="off"
      onSubmit={handleSubmit}
      className="action-plan-form"
    >
      <Row>
        <Col md={12}>
          <p className="textarea-label" style={{ marginTop: 0 }}>
            {t('form.next.actionmPlan.nonCompliance.label')}
          </p>

          <TextAreaField
            field="nonCompliance"
            setState={e => {
              setFieldValue('nonCompliance', e);
            }}
            placeholder={t(
              'form.next.actionmPlan.nonCompliance.placeholder',
            )}
            onBlur={e => handleBlur(e)}
            onChange={e => handleChange(e)}
            values={values}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <p className="textarea-label">
            {t('form.next.actionmPlan.nonCompliance.cause.label')}
          </p>

          <TextAreaField
            field="cause"
            setState={e => {
              setFieldValue('cause', e);
            }}
            placeholder={t(
              'form.next.actionmPlan.nonCompliance.cause.placeholder',
            )}
            onBlur={e => handleBlur(e)}
            onChange={e => handleChange(e)}
            values={values}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <p className="textarea-label">
            {t('form.next.actionmPlan.nonCompliance.action.label')}
          </p>

          <TextAreaField
            field="action"
            setState={e => {
              setFieldValue('action', e);
            }}
            placeholder={t(
              'form.next.actionmPlan.nonCompliance.action.placeholder',
            )}
            onBlur={e => handleBlur(e)}
            onChange={e => handleChange(e)}
            values={values}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <p className="textarea-label">
            {t('form.next.actionmPlan.nonCompliance.how.label')}
          </p>

          <TextAreaField
            field="how"
            setState={e => {
              setFieldValue('how', e);
            }}
            placeholder={t(
              'form.next.actionmPlan.nonCompliance.how.placeholder',
            )}
            onBlur={e => handleBlur(e)}
            onChange={e => handleChange(e)}
            values={values}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <p className="textarea-label">
            {t(
              'form.next.actionmPlan.nonCompliance.responsible.label',
            )}
          </p>

          <InputField
            type="text"
            field="responsible"
            label=""
            placeholder={t(
              'form.next.actionmPlan.nonCompliance.responsible.placeholder',
            )}
            onBlur={handleBlur}
            onChange={e => handleChange(e)}
            values={values}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p className="textarea-label">
            {t(
              'form.next.actionmPlan.nonCompliance.expectedDate.label',
            )}
          </p>

          <InputField
            type="date"
            field="expectedDate"
            label={null}
            placeholder={t(
              'form.next.actionmPlan.nonCompliance.expectedDate.placeholder',
            )}
            onChange={e => handleChange(e)}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <FileInput
            type="image"
            field="attachmentsUrls"
            label={t(
              'form.next.actionmPlan.nonCompliance.attachmentsUrls.label',
            )}
            onChange={event => {
              handleFile(event.target.files[0]);
            }}
            accept=".png, .jpeg, .pdf"
            removeFile={removeFile}
            isLoading={isUploading}
            itemsToList={files}
            blobContainerName="logbookattachment"
            itemLabel="fileName"
            onBlur={handleBlur}
            values={values}
            token={user.token}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>

      <Separator />

      <Row>
        {/* \ Botões */}
        <Col sm={6}>
          <BtnGroup noReverse>
            <Button
              key="clean"
              className="white"
              onClick={() => {
                setDisplayPlan(null);
              }}
            >
              {t('global.button.back')}
            </Button>
          </BtnGroup>
        </Col>
        <Col sm={6}>
          <BtnGroup>
            <Button
              type="submit"
              key="submitForm"
              className="tertiary"
              disabled={!isValid || pristine || isValidating}
            >
              {t('global.button.save')}
            </Button>

            <Button
              key="clean"
              className="white"
              onClick={() => {
                resetForm();
              }}
            >
              {t('global.button.clear')}
            </Button>
          </BtnGroup>
        </Col>
      </Row>
      <br />
      {/* \ Botões */}
    </Form>
  );
}
