import React from 'react';
import IconAndText from 'components/IconAndText';
import moment from 'moment';
import t from 'components/i18n';
import { LIBRARY } from 'config/routes';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { useSelector } from 'react-redux';
import Breadcrumb from 'components/Breadcrumb';
import Top from 'components/Top';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { getPermissionsById } from 'shared/utils/Helpers';
import { profileTypes, icons } from './utils';
import {
  Container,
  Subheading,
  Details,
  Item,
} from './Library.styled';
import { regionalMapper, creationMapper } from 'utils/mapper';

export default function ItemDetails({
  history,
  selectedItem,
  setSelectedItem,
  setEditLibrary,
  setShowDetails,
  setDisplayDeleteModal,
  regionals,
}) {
  const user = useSelector(state => state.user);

  const detailsPermission = getPermissionsById(user.allowRoutes, '3');

  const getIcon = icons.filter(
    e => e.fileType === selectedItem.fileType,
  )[0];

  const filteredRegionals = regionals
    .map(regional => ({
      ...regional,
      centerList: regional.centerList.filter(center =>
        selectedItem.centersIds.includes(center.id),
      ),
    }))
    .filter(({ centerList }) => centerList.length);

  const allCenters = filteredRegionals
    .map(({ centerList }) => centerList)
    .flat();

  return (
    <Details>
      <Breadcrumb
        history={history}
        backTo={() => history.push(`${LIBRARY}/list`)}
        links={[
          `${LIBRARY}/list`,
          `${LIBRARY}/list`,
          `${LIBRARY}/list`,
        ]}
        path={[
          t('menu.library.title'),
          t(selectedItem.libraryCategoryName),
          t(selectedItem.librarySubCategoryName),
          t(selectedItem.name),
        ]}
      />
      <Top>
        <IconAndText
          iconBackGround={getIcon ? getIcon.color : 'colorPrimary'}
          icon={getIcon ? getIcon.icon : 'icon-file'}
          title={selectedItem.name}
          subtitle={selectedItem.fileType}
        />
      </Top>
      <br />
      <Container style={{ padding: '16px' }}>
        <Subheading>{t('library.itemDetails.subtitle')}</Subheading>

        <Item className="flex">
          <p>{t('library.itemDetails.date')}</p>
          <span>
            {moment(selectedItem.createdDate).format('DD ̸̸  MM ̸̸  YYYY')}
          </span>
        </Item>

        <Item className="flex">
          <p>{t("library.itemDetails.fileSize")}</p>
          <span>{selectedItem.fileLenght}</span>
        </Item>

        <Item className="flex">
          <p>{t('library.itemDetails.fileLink')}</p>
          <span>
            <a
              href={`${API_URL}/Blob/Download?fileName=${selectedItem.blobName}&blobContainerName=library&token=${user.token}&client_id=${KONG_CLIENT_ID}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('library.itemDetails.fileLinkHref')}
            </a>
          </span>
        </Item>

        <Subheading>{t('library.itemDetails.audience')}</Subheading>

        <Item className="flex">
          <p>{t('library.itemDetails.profile')}</p>
          <span>
            {
              t(profileTypes.find(
                e => e.value === selectedItem.profileType,
              ).label)
            }
          </span>
        </Item>

        <Item className="flex">
          <p>{t("library.itemDetails.anymalType")}</p>
          <span>{creationMapper(selectedItem.animalType)}</span>
        </Item>

        {!!filteredRegionals.length && (
          <Item className="flex">
            <p>
              {filteredRegionals.length > 1
                ? t('library.itemDetails.regional')
                : t('library.itemDetails.regionals')}
            </p>
            <span>
              {filteredRegionals.map(({ name }) => t(regionalMapper(name))).join(', ')}
            </span>
          </Item>
        )}

        {!!allCenters.length && (
          <Item className="flex">
            <p>{t('library.itemDetails.unit')}</p>
            <span>
              {allCenters
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(center => `${center.code} - ${center.name}`)
                .join(', ')}
            </span>
          </Item>
        )}

        <Subheading>{t('library.itemDetails.classification')}</Subheading>

        <Item className="flex">
          <p>{t('library.itemDetails.category')}</p>
          <span>{t(selectedItem.libraryCategoryName)}</span>
        </Item>

        <Item className="flex">
          <p>{t('library.itemDetails.subcategory')}</p>
          <span>{t(selectedItem.librarySubCategoryName)}</span>
        </Item>

        <Item className="flex">
          <p>{t('library.itemDetails.tags')}</p>
          <div>
            {selectedItem.tags.map((item, index) => (
              <span key={index}>
                {t(item.name)}
                {index !== selectedItem.tags.length - 1 && ', '}
              </span>
            ))}
          </div>
        </Item>

        <Row>
          <Col sm={6}>
            {detailsPermission && !!detailsPermission.delete && (
              <BtnGroup noReverse>
                <Button
                  key="submitForm"
                  onClick={() => setDisplayDeleteModal(true)}
                  className="white"
                >
                  {t('library.itemDetails.delete')}
                </Button>
              </BtnGroup>
            )}
          </Col>
          <Col sm={6}>
            {/* \ Botões */}
            <BtnGroup style={{ paddingBottom: '16px' }}>
              {detailsPermission && !!detailsPermission.edit && (
                <Button
                  key="submitForm"
                  onClick={e => {
                    setEditLibrary(true);
                    setShowDetails(false);
                  }}
                >
                  {t("global.button.edit")}
                </Button>
              )}
              <Button
                key="clean"
                className="white"
                onClick={() => setShowDetails(false)}
              >
                {t('global.button.back')}
              </Button>
            </BtnGroup>
            {/* \ Botões */}
          </Col>
        </Row>
      </Container>
    </Details>
  );
}
