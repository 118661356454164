import FlockList from 'containers/AgroBRF/FlockList';
import Cadec from 'containers/AgroBRF/Cadec';
import CadecToVendor from 'containers/AgroBRF/Cadec/CadecToVendor';
import Flock from 'containers/AgroBRF/FlockList/Flock';
import News from 'containers/AgroBRF/News';
import FullNewsWrapper from 'containers/AgroBRF/News/FullNewsWrapper';
import OpenRecord from 'containers/AgroBRF/FlockList/Record/OpenRecord';
import Collaborator from 'containers/AgroBRF/Collaborator';
import Announcements from 'containers/AgroBRF/Announcements';
import InsurancePolicy from 'containers/AgroBRF/InsurancePolicy';
import OrientationScreen from 'containers/AgroBRF/Orientations';
import Vendors from 'containers/NextBRF/Vendors';
import AnnouncementsDetails from 'containers/AgroBRF/Announcements/AnnouncementsDetails';
import ProfileAndSettings from 'containers/AgroBRF/ProfileAndSettings';
import Grains from 'containers/AgroBRF/Grains';
import BancoDoBrasil from 'containers/AgroBRF/BancoDoBrasil';
import { EditPersonalData } from 'containers/AgroBRF/ProfileAndSettings/AccountSettings/PersonalData';
import {
  UpdatePassword,
  EditPhoneNumber,
  AccountPasswordRecover,
  DeactivateAccount,
} from 'containers/AgroBRF/ProfileAndSettings/AccountSettings/AccountData';

import Home from 'containers/SharedContainers/Home';
import Library from 'containers/SharedContainers/Library';
import Faq from 'containers/SharedContainers/Faq';
import InvoiceDetails from 'components/Invoice/InvoiceDetails';

export default function agroRouteList(user) {
  const routes = [
    {
      route: 'HOME',
      path: false,
      component: Home,
    },
    {
      route: 'FLOCK_LIST',
      path: '/:id/:activity?/:type?',
      component: Flock,
      id: 14,
    },
    {
      route: 'CADEC',
      path: false,
      component:
        user.type === 'P' || user.type === 'E' || user.type === 'C'
          ? CadecToVendor
          : Cadec,
      id: 4,
    },
    {
      route: 'NEWS_LIST',
      path: false,
      component: News,
      id: 13,
    },
    {
      route: 'INVOICE',
      path: '/:id',
      component: InvoiceDetails,
      id: 33,
    },
    {
      route: 'OPEN_NEWS',
      path: '/:id',
      component: FullNewsWrapper,
      id: 13,
    },
    {
      route: 'FLOCK_LIST',
      path: false,
      component: FlockList,
      id: 14,
    },
    {
      route: 'OPEN_VENDORS',
      path: '/:id',
      component: Vendors,
      id: 10,
    },
    {
      route: 'FAQ',
      path: false,
      component: user.type !== 'E' && Faq,
      id: 6,
    },
    {
      route: 'OPEN_RECORD',
      path: '/:id/:date?',
      component: OpenRecord,
      id: 12,
    },
    {
      route: 'COLLABORATOR',
      path: '/:id/:type',
      component: Collaborator,
      id: 8,
    },
    {
      route: 'ANNOUNCEMENTS',
      path: false,
      component: Announcements,
      id: 9,
    },
    {
      route: 'OPEN_ANNOUNCEMENT',
      path: '/:id',
      component: AnnouncementsDetails,
      id: 9,
    },
    {
      route: 'INSURANCE',
      path: '/:type?/',
      component: user.profile === 'Administrador' && InsurancePolicy,
      id: 2,
    },
    {
      route: 'LIBRARY',
      path: '/:type?/:urlCategory?/:urlSubCategory?',
      component: Library,
      id: 3,
    },
    {
      route: 'ORIENTATION',
      path: '/:id?',
      component: OrientationScreen,
      id: 17,
    },
    {
      route: 'PROFILE',
      path: '/:tab/:type?',
      component: ProfileAndSettings,
      id: 20,
    },
    {
      route: 'EDIT_PROFILE',
      path: '/:slice',
      component: EditPersonalData,
      id: 20,
    },
    {
      route: 'UPDATE_PASSWORD',
      component: UpdatePassword,
      id: 20,
    },
    {
      route: 'EDIT_PHONENUMBER',
      component: EditPhoneNumber,
      id: 20,
    },
    {
      route: 'ACCOUNT_PASSWORD_RECOVERY',
      component: AccountPasswordRecover,
      id: 20,
    },
    {
      route: 'DEACTIVATE_ACCOUNT',
      component: DeactivateAccount,
      id: 20,
    },
    {
      route: 'GRAINS',
      component: user.type !== 'E' && Grains,
      // id: 21,
    },
    {
      route: 'BANCO_DO_BRASIL',
      component: user.type === 'P' && BancoDoBrasil,
    },
  ];

  return routes;
}
