import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { StyleIcon, IconFooter } from './BadgeIcon.styled';

export default function BadgeIcon(props) {
  const {
    footerContent,
    footerColor,
    footerIcon,
    noShadow,
    whiteBg = false,
    margin,
  } = props;

  const themeContext = useContext(ThemeContext);

  return (
    <StyleIcon
      className="card-rounded-container"
      noShadow={noShadow}
      noMargin
      margin={margin}
    >
      {footerContent && (
        <IconFooter
          whiteBg={whiteBg}
          footerColor={themeContext.styles.palette[footerColor]}
        >
          <p className="footer-p">
            {footerIcon && <span className={`icon ${footerIcon}`} />}
            {footerContent}
          </p>
        </IconFooter>
      )}
    </StyleIcon>
  );
}