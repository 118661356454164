import React from 'react';

import { Dot } from './Icon.styled';

/* <IconWrapper
  src={`${process.env.PUBLIC_URL}/media/icons/${name}.svg`}
  {...rest}
/> */

function Icon({ name, size, color, ...rest }) {
  const style = {};
  if (name === 'dot') {
    return <Dot {...rest} />;
  }

  if (size) {
    style.fontSize = `${size}px`;
  }

  if (color) {
    style.color = color;
  }

  const klasses = [name, rest.className].join(' ').trim();

  return <span style={style} className={klasses} {...rest} />;
}

export default Icon;
