// @flow

import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import { PROFILE } from 'config/routes';
import Breadcrumb from 'components/Breadcrumb';
import t from 'components/i18n';
import Top from 'components/Top';
import Container from 'components/Container';
import LoadingHolder from 'components/LoadingHolder';
import Modal from 'components/Forms/FormModal/Modal';
import PhoneNumberForm from 'containers/AgroBRF/Forms/PhoneNumberForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import {
  updateUserLgpd,
  getUserLgpd,
  authenticate,
} from '../../service';
import { ProfileStyled } from '../../ProfileAndSettings.styled';

const validateSchema = props =>
  yup.object().shape({
    password: yup
      .string()
      .required(t('global.status.required-field'))
      .min(6, t('global.validation.min-character-length', { length: 6 })),
    phoneNumber: yup
      .string()
      .min(14, t('global.validation.min-character-length', { length: 14 }))
      .required(t('global.status.required-field')),
  });

export default function EditPhoneNumber({
  match: { params },
  history,
  setEditUser,
  setSelected,
  isValidating,
  ...props
}) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await getUserLgpd();
        setData(response);
        if (response) setIsLoading(false);
      } catch (e) {
        console.error('Fetch error', e);
      }
      setIsLoading(false);
    }

    fetchData();
  }, []);

  const authenticateUser = useCallback(async (cpf, password) => {
    try {
      const response = await authenticate({
        documentNumber: cpf,
        password: password,
      });
      if (response.success) {
        setAuthenticated(true);
      } else {
        setErrorMessage(t('global.validation.wrong-password'));
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const successMessage = useMemo(
    () => t('global.validation.info-success-save'),
    [],
  );

  const modalErrorMessage = useMemo(
    () =>
      t('global.validation.error-saving-info'),
    [],
  );

  const initialValues = useMemo(
    () => ({
      password: '',
      birthDate: data && moment(data.birthDate).format('YYYY-MM-DD'),
      email: data && data.email,
      integratedDate:
        data && moment(data.integratedDate).format('YYYY-MM-DD'),
      pictureBlobName: data && data.pictureBlobName,
      phoneNumber: data && data.phoneNumber,
    }),
    [data],
  );

  function submitForm(
    values,
    { setSubmitting, setErrors, isValidating },
  ) {
    const formData = {
      birthDate: values.birthDate ? moment(values.birthDate) : null,
      email: values.email || null,
      integratedDate: values.integratedDate
        ? moment(values.integratedDate)
        : null,
      pictureBlobName: values.pictureBlobName || null,
      pictureBlobContainer: 'test',
      phoneNumber: values.phoneNumber || null,
    };

    async function sendData(val) {
      setSubmitting(true);
      try {
        const response = await updateUserLgpd(val);
        if (!response.success) {
          throw Error(response.message);
        }
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setSubmitting(false);
    }

    sendData(formData);
  }

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() =>
          history.push(`${PROFILE}/account-settings/personal-data`)
        }
        links={[
          `${PROFILE}/account-settings`,
          `${PROFILE}/account-settings`,
          `${PROFILE}/account-settings/account-data`,
        ]}
        path={[
          t('menu.profile.title'),
          t('menu.profile.sectionAccountConfig.Title'),
          t('menu.profile.accountData.title'),
          t(
            'menu.profile.accountData.accessData.updateNumber.buttonsSingleLine.label',
          ),
        ]}
      />

      <Top>
        <h2>
          {t(
            'menu.profile.accountData.accessData.updateNumber.buttonsSingleLine.label',
          )}
        </h2>
      </Top>

      <ProfileStyled>
        <Container className="container" hideDetail>
          <LoadingHolder
            message={t('global.loading')}
            isLoading={isLoading}
          />
          {!isLoading && data && (
            <Formik
              initialValues={initialValues}
              validate={validate(validateSchema)}
              onSubmit={submitForm}
              render={formikProps => (
                <PhoneNumberForm
                  {...formikProps}
                  isValidating={isValidating}
                  message={errorMessage}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  success={success}
                  history={history}
                  authenticateUser={authenticateUser}
                  authenticated={authenticated}
                  {...props}
                />
              )}
            />
          )}
        </Container>
      </ProfileStyled>

      {/* On submit modal */}
      <Modal
        noButtons
        opened={success || errorMessage}
        warning={errorMessage}
        onCloseModal={() => {
          setSuccess(false);
          setErrorMessage('');
          history.push(`${PROFILE}/account-settings`);
        }}
      >
        <div className="modal-title">
          <span
            className={`icon icon-${
              success ? 'check' : 'exclamation-triangle'
            }`}
          />
          <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
        </div>

        <p>
          {success
            ? successMessage
            : errorMessage || modalErrorMessage}
        </p>
      </Modal>
      {/* \ On submit modal */}
    </>
  );
}
