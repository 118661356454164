import i18next from 'i18next';
import t from 'components/i18n';

const profileKeyMapper = (profileName, fallback) => {
  if (!profileName || typeof profileName === 'undefined') {
    return fallback;
  }

  const normalizedName = profileName
    .trim()
    .replaceAll(' ', '-')
    .toLowerCase();
  const key = `profiles.label.${normalizedName}`;

  return i18next.exists(key) ? t(key) : fallback || profileName;
};

const profileAttributionMapper = (attribution, fallback) => {
  if (!attribution || typeof attribution === 'undefined') {
    return fallback || attribution;
  }

  const normalizedName = attribution
    .trim()
    .replaceAll(' ', '-')
    .toLowerCase();
  const key = `profiles.attribution.${normalizedName}`;

  return i18next.exists(key) ? t(key) : fallback || attribution;
};

const profileCreationMapper = item => {
  return (
    {
      ['Frango']: t('next.userManagement.user.creationType.chicken'),
      ['Suíno']: t('next.userManagement.user.creationType.swine'),
      ['Peru']: t('next.userManagement.user.creationType.turkey'),
    }[item] || item
  );
};

const profileRegionMapper = item => {
  return (
    {
      ['Centro Oeste']: t('next.userManagement.user.region.midwest'),
      ['Central']: t('next.userManagement.user.region.central'),
      ['Sul']: t('next.userManagement.user.region.south'),
      ['Turquia']: t('next.userManagement.user.region.turkey'),
    }[item] || item
  );
};

export {
  profileKeyMapper,
  profileCreationMapper,
  profileRegionMapper,
  profileAttributionMapper,
};
