import styled from 'styled-components';
import { rgba } from 'polished';
import {
  boxShadow,
  fontFamilyText,
  colorGray,
  colorClear,
  colorSuccess,
  colorDanger,
  colorGrayDarkest,
  colorGrayLight,
  colorGrayLighter,
  breakpointTablet,
  DEFAULT_SIZE,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

export const DocumentationStyled = styled.div`
  position: relative;
`;

export const ContentsWrapper = styled.div`
  position: relative;

  .side-box {
    .bordered-wrapper {
      padding: 0 8px;
      border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};

      &.no-border {
        border: none;
      }

      p {
        font-size: 14px;
        line-height: 16px;
        color: ${colorGrayDarkest};

        a {
          color: ${colorGrayDarkest};

          span {
            margin-right: 8px;
          }
        }

        .label {
          font-size: 12px;
          color: ${colorGrayLight};
        }

        .data {
          &.full {
            line-height: 21px;
          }
        }
      }
    }
  }
`;

export const Container = styled.div`
  color: ${colorGrayDarkest};
  position: relative;
  padding: 0 ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px 20px 60px 20px;
  }

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }

  .strong {
    font-family: ${fontFamilyTextBold};
  }

  .icon {
    font-size: 20px;
  }

  .green {
    color: ${colorSuccess};
  }

  .red {
    color: ${colorDanger};
  }

  .open-details {
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    color: ${colorGrayLighter};
    font-size: 22px;
  }
`;
