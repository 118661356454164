export default function getAnimalIcon(type) {
  const types = {
    broiler: 'icon-chicken',
    turkey: 'icon-turkey',
    pig: 'icon-pig',
    swine: 'icon-pig',
  };

  return types[type] || 'icon-chicken';
}
