import t from 'components/i18n';
import { reduceBy } from 'shared/utils/Helpers';
import { statusMapper } from 'utils/mapper';

export default function getPlacementFilters(dataItems, flockId) {
  const newD = Object.assign([], dataItems);
  let statusOptions = [];
  let flocks = [];
  let houseCodes = [];

  if (newD.length > 0) {
    statusOptions = reduceBy(newD, 'status').map((e, i) => {
      return { txt: e.status, id: i, name: t(statusMapper(e.status)) };
    });

    if (!flockId) {
      flocks = reduceBy(newD, 'flock').map((e, i) => {
        return { txt: e.flock, id: e.flockId };
      });
    }

    houseCodes = reduceBy(newD, 'houseCode').map((e, i) => {
      return {
        txt: t('next.vendorSchedule.filter.house.value', { value: e.houseCode }),
        value: `${e.houseCode}`,
        id: i,
      };
    });
  }

  const filtersData = [
    {
      label: t('next.vendorSchedule.filter.status'),
      items: statusOptions.length > 0 ? statusOptions : [],
    },
    {
      label: t('next.vendorSchedule.filter.feed'),
      items: flocks.length > 0 ? flocks : [],
    },
    {
      label: t('next.vendorSchedule.filter.house'),
      items: houseCodes.length > 0 ? houseCodes : [],
    },
  ];

  if (flockId) {
    filtersData.splice(1, 1);
  }

  return filtersData;
}
