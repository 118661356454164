import styled from 'styled-components';
import { rgba } from 'polished';
import { Button } from '@zendeskgarden/react-buttons';

import {
  colorGrayDarkest,
  colorGray,
  colorGrayLightest,
  colorGrayLight,
  colorTertiary,
  colorSuccess,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

export const VisitStyled = styled.div`
  display: flex;
  padding: 20px;

  .secondary {
    width: 100%;
    max-width: 316px;
    margin-right: 20px;
  }

  .primary {
    width: 100%;
    max-width: 652px;
  }

  .steps-container {
    padding: 0 24px 16px;
    margin: 0 -16px 16px;
    border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.25)};
  }

  .textarea-label {
    font-size: 16px;
    font-family: ${fontFamilyTextBold};
  }
`;

export const StyledFlockVisitCircuit = styled.section`
  button {
    &.white {
      &.flock {
        color: ${colorGrayDarkest} !important;
        font-size: 14px !important;
      }
    }
  }
`;

export const CardTitle = styled.div`
  color: ${colorGrayDarkest};

  h2 {
    font-family: ${fontFamilyTextBold};
    font-size: 16px;
    margin: 0;
  }

  p {
    color: ${colorGray};
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }
`;

export const FlockList = styled.ul`
  list-style: none;
  margin: 8px 0 0;
  padding: 0;

  li {
    color: ${colorGrayLight};
    font-size: 12px;
    line-height: 14px;
    margin: 0 0 6px;
  }
`;

export const Item = styled.div`
  color: ${colorGrayDarkest};
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.25)};
  padding: ${props => props.padding || '16px 0'};
  font-size: 14px;
  line-height: 21px;

  &:last-of-type {
    border: none;
  }

  &.right {
    justify-content: flex-end;
  }

  .margin {
    margin-bottom: 8px;
  }

  .label {
    font-size: 12px;
  }

  .small {
    font-size: 12px;
    line-height: 16px;
  }

  button {
    width: 154px;
  }
`;

export const ButtonBack = styled(Button)`
  color: ${colorTertiary} !important;
  width: 100% !important;
  margin-top: 2rem !important;
  background-color: white !important;
  font-weight: bold !important;
  border-color: ${colorGrayLightest} !important;

  &:hover,
  &: focus {
    background-color: white !important;
    color: ${colorTertiary} !important;
  }
`;

export const TopBadger = styled.div`
  background-color: ${colorSuccess};
  width: 100%;
  position: absolute;
  z-index: 1;
  margin: -20px;
  height: 40px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 12px;

  span {
    margin: 0 0.5rem 0 2rem;
    font-size: 2rem;
  }
`;

export const CircuitDescription = styled.p`
  margin-bottom: 1rem !important;
`;
