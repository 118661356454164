import React, { useState, useEffect, useCallback } from 'react';
import logger from 'shared/utils/logger';
import uid from 'uuid';
import { scrollToTop } from 'shared/utils/Helpers';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import Container from 'components/Container';
import { colorTertiary } from 'components/GlobalStyles/variables';
import { getSwineMortalityList } from '../serivce';
import DeceasedSummaryItem from './DeceasedSummaryItem';
import t from 'components/i18n';

export default function SwineMortalityList({
  data,
  flockID,
  houses,
  updateItems,
  setUpdateItems,
  setSelectedKey,
  removed,
  setRemoved,
  setDisplayExcludeModal,
  goTo,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [mortalityList, setMortalityList] = useState([]);

  const fetchSummary = useCallback(async id => {
    setIsLoading(true);
    try {
      const response = await getSwineMortalityList(id);
      setMortalityList(response);
      setIsLoading(false);
    } catch (e) {
      logger('Error on getSummary', e);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (removed) {
      setUpdateItems(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removed]);

  useEffect(() => {
    if (updateItems) {
      fetchSummary(flockID);
      setTimeout(() => setRemoved(false), 2000);
      scrollToTop(document.getElementById('main'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItems]);

  useEffect(() => {
    fetchSummary(flockID);
  }, [fetchSummary, flockID]);

  return (
    <>
      <LoadingHolder
        isLoading={isLoading}
        message={t('global.loading')}
      />

      {mortalityList.swineMortality &&
        mortalityList.swineMortality.map(feed => (
          <DeceasedSummaryItem
            key={`feed-${uid()}`}
            feed={feed}
            goTo={e => setSelectedKey(e)}
            exclude={() => setDisplayExcludeModal(true)}
            houses={houses}
            hideOptions={!feed.isEditable}
          />
        ))}

      {Object.keys(mortalityList).length > 0 &&
        mortalityList.swineMortality.length === 0 &&
        !isLoading && (
          <Container hideDetail slim>
            <NoContentTab
              icon="icon-pig-alt"
              buttonLabel={
                data && data.flockStatus !== 'closed'
                  ? t('next.swineRecord.mortalityList.resume.no-content.button.add')
                  : undefined
              }
              onButtonClick={() => goTo('addDeceased')}
              buttonColor={colorTertiary}
            >
              <p>
                {t('next.swineRecord.mortalityList.resume.no-content.message')}
              </p>
            </NoContentTab>
          </Container>
        )}
    </>
  );
}
