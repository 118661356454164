const errorMessages = {
  // AUTHENTICATION
  'authentication.authenticatenext.InvalidRegistrationOrPassword':
    'Senha incorreta. Tente novamente ou clique em "esqueci minha senha" para informações sobre como redefini-la.',
  'authentication.authenticatenext.disabledUser': 'Usuário inativo.',
  'authentication.authenticate.userNotRegistered':
    'Por favor, realize seu cadastro no botão "Quero me cadastrar".',
  'authentication.verifyaccount.invalidUserName':
    'O CPF/CNPJ informado não está correto.',
  'authentication.verifyaccountvendorcode.invalidUserName':
    'O CPF/CNPJ informado não está correto.',
  'authentication.authenticate.invalidPassword':
    'Senha incorreta. Tente novamente ou clique em "Esqueceu a senha?" para redefini-la.',
  'authentication.authenticate.documentNotFound':
    'Plataforma de uso exclusivo dos produtores integrados. Favor entrar em contato com a BRF para maiores informações.',
  'authentication.authenticate.passwordNotSet':
    'Senha não definida. Favor clicar em "Quero me cadastrar" para defini-la.',
  'authentication.authenticate.invalidUserName':
    'O CPF/CNPJ informado não está correto.',
  'authentication.authenticatenext.userWithoutProfile':
    'Usuário inativo. Entre em contato através do e-mail plataformadigitalagro@brf.com para habilitar seu acesso.',
  'authentication.verifyaccount.invalidPhone':
    'O número de telefone informado não corresponde ao cadastro em nosso sistema. Atualize suas informações.',
  'authentication.verifyaccountvendorcode.invalidPhone':
    'O número de telefone informado não corresponde ao cadastro em nosso sistema. Atualize suas informações.',
  'authentication.verifyaccount.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'authentication.verifyaccount.unregisteredUser':
    'Plataforma de uso exclusivo dos produtores integrados. Favor entrar em contato com a BRF para maiores informações.',
  'authentication.verifyaccount.smsError':
    'Ocorreu um erro no envio de SMS.',
  'authentication.verifyaccount.registeredUser':
    'Usuário já registrado em nosso sistema. Clique em "Já sou cadastrado" para realizar o login.',
  'authentication.authenticatesmstoken.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'authentication.authenticatesmstoken.invalidToken':
    'O código de confirmação informado não está correto.',
  'authentication.authenticatevendorcode.invalidVendor':
    'O clifor informado não está correto.',
  'authentication.createpassword.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'authentication.createpassword.invalidUserName':
    'O CPF/CNPJ informado não está correto.',
  'authentication.authenticate.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'authentication.authenticate.unregisteredUser':
    "Não foi possível encontrar seu cadastro em nosso sistema. Clique em 'Quero me cadastrar' para criá-lo.",
  'authentication.authenticate.invalidUserCredentials':
    'O CPF/CNPJ e/ou senha informados não estão corretos.',
  'authentication.termaccepted.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'authentication.createpasswordlgpd.invalidPassword':
    'A senha informada está incorreta.',
  'user.deactivateuser.invalidPassword':
    'A senha informada está incorreta.',

  // INTERNAL ERROR
  'authentication.term.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',

  // BLOB
  'blob.uploadfile.blobUploadError':
    'Ocorreu um erro no envio do arquivo.',
  'blob.uploadfile.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'blob.downloadfile.blobDownloadError':
    'Ocorreu um erro no download do arquivo.',

  // CADEC
  'blob.deletefile.fileNotFound':
    'Arquivo não encontrado em nosso sistema.',
  'cadec.getcadecinfos.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'cadec.getall.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'cadec.savecadec.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'cadec.savecadec.centerCodeNotFound': 'Centro não encontrado.',
  'cadec.savecadec.nullCenterCode': 'código de centro nulo',
  'cadec.savecadec.typeNotFound': 'Tipo de criação não encontrado.',
  'cadec.savecadec.fileNotFound': 'Arquivo não encontrado',
  'cadec.savecadec.invalidSegment':
    'Segmento não é válido para centro e tipo de criação.',
  'cadec.deletecadec.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'cadec.deletecadec.fileNotFound': 'Arquivo não encontrado.',
  'cadecdocument.getcadecdocumentinfos.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'cadecdocument.getcadecdocuments.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'cadecdocument.savecadecdocument.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'cadecdocument.savecadecdocument.centerCodeNotFound':
    'Centro não encontrado.',
  'cadecdocument.savecadecdocument.cadecNotFound':
    'CADEC não encontrada.',
  'cadecdocument.savecadecdocument.fileNotFound':
    'Arquivo não encontrado.',
  'cadecdocument.deletecadec.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'cadecdocument.deletecadec.cadecDocumentNotFound':
    'Ata não encontrada.',
  'cadecdocument.deletecadec.fileNotFound': 'Arquivo não encontrado.',
  'cadecdocument.getallcadecdocuments.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',

  // VENDOR
  'contact.becomeVendor.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'contact.becomeVendor.sendEmailError':
    'Ocorreu um erro no envio do e-mail. Tente novamente mais tarde.',

  // CONTACT US
  'contact.contactus.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'contact.contactus.sendEmailError':
    'Ocorreu um erro no envio do e-mail. Tente novamente mais tarde.',

  // GET STATE AND CITY
  'contact.getstates.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'contact.getcity.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',

  // FAQ
  'faq.getquestions.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'faq.insertfaq.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'faq.insertfaq.sendEmailError':
    'Ocorreu um erro no envio do e-mail. Tente novamente mais tarde.',

  // FLOCK
  'flock.getflocklist.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'flock.getflockinformation.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'flock.getflockallinformation.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'flock.getflocklistsupervisor.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'flock.getvendors.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',

  // PAYMENT
  'payment.getpayment.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'payment.getpayment.flockNotFound':
    'Não foi possível encontrar o lote no sistema.',
  'payment.getpayment.creationTypeNotFound':
    'Não foi possível encontrar o tipo de criação no sistema.',
  'payment.getremunerationresume.flockNotFound':
    'Não foi possível encontrar o lote no sistema.',
  'payment.getremunerationresume.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'payment.getremunerationresume.creationTypeNotFound':
    'Não foi possível encontrar o tipo de criação no sistema.',
  'payment.gettechnicalindicators.flockNotFound':
    'Não foi possível encontrar o lote no sistema.',
  'payment.gettechnicalindicators.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'payment.gettechnicalindicators.creationTypeNotFound':
    'Não foi possível encontrar o tipo de criação no sistema.',
  'payment.getpartialpayment.flockNotFound':
    'Não foi possível encontrar o lote no sistema.',
  'payment.getpartialpayment.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'payment.getpartialpayment.creationTypeNotFound':
    'Não foi possível encontrar o tipo de criação no sistema.',

  // SCHEDULE
  'schedule.getschedule.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',

  // USER
  'user.get.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',

  // WEIGHT
  'weight.saveweight.weightAlreadyExists':
    'Já existe pesagem para este lote, instalação e idade.',
  'weight.saveweight.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'weight.saveweight.weightOutofRange':
    'Peso fora dos padrões para a idade',
  'weight.saveweight.integrationError': 'Erro ao integrar a pesagem',
  'weight.saveweight.weightCompleted':
    'Não é possível atualizar essa pesagem. A integração dos dados já foi concluída.',
  'weight.saveweight.weightUpdateExpired':
    'Não é possível atualizar essa pesagem. O período máximo de atualização já expirou.',
  'weight.create.weightAlreadyExists':
    'Já existe pesagem para este lote, instalação e idade.',
  'weight.create.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'weight.create.weightOutofRange':
    'Insira um peso válido para essa idade.',
  'weight.create.integrationError': 'Erro ao integrar a pesagem',
  'weight.getweights.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'weight.getagelist.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'weight.resendallmtechintegrationerror.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'weight.resendallmtechintegrationerror.integrationError':
    'Ocorreu um erro ao integrar a pesagem. Tenta novamente mais tarde.',

  // NOTIFICATIONS
  'notifications.register.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'notifications.register.registrationError':
    'Erro ao registrar push registration',
  'notifications.clearRegister.registrationIDNotFound':
    'Não foi possível encontrar o registrationID',
  'notifications.clearRegister.deleteRegistrationError':
    'Não foi possível deletar o registrationID',
  'notifications.unregister.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'notifications.unregister.deleteRegistrationError':
    'Não foi possível deletar o registrationID',
  'notifications.enable.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'notifications.enable.registrationIDNotFound':
    'Não foi possível encontrar o registrationID',
  'notifications.enable.deleteRegistrationError':
    'Não foi possível deletar o registrationID',
  'notifications.enable.createRegistrationIDError':
    'Não foi possível criar o registrationID',
  'notifications.enable.registerPushNotificationError':
    'Registration failed because of HttpStatusCode.Gone. PLease register once again.',
  'notifications.enable.configurePushNotificationError':
    'Ocorreu um erro ao atualizar as configurações de push notification.',
  'notifications.enable.platformError':
    'Não foi possível encontrar o tipo do seu dispositivo.',
  'notifications.send.sendNotificationError':
    'Houve um erro ao processar sua notificação.',
  'notifications.getallnotifications.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'notifications.getallnotifications.getAllNotificationError':
    'Ocorreu um problema ao buscar as notificações. Tente novamente mais tarde.',
  'notifications.getlastnotifications.getLastNotificationsError':
    'Ocorreu um erro ao buscar as últimas notificações.',
  'notifications.getallnotificationstoberead.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'notifications.getallnotificationstoberead.getLastNotificationsToBeReadError':
    'Ocorreu um problema ao buscar as notificações. Tente novamente mais tarde.',
  'notifications.readnotification.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'notifications.readnotification.readNotificationError':
    'Ocorreu um erro ao marcar a notificação como lida. Tente novamente mais tarde.',
  'notifications.hidenotification.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'notifications.hidenotification.hideNotificationError':
    'Ocorreu um erro ao ocultar a notificação. Tente novamente mais tarde.',
  'notifications.getallregistrations.getAllRegistrationsError':
    'Ocorreu um erro ao buscar os registros',
  'notifications.clearRegister.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',
  'notifications.getlastnotifications.unknownError':
    'Ocorreu um problema ao buscar as notificações. Tente novamente mais tarde.',
  'notifications.getallregistrations.unknownError':
    'Ocorreu um problema em nosso sistema. Tente novamente mais tarde.',

  // SWINE
  'swinedeceaseddiscarded.save.swineDeceasedDiscardedInvalidEdition':
    'Mortalidade suína cadastrada a mais de 48 horas.',
  'swinedeceaseddiscarded.save.swineDeceasedDiscardedAlreadyExist':
    'Mortalidade suína já cadastrada no sistema.',

  // COLABORATOR
  'inviteuser.verifycpf.invalidCPFAlreadyCollaborator':
    'CPF já cadastrado como seu colaborador.',
  'inviteuser.verifycpf.invalidCPFOtherUserCollaborator':
    'CPF já cadastrado como colaborador de outro produtor.',
  'inviteuser.verifycpf.invalidCPFNotCollaborator':
    'CPF já cadastrado como usuário da plataforma.',
};

export default errorMessages;
