// @flow

import {
  breakpointDesktop,
  breakpointTablet,
} from 'components/GlobalStyles/variables';

export const isMobile = ({ width }) => width < breakpointTablet;
export const isTablet = ({ width }) =>
  width >= breakpointTablet && width < breakpointDesktop;
export const isDesktop = ({ width }) => width >= breakpointDesktop;

export const isGtMobile = sizes => !isMobile(sizes);
export const isGtTablet = sizes => isDesktop(sizes);

export const isStTablet = sizes => isMobile(sizes);
export const isStDesktop = sizes => !isDesktop(sizes);

export const isTabletAndGreater = sizes => !isMobile(sizes);
export const isTabletAndSmaller = sizes => !isStDesktop(sizes);

export default function mapSizesToProps(sizes) {
  return {
    responsive: {
      isMobile: isMobile(sizes),
      isTablet: isTablet(sizes),
      isDesktop: isDesktop(sizes),
      isGtMobile: !isMobile(sizes),
      isGtTablet: isDesktop(sizes),
      isStTablet: isMobile(sizes),
      isStDesktop: !isStDesktop(sizes),
      isTabletAndGreater: !isMobile(sizes),
      isTabletAndSmaller: !isStDesktop(sizes),
    },
  };
}

export type TResponsive = {
  isDesktop: Boolean,
  isGtMobile: Boolean,
  isGtTablet: Boolean,
  isMobile: Boolean,
  isStDesktop: Boolean,
  isStTablet: Boolean,
  isTablet: Boolean,
  isTabletAndGreater: Boolean,
  isTabletAndSmaller: Boolean,
};
