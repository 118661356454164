import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { IconPlaceholderStyled } from './IconPlaceholder.styled';

export default function IconPlaceholder(props) {
  const themeContext = useContext(ThemeContext);

  const theme = {
    bgColor: themeContext.styles.palette[props.bgColor],
    text: themeContext.styles.palette.colorClear,
    round: props.round,
    className: props.className,
    bigIcon: props.bigIcon,
  };

  return (
    <IconPlaceholderStyled {...theme}>
      {props.children}
    </IconPlaceholderStyled>
  );
}
