import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  boxShadow,
  fontFamilyText,
  colorGray,
  colorClear,
  colorGrayLight,
  colorGrayDarkest,
  colorDanger,
  breakpointTablet,
  DEFAULT_SIZE,
} from 'components/GlobalStyles/variables';

export const VendorsTableStyled = styled.div`
  position: relative;

  .justification {
    h1 {
      font-size: 24px;
      margin-bottom: 60px;
    }

    .container-inner {
      > div {
        padding: 0 8px;
        border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};

        &.no-border {
          border: none;
        }
      }

      p {
        font-size: 14px;
        line-height: 16px;
        color: ${colorGrayDarkest};

        &.red {
          color: ${colorDanger};
          font-size: 14px;
          line-height: 21px;
          padding: 12px 26px;
          border-radius: 8px;
          background-color: ${rgba(colorDanger, 0.16)};
          display: flex;
          align-items: center;

          .icon {
            font-size: 18px;
            margin-right: 20px;
          }
        }

        .label {
          font-size: 12px;
          color: ${colorGrayLight};
        }

        .data {
          &.full {
            line-height: 21px;
          }
        }
      }
    }
  }
`;

export const Container = styled.div`
  position: relative;
  padding: 0 ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px 20px 60px 20px;
  }

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }
`;
