import { Button } from '@zendeskgarden/react-buttons';
import { Col, Row } from '@zendeskgarden/react-grid';
import Box from 'components/Container';
import { DropdownFilter } from 'components/Filter';
import {
  dateFilterList,
  getDateLabel,
} from 'components/Filter/FiltersUtils';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import t from 'components/i18n';
import { List } from 'components/List/List';
import LoadingHolder from 'components/LoadingHolder';
import {
  Close,
  RoundedItem,
} from 'components/MultiSelect/Items.styled';
import NoContentTab from 'components/NoContentTab';
import Pagination from 'components/Pagination';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { getCadecPagedDocuments } from 'containers/AgroBRF/Cadec/service';
import { Separator } from 'containers/AgroBRF/FlockList/Flock/Flock.styled';
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AtaContext } from 'shared/contexts';
import AtaItem from './AtaList/AtaItem';
import { CadecDetail } from './Details.styled';

const AtaListTab = ({
  cadec,
  goTo,
  setDisplayModal,
  stretchBox,
  cadecPermissions,
}) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const hasData = data.length > 0;
  const user = useSelector(state => state.user);
  const { ataToaster } = useContext(AtaContext);

  async function fetchData() {
    setIsLoading(true);

    try {
      const params = {
        PageNumber: currentPage,
        PageSize: 20,
        CadecId: cadec.cadecId || cadec.id,
        StartDate: dateFilter ? dateFilter.startDate : '',
        EndDate: dateFilter ? dateFilter.endDate : '',
      };

      const response = await getCadecPagedDocuments(params);

      setTotalPages(response.totalPages);
      setData(response.cadecDocuments);
    } catch (e) {
      console.error('Error on getCadecPagedDocuments', e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (!ataToaster) {
      fetchData();
    }
  }, [currentPage, dateFilter, ataToaster]);

  useEffect(() => {
    setOpenCalendar(false);
  }, [dateFilter]);

  return (
    <>
      <Box
        style={stretchBox ? { maxWidth: '630px', width: '100%' } : {}}
        hideDetail
      >
        <LoadingHolder isLoading={isLoading} />

        {!dateFilter ? (
          <DropdownFilter
            buttonLabel={t('global.button.filter.date')}
            list={dateFilterList}
            arrayItem="date"
            keysToCompare={['startDate', 'endDate']}
            onSelectItemAction={setDateFilter}
            openCalendar={openCalendar}
            setOpenCalendar={setOpenCalendar}
            clearFilters={dateFilter === null}
          />
        ) : (
          <div style={{ display: 'flex' }}>
            {' '}
            <RoundedItem>
              <p>
                {getDateLabel(dateFilter.startDate)} -{' '}
                {getDateLabel(dateFilter.endDate)}
              </p>
              <Close
                onClick={() => {
                  setDateFilter(null);
                }}
              >
                <span className="icon icon-close-circle" />
              </Close>
            </RoundedItem>
          </div>
        )}

        {!isLoading && (
          <div className="cadec-list">
            <Separator />
            {hasData &&
              data.map((item, index) => (
                <AtaItem
                  key={item.id}
                  ata={item}
                  link={`${API_URL}/Blob/Download?fileName=${item.fileName}&blobContainerName=${item.container}&token=${user.token}&client_id=${KONG_CLIENT_ID}`}
                  name={item.name}
                  goTo={goTo}
                  iconBox="turkey"
                  setDisplayModal={() => setDisplayModal()}
                  whiteBgIcon
                  cadecPermissions={cadecPermissions}
                />
              ))}
          </div>
        )}

        {!isLoading && !data.length && (
          <NoContentTab icon="icon-users">
            <p>{t("cadec.filters.no-result")}</p>
          </NoContentTab>
        )}
      </Box>
      {data && totalPages > 0 && (
        <Pagination
          totalPages={totalPages}
          pagePadding={2}
          currentPage={currentPage}
          onChangePage={({ e }) => setCurrentPage(e)}
        />
      )}
    </>
  );
};

export default function Details(props) {
  const {
    center,
    centerCode,
    files,
    type,
    segmentList,
    typeName,
    segment,
    representatives,
    regulation,
  } = props.cadec;
  const { cadec, detailsTab, cadecPermissions, stretchBox } = props;

  const ListArray = [];

  const user = useSelector(state => state.user);

  function getCadecFiles(fileType) {
    if (fileType === 'REGULAMENTO' && regulation) {
      return `${API_URL}/Blob/Download?fileName=${regulation.cadecBlobName}&blobContainerName=${regulation.cadecContainer}&token=${user.token}&client_id=${KONG_CLIENT_ID}`;
    }

    if (fileType === 'REPRESENTANTES' && representatives) {
      return `${API_URL}/Blob/Download?fileName=${representatives.cadecBlobName}&blobContainerName=${representatives.cadecContainer}&token=${user.token}&client_id=${KONG_CLIENT_ID}`;
    }

    const documentExists =
      files && files.filter(e => e.type === fileType)[0];

    if (documentExists) {
      return `${API_URL}/Blob/Download?fileName=${documentExists.fileName}&blobContainerName=${documentExists.container}&token=${user.token}&client_id=${KONG_CLIENT_ID}`;
    }

    return null;
  }

  if (Object.keys(cadec).length > 0) {
    ListArray.push(
      {
        label: t("cadec.filters.options.unit"),
        description:
          center.code || centerCode
            ? `${center.code || centerCode} - ${center.name ||
                center}`
            : '',
      },
      {
        label: t('cadec.filters.options.animalType'),
        description: typeName || type
      },
      {
        label: t("cadec.filters.options.segment"),
        description:
          segment ||
          segmentList.map((item, index) => (
            <span className="item" key={item.id}>
              {item.name}
            </span>
          )),
      },
      {
        label: t('cadec.filters.options.representatives'),
        description: getCadecFiles('REPRESENTANTES')
          ? t('cadec.button.checkRepresentatives')
          : undefined,
        url: getCadecFiles('REPRESENTANTES'),
      },
      {
        label: t('cadec.filters.options.regulation'),
        description: getCadecFiles('REGULAMENTO')
          ? t('cadec.button.checkRegulation')
          : undefined,
        url: getCadecFiles('REGULAMENTO'),
      },
    );
  }

  if (detailsTab === 'atas') {
    return (
      <AtaListTab
        cadec={cadec}
        goTo={props.goTo}
        setDisplayModal={props.setAtaDisplayModal}
        stretchBox
        cadecPermissions={cadecPermissions}
      />
    );
  }

  return (
    <>
      <Box
        style={stretchBox ? { maxWidth: '630px', width: '100%' } : {}}
        hideDetail
      >
        <List className="confirm-list">
          {ListArray.map(
            item =>
              item.description &&
              item.description.length > 0 && (
                <CadecDetail
                  key={item.label}
                  className="animated fadeIn"
                >
                  <p className="list-label">{item.label}</p>
                  <p className="list-item-description">
                    {'url' in item && item.url !== undefined ? (
                      <a
                        href={item.url}
                        className="primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.description}
                      </a>
                    ) : (
                      item.description
                    )}
                  </p>
                </CadecDetail>
              ),
          )}
        </List>

        <Separator />

        {/* \ Botões */}
        <Row>
          <Col size={4}>
            {!!(cadecPermissions && cadecPermissions.delete) && (
              <BtnGroup noReverse className="button-group--bottom">
                <Button
                  key="edit"
                  onClick={() => {
                    props.setDisplayModal();
                  }}
                  className="white"
                >
                  {t('cadec.options.delete')}
                </Button>
              </BtnGroup>
            )}
          </Col>
          <Col size={8}>
            <BtnGroup className="button-group--bottom">
              {!!(cadecPermissions && cadecPermissions.create) && (
                <Button
                  key="edit"
                  onClick={() => {
                    props.goTo('editCadec');
                  }}
                >
                  {t("global.button.edit")}
                </Button>
              )}
              <Button
                key="back"
                onClick={() => props.goTo('cadecList')}
                disabled={props.isSubmitting}
                className="white"
              >
                {t('global.button.back')}
              </Button>
            </BtnGroup>
          </Col>
        </Row>

        {/* \ Botões */}
      </Box>
    </>
  );
}
