import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import moment from 'moment';
import * as routes from 'config/routes';
import t from 'components/i18n';
import Top from 'components/Top';
import IconAndText from 'components/IconAndText';
import { scrollToTop } from 'shared/utils/Helpers';
import LoadingHolder from 'components/LoadingHolder';
import Breadcrumb from 'components/Breadcrumb';
import Card from 'components/Card';
import NoContentTab from 'components/NoContentTab';
import Container from 'components/Container';
import QuestionForm from './QuestionForm';
import FlockVisitCircuitList from './FlockVisitCircuitList';
import { getLogBookChecklistsSchedules } from './service';
import { VisitStyled } from '../VisitList/VisitStyled.styled';
import { useChecklist } from '../Checklists/hooks';
import { getParameterByName } from 'utils/url';

export default function VisitCircuitList({
  history,
  match,
  user,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [schedules, setSchedules] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState(null);
  const [actionPlanDetails, setActionPlanDetails] = useState(null);
  const [actionPlanEdit, setActionPlanEdit] = useState(null);
  const [selectedFlockCircuit, setSelectedFlockCircuit] = useState(
    null,
  );
  const [displayPlanForm, setDisplayPlanForm] = useState(null);

  const [{ details }] = useChecklist();

  const { vendor, checklist } = details;
  const { params } = match;

  const state = getParameterByName("state");

  const getSchedules = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getLogBookChecklistsSchedules(
        checklist.id,
        vendor.vendorCode,
        state
      );
      setSchedules(response);
    } catch (e) {
      console.error('Error on get logbook details', e);
    }
    setIsLoading(false);
  }, [checklist.id, vendor.vendorCode]);

  useEffect(() => {
    if (checklist && checklist.id && vendor && vendor.vendorCode) {
      getSchedules();
    }
  }, [checklist, getSchedules, vendor]);

  const getNoContentTab = useMemo(
    () => (
      <Container
        hideDetail
        slim
        style={{ maxWidth: 652, width: '100%' }}
      >
        <NoContentTab icon="icon-tasks" hideButton>
          <p>
            {t('global.no-content')}
          </p>
        </NoContentTab>
      </Container>
    ),
    [],
  );

  const displayScheduleList = useCallback(() => {
    return (
      schedules &&
      schedules.length > 0 &&
      schedules.map(item => (
        <Card
          key={item.id}
          title={item.name}
          progress={item.fillingPercentage || '0'}
          progressColor={
            item.isFinished
              ? item.fillingPercentage !== 100
                ? 'colorWarning'
                : 'colorSuccess'
              : 'colorPrimary'
          }
          footerContent={
            item.isFinished
              ? item.fillingPercentage !== 100
                ? t('next.visitCircuit.visitCircuitList.schedule.fillingPercentage.complete')
                : t('next.visitCircuit.visitCircuitList.schedule.fillingPercentage.incomplete')
              : item.lastAnswer
                ? t('next.visitCircuit.visitCircuitList.schedule.lastAnswer.filled', { value: moment(item.lastAnswer).format('DD ̸̸  MM ̸̸  YYYY') })
                : t('next.visitCircuit.visitCircuitList.schedule.lastAnswer.unfilled')
          }
          footerIcon={
            item.isFinished
              ? item.fillingPercentage !== 100
                ? 'icon-stopwatch'
                : 'icon-check-circle'
              : 'icon-clock'
          }
          footerColor={
            item.isFinished
              ? item.fillingPercentage !== 100
                ? 'colorWarning'
                : 'colorSuccess'
              : 'colorInfo'
          }
          subtitle={
            item.endDate
              ? t('next.visitCircuit.visitCircuitList.schedule.subtitle.fillingDateRange', { start: moment(item.startDate).format('DD ̸̸  MM ̸̸  YYYY'), end: moment(item.endDate).format('DD ̸̸  MM ̸̸  YYYY') })
              : t('next.visitCircuit.visitCircuitList.schedule.subtitle.endDateNotSpecified')
          }
          action={() =>
            item.logBookChecklistScheduleTypeId === 1
              ? setSelectedFlockCircuit({ ...item, id: item.id })
              : setSelectedSchedule(item)
          }
        />
      ))
    );
  }, [schedules]);

  const screenOptions = useCallback(() => {
    if (!selectedSchedule && !selectedFlockCircuit) {
      return displayScheduleList();
    }

    if (selectedSchedule) {
      return (
        <QuestionForm
          selectedFlockCircuitName={selectedItem && selectedItem.name}
          selectedItem={selectedItem}
          user={user}
          id={params.id}
          state={state}
          scheduleId={selectedSchedule.id}
          vendorCode={vendor.vendorCode}
          selectedSchedule={selectedSchedule}
          setSelectedSchedule={setSelectedSchedule}
          selectedQuestions={selectedQuestions}
          isFinished={
            // using the same attribute to avoid filling for future dates
            (schedules.find(el => el.id === selectedSchedule.id) || {}).isFinished ||
            selectedSchedule.startDate && moment(selectedSchedule.startDate).isSameOrAfter(moment())
          }
          setSelectedQuestions={setSelectedQuestions}
          displayDetails={actionPlanDetails}
          setDisplayDetails={setActionPlanDetails}
          displayPlanEdit={actionPlanEdit}
          setDisplayPlanEdit={setActionPlanEdit}
          displayPlanForm={displayPlanForm}
          setDisplayPlanForm={setDisplayPlanForm}
          getSchedules={getSchedules}
        />
      );
    }

    if (selectedFlockCircuit) {
      return (
        <FlockVisitCircuitList
          vendorCode={vendor.vendorCode}
          state={state}
          scheduleId={selectedFlockCircuit.id}
          selectedFlockCircuit={selectedFlockCircuit}
          setSelectedSchedule={setSelectedSchedule}
        />
      );
    }
  }, [
    actionPlanDetails,
    actionPlanEdit,
    displayPlanForm,
    displayScheduleList,
    getSchedules,
    selectedFlockCircuit,
    selectedItem,
    selectedQuestions,
    selectedSchedule,
    user,
    vendor.vendorCode,
  ]);

  useEffect(() => {
    scrollToTop(document.getElementById('main'));
  }, [actionPlanDetails]);

  const selectedItem = useMemo(
    () => selectedFlockCircuit || selectedSchedule || {},
    [selectedFlockCircuit, selectedSchedule],
  );

  const breadcrumbPaths = useCallback(() => {
    const paths = [
      {
        label: t('next.visitCircuit.visitCircuitList.bc.growers'),
        link: `${routes.VENDOR}/${params.id}`,
      },
      {
        label: vendor.vendorName,
        link: `${routes.NEXT_FLOCK_LIST}/${params.id}/new`,
      },
      {
        label: t('next.visitCircuit.visitCircuitList.bc.checklists'),
        link: `${routes.NEXT_FLOCK_LIST}/${params.id}/new?tab=1`,
      },
      {
        label: checklist.name,
        link: () => {
          setSelectedSchedule(null);
          setSelectedFlockCircuit(null);
        },
      },
    ];

    if (selectedItem) {
      paths.push({ label: selectedItem.name });
    }

    if (selectedQuestions) {
      paths.splice(-1);

      paths.push(
        {
          label: selectedItem && selectedItem.name,
          link: () => {
            setSelectedQuestions(null);
            setDisplayPlanForm(null);
            setActionPlanDetails(null);
            setActionPlanEdit(null);
          },
        },
        { label: selectedQuestions.title },
      );
    }

    if (actionPlanDetails) {
      paths.splice(-1);

      paths.push({ label: t('next.visitCircuit.visitCircuitList.bc.planActionDetails') });
    }

    if (actionPlanEdit) {
      paths.splice(-1);

      paths.push({ label: t('next.visitCircuit.visitCircuitList.bc.editPlanActionDetails') });
    }

    if (displayPlanForm) {
      paths.splice(-1);

      paths.push({ label: t('next.visitCircuit.visitCircuitList.bc.addPlanActionDetails') });
    }

    return paths;
  }, [
    params.id,
    vendor.vendorName,
    checklist.name,
    selectedItem,
    selectedQuestions,
    actionPlanDetails,
    actionPlanEdit,
    displayPlanForm,
  ]);

  return (
    <>
      <Breadcrumb
        history={history}
        backTo={() =>
          history.push(`${routes.NEXT_FLOCK_LIST}/${params.id}/new`)
        }
        path={breadcrumbPaths().map(item => item.label)}
        links={breadcrumbPaths().map(item => item.link)}
      />

      <Top
        style={{ height: actionPlanDetails ? 64 : 84 }}
        padding={0}
      >
        {!actionPlanDetails && (
          <IconAndText
            title={(selectedItem && Object.keys(selectedItem).length) ? selectedItem.name : checklist.name}
            iconBackGround="colorPrimary"
            icon="icon-tasks"
            subtitle={
              (selectedItem && Object.keys(selectedItem).length)
                ? selectedItem.name
                : checklist.subtitle
            }
            noPadding
          />
        )}
      </Top>

      <VisitStyled style={{ flexDirection: 'column', maxWidth: 652 }}>
        <LoadingHolder isLoading={isLoading} />

        {!isLoading &&
          schedules &&
          !schedules.length &&
          getNoContentTab()}

        {!isLoading && screenOptions()}
      </VisitStyled>
    </>
  );
}
