import styled from 'styled-components';
import {
  colorGrayDarkest,
  colorClear,
  colorGrayLighter,
  DEFAULT_SIZE,
} from 'components/GlobalStyles/variables';

export const Container = styled.div`
  background-color: ${colorGrayDarkest};
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  transition: right 0.5s ease-in-out, opacity 0.7s ease-in-out;

  /* Initial display */
  top: 0;
  right: -150%;
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  opacity: 0;
  overflow: hidden;

  &.is-open {
    width: 100vw;
    min-width: 100vw;
    right: 0;
    opacity: 1;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  height: 64px;
  align-items: center;
  justify-content: space-between;

  .icon {
    color: ${colorGrayLighter};
    font-size: 24px;
  }

  .icon-back {
  }

  .title-holder {
    display: flex;
    height: 64px;
    align-items: center;

    h2 {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.15px;
      font-weight: bold;
      color: ${colorClear};
      margin: 0;
    }
  }

  .button-holder {
    padding: 21px;
  }
`;

export const Content = styled.div`
  overflow-x: auto;
  /* padding: ${DEFAULT_SIZE * 2}px; */
`;

export const Button = styled.button`
  border: 0 none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;
