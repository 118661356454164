import mask, { cleanUp } from './mask';

const PATTERN = '####';

const inputNumber = str => {
  const num = str.replace(/[^0-9]/g, '');
  return num || 0;
};

const newFormat = val => inputNumber(val).toString();

export default function doMask(input) {
  if (input === '') {
    return '';
  }
  const value = cleanUp(input);

  if (input.length > PATTERN.length - 1) {
    return newFormat(mask(`${PATTERN}`, value));
  }

  return newFormat(value);
}
