import React, { useRef, useEffect, Fragment } from 'react';
import * as routes from 'config/routes';
import {
  BrfLogoWhite,
  BanvitLogoWhite,
  Separator,
  Footer,
} from 'containers/OnePage/Login/Login.styled';
import { Welcome } from './Welcome.styled';
import t from 'components/i18n';

export default function WelcomeView(props) {
  const refs = [useRef(), useRef(), useRef()];
  const [h1, h3, logo] = refs;

  const elements = [
    <Fragment key="s">
      <section className="text">
        <h1 className="ws-pl" ref={h1}>
          {t("lp.hero.title")}
        </h1>

        <hr />

        <h3 className="ws-pl" ref={h3}>
          {t('lp.hero.text')}
        </h3>

        <button
          type="button"
          onClick={() => props.history.push(routes.BECOME_A_MEMBER)}
        >
          {t('lp.hero.buttonSecondary')}
        </button>
      </section>
      <Footer className="logo" ref={logo}>
        <BrfLogoWhite />
        <Separator />
        <BanvitLogoWhite />
      </Footer>
    </Fragment>,
  ];

  useEffect(() => {
    refs.forEach((el, idx) => {
      setTimeout(() => {
        if (el.current) {
          el.current.classList.add('expose');
        }
      }, 150 * idx + 1);
    });
  });

  return <Welcome {...props}>{elements}</Welcome>;
}
