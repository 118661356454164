import React from 'react';
import Icon from 'components/Icon';
import {
  StyledKeyValueTable,
  RowGroup,
  KeyValueItem,
  StatusRow,
} from './KeyValueTable.styled';
import { statusMapper } from 'utils/mapper';
import t from 'components/i18n';

const groupByQuantity = (items = [], quantity = 2) => {
  const grouped = [];
  const modiableCopyOfItems = [...items];
  while (modiableCopyOfItems.length) {
    grouped.push(modiableCopyOfItems.splice(0, quantity));
  }
  return grouped;
};

export default function KeyValueTable({
  values,
  status = { text: '', icon: 'check' },
  groupSize = 2,
  style,
  danger = false,
} = {}) {
  const groupedValues = groupByQuantity(values, groupSize) || [];

  return (
    <StyledKeyValueTable hasStatusRow={!!status.text} style={style}>
      {groupedValues.map((group, index) => {
        while (group.length < groupSize) {
          group.push({ label: '', value: '' });
        }
        return (
          <RowGroup isLastRow={index >= group.length}>
            {group.map(({ label, value }) => (
              <KeyValueItem>
                <span className="item-label">{label}</span>
                <span className="item-value">{value}</span>
              </KeyValueItem>
            ))}
          </RowGroup>
        );
      })}
      {status.text && (
        <StatusRow danger={danger}>
          <Icon name={`status icon icon-${status.icon}`} />
          {t(statusMapper(status.text))}
        </StatusRow>
      )}
    </StyledKeyValueTable>
  );
}
