import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import moment from 'moment';
import { WEEK_SCHEDULE_SETTINGS } from 'config/routes';
import { ThemeContext } from 'styled-components';
import { Button } from '@zendeskgarden/react-buttons';
import { getPermissionsById } from 'shared/utils/Helpers';
import LoadingHolder from 'components/LoadingHolder';
import Top from 'components/Top';
import TableComponent from 'components/Table';
import BadgePill from 'components/BadgePill';
import Pagination from 'components/Pagination';
import Icon from 'components/Icon';
import {
  colorSuccess,
  colorDanger,
} from 'components/GlobalStyles/variables';
import JustificationDetails from './JustificationDetails';
import {
  getLogBookItems,
} from './service';
import { tableHeader, getStatus, formatVisitName } from './utils';
import {
  ScheduleStyled,
  Container,
  OptionsButton,
} from './WeekSchedule.styled';
import t from 'components/i18n';
import { statusMapper } from 'utils/mapper';

export default function WeekSchedule(props) {
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);

  const [data, setData] = useState([]);
  const [producers, setProducers] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [visitTypes, setVisitTypes] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [totalPages, setTotalPages] = useState(null);

  const [bodyRequest, setBodyRequest] = useState({
    PageNumber: 1,
    PageSize: 20,
    StartDate: '',
    EndDate: '',
    SupervisorCode: '',
    VendorCode: '',
    LogBookItemTypeId: '',
    LogBookItemStatusId: '',
  });

  const { isMobile } = props.responsive;

  const themeContext = useContext(ThemeContext);

  const getLogBook = useCallback(async e => {
    setIsContentLoading(true);
    try {
      const response = await getLogBookItems(e);

      if (response) {
        setData(response.itens);
        setTotalPages(response.totalPages);
        setVisitTypes(response.typesList);
        setStatusList(response.statusList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsContentLoading(false);}
  }, []);

  const getFilterByLabel = useCallback(
    label => activeFilters.find(filter => label === filter.label),
    [activeFilters],
  );

  const settingsPermission = getPermissionsById(
    props.user.allowRoutes,
    '1',
  );

  const getFilters = useMemo(() => {
    let visitArray = [];
    let statusArray = [];

    visitArray =
      visitTypes &&
      visitTypes.length > 0 &&
      visitTypes.map(type => ({
        txt: formatVisitName(t(type.logBookItemType)),
        visitId: type.logBookItemTypeId,
      }));

    statusArray =
      statusList &&
      statusList.length > 0 &&
      statusList.map(type => ({
        txt: t(statusMapper(type.status)),
        statusId: type.statusId,
      }));

    return [
      {
        label: t('next.weekSchedule.visit-type'),
        items: visitArray,
      },
      {
        label: t('next.weekSchedule.status'),
        items: statusArray,
      },
    ];
  }, [visitTypes, statusList]);

  useEffect(() => {
    if (activeFilters) {
      // new search
      setCurrentPage(1);

      const supervisor = getFilterByLabel(t('next.weekSchedule.extensionist-name'));
      const producer = getFilterByLabel(t('next.weekSchedule.integrated-name'));
      const visitType = getFilterByLabel(t('next.weekSchedule.visit-type'));
      const status = getFilterByLabel(t('next.weekSchedule.status'));

      setBodyRequest(oldBodyRequest => ({
        ...oldBodyRequest,
        StartDate: dateFilter
          ? moment(dateFilter.startDate).format('YYYY-MM-DDT00:00:01')
          : '',
        EndDate: dateFilter
          ? moment(dateFilter.endDate).format('YYYY-MM-DDT23:59:59')
          : '',
        SupervisorCode: supervisor ? supervisor.id : '',
        VendorCode: producer ? producer.code : '',
        LogBookItemTypeId: visitType ? visitType.visitId : '',
        LogBookItemStatusId: status ? status.statusId : '',
      }));
    }
  }, [activeFilters, dateFilter, getFilterByLabel]);

  const tableData = useMemo(
    () =>
      data &&
      data.length > 0 &&
      data.map((e, index) => ({
        0: (
          <div className={isMobile ? 'column mobile' : 'column'}>
            <p className="small caps">{t('next.weekSchedule.table.code', { value: e.vendorCode })}</p>
            <p className="strong">{e.vendorName}</p>
            <p className="small">{t("next.weekSchedule.table.extensionist", { value: e.supervisorName })}</p>
          </div>
        ),
        1: e.flock,
        2: `${moment(e.visitDate).format('DD ̸̸  MM ̸̸  YYYY')}`,
        3: `${formatVisitName(t(e.logBookItemType))}`,
        4: (
          <BadgePill
            bgColor={getStatus(e.statusId).color}
            style={{ margin: '3px auto' }}
          >
            {t(getStatus(e.statusId).label)}
          </BadgePill>
        ),
        5: (
          <div
            className="flex"
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {e.status.startsWith('Não') && (
              <>
                <Icon
                  color={e.justification ? colorSuccess : colorDanger}
                  name={
                    e.justification
                      ? 'icon-check-circle'
                      : 'icon-close-circle'
                  }
                  size={32}
                />
                <OptionsButton onClick={() => setSelectedSchedule(e)}>
                  <Icon size={24} name="icon-ellipsis-v" />
                </OptionsButton>
              </>
            )}
          </div>
        ),
      })),
    [data, isMobile],
  );

  const getPagination = useMemo(
    () =>
      data.length > 0 &&
      Number(totalPages) > 0 && (
        <Pagination
          totalPages={totalPages}
          pagePadding={isMobile ? 0.75 : 2}
          currentPage={currentPage}
          onChangePage={x => setCurrentPage(x.e)}
        />
      ),
    [currentPage, data, isMobile, totalPages],
  );

  useEffect(() => {
    if (currentPage) {
      getLogBook({ ...bodyRequest, PageNumber: currentPage });
    }
  }, [currentPage, bodyRequest, getLogBook]);

  useEffect(() => setCurrentPage(1), []);

  const renderTable = useMemo(
    () => (
      <TableComponent
        loading={isContentLoading}
        filter={{
          setDateFilter: setDateFilter,
          activeFilters: activeFilters,
          setActiveFilters: setActiveFilters,
          filters: getFilters,
        }}
        titleColor={themeContext.styles.palette.colorPrimary}
        rowSize="large"
        header={tableHeader}
        mobileHeader={t("next.weekSchedule.table.header.user")}
        data={tableData}
        width="25%"
        isMobile={isMobile}
      />
    ),
    [
      activeFilters,
      getFilters,
      isContentLoading,
      isMobile,
      tableData,
      themeContext,
    ],
  );

  return (
    <>
      <Top>
        <h2>{t('next.weekSchedule.logbook.title')}</h2>
        {Boolean(settingsPermission.edit) && (
          <Button
            className="tertiary"
            onClick={() => props.history.push(WEEK_SCHEDULE_SETTINGS)}
          >
            {t('next.weekSchedule.button.config')}
          </Button>
        )}
      </Top>
      <ScheduleStyled>
        <Container style={{ marginBottom: '30px' }}>
          <LoadingHolder isLoading={isContentLoading} />

          {!isContentLoading && renderTable}
        </Container>

        <JustificationDetails
          setDisplayBox={setSelectedSchedule}
          displayBox={selectedSchedule}
          data={selectedSchedule}
        />

        {getPagination}
      </ScheduleStyled>
    </>
  );
}
