import styled from 'styled-components';
import { rgba } from 'polished';
import {
  DEFAULT_SIZE,
  colorClear,
  colorGrayDark,
  colorGrayDarkest,
  colorGrayLight,
  colorGrayLightest,
  colorTertiary,
  borderRadius,
  fontFamilyTextBold,
  breakpointTablet,
} from 'components/GlobalStyles/variables';

export const OverviewStyled = styled.section`
  background-color: ${colorGrayLightest};
  position: relative;
  padding: 20px 24px;

  strong.title {
    color: ${colorGrayDarkest};
    font-family: ${fontFamilyTextBold};
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
  }

  ul {
    margin: 0;
    padding: 0;
    max-width: 1112px;
  }

  .cardWrapper {
    margin: 16px 0 0;
    gap: 16px;
    max-width: 1112px;

    section {
      width: 50%;
    }

    .additional {
      color: ${colorTertiary};
    }
  }
`;

export const OverviewItem = styled.li`
  border-radius: ${borderRadius};
  background-color: ${colorClear};
  padding: ${DEFAULT_SIZE * 2}px;
  width: 100%;
  max-width: 47%;
  margin-top: 16px;

  &.disabled {
    background-color: ${rgba(colorClear, 0.25)};

    strong {
      color: ${rgba(colorGrayDarkest, 0.5)};
    }

    p {
      color: ${rgba(colorGrayDark, 0.5)};
    }

    .icon {
      color: ${rgba(colorTertiary, 0.5)};
    }
  }

  @media (min-width: ${breakpointTablet}px) {
    max-width: 31%;
  }

  strong {
    color: ${colorGrayDarkest};
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 5px;
    display: block;
  }

  p {
    color: ${colorGrayLight};
    font-size: 12px;
    line-height: 16px;
    margin: 0;
  }

  .icon {
    font-size: 26px;
    color: ${colorTertiary};
  }
`;
