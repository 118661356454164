import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  Header,
  Body,
  Footer,
  Close,
} from '@zendeskgarden/react-modals';
import { Button, ButtonGroup } from '@zendeskgarden/react-buttons';
import { FormModal } from './FormModal.styled';
import t from 'components/i18n';

export default function Modal(props) {
  const themeContext = useContext(ThemeContext);

  const {
    opened,
    color,
    header = false,
    confirm,
    confirmLabel = t('button.global.send'),
    deny,
    denyLabel = t('button.global.cancel'),
    onCloseModal = () => {},
    noButtons = false,
  } = props;

  return (
    opened && (
      <FormModal
        {...props}
        color={themeContext.styles.palette[color]}
        animate
        bgColor={themeContext.styles.palette.colorPrimary}
        onClose={() => {
          onCloseModal();
        }}
      >
        {header && (
          <>
            <Header>
              <span className="container-detail" />
              {header}
            </Header>
            <Close
              aria-label="Close modal"
              onClick={() => onCloseModal()}
            />
          </>
        )}
        <Body>{props.children}</Body>
        {!noButtons && (
          <Footer>
            <ButtonGroup>
              <Button
                key="no"
                className={'warning' in props ? 'white' : ''}
                onClick={() => deny()}
              >
                {denyLabel}
              </Button>
              <Button
                key="yes"
                className={!('warning' in props) ? 'white' : ''}
                onClick={() => confirm()}
              >
                {confirmLabel}
              </Button>
            </ButtonGroup>
          </Footer>
        )}
      </FormModal>
    )
  );
}
