import styled from 'styled-components';
import {
  colorClear,
  colorGrayLighter,
  // DEFAULT_SIZE,
  // breakpointTablet,
  // breakpointDesktop,
} from 'components/GlobalStyles/variables';

export const Notification = styled.div`
  color: ${colorClear};
  display: flex;
  align-items: center;
  height: 40px;
  background-color: ${props => props.bgColor || colorGrayLighter};
  width: calc(100% + 32px);
  font-size: 12px;
  line-height: 16px;
  margin-left: -16px;
  margin-top: ${props => (props.slim ? '-17px' : '0')};
  padding: 0 16px;
  box-shadow: none;
  border-radius: 0;

  .icon-wrapper {
    text-align: center;
    width: ${props => (props.slim ? '30px' : '56px')};

    .icon {
      font-size: 24px;
      margin-left: -8px;
    }
  }
`;
