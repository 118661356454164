import React from 'react';
import * as routes from 'config/routes';
import Heading from 'components/Heading';
import { Button } from '@zendeskgarden/react-buttons';
import {
  Container,
  ConclusionBox,
} from 'containers/OnePage/BecomeAMember/BecomeAMember.styled';
import { FixedField } from 'components/Forms/Forms.styled';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import t from 'components/i18n';

export default function OnSubmitPage(props) {
  return (
    <>
      <Heading pageTitle={t('beAnAGrower.form.title')} />
      <ConclusionBox>
        <span
          className={`icon icon-${
            props.success ? 'mail' : 'exclamation-triangle'
          }  `}
        />
        <p className="bold">{t('beAnAGrower.form.success.title"')}</p>
        <p>
          {t('beAnAGrower.form.success.subtitle')}
        </p>
      </ConclusionBox>
      {/* \ Botões */}
      <FixedField>
        <Container>
          <BtnGroup>
            <Button
              key="clean"
              onClick={() => props.history.push(routes.SIGNIN)}
              disabled={props.isSubmitting}
            >
              {t('beAnAGrower.form.success.buttonPrimary')}
            </Button>
          </BtnGroup>
        </Container>
      </FixedField>
      {/* \ Botões */}
    </>
  );
}
