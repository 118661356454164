import t from 'components/i18n';
import React from 'react';
import { SummaryItem } from '../StyledNextFlock.styled';

export default function Origin({ data }) {
  return (
    <>
      <br />
      {data &&
        data.originInformation.map((item, index) => (
          <SummaryItem key={index}>
            <span className="info">{t('next.flock.labelInfo.origin.origin', { value: item.originCode })}</span>
            <br />
            <span className="label">{t('next.flock.labelInfo.origin.weeks', { value: item.weeksNumber })}</span>
            <br />
            <br />
            <span className="info">{t('next.flock.labelInfo.origin.quantity', { value: item.quantity })}</span>
            <br />
            <span className="label">
              {t('next.flock.labelInfo.origin.averageWeight', { value: item.averageWeight })}
            </span>
            <br />
            <span className="label">
              {t('next.flock.labelInfo.origin.houseDestination', { value: item.houseDestination })}
            </span>
          </SummaryItem>
        ))}
    </>
  );
}
