import React, { useState, useEffect, useContext } from 'react';
import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import qs from 'qs';
import { Button } from '@zendeskgarden/react-buttons';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Separator } from '@zendeskgarden/react-dropdowns';
import Badge from 'components/Badge';
import t from 'components/i18n';
import Breadcrumb from 'components/Breadcrumb';
import NoContentTab from 'components/NoContentTab';
import Modal from 'components/Forms/FormModal/Modal';
import {
  handleDateAndTime,
  getFileExtension,
  getPermissionsById,
} from 'shared/utils/Helpers';
import { ANNOUNCEMENTS } from 'config/routes';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import { AnnouncementsContext } from 'shared/contexts';
import { deleteItem } from './utils';
import { getAnnouncementsById } from './service';
import {
  Container,
  Title,
  Image,
  DateAndTime,
  ContentWrapper,
  Attachments,
  AHref,
  Text,
  Source,
} from './Announcements.styled';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { regionalMapper } from 'utils/mapper';

export default function AnnouncementDetails({
  match,
  user,
  location,
  history,
  isValidating,
  responsive,
}) {
  const [imageFiles, setImageFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [centers, setCenters] = useState([]);
  const [announcementsPermissions, setAnnouncementsPermissions] = useState({});
  const context = useContext(AnnouncementsContext);
  const { removedId } = context;
  const {
    setRemovedId,
    setEditAnnouncement,
    setSelected,
  } = context.actions;

  const userToken = user && user.token;
  const urlToken = location
    ? qs.parse(location.search)['?token']
    : null;
  const token = userToken || urlToken;

  function getPriorityLabel(item) {
    const options = {
      LOW: 'menu.announcements.listView.item.subtitle.low',
      MODERATE: 'menu.announcements.listView.item.subtitle.medium',
      HIGH: 'menu.announcements.listView.item.subtitle.high',
    };

    return options[item];
  }

  function backToList() {
    return history.push(ANNOUNCEMENTS);
  }

  async function fetchData(id, tk) {
    setIsLoading(true);
    try {
      const response = await getAnnouncementsById(id, tk);

      const announcement = {
        ...response.data,
        countryCode: uniqBy(
          response.data.regionals.map((element, id) => ({
            countryCode: element.countryCode,
            id,
          })),
          'countryCode',
        )
      }      

      setData(announcement);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData(match.params.id, token);
  }, [match.params.id, token]);

  useEffect(() => {
    setAnnouncementsPermissions(getPermissionsById((user || {}).allowRoutes, '9') || {});
  }, [user, getPermissionsById]);

  useEffect(() => {
    const imageExtensions = ['jpg', 'jpeg', 'gif', 'png'];

    if (data) {
      if (data.files && data.files.length > 0) {
        const images = data.files.filter(item =>
          imageExtensions.includes(getFileExtension(item.name)),
        );
        const other = data.files.filter(
          item =>
            !imageExtensions.includes(getFileExtension(item.name)),
        );

        setImageFiles(images);
        setOtherFiles(other);
      }

      if (data.regionals && data.regionals.length > 0) {
        setCenters(data.regionals.map((e, index) => e.centerList));
      }
    }
  }, [data]);

  useEffect(() => {
    if (removedId) {
      backToList();
      setRemovedId(match.params.id);
    }
  }, [backToList, match.params.id, removedId, setRemovedId]);

  return (
    <>
      {user && responsive && !responsive.isMobile && (
        <Breadcrumb
          history={history}
          path={[t('menu.announcements.title')]}
        />
      )}
      <div style={{ marginTop: responsive && responsive.isMobile ? '0px' : '60px' }}>
        <Container>
          {!isLoading &&
            (data ? (
              <>
                <DateAndTime>
                  {handleDateAndTime(moment(data.date))}
                </DateAndTime>

                <Badge
                  dark
                  style={{
                    position: 'absolute',
                    right: '16px',
                    top: '40px',
                    padding: '0 10px',
                  }}
                >
                  {t(getPriorityLabel(data.priority))}
                </Badge>
                <Title>{data.title}</Title>
                <ContentWrapper>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.description,
                    }}
                  />

                  {data.files &&
                    imageFiles.length > 0 &&
                    imageFiles.map(image => (
                      <div key={image.id} className="image-wrapper">
                        <Image
                          src={`${API_URL}/Blob/Download?fileName=${image.name}&blobContainerName=${image.container}&token=${token}&client_id=${KONG_CLIENT_ID}`}
                          alt={image.title}
                        />
                      </div>
                    ))}

                  {data.files && (
                    <Attachments>
                      <ul>
                        {otherFiles.length > 0 &&
                          otherFiles.map(file => {
                            let url = file.url;

                            if (
                              token !== undefined &&
                              getFileExtension(url) === 'pdf'
                            ) {
                              url = `https://docs.google.com/gview?embedded=true&url=${file.url}`;
                            }

                            return (
                              <li key={file.id}>
                                <AHref
                                  href={`${API_URL}/Blob/Download?fileName=${file.name}&blobContainerName=${file.container}&token=${token}&client_id=${KONG_CLIENT_ID}`}
                                  target={
                                    token !== undefined
                                      ? '_self'
                                      : '_blank'
                                  }
                                  rel="noopener noreferrer"
                                >
                                  <span className="icon icon-clip" />
                                  <Text>
                                    {file.name.split('_')[1]}
                                  </Text>
                                </AHref>
                              </li>
                            );
                          })}
                      </ul>
                    </Attachments>
                  )}

                  <Source>
                    {data.sourceName && (
                      <div>
                        {(data.sourceName.length > 0 ||
                          data.regionals.length > 0) && (
                          <>
                            <Separator />
                            <br />
                          </>
                        )}
                        <span className="label">Fonte: </span>
                        {data.sourceUrl.length > 0 ? (
                          <a
                            href={data.sourceUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>{data.sourceName}</span>
                          </a>
                        ) : (
                          data.sourceName && <p>{data.sourceName}</p>
                        )}
                      </div>
                    )}

                    {data.regionals && data.regionals.length > 0 && (
                      <>
                        <div>
                          <span className="label">
                            {t(
                              'menu.announcements.details.regional.label',
                            )}{' '}
                          </span>
                          {data.regionals.map((e, index) => (
                            <span key={index}>
                              {t(regionalMapper(e.name))}
                              {index !== data.regionals.length - 1 &&
                                ', '}
                            </span>
                          ))}
                        </div>

                        <div>
                          {centers && centers.length > 0 && (
                            <>
                              <span className="label">
                                {t(
                                  'menu.announcements.details.block.label',
                                )}{' '}
                              </span>
                              {centers.flat().map((e, index) => (
                                <span key={index}>
                                  {e.name}
                                  {index !==
                                    centers.flat().length - 1 && ', '}
                                </span>
                              ))}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </Source>
                </ContentWrapper>
                <Separator />

                <br />

                {user && (
                  <Row>
                    <Col sm={6}>
                      {announcementsPermissions &&
                        announcementsPermissions.delete && (
                          <BtnGroup noReverse>
                            <Button
                              key="submitForm"
                              onClick={() => setOpenModal(true)}
                              className="white"
                              disabled={isValidating}
                            >
                              {t('announcements.delete')}
                            </Button>
                          </BtnGroup>
                        )}
                    </Col>
                    <Col sm={6}>
                      {/* \ Botões */}
                      <BtnGroup style={{ paddingBottom: '16px' }}>
                        {announcementsPermissions &&
                          announcementsPermissions.edit && (
                          <Button
                            key="submitForm"
                            onClick={e => {
                              history.push(ANNOUNCEMENTS);
                              setSelected(data);
                              setEditAnnouncement(true);
                            }}
                          >
                            {t("global.button.edit")}
                          </Button>
                        )}
                        <Button
                          key="clean"
                          className="white"
                          onClick={() => backToList()}
                        >
                          {t('global.button.back')}
                        </Button>
                      </BtnGroup>
                      {/* \ Botões */}
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <NoContentTab
                icon="icon-close-circle"
                onButtonClick={() => backToList()}
                buttonLabel={t("announcements.button.list")}
              >
                <p>
                  {t("announcements.error.not-found.pt1")}<br />{' '}
                  <span className="lighter">
                    {t("announcements.error.not-found.pt2")}
                  </span>
                </p>
              </NoContentTab>
            ))}

          <Modal
            warning
            opened={openModal}
            confirm={() => {
              deleteItem(match.params.id, setRemovedId);
            }}
            deny={() => setOpenModal(false)}
            onCloseModal={() => {
              setOpenModal(false);
            }}
          >
            <span className="icon icon-exclamation-triangle" />
            <h4>{t("announcements.delete.confirmation.title")}</h4>
            <p>
              {t("announcements.delete.confirmation.subtitle")}
            </p>
          </Modal>
        </Container>
      </div>
    </>
  );
}
