// @flow

import React, { useState, useEffect } from 'react';
import uid from 'uuid';
import * as routes from 'config/routes';
import Box from 'components/Container';
import Badge from 'components/Badge';
import t from 'components/i18n';
import {
  Dropdown,
  Menu,
  Item,
  Trigger,
} from '@zendeskgarden/react-dropdowns';
import { getSchedule } from 'components/Schedule/service';
import LoadingHolder from 'components/LoadingHolder';
import NoContentTab from 'components/NoContentTab';
import FeedList, { FeedItem } from 'components/FeedList';
import { POLLING_INTERVAL } from 'config/constants';
import {
  FeedWrapper,
  FeedContainer,
  Separator,
  SelectBox,
} from './SchedulingFeed.styled';
import { DropdownBox, FieldSet } from 'components/Forms/Forms.styled';
import { DropdownField } from 'components/Forms/Fields';
import { Label } from 'styled-minimal';
import Icon from 'components/Icon';
import { getIcon } from 'containers/NextBRF/NextFlock/utils';
import { statusMapper } from 'utils/mapper';
import moment from 'moment';

const options = [
  {
    value: 'all',
    label: t('dashboard.sectionSchedule.tabMenu.itemOne'),
  },
  {
    value: 'feed',
    label: t('dashboard.sectionSchedule.tabMenu.itemTwo'),
  },
  {
    value: 'placement',
    label: t('dashboard.sectionSchedule.tabMenu.itemThree'),
  },
  {
    value: 'slaughter',
    label: t('dashboard.sectionSchedule.tabMenu.itemFour'),
  },
];

export default function SchedulingFeed(props) {
  const {
    history,
    responsive,
    className,
    isCompact,
  } = props;

  const [selectedKey, setSelectedKey] = useState({
    value: 'all',
    label: t('dashboard.sectionSchedule.tabMenu.itemOne'),
  });
  const [data, setData] = useState < TScheduleObject > ({});
  const [loading, setLoading] = useState(true);
  const hasData = Object.keys(data).length > 0;

  let elements = [];

  useEffect(() => {
    let timer = null;
    async function fetchData() {
      setLoading(true);
      try {
        const schedule = await getSchedule();
        setData(schedule);
      } catch (e) {
        console.error(e);
      }

      clearTimeout(timer);
      timer = setTimeout(fetchData, POLLING_INTERVAL * 6);
      setLoading(false);
    }

    fetchData();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const getDate = (date) => {
    return moment(date).format('DD ̸̸  MM ̸̸  YYYY');
  }

  if (!data[selectedKey.value] || !data[selectedKey.value].length) {
    elements = (
      <NoContentTab icon="icon-close-circle">
        <p>
          {t('schedule.unavailable')}<br />{' '}
        </p>
      </NoContentTab>
    );
  } else {
    elements = data[selectedKey.value].map(feed => {
      const badgeProps = {};
      const feedProps = {
        [feed.type]: true,
      };

      if (feed.type === 'slaughter') {
        // @todo Implement animal type
        feedProps[feed.flockType] = true;
      }

      if (feed.status) {
        switch (feed.status.toLowerCase()) {
          case 'confirmado':
            badgeProps.info = true;
            break;
          case 'programado':
            badgeProps.info = true;
            break;
          default:
            badgeProps.default = true;
        }
      }

      return (
        <FeedItem
          key={uid()}
          {...feedProps}
          type={feed.type}
          onClick={() => {
            history.push(
              `${routes.FLOCK_LIST}/${feed.flockID}`,
            );
          }}
        >
          <header className="item-header">
            <div className="date">
              {t(feed.date && feed.date.key, {
                value: getDate(feed.date && feed.date.value),
              })}
            </div>

            <div className="status">
              {feed.status && (
                <Badge {...badgeProps} type={feed.flockType}>
                  {t(statusMapper(feed.status))}
                </Badge>
              )}
            </div>
          </header>
          <div className="flock">
            {t(feed.title && feed.title.key, {
              value: feed.title && feed.title.value,
            })}
          </div>
          <footer className="item-footer">
            <span className="units">
              {t(feed.subtitle && feed.subtitle.key, {
                value: feed.subtitle && feed.subtitle.value,
              })}
            </span>
            <p className="caption">
              {t("flock.activities.feed.feed-item", {
                value: feed.subtitleInfo && feed.subtitleInfo.value,
              })}
            </p>
          </footer>
        </FeedItem>
      );
    });
  }

  const classNames = `scheduling-feed ${className}`;

  return (
    <Box slim className={classNames}>
      <FeedWrapper className="scheduling-feed__wrapper">
        <header className="header">
          <h2>{t('schedule.title')}</h2>

          {isCompact && (
            <Dropdown>
              <Trigger>
                <span className="scheduling-feed__placeholder">
                  {selectedKey.label}
                  <Icon name="icon-chevron-down" />
                </span>
              </Trigger>
              <Menu>
                {options.map((option, index) => (
                  <Item key={index} value={option.value} onClick={() => setSelectedKey(option)}>
                    {t(option.label)}
                  </Item>
                ))}
              </Menu>
            </Dropdown>
          )}
        </header>

        <Separator />

        <FeedContainer className={`scheduling-feed__container compact`}>
          {loading
            ?
              <LoadingHolder isLoading={loading} />
            :
              <>
                {!isCompact &&
                  <div className="scheduling-feed-sidebar scheduling-feed-item">
                    <ul>
                      <li
                        className={`${selectedKey.value === 'all' ? 'active' : ''}`}
                      >
                        <button
                          onClick={() => setSelectedKey(options.find(option => option.value === 'all'))}
                          type="button"
                        >
                          {t('dashboard.sectionSchedule.tabMenu.itemOne')}
                        </button>
                      </li>
                      <li
                        className={`${selectedKey.value === 'feed' ? 'active' : ''}`}
                      >
                        <button
                          onClick={() => setSelectedKey(options.find(option => option.value === 'feed'))}
                          type="button"
                        >
                          {t('dashboard.sectionSchedule.tabMenu.itemTwo')}
                        </button>
                      </li>
                      <li
                        className={`${selectedKey.value === 'placement' ? 'active' : ''}`}
                      >
                        <button
                          onClick={() => setSelectedKey(options.find(option => option.value === 'placement'))}
                          type="button"
                        >
                          {t('dashboard.sectionSchedule.tabMenu.itemThree')}
                        </button>
                      </li>
                      <li
                        className={`${selectedKey.value === 'slaughter' ? 'active' : ''}`}
                      >
                        <button
                          onClick={() => setSelectedKey(options.find(option => option.value === 'slaughter'))}
                          type="button"
                        >
                          {t('dashboard.sectionSchedule.tabMenu.itemFour')}
                        </button>
                      </li>
                    </ul>
                  </div>
                }
                <div className="scheduling-feed-elements scheduling-feed-item">
                  {data[selectedKey.value] && <FeedList>{elements}</FeedList>}
                </div>
              </>
          }
        </FeedContainer>
      </FeedWrapper>
    </Box>
  );
}
