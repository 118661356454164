import styled from 'styled-components';

import {
  sceneGap,
  colorPrimary,
  colorClear,
  colorDanger,
  breakpointTablet,
  breakpointDesktop,
} from 'components/GlobalStyles/variables';
import bgDetail from 'shared/assets/images/background-detail.png';
import brfLogoWhite from './assets/brf-logo-white.svg';
import banvitLogoWhite from './assets/banvit-logo-white.svg';
import bgImage from './assets/login-background.jpg';
import logo from './assets/agro-logo-white.png';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: ${sceneGap};
  margin: 0 auto;

  header & {
    align-items: center;
  }

  @media (min-width: ${breakpointTablet}px) {
    max-width: ${breakpointDesktop - 1}px;
  }

  @media (min-width: ${breakpointDesktop}px) {
    flex-direction: row;
    padding-left: ${sceneGap};
    padding-right: ${sceneGap};
    max-width: 950px;
  }

  .container-content {
    height: 100%;
    border-radius: 0;
    padding-top: 14px;
  }

  .container-item {
    min-width: 312px;

    .side-box {
      display: flex;
      justify-content: flex-end;

      @media (min-width: ${breakpointDesktop}px) {
        width: 350px;
      }
    }

    @media (min-width: ${breakpointTablet}px) {
      width: 100%;
      max-width: 528px;
    }

    @media (min-width: ${breakpointDesktop}px) {
      width: 440px;
    }
  }
`;

export const Separator = styled.div`
  width: 2px;
  background-color: #ffffff;
  z-index: 1;
  opacity: 0.75;
  border-radius: 110px;
  margin: 0 3rem;
`;

export const Footer = styled.footer`
  display: flex;
`;

export const Background = styled.div`
  position: relative;
  /* background: linear-gradient(132.6deg, ${colorDanger} 31.02%, ${colorPrimary} 81.73%); */
  background: linear-gradient(135deg, rgba(239,65,17,1) 0%, rgba(216,43,40,1) 80%, rgba(210,38,46,1) 100%);
  // position: absolute;
  width: 100%;
  min-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 1;
  background: ${colorPrimary} url(${bgImage}) no-repeat 25% 0;
  background-size: cover;

  @media (min-width: ${breakpointTablet}px) {
    background-position: center left;
  }
  

  &::before {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    background-size: cover !important;
    background: url(${bgDetail}) no-repeat 0 0;  
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,0));
    height: 60px;
    width: 100%;
    z-index: -1;
  }
`;

export const Logo = styled.div`
  width: 145px;
  height: 24px;
  background: transparent url(${logo}) no-repeat center center;
  background-size: contain;
  /* margin: 0 ${sceneGap}; */
`;

export const Header = styled.header`
  height: 64px;
  position: relative;

  ${Container} {
    height: 64px;
    /* padding: 0 ${sceneGap}; */
    padding-top: 0;
    padding-bottom: 0;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50px;

    @media (min-width: ${breakpointDesktop}px) {
      justify-content: space-between;
      align-items: center;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    background-color: ${colorClear};
    border: none;
    height: 1px;
    opacity: 0.12;
    width: 100%;
    margin: 0;
  }
`;

export const Body = styled.div`
  /* margin-top: 20px; */
  overflow: hidden;
`;

export const LogoFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  min-width: 312px;

  @media (min-width: ${breakpointDesktop}px) {
    display: none;
  }
`;

export const TitleBlock = styled.div``;

export const BrfLogoWhite = styled.div`
  width: 110px;
  height: 53px;
  background: transparent url(${brfLogoWhite}) no-repeat 0 0;
`;

export const BanvitLogoWhite = styled.div`
  width: 90px;
  height: 54px;
  background: transparent url(${banvitLogoWhite}) no-repeat 0 0;
`;
