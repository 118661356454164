import React from 'react';
import TemporaryRecord from 'containers/AgroBRF/FlockList/Flock/TemporaryRecord';
import SwineRecord from 'containers/AgroBRF/FlockList/Swine/SwineRecord';

export default function ContentSwitcher(props) {
  const switcher = [
    {
      type: 'broiler',
      component: TemporaryRecord,
      visible: true,
    },
    {
      type: 'swine',
      component: SwineRecord,
      visible: props.isVisible,
    },
  ];

  return switcher.map(
    (e, i) =>
      props.data.flockType === e.type &&
      props.isVisible && <e.component key={i} {...props} />,
  );
}
