// @flow

import React, { useState, useEffect, useContext } from 'react';
import t from 'components/i18n';
import { dateToTimeString } from 'shared/utils/Helpers/handleDate';
import * as yup from 'yup';
import { Formik } from 'formik';
import { convertWeightToNumber } from 'shared/utils/Helpers/formatWeight';

// validate
import validate from 'components/Forms/Fields/validate';
// Contexts
import { WeighingContext } from 'shared/contexts';
import NoContentTab from 'components/NoContentTab';
import { saveWeight } from 'containers/AgroBRF/FlockList/service';
import AddWeighingForm from './AddWeighingForm';
import moment from 'moment';

function validateSchema(props) {
  // custom method for minimum weight value
  yup.addMethod(yup.string, 'minMethod', function(args) {
    const message = t('global.validation.min-weight', { weight: props.age.min });

    return this.test('test-min', message, value => {
      return props.age.min <= convertWeightToNumber(value);
    });
  });

  // custom method for maximum weight value
  yup.addMethod(yup.string, 'maxMethod', function(args) {
    const message = t('global.validation.min-weight', { weight: props.age.max });

    return this.test('test-min', message, value => {
      return props.age.max >= convertWeightToNumber(value);
    });
  });

  return yup.object().shape({
    house: yup.object().required(t('global.status.required-field')),
    age: yup.object().required(t('global.status.required-field')),
    schedule: yup.string().required(t('global.status.required-field')),
    weight: yup
      .string()
      .minMethod()
      .maxMethod()
      .required(t('global.status.required-field')),
    deaths: yup
      .number()
      .transform(cv => (isNaN(cv) ? undefined : cv))
      .min(0, t('global.validation.fill-input'))
      .required(t('global.status.required-field')),
    deletions: yup
      .number()
      .transform(cv => (isNaN(cv) ? undefined : cv))
      .min(0, t('global.validation.fill-input'))
      .required(t('global.status.required-field')),
  });
}

const initialValues = {
  flockId: '',
  house: {},
  age: {},
  schedule: '',
  weight: '',
  deaths: null,
  deletions: null,
};

export default function AddWeighing(props) {
  const weighingContext = useContext(WeighingContext);

  const { selectedWeight, submitResponse } = weighingContext;
  const {
    setSelectedWeight,
    setSubmitResponse,
    setSuccess,
  } = weighingContext.actions;

  const [message, setMessage] = useState('');
  const {
    id,
    flockId,
    houseValue,
    houseId,
    ageValue,
    ageId,
    date,
    hour,
    value,
    dead,
    eliminate,
    enabledAges,
    totalDeaths,
  } = selectedWeight;

  const minWeight =
    enabledAges && enabledAges.filter(e => date === e.date)[0].min;
  const maxWeight =
    enabledAges && enabledAges.filter(e => date === e.date)[0].max;

  const selectedValues = {
    id: id,
    flockId: flockId,
    house: {
      id: houseId,
      value: houseValue,
      label: t('activities.weight.house', { value: houseValue }),
    },
    age: {
      id: ageId,
      value: ageValue,
      date: date,
      label: t('activities.weight.days', { value: ageValue }),
      min: minWeight && minWeight,
      max: maxWeight && maxWeight,
    },
    schedule: moment(new Date(`2020/01/01 ${hour}`)),
    weight: value,
    deaths: dead,
    deletions: eliminate,
    totalDeaths: totalDeaths,
  };

  function submitForm(values, { setSubmitting, setErrors }) {
    const formData = {
      id: values.id || null,
      weightHour: dateToTimeString(values.schedule),
      weightValue: values.weight,
      dead: Number(values.deaths),
      eliminate: Number(values.deletions),
      flockId: values.flockId,
      houseId: values.house.id,
      weightAgeId: values.age.id,
    };

    async function saveData(val) {
      setSubmitting(true);
      try {
        const response = await saveWeight(val);
        if (response) {
          setSuccess(response.success);
          setSubmitResponse(response.message);
        } else {
          setSuccess(false);
          throw Error(response);
        }
      } catch (error) {
        console.error(error);
        setSubmitResponse(t('global.validation.error.upload'));
      }
      setSubmitting(false);
      props.goTo('summary');
    }
    saveData(formData);
  }

  return props.houses.length > 0 ? (
    <Formik
      initialValues={
        selectedWeight.id ? selectedValues : initialValues
      }
      validate={validate(validateSchema)}
      onSubmit={submitForm}
      render={formikProps => (
        <AddWeighingForm
          {...formikProps}
          selectedWeight={selectedWeight}
          houses={props.houses}
          flockID={props.flockID}
          message={message}
          setMessage={setMessage}
          setSelectedWeight={e => setSelectedWeight(e)}
          enabledAges={enabledAges}
          goTo={props.goTo}
          sendToConfirmation={props.sendToConfirmation}
          isLoading
        />
      )}
    />
  ) : (
    <NoContentTab icon="icon-scale">
      <p>{t('activities.error.noHouses')}</p>
    </NoContentTab>
  );
}
