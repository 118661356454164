import React, { useMemo } from 'react';
import { SCHEDULE, NEXT_FLOCK_LABEL } from 'config/routes';
import SidebarRight, {
  // SidebarRightContent,
  SidebarToggler,
} from 'components/SidebarRight';
import t from 'components/i18n';
import Icon from 'components/Icon';
import {
  Menu,
  TabletMenu,
  ListItem,
} from 'containers/AgroBRF/FlockList/Flock/Flock.styled';

export default function NextFlockDetailsMenu(props) {
  const {
    user,
    flockClosed,
    history,
    vendorId,
    flockId,
    isMobile,
    isTablet,
    isGtMobile,
    setDisplayDetails,
    isSwine,
  } = props;

  const displayDemonstrative = useMemo(() => {
    return user.allowRoutes
      ? user.allowRoutes.findIndex(item => item.id === '11' > -1)
      : true;
  }, [user]);

  const menuOptions = useMemo(() => {
    const options = [
      {
        id: 1,
        title: t('next.flock.flockDetailsMenu.menu.options.demonstrative'),
        icon: 'icon-chart-analytics',
        action: () => setDisplayDetails('demonstrative'),
        visible: flockClosed && displayDemonstrative,
      },
      {
        id: 2,
        title: t('next.flock.flockDetailsMenu.menu.options.flock'),
        icon: 'icon-info',
        action: () => history.push(`${NEXT_FLOCK_LABEL}/${flockId}`),
        visible:
          user.allowRoutes.length > 0
            ? !!user.allowRoutes.findIndex(
                item => item.id === '15' > -1,
              )
            : true,
      },
      {
        id: 3,
        title: t('next.flock.flockDetailsMenu.menu.options.schedule'),
        icon: 'icon-stopwatch',
        action: () =>
          history.push(`${SCHEDULE}/${flockId}/${vendorId}`),
        visible:
          user.allowRoutes.length > 0
            ? !!user.allowRoutes.findIndex(
                item => item.id === '16' > -1,
              )
            : true,
      },
    ];

    if (isSwine) {
      // delete 'Dados de etiqueta' for swine
      return options.filter(item => item.id !== 2);
    }

    return options;
  }, [
    displayDemonstrative,
    flockClosed,
    flockId,
    history,
    isSwine,
    setDisplayDetails,
    user.allowRoutes,
    vendorId,
  ]);

  function getTabletToggler(type) {
    return menuOptions.map(item => (
      <button
        key={item.id}
        onClick={() => item.action()}
        type="button"
        className="unstyled-button tablet-menu-item"
      >
        <Icon className="tablet-menu-icon" name={item.icon} />
        <span className="tablet-menu-label">{item.title}</span>
      </button>
    ));
  }

  function getToggler(type) {
    return menuOptions.map(
      item =>
        item.visible && (
          <div key={item.id} className="menu-item">
            <Icon className="menu-icon" name={item.icon} />

            <ListItem
              onClick={() => item.action()}
              role="button"
              className="animated fadeIn"
            >
              <div className="list-item-content">
                <div className="list-item-heading">{item.title}</div>
              </div>
              <Icon name="chevron icon-chevron-right" />
            </ListItem>
          </div>
        ),
    );
  }

  return (
    <div className="flock-menu">
      {props.children}
      {isTablet && <TabletMenu>{getTabletToggler()}</TabletMenu>}

      {!isTablet && (
        <>
          <Menu hasChildren={props.children}>
            <div className="menu-group">
              {isMobile && (
                <SidebarRight>
                  <SidebarToggler>
                    {getToggler('tagData')}
                  </SidebarToggler>
                  <SidebarToggler>
                    {getToggler('schedule')}
                  </SidebarToggler>
                </SidebarRight>
              )}

              {isGtMobile && getToggler()}
            </div>
          </Menu>
        </>
      )}
    </div>
  );
}
