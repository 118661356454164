import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import {
  DEFAULT_SIZE,
  boxShadow,
  fontFamilyText,
  breakpointDesktop,
  breakpointTablet,
  colorTertiary,
  colorGrayLightest,
  colorClear,
  colorGrayLight,
  colorGrayUltraLight,
  colorGrayDarker,
  colorGrayDarkest,
  colorDanger,
  colorSuccess,
  colorGrayLighter,
  colorGray,
  fontFamilyTextBold,
} from 'components/GlobalStyles/variables';

export const StyledNextFlockList = styled.div`
  display: flex;
  padding: ${DEFAULT_SIZE * 2}px 20px;
  flex-wrap: wrap;

  .secondary-wrapper {
    min-width: 280px;
    margin: 0 20px 20px 0;

    @media (min-width: ${breakpointDesktop}px) {
      min-width: 316px;
    }
  }

  .align-right {
    text-align: right;
  }

  .flock-details {
    background-color: ${colorClear};
    border-radius: 8px;
    width: 100%;
    max-width: 692px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(37, 48, 71, 0.16);
  }

  .list-item-content {
    width: 100%;
  }

  .small-heading {
    color: ${colorGrayLight};
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: ${DEFAULT_SIZE * 2}px;
    display: block;
  }

  .big {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;

    &:before {
      font-size: 20px;
      position: relative;
      top: 3px;
      left: 3px;
    }
  }

  .red {
    color: ${colorDanger};
  }

  .green {
    color: ${colorSuccess};
  }

  .highlight {
    background-color: ${colorGrayUltraLight};
    text-align: center;
    margin: 0 -16px 18px;
    padding: 10px 0;

    p {
      color: ${colorGrayDarker};
      margin: 0;
      font-size: 16px;
      line-height: 24px;
    }

    h2 {
      color: ${colorGrayDarkest};
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
    }
  }
`;

export const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${colorGrayDarker};
  font-size: 14px;

  strong {
    display: block;
    margin-top: 6px;
  }

  p {
    margin: 0;
  }

  .small {
    font-size: 12px;
    color: ${colorGrayLighter};
  }

  .list-item-heading {
    margin: 0;
  }
`;

export const ItemColumn = styled.div`
  width: 50%;
  position: relative;
`;

export const FlockActions = styled.div`
  background-color: ${colorGrayLightest};
  padding: 20px ${DEFAULT_SIZE * 2}px;
`;

export const IconList = styled.div`
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
`;

export const Separator = styled.hr`
  margin: ${DEFAULT_SIZE * 2}px -${DEFAULT_SIZE * 2}px;
  opacity: 0.12;
  margin: ${props =>
    props.gap && `${props.gap}px -${DEFAULT_SIZE * 2}px`};
  width: ${props => props.full && `calc(100% + 32px)`};
`;

export const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Container = styled.div`
  position: relative;
  padding: ${DEFAULT_SIZE}px ${DEFAULT_SIZE * 2}px;
  background-color: ${colorClear};
  margin: 16px;
  border-radius: 12px;
  box-shadow: ${boxShadow};
  max-width: 630px;
  width: 100%;
  overflow: hidden;

  @media (min-width: ${breakpointTablet}px) {
    margin: 20px 20px 60px 20px;
  }

  > p {
    font-family: ${fontFamilyText};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colorGray};
    margin: 0 0 24px;
  }

  .container-detail {
    display: block;
    background-color: ${colorTertiary};
    height: 3px;
    width: 50px;
    margin: -8px 0 13px;
  }

  .container-title {
    h4 {
      font-family: ${fontFamilyTextBold};
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 12px;
    }

    &:after {
      content: '';
      display: block;
      height: 1px;
      background-color: ${rgba(colorGrayDarkest, 0.12)};
      width: calc(100% + 32px);
      position: relative;
      left: -16px;
    }
  }
`;

export const HistoryItem = styled.div`
  position: relative;
  margin: 10px 0;

  &::after {
    position: absolute;
    content: '';
    width: 1px;
    top: 40px;
    left: 10px;
    height: calc(100% - 40px);
    background-color: ${colorGrayLightest};
  }

  .item-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0 12px;
  }

  .date {
    margin: 0;
    align-items: center;
    display: flex;

    p {
      font-size: 12px;
      margin: 4px 0 0;
      line-height: 20px;
    }

    span {
      font-size: 22px;
      line-height: 20px;
      margin-right: 16px;
    }
  }
`;

export const Summary = styled.div`
  position: relative;
`;

export const Subheading = styled.div`
  color: ${colorGrayDarkest};
  font-family: ${fontFamilyTextBold};
  font-size: 16px;
  line-height: 24px;
  margin: 0 16px 16px;
`;

export const SummaryItem = styled.p`
  color: ${colorGrayLight};
  margin: 0;
  font-size: 14px;
  padding: 16px;
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.12)};
  align-items: center;

  .label {
    font-size: 12px;
  }

  .info {
    color: ${colorGrayDarkest};

    &.add {
      color: ${colorGrayLight};
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.gray {
    background-color: ${colorGrayUltraLight};
  }

  .column {
    display: flex;
    flex-direction: column;
    text-align: right;

    span {
      margin-bottom: 7px;
    }
  }

  &:last-of-type {
    border: none;
  }
`;
