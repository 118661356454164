import React, { useMemo } from 'react';
import { PROFILE } from 'config/routes';
import Top from 'components/Top';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import t from 'components/i18n';
import { ProfileStyled } from './ProfileAndSettings.styled';

import AccountSettings from './AccountSettings';
import Documentation from './Documentation';
import LegalInformation from './LegalInformation';

export default function ProfileAndSettings({
  responsive,
  match: { params },
  history,
  user,
}) {

  const tabs = useMemo(
    () => [
      {
        name: t('menu.profile.sectionAccountConfig.Title'),
        key: 'account-settings',
        view: AccountSettings,
        visible: true,
      },
      {
        name: t('menu.profile.sectionDocumentation.title'),
        key: 'documentation',
        view: Documentation,
        visible:
          user.profile === 'Administrador' &&
          user.type !== 'F'
      },
      {
        name: t('menu.profile.sectionLegalInformation.title'),
        key: 'legal-information',
        view: LegalInformation,
        visible: true,
      },
    ],
    [user.profile, user.type],
  );

  return (
    <>
      <Top>
        <h2>{t('menu.profile.title')}</h2>
      </Top>
      <Header>
        {tabs.map(
          (item, index) =>
            item.visible && (
              <TabHeaderHolder
                key={index}
                onClick={() => history.push(`${PROFILE}/${item.key}`)}
                className={params.tab === item.key ? 'active' : ''}
              >
                <div className="label">{item.name}</div>
                <div className="bar" />
              </TabHeaderHolder>
            ),
        )}
      </Header>

      <ProfileStyled>
        {tabs.map(
          (item, index) =>
            params.tab === item.key &&
            item.visible && (
              <item.view
                key={index}
                responsive={responsive}
                history={history}
                type={params.type}
                user={user}
              />
            ),
        )}
      </ProfileStyled>
    </>
  );
}
