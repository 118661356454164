import React, { useState, useEffect, useCallback } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import Modal from 'components/Forms/FormModal/Modal';
import { reduceBy } from 'shared/utils/Helpers';
import UserForm from 'containers/NextBRF/Forms/UserForm';
// validate
import validate from 'components/Forms/Fields/validate';
// service
import { getRegionalsWithCenters, saveUser } from '../service';
import t from 'components/i18n';


const validateSchema = props =>
  yup.object().shape({
    name: yup.string().required(t('global.status.required-field')),
    mail: yup.string().required(t('global.status.required-field')),
    enrollment: yup.string().required(t('global.status.required-field')),
    creationTypes: yup.array().required(t('global.status.required-field')),
    regionals: yup.array().required(t('global.status.required-field')),
    centerList: yup.array().required(t('global.status.required-field')),
    userCorporateProfileId: yup
      .string()
      .required(t('global.status.required-field')),
  });

export default function EditUser(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [regionals, setRegionals] = useState([]);

  const { isLoading, selectedUser, isValidating } = props;

  const initialValues = {
    name: '',
    mail: '',
    userCorporateProfile: '',
    regionals: '',
    creationTypes: '',
    active: true,
    centerList: '',
  };

  const formData = useCallback(
    values => {
      const unities = values.centerList
        ? values.centerList.map(item => item.id)
        : regionals
          .map(item =>
            item.centerList.map(center => center.id).flat(),
          )
          .flat();

      return {
        id: values.id,
        active: values.active,
        name: values.name,
        mail: values.mail,
        enrollment: values.enrollment.toString(),
        userCorporateProfileId: Number(values.userCorporateProfileId),
        centerIds: unities,
      };
    },
    [regionals],
  );

  useEffect(() => {
    async function getRegionals() {
      try {
        const response = await getRegionalsWithCenters();
        setRegionals(
          response.data.map(item => ({
            ...item,
            animalTypes: reduceBy(
              item.centerList.map(center => ({
                animalType: center.animalType,
              })),
              'animalType',
            ).map(animal => animal.animalType),
          })),
        );
      } catch (error) {
        console.error(error);
      }
    }

    getRegionals();
  }, []);

  const submitForm = useCallback(
    (values, { setSubmitting }) => {
      formData(values);

      async function sendData(val) {
        setSubmitting(true);
        try {
          const response = await saveUser(val);
          if (!response.success) {
            throw Error(response.message);
          }
          setSuccess(true);
        } catch (error) {
          setErrorMessage(error.message);
        }
        setSubmitting(false);
      }
      sendData(formData(values));
    },
    [formData],
  );

  return (
    <>
      {regionals.length > 0 && (
        <Formik
          initialValues={initialValues}
          validate={validate(validateSchema)}
          onSubmit={submitForm}
          render={formikProps => (
            <UserForm
              {...formikProps}
              isValidating={isValidating}
              message={errorMessage}
              isLoading={isLoading}
              selected={selectedUser}
              success={success}
              profileList={props.profileList}
              regionals={regionals}
              {...props}
            />
          )}
        />
      )}

      {/* On submit modal */}
      {(success || errorMessage) &&
        <Modal
          noButtons
          opened={success || errorMessage}
          warning={errorMessage}
          onCloseModal={() => {
            setSuccess(false);
            setErrorMessage('');
            props.setSelectedKey('user-list');
          }}
        >
          <div className="modal-title">
            <span
              className={`icon icon-${
                success ? 'check' : 'exclamation-triangle'
              }`}
            />
            <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
          </div>

          <p>
            {success
              ? t('next.userManagement.user.addUser.success.message')
              : t('next.userManagement.user.addUser.error.message')}
          </p>
        </Modal>
      }
    </>
  );
}
