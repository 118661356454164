// @flow
import { createActions } from 'redux-actions';

import * as ActionTypes from './types';

export { goBack, go, push, replace } from 'shared/history';

export const { switchMenu } = createActions({
  [ActionTypes.SWITCH_MENU]: (menuIsOpen: boolean) => ({
    menuIsOpen,
  }),
});
