import { API_SUPERVISOR_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function geVendors() {
  const response = await request(
    `${API_SUPERVISOR_URL}/Producer/GetVendors`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getFeedSchedule(
  flockId = '',
  vendor = '',
  status = '',
  type = '',
  id = '',
) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Feed/GetFeedSchedule?FlockId=${flockId ||
      id}&vendor=${vendor}&status=${status}&type=${type}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getSchedulePlacement(
  flockId = '',
  vendorCode = '',
  houseCode = '',
  status = '',
  id = '',
) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Placement/GetSchedulePlacement?houseCode=${houseCode}&vendorCode=${vendorCode}&status=${status}&flockId=${flockId ||
      id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}

export async function getScheduleSlaughter(
  flockId = '',
  vendorCode = '',
  houseCode = '',
  status = '',
  id = '',
) {
  const response = await request(
    `${API_SUPERVISOR_URL}/Slaughter/GetScheduleSlaughter?houseCode=${houseCode}&vendor=${vendorCode}&status=${status}&flockId=${flockId ||
      id}`,
    {
      method: 'GET',
    },
  );
  return response.data;
}
