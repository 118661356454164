// @flow

import React, { useState, useEffect } from 'react';
import t from 'components/i18n';

// components
import Box from 'components/Container';
import { OpenedResumeCard } from 'components/ResumeCard';
import SummaryItem from 'containers/AgroBRF/FlockList/Flock/FlockHistory/SummaryItem';
import DeceasedSummaryItem from 'containers/AgroBRF/FlockList/Flock/FlockHistory/DeceasedSummaryItem';
import MedicationSummaryItem from 'containers/AgroBRF/FlockList/Flock/FlockHistory/MedicationSummaryItem';
import NoContentTab from 'components/NoContentTab';
import uid from 'uuid';
import * as routes from 'config/routes';
import logger from 'shared/utils/logger';
import { reduceBy } from 'shared/utils/Helpers';
import LoadingHolder from 'components/LoadingHolder';
import {
  getWeights,
  getSwineMortalityList,
  getLatestResume,
  getWeightsResume,
  getFlockResumeCard,
  getFlockInvoice,
} from 'containers/AgroBRF/FlockList/service';
import { getBoxType } from './Flock.utils';
import { Container, Separator } from './Flock.styled';
import ColoredCard from 'components/ColoredCard';
import moment from 'moment';

export default function SummaryScreen(props) {
  const { animalType, history, user } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [weights, setWeights] = useState([]);
  const [mortalities, setMortalities] = useState([]);
  const [latestMedication, setLatestMedication] = useState(null);
  const [cardData, setCardData] = useState([]);
  const [selectedKey, setSelectedKey] = useState('resume');
  const [invoice, setInvoice] = useState(null);

  const flockID = props.match.params.id;

  const isTurkish = user.countryCodes.includes('TR');

  let flockDetailsTop;

  /*
   * Get weights content
   */
  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      if (animalType === 'swine') {
        try {
          const mortality = await getSwineMortalityList(flockID);
          const medication = await getLatestResume(flockID);
          const resumeCard = await getFlockResumeCard(flockID);

          setMortalities(
            reduceBy(mortality.swineMortality, 'houseId'),
          );
          setCardData(resumeCard);
          setLatestMedication(medication);
        } catch (e) {
          logger('Error on getSwineMortality', e);
        }
      } else {
        try {
          const response = props.oldWeights
            ? await getWeights(flockID)
            : await getWeightsResume(flockID);
          const resumeCard = await getFlockResumeCard(flockID);

          setWeights(reduceBy(response, 'house'));
          setCardData(resumeCard);
        } catch (e) {
          logger('Error on getWeights', e);
        }
      }

      setIsLoading(false);
    }

    fetch();
  }, [animalType, flockID, props.oldWeights]);

  useEffect(() => {
    async function fetch() {
      const invoice = await getFlockInvoice(props.flock);
      setInvoice(invoice);
    }
    
    fetch();
  }, [cardData, props.flock]);

  const boxProps = {
    slim: true,
    [getBoxType(selectedKey)]: true,
  };

  const data = {
    swine: mortalities,
    broiler: weights,
    turkey: weights,
  };

  const components = {
    swine: DeceasedSummaryItem,
    broiler: SummaryItem,
    turkey: SummaryItem,
  };

  const getTitle = {
    swine: t('flocks.flocksDetail.sectionLastestRecords.title'),
    broiler: t('flocks.flocksDetail.sectionLastestRecords.title'),
    turkey: t('flocks.flocksDetail.sectionLastestRecords.title'),
  };

  const Item = components[animalType];

  function getFlockDetails() {
    const top = (
      <header className="flock-details-header">
        <h2 className="flock-details-heading">
          {getTitle[animalType]}
        </h2>
      </header>
    );

    const content = (
      <div className="flock-details-content">
        {data &&
          data[animalType] &&
          data[animalType].length &&
          data[animalType].map(feed => (
            <Item
              key={`feed-${uid()}`}
              feed={feed}
              goTo={e => setSelectedKey(e)}
              exclude={() => {}}
              houses={props.houses}
              hideOptions
            />
          ))}

        {animalType === 'swine' && latestMedication && (
          <MedicationSummaryItem
            key={`feed-${uid()}`}
            feed={latestMedication}
            goTo={e => setSelectedKey(e)}
            exclude={() => {}}
            hideOptions
          />
        )}
      </div>
    );

    const noContent = isLoading ? (
      <LoadingHolder
        isLoading={isLoading}
        message={t(
          'flocks.flocksDetail.sectionLastestRecords.loading',
        )}
      />
    ) : (
      <NoContentTab icon="icon-scale">
        <p>
          {t('flocks.empty.title')} <br />{' '}
          <span className="lighter">
            {t(
              'flocks.flocksDetail.sectionLastestRecords.empty.subtitle',
            )}
          </span>
        </p>
      </NoContentTab>
    );

    return (
      <div className="flock-details" style={{ maxWidth: '676px' }}>
        <Box {...boxProps}>
          <div className="flock-details-container">
            {top}
            <Separator />
            {flockDetailsTop}
            {data[animalType].length ? content : noContent}
          </div>
        </Box>
      </div>
    );
  }

  return (
    <>
      <LoadingHolder isLoading={isLoading} />

      {!isLoading && (
        <>
          <Container className="flock-container column flock-container__summary-screen">
            {cardData && (
              <div className="secondary-wrapper">
                <OpenedResumeCard
                  color="colorSuccess"
                  title={
                    cardData.placementRealDt &&
                    cardData.placementRealDt.key
                      ? t(
                          'flocks.flocksDetail.sectionGeneralData.card.totalFeedDelivered.label',
                        )
                      : t(
                          'flocks.flocksDetail.sectionGeneralFlockData.card.feedForecast.label',
                        )
                  }
                  highlight={
                    cardData.qtFeedReal || cardData.qtFeedPrev
                  }
                  content={[
                    {
                      label: t(
                        'flocks.flocksDetail.sectionFlockStatement.placementDate.label',
                      ),
                      info: cardData.placementRealDt,
                    },
                    {
                      label: t(
                        'flocks.flocksDetail.sectionFlockStatement.quantityHoused.label',
                      ),
                      info: t(
                        cardData.placementRealQt &&
                          cardData.placementRealQt.key,
                        {
                          quantity:
                            cardData.placementRealQt &&
                            cardData.placementRealQt.value,
                        },
                      ),
                    },
                    {
                      label: t(
                        'flocks.flocksDetail.sectionGeneralFlockData.placementForeseen.label',
                      ),
                      info: cardData.placementPrevDt,
                    },
                    {
                      label: t(
                        'flocks.flocksDetail.sectionGeneralFlockData.quantityForeseen.label',
                      ),
                      info: t(
                        cardData.placementPrevQt &&
                          cardData.placementPrevQt.key,
                        {
                          quantity:
                            cardData.placementPrevQt &&
                            cardData.placementPrevQt.value,
                        },
                      ),
                    },
                    {
                      label: t(
                        'flocks.flocksDetail.sectionGeneralData.flockAge.label',
                      ),
                      info:
                        t(
                          cardData.age && cardData.age.key,
                          {
                            value: cardData.age && cardData.age.value,
                            count: cardData.age && Number(cardData.age.value),
                            postProcess: 'interval',
                          }
                        ),
                    },
                    {
                      label: t(
                        'flocks.flocksDetail.sectionGeneralData.mortalityRate.label',
                      ),
                      info: cardData.mortalityPercentage,
                    },
                  ]}
                />

                {isTurkish && invoice && invoice.status === "Pendente" && (
                  <ColoredCard
                    icon="icon icon-file"
                    title={t("alertCard.title.pendingInvoice")}
                    text={t("alertCard.description.pendingInivoice", { value: props.flock })}
                    buttonLabel={t("alertCard.button.sendInvoice")}
                    buttonAction={() =>
                      history.push(`${routes.INVOICE}/${flockID}`)
                    }
                  />
                )}

                {isTurkish && invoice && invoice.status === "Processando" && (
                  <ColoredCard
                    icon="icon icon-file"
                    bgColor="colorInfo"
                    title={t("alertCard.title.invoiceSent")}
                    text={
                      t(
                        'alertCard.description.invoiceSent',
                        {
                          value: props.flock,
                          hour: moment(invoice.updatedDate).format('DD ̸̸  MM ̸̸  YYYY'),
                          date: moment(invoice.updatedDate).format('HH:mm'),
                        }
                      )
                    } 
                    buttonLabel={t("alertCard.button.invoiceSent")}
                    buttonAction={() =>
                      history.push(`${routes.INVOICE}/${flockID}`)
                    }
                  />
                )}
              </div>
            )}

            {getFlockDetails()}
          </Container>
        </>
      )}
    </>
  );
}
