import * as variables from './variables';
import zendeskGardenOverrides from './zendesk-garden';

const themeNext = {
  breakpoints: {
    xs: 0,
    ix: 400,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1920,
  },

  fontSizes: [12, 14, 16, 18, 22, 26, 32, 48],

  palette: {
    ...variables.colorsNext,
  },

  button: {
    borderRadius: {
      xs: 4,
      lg: 28,
      xl: 32,
    },
    padding: {
      lg: [12, 28],
      xl: [14, 32],
    },
  },
};

export default {
  ...zendeskGardenOverrides,
  ...themeNext,
};
