import React from 'react';
import { Message, Label, Field } from '@zendeskgarden/react-forms';

import {
  getValidationMessage,
  getValidation,
} from 'shared/utils/Helpers';
import { TextArea } from '../Forms.styled';

export default function TextAreaField(props) {
  return (
    <Field>
      <Label>{props.label}</Label>
      <TextArea
        name={props.field}
        placeholder={props.placeholder}
        value={props.values[props.field]}
        onBlur={props.onBlur}
        onChange={props.onChange}
        setState={props.setState}
        disabled={props.disabled}
      >
        {props.children}
      </TextArea>
      {props.errors && props.errors.length > 0 && (
        <Message
          validation={getValidation(
            props.values[props.field],
            props.errors,
            props.field,
            !props.touched[props.field],
          )}
        >
          {getValidationMessage(
            props.field,
            props.errors,
            !props.touched[props.field],
          )}
        </Message>
      )}
    </Field>
  );
}
