import React from 'react';
import uid from 'uuid';

import t from 'components/i18n';

export default function Term({ data }) {
  const formatFooter = () => {
    const footer = data.footer;

    if (!footer) {
      return '';
    }

    const [locale, date] = footer.split(/, /gi);
    const [day, month, year] = date.split(/ de /gi);

    return t('home.signup.termsOfUseModal.date', {
      locale,
      day,
      month,
      year,
    });
  };

  return (
    <div>
      <h2>{data.title}</h2>
      {data.sections.map(section => (
        <section key={`section-${uid()}`}>
          <h3>{section.title}</h3>
          <div className="description">
            {section.description.map(desc => (
              <p key={`description-${uid()}`}>{desc.paragraph}</p>
            ))}
          </div>
        </section>
      ))}
      <footer>{formatFooter()}</footer>
    </div>
  );
}
