import React, { useContext, memo, useMemo } from 'react';
import {
  Table,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell,
} from '@zendeskgarden/react-tables';
import { Field, Checkbox, Label } from '@zendeskgarden/react-forms';

import styled, { ThemeContext } from 'styled-components';
import SideboxFilter from 'components/SideboxFilter/SideboxFilter';
import Loader from 'components/Loader';
import NoContentTab from 'components/NoContentTab';
import DownloadButtonForTable from 'components/DownloadButtonForTable';
import { WhiteBoxInsideHeader } from 'components/HeaderBox';
import { TableStyled, MobileRow } from './Table.styled';
import t from 'components/i18n';
import { differenceBy, isEqual } from 'lodash';

const CustomRow = styled(Row)`
  cursor: ${p => (p.isClickable ? 'pointer' : 'initial')};
`;

function TableComponent(props) {
  const {
    rowSize,
    header,
    mobileHeader,
    data = [],
    title,
    titleColor,
    filter,
    loading,
    isMobile,
    downloadLabel,
    downloadLink,
    showCheckbox,
    setData,
    filterDisabled,
    state = {}
  } = props;

  const themeContext = useContext(ThemeContext);

  const noContent = useMemo(
    () => (
      <NoContentTab icon="icon-search">
        <p>
          {t('global.no-content')}
        </p>
      </NoContentTab>
    ),
    [],
  );

  const renderTableFilters = useMemo(() => {
    if (filter && filter.filters !== undefined) {
      return (
        <WhiteBoxInsideHeader style={{ margin: 0 }}>
          {!!downloadLink && (
            <DownloadButtonForTable
              label={downloadLabel}
              link={downloadLink}
            />
          )}

          <SideboxFilter
            activeFilters={filter.activeFilters}
            dateFilter={filter.dateFilter}
            setDateFilter={filter.setDateFilter} // activeFilters
            setActiveFilters={filter.setActiveFilters} // setActiveFilters
            filters={filter.filters}
            labelApply={t("global.button.apply")}
            disabled={filterDisabled}
          />
        </WhiteBoxInsideHeader>
      );
    }

    return null;
  }, [downloadLabel, downloadLink, filter]);

  const renderMobileTableHeader = useMemo(
    () => (
      <HeaderCell width="100%" className="left">
        {t(mobileHeader)}
      </HeaderCell>
    ),
    [mobileHeader],
  );

  const isSelectAllChecked = (rows) => (rows || []).filter(row => row.status !== 'previsto').every(row => row.selected);
  
  const isSelectAllIndeterminate = (rows) => {
    const numSelectedRows = (rows || []).reduce((accumulator, row) => {
      if (row.selected) {
        return accumulator + 1;
      }
  
      return accumulator;
    }, 0);
  
    return numSelectedRows > 0 && numSelectedRows < rows.length;
  };

  const renderSelectableHeader = () => {
    return (
      <HeaderCell isMinimum>
        <Field>
          <Checkbox
            indeterminate={isSelectAllIndeterminate(state.itens)}
            checked={isSelectAllChecked(state.itens)}
            onChange={e => {
              if (e.target.checked) {
                const updatedRows =
                  state.itens
                    .filter(row => row.status !== 'previsto')
                    .map(row => ({ ...row, selected: true }));

                const itens = [...differenceBy(state.itens, updatedRows, item => item.id), ...updatedRows];

                setData({ ...state, itens });
              } else {
                const updatedRows = 
                  state.itens
                    .filter(row => row.status !== 'previsto')
                    .map(row => ({ ...row, selected: false }));
                
                const itens = [...differenceBy(state.itens, updatedRows, item => item.id), ...updatedRows];

                setData({ ...state, itens });
              }
            }}
          >
            <Label hidden>Select all tickets</Label>
          </Checkbox>
        </Field>
      </HeaderCell>
    )
  }

  const renderDesktopTableHeader = useMemo(
    () => {
      const headers = header.map((item, index) => (
        item.hidden 
        ? null
        : (
          <HeaderCell
            key={index}
            width={`${item.width}%`}
            className={item.alignLeft ? 'left' : ''}
            >
            {t(item.title)}
          </HeaderCell>
          )
      ));

      return [
        showCheckbox ? renderSelectableHeader() : null,
        ...headers
      ]
    },
    [header],
  );

  const renderMobileTableBody = useMemo(() => {
    if (data && data.length > 0) {
      return data.map((row, i) => (
        <MobileRow key={i}>
          {/* Mobile */}
          {Object.keys(data[0])
            .filter(key => !isNaN(key))
            .map((item, idx) => (
              <CustomRow
                key={idx}
                isStriped={false}
                onClick={() => row.onClick != null && row.onClick()}
                isClickable={row.onClick != null}
              >
                <Cell width="100%" className="justify">
                  {idx > 0 && <span>{header[idx].title}:</span>}
                  <span>{row[item]}</span>
                </Cell>
              </CustomRow>
            ))}
          {/* \ Mobile */}
        </MobileRow>
      ));
    }

    return noContent;
  }, [data, header, noContent]);

  const renderDesktopTableBody = useMemo(() => {
    if (data && data.length > 0) {
      return data.map((row, i) => (
        <CustomRow
          key={i}
          isStriped={false}
          onClick={() => row.onClick != null && row.onClick()}
          isClickable={row.onClick != null}
        >
          {Object.keys(data[0])
            .filter(key => !isNaN(key))
            .map((item, idx) => (
              <Cell
                key={idx}
                width={`${header[idx].width}%`}
                className={header[idx].alignLeft ? 'justify' : ''}
              >
                {row[item]}
              </Cell>
            ))}
          {/* \ Desktop / Tablet */}
        </CustomRow>
      ));
    }

    return noContent;
  }, [data, header, noContent]);

  const loadingRender = useMemo(
    () => (
      <div
        style={{
          display: 'flex',
          padding: '30px 0 0 0',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Loader
          color={themeContext.styles.palette.colorPrimary}
          size="16"
        />
        <p>{t('global.loading')}</p>
      </div>
    ),
    [themeContext],
  );

  return (
    <TableStyled color={titleColor}>
      {title && (
        <header className="details-header">
          <span className="container-detail" />
          <h3 className="details-heading">{title}</h3>
        </header>
      )}

      <Table size={rowSize === 'default' ? 'small' : 'large'}>
        {renderTableFilters}

        <Head>
          <HeaderRow>
            {/* Mobile header */}
            {!loading && isMobile && renderMobileTableHeader}
            {/* \ Mobile header */}

            {/* Desktop/tablet header */}
            {!loading && !isMobile && renderDesktopTableHeader}
            {/* \ Desktop/tablet header */}
          </HeaderRow>
        </Head>
        <Body>
          {/* is loading */}
          {loading && loadingRender}
          {/* \ is loading */}

          {/* Desktop body */}
          {!loading && !isMobile && renderDesktopTableBody}
          {/* \Desktop body */}

          {/* Mobile body */}
          {!loading && isMobile && renderMobileTableBody}
          {/* \Mobile body */}
        </Body>
      </Table>
    </TableStyled>
  );
}

export default memo(TableComponent);
