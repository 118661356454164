import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import * as ActionTypes from './types';

export const initialState = {
  details: null,
};

export default {
  checklist: handleActions(
    {
      [ActionTypes.CHECKLIST_SHOW_DETAILS]: (state, action) =>
        immutable(state, {
          details: { $set: action.payload },
        }),
    },
    initialState,
  ),
};
