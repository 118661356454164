// @flow

import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import t from 'components/i18n';
// Contexts
import { WeighingContext } from 'shared/contexts';

// components
import Box from 'components/Container';
import FeedList from 'components/FeedList';
import NoContentTab from 'components/NoContentTab';
import GroupedDetails from 'components/GroupedDetails';
import RoundContainer from 'components/RoundContainer';
import FlockHistory from 'containers/AgroBRF/FlockList/Flock/FlockHistory/FlockHistory';
import HistoryTop from 'containers/AgroBRF/FlockList/Flock/FlockHistory/HistoryTop';
import FlockMenu from 'containers/AgroBRF/FlockList/Flock/FlockMenu';
import Modal from 'components/Forms/FormModal/Modal';
import AddWeighing from 'containers/AgroBRF/Forms/AddWeighing/AddWeighing';
import NotificationBox from 'components/NotificationBox';
import { colorInfo } from 'components/GlobalStyles/variables';
import EditWeighing from 'containers/AgroBRF/Forms/AddWeighing/EditWeighing';
import { DropdownFilter, ModalFilter } from 'components/Filter';
import Icon from 'components/Icon';
import floatMask from 'components/MaskedInput/floatMask';
import { Close } from 'components/MultiSelect/Items.styled';
import {
  dateFilterList,
  getValuesOf,
  filterByDate,
  simpleFilter,
  getDateLabel,
} from 'components/Filter/FiltersUtils';
import {
  getBoxType,
  getFeedIcon,
  getFlockIcon,
  getHeading,
  sumOfItems,
} from './Flock.utils';
import {
  Container,
  ListItem,
  Separator,
  FlockDetailsMobileWrapper,
  RoundedItem,
} from './Flock.styled';
import { QuickActionScroll } from './QuickAccessItems.styled';
import QuickAccessItems from './QuickAccessItems';
import { getFeedCutDates } from '../service';

export default function Activities(props) {
  const {
 match, events, data, responsive, history 
} = props;

  const [selectedKey, setSelectedKey] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [houseFilter, setHouseFilter] = useState(null);
  const [feedFilter, setFeedFilter] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [hideFilters, setHideFilters] = useState(false);
  const [historyContent, setHistoryContent] = useState([]);
  const [notificationCount, setNotificationCount] = useState(false);
  const [groupedResults, setGroupedResults] = useState(null);
  const [displayGrouped, setDisplayGrouped] = useState(false);
  const [feedCutDates, setFeedCutDates] = useState([]);

  const weighingContext = useContext(WeighingContext);
  const { submitResponse } = weighingContext;
  const {
    setSubmitResponse,
    setSelectedWeight,
  } = weighingContext.actions;
  const { isGtMobile, isMobile, isTablet } = responsive;

  const flockTitle = t(data.flockTitle && data.flockTitle.key, {
    flockNumber: data.flockTitle && data.flockTitle.value,
  });

  let flockDetailsContent = [];
  let flockStatusList = [];
  let flockFeedList = [];
  let flockHouseList = [];
  let flockDetailsTop;

  const flockSelected = useMemo(() => data[selectedKey], [
    data,
    selectedKey,
  ]);

  const flockHistory = useMemo(
    () => flockSelected && flockSelected.history,
    [flockSelected],
  );

  const checkRead = useCallback(
    arr =>
      arr &&
      arr.length > 0 &&
      arr
        .flat()
        .filter(item => item.status === 'CONFIRMADO' && !item.isRead),
    [],
  );

  /**
   * @deprecated Pendente: receber valores já calculados do back
   * get total quantity and average weight by house
   */
  const getGroupedListByHouse = useCallback(
    arr =>
      (arr || []).map((list, houseIndex) => {

        const accomplished = list.filter(
          item => item.status === 'REALIZADO',
        );

        const totalQuantity = accomplished
          .reduce(
            (acum, curr) => (acum += parseFloat(curr.quantity.value.toString().replace('.', ''))),
            0,
          );

        const reduced = accomplished.reduce(
          (acum, curr) => (acum += +curr.infoLeft.value),
          0,
        ) * 1000;

        return {
          key: houseIndex,
          house: t('activities.weight.house', {
            value: list[0].house,
          }),
          list: [list],
          totalQuantity,
          flockAverageWeight: (reduced / totalQuantity)
            .toFixed(3)
            .replace('.', ','),
        };
      }),
    [],
  );

  const fetchFeedCutDates = async () => {
    getFeedCutDates().then(fetchedFeedCutDates =>
      setFeedCutDates(fetchedFeedCutDates),
    );
  };

  useEffect(() => {
    fetchFeedCutDates();
  }, []);

  /*
   * set data based on url params
   */

  useEffect(() => {
    if (match.params.activity) {
      if (
        match.params.activity === 'closure' ||
        match.params.activity === 'payment' ||
        match.params.activity === 'partial'
      ) {
        setSelectedKey('next');
      } else {
        setSelectedKey(match.params.activity);
      }
    } else {
      setSelectedKey('next');
    }
  }, [match.params.activity]);

  useEffect(() => {
    /*
     * clear filters on menu change
     */
    setDateFilter(null);
    setStatusFilter(null);
    setHouseFilter(null);
    setFeedFilter(null);
    setIsFiltered(false);
    setOpenCalendar(false);

    /*
     * set history content
     */
    if (selectedKey) {
      if (selectedKey === 'next') {
        setHideFilters(true);
        setHistoryContent(events);
      } else {
        if (hideFilters) setHideFilters(false);
        setHistoryContent(flockHistory);
        setNotificationCount(false);

        /*
         * group slaughter results by house
         */
        if (selectedKey === 'slaughter') {
          const grouped = getGroupedListByHouse(flockHistory);
          const confirmedList = checkRead(flockHistory);

          setNotificationCount(confirmedList && confirmedList.length);

          setGroupedResults(grouped);

          setDisplayGrouped(true);
        } else {
          setDisplayGrouped(false);
        }
      }
    }
  }, [
    flockSelected,
    getGroupedListByHouse,
    hideFilters,
    events,
    selectedKey,
    flockHistory,
    checkRead,
  ]);

  /*
   * apply filters
   */
  useEffect(() => {
    let filteredByDate = [];
    let filteredByStatus = [];
    let filteredByFeed = [];
    let filteredByHouse = [];

    const filters = [
      dateFilter,
      houseFilter,
      statusFilter,
      feedFilter,
    ];

    if (dateFilter) {
      filteredByDate = filterByDate(
        flockSelected.history,
        'date',
        dateFilter.startDate,
        dateFilter.endDate,
      );
    }

    if (statusFilter) {
      filteredByStatus = simpleFilter(
        flockSelected.history,
        'status',
        statusFilter,
      );
    }

    if (houseFilter) {
      filteredByHouse = simpleFilter(
        flockSelected.history,
        'house',
        houseFilter,
      );
    }

    if (feedFilter) {
      filteredByFeed = simpleFilter(
        flockSelected.history,
        'infoLeft',
        feedFilter,
      );
    }

    if (filters.filter(e => e !== null).length) {
      setIsFiltered(true);
      setDisplayGrouped(false);

      const list = flockSelected.history;

      const ids = list
        .flat()
        .filter(
          el =>
            (!filteredByDate.length ||
              filteredByDate.flat().find(x => x.id === el.id)) &&
            (!filteredByStatus.length ||
              filteredByStatus.flat().find(x => x.id === el.id)) &&
            (!filteredByFeed.length ||
              filteredByFeed.flat().find(x => x.id === el.id)) &&
            (!filteredByHouse.length ||
              filteredByHouse.flat().find(x => x.id === el.id)),
        )
        .map(x => x.id);

      setHistoryContent(
        flockSelected.history
          .map(x => {
            const newArr = x.filter(y => ids.indexOf(y.id) >= 0);
            if (!newArr.length) return null;
            return newArr;
          })
          .filter(x => x),
      );

      if (dateFilter && !filteredByDate.length) {
        setHistoryContent([]);
      }
    } else {
      setHistoryContent(flockHistory);

      // group slaughter results again
      if (selectedKey === 'slaughter') {
        setDisplayGrouped(true);
      }
    }
  }, [
    dateFilter,
    houseFilter,
    feedFilter,
    statusFilter,
    flockSelected,
    selectedKey,
    flockHistory,
  ]);

  /*
   * clear filters
   */
  useEffect(() => {
    if (!isFiltered) {
      setDateFilter(null);
      setStatusFilter(null);
      setHouseFilter(null);
      setFeedFilter(null);
      setHistoryContent(flockHistory);
    }
  }, [flockHistory, flockSelected, isFiltered]);

  const mapContent = useCallback(
    (content, style) =>
      content.map((feed, index) => (
        <FlockHistory
          key={index}
          feed={feed}
          flockType={data.flockType}
          selectedKey={selectedKey}
          style={style}
          routerHistory={history}
          setNotificationCount={setNotificationCount}
          notificationCount={notificationCount}
        />
      )),
    [data, history, notificationCount, selectedKey],
  );

  const mapGroupedContent = useCallback(content =>
    content.map((item, index) => {
      const accomplishedFound =
        item.list
          .flat()
          .findIndex(listItem => listItem.status === 'REALIZADO') >
        -1;

      if (accomplishedFound) {
        return (
          <RoundContainer key={index} noMargin className='accomplished__wrapper'>
            <GroupedDetails
              title={item.house}
              values={[
                {
                  label: t('activities.card.total'),
                  value: `${item.totalQuantity} ${t('global.unit.head')}`,
                },
                {
                  label: t('activities.card.average'),
                  value: t('activities.card.weight', { value: item.flockAverageWeight }),
                },
              ]}
            />
            {mapContent(item.list, {
              border: '0',
              boxShadow: 'none',
            })}
          </RoundContainer>
        );
      }

      return mapContent(item.list);
    }),
  );

  const noContent = useCallback(
    selected => (
      <NoContentTab icon={getFeedIcon(data.flockType)}>
        <p>
          {t('flocks.empty.title')}
          <br />{' '}
          <span className="lighter">
            {selected && t('global.empty.list')}
          </span>
        </p>
      </NoContentTab>
    ),
    [data.flockType],
  );

  const boxProps = useMemo(
    () => ({ slim: true, [getBoxType(selectedKey)]: true }),
    [selectedKey],
  );

  const getTabletToggler = useCallback(
    type => (
      <button
        onClick={() => setSelectedKey(type)}
        type="button"
        className={`unstyled-button tablet-menu-item ${
          selectedKey === type ? 'active' : ''
        }`}
      >
        <Icon
          className="tablet-menu-icon"
          name={getFlockIcon(type)}
        />
        <span className="tablet-menu-label">{getHeading(type)}</span>
      </button>
    ),
    [selectedKey],
  );

  const getToggler = useCallback(
    type => {
      const menuItemProps = {
        className: 'menu-item',
      };

      if (isGtMobile) {
        menuItemProps.className = `menu-item ${
          selectedKey === type ? 'active' : ''
        }`;
      }

      return (
        <div {...menuItemProps}>
          {type !== 'slaughter' ? (
            <Icon className="menu-icon" name={getFlockIcon(type)} />
          ) : (
            <Icon
              className="menu-icon"
              name={getFeedIcon(data.flockType)}
            />
          )}

          <ListItem
            onClick={() => setSelectedKey(type)}
            role="button"
            className="animated fadeIn"
          >
            <div className="list-item-content">
              <div className="list-item-heading">
                {getHeading(type)}
              </div>
            </div>
            <Icon name="chevron icon-chevron-right" />
          </ListItem>
        </div>
      );
    },
    [data.flockType, isGtMobile, selectedKey],
  );

  const getFlockDetails = useCallback(() => {
    const top = (
      <header className="flock-details-header">
        <h2 className="flock-details-heading">
          {getHeading(selectedKey)}
        </h2>
      </header>
    );

    const quickAccessTop = (
      <header
        className="flock-details-header"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <h2 className="flock-details-heading">
          {t('dashboard.sectionQuickAccess.title')}
        </h2>
        <QuickActionScroll>
          <div className="action">
            <Icon
              className="quick-action quick-action-left"
              name="icon-chevron-left"
            />
          </div>
          <div className="action">
            <Icon
              className="quick-action quick-action-right"
              name="icon-chevron-right"
            />
          </div>
        </QuickActionScroll>
      </header>
    );

    function innerContent() {
      switch (selectedKey) {
        case 'confirmation':
          return (
            <AddWeighing
              houses={data && data.houseInformation}
              flockID={data.flockID}
              goTo={e => setSelectedKey(e)}
              sendToConfirmation
            />
          );

        case 'add':
          return (
            <AddWeighing
              houses={data.houseInformation}
              flockID={data.flockID}
              goTo={e => setSelectedKey(e)}
              sendToConfirmation={false}
            />
          );

        case 'edit':
          return (
            <EditWeighing
              houses={data.houseInformation}
              flockID={data.flockID}
              goTo={e => setSelectedKey(e)}
            />
          );

        default:
          return (
            <>
              {!hideFilters && historyContent && (
                <div className="filter-wrapper filter-wrapper__filter">
                  {!dateFilter ? (
                    <DropdownFilter
                      buttonLabel={t('global.button.filter.date')}
                      list={dateFilterList}
                      arrayItem="date"
                      keysToCompare={['startDate', 'endDate']}
                      onSelectItemAction={setDateFilter}
                      openCalendar={openCalendar}
                      setOpenCalendar={setOpenCalendar}
                      clearFilters={dateFilter === null}
                    />
                  ) : (
                    <RoundedItem className="activities__item">
                      <p>
                        {getDateLabel(dateFilter.startDate)} -{' '}
                        {getDateLabel(dateFilter.endDate)}
                      </p>
                      <Close
                        onClick={() => {
                          setDateFilter(null);
                        }}
                      >
                        <span className="icon icon-close-circle" />
                      </Close>
                    </RoundedItem>
                  )}

                  {statusFilter &&
                    [statusFilter].map(
                      (item, i) =>
                        item && (
                          <RoundedItem key={i} className="activities__item">
                            <p>{item}</p>
                            <Close
                              onClick={() => {
                                setStatusFilter(null);
                              }}
                            >
                              <span className="icon icon-close-circle" />
                            </Close>
                          </RoundedItem>
                        ),
                    )}

                  {feedFilter &&
                    [feedFilter].map(
                      (item, i) =>
                        item && (
                          <RoundedItem key={i} className="activities__item">
                            <p>{item}</p>
                            <Close
                              onClick={() => {
                                setFeedFilter(null);
                              }}
                            >
                              <span className="icon icon-close-circle" />
                            </Close>
                          </RoundedItem>
                        ),
                    )}

                  {houseFilter &&
                    [houseFilter].map(
                      (item, i) =>
                        item && (
                          <RoundedItem key={i} className="activities__item">
                            <p>
                              {t('activities.weight.house', {
                                value: item,
                              })}
                            </p>
                            <Close
                              onClick={() => {
                                setHouseFilter(null);
                              }}
                            >
                              <span className="icon icon-close-circle" />
                            </Close>
                          </RoundedItem>
                        ),
                    )}

                  <ModalFilter
                    dark
                    title={t('activities.filter.modal.title')}
                    buttonLabel={t('global.button.filter')}
                    list={[
                      {
                        label: 'status',
                        arrayList: flockStatusList,
                        action: setStatusFilter,
                        placeholder: t('activities.filter.modal.option.one'),
                      },
                      {
                        label: 'feed',
                        arrayList: flockFeedList,
                        action: setFeedFilter,
                        placeholder: t('activities.filter.modal.option.two'),
                      },
                      {
                        label: 'house',
                        arrayList: flockHouseList,
                        action: setHouseFilter,
                        placeholder: t('activities.filter.modal.option.three'),
                      },
                    ]}
                    clearFilters={() => setIsFiltered(false)}
                  />

                  <Separator />
                </div>
              )}

              {!!notificationCount && (
                <NotificationBox
                  className="notification notification--info"
                  bgColor={colorInfo}
                >
                  <p>
                    {t('agro.flock.activities.notification', { notificationCount })}
                  </p>
                </NotificationBox>
              )}
              <FeedList className="flock-details-content__content">
                {flockDetailsContent}
              </FeedList>
            </>
          );
      }
    }

    const content = (
      <div className="flock-details-content flock-details-content__inner-content">
        {innerContent()}
      </div>
    );

    const quickAccessContent = (
      <div className="flock-details-content flock-details__quick-access-items">
        <QuickAccessItems
          fetchFeedCutDates={fetchFeedCutDates}
          feedCutDates={feedCutDates}
          flockNumber={flockTitle.split(' ')[1]}
        />
      </div>
    );

    const details = (
      <div className="flock-details-container">
        {isMobile && (
          <>
            <FlockDetailsMobileWrapper className="mobile-wrapper">
              {content}
            </FlockDetailsMobileWrapper>
          </>
        )}

        {isGtMobile && (
          <>
            {top}
            <Separator />
            {selectedKey !== 'payment' && flockDetailsTop}
            {content}
          </>
        )}
      </div>
    );

    const quickAccess = (
      <div className="flock-details-container">
        {isMobile && (
          <>
            <FlockDetailsMobileWrapper className="mobile-wrapper">
              {quickAccessContent}
            </FlockDetailsMobileWrapper>
          </>
        )}

        {isGtMobile && (
          <>
            {quickAccessTop}
            <Separator />
            {selectedKey !== 'payment' && flockDetailsTop}
            {quickAccessContent}
          </>
        )}
      </div>
    );

    return (
      <div className="flock-details" style={{ maxWidth: '676px' }}>
        {isMobile && details}
        {selectedKey === 'next' &&
          isGtMobile &&
          !!feedCutDates.length && (
            <Box slim primary>
              {quickAccess}
            </Box>
          )}
        {isGtMobile && <Box {...boxProps}>{details}</Box>}
      </div>
    );
  }, [
    selectedKey,
    feedCutDates,
    flockTitle,
    isMobile,
    isGtMobile,
    flockDetailsTop,
    boxProps,
    data,
    hideFilters,
    historyContent,
    dateFilter,
    openCalendar,
    statusFilter,
    feedFilter,
    houseFilter,
    flockStatusList,
    flockFeedList,
    flockHouseList,
    notificationCount,
    flockDetailsContent,
  ]);

  if (historyContent && !historyContent.length) {
    flockDetailsTop = '';
    flockDetailsContent.push(noContent(selectedKey));
  } else {
    if (selectedKey === 'next') {
      flockDetailsTop = '';
    } else if (flockSelected) {
      flockDetailsTop = (
        <HistoryTop
          iconBox={getBoxType(selectedKey)}
          iconType="chart-pie"
          textContent={[
            {
              label: t('flocks.flocksActivities.totalForeseen.label'),
              content: t(
                flockSelected.totalEstimated &&
                  flockSelected.totalEstimated.key,
                {
                  value:
                    flockSelected.totalEstimated &&
                    flockSelected.totalEstimated.value,
                },
              ),
            },
            {
              label: t(
                'flocks.flocksActivities.totalAccomplished.label',
              ),
              content: t(
                flockSelected.totalAccomplished &&
                  flockSelected.totalAccomplished.key,
                {
                  value:
                    flockSelected.totalAccomplished &&
                    flockSelected.totalAccomplished.value,
                },
              ),
            },
          ]}
        />
      );
    } else {
      flockDetailsTop = null;
    }

    if (historyContent && historyContent.length) {
      if (displayGrouped) {
        flockDetailsContent = mapGroupedContent(groupedResults);
      } else {
        flockDetailsContent = mapContent(historyContent);
      }
    } else {
      flockDetailsTop = '';
      flockDetailsContent.push(noContent(selectedKey));
    }

    if (flockSelected) {
      if (selectedKey !== 'next') {
        flockStatusList = getValuesOf(flockHistory, 'status');
      }
      if (selectedKey === 'feed') {
        flockFeedList = getValuesOf(flockHistory, 'infoLeft');
      }
      if (selectedKey === 'slaughter') {
        flockHouseList = getValuesOf(flockHistory, 'house');
      }
      if (selectedKey === 'placement') {
        flockHouseList = getValuesOf(flockHistory, 'house');
      }
    }
  }

  return (
    <>
      <Container className="flock-container flock-container__activities">
        <FlockMenu
          flockId={match.params.id}
          getHeading={getHeading}
          getTabletToggler={getTabletToggler}
          getToggler={getToggler}
          isTablet={isTablet}
          isMobile={isMobile}
          isGtMobile={isGtMobile}
          selectedKey={selectedKey}
          getFlockDetails={getFlockDetails}
          type={data.flockStatus}
          data={data}
        />

        {isGtMobile && getFlockDetails()}

        <Modal
          opened={submitResponse !== null}
          confirm={() => {
            setSelectedKey('add');
            setSubmitResponse(null);
          }}
          deny={() => setSubmitResponse(null)}
          onCloseModal={() => {
            setSubmitResponse(null);
            setSelectedWeight({});
          }}
        >
          <span className="icon icon-check" />
          <h4>{t('agro.flock.activities.add.success.title')}</h4>
          {/* <p>{submitResponse}</p> */}
          <p>{t('agro.flock.activities.add.success.subtitle')}</p>
        </Modal>
      </Container>
    </>
  );
}
