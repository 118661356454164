import React, { useState, useEffect, useMemo } from 'react';
import { INDICATORS } from 'config/routes';
import Top from 'components/Top';
import { Header, TabHeaderHolder } from 'components/Tabs/Tabs.styled';
import { getAnimalIcon } from 'shared/utils/Helpers';
import IndicatorsList from './IndicatorsList';
import IndicatorDetails from './IndicatorDetalis';
import { IndcatorsStyled } from './Indicators.styled';
import t from 'components/i18n';

export default function Indicators({
  history,
  match: { params },
  user,
}) {
  const [selected, setSelected] = useState(null);
  const [mainColor, setMainColor] = useState(null);

  const isSwine = user.animalType === 'swine';

  useEffect(() => {
    setMainColor(
      params.type === 'closed' ? 'colorGray' : 'colorInfo',
    );
  }, [params.type]);

  const openedMainList = useMemo(() => {
    return [
      {
        title: t('next.indicatorDetails.list.mortality.title'),
        subheader: t('next.indicatorDetails.list.mortality.subHeader'),
        key: 'mortality',
        icon: `${getAnimalIcon(user.animalType)}-alt`,
        link:
          'https://app.powerbi.com/reportEmbed?reportId=ce7931ad-8459-4c0b-b103-f1b39c954e55&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.list.weight.title'),
        subheader: t('next.indicatorDetails.list.weight.subHeader'),
        key: 'weight',
        icon: 'icon-scale',
        link:
          'https://app.powerbi.com/reportEmbed?reportId=8f5500a9-c7ad-4816-9883-c5b9dc9c9190&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.list.phase-feeding.title'),
        subheader: t('next.indicatorDetails.list.phase-feeding.subHeader'),
        key: 'phase-feeding',
        icon: getAnimalIcon(user.animalType),
        link:
          'https://app.powerbi.com/reportEmbed?reportId=ca4dd685-cc9c-4184-9c72-cacb822c7f77&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.list.fal.title'),
        subheader: t('next.indicatorDetails.list.fal.subHeader'),
        key: 'fal',
        icon: 'icon-tasks',
        link:
          'https://app.powerbi.com/reportEmbed?reportId=4e76d4f6-3ddb-44f5-8043-969fc6e171c0&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
    ];
  }, [user.animalType]);

  const openedSwineList = useMemo(() => {
    return [
      {
        title: t('next.indicatorDetails.open-swine.list.mortality.title'),
        subheader: t('next.indicatorDetails.open-swine.list.mortality.subHeader'),
        key: 'mortality',
        icon: `${getAnimalIcon(user.animalType)}-alt`,
        link:
          'https://app.powerbi.com/reportEmbed?reportId=2c256591-e93c-44f3-8c90-275d2876cf41&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
    ];
  }, [user.animalType]);

  const openedSecondaryList = useMemo(() => {
    const leftOver = {
      title: t('next.indicatorDetails.secondary-list.title'),
      icon: 'icon-truck',
      link: null,
    };

    const list = [
      {
        title: t('next.indicatorDetails.secondary-list.temperature'),
        key: 'temperature',
        icon: 'icon-temperature',
        link:
          'https://app.powerbi.com/reportEmbed?reportId=ab20506d-d888-4399-9949-d96d0e2643de&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.secondary-list.humidity'),
        key: 'humidity',
        icon: 'icon-umidity',
        link:
          'https://app.powerbi.com/reportEmbed?reportId=99af8774-2c0a-425f-976a-732ed3c35bb9&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.secondary-list.water'),
        key: 'water',
        icon: 'icon-water',
        link:
          'https://app.powerbi.com/reportEmbed?reportId=66847509-27d7-47b9-8c3c-6f4d79b6f9c7&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.secondary-list.origin'),
        key: 'origin',
        icon: 'icon-chart-analytics',
        link: null,
      },
    ];

    if (params.type === 'closed') {
      return [...list, leftOver];
    }

    return list;
  }, [params.type]);

  const closedMainList = useMemo(() => {
    return [
      {
        title: t('next.indicatorDetails.closed-list.mortality.title'),
        subheader: t('next.indicatorDetails.closed-list.mortality.subheader'),
        key: 'mortality',
        icon: `${getAnimalIcon(user.animalType)}-alt`,
        link:
          'https://app.powerbi.com/reportEmbed?reportId=68be9ec9-3189-47ba-b1b7-be2f6b23668a&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.closed-list.weight.title'),
        subheader: t('next.indicatorDetails.closed-list.weight.subheader'),
        key: 'weight',
        icon: 'icon-scale',
        link:
          'https://app.powerbi.com/reportEmbed?reportId=f65ae2d3-02d4-4f79-a9b9-14ae4b2d3ba5&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.closed-list.lack.title'),
        subheader: t('next.indicatorDetails.closed-list.lack.subheader'),
        key: 'lack',
        icon: 'icon-truck',
        link:
          'https://app.powerbi.com/reportEmbed?reportId=d0922919-edf5-44b1-a7b9-9ed507b3fab0&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.closed-list.fal.title'),
        subheader: t('next.indicatorDetails.closed-list.fal.subheader'),
        key: 'fal',
        icon: 'icon-tasks',
        link:
          'https://app.powerbi.com/reportEmbed?reportId=de2f0197-cc50-4e7d-9312-e27b48d05efd&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
    ];
  }, [user.animalType]);

  const closedSwineList = useMemo(() => {
    return [
      {
        title: t('next.indicatorDetails.closed-swine-list.mortality.title'),
        subheader: t('next.indicatorDetails.closed-swine-list.mortality.subheader'),
        key: 'mortality',
        icon: `${getAnimalIcon(user.animalType)}-alt`,
        link:
          'https://app.powerbi.com/reportEmbed?reportId=7698bb55-6b26-4bb1-b759-46632b94b2cd&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
    ];
  }, [user.animalType]);

  const closedSecondaryList = useMemo(() => {
    const list = [
      {
        title: t('next.indicatorDetails.closed-secondary-list.temperature.title'),
        key: 'temperature',
        icon: 'icon-temperature',
        link:
          'https://app.powerbi.com/reportEmbed?reportId=b1792f49-7630-470a-8b32-fcf04e90726c&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.closed-secondary-list.humidity.title'),
        key: 'humidity',
        icon: 'icon-umidity',
        link:
          'https://app.powerbi.com/reportEmbed?reportId=8df95888-cf21-4aa8-8992-ceffecfaac2e&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.closed-secondary-list.water.title'),
        key: 'water',
        icon: 'icon-water',
        link:
          'https://app.powerbi.com/reportEmbed?reportId=28b5016b-ed69-4a2d-a917-70137cb60444&autoAuth=true&ctid=27951710-ebb9-4efb-a4a5-48e460822d4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D',
      },
      {
        title: t('next.indicatorDetails.closed-secondary-list.origin.title'),
        key: 'origin',
        icon: 'icon-chart-analytics',
        link: null,
      },
    ];

    return list;
  }, []);

  const Tabs = useMemo(
    () => [
      { label: t('next.indicatorDetails.tab.open'), key: 'opened' },
      { label: t('next.indicatorDetails.tab.close'), key: 'closed' },
    ],
    [],
  );

  return (
    <>
      {!selected && (
        <>
          <Top>
            <div className="top-content">
              <h2 className="top-content-heading">{t('next.indicatorDetailsdashboard.title')}</h2>
            </div>
          </Top>

          <Header>
            {Tabs.map((item, index) => (
              <TabHeaderHolder
                key={item.key}
                onClick={() =>
                  history.push(`${INDICATORS}/${item.key}`)
                }
                className={params.type === item.key ? 'active' : ''}
              >
                <div className="label">{item.label}</div>
                <div className="bar" />
              </TabHeaderHolder>
            ))}
          </Header>

          <IndcatorsStyled className="animated fadeIn">
            {params.type === 'opened' && (
              <IndicatorsList
                title={t('next.indicatorDetails.list.opened.title')}
                main={isSwine ? openedSwineList : openedMainList}
                secondary={isSwine ? [] : openedSecondaryList}
                color={mainColor}
                setSelected={setSelected}
              />
            )}

            {params.type === 'closed' && (
              <IndicatorsList
                title={t('next.indicatorDetails.list.closed.title')}
                main={isSwine ? closedSwineList : closedMainList}
                secondary={isSwine ? [] : closedSecondaryList}
                color={mainColor}
                setSelected={setSelected}
              />
            )}
          </IndcatorsStyled>
        </>
      )}

      {selected && (
        <IndicatorDetails
          selected={selected}
          setSelected={setSelected}
          color={mainColor}
          history={history}
        />
      )}
    </>
  );
}
