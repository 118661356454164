// @flow

import React, { useState, useEffect, useContext } from 'react';
// Contexts
import { WeighingContext } from 'shared/contexts';

// components
import Box from 'components/Container';
import TemporaryFlockMenu from 'containers/AgroBRF/FlockList/Flock/TemporaryFlockMenu';
import SummaryItem from 'containers/AgroBRF/FlockList/Flock/FlockHistory/SummaryItem';
import Modal from 'components/Forms/FormModal/Modal';
import AddWeighing from 'containers/AgroBRF/Forms/AddWeighing/AddWeighing';
import EditWeighing from 'containers/AgroBRF/Forms/AddWeighing/EditWeighing';
import NoContentTab from 'components/NoContentTab';
import t from 'components/i18n';
import { scrollToTop } from 'shared/utils/Helpers';
import uid from 'uuid';
import logger from 'shared/utils/logger';
import Icon from 'components/Icon';
import LoadingHolder from 'components/LoadingHolder';
import { getWeights } from 'containers/AgroBRF/FlockList/service';
import {
  getBoxType,
  getFeedIcon,
  getFlockIcon,
  getHeading,
} from './Flock.utils';
import {
  Container,
  ListItem,
  Separator,
  FlockDetailsMobileWrapper,
} from './Flock.styled';
import type { TFlockProps } from './typings';

export default function TemporaryRecord(props: TFlockProps) {
  const [weights, setWeights] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedKey, setSelectedKey] = useState('summary');
  const weighingContext = useContext(WeighingContext);
  const { submitResponse, success } = weighingContext;
  const { data } = props;
  const {
    setSubmitResponse,
    setSelectedWeight,
  } = weighingContext.actions;
  const { isGtMobile, isMobile, isTablet } = props.responsive;

  let flockDetailsTop;

  async function fetchSummary(flock) {
    setIsLoading(true);
    try {
      const response = await getWeights(flock);
      setWeights(response);
      setIsLoading(false);
    } catch (e) {
      logger('Error on getSummary', e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedKey === 'summary') {
      fetchSummary(props.match.params.id);
    }

    if (selectedKey === 'add') {
      setSelectedWeight({});
    }

    scrollToTop(document.getElementById('main'));
  }, [props.match.params.id, selectedKey]);

  const boxProps = {
    slim: true,
    [getBoxType(selectedKey)]: true,
  };

  function getTabletToggler(type) {
    return (
      <button
        onClick={() => setSelectedKey(type)}
        type="button"
        className={`unstyled-button tablet-menu-item ${
          selectedKey === type ? 'active' : ''
        }`}
      >
        <Icon
          className="tablet-menu-icon"
          name={getFlockIcon(type)}
        />
        <span className="tablet-menu-label">{getHeading(type)}</span>
      </button>
    );
  }

  function getToggler(type) {
    const menuItemProps = {
      className: 'menu-item',
    };

    if (isGtMobile) {
      menuItemProps.className = `menu-item ${
        selectedKey === type ? 'active' : ''
      }`;
    }

    return (
      <div {...menuItemProps}>
        {type !== 'slaughter' ? (
          <Icon className="menu-icon" name={getFlockIcon(type)} />
        ) : (
          <Icon
            className="menu-icon"
            name={getFeedIcon(data.flockType)}
          />
        )}

        <ListItem
          onClick={() => {
            setSelectedKey(type);
            setSelectedWeight({});
          }}
          role="button"
          className="animated fadeIn"
        >
          <div className="list-item-content">
            <div className="list-item-heading">
              {getHeading(type)}
            </div>
          </div>
          <Icon name="chevron icon-chevron-right" />
        </ListItem>
      </div>
    );
  }

  function getFlockDetails() {
    const top = (
      <header className="flock-details-header">
        <h2 className="flock-details-heading">
          {getHeading(selectedKey)}
        </h2>
      </header>
    );

    function innerContent() {
      switch (selectedKey) {
        case 'confirmation':
          return (
            <AddWeighing
              houses={data.houseInformation}
              flockID={data.flockID}
              goTo={e => setSelectedKey(e)}
              sendToConfirmation
            />
          );
          break;

        case 'add':
          return (
            <AddWeighing
              houses={data.houseInformation}
              flockID={data.flockID}
              goTo={e => setSelectedKey(e)}
              sendToConfirmation={false}
            />
          );
          break;

        case 'edit':
          return (
            <EditWeighing
              houses={data.houseInformation}
              flockID={data.flockID}
              goTo={e => setSelectedKey(e)}
              sendToConfirmation={false}
              isEditing
            />
          );
          break;

        default:
          return (
            <>
              {weights.length > 0 &&
                weights.map(feed => (
                  <SummaryItem
                    key={`feed-${uid()}`}
                    feed={feed}
                    goTo={e => setSelectedKey(e)}
                    exclude={() => {}}
                    houses={data.houseInformation}
                  />
                ))}

              {isLoading ? (
                <LoadingHolder
                  isLoading={isLoading}
                  message={t('global.loading')}
                />
              ) : (
                weights.length === 0 && (
                  <NoContentTab icon="icon-scale">
                    <p>
                      {t(
                        'flocks.flocksActivities.sectionNextEvents.empty.title',
                      )}{' '}
                      <br />{' '}
                      <span className="lighter">
                        {t(
                          'flocks.flocksActivities.sectionNextEvents.empty.description',
                        )}
                      </span>
                    </p>
                  </NoContentTab>
                )
              )}
            </>
          );
      }
    }

    const content = (
      <div className="flock-details-content">{innerContent()}</div>
    );

    const details = (
      <div className="flock-details-container">
        {isMobile && (
          <>
            <FlockDetailsMobileWrapper className="mobile-wrapper">
              {content}
            </FlockDetailsMobileWrapper>
          </>
        )}

        {isGtMobile && (
          <>
            {top}
            <Separator />
            {flockDetailsTop}
            {content}
          </>
        )}
      </div>
    );

    return (
      <div className="flock-details">
        {isMobile && details}
        {isGtMobile && (
          <Box {...boxProps} style={{ maxWidth: '676px' }}>
            {details}
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
      <LoadingHolder isLoading={isLoading} />

      {!isLoading && (
        <Container className="flock-container flock-container__temporary-record">
          <TemporaryFlockMenu
            flockId={props.match.params.id}
            getHeading={getHeading}
            getTabletToggler={getTabletToggler}
            getToggler={getToggler}
            isTablet={isTablet}
            isMobile={isMobile}
            isGtMobile={isGtMobile}
            selectedKey={selectedKey}
            getFlockDetails={getFlockDetails}
            type={data.flockStatus}
            data={data}
            user={props.user}
          />

          {isGtMobile && getFlockDetails()}

          <Modal
            opened={submitResponse !== null}
            confirm={() => {
              setSelectedKey('add');
              setSubmitResponse(null);
            }}
            deny={() => setSubmitResponse(null)}
            onCloseModal={() => {
              setSubmitResponse(null);
              setSelectedWeight({});
            }}
            warning={!success}
            noButtons={!success}
          >
            <span
              className={`icon icon-${
                success ? 'check' : 'exclamation-triangle'
              }`}
            />
            <h4>{success ? t('global.validation.success') : t('global.validation.error')}</h4>
            {/* <p>{submitResponse}</p> */}
            <p>
              {success
                ? t('agro.flock.temporaryRecord.newRecord')
                : t('global.validation.record-error')}
            </p>
          </Modal>
        </Container>
      )}
    </>
  );
}
