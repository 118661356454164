import React, { useState, useEffect } from 'react';
import Icon from 'components/Icon';
import t from 'components/i18n';
import { List, ListItem } from 'components/List';
import { Menu } from './Library.styled';

export default function LibraryMenu({
  menuItems,
  getLibraryList,
  urlSubCategory,
  setSubCategory,
  subCategory,
}) {
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    if (menuItems.length > 0) {
      // if there is a url subcategory
      if (urlSubCategory) {
        const filterByUrlSubCategory = menuItems.findIndex(
          cat => cat.id === Number(urlSubCategory),
        );

        setActiveItem(
          filterByUrlSubCategory > -1 ? filterByUrlSubCategory : 0,
        );
      } else {
        // On change active top tab
        setActiveItem(0);
        setSubCategory(menuItems[0]);
      }
    }
  }, [urlSubCategory, menuItems, setSubCategory]);

  useEffect(() => {
    // On change active sidebar items
    if (menuItems && Number(activeItem) > -1) {
      setSubCategory(menuItems[activeItem]);
    }
  }, [activeItem, menuItems, setSubCategory]);

  useEffect(() => {
    if (subCategory && menuItems[activeItem]) {
      getLibraryList(false, menuItems[activeItem].id, 1, 10);
    }
  }, [activeItem, getLibraryList, menuItems, subCategory]);

  return (
    <Menu>
      <List>
        {menuItems.length > 0 &&
          menuItems.map((item, index) => (
            <div
              className="menu-item"
              onClick={() => setActiveItem(index)}
            >
              <ListItem
                noMargin
                key={index}
                className={index === activeItem ? 'active' : ''}
              >
                <Icon className="menu-icon" name="icon-folder" />
                <div className="list-item-content">
                  <div className="list-item-heading">
                    {t(item.name)}
                  </div>
                </div>
              </ListItem>
              <Icon className="arrow" name="icon-chevron-right" />
            </div>
          ))}
      </List>
    </Menu>
  );
}
