import React, { Fragment, useContext } from 'react';
import uid from 'uuid';
import { ThemeContext } from 'styled-components';
import { Button } from '@zendeskgarden/react-buttons';
import Icon from 'components/Icon';
import Container from 'components/Container';
// helpers
import { displayMessageText } from 'shared/utils/Helpers';

import { colorDanger } from 'components/GlobalStyles/variables';
import {
  ItemRow,
  ItemColumn,
  Separator,
} from '../StyledNextFlock.styled';
import t from 'components/i18n';

export default function InfoCard({
  title,
  mainInfo,
  mainLabel,
  headingList,
  houseInfoList,
  buttonLabel,
  buttonAction,
  bgColor,
}) {
  const themeContext = useContext(ThemeContext);
  return (
    <Container slim bgColor={themeContext.styles.palette[bgColor]}>
      <h4 style={{ fontSize: '18px', marginBottom: '16px' }}>
        {title}
      </h4>

      <div className="highlight">
        <h2>{mainInfo}</h2>
        <p>{mainLabel}</p>
      </div>

      <strong className="small-heading">{headingList}</strong>

      {houseInfoList.map((item, index) => {
        const arrow = item.quantity > item.expected ? 'up' : 'down';

        return (
          <Fragment key={`house-${uid()}`}>
            <ItemRow>
              <ItemColumn>
                <strong>{item.house}</strong>
              </ItemColumn>
              <ItemColumn className="align-right">
                <p>
                  <span className={item.alert ? 'red big' : 'big'}>
                    {item.quantity}
                  </span>
                  {item.alert && (
                    <Icon
                      name={`icon-arrow-${arrow}`}
                      className="big red"
                    />
                  )}
                </p>
                <p>
                  <span className="small">{item.small}</span>
                </p>
              </ItemColumn>
            </ItemRow>
            <br />

            {item.alert &&
              displayMessageText(
                'icon-exclamation-circle',
                item.alertLabel,
                colorDanger,
              )}
            {index !== houseInfoList.length - 1 ? (
              <Separator />
            ) : (
              <br />
            )}
          </Fragment>
        );
      })}

      {buttonLabel && (
        <Button
          className="white"
          style={{ width: '100%' }}
          onClick={e => buttonAction(e)}
        >
          {t(buttonLabel)}
        </Button>
      )}
    </Container>
  );
}
