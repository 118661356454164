import styled from 'styled-components';
import {
  colorDanger,
  colorSuccess,
  colorInfo,
  colorWarning,
  colorClear,
  colorPrimary,
  colorTertiary,
  colorGrayLighter,
  colorGrayDarkest,
  DEFAULT_SIZE,
  breakpointTablet,
} from 'components/GlobalStyles/variables';

const Badge = styled.span`
  display: inline-block;
  background-color: ${props => {
    if ('danger' in props) {
      return colorDanger;
    }
    if ('warning' in props) {
      return colorWarning;
    }
    if ('info' in props) {
      return colorInfo;
    }
    if ('dark' in props) {
      return colorGrayDarkest;
    }
    if ('primary' in props) {
      return colorPrimary;
    }
    if ('tertiary' in props) {
      return colorTertiary;
    }
    if ('default' in props) {
      return colorGrayLighter;
    }
    if ('white' in props) {
      return colorClear;
    }

    return colorSuccess;
  }};

  color: ${props => (props.white ? colorPrimary : colorClear)};
  border: ${props =>
    props.white ? `1px solid ${colorPrimary}` : 'none'};
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 10px;
  line-height: 16px;
  border-radius: ${DEFAULT_SIZE}px;
  text-align: center;
  padding: 0;
  min-width: 110px;

  align-self: flex-start;

  @media (max-width: ${breakpointTablet}px) {
    min-width: auto;
    padding: 0 8px;
  }
`;

export default Badge;
