// @flow

import { API_EVENT_URL } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getAllNotifications() {
  const response = await request(
    `${API_EVENT_URL}/notifications/GetAllNotifications`,
    {
      method: 'GET',
    },
  );

  return response.data;
}

/**
 * No limit and no previousDays returns all the notifications available
 * @param {number} limit Number of notifications to fetch
 * @param {number} previousDays The days you want you start to get data from
 */
export async function getLastNotifications(
  limit = '',
  previousDays = 2,
  app = 1,
) {
  const payload = {
    limit,
    app: app,
  };

  if (previousDays) {
    payload.previousDays = previousDays;
  }

  const response = await request(
    `${API_EVENT_URL}/notifications/GetLastNotifications`,
    {
      method: 'GET',
      payload,
    },
  );

  return response.data.filter(d => d.isOcculted === false);
}

export async function hideNotification(notificationID: number) {
  const response = await request(
    `${API_EVENT_URL}/notifications/HideNotification`,
    {
      method: 'POST',
      payload: {
        notificationID,
      },
    },
  );

  return response.data;
}

export async function readNotification(notificationID: number) {
  const response = await request(
    `${API_EVENT_URL}/notifications/ReadNotification`,
    {
      method: 'POST',
      payload: {
        notificationID,
      },
    },
  );

  return response.data;
}
