import styled from 'styled-components';
import { rgba } from 'polished';
import {
  colorGrayLightest,
  colorGrayDarkest,
} from 'components/GlobalStyles/variables';

export const SmallButtonStyled = styled.button`
  border-radius: 16px;
  border: none;
  background-color: ${props => props.buttonBg || colorGrayLightest};
  color: ${props => props.textColor || colorGrayDarkest};
  transition: 0.2s width ease-in;
  letter-spacing: 0.25px;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in;

  &:hover {
    background-color: ${props =>
      rgba(props.buttonBg || colorGrayLightest, 0.75)};
  }

  &:disabled {
    background: ${colorGrayLightest};
  }

  .icon {
    font-size: 20px;
    margin-left: 8px;

    &.icon-close {
      font-size: 18px;
    }
  }
`;
