import styled from 'styled-components';

export const ProgressBar = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: rgba(
    ${props => props.color},
    ${props => props.opacity}
  );
  position: relative;
  height: 4px;
  > span {
    background-color: ${props => props.bg};
    display: inline-block;
    width: ${props => props.value}%;
    height: 4px;
    position: absolute;
    border-radius: 8px;
  }
`;
