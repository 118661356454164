import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
// context
import { Button } from '@zendeskgarden/react-buttons';
import { Field } from '@zendeskgarden/react-forms';
import { Row, Col } from '@zendeskgarden/react-grid';
import { useSelector } from 'react-redux';
import LoadingHolder from 'components/LoadingHolder';
import { getAnimalIcon } from 'shared/utils/Helpers';
import { BtnGroup } from 'components/Forms/BtnGroup/BtnGroup.styled';
import PromptModal from 'components/PromptModal';
import { FieldArray } from 'formik';
import {
  Form,
  LabelStyled,
  ToggleStyled,
  AddItem,
} from 'components/Forms/Forms.styled';
import {
  InputField,
  DropdownField,
  DropdownFieldFromArray,
  AutoCompleteDropdownField,
  TimePickerField,
  FloatInputField,
} from 'components/Forms/Fields';
import FormAccordion from 'components/FormAccordion';
import { Separator } from 'components/ResumeCard/ResumeCard.styled';
import t from 'components/i18n';
import { isUndefined } from 'utils';

export default function FalForm(props) {
  const [maxH, setMaxH] = useState(600);
  const {
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    feedTypes,
    isLoading,
    isFirstDay,
    showFlockClosure,
    resetForm,
    getFormVendors,
    vendors,
    loadingVendors,
    values,
    errors,
    touched,
    dirty,
    currentFlockAge,
  } = props;

  const { isMobile } = props.responsive;

  const user = useSelector(state => state.user);
  const animalIcon = useMemo(() => getAnimalIcon(user.animalType), [
    user,
  ]);

  const userCountryCode =
    !isUndefined(user.countryCodes) && user.countryCodes[0];

  const checkFoodConsume = useCallback((val, err) => {
    const checkFeedType =
      val.initialFeedType &&
      val.initialFeedTime &&
      !err.initialFeedType &&
      !err.initialFeedTime;

    const checkMovimentation =
      val.movimentationType &&
      val.movimentationFeedType &&
      val.movimentationFeedValue &&
      val.movimentationVendorInformation &&
      !err.movimentationType &&
      !err.movimentationFeedType &&
      !err.movimentationFeedValue &&
      !err.movimentationVendorInformation;

    if (val.isInitialFeed) {
      if (val.hasMovimentation) {
        return checkFeedType && checkMovimentation;
      }
      return checkFeedType;
    }

    if (val.hasMovimentation) {
      if (val.isInitialFeed) {
        return checkFeedType && checkMovimentation;
      }

      return checkMovimentation;
    }

    return false;
  }, []);

  useEffect(() => {
    if (isMobile) setMaxH(1000);

    if (values.waterConsumption === null) {
      setFieldValue('waterConsumption', 0);
    }
  }, []);

  const checkMortalityField = useCallback((val, err) => {
    if (isFirstDay) {
      return (
        (val.mortalityOnArrival || val.mortalityOnArrival === '0') &&
        !err.mortalityOnArrival
      );
    }

    return (
      val.naturalDeath &&
      val.refuseElimination &&
      val.legElimination &&
      val.otherElimination &&
      !err.naturalDeath &&
      !err.refuseElimination &&
      !err.legElimination &&
      !err.otherElimination &&
      !err.mortalityOnArrival
    );
  }, []);

  return (
    <>
      <PromptModal
        opened={false}
        history={props.history}
        when={dirty}
        confirmLabel={t('global.button.exit')}
        denyLabel={t('global.button.cancel')}
        message={t(
          'flock.openRecord.history.form.exit-confirmation.description',
        )}
        title={t(
          'flock.openRecord.history.form.exit-confirmation.title',
        )}
      />
      {isLoading ? (
        <LoadingHolder
          isLoading={isLoading}
          message={t('global.loading')}
        />
      ) : (
        <>
          <Form autoComplete="off">
            <FormAccordion
              icon={`${animalIcon}-alt`}
              sectionTitle={
                isFirstDay
                  ? t(
                      'flocks.newFal.form.section.dead-at-arrive.title',
                    )
                  : t('flocks.newFal.form.section.mortality.title')
              }
              isChecked={checkMortalityField(values, errors)}
              maxHeight={maxH}
              isRequired
            >
              {isFirstDay && (
                <Row>
                  <Col sm={12}>
                    <InputField
                      masked
                      type="dot"
                      size={5}
                      field="mortalityOnArrival"
                      label={t(
                        'flocks.fal.form.mortalityOnArrival.label',
                      )}
                      placeholder={t(
                        'flocks.fal.form.mortalityOnArrival.placeholder',
                      )}
                      setState={e =>
                        setFieldValue('mortalityOnArrival', e)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>
              )}

              {!isFirstDay && (
                <Row>
                  <Col sm={6}>
                    <InputField
                      masked
                      type="dot"
                      size={5}
                      field="naturalDeath"
                      label={t('flocks.fal.form.naturalDeath.label')}
                      placeholder={t(
                        'flocks.fal.form.naturalDeath.placeholder',
                      )}
                      setState={e => setFieldValue('naturalDeath', e)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col sm={6}>
                    <InputField
                      masked
                      type="dot"
                      size={5}
                      field="refuseElimination"
                      label={t(
                        'flocks.fal.form.refuseElimination.label',
                      )}
                      placeholder={t(
                        'flocks.fal.form.refuseElimination.placeholder',
                      )}
                      setState={e =>
                        setFieldValue('refuseElimination', e)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>
              )}

              {!isFirstDay && (
                <Row>
                  <Col sm={6}>
                    <InputField
                      masked
                      type="dot"
                      size={5}
                      field="legElimination"
                      label={t(
                        'flocks.fal.form.legElimination.label',
                      )}
                      placeholder={t(
                        'flocks.fal.form.legElimination.placeholder',
                      )}
                      setState={e =>
                        setFieldValue('legElimination', e)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col sm={6}>
                    <InputField
                      masked
                      type="dot"
                      size={5}
                      field="otherElimination"
                      label={t(
                        'flocks.fal.form.otherElimination.label',
                      )}
                      placeholder={t(
                        'flocks.fal.form.otherElimination.placeholder',
                      )}
                      onChange={handleChange}
                      setState={e =>
                        setFieldValue('otherElimination', e)
                      }
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>
              )}
            </FormAccordion>

            <FormAccordion
              icon="icon-scale"
              sectionTitle={t(
                'flocks.fal.form.weightValue.section.title',
              )}
              isChecked={
                values.weightValue &&
                values.weightTime &&
                !errors.weightValue &&
                !errors.weightTime
              }
              hide={isFirstDay}
              isRequired={values.isRequiredWeight}
            >
              <Row>
                <Col sm={6}>
                  <Field>
                    <FloatInputField
                      field="weightValue"
                      label={t('flocks.fal.form.weightValue.label')}
                      placeholder={t(
                        'flocks.fal.form.weightValue.placeholder',
                      )}
                      setState={e => setFieldValue('weightValue', e)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      max={999999}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={6}>
                  <TimePickerField
                    type="time"
                    field="weightTime"
                    label={t(
                      'next.flock.recordItemList.weightValue.weightTime.title',
                    )}
                    onChange={e => setFieldValue('weightTime', e)}
                    onBlur={handleBlur}
                    dateFormat="HH:mm"
                    timeCaption={t(
                      'flocks.fal.form.weightValue.placeholder',
                    )}
                    timeIntervals={15}
                    disabled={false}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
              </Row>
            </FormAccordion>

            <FormAccordion
              icon="icon-drop-rain"
              sectionTitle={t(
                'flock.openRecord.history.form.water-consumition.section-title',
              )}
              hideSeparator={!isFirstDay}
              isChecked={
                values.waterConsumption &&
                values.waterTime &&
                !errors.waterConsumption &&
                !errors.waterTime
              }
              opened={!isFirstDay}
              hide={isFirstDay}
              isRequired={!userCountryCode.includes('TR')}
            >
              <Row>
                <Col sm={6}>
                  <Field>
                    <InputField
                      masked
                      type="dot"
                      field="waterConsumption"
                      label={t(
                        'flocks.fal.form.waterConsumption.label',
                      )}
                      placeholder={t(
                        'flocks.fal.form.waterConsumption.placeholder',
                      )}
                      setState={e =>
                        setFieldValue('waterConsumption', e)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      min={0}
                      max={999999}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={6}>
                  <TimePickerField
                    type="time"
                    field="waterTime"
                    label={t('flocks.fal.form.waterTime.label')}
                    onChange={e => setFieldValue('waterTime', e)}
                    onBlur={handleBlur}
                    dateFormat="HH:mm"
                    timeCaption={t(
                      'flocks.fal.form.waterTime.placeholder',
                    )}
                    timeIntervals={15}
                    disabled={false}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
              </Row>
            </FormAccordion>

            <FormAccordion
              icon="icon-temperature"
              sectionTitle={t(
                'flocks.fal.form.temperature.section.title',
              )}
              isChecked={
                values.minTemperature &&
                values.maxTemperature &&
                !errors.minTemperature &&
                !errors.maxTemperature
              }
              hide={isFirstDay}
              isRequired={!userCountryCode.includes('TR')}
            >
              <Row>
                <Col sm={6}>
                  <InputField
                    masked
                    type="temperature"
                    field="minTemperature"
                    label={t('flocks.fal.form.minTemperature.label')}
                    placeholder={t(
                      'flocks.fal.form.minTemperature.placeholder',
                    )}
                    onChange={handleChange}
                    setState={e => setFieldValue('minTemperature', e)}
                    onBlur={handleBlur}
                    disabled={false}
                    min={0}
                    max={100}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col sm={6}>
                  <InputField
                    masked
                    type="temperature"
                    field="maxTemperature"
                    label={t('flocks.fal.form.maxTemperature.label')}
                    placeholder={t(
                      'flocks.fal.form.maxTemperature.placeholder',
                    )}
                    onChange={handleChange}
                    setState={e => setFieldValue('maxTemperature', e)}
                    onBlur={handleBlur}
                    disabled={false}
                    min={0}
                    max={100}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
              </Row>
            </FormAccordion>

            <FormAccordion
              icon="icon-drop"
              sectionTitle={t(
                'flocks.fal.form.humidity.section.title',
              )}
              isChecked={
                values.minHumidity &&
                values.maxHumidity &&
                !errors.minHumidity &&
                !errors.maxHumidity
              }
              hide={isFirstDay}
              isRequired={!userCountryCode.includes('TR')}
            >
              <Row>
                <Col sm={6}>
                  <InputField
                    type="number"
                    field="minHumidity"
                    label={t('flocks.fal.form.minHumidity.label')}
                    placeholder={t(
                      'flocks.fal.form.minHumidity.placeholder',
                    )}
                    onChange={handleChange}
                    setState={e => setFieldValue('minHumidity', e)}
                    onBlur={handleBlur}
                    disabled={false}
                    min={0}
                    max={100}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col sm={6}>
                  <InputField
                    type="number"
                    field="maxHumidity"
                    label={t('flocks.fal.form.maxHumidity.label')}
                    placeholder={t(
                      'flocks.fal.form.maxHumidity.placeholder',
                    )}
                    setState={e => setFieldValue('maxHumidity', e)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={false}
                    min={0}
                    max={100}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
              </Row>
            </FormAccordion>

            <FormAccordion
              icon="icon-water"
              sectionTitle={t('flocks.fal.form.ph.section.title')}
              isChecked={
                values.chlorine &&
                values.ph &&
                !errors.chlorine &&
                !errors.ph
              }
              hide={isFirstDay}
              isRequired={values.isRequiredPh}
              // hide={!isRequiredPh}
            >
              <Row>
                <Col sm={6}>
                  <InputField
                    masked
                    type="dot"
                    size={5}
                    field="chlorine"
                    label={t('flocks.fal.form.minChlorine.label')}
                    placeholder={t(
                      'flocks.fal.form.minChlorine.placeholder',
                    )}
                    setState={e => setFieldValue('chlorine', e)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={false}
                    min={0}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col sm={6}>
                  <InputField
                    masked
                    type="temperature"
                    field="ph"
                    label={t('flocks.fal.form.ph.label')}
                    placeholder={t('Informar valor pH da água')}
                    setState={e => setFieldValue('ph', e)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={false}
                    min={0}
                    max={14}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
              </Row>
            </FormAccordion>

            <FormAccordion
              icon="icon-corn"
              sectionTitle={t(
                'flocks.fal.form.feedConsumption.section.title',
              )}
              isChecked={checkFoodConsume(values, errors)}
              maxHeight={maxH}
              isRequired={
                values.isInitialFeed || values.hasMovimentation
              }
            >
              <Row>
                <Col sm={12}>
                  <Field>
                    <ToggleStyled
                      checked={values.isInitialFeed}
                      onChange={e => {
                        setFieldValue(
                          'isInitialFeed',
                          e.target.checked,
                        );
                        setFieldValue('initialFeedType', '');
                        setFieldValue('initialFeedTime', '');
                      }}
                    >
                      {!isFirstDay && (
                        <LabelStyled regular={false}>
                          {t(
                            'flocks.fal.form.feedConsumption.today-section.label',
                          )}
                        </LabelStyled>
                      )}
                    </ToggleStyled>
                  </Field>
                </Col>
              </Row>

              <Separator />

              <Row>
                <Col sm={6}>
                  <Field>
                    <DropdownField
                      selectedItem={values.initialFeedType}
                      field="initialFeedType"
                      label={t(
                        'flocks.fal.form.initialFeedType.label',
                      )}
                      placeholder={t(
                        'flocks.fal.form.initialFeedType.placeholder',
                      )}
                      onSelect={item => {
                        setFieldValue('initialFeedType', item);
                      }}
                      options={feedTypes}
                      optionLabel="description"
                      disabled={
                        isFirstDay ? false : !values.isInitialFeed
                      }
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
                <Col sm={6}>
                  <Field>
                    <TimePickerField
                      type="time"
                      field="initialFeedTime"
                      label={t(
                        'flocks.fal.form.initialFeedTime.label',
                      )}
                      onChange={e =>
                        setFieldValue('initialFeedTime', e)
                      }
                      onBlur={handleBlur}
                      dateFormat="HH:mm"
                      timeCaption={t(
                        'flocks.fal.form.initialFeedTime.placeholder',
                      )}
                      timeIntervals={15}
                      disabled={
                        isFirstDay ? false : !values.isInitialFeed
                      }
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
              </Row>

              <Separator />

              <Row>
                <Col sm={12}>
                  <Field>
                    <ToggleStyled
                      checked={values.hasMovimentation}
                      onChange={e => {
                        setFieldValue(
                          'hasMovimentation',
                          e.target.checked,
                        );
                        setFieldValue('movimentationType', '');
                        setFieldValue('movimentationFeedType', '');
                        setFieldValue('movimentationFeedValue', '');
                        setFieldValue(
                          'movimentationVendorInformation',
                          '',
                        );
                      }}
                    >
                      <LabelStyled regular={false}>
                        {t(
                          'flocks.fal.form.movimentationFeed.section.label',
                        )}
                      </LabelStyled>
                    </ToggleStyled>
                  </Field>
                </Col>
              </Row>

              <Separator />

              <Row>
                <Col sm={4}>
                  <DropdownField
                    field="movimentationType"
                    label={t(
                      'flocks.fal.form.movimentationType.label',
                    )}
                    placeholder={t(
                      'flocks.fal.form.movimentationType.placeholder',
                    )}
                    onSelect={e => {
                      setFieldValue('movimentationType', e);
                    }}
                    options={['Transferência', 'Recebimento']}
                    disabled={!values.hasMovimentation}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col sm={8}>
                  <DropdownField
                    field="movimentationFeedType"
                    label={t(
                      'flocks.fal.form.movimentationFeedType.label',
                    )}
                    placeholder={t(
                      'flocks.fal.form.movimentationFeedType.placeholder',
                    )}
                    onSelect={e => {
                      setFieldValue('movimentationFeedType', e);
                      setFieldValue(
                        'movimentationFeedMaxAllowed',
                        e.maximumAllowed,
                      );
                    }}
                    disabled={!values.hasMovimentation}
                    options={feedTypes}
                    optionLabel="description"
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={4}>
                  <InputField
                    type="number"
                    field="movimentationFeedValue"
                    label={t(
                      'flocks.fal.form.movimentationFeedValue.label',
                    )}
                    placeholder={t(
                      'flocks.fal.form.movimentationFeedValue.placeholder',
                    )}
                    setState={e =>
                      setFieldValue('movimentationFeedValue', e)
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.hasMovimentation}
                    min={0}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col sm={8}>
                  <Field>
                    <AutoCompleteDropdownField
                      field="movimentationVendorInformation"
                      label={t(
                        'flocks.fal.form.movimentationVendorInformation.label',
                      )}
                      placeholder={t(
                        'flocks.fal.form.movimentationVendorInformation.placeholder',
                      )}
                      onSelect={e => {
                        setFieldValue(
                          'movimentationVendorInformation',
                          e,
                        );
                      }}
                      disabled={!values.hasMovimentation}
                      loadingLabel={t('global.loading')}
                      optionLabel="vendorName"
                      caption="vendorCode"
                      noMatchLabel={t(
                        'flocks.fal.form.movimentationVendorInformation.no-search-results',
                      )}
                      search={getFormVendors}
                      loading={loadingVendors}
                      options={vendors}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </Field>
                </Col>
              </Row>
            </FormAccordion>

            <FormAccordion
              icon="icon-lock-enabled"
              sectionTitle={t(
                'flocks.fal.form.close-fal.section.title',
              )}
              isChecked={
                values.waterConsume &&
                values.selfConsume &&
                values.feedType &&
                values.feedQuantity &&
                !errors.waterConsume &&
                !errors.selfConsume &&
                !errors.feedType &&
                !errors.feedQuantity
              }
              maxHeight={maxH}
              hide={!showFlockClosure}
            >
              <Row>
                <Col sm={12}>
                  <Field>
                    <ToggleStyled
                      checked={values.isFlockClosure}
                      onChange={e =>
                        setFieldValue(
                          'isFlockClosure',
                          e.target.checked,
                        )
                      }
                    >
                      <LabelStyled regular={false}>
                        {t(
                          'flocks.fal.form.close-fal.section.is-today.title',
                        )}
                      </LabelStyled>
                    </ToggleStyled>
                  </Field>
                </Col>
              </Row>

              <Separator />

              <Row>
                <Col sm={6}>
                  <InputField
                    masked
                    type="dot"
                    field="waterConsume"
                    label={t('flocks.fal.form.waterConsume.label')}
                    placeholder={t(
                      'flocks.fal.form.waterConsume.placeholder',
                    )}
                    setState={e => setFieldValue('waterConsume', e)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.isFlockClosure}
                    min={0}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col sm={6}>
                  <InputField
                    type="number"
                    field="selfConsume"
                    label={t('flocks.fal.form.selfConsume.label')}
                    placeholder={t(
                      'flocks.fal.form.selfConsume.placeholder',
                    )}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.isFlockClosure}
                    min={0}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
              </Row>

              <Separator />

              <Row>
                <Col sm={12}>
                  <Field>
                    <ToggleStyled
                      checked={values.hasFeedRemnant}
                      onChange={e => {
                        setFieldValue(
                          'hasFeedRemnant',
                          e.target.checked,
                        );
                        setFieldValue('feedRemnant', [
                          {
                            feedType: null,
                            feedQuantity: null,
                          },
                        ]);
                      }}
                    >
                      <LabelStyled regular={false}>
                        {t('flocks.fal.form.feedRemnant.placeholder')}
                      </LabelStyled>
                    </ToggleStyled>
                  </Field>
                </Col>
              </Row>

              <FieldArray
                name="feedRemnant"
                render={arrayHelpers =>
                  values.feedRemnant.map((item, index) => (
                    <>
                      {index > 0 && values.feedRemnant[index] && (
                        <div>
                          <AddItem
                            onClick={() => {
                              arrayHelpers.remove(index);
                              setMaxH(maxH - 152);
                            }}
                          >
                            <span className="icon icon-minus" />{' '}
                            {t(
                              'flocks.fal.form.remove-feedRemnant.placeholder',
                            )}
                          </AddItem>
                        </div>
                      )}

                      <Row index={index}>
                        <Col sm={6}>
                          <Field>
                            <DropdownFieldFromArray
                              field={`feedRemnant.${index}.feedType`}
                              label={t(
                                'flocks.fal.form.feedRemnant-feedType.label',
                              )}
                              placeholder={t(
                                'flocks.fal.form.feedRemnant-feedType.placeholder',
                              )}
                              fieldString="feedType"
                              from="feedRemnant"
                              index={index}
                              onSelect={e => {
                                setFieldValue(
                                  `feedRemnant.${index}.feedType`,
                                  e,
                                );
                                setFieldValue(
                                  `feedRemnant.${index}.feedRemnantMaxAllowed`,
                                  e.maximumAllowed,
                                );
                              }}
                              options={feedTypes}
                              optionLabel="description"
                              disabledItems={values.feedRemnant.map(
                                e => e.feedType,
                              )}
                              disabled={!values.hasFeedRemnant}
                              values={values}
                              errors={errors}
                              touched={touched}
                            />
                          </Field>
                        </Col>
                        <Col sm={6}>
                          <InputField
                            type="number"
                            field={`feedRemnant.${index}.feedQuantity`}
                            label={t(
                              'flocks.fal.form.feedRemnant-feedQuantity.label',
                            )}
                            placeholder={t(
                              'flocks.fal.form.feedRemnant-feedQuantity.placeholder',
                            )}
                            from="feedRemnant"
                            index={index}
                            fieldString="feedQuantity"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!values.hasFeedRemnant}
                            min={0}
                            values={values}
                            errors={errors}
                            touched={touched}
                          />
                        </Col>
                      </Row>

                      {values.feedRemnant[index].feedType &&
                        values.feedRemnant[index].feedQuantity &&
                        !values.feedRemnant[index + 1] &&
                        values.feedRemnant.length !==
                          feedTypes.length && (
                          <div>
                            <AddItem
                              onClick={() => {
                                arrayHelpers.push({
                                  feedType: '',
                                  feedQuantity: '',
                                });
                                setMaxH(maxH + 182); // section height
                              }}
                            >
                              <span className="icon icon-plus" />{' '}
                              {t(
                                'flocks.fal.form.add-feedRemnant.placeholder',
                              )}
                            </AddItem>
                          </div>
                        )}
                    </>
                  ))
                }
              />
            </FormAccordion>

            <Separator gap={8} />

            <br />

            <Row>
              <Col sm={6} />
              <Col sm={6}>
                <BtnGroup>
                  {userCountryCode.includes('TR') &&
                    ['7', '14', '21', '28', '35', '42'].includes(
                      currentFlockAge,
                    ) && (
                    <Button
                      key="submitForm"
                      onClick={e => handleSubmit(e)}
                      disabled={
                        !props.isValid || props.isValidating
                      }
                    >
                      {t('global.button.send')}
                    </Button>
                  )}
                  {userCountryCode.includes('TR') &&
                    !['7', '14', '21', '28', '35', '42'].includes(
                      currentFlockAge,
                    ) && (
                    <Button
                      key="submitForm"
                      onClick={e => handleSubmit(e)}
                      disabled={
                        !props.isValid || props.isValidating
                      }
                    >
                      {t('global.button.send')}
                    </Button>
                  )}
                  {!userCountryCode.includes('TR') && (
                    <Button
                      key="submitForm"
                      onClick={e => handleSubmit(e)}
                      disabled={!props.isValid || props.isValidating}
                    >
                      {t('global.button.send')}
                    </Button>
                  )}
                  <Button
                    key="clean"
                    className="white"
                    onClick={() => {
                      resetForm();
                    }}
                  >
                    {t('global.button.clear')}
                  </Button>
                </BtnGroup>
              </Col>
            </Row>
          </Form>

        </>
      )}
    </>
  );
}
