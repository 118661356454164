import styled from "styled-components";
import { rgba } from "polished";

import ContainerBase from "components/Container";
import { colorDanger, colorGrayDarker, colorGrayDarkest, colorInfo } from "components/GlobalStyles/variables";
import { BtnGroup as BtnGroupBase } from 'components/Forms/BtnGroup/BtnGroup.styled';

export const Separator = styled.hr`
  margin: 0 -16px;
  opacity: 0.25;
`;

export const Container = styled(ContainerBase)`
  margin: 20px;
  max-width: 50%;
  min-width: 600px;

  .header {
    margin-bottom: 16px;
  }
`;

export const FormSection = styled.section`
  padding: 16px 16px 12px;
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.16)};
  margin: 0 -16px;
`;

export const Item = styled.li`
  color: ${colorGrayDarkest};
  border-bottom: 1px solid ${rgba(colorGrayDarkest, 0.16)};
  padding: 16px;
  font-size: 14px;
  line-height: 21px;
  list-style: none;

  p {
    margin: 0;
  }

  &:last-of-type {
    border: none;
  }

  &.right {
    justify-content: flex-end;
  }

  .margin {
    margin-bottom: 8px;
  }

  .label {
    font-size: 12px;
  }

  .small {
    font-size: 12px;
    line-height: 16px;
  }

  .icon {
    font-size: 21px;
    margin: 0 0 0 16px;
  }

  button {
    width: 154px;
  }
`;

export const SectionWrapper = styled.div`
  padding: 16px;

  & .file-input__items {
    margin-left: 0;
    margin-bottom: 12px;
  }

  & .file-input {
    margin-top: 14px;
  }
`;

export const Title = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 24px;
  color: ${colorGrayDarker}

  ${({ required }) => required && `
    &:after {
      content: "*";
      color: #D22630;
    }
  `}
`;

export const Subtitle = styled.h4`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 24px !important;
  color: ${colorGrayDarker}

  ${({ required }) => required && `
    &:after {
      content: "*";
      color: #D22630;
    }
  `}
`;

export const BtnGroup = styled(BtnGroupBase)`
  margin-top: 16px;
`;

export const StatusBadgeContainer = styled.div`
  height: 42px;
  background-color: ${({ error }) => error ? colorDanger : colorInfo};
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  span {
    margin: 0 10px 0 20px;
    font-size: 24px;
  }
`;
