import { API_URL, KONG_CLIENT_ID } from 'config/constants';
import { request } from 'shared/utils/client';

export async function getAnnouncements({ quantity = 0, system = 'agro' }) {
  const response = await request(
    `${API_URL}/Announcement/GetAnnouncements?quantity=${quantity}&system=${system}`,
    {
      method: 'GET',
    },
  );
  return response;
}

export async function getAnnouncementsWithPagination({ page = 0, limit = 10, system = 'agro' }) {
  const payload = {
    page,
    limit,
    system
  };

  const response = await request(
    `${API_URL}/Announcement/GetAnnouncementsWithPagination`,
    {
      method: 'GET',
      payload
    },
  );
  return response;
}

export async function getAnnouncementsById(id, token = null) {
  const response = await request(
    `${API_URL}/Announcement/GetAnnouncementById?id=${id}`,
    {
      headers: {
        Authorization: token,
        client_id: KONG_CLIENT_ID
      },
      method: 'GET',
    },
  );
  return response;
}

export async function getRegionalsWithCenters() {
  const response = await request(
    `${API_URL}/Announcement/GetRegionalsWithCenters`,
    {
      method: 'GET',
    },
  );
  return response;
}

export async function getVendorsByCenter(
  center,
  producers,
  supervisors,
) {
  const response = await request(
    `${API_URL}/Announcement/GetVendorsByCenter?centerCode=${center}&onlyProducers=${producers}&onlySupervisors=${supervisors}`,
    {
      method: 'GET',
    },
  );
  return response;
}

export async function putAnnouncement(payload) {
  const response = await request(`${API_URL}/Announcement`, {
    method: 'PUT',
    payload,
  });
  return response;
}

export async function deleteAnnouncement(id) {
  const response = await request(
    `${API_URL}/Announcement/DeleteAnnouncement?announcementID=${id}`,
    {
      method: 'DELETE',
    },
  );
  return response.data;
}
